import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T } from '@/modules/Language'
import { orderMutations } from '@/modules/Order/mutations'
import { salesHooks } from '@/modules/Sales/hooks'

import { OrderConfirmationAction as CA } from '~generated-types'

import { Confirmation as ConfirmationFull, ConfirmationInfo } from '../types'

type Confirmation = ConfirmationFull | ConfirmationInfo

export default function ConfirmationService() {
  const { confirm } = useDialogService()
  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [createConfirmationMutation] =
    orderMutations.useCreateConfirmationMutation()
  const [removeConfirmationMutation] =
    orderMutations.useRemoveConfirmationMutation()
  const [triggerConfirmationActionMutation] =
    orderMutations.useTriggerConfirmationActionMutation()

  const updateConfirmations = (
    orderId: string,
    getConfirmations: (confirmations: Confirmation[]) => Confirmation[]
  ) =>
    setOrdersById((ordersById) => ({
      ...ordersById,
      [orderId]: {
        ...ordersById[orderId],
        confirmations: getConfirmations(ordersById[orderId].confirmations),
      },
    }))

  const createConfirmation = (orderId: string) =>
    createConfirmationMutation({ variables: { input: { orderId } } })
      .then(({ data }) => {
        if (data) {
          const confirmation = data.orderCreateConfirmation.confirmation

          updateConfirmations(orderId, (confirmations) => {
            const isAlreadyCreated = !!confirmations.find(
              ({ id }) => id === confirmation.id
            )

            return isAlreadyCreated
              ? confirmations.map((c) =>
                  c.id === confirmation.id ? confirmation : c
                )
              : [...confirmations, confirmation]
          })

          return confirmation
        }
      })
      .catch(() => undefined)

  const removeConfirmation = (confirmationId: string, orderId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Orders:Confirmation.REMOVE_CONFIRMATION.description</T>,
      title: <T>Orders:Confirmation.REMOVE_CONFIRMATION.title</T>,
    })
      .then(() =>
        removeConfirmationMutation({ variables: { input: { confirmationId } } })
          .then(({ data }) => {
            if (data) {
              updateConfirmations(orderId, (confirmations) =>
                confirmations.filter(({ id }) => id !== confirmationId)
              )
            }

            return data
          })
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const triggerConfirmationAction = (
    action: CA,
    confirmationId: string,
    orderId: string
  ) =>
    triggerConfirmationActionMutation({
      variables: { input: { action, confirmationId } },
    })
      .then(({ data }) => {
        if (data) {
          const confirmation = data.orderConfirmationAction.confirmation

          updateConfirmations(orderId, (confirmations) =>
            confirmations.map((c) =>
              c.id === confirmationId ? confirmation : c
            )
          )
        }
      })
      .catch(() => undefined)

  return {
    createConfirmation,
    removeConfirmation,
    triggerConfirmationAction,
  }
}
