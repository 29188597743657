import { gql } from '@apollo/client'

export const RoomReservationsForDateParticipantFragment = gql`
  fragment RoomReservationsForDateParticipant on SalesParticipant {
    age
    ageCategory {
      abbreviation
      key
      name
      shortName
    }
    ageSource
    birthday {
      date
      month
      year
    }
    firstName
    gender
    id
    lastName
    postalAddress {
      address1
      address2
      postcode
      city
      country
    }
    sales {
      facet {
        abbreviation
        color
        id
        name
      }
      id
      locked
      name
      orderNumber
      orders {
        id
        paymentInfo {
          status
        }
      }
      paymentAgreement {
        code
        id
        name
      }
      type
    }
  }
`
