import React from 'react'

import {
  SpreadsheetTH as TH,
  SpreadsheetTHead as THead,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { T } from '@/modules/Language'

export const AttachmentListHeader = () => (
  <THead>
    <TR>
      <TH forcedWidth={60}>#</TH>
      <TH>
        <T>Attachments:field.fileName</T>
      </TH>
      <TH>
        <T>Attachments:field.createdAt</T>
      </TH>
      <TH>
        <T>Attachments:field.fileType</T>
      </TH>
      <TH>
        <T>Attachments:field.fileSize</T>
      </TH>
      <TH forcedWidth={120} />
    </TR>
  </THead>
)
