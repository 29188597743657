import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Address as AddressType, Participant } from '@/modules/FrontDesk'
import { T } from '@/modules/Language'
import { ParticipantAddressModal } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import { SalesParticipantUpdateInput as UpdateInput } from '~generated-types'

import { Placeholder } from '../../common'

type Props = {
  participant: Participant
  updateParticipant: (input: UpdateInput) => Promise<any>
}

export const Address = ({
  participant: {
    id,
    postalAddress,
    sales: { locked },
  },
  updateParticipant,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { address1, city, postcode, country } = postalAddress || {}

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const isEmptyAddress =
    !postalAddress || (!address1 && !city && !postcode && !country)

  const handleSubmit = (postalAddress: AddressType) =>
    updateParticipant({ id, postalAddress })

  const renderLabel = () => {
    if (isEmptyAddress) {
      return (
        <Placeholder>
          <T>FrontDesk:DailyRoomReservations.participants.setAddress</T>
        </Placeholder>
      )
    }

    return (
      <FlexColumn noPadding alignItems="flex-start">
        {address1 && <AddressSpan>{address1}</AddressSpan>}

        {(city || postcode) && (
          <AddressSpan>
            {postcode}
            {city && postcode && ' '}
            {city}
          </AddressSpan>
        )}

        {country && <span>{country}</span>}
      </FlexColumn>
    )
  }

  return (
    <InlineModalIconSection icon="location-dot">
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <ParticipantAddressModal
            address={postalAddress}
            handleUpdateAddress={handleSubmit}
          />
        }
        onClose={() => setModalOpen(false)}
        placement="bottom-start"
        referenceElement={({ ref }) => (
          <EditButton
            disabled={locked}
            onClick={() => setModalOpen(true)}
            innerRef={ref as Ref<HTMLButtonElement> | undefined}
            style={{
              color: palette.text.light,
              height: '100%',
              width: `calc(100% + ${spacing.gu(1)}rem)`,
            }}
          >
            {renderLabel()}
          </EditButton>
        )}
        zIndex={10003}
      />
    </InlineModalIconSection>
  )
}

//////

const AddressSpan = styled.span`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`
