import styled, { css } from 'styled-components/macro'

export const LineLeader = styled.div<{
  fullHeight?: boolean
  isPlaceholder?: boolean
}>`
  align-self: stretch;
  height: 100%;
  position: relative;
  height: 100%;

  ${({ theme }) => css`
    width: ${theme.spacing.gutter};
    margin-left: ${theme.spacing.gutterSmall};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}

  &::after {
    content: ' ';
    position: absolute;
    height: ${({ fullHeight }) => `calc(${fullHeight ? '100%' : '50%'} + 1px)`};
    width: 1px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      margin-left: calc(${theme.spacing.gutter} - 1px);
    `}
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;

    ${({ isPlaceholder, theme }) => css`
      background: ${isPlaceholder ? 'transparent' : theme.palette.smoke.main};
    `}
  }
`
