import { ChangeEvent, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'

import {
  DataTableInput as Input,
  DataTableSelect as Select,
} from '@/components/DataTable'
import { MealRow as MealRowType, useUpdateMealMutation } from '@/modules/Meals'
import { useTheme } from '@/theme'
import { parseTimeFromString } from '@/utils/time'

import { ScheduledMealStatus as Status } from '~generated-types'

import { useMealsContext } from '../../../../MealsState'
import { Row, Section, ShortSeparator } from '../../../common'
import { StatusTooltip } from './StatusTooltip'

type Props = {
  meal: MealRowType
}

export const MealRow = ({
  meal: { duration, ids, location, meal, start, statuses },
}: Props) => {
  const { spacing } = useTheme()
  const { isEditMode, restaurants } = useMealsContext()

  const [update] = useUpdateMealMutation()

  const [durationValue, setDurationValue] = useState<number>(duration ?? 0)
  const [startTimeValue, setStartTimeValue] = useState<string>(
    moment(start).format('HH:mm')
  )

  const isRemoved = statuses.includes(Status.ManuallyRemoved)

  const handleSetStartTime = () => {
    const time = parseTimeFromString(startTimeValue) ?? { hour: 0, minute: 0 }
    const startTime = moment(start)
      .hour(time.hour)
      .minute(time.minute)
      .format('HH:mm')
    const prevStartTime = moment(start).format('HH:mm')

    if (startTime !== prevStartTime) {
      update({
        variables: { input: { scheduledMealIds: ids, startTime } },
      }).catch(() => undefined)
    }
  }

  const handleSetDuration = () => {
    if (durationValue !== duration) {
      update({
        variables: {
          input: { duration: durationValue, scheduledMealIds: ids },
        },
      }).catch(() => undefined)
    }
  }

  const handleSetRestaurant = (key: string) => {
    update({
      variables: { input: { restaurantId: key, scheduledMealIds: ids } },
    }).catch(() => undefined)
  }

  const renderViewRow = () => (
    <Row>
      <div style={{ marginLeft: spacing.gutter }} />

      <Section width={`${spacing.gu(10)}rem`}>
        {moment.utc(start).format('HH:mm')}
      </Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(28)}rem`}>{meal.name}</Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(12)}rem`}>{duration ?? '–'} min</Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(18)}rem`}>{location?.name ?? '–'}</Section>
    </Row>
  )

  const renderRemovedRow = () => (
    <Row style={{ textDecoration: 'line-through' }}>
      <Section width={spacing.gutter} style={{ justifyContent: 'center' }}>
        <StatusTooltip statuses={statuses} />
      </Section>

      <Section
        width={`${spacing.gu(10)}rem`}
        style={{ marginLeft: spacing.gutter }}
      >
        {moment.utc(start).format('HH:mm')}
      </Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(28)}rem`}>{meal.name}</Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(12)}rem`}>{duration ?? '–'} min</Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(18)}rem`}>{location?.name ?? '–'}</Section>
    </Row>
  )

  const renderEditRow = () => (
    <Row>
      <Section width={spacing.gutter} style={{ justifyContent: 'center' }}>
        <StatusTooltip statuses={statuses} />
      </Section>

      <Section
        width={`${spacing.gu(10)}rem`}
        style={{ marginLeft: spacing.gutter }}
      >
        <Input
          onBlur={handleSetStartTime}
          onChange={(e) => setStartTimeValue(e.target.value)}
          onFocus={(e) => e.currentTarget.select()}
          placeholder=""
          showBorder
          style={{ flex: 'unset', width: 56 }}
          value={startTimeValue}
        />
      </Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(28)}rem`}>{meal.name}</Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(12)}rem`}>
        <DurationInput
          onBlur={() => handleSetDuration()}
          onChange={(e) => setDurationValue(Number(e.target.value))}
          onFocus={(e) => e.currentTarget.select()}
          showBorder
          type="number"
          value={`${durationValue}`}
        />
        <span style={{ marginLeft: spacing.gutterSmall }}>min</span>
      </Section>

      <ShortSeparator />

      <Section width={`${spacing.gu(18)}rem`}>
        <Select
          disabled={false}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            handleSetRestaurant(e.target.value)
          }
          showBorder
          style={{ padding: `0 ${spacing.gutterSmall}` }}
          value={location.id}
        >
          {restaurants.map((r) => (
            <option key={r.id} value={r.id}>
              {r.name}
            </option>
          ))}
        </Select>
      </Section>
    </Row>
  )

  return (
    <>
      {isEditMode && isRemoved && renderRemovedRow()}
      {isEditMode && !isRemoved && renderEditRow()}
      {!isEditMode && !isRemoved && renderViewRow()}
    </>
  )
}

////////////

const DurationInput = styled(Input)`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
