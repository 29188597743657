import styled from 'styled-components/macro'

import type { PropsWithTheme } from '@/theme'

type Props = {
  type?: 'white' | 'extraLightSmoke' | 'grey'
  zIndex?: 0 | 1 | 2
}

export const Canvas = styled.div<Props>`
  background: ${(props): string => backgroundReconciler(props)};
  box-shadow: ${(props): string => shadowReconciler(props)};
`

const shadowReconciler = (props: PropsWithTheme<Props>): string => {
  const defaultVal = 'none'
  const raised = '2px 2px 14px rgba(34, 38, 43, 0.18)'
  const focused =
    '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)'

  switch (props.zIndex) {
    case 0:
      return defaultVal
    case 1:
      return raised
    case 2:
      return focused
    default:
      return defaultVal
  }
}

const backgroundReconciler = (props: PropsWithTheme<Props>): string => {
  const defaultVal = props.theme.palette.white
  const white = props.theme.palette.white
  const extraLightSmoke = '#f6f7f9'

  switch (props.type) {
    case 'white':
      return white
    case 'grey':
      return extraLightSmoke
    default:
      return defaultVal
  }
}
