import { gql, useMutation } from '@apollo/client'

import type {
  CopySalesProductToCatalogMutation,
  CopySalesProductToCatalogMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProduct}

  mutation CopySalesProductToCatalog($input: SalesProductCopyToCatalogInput!) {
    salesProductCopyToCatalog(input: $input) {
      ...CatalogProduct
    }
  }
`

export const useCopySalesProductToCatalogMutation = () =>
  useMutation<
    CopySalesProductToCatalogMutation,
    CopySalesProductToCatalogMutationVariables
  >(MUTATION)
