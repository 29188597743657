import { gql } from '@apollo/client'

import customerBase from './customerBase'

export default gql`
  ${customerBase}

  fragment CustomerPerson on CustomerPerson {
    ...CustomerBase
    person {
      email
      firstName
      lastName
      phone
    }
  }
`
