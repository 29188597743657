import { gql } from '@apollo/client'

export const AccommodationIssueFragment = gql`
  fragment AccommodationIssue on ValidationIssue {
    key
    code
    level
  }
`

export const ReservationStatusFragment = gql`
  fragment ReservationStatus on ReservationStatus {
    reservedCapacity {
      rooms
      beds
      extraBeds
    }
    consumptionBreakdown {
      type
      consumption
      floatingConsumption
    }
  }
`

export const CapacityStatusFragment = gql`
  ${ReservationStatusFragment}

  fragment CapacityStatus on TargetCapacityStatus {
    status {
      ...ReservationStatus
    }
    dailyStatus {
      date
      status {
        ...ReservationStatus
      }
    }
  }
`

export const RoomReservationFragment = gql`
  ${AccommodationIssueFragment}

  fragment RoomReservation on RoomReservation {
    id
    issues {
      ...AccommodationIssue
    }
    request {
      beds
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      info
      extraBeds
      features {
        icon
        id
        name
        shortName
      }
      info
      room {
        floor
        id
        number
        beds
        extraBeds
        internalInfo
        roomType {
          id
          accommodationLevel {
            id
            name
            shortName
          }
          category {
            id
            name
          }
          name
        }
        status {
          cleaningStatus
        }
      }
    }
    participantRooms {
      id
      participant {
        id
      }
    }
    roomTypeReservation {
      id
    }
    type
    target {
      id
    }
  }
`

export const RoomTypeReservationFragment = gql`
  fragment RoomTypeReservation on RoomTypeReservation {
    id
    fulfilledByRooms
    request {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      features {
        icon
        id
        name
        shortName
      }
      info
      beds
      extraBeds
      rooms
      roomType {
        id
        name
        beds
        extraBeds
        accommodationLevel {
          id
          name
          shortName
        }
        category {
          id
          name
        }
        name
      }
    }
    target {
      id
    }
  }
`

export const AccommodationSettingsFragment = gql`
  fragment AccommodationSettings on AccommodationGroup {
    id
    settings {
      consumptionType
      consumptionDurationType
    }
    status {
      available
      dates {
        checkIn {
          date
        }
        checkOut {
          date
        }
      }
      reserved
      reservedDetailed {
        rooms
        beds
        extraBeds
      }
      type
      status {
        available
        date
        reserved
        reservedDetailed {
          rooms
          beds
          extraBeds
        }
      }
    }
  }
`

export const AccommodationTargetFragment = gql`
  ${CapacityStatusFragment}

  fragment AccommodationTarget on AccommodationTarget {
    accommodationGroup {
      id
      name
      sortOrder
    }
    default
    floating
    id
    name
    nonBlockingCapacity
    private
    sharedTo {
      id
      orderNumber
    }
    sortOrder
    status {
      ...CapacityStatus
    }
  }
`

export const AccommodationGroupFragment = gql`
  ${RoomReservationFragment}
  ${RoomTypeReservationFragment}
  ${AccommodationSettingsFragment}
  ${AccommodationTargetFragment}

  fragment AccommodationGroup on AccommodationGroup {
    id
    name
    sortOrder
    sales {
      id
      type
    }
    ...AccommodationSettings
    targets {
      ...AccommodationTarget
    }
    roomReservations {
      ...RoomReservation
    }
    roomTypeReservations {
      ...RoomTypeReservation
    }
  }
`
