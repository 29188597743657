import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'

export const PlainLinkButtonButton = styled(Button)<any>`
  color: #2f60e8;
  font-weight: 500;
  text-decoration: underline;

  background: transparent;
  border-color: transparent;

  outline: none;

  ${(props) =>
    props.asText &&
    css<any>`
      padding: 0;
      margin: 0;
      height: auto;

      letter-spacing: 0;
      font-weight: 400;
      line-height: inherit;
      text-decoration: none;

      border: none;
    `}

  &:not(:disabled):hover {
    color: #2f60e8;

    text-decoration: none;
    background: transparent;
    border-color: transparent;
  }

  &:not(:disabled):focus,
  &:not(:disabled):active {
    color: #2f60e8;

    text-decoration: none;
    background: transparent;
    border-color: transparent;
  }
`
