import styled, { css } from 'styled-components/macro'

export const ModalSheetOverlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(4, 14, 31, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      pointer-events: unset;
    `}
`
