import { gql, useMutation } from '@apollo/client'

import { AccommodationSettingsFragment } from '@/modules/Accommodation/Accommodation.fragments'

import {
  RoomTypeReservationDeleteMutation,
  RoomTypeReservationDeleteMutationVariables,
} from '~generated-types'

export type {
  RoomTypeReservationDeleteMutation,
  RoomTypeReservationDeleteMutationVariables,
}

export const ROOM_TYPE_RESERVATION_DELETE = gql`
  ${AccommodationSettingsFragment}

  mutation RoomTypeReservationDelete(
    $input: AccommodationRoomTypeReservationDeleteInput!
  ) {
    accommodationRoomTypeReservationDelete(input: $input) {
      deleted
      id
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export function useRoomTypeReservationDelete() {
  return useMutation<
    RoomTypeReservationDeleteMutation,
    RoomTypeReservationDeleteMutationVariables
  >(ROOM_TYPE_RESERVATION_DELETE)
}

export default useRoomTypeReservationDelete
