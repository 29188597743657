import { gql, useMutation } from '@apollo/client'

import {
  CancelSaleMutation,
  CancelSaleMutationVariables,
} from '~generated-types'

import salesLifecycle from '../fragments/salesLifecycle'

const MUTATION = gql`
  ${salesLifecycle}

  mutation CancelSale($input: SalesCancelInput!) {
    salesCancel(input: $input) {
      id
      locked
      lifecycle {
        ...SalesLifecycle
      }
    }
  }
`

export const useCancelSaleMutation = () =>
  useMutation<CancelSaleMutation, CancelSaleMutationVariables>(MUTATION)
