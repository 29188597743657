import { ComponentType } from 'react'
import styled, { css } from 'styled-components/macro'

export const Separator = styled.div`
  flex: 0 0 auto;
  height: 1px;
  max-height: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
  `}
` as ComponentType<any>
