import styled, { css } from 'styled-components/macro'

export const Title = styled.span`
  font-weight: 400;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};

    & small {
      color: ${theme.palette.text.lighter};
    }
  `}
`

export default Title
