import { v4 as uuidv4 } from 'uuid'

import { MomentDateRange } from '@/modules/Reservations/components/RoomLayout'

export type Column = {
  data: { end: string; id: string; start: string }
  id: string
  index: number
}

export const getColumns = ({ from, to }: MomentDateRange): Column[] => {
  const firstDay = from.clone().startOf('day')
  const lastDay = to.clone().startOf('day')
  const dayIterator = firstDay.clone()

  const items = []
  let index = 0

  while (dayIterator.isValid() && dayIterator.isSameOrBefore(lastDay)) {
    items.push({
      data: {
        end: dayIterator.clone().add(1, 'day').format('YYYY-MM-DD'),
        // @ts-ignore
        id: uuidv4(),
        start: dayIterator.format('YYYY-MM-DD'),
      },
      id: dayIterator.format('YYYY-MM-DD'),
      index,
    })

    index++
    dayIterator.add(1, 'day')
  }

  return items
}
