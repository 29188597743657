import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { DownloadableDocument } from '@/modules/Document'
import { T } from '@/modules/Language'
import * as salesMutations from '@/modules/Sales/mutations'

import { UseOwnerDocumentsQuery } from '~generated-types'

type Props = {
  document: DownloadableDocument
  readOnly: boolean
  refetch: () => Promise<ApolloQueryResult<UseOwnerDocumentsQuery>>
}

export const Header = ({
  document: {
    document: { id },
    printPDF,
    downloadPDF,
  },
  readOnly,
  refetch,
}: Props) => {
  const [downloadDocument] = salesMutations.useDownloadDocumentMutation()

  const handlePrintDocument = () =>
    printPDF()
      .then(() => downloadDocument({ variables: { input: { id } } }))
      .then(refetch)
      .catch(() => undefined)

  const handleDownloadDocument = () =>
    downloadPDF()
      .then(() => downloadDocument({ variables: { input: { id } } }))
      .then(refetch)
      .catch(() => undefined)

  return (
    <Wrapper alignItems="center">
      <Title>
        <T>SalesDetails:VST.vstDocument</T>
      </Title>

      <Spacer />

      <InnocuousButton
        compact
        disabled={readOnly}
        onClick={handlePrintDocument}
      >
        <PrimaryColor>
          <Icon icon="print" />
          <T>common:action.print</T>
        </PrimaryColor>
      </InnocuousButton>

      <InnocuousButton
        compact
        disabled={readOnly}
        onClick={handleDownloadDocument}
      >
        <PrimaryColor>
          <Icon icon="download" />
          <T>Orders:Documents.action.download</T>
        </PrimaryColor>
      </InnocuousButton>
    </Wrapper>
  )
}

///////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
  `}
`
