import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { CheckboxInput } from '@/components/FormControls'
import { TreeRow, TreeRowLabel } from '@/components/TreeSelector'
import { T } from '@/modules/Language'

import { SalesForDateType } from '~generated-types'

interface Props {
  setValue: (types: SalesForDateType[]) => void
  value: SalesForDateType[]
}

export const TypeFilter: FC<Props> = ({ setValue, value }): ReactElement => {
  const options = [
    SalesForDateType.Arriving,
    SalesForDateType.Present,
    SalesForDateType.Departing,
  ]

  const toggleValue = (type: SalesForDateType) => {
    if (value.includes(type)) {
      setValue(value.filter((x) => x !== type))
    } else {
      setValue([...value, type])
    }
  }

  return (
    <FilterSection
      forceBottomSpacing
      label={<T>FrontDesk:DailyCustomerList.type.title</T>}
      render={() => (
        <SelectorWrapper>
          {options.map((option) => (
            <CheckboxWrapper key={`type-option-${option}`}>
              <CheckboxInput
                checked={value.includes(option)}
                noMargin
                onChange={() => toggleValue(option)}
              />
              <TreeRowLabel>
                <T l10n={`FrontDesk:DailyCustomerList.type.${option}`} />
              </TreeRowLabel>
            </CheckboxWrapper>
          ))}
        </SelectorWrapper>
      )}
    />
  )
}

////////////

const CheckboxWrapper = styled(TreeRow)`
  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const SelectorWrapper = styled.div`
  border-radius: 4px;
  max-height: 500px;
  overflow: auto;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
