import { gql, useQuery } from '@apollo/client'

import {
  ParticipantsListDataQuery as QueryData,
  ParticipantsListDataQueryVariables as QueryVariables,
} from '~generated-types'

import { ParticipantFragmentFull } from '../ParticipantsList.fragments'

const QUERY = gql`
  ${ParticipantFragmentFull}

  query ParticipantsListData(
    $salesId: ID!
    $participantConnectionInput: ParticipantConnectionInput
  ) {
    sales(id: $salesId) {
      id
      type
      orderNumber
      participantConnection(input: $participantConnectionInput) {
        totalPages
        totalElements
        hasNextPage
        hasPreviousPage
        nodes {
          ...SalesParticipantFull
        }
      }
    }
  }
`

type Props = {
  skip: boolean
  variables: QueryVariables
}

export const useParticipants = ({ skip, variables }: Props) =>
  useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'no-cache',
    skip,
    variables,
  })
