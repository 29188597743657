import moment, { Moment } from 'moment'

import {
  CalendarNestedResourceElastic,
  CalendarResourceElastic,
} from '@/modules/Registry/CalendarResources'
import { CalendarViewMode } from '@/modules/Reservations'

import { ResourceDisplayName } from '../components/ResourceDisplayName'
import { getDateKeys } from './getDateKeys'

type Props = {
  nestedResourcesIds?: string[]
  parentResourceId?: string
  resource: CalendarResourceElastic | CalendarNestedResourceElastic
  targetDate: Moment
  viewMode: CalendarViewMode
}

export const getGridGroupForWeek = ({
  nestedResourcesIds,
  parentResourceId,
  resource,
  targetDate,
  viewMode,
}: Props) => ({
  emptyPlaceholder: null,
  error: null,
  fetching: false,
  id: resource.id,
  isFocused: false,
  sections: getDateKeys(viewMode, targetDate).map((dateKey) => ({
    id: `${resource.id}.${dateKey}`,
    isPooled: (resource as CalendarResourceElastic).isPooled,
    nestedResourcesIds,
    parentResourceId,
    resourceId: resource.id,
    targetDate: dateKey,
    title: `${moment(dateKey).format('dddd')} (${moment(dateKey).format(
      'DD.MM'
    )})`,
  })),
  title: (
    <ResourceDisplayName
      isNested={!(resource as CalendarResourceElastic).type}
      resource={resource}
      viewMode={viewMode}
    />
  ),
  titlePlaceholder: null,
})
