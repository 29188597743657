import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import { Meals } from './Meals'
import { MealsContextProvider } from './MealsState'

const SECTION: SectionFeatures = {
  icon: 'utensils',
  key: 'meals',
}

const MealsComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <MealsContextProvider>
      <Meals />
    </MealsContextProvider>
  </SectionContainer>
)

export default Object.freeze({
  component: MealsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
