interface GenericOrganizationCustomer {
  __typename: 'CustomerOrganization'
  customerNumber: string
  id: string
  organization: {
    businessId: string | null
    name: string | null
  }
}

interface GenericPersonCustomer {
  __typename: 'CustomerPerson'
  customerNumber: string
  id: string
  person: {
    firstName: string
    lastName: string
  }
}

type GenericCustomer = GenericOrganizationCustomer | GenericPersonCustomer

interface UseCustomerDetailsProps {
  customer: GenericCustomer
}

interface UseCustomerDetailsHook {
  customerNumber: string
  displayName: string | null
  id: string
}

export default function useCustomerDetails({
  customer,
}: UseCustomerDetailsProps): UseCustomerDetailsHook {
  const { customerNumber } = customer

  const displayName =
    customer.__typename === 'CustomerOrganization'
      ? customer.organization.name
      : parseName({
          firstName: customer?.person.firstName || '',
          lastName: customer?.person.lastName || '',
        })

  return {
    customerNumber,
    displayName,
    id: customer.id,
  }
}

////////////

const parseName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`
