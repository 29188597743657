import { gql, useMutation } from '@apollo/client'

import {
  CreateEventMutation,
  CreateEventMutationVariables,
} from '~generated-types'

export const MUTATION = gql`
  mutation CreateEvent($input: SalesCreateEventInput!) {
    salesCreateEvent(input: $input) {
      id
      orderNumber
    }
  }
`

export const useCreateEventMutation = () =>
  useMutation<CreateEventMutation, CreateEventMutationVariables>(MUTATION)
