import { StrapiBaseUrl } from '@/utils/api'
import { isUriExternal } from '@/utils/urls'

import type { BrandingOptions } from '../types'

export default function mapStrapiBrandingConfigToBrandingOptions(
  input: Record<string, unknown>
): BrandingOptions {
  const options: BrandingOptions = {}

  const faviconSrc =
    input && input.favicon
      ? parseStrapiImageUrl(input.favicon as Record<string | number, unknown>)
      : null
  const logoWideSrc =
    input && input.logo_wide
      ? parseStrapiImageUrl(input.logo_wide as Record<string | number, unknown>)
      : null

  if (faviconSrc) options.faviconSrc = faviconSrc
  if (logoWideSrc) options.logoWideSrc = logoWideSrc
  if (input.page_title) options.pageTitle = input.page_title as string

  return options
}

const parseStrapiImageUrl = (
  input: Record<string | number, unknown>
): string | null => {
  const rawUrl = typeof input.url === 'string' ? input.url : null

  if (!rawUrl) {
    return null
  }

  return isUriExternal(rawUrl) ? rawUrl : `${StrapiBaseUrl}${rawUrl}`
}
