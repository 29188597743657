import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

type Props = {
  days: number
  participants: number
}

export const DaysAndParticipants = ({ days, participants }: Props) => (
  <Wrapper>
    <Participants>
      {participants}
      <ParticipantIcon icon="user" size="xs" />
    </Participants>
    <Days>{`${days === 0 ? days : days.toFixed(2)} d`}</Days>
  </Wrapper>
)

/////

const Days = styled(FlexRow).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
}))`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1.5)}rem;
    width: ${theme.spacing.gu(11)}rem;
  `}
`

const ParticipantIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Participants = styled(FlexRow).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
}))`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const Wrapper = styled.div`
  display: inline-flex;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
