import styled, { css } from 'styled-components/macro'

import { Input, Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InputEditor, ToggleEditableValue } from '@/components/ToggleHelpers'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ProbabilitySource } from '~generated-types'

import { useSalesDetailsContext } from '../../../../SalesDetailsState'

type Props = {
  estimation: number
  setEstimation: (estimation: number) => void
}

export const Probability = ({ estimation, setEstimation }: Props) => {
  const { spacing } = useTheme()
  const {
    data: { id, probabilitySource, type },
    saleReadOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  const updateProbability = (probability: number) => {
    const validatedProbability =
      probability > 100 ? 100 : probability < 0 ? 0 : probability

    return updateAttributes(id, { probabilityEstimate: validatedProbability })
      .then((data) =>
        setEstimation(data?.probabilityEstimate ?? validatedProbability)
      )
      .catch(() => undefined)
  }

  return (
    <Wrapper flex={0.75} justifyContent="flex-start">
      <Label>
        <T>SalesDetails:Settings.probability.probability</T>
      </Label>

      {saleReadOnly ||
      (type === 'ENROLLMENT' &&
        probabilitySource === ProbabilitySource.Event) ? (
        <Placeholder alignItems="center">{estimation} %</Placeholder>
      ) : (
        <StyledToggleEditableValue
          mode="input"
          renderEditor={(onClose) => (
            <InputEditor
              close={onClose}
              handleSubmit={(value: string | null) =>
                updateProbability(value ? Number(value) : estimation || 0)
              }
              inputComponent={Input}
              onFocus={(e) => e.currentTarget.select()}
              style={{
                height: 30,
                margin: `0 -${spacing.gu(1)}rem`,
                maxWidth: `${spacing.gu(13)}rem`,
              }}
              type="number"
              value={`${estimation}`}
            />
          )}
          style={{ height: 30 }}
          value={<>{estimation} %</>}
        />
      )}
    </Wrapper>
  )
}

//////////

const StyledToggleEditableValue = styled(ToggleEditableValue)`
  ${({ theme }) => css`
    margin: 0 -${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled(FlexRow)`
  height: 30px;
  cursor: not-allowed;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
