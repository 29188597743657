import { CSSProperties, ReactNode } from 'react'

import { ListTR } from './common'

type Props = {
  align?: any
  children: ReactNode
  highlight?: boolean
  onClick?: (...args: any[]) => void
  style?: CSSProperties
}

const ListItem = ({ children, ...rest }: Props) => (
  <ListTR {...rest}>{children}</ListTR>
)

export default ListItem
