import { SalesStatesQuery } from '~generated-types'

type SalesStateNames = SalesStatesQuery['registry']['salesStates'][0]['names']
type SalesStateLabels = { [locale: string]: string }

export const getStateLabels = (names: SalesStateNames): SalesStateLabels =>
  names.reduce(
    (a, { content, locale }) => ({
      ...a,
      [locale]: content,
    }),
    {}
  )
