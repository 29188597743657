import { T } from '@/modules/Language'
import { ListingTable } from '@/modules/Listing/common'
import { PrintSize } from '@/modules/Listing/types'

import {
  ListingMealType,
  ListingRestaurant,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { compareMealsFn } from '../../utils'
import { MealTypeMeals } from './MealTypeMeals'

type Props = {
  data: {
    [mealKey: string]: ListingScheduledMeal[]
  }
  mealTypes: ListingMealType[]
  restaurant: ListingRestaurant
  printSize: PrintSize
}

export const RestaurantMeals = ({ data, mealTypes, printSize }: Props) => {
  const dataCount = Object.keys(data).reduce(
    (acc, mKey) => acc + (data[mKey] || []).length,
    0
  )

  return dataCount ? (
    <ListingTable printSize={printSize}>
      <thead>
        <tr>
          <th style={{ width: '5%' }} />
          <th style={{ width: '15%' }}>
            <T>Listings:PacketMealListing.field.time</T>
          </th>
          <th style={{ width: '13%' }}>
            <T>Listings:PacketMealListing.field.people</T>
          </th>
          <th style={{ width: '7%' }}>
            <T>Listings:PacketMealListing.field.age</T>
          </th>
          <th style={{ width: '37%' }}>
            <T>Listings:PacketMealListing.field.sales</T>
          </th>
          <th style={{ width: '23%' }}>
            <T>Listings:PacketMealListing.field.note</T>
          </th>
        </tr>
      </thead>
      <tbody>
        {compareMealsFn(data, mealTypes).map((m) => (
          <MealTypeMeals
            data={data[m.id] || []}
            key={`meal-type-meal-${m.id}`}
            mealType={m}
          />
        ))}
      </tbody>
    </ListingTable>
  ) : null
}
