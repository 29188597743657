import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { ListItem, ListItemSection } from '@/components/List'
import { useTheme } from '@/theme'

type Props = {
  columnCount: number
  compact?: boolean
  content: ReactNode
  helper?: ReactNode
  icon?: IconProp
}

const ListPlaceholder = ({
  columnCount,
  compact,
  content,
  helper,
  icon,
}: Props) => {
  const theme = useTheme()

  return (
    <ListItem>
      <Section colSpan={columnCount} compact={compact}>
        <PlaceholderPosition>
          {icon && (
            <FontAwesomeIcon
              color={theme.palette.smoke.dark}
              icon={icon}
              size="3x"
              style={{ marginBottom: `${theme.spacing.gu(2)}rem` }}
            />
          )}
          <Content>{content}</Content>
          {helper && <Helper>{helper}</Helper>}
        </PlaceholderPosition>
      </Section>
    </ListItem>
  )
}

export default ListPlaceholder

////////////

const PlaceholderPosition = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(8)}rem;
  `}
`

const Section = styled(ListItemSection)<{ compact?: boolean }>`
  &&& {
    ${({ compact, theme }) =>
      compact &&
      `
          border-top: 1px solid ${theme.palette.smoke.dark};
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          :hover {
            border-color: ${theme.palette.primary.light};
          }
          :after {
            opacity: 0;
          }
      `};
  }
`

const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const Helper = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase};
  `}
`
