import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactResizeDetector from 'react-resize-detector'
import styled, { css } from 'styled-components/macro'

import { InlineFieldButton } from '@/components/ExtraButtons'
import { useTheme } from '@/theme'

import { DataTableButton } from './DataTableButton'
import type { Props as CellProps } from './DataTableCell'
import { DataTableCell } from './DataTableCell'
import { DataTableInput } from './DataTableInput'
import { DataTableTextarea } from './DataTableTextarea'

type Props = CellProps & {
  disabled?: boolean
  isTextarea?: boolean
  placeholder?: ReactNode
  updateValue: (newValue: string) => Promise<void>
  value: string | null | undefined
}

export const DataTableEditableTextCell = ({
  disabled,
  isTextarea,
  placeholder,
  updateValue,
  value,
  ...cellProps
}: Props) => {
  const theme = useTheme()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [editedValue, setEditedValue] = useState<string>(value || '')

  useEffect(() => {
    setEditedValue(value || '')
  }, [value])

  const handleInitEdit = () => {
    setIsEditing(true)
  }

  const handleUpdateValue = (event: SyntheticEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    if (!isProcessing) {
      setIsProcessing(true)

      updateValue(editedValue)
        .then(() => setIsEditing(false))
        .finally(() => setIsProcessing(false))
    }
  }

  const resetForm = () => {
    setIsEditing(false)
    setEditedValue(value || '')
  }

  return (
    // @ts-ignore
    <DataTableCell {...cellProps}>
      <ReactResizeDetector handleWidth>
        {({ width: cellWidth }: any) => (
          <Wrapper>
            {isEditing ? (
              <>
                {isTextarea ? (
                  <DataTableTextarea
                    background={theme.palette.smoke.light}
                    disabled={isProcessing}
                    isProcessing={isProcessing}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setEditedValue(event.target.value)
                    }
                    onClick={(event: SyntheticEvent) => event.stopPropagation()}
                    onSubmit={handleUpdateValue}
                    style={{ marginRight: theme.spacing.gutterSmall }}
                    value={editedValue}
                  />
                ) : (
                  <DataTableInput
                    background={theme.palette.smoke.light}
                    disabled={isProcessing}
                    isProcessing={isProcessing}
                    focusOnMount
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setEditedValue(event.target.value)
                    }
                    onClick={(event: SyntheticEvent) => event.stopPropagation()}
                    onSubmit={handleUpdateValue}
                    style={{ marginRight: theme.spacing.gutterSmall }}
                    value={editedValue}
                  />
                )}
                <DataTableButton
                  key="save-title-edit"
                  disabled={isProcessing}
                  icon="check"
                  onClick={handleUpdateValue}
                />
                <DataTableButton
                  color="danger"
                  disabled={isProcessing}
                  icon="xmark"
                  key="cancel-title-edit"
                  onClick={resetForm}
                  variant="outlined"
                />
              </>
            ) : (
              <>
                {disabled ? (
                  <>
                    {value ? (
                      <Value>{value}</Value>
                    ) : (
                      <ValuePlaceholder>{placeholder}</ValuePlaceholder>
                    )}
                  </>
                ) : (
                  <InlineFieldButton
                    onClick={handleInitEdit}
                    style={{
                      maxWidth: cellWidth,
                      paddingBottom: 2,
                      paddingTop: 2,
                      whiteSpace: 'normal',
                    }}
                  >
                    {value ? (
                      <Value>{value}</Value>
                    ) : (
                      <ValuePlaceholder>{placeholder}</ValuePlaceholder>
                    )}
                    <span style={{ flex: 1 }} />
                    <FontAwesomeIcon icon="pen" />
                  </InlineFieldButton>
                )}
              </>
            )}
          </Wrapper>
        )}
      </ReactResizeDetector>
    </DataTableCell>
  )
}

////////////

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Value = styled.span`
  text-align: left;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const ValuePlaceholder = styled(Value)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
