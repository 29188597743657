import { gql } from '@apollo/client'

export default gql`
  fragment WebshopSalesToken on WebshopSalesToken {
    id
    issues {
      targetPath {
        id
        type
      }
      message
      level
      key
      code
    }
    link
    scopes
    token
    tokenDescription
    validFrom
    validTo
    completedAt
    invoice {
      id
      document {
        id
      }
    }
  }
`
