import { gql } from '@apollo/client'

import document from './document'
import invoiceInfo from './invoiceInfo'
import orderPacket from './orderPacket'
import payment from './payment'

export default gql`
  ${document}
  ${invoiceInfo}
  ${orderPacket}
  ${payment}

  fragment Invoice on Invoice {
    ...InvoiceInfo

    document {
      ...Document
    }
    items {
      ...OrderPacket
    }
    payments(states: [FAILED, IN_PROGRESS, PAID, PAID_PENDING]) {
      ...Payment
    }
  }
`
