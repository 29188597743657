import { CSSProperties, ReactNode, Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { useTheme } from '@/theme'

type Props = {
  children: ReactNode
  customIcon?: ReactNode
  disabled?: boolean
  innerRef?: Ref<HTMLButtonElement>
  noIcon?: boolean
  onClick: () => void
  style?: CSSProperties
}

export const EditButton = ({
  children,
  customIcon,
  innerRef,
  noIcon,
  ...props
}: Props) => {
  const theme = useTheme()

  const [isHover, setHover] = useState<boolean>(false)

  return (
    <Button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={innerRef}
      {...props}
    >
      {children}

      {!noIcon && (
        <IconWrapper className="edit-button-icon">
          {isHover &&
            (customIcon ?? (
              <FontAwesomeIcon
                color={theme.palette.text.lighter}
                icon="pen"
                size="sm"
              />
            ))}
        </IconWrapper>
      )}
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;

  ${({ theme }) => `
    padding: ${theme.spacing.gu(1)}rem;
    margin: 0 -${theme.spacing.gu(1)}rem;
  `}

  &:hover:not([disabled]) {
    ${({ theme }) => `
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => `
      background: ${theme.palette.smoke.main};
    `}
  }

  &:disabled {
    cursor: not-allowed;
    color: unset;
  }
`

const IconWrapper = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gutter};
  `}
`
