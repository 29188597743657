import React from 'react'

import { useRouteDateInterval } from '@/utils/hooks'

import PacketMealListing from './PacketMealListing'
import usePacketMealsListing from './usePacketMealsListing'

const PacketMealListingContainer = () => {
  const { from, range, setInterval, to } = useRouteDateInterval()
  const { error, loading, meals } = usePacketMealsListing({ from, to })

  return (
    <PacketMealListing
      error={error}
      interval={{ from, to }}
      loading={loading}
      meals={meals}
      range={range}
      setInterval={({ from, to }) => setInterval(from, to)}
    />
  )
}

export default PacketMealListingContainer
