export default function getYearRange(from: number, to: number): number[] {
  const years = []
  let iterator = from

  while (iterator <= to) {
    years.push(iterator)
    iterator += 1
  }

  return years
}
