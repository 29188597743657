import { gql, useMutation } from '@apollo/client'

import type {
  AddCatalogProductItemFromCatalogMutation,
  AddCatalogProductItemFromCatalogMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProduct}

  mutation AddCatalogProductItemFromCatalog(
    $input: CatalogProductAddItemsFromCatalogInput!
  ) {
    catalogProductAddItemsFromCatalog(input: $input) {
      ...CatalogProduct
    }
  }
`

export const useAddCatalogProductItemFromCatalogMutation = () =>
  useMutation<
    AddCatalogProductItemFromCatalogMutation,
    AddCatalogProductItemFromCatalogMutationVariables
  >(MUTATION)
