import moment from 'moment'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { Chip, ChipColor } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ListItem as Wrapper,
  ListItemSection as Section,
  ListItemSectionIconValue as IconValue,
} from '@/components/List'
import { T, useLanguageContext } from '@/modules/Language'
import { LastUsedSalesPayload } from '@/modules/Sales/queries'
import { getStateColor, getStateLabels } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'

const DATE_FORMAT = 'dd DD.MM.YY'

type Props = {
  sale: LastUsedSalesPayload['salesAll'][0]
  onClose: () => void
}

export const LastUsedSalesModalRow = ({
  sale: {
    customer: customerData,
    id,
    estimatedDates,
    facet,
    lifecycle: { state },
    name,
    orderNumber,
    reservationDates,
    type,
  },
  onClose,
}: Props) => {
  const { language } = useLanguageContext()

  const history = useHistory()
  const { spacing } = useTheme()

  const handleClick = (saleId: string) => {
    history.push(`/sales/details/${saleId}`)
    onClose()
  }

  const customer = customerData?.customer
  const customerName = !customer
    ? ''
    : customer.__typename === 'CustomerOrganization'
      ? customer.organization.name
      : customer.__typename === 'CustomerPerson'
        ? `${customer.person.firstName} ${customer.person.lastName}`
        : ''
  const customerNumber = customer?.customerNumber || null

  const dates = estimatedDates || reservationDates || null

  return (
    <Wrapper onClick={() => handleClick(id)}>
      <Section
        style={{ padding: 0, paddingLeft: `${spacing.gu(2)}rem` }}
        align="center"
      >
        <Chip
          variant="outlined"
          size="sm"
          color={getStateColor(state.systemState) as ChipColor}
        >
          {getStateLabels(state.names)[language]}
        </Chip>
      </Section>

      <Section align="center">
        <FlexRow alignItems="center" justifyContent="space-between">
          <FlexColumn
            alignItems="flex-start"
            noPadding
            style={{ marginRight: spacing.gutter }}
          >
            {name ? (
              <SalesName>{name}</SalesName>
            ) : (
              <SalesNamePlaceholder>
                <T l10n={`SalesSearchList:ListItem.unnamed.${type}`} />
              </SalesNamePlaceholder>
            )}
            <small>#{orderNumber}</small>
          </FlexColumn>

          <FacetChip
            size="sm"
            variant="outlined"
            customColor={{ fill: facet.color, stroke: facet.color }}
          >
            {facet.abbreviation}
          </FacetChip>
        </FlexRow>
      </Section>

      <Section>
        {customerName && customerNumber && (
          <FlexColumn noPadding>
            <span>{customerName}</span>
            <small>#{customerNumber}</small>
          </FlexColumn>
        )}
      </Section>

      <Section>
        {dates && (
          <>
            <IconValue
              icon="right-to-bracket"
              value={moment(dates.start).format(DATE_FORMAT)}
            />
            <IconValue
              icon="right-from-bracket"
              value={moment(dates.end).format(DATE_FORMAT)}
            />
          </>
        )}
      </Section>
    </Wrapper>
  )
}

///////

const FacetChip = styled(Chip)`
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const SalesName = styled.a`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const SalesNamePlaceholder = styled.a`
  &&& {
    font-style: italic;
    font-weight: 400;

    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }
`
