import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

export const Header = styled(FlexColumn).attrs(() => ({
  alignItems: 'flex-start',
  noPadding: true,
}))`
  flex: 1;
`
