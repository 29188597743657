import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { FlexRow } from '@/components/Layout'
import { H3 } from '@/components/Typography'
import { T } from '@/modules/Language'

import { Customer } from '../../../types'
import { parseExtraDetails } from '../../../utils'
import { CustomerDisplayName } from '../../CustomerDisplayName'
import { CustomerLabel } from '../../CustomerLabel'

type Props = {
  data: Customer
  onSelect?: () => Promise<void>
}

export const Header = ({ data, onSelect }: Props) => {
  const [processing, setProcessing] = useState<boolean>(false)

  const details = parseExtraDetails(data)

  return (
    <Wrapper>
      <FlexRow alignItems="center">
        <NameLabel>
          <CustomerDisplayName customer={data} hideDetails hidePublicName />
        </NameLabel>

        <span style={{ flex: 1 }} />

        {onSelect && (
          <Button
            color="primary"
            disabled={processing}
            onClick={() => {
              setProcessing(true)
              onSelect()
                .catch(() => undefined)
                .finally(() => setProcessing(false))
            }}
            size="small"
          >
            <T>Customers:EditCustomer.select</T>
          </Button>
        )}
      </FlexRow>
      <DetailsWrapper alignItems="center">
        {details && <Details>{details}</Details>}
        {data.labels.map((label) => (
          <CustomerLabel key={`customer-label-${label.id}`} {...label} />
        ))}
      </DetailsWrapper>
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.palette.smoke.main};

    margin-top: ${theme.spacing.gu(1)}rem;
    margin-bottom: ${theme.spacing.gutter};
    padding-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const NameLabel = styled(H3)`
  margin-bottom: 0;
`

const DetailsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Details = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gutter};
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
