import { gql } from '@apollo/client'

import {
  AccommodationGroupFragment,
  CapacityStatusFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

export const AccommodationQueries = Object.freeze({
  GROUP_STATUS_DATA: gql`
    ${CapacityStatusFragment}

    query GroupStatusData($id: ID!) {
      accommodationGroup(id: $id) {
        id
        targets {
          id
          status {
            ...CapacityStatus
          }
        }
      }
    }
  `,
  SALE_RESERVATIONS: gql`
    ${AccommodationGroupFragment}

    query SalesAccommodationAvailability($id: ID!) {
      sales(id: $id) {
        accommodation {
          accommodationGroups {
            ...AccommodationGroup
          }
          id
        }
        id
        type
      }
    }
  `,
})
