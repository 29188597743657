import moment, { Moment } from 'moment'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { CheckboxInput } from '@/components/FormControls'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { DatePicker } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { ListingLayout, PlaceholderLabel } from '@/modules/Listing/common'
import { useTheme } from '@/theme'

import { PrintSize } from '../types'
import RoomReservationsTable from './components/RoomReservationsTable'
import { RoomReservation,RoomReservationFilter as Filter } from './types'

interface Props {
  date: Moment
  error: boolean
  filters: Filter[]
  loading: boolean
  roomReservations: RoomReservation[]
  setDate: (targetDate: Moment) => void
  setFilters: (targetFilters: Filter[]) => void
}

export const RoomReservationsListing = ({
  date,
  error,
  filters,
  loading,
  roomReservations,
  setDate,
  setFilters,
}: Props) => {
  const theme = useTheme()

  const renderContent = (printSize: PrintSize) => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <PlaceholderLabel>
          <T>Listings:RoomReservationsListing.error</T>
        </PlaceholderLabel>
      )
    }

    const tableData = getTableData(roomReservations, filters)

    if (!tableData.length) {
      return (
        <PlaceholderLabel>
          <T>Listings:RoomReservationsListing.empty</T>
        </PlaceholderLabel>
      )
    }

    return <RoomReservationsTable data={tableData} printSize={printSize} />
  }

  const onUpdateFilter = (currentFilter: Filter) =>
    setFilters(
      filters.includes(currentFilter)
        ? filters.filter((f) => f !== currentFilter)
        : [...filters, currentFilter]
    )

  const renderSidebar = () => (
    <>
      <FilterSection
        label={<T>Listings:RoomReservationsListing.filter.date</T>}
        render={() => (
          <div style={{ margin: `-${theme.spacing.gu(1)}rem` }}>
            <DatePicker
              setValue={(targetDate) => setDate(targetDate || moment())}
              value={date}
            />
          </div>
        )}
      />
      <FilterSection
        label={<T>Listings:RoomReservationsListing.filter.filters</T>}
        render={() => (
          <>
            <CheckboxInput
              checked={filters.includes(Filter.HIDE_LODGING)}
              onChange={() => onUpdateFilter(Filter.HIDE_LODGING)}
            >
              <T>Listings:RoomReservationsListing.filter.type.HIDE_LODGING</T>
            </CheckboxInput>
            <CheckboxInput
              checked={filters.includes(Filter.HIDE_FREE)}
              onChange={() => onUpdateFilter(Filter.HIDE_FREE)}
            >
              <T>Listings:RoomReservationsListing.filter.type.HIDE_FREE</T>
            </CheckboxInput>
          </>
        )}
      />
    </>
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderSidebar={renderSidebar}
      title={
        <>
          <T>Listings:RoomReservationsListing.title</T>
          {' – '}
          {date.format('dd D.M.YYYY')}
        </>
      }
    />
  )
}

export default RoomReservationsListing

////////

const getTableData = (reservations: RoomReservation[], filters: Filter[]) => {
  const hideLodgingFilter = filters.includes(Filter.HIDE_LODGING)
  const hideFreeFilter = filters.includes(Filter.HIDE_FREE)

  if (!hideLodgingFilter && !hideFreeFilter) {
    return reservations
  }

  return reservations.filter((r) => {
    const isHideLodging = hideLodgingFilter && r.presentReservation
    const isHideFree =
      hideFreeFilter &&
      !r.presentReservation &&
      !r.arrivingReservation &&
      !r.departingReservation

    return !isHideLodging && !isHideFree
  })
}
