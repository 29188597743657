import styled, { css } from 'styled-components/macro'

const P = styled.p`
  ${({ theme }) => css`
    margin: 0 0 ${theme.spacing.gutter};
  `}

  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
`

export default P
