import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  children?: ReactNode
  isToday: boolean
}

// Using memo here is very important as there are very many instances of Cells
// being rendered.
// @ts-ignore
export const Cell = React.memo<Props>(styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
  `}

  ${({ isToday, theme }) =>
    isToday ? `background: ${theme.palette.primary.extraLighter};` : ''}
`)
