import styled from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import DateRangeSelector from './components/DateRangeSelector'

type Props = {
  dates: {
    end: string
    start: string
  } | null
  readOnly?: boolean
  setVisitDates: (
    dateRange: {
      end: string
      start: string
    } | null
  ) => Promise<any>
}

const ParticipantDayVisitor = ({ dates, readOnly, setVisitDates }: Props) => {
  const { spacing, typography } = useTheme()

  return (
    <FlexRow flex={1} style={{ minHeight: '35px' }}>
      <FlexColumn style={{ marginLeft: `${spacing.gu(1)}rem` }} flex={1}>
        <ContentWrapper>
          <DateRangeSelector
            dates={dates}
            readOnly={readOnly}
            handleSetDates={setVisitDates}
          />
          <Label>
            <FontWeight
              style={{
                fontSize: typography.fontSizeBase,
                marginLeft: spacing.gutter,
              }}
            >
              <T>ParticipantsList:VisitStatus.dayVisitor</T>
            </FontWeight>
          </Label>
        </ContentWrapper>
      </FlexColumn>
    </FlexRow>
  )
}

export default ParticipantDayVisitor

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  margin-left: 15.5rem;
  min-height: 35px;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
