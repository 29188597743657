import { gql, useMutation } from '@apollo/client'

import {
  AddPublicRoomMutation,
  AddPublicRoomMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.publicRoom}

  mutation AddPublicRoom($input: PublicRoomAddInput!) {
    publicRoomAdd(input: $input) {
      publicRoom {
        ...PublicRoom
      }
    }
  }
`

export const useAddPublicRoomMutation = () =>
  useMutation<AddPublicRoomMutation, AddPublicRoomMutationVariables>(MUTATION)
