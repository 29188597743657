import { CSSProperties, KeyboardEvent, ReactNode, Ref } from 'react'

import BusyIndicator from '../elements/BusyIndicator'
import Button from '../elements/Button'
import LiveUpdateIndicator from '../elements/LiveUpdateIndicator'
import TD from '../elements/TD'

type Props = {
  align?: string
  busy?: boolean
  children: ReactNode
  colSpan?: number
  disabled?: boolean
  innerRef?: Ref<HTMLButtonElement>
  onClick: (...args: Array<any>) => any
  onPressKey?: (key: string) => void
  rowSpan?: number
  style?: CSSProperties
  valueStyle?: CSSProperties
  updated?: boolean
  editorColor?: string
}

const ButtonCell = ({
  align,
  busy,
  children,
  colSpan,
  disabled,
  innerRef,
  onClick,
  onPressKey,
  rowSpan,
  style,
  valueStyle,
  updated,
  editorColor,
}: Props) => (
  <TD colSpan={colSpan} noPadding noWrap rowSpan={rowSpan}>
    <Button
      align={align}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={(e: KeyboardEvent) => onPressKey && onPressKey(e.key)}
      ref={innerRef}
      style={style}
    >
      <span style={valueStyle}>{children}</span>
      {busy && <BusyIndicator />}
    </Button>
    <LiveUpdateIndicator updated={updated} editorColor={editorColor} />
  </TD>
)

export default ButtonCell
