import { gql } from '@apollo/client'

import CalendarResourceReservationFragment from '../fragments/calendarResourceReservation'

export const RESOURCE_RESERVATIONS = gql`
  ${CalendarResourceReservationFragment}

  query ResourceReservations($input: ResourceReservationsInput!) {
    resourceReservations(input: $input) {
      reservations {
        reservations {
          ...CalendarResourceReservation
        }
      }
    }
  }
`

export default RESOURCE_RESERVATIONS
