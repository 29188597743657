import styled, { css } from 'styled-components/macro'

import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

type Props = {
  isMulti?: boolean
  selectedItemsNumber?: number
}

export const ListHeader = ({ isMulti, selectedItemsNumber }: Props) => (
  <Wrapper>
    <FirstSection align="center" width="49px" />

    <Section>
      <T>Products:CatalogProductList.field.name</T>
      {isMulti && !!selectedItemsNumber && (
        <SelectedNumber>
          {`  –  ${selectedItemsNumber} selected`}
        </SelectedNumber>
      )}
    </Section>

    <Section align="right">
      <T>Products:CatalogProductList.field.price</T>
    </Section>

    <Section align="right">
      <T>Products:CatalogProductList.field.vat</T>
    </Section>

    <Section>
      <T>Products:CatalogProductList.field.catalog</T>
    </Section>

    <Section>
      <T>Products:CatalogProductList.field.description</T>
    </Section>

    <LastSection width="53px" />
  </Wrapper>
)

////////////

const FirstSection = styled(Section)`
  ${({ theme }) => css`
    &&&& {
      padding-left: ${theme.spacing.gu(3)}rem;
      padding-right: ${theme.spacing.gu(1)}rem;
    }
  `}
`

const LastSection = styled(Section)`
  ${({ theme }) => css`
    &&&& {
      padding-right: ${theme.spacing.gu(2)}rem;
    }
  `}
`

const SelectedNumber = styled.span`
  white-space: pre;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`
