export const translations = {
  en: {
    // Inline
    'components.controls.inline.bold': 'Bold',
    'components.controls.inline.italic': 'Italic',

    // Link
    'components.controls.link.link': 'Link',
    'components.controls.link.linkTarget': 'Link target',
    'components.controls.link.linkTitle': 'Link title',
    'components.controls.link.unlink': 'Unlink',

    // Generic
    'generic.add': 'Add',
    'generic.cancel': 'Cancel',
  },
  fi: {
    // Inline
    'components.controls.inline.bold': 'Lihavoitu',
    'components.controls.inline.italic': 'Kursivoitu',

    // Link
    'components.controls.link.link': 'Linkki',
    'components.controls.link.linkTarget': 'Linkin osoite',
    'components.controls.link.linkTitle': 'Linkin teksti',
    'components.controls.link.unlink': 'Poista linkki',

    // Generic
    'generic.add': 'Lisää',
    'generic.cancel': 'Peruuta',
  },
}
