import { gql, useMutation } from '@apollo/client'

import type {
  AddCatalogProductItemFromProductTitleMutation,
  AddCatalogProductItemFromProductTitleMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProductItem}

  mutation AddCatalogProductItemFromProductTitle(
    $input: CatalogProductItemAddInput!
  ) {
    catalogProductItemAdd(input: $input) {
      ...CatalogProductItem
    }
  }
`

export const useAddCatalogProductItemFromProductTitleMutation = () =>
  useMutation<
    AddCatalogProductItemFromProductTitleMutation,
    AddCatalogProductItemFromProductTitleMutationVariables
  >(MUTATION)
