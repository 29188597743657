import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { FlexColumn } from '@/components/Layout'
import { notify } from '@/components/NotificationService'
import { T } from '@/modules/Language'

import {
  CatalogProductUpdateInput,
  ConditionRuleType,
  ProductMealInput,
  ProductRuleUpdateInput,
  ProductUpdateItemInput,
} from '~generated-types'

import { useCatalogProductById } from '../../hooks'
import {
  useAddCatalogProductItemFromCatalogMutation,
  useAddCatalogProductItemFromProductTitleMutation,
  useAddCatalogProductItemRuleMutation,
  useCopyCatalogProductToCatalogMutation,
  useDeleteCatalogProductItemMutation,
  useDeleteCatalogProductItemRuleMutation,
  useDeleteCatalogProductMutation,
  useUpdateCatalogProductItemMealMutation,
  useUpdateCatalogProductItemMutation,
  useUpdateCatalogProductItemRuleMutation,
  useUpdateCatalogProductMutation,
} from '../../mutations'
import { productQueries } from '../../queries'
import {
  CatalogProduct,
  CatalogProductByIdPayload,
  CatalogProductItem,
  ProductMeal,
  ProductRule,
} from '../../types'

type ContextType = {
  // Data
  error?: ApolloError
  loading: boolean
  product: CatalogProduct | null
  sellerId?: string
  // Methods
  addProductItemFromCatalog: (
    productId: string,
    catalogProductId: string
  ) => Promise<CatalogProduct | undefined>
  addProductItemFromProductTitle: (
    productId: string,
    productTitleId: string
  ) => Promise<CatalogProductItem | undefined>
  addProductItemRule: (itemId: string) => Promise<ProductRule | undefined>
  copyProduct: (
    productId: string,
    catalogId: string
  ) => Promise<CatalogProduct | undefined>
  deleteProduct: (productId: string) => Promise<string | undefined>
  deleteProductItem: (itemId: string) => Promise<string | undefined>
  deleteProductItemRule: (ruleId: string) => Promise<string | undefined>
  deleteProductItemRuleWithConfirm: (
    ruleId: string
  ) => Promise<string | undefined>
  deleteProductItemWithConfirm: (itemId: string) => Promise<string | undefined>
  deleteProductWithConfirm: (productId: string) => Promise<string | undefined>
  updateProduct: (
    input: CatalogProductUpdateInput
  ) => Promise<CatalogProduct | undefined>
  updateProductItem: (
    input: ProductUpdateItemInput
  ) => Promise<CatalogProductItem | undefined>
  updateProductItemMeal: (
    input: ProductMealInput
  ) => Promise<ProductMeal | undefined>
  updateProductItemRule: (
    input: ProductRuleUpdateInput
  ) => Promise<ProductRule | undefined>
}

const CatalogProductManagerContext = createContext<ContextType>({
  addProductItemFromCatalog: () => Promise.reject(),
  addProductItemFromProductTitle: () => Promise.reject(),
  addProductItemRule: () => Promise.reject(),
  copyProduct: () => Promise.reject(),
  deleteProduct: () => Promise.reject(),
  deleteProductItem: () => Promise.reject(),
  deleteProductItemRule: () => Promise.reject(),
  deleteProductItemRuleWithConfirm: () => Promise.reject(),
  deleteProductItemWithConfirm: () => Promise.reject(),
  deleteProductWithConfirm: () => Promise.reject(),
  error: undefined,
  loading: false,
  product: null,
  sellerId: undefined,
  updateProduct: () => Promise.reject(),
  updateProductItem: () => Promise.reject(),
  updateProductItemMeal: () => Promise.reject(),
  updateProductItemRule: () => Promise.reject(),
})

type Props = {
  children: ReactNode
  productId: string
  sellerId?: string
}

export const CatalogProductManagerContextProvider = ({
  children,
  productId,
  sellerId,
}: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { confirm } = useDialogService()

  const {
    error,
    loading: productsLoading,
    product,
  } = useCatalogProductById({ id: productId })

  const [loading, setLoading] = useState<boolean>(false)

  // Product mutations
  const [copyProductMutation] = useCopyCatalogProductToCatalogMutation()
  const [deleteProductMutation] = useDeleteCatalogProductMutation()
  const [updateProductMutation] = useUpdateCatalogProductMutation()

  // Product item mutations
  const [addProductItemMutationFromProductTitle] =
    useAddCatalogProductItemFromProductTitleMutation()
  const [addProductItemFromCatalogMutation] =
    useAddCatalogProductItemFromCatalogMutation()
  const [deleteProductItemMutation] = useDeleteCatalogProductItemMutation()
  const [updateProductItemMutation] = useUpdateCatalogProductItemMutation()

  // Product meal mutations
  const [updateProductItemMealMutation] =
    useUpdateCatalogProductItemMealMutation()

  // Product rule mutations
  const [addProductItemRuleMutation] = useAddCatalogProductItemRuleMutation()
  const [deleteProductItemRuleMutation] =
    useDeleteCatalogProductItemRuleMutation()
  const [updateProductItemRuleMutation] =
    useUpdateCatalogProductItemRuleMutation()

  useEffect(() => {
    setLoading(productsLoading)
  }, [productsLoading])

  const addProductItemFromProductTitle = (
    productId: string,
    productTitleId: string
  ) =>
    addProductItemMutationFromProductTitle({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedData = cache.readQuery<CatalogProductByIdPayload>({
          query: productQueries.GET_CATALOG_PRODUCT_BY_ID,
          variables: { id: productId },
        })

        if (cachedData && data) {
          cache.writeQuery<CatalogProductByIdPayload>({
            data: {
              ...cachedData,
              catalogProduct: {
                ...cachedData.catalogProduct,
                items: [
                  ...cachedData.catalogProduct.items,
                  data.catalogProductItemAdd,
                ],
              },
            },
            query: productQueries.GET_CATALOG_PRODUCT_BY_ID,
          })
        }
      },
      variables: { input: { productId, productTitleId } },
    })
      .then(({ data }) => data?.catalogProductItemAdd)
      .catch(() => undefined)

  const addProductItemFromCatalog = (
    productId: string,
    catalogProductId: string
  ) =>
    addProductItemFromCatalogMutation({
      variables: { input: { catalogProductId, id: productId } },
    })
      .then(({ data }) => data?.catalogProductAddItemsFromCatalog)
      .catch(() => undefined)

  const addProductItemRule = (itemId: string) =>
    addProductItemRuleMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedData = cache.readQuery<CatalogProductByIdPayload>({
          query: productQueries.GET_CATALOG_PRODUCT_BY_ID,
          variables: { id: productId },
        })

        if (cachedData && data) {
          cache.writeQuery<CatalogProductByIdPayload>({
            data: {
              ...cachedData,
              catalogProduct: {
                ...cachedData.catalogProduct,
                items: cachedData.catalogProduct.items.map((i) =>
                  i.id === itemId
                    ? {
                        ...i,
                        rules: [...i.rules, data.catalogProductItemAddRule],
                      }
                    : i
                ),
              },
            },
            query: productQueries.GET_CATALOG_PRODUCT_BY_ID,
          })
        }
      },
      variables: {
        input: { id: itemId, ruleType: ConditionRuleType.Date },
      },
    })
      .then(({ data }) => data?.catalogProductItemAddRule)
      .catch(() => undefined)

  const copyProduct = (productId: string, catalogId: string) =>
    copyProductMutation({
      variables: { input: { copyTo: { catalogId }, id: productId } },
    })
      .then(({ data }) => {
        if (data) {
          notify({
            content: (
              <FlexColumn>
                <span style={{ fontWeight: 500, marginBottom: 2 }}>
                  {data.catalogProductCopy.name}
                </span>
                <T>Products:Notification.copyProduct</T>
              </FlexColumn>
            ),
            type: 'SUCCESS',
          })
        }

        return data?.catalogProductCopy
      })
      .catch(() => undefined)

  const deleteProduct = (productId: string) =>
    // Manually update GraphQL cache after deleting
    deleteProductMutation({
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'CatalogProduct',
            id: data.catalogProductDelete.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: productId },
    })
      .then(({ data }) => data?.catalogProductDelete.id)
      .catch(() => undefined)

  const deleteProductWithConfirm = (productId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: (
        <T>Products:Confirmation.deleteCatalogProduct.description</T>
      ),
      title: <T>Products:Confirmation.deleteCatalogProduct.title</T>,
    })
      .then(() => deleteProduct(productId))
      .catch(() => undefined)

  const deleteProductItem = (itemId: string) =>
    deleteProductItemMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'CatalogProductItem',
            id: data.catalogProductItemDelete.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: itemId },
    })
      .then(({ data }) => data?.catalogProductItemDelete.id)
      .catch(() => undefined)

  const deleteProductItemWithConfirm = (itemId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deleteProductItem.description</T>,
      title: <T>Products:Confirmation.deleteProductItem.title</T>,
    })
      .then(() => deleteProductItem(itemId))
      .catch(() => undefined)

  const deleteProductItemRule = (ruleId: string) =>
    deleteProductItemRuleMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'ProductRule',
            id: data.catalogProductItemDeleteRule.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { productRuleId: ruleId },
    })
      .then(({ data }) => data?.catalogProductItemDeleteRule.id)
      .catch(() => undefined)

  const deleteProductItemRuleWithConfirm = (ruleId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deleteProductRule.description</T>,
      title: <T>Products:Confirmation.deleteProductRule.title</T>,
    })
      .then(() => deleteProductItemRule(ruleId))
      .catch(() => undefined)

  const updateProduct = (input: CatalogProductUpdateInput) =>
    updateProductMutation({ variables: { input } })
      .then(({ data }) => data?.catalogProductUpdate)
      .catch(() => undefined)

  const updateProductItem = (input: ProductUpdateItemInput) =>
    updateProductItemMutation({ variables: { input } })
      .then(({ data }) => data?.catalogProductItemUpdate)
      .catch(() => undefined)

  const updateProductItemMeal = (input: ProductMealInput) =>
    updateProductItemMealMutation({ variables: { input } })
      .then(({ data }) => data?.catalogProductItemUpdateMeal)
      .catch(() => undefined)

  const updateProductItemRule = (input: ProductRuleUpdateInput) =>
    updateProductItemRuleMutation({ variables: { input } })
      .then(({ data }) => data?.catalogProductItemUpdateRule)
      .catch(() => undefined)

  contextValueRef.current = {
    addProductItemFromCatalog,
    addProductItemFromProductTitle,
    addProductItemRule,
    copyProduct,
    deleteProduct,
    deleteProductItem,
    deleteProductItemRule,
    deleteProductItemRuleWithConfirm,
    deleteProductItemWithConfirm,
    deleteProductWithConfirm,
    error,
    loading,
    product,
    sellerId,
    updateProduct,
    updateProductItem,
    updateProductItemMeal,
    updateProductItemRule,
  }

  return (
    <CatalogProductManagerContext.Provider value={contextValueRef.current}>
      {children}
    </CatalogProductManagerContext.Provider>
  )
}

export const useCatalogProductManagerContext = () =>
  useContext(CatalogProductManagerContext)
