export const filterOption = (
  option: { [key: string]: any },
  input: string | null | undefined
): boolean => {
  if (input) {
    const searchValue = option.data?.searchValue
      ? option.data?.searchValue.toLowerCase()
      : null
    const inputValue = input ? input?.toLowerCase() : ''

    if (!searchValue) {
      return true
    }

    return searchValue.includes(inputValue)
  }

  return true
}
