import { Fragment } from 'react'

import { FlexColumn } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'

import { PaymentType } from '~generated-types'

import type {
  NotesByType,
  PaymentAmountsByType,
  SupportedPaymentType,
} from '../usePaymentsForInterval'

export interface PaymentCellsProps {
  amounts: PaymentAmountsByType
  nonEmptyPaymentTypes: PaymentType[]
  nonEmptyVoucherProviders: string[]
  notes: NotesByType | null
}

export const PaymentCells = ({
  amounts,
  nonEmptyPaymentTypes,
  nonEmptyVoucherProviders,
  notes,
}: PaymentCellsProps) => {
  const renderColumnData = (type: SupportedPaymentType) => (
    <FlexColumn noPadding>
      <span>{formatCurrency(amounts[type].toNumber())}</span>
      {notes && !!notes[type] ? <small>{notes[type]}</small> : null}
    </FlexColumn>
  )

  return (
    <Fragment>
      {nonEmptyPaymentTypes.map((type, idx) => (
        <td
          className={idx === 0 ? 'show-left-border' : undefined}
          key={`payment-type-${type}`}
          style={{ textAlign: 'right' }}
        >
          {renderColumnData(type as SupportedPaymentType)}
        </td>
      ))}

      {nonEmptyVoucherProviders.map((provider) => {
        const voucherAmount = amounts.vouchersByProvider[provider]?.toNumber()
        const voucherNotes = notes?.vouchersByProvider?.[provider]

        return (
          <td
            key={`payment-cell-voucher-${provider}`}
            style={{ textAlign: 'right' }}
          >
            <FlexColumn noPadding>
              <span>
                {voucherAmount ? formatCurrency(voucherAmount) : '0.00'}
              </span>
              {voucherNotes ? <small>{voucherNotes}</small> : null}
            </FlexColumn>
          </td>
        )
      })}
    </Fragment>
  )
}

export default PaymentCells
