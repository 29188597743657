import React, { ReactNode } from 'react'

import { ListTH } from './common'

type Props = {
  align?: any
  children?: ReactNode
  width?: string
}

const ListHeaderSection = ({ children, width, ...rest }: Props) => (
  <ListTH {...rest} style={width ? { width } : {}}>
    {children}
  </ListTH>
)

export default ListHeaderSection
