import { useState } from 'react'

import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import {
  MobileSalesParticipant,
  MobileSalesRoomReservation,
} from '../../../../types'
import { Placeholder, Section, Wrapper } from '../components'
import { GroupBySelector, Participant, Room } from './components'
import { groupByRoom } from './utils'

enum GroupBy {
  Name = 'NAME',
  Room = 'ROOM',
}

type Props = {
  participants: MobileSalesParticipant[]
  roomReservations: MobileSalesRoomReservation[]
  showPrices: boolean
}

export const Participants = ({
  participants,
  roomReservations,
  showPrices,
}: Props) => {
  const [groupBy, setGroupBy] = useState<GroupBy>(GroupBy.Room)

  const renderParticipants = () => {
    switch (groupBy) {
      case GroupBy.Name:
        return [...participants]
          .sort(generateCompareFn('lastName'))
          .map((p) => (
            <Participant key={p.id} participant={p} showPrices={showPrices} />
          ))
      case GroupBy.Room:
        return groupByRoom(participants, roomReservations).map((r) => (
          <Room key={r.roomNumber} room={r} showPrices={showPrices} />
        ))

      default:
        return null
    }
  }

  return (
    <Section
      extraAction={
        participants.length ? (
          <GroupBySelector groupBy={groupBy} setGroupBy={setGroupBy} />
        ) : null
      }
      isExpandable
      title={<T>SalesDetails:PrintSale.label.PARTICIPANTS</T>}
    >
      <Wrapper>
        {participants.length ? (
          renderParticipants()
        ) : (
          <Placeholder>
            – <T>SalesDetails:PrintSale.noParticipants</T> –
          </Placeholder>
        )}
      </Wrapper>
    </Section>
  )
}
