import { gql } from '@apollo/client'

import { RoomReservationsForDateParticipantRoomFragment } from './roomReservationsForDateParticipantRoom'
import { RoomReservationsForDateRequestFragment } from './roomReservationsForDateRequest'
import { RoomReservationsForDateSalesFragment } from './roomReservationsForDateSales'

export const RoomReservationsForDateRoomReservationFragment = gql`
  ${RoomReservationsForDateParticipantRoomFragment}
  ${RoomReservationsForDateRequestFragment}
  ${RoomReservationsForDateSalesFragment}

  fragment RoomReservationsForDateRoomReservation on RoomReservation {
    id
    checkIn
    checkOut
    estimatedCheckIn
    estimatedCheckOut
    participantRooms {
      ...RoomReservationsForDateParticipantRoom
    }
    request {
      ...RoomReservationsForDateRequest
    }
    sales {
      ...RoomReservationsForDateSales
    }
  }
`
