import moment from 'moment'

import { EducationLevel, Gender, ParticipantLifeStage } from '~generated-types'

import {
  CreateInputKey,
  Language,
  Participant,
  TableRow,
} from '../ExcelImport.types'

const educationOptions = [
  { key: EducationLevel.Elementary, value: 'perus' },
  { key: EducationLevel.Higher, value: 'korkea' },
  { key: EducationLevel.Secondary, value: 'toinen' },
]

const lifeStageOptions = [
  { key: ParticipantLifeStage.Employed, value: 'työllinen' },
  { key: ParticipantLifeStage.Other, value: 'muu' },
  { key: ParticipantLifeStage.Pensioner, value: 'eläkeläinen' },
  { key: ParticipantLifeStage.Student, value: 'opiskelija' },
  { key: ParticipantLifeStage.Unemployed, value: 'työtön' },
]

export const parseTable = (
  rows: TableRow[],
  fileSchema: (CreateInputKey | null)[],
  options?: {
    languages: Language[]
  }
) =>
  rows.map((row) => {
    const participantData = {} as Participant

    row.forEach((value, index) => {
      if (!value) return

      const key = fileSchema[index]

      if (
        key === 'address1' ||
        key === 'address2' ||
        key === 'postcode' ||
        key === 'city' ||
        key === 'country'
      ) {
        participantData.postalAddress = !participantData.postalAddress
          ? {}
          : participantData.postalAddress
        participantData.postalAddress[key] = `${value}`
      } else if (key === 'age') {
        participantData[key] = Number(value)
      } else if (key === 'birthday') {
        let momentDate = null

        if (typeof value === 'string') {
          momentDate = moment(
            value.toString().replace(/[\/-]/g, '.'),
            'DD.MM.YYYY'
          )
        }

        if (value instanceof Date) {
          momentDate = moment(value)
        }

        if (momentDate) {
          participantData[key] = {
            date: Number(momentDate.format('DD')),
            month: Number(momentDate.format('MM')),
            year: Number(momentDate.format('YYYY')),
          }
        }
      } else if (key === 'gender') {
        if (typeof value !== 'string') return
        const parseGender = () => {
          if (
            value.toLowerCase().includes('female') ||
            value.toLowerCase().includes('f') ||
            value.toLowerCase().includes('n')
          ) {
            return Gender.Female
          } else if (
            value.toLowerCase().includes('male') ||
            value.toLowerCase().includes('m')
          ) {
            return Gender.Male
          } else if (value.toLowerCase().includes('unknown')) {
            return Gender.Unknown
          }
        }

        participantData[key] = parseGender()
      } else if (key === 'roomNumber') {
        participantData[key] = value?.toString()
      } else if (key === 'language') {
        const languageOption = options?.languages.find(
          ({ code, name }) =>
            `${value}`.toLowerCase() === code.toLowerCase() ||
            `${value}`.toLowerCase() === name.toLowerCase()
        )
        participantData[key] = languageOption?.code || null
      } else if (key === 'education') {
        const educationOption = educationOptions.find((option) =>
          `${value}`.toLowerCase().includes(option.value)
        )
        participantData[key] = educationOption?.key || null
      } else if (key === 'lifeStage') {
        const lifeStageOption = lifeStageOptions.find((option) =>
          `${value}`.toLowerCase().includes(option.value)
        )
        participantData[key] = lifeStageOption?.key || null
      } else {
        if (!key) return
        participantData[key] = `${value}`
      }
    })

    return participantData
  })
