import { IssueLevel } from '~generated-types'

import { Palette } from '../types'

export const getIssueColor = (level: IssueLevel, palette: Palette) => {
  switch (level) {
    case IssueLevel.Error:
      return { background: '#fff2ef', color: palette.danger.main }
    case IssueLevel.Warning:
      return { background: '#fff7e6', color: palette.warning.dark }
    case IssueLevel.Info:
    default:
      return { background: 'transparent', color: palette.smoke.dark }
  }
}
