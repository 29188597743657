import Collapse from '@/components/Collapse'
import { T } from '@/modules/Language'

import {
  AvailabilityTableProps,
  RoomTypeAvailability,
} from '../../AvailabilityCalendar.types'
import { CountStatusRow } from './CountStatusRow'
import { RoomSection } from './RoomSection'
import { RoomSectionPlaceholder } from './RoomSectionPlaceholder'
import { IndentedDataRow, ScrollTableProps } from './ScrollTable'

type Props = AvailabilityTableProps &
  ScrollTableProps & {
    data: RoomTypeAvailability
    handleOnSelectRoom?: (arg0: {
      roomId: string
      roomNumber: string
      roomTypeName: string
    }) => void
    handleOnSelectRoomType?: () => void
    isLastCategory: boolean
    isLastRow: boolean
  }

export const RoomTypeSection = ({
  data,
  emphasis,
  handleOnSelectRoom,
  handleOnSelectRoomType,
  isLastCategory,
  isLastRow,
  mode,
  openSections,
  toggleSectionOpen,
  ...tableProps
}: Props) => {
  const sectionId = `roomType-${data.roomType.id}`
  const isExpanded = openSections[sectionId]

  return (
    <>
      <IndentedDataRow
        indents={[!isLastCategory, true]}
        isExpanded={isExpanded}
        isLastRow={isLastRow}
        onSelect={handleOnSelectRoomType}
        onToggleExpand={() => toggleSectionOpen(sectionId)}
        renderContent={() => (
          <CountStatusRow
            depth={2}
            emphasis={emphasis?.roomTypes?.[data.roomType.id]}
            mode={mode}
            property="rooms"
            status={data.status}
            roomTypes={[
              {
                id: data.roomType.id,
                name: data.roomType.name,
              },
            ]}
            {...tableProps}
          />
        )}
        renderHeader={() => data.roomType.name}
        {...tableProps}
      />
      <Collapse in={isExpanded}>
        {data.rooms.length ? (
          data.rooms.map((x, idx) => (
            <RoomSection
              data={x}
              emphasis={emphasis?.rooms?.[x.room.id]}
              handleOnSelectRoom={
                handleOnSelectRoom
                  ? () =>
                      handleOnSelectRoom({
                        roomId: x.room.id,
                        roomNumber: x.room.number,
                        roomTypeName: data.roomType.name,
                      })
                  : undefined
              }
              isLastCategory={isLastCategory}
              isLastRoomType={isLastRow}
              isLastRow={idx + 1 === data.rooms.length}
              key={`room-section-${x.room.id}`}
              mode={mode}
              {...tableProps}
            />
          ))
        ) : (
          <RoomSectionPlaceholder
            isLastCategory={isLastCategory}
            isLastRoomType={isLastRow}
            isLastRow
            {...tableProps}
          >
            <T>Accommodation:AvailabilityCalendar.noRooms</T>
          </RoomSectionPlaceholder>
        )}
      </Collapse>
    </>
  )
}
