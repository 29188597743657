import { ChangeEvent, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { Select } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { localeList, useLanguageContext } from '@/modules/Language'
import { T } from '@/modules/Language'

import { IconButton } from '../IconButton'
import { Sidebar } from '../Sidebar'

type Props = {
  isOpen: boolean
  closeMenu: () => void
}

export const MenuSidebar = ({ closeMenu, isOpen }: Props) => {
  const { language, switchLanguage } = useLanguageContext()

  const ref = useRef<HTMLInputElement>(null)

  return (
    <Sidebar noPadding isOpen={isOpen}>
      <Header alignItems="center" justifyContent="flex-end">
        <IconButton icon="times" onClick={closeMenu} />
      </Header>

      <Content flex={1}>
        <LinkButton onClick={closeMenu} to={`/mobile/sales`}>
          <T>Navigation:navDropdownItem.SEARCH_SALES</T>
        </LinkButton>
      </Content>

      <Toggle
        noArrow
        noMargin
        innerRef={ref}
        value={language}
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          switchLanguage(event.target.value)
          ref.current && ref.current.blur()
        }}
      >
        {localeList.map((l) => (
          <option key={l} value={l}>
            {l.toUpperCase()}
          </option>
        ))}
      </Toggle>
    </Sidebar>
  )
}

////////////

const Content = styled(FlexColumn)`
  width: 100%;
`

const Header = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(10)}rem;
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`

const LinkButton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  transition: background 300ms linear;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gu(8)}rem;
    padding: 0 ${theme.spacing.gu(3)}rem;
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Toggle = styled(Select)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align-last: center;
    font-weight: 600;
    border: none;
    border-radius: 0;

    ${({ theme }) => css`
      color: ${theme.palette.text.light};
      height: ${theme.spacing.gu(8)}rem;
      padding: 0 ${theme.spacing.gu(3)}rem;
      margin-bottom: ${theme.spacing.gu(3)}rem;
      font-size: ${theme.typography.fontSizeBase2};
      background: transparent;
    `}

    &:hover, &:focus, &:active {
      background: transparent;
    }
  }
`
