import React from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import {
  MomentDateRange,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'

import { CleaningManager } from './CleaningManager'

export const CalendarHeader = () => {
  const {
    layoutVariables: { COLUMN_HEADER_WIDTH, COLUMN_WIDTH },
    selectedRoomType,
    targetCalendarDateRange,
  } = useRoomLayoutContext()

  const getColumnGroups = ({ from, to }: MomentDateRange) => {
    const firstDay = from.clone().startOf('day')
    const lastDay = to.clone().startOf('day')

    // Use weekday 4 to get the year correct for weeks during the
    // end/beginning of a year
    const weekIterator = firstDay.clone().isoWeekday(4)
    const lastWeek = lastDay.clone().isoWeekday(4)

    const grouped = []

    while (weekIterator.isValid() && weekIterator.isSameOrBefore(lastWeek)) {
      const items = []
      const dayIterator = weekIterator.clone().isoWeekday(1)

      for (let i = 0; i < 7; i++) {
        if (dayIterator.isSameOrAfter(firstDay)) {
          items.push({
            date: dayIterator.format('DD.MM'),
            key: dayIterator.format('YYYY-MM-DD'),
            weekDay: dayIterator.format('dd'),
          })
        }

        dayIterator.add(1, 'day')
      }

      const key = weekIterator.format('YYYY[W]WW')
      const label = (
        <span>
          <T>common:time.weekAbbreviated</T> {weekIterator.isoWeek()}
        </span>
      )

      grouped.push({ items, key, label })
      weekIterator.add(1, 'week')
    }

    return grouped
  }

  const isFocusedDate = (date: string) =>
    moment(date).isSameOrBefore(selectedRoomType.checkOut) &&
    moment(date).isSameOrAfter(selectedRoomType.checkIn)

  return (
    <>
      <CleaningManager />
      <HeaderWrapper offset={COLUMN_HEADER_WIDTH}>
        {getColumnGroups(targetCalendarDateRange).map(
          ({ items, key, label }) => (
            <HeaderGroupWrapper key={key}>
              <HeaderGroupLabel>{label}</HeaderGroupLabel>
              <ItemsWrapper>
                {items.map(({ key, date, weekDay }) => (
                  <HeaderCell
                    isToday={key === moment().format('YYYY-MM-DD')}
                    key={key}
                    width={COLUMN_WIDTH}
                  >
                    <DateLabel>{date}</DateLabel>
                    <WeekDayLabel>{weekDay}</WeekDayLabel>
                    {isFocusedDate(key) && <FocusedLine />}
                  </HeaderCell>
                ))}
              </ItemsWrapper>
            </HeaderGroupWrapper>
          )
        )}
      </HeaderWrapper>
    </>
  )
}

const FocusedLine = styled.div`
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.success.light};
  `}
`

const HeaderWrapper = styled.div<{ offset: number }>`
  display: inline-flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  z-index: 3100;

  padding-left: ${({ offset }) => offset}px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    height: ${`calc(${theme.spacing.gu(13)}rem + 1px)`};
    padding-top: ${theme.spacing.gutterBig};
  `}
`

const HeaderGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const HeaderGroupLabel = styled.div`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.gu(3)}rem;
    margin-bottom: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBig};
    height: ${theme.spacing.gu(3)}rem;
  `}
`

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const HeaderCell = React.memo(styled.div<{ isToday: boolean; width: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ width }) => width}px;
  flex: 0 0 ${({ width }) => width}px;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
  `}

  ${({ isToday, theme }) =>
    isToday &&
    css<any>`
      background: ${theme.palette.primary.extraLighter};
      border-top-left-radius: ${theme.spacing.gu(2.5)}rem;
      border-top-right-radius: ${theme.spacing.gu(2.5)}rem;
      border: 1px solid ${theme.palette.primary.extraLight};
      border-bottom: 0;
      left: -1px;
      margin-right: -1px;
      width: ${({ width }) => width + 1}px;
      flex: 0 0 ${({ width }) => width + 1}px;
    `}

    ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
  `}
    
    padding: 0 12px;

  &::before {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50%;
    width: 1px;

    background: ${({ isToday, theme }) =>
      isToday ? 'transparent' : theme.palette.smoke.dark};
  }

  &:last-child::before {
    right: 0px;
  }
`)

const DateLabel = styled.span`
  font-weight: 500;
`

const WeekDayLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`
