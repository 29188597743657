import { gql, useMutation } from '@apollo/client'

import type {
  DeleteSalesProductItemMutation,
  DeleteSalesProductItemMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteSalesProductItem($id: ID!) {
    salesProductDeleteItem(id: $id) {
      deleted
      id
    }
  }
`

export const useDeleteSalesProductItemMutation = () =>
  useMutation<
    DeleteSalesProductItemMutation,
    DeleteSalesProductItemMutationVariables
  >(MUTATION)
