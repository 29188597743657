import COPY from './copyAttachment'
import REMOVE from './removeAttachment'
import RENAME from './renameAttachment'

export const attachmentMutations = Object.freeze({
  COPY,
  REMOVE,
  RENAME,
})

export type {
  CopyAttachmentMutation as CopyAttachmentPayload,
  CopyAttachmentMutationVariables as CopyAttachmentVariables,
  RemoveAttachmentMutation as RemoveAttachmentPayload,
  RemoveAttachmentMutationVariables as RemoveAttachmentVariables,
  RenameAttachmentMutation as RenameAttachmentPayload,
  RenameAttachmentMutationVariables as RenameAttachmentVariables,
} from '~generated-types'
