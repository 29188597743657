import moment from 'moment'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { ElasticRoomReservation } from '@/modules/Reservations/components/RoomLayout'
import { generateCompareFn } from '@/utils/arrays'

export const calculateOverlaps = (
  reservations: ElasticRoomReservation[]
): { overlapOffsets: Record<string, number>; rowCount: number } => {
  const overlapOffsets: Record<string, number> = {}
  const rows: [ElasticRoomReservation[]] = [[]]

  reservations
    .sort(generateCompareFn('request.checkIn.date'))
    .forEach((res: ElasticRoomReservation) => {
      const targetRowIdx = rows.findIndex(
        (rowReservations: ElasticRoomReservation[]) => {
          const { request } = rowReservations.slice(-1)[0] || {}

          if (!request?.checkOut?.date) {
            return true
          }

          // Exception - "checkIn" and "checkOut" of reservations can be in the same cell without moving to a new line.
          if (moment(request.checkOut.date).isSame(res.request.checkIn.date)) {
            if (
              request.checkOut.type === CheckOutTypes.STANDARD &&
              res.request.checkIn.type === CheckInTypes.STANDARD
            ) {
              return true
            }
            return false
          }

          return moment(request.checkOut.date).isSameOrBefore(
            res.request.checkIn.date
          )
        }
      )

      if (targetRowIdx < 0) {
        rows.push([res])
        overlapOffsets[res.id] = rows.length - 1
      } else {
        rows[targetRowIdx].push(res)
        overlapOffsets[res.id] = targetRowIdx
      }
    })

  return { overlapOffsets, rowCount: rows.length }
}
