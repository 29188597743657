import React, { createContext, ReactNode, useContext, useEffect } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { useItemEntityState } from '@/utils/hooks'

import { ColumnDetails, RowDetails } from './PresentationState'

type DataPositionTuple = [
  {
    [key: string]: any
  },
  {
    columnIndexes: number[]
    gridId: string
    rowIndexes: number[]
  }
]

type Config = {
  immutable?: boolean
}

export type ItemPosition = {
  columnIndexes: number[]
  config?: Config
  dataId: string
  gridId: string
  id: string
  rowIndexes: number[]
}

export type AllItemData = {
  columnIndexes: number[]
  config?: Config
  data: {
    [key: string]: any
  }
  dataId: string
  gridId?: string
  id: string
  rowIndexes: number[]
}

type GridContextType = {
  getItemData: (dataId: string) => any
  getAllItemData: (dataId: string) => AllItemData | null | undefined
  setItemPosition: (
    gridId: string,
    itemId: string,
    rowIndexes: number[],
    columnIndexes: number[]
  ) => void
  addManyItemsWithPosition: (
    gridId: string,
    dataPositionTuple: DataPositionTuple[]
  ) => void
  getRowDataByIndex: (
    gridId: string,
    rowIndex: number
  ) => RowDetails | null | undefined
  getColumnDataByIndex: (
    columnIndex: number
  ) => ColumnDetails | null | undefined
}

const GridContext = createContext<GridContextType>({
  addManyItemsWithPosition: () => undefined,
  getAllItemData: () => null,
  getColumnDataByIndex: () => null,
  getItemData: () => null,
  getRowDataByIndex: () => null,
  setItemPosition: () => undefined,
})

type Props = {
  children: (arg0: { items: AllItemData[] }) => ReactNode
  data: {
    [id: string]: {
      [key: string]: any
    }
  }
  extraDataContext?: {
    [id: string]: {
      [key: string]: any
    }
  }
  mapDataToPositions: (data: { [key: string]: any }) => ItemPosition[]
  mapItemIdToDataId: (itemId: string) => string
  rows: RowDetails[]
  columns: ColumnDetails[]
}

export const GridContextProvider = ({
  children,
  data,
  extraDataContext = {},
  mapDataToPositions,
  mapItemIdToDataId,
  rows,
  columns,
}: Props) => {
  const [itemPositions, itemPositionMethods] = useItemEntityState<
    ItemPosition,
    null
  >({}, null)

  const getItemData = (
    itemId: string
  ):
    | {
        [key: string]: any
      }
    | null
    | undefined => {
    const dataId = mapItemIdToDataId(itemId)

    return data[dataId] || extraDataContext[dataId]
  }

  const getAllItemData = (itemId: string): AllItemData | null | undefined => {
    const itemPosition = itemPositions[itemId]
    const dataId = itemPosition?.dataId || 'invalid-data-id'
    const itemData = data[dataId] || extraDataContext[dataId]

    if (!itemPosition || !itemData) {
      return null
    }

    return itemPosition && itemData ? { ...itemPosition, data: itemData } : null
  }

  const setItemPosition = (
    gridId: string,
    itemId: string,
    rowIndexes: number[],
    columnIndexes: number[]
  ) => {
    const previousItemPosition = itemPositions[itemId]

    itemPositionMethods.update(itemId, {
      ...previousItemPosition,
      columnIndexes,
      gridId,
      rowIndexes,
    })
  }

  const addManyItemsWithPosition = (
    gridId: string,
    dataPositionTuples: DataPositionTuple[]
  ) => {
    const itemPositions = dataPositionTuples.map(([data, position]) => {
      return {
        dataId: data.id,
        ...position,
        gridId,
        id: data.id,
      }
    })

    itemPositionMethods.addArrayOfEntities(itemPositions)
  }

  const getRowDataByIndex = (
    gridId: string,
    rowIndex: number
  ): RowDetails | null | undefined =>
    rows.find((x) => x.gridId === gridId && x.index === rowIndex)

  const getColumnDataByIndex = (
    columnIndex: number
  ): ColumnDetails | null | undefined => {
    // Only for hidden start of a room reservation.
    if (columnIndex < 0) {
      return {
        data: {
          end: moment(columns[0].id)
            .subtract(-columnIndex + 1, 'day')
            .format('YYYY-MM-DD'),
          // @ts-ignore
          id: uuidv4(),
          start: moment(columns[0].id)
            .subtract(-columnIndex, 'day')
            .format('YYYY-MM-DD'),
        },
        id: moment(columns[0].id)
          .subtract(-columnIndex, 'day')
          .format('YYYY-MM-DD'),
        index: columnIndex,
      }
    }

    // Only for hidden end of a room reservation.
    if (columnIndex > columns.length - 1) {
      return {
        data: {
          end: moment(columns[columns.length - 1].id)
            .add(columnIndex - columns.length - 1 + 1, 'day')
            .format('YYYY-MM-DD'),
          // @ts-ignore
          id: uuidv4(),
          start: moment(columns[columns.length - 1].id)
            .add(columnIndex - columns.length - 1, 'day')
            .format('YYYY-MM-DD'),
        },
        id: moment(columns[columns.length - 1].id)
          .add(columnIndex - columns.length - 1, 'day')
          .format('YYYY-MM-DD'),
        index: columnIndex,
      }
    }

    if (columnIndex > columns.length - 1) {
      return columns[columns.length - 1]
    }

    return columns.find((column) => column.index === columnIndex)
  }

  useEffect(() => {
    const itemPositions = {}

    Object.entries(data).forEach(([_id, data]) => {
      const positions = mapDataToPositions(data)

      positions.forEach((position) => {
        // @ts-ignore
        itemPositions[position.id] = position
      })
    })

    itemPositionMethods.clear()
    itemPositionMethods.addMany(itemPositions)
  }, [data, itemPositionMethods, mapDataToPositions])

  const value = {
    addManyItemsWithPosition,
    getAllItemData,
    getColumnDataByIndex,
    getItemData,
    getRowDataByIndex,
    setItemPosition,
  }

  return (
    <GridContext.Provider value={value}>
      {children({
        items: Object.keys(itemPositions)
          .map((key) => itemPositions[key])
          .map((item) => ({
            ...item,
            data: data[item.dataId],
          })),
      })}
    </GridContext.Provider>
  )
}

export const useGridContext = () => useContext(GridContext)
