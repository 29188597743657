import { DimensionSelection } from './types'

export default function transformSelectionToSelectorValue(
  selection: DimensionSelection
): string[] | null {
  const value: string[] = []

  Object.entries(selection).forEach(([dimensionName, labelNames]) => {
    labelNames.forEach((labelName) => {
      value.push(`${dimensionName}—${labelName}`)
    })
  })

  return value.length ? value : null
}
