import React from 'react'

import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { SortOption } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'

import { ElasticCatalog } from './CatalogList.types'
import { ListControls, ListFilters, ListHeader, ListItem } from './components'

const ComponentIds = Object.freeze({
  COMPANY: 'company',
  LIST: 'page',
  SEARCH: 'search',
  STATE: 'active',
})

const CATALOG_INDEX = 'registry.catalog'

type Props = {
  highlightTargetId?: string | null | undefined
  onAdd?: () => void
  onOpen?: (id: string) => void
  renderItemControls?: (id: string) => React.ReactNode
  URLParams?: boolean
}

export const CatalogList = ({
  highlightTargetId,
  onAdd,
  onOpen,
  renderItemControls,
  URLParams = true,
}: Props) => {
  const { language } = useLanguageContext()

  const sortOptions: SortOption[] = [
    {
      dataField: 'name.keyword',
      direction: 'asc',
      label: translate(
        'Catalog:CatalogList.sortOptions.catalogNameAsc',
        language
      ),
    },
    {
      dataField: 'name.keyword',
      direction: 'desc',
      label: translate(
        'Catalog:CatalogList.sortOptions.catalogNameDesc',
        language
      ),
    },
  ]

  const [sortProperty, setSortProperty] = React.useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { _score: 'asc' },
        { [sortProperty.dataField]: sortProperty.direction },
        { 'name.keyword': 'asc' },
      ]
    : [{ _score: 'asc' }, { 'name.keyword': 'asc' }]

  const defaultQueryFn = () =>
    highlightTargetId
      ? {
          query: {
            bool: {
              should: [
                {
                  match_all: {},
                },
                {
                  bool: {
                    boost: 2,
                    must: [{ term: { id: highlightTargetId } }],
                  },
                },
              ],
            },
          },
        }
      : {}

  return (
    <ElasticFilterSearchList
      columnCount={renderItemControls ? 4 : 3}
      indexName={CATALOG_INDEX}
      placeholders={{
        empty: <T>Catalog:CatalogList.empty</T>,
        error: <T>Catalog:CatalogList.error</T>,
      }}
      reactiveListProps={{
        componentId: ComponentIds.LIST,
        dataField: 'name',
        defaultQuery: () => ({
          ...defaultQueryFn(),
          sort,
        }),
        pagination: true,
        react: {
          and: [ComponentIds.COMPANY, ComponentIds.SEARCH, ComponentIds.STATE],
        },
        size: 10,
      }}
      renderListControls={() => (
        <ListControls
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
          handleAdd={onAdd}
          URLParams={URLParams}
        />
      )}
      renderListFilters={() => (
        <ListFilters
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
          URLParams={URLParams}
        />
      )}
      renderListHeader={() => <ListHeader />}
      renderListItem={(i: ElasticCatalog) => (
        <ListItem
          key={i.id}
          data={i}
          isHighlighted={i.id === highlightTargetId}
          itemControls={renderItemControls ? renderItemControls(i.id) : null}
          onOpen={() => onOpen && onOpen(i.id)}
        />
      )}
      sortProps={{
        options: sortOptions,
        setValue: setSortProperty,
        value: sortProperty,
      }}
      title={<T>Catalog:CatalogList.title</T>}
    />
  )
}
