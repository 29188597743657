import React, { ReactNode } from 'react'

import { ListTBody } from './common'

type Props = {
  children: ReactNode
}

const ListContent = ({ children, ...rest }: Props) => (
  <ListTBody {...rest}>{children}</ListTBody>
)

export default ListContent
