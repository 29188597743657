import {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'

type Props = {
  handleSubmit: (newValue: string | null) => Promise<any>
  max?: number
  maxLength?: number
  min?: number
  style?: CSSProperties
  type: string
  value: string | null | undefined
}

export const DataFieldInput = ({
  handleSubmit,
  max,
  maxLength,
  min,
  style,
  type,
  value,
}: Props) => {
  const inputEl = useRef<any>(null)

  const [editedValue, setEditedValue] = useState<string>(value || '')

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.focus()
  }, [inputEl])

  const onSubmit = (submitValue: string | null) =>
    handleSubmit(submitValue).catch(() =>
      console.warn('Failed to submit edited value')
    )

  return (
    <StyledDataFieldInput
      onBlur={() => onSubmit(editedValue)}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setEditedValue(e.target.value)
      }
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onSubmit(editedValue)
        }
      }}
      max={max}
      maxLength={maxLength}
      min={min}
      ref={inputEl}
      style={style}
      type={type}
      value={editedValue}
    />
  )
}

////////////

const StyledDataFieldInput = styled(Input)`
  &:not([type='checkbox']):not([type='radio']) {
    height: 28px;
    text-align: inherit;
    border-radius: 4px;

    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(1)}rem;
    `}
  }
`
