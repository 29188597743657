import { gql } from '@apollo/client'

export default gql`
  mutation RemoveAttachment($input: FileDeleteInput!) {
    fileDelete(input: $input) {
      deleted
      id {
        fileName
        folder
        ownerType
        ownerId
      }
    }
  }
`
