import { ChangeEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import { DescriptionOptions } from './DescriptionOptions'

type Props = {
  description: string | null
  descriptionOptions: string[]
  internalNote: string | null
  readOnly: boolean
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const BasicDetails = ({
  description,
  descriptionOptions,
  internalNote,
  readOnly,
  reservationId,
  updateReservations,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const { setDescription, setInternalNote } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [editedDescription, setEditedDescription] = useState<string>(
    description || ''
  )
  const [editedInternalNote, setEditedInternalNote] = useState<string>(
    internalNote || ''
  )

  const onSelectDescription = (descriptionOption: string) =>
    setDescription(reservationId, descriptionOption).then(() =>
      setEditedDescription(descriptionOption)
    )

  return (
    <>
      {/* Description */}
      <Wrapper style={{ paddingTop: `${spacing.gu(1.5)}rem` }}>
        <IconWrapper>
          <FontAwesomeIcon
            color={palette.text.light}
            icon="align-left"
            size="sm"
          />
        </IconWrapper>

        <TextareaWrapper>
          <Textarea
            disabled={readOnly}
            height={66}
            onBlur={() => setDescription(reservationId, editedDescription)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEditedDescription(e.target.value)
            }
            placeholder={translate(
              'ReservationModal:action.addDescription',
              language
            )}
            value={editedDescription}
            withOptions={!!descriptionOptions.length}
          />
        </TextareaWrapper>

        {!!descriptionOptions.length && !readOnly && (
          <DescriptionOptions
            options={descriptionOptions}
            onSelect={onSelectDescription}
          />
        )}
      </Wrapper>

      {/* Internal note */}
      <Wrapper>
        <IconWrapper>
          <FontAwesomeIcon
            color={palette.text.light}
            icon={['far', 'note-sticky']}
            size="sm"
          />
        </IconWrapper>

        <TextareaWrapper>
          <Textarea
            disabled={readOnly}
            height={66}
            onBlur={() => setInternalNote(reservationId, editedInternalNote)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEditedInternalNote(e.target.value)
            }
            placeholder={translate(
              'ReservationModal:action.addInternalNote',
              language
            )}
            value={editedInternalNote}
          />
        </TextareaWrapper>
      </Wrapper>
    </>
  )
}

/////////

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.guPx(1) + 2}px;
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))<{ withOptions?: boolean }>`
  &&& {
    line-height: 1.2;
    resize: vertical;

    min-height: ${({ height }) => height}px;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
      border-color: ${theme.palette.smoke.light};
    `}

    ${({ theme, withOptions }) =>
      withOptions &&
      css`
        padding-right: ${theme.spacing.gu(4)}rem;
      `}

    ::placeholder {
      font-style: italic;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }

    &:disabled {
      cursor: not-allowed;

      ${({ theme }) => css`
        background: ${theme.palette.smoke.extraLight};
      `}
    }
  }
`

const TextareaWrapper = styled.div`
  flex: 1;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  position: relative;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`
