import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { InnocuousButton } from '@/components/ExtraButtons'
import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { DatePicker } from '@/components/TimeControls'
import { T } from '@/modules/Language'

type Props = {
  loading: boolean
  pickerDate: Moment | null
  selectedSellers: string[]
  sellers: string[]
  setDate: (date: Moment) => void
  setPickerDate: (date: Moment | null) => void
  setSelectedSellers: (sellers: string[]) => void
  sidebarWidth: string
}

export const OpenAdvancesFilters = ({
  loading,
  pickerDate,
  selectedSellers,
  sellers,
  setDate,
  setPickerDate,
  setSelectedSellers,
  sidebarWidth,
}: Props) => {
  const toggleSellerFilter = (seller: string) =>
    setSelectedSellers(
      selectedSellers.includes(seller)
        ? selectedSellers.filter((x) => x !== seller)
        : [...selectedSellers, seller]
    )

  return (
    <Wrapper width={sidebarWidth}>
      <FilterSection
        label={<T>Reports:OpenAdvances.filter.targetDate</T>}
        render={() => (
          <FlexColumn alignItems="center" justifyContent="center">
            <DatePicker
              setValue={(date) => date && setPickerDate(date)}
              value={pickerDate}
            />
            <InnocuousButton
              compact
              disabled={loading || !pickerDate}
              onClick={() => {
                pickerDate && setDate(pickerDate)
                setSelectedSellers([])
              }}
            >
              <PrimaryColor>
                <T>common:action.apply</T>
              </PrimaryColor>
            </InnocuousButton>
          </FlexColumn>
        )}
      />

      {sellers.length > 1 && (
        <FilterSection
          label={<T>Reports:OpenAdvances.filter.seller</T>}
          render={() => (
            <SellerFilterWrapper>
              {sellers.map((option) => (
                <CheckboxWrapper alignItems="center" key={option}>
                  <CheckboxInput
                    checked={!!selectedSellers.includes(option)}
                    noMargin
                    onChange={() => toggleSellerFilter(option)}
                  >
                    <CheckboxLabel>{option}</CheckboxLabel>
                  </CheckboxInput>
                </CheckboxWrapper>
              ))}
            </SellerFilterWrapper>
          )}
        />
      )}
    </Wrapper>
  )
}

//////

const CheckboxLabel = styled(FlexRow)`
  margin-left: 10px;
`

const CheckboxWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const SellerFilterWrapper = styled(FlexColumn)`
  border-radius: 4px;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ width: string }>`
  align-self: flex-start;
  align-items: stretch;
  border-radius: 8px;

  ${({ theme, width }) => css`
    flex: 0 0 calc(${width} - ${theme.spacing.gutter});
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
    margin-right: ${theme.spacing.gutter};
    padding: ${theme.spacing.gutter};
  `}
`
