import styled, { css } from 'styled-components/macro'

export const HeaderValue = styled.p`
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
  `}
`

export const HeaderValuePlaceholder = styled(HeaderValue)`
  margin: 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    color: ${theme.palette.text.lighter};
  `}
`
