import React, { createContext, ReactNode, useContext } from 'react'

export type ColumnDetails = {
  index: number
  data: {
    [key: string]: any
  }
  id: string
}

export type RowDetails = ColumnDetails & {
  gridId: string
}

type CellRenderProps = {
  coordinates: { x: number; y: number }
  column: ColumnDetails
  row: RowDetails
}

type ItemRenderProps = {
  columns: ColumnDetails[]
  gridId: string
  isDragging: boolean
  isDragPreview?: boolean
  itemData: {
    [key: string]: any
  }
  leftOffset?: number
  rows: RowDetails[]
}

export type PositionData = {
  columns: ColumnDetails[]
  gridId: string
  rows: RowDetails[]
}

export type ActionData = PositionData & {
  item: {
    [key: string]: any
  }
}

type ItemResizeDirections = {
  top?: boolean
  right?: boolean
  bottom?: boolean
  left?: boolean
}

type EmptyDragDirections = {
  bottom?: boolean
  left?: boolean
}

type ResourceCalendarConfig = {
  density: 'S' | 'M' | 'L'
  isMovingDisabled: boolean
  ownerId: string | null
}

type SharedProps = {
  columnWidth: number
  emptyDragDirections?: EmptyDragDirections
  itemResizeDirections?: ItemResizeDirections
  minColumnSpan?: number
  minRowSpan?: number
  onDrop: (arg0: ActionData) => void
  onEmptyDragEnd: (arg0: PositionData) => void
  onResize: (arg0: ActionData) => void
  processing?: boolean
  renderCell: (renderProps: CellRenderProps) => ReactNode
  renderItem: (renderProps: ItemRenderProps) => ReactNode
  renderItemPreview: () => ReactNode
  resourceCalendarConfig?: ResourceCalendarConfig
  rowHeight: number
}

type PresentationContextType = SharedProps & {
  leftOffset: number
  snapToGridCellBounds: (x: number, y: number) => [number, number]
  snapToGridCellCenter: (x: number, y: number) => [number, number]
}

const PresentationContext = createContext<PresentationContextType>({
  columnWidth: 0,
  leftOffset: 0,
  onDrop: () => undefined,
  onEmptyDragEnd: () => undefined,
  onResize: () => undefined,
  processing: false,
  renderCell: () => null,
  renderItem: () => null,
  renderItemPreview: () => null,
  rowHeight: 0,
  snapToGridCellBounds: (x, y) => [x, y],
  snapToGridCellCenter: (x, y) => [x, y],
})

type Props = SharedProps & {
  children: ReactNode
  itemResizeDirections?: ItemResizeDirections
}

export const PresentationState = ({ children, ...value }: Props) => {
  const { columnWidth, rowHeight } = value

  const leftOffset = columnWidth - (columnWidth / 2 - 10)

  const snapToGridCellBounds = (x: number, y: number): [number, number] => {
    const snappedX = Math.floor(x / columnWidth) * columnWidth
    const snappedY = Math.floor(y / rowHeight) * rowHeight

    return [snappedX, snappedY]
  }

  const snapToGridCellCenter = (x: number, y: number): [number, number] => {
    const snappedX = Math.round(x / columnWidth) * columnWidth
    const snappedY = Math.round(y / rowHeight) * rowHeight

    return [snappedX, snappedY]
  }

  return (
    <PresentationContext.Provider
      value={{
        ...value,
        leftOffset,
        snapToGridCellBounds,
        snapToGridCellCenter,
      }}
    >
      {children}
    </PresentationContext.Provider>
  )
}

export const usePresentationState = () => useContext(PresentationContext)
