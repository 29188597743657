import styled, { css } from 'styled-components/macro'

export const Cell = styled.div<{
  align?: string
  flex?: number
  noBorder?: boolean
  width?: number
}>`
  padding: 8px;
  text-align: ${({ align }) => align || 'start'};

  ${({ flex }) => flex && `flex: ${flex}`};

  ${({ noBorder, theme }) =>
    !noBorder && `border-left: 1px solid ${theme.palette.smoke.dark}`};

  ${({ theme, width }) => width && `width: ${theme.spacing.gu(width)}rem`};
`

export const Separator = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.dark};
  `}
`
