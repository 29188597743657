import { gql } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

export default gql`
  ${orderFragments.payment}

  query PaymentById($id: ID!) {
    payment(id: $id) {
      ...Payment
    }
  }
`
