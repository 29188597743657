import { gql, useMutation } from '@apollo/client'

import {
  AccommodationSettingsFragment,
  RoomReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

import {
  RoomReservationSetDatesMutation,
  RoomReservationSetDatesMutationVariables,
} from '~generated-types'

export type {
  RoomReservationSetDatesMutation,
  RoomReservationSetDatesMutationVariables,
}

export const ROOM_RESERVATION_SET_DATES = gql`
  ${RoomReservationFragment}
  ${AccommodationSettingsFragment}

  mutation RoomReservationSetDates(
    $input: AccommodationRoomReservationSetDatesInput!
  ) {
    accommodationRoomReservationSetDates(input: $input) {
      ... on AccommodationRoomReservationPayloadOk {
        roomReservation {
          ...RoomReservation
        }
        accommodationGroup {
          ...AccommodationSettings
        }
      }
      ... on RoomReservationOverlappingError {
        message
      }
      ... on RoomReservationCapacityRestricted {
        message
      }
    }
  }
`

export function useRoomReservationSetDates() {
  return useMutation<
    RoomReservationSetDatesMutation,
    RoomReservationSetDatesMutationVariables
  >(ROOM_RESERVATION_SET_DATES)
}

export default useRoomReservationSetDates
