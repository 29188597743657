import * as Moment from 'moment'
import moment from 'moment'
import { extendMoment } from 'moment-range'

export const mapTimeToColumns = (
  startStr: string,
  endStr: string,
  step: number
): string[] => {
  const end = moment(endStr)
  const start = moment(startStr)

  end.set({ minutes: transformEndMinutes(end.minutes()) })
  start.set({ minutes: transformStartMinutes(start.minutes()) })

  const isEndOfDay = end.format('HH:mm') === '23:59'

  const range = extendMoment(Moment).range(start, end)

  const intervals = Array.from(range.by('minutes', { step })).map((interval) =>
    interval.format('HH:mm')
  )

  // Intervals are not "points", but "lines" which span 15 minute onwards from
  // their time value. When we only consider the starting value while
  // generating the ids, we end up creating one extra value whose "starting"
  // point belongs in the range we are inspecting, but whose "end value" is
  // out of the range. We are removing that one extra value here.
  //
  // We only show this extra "end value" when the reservation ends at the end
  // of the day (to correctly show in the calendar)
  return isEndOfDay ? intervals : intervals.slice(0, -1)
}

/////

const transformEndMinutes = (minutes: number) => Math.ceil(minutes / 15) * 15
const transformStartMinutes = (minutes: number) => Math.floor(minutes / 15) * 15
