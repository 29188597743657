import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { PaymentTypeOption } from '@/modules/Registry/PaymentTypeOption'
import { useTheme } from '@/theme'

import { PaymentType } from '~generated-types'

import { Button, Icon, Label } from '../common'

type Props = {
  icon: IconProp
  isSelected: boolean
  onClick: () => void
  readOnly: boolean
  type: PaymentType
  validation: PaymentTypeOption | null
}

export const PaymentButton = ({
  icon,
  isSelected,
  onClick,
  readOnly,
  type,
  validation,
}: Props) => {
  const { spacing } = useTheme()

  const isPaymentDisabled = validation && !validation.enabled

  return isPaymentDisabled ? (
    <Tooltip
      content={<T>{`IssueCodes:${validation.reason?.code}`}</T>}
      maxWidth={`${spacing.gu(25)}rem`}
      trigger={(triggerProps) => (
        <Button disabled isSelected={isSelected} {...triggerProps}>
          <Icon fixedWidth icon={icon} />
          <Label>
            <T>{`Orders:Payments.type.${type}`}</T>
          </Label>
          <span style={{ flex: 1 }} />
          <Icon icon={['far', 'circle-question']} />
        </Button>
      )}
    />
  ) : (
    <Button disabled={readOnly} isSelected={isSelected} onClick={onClick}>
      <Icon fixedWidth icon={icon} />
      <Label>
        <T>{`Orders:Payments.type.${type}`}</T>
      </Label>
    </Button>
  )
}
