import { gql, useMutation } from '@apollo/client'

import {
  UpdateRoomMutation,
  UpdateRoomMutationVariables,
} from '~generated-types'

export const MUTATION = gql`
  mutation UpdateRoom($input: RoomUpdateInput!) {
    roomUpdate(input: $input) {
      id
      internalInfo
    }
  }
`

export const useUpdateRoomMutation = () =>
  useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(MUTATION)
