import moment from 'moment'

import DateHeaderCell from './DateHeaderCell'

interface DateHeadersProps {
  isoWeek: number
  isoWeekYear: number
}

export const DateHeaders = ({ isoWeek, isoWeekYear }: DateHeadersProps) => {
  const FORMAT = 'YYYY[W]W'

  const monday = moment(`${isoWeekYear}W${isoWeek}`, FORMAT).startOf('isoWeek')
  const tuesday = moment(monday).add(1, 'days')
  const wednesday = moment(monday).add(2, 'days')
  const thursday = moment(monday).add(3, 'days')
  const friday = moment(monday).add(4, 'days')
  const saturday = moment(monday).add(5, 'days')
  const sunday = moment(monday).add(6, 'days')

  return (
    <tr>
      <th colSpan={4} />
      <DateHeaderCell date={monday} />
      <DateHeaderCell date={tuesday} />
      <DateHeaderCell date={wednesday} />
      <DateHeaderCell date={thursday} />
      <DateHeaderCell date={friday} />
      <DateHeaderCell date={saturday} />
      <DateHeaderCell date={sunday} />
    </tr>
  )
}

export default DateHeaders
