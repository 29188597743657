export const getSelectedViews = (search: string) => {
  const searchParams = new URLSearchParams(search)
  const viewKeys: string[] = []

  for (const key of searchParams.keys()) {
    key.startsWith('view') && viewKeys.push(key)
  }

  return viewKeys.map((key) => searchParams.get(key) || '')
}
