import { CSSProperties, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InlineModalIconSection } from '@/components/InlineModal'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { Spacing, useTheme } from '@/theme'

import { EditButton, IconWrapper, Placeholder } from '../common'

type Props = {
  onAddField: (fieldKey: string) => void
  options: Option[]
}

export const FieldSelector = ({ onAddField, options }: Props) => {
  const { palette, spacing } = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)

  const handleAddField = (option?: Option | null) => {
    if (option) {
      onAddField(option.key as string)
      setEditMode(false)
      setHover(false)
    }
  }

  return (
    <InlineModalIconSection icon="plus">
      {isEditMode ? (
        <>
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            menuIsOpen={isEditMode}
            name="resource-reservation-field-selector"
            onBlur={() => {
              setEditMode(false)
              setHover(false)
            }}
            onChange={(option?: Option | null) => handleAddField(option)}
            options={options}
            placeholder={<T>ReservationModal:fields.title</T>}
            value={null}
          />
        </>
      ) : (
        <EditButton
          onClick={() => setEditMode(true)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Placeholder>
            <T>ReservationModal:fields.title</T>
          </Placeholder>

          <IconWrapper>
            {isHover && (
              <FontAwesomeIcon
                color={palette.primary.main}
                icon="plus"
                size="sm"
              />
            )}
          </IconWrapper>
        </EditButton>
      )}
    </InlineModalIconSection>
  )
}

////////////

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
})
