import { gql, useQuery } from '@apollo/client'

import {
  SalesWebshopSettingsQuery as QueryData,
  SalesWebshopSettingsQueryVariables as QueryVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'
import { WebshopSettings } from '../types'

const QUERY = gql`
  ${publishFragments.salesWebshopSettings}

  query SalesWebshopSettings($input: ID!) {
    sales(id: $input) {
      id
      locked
      webshopSettings {
        ...SalesWebshopSettings
      }
    }
  }
`

type Params = {
  salesId: string
}

export const useSalesWebshopSettings = ({ salesId }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { input: salesId },
    }
  )

  const salesWebshopSettings: WebshopSettings | null =
    data?.sales.webshopSettings || null

  return {
    error,
    loading,
    readOnly: !!data?.sales.locked,
    refetch,
    salesWebshopSettings,
  }
}
