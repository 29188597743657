import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { generateCompareFn } from '@/utils/arrays'

import {
  SalesForPrintParticipant,
  SalesPrintConfigKey as Key,
} from '../../../../types'
import { GroupRow, GuestRow, RoomRow, TargetRow } from './Rows'
import { groupByGroup, groupByRoom, groupByTarget } from './utils'

type Props = {
  participants: SalesForPrintParticipant[]
}

export const Guests = ({ participants }: Props) => {
  const {
    salesPrintConfig: config,
    data: { groups },
  } = salesHooks.useSalesDetailsContext()

  const groupByOption = config.find(({ key }) => key === Key.Participants)
    ?.group?.by

  const groupBy =
    groupByOption === Key.Group && !groups?.values.length
      ? Key.Name
      : groupByOption

  const renderContent = () => {
    switch (groupBy) {
      case Key.Name:
        return [...participants]
          .sort(generateCompareFn('lastName'))
          .map((p) => <GuestRow data={p} key={p.id} />)
      case Key.Group:
        return groupByGroup(participants).map((g) => (
          <GroupRow data={g} key={g.group} />
        ))
      case Key.Room:
        return groupByRoom(participants).map((r) => (
          <RoomRow data={r} key={r.room} />
        ))
      case Key.Target:
        return groupByTarget(participants).map((t) => (
          <TargetRow data={t} key={t.target?.id ?? 'NO_TARGET'} />
        ))

      default:
        return null
    }
  }

  return (
    <>
      <Title>
        <T>SalesDetails:PrintSale.label.PARTICIPANTS</T>
      </Title>

      {!participants.length ? (
        <Placeholder>
          – <T>SalesDetails:PrintSale.noParticipants</T> –
        </Placeholder>
      ) : (
        renderContent()
      )}
    </>
  )
}

////////////

const Placeholder = styled.span`
  font-size: 1.25rem;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled.span`
  font-size: 1.375rem;
  font-weight: 600;
  page-break-after: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
    margin-top: ${theme.spacing.gu(5)}rem;
  `}
`
