import styled, { css } from 'styled-components/macro'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { CheckboxInput } from '@/components/FormControls'
import { TreeRow, TreeRowLabel } from '@/components/TreeSelector'
import { RoomStatus, useRoomReservationsContext } from '@/modules/FrontDesk'
import { T } from '@/modules/Language'

const PATH = 'FrontDesk:DailyRoomReservations'

export const RoomStatusFilter = () => {
  const { roomStatuses, setRoomStatuses } = useRoomReservationsContext()

  const options = [RoomStatus.Occupied, RoomStatus.Reserved, RoomStatus.Vacant]

  const toggleValue = (status: RoomStatus) =>
    setRoomStatuses(
      roomStatuses.includes(status)
        ? roomStatuses.filter((x) => x !== status)
        : [...roomStatuses, status]
    )

  return (
    <FilterSection
      label={<T l10n={`${PATH}.roomStatus.title`} />}
      render={() => (
        <SelectorWrapper>
          {options.map((o: RoomStatus) => (
            <CheckboxWrapper key={`room-status-option-${o}`}>
              <CheckboxInput
                checked={roomStatuses.includes(o)}
                noMargin
                onChange={() => toggleValue(o)}
              />
              <TreeRowLabel>
                <T l10n={`${PATH}.roomStatus.${o}`} />
              </TreeRowLabel>
            </CheckboxWrapper>
          ))}
        </SelectorWrapper>
      )}
    />
  )
}

////////////

const CheckboxWrapper = styled(TreeRow)`
  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const SelectorWrapper = styled.div`
  border-radius: 4px;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
