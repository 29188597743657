import { ReactNode, Ref, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { DropdownButton, EditButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { PublicRoomInput } from '~generated-types'

import { usePublishContext } from '../../../../PublishState'
import { PublicRoom } from '../../../../types'
import { ContentPlaceholder, Section } from '../../../common'
import { PublishDatesModal } from './PublishDatesModal'
import { formatDateRange, getPublishStatus } from './utils'

type DateRange = {
  from: Moment
  to: Moment
}

type Props = {
  publicRoom: PublicRoom | null
  targetId: string
}

export const PublishDates = ({ publicRoom, targetId }: Props) => {
  const { palette } = useTheme()
  const { readOnly, updatePublicRoom } = usePublishContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const handleUpdatePublicRoom = (update: PublicRoomInput) =>
    updatePublicRoom({ roomId: publicRoom?.id, targetId, update })

  const handlePublish = (range: DateRange) =>
    handleUpdatePublicRoom({
      publishFrom: range.from.format('YYYY-MM-DD'),
      publishTo: range.to.format('YYYY-MM-DD'),
    }).finally(() => setModalOpen(false))

  const handleUnpublish = () =>
    handleUpdatePublicRoom({ publishFrom: null, publishTo: null }).finally(() =>
      setModalOpen(false)
    )

  const handlePublishNow = () =>
    handleUpdatePublicRoom({ publishFrom: moment().format('YYYY-MM-DD') })

  const publishedDates = formatDateRange(
    publicRoom?.publishedFrom ?? null,
    publicRoom?.publishedTo ?? null
  )

  const publishedStatus = getPublishStatus(
    publicRoom?.publishedFrom ?? null,
    publicRoom?.publishedTo ?? null,
    palette
  )

  const renderLabel = (label: string | ReactNode, icon: IconProp) => (
    <>
      <OptionIcon icon={icon} />
      {label}
    </>
  )

  const options = [
    {
      label: renderLabel(<T>Publish:Rooms.publishNow</T>, 'check'),
      onClick: handlePublishNow,
    },
    {
      label: renderLabel(<T>Publish:Rooms.setDates</T>, ['far', 'calendar']),
      onClick: () => setModalOpen(true),
    },
  ]

  return (
    <Section flex={1.5}>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <PublishDatesModal
            handlePublish={handlePublish}
            handleUnpublish={handleUnpublish}
            publishedFrom={publicRoom?.publishedFrom ?? null}
            publishedTo={publicRoom?.publishedTo ?? null}
          />
        }
        onClose={() => setModalOpen(false)}
        placement="bottom-start"
        referenceElement={({ ref }) =>
          publicRoom?.publishedFrom || publicRoom?.publishedTo ? (
            <EditButton
              disabled={readOnly}
              innerRef={ref as Ref<HTMLButtonElement> | undefined}
              onClick={() => setModalOpen(true)}
              style={{ flex: 'unset' }}
            >
              <div>
                {publishedDates}
                {publishedStatus && (
                  <Tooltip
                    content={publishedStatus.content}
                    maxWidth={200}
                    trigger={(triggerProps) => (
                      <span {...triggerProps}>{publishedStatus.icon}</span>
                    )}
                  />
                )}
              </div>
            </EditButton>
          ) : (
            <DropdownButton
              options={options}
              dropdownPlacement="bottom-start"
              renderCustomButton={({ onClick }) => (
                <EditButton
                  disabled={readOnly}
                  innerRef={ref as Ref<HTMLButtonElement> | undefined}
                  onClick={onClick}
                  noIcon
                  style={{ flex: 'unset' }}
                >
                  <ContentPlaceholder>
                    <T>Publish:Rooms.publish</T>
                  </ContentPlaceholder>
                  <DropdownIcon icon="angle-down" size="xs" />
                </EditButton>
              )}
            />
          )
        }
      />
    </Section>
  )
}

///////

const OptionIcon = styled(FontAwesomeIcon)`
  && {
    width: 20px;
    margin-right: 8px;
  }
`

const DropdownIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
