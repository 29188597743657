import i18n from './config'

type loadLanguageProps = {
  _locale: string
  _namespace: string
  [key: string]: any
}

export function addResources({
  _locale,
  _namespace,
  ...translations
}: loadLanguageProps): void {
  if (_locale && _namespace && translations) {
    i18n.addResourceBundle(_locale, _namespace, translations)
  }
}

export function setLocale(locale: string): Promise<void> {
  return new Promise((resolve, reject) => {
    i18n.changeLanguage(locale, (err: Error) => {
      if (err) {
        return reject(err)
      }

      resolve()
    })
  })
}

// Initial page renders do not work correctly with translate unless we
// set the passed locale as an override language. This might be because
// correct language is not yet in place in i18n (default language of en is
// used for the first render).
//
// The locale provided by the languageContext-hook is the correct one
// though so we can use that safely.
export function translate(
  keys: string | Array<string>,
  locale: string,
  options?: Record<string, unknown>
): string {
  return i18n.t(keys, options ? { ...options, lng: locale } : { lng: locale })
}
