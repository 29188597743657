import moment from 'moment'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { Task } from '@/modules/Task/types'

import { TaskRow } from './TaskRow'

type Props = {
  onUpdateTasks: (tasks: Task[]) => void
  readOnly: boolean
  setTargetTaskNumber: (targetTaskNumber: number | null) => void
  targetTaskNumber: number | null
  tasks: Task[]
}

export const TaskList = ({
  onUpdateTasks,
  readOnly,
  setTargetTaskNumber,
  targetTaskNumber,
  tasks,
}: Props) => (
  <Wrapper>
    {[...tasks]
      .sort((a, b) => {
        const toDate = (date: string, time: string | null) =>
          new Date(time ? `${date}T${time}` : date)

        if (a.dueDate && b.dueDate) {
          return (
            moment(toDate(a.dueDate, a.dueTime)).valueOf() -
            moment(toDate(b.dueDate, b.dueTime)).valueOf()
          )
        }

        return -1
      })
      .map((task, index) => (
        <TaskRow
          isLast={index === tasks.length - 1}
          key={task.id}
          onUpdateTasks={onUpdateTasks}
          readOnly={readOnly}
          targetTaskNumber={targetTaskNumber}
          task={task}
          setTargetTaskNumber={setTargetTaskNumber}
        />
      ))}
  </Wrapper>
)

///////

const Wrapper = styled(FlexColumn)`
  min-width: 900px;
`
