import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ToggleButtonHelper } from '@/components/ToggleHelpers'

import { Customer } from '../types'
import { CustomerContactDetails } from './CustomerContactDetails'
import { CustomerContactSelector } from './CustomerContactSelector'

type Props = {
  customer: Customer
  editInRegistry: () => void
  readOnly?: boolean
  selectedContactId: string | null | undefined
  setContact: (contactId: string | null) => Promise<void>
}

export const CustomerContactEditable = ({
  customer,
  editInRegistry,
  readOnly,
  selectedContactId,
  setContact,
}: Props) => {
  if (customer.__typename === 'CustomerPerson') {
    const customerPerson = customer

    return (
      <PersonCustomerToggleButtonHelper
        disabled={readOnly}
        flex
        handleOnClick={editInRegistry}
        value={
          <FlexColumn alignItems="stretch" flex={1}>
            <CustomerContactDetails
              email={customerPerson.person.email}
              phone={customerPerson.person.phone}
            />
          </FlexColumn>
        }
      />
    )
  }

  const { contacts, defaultContactId } = customer

  const selectedContact =
    contacts.find(({ id }) => id === selectedContactId) || null

  return (
    <>
      <CustomerContactSelector
        contacts={contacts}
        defaultContactId={defaultContactId}
        editInRegistry={editInRegistry}
        readOnly={readOnly}
        selectedContact={selectedContact}
        setContact={setContact}
      />

      {!!selectedContact && (
        <OrganizationCustomerToggleButtonHelper
          disabled={readOnly}
          flex
          handleOnClick={editInRegistry}
          value={
            <FlexColumn alignItems="stretch" flex={1}>
              <CustomerContactDetails
                email={selectedContact.email}
                phone={selectedContact.phone}
              />
            </FlexColumn>
          }
        />
      )}
    </>
  )
}

///////

const PersonCustomerToggleButtonHelper = styled(ToggleButtonHelper)`
  margin-top: 0;
`

const OrganizationCustomerToggleButtonHelper = styled(ToggleButtonHelper)`
  ${({ theme }) => css`
    margin-top: -${theme.spacing.gutterSmall};
  `}
`
