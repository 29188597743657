import { gql } from '@apollo/client'

export default gql`
  query SalesProductsCompact($id: ID!) {
    sales(id: $id) {
      id
      products {
        id
        name
      }
    }
  }
`
