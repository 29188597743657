import { LinkLikeButton } from '@/components/ExtraButtons'
import { StatusToggle } from '@/components/Reactivesearch'
import { T } from '@/modules/Language'

type Props = {
  componentIds: Record<string, string>
  getDefaultQuery: () => Record<string, unknown>
  handleAdd?: () => void
  URLParams?: boolean
}

export const ListControls = ({
  componentIds,
  getDefaultQuery,
  handleAdd,
  URLParams,
}: Props) => (
  <>
    <StatusToggle
      componentIds={componentIds}
      context="CATALOG"
      getDefaultQuery={getDefaultQuery}
      URLParams={URLParams}
    />

    {handleAdd && (
      <LinkLikeButton onClick={() => handleAdd()}>
        + <T>Catalog:CatalogList.create</T>
      </LinkLikeButton>
    )}

    <span style={{ flex: 1 }} />
  </>
)
