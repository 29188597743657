import { useEffect, useState } from 'react'
import moment from 'moment'
import DayPicker, { DayModifiers, DayPickerProps } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import { createGlobalStyle, css } from 'styled-components/macro'

import { translate, useLanguageContext } from '@/modules/Language'
import { getYearRange } from '@/utils/time'

import YearMonthSelector from './YearMonthSelector'

import 'react-day-picker/lib/style.css'

export type BasePickerProps = DayPickerProps & {
  fromYear?: number
  initialMonth?: Date
  selectedDays?: { from: Date; to: Date } | null
  selectToday?: (modifiers: DayModifiers) => void
  toYear?: number
}

const BasePicker = ({
  fromYear,
  initialMonth,
  selectedDays,
  selectToday,
  toYear,
  ...dayPickerProps
}: BasePickerProps) => {
  const { language } = useLanguageContext()

  const [targetMonth, setTargetMonth] = useState<Date>(
    initialMonth || new Date()
  )

  useEffect(() => {
    if (selectedDays instanceof Date) {
      setTargetMonth((selectedDays as Date) || new Date())
    }
  }, [selectedDays])

  // Default year range to 10 year from now to both directions
  const fromYearWithFallback = moment()
    .year(fromYear ?? moment().year() - 10)
    .year()
  const toYearWithFallback = moment()
    .year(toYear ?? moment().year() + 10)
    .year()

  const years = getYearRange(fromYearWithFallback, toYearWithFallback)

  return (
    <>
      <PickerStyles />
      <DayPicker
        firstDayOfWeek={1}
        fixedWeeks
        showOutsideDays
        showWeekNumbers
        selectedDays={selectedDays}
        {...dayPickerProps}
        // ↓ These props cannot be overridden
        captionElement={({ date, locale, localeUtils }) => (
          <YearMonthSelector
            monthLabels={localeUtils.getMonths(locale)}
            setValue={setTargetMonth}
            value={date}
            years={years}
          />
        )}
        localeUtils={MomentLocaleUtils}
        locale={language}
        month={targetMonth}
        onTodayButtonClick={
          !!selectToday
            ? (_, modifiers: DayModifiers) => selectToday(modifiers)
            : undefined
        }
        todayButton={
          !!selectToday ? translate('common:time.today', language) : undefined
        }
      />
    </>
  )
}

export default BasePicker

////////////

const PickerStyles = createGlobalStyle`
  .DayPicker:focus,
  .DayPicker-wrapper:focus {
    outline: 0px solid transparent;
  }

  .DayPicker-Caption {
    padding-left: 0;
  }

  .DayPicker-Day {
    cursor: pointer;
    border-radius: 0;

    @media (max-width: 900px) {
      padding: 10px;
    }
  }

  .DayPicker-NavButton {
    @media (max-width: 900px) {
      right: 3rem;
    }
  }
  
  .DayPicker-Day:focus {
    outline: 0px solid transparent;
  }

  .DayPicker-Day--disabled {
    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
      pointer-events: none;
    `}
  }

  .DayPicker-Day--outside {
    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
    `}
  }

  .DayPicker-WeekNumber {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    ${({ theme }) => css`
      background: ${theme.palette.primary.main};
    `}
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    ${({ theme }) => css`
      background: ${theme.palette.primary.lighter};
      color: ${theme.palette.text.white};
    `}
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    ${({ theme }) => css`
      background: ${theme.palette.primary.dark};
    `}
  }

  .DayPicker-Day--outside:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):hover {
    background: #f0f8ff;
  }
  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside {
    ${({ theme }) => css`
      background: ${theme.palette.primary.lighter};
      color: ${theme.palette.text.white};
    `}
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside:hover {
    ${({ theme }) => css`
      background: ${theme.palette.primary.light};
    `}
  }

  .DayPicker-Footer {
    text-align: center;
  }
`
