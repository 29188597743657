import { useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { ActionButton, LoaderWrapper } from '@/modules/Products/components'
import { useSalesProductListContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

type Props = {
  extractAll?: boolean
  productId: string
  purchaseId: string
  quantity: number
}

export const ExtractToParticipantButton = ({
  extractAll,
  productId,
  purchaseId,
  quantity,
}: Props) => {
  const { palette } = useTheme()
  const { extractPurchase } = useSalesProductListContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)

  const handleExtractPurcaseToParticipants = () => {
    setProcessing(true)

    return extractPurchase({
      productId,
      purchaseId,
      quantity: extractAll ? quantity : 1,
    }).then((res) => {
      res && !res.source ? null : setProcessing(false)
    })
  }

  const content = extractAll ? (
    <span>
      <T>Products:ProductManager.action.extractToParticipants</T> ({quantity})
    </span>
  ) : (
    <T>Products:ProductManager.action.extractToParticipant</T>
  )

  return (
    <Wrapper>
      {isProcessing ? (
        <LoaderWrapper>
          <ReactLoading
            color={palette.smoke.dark}
            height={18}
            type="spin"
            width={18}
          />
        </LoaderWrapper>
      ) : (
        <ActionButton
          content={content}
          icon={extractAll ? 'user-group' : 'user'}
          iconColor={palette.primary.main}
          onClick={handleExtractPurcaseToParticipants}
        />
      )}
    </Wrapper>
  )
}

///////

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
