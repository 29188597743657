import { gql, useMutation } from '@apollo/client'

import {
  SetTaskAssigneeMutation,
  SetTaskAssigneeMutationVariables,
} from '~generated-types'

import { taskFragments } from '../fragments'

export type { SetTaskAssigneeMutation, SetTaskAssigneeMutationVariables }

export const SET_TASK_ASSIGNEE_MUTATION = gql`
  ${taskFragments.task}

  mutation SetTaskAssignee($input: TaskSetAssigneeInput!) {
    taskSetAssignee(input: $input) {
      ...Task
    }
  }
`

export function useSetTaskAssigneeMutation() {
  return useMutation<SetTaskAssigneeMutation, SetTaskAssigneeMutationVariables>(
    SET_TASK_ASSIGNEE_MUTATION
  )
}

export default useSetTaskAssigneeMutation
