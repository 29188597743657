import { HTML5_FMT, Moment } from 'moment'

type DateRange = {
  end: Moment
  start: Moment
}

const DATE_FORMAT = HTML5_FMT.DATE
const DATE_SEPARATOR = '–'

export const getQuery = (
  dataField: string | { start: string; end: string },
  range: DateRange | null | undefined
) =>
  range
    ? {
        query:
          typeof dataField === 'string'
            ? getQueryForDataField(dataField, range)
            : getQueryForMultiDataField(dataField, range),
        value: formatRange(range),
      }
    : {
        value: null,
      }

const getQueryForMultiDataField = (
  dataField: { start: string; end: string },
  range: DateRange
) => ({
  bool: {
    should: [
      // This part of the query is needed to get results where:
      // item.start <= range.start AND item.end >= range.start
      {
        bool: {
          must: [
            {
              range: {
                [dataField.start]: {
                  lte: range.start.format(DATE_FORMAT),
                },
              },
            },
            {
              range: {
                [dataField.end]: {
                  gte: range.start.format(DATE_FORMAT),
                },
              },
            },
          ],
        },
      },
      // This part of the query is needed to get results where:
      // item.start <= range.end AND item.end >= range.end
      {
        bool: {
          must: [
            {
              range: {
                [dataField.start]: {
                  lte: range.end.format(DATE_FORMAT),
                },
              },
            },
            {
              range: {
                [dataField.end]: {
                  gte: range.end.format(DATE_FORMAT),
                },
              },
            },
          ],
        },
      },
      // This part of the query is needed to get results where:
      // item.start => range.start AND item.end <= range.end
      {
        bool: {
          must: [
            {
              range: {
                [dataField.start]: {
                  gte: range.start.format(DATE_FORMAT),
                },
              },
            },
            {
              range: {
                [dataField.end]: {
                  lte: range.end.format(DATE_FORMAT),
                },
              },
            },
          ],
        },
      },
    ],
  },
})

const getQueryForDataField = (dataField: string, range: DateRange) => ({
  range: {
    [dataField]: {
      gte: range.start.format(DATE_FORMAT),
      lte: range.end.format(DATE_FORMAT),
    },
  },
})

const formatRange = (range: DateRange): string => {
  if (range.start.isSame(range.end)) {
    return range.start.format(DATE_FORMAT)
  }

  return `${range.start.format(DATE_FORMAT)}${DATE_SEPARATOR}${range.end.format(
    DATE_FORMAT
  )}`
}
