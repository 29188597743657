import {
  useAddPurchaseFromCatalogProductMutation,
  useAddPurchaseFromSalesProductMutation,
  useDeletePurchaseMutation,
} from '@/modules/Products/mutations'
import * as taskMutations from '@/modules/Task/mutations'

import {
  AssigneeInput as TaskAssignee,
  DimensionReferenceLabelInput,
  ResourceReservationCreateInput,
  ResourceReservationCustomerVisibility,
  ResourceReservationSetTimesInput,
} from '~generated-types'

import * as resourceReservationMutations from '../mutations'
import type {
  Reservation,
  ResourceReservation,
  ResourceReservationTask,
} from '../types'
import {
  overlappingHandler,
  updateReservation,
  updateReservationTask,
} from './utils'

type UpdateReservationsType = (reservations: Reservation[]) => void
type UpdateReservationsFromCurrentType = (
  reservations: (current: Reservation[]) => Reservation[]
) => void

type Params = {
  updateReservations: UpdateReservationsType | UpdateReservationsFromCurrentType
}

export function useResourceReservationMutations({
  updateReservations,
}: Params) {
  // Reservation mutations
  const [handleCreateReservation] =
    resourceReservationMutations.useCreateResourceReservationMutation()
  const [handleCreateReservationGroup] =
    resourceReservationMutations.useCreateResourceReservationGroupMutation()
  const [handleDeleteReservation] =
    resourceReservationMutations.useRemoveResourceReservationMutation()
  const [handleDeleteReservationGroup] =
    resourceReservationMutations.useRemoveResourceReservationGroupMutation()
  const [handleMoveReservation] =
    resourceReservationMutations.useMoveResourceReservationMutation()
  const [handleSetColor] =
    resourceReservationMutations.useSetResourceReservationColorMutation()
  const [handleSetDescription] =
    resourceReservationMutations.useSetResourceReservationDescriptionMutation()
  const [handleSetDimensions] =
    resourceReservationMutations.useSetResourceReservationDimensionMutation()
  const [handleSetDisplayMessage] =
    resourceReservationMutations.useSetResourceReservationDisplayMessageMutation()
  const [handleSetGroup] =
    resourceReservationMutations.useSetResourceReservationGroupMutation()
  const [handleSetGroupName] =
    resourceReservationMutations.useSetResourceReservationGroupNameMutation()
  const [handleSetInternalNote] =
    resourceReservationMutations.useSetResourceReservationInternalNoteMutation()
  const [handleSetTimes] =
    resourceReservationMutations.useSetResourceReservationTimesMutation()
  const [handleSetSale] =
    resourceReservationMutations.useSetResourceReservationSalesMutation()
  const [handleSetReservationResource] =
    resourceReservationMutations.useSetResourceReservationResourceMutation()
  const [handleSetVisibility] =
    resourceReservationMutations.useSetResourceReservationVisibilityMutation()
  const [handleMoveReservationsByOffset] =
    resourceReservationMutations.useReservationsDateOffset()
  const [handleUpdateResource] =
    resourceReservationMutations.useUpdateResourceMutation()

  // Purchase mutations
  const [handleAddPurchaseFromSalesProduct] =
    useAddPurchaseFromSalesProductMutation()
  const [handleAddPurchaseFromCatalogProduct] =
    useAddPurchaseFromCatalogProductMutation()
  const [handleRemovePurchase] = useDeletePurchaseMutation()

  // Task mutations
  const [handleCreateTask] = taskMutations.useCreateTaskMutation()
  const [handleRemoveTask] = taskMutations.useDeleteTaskMutation()
  const [handleCloseTask] = taskMutations.useCloseTaskMutation()
  const [handleOpenTask] = taskMutations.useOpenTaskMutation()
  const [handleSetTaskAssignee] = taskMutations.useSetTaskAssigneeMutation()
  const [handleUpdateTask] = taskMutations.useUpdateTaskMutation()

  const updateReservationsFromCurrent =
    updateReservations as UpdateReservationsFromCurrentType

  //
  // Reservation mutations
  //

  const changeResource = (
    allowOverbooking: boolean,
    reservationId: string,
    resourceId: string,
    quantity: number
  ) =>
    handleSetReservationResource({
      variables: {
        input: { allowOverbooking, quantity, reservationId, resourceId },
      },
    })
      .then(({ data }) =>
        overlappingHandler({
          onUpdate: (r) =>
            updateReservationsFromCurrent((c) => updateReservation(c, r)),
          payload: data?.resourceReservationSetResource,
        })
      )
      .catch((err) => console.error('Failed to change resource', err))

  const createReservation = (reservation: ResourceReservationCreateInput) =>
    handleCreateReservation({ variables: { input: reservation } })
      .then(({ data }) =>
        overlappingHandler({
          onUpdate: (r) => updateReservationsFromCurrent((c) => [...c, r]),
          payload: data?.resourceReservationCreate,
        })
      )
      .catch((err) => console.error('Failed to create reservation', err))

  const moveReservation = (
    allowOverbooking: boolean,
    id: string,
    start: string,
    end: string,
    resourceId: string | null
  ) =>
    handleMoveReservation({
      variables: {
        input: { allowOverbooking, end, reservationId: id, resourceId, start },
      },
    })
      .then(({ data }) =>
        overlappingHandler({
          onUpdate: (r) =>
            updateReservationsFromCurrent((c) => updateReservation(c, r)),
          payload: data?.resourceReservationMove,
        })
      )
      .catch((err) => console.error('Failed to move reservation', err))

  const moveToSale = (reservationId: string, salesId: string) =>
    handleSetSale({ variables: { input: { reservationId, salesId } } })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservation(current, data?.resourceReservationSetSales || null)
        )
      })
      .catch(() => undefined)

  const removeReservation = (reservationId: string) =>
    handleDeleteReservation({ variables: { input: { reservationId } } })
      .then(({ data }) => {
        data?.resourceReservationDelete.deleted &&
          updateReservationsFromCurrent((current) =>
            current.filter((r) => r.id !== reservationId)
          )
      })
      .catch(() => undefined)

  const setColor = (reservationId: string, color: string | null) =>
    handleSetColor({ variables: { input: { color, reservationId } } })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservation(current, data?.resourceReservationSetColor || null)
        )
      })
      .catch(() => undefined)

  const setDescription = (reservationId: string, description: string | null) =>
    handleSetDescription({
      variables: { input: { description, reservationId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservation(
            current,
            data?.resourceReservationSetDescription || null
          )
        )
      })
      .catch(() => undefined)

  const setDimensions = (
    reservationId: string,
    update: DimensionReferenceLabelInput[]
  ) =>
    handleSetDimensions({
      variables: { input: { reservationId, update } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          // @ts-ignore
          current.map((r) =>
            r.id === reservationId
              ? {
                  ...r,
                  dimensions:
                    data?.resourceReservationDimensionsSetLabels || [],
                }
              : r
          )
        )
      })
      .catch(() => undefined)

  const setDisplayMessage = (
    reservationId: string,
    displayMessage: string | null
  ) =>
    handleSetDisplayMessage({
      variables: { input: { displayMessage, reservationId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservation(
            current,
            data?.resourceReservationSetDisplayMessage || null
          )
        )
      })
      .catch(() => undefined)

  const setInternalNote = (
    reservationId: string,
    internalNote: string | null
  ) =>
    handleSetInternalNote({
      variables: { input: { internalNote, reservationId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservation(
            current,
            data?.resourceReservationSetInternalNote || null
          )
        )
      })
      .catch(() => undefined)

  const setTimes = (input: ResourceReservationSetTimesInput) =>
    handleSetTimes({ variables: { input } })
      .then(({ data }) =>
        overlappingHandler({
          onUpdate: (r) =>
            updateReservationsFromCurrent((c) => updateReservation(c, r)),
          payload: data?.resourceReservationSetTimes,
        })
      )
      .catch((err) => console.error('Failed to set times', err))

  const setVisibility = (
    reservationId: string,
    customerVisibility: ResourceReservationCustomerVisibility
  ) =>
    handleSetVisibility({
      variables: { input: { customerVisibility, reservationId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservation(
            current,
            data?.resourceReservationSetVisibility || null
          )
        )
      })
      .catch(() => undefined)

  const moveReservationsByOffset = (
    reservationIds: string[],
    offset: {
      days: number
      minutes: number
    }
  ) =>
    handleMoveReservationsByOffset({
      variables: {
        input: {
          offset,
          reservationIds,
        },
      },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) => {
          const reservations = data?.resourceReservationUpdateAll.reservations

          return current.map(
            (r) => reservations?.find(({ id }) => id === r.id) ?? r
          )
        })
      })
      .catch(() => undefined)

  //
  // Reservation group mutations
  //

  // Create new group containig this reservation. If reservation was in group already, it is moved out of it.
  const createGroup = (reservationId: string) =>
    handleCreateReservationGroup({ variables: { input: { reservationId } } })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          current.map((r) =>
            r.id === reservationId
              ? { ...r, group: data?.resourceReservationCreateGroup || null }
              : r
          )
        )
      })
      .catch(() => undefined)

  const removeGroup = (
    reservationGroupId: string,
    deleteReservations: boolean
  ) =>
    handleDeleteReservationGroup({
      variables: { input: { deleteReservations, reservationGroupId } },
    })
      .then(({ data }) => {
        data?.resourceReservationDeleteGroup.deleted &&
          updateReservationsFromCurrent((current) =>
            deleteReservations
              ? current.filter((r) => r.group?.id !== reservationGroupId)
              : current.map((r) =>
                  r.group?.id === reservationGroupId ? { ...r, group: null } : r
                )
          )
      })
      .catch(() => undefined)

  const setGroupName = (reservationGroupId: string, name: string) =>
    handleSetGroupName({
      variables: { input: { name, reservationGroupId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          // @ts-ignore
          current.map((r) =>
            r.group?.id === reservationGroupId
              ? { ...r, group: data?.resourceReservationRenameGroup }
              : r
          )
        )
      })
      .catch(() => undefined)

  // Move resource reservation to the group. Reservation group id of null means single reservation without group
  const setToGroup = (reservationId: string, groupId: string | null) =>
    handleSetGroup({
      variables: { input: { reservationGroupId: groupId, reservationId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) => {
          if (data?.resourceReservationSetGroup) {
            const { group, reservation } = data.resourceReservationSetGroup

            return (
              current
                // Set new group or remove group from current reservation
                .map((r) =>
                  r.id === reservationId
                    ? { ...r, group: groupId ? reservation.group : null }
                    : r
                )
                // Update current group after adding or deleting
                .map((r) =>
                  r.group?.id === (groupId ? reservation.group?.id : group?.id)
                    ? {
                        ...r,
                        group: (groupId ? reservation.group : group) || null,
                      }
                    : r
                )
            )
          } else {
            return current
          }
        })
      })
      .catch(() => undefined)

  const updateResource = (
    reservationId: string,
    resourceId: string,
    note: string
  ) =>
    handleUpdateResource({
      variables: { input: { id: resourceId, internalInfo: note } },
    })
      .then(({ data }) => {
        data &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? { ...r, resource: data.resourceUpdate }
                : r
            )
          )
      })
      .catch(() => undefined)

  //
  // Reservation purchase mutations
  //

  const addReservationPurchaseFromCatalogProduct = ({
    catalogProductId,
    reservationId,
    salesId,
  }: {
    catalogProductId: string
    reservationId: string
    salesId: string
  }) =>
    handleAddPurchaseFromCatalogProduct({
      variables: {
        input: {
          add: { link: { reservationId, salesId } },
          catalogProductId,
        },
      },
    })
      .then(({ data }) => {
        data?.purchaseProductAddFromCatalogProduct &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    purchaseProducts: [
                      ...(r as ResourceReservation).purchaseProducts,
                      data.purchaseProductAddFromCatalogProduct,
                    ],
                  }
                : r
            )
          )
      })
      .catch(() => undefined)

  const changeReservationPurchaseFromCatalogProduct = ({
    catalogProductId,
    purchaseId,
    reservationId,
    salesId,
  }: {
    catalogProductId: string
    purchaseId: string
    reservationId: string
    salesId: string
  }) =>
    removeReservationPurchase({ purchaseId, reservationId })
      .then(() =>
        addReservationPurchaseFromCatalogProduct({
          catalogProductId,
          reservationId,
          salesId,
        })
      )
      .catch(() => undefined)

  const addReservationPurchaseFromSalesProduct = ({
    salesProductId,
    reservationId,
    salesId,
  }: {
    salesProductId: string
    reservationId: string
    salesId: string
  }) =>
    handleAddPurchaseFromSalesProduct({
      variables: {
        input: {
          add: { link: { reservationId, salesId } },
          salesProductId,
        },
      },
    })
      .then(({ data }) => {
        data?.purchaseProductAddFromSalesProduct &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    purchaseProducts: [
                      ...(r as ResourceReservation).purchaseProducts,
                      data.purchaseProductAddFromSalesProduct,
                    ],
                  }
                : r
            )
          )
      })
      .catch(() => undefined)

  const changeReservationPurchaseFromSalesProduct = ({
    purchaseId,
    reservationId,
    salesId,
    salesProductId,
  }: {
    purchaseId: string
    reservationId: string
    salesId: string
    salesProductId: string
  }) =>
    removeReservationPurchase({ purchaseId, reservationId })
      .then(() =>
        addReservationPurchaseFromSalesProduct({
          reservationId,
          salesId,
          salesProductId,
        })
      )
      .catch(() => undefined)

  const removeReservationPurchase = ({
    purchaseId,
    reservationId,
  }: {
    purchaseId: string
    reservationId: string
  }) =>
    handleRemovePurchase({ variables: { id: purchaseId } })
      .then(({ data }) => {
        data?.purchaseProductDelete?.deleted &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    purchaseProducts: (
                      r as ResourceReservation
                    ).purchaseProducts.filter((p) => p.id !== purchaseId),
                  }
                : r
            )
          )
      })
      .catch(() => undefined)

  //
  // Task mutations
  //

  const closeTask = (taskId: string, reservationId: string) =>
    handleCloseTask({ variables: { id: taskId } })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservationTask(
            current,
            reservationId,
            taskId,
            data?.closeTask || null
          )
        )
      })
      .catch(() => undefined)

  const createTask = (reservationId: string) =>
    handleCreateTask({ variables: { input: { reservationId } } })
      .then(({ data }) => {
        data?.createTask &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    tasks: [
                      ...(r as ResourceReservation).tasks,
                      data.createTask,
                    ],
                  }
                : r
            )
          )

        return data?.createTask || null
      })
      .catch(() => undefined)

  const openTask = (taskId: string, reservationId: string) =>
    handleOpenTask({ variables: { id: taskId } })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservationTask(
            current,
            reservationId,
            taskId,
            data?.openTask || null
          )
        )
      })
      .catch(() => undefined)

  const removeTask = (taskId: string, reservationId: string) =>
    handleRemoveTask({ variables: { id: taskId } })
      .then(({ data }) => {
        data?.deleteTask.deleted &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    tasks: (r as ResourceReservation).tasks.filter(
                      (t) => t.id !== taskId
                    ),
                  }
                : r
            )
          )
      })
      .catch(() => {
        throw new Error()
      })

  const setTaskAssignee = (
    taskId: string,
    assignee: TaskAssignee | null | undefined,
    reservationId: string
  ) =>
    handleSetTaskAssignee({
      variables: { input: { assignee, id: taskId } },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservationTask(
            current,
            reservationId,
            taskId,
            data?.taskSetAssignee || null
          )
        )
      })
      .catch(() => undefined)

  const updateTask = (
    task: ResourceReservationTask,
    input: Record<string, unknown>,
    reservationId: string
  ) => {
    const { name, description, dueDate, dueTime, id } = task

    return handleUpdateTask({
      variables: {
        id,
        input: {
          description,
          dueDate,
          dueTime,
          name,
          ...input,
        },
      },
    })
      .then(({ data }) => {
        updateReservationsFromCurrent((current) =>
          updateReservationTask(
            current,
            reservationId,
            id,
            data?.updateTask || null
          )
        )
      })
      .catch(() => undefined)
  }

  //
  // Task purchase mutations
  //

  const addTaskPurchaseFromCatalogProduct = ({
    catalogProductId,
    reservationId,
    salesId,
    taskId,
  }: {
    catalogProductId: string
    reservationId: string
    salesId: string
    taskId: string
  }) =>
    handleAddPurchaseFromCatalogProduct({
      variables: {
        input: {
          add: { link: { salesId, taskId } },
          catalogProductId,
        },
      },
    })
      .then(({ data }) => {
        data?.purchaseProductAddFromCatalogProduct &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    tasks: (r as ResourceReservation).tasks.map((t) =>
                      t.id === taskId
                        ? {
                            ...t,
                            purchaseProducts: [
                              ...t.purchaseProducts,
                              data.purchaseProductAddFromCatalogProduct,
                            ],
                          }
                        : t
                    ),
                  }
                : r
            )
          )
      })
      .catch(() => undefined)

  const addTaskPurchaseFromSalesProduct = ({
    salesProductId,
    reservationId,
    salesId,
    taskId,
  }: {
    salesProductId: string
    reservationId: string
    salesId: string
    taskId: string
  }) =>
    handleAddPurchaseFromSalesProduct({
      variables: {
        input: {
          add: { link: { salesId, taskId } },
          salesProductId,
        },
      },
    })
      .then(({ data }) => {
        data?.purchaseProductAddFromSalesProduct &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    tasks: (r as ResourceReservation).tasks.map((t) =>
                      t.id === taskId
                        ? {
                            ...t,
                            purchaseProducts: [
                              ...t.purchaseProducts,
                              data.purchaseProductAddFromSalesProduct,
                            ],
                          }
                        : t
                    ),
                  }
                : r
            )
          )
      })
      .catch(() => undefined)

  const removeTaskPurchase = ({
    purchaseId,
    reservationId,
    taskId,
  }: {
    purchaseId: string
    reservationId: string
    taskId: string
  }) =>
    handleRemovePurchase({ variables: { id: purchaseId } })
      .then(({ data }) => {
        data?.purchaseProductDelete?.deleted &&
          updateReservationsFromCurrent((current) =>
            current.map((r) =>
              r.id === reservationId
                ? {
                    ...r,
                    tasks: (r as ResourceReservation).tasks.map((t) =>
                      t.id === taskId
                        ? {
                            ...t,
                            purchaseProducts: t.purchaseProducts.filter(
                              (p) => p.id !== purchaseId
                            ),
                          }
                        : t
                    ),
                  }
                : r
            )
          )
      })
      .catch(() => undefined)

  return {
    addReservationPurchaseFromCatalogProduct,
    addReservationPurchaseFromSalesProduct,
    addTaskPurchaseFromCatalogProduct,
    addTaskPurchaseFromSalesProduct,
    changeReservationPurchaseFromCatalogProduct,
    changeReservationPurchaseFromSalesProduct,
    changeResource,
    closeTask,
    createGroup,
    createReservation,
    createTask,
    moveReservation,
    moveReservationsByOffset,
    moveToSale,
    openTask,
    removeGroup,
    removeReservation,
    removeReservationPurchase,
    removeTask,
    removeTaskPurchase,
    setColor,
    setDescription,
    setDimensions,
    setDisplayMessage,
    setGroupName,
    setInternalNote,
    setTaskAssignee,
    setTimes,
    setToGroup,
    setVisibility,
    updateResource,
    updateTask,
  }
}

export default useResourceReservationMutations
