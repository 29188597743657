import { gql, useMutation } from '@apollo/client'

import {
  MoveResourceReservationMutation,
  MoveResourceReservationMutationVariables,
} from '~generated-types'

import ResourceReservationFragment from '../fragments/resourceReservation'

export type {
  MoveResourceReservationMutation,
  MoveResourceReservationMutationVariables,
}

export const MOVE_RESOURCE_RESERVATION_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation MoveResourceReservation($input: ResourceReservationMoveInput!) {
    resourceReservationMove(input: $input) {
      ... on ReservationPayloadOk {
        reservation {
          ...ResourceReservation
        }
      }
      ... on ReservationAvailabilityError {
        message
      }
      ... on ReservationAvailabilityWarning {
        message
      }
    }
  }
`

export function useMoveResourceReservationMutation() {
  return useMutation<
    MoveResourceReservationMutation,
    MoveResourceReservationMutationVariables
  >(MOVE_RESOURCE_RESERVATION_MUTATION)
}

export default useMoveResourceReservationMutation
