import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Action, SectionSeparator } from '@/modules/Order/components'
import { orderContexts } from '@/modules/Order/contexts'
import { orderServices } from '@/modules/Order/services'
import { OrderValidatedAction } from '@/modules/Order/types'

import { OrderAction as OA } from '~generated-types'

type Props = {
  actions: OrderValidatedAction[]
  noPhases: boolean
  orderId: string
}

export const InvoicePhaseActions = ({ actions, noPhases, orderId }: Props) => {
  const { setSelectedPhaseId } = orderContexts.useOrderView()
  const { triggerCreateAction } = orderServices.orderService()

  const [processingTarget, setProcessingTarget] = useState<OA | null>(null)

  const createAutomaticAdvanceAction = actions.find(
    (a) => a.action === OA.CreateAutomaticAdvance
  )
  const createManualAdvanceAction = actions.find(
    (a) => a.action === OA.CreateManualAdvance
  )
  const createRefundUnpaidAdvances = actions.find(
    (a) => a.action === OA.CreateRefundUnpaidAdvances
  )
  const createInvoiceAction = actions.find((a) => a.action === OA.CreateInvoice)

  const isAutomaticAdvanceValid = createAutomaticAdvanceAction?.valid
  const isManualAdvanceValid = createManualAdvanceAction?.valid
  const isRefundUnpaidAdvancesValid = createRefundUnpaidAdvances?.valid
  const isInvoiceValid = createInvoiceAction?.valid

  const showActionsRow =
    isAutomaticAdvanceValid ||
    isManualAdvanceValid ||
    isInvoiceValid ||
    isRefundUnpaidAdvancesValid

  const handleCreate = (action: OA) => {
    setProcessingTarget(action)

    triggerCreateAction(orderId, action).then((data) => {
      data && setSelectedPhaseId(data.id)
      setProcessingTarget(null)
    })
  }

  return showActionsRow ? (
    <Wrapper withBorder={!noPhases}>
      {createInvoiceAction && isInvoiceValid && (
        <Action
          isProcessing={processingTarget === OA.CreateInvoice}
          onClick={() => handleCreate(OA.CreateInvoice)}
          phase="ORDER"
          validatedAction={createInvoiceAction}
        />
      )}

      {isInvoiceValid && isAutomaticAdvanceValid && <Separator light />}

      {createAutomaticAdvanceAction && isAutomaticAdvanceValid && (
        <Action
          isProcessing={processingTarget === OA.CreateAutomaticAdvance}
          onClick={() => handleCreate(OA.CreateAutomaticAdvance)}
          phase="ORDER"
          validatedAction={createAutomaticAdvanceAction}
        />
      )}

      {isAutomaticAdvanceValid && isManualAdvanceValid && <Separator light />}

      {createManualAdvanceAction && isManualAdvanceValid && (
        <Action
          isProcessing={processingTarget === OA.CreateManualAdvance}
          onClick={() => handleCreate(OA.CreateManualAdvance)}
          phase="ORDER"
          validatedAction={createManualAdvanceAction}
        />
      )}

      {isRefundUnpaidAdvancesValid && isManualAdvanceValid && (
        <Separator light />
      )}

      {createRefundUnpaidAdvances && isRefundUnpaidAdvancesValid && (
        <Action
          isProcessing={processingTarget === OA.CreateRefundUnpaidAdvances}
          onClick={() => handleCreate(OA.CreateRefundUnpaidAdvances)}
          phase="ORDER"
          validatedAction={createRefundUnpaidAdvances}
        />
      )}
    </Wrapper>
  ) : null
}

/////

const Separator = styled(SectionSeparator)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexRow)<{ withBorder: boolean }>`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ theme, withBorder }) =>
    withBorder &&
    css`
      border-top: 1px solid ${theme.palette.smoke.main};
    `}
`
