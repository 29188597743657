import styled, { css } from 'styled-components/macro'

import { LinkLikeButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

type Props = {
  handleAdd?: () => void
}

const ListControls = ({ handleAdd }: Props) => (
  <>
    {handleAdd ? (
      <AddButton onClick={() => handleAdd()}>
        + <T>Customers:CustomerList.create</T>
      </AddButton>
    ) : null}
    <span style={{ flex: 1 }} />
  </>
)

export default ListControls

////////////

const AddButton = styled(LinkLikeButton)`
  margin-bottom: 0;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gutter};
  `}
`
