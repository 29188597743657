import { gql } from '@apollo/client'

export default gql`
  fragment InvoicePaymentInfo on Invoice {
    auditLog {
      bookkeepingTransferred
      createdAt
      invoiceAccepted
      updatedAt
    }
    id
    paymentInfo {
      paid
      payableAmount
      status
      totalPrice {
        amount
        amountVatIncluded
        vatAmount
      }
    }
  }
`
