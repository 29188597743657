import styled, { css } from 'styled-components/macro'

export const EmptySpace = styled.div<{ width: number }>`
  width: ${({ theme, width }) =>
    `${theme.spacing.guPx(width * 4) - width * 2}px`};
`

export const ExpansionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    width: ${theme.spacing.guPx(2)}px;
    height: ${theme.spacing.guPx(2)}px;
    margin-right: ${theme.spacing.guPx(1) - 3}px;
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}
  }
`

type LineLeaderProps = {
  isLast: boolean
  isLong: boolean
  height: number
}

export const LineLeader = ({ isLast, isLong, height }: LineLeaderProps) => (
  <LineLeaderWrapper isLast={isLast} isLong={isLong} height={height}>
    <LineLeaderDot isLong={isLong} />
  </LineLeaderWrapper>
)

const LineLeaderDot = styled.div<{ isLong: boolean }>`
  display: inline-block;
  position: absolute;

  ${({ theme }) => css`
    height: ${theme.spacing.guPx(1) - 1}px;
    width: ${theme.spacing.guPx(1) - 1}px;
    background: ${theme.palette.smoke.main};
    border-radius: ${theme.spacing.guPx(1) - 4}px;
  `}

  right: ${({ isLong, theme }) =>
    isLong ? 0 : `${theme.spacing.guPx(1) - 9}px`};
  align-self: center;
`

const LineLeaderWrapper = styled.div<LineLeaderProps>`
  position: relative;
  align-self: stretch;
  display: flex;
  flex: 0 0
    ${({ isLong, theme }) =>
      isLong ? `${theme.spacing.guPx(4) + 4}px` : `${theme.spacing.guPx(2)}px`};

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.guPx(1) - 1}px 0 ${theme.spacing.guPx(1) - 1}px;
  `}

  &::after {
    content: ' ';
    position: absolute;
    height: ${({ isLast, height }) => (isLast ? '50%' : `${height * 100}%`)};
    width: 2px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &::before {
    content: ' ';
    position: absolute;
    height: 2px;
    width: calc(100%);
    align-self: center;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`
