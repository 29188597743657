import styled from 'styled-components/macro'

type Props = {
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  flex?: number | string
  inline?: boolean
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  noPadding?: boolean
  padding?: boolean
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit' | 'unset'
}

export const FlexColumn = styled.div<Props>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: column;
  justify-content: ${(props: Props) => props.justifyContent || 'inherit'};
  align-items: ${(props: Props) => props.alignItems || 'inherit'};

  ${({ flex }) => (!!flex || flex === 0) && `flex: ${flex};`}

  ${(props: Props) => (props.wrap ? 'flex-wrap: ' + props.wrap + ';' : '')};
  padding-right: ${({ noPadding, padding, theme }) =>
    noPadding ? 0 : padding ? theme.spacing.gutterBig : 'inherit'};
  padding-bottom: ${({ noPadding, padding, theme }) =>
    noPadding ? 0 : padding ? theme.spacing.gutterBig : 'inherit'};
`

export default FlexColumn
