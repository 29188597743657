import { SectionContainer } from '../../components'
import { useSalesDetailsContext } from '../../SalesDetailsState'
import { SectionFeatures } from '../../types'
import EnrollmentsView from './Enrollments'
import { EnrollmentsContextProvider } from './EnrollmentsContext'

const SECTION: SectionFeatures = {
  icon: 'id-badge',
  key: 'enrollments',
}

const EnrollmentsComponent = () => {
  const {
    data: { id },
    saleReadOnly,
  } = useSalesDetailsContext()

  return (
    <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
      <EnrollmentsContextProvider eventId={id}>
        <EnrollmentsView readOnly={saleReadOnly} />
      </EnrollmentsContextProvider>
    </SectionContainer>
  )
}

export const Enrollments = Object.freeze({
  component: EnrollmentsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
