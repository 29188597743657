import { Ref, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { FontWeight } from '@/components/Typography'
import { ValidationIndicator } from '@/components/ValidationIndicator'
import { T } from '@/modules/Language'
import { SalesProductManager } from '@/modules/Products'
import { ProductPurchase } from '@/modules/Products/types'
import {
  Reservation,
  resourceReservationHooks,
  ResourceReservationSales,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import {
  Feature,
  PurchaseProductAction as PA,
  UseResourceReservationQuery,
} from '~generated-types'

import { Context } from '../../types'
import {
  ActionButton,
  ActionButtonWrapper,
  EditButton,
  LineLeader,
  LoaderWrapper,
} from '..'

type Props = {
  context: Context
  isLast: boolean
  purchase: ProductPurchase
  readOnly: boolean
  refetchReservation: () => Promise<
    ApolloQueryResult<UseResourceReservationQuery>
  >
  reservationId: string
  sales: ResourceReservationSales
  taskId: string
  updateReservations: (reservations: Reservation[]) => void
}

// TODO Purchase quantity

export const TaskPurchase = ({
  context,
  isLast,
  purchase: {
    id,
    product: { id: productId, name },
    status: { issues, validatedActions },
    totalPrice: { amountVatIncluded },
  },
  readOnly,
  refetchReservation,
  reservationId,
  sales: { commissionRate, facet, id: salesId, seller, type: salesType },
  taskId,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { confirm } = useDialogService()

  const { removeTaskPurchase } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const isCommissionEnabled = !!facet.features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const deleteAction = validatedActions.find((a) => a.action === PA.Delete)

  const handleCloseModal = () => {
    setModalOpen(false)
    refetchReservation()
  }

  const handleDeletePurchase = () => {
    setProcessing(true)

    const handleDelete = () =>
      removeTaskPurchase({ purchaseId: id, reservationId, taskId })
        .catch(() => undefined)
        .finally(() => setProcessing(false))

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>ReservationModal:removalConfirmation.product</T>,
    })
      .then(handleDelete)
      .catch(() => setProcessing(false))
  }

  return (
    <InlineModalIconSection style={{ paddingTop: 0 }}>
      <FlexRow flex={1}>
        <LineLeader fullHeight={!isLast} />

        <ModalContainer
          isOpen={isModalOpen}
          modal={
            <SalesProductManager
              commission={commission}
              context={context}
              onClose={handleCloseModal}
              productId={productId}
              readOnly={readOnly}
              salesId={salesId}
              salesType={salesType}
              sellerId={seller?.id}
              targetPurchaseId={id}
            />
          }
          onClose={handleCloseModal}
          placement="bottom"
          referenceElement={({ ref }) => (
            <EditButton
              onClick={() => setModalOpen(true)}
              ref={ref as Ref<HTMLButtonElement>}
              style={{ justifyContent: 'flex-start' }}
            >
              {!!issues.length ? (
                issues.map(({ code, key, level }) => (
                  <ValidationIndicatorWrapper key={`${key}-${code}`}>
                    <ValidationIndicator
                      code={code}
                      level={level}
                      style={{ margin: `0 ${spacing.gutterSmall}` }}
                    />
                  </ValidationIndicatorWrapper>
                ))
              ) : (
                <FontAwesomeIcon
                  icon="cube"
                  color={palette.text.lighter}
                  size="xs"
                  style={{
                    marginLeft: spacing.gutterSmall,
                    marginRight: `${spacing.gu(1.5)}rem`,
                  }}
                />
              )}

              <PurchaseName>
                <Tooltip
                  content={name}
                  trigger={(triggerProps) => (
                    <span {...triggerProps}>{name}</span>
                  )}
                />
              </PurchaseName>
            </EditButton>
          )}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />

        <PurchaseTotal semiBold>
          {formatCurrency(amountVatIncluded || 0)} €
        </PurchaseTotal>

        {isProcessing ? (
          <LoaderWrapper>
            <ReactLoading
              color={palette.smoke.dark}
              height={18}
              type="spin"
              width={18}
            />
          </LoaderWrapper>
        ) : (
          <ActionButtonWrapper>
            {deleteAction && deleteAction.valid && (
              <ActionButton
                content={<T>Products:ProductManager.action.deletePurchase</T>}
                icon="trash"
                iconColor={palette.danger.main}
                onClick={handleDeletePurchase}
              />
            )}
          </ActionButtonWrapper>
        )}
      </FlexRow>
    </InlineModalIconSection>
  )
}

//////

const PurchaseName = styled.span`
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(26)}rem;
  `}
`

const PurchaseTotal = styled(FontWeight)`
  align-self: center;
  text-align: end;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(14)}rem;
  `}
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
