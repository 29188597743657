import { gql } from '@apollo/client'

export const RoomReservationsForDateSalesFragment = gql`
  fragment RoomReservationsForDateSales on Sales {
    customer {
      customer {
        customerNumber
        id
        ... on CustomerOrganization {
          organization {
            businessId
            name
          }
        }
        ... on CustomerPerson {
          person {
            firstName
            lastName
          }
        }
      }
    }
    facet {
      abbreviation
      color
      id
      name
    }
    id
    locked
    name
    notes
    orderNumber
    orders {
      id
      paymentInfo {
        status
      }
    }
    paymentAgreement {
      code
      id
      name
    }
    type
  }
`
