import { useLazyQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexRow } from '@/components/Layout'
import { ModalSelector } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  ParticipantsListQueries,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  SaleAndEnrollmentsBaseQuery,
  SaleAndEnrollmentsBaseQueryVariables,
} from '~generated-types'

type Props = {
  participantSalesId: string
  readOnly?: boolean
  handleSelectSale: (newSale: string) => Promise<any>
}

export const ParticipantSaleSelector = ({
  participantSalesId,
  readOnly,
  handleSelectSale,
}: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const { salesId } = useParticipantsListContext()

  const [loadEnrollments, { data: enrollmentsData }] = useLazyQuery<
    SaleAndEnrollmentsBaseQuery,
    SaleAndEnrollmentsBaseQueryVariables
  >(ParticipantsListQueries.SALE_AND_ENROLLMENTS_BASE, {
    fetchPolicy: 'cache-and-network',
  })

  const enrollments = [...(enrollmentsData?.sales?.enrollments?.nodes || [])]

  const enrollmentsOptions = [
    ...(participantSalesId === salesId
      ? [
          {
            disabled: true,
            label: translate(
              'ParticipantsList:ParticipantFormFields.notSelected',
              language
            ),
            value: salesId,
          },
        ]
      : []),
    ...enrollments.sort(generateCompareFn('orderNumber')).map((enrollment) => ({
      disabled: participantSalesId === enrollment.id,
      label: enrollment.name
        ? `#${enrollment.orderNumber ? enrollment.orderNumber : ''} – ${
            enrollment.name
          }`
        : `#${enrollment.orderNumber ? enrollment.orderNumber : ''}`,
      value: enrollment.id,
    })),
  ]

  const handleOpenModal = (setModalOpen: (isModalOpen: boolean) => void) => {
    if (readOnly) {
      return
    }

    loadEnrollments({
      variables: {
        id: salesId,
      },
    })
    setModalOpen(true)
  }

  return (
    <ModalSelector
      options={enrollmentsOptions}
      handleChange={handleSelectSale}
      referenceElement={(ref, setModalOpen) => (
        <SaleSelectorWrapper
          ref={ref}
          disabled={readOnly}
          onClick={() => handleOpenModal(setModalOpen)}
        >
          <FlexRow
            alignItems={'center'}
            style={{ marginRight: `${spacing.gu(2)}rem` }}
          >
            <Chip size="sm" color="secondary">
              <T>Sales:entity.type.ENROLLMENT</T>
            </Chip>
          </FlexRow>
          <Label normal>
            {(
              enrollmentsOptions.find(
                ({ value }) => value === participantSalesId
              ) || enrollmentsOptions[0]
            )?.label || ''}
          </Label>
        </SaleSelectorWrapper>
      )}
    />
  )
}

const Label = styled(FontWeight)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SaleSelectorWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};
  transition: all 0.1s ease-out;
  border-radius: 8px;
  height: 100%;

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    margin-left: ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
