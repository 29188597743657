import { ListItemSection as Wrapper } from '@/components/List'

import { ElasticCustomerLabel } from '../../../../../types'
import { CustomerLabel } from '../../../../CustomerLabel'

export interface LabelsProps {
  data: ElasticCustomerLabel[]
}

export const Labels = ({ data }: LabelsProps) => (
  <Wrapper>
    {data.map((label) => (
      <CustomerLabel key={`customer-label-${label.id}`} {...label} />
    ))}
  </Wrapper>
)

export default Labels
