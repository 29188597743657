import styled, { css } from 'styled-components'

import { FlexColumn, FlexRow } from '@/components/Layout'

export const Content = styled(FlexColumn)<{ bold?: boolean; width?: string }>`
  align-items: flex-start;
  overflow-wrap: anywhere;

  ${({ width }) => css`
    width: ${width || '100%'};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
    `}
`

export const ContentWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    gap: ${theme.spacing.gu(1.5)}rem;
  `};
`

export const Info = styled.span`
  overflow-wrap: anywhere;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `};
`

export const InfoTitle = styled(FlexRow)`
  font-weight: 600;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `};
`

export const InfoWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `};
`

export const InnerContainer = styled(FlexColumn)<{ compact?: boolean }>`
  padding: 0;

  ${({ compact, theme }) => css`
      padding-bottom: ${theme.spacing.gu(compact ? 1.5 : 2)}rem;
      padding-top: ${theme.spacing.gu(compact ? 1.5 : 2)}rem;

      &:not(:last-child) {
        border-bottom: 1px solid ${theme.palette.smoke.light};
      }
    }
  `};
`

export const Placeholder = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1.5)}rem 0;
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem;
  `}
`
