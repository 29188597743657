import publicRoom from './publicRoom'
import salesPublisedProducts from './salesPublisedProducts'
import salesWebshopSettings from './salesWebshopSettings'
import saleWebshopContacts from './saleWebshopContacts'
import webshopSalesToken from './webshopSalesToken'

export const publishFragments = Object.freeze({
  publicRoom,
  salesPublisedProducts,
  salesWebshopSettings,
  saleWebshopContacts,
  webshopSalesToken,
})
