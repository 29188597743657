import { useQuery } from '@apollo/client'

import type {
  SalesAccommodationGroupsQuery as QueryData,
  SalesAccommodationGroupsQueryVariables as QueryVariables,
} from '~generated-types'

import { productQueries } from '../queries'
import { SalesAccommodationGroup } from '../types'

type Params = {
  id: string
  skip: boolean
}

export const useSalesAccommodationGroups = ({ id, skip }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    productQueries.GET_SALES_ACCOMMODATION_GROUPS,
    {
      fetchPolicy: 'network-only',
      skip,
      variables: { id },
    }
  )

  const accommodationGroups: SalesAccommodationGroup[] =
    data?.sales.accommodation.accommodationGroups ?? []

  return {
    accommodationGroups,
    error,
    loading,
    refetch,
  }
}
