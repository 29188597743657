import styled, { css } from 'styled-components/macro'

export const PaginationButton = styled.button<{
  active?: boolean
  disabled?: boolean
}>`
  border-radius: 6px;

  width: 35px;
  height: 35px;

  outline: none;
  transition: 0.2s;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}

  background-color: ${({ active, disabled, theme }) =>
    active
      ? theme.palette.primary.main
      : disabled
      ? theme.palette.smoke.lighter
      : theme.palette.white};

  color: ${({ active, disabled, theme }) =>
    active
      ? theme.palette.text.white
      : disabled
      ? theme.palette.text.lighter
      : theme.palette.text.light};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover:not([disabled]) {
    ${({ active, theme }) => css`
      ${active
        ? `background-color: ${theme.palette.primary.light};
      `
        : `background-color: ${theme.palette.smoke.main};
      `}
    `}
  }
`
