import moment, { HTML5_FMT, Moment } from 'moment'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

interface IntervalHook {
  from: Moment
  range: Moment[]
  setInterval: (from: Moment, to: Moment) => void
  to: Moment
}

const useRouteDateInterval = (): IntervalHook => {
  const history = useHistory()
  const { search } = useLocation()
  const { path } = useRouteMatch()
  const { from: searchFrom, to: searchTo, ...searchRest } = parse(search)

  const routeFrom =
    searchFrom && typeof searchFrom === 'string' && moment(searchFrom).isValid()
      ? moment(searchFrom).startOf('day')
      : null
  const routeTo =
    searchTo && typeof searchTo === 'string' && moment(searchTo).isValid()
      ? moment(searchTo).startOf('day')
      : null

  const from = routeFrom || routeTo || moment()
  const to = routeTo || routeFrom || moment()

  if (!to.isSameOrAfter(from)) {
    history.replace(
      `${path}?${stringify({
        ...searchRest,
        from: from.format(HTML5_FMT.DATE),
      })}`
    )
  }

  const range: Moment[] = []
  const rangeIterator = moment(from)

  while (rangeIterator.isSameOrBefore(to)) {
    range.push(moment(rangeIterator))
    rangeIterator.add(1, 'days')
  }

  const handleSetInterval = (inputFrom: Moment, inputTo: Moment) => {
    history.replace(
      `${path}?${stringify({
        ...parse(search),
        from: inputFrom.format(HTML5_FMT.DATE),
        to: inputTo.format(HTML5_FMT.DATE),
      })}`
    )
  }

  return {
    from,
    range,
    setInterval: handleSetInterval,
    to,
  }
}

export default useRouteDateInterval
