import { gql } from '@apollo/client'

import { ageCategoryFragments } from '@/modules/Registry/AgeCategory'

export default gql`
  ${ageCategoryFragments.ageCategory}

  fragment ProductRule on ProductRule {
    action {
      type
      ... on AmountPriceRule {
        amount
      }
    }
    auditLog {
      createdAt
    }
    condition {
      type
      ... on AgeCategoryRule {
        ageCategories {
          ...AgeCategory
        }
      }
      ... on BoundaryRule {
        bounds
      }
      ... on DateRule {
        dates
      }
      ... on QuantityRule {
        quantity
      }
      ... on WeekdayRule {
        weekdays
      }
    }
    id
  }
`
