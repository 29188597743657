import { CSSProperties, MouseEvent, ReactNode, Ref } from 'react'

import { Placement, PopperWithShadow } from '@/components/PopperWithShadow'

type Props = {
  isOpen: boolean
  keepMounted?: boolean
  modal: ReactNode
  onClose: (e: MouseEvent<HTMLDivElement>) => void
  placement?: Placement
  referenceElement: (props: { ref?: Ref<HTMLDivElement> }) => ReactNode
  styleOverrides?: CSSProperties
  zIndex?: number
}

export const ModalContainer = ({
  isOpen,
  keepMounted,
  modal,
  onClose,
  placement,
  referenceElement,
  styleOverrides,
  zIndex,
}: Props) => {
  return (
    <PopperWithShadow
      keepMounted={keepMounted}
      modal={modal}
      modalIsOpen={isOpen}
      onShadowClick={onClose}
      popperPlacement={placement || 'bottom'}
      popperModifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 16,
            rootBoundary: 'viewport',
          },
        },
      ]}
      referenceElement={referenceElement}
      styleOverrides={
        styleOverrides ? styleOverrides : { maxHeight: 'calc(100% - 32px)' }
      }
      zIndex={zIndex}
    />
  )
}
