import { gql, useMutation } from '@apollo/client'

import type {
  DeleteCatalogProductItemRuleMutation,
  DeleteCatalogProductItemRuleMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteCatalogProductItemRule($productRuleId: ID!) {
    catalogProductItemDeleteRule(productRuleId: $productRuleId) {
      deleted
      id
    }
  }
`

export const useDeleteCatalogProductItemRuleMutation = () =>
  useMutation<
    DeleteCatalogProductItemRuleMutation,
    DeleteCatalogProductItemRuleMutationVariables
  >(MUTATION)
