/**
 * GENERATED
 */
import type {
  RoomReservationsListingRoomFragment,
  RoomReservationsListingRoomReservationFragment,
} from '~generated-types'

export type Reservation = RoomReservationsListingRoomReservationFragment
export type ParticipantReservation = Reservation['participantRooms'][0]
export type ReservationRequest = Reservation['request']
export type ReservationCheckIn = Reservation['request']['checkIn']
export type ReservationCheckOut = Reservation['request']['checkOut']
export type ReservationParticipant = ParticipantReservation['participant']
export type ReservationSales = ReservationParticipant['sales']
export type ReservationCustomer = NonNullable<
  ReservationSales['customer']
>['customer']
export type Room = RoomReservationsListingRoomFragment
export type Building = Room['building']

export type RoomReservation = {
  arrivingReservation: Reservation | null
  departingReservation: Reservation | null
  presentReservation: Reservation | null
  room: Room
}

/**
 * MANUAL
 */
export enum RoomReservationFilter {
  HIDE_FREE = 'HIDE_FREE',
  HIDE_LODGING = 'HIDE_LODGING',
}
