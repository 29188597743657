export const getCustomerName = (name: string | null, number: string | null) => {
  if (name && number) {
    return `#${number} – ${name}`
  }

  if (name) {
    return name
  }

  if (number) {
    return `#${number}`
  }

  return '–'
}
