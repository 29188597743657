import { useState } from 'react'
import moment from 'moment'

import { ById } from '@/common/types'
import { useDialogService } from '@/components/DialogService'
import {
  ActionData,
  AllItemData,
  GridContextProvider,
  GridSection,
  ItemPosition,
  PositionData,
  PresentationState,
} from '@/components/Grid'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useCalendarState } from '@/modules/Reservations'
import {
  CalendarReservation,
  OverlappingError,
  ResourceReservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { generateCompareFn } from '@/utils/arrays'
import { useShiftPress } from '@/utils/hooks'

import type { GridGroupSection as GridGroupSectionType } from '../../../ReservationsGridState'
import { useReservationsGridState } from '../../../ReservationsGridState'
import { getStartEndFromColumnData, mapTimeToColumns } from '../../../utils'
import { ResourceDisplayNamePlaceholder } from '../../ResourceDisplayNamePlaceholder'
import AvailabilityRow from './components/AvailabilityRow'
import CalendarItem from './components/CalendarItem'
import CalendarItemPreview from './components/CalendarItemPreview'
import Cell from './components/Cell'
import ColumnHeader from './components/ColumnHeader'
import { ReservationBlockPreview } from './components/ReservationBlock'
import ContentWrapper from './components/SectionContentWrapper'
import HeaderWrapper from './components/SectionHeaderWrapper'

type ResourceReservationPreview = {
  end: string
  id: 'PREVIEW'
  resourceId: string
  salesId: string | null
  start: string
}

type Reservation = CalendarReservation | ResourceReservation

type Props = {
  availabilityOffset: number
  data: GridGroupSectionType
  isLast: boolean
  isLastParent?: boolean
  nestedResourcesIds?: string[]
  parentResourceId?: string
}

export default function GridGroupSection({
  availabilityOffset,
  data: { isPooled, resourceId, targetDate, title },
  isLast,
  isLastParent,
  nestedResourcesIds,
  parentResourceId,
}: Props) {
  const { confirm, warn } = useDialogService()
  const { isShiftPressed } = useShiftPress()
  const {
    isReservationsLocked,
    ownerId,
    refresh: refreshFocusedResources,
    viewMode,
  } = useCalendarState()
  const {
    calendarDensity,
    calendarVariables: {
      COLUMN_HEADER_WIDTH,
      COLUMN_WIDTH,
      RESERVATION_TIME_STEP,
      ROW_HEIGHT,
    },
    columns,
    refetchAvailabilities,
    reservationsById,
    reservationIdsByResource,
    setReservationsArr,
  } = useReservationsGridState()

  const { createReservation, moveReservation } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations: setReservationsArr,
    })

  const [preview, setPreview] = useState<
    ResourceReservation | ResourceReservationPreview | null
  >(null)
  const [datesChange, storeDatesChange] = useState<Reservation | null>(null)

  const gridId = `${resourceId}.${targetDate}`

  const isNestedSection = !!parentResourceId
  const isParentSection = !!nestedResourcesIds?.length
  const isComplexSection = isParentSection || isNestedSection
  const isLastSection =
    isLast && (!isComplexSection || (isNestedSection && isLastParent))

  const reservationIdsByParentResourceId = parentResourceId
    ? reservationIdsByResource?.[parentResourceId]?.[targetDate] || []
    : []

  const reservationIdsByNestedResourcesIds = nestedResourcesIds
    ? nestedResourcesIds
        .map((id) => reservationIdsByResource?.[id]?.[targetDate] || [])
        .flat()
    : []

  const reservationIds =
    reservationIdsByResource?.[resourceId]?.[targetDate] || []

  const allReservationIds = [
    ...reservationIds,
    ...reservationIdsByNestedResourcesIds,
    ...reservationIdsByParentResourceId,
  ]

  const getReservationsById = (reservationIds: string[]): ById<Reservation> =>
    reservationIds
      .map((id) =>
        datesChange?.id === id ? { ...datesChange } : reservationsById[id]
      )
      .filter(Boolean)
      .reduce((acc, val) => ({ ...acc, [val.id]: val }), {})

  const { overlapOffsets, rowCount } = calculateOverlaps(
    Object.values(getReservationsById(reservationIds))
  )

  const allReservationsById = getReservationsById(allReservationIds)

  const renderConfirmDialog = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.continue</T>,
      description: (
        <T>ResourceReservationsCalendar:overlapWarning.description</T>
      ),
      title: <T>ResourceReservationsCalendar:overlapWarning.title</T>,
    })

  const renderWarningDialog = () =>
    warn({
      cancelLabel: <T>common:action.cancel</T>,
      description: <T>ResourceReservationsCalendar:overlapError.description</T>,
      title: <T>ResourceReservationsCalendar:overlapError.title</T>,
    })

  const updateAfterConfirmPropmt = (item: { [key: string]: any }) => {
    const { end, id, resourceId, start } = item

    const doMoveReservation = (allowOverbooking: boolean) =>
      moveReservation(allowOverbooking, id, start, end, resourceId)

    return doMoveReservation(false).then(
      (data: void | ResourceReservation | OverlappingError | null) => {
        const errorData = data as OverlappingError

        if (errorData.message) {
          if (errorData.type === 'WARNING') {
            return renderConfirmDialog()
              .then(() => doMoveReservation(true))
              .catch(() => undefined)
          }
          if (errorData.type === 'ERROR') {
            return renderWarningDialog().catch(() => undefined)
          }
        }
      }
    )
  }

  const handleOnMove = ({ columns, item, rows }: ActionData) => {
    if (!rows[0]) {
      return
    }

    const { date, resourceId } = rows[0]?.data || {}
    const [start, end] = getStartEndFromColumnData(date, columns)
    const isPooled = item.resource.isPooled || resourceId?.includes('EQUIPMENT')

    storeDatesChange({ ...item, end, start } as Reservation)

    updateAfterConfirmPropmt({ ...item, end, resourceId, start }).finally(
      () => {
        storeDatesChange(null)
        isPooled && refetchAvailabilities()
        ownerId && refreshFocusedResources()
      }
    )
  }

  const handleEmptyDragEnd = ({ columns, rows }: PositionData) => {
    if (!rows[0]) {
      return
    }

    const { date, resourceId } = rows[0]?.data || {}
    const [start, end] = getStartEndFromColumnData(date, columns)
    const isPooled = resourceId?.includes('EQUIPMENT')

    const commonProps = { end, resourceId, salesId: ownerId, start }

    setPreview({ id: 'PREVIEW', ...commonProps })

    createReservation({ allowOverbooking: false, ...commonProps })
      .then((data: void | ResourceReservation | OverlappingError | null) => {
        const errorData = data as OverlappingError

        if (errorData.message) {
          if (errorData.type === 'WARNING') {
            return renderConfirmDialog()
              .then(() =>
                createReservation({ allowOverbooking: true, ...commonProps })
              )
              .catch(() => undefined)
          }
          if (errorData.type === 'ERROR') {
            return renderWarningDialog().catch(() => undefined)
          }
        }

        return data
      })
      .then((data) => {
        setPreview(isShiftPressed ? null : (data as ResourceReservation))

        ownerId && refreshFocusedResources()
        isPooled && refetchAvailabilities()
      })
      .catch(() => setPreview(null))
  }

  const mapDataToPositions = (reservation: Reservation): ItemPosition[] => {
    const columnIds = mapTimeToColumns(
      reservation.start,
      reservation.end,
      RESERVATION_TIME_STEP
    )
    const columnIndexes = columnIds
      .map((columnId) => {
        const targetColumn = columns.find((column) => column.id === columnId)

        return targetColumn ? targetColumn.index : -1
      })
      .filter((index) => index >= 0)

    const rowIndexes = [overlapOffsets[reservation.id] ?? 0]

    if (resourceId !== reservation.resource?.id) {
      const complexReservations: ItemPosition[] = []

      Array(rowCount)
        .fill(0)
        .forEach((_, index) => {
          complexReservations.push({
            columnIndexes,
            dataId: reservation.id,
            gridId,
            id: `${gridId}.${reservation.id}.${index}`,
            rowIndexes: [index],
          })
        })

      return complexReservations
    }

    return [
      {
        columnIndexes,
        dataId: reservation.id,
        gridId,
        id: `${gridId}.${reservation.id}`,
        rowIndexes,
      },
    ]
  }

  const mapItemIdToDataId = (itemId: string) => itemId.split('.').pop() || ''

  const renderCell = ({ coordinates }: any) => (
    <Cell border={(coordinates.x + 1) % 4 === 0} />
  )

  const renderItem = ({ isDragging, isDragPreview, itemData: item }: any) => {
    if (!item) {
      return null
    }

    const baseProps = {
      cancelPreview: () => setPreview(null),
      isDragSource: isDragging && !isDragPreview,
      isDragTarget: !!isDragPreview && isDragging,
      isLocked: isReservationsLocked,
      isShadow: item.resource?.id !== resourceId,
      preview: item.id === preview?.id,
      reservation: item,
      reservationContextId: ownerId,
    }

    return item.id === 'PREVIEW' ? (
      <CalendarItemPreview />
    ) : (
      <CalendarItem {...baseProps} />
    )
  }

  const renderPreview = () => <ReservationBlockPreview />

  const rows = Array(rowCount)
    .fill(0)
    .map((_, index) => ({
      data: {
        date: moment(targetDate),
        resourceId,
      },
      gridId,
      id: `${resourceId}.${targetDate}.${index}`,
      index,
    }))

  const renderHeader = () =>
    isPooled ? (
      <>
        <ColumnHeader height={ROW_HEIGHT} key={`row-header-availability`}>
          {title}
        </ColumnHeader>
        {rows.map(({ id }) => (
          <ColumnHeader height={ROW_HEIGHT} key={`row-header-${id}`} />
        ))}
      </>
    ) : (
      rows.map(({ id }, idx) =>
        idx === 0 ? (
          <ColumnHeader height={ROW_HEIGHT} key={`row-header-${id}`}>
            {title}
          </ColumnHeader>
        ) : (
          <ColumnHeader height={ROW_HEIGHT} key={`row-header-${id}`}>
            {((isNestedSection && !isLast) || isParentSection) &&
              viewMode === 'DATE' && <ResourceDisplayNamePlaceholder />}
          </ColumnHeader>
        )
      )
    )

  const renderRows = (items: AllItemData[]) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {isPooled && (
        <AvailabilityRow
          availabilityOffset={availabilityOffset}
          resourceId={resourceId}
        />
      )}
      <ContentWrapper
        height={ROW_HEIGHT * rows.length}
        isLast={viewMode === 'DATE' ? isLastSection : isLast}
        isLight={
          viewMode === 'DATE' &&
          (isParentSection || (isNestedSection && !isLast))
        }
      >
        <GridSection
          columns={columns}
          items={items}
          gridId={gridId}
          rows={rows}
        />
      </ContentWrapper>
    </div>
  )

  return (
    <PresentationState
      columnWidth={COLUMN_WIDTH}
      emptyDragDirections={{
        bottom: false,
        left: true,
      }}
      itemResizeDirections={{
        bottom: false,
        left: true,
        right: true,
        top: false,
      }}
      minColumnSpan={1}
      onDrop={handleOnMove}
      onEmptyDragEnd={handleEmptyDragEnd}
      onResize={handleOnMove}
      renderCell={renderCell}
      renderItem={renderItem}
      renderItemPreview={renderPreview}
      resourceCalendarConfig={{
        density: calendarDensity,
        isMovingDisabled: isReservationsLocked,
        ownerId,
      }}
      rowHeight={ROW_HEIGHT}
    >
      <GridContextProvider
        data={
          preview?.id === 'PREVIEW'
            ? { ...allReservationsById, PREVIEW: preview }
            : allReservationsById
        }
        extraDataContext={reservationsById}
        // @ts-ignore
        mapDataToPositions={mapDataToPositions}
        mapItemIdToDataId={mapItemIdToDataId}
        rows={rows}
        columns={columns}
      >
        {({ items }) => (
          <FlexRow>
            <HeaderWrapper width={COLUMN_HEADER_WIDTH}>
              {renderHeader()}
            </HeaderWrapper>
            {renderRows(items)}
          </FlexRow>
        )}
      </GridContextProvider>
    </PresentationState>
  )
}

////////////

function calculateOverlaps(reservations: Reservation[]) {
  const overlapOffsets: { [key: string]: number } = {}
  let rowCount = 1

  const sortedReservations = reservations.sort(generateCompareFn('start'))

  sortedReservations.forEach((reservation, index) => {
    const prevReservations = sortedReservations.filter(({ end }) =>
      moment(end).isSameOrBefore(reservation.start)
    )

    if (index === 0 || prevReservations.length === index) {
      overlapOffsets[reservation.id] = rowCount - 1
      return
    }

    if (prevReservations.length < index) {
      overlapOffsets[reservation.id] = rowCount
      rowCount += 1
    }
  })

  return { overlapOffsets, rowCount }
}
