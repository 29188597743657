import { useEffect, useState } from 'react'

const useShiftPress = () => {
  const [isShiftPressed, setShiftPressed] = useState<boolean>(false)

  const downHandler = (event: KeyboardEvent) => {
    if (event.shiftKey) {
      setShiftPressed(true)
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (!event.shiftKey) {
      setShiftPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  return { isShiftPressed }
}

export default useShiftPress
