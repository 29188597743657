import ReactLoading from 'react-loading'
import styled, { css, keyframes } from 'styled-components/macro'

import { useTheme } from '@/theme'

export const AvailabilityCalendarLoader = () => {
  const { palette } = useTheme()

  return (
    <Wrapper>
      <ReactLoading
        color={palette.smoke.extraDark}
        height={54}
        type="spin"
        width={54}
      />
    </Wrapper>
  )
}

const popIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 4100;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.4);
  animation: ${popIn} 0.5s cubic-bezier(0, 0.83, 0.75, 0.91);

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(12)}rem;
  `}
`
