import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { PUBLIC_URL } from '@/config/env'
import { useKeycloakContext } from '@/config/keycloak'

import { useRefreshUserMutation } from './refreshUser'

interface AuthProviderProps {
  children: ReactNode
}

export interface WillbaUser {
  displayName: string
  email: string | null
  photoURL: string | null
}

interface AuthContextType {
  signOut: () => void
  user: WillbaUser | null
}

export const AuthContext = createContext<AuthContextType>({
  signOut: () => undefined,
  user: null,
})

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const contextValueRef = useRef<AuthContextType | null>(null)

  const { keycloak } = useKeycloakContext()

  const [refreshUser] = useRefreshUserMutation()

  const { email, name, picture, sub } = keycloak?.idTokenParsed || {
    email: null,
    name: 'Willba User',
    picture: null,
  }

  const [user, setUser] = useState<WillbaUser | null>(null)

  useEffect(() => {
    if (sub) {
      refreshUser({ variables: { externalId: sub } })
    }
  }, [sub])

  useEffect(() => {
    setUser({
      displayName: name,
      email: email,
      photoURL: picture,
    })
  }, [name, email, picture])

  const signOut = () => keycloak?.logout({ redirectUri: PUBLIC_URL })

  contextValueRef.current = {
    signOut,
    user,
  }

  return (
    <AuthContext.Provider value={contextValueRef.current}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
