import React, { ReactNode } from 'react'
import { ReactiveList as LibraryReactiveList } from '@appbaseio/reactivesearch'

import {
  GlobalPaginationStyles,
  paginationButtonClassName,
  paginationContainerClassName,
} from './paginationStyles'
import type { RenderProps } from './types'

interface Props<T> extends AnyObject {
  innerClass?: Record<string, unknown>
  render?: (renderProps: RenderProps<T>) => ReactNode
}

export function ReactiveList<T>({ innerClass = {}, ...props }: Props<T>) {
  const innerClassWithDefaults = {
    button: paginationButtonClassName,
    pagination: paginationContainerClassName,
    ...innerClass,
  }

  return (
    <>
      <GlobalPaginationStyles />
      {/* @ts-ignore */}
      <LibraryReactiveList {...props} innerClass={innerClassWithDefaults} />
    </>
  )
}
