import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  vatRate: number
  withMarginalTax: boolean
}

export const VatSection = ({ vatRate, withMarginalTax }: Props) => (
  <Wrapper alignItems="flex-start">
    <Title>
      <T>common:pricing.vatAmount</T>
    </Title>
    <Label alignItems="center">
      {withMarginalTax ? (
        <T>Products:ProductManager.pricing.marginal</T>
      ) : (
        `${vatRate} %`
      )}
    </Label>
  </Wrapper>
)

/////////

const Label = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(9.5)}rem;
  `}
`
