import type { SpacingOptions } from '../types'

export default function mapStrapiSpacingConfigToSpacingOptions(
  input: Record<string, unknown>
): SpacingOptions {
  const options: SpacingOptions = {}

  if (input.grid_unit_size_in_px)
    options.gridUnitSizeInPx = input.grid_unit_size_in_px as number

  return options
}
