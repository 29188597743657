import { InlineModalIconSection } from '@/components/InlineModal'
import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { SalesSelector } from '@/modules/Sales'

import { SalesType } from '~generated-types'

type Props = {
  closeSection: () => void
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const OwnerSearchManager = ({
  closeSection,
  reservationId,
  updateReservations,
}: Props) => {
  const { moveToSale } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const handleMoveToSale = (id: string) =>
    moveToSale(reservationId, id).finally(closeSection)

  return (
    <InlineModalIconSection icon="euro-sign">
      <SalesSelector
        onSelect={(id: string) => handleMoveToSale(id)}
        types={[SalesType.Event, SalesType.Sales]}
      />
    </InlineModalIconSection>
  )
}
