import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import type {
  ProductPurchaseDuration,
  ProductPurchaseReservation,
} from '@/modules/Products/types'

import { QuantityUnit } from '~generated-types'

import { DatesSelector } from '../DatesSelector'

type Props = {
  duration: ProductPurchaseDuration
  id: string
  quantityUnit: QuantityUnit
  readOnly: boolean
  reservation: ProductPurchaseReservation | null
}

export const ReservationSection = ({
  duration,
  id,
  quantityUnit,
  readOnly,
  reservation,
}: Props) => {
  const renderReservationName = () => {
    if (reservation) {
      const { resource, resourceQuantity } = reservation

      return (
        <Name>
          {resource?.isPooled && resourceQuantity > 1
            ? `${resourceQuantity} x `
            : ''}
          {resource?.name ?? <T>ResourceReservations:resource.program</T>}
        </Name>
      )
    }

    return null
  }

  return (
    <FlexRow flex={1}>
      {renderReservationName()}

      <Spacer />

      {quantityUnit !== QuantityUnit.Piece && (
        <DatesSelector
          id={id}
          duration={duration}
          quantityUnit={quantityUnit}
          readOnly={readOnly}
        />
      )}
    </FlexRow>
  )
}

/////////

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(24)}rem;
  `}
`

const Spacer = styled.div`
  flex: 1;
`
