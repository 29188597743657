import styled, { css } from 'styled-components/macro'

import { PrintTitle } from './PrintTitle'

export const PrintSubtitle = styled(PrintTitle)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    font-variant-caps: all-small-caps;
  `}
`
