import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

interface Props {
  visibleInPrint?: boolean
}

export const HeaderControls = styled(FlexRow)<Props>`
  flex: 0;
  align-items: flex-end;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gutter};
    `}
  }

  @media print {
    display: ${({ visibleInPrint }) =>
      visibleInPrint ? 'inline-block' : 'none'};
  }
`
