import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'

import { usePublishContext } from '../../../PublishState'
import { PublicRoom } from '../../../types'
import { Section } from '../../common'

type Props = {
  publicRoom: PublicRoom | null
  field: 'maxGuests' | 'minGuests'
  targetId: string
}

export const GuestAmountInput = ({ publicRoom, field, targetId }: Props) => {
  if (!publicRoom) {
    return (
      <Wrapper>
        <Placeholder>–</Placeholder>
      </Wrapper>
    )
  }

  const { readOnly, updatePublicRoom } = usePublishContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [value, setValue] = useState<number>(0)

  const roomId = publicRoom.id
  const prevValue = publicRoom[field] ?? 0

  useEffect(() => {
    setValue(prevValue)
  }, [publicRoom[field]])

  const handleUpdatePublicRoom = () => {
    const isMaxValid = !(field === 'maxGuests' && value < publicRoom.minGuests)
    const isMinValid = !(field === 'minGuests' && value > publicRoom.maxGuests)

    if (prevValue !== value && isMaxValid && isMinValid) {
      updatePublicRoom({
        roomId,
        targetId,
        update: { [field]: value },
      }).finally(() => setEditMode(false))
    } else {
      setValue(prevValue)
      setEditMode(false)
    }
  }

  return (
    <Wrapper>
      {isEditMode ? (
        <StyledInput
          autoFocus
          max={field === 'minGuests' ? publicRoom.maxGuests : 9999}
          min={field === 'maxGuests' ? publicRoom.minGuests : 1}
          onBlur={handleUpdatePublicRoom}
          onChange={(e) => setValue(Number(e.target.value))}
          onFocus={(e) => e.currentTarget.select()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleUpdatePublicRoom()
            }
          }}
          value={`${value}`}
          type="number"
        />
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          style={{ flex: 'unset' }}
        >
          {prevValue}
        </EditButton>
      )}
    </Wrapper>
  )
}

//////////

const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const StyledInput = styled(Input)`
  &:not([type='checkbox']):not([type='radio']) {
    height: 30px;
    border-radius: 4px;

    ${({ theme }) => css`
      margin-left: -${theme.spacing.gu(1)}rem;
      padding-left: calc(${theme.spacing.gu(1)}rem - 1px);
      min-width: calc(100% + ${theme.spacing.gu(2)}rem);
    `}
  }

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

const Wrapper = styled(Section)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
  `}
`
