import { ColorLightness } from '../enums'
import adjustLightness from './adjustLightness'
import correctSaturationForLightness from './correctSaturationForLightness'

export default function getColorVariant(
  color: string,
  target: ColorLightness
): string {
  const saturationCorrected = correctSaturationForLightness(color, target)

  return adjustLightness(saturationCorrected, target)
}
