import type { WebFont, WebFontConfiguration } from '../types'

export default function parseWebFontConfig(
  input: Record<string, unknown>
): WebFontConfiguration {
  const fonts: WebFont[] = []
  let typekitKitId: string | null = null

  if (input.typography) {
    const typographyOpts = input.typography as Record<string, unknown>

    if (typographyOpts.body_font_family) {
      const bodyFont = parseWebFont(
        typographyOpts.body_font_family as Record<string, unknown>
      )

      if (bodyFont) {
        fonts.push(bodyFont)
      }
    }

    if (typographyOpts.headline_font_family) {
      const headlineFont = parseWebFont(
        typographyOpts.headline_font_family as Record<string, unknown>
      )

      if (headlineFont) {
        fonts.push(headlineFont)
      }
    }

    if (typographyOpts.typekit_kit_id) {
      typekitKitId = typographyOpts.typekit_kit_id as string
    }
  }

  return {
    fonts,
    typekitKitId,
  }
}

////////////

const parseWebFont = (input: Record<string, unknown>): WebFont | null => {
  const service =
    input.service === 'google'
      ? 'google'
      : input.service === 'typekit'
      ? 'typekit'
      : null
  const value = input.font_family_value as string

  return service && value ? { service, value } : null
}
