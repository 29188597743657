import { gql } from '@apollo/client'

import { catalogFragments } from '@/modules/Registry/Catalog'

import productCategory from './productCategory'
import productPurchase from './productPurchase'
import salesProductItem from './salesProductItem'

export default gql`
  ${catalogFragments.catalog}
  ${productCategory}
  ${productPurchase}
  ${salesProductItem}

  fragment SalesProduct on SalesProduct {
    auditLog {
      createdAt
    }
    catalog {
      ...Catalog
    }
    categories {
      ...ProductCategory
    }
    group
    id
    items {
      ...SalesProductItem
    }
    name
    notes
    purchases {
      ...ProductPurchase
    }
    sales {
      id
    }
    settings {
      accommodationGroup {
        id
        name
        sortOrder
      }
      published
      description
      purchaseDates {
        end
        start
      }
    }
  }
`
