import styled, { css } from 'styled-components/macro'

import { H4 } from '@/components/Typography'

export const FormSectionTitle = styled(H4)`
  margin-top: 0;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}
`
