import { gql } from '@apollo/client'

export default gql`
  query SalesIssues($id: ID!) {
    salesIssues(id: $id) {
      accommodationIssues {
        code
        key
        level
      }
      reservationIssues {
        code
        key
        level
      }
      salesIssues {
        code
        key
        level
      }
    }
  }
`
