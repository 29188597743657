import { gql, useQuery } from '@apollo/client'

import type {
  OpenAdvancesQuery as QueryData,
  OpenAdvancesQueryVariables as QueryVariables,
} from '~generated-types'

export const QUERY = gql`
  query OpenAdvances($input: OpenAdvancesInput!) {
    openAdvances(input: $input) {
      amountWithoutServiceFeeOrVat
      customerName
      customerNumber
      invoiceDate
      invoiceId
      invoiceNumber
      salesEnd
      salesId
      salesNumber
      sellerShortName
    }
  }
`

type Params = {
  date: string
  skip: boolean
}

export const useOpenAdvances = ({ date, skip }: Params) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'no-cache',
    skip,
    variables: { input: { date } },
  })

  const openAdvances = data ? [...data.openAdvances] : []

  return {
    error,
    loading,
    openAdvances,
  }
}
