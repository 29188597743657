import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

export const ActionButton = styled.button<{
  background?: string
  isDisabled?: boolean
  isHover?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  border: none;
  cursor: pointer;
  font-weight: 500;
  height: 100%;
  width: 100%;
  transition: background 0.5s, color 0.5s;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.primary.dark};
    padding: 0 ${theme.spacing.gu(1)}rem;
  `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `} {
  }

  ${({ background, isDisabled, isHover, theme }) =>
    isHover &&
    css`
      background: ${background};
      color: ${theme.palette.white};

      ${isDisabled &&
      css`
        cursor: not-allowed;
        background: ${theme.palette.smoke.lighter};
        color: ${theme.palette.text.lighter};
      `} {
      }
    `};
`

export const Placeholder = styled.span`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
  `};
`

export const Section = styled(FlexRow)<{
  highlight?: boolean
  isLastLeft?: boolean
  isLastRight?: boolean
}>`
  height: 50px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
  `};

  ${({ highlight, theme }) =>
    highlight &&
    css`
      border: 1px solid ${theme.palette.primary.dark};
      box-shadow: 0 0 2px 0 ${theme.palette.primary.dark};
    `};

  ${({ isLastLeft }) =>
    isLastLeft &&
    css`
      border-bottom-left-radius: 6px;
    `};

  ${({ isLastRight }) =>
    isLastRight &&
    css`
      border-bottom-right-radius: 6px;
    `};
`

export const Separator = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `};
`
