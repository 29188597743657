import { gql } from '@apollo/client'

import { RoomReservationsForDateParticipantRoomFragment } from './roomReservationsForDateParticipantRoom'

export const RoomReservationsForDateRoomFragment = gql`
  ${RoomReservationsForDateParticipantRoomFragment}

  fragment RoomReservationsForDateRoom on Room {
    beds
    building {
      id
      name
    }
    extraBeds
    id
    number
    status {
      cleaningStatus
      occupants {
        ...RoomReservationsForDateParticipantRoom
      }
      reservationStatus
      statusCode
    }
  }
`
