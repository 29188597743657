import Iframe from 'react-iframe'
import styled from 'styled-components/macro'

type Props = {
  id: string
  url: string
}

export const ExternalPage = ({ id, url }: Props) => (
  <Wrapper>
    <Iframe url={url} id={id} />
  </Wrapper>
)

////////////

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  & > iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }
`
