import { gql, useMutation } from '@apollo/client'

import {
  SetParticipantCheckInMutation,
  SetParticipantCheckInMutationVariables,
} from '~generated-types'

import { RoomReservationFragment } from '../fragments'

export type {
  SetParticipantCheckInMutation,
  SetParticipantCheckInMutationVariables,
}

export const SET_PARTICIPANT_CHECK_IN = gql`
  ${RoomReservationFragment}

  mutation SetParticipantCheckIn($input: MarkCheckedInput!) {
    salesParticipantCheckIn(input: $input) {
      roomReservation {
        ...RoomReservationsForDateRoomReservation
      }
    }
  }
`

export function useSetParticipantCheckIn() {
  return useMutation<
    SetParticipantCheckInMutation,
    SetParticipantCheckInMutationVariables
  >(SET_PARTICIPANT_CHECK_IN)
}

export default useSetParticipantCheckIn
