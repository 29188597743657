import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { ListingTitle, PlaceholderLabel } from '@/modules/Listing/common'

import { PrintSize } from '../../types'
import { AllergyBy, ListType } from '../usePacketMealListControls'
import {
  ListingMealParticipant,
  ListingMealType,
  ListingRestaurant,
  ListingScheduledMeal,
} from '../usePacketMealsListing'
import { DailyAllergies } from './DailyAllergies'
import { DailyMeals } from './DailyMeals'

type Props = {
  data: {
    [restaurantKey: string]: {
      [mealKey: string]: ListingScheduledMeal[]
    }
  }
  date: Moment
  listType: ListType
  meals: ListingScheduledMeal[]
  mealTypes: {
    [mealKey: string]: ListingMealType
  }
  printSize: PrintSize
  restaurants: ListingRestaurant[]
  allergyBy: AllergyBy
}

export const DailyMealsContainer = ({
  data,
  date,
  meals,
  mealTypes,
  restaurants,
  printSize,
  listType,
  allergyBy,
}: Props) => {
  const dataCount = Object.keys(data).reduce((totalAcc, rKey) => {
    if (restaurants.find(({ id }) => id === rKey)) {
      totalAcc =
        totalAcc +
        Object.keys(data[rKey] || {}).reduce(
          (acc, mKey) => acc + ((data[rKey] || {})[mKey] || {}).length,
          0
        )
    }
    return totalAcc
  }, 0)

  const hasParticipantsWithAllergies = meals
    .reduce(
      (acc: ListingMealParticipant[], meal) => [...acc, ...meal.participants],
      []
    )
    .some(
      (p) => !!p.allergies.length || p.allergyDescription || !!p.diets.length
    )

  return dataCount ? (
    <Wrapper>
      {listType !== 'ALLERGY' && (
        <DailyMeals
          data={data}
          mealTypes={mealTypes}
          printSize={printSize}
          restaurants={restaurants}
          date={date}
          listType={listType}
        />
      )}

      {(listType === 'ALL' || listType === 'ALLERGY') &&
        (hasParticipantsWithAllergies ? (
          <DailyAllergies
            data={data}
            mealTypes={mealTypes}
            printSize={printSize}
            restaurants={restaurants}
            date={date}
            allergyBy={allergyBy}
          />
        ) : (
          <>
            <Title isFirst style={{ pageBreakAfter: 'avoid' }}>
              <T>Listings:PacketMealListing.field.allergies</T>
              {' – '}
              {date.format('dd D.M.YYYY')}
            </Title>
            <Placeholder>
              <T>Listings:PacketMealListing.emptyAllergies</T>
            </Placeholder>
          </>
        ))}
    </Wrapper>
  ) : null
}

////////////

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(3)}rem;
  `}
`

const Title = styled(ListingTitle)<{ isFirst?: boolean }>`
  ${({ isFirst }) => css`
    break-before: ${isFirst ? 'auto' : 'page'};
  `}

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(3)}rem;
  `}

  @media print {
    margin: 0;
  }
`

const Placeholder = styled(PlaceholderLabel)`
  @media print {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(3)}rem;
    `}
  }
`
