import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { T } from '@/modules/Language'
import { Theme, useTheme } from '@/theme'

import { IconWrapper } from './common'

type Props = {
  checkOut?: string | null
  disabled: boolean
  handleSetCheckOut: (providedTime?: string | null) => Promise<any>
  isAvailable?: boolean
  isValid: boolean
}

export const ParticipantCheckOut = ({
  checkOut,
  disabled,
  handleSetCheckOut,
  isAvailable = true,
  isValid,
}: Props) => {
  const theme = useTheme()
  const { timezone } = useWillbaConfigContext()

  const [isHover, setHover] = useState<boolean>(false)

  const config = { theme, timezone }
  const readOnly = !isAvailable || disabled

  const checkOutColor =
    !isAvailable || (isHover && !isValid)
      ? theme.palette.smoke.dark
      : theme.palette.gold

  const undoCheckOutColor = isHover
    ? theme.palette.danger.main
    : theme.palette.success.main

  const handleCheckOut = () =>
    !readOnly &&
    isValid &&
    handleSetCheckOut(moment().tz(timezone).format('YYYY-MM-DDTHH:mm:ss')).then(
      () => setHover(false)
    )

  const handleUndoCheckOut = () =>
    !readOnly && handleSetCheckOut(null).then(() => setHover(false))

  const renderUndoCheckOutIcon = () => (
    <IconWrapper
      disabled={readOnly}
      onClick={handleUndoCheckOut}
      onMouseEnter={() => !readOnly && setHover(true)}
      onMouseLeave={() => !readOnly && setHover(false)}
      borderColor={undoCheckOutColor}
    >
      <FontAwesomeIcon
        icon={isHover ? 'xmark' : 'check'}
        color={undoCheckOutColor}
      />
    </IconWrapper>
  )

  const renderCheckOutIcon = () => (
    <IconWrapper
      disabled={readOnly || !isValid}
      onClick={handleCheckOut}
      onMouseEnter={() => !readOnly && setHover(true)}
      onMouseLeave={() => !readOnly && setHover(false)}
      borderColor={checkOutColor}
    >
      <FontAwesomeIcon
        icon={isHover ? 'check' : 'right-from-bracket'}
        color={checkOutColor}
      />
    </IconWrapper>
  )

  return (
    <FlexRow alignItems="center">
      {checkOut ? (
        <Tooltip
          content={getCheckOutLabel({ checkOut, ...config })}
          maxWidth={180}
          trigger={(triggerProps) => (
            <div {...triggerProps} style={{ margin: 'auto' }}>
              {renderUndoCheckOutIcon()}
            </div>
          )}
        />
      ) : disabled ? (
        renderCheckOutIcon()
      ) : (
        <Tooltip
          content={getCheckOutLabel({ isAvailable, isValid, ...config })}
          maxWidth={180}
          trigger={(triggerProps) => (
            <div {...triggerProps} style={{ margin: 'auto' }}>
              {renderCheckOutIcon()}
            </div>
          )}
        />
      )}
    </FlexRow>
  )
}

////////

type CheckOutLabelProps = {
  checkOut?: string
  isAvailable?: boolean
  isValid?: boolean
  timezone: string
  theme: Theme
}

const getCheckOutLabel = ({
  checkOut,
  isAvailable = true,
  isValid = true,
  theme,
  timezone,
}: CheckOutLabelProps) => {
  if (!isAvailable) {
    return <T>ParticipantsList:ParticipantFormFields.noRoomWarning</T>
  }

  if (!isValid) {
    return <T>ParticipantsList:ParticipantFormFields.checkOutValidation</T>
  }

  return (
    <FlexColumn noPadding alignItems="flex-start">
      <span style={{ marginBottom: theme.spacing.gutterSmall }}>
        <T>{`ParticipantsList:ParticipantFormFields.${
          checkOut ? 'checkedOutAt' : 'setCheckOutTo'
        }`}</T>
      </span>
      <span>
        {checkOut
          ? moment(checkOut).format('DD.MM.YYYY HH:mm')
          : moment().tz(timezone).format('DD.MM.YYYY HH:mm')}
      </span>
    </FlexColumn>
  )
}
