import styled, { css } from 'styled-components/macro'

const TreeNodeButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeSmall};
    font-family: ${theme.typography.fontFamily};
    margin-left: -${theme.spacing.gu(1)}rem;
  `}

  font-weight: 500;
  text-decoration: none;

  background: transparent;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
`

export default TreeNodeButton
