import { useEffect, useState } from 'react'
import moment, { HTML5_FMT, Moment } from 'moment'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { Input, Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { DatePicker } from '@/components/TimeControls'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  HideFromPrintSpan,
  ListingLayout,
  PlaceholderLabel,
} from '@/modules/Listing/common'
import { useTheme } from '@/theme'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { PrintSize } from '../types'
import AccommodationGuestsTable from './components/AccommodationGuestsTable'
import { SortOrder } from './types'
import { AccommodationGuest } from './useAccommodationGuests'

interface Props {
  date: Moment
  error: boolean
  guests: AccommodationGuest[]
  loading: boolean
  searchValue: string
  setDate: (targetDate: Moment) => void
  setSearchValue: (value: string) => void
  setSortOrder: (targetSortOrder: SortOrder) => void
  sortOrder: SortOrder
}

export const AccommodationGuestListing = ({
  date,
  error,
  guests,
  loading,
  searchValue,
  setDate,
  setSearchValue,
  setSortOrder,
  sortOrder,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [datesLabel, setDatesLabel] = useState<string>()

  const dateValue = date.valueOf()

  useEffect(() => {
    const from = moment(dateValue)
    const to = moment(dateValue).add(1, 'days')

    setDatesLabel(
      translate('Listings:AccommodationGuestListing.nightOf', language, {
        days: formatDateRangeWithoutRepetition(
          from.format(HTML5_FMT.DATE),
          to.format(HTML5_FMT.DATE)
        ),
      })
    )
  }, [dateValue, language])

  const renderContent = (printSize: PrintSize) => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <PlaceholderLabel>
          <T>Listings:AccommodationGuestListing.error</T>
        </PlaceholderLabel>
      )
    }

    if (!guests.length) {
      return (
        <PlaceholderLabel>
          <T>Listings:AccommodationGuestListing.empty</T>
        </PlaceholderLabel>
      )
    }

    return (
      <AccommodationGuestsTable
        data={guests}
        printSize={printSize}
        sortOrder={sortOrder}
      />
    )
  }

  const renderSidebar = () => (
    <>
      <FilterSection
        label={<T>Listings:AccommodationGuestListing.filter.search</T>}
        render={() => (
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value || '')
            }
            type="text"
            value={searchValue}
          />
        )}
      />
      <FilterSection
        label={<T>Listings:AccommodationGuestListing.filter.date</T>}
        render={() => (
          <div style={{ margin: `-${theme.spacing.gu(1)}rem` }}>
            <DatePicker
              setValue={(targetDate) => setDate(targetDate || moment())}
              value={date}
            />
          </div>
        )}
      />
    </>
  )

  const renderSortToggle = () => (
    <>
      <FlexColumn alignItems="flex-start">
        <Label style={{ marginRight: `${theme.spacing.gu(1)}rem` }}>
          <T>Listings:AccommodationGuestListing.sortOrder.label</T>
        </Label>
        <select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const targetSortOrder =
              e.target.value === SortOrder.ROOM
                ? SortOrder.ROOM
                : SortOrder.PERSON

            setSortOrder(targetSortOrder)
          }}
          value={sortOrder}
        >
          <option value={SortOrder.PERSON}>
            {translate(
              'Listings:AccommodationGuestListing.sortOrder.PERSON',
              language
            )}
          </option>
          <option value={SortOrder.ROOM}>
            {translate(
              'Listings:AccommodationGuestListing.sortOrder.ROOM',
              language
            )}
          </option>
        </select>
      </FlexColumn>

      <HideFromPrintSpan style={{ marginLeft: theme.spacing.gutter }} />
    </>
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderHeaderControls={renderSortToggle}
      renderSidebar={renderSidebar}
      title={
        <>
          <T>Listings:AccommodationGuestListing.title</T> |{' '}
          <small>{datesLabel}</small>
        </>
      }
    />
  )
}

export default AccommodationGuestListing
