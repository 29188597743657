import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

export const Section = styled(FlexColumn)<{ flex?: number }>`
  flex: ${({ flex }) => flex ?? '1 1 auto'};
  overflow: hidden;

  ${({ theme }) => `
    padding: ${theme.spacing.gutter};
  `};
`
