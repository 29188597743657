import { CategorySet } from '@/modules/Registry/Category'

type PathsByName = {
  [name: string]: string
}

export const getPathsByNames = (sets: CategorySet[]): PathsByName => {
  const paths: PathsByName = {}

  const processCategories = (
    prevPath: string,
    categories: {
      [key: string]: any
    }[]
  ) => {
    if (Array.isArray(categories)) {
      categories.forEach((x) => {
        const name = typeof x.name === 'string' ? x.name.trim() : null

        if (name) {
          const path = `${prevPath}/${x.id}`
          paths[name] = path
          processCategories(path, x.children)
        }
      })
    }
  }

  sets.forEach((set) => {
    processCategories('', set.rootCategories)
  })

  return paths
}
