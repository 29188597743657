import { gql, useMutation } from '@apollo/client'

import {
  UpdateSalesNameMutation,
  UpdateSalesNameMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation UpdateSalesName($input: SalesUpdateNameInput!) {
    salesUpdateName(input: $input) {
      id
      name
    }
  }
`

export const useUpdateSalesNameMutation = () =>
  useMutation<UpdateSalesNameMutation, UpdateSalesNameMutationVariables>(
    MUTATION
  )
