import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'

export const NewParticipantsDivider = () => (
  <Divider>
    <span>
      <T>ParticipantsList:newParticipants</T>
    </span>
  </Divider>
)

/////////

const Divider = styled.div`
  position: relative;
  text-align: center;
  margin: ${({ theme }) => theme.spacing.gu(2)}rem 0;

  & > span {
    z-index: 1;
    position: relative;
    padding: 10px;
    font-weight: 500;

    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.extraLight};
      font-size: ${theme.typography.fontSizeBase2};
    `}
  }

  &:before,
  &:after {
    display: block;
    content: '';
    height: 2px;
    width: 50%;
    position: absolute;
    top: 50%;
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  &:after {
    right: 0;
  }
`
