import { gql, useMutation } from '@apollo/client'

import type {
  UpdateSalesProductMutation,
  UpdateSalesProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.salesProduct}

  mutation UpdateSalesProduct($input: SalesProductUpdateInput!) {
    salesProductUpdate(input: $input) {
      ...SalesProduct
    }
  }
`

export const useUpdateSalesProductMutation = () =>
  useMutation<UpdateSalesProductMutation, UpdateSalesProductMutationVariables>(
    MUTATION
  )
