import styled from 'styled-components/macro'

import type { PropsWithTheme } from '@/theme'

type Props = {
  base?: boolean
  dark?: boolean
  light?: boolean
  lighter?: boolean
  white?: boolean
}

const findColor = ({
  base,
  dark,
  light,
  lighter,
  theme,
  white,
}: PropsWithTheme<Props>): string => {
  if (base) return theme.palette.text.main
  if (dark) return theme.palette.text.dark
  if (light) return theme.palette.text.light
  if (lighter) return theme.palette.text.lighter
  if (white) return theme.palette.text.white
  return 'inherit'
}

const FontColor = styled.span<Props>`
  color: ${(props) => findColor(props)};
`

export default FontColor
