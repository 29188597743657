import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

export const DeleteIcon = styled(FontAwesomeIcon)`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    margin-left: ${theme.spacing.gu(1)}rem;

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.smoke.extraDark};
      `}
    }
  `}
`

export const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`
