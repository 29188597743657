import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { AgeReference } from '@/modules/ParticipantsList'
import { ageCategoryHooks } from '@/modules/Registry/AgeCategory'
import { generateCompareFn } from '@/utils/arrays'

import { AgeField, SalesParticipantFullFragment } from '~generated-types'

type ParticipantAgeCategory = SalesParticipantFullFragment['ageCategory']

type Props = {
  ageCategory: ParticipantAgeCategory | null
  ageSource: AgeField | null
  handleSubmit: (value: AgeReference) => Promise<any>
}

export const AgeCategorySelector = ({
  ageCategory,
  ageSource,
  handleSubmit,
}: Props) => {
  const { ageCategories } = ageCategoryHooks.useAgeCategories()

  const ageCategoryOptions = [
    ...[...ageCategories]
      .sort(generateCompareFn('sortOrder'))
      .map((category) => ({
        label: category.name,
        value: category.key,
      })),
  ]

  const handleSelect = (selectedValue: Option | undefined | null) => {
    if (selectedValue?.value !== ageCategory?.key) {
      handleSubmit({
        age: null,
        ageCategoryKey: selectedValue?.value || null,
        birthday: null,
      })
    }
  }

  const disabled = !!ageSource && ageSource !== AgeField.AgeCategory

  return (
    <>
      <Label>
        <T>ParticipantsList:ParticipantFormFields.ageCategory</T>
      </Label>
      <SelectWrapper>
        <ThemedSelect
          isCompact
          isClearable
          isDisabled={disabled}
          name="language-selector"
          onChange={handleSelect}
          options={ageCategoryOptions as Option[]}
          placeholder=""
          value={
            ageCategory
              ? {
                  label: ageCategory.name,
                  value: ageCategory.key,
                }
              : null
          }
        />
      </SelectWrapper>
    </>
  )
}

const SelectWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`
