import styled from 'styled-components/macro'

export const HideFromPrintSpan = styled.span`
  display: inline;

  @media print {
    display: none;
  }
`

export default HideFromPrintSpan
