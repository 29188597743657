import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

const ListHeader = () => (
  <Wrapper>
    <Section>
      <T>InvoicesSearchList:ListHeader.number</T>
    </Section>
    <Section />
    <Section align="center">
      <T>InvoicesSearchList:ListHeader.invoiceDate</T>
    </Section>
    <Section>
      <T>InvoicesSearchList:ListHeader.total</T>
    </Section>
    <Section>
      <T>InvoicesSearchList:ListHeader.customer</T>
    </Section>
    <Section>
      <T>InvoicesSearchList:ListHeader.sales</T>
    </Section>
    <Section>
      <T>InvoicesSearchList:ListHeader.event</T>
    </Section>
  </Wrapper>
)

export default ListHeader
