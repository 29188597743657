import { useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import ReactLoading from 'react-loading'
import VisibilitySensor from 'react-visibility-sensor'
import styled, { css } from 'styled-components/macro'

import { Toggle } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { H3 } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  FetchedParticipant,
  ParticipantSkeletonData,
  ParticipantsListQueries,
  useParticipantsListContext,
  useSaleParticipants,
} from '@/modules/ParticipantsList'
import { RoomPlaceholder } from '@/modules/ParticipantsList/components/Placeholders'
import { useTheme } from '@/theme'

import {
  SaleAccommodationBaseQuery,
  SaleAccommodationBaseQueryVariables,
  SaleAccommodationQuery,
  SaleAccommodationQueryVariables,
  SuitableFilter,
} from '~generated-types'

import { MessageWrapper } from '../../components/common'
import subscriptions from '../../ParticipantsList.subscriptions'
import Room from './Room'

type Props = {
  ownerId: string
  participants: (FetchedParticipant | ParticipantSkeletonData)[]
  readOnly?: boolean
}

const RoomsListContainer = ({ ownerId, participants, readOnly }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const { handleDeleteRoom } = useSaleParticipants()
  const { refetchAccommodationTick } = useParticipantsListContext()

  const [isAllExpanded, setAllExpanded] = useState<boolean>(true)
  const [expandedCounter, setExpandedCounter] = useState<number>(0)
  const [roomsList, setRoomsList] = useState<
    SaleAccommodationQuery['suitableRooms'][0]['roomReservation'][]
  >([])
  const [baseRoomsList, setBaseRoomsList] = useState<
    SaleAccommodationBaseQuery['suitableRooms'][0]['roomReservation'][]
  >([])

  const { data: baseData, loading: baseLoading } = useQuery<
    SaleAccommodationBaseQuery,
    SaleAccommodationBaseQueryVariables
  >(ParticipantsListQueries.SUITABLE_ROOMS_BASE, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        filter: SuitableFilter.Default,
        salesId: ownerId,
      },
    },
  })

  const {
    data,
    error,
    refetch: refetchRooms,
  } = useQuery<SaleAccommodationQuery, SaleAccommodationQueryVariables>(
    ParticipantsListQueries.SUITABLE_ROOMS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          filter: SuitableFilter.Default,
          salesId: ownerId,
        },
      },
    }
  )

  useEffect(() => {
    const rooms = data?.suitableRooms
      ? data.suitableRooms
          .map(({ roomReservation }) => roomReservation)
          .sort((a, b) =>
            a.request.room.number.localeCompare(
              b.request.room.number,
              undefined,
              {
                numeric: true,
              }
            )
          )
      : []

    setRoomsList(rooms)
    setExpandedCounter(rooms.length)
  }, [data])

  useEffect(() => {
    const rooms = baseData?.suitableRooms
      ? baseData.suitableRooms
          .map(({ roomReservation }) => roomReservation)
          .sort((a, b) =>
            a.request.room.number.localeCompare(
              b.request.room.number,
              undefined,
              {
                numeric: true,
              }
            )
          )
      : []

    setBaseRoomsList(rooms)
    setExpandedCounter(rooms.length)
  }, [baseData])

  useSubscription(subscriptions.ROOMS, {
    onData({ data: { data } }) {
      data.accommodationReservation.event.__typename === 'RoomEvent' &&
        refetchRooms()
    },
    variables: {
      filter: {
        salesId: ownerId,
      },
    },
  })

  useEffect(() => {
    isAllExpanded &&
      expandedCounter !== roomsList.length &&
      setAllExpanded(true)
  }, [roomsList.length])

  useEffect(() => {
    refetchAccommodationTick > 0 && refetchRooms()
  }, [refetchAccommodationTick])

  const onDeleteRoom = (id: string) =>
    handleDeleteRoom(id).then((res) => {
      res &&
        res?.deleted &&
        setRoomsList((current) => current.filter((room) => room.id !== id))
    })

  return (
    <FlexColumn noPadding>
      {baseLoading ? (
        <StyledSpinner
          color={palette.smoke.main}
          height={28}
          type={'spin'}
          width={28}
        />
      ) : roomsList.length > 0 || baseRoomsList.length > 0 ? (
        <>
          <FlexRow
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            style={{ marginTop: `${spacing.gu(1)}rem` }}
          >
            <Title>
              <T>ParticipantsList:ListHeader.rooms</T>
            </Title>

            {roomsList?.length > 1 && (
              <ExpandAllWrapper>
                <Toggle
                  checked={isAllExpanded}
                  noMargin
                  onChange={() => {
                    if (isAllExpanded) {
                      setExpandedCounter(0)
                      setAllExpanded(false)
                    } else {
                      setExpandedCounter(roomsList.length)
                      setAllExpanded(true)
                    }
                  }}
                  onLabel={translate(
                    'ParticipantsList:ListView.expandAllRooms',
                    language
                  )}
                  offLabel={translate(
                    'ParticipantsList:ListView.expandAllRooms',
                    language
                  )}
                />
              </ExpandAllWrapper>
            )}
          </FlexRow>
          {!error ? (
            <FlexColumn>
              {(!roomsList.length ? baseRoomsList : roomsList).map((room) => (
                <VisibilitySensor
                  key={`room-list-${room.id}`}
                  partialVisibility
                  offset={{ bottom: -200, top: -200 }}
                >
                  {({ isVisible }) =>
                    isVisible &&
                    // @ts-ignore
                    room?.request?.checkIn ? (
                      <Room
                        // @ts-ignore
                        room={room}
                        participants={participants}
                        isAllExpanded={isAllExpanded}
                        readOnly={readOnly}
                        updateExpandedCounter={(value) => {
                          const newCounter = expandedCounter + value

                          newCounter === 0
                            ? setAllExpanded(false)
                            : newCounter === roomsList.length &&
                              setAllExpanded(true)

                          setExpandedCounter(newCounter)
                        }}
                        handleDeleteRoom={onDeleteRoom}
                      />
                    ) : (
                      <RoomPlaceholder
                        room={room}
                        isAllExpanded={isAllExpanded}
                      />
                    )
                  }
                </VisibilitySensor>
              ))}
            </FlexColumn>
          ) : (
            <T>common:error.common</T>
          )}
        </>
      ) : (
        roomsList.length === 0 && (
          <MessageWrapper
            message={<T>ParticipantsList:ParticipantRooms.noRooms</T>}
          />
        )
      )}
    </FlexColumn>
  )
}

export default RoomsListContainer

const StyledSpinner = styled(ReactLoading)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem auto;
  `}
`

const ExpandAllWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1px;
  cursor: pointer;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem ${theme.spacing.gu(1)}rem;
  `}

  &:hover > button {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
    `}
  }
`

const Title = styled(H3)`
  font-variant: all-small-caps;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    line-height: ${theme.spacing.gu(4)}rem;
  `}
`
