import { Ref, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { orderQueries } from '@/modules/Order/queries'
import { orderServices } from '@/modules/Order/services'
import {
  OrderLifecycle,
  OrderLifecycleQuery,
  OrderLifecycleQueryVariables,
} from '@/modules/Order/types'

import { OrderAction as OA } from '~generated-types'

import { Action } from '../../Action'

type Props = {
  orderId: string
}

export const ActionsButton = ({ orderId }: Props) => {
  const { removeOrder } = orderServices.orderService()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [lifecycle, setLifecycle] = useState<OrderLifecycle | null>(null)
  const [processingTarget, setProcessingTarget] = useState<OA | null>(null)

  const [loadLifecycle, { data }] = useLazyQuery<
    OrderLifecycleQuery,
    OrderLifecycleQueryVariables
  >(orderQueries.ORDER_LIFECYCLE, {
    fetchPolicy: 'no-cache',
    variables: { id: orderId },
  })

  useEffect(() => {
    if (data) {
      setLifecycle(data.order.lifecycle)
      setModalOpen(true)
    }
  }, [data])

  const handleDeleteOrder = () => {
    setProcessingTarget(OA.Delete)
    removeOrder(orderId).then((isRemoved) => {
      if (!isRemoved) {
        setProcessingTarget(null)
        setModalOpen(false)
      }
    })
  }

  const renderActions = ({ validatedActions: actions }: OrderLifecycle) => {
    const deleteAction = actions.find((a) => a.action === OA.Delete)

    return !deleteAction ? (
      <Placeholder>
        <T>Orders:OrdersForOwner.noActions</T>
      </Placeholder>
    ) : (
      <FlexColumn noPadding>
        {deleteAction && (
          <Action
            isProcessing={processingTarget === deleteAction.action}
            onClick={handleDeleteOrder}
            phase="ORDER"
            validatedAction={deleteAction}
          />
        )}
      </FlexColumn>
    )
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={<Modal>{lifecycle && renderActions(lifecycle)}</Modal>}
      onClose={() => setModalOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Button
          innerRef={ref as Ref<HTMLButtonElement> | undefined}
          noNudge
          onClick={() => loadLifecycle()}
        >
          <FontAwesomeIcon icon="ellipsis-vertical" />
        </Button>
      )}
    />
  )
}

/////

const Button = styled(InnocuousButton)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(5)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}
`
