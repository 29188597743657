import { gql, useMutation } from '@apollo/client'

import {
  AccommodationIssueFragment,
  RoomReservationFragment,
  RoomTypeReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

import {
  AccommodationDateOffsetMutation,
  AccommodationDateOffsetMutationVariables,
} from '~generated-types'

export type {
  AccommodationDateOffsetMutation,
  AccommodationDateOffsetMutationVariables,
}

export const ACCOMMODATION_DATE_OFFSET = gql`
  ${AccommodationIssueFragment}
  ${RoomReservationFragment}
  ${RoomTypeReservationFragment}

  mutation AccommodationDateOffset($input: AccommodationUpdateAllInput!) {
    accommodationUpdateAll(input: $input) {
      ... on AccommodationUpdateAllPayloadOk {
        roomReservations {
          ...RoomReservation
        }
        roomTypeReservations {
          ...RoomTypeReservation
        }
      }
      ... on AccommodationUpdateAllError {
        issues {
          key
          code
          level
        }
      }
    }
  }
`

export function useAccommodationDateOffset() {
  return useMutation<
    AccommodationDateOffsetMutation,
    AccommodationDateOffsetMutationVariables
  >(ACCOMMODATION_DATE_OFFSET)
}

export default useAccommodationDateOffset
