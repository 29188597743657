import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useSubscription } from '@apollo/client'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ContentSection } from '@/modules/Sales/components/SalesDetails/components/ContentSection'
import { salesHooks } from '@/modules/Sales/hooks'
import { salesSubscriptions } from '@/modules/Sales/subscriptions'
import { useTheme } from '@/theme'

import { useSalesDimensions } from '../../hooks'
import { SalesDimension } from '../../types'
import { Dimension, DimensionHierarchy, DimensionShortcut } from './components'

export const Dimensions = () => {
  const { palette } = useTheme()

  const {
    data: { id: salesId },
    saleReadOnly: readOnly,
  } = salesHooks.useSalesDetailsContext()

  const [editMode, setEditMode] = useState<boolean>(false)

  const {
    dimensions: dimensionsArr,
    error,
    loading,
  } = useSalesDimensions({ salesId })

  useSubscription(salesSubscriptions.SALES, {
    variables: { filter: { salesId } },
  })

  useEffect(() => {
    readOnly && setEditMode(false)
  }, [readOnly])

  const dimensions = useMemo(
    () =>
      [...dimensionsArr].sort((a, b) => {
        const aKey = a.dimension.sortOrder
        const bKey = b.dimension.sortOrder

        if (aKey < bKey) {
          return -1
        }

        if (aKey > bKey) {
          return 1
        }

        return 0
      }),
    [dimensionsArr]
  )

  const hierarchyDimensions = useMemo(() => {
    const dimensionsWithHierarchyRoot = dimensions.filter(
      (d: SalesDimension) => !!d.dimension.dimensionHierarchyRoot
    )

    if (dimensionsWithHierarchyRoot.length > 1) {
      return dimensionsWithHierarchyRoot.filter(
        (d: SalesDimension) =>
          d.dimension.id === d.dimension.dimensionHierarchyRoot &&
          (editMode ? true : !!d.selectedLabel || !!d.dimension.required)
      )
    }

    return []
  }, [dimensions, editMode])

  const simpleDimensions = useMemo(
    () =>
      dimensions.filter(
        (d: SalesDimension) =>
          (hierarchyDimensions.length === 0 ||
            !d.dimension.dimensionHierarchyRoot) &&
          (editMode ? true : !!d.selectedLabel || !!d.dimension.required)
      ),
    [dimensions, editMode]
  )

  useEffect(() => {
    dimensions.length > 0 &&
      hierarchyDimensions.length + simpleDimensions.length === 0 &&
      !readOnly &&
      setEditMode(true)
  }, [hierarchyDimensions, simpleDimensions, dimensions, readOnly])

  const renderContent = () => {
    // Fetching
    if (loading) {
      return (
        <ReactLoading
          color={palette.smoke.main}
          height={24}
          type="spin"
          width={24}
        />
      )
    }

    // Error
    if (error) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.errorDimensions</T>
        </Placeholder>
      )
    }

    return (
      <ContentSection
        title={<T>SalesDetails:dimensions.dimensions</T>}
        headerExtra={
          <FlexRow>
            <DimensionShortcut salesId={salesId} readOnly={readOnly} />

            <ButtonGroup>
              <ButtonGroupButton
                disabled={readOnly}
                noNudge
                onClick={() => setEditMode(false)}
                selected={!editMode}
                size="small"
              >
                <T>SalesDetails:meals.action.view</T>
              </ButtonGroupButton>
              <ButtonGroupButton
                disabled={readOnly}
                noNudge
                onClick={() => setEditMode(true)}
                selected={editMode}
                size="small"
              >
                <T>common:action.edit</T>
              </ButtonGroupButton>
            </ButtonGroup>
          </FlexRow>
        }
        flex={3}
      >
        {hierarchyDimensions.length + simpleDimensions.length === 0 ? (
          <FlexRow justifyContent="center">
            <Placeholder>
              <T>SalesDetails:dimensions.error.emptyDimensions</T>
            </Placeholder>
          </FlexRow>
        ) : (
          <DimensionsWrapper wrap="wrap">
            {hierarchyDimensions.map((dimension) => (
              <DimensionHierarchy
                dimension={dimension}
                dimensions={dimensions}
                disabled={readOnly}
                readOnly={!editMode}
                key={dimension.dimension.id}
                salesId={salesId}
              />
            ))}
            {simpleDimensions.map((dimension) => (
              <Dimension
                dimension={dimension}
                dimensions={dimensions}
                key={dimension.dimension.id}
                salesId={salesId}
                disabled={readOnly}
                readOnly={!editMode}
              />
            ))}
          </DimensionsWrapper>
        )}
      </ContentSection>
    )
  }

  return <Wrapper>{renderContent()}</Wrapper>
}

//////

type PlaceholderProps = {
  children: ReactNode
}

const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </Gutter>
)

const PlaceholderLabel = styled.div`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Wrapper = styled.div`
  width: 100%;
`

const DimensionsWrapper = styled(FlexRow)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
