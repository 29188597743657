import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import { Cell } from '../Cell'
import { FormatNumberInput } from './FormatNumberInput'

export const Reward = () => {
  const {
    data: { id: salesId, vst, type },
    setVstAttributes,
    refresh,
  } = salesHooks.useSalesDetailsContext()

  const otherLessonFee = vst?.otherLesson.fee || 0
  const participantLessonFee = vst?.participantLessonSummary.fee || 0

  const handleSetLessonFee = (value: string | null) =>
    setVstAttributes({
      otherLessonFee: Number(value || 0) || null,
      salesId,
    }).then(refresh)

  return (
    <FlexColumn alignItems="flex-start" noPadding>
      <Title>
        <T>ParticipantsList:VST.reward</T>
      </Title>
      <FlexRow>
        <Cell justifyContent="flex-start" width={12}>
          <T>ParticipantsList:participant</T>
        </Cell>
        <FlexRow>
          <Cell justifyContent="flex-end" width={10}>
            {participantLessonFee} €
          </Cell>
        </FlexRow>
      </FlexRow>
      {type === 'ENROLLMENT' ? (
        otherLessonFee && (
          <Cell justifyContent="flex-start" width={22}>
            <Cell justifyContent="flex-start" width={12}>
              <T>ParticipantsList:VST.other</T>
            </Cell>

            <Cell justifyContent="flex-end" width={10}>
              {otherLessonFee} €
            </Cell>
          </Cell>
        )
      ) : (
        <FormatNumberInputWrapper>
          <FormatNumberInput
            value={(otherLessonFee || '').toString()}
            handleSubmit={handleSetLessonFee}
            endAdornment={<>€</>}
            placeholder={<T>SalesDetails:VST.setOtherRewards</T>}
          />
        </FormatNumberInputWrapper>
      )}
    </FlexColumn>
  )
}

/////////

const FormatNumberInputWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
