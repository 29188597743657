/* eslint-disable @typescript-eslint/no-var-requires */
// @ts-ignore
const req = require.context('../', true, /i18n\/.+\.yml$/)
const addTranslations = require('@/modules/Language').addI18nResources

console.debug(`Adding ${req.keys().length} translation files to the bundle...`)

req.keys().forEach(function (key: any) {
  addTranslations(req(key))
})

console.debug('Translation files added!')
