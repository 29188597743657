import { Fragment } from 'react'

import {
  AvailabilityByDate,
  CellStatus,
  EmphasisByDate,
} from '../../AvailabilityCalendar.types'
import { DataCell, ScrollTableProps } from './ScrollTable'

type Props = ScrollTableProps & {
  emphasis: EmphasisByDate | null | undefined
  mode: 'confirmed' | 'tentative'
  property: 'beds' | 'extraBeds' | 'rooms'
  status: AvailabilityByDate
}

export const BooleanStatusRow = ({
  emphasis,
  mode,
  property,
  status,
  ...tableProps
}: Props) => {
  const { columnGroups } = tableProps

  return (
    <>
      {columnGroups.map(({ items, key: weekKey }, weekIdx) => (
        <Fragment key={`data-${weekKey}`}>
          {items.map(({ key: dateKey }, dateIdx) => {
            const vacantRooms = status[dateKey]?.[mode]?.[property] ?? 0
            const isVacant = vacantRooms > 0
            const isClosed = status[dateKey]?.totalCapacity?.[property] === 0
            const isLastCell =
              weekIdx === columnGroups.length - 1 &&
              dateIdx === items.length - 1

            return (
              <DataCell
                depth={3}
                emphasis={emphasis?.[dateKey]}
                isLast={isLastCell}
                key={`data-${dateKey}-${property}`}
                status={
                  isClosed
                    ? CellStatus.UNAVAILABLE
                    : isVacant
                    ? CellStatus.VACANT
                    : CellStatus.OCCUPIED
                }
                {...tableProps}
              />
            )
          })}
        </Fragment>
      ))}
    </>
  )
}
