import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useParticipantsListContext } from '@/modules/ParticipantsList'

import { SalesParticipantFullFragment } from '~generated-types'

import { CheckContainer } from './CheckContainer'
import { DateSelector } from './DateSelector'

type Props = {
  selectedIds: string[]
}

export const CheckInOut = ({ selectedIds }: Props) => {
  const { participants, handleSetCheckIn, handleSetCheckOut } =
    useParticipantsListContext()

  const datesSet: Set<string> = new Set()

  participants.forEach((participant) => {
    participant.services.forEach((service) => {
      if (
        service.__typename === 'ServicePurchase' ||
        !service.participantRoom ||
        !service.dates
      )
        return

      datesSet.add(service.dates.checkIn.date)
      datesSet.add(service.dates.checkOut.date)
    })
  })

  const dates = Array.from(datesSet).sort()

  const firstSelectedParticipantDate = participants
    .find(({ id }) => id === selectedIds[0])
    ?.services.map(
      (service) =>
        service.__typename === 'ServiceParticipantBed' &&
        service.dates?.checkIn.date
    )
    .filter(Boolean)[0]

  const [checkIn, setCheckIn] = useState<string | null>(null)
  const [checkOut, setCheckOut] = useState<string | null>(null)
  const [dateToUpdate, setDateToUpdate] = useState(
    firstSelectedParticipantDate || null
  )

  const getRoomIdsForDate = (field: 'checkIn' | 'checkOut') => {
    return dateToUpdate
      ? participants
          .filter(({ id }) => selectedIds.includes(id))
          .map(
            (participant) =>
              (participant as SalesParticipantFullFragment)?.services
          )
          .flat()
          .reduce((prev: string[], service) => {
            const dateMatch =
              service.__typename === 'ServiceParticipantBed' &&
              service.participantRoom?.id &&
              moment(service.dates?.[field].date).isSame(
                new Date(dateToUpdate),
                'day'
              )

            if (dateMatch) {
              return [...prev, service.participantRoom?.id || '']
            }

            return prev
          }, [])
      : []
  }

  const checkInRoomIdsForDate = getRoomIdsForDate('checkIn')
  const checkOutRoomIdsForDate = getRoomIdsForDate('checkOut')

  const handleUpdateCheckIn = (time: null | string) => {
    setCheckIn(time)

    async function update() {
      await Promise.all(
        checkInRoomIdsForDate.map(async (roomId) => {
          await handleSetCheckIn(roomId, time)
        })
      )
    }

    return update()
  }

  const handleUpdateCheckOut = (time: null | string) => {
    setCheckOut(time)

    async function update() {
      await Promise.all(
        checkOutRoomIdsForDate.map(async (roomId) => {
          await handleSetCheckOut(roomId, time)
        })
      )
    }

    return update()
  }

  const today = moment().format('MM.DD.YYYY')
  const isTodaySelected = moment(dateToUpdate).format('MM.DD.YYYY') === today

  return (
    <ColumnWrapper>
      <FlexColumn>
        <span>
          <T>ParticipantsList:MassUpdate.updateFor</T>:
        </span>
        <DateSelector
          date={dateToUpdate}
          dates={dates}
          setDate={setDateToUpdate}
        />
      </FlexColumn>
      <ButtonWrapper>
        <span>
          <T>ParticipantsList:MassUpdate.arrives</T>{' '}
          {isTodaySelected ? (
            <T>ParticipantsList:MassUpdate.today</T>
          ) : (
            moment(dateToUpdate).format('dd. DD.MM')
          )}
          : <FontAwesomeIcon icon="user-friends" />
          <b> {checkInRoomIdsForDate.length}</b>
        </span>
        <CheckContainer
          time={checkIn}
          icon="right-to-bracket"
          placeholder={<T>ParticipantsList:ParticipantFormFields.checkIn</T>}
          handleUpdateTime={handleUpdateCheckIn}
          setTimeState={setCheckIn}
          disabled={checkInRoomIdsForDate.length === 0}
          dateToUpdate={dateToUpdate}
          defaultHour={15}
          isToday={isTodaySelected}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <span>
          <T>ParticipantsList:MassUpdate.leaves</T>{' '}
          {isTodaySelected ? (
            <T>ParticipantsList:MassUpdate.today</T>
          ) : (
            moment(dateToUpdate).format('dd. DD.MM')
          )}
          : <FontAwesomeIcon icon="user-friends" />
          <b> {checkOutRoomIdsForDate.length}</b>
        </span>
        <CheckContainer
          time={checkOut}
          icon="right-from-bracket"
          placeholder={<T>ParticipantsList:ParticipantFormFields.checkOut</T>}
          handleUpdateTime={handleUpdateCheckOut}
          setTimeState={setCheckOut}
          disabled={checkOutRoomIdsForDate.length === 0}
          dateToUpdate={dateToUpdate}
          defaultHour={12}
          isToday={isTodaySelected}
        />
      </ButtonWrapper>
    </ColumnWrapper>
  )
}

const ColumnWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ disabled }) => disabled && `opacity: 0.7`};
`

const ButtonWrapper = styled(FlexColumn)`
  align-items: flex-start;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
