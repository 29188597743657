import { useQuery } from '@apollo/client'

import type {
  SalesDimensionsQuery as QueryData,
  SalesDimensionsQueryVariables as QueryVariables,
} from '~generated-types'

import { dimensionQueries } from '../queries'
import { SalesDimension } from '../types'

type Params = {
  salesId: string
}

export const useSalesDimensions = ({ salesId }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    dimensionQueries.GET_SALES_DIMENSIONS,
    {
      variables: { salesId },
    }
  )

  const dimensions: SalesDimension[] = data?.salesDimensions.selection || []

  return {
    dimensions,
    error,
    loading,
    refetch,
  }
}
