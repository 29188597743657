import { ComponentType } from 'react'
import styled from 'styled-components/macro'

export const ContentWrapper = styled.div<{ isLast: boolean; height: number }>`
  position: relative;
  display: inline-flex;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: 100%;
  ${({ isLast, theme }) =>
    !isLast && `border-bottom: solid 1px ${theme.palette.smoke.main};`}

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0px;
    right: 0px;
    width: 10px;
    z-index: 3000;
    pointer-events: none;

    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
` as ComponentType<any>
