import { ColorLightness } from '../enums'
import type { ExtendedColor } from '../types'
import getColorVariant from './getColorVariant'

export default function generateExtendedColor(input: string): ExtendedColor {
  return {
    dark: getColorVariant(input, ColorLightness.Dark),
    darker: getColorVariant(input, ColorLightness.Darker),
    extraDark: getColorVariant(input, ColorLightness.ExtraDark),
    extraDarker: getColorVariant(input, ColorLightness.ExtraDarker),
    extraLight: getColorVariant(input, ColorLightness.ExtraLight),
    extraLighter: getColorVariant(input, ColorLightness.ExtraLighter),
    light: getColorVariant(input, ColorLightness.Light),
    lighter: getColorVariant(input, ColorLightness.Lighter),
    main: input,
  }
}
