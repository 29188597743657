import { FlexRow } from '@/components/Layout'
import { EventEnrollment, SetDatesInput } from '@/modules/ParticipantsList'

import {
  PurchaseProductAddFromCatalogProductInput,
  PurchaseProductAddFromSalesProductInput,
  SalesType,
  ServiceParticipantBedFragment,
} from '~generated-types'

import ParticipantTotalPrice from '../../ParticipantRow/common/ParticipantTotalPrice'
import {
  ParticipantCheck,
  ParticipantDates,
  ParticipantProduct,
  ParticipantProductSelector,
  ParticipantRoom,
  ServiceTarget,
} from './modules'

type Props = {
  participantId: string
  participantSaleCustomer:
    | NonNullable<EventEnrollment['customer']>['customer']
    | null
  service: ServiceParticipantBedFragment
  participantSalesId: string
  actionsDisabled: boolean
  isMassUpdate?: boolean
  saleType: SalesType | null
  isEventParticipant?: boolean
  checkAvailable: boolean
  checkIn?: string | null
  checkOut?: string | null
  setCreatingNew: (creatingNew: 'BED' | 'PRODUCT' | null) => void
  handleAddRoom: (input: {
    roomReservationId: string
    serviceId: string | null
  }) => Promise<any>
  handleAddCatalogProduct: (
    input: PurchaseProductAddFromCatalogProductInput
  ) => Promise<any>
  handleAddSalesProduct: (
    input: PurchaseProductAddFromSalesProductInput
  ) => Promise<any>
  handleDeleteService: (id: string) => Promise<any>
  handleServiceSetDates: (
    input: SetDatesInput,
    participantId: string
  ) => Promise<any>
  handleSetCheckIn: (
    providedTime: string | null | undefined,
    serviceId: string
  ) => Promise<void>
  handleSetCheckOut: (
    providedTime: string | null | undefined,
    serviceId: string
  ) => Promise<void>
  handleSetParticipantTarget: (input: {
    targetId: string
    serviceId: string | null
  }) => Promise<any>
  handleRemoveRoom: (roomId: string, serviceId: string) => Promise<any>
  handleRemovePurchase: (purchaseId: string) => Promise<any>
}

const AccommodationService = ({
  participantId,
  participantSaleCustomer,
  service,
  saleType,
  participantSalesId,
  isEventParticipant,
  actionsDisabled,
  isMassUpdate,
  checkAvailable,
  checkIn,
  checkOut,
  setCreatingNew,
  handleAddRoom,
  handleAddCatalogProduct,
  handleAddSalesProduct,
  handleDeleteService,
  handleServiceSetDates,
  handleSetCheckIn,
  handleSetCheckOut,
  handleSetParticipantTarget,
  handleRemoveRoom,
  handleRemovePurchase,
}: Props) => (
  <>
    <FlexRow style={{ height: '100%', minHeight: '35px' }}>
      <ServiceTarget
        salesId={participantSalesId}
        participantSaleCustomer={participantSaleCustomer}
        target={service.accommodationTarget}
        hasRoom={!!service.participantRoom}
        hasPurchase={!!service.purchaseProduct}
        isDisabled={actionsDisabled}
        saleType={saleType}
        isEventParticipant={isEventParticipant}
        serviceId={service.id.includes('PENDING') ? null : service.id}
        handleSetTarget={({ targetId }) =>
          handleSetParticipantTarget({
            serviceId: service.id.includes('PENDING') ? null : service.id,
            targetId,
          }).then(() => setCreatingNew(null))
        }
        handleDeleteService={(id) => handleDeleteService(id)}
      />

      <ParticipantRoom
        participantSalesId={participantSalesId}
        serviceId={service.id.includes('PENDING') ? null : service.id}
        room={service.participantRoom}
        isDisabled={actionsDisabled}
        handleAddRoom={(roomReservationId) =>
          handleAddRoom({
            roomReservationId,
            serviceId: service.id.includes('PENDING') ? null : service.id,
          }).then(() => setCreatingNew(null))
        }
        handleRemoveRoom={(roomId) => handleRemoveRoom(roomId, service.id)}
      />

      <ParticipantDates
        participantId={participantId}
        service={service}
        disabled={actionsDisabled}
        handleServiceSetDates={handleServiceSetDates}
      />

      {service.purchaseProduct ? (
        <ParticipantProduct
          disabled={actionsDisabled}
          handleRemovePurchase={handleRemovePurchase}
          isMassUpdate={isMassUpdate}
          purchase={service.purchaseProduct}
        />
      ) : (
        <ParticipantProductSelector
          handleAddCatalogProduct={(input) =>
            handleAddCatalogProduct(input).then(() => setCreatingNew(null))
          }
          handleAddSalesProduct={(input) =>
            handleAddSalesProduct(input).then(() => setCreatingNew(null))
          }
          isDisabled={actionsDisabled}
          isMassUpdate={isMassUpdate}
          participantId={participantId}
          participantSalesId={participantSalesId}
          serviceId={service.id.includes('PENDING') ? undefined : service.id}
        />
      )}

      <ParticipantTotalPrice
        paymentStatus={service.purchaseProduct?.order?.paymentInfo.status}
        priceTotal={
          service.purchaseProduct?.totalPrice || {
            __typename: 'PriceElements',
            amount: 0,
            amountVatIncluded: 0,
            vatAmount: 0,
          }
        }
      />

      {isMassUpdate ? (
        <div style={{ width: '74px' }} />
      ) : (
        <ParticipantCheck
          checkIn={checkIn}
          checkOut={checkOut}
          disabled={actionsDisabled}
          checkAvailable={checkAvailable}
          handleSetCheckIn={(providedTime) =>
            handleSetCheckIn(providedTime, service.id)
          }
          handleSetCheckOut={(providedTime) =>
            handleSetCheckOut(providedTime, service.id)
          }
        />
      )}
    </FlexRow>
  </>
)

export default AccommodationService
