import { ChangeEvent, RefObject } from 'react'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { IconButton as IconButtonBase } from '@/components/ExtraButtons'
import { Select } from '@/components/FormControls'
import { SortOption } from '@/components/Reactivesearch'
import { translate, useLanguageContext } from '@/modules/Language'

type Props = {
  scrollRef: RefObject<HTMLDivElement>
  setSortProperty: (option: SortOption) => void
  sortProperty: SortOption
}

export const SalesListSort = ({
  scrollRef,
  setSortProperty,
  sortProperty,
}: Props) => {
  const history = useHistory()
  const { language } = useLanguageContext()
  const { pathname } = useLocation()

  const sortOptions = getSortOptions(language)

  const handleSetSortProperty = (e: ChangeEvent<HTMLSelectElement>) => {
    const [dataField, direction] = e.target.value.split('_')

    const target = sortOptions.find(
      (x) => x.dataField === dataField && x.direction === direction
    )

    if (target) {
      const { sort, ...rest } = parse(window.location.search)

      history.replace(
        `${pathname}?${stringify({
          ...rest,
          sort: `${target.dataField}__${target.direction}`,
        })}`
      )

      setSortProperty(target)

      if (scrollRef.current) {
        scrollRef.current.scroll({
          behavior: 'smooth',
          top: 0,
        })
      }
    }
  }

  return (
    <>
      <Button color="transparent" noNudge icon="sort" />
      <Toggle
        noArrow
        noMargin
        value={`${sortProperty.dataField}_${sortProperty.direction}`}
        onChange={handleSetSortProperty}
      >
        {sortOptions.map(({ dataField, direction, label }) => (
          <option
            key={`${dataField}_${direction}`}
            value={`${dataField}_${direction}`}
          >
            {label}
          </option>
        ))}
      </Toggle>
    </>
  )
}

////////////

export const getSortOptions = (lang: string): SortOption[] => [
  {
    dataField: 'estimatedDates.start',
    direction: 'asc',
    label: translate('SalesSearchList:SortOptions.estimatedDatesAsc', lang),
  },
  {
    dataField: 'estimatedDates.start',
    direction: 'desc',
    label: translate('SalesSearchList:SortOptions.estimatedDatesDesc', lang),
  },
  {
    dataField: 'orderNumber',
    direction: 'desc',
    label: translate('SalesSearchList:SortOptions.orderNumberDesc', lang),
  },
  {
    dataField: 'customerName.keyword',
    direction: 'asc',
    label: translate('SalesSearchList:SortOptions.customerNameAsc', lang),
  },
  {
    dataField: 'name.keyword',
    direction: 'asc',
    label: translate('SalesSearchList:SortOptions.nameAsc', lang),
  },
  {
    dataField: 'participants',
    direction: 'desc',
    label: translate('SalesSearchList:SortOptions.participantsDesc', lang),
  },
  {
    dataField: 'participants',
    direction: 'asc',
    label: translate('SalesSearchList:SortOptions.participantsAsc', lang),
  },
]

const Toggle = styled(Select)`
  &:not([type='checkbox']):not([type='radio']) {
    height: 32px;
    width: 32px;
    padding: 0;
    background: transparent;
    border-radius: 50%;
    border: none;
    color: transparent;

    &&:hover,
    &&:focus,
    &&:active {
      background: transparent;
    }
  }
`

const Button = styled(IconButtonBase)`
  position: absolute;
  justify-content: center;
  pointer-events: none;
  z-index: 1;

  border: none;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    right: ${theme.spacing.gu(3)}rem;
    font-size: ${theme.typography.fontSizeBig};
  `}

  &&:hover, &&:focus, &&:active {
    background: transparent;
  }
`
