import styled, { css } from 'styled-components/macro'

import { ChangelogOverlayWidget } from '@/modules/Changelog'

import { CreationDate } from './CreationDate'

type Props = {
  salesId: string
  creationDate: string
}

export const SaleLogs = ({ salesId, creationDate }: Props) => (
  <Wrapper>
    <CreationDate date={creationDate} />
    <ChangelogOverlayWidget offsetTop={125} target={salesId} />
  </Wrapper>
)

//////////

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1000;
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.09);

  ${({ theme }) => css`
    width: calc(${theme.spacing.gu(32)}rem - 1px);
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter};
    background-color: ${theme.palette.white};
  `}

  @media print {
    display: none;
  }
`
