import styled, { css } from 'styled-components/macro'

import { useCellSizeContext } from '../CellSizeContext'

export const LabelTruncate = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => {
    const { cellSize } = useCellSizeContext()

    return css`
      max-width: ${theme.spacing.gu(cellSize)}rem;
    `
  }}

  @media print {
    white-space: pre-wrap;
    max-width: auto;
    overflow: inital;
    text-overflow: initial;
  }
`
