import { CSSProperties, useState } from 'react'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'

import { InvoicePaymentType, OrderPhaseType } from '~generated-types'

import { EditButton, Subsection } from './components'

type Props = {
  paymentType: InvoicePaymentType
  phase: OrderPhaseType
  readOnly: boolean
  setPaymentType: (type: InvoicePaymentType) => Promise<void | null | undefined>
}

export const PaymentTypeSelector = ({
  paymentType,
  phase,
  readOnly,
  setPaymentType,
}: Props) => {
  const { language } = useLanguageContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const options = Object.values(InvoicePaymentType).map(
    (option: InvoicePaymentType) => ({
      isDisabled:
        option === InvoicePaymentType.OnlinePayment &&
        phase !== OrderPhaseType.InvoiceReservationFee,
      label: translate(`Orders:PaymentType.${option}`, language),
      value: option,
    })
  )

  const onSelect = (selected?: Option | null) => {
    const selectedOption = Object.values(InvoicePaymentType).find(
      (type: InvoicePaymentType) => type === selected?.value
    )
    if (selectedOption) {
      setProcessing(true)
      setPaymentType(selectedOption).then(() => {
        setProcessing(false)
        setEditMode(false)
      })
    }
  }

  const label = translate(`Orders:PaymentType.${paymentType}`, language)

  return (
    <Subsection isEditable noMargin title={<T>Orders:PaymentType.title</T>}>
      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={extraStyles}
          isCompact
          isLoading={isProcessing}
          menuIsOpen={isEditMode}
          name="payment-type-selector"
          onBlur={() => setEditMode(false)}
          onChange={onSelect}
          options={options}
          placeholder=""
          value={{ label, value: paymentType }}
        />
      ) : (
        <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
          {label}
        </EditButton>
      )}
    </Subsection>
  )
}

/////

const extraStyles = {
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    minHeight: '30px',
  }),
}
