import { gql } from '@apollo/client'

export default gql`
  mutation CreateCustomerAddress($input: CustomerCreateAddressInput!) {
    customerCreateAddress(input: $input) {
      address {
        id
        label
        postalAddress {
          address1
          address2
          city
          country
          postcode
        }
      }
    }
  }
`
