import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

import {
  SalesForPrintParticipantServiceBed,
  SalesPrintConfigKey as Key,
} from '../../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../../utils/salesPrintConfig'
import { formatRoom, getFullName, getTargetName } from '../../../utils'
import { GuestDetails } from '../GuestDetails'
import { ParticipantsByRoom } from '../utils'
import { ParticipantWrapper, Placeholder, Section, Wrapper } from './common'

type Props = {
  data: ParticipantsByRoom
}

export const RoomRow = ({ data: { room, participants } }: Props) => {
  const { language } = useLanguageContext()
  const {
    salesPrintConfig: config,
    data: { groups },
  } = salesHooks.useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Participants}.${key}`)

  return (
    <Wrapper>
      <FlexRow>
        <Section style={{ fontWeight: 600, width: '8rem' }}>
          {room === 'NO_ROOM' ? (
            <Placeholder>
              <T>SalesDetails:PrintSale.noRoom</T>
            </Placeholder>
          ) : (
            formatRoom(room)
          )}
        </Section>

        <FlexColumn flex={1}>
          {participants.map((participant, idx) => {
            const {
              age,
              ageCategory,
              firstName,
              group,
              id,
              lastName,
              services,
            } = participant

            const isGroupsAvailable = !!groups?.values.length

            const [service] = services.filter(
              (s) => s.__typename === 'ServiceParticipantBed'
            ) as SalesForPrintParticipantServiceBed[]

            const target = service?.accommodationTarget
            const dates = service?.dates

            return (
              <FlexColumn key={id}>
                <ParticipantWrapper isFirst={idx === 0}>
                  {checkVisibility(Key.Name) && (
                    <Section style={{ fontWeight: 600, width: '12rem' }}>
                      {getFullName(firstName, lastName) || '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Age) && (
                    <Section flex={0.5}>
                      {age ?? ageCategory?.abbreviation ?? '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Target) && (
                    <Section flex={1.5}>
                      {target ? getTargetName(target, language) : '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Dates) && (
                    <Section flex={1.5}>
                      {dates
                        ? formatDate(
                            dates.checkIn.date,
                            dates.checkOut.date,
                            'short'
                          )
                        : '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Group) && isGroupsAvailable && (
                    <Section flex={1.5}>
                      <span style={{ whiteSpace: 'pre-wrap' }}>
                        {group || '–'}
                      </span>
                    </Section>
                  )}
                </ParticipantWrapper>

                <GuestDetails data={participant} />
              </FlexColumn>
            )
          })}
        </FlexColumn>
      </FlexRow>
    </Wrapper>
  )
}
