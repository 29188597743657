import { ReactNode } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { DateRange, DateRangePicker } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import type { CalendarListingPreset } from '@/modules/Registry/CalendarResources'
import { CategorySelection } from '@/modules/Reservations'
import { useTheme } from '@/theme'

import {
  CategorySearchGroups,
  ResourceSelector,
  ResourceSelectorProps,
  TaskTargetSelector,
  TaskTargetSelectorProps,
} from './components'

export interface ResourceCalendarDateRangeSidebarProps
  extends ResourceSelectorProps,
    TaskTargetSelectorProps {
  activePreset: CalendarListingPreset | null
  addCategorySelection: () => CategorySelection
  clearSelectionsLockedOverlay?: () => void
  interval: DateRange
  loadingPresets: boolean
  presets: CalendarListingPreset[]
  removeCategorySelection: (selectionId: string) => void
  selectedCategories: CategorySelection[]
  selectionsLockedOverlay?: ReactNode | null | undefined
  selectPreset: (key: string) => void
  setInterval: (arg0: DateRange) => void
  updateCategorySelection: (
    selectionId: string,
    update: {
      [key: string]: any
    }
  ) => void
}

export const ResourceCalendarDateRangeSidebar = ({
  activePreset,
  addCategorySelection,
  addResourceSelection,
  allResources,
  clearSelectionsLockedOverlay,
  interval,
  loadingPresets,
  loadingResources,
  presets,
  removeCategorySelection,
  removeResourceSelection,
  selectedCategories,
  selectedResources,
  selectionsLockedOverlay,
  selectPreset,
  setInterval,
  setTaskTarget,
  taskTargetOptions,
  taskTargetSelection,
  updateCategorySelection,
}: ResourceCalendarDateRangeSidebarProps) => {
  const theme = useTheme()

  return (
    <>
      {!loadingPresets && !!presets.length ? (
        <FilterSection
          label={<T>Listings:CalendarResourceListing.preset.title</T>}
          render={() => (
            <FlexRow wrap="wrap">
              {presets.map(({ id, label }) => (
                <Button
                  color={activePreset?.id === id ? 'primary' : 'default'}
                  key={`preset-button-${id}`}
                  onClick={() => selectPreset(id)}
                  size="small"
                  style={{ marginBottom: theme.spacing.gutterSmall }}
                  variant={activePreset?.id === id ? 'default' : 'outlined'}
                >
                  {label}
                </Button>
              ))}
            </FlexRow>
          )}
        />
      ) : null}
      <FilterSection
        label={<T>ResourceReservationsCalendar:interval</T>}
        render={() => (
          <div style={{ margin: `-${theme.spacing.gu(1)}rem` }}>
            <DateRangePicker
              maxWeekRange={5}
              setValue={(range) =>
                setInterval(range || { from: moment(), to: moment() })
              }
              value={interval}
            />
          </div>
        )}
      />

      <LockableSelectionsWrapper>
        <FlexColumn
          noPadding
          style={!!selectionsLockedOverlay ? { filter: 'blur(1px)' } : {}}
        >
          <TaskTargetSelector
            setTaskTarget={setTaskTarget}
            taskTargetOptions={taskTargetOptions}
            taskTargetSelection={taskTargetSelection}
          />

          <ResourceSelector
            addResourceSelection={addResourceSelection}
            allResources={allResources}
            disabled={!!selectionsLockedOverlay}
            loadingResources={loadingResources}
            removeResourceSelection={removeResourceSelection}
            selectedResources={selectedResources}
          />

          <CategorySearchGroups
            add={addCategorySelection}
            data={selectedCategories}
            disabled={!!selectionsLockedOverlay}
            remove={removeCategorySelection}
            update={updateCategorySelection}
          />
        </FlexColumn>

        {selectionsLockedOverlay && (
          <LockedSelectionsOverlayWrapper>
            <LockedSelectionsOverlay>
              {selectionsLockedOverlay}
              <Button
                color="primary"
                onClick={() =>
                  clearSelectionsLockedOverlay && clearSelectionsLockedOverlay()
                }
                size="small"
                style={{ alignSelf: 'center' }}
              >
                <T>Listings:CalendarResourceListing.preset.edit</T>
              </Button>
            </LockedSelectionsOverlay>
          </LockedSelectionsOverlayWrapper>
        )}
      </LockableSelectionsWrapper>
    </>
  )
}

export default ResourceCalendarDateRangeSidebar

////////////

const LockableSelectionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const LockedSelectionsOverlayWrapper = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.12);
  z-index: 101;

  ${({ theme }) => css`
    top: -${theme.spacing.gu(1)}rem;
    right: -${theme.spacing.gu(2)}rem;
    bottom: -${theme.spacing.gu(1)}rem;
    left: -${theme.spacing.gu(2)}rem;
    padding: ${theme.spacing.gutterBig};
  `}

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

const LockedSelectionsOverlay = styled(FlexColumn)`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  text-align: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`
