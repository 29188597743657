import { FlexColumn, FlexRow } from '@/components/Layout'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'

import { AmountInput } from './AmountInput'
import { DeleteIcon, Title } from './common'

type Props = {
  id: string
  internalCost: number
}

export const InternalCostSection = ({ id, internalCost }: Props) => {
  const { updateProductItem } = useCatalogProductManagerContext()
  const { isCatalogInternalPriceEnabled } = useWillbaConfigContext()

  return (
    <FlexColumn>
      <Title>
        <T>Products:ProductManager.pricing.internalCost</T>
      </Title>

      <FlexRow alignItems="center">
        <AmountInput
          amount={internalCost}
          disabled={!isCatalogInternalPriceEnabled}
          updateAmount={(amount) =>
            updateProductItem({ id, internalCost: amount })
          }
        />

        {isCatalogInternalPriceEnabled && (
          <DeleteIcon
            icon="xmark"
            onClick={() => updateProductItem({ id, internalCost: null })}
          />
        )}
      </FlexRow>
    </FlexColumn>
  )
}
