import { lighten } from 'polished'
import styled from 'styled-components/macro'

import { RoomCleaningStatus } from '~generated-types'

type Props = {
  status: RoomCleaningStatus
}

export const CleaningStatus = ({ status }: Props) => <Dot status={status} />

export default CleaningStatus

////////////

const Dot = styled.div<{ status: RoomCleaningStatus }>`
  background: ${({ status, theme }) =>
    status === 'DIRTY'
      ? lighten(0.44, theme.palette.danger.main)
      : lighten(0.39, theme.palette.secondary.main)};
  border: 1px solid
    ${({ status, theme }) =>
      status === 'DIRTY'
        ? theme.palette.danger.main
        : theme.palette.secondary.dark};
  border-radius: 5px;
  display: inline-block;
  height: 10px;
  width: 10px;
`
