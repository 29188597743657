import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { FlexRow } from '@/components/Layout'

type Props = {
  children: ReactNode
  className?: string
  label?: ReactNode
  style?: CSSProperties
}

export const ButtonGroup = ({ children, label, ...props }: Props) => (
  <Wrapper {...props}>
    {label && <Label>{label}:</Label>}
    <FlexRow>{children}</FlexRow>
  </Wrapper>
)

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterBig};
  `}
`

const Label = styled.div`
  font-size: 0.9rem;
  font-variant: all-small-caps;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

export const ButtonGroupButton = styled(Button)<{ selected: boolean }>`
  border-radius: 0;
  margin: 0;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
  `}

  &:not(:last-child) {
    border-right: none;
  }

  &,
  &:not(:disabled):focus {
    ${({ theme }) => css`
      background: ${theme.palette.white};
      border-color: ${theme.palette.smoke.main};
      color: ${theme.palette.text.light};
    `}
  }

  &:not(:disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
      border-color: ${theme.palette.smoke.main};
      color: ${theme.palette.text.light};
    `}
  }

  ${({ selected }) =>
    selected &&
    css`
      &,
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        ${({ theme }) => css`
          background: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
          color: ${theme.palette.white};
        `}
      }
    `}

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`
