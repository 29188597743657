import { gql, useMutation } from '@apollo/client'

import type {
  SetDimensionShortcutMutation,
  SetDimensionShortcutMutationVariables,
} from '~generated-types'

import { dimensionFragments } from '../fragments'

const MUTATION = gql`
  ${dimensionFragments.dimensionReference}

  mutation SetDimensionShortcut($input: SalesDimensionsSetShortcut!) {
    salesDimensionsSetShortcut(input: $input) {
      ...DimensionReference
    }
  }
`

export const useSetDimensionShortcutMutation = () =>
  useMutation<
    SetDimensionShortcutMutation,
    SetDimensionShortcutMutationVariables
  >(MUTATION)
