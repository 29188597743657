import styled, { css } from 'styled-components/macro'

import { H4 } from '@/components/Typography'

export const PrintTitle = styled(H4)`
  font-weight: 500;
  margin-bottom: 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
  `}

  @media print {
    display: block;
  }
`
