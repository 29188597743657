import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import {
  ExpansionButton,
  Padding,
  TreeEndCap,
  TreePadding,
  TreeRow,
} from '@/components/TreeSelector'
import { CategorySetCategory } from '@/modules/Registry/Category'
import { generateCompareFn } from '@/utils/arrays'

type Category = Omit<CategorySetCategory, 'children'> & {
  children?: Category[]
}

type Props = {
  category: Category
  depth: number
  isLastReactNode: boolean
  padding: Padding[]
  selection: string[]
  toggleCategory: (id: string) => void
}

export const CategoryNode = ({
  category,
  depth,
  isLastReactNode,
  padding,
  selection,
  toggleCategory,
}: Props) => {
  const children =
    [...(category?.children || [])].sort(generateCompareFn('name')) || []

  const [isExpanded, setIsExpanded] = useState<boolean>(
    !!children.find((c) => selection.includes(c.id))
  )

  return (
    <>
      <TreeRow>
        {[...padding, isLastReactNode ? 'half' : 'full'].map((mode, idx) => (
          <TreePadding
            key={`tree-padding-${category.id}-${idx}`}
            mode={mode as Padding}
          />
        ))}
        {children.length ? (
          <ExpansionButton
            isExpanded={isExpanded}
            onClick={() => setIsExpanded((current) => !current)}
          >
            <TreeRowLabel>{category.name}</TreeRowLabel>
          </ExpansionButton>
        ) : (
          <>
            <TreeEndCap />
            <CheckboxWrapper flex={1}>
              <CheckboxInput
                checked={!!selection.includes(category.id)}
                noMargin
                onChange={() => toggleCategory(category.id)}
              >
                <TreeRowLeafLabel>{category.name}</TreeRowLeafLabel>
              </CheckboxInput>
            </CheckboxWrapper>
          </>
        )}
      </TreeRow>

      {isExpanded &&
        children.map((x, idx) => (
          <CategoryNode
            category={x}
            depth={depth + 1}
            isLastReactNode={idx === children.length - 1}
            key={`category-node-${x.id}`}
            padding={[...padding, isLastReactNode ? 'none' : 'vertical']}
            selection={selection}
            toggleCategory={toggleCategory}
          />
        ))}
    </>
  )
}

/////////

const CheckboxWrapper = styled(FlexRow)`
  label {
    flex: 1;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const TreeRowLabel = styled.span`
  line-height: 1.1;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const TreeRowLeafLabel = styled(TreeRowLabel)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
