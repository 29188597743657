import { gql, useMutation } from '@apollo/client'

import type {
  RemoveOrderMutation,
  RemoveOrderMutationVariables,
} from '~generated-types'

const REMOVE_ORDER_MUTATION = gql`
  mutation RemoveOrder($id: ID!) {
    orderDelete(id: $id) {
      deleted
      id
    }
  }
`

export const useRemoveOrderMutation = () =>
  useMutation<RemoveOrderMutation, RemoveOrderMutationVariables>(
    REMOVE_ORDER_MUTATION
  )
