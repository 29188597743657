import { Moment } from 'moment'

import type { CalendarViewMode } from '@/modules/Reservations'

import { findDates } from './findDates'

export const getDateKeys = (
  viewMode: CalendarViewMode,
  targetDate: Moment
): string[] => {
  const isWeekMode = viewMode === 'WEEK'

  const endDate = isWeekMode
    ? targetDate.clone().endOf('isoWeek')
    : targetDate.clone()
  const startDate = isWeekMode
    ? targetDate.clone().startOf('isoWeek')
    : targetDate.clone()

  return findDates(startDate, endDate)
}
