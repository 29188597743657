import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { BottomSheet } from '@/components/BottomSheet'
import { T } from '@/modules/Language'

type Props = {
  isExpanded: boolean
  isFirst: boolean
  isLast: boolean
  onClose: () => void
  renderHeader: () => ReactNode
  renderContent: () => ReactNode
}

export const EnrollmentContainer = ({
  isExpanded,
  isFirst,
  isLast,
  onClose,
  renderHeader,
  renderContent,
}: Props) => {
  return isExpanded ? (
    <BottomSheet onClose={onClose} title={<T>SalesDetails:Enrollment.title</T>}>
      <Container>
        {renderHeader()}
        {renderContent()}
      </Container>
    </BottomSheet>
  ) : (
    <Container isFirst={isFirst} isLast={isLast}>
      {renderHeader()}
    </Container>
  )
}

const Container = styled.div<{
  isFirst?: boolean
  isLast?: boolean
}>`
  margin-top: -1px;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
  `}

  ${({ isLast, isFirst }) => css`
    border-bottom-left-radius: ${isLast ? '6px' : '0px'};
    border-bottom-right-radius: ${isLast ? '6px' : '0px'};
    border-top-left-radius: ${isFirst ? '6px' : '0px'};
    border-top-right-radius: ${isFirst ? '6px' : '0px'};
  `}
`
