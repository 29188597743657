import { gql, useMutation } from '@apollo/client'

import {
  CreateResourceReservationGroupMutation,
  CreateResourceReservationGroupMutationVariables,
} from '~generated-types'

export type {
  CreateResourceReservationGroupMutation,
  CreateResourceReservationGroupMutationVariables,
}

export const CREATE_RESOURCE_RESERVATION_GROUP_MUTATION = gql`
  mutation CreateResourceReservationGroup(
    $input: ResourceReservationCreateGroupInput!
  ) {
    resourceReservationCreateGroup(input: $input) {
      end
      id
      name
      number
      start
    }
  }
`

export function useCreateResourceReservationGroupMutation() {
  return useMutation<
    CreateResourceReservationGroupMutation,
    CreateResourceReservationGroupMutationVariables
  >(CREATE_RESOURCE_RESERVATION_GROUP_MUTATION)
}

export default useCreateResourceReservationGroupMutation
