import { gql } from '@apollo/client'

export default gql`
  fragment MealForSalesPrint on ScheduledMeal {
    id
    start
    status
    duration
    location {
      name
    }
    meal {
      name
    }
    notes
    quantities {
      quantity
    }
  }
`
