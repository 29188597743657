import { gql } from '@apollo/client'

export default gql`
  mutation CreateOrganizationCustomerContact(
    $input: CustomerOrganizationCreateContactInput!
  ) {
    customerOrganizationCreateContact(input: $input) {
      contact {
        email
        firstName
        id
        lastName
        notes
        phone
      }
    }
  }
`
