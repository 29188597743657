import { Component, ErrorInfo, ReactNode } from 'react'

import { ErrorContent } from './ErrorContent'

type Props = {
  children: ReactNode
}

type State = {
  error: Error | null
  info: ErrorInfo | null
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null, info: null }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, info })
  }

  render() {
    const { children } = this.props
    const { error, info } = this.state

    if (!!error && !!info) {
      return <ErrorContent error={error} info={info} />
    }

    return children
  }
}
