import moment from 'moment'

import {
  ListingMealType,
  ListingSales,
  ListingScheduledMeal,
} from './usePacketMealsListing'

type MealTypeWithMeals = ListingMealType & { meals: ListingScheduledMeal[] }

export const parseParticipantName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`

export const parseCustomerName = (sales: ListingSales) => {
  const customer = sales.customer?.customer
  const customerOrg =
    customer?.__typename === 'CustomerOrganization' &&
    customer.organization.name
  const customerPerson =
    customer?.__typename === 'CustomerPerson' && customer.person

  const customerName = customerOrg
    ? customerOrg
    : customerPerson
    ? `${customerPerson.lastName} ${customerPerson.firstName}`
    : null

  return `#${sales.orderNumber} ${sales.name ?? ''}${' '}
    ${customerName ? `(${customerName})` : ''}`
}

export const compareMealsFn = (
  data: { [mealKey: string]: ListingScheduledMeal[] },
  mealTypes: ListingMealType[]
) =>
  mealTypes
    .map((mealType) => ({
      ...mealType,
      meals: data[mealType.id] || [],
    }))
    .sort((a: MealTypeWithMeals, b: MealTypeWithMeals) => {
      const aStart = a.meals[0]?.start || moment().toISOString()
      const bStart = b.meals[0]?.start || moment().toISOString()
      return moment(aStart).valueOf() - moment(bStart).valueOf()
    })
    .map(({ meals, ...mealType }) => ({ ...mealType }))
