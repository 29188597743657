import { gql, useMutation } from '@apollo/client'

import type {
  SetMealQuantityMutation,
  SetMealQuantityMutationVariables,
} from '~generated-types'

import { MealFragment } from '../fragments'

const SET_MEAL_QUANTITY_MUTATION = gql`
  ${MealFragment}

  mutation SetMealQuantity($id: ID!, $input: ScheduledMealSetQuantity!) {
    scheduledMealSetQuantity(id: $id, input: $input) {
      ...Meal
    }
  }
`

export const useSetMealQuantityMutation = () =>
  useMutation<SetMealQuantityMutation, SetMealQuantityMutationVariables>(
    SET_MEAL_QUANTITY_MUTATION
  )
