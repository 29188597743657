import { useRef, useState } from 'react'

type EntityBase = {
  id: string
}
export type EntityState<E> = {
  [id: string]: E
}
type Methods<E> = {
  add: (entity: E) => void
  addMany: (manyEntities: EntityState<E>) => void
  addArrayOfEntities: (arrayOfEntities: E[]) => void
  update: (entityId: string, entity: E) => void
  remove: (entityId: string) => void
  clear: () => void
}

export default function useItemEntityState<E extends EntityBase, Status>(
  initialState: EntityState<E> = Object.freeze({}),
  initialStatus: Status
): [EntityState<E>, Methods<E>, Status, (status: Status) => void] {
  const [entities, setEntities] = useState<EntityState<E>>(initialState)
  const [status, setStatus] = useState<Status>(initialStatus)

  const add = (entity: E) => {
    setEntities((entities) => ({
      ...Object.freeze(entities),
      [entity.id]: entity,
    }))
  }
  const addMany = (manyEntities: EntityState<E>) => {
    setEntities((entities) => ({ ...entities, ...manyEntities }))
  }
  const addArrayOfEntities = (arrayOfEntities: E[]) => {
    const manyEntities: EntityState<E> = {}

    arrayOfEntities.forEach((entity) => {
      manyEntities[entity.id] = entity
    })

    addMany(manyEntities)
  }
  const update = (entityId: string, entity: E) => {
    setEntities((entities) => ({ ...entities, [entityId]: entity }))
  }
  const remove = (entityId: string) => {
    setEntities((entities) => {
      const clone = { ...entities }
      delete clone[entityId]

      return clone
    })
  }

  const clear = () => {
    setEntities(() => Object.freeze({}))
  }

  const methods = {
    add,
    addArrayOfEntities,
    addMany,
    clear,
    remove,
    update,
  }
  const stableMethods = useRef<Methods<E>>(methods)

  return [
    entities,
    stableMethods.current ? stableMethods.current : methods,
    status,
    setStatus,
  ]
}
