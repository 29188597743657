import React from 'react'
import { SingleList as LibrarySingleList } from '@appbaseio/reactivesearch'
import { SingleList as SingleListProps } from '@appbaseio/reactivesearch/lib/components/list/SingleList'
import { createGlobalStyle } from 'styled-components/macro'

import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T } from '@/modules/Language'

import { CollapsedFilterValue } from './CollapsedFilterValue'
import { inputCss, listCss } from './styles'
import { l10nListItemRenderer } from './utils'

export const inputClassName = 'rs-single-list-input'
export const wrapperClassName = 'rs-single-list-wrap'

interface Props extends SingleListProps {
  isCollapsed?: boolean
  l10nPrefix?: string
}

export const SingleList = ({ isCollapsed, l10nPrefix, ...props }: Props) => {
  const customRenderProps = l10nPrefix
    ? {
        renderItem: l10nListItemRenderer(l10nPrefix),
      }
    : {}

  return (
    <>
      <MultiListStyles />
      {isCollapsed && (
        <CollapsedFilterValue
          componentId={props.componentId}
          placeholder={<T>Reactivesearch:unfiltered</T>}
        />
      )}
      <LibrarySingleList
        {...props}
        {...customRenderProps}
        className={wrapperClassName}
        innerClass={{ input: inputClassName }}
        loader={!isCollapsed ? <LoadingPlaceholder size="lg" /> : null}
        renderError={() =>
          !isCollapsed ? (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          ) : null
        }
        renderNoResults={() =>
          !isCollapsed ? (
            <DisabledColor>
              <T>Reactivesearch:empty</T>
            </DisabledColor>
          ) : null
        }
        style={isCollapsed ? { display: 'none' } : {}}
      />
    </>
  )
}

////////////

const MultiListStyles = createGlobalStyle`
  .${wrapperClassName} {
    ${listCss}
  }

  .${inputClassName} {
    ${inputCss}
  }
`
