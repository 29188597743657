import { gql } from '@apollo/client'

export default gql`
  fragment Room on Room {
    beds
    building {
      name
    }
    extraBeds
    features {
      name
      icon
      id
      shortName
    }
    floor
    id
    number
    roomType {
      name
    }
  }
`
