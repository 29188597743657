import { generateCompareFn } from '@/utils/arrays'

import {
  SalesForPrintAccommodationTarget,
  SalesForPrintParticipant,
} from '../../../../../types'

export type ParticipantsByTarget = {
  participants: SalesForPrintParticipant[]
  target: SalesForPrintAccommodationTarget | null
}

export const groupByTarget = (participants: SalesForPrintParticipant[]) =>
  Object.values(
    participants.reduce(
      (acc: { [target: string]: ParticipantsByTarget }, p) => {
        const accommodationServices = p.services.filter(
          (s) => s.__typename === 'ServiceParticipantBed'
        )
        const purchaseServices = p.services.filter(
          (s) => s.__typename === 'ServicePurchase'
        )

        if (!accommodationServices.length) {
          acc['NO_TARGET'] = {
            participants: [...(acc['NO_TARGET']?.participants ?? []), p],
            target: null,
          }
        }

        p.services.forEach((s) => {
          if (s.__typename === 'ServiceParticipantBed') {
            const target = s.accommodationTarget
            const targetKey = target?.id ?? 'NO_TARGET'

            const prevParticipants = acc[targetKey]?.participants

            if (prevParticipants) {
              const prevParticipant = prevParticipants.find(
                (pp) => pp.id === p.id
              )

              acc[targetKey] = {
                participants: prevParticipant
                  ? prevParticipants.map((pp) =>
                      pp.id === p.id
                        ? { ...pp, services: [...pp.services, s] }
                        : pp
                    )
                  : [
                      ...prevParticipants,
                      { ...p, services: [s, ...purchaseServices] },
                    ],
                target,
              }
            } else {
              acc[targetKey] = {
                participants: [{ ...p, services: [s, ...purchaseServices] }],
                target,
              }
            }
          }
        })

        return acc
      },
      {}
    )
  ).sort(generateCompareFn('target.sortOrder'))
