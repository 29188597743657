import { CSSProperties, ReactNode, useEffect, useState } from 'react'

import { ModalContainer } from '@/components/Modal'

import { Option } from '../types'
import { Control } from './Control'
import { OptionsModal } from './OptionsModal'

type Props = {
  allSelectedPlaceholder?: ReactNode | string
  controlStyle?: CSSProperties
  isCompact?: boolean
  labelsAmount?: number
  loading?: boolean
  modalWidth?: string
  noOptionsPlaceholder?: ReactNode | string
  onModalOpen?: () => void
  onModalClose?: () => void
  setModalOpen?: () => void
  openModal?: boolean
  options: Option[]
  placeholder?: ReactNode | string
  selectedValues: string[]
  setSelectedValues: (values: string[]) => void
  withSearch?: boolean
  withSelectAll?: boolean
  liftSetValues?: (values: string[]) => void
}

export const ThemedMultiSelect = ({
  allSelectedPlaceholder,
  controlStyle,
  isCompact,
  labelsAmount = 1,
  loading = false,
  modalWidth = '100%',
  noOptionsPlaceholder,
  onModalOpen,
  onModalClose,
  openModal,
  options,
  placeholder,
  selectedValues,
  setSelectedValues,
  withSearch,
  withSelectAll,
  liftSetValues,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [values, setValues] = useState<string[]>([])

  useEffect(() => {
    setValues(selectedValues)
    if (openModal) {
      setModalOpen(true)
    }
  }, [selectedValues])

  const handleCloseModal = () => {
    setSelectedValues(values)
    setModalOpen(false)
    onModalClose && onModalClose()
  }

  const handleOpenModal = () => {
    setModalOpen(true)
    onModalOpen && onModalOpen()
  }

  const handleSetValues = (values: string[]) => {
    setValues(values)
    liftSetValues && liftSetValues(values)
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <OptionsModal
          loading={loading}
          modalWidth={modalWidth}
          noOptionsPlaceholder={noOptionsPlaceholder}
          options={options}
          setValues={handleSetValues}
          values={values}
          withSearch={withSearch}
          withSelectAll={withSelectAll}
        />
      }
      onClose={handleCloseModal}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Control
          allSelectedPlaceholder={allSelectedPlaceholder}
          innerRef={ref}
          isCompact={isCompact}
          isOpen={isModalOpen}
          labelsAmount={labelsAmount}
          loading={loading}
          openModal={handleOpenModal}
          options={options}
          placeholder={placeholder}
          style={controlStyle}
          values={values}
        />
      )}
    />
  )
}
