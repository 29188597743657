import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableInput } from '@/components/DataTable'
import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'

type Props = HOCProps & {
  disabled: boolean
  payableAmount: number
  setAmount: (amount: number) => void
}

const AmountInputBase = ({
  payableAmount,
  setAmount,
  value,
  ...props
}: Props) => {
  const [amountError, setAmountError] = useState<boolean>(false)

  const checkAmountError = () => setAmountError(Number(value) > payableAmount)

  useEffect(() => {
    setAmount(Number(value || 0))
  }, [value])

  return (
    <FlexColumn flex={1} noPadding>
      <SubTitle>
        <T>Orders:Payments.field.amount</T>
      </SubTitle>

      <Input
        {...props}
        endAdornment="€"
        hasError={amountError}
        onBlur={checkAmountError}
        onFocus={(e) => e.currentTarget.select()}
        showBorder
        type="text"
        value={value}
      />
    </FlexColumn>
  )
}

export const AmountInput = withPriceInputLogic(AmountInputBase)

///////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    border-radius: 6px;
    height: 35px;

    &:disabled {
      ${({ theme }) => css`
        border-color: ${theme.palette.smoke.dark};
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const SubTitle = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gu(1)}rem;
  `}
`
