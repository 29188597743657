import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { IconSpacer, Label, PriceLabel, Spacer, Wrapper } from './common'

export const OtherPhaseTitles = () => {
  const { spacing } = useTheme()

  return (
    <Wrapper>
      <IconSpacer />

      <Label width={`${spacing.gu(26)}rem`} />

      <Label width={`${spacing.gu(24)}rem`}>
        <T>Orders:Details.created</T>
      </Label>

      <Label width={`${spacing.gu(20)}rem`}>
        <T>Orders:State.title</T>
      </Label>

      <Spacer />

      <PriceLabel width={`${spacing.gu(16)}rem`} />

      <Label width={`${spacing.gu(6.5)}rem`} />
    </Wrapper>
  )
}
