import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import type {
  ResourceReservationsListingQuery as QueryData,
  ResourceReservationsListingQueryVariables as QueryVariables,
} from '~generated-types'

type Reservation =
  QueryData['resourceReservations']['reservations'][0]['reservations'][0]
type ReservationResource = Reservation['resource']
type ReservationTask = Reservation['tasks'][0]
type ReservationTaskPurchase = ReservationTask['purchaseProducts'][0]

const QUERY = gql`
  fragment ResourceReservationListingReservation on ResourceReservation {
    customerVisibility
    description
    dimensions {
      selection {
        selectedLabel {
          name
        }
      }
    }
    internalNote
    end
    id
    purchaseProducts {
      id
      product {
        id
        name
      }
      totalPrice {
        amountVatIncluded
      }
    }
    resource {
      id
      name
      ... on ResourcePropertyNested {
        parentResource {
          id
          name
        }
      }
    }
    resourceQuantity
    start
    tasks {
      assignee {
        ... on User {
          email
          id
        }
        ... on UserGroup {
          id
          name
        }
      }
      description
      dueDate
      dueTime
      id
      number
      purchaseProducts {
        attributes {
          quantity
        }
        id
        product {
          id
          items {
            id
            unitPrice {
              id
              price
              vat {
                id
                vatRate
              }
            }
          }
          name
        }
        totalPrice {
          amountVatIncluded
        }
      }
    }
  }

  query ResourceReservationsListing($input: ResourceReservationsInput!) {
    resourceReservations(input: $input) {
      reservations {
        reservations {
          ...ResourceReservationListingReservation

          group {
            id
            name
            reservations {
              ...ResourceReservationListingReservation
              sales {
                id
                estimatedParticipants
              }
            }
          }

          sales {
            customer {
              customer {
                customerNumber

                ... on CustomerPerson {
                  person {
                    firstName
                    lastName
                  }
                }

                ... on CustomerOrganization {
                  organization {
                    name
                  }
                }
              }
            }
            estimatedParticipants
            facet {
              abbreviation
              color
              id
              name
            }
            id
            name
            orderNumber
            seller {
              id
              name
              shortName
            }
            type
          }
        }
      }
    }
  }
`

type Props = {
  date: Moment
  resourceIds: string[]
}

const useReservationList = ({ date, resourceIds }: Props) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !resourceIds.length,
    variables: {
      input: {
        end: date.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        resourceIds,
        start: date.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      },
    },
  })

  const reservations: Reservation[] = data
    ? data.resourceReservations.reservations.reduce(
        (acc: Reservation[], r) => [...acc, ...r.reservations],
        []
      )
    : []

  return {
    error,
    loading,
    reservations,
  }
}

export default useReservationList

export type ListingReservation = Reservation
export type ListingReservationResource = ReservationResource
export type ListingReservationTask = ReservationTask
export type ListingReservationTaskPurchase = ReservationTaskPurchase
export type ListingGroupReservation = NonNullable<
  Reservation['group']
>['reservations'][0]
