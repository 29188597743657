import styled, { css } from 'styled-components/macro'

import { CellProps } from './types'

const ListTH = styled.th<CellProps>`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(1)}rem;
  `}

  font-weight: 400;
  text-align: ${(props) => props.align || 'left'};
  vertical-align: ${(props) => props.verticalAlign || 'inherit'};
`

export default ListTH
