import { CSSProperties, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  options: {
    disabled?: boolean
    label: string | ReactNode
    value: string | null
  }[]
  value: string | null
  style?: CSSProperties
  handleChange: (value: string | null) => Promise<any>
}

const OptionSelector = ({ options, value, style, handleChange }: Props) => {
  const { palette } = useTheme()

  return (
    <FlexColumn noPadding style={style}>
      {options.map((option) => (
        <OptionWrapper
          onClick={() => handleChange(option.value)}
          key={option.value}
        >
          <CheckBoxWrapper>
            {value === option.value && (
              <FontAwesomeIcon
                fixedWidth
                color={palette.primary.dark}
                icon={'check'}
              />
            )}
          </CheckBoxWrapper>
          {option.label}
        </OptionWrapper>
      ))}
    </FlexColumn>
  )
}

export default OptionSelector

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.1s;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(0.5)}rem;
    background-color: ${theme.palette.white};
    border-bottom: 1px solid ${theme.palette.smoke.light};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:first-of-type {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const CheckBoxWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gutter};
  `}
`
