import type { DefaultThemeOptions, Theme, ThemeOptions } from '../types'
import generateColor from './generateColor'
import generateExtendedColor from './generateExtendedColor'

export default function generateTheme(
  opts: ThemeOptions,
  defaultOpts: DefaultThemeOptions
): Theme {
  const optsWithDefaults: DefaultThemeOptions = {
    branding: {
      ...defaultOpts.branding,
      ...opts.branding,
    },
    palette: {
      ...defaultOpts.palette,
      ...opts.palette,
      coal: {
        ...defaultOpts.palette.coal,
        ...opts.palette?.coal,
      },
      smoke: {
        ...defaultOpts.palette.smoke,
        ...opts.palette?.smoke,
      },
      text: {
        ...defaultOpts.palette.text,
        ...opts.palette?.text,
      },
    },
    spacing: {
      ...defaultOpts.spacing,
      ...opts.spacing,
    },
    typography: {
      ...defaultOpts.typography,
      ...opts.typography,
      fontFamily: `${
        opts.typography?.fontFamily ? `${opts.typography.fontFamily}, ` : ''
      }${defaultOpts.typography.fontFamily}`,
      fontFamilyHeading: `${
        opts.typography?.fontFamilyHeading
          ? `${opts.typography.fontFamilyHeading}, `
          : ''
      }${defaultOpts.typography.fontFamilyHeading}`,
    },
  }

  const dangerColor = generateColor(optsWithDefaults.palette.danger)
  const successColor = generateColor(optsWithDefaults.palette.success)
  const warningColor = generateColor(optsWithDefaults.palette.warning)

  const gridUnitSizeInRems =
    optsWithDefaults.spacing.gridUnitSizeInPx /
    optsWithDefaults.typography.fontSizeBaseInPx

  return {
    branding: {
      faviconSrc: optsWithDefaults.branding.faviconSrc,
      logoWideSrc: optsWithDefaults.branding.logoWideSrc,
      pageTitle: optsWithDefaults.branding.pageTitle,
    },
    palette: {
      accent1: generateExtendedColor(optsWithDefaults.palette.accent1),
      accent2: generateExtendedColor(optsWithDefaults.palette.accent2),
      accent3: generateExtendedColor(optsWithDefaults.palette.accent3),
      black: optsWithDefaults.palette.black,
      coal: {
        dark: optsWithDefaults.palette.coal.dark,
        light: optsWithDefaults.palette.coal.light,
        main: optsWithDefaults.palette.coal.main,
      },
      danger: dangerColor,
      gold: optsWithDefaults.palette.gold,
      primary: generateExtendedColor(optsWithDefaults.palette.primary),
      secondary: generateExtendedColor(optsWithDefaults.palette.secondary),
      smoke: {
        dark: optsWithDefaults.palette.smoke.dark,
        extraDark: optsWithDefaults.palette.smoke.extraDark,
        extraLight: optsWithDefaults.palette.smoke.extraLight,
        light: optsWithDefaults.palette.smoke.light,
        lighter: optsWithDefaults.palette.smoke.lighter,
        main: optsWithDefaults.palette.smoke.main,
      },
      success: successColor,
      text: {
        dark: optsWithDefaults.palette.text.dark,
        light: optsWithDefaults.palette.text.light,
        lighter: optsWithDefaults.palette.text.lighter,
        main: optsWithDefaults.palette.text.main,
        white: optsWithDefaults.palette.text.white,
      },
      warning: warningColor,
      white: optsWithDefaults.palette.white,
    },
    spacing: {
      gu(multiplier: number) {
        return multiplier * gridUnitSizeInRems
      },
      guPx(multiplier: number) {
        return multiplier * optsWithDefaults.spacing.gridUnitSizeInPx
      },
      gutter: `${gridUnitSizeInRems * 2}rem`,
      gutterBig: `${gridUnitSizeInRems * 4}rem`,
      gutterSmall: `${gridUnitSizeInRems * 0.5}rem`,
    },
    typography: {
      defaultFontWeight: optsWithDefaults.typography.defaultFontWeight,
      defaultFontWeightHeading:
        optsWithDefaults.typography.defaultFontWeightHeading,
      fontFamily: optsWithDefaults.typography.fontFamily,
      fontFamilyHeading: optsWithDefaults.typography.fontFamilyHeading,
      fontSizeBase: '1rem',
      fontSizeBase2: '1.1rem',
      fontSizeBaseInPx: optsWithDefaults.typography.fontSizeBaseInPx,
      fontSizeBig: '1.25rem',
      fontSizeBigger: '1.563rem',
      fontSizeLarge: '1.953rem',
      fontSizeLarger: '2.441rem',
      fontSizeSmall: '.925rem',
      fontSizeSmaller: '.85rem',
    },
  }
}
