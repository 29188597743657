import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

export const ListHeader = () => {
  return (
    <Wrapper>
      <Section width="8rem">
        <T>Customers:orders.field.state</T>
      </Section>
      <Section>
        <T>Customers:orders.field.name</T>
      </Section>
      <Section align="right">
        <T>Customers:orders.field.price</T>
      </Section>
      <Section>
        <T>Customers:orders.field.sales</T>
      </Section>
    </Wrapper>
  )
}
