import { gql } from '@apollo/client'

import { RoomReservationsForDateRoomFragment } from './roomReservationsForDateRoom'

export const RoomReservationsForDateRequestFragment = gql`
  ${RoomReservationsForDateRoomFragment}

  fragment RoomReservationsForDateRequest on RoomRequest {
    beds
    checkIn {
      date
      type
    }
    checkOut {
      date
      type
    }
    extraBeds
    features {
      icon
      id
      name
      shortName
    }
    info
    room {
      ...RoomReservationsForDateRoom
    }
  }
`
