import { gql, useMutation } from '@apollo/client'

import {
  SetParticipantCheckOutMutation,
  SetParticipantCheckOutMutationVariables,
} from '~generated-types'

import { RoomReservationFragment } from '../fragments'

export type {
  SetParticipantCheckOutMutation,
  SetParticipantCheckOutMutationVariables,
}

export const SET_PARTICIPANT_CHECK_OUT = gql`
  ${RoomReservationFragment}

  mutation SetParticipantCheckOut($input: MarkCheckedInput!) {
    salesParticipantCheckOut(input: $input) {
      roomReservation {
        ...RoomReservationsForDateRoomReservation
      }
    }
  }
`

export function useSetParticipantCheckOut() {
  return useMutation<
    SetParticipantCheckOutMutation,
    SetParticipantCheckOutMutationVariables
  >(SET_PARTICIPANT_CHECK_OUT)
}

export default useSetParticipantCheckOut
