import { gql } from '@apollo/client'

import { productFragments } from '@/modules/Products/fragments'
import { taskFragments } from '@/modules/Task/fragments'

import ResourceReservationDimensionsFragment from './resourceReservationDimensions'
import ResourceReservationResourceFragment from './resourceReservationResource'
import ResourceReservationSalesFragment from './resourceReservationSales'

export const ResourceReservationFragment = gql`
  ${productFragments.catalogProductOld}
  ${productFragments.productPurchase}
  ${ResourceReservationDimensionsFragment}
  ${ResourceReservationResourceFragment}
  ${ResourceReservationSalesFragment}
  ${taskFragments.task}

  fragment ResourceReservation on ResourceReservation {
    color
    customerVisibility
    description
    descriptionOptions
    dimensions {
      ...ResourceReservationDimensions
    }
    end
    group {
      end
      id
      name
      number
      start
    }
    id
    internalNote
    displayMessage
    issues {
      key
      code
      level
    }
    recommendedProducts {
      ...CatalogProductOld
    }
    resource {
      ...ResourceReservationResource
    }
    purchaseProducts {
      ...ProductPurchase
    }
    resourceQuantity
    sales {
      ...ResourceReservationSales
    }
    start
    tasks {
      ...Task
    }
  }
`

export default ResourceReservationFragment
