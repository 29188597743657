import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { FlexColumn } from '@/components/Layout'
import { PaymentsModal } from '@/modules/Order/components'
import { orderContexts } from '@/modules/Order/contexts'
import { orderQueries } from '@/modules/Order/queries'
import {
  Invoice,
  InvoiceByIdPayload,
  InvoiceByIdVariables,
  InvoiceInfo,
  OrderType as Order,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales/hooks'

import { CollapseWrapper, ContentWrapper } from '../common'
import { Content, Header } from './components'

type Props = {
  invoice: Invoice | InvoiceInfo
  isLastNested?: boolean
  isNested?: boolean
  order: Order
}

export const InvoicePhase = ({
  invoice,
  isLastNested,
  isNested,
  order,
}: Props) => {
  const { id } = invoice

  const { selectedPhaseId, setSelectedPhaseId } = orderContexts.useOrderView()
  const { setOrder } = salesHooks.useSalesDetailsContext()

  const invoiceFull = invoice as Invoice
  const isFull = invoiceFull.document !== undefined
  const isOpen = selectedPhaseId === id

  const [isContentFetched, setContentFetched] = useState<boolean>(false)
  const [isPaymentsModalOpen, setPaymentsModalOpen] = useState<boolean>(false)

  const { data, loading, refetch } = useQuery<
    InvoiceByIdPayload,
    InvoiceByIdVariables
  >(orderQueries.INVOICE_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !isOpen,
    variables: { id },
  })

  useEffect(() => {
    if (data) {
      setOrder({
        ...order,
        invoices: order.invoices.map((i) => (i.id === id ? data.invoice : i)),
      })
      setContentFetched(true)
    }
  }, [data])

  const toggleContent = () => {
    if (isOpen) {
      setSelectedPhaseId(null)
      setContentFetched(false)
    } else {
      setSelectedPhaseId(id)
    }
  }

  return (
    <FlexColumn>
      <Header
        isOpen={isOpen && isContentFetched}
        isLastNested={isLastNested}
        isLoading={loading}
        isNested={isNested}
        invoice={invoice}
        openPaymentsModal={() => setPaymentsModalOpen(true)}
        orderId={order.id}
        toggleContent={toggleContent}
      />

      <CollapseWrapper isOpen={isOpen && isContentFetched}>
        <FlexColumn>
          {isFull && (
            <ContentWrapper>
              <Content
                invoice={invoiceFull}
                isVisible={isOpen}
                openPaymentsModal={() => setPaymentsModalOpen(true)}
                order={order}
                refetch={refetch}
              />
            </ContentWrapper>
          )}
        </FlexColumn>
      </CollapseWrapper>

      {isPaymentsModalOpen && invoiceFull.document && invoiceFull.seller && (
        <PaymentsModal
          documentId={invoiceFull.document.id}
          invoice={invoiceFull}
          onClose={() => setPaymentsModalOpen(false)}
          orderId={order.id}
          sellerId={invoiceFull.seller.id}
        />
      )}
    </FlexColumn>
  )
}
