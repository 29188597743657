import moment, { Moment } from 'moment'

import { ColumnDetails } from '@/components/Grid/PresentationState'

export const getStartEndFromColumnData = (
  date: Moment,
  columns: ColumnDetails[]
) => {
  const dateStr = date.format('YYYY-MM-DD')
  const startTime = columns[0].index < 0 ? '00:00' : columns[0].data.start
  const endTime =
    columns.slice(-1)[0].index > 94 ? '23:59' : columns.slice(-1)[0].data.end

  const start = moment(`${dateStr}T${startTime}`).format('YYYY-MM-DDTHH:mm:ss')
  const end = moment(`${dateStr}T${endTime}`).format('YYYY-MM-DDTHH:mm:ss')

  return [start, end]
}
