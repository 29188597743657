import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Dropdown,
  DropdownPositioningProps,
  DropdownRenderProps,
} from '@/components/Dropdown'
import { useTheme } from '@/theme'

import {
  ActionDropdownButton,
  ActionDropdownLink,
  ActionDropdownList,
  ActionDropdownListItem,
  ActionDropdownRRLink,
} from './components'

interface DropdownItemBase {
  disabled?: boolean
  icon?: IconProp
  label: ReactNode
}

interface DropdownButton extends DropdownItemBase {
  onClick: (...args: Array<any>) => any
}

interface DropdownLink extends DropdownItemBase {
  href: string
  target?: string
}

interface DropdownRRLink extends DropdownItemBase {
  to: string
}

export type DropdownItem = DropdownButton | DropdownLink | DropdownRRLink

type Props = {
  items: Array<DropdownItem>
  positioning?: DropdownPositioningProps
  renderToggle: (arg0: DropdownRenderProps) => ReactNode
}

export const ActionDropdown = ({ items, positioning, renderToggle }: Props) => {
  const theme = useTheme()

  const renderLabel = (item: DropdownItem): ReactNode => (
    <>
      {item.icon && (
        <FontAwesomeIcon
          color={theme.palette.text.light}
          fixedWidth
          icon={item.icon}
          size="sm"
          style={{
            marginRight: '0.5rem',
          }}
        />
      )}
      {item.label}
    </>
  )

  return (
    <div style={{ position: 'relative' }}>
      <Dropdown
        isInline
        noPadding
        positioning={positioning || { right: '0', top: '30px' }}
        renderControl={(renderProps) => renderToggle(renderProps)}
        renderContent={({ toggleDropdown }: DropdownRenderProps) => (
          <ActionDropdownList>
            {items.map((item, idx) =>
              'onClick' in item ? (
                <ActionDropdownListItem key={'dropdown-' + idx}>
                  <ActionDropdownButton
                    disabled={item.disabled}
                    onClick={(e: any) => {
                      toggleDropdown(e)
                      item.onClick()
                    }}
                    type="button"
                  >
                    {renderLabel(item)}
                  </ActionDropdownButton>
                </ActionDropdownListItem>
              ) : 'href' in item ? (
                <ActionDropdownLink
                  key={'dropdown-' + idx}
                  onClick={(e: any) => {
                    toggleDropdown(e)
                  }}
                  rel="noopener noreferrer"
                  target={item.target}
                  href={item.href}
                >
                  {renderLabel(item)}
                </ActionDropdownLink>
              ) : (
                <ActionDropdownRRLink
                  key={'dropdown-' + idx}
                  onClick={(e: any) => {
                    toggleDropdown(e)
                  }}
                  to={item.to}
                >
                  {renderLabel(item)}
                </ActionDropdownRRLink>
              )
            )}
          </ActionDropdownList>
        )}
      />
    </div>
  )
}
