import styled, { css } from 'styled-components/macro'

export const ContentSidebarTitle = styled.h4`
  flex-grow: 1;
  margin: 0;
  padding: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
  `}

  font-weight: 500;
  text-transform: uppercase;
`
