import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T } from '@/modules/Language'
import { orderMutations } from '@/modules/Order/mutations'
import { orderServices } from '@/modules/Order/services'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  AdvanceType,
  CreateOrderType,
  InvoicePaymentType as IPT,
  OrderAction as OA,
} from '~generated-types'

import { Confirmation, Invoice, Offer } from '../types'

export default function OrderService() {
  const { confirm } = useDialogService()

  const { createAdvance, createInvoice } = orderServices.invoiceService()
  const { createConfirmation } = orderServices.confirmationService()
  const { createOffer } = orderServices.offerService()
  const { ordersById, setOrder, setOrdersById } =
    salesHooks.useSalesDetailsContext()

  const [createOrderMutation] = orderMutations.useCreateOrderMutation()
  const [removeOrderMutation] = orderMutations.useRemoveOrderMutation()
  const [setOrderCustomerMutation] =
    orderMutations.useSetOrderCustomerMutation()
  const [updateOrderMutation] = orderMutations.useUpdateOrderMutation()

  const createOrder = (salesId: string, name?: string) =>
    createOrderMutation({
      variables: { input: { name, orderType: CreateOrderType.Order, salesId } },
    })
      .then(({ data }) => {
        const newOrder = data?.orderCreate

        if (newOrder) {
          setOrder(newOrder)
        }

        return newOrder
      })
      .catch(() => undefined)

  const removeOrder = (orderId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Orders:Confirmation.REMOVE_ORDER.description</T>,
      title: <T>Orders:Confirmation.REMOVE_ORDER.title</T>,
    })
      .then(() =>
        removeOrderMutation({ variables: { id: orderId } })
          .then(({ data }) => {
            const orderId = data?.orderDelete.id

            if (orderId) {
              const { [orderId]: removedOrder, ...rest } = ordersById
              setOrdersById(rest)
            }

            return data
          })
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const setCustomer = (
    orderId: string,
    customerId: string | null | undefined,
    addressId: string | null | undefined,
    customerContactId: string | null | undefined
  ) =>
    setOrderCustomerMutation({
      variables: {
        input: { addressId, customerContactId, customerId, orderId },
      },
    })
      .then(({ data }) => data && setOrder(data.orderSetCustomer.order))
      .catch(() => undefined)

  const triggerCreateAction = (
    orderId: string,
    action: OA
  ): Promise<Confirmation | Invoice | Offer | undefined> => {
    switch (action) {
      case OA.CreateAutomaticAdvance:
        return createAdvance(orderId, AdvanceType.Automatic)
      case OA.CreateConfirmation:
        return createConfirmation(orderId)
      case OA.CreateInvoice:
        return createInvoice(orderId, IPT.Invoice)
      case OA.CreateManualAdvance:
        return createAdvance(orderId, AdvanceType.Manual)
      case OA.CreateOffer:
        return createOffer(orderId)
      case OA.CreateRefundUnpaidAdvances:
        return createAdvance(orderId, AdvanceType.RefundUnpaidAdvances)
      default:
        return new Promise(() => {
          console.warn(`Unsupported order action [${action}]`)
          return undefined
        })
    }
  }

  const updateOrder = (orderId: string, name: string | null) =>
    updateOrderMutation({
      variables: { input: { id: orderId, name } },
    })
      .then(({ data }) => data && setOrder(data.orderUpdate))
      .catch(() => undefined)

  return {
    createOrder,
    removeOrder,
    setCustomer,
    triggerCreateAction,
    updateOrder,
  }
}
