import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import ModalHeader from './common/ModalHeader'
import ModalNoteEditor from './common/ModalNoteEditor'
import RoomFriends from './components/RoomFriends'

type Props = {
  note: string
  roomFriends: string[]
  readOnly?: boolean
  handleUpdateNote: (note: string) => Promise<FetchedParticipant | undefined>
  handleUpdateRoomFriends: (roomFriends: string[]) => Promise<any>
}

const Request = ({
  note,
  roomFriends,
  readOnly,
  handleUpdateNote,
  handleUpdateRoomFriends,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalContainer
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            ref={ref}
            disabled={readOnly}
            width={`${spacing.gu(20)}rem`}
            onClick={readOnly ? () => null : () => setModalOpen(true)}
            renderContent={() =>
              note || roomFriends.length ? (
                <ContentWrapper>
                  <FontAwesomeIcon
                    color={note ? palette.warning.dark : palette.text.light}
                    icon={['far', 'note-sticky']}
                    size="lg"
                    style={{ margin: `0 ${spacing.gutterSmall}` }}
                  />
                  <FontAwesomeIcon
                    color={palette.coal.dark}
                    size="xs"
                    icon={'plus'}
                    style={{ margin: `0 ${spacing.gu(1)}rem` }}
                  />
                  <FontAwesomeIcon
                    color={
                      roomFriends.length
                        ? palette.primary.dark
                        : palette.coal.light
                    }
                    icon="user-group"
                    size="lg"
                    style={{ margin: `0 ${spacing.gutterSmall}` }}
                  />
                  x {roomFriends.length}
                </ContentWrapper>
              ) : (
                <>
                  <Label>
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      icon={'plus'}
                      style={{ marginRight: `${spacing.gu(1)}rem` }}
                    />
                    <T>ParticipantsList:PersonalFields.accommodationRequest</T>
                  </Label>
                </>
              )
            }
          />
        )}
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        modal={
          <ModalWrapper>
            <ModalHeader
              title={<T>ParticipantsList:PersonalFields.requestManager</T>}
              onClose={() => setModalOpen(false)}
            />
            <ModalNoteEditor note={note} handleUpdateNote={handleUpdateNote} />
            <RoomFriends
              roomFriends={roomFriends}
              handleUpdateRoomFriends={handleUpdateRoomFriends}
            />
          </ModalWrapper>
        }
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

export default Request

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`

const ModalWrapper = styled(InlineModal)`
  max-height: 100vh;
  width: 450px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`
