import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { PrintLink } from '@/modules/Listing/common'

import {
  ParticipantReservation,
  ReservationCustomer,
  ReservationSales,
} from '../../types'

interface Props {
  data: ParticipantReservation | null
}

export const SalesCell = ({ data }: Props) =>
  data ? (
    <td>
      <FlexColumn noPadding>
        {getSalesLabel(data.participant.sales)}
        {getCustomerLabel(data.participant.sales.customer?.customer)}
      </FlexColumn>
    </td>
  ) : (
    <td />
  )

/////

const getCustomerLabel = (customer?: ReservationCustomer) => {
  return customer ? (
    <PrintLink to={`/registry/customers/${customer?.customerNumber}`}>
      #{customer.customerNumber} {parseCustomerName(customer) || '—'}
    </PrintLink>
  ) : (
    '—'
  )
}

const getSalesLabel = ({ id, name, orderNumber, type }: ReservationSales) => (
  <PrintLink to={`/sales/details/${id}`}>
    #{orderNumber}{' '}
    {name ? (
      name
    ) : (
      <em>
        <T l10n={`Listings:AccommodationGuestListing.unnamed.sales.${type}`} />
      </em>
    )}
  </PrintLink>
)

const parseCustomerName = (customer: ReservationCustomer) =>
  customer.__typename === 'CustomerOrganization'
    ? customer.organization.name
    : parseName({
        firstName: customer?.person.firstName || '',
        lastName: customer?.person.lastName || '',
      })

const parseName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`
