import { gql, useMutation } from '@apollo/client'

import { CopySaleMutation, CopySaleMutationVariables } from '~generated-types'

const MUTATION = gql`
  mutation CopySale($input: SalesCopyInput!) {
    salesCopy(input: $input) {
      sales {
        id
      }
    }
  }
`

export const useCopySaleMutation = () =>
  useMutation<CopySaleMutation, CopySaleMutationVariables>(MUTATION)
