import { gql, useMutation } from '@apollo/client'

import {
  AccommodationSettingsFragment,
  RoomTypeReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

import {
  RoomTypeReservationSetDatesMutation,
  RoomTypeReservationSetDatesMutationVariables,
} from '~generated-types'

export type {
  RoomTypeReservationSetDatesMutation,
  RoomTypeReservationSetDatesMutationVariables,
}

export const ROOM_TYPE_RESERVATION_SET_DATES = gql`
  ${RoomTypeReservationFragment}
  ${AccommodationSettingsFragment}

  mutation RoomTypeReservationSetDates(
    $input: AccommodationRoomTypeReservationSetDatesInput!
  ) {
    accommodationRoomTypeReservationSetDates(input: $input) {
      roomTypeReservation {
        ...RoomTypeReservation
      }
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export function useRoomTypeReservationSetDates() {
  return useMutation<
    RoomTypeReservationSetDatesMutation,
    RoomTypeReservationSetDatesMutationVariables
  >(ROOM_TYPE_RESERVATION_SET_DATES)
}

export default useRoomTypeReservationSetDates
