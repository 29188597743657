import { gql, useMutation } from '@apollo/client'

import {
  UpdatePublicRoomMutation,
  UpdatePublicRoomMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.publicRoom}

  mutation UpdatePublicRoom($input: PublicRoomUpdateInput!) {
    publicRoomUpdate(input: $input) {
      publicRoom {
        ...PublicRoom
      }
    }
  }
`

export const useUpdatePublicRoomMutation = () =>
  useMutation<UpdatePublicRoomMutation, UpdatePublicRoomMutationVariables>(
    MUTATION
  )
