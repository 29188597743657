export const getDocumentsByPath = (rawAggs: {
  [key: string]: any
}): {
  [path: string]: number
} => {
  const counts: { [key: string]: number } = {}
  const buckets = rawAggs?.categories?.path?.buckets || []

  if (Array.isArray(buckets)) {
    buckets.forEach((x) => {
      if (x.key && typeof x.doc_count === 'number') {
        counts[x.key] = x.doc_count
      }
    })
  }

  return counts
}
