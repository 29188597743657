import { gql } from '@apollo/client'

export const MealTypeFragment = gql`
  fragment MealType on Meal {
    id
    name
    defaultRestaurant {
      id
      name
    }
    defaultStartTime
    defaultDuration
  }
`
