import styled, { css } from 'styled-components'

import { useTheme } from '@/theme'
import { typeGuardFactory } from '@/utils/types'

import { CheckInOutTimes } from '../../common'
import { parseParticipantName, parseParticipantProduct } from '../../utils'
import {
  ArrivalsParticipant,
  ArrivalsRoomReservation,
} from '../useArrivalsForDate'

type Props = {
  participant: ArrivalsParticipant
  roomsById: Record<string, ArrivalsRoomReservation>
}

export const EventsTableParticipantRow = ({
  participant,
  roomsById,
}: Props) => {
  const theme = useTheme()

  const participantBedService = participant.services.filter(
    typeGuardFactory('__typename', 'ServiceParticipantBed')
  )[0]

  const roomReservationId =
    participantBedService?.participantRoom?.roomReservation.id
  const roomReservation = roomReservationId
    ? roomsById[roomReservationId]
    : undefined

  const orderNumber = participant.sales.orderNumber
  const paymentAgreement = participant.sales.paymentAgreement?.name

  if (!participantBedService) return <></>

  return (
    <tr>
      <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
        {parseParticipantName(participant) || ''}
      </td>
      <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
        {participantBedService?.accommodationTarget?.name || '—'}
      </td>
      <td colSpan={2} style={{ maxWidth: '50mm', wordBreak: 'break-word' }}>
        <span>{parseParticipantProduct(participant, theme)}</span>
        {participantBedService.purchaseProduct && paymentAgreement && (
          <Label>{paymentAgreement}</Label>
        )}
      </td>
      <td>
        {roomReservation?.request.room.number
          ? `#${roomReservation?.request.room.number}`
          : '—'}
      </td>
      <td>{orderNumber ? `#${orderNumber}` : ''}</td>
      <td colSpan={2} style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
        {participant.additionalInfo || '—'}
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {participantBedService?.dates?.checkIn &&
        participantBedService?.dates?.checkOut ? (
          <CheckInOutTimes
            checkIn={participantBedService.dates.checkIn}
            checkOut={participantBedService.dates.checkOut}
          />
        ) : null}
      </td>
    </tr>
  )
}

////////////

const Label = styled.span`
  display: inline-block;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
    padding-top: ${theme.spacing.gu(1)}rem;
    border-top: 1px solid ${theme.palette.smoke.dark};
  `}
`
