import { ChangeEvent, CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

import { InlineLabel } from '../InlineLabel'
import { Input } from '../Input'
import { InputIndicatorBase } from '../InputIndicatorBase'

type RCProps = {
  disabled?: boolean
}

type RIProps = {
  checked?: boolean
  children?: ReactNode
  color?: string
  disabled?: boolean
  noMargin?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  style?: CSSProperties
}

const StyledRadioInput = styled(Input)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
`

const RadioCircle = styled(InputIndicatorBase)<RCProps>`
  border-radius: 100%;

  &::before {
    border-radius: 100%;
    width: 7px;
    height: 7px;
  }
`

const Label = styled(InlineLabel)`
  & ${StyledRadioInput}:active ~ ${RadioCircle} {
    box-shadow: 0 0 0 2px rgba(0, 149, 218, 0.3);
  }

  & ${StyledRadioInput}:focus ~ ${RadioCircle} {
    box-shadow: 0 0 0 2px rgba(0, 149, 218, 0.3);
  }

  & ${StyledRadioInput}:checked ~ ${RadioCircle} {
    background: ${({ color, theme }) => color || theme.palette.primary.main};
    border-color: ${({ color, theme }) => color || theme.palette.primary.main};
  }

  & ${StyledRadioInput}:checked:disabled ~ ${RadioCircle} {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      border-color: ${theme.palette.smoke.main};
    `}
  }

  & ${StyledRadioInput}:indeterminate ~ ${RadioCircle} {
    background: ${({ color, theme }) => color || theme.palette.primary.light};
    border-color: ${({ color, theme }) => color || theme.palette.primary.light};
  }

  & ${StyledRadioInput}:indeterminate:disabled ~ ${RadioCircle} {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      border-color: ${theme.palette.smoke.main};
    `}
  }

  & ${StyledRadioInput}:checked ~ ${RadioCircle}::before {
    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.dark
        : props.theme.palette.white};
  }

  & ${StyledRadioInput}:indeterminate ~ ${RadioCircle} {
    ${({ theme }) => css`
      background: ${theme.palette.white};
      border-color: ${theme.palette.smoke.dark};
    `}
  }
`

const RadioInput = ({
  children,
  color,
  disabled,
  noMargin,
  style,
  ...props
}: RIProps) => {
  const theme = useTheme()

  return (
    <Label
      color={color}
      disabled={disabled}
      style={
        noMargin
          ? { ...style, margin: 0 }
          : { ...style, marginRight: theme.spacing.gutterSmall }
      }
    >
      <StyledRadioInput {...props} disabled={disabled} type="radio" />
      <RadioCircle color={color} disabled={disabled} />
      {children}
    </Label>
  )
}

export default RadioInput
