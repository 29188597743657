import { gql } from '@apollo/client'

export const RoomReservationFragment = gql`
  fragment RoomLayoutRoomReservation on RoomReservation {
    id
    request {
      room {
        id
        number
        features {
          id
          icon
          name
          shortName
        }
        floor
        internalInfo
        roomType {
          id
          name
          accommodationLevel {
            id
            name
            shortName
          }
        }
        beds
        extraBeds
      }
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      beds
      extraBeds
      features {
        id
        icon
        name
        shortName
      }
      info
    }
  }
`

export const RoomTypeReservationFragment = gql`
  ${RoomReservationFragment}

  fragment RoomLayoutRoomTypeReservation on RoomTypeReservation {
    id
    request {
      roomType {
        id
        name
        accommodationLevel {
          id
          name
          shortName
        }
        beds
        extraBeds
      }
      rooms
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      beds
      extraBeds
      features {
        id
        icon
        name
        shortName
      }
      info
    }
    fulfilledByRooms
    roomReservations {
      ...RoomLayoutRoomReservation
    }
  }
`
