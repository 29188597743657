import styled, { css } from 'styled-components/macro'

import { DataTableInput } from '@/components/DataTable'
import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'

type Props = {
  amount: number
  disabled: boolean
  updateAmount: (amount: number) => void
}

type InternalProps = HOCProps & {
  disabled: boolean
  updateValue: (amount: number) => void
}

export const AmountInput = ({ amount, disabled, updateAmount }: Props) => (
  <InputWithPriceLogic
    disabled={disabled}
    updateValue={updateAmount}
    value={`${amount}`}
  />
)

const InputBase = ({ updateValue, value, ...props }: InternalProps) => (
  <Input
    {...props}
    endAdornment="€"
    flex="none"
    onBlur={() => updateValue(Number(value || 0))}
    onFocus={(e) => e.currentTarget.select()}
    showBorder
    type="text"
    value={value}
  />
)

const InputWithPriceLogic = withPriceInputLogic(InputBase)

///////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0px ${theme.spacing.gu(3)}rem 0px ${theme.spacing.gu(1)}rem;
      width: ${theme.spacing.gu(12)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`
