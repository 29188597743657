import { ById } from '@/common/types'
import { T } from '@/modules/Language'
import { CalendarResourceElastic } from '@/modules/Registry/CalendarResources'
import { CalendarViewMode, CategorySelection } from '@/modules/Reservations'

import { getGridGroupSection } from './getGridGroupSection'

type Props = {
  allResourcesById: ById<CalendarResourceElastic>
  categorySelection: CategorySelection
  idx: number
  targetDate: string
  viewMode: CalendarViewMode
}

export const getGridGroupForCategorySelection = ({
  allResourcesById,
  categorySelection: { id: groupId, label, resourceIds },
  idx,
  targetDate,
  viewMode,
}: Props) => ({
  emptyPlaceholder: resourceIds.length ? (
    <T>ResourceReservationsCalendar:GridGroup.noMatchingResources</T>
  ) : (
    <T>ResourceReservationsCalendar:GridGroup.noSelectedResources</T>
  ),
  error: null,
  fetching: false,
  id: groupId,
  isFocused: false,
  sections: resourceIds
    .map((x) => allResourcesById[x])
    .filter(Boolean)
    .map((resource, idx, arr) =>
      getGridGroupSection({
        isLast: idx === arr.length - 1,
        isNested: false,
        resource,
        targetDate,
        viewMode,
      })
    ),
  title: label,
  titlePlaceholder: (
    <span>
      <T>ResourceReservationsCalendar:ResourceSearchGroup.title</T> #{idx + 1}
    </span>
  ),
})
