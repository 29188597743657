import { gql } from '@apollo/client'

import webshopSalesToken from './webshopSalesToken'

export default gql`
  ${webshopSalesToken}

  fragment SalesWebshopSettings on WebshopSettings {
    id
    contacts {
      id
      name
    }
    customerInformation
    fieldsConfig {
      participant {
        field
        status
      }
    }
    theme {
      id
      description
      name
    }
    tokens {
      ...WebshopSalesToken
    }
  }
`
