import React, { ReactNode } from 'react'
import { Translation } from 'react-i18next'

import '../config'

type tProps = {
  children?: ReactNode
  l10n?: string
  [key: string]: any
}

const T = ({ children, l10n, ...rest }: tProps) => {
  return (
    <Translation useSuspense={false}>
      {(t: (...args: any[]) => ReactNode) => t(l10n || children, rest)}
    </Translation>
  )
}

export default T
