import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input, Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InputEditor, ToggleEditableValue } from '@/components/ToggleHelpers'
import { T } from '@/modules/Language'
import * as salesMutations from '@/modules/Sales/mutations'
import { useTheme } from '@/theme'

import { useSalesDetailsContext } from '../../../SalesDetailsState'

export const CommissionRate = () => {
  const { spacing } = useTheme()
  const {
    data: { commissionRate, id },
    saleReadOnly,
  } = useSalesDetailsContext()

  const [value, setValue] = useState<number>(commissionRate)

  const [updateCommission] = salesMutations.useUpdateSalesCommissionMutation()

  useEffect(() => {
    setValue(commissionRate)
  }, [commissionRate])

  const handleUpdateCommission = (commission: number) => {
    if (commission === commissionRate) {
      return new Promise<void>((reject) => reject())
    }

    const validatedCommission =
      commission > 100 ? 100 : commission < 0 ? 0 : commission

    setValue(validatedCommission)

    return updateCommission({
      variables: {
        input: {
          commissionRate: validatedCommission,
          salesId: id,
        },
      },
    }).catch(() => setValue(commissionRate))
  }

  return (
    <Wrapper flex={1} noPadding>
      <Label>
        <T>SalesDetails:Settings.commissionRate</T>
      </Label>

      {saleReadOnly ? (
        <Placeholder alignItems="center">{value} %</Placeholder>
      ) : (
        <StyledToggleEditableValue
          mode="input"
          renderEditor={(onClose) => (
            <InputEditor
              close={onClose}
              handleSubmit={(v: string | null) =>
                handleUpdateCommission(v ? Number(v) : commissionRate)
              }
              inputComponent={Input}
              onFocus={(e) => e.currentTarget.select()}
              style={{
                height: 30,
                margin: `0 -${spacing.gu(1)}rem`,
                maxWidth: `${spacing.gu(13)}rem`,
              }}
              type="number"
              value={`${value}`}
            />
          )}
          style={{ height: 30 }}
          value={`${value} %`}
        />
      )}
    </Wrapper>
  )
}

////////

const StyledToggleEditableValue = styled(ToggleEditableValue)`
  ${({ theme }) => css`
    margin: 0 -${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled(FlexRow)`
  height: 30px;
  cursor: not-allowed;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`
