import { FC, ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { SalesDetails } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import {
  LocalStoragePosition,
  SalesForDate,
  SalesForDateStats,
} from '../../../types'
import { CustomerInfo } from './CustomerInfo'
import { ListItemColumn } from './ListItemColumn'

interface Props {
  data: SalesForDate
  customerData?: NonNullable<SalesForDateStats['customer']>['customer']
  salesStats?: SalesForDateStats['visitStats']
  onClose: () => void
  localStoragePosition: LocalStoragePosition | null
  onSaleSectionsUpdate: (sections: (string | null)[]) => void
}

export const FocusedCustomer: FC<Props> = ({
  data,
  customerData,
  salesStats,
  onClose,
  localStoragePosition,
  onSaleSectionsUpdate,
}): ReactElement => {
  const theme = useTheme()

  const { id } = data
  const { url } = useRouteMatch()

  return (
    <>
      <Header>
        <ListItemColumn
          style={{
            alignItems: 'center',
            paddingLeft: `${theme.spacing.gu(1)}rem`,
            paddingRight: 0,
          }}
          width="50px"
        >
          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon="xmark" size="2x" />
            <span style={{ fontVariantCaps: 'all-small-caps' }}>
              <T>common:action.close</T>
            </span>
          </CloseButton>
        </ListItemColumn>
        <CustomerInfo
          data={data}
          customerData={customerData}
          salesStats={salesStats}
          showHeaders
        />
      </Header>
      <ScrollContent>
        <SalesDetails
          baseRoute={url}
          id={id}
          viewType="secondary"
          externalEmbeddedSections={
            localStoragePosition?.salesId === id
              ? localStoragePosition?.sections ?? undefined
              : undefined
          }
          onSectionsUpdate={onSaleSectionsUpdate}
        />
      </ScrollContent>
    </>
  )
}

////////////

const CloseButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  outline: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
    `}
  }

  & > span {
    font-variant-caps: all-small-caps;
  }
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 2;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    padding-top: ${theme.spacing.gutterSmall};
    padding-bottom: ${theme.spacing.gutter};
  `}
`

const ScrollContent = styled.section`
  flex: 1 1 auto;
  overflow: auto;
`
