export enum TaskStatus {
  All = 'ALL',
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type TaskStatusStats = {
  [TaskStatus.All]: number
  [TaskStatus.Closed]: number
  [TaskStatus.Open]: number
}
