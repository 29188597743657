import styled, { css } from 'styled-components/macro'

import { RadioInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { CollapsedFilterValue } from '@/components/Reactivesearch'
import { CountLabel } from '@/components/TreeSelector'
import { T } from '@/modules/Language'

import { TaskStatus, TaskStatusStats } from './types'

interface Props {
  componentId: string
  isCollapsed?: boolean
  l10nPrefix: string
  stats: TaskStatusStats
  toggleValue: (state: TaskStatus) => void
  value: TaskStatus
}

export const TaskStatusFilter = ({
  componentId,
  isCollapsed,
  l10nPrefix,
  stats,
  toggleValue,
  value,
}: Props) => {
  if (isCollapsed) {
    return (
      <CollapsedFilterValue
        componentId={componentId}
        placeholder={<T>Reactivesearch:unfiltered</T>}
      />
    )
  }

  return (
    <>
      <RadioInputWrapper>
        <RadioInput
          checked={value === TaskStatus.All}
          onChange={() => toggleValue(TaskStatus.All)}
        >
          <T l10n={`${l10nPrefix}.${TaskStatus.All}`} />
          <Spacer />
          <CountLabel>{stats[TaskStatus.All]}</CountLabel>
        </RadioInput>
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput
          checked={value === TaskStatus.Open}
          onChange={() => toggleValue(TaskStatus.Open)}
        >
          <T l10n={`${l10nPrefix}.${TaskStatus.Open}`} />
          <Spacer />
          <CountLabel>{stats[TaskStatus.Open]}</CountLabel>
        </RadioInput>
      </RadioInputWrapper>
      <RadioInputWrapper>
        <RadioInput
          checked={value === TaskStatus.Closed}
          onChange={() => toggleValue(TaskStatus.Closed)}
        >
          <T l10n={`${l10nPrefix}.${TaskStatus.Closed}`} />
          <Spacer />
          <CountLabel>{stats[TaskStatus.Closed]}</CountLabel>
        </RadioInput>
      </RadioInputWrapper>
    </>
  )
}

////////////

const RadioInputWrapper = styled(FlexRow)`
  &&&& label {
    flex: 1;
    margin-left: 2px;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    span {
      border-width: 1px;
    }

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.main};
      `}

      span {
        ${({ theme }) => css`
          border-color: ${theme.palette.primary.main};
        `}
      }
    }
  }
`

const Spacer = styled.span`
  flex: 1;
`
