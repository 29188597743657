import { Fragment, useState } from 'react'
import { useQuery } from '@apollo/client'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { HeaderSectionSeparator } from '@/components/ExpansionPanel'
import { CheckboxInput } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'
import { RoomLayoutQueries } from '@/modules/Reservations/components/RoomLayout'
import { useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { RoomFeaturesQuery, RoomFeaturesQueryVariables } from '~generated-types'

type FeatureSelected = RoomFeaturesQuery['registry']['roomFeatures'][0] & {
  selected: boolean
}

type Props = {
  selectedFeatures: RoomFeature[]
  readOnly?: boolean
  updateFeatures: (featureIds: string[]) => Promise<any>
}

export const Features = ({
  selectedFeatures,
  readOnly,
  updateFeatures,
}: Props) => {
  const { spacing } = useTheme()

  const { checkIconValidity } = useGlobalIconsContext()

  const [isModalOpen, setModalOpen] = useState(false)
  const [features, setFeatures] = useState<FeatureSelected[]>([])

  useQuery<RoomFeaturesQuery, RoomFeaturesQueryVariables>(
    RoomLayoutQueries.ROOM_FEATURES,
    {
      onCompleted: ({ registry }) => {
        setFeatures(
          registry.roomFeatures.length
            ? registry.roomFeatures.map((feature) => ({
                ...feature,
                selected: !!selectedFeatures.find(
                  (selectedFeature: RoomFeature) =>
                    selectedFeature.id === feature.id
                ),
              }))
            : []
        )
      },
    }
  )

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
            <FlexColumn noPadding>
              {[...features].sort(generateCompareFn('name')).map((feature) => (
                <CheckboxWrapper key={feature.id}>
                  <CheckboxInput
                    checked={feature.selected}
                    noMargin
                    onChange={() =>
                      setFeatures(
                        features.map((f) =>
                          f.id === feature.id
                            ? { ...f, selected: !f.selected }
                            : f
                        )
                      )
                    }
                  >
                    <FlexRow alignItems="center">
                      <CheckboxLabel>{feature.name}</CheckboxLabel>
                      {checkIconValidity(feature.icon) && (
                        <FontAwesomeIcon
                          fixedWidth
                          icon={feature.icon as IconProp}
                          size="sm"
                        />
                      )}
                    </FlexRow>
                  </CheckboxInput>
                </CheckboxWrapper>
              ))}
            </FlexColumn>
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => {
        setModalOpen(false)
        updateFeatures(features.filter((f) => f.selected).map((f) => f.id))
      }}
      placement="bottom"
      referenceElement={({ ref }) => (
        <NeedsWrapper
          ref={ref}
          disabled={readOnly}
          onClick={() => (readOnly ? null : setModalOpen(true))}
        >
          <HeaderSectionSeparator />
          <FlexRow style={{ padding: `0 ${spacing.gu(1)}rem` }}>
            {selectedFeatures.length ? (
              [...selectedFeatures]
                .sort(generateCompareFn('name'))
                .map((feature) => (
                  <Fragment key={feature.id}>
                    {checkIconValidity(feature.icon) ? (
                      <FontAwesomeIcon
                        fixedWidth
                        icon={feature.icon as IconProp}
                        style={{ marginRight: spacing.gutterSmall }}
                      />
                    ) : (
                      <ShortName>{feature.shortName}</ShortName>
                    )}
                  </Fragment>
                ))
            ) : (
              <Placeholder>
                <T>Accommodation:SalesReservationList.addFeatures</T>
              </Placeholder>
            )}
          </FlexRow>
          <HeaderSectionSeparator />
        </NeedsWrapper>
      )}
    />
  )
}

const NeedsWrapper = styled(FlexRow)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: 0.2s;

  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${theme.palette.smoke.light};
    cursor: disabled;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

const Placeholder = styled.span`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const ShortName = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
