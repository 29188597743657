import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { lighten } from 'polished'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components/macro'

import { OutsideClickHelper } from '@/components/OutsideClick'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { NavSortBy } from '~generated-types'

import {
  NavDropdownExternalItem,
  NavDropdownInternalItem,
  NavDropdownItemType,
  NavItem,
} from '../../types'
import { ReactComponent as UpArrow } from './arrow-up-with.svg'

export type NavbarButtonProps = {
  item: NavItem
}

export const NavbarButton = ({
  item: { items, key, sort },
}: NavbarButtonProps) => {
  const { language } = useLanguageContext()
  const { pathname } = useLocation()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const dropdownItems = items.map((item) => {
    const externalItem = item as NavDropdownExternalItem
    const internalItem = item as NavDropdownInternalItem

    return item.type === NavDropdownItemType.InternalItem
      ? {
          isEmbedded: !internalItem.isExternalUrl,
          key: internalItem.key,
          label: translate(
            `Navigation:navDropdownItem.${internalItem.key}`,
            language
          ),
          openInNewTab: internalItem.openInNewTab,
          to: internalItem.to,
        }
      : {
          isEmbedded: externalItem.isEmbedded,
          key: externalItem.to,
          label: externalItem.name,
          openInNewTab: externalItem.openInNewTab,
          to: externalItem.isEmbedded ? externalItem.to : externalItem.url,
        }
  })

  const sortedDropdownItems =
    sort === NavSortBy.Name
      ? dropdownItems.sort(generateCompareFn('label'))
      : dropdownItems

  const activeItem = sortedDropdownItems.find(({ to }) => {
    const match = pathname.match(/^\/[^/]+\/[^/]+/gm)

    return match && to === match[0]
  })

  return (
    <NavigationButtonWrapper className={classNames({ 'is-open': isOpen })}>
      <OutsideClickHelper
        onOutsideClick={() => setIsOpen(false)}
        render={({ ref }) => (
          <StyledNavDropdownToggle
            className={classNames({ 'is-active': !!activeItem })}
            onClick={() => setIsOpen(true)}
            to=""
            ref={ref as Ref<HTMLAnchorElement>}
          >
            <LabelWrapper>
              <Label>
                <T>{`Navigation:navItem.${key}`}</T>
              </Label>
              {activeItem && <SubLabel>{activeItem.label}</SubLabel>}
            </LabelWrapper>
            {sortedDropdownItems && <ChevronIcon icon="angle-down" />}
          </StyledNavDropdownToggle>
        )}
      />

      {!!sortedDropdownItems.length && (
        <Dropdown>
          <StyledUpArrow />
          {sortedDropdownItems.map(
            ({ isEmbedded, key, label, openInNewTab, to }) => (
              <DropdownButtonWrapper key={`navDropdownItem-${key}`}>
                {isEmbedded ? (
                  <DropdownButtonEmbedded
                    activeClassName="is-active"
                    to={to}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel={openInNewTab ? 'noopener noreferrer' : undefined}
                  >
                    {label}
                  </DropdownButtonEmbedded>
                ) : (
                  <DropdownButtonExternal
                    href={to}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel={openInNewTab ? 'noopener noreferrer' : undefined}
                  >
                    {label}
                  </DropdownButtonExternal>
                )}
              </DropdownButtonWrapper>
            )
          )}
        </Dropdown>
      )}
    </NavigationButtonWrapper>
  )
}

////////////

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  80% {
    opacity: 1;
  }
  to {
    transform: translateY(0);
  }
`

const ChevronIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}

  @media (max-width: 1350px) {
    display: none;
  }
`

const Dropdown = styled.ul`
  display: none;
  position: absolute;
  z-index: 5000;
  left: 0;
  top: calc(100% + -2px);
  margin: 0;
  padding: 2px;
  list-style: none;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.14);
  animation: ${fadeUp} 0.1s ease-out both;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const DropdownButtonWrapper = styled.li`
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`

const DropdownButtonStyles = css`
  display: block;
  white-space: nowrap;
  text-decoration: none;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutterBig}
      ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
    color: ${theme.palette.text.light};
  `}

  &:hover,
  &.is-active {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${theme.palette.primary.extraLight};
    `}
  }
`

const DropdownButtonEmbedded = styled(NavLink)`
  ${DropdownButtonStyles}
`

const DropdownButtonExternal = styled.a`
  ${DropdownButtonStyles}
`

const Label = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
`

const NavigationButtonWrapper = styled.li`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;

  &.is-open ${Dropdown} {
    display: block;
  }
`

const StyledNavButton = styled(NavLink)`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  position: relative;
  text-decoration: none;
  font-weight: 500;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  &.is-active {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${lighten(0.05, theme.palette.primary.extraLight)};
    `}
  }

  &.is-active ${Label} {
    font-weight: 700;
  }

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
      background: ${theme.palette.primary.extraLight};
    `}
  }
`

const StyledNavDropdownToggle = styled(StyledNavButton).attrs(() => ({
  as: 'button',
}))`
  border: none;
  box-shadow: none;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const StyledUpArrow = styled(UpArrow)`
  height: 16px;
  left: 16px;
  position: absolute;
  top: -16px;
  width: 16px;
`

const SubLabel = styled.span`
  font-variant-caps: all-small-caps;
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`
