import isEmpty from 'lodash.isempty'

type CategorySelection = {
  [path: string]: string
}

export const getQuery = (
  selection: CategorySelection,
  defaultSort?: {
    [key: string]: string
  }
) =>
  Object.keys(selection).length
    ? {
        query: {
          nested: {
            path: 'categories',
            query: {
              terms: {
                'categories.path': Object.keys(selection),
              },
            },
          },
        },
        sort: !isEmpty(defaultSort) ? undefined : defaultSort,
        value: Object.keys(selection)
          .map((x) => selection[x])
          .map((x) => x.replace(',', ';')),
      }
    : {
        value: null,
      }
