import { gql } from '@apollo/client'

export default Object.freeze({
  RESOURCE_AVAILABILITIES: gql`
    query ResourceAvailabilities($input: ResourcesAvailabilityInput!) {
      resourceAvailability(input: $input) {
        resourceId
        availability {
          available
          offset
          totalCapacity
        }
      }
    }
  `,
})
