import { useState } from 'react'
import { createPortal } from 'react-dom'

import { ChangelogFAB } from './ChangelogFAB'
import { ChangelogOverlay } from './ChangelogOverlay'

type Props = {
  offsetTop?: number
  target: string
}

export const ChangelogOverlayWidget = ({ offsetTop, target }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <ChangelogFAB onClick={() => setIsOpen((current) => !current)} />

      {createPortal(
        <ChangelogOverlay
          isVisible={isOpen}
          offsetTop={offsetTop}
          onClose={() => setIsOpen(false)}
          target={target}
        />,
        document.getElementById('modal-root') as HTMLDivElement
      )}
    </>
  )
}
