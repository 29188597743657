import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'

import { OrganizationCustomerContact } from '../types'
import { parseContactName } from '../utils'

type Props = {
  contact: OrganizationCustomerContact
  isDefault?: boolean
}

export const CustomerContactDisplayName = ({ contact, isDefault }: Props) => {
  const displayName = parseContactName(contact)

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {displayName || <T>Customers:contact.unnamed</T>}
      {isDefault && (
        <FontColor lighter>
          {' | '} <T>Customers:contacts.default</T>
        </FontColor>
      )}
    </div>
  )
}
