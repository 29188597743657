import { gql } from '@apollo/client'

export const RESOURCE_RESERVATION_RESOURCES_BY_SALES_QUERY = gql`
  query ResourceReservationResourcesBySales($id: ID!, $dates: DatesInput!) {
    sales(id: $id) {
      id
      resourceReservations(dates: $dates) {
        id
        resource {
          id
          isPooled
        }
      }
    }
  }
`

export default RESOURCE_RESERVATION_RESOURCES_BY_SALES_QUERY
