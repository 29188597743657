import { PostalAddress } from '@/common/types'
import { FlexColumn } from '@/components/Layout'
import { ListItemSection as Wrapper } from '@/components/List'

type Props = {
  address: PostalAddress | null | undefined
}

const AddressInfo = ({ address }: Props) => (
  <Wrapper>
    {!!address && (
      <FlexColumn noPadding>
        {address.address1 && <span>{address.address1}</span>}
        {address.address2 && <span>{address.address2}</span>}
        {(address.postcode || address.city) && (
          <span>
            {address.postcode && `${address.postcode} `}
            {address.city}
          </span>
        )}
        {address.country && <small>{address.country}</small>}
      </FlexColumn>
    )}
  </Wrapper>
)

export default AddressInfo
