import { useState } from 'react'
import styled from 'styled-components/macro'

import { ExpansionButton, TreeRow } from '@/components/TreeSelector'
import { CategorySet } from '@/modules/Registry/Category'

import { CategoryNode } from './CategoryNode'

type Props = {
  data: CategorySet
  expand?: boolean
  selection: string[]
  toggleCategory: (id: string) => void
}

export const CategorySetTree = ({
  data: { name, rootCategories },
  expand,
  selection,
  toggleCategory,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(!!expand)

  return (
    <>
      <TreeRow>
        <ExpansionButton
          isExpanded={isExpanded}
          onClick={() => setIsExpanded((current) => !current)}
        >
          <TreeRowLabel>{name}</TreeRowLabel>
        </ExpansionButton>
      </TreeRow>

      {isExpanded &&
        rootCategories.map((x, idx) => (
          <CategoryNode
            category={x}
            depth={1}
            isLastReactNode={idx === rootCategories.length - 1}
            key={`category-node-${x.id}`}
            padding={[]}
            selection={selection}
            toggleCategory={toggleCategory}
          />
        ))}
    </>
  )
}

////////////

const TreeRowLabel = styled.span`
  line-height: 1;
`
