import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { StyledInnocuousButton } from '@/components/ExtraButtons'
import { useTheme } from '@/theme'

import { TreeLine } from './TreeLine'
import { ScrollTableProps } from './types'

type Props = ScrollTableProps & {
  indents: boolean[]
  isExpanded?: boolean
  isLastRow?: boolean
  onSelect?: () => void
  onToggleExpand?: () => void
  renderContent: () => ReactNode
  renderHeader: () => ReactNode
}

export const IndentedDataRow = ({
  indents,
  isExpanded,
  isLastRow,
  onSelect,
  onToggleExpand,
  renderContent,
  renderHeader,
  ...tableProps
}: Props) => {
  const { palette } = useTheme()

  const hasContentBelow = indents.slice(0, -1).includes(true)

  return (
    <Wrapper>
      {indents.map((isVisible, idx) => {
        const isLastTreeLine = idx + 1 === indents.length

        return (
          <TreeLine
            index={idx}
            hideSideLine={!isLastTreeLine}
            isLastRow={isLastRow && isLastTreeLine}
            isVisible={isVisible}
            key={`indent-${idx}`}
          />
        )
      })}

      <Header indents={indents} {...tableProps}>
        {!!onToggleExpand ? (
          <ExpandButton
            height={tableProps.rowHeight}
            onClick={() => onToggleExpand()}
          >
            <FontAwesomeIcon
              color={palette.text.light}
              icon={isExpanded ? 'angle-down' : 'angle-right'}
            />
          </ExpandButton>
        ) : (
          <TreeLeafDot />
        )}

        {renderHeader()}

        <span style={{ flex: 1 }} />

        {!!onSelect && (
          <SelectButton
            height={tableProps.rowHeight}
            onClick={() => onSelect()}
          >
            <FontAwesomeIcon color={palette.primary.main} icon="plus" />
          </SelectButton>
        )}
      </Header>

      <Content
        hasContentBelow={hasContentBelow || isExpanded || !isLastRow}
        {...tableProps}
      >
        {renderContent()}
      </Content>
    </Wrapper>
  )
}

////////////

const ButtonBase = styled(StyledInnocuousButton).attrs(() => ({
  noNudge: true,
}))<{ height: string }>`
  justify-content: center;
  margin-right: 4px;
  max-height: ${({ height }) => height ?? 'auto'};
  width: 40px;
  z-index: 3002;
`

const Content = styled.div<{ hasContentBelow: boolean; rowHeight: string }>`
  height: ${({ rowHeight }) => rowHeight};
  display: inline-flex;
  width: 100%;

  ${({ hasContentBelow, theme }) =>
    hasContentBelow
      ? `border-bottom: 1px solid ${theme.palette.smoke.dark};`
      : ''}
`

const ExpandButton = styled(ButtonBase)`
  &&& {
    ${({ theme }) => css`
      background: ${theme.palette.white};
    `}
  }
`

const Header = styled.div<{
  columnHeaderWidth: string
  indents: boolean[]
  rowHeight: string
}>`
  position: sticky;
  left: ${({ indents }) => `${30 * indents.length}px`};
  flex: 0 0
    ${({ columnHeaderWidth, indents }) =>
      `calc(${columnHeaderWidth} - ${30 * indents.length}px)`};
  display: flex;
  z-index: 3000;
  height: ${({ rowHeight }) => rowHeight};
  padding: 0 10px 0 0;
  text-align: left;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
    border-right: 1px solid ${theme.palette.smoke.dark};
    font-size: ${theme.typography.fontSizeBase};
  `}

  align-items: center;
  font-weight: 500;
`

const SelectButton = styled(ButtonBase)`
  border-radius: 0;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.lighter};
  `}

  &&& {
    margin-right: -10px;
  }
`

const TreeLeafDot = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}

  position: relative;
  width: 20px;
  z-index: 3002;

  &:after {
    content: ' ';

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}

    border-radius: 6px;
    position: absolute;
    height: 6px;
    width: 6px;
    top: calc(50% - 3px);
    left: 2px;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
`
