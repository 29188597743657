import { gql } from '@apollo/client'

import { customerFragments } from '@/modules/Registry/Customer/fragments'

export const ReservationsForDateQuery = gql`
  ${customerFragments.customerReference}

  query ReservationsForDate($input: ReservationSearchInput!) {
    accommodationRoomTypeReservations(input: $input) {
      reservations {
        id
        sortOrder
        accommodationGroup {
          sales {
            id
            name
            orderNumber
            customer {
              ...CustomerReference
            }
          }
        }
        request {
          roomType {
            id
            beds
            extraBeds
          }
          rooms
          checkIn {
            date
            type
          }
          checkOut {
            date
            type
          }
          beds
          extraBeds
          features {
            id
            name
            icon
            shortName
          }
        }
        fulfilledByRooms
      }
    }
    accommodationRoomReservations(input: $input) {
      reservations {
        id
        sortOrder
        accommodationGroup {
          sales {
            id
            name
            orderNumber
            customer {
              ...CustomerReference
            }
          }
        }
        request {
          beds
          extraBeds
          room {
            id
            beds
            extraBeds
          }
        }
      }
    }
  }
`
