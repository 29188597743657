import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { ProductPurchaseDuration } from '@/modules/Products/types'

import { QuantityUnit as QU } from '~generated-types'

type Props = {
  duration: ProductPurchaseDuration
  quantityUnit: QU
}

export const Date = ({ duration, quantityUnit }: Props) => (
  <Wrapper justifyContent="center">
    {getPurchaseDate(duration, quantityUnit)}
  </Wrapper>
)

///////

const formatDate = (date: ProductPurchaseDuration) => {
  const isSameDate = moment(date.from).isSame(moment(date.to), 'day')
  const isSameYear = moment(date.from).isSame(moment(date.to), 'year')

  const start = moment(date.from).format(isSameYear ? 'DD.MM' : 'DD.MM.YY')
  const end = moment(date.to).format('DD.MM.YY')

  return isSameDate ? end : `${start} – ${end}`
}

const formatTime = (date: ProductPurchaseDuration) => {
  const start = moment(date.from).format('HH:mm')
  const end = moment(date.to).format('HH:mm')

  return `${start} – ${end}`
}

const getPurchaseDate = (
  duration: ProductPurchaseDuration,
  quantityUnit: QU
) => {
  switch (quantityUnit) {
    case QU.Hour:
      return `${formatDate(duration)}, ${formatTime(duration)}`
    case QU.Day:
    case QU.Night:
      return formatDate(duration)
    case QU.Piece:
    default:
      return ''
  }
}

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(24)}rem;
  `}
`
