import { isMobile } from 'react-device-detect'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { MainNavbarDesktop, MainNavbarMobile } from '@/components/MainNavbar'
import { useKeycloakContext } from '@/config/keycloak'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'

import { AppAccessError } from './AppAccessError'
import { FrameMobile } from './FrameMobile'
import { AppRoutesDesktop, AppRoutesMobile } from './routes'

export const App = () => {
  const { navItems } = useWillbaConfigContext()
  const { keycloak } = useKeycloakContext()

  if (!keycloak) {
    return null
  }

  const desktopAccess = keycloak.hasRealmRole('willba-office:access')
  const mobileAccess = keycloak.hasRealmRole('willba-mobile:access')

  const showMobile =
    mobileAccess && (!desktopAccess || (desktopAccess && isMobile))
  const showDesktop =
    desktopAccess && (!mobileAccess || (mobileAccess && !isMobile))

  if (showMobile) {
    return (
      <FrameMobile>
        <MainNavbarMobile />
        <AppRoutesMobile />
      </FrameMobile>
    )
  }

  if (showDesktop) {
    return (
      <Frame>
        <MainNavbarDesktop items={navItems} />
        <AppRoutesDesktop />
      </Frame>
    )
  }

  return <AppAccessError />
}

////////////

const Frame = styled(FlexColumn)`
  position: relative;
  flex-shrink: 0;
  height: 100%;
  z-index: 1;
`
