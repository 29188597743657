import { gql } from '@apollo/client'

import { productFragments } from '@/modules/Products/fragments'

import orderProduct from './orderProduct'

export default gql`
  ${orderProduct}
  ${productFragments.productPurchaseLink}

  fragment OrderPacket on OrderPacket {
    id
    name
    packetTotal {
      amount
      amountVatIncluded
      vatAmount
    }
    products {
      ...OrderProduct
    }
    quantity
    source {
      purchase {
        id
        link {
          ...ProductPurchaseLink
        }
        product {
          id
        }
      }
      type
    }
  }
`
