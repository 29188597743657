import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import styled from 'styled-components/macro'

import { FetchState, FetchStates } from '@/common/types'
import { BottomSheet } from '@/components/BottomSheet'
import { DocumentPreview, DocumentsService } from '@/modules/Document'
import { T } from '@/modules/Language'

import {
  InvoiceDocumentQuery,
  InvoiceDocumentQueryVariables,
} from '~generated-types'

const QUERY = gql`
  query InvoiceDocument($input: InvoicesByIdsInput!) {
    invoicesByIds(input: $input) {
      invoices {
        id
        document {
          id
        }
      }
    }
  }
`

export interface ReceiptModalProps {
  invoiceId: string
  showReceipt: boolean
  onClose: () => void
}

export const ReceiptModal = ({
  invoiceId,
  showReceipt,
  onClose,
}: ReceiptModalProps) => {
  const [fetchState, setFetchState] = useState<FetchState>(FetchStates.LOADING)
  const [PDFUrl, setPDFUrl] = useState<string>('')

  const [loadDocumentId, { data }] = useLazyQuery<
    InvoiceDocumentQuery,
    InvoiceDocumentQueryVariables
  >(QUERY)

  const documentId = data?.invoicesByIds.invoices[0].document?.id

  useEffect(() => {
    showReceipt &&
      loadDocumentId({
        variables: {
          input: {
            ids: [invoiceId],
          },
        },
      })
  }, [showReceipt])

  useEffect(() => {
    if (!documentId) return

    DocumentsService.buildPdf(documentId)
      .then((url) => {
        setFetchState(FetchStates.IDLE)
        setPDFUrl(url)
      })
      .catch((err) => {
        console.error('PDF downloading error', err)
        setFetchState(FetchStates.ERROR)
        setPDFUrl('')
      })
  }, [documentId])

  return (
    <BottomSheet onClose={onClose} title={<T>Reports:Payments.receipt</T>}>
      <Container>
        <DocumentPreview
          fetchingState={fetchState}
          minWidth="1024px"
          url={PDFUrl}
        />
      </Container>
    </BottomSheet>
  )
}

export default ReceiptModal

////////////

const Container = styled.div`
  display: flex;
  height: 100%;
  margin: auto;
`
