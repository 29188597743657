import styled, { css } from 'styled-components/macro'

export const LabelWrapper = styled.span`
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  text-align: left;
  min-width: 0;
  flex: 1;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutterSmall};
  `}
`
