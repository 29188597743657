import { gql } from '@apollo/client'

export default gql`
  query Invoices($input: InvoicesByIdsInput!) {
    invoicesByIds(input: $input) {
      invoices {
        id
        invoiceNumber
        paymentInfo {
          payableAmount
          totalPrice {
            amountVatIncluded
          }
        }
        order {
          sales {
            id
            paymentAgreement {
              id
              name
              code
            }
          }
        }
      }
    }
  }
`
