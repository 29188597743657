import { Fragment } from 'react'
import { Decimal } from 'decimal.js'
import styled from 'styled-components/macro'

import { T } from '@/modules/Language'
import {
  ListingTable,
  ListingTableRowSpacer,
  ListingTableSubheader,
} from '@/modules/Listing/common'
import { PrintSize } from '@/modules/Listing/types'
import { generateCompareFn } from '@/utils/arrays'
import { formatCurrency } from '@/utils/currency'

import { PaymentType } from '~generated-types'

import type {
  PaymentAmountsByType,
  PointOfSaleDetails,
  SupportedPaymentType,
} from '../usePaymentsForInterval'
import { PaymentCells } from './PaymentCells'
import { PaymentsTableRow } from './PaymentsTableRow'

export interface PaymentsTableProps {
  payments: PointOfSaleDetails[]
  printSize: PrintSize
  totalAmounts: PaymentAmountsByType
}

export const PaymentsTable = ({
  payments,
  printSize,
  totalAmounts,
}: PaymentsTableProps) => {
  const totalAmountsCombined = [
    totalAmounts[PaymentType.Cash],
    totalAmounts[PaymentType.CreditCard],
    totalAmounts[PaymentType.GiftCard],
    ...Object.values(totalAmounts.vouchersByProvider),
  ].reduce((acc: Decimal, val) => acc.plus(val), new Decimal(0))

  const nonEmptyPaymentTypes = [
    PaymentType.Cash,
    PaymentType.CreditCard,
    PaymentType.GiftCard,
  ].filter(
    (type) =>
      totalAmounts[type as SupportedPaymentType] &&
      !totalAmounts[type as SupportedPaymentType].isZero()
  )

  const nonEmptyVoucherProviders = Object.keys(totalAmounts.vouchersByProvider)

  const colSpan =
    5 + nonEmptyPaymentTypes.length + nonEmptyVoucherProviders.length

  return (
    <ListingTable collapsePaddingRight printSize={printSize}>
      <thead>
        <tr>
          <TH>
            <T>Reports:Payments.field.invoiceNr</T>
          </TH>
          {nonEmptyPaymentTypes.map((type, idx) => (
            <TH
              className={idx === 0 ? 'show-left-border' : undefined}
              key={`payment-type-header-${type}`}
              style={{ textAlign: 'right' }}
            >
              <T>{`enums:paymentType.${type}`}</T>
            </TH>
          ))}
          {nonEmptyVoucherProviders.map((provider) => (
            <TH
              key={`voucher-provider-header-${provider}`}
              style={{ textAlign: 'right' }}
            >
              {provider}
            </TH>
          ))}
          <TH className="show-left-border">
            <T>Reports:Payments.field.seller</T>
          </TH>
          <TH>
            <T>Reports:Payments.field.customer</T>
          </TH>
          <SalesTH>
            <T>Reports:Payments.field.sales</T>
          </SalesTH>
          <ActionButtonTH />
        </tr>
      </thead>
      <tbody>
        {payments.map(
          ({ amounts, amountsTotal, invoicesById, pointOfSale }) => (
            <Fragment key={`point-of-sales-payments-${pointOfSale.id}`}>
              <ListingTableSubheader colSpan={colSpan} printSize={printSize}>
                {pointOfSale.name}
              </ListingTableSubheader>

              {Object.values(invoicesById)
                .sort(generateCompareFn('invoice.invoiceNumber'))
                .map((invoice) => (
                  <PaymentsTableRow
                    colSpan={colSpan}
                    data={invoice}
                    key={`point-of-sales-payments-invoice-${invoice.invoice?.id}`}
                    nonEmptyPaymentTypes={nonEmptyPaymentTypes}
                    nonEmptyVoucherProviders={nonEmptyVoucherProviders}
                  />
                ))}

              <tr className="summaryrow">
                <td>
                  <Uppercase>
                    <T>Reports:Payments.field.total</T>
                  </Uppercase>
                </td>
                <PaymentCells
                  amounts={amounts}
                  nonEmptyPaymentTypes={nonEmptyPaymentTypes}
                  nonEmptyVoucherProviders={nonEmptyVoucherProviders}
                  notes={null}
                />
                <td className="show-left-border" style={{ textAlign: 'right' }}>
                  {formatCurrency(amountsTotal.toNumber())}
                </td>
                <td className="show-left-border" colSpan={3} />
              </tr>
            </Fragment>
          )
        )}

        <ListingTableRowSpacer colSpan={colSpan} />
        <tr className="summaryrow">
          <td>
            <Uppercase>
              <T>Reports:Payments.field.all</T>
            </Uppercase>
          </td>
          <PaymentCells
            amounts={totalAmounts}
            nonEmptyPaymentTypes={nonEmptyPaymentTypes}
            nonEmptyVoucherProviders={nonEmptyVoucherProviders}
            notes={null}
          />
          <td className="show-left-border" style={{ textAlign: 'right' }}>
            {formatCurrency(totalAmountsCombined.toNumber())}
          </td>
          <td className="show-left-border" colSpan={3} />
        </tr>
      </tbody>
    </ListingTable>
  )
}

export default PaymentsTable

////////////

const TH = styled.th`
  white-space: nowrap;
`

const ActionButtonTH = styled(TH)`
  width: 30px;

  @media print {
    &&& {
      border-right-width: 0;
      padding-left: 0;
      padding-right: 0;
      width: 0;
    }
  }
`

const SalesTH = styled(TH)`
  @media print {
    &&& {
      border-top-right-radius: 8px;
    }
  }
`

const Uppercase = styled.span`
  text-transform: uppercase;
`
