import React, { MouseEvent } from 'react'

import { ReservationBlockPreview } from './ReservationBlock'

const CalendarItemPreview = () => (
  <ReservationBlockPreview
    isFocused
    onClick={(e: MouseEvent) => e.stopPropagation()}
  />
)

export default CalendarItemPreview
