import { gql } from '@apollo/client'

export default gql`
  fragment SalesAssignee on User {
    abbreviation
    avatarUrl
    department
    email
    firstName
    id
    lastName
  }
`
