import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { Gutter } from '@/components/Layout/Gutter'
import { ModalContainer } from '@/components/Modal'
import { MarkdownCss, RichTextEditor } from '@/components/RichTextEditor'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

type Props = {
  notes: Record<string, unknown> | null
  readOnly?: boolean
  updateNotes: (notes: { [key: string]: any }) => void
}

export const Note = ({ notes, readOnly, updateNotes }: Props) => {
  const { palette } = useTheme()

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [showForm, setShowForm] = useState<boolean>(false)
  const editorContent = notes
    ? // @ts-ignore
      EditorState.createWithContent(convertFromRaw(notes))
    : EditorState.createEmpty()

  function cancelChanges() {
    setEditorState(editorContent)
  }

  function saveNote() {
    const editorContentAsJSON = convertToRaw(editorState.getCurrentContent())

    updateNotes(editorContentAsJSON)
  }

  function handleChangeToShowForm() {
    if (readOnly) {
      return
    }

    setEditorState(editorContent)
    setShowForm(true)
  }

  // @ts-ignore
  const markdown: string = notes ? draftToMarkdown(notes) : ''

  const widgetStyles = {
    border: `1px solid ${palette.smoke.main}`,
    padding: 0,
  }

  return (
    <NoteWrapper style={widgetStyles}>
      <MarkdownCss />

      <FlexColumn style={{ height: '100%' }} className="markdown-wrapper">
        <ModalContainer
          isOpen={showForm}
          modal={
            <InlineModal style={{ minWidth: '450px' }}>
              <InlineModalSection style={{ margin: 0, padding: 0 }}>
                <RichTextEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </InlineModalSection>
              <InlineModalSection style={{ margin: 0, padding: 0 }}>
                <Gutter
                  flex={1}
                  type={[1]}
                  style={{
                    background: palette.smoke.light,
                    borderRadius: '0 0 6px 6px',
                    borderTop: `1px solid ${palette.smoke.main}`,
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowForm(false)
                      saveNote()
                    }}
                    size="small"
                  >
                    <T>common:action.save</T>
                  </Button>
                  <Button
                    onClick={() => {
                      setShowForm(false)
                      cancelChanges()
                    }}
                    size="small"
                  >
                    <T>common:action.cancel</T>
                  </Button>
                </Gutter>
              </InlineModalSection>
            </InlineModal>
          }
          onClose={() => setShowForm(false)}
          placement="bottom-end"
          referenceElement={({ ref }) => (
            <ClickableRegionWrapper ref={ref}>
              <ClickableRegion
                onClick={handleChangeToShowForm}
                readOnly={readOnly}
                role="button"
                style={{
                  color: readOnly ? palette.text.light : 'inherit',
                  cursor: readOnly ? 'not-allowed' : 'pointer',
                }}
              >
                {!readOnly && (
                  <EditButton className="edit-button">
                    <FontAwesomeIcon
                      icon="pen"
                      color={palette.text.light}
                      style={{ fontSize: '0.8em' }}
                    />
                  </EditButton>
                )}
                <Gutter type={[2, 6, 1, 2]}>
                  {markdown === '' ? (
                    <FontColor lighter>
                      <T>SalesDetails:note.add</T>
                    </FontColor>
                  ) : (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      children={markdown}
                    />
                  )}
                </Gutter>
              </ClickableRegion>
            </ClickableRegionWrapper>
          )}
        />
      </FlexColumn>
    </NoteWrapper>
  )
}

const EditButton = styled.button`
  background: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8em;
  position: absolute;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
    right: ${theme.spacing.gu(1.5)}rem;
    top: ${theme.spacing.gu(1.5)}rem;
  `}
`

const ClickableRegion = styled.div<{ readOnly?: boolean }>`
  min-height: 100%;
  cursor: default;

  ${({ readOnly, theme }) =>
    !readOnly &&
    `
    cursor: pointer; 
    border-radius: 6px;

    &:hover {
      background: ${theme.palette.smoke.lighter};
    }

    & .edit-button-detail-label {
      display: none;
      margin-right: ${theme.spacing.gu(1)}rem;
    }

    &:hover .edit-button-detail-label {
      display: inline;
    }
  `}
`

const ClickableRegionWrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const NoteWrapper = styled.div`
  flex: 1;

  border-radius: 6px;
  position: relative;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    padding: ${theme.spacing.gu(3)}rem;
    max-height: ${theme.spacing.gu(40)}rem;
  `}
`
