import ReactLoading from 'react-loading'

import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentSection } from '../../../../components'
import { usePublishContext } from '../../PublishState'
import {
  ListContainerColumn,
  ListPlaceholder,
  ListRow,
  RowWrapper,
  Section,
  VerticalDivider,
} from '../common'
import {
  AddLinkButton,
  CopyToClipboard,
  IntervalSelector,
  LinkDescription,
  LinkIssueMessage,
  RemoveLinkButton,
  ScopeSelector,
} from './components'

export const Permissions = () => {
  const { palette } = useTheme()
  const { salesWebshopSettings, error, loading } = usePublishContext()

  const tokens = salesWebshopSettings?.tokens ?? []

  const isSalePaidByLink = tokens.find(({ invoice }) => invoice?.document?.id)

  return (
    <RowWrapper>
      <ContentSection
        title={<T>Publish:Permissions.title</T>}
        headerExtra={<AddLinkButton />}
      >
        {loading ? (
          <ReactLoading
            color={palette.smoke.main}
            height={24}
            type="spin"
            width={24}
          />
        ) : error ? (
          <ListPlaceholder>
            – <T>Publish:Permissions.error</T> –
          </ListPlaceholder>
        ) : !tokens.length ? (
          <ListPlaceholder>
            – <T>Publish:Permissions.empty</T> –
          </ListPlaceholder>
        ) : (
          <ListContainerColumn>
            <FlexRow>
              <Section noPadding flex={2.3} noLeftMargin>
                <Label>
                  <T>Publish:Permissions.scope.title</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding flex={1.6} style={{ minWidth: '170px' }}>
                <Label>
                  <T>Publish:Permissions.validity.title</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding flex={3.5}>
                <Label>
                  <T>Publish:Permissions.link.copy</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding flex={1.5} style={{ minWidth: '130px' }}>
                <Label>
                  <T>Publish:Permissions.customer.copyEmail</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding flex={0.4}>
                <Label>
                  <T>Publish:Permissions.link.description</T>
                </Label>
              </Section>

              <VerticalDivider isTransparent />

              <Section noPadding flex={2.4} />

              <Section noPadding noMargin flex={0.3} />
            </FlexRow>

            {tokens.map((token, idx) => (
              <ListRow key={`sale-scope-row-${idx}${token.token}`}>
                <Section
                  noPadding
                  flex={2.3}
                  noLeftMargin
                  style={{ minHeight: '30px' }}
                >
                  <ScopeSelector
                    isSalesPaid={!!isSalePaidByLink}
                    token={token}
                  />
                </Section>

                <VerticalDivider />

                <Section noPadding flex={1.6} style={{ minWidth: '170px' }}>
                  <IntervalSelector token={token} />
                </Section>

                <VerticalDivider />

                <Section noPadding flex={3.5}>
                  <CopyToClipboard token={token} type="LINK" />
                </Section>

                <VerticalDivider />

                <Section noPadding flex={1.5} style={{ minWidth: '130px' }}>
                  <CopyToClipboard token={token} type="EMAIL" />
                </Section>

                <VerticalDivider />

                <Section noPadding flex={0.4}>
                  <LinkDescription token={token} />
                </Section>

                <VerticalDivider />

                <Section noPadding flex={2.4}>
                  <LinkIssueMessage token={token} />
                </Section>

                <Section
                  noPadding
                  noMargin
                  flex={0.3}
                  style={{ alignItems: 'center' }}
                >
                  <RemoveLinkButton token={token} />
                </Section>
              </ListRow>
            ))}
          </ListContainerColumn>
        )}
      </ContentSection>
    </RowWrapper>
  )
}
