import { gql, useMutation } from '@apollo/client'

import type {
  AddSalesProductItemRuleMutation,
  AddSalesProductItemRuleMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productRule}

  mutation AddSalesProductItemRule($input: ProductItemAddRuleInput!) {
    salesProductItemAddRule(input: $input) {
      ...ProductRule
    }
  }
`

export const useAddSalesProductItemRuleMutation = () =>
  useMutation<
    AddSalesProductItemRuleMutation,
    AddSalesProductItemRuleMutationVariables
  >(MUTATION)
