import { Palette } from '@/theme'

import { SalesSystemState } from '~generated-types'

export const getStateColor = (state: SalesSystemState, palette?: Palette) => {
  switch (state) {
    case SalesSystemState.Cancelled:
    case SalesSystemState.CancelProcess:
    case SalesSystemState.Deleted:
      return palette ? palette.danger.dark : 'danger'
    case SalesSystemState.Closed:
      return palette ? palette.success.dark : 'success'
    case SalesSystemState.Draft:
      return palette ? palette.text.dark : 'default'
    case SalesSystemState.Open:
    default:
      return palette ? palette.primary.dark : 'primary'
  }
}
