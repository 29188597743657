import { gql } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

export default gql`
  ${orderFragments.orderInfo}

  query Orders($ownerId: ID!) {
    ordersBySales(salesId: $ownerId) {
      ...OrderInfo
    }
  }
`
