import { forwardRef, ReactNode, Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

type Props = {
  disabled?: boolean
  isError?: boolean
  isRemovable?: boolean
  isWarning?: boolean
  margin?: string
  minWidth?: string
  noBorder?: boolean
  onClick: () => void
  onRemove?: () => Promise<any>
  renderContent: () => ReactNode
  width?: string
}

const ServiceItemSquared = forwardRef(
  (
    {
      disabled,
      isError,
      isRemovable,
      isWarning,
      margin,
      minWidth,
      noBorder,
      onClick,
      onRemove,
      renderContent,
      width,
    }: Props,
    ref
  ) => {
    const { palette } = useTheme()

    const [hover, setHover] = useState<boolean>(false)

    return (
      <Container
        ref={ref as Ref<HTMLDivElement> | undefined}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        width={width}
        minWidth={minWidth}
        margin={margin}
        noBorder={noBorder}
      >
        <Wrapper
          disabled={disabled}
          hover={hover}
          isError={isError}
          isWarning={isWarning}
          onClick={onClick}
        >
          {renderContent()}
        </Wrapper>

        {isRemovable && hover && (
          <RemoveWrapper onClick={onRemove}>
            <FontAwesomeIcon
              color={palette.danger.light}
              icon="trash"
              size="sm"
            />
          </RemoveWrapper>
        )}
      </Container>
    )
  }
)

export default ServiceItemSquared

const Container = styled.div<{
  margin?: string
  minWidth?: string
  noBorder?: boolean
  width?: string
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  min-width: ${({ width, minWidth }) => minWidth || width || '10rem'};
  ${({ width, minWidth }) =>
    minWidth ? `width: ${minWidth}` : `max-width: ${width || '10rem'} `};
  margin: ${({ margin }) => (margin ? margin : 0)};
  overflow: hidden;

  ${({ noBorder, theme }) =>
    !noBorder &&
    `
      border-right: 1px solid ${theme.palette.smoke.main};
    `}
`

const RemoveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-out;
  min-width: 30px;
  max-width: 30px;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const Wrapper = styled.div<{
  disabled?: boolean
  hover: boolean
  isError?: boolean
  isWarning?: boolean
}>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.1s ease-out;
  background-color: ${({ disabled, isError, isWarning, theme }) =>
    disabled
      ? theme.palette.smoke.light
      : isWarning
      ? '#fff7e6'
      : isError
      ? '#fff2ef'
      : theme.palette.white};

  width: calc(100% - 35px);
  overflow: hidden;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
