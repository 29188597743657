import { CSSProperties, ReactNode, UIEvent, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  children?: ReactNode
  style?: CSSProperties
}

export const InlineModalContent = ({ children, ...rest }: Props) => {
  const wrapperRef = useRef(null)
  const [scrollTop, setScrollTop] = useState<number>(0)

  const handleOnScroll = (event: UIEvent<HTMLDivElement>) => {
    if (wrapperRef && wrapperRef.current === event.target) {
      setScrollTop(event.currentTarget.scrollTop)
    }
  }

  return (
    <>
      <ScrollIndicator hasScrolled={!!scrollTop} />
      <Wrapper {...rest} onScroll={handleOnScroll} ref={wrapperRef}>
        {children}
      </Wrapper>
    </>
  )
}

////////////

const Wrapper = styled.div`
  display: block;
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
  z-index: 1;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gutterSmall};
  `}

  &:not(:last-child) {
    ${({ theme }) => css`
      padding-bottom: ${theme.spacing.gutter};
    `}
  }
`

const ScrollIndicator = styled.div<{ hasScrolled?: boolean }>`
  width: 100%;
  position: relative;
  z-index: 2;

  transition: opacity ease 0.3s;

  ${({ hasScrolled, theme }) => css`
    background: ${theme.palette.smoke.main};
    opacity: ${hasScrolled ? 1 : 0};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    width: 100%;
    height: 10px;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  }
`
