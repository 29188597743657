import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

export const PricingHeader = () => {
  const {
    isPricingLocked,
    isSinglePurchase,
    setPricingLocked,
    targetPurchaseId,
  } = useSalesProductManagerContext()

  return (
    <TitleWrapper alignItems="center" justifyContent="space-between">
      <Title>
        <T>Products:ProductManager.pricing.product</T>
      </Title>

      {targetPurchaseId && !isSinglePurchase && (
        <FlexRow>
          {isPricingLocked && (
            <div>
              <WarningIcon icon="triangle-exclamation" />
              <T>Products:ProductManager.pricing.lockedPricing</T>
            </div>
          )}

          <ButtonGroup>
            <ButtonGroupButton
              noNudge
              onClick={() => setPricingLocked(true)}
              selected={isPricingLocked}
              size="small"
            >
              <FontAwesomeIcon icon="lock" size="sm" />
            </ButtonGroupButton>
            <ButtonGroupButton
              noNudge
              onClick={() => setPricingLocked(false)}
              selected={!isPricingLocked}
              size="small"
            >
              <FontAwesomeIcon icon="unlock" size="sm" />
            </ButtonGroupButton>
          </ButtonGroup>
        </FlexRow>
      )}
    </TitleWrapper>
  )
}

/////////

const Title = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(7)}rem;
    padding-left: ${theme.spacing.gu(4)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
  `}
`

const WarningIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
