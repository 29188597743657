import { useRef } from 'react'
import { ReactiveComponent } from '@appbaseio/reactivesearch'
import isNumber from 'lodash.isnumber'

import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { ChecksListFilter } from './ChecksListFilter'
import { ChecksSelection, ChecksTarget, CheckState } from './types'

type Props = {
  componentId: string
  filterLabel?: string
  getDefaultQuery: () => Record<string, unknown>
  isCollapsed?: boolean
  l10nPrefix?: string
  showFilter?: boolean
  URLParams?: boolean
}

export const ChecksListFilterContainer = ({
  componentId,
  filterLabel,
  getDefaultQuery,
  isCollapsed,
  l10nPrefix,
  showFilter,
  URLParams,
}: Props) => {
  const isInitialised = useRef<boolean>(false)

  const { language } = useLanguageContext()

  const getQuery = (selection: ChecksSelection) => {
    const shouldOptions: any[] = []
    const value = transformSelectionToSelectorValue(selection)

    Object.entries(selection).forEach(([label, states]) => {
      states.forEach((state) => {
        shouldOptions.push({
          bool: {
            must: [
              { match: { 'tasks.assignee.name': label } },
              { match: { 'tasks.isOpen': state === CheckState.Open } },
            ],
          },
        })
      })
    })

    return shouldOptions.length
      ? {
          query: {
            nested: {
              path: 'tasks',
              query: {
                bool: {
                  should: shouldOptions,
                },
              },
            },
          },
          value,
        }
      : { value }
  }

  const transformSelectorValueToSelection = (
    value: string[] | undefined
  ): ChecksSelection => {
    const selection: ChecksSelection = {}

    Array.isArray(value) &&
      value.forEach((x) => {
        if (typeof x === 'string') {
          const [label, statesStr] = x.split(':')

          if (typeof label === 'string' && typeof statesStr === 'string') {
            const states: CheckState[] = []
            const statesArr: string[] = statesStr.split(',')

            const openKey = l10nPrefix
              ? translate(`${l10nPrefix}.${CheckState.Open}`, language)
              : CheckState.Open
            const readyKey = l10nPrefix
              ? translate(`${l10nPrefix}.${CheckState.Ready}`, language)
              : CheckState.Ready

            if (statesArr.includes(openKey)) states.push(CheckState.Open)
            if (statesArr.includes(readyKey)) states.push(CheckState.Ready)

            selection[label] = states
          }
        }
      })

    return selection
  }

  const transformSelectionToSelectorValue = (
    selection: ChecksSelection
  ): string[] => {
    return Object.entries(selection)
      .filter(([_, states]) => states.length)
      .map(
        ([label, states]) =>
          `${label}:${states
            .map((x) =>
              l10nPrefix ? translate(`${l10nPrefix}.${x}`, language) : x
            )
            .sort()
            .join(',')}`
      )
  }

  const updateSelection = (
    selection: ChecksSelection,
    label: string,
    state: CheckState
  ): ChecksSelection => {
    if (selection[label]) {
      const states = selection[label].includes(state)
        ? selection[label].filter((x) => x !== state)
        : [...selection[label], state]

      return {
        ...selection,
        [label]: states,
      }
    }

    return { ...selection, [label]: [state] }
  }

  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={() => ({
        ...getDefaultQuery(),
        aggs: {
          tasks: {
            aggs: {
              assignee: {
                aggs: {
                  isOpen: {
                    terms: {
                      field: 'tasks.isOpen',
                    },
                  },
                },
                terms: {
                  field: 'tasks.assignee.name.keyword',
                },
              },
            },
            nested: {
              path: 'tasks',
            },
          },
        },
        size: 0,
      })}
      filterLabel={filterLabel}
      render={({ aggregations, error, loading, setQuery, value: rawValue }) => {
        if (!aggregations && loading) {
          return <LoadingPlaceholder size="lg" style={{ width: '100%' }} />
        }

        if (error) {
          return (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          )
        }

        const value: ChecksSelection =
          transformSelectorValueToSelection(rawValue)

        // Update the query on first render with data
        if (!isInitialised.current) {
          isInitialised.current = true
          setQuery(getQuery(value))
        }

        if (rawValue === null) {
          setQuery({ value: [] })
        }

        const aggs = aggregations?.tasks?.assignee?.buckets

        const targets: ChecksTarget[] = Array.isArray(aggs)
          ? aggs.map((x) => {
              const target: ChecksTarget = {
                counts: {
                  [CheckState.Open]: 0,
                  [CheckState.Ready]: 0,
                  total: 0,
                },
                label: 'n/a',
              }

              if (isNumber(x?.doc_count)) target.counts.total = x.doc_count
              if (typeof x?.key === 'string') target.label = x.key

              if (Array.isArray(x?.isOpen?.buckets)) {
                const openBucket = x.isOpen.buckets.find(
                  (bucket: any) => bucket?.key_as_string === 'true'
                )
                const readyBucket = x.isOpen.buckets.find(
                  (bucket: any) => bucket?.key_as_string === 'false'
                )

                if (isNumber(openBucket?.doc_count)) {
                  target.counts[CheckState.Open] = openBucket.doc_count
                }
                if (isNumber(readyBucket?.doc_count)) {
                  target.counts[CheckState.Ready] = readyBucket.doc_count
                }
              }

              return target
            })
          : []

        const handleToggleSelection = (label: string, state: CheckState) => {
          const nextSelection = updateSelection(value, label, state)

          setQuery(getQuery(nextSelection))
        }

        return (
          <ChecksListFilter
            componentId={componentId}
            isCollapsed={isCollapsed}
            targets={[...targets].sort(generateCompareFn('label'))}
            toggleValue={handleToggleSelection}
            value={value}
          />
        )
      }}
      showFilter={showFilter}
      URLParams={URLParams}
    />
  )
}
