import { createGlobalStyle, css } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /*
  * kill document defaults margin & padding. We all do that all the time, right ?
  */
  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      font-family: ${theme.typography.fontFamily};
      font-size: ${theme.typography.fontSizeBaseInPx}px;
    `}
  }

  html,
  body,
  #root {
    height: 100%;
  }

  @media print {
    html,
    body,
    #root {
      width: 100%;
      height: 100%;
      overflow: visible;
      page-break-inside: auto;
    }
  }

  @page {
    size: A4;
  }
`
