import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Canvas } from '../Canvas'

const CanvasStretch = styled(Canvas)`
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  @media print {
    display: none;
  }
`

type Props = {
  children: ReactNode
  style?: CSSProperties
}
export const ContentWrap = ({ children, style }: Props) => (
  <CanvasStretch style={style}>{children}</CanvasStretch>
)
