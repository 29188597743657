import moment from 'moment'

type Overlap = 'DATE' | 'MONTH' | 'YEAR' | 'NONE'

type Variant = 'normal' | 'short'

export default function formatDateRangeWithoutRepetition(
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  variant?: Variant,
  monthVariant?: Variant
): string {
  const targetVariant = variant || 'normal'
  const targetMonthVariant = monthVariant || 'normal'
  const format = getFullDateFormat(targetVariant, targetMonthVariant)
  const padding = getPadding(targetVariant)

  if (startDate && endDate) {
    return avoidRepetition(
      startDate,
      endDate,
      targetVariant,
      targetMonthVariant
    )
  }

  if (startDate && !endDate) {
    return `${moment(startDate).format(format)}${padding} –`
  }

  if (!startDate && endDate) {
    return `– ${padding}${moment(endDate).format(format)}`
  }

  return ''
}

function avoidRepetition(
  startDate: string,
  endDate: string,
  variant: Variant,
  monthFormat: Variant
) {
  const start = moment(startDate)
  const end = moment(endDate)
  const overlap = getOverlapType(start, end)
  const startFormat = getStartDateFormat(overlap, variant, monthFormat)
  const format = getFullDateFormat(variant, monthFormat)
  const padding = getPadding(variant)

  return !!startFormat
    ? `${start.format(startFormat)}${padding} – ${padding}${end.format(format)}`
    : `${end.format(format)}`
}

function getDateFormat(variant: Variant): string {
  switch (variant) {
    case 'short':
      return 'D.'
    case 'normal':
    default:
      return 'Do'
  }
}

function getFullDateFormat(variant: Variant, monthVariant: Variant): string {
  switch (variant) {
    case 'short':
      return 'D.M.YY'
    case 'normal':
    default:
      return `Do ${monthVariant === 'short' ? 'MMM' : 'MMMM'} YYYY`
  }
}

function getMonthDateFormat(variant: Variant, monthVariant: Variant): string {
  switch (variant) {
    case 'short':
      return 'D.M'
    case 'normal':
    default:
      return `Do ${monthVariant === 'short' ? 'MMM' : 'MMMM'}`
  }
}

function getOverlapType(start: moment.Moment, end: moment.Moment): Overlap {
  if (start.format('l') === end.format('l')) {
    return 'DATE'
  }

  if (start.format('YYYY-MM') === end.format('YYYY-MM')) {
    return 'MONTH'
  }

  if (start.format('YYYY') === end.format('YYYY')) {
    return 'YEAR'
  }

  return 'NONE'
}

function getPadding(variant: Variant): string {
  switch (variant) {
    case 'short':
      return ''
    case 'normal':
    default:
      return ' '
  }
}

function getStartDateFormat(
  overlap: Overlap,
  variant: Variant,
  monthFormat: Variant
): string | null | undefined {
  switch (overlap) {
    case 'DATE':
      return null
    case 'MONTH':
      return getDateFormat(variant)
    case 'YEAR':
      return getMonthDateFormat(variant, monthFormat)
    default:
      return getFullDateFormat(variant, monthFormat)
  }
}
