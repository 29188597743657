import styled, { css } from 'styled-components/macro'

const ListControls = styled.div`
  position: relative;
  z-index: 1000;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  &:first-child {
    ${({ theme }) => css`
      margin-top: ${theme.spacing.gu(3)}rem;
    `}
  }
`

export default ListControls
