import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { CatalogProductPicker } from '@/modules/Products'
import { useSalesProductsCompact } from '@/modules/Products/hooks'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  PurchaseProductAddFromCatalogProductInput,
  PurchaseProductAddFromSalesProductInput,
} from '~generated-types'

import ServiceItemSquared from '../../../ParticipantRow/common/ServiceItemSquared'

type Props = {
  handleAddCatalogProduct: (
    input: PurchaseProductAddFromCatalogProductInput
  ) => Promise<any>
  handleAddSalesProduct: (
    input: PurchaseProductAddFromSalesProductInput
  ) => Promise<any>
  isDisabled: boolean
  isMassUpdate?: boolean
  participantId: string
  participantSalesId: string
  serviceId?: string
}

export const ParticipantProductSelector = ({
  handleAddCatalogProduct,
  handleAddSalesProduct,
  isDisabled,
  isMassUpdate,
  participantId,
  participantSalesId,
  serviceId,
}: Props) => {
  const { palette, spacing } = useTheme()
  const {
    data: { id: salesId, seller },
  } = salesHooks.useSalesDetailsContext()

  const [isBottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const { products, error, loading } = useSalesProductsCompact({
    salesId,
    skip: !isModalOpen,
  })

  const addCatalogProduct = (catalogProductId: string) =>
    handleAddCatalogProduct({
      add: { link: { participantId, salesId: participantSalesId, serviceId } },
      catalogProductId,
    }).then(() => setBottomSheetOpen(false))

  const addSalesProduct = (salesProductId: string) => {
    setModalOpen(false)
    setProcessing(true)

    return handleAddSalesProduct({
      add: { link: { participantId, salesId: participantSalesId, serviceId } },
      salesProductId,
    }).finally(() => setProcessing(false))
  }

  return (
    <>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <Modal>
            <ModalSection alignItems="center">
              {loading ? (
                <LoaderWrapper>
                  <ReactLoading
                    color={palette.smoke.main}
                    height={24}
                    type="spin"
                    width={24}
                  />
                </LoaderWrapper>
              ) : error ? (
                <Placeholder>
                  – <T>common:error.common</T> –
                </Placeholder>
              ) : products.length > 0 ? (
                [...products].sort(generateCompareFn('name')).map((product) => (
                  <Option
                    key={product.id}
                    onClick={() => addSalesProduct(product.id)}
                  >
                    {product.name}
                  </Option>
                ))
              ) : (
                <T>ParticipantsList:ParticipantProducts.noProducts</T>
              )}
            </ModalSection>

            {!loading && !isMassUpdate && (
              <OptionWrapper>
                <Option
                  onClick={() => {
                    setModalOpen(false)
                    setBottomSheetOpen(true)
                  }}
                >
                  <T>Products:SalesProductList.selectFromCatalog</T>
                </Option>
              </OptionWrapper>
            )}
          </Modal>
        }
        onClose={() => setModalOpen(false)}
        placement="bottom"
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            ref={ref}
            minWidth="8rem"
            disabled={isDisabled}
            margin={`0 0 0 ${spacing.gu(1)}rem`}
            onClick={() =>
              isDisabled || processing ? null : setModalOpen(true)
            }
            renderContent={() => (
              <FlexRow alignItems="center" justifyContent="center">
                {processing ? (
                  <ReactLoading
                    color={palette.smoke.main}
                    height={20}
                    type="spin"
                    width={20}
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      fixedWidth
                      size="xs"
                      icon="plus"
                    />
                    <Placeholder>
                      <T>ParticipantsList:ParticipantProducts.addProduct</T>
                    </Placeholder>
                  </>
                )}
              </FlexRow>
            )}
          />
        )}
      />

      {isBottomSheetOpen && (
        <CatalogProductPicker
          onItemSelect={addCatalogProduct}
          onClose={() => setBottomSheetOpen(false)}
          sellerId={seller?.id}
        />
      )}
    </>
  )
}

///////

const Modal = styled(InlineModal)`
  min-width: 320px;
`

const ModalSection = styled(FlexColumn)`
  max-height: 250px;
  overflow: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `};
`

const OptionWrapper = styled.div`
  text-align: end;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    border-top: 1px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Option = styled.div`
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const Placeholder = styled(FontWeight)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    padding-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
