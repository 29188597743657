import {
  ListItem as Wrapper,
  ListItemSection as Section,
} from '@/components/List'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { OrderStateChip } from '@/modules/Order/components/OrderHeader/components/OrderStateChip'
import { ElasticOrder } from '@/modules/Order/types'
import { formatCurrency } from '@/utils/currency'

import { SalesDetails } from './SalesDetails'

type Props = {
  data: ElasticOrder
}

export const ListItem = ({ data }: Props) => (
  <Wrapper>
    <Section width="8rem">
      <OrderStateChip fixedWidth state={data.orderState} />
    </Section>
    <Section>
      {data.name ? (
        data.name
      ) : (
        <FontColor lighter>
          <T>Orders:OrdersForOwner.unnamed</T>
        </FontColor>
      )}
    </Section>
    <Section align="right">
      {formatCurrency(data.prices?.vatIncluded) ?? '–'} €
    </Section>
    <Section>{data.sales ? <SalesDetails data={data.sales} /> : '—'}</Section>
  </Wrapper>
)
