import { gql, useMutation } from '@apollo/client'

import type {
  SetDimensionLabelsMutation,
  SetDimensionLabelsMutationVariables,
} from '~generated-types'

import { dimensionFragments } from '../fragments'

const MUTATION = gql`
  ${dimensionFragments.dimensionReference}

  mutation SetDimensionLabels($input: SalesDimensionsSetLabels!) {
    salesDimensionsSetLabels(input: $input) {
      ...DimensionReference
    }
  }
`

export const useSetDimensionLabelsMutation = () =>
  useMutation<SetDimensionLabelsMutation, SetDimensionLabelsMutationVariables>(
    MUTATION
  )
