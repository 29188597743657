export enum CheckState {
  Open = 'OPEN',
  Ready = 'READY',
}

export interface ChecksSelection {
  [assigneeLabel: string]: CheckState[]
}

export interface ChecksTarget {
  counts: {
    [CheckState.Open]: number
    [CheckState.Ready]: number
    total: number
  }
  label: string
}
