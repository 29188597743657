import { ChangeEvent, useRef } from 'react'
import styled, { css } from 'styled-components/macro'

import { Select } from '@/components/FormControls'
import { localeList, useLanguageContext } from '@/modules/Language'

export const LanguageToggle = () => {
  const { language, switchLanguage } = useLanguageContext()

  const ref = useRef<HTMLInputElement>(null)

  return (
    <Wrapper>
      <Toggle
        noArrow
        noMargin
        innerRef={ref}
        value={language}
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          switchLanguage(event.target.value)
          ref.current && ref.current.blur()
        }}
      >
        {localeList.map((l) => (
          <option key={l} value={l}>
            {l.toUpperCase()}
          </option>
        ))}
      </Toggle>
    </Wrapper>
  )
}

////////////

const Toggle = styled(Select)`
  &:not([type='checkbox']):not([type='radio']) {
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    border: none;
    border-radius: 0;

    ${({ theme }) => css`
      height: ${theme.spacing.gu(8)}rem;
      padding: 0 ${theme.spacing.gu(2)}rem;
      background: transparent;
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
        background: ${theme.palette.primary.extraLight};
      `}
    }

    &:focus {
      background: transparent;
    }
  }
`

const Wrapper = styled.div`
  margin-right: 2px;
`
