import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled, { css } from 'styled-components/macro'

import { ActionDropdown } from '@/components/ActionDropdown'
import { IconButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { translate, useLanguageContext } from '@/modules/Language'
import { useParticipantsListContext } from '@/modules/ParticipantsList'
import { CheckBox } from '@/modules/ParticipantsList/components/common'
import { useTheme } from '@/theme'

import { SalesParticipantFullFragment } from '~generated-types'

import {
  ParticipantAge,
  ParticipantGender,
  ParticipantName,
  ParticipantVisitStatus,
} from './components'

type Props = {
  participant: SalesParticipantFullFragment
  showMore: () => void
  rootRoomId?: string | undefined
  extraRemoveOptions?: {
    key: string
    disabled: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  }[][]
  isSelectable?: boolean | undefined
  isSelected?: boolean | undefined
  isVSTEnabled?: boolean
  hideAdditional?: boolean
  readOnly?: boolean
  notRemovable?: boolean
  select?: ((select: boolean) => void) | undefined
  children?: React.ReactNode
}

const ParticipantMainInfo = ({
  participant: {
    age,
    ageCategory,
    ageSource,
    birthday,
    gender,
    firstName,
    id,
    lastName,
    services,
    visitStatus,
    vst,
  },
  showMore,
  rootRoomId,
  extraRemoveOptions,
  isSelectable,
  isSelected,
  isVSTEnabled,
  hideAdditional,
  readOnly,
  notRemovable,
  select,
  children,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const {
    handleChangeParticipantData,
    handleRemoveParticipant,
    handleSetVisitStatus,
    handleRemoveRoom,
    refetchParticipants,
  } = useParticipantsListContext()

  const participantRemoveOption: {
    disabled?: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  } = {
    disabled: notRemovable,
    icon: 'trash',
    label: translate(
      'ParticipantsList:ParticipantRooms.removeParticipant',
      language
    ),
    onClick: async () => {
      await handleRemoveParticipant(id)
      await refetchParticipants()
    },
  }

  const isVSTIncluded = isVSTEnabled && !!vst?.included

  return (
    <FlexRow
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      flex={1}
      style={{ minHeight: '35px' }}
    >
      <FlexRow style={{ maxHeight: '35px' }}>
        {!rootRoomId && (
          <CheckBox
            isSelected={isSelected}
            isDisabled={!isSelectable}
            onClick={select}
          />
        )}

        <ParticipantName
          firstName={firstName}
          lastName={lastName}
          readOnly={readOnly}
          handleChange={(firstName, lastName) =>
            handleChangeParticipantData(
              {
                firstName,
                lastName,
              },
              id
            )
          }
        />

        <ParticipantGender
          gender={gender}
          isVSTError={isVSTIncluded && !gender}
          readOnly={readOnly}
          handleSelect={(gender) =>
            // @ts-ignore
            handleChangeParticipantData({ gender }, id)
          }
        />

        <ParticipantAge
          birthday={birthday}
          age={age}
          ageCategory={ageCategory}
          ageSource={ageSource}
          isVSTError={isVSTIncluded && !ageCategory}
          readOnly={readOnly}
          handleChange={(age, ageCategoryKey, birthday) =>
            handleChangeParticipantData(
              {
                age,
                ageCategoryKey,
                birthday: birthday || undefined,
              },
              id
            )
          }
        />

        <ParticipantVisitStatus
          disabled={
            readOnly ||
            !!services.find(
              (service) => service.__typename === 'ServiceParticipantBed'
            )
          }
          isDayVisitor={visitStatus.isDayVisitor}
          setVisitStatus={(visitStatus: string | null) =>
            handleSetVisitStatus({
              dayVisitorDates: null,
              id,
              isDayVisitor: visitStatus === 'day',
            })
          }
        />
      </FlexRow>

      {children}

      <FlexRow style={{ marginLeft: `${spacing.gu(0.5)}rem` }}>
        {!hideAdditional && (
          <IconWrapper>
            <IconButton
              color="transparent"
              icon="gear"
              onClick={() => showMore()}
              style={{
                color: palette.black,
              }}
              tabIndex="-1"
            />
          </IconWrapper>
        )}
        {rootRoomId ? (
          <FlexColumn>
            {services.map((service, index) => (
              <Tooltip
                key={`remove-${
                  (service.__typename === 'ServiceParticipantBed' &&
                    service?.id) ||
                  ''
                }`}
                content={translate(
                  'ParticipantsList:ParticipantFormFields.removeFromRoom',
                  language
                )}
                trigger={(triggerProps) => (
                  <IconWrapper
                    {...(service.__typename === 'ServiceParticipantBed'
                      ? triggerProps
                      : {})}
                    style={{
                      marginTop:
                        index !== 0 ? `calc(${spacing.gu(2)}rem + 1px)` : 0,
                    }}
                  >
                    {service.__typename === 'ServiceParticipantBed' && (
                      <IconButton
                        disabled={readOnly}
                        color="transparent"
                        icon="user-times"
                        onClick={() =>
                          handleRemoveRoom(
                            (service.__typename === 'ServiceParticipantBed' &&
                              service.participantRoom?.id) ||
                              ''
                          )
                        }
                        style={{
                          color: palette.danger.main,
                        }}
                        tabIndex="-1"
                      />
                    )}
                  </IconWrapper>
                )}
              />
            ))}
          </FlexColumn>
        ) : (
          <DeleteButtonsWrapper>
            {(visitStatus.isDayVisitor ||
              !extraRemoveOptions?.flat()?.length) && (
              <ActionDropdown
                items={[participantRemoveOption]}
                renderToggle={({ toggleDropdown }) => (
                  <IconWrapper>
                    <IconButton
                      disabled={readOnly}
                      color="transparent"
                      icon="trash"
                      onClick={toggleDropdown}
                      style={{
                        color: palette.danger.main,
                      }}
                      tabIndex="-1"
                    />
                  </IconWrapper>
                )}
              />
            )}
            {!!extraRemoveOptions?.flat()?.length &&
              extraRemoveOptions.map(
                (options) =>
                  !!options.length && (
                    <ActionDropdown
                      key={options[0].key}
                      positioning={{ right: '30px', top: '-50px' }}
                      items={[participantRemoveOption, ...options]}
                      renderToggle={({ toggleDropdown }) => (
                        <IconWrapper>
                          <IconButton
                            disabled={readOnly}
                            color="transparent"
                            icon="trash"
                            onClick={toggleDropdown}
                            style={{
                              color: palette.danger.main,
                            }}
                            tabIndex="-1"
                          />
                        </IconWrapper>
                      )}
                    />
                  )
              )}
          </DeleteButtonsWrapper>
        )}
      </FlexRow>
    </FlexRow>
  )
}

export default ParticipantMainInfo

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
`

const DeleteButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > div:not(:last-of-type) {
    ${({ theme }) => css`
      margin-bottom: calc(${theme.spacing.gu(2)}rem + 1px);
    `}
  }
`
