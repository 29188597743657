import styled, { css } from 'styled-components/macro'

export const Name = styled.div<{
  compactWidth?: number
  customMargin?: string
  width: number
}>`
  margin: ${({ customMargin, theme }) =>
    customMargin || `0 ${theme.spacing.gutter}`};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${({ theme, width }) => `${theme.spacing.gu(width)}rem`};

  ${({ theme, compactWidth }) =>
    compactWidth &&
    css`
      @media (max-width: 1550px) {
        width: ${`${theme.spacing.gu(compactWidth)}rem`};
      }
    `};
`

export const Price = styled.div`
  text-align: end;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
    margin: 0 ${theme.spacing.gutter};
  `}
`

export const Quantity = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

export const Separator = styled.div<{ transparent?: boolean }>`
  height: 100%;
  border-left: 1px solid
    ${({ theme, transparent }) =>
      transparent ? 'transparent' : theme.palette.smoke.main};
`

export const TimeInterval = styled.div`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(20)}rem;
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
