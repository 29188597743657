import { gql } from '@apollo/client'

export const RESOURCE_RESERVATION_GROUPS_BY_SALES_QUERY = gql`
  query ResourceReservationGroupsBySales($salesId: ID!) {
    reservationGroupsBySales(salesId: $salesId) {
      end
      id
      name
      number
      start
    }
  }
`

export default RESOURCE_RESERVATION_GROUPS_BY_SALES_QUERY
