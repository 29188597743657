import { useEffect, useRef } from 'react'

type HookReturnType = {
  hasUnmounted: boolean
}

export default function useHasUnmounted(): HookReturnType {
  const hasUnmounted = useRef<boolean>(false)

  useEffect(
    () => () => {
      hasUnmounted.current = true
    },
    []
  )

  return { hasUnmounted: hasUnmounted.current ?? false }
}
