import moment, { HTML5_FMT } from 'moment'

import { ElasticTask } from '@/modules/Task/types'
import { elasticApiSearch } from '@/utils/api'

export interface Params {
  assigneeIds: string[]
  from: string
  to: string
}

export default async function fetchTaskTargetReservationResourceIds({
  assigneeIds,
  from,
  to,
}: Params): Promise<string[]> {
  const tasks = await elasticApiSearch<ElasticTask>('task.task', {
    query: {
      bool: {
        must: [
          { terms: { 'assignee.id': assigneeIds } },
          {
            range: {
              searchTime: {
                gte: moment(from)
                  .startOf('day')
                  .format(HTML5_FMT.DATETIME_LOCAL_SECONDS),
                lt: moment(to)
                  .endOf('day')
                  .format(HTML5_FMT.DATETIME_LOCAL_SECONDS),
              },
            },
          },
        ],
      },
    },
    size: 10000,
  })

  const resourceIds = Array.from(
    new Set(
      (tasks.data || [])
        .map(({ reservation }) => reservation?.resourceId || '')
        .filter(Boolean)
    )
  )

  return resourceIds
}
