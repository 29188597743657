import { useState } from 'react'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'
import { formatCurrency } from '@/utils/currency'

import { ResourceReservationCustomerVisibility as CustomerVisibility } from '~generated-types'

import { MobileSalesResourceReservationWithTasks } from '../../../../../types'
import { formatResourceName, formatTime, getDimensionValues } from '../utils'
import {
  ChevronIcon,
  Description,
  Details,
  DetailsTitle,
  DetailsWrapper,
  Price,
  Time,
  Wrapper,
} from './common'
import { TaskRow } from './TaskRow'

type Props = {
  data: MobileSalesResourceReservationWithTasks
  showAllResources: boolean
  showPrices: boolean
}

export const ReservationRow = ({
  data,
  showAllResources,
  showPrices,
}: Props) => {
  const {
    customerVisibility,
    description,
    dimensions: dimensionsInput,
    end,
    internalNote,
    purchaseProducts,
    resource,
    resourceQuantity,
    start,
    tasks,
  } = data

  const { language } = useLanguageContext()

  const [isExpanded, setExpanded] = useState<boolean>(false)

  const endTime = formatTime(end)
  const startTime = formatTime(start)
  const time = startTime === endTime ? startTime : `${startTime} – ${endTime}`

  const dimensionValues = getDimensionValues(dimensionsInput)
  const dimensions = Array.from(new Set(dimensionValues)).join(', ')
  const product = purchaseProducts[0] ?? null
  const resourceInfo = resource?.internalInfo || null
  const resourceName = formatResourceName(resource, resourceQuantity, language)

  const showSubject = !!description
  const showDimensions = !!dimensions
  const showInternalNote = !!internalNote
  const showProduct = !!product
  const showResourceInfo = !!resourceInfo
  const showResourceName =
    showAllResources || customerVisibility === CustomerVisibility.Visible

  const isExpandable = showInternalNote || showProduct || showResourceInfo

  return (
    <>
      <Wrapper>
        <Time>{time}</Time>

        <FlexColumn alignItems="flex-start" style={{ width: '100%' }}>
          <FlexRow
            onClick={() => isExpandable && setExpanded(!isExpanded)}
            style={{ width: '100%' }}
          >
            <span style={{ width: '100%' }}>
              {showResourceName && (
                <span style={{ fontWeight: 500 }}>{resourceName}</span>
              )}

              {showResourceName && (showDimensions || showSubject) && (
                <span style={{ whiteSpace: 'pre-wrap' }}>{'  –  '}</span>
              )}

              {showDimensions && dimensions}

              {showDimensions && showSubject && ', '}

              {showSubject && <Description>{description}</Description>}
            </span>

            {isExpandable && (
              <ChevronIcon icon="chevron-up" rotation={isExpanded ? 180 : 90} />
            )}
          </FlexRow>

          <DetailsWrapper isOpen={isExpanded}>
            <Details>
              {showProduct && (
                <>
                  <DetailsTitle>
                    <T>SalesDetails:PrintSale.label.PRODUCT</T>
                  </DetailsTitle>

                  <FlexRow flex={1} justifyContent="space-between">
                    <span>
                      {product.attributes.quantity &&
                        `${product.attributes.quantity} × `}

                      <span>{product.product.name}</span>
                    </span>

                    {showPrices && (
                      <Price>
                        {formatCurrency(product.totalPrice.amountVatIncluded)} €
                      </Price>
                    )}
                  </FlexRow>
                </>
              )}

              {showInternalNote && (
                <>
                  <DetailsTitle>
                    <T>SalesDetails:PrintSale.label.INTERNAL_NOTE</T>
                  </DetailsTitle>

                  <Description>{internalNote}</Description>
                </>
              )}

              {showResourceInfo && (
                <>
                  <DetailsTitle>
                    <T>SalesDetails:PrintSale.label.RESOURCE_INFO</T>
                  </DetailsTitle>

                  <Description>{resourceInfo}</Description>
                </>
              )}
            </Details>
          </DetailsWrapper>
        </FlexColumn>
      </Wrapper>

      {tasks
        .map((task) => ({
          ...task,
          sortDue: task.dueDate
            ? moment(`${task.dueDate}T${task.dueTime || '00:00:00'}`)
            : null,
        }))
        .sort(generateCompareFn('sortDue'))
        .map((task, idx) => {
          const { sortDue, ...pureTask } = task

          return (
            <TaskRow
              isLast={idx === tasks.length - 1}
              key={task.id}
              data={pureTask}
              reservationStart={start}
              showPrices={showPrices}
            />
          )
        })}
    </>
  )
}
