import { useRouteDateInterval, useRouteValue } from '@/utils/hooks'

import { Payments } from './Payments'
import usePaymentsForInterval, {
  SUPPORTED_TYPES,
} from './usePaymentsForInterval'

export const PaymentsContainer = () => {
  const { from, setInterval, to } = useRouteDateInterval()
  const { setValue: setPointOfSalesIds, value: pointOfSalesIds } =
    useRouteValue({
      multi: true,
      routeKey: 'point',
    })

  const { error, groupedData, loading } = usePaymentsForInterval({
    from: from.startOf('day'),
    to: to.endOf('day'),
    types: [...SUPPORTED_TYPES],
  })

  return (
    <Payments
      error={error}
      interval={{ from, to }}
      loading={loading}
      payments={groupedData}
      pointOfSalesIds={
        Array.isArray(pointOfSalesIds)
          ? pointOfSalesIds
          : pointOfSalesIds
            ? [pointOfSalesIds]
            : []
      }
      setInterval={({ from, to }) => setInterval(from, to)}
      setPointOfSalesIds={(ids) => setPointOfSalesIds(ids)}
    />
  )
}

export default PaymentsContainer
