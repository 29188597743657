import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from '@/components/Button'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

interface PrintButtonProps {
  disabled?: boolean
}

export const PrintButton = ({ disabled = false }: PrintButtonProps) => {
  const theme = useTheme()

  return (
    <Button disabled={disabled} onClick={() => window.print()} size="small">
      <FontAwesomeIcon
        icon="print"
        style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
      />
      <T>common:action.print</T>
    </Button>
  )
}

export default PrintButton
