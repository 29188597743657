import { parseToHsl, setSaturation } from 'polished'

import { ColorLightness } from '../enums'

export default function correctSaturationForLightness(
  color: string,
  targetLevel: ColorLightness
): string {
  const { saturation } = parseToHsl(color)

  const unsaturatedSaturationAdjustment =
    targetLevel === ColorLightness.ExtraLight ||
    targetLevel === ColorLightness.ExtraLighter
      ? 0.25
      : 0.05

  const correctedSaturation =
    saturation > 0.85
      ? saturation - 0.4
      : saturation > 0.65
      ? saturation - 0.2
      : saturation < 0.35
      ? saturation + unsaturatedSaturationAdjustment
      : saturation

  return setSaturation(correctedSaturation)(color)
}
