import { gql } from '@apollo/client'

import productItemMarginalTax from './productItemMarginalTax'
import productMeal from './productMeal'
import productRule from './productRule'

export default gql`
  ${productItemMarginalTax}
  ${productMeal}
  ${productRule}

  fragment SalesProductItem on SalesProductItem {
    code
    commissionMode
    id
    internalCost
    internalPurchase
    marginalTax {
      ...ProductItemMarginalTax
    }
    meals {
      ...ProductMeal
    }
    name
    quantityUnit
    rules {
      ...ProductRule
    }
    unitPrice {
      id
      price
      type
      vat {
        code
        id
        vatRate
      }
    }
  }
`
