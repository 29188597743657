import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Description } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useSaleParticipants } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import { ParticipantCheckTime } from './ParticipantCheckTime'

type Props = {
  checkIn?: string | null
  checkOut?: string | null
  roomReservationId: string
  readOnly?: boolean
}

export const ParticipantCheckManualControl = ({
  checkIn,
  checkOut,
  roomReservationId,
  readOnly,
}: Props) => {
  const { palette, spacing, typography } = useTheme()

  const { handleSetCheckIn, handleSetCheckOut } = useSaleParticipants()

  const transformDate = (date: Moment) => ({
    date,
    time: {
      hour: date.format('HH'),
      minute: date.format('mm'),
    },
  })

  return (
    <>
      <ColumnWrapper disabled={readOnly}>
        <FlexRow
          alignItems={'center'}
          style={{ padding: `${spacing.gu(1)}rem` }}
        >
          {checkIn ? (
            <>
              <FontAwesomeIcon
                icon="right-to-bracket"
                color={palette.success.main}
              />
              <ParticipantCheckTime
                checkTimeValue={transformDate(moment.utc(checkIn).local())}
                setCheckTime={handleSetCheckIn}
                roomReservationId={roomReservationId}
                readOnly={readOnly}
              />
              <IconWrapper
                onClick={() =>
                  !readOnly && handleSetCheckIn(roomReservationId, null)
                }
                style={{ cursor: readOnly ? 'not-allowed' : 'pointer' }}
              >
                <FontAwesomeIcon
                  icon="xmark"
                  color={!readOnly ? palette.danger.main : palette.smoke.dark}
                />
              </IconWrapper>
            </>
          ) : (
            <>
              <IconWrapper
                onClick={() =>
                  !readOnly &&
                  handleSetCheckIn(roomReservationId, moment.utc().format())
                }
                style={{
                  cursor: readOnly ? 'not-allowed' : 'pointer',
                  width: `${typography.fontSizeBaseInPx + spacing.guPx(1)}px`,
                }}
              >
                <FontAwesomeIcon
                  icon="right-to-bracket"
                  color={!readOnly ? palette.gold : palette.smoke.dark}
                />
              </IconWrapper>
              <Description
                style={{
                  fontSize: typography.fontSizeSmall,
                  fontStyle: 'italic',
                  margin: `0 ${spacing.gu(1)}rem`,
                }}
              >
                <T>ParticipantsList:ParticipantFormFields.checkIn</T>
              </Description>
            </>
          )}
        </FlexRow>
        <FlexRow
          alignItems={'center'}
          style={{ padding: `${spacing.gu(1)}rem` }}
        >
          {checkOut ? (
            <>
              <FontAwesomeIcon
                icon="right-from-bracket"
                color={palette.success.main}
              />
              <ParticipantCheckTime
                checkTimeValue={transformDate(moment.utc(checkOut).local())}
                setCheckTime={handleSetCheckOut}
                roomReservationId={roomReservationId}
                readOnly={readOnly}
              />
              <IconWrapper
                onClick={() =>
                  !readOnly && handleSetCheckOut(roomReservationId, null)
                }
                style={{ cursor: readOnly ? 'not-allowed' : 'pointer' }}
              >
                <FontAwesomeIcon
                  icon="xmark"
                  color={!readOnly ? palette.danger.main : palette.smoke.dark}
                />
              </IconWrapper>
            </>
          ) : (
            <>
              <IconWrapper
                onClick={() =>
                  !readOnly &&
                  handleSetCheckOut(roomReservationId, moment.utc().format())
                }
                style={{
                  cursor: readOnly ? 'not-allowed' : 'pointer',
                  width: `${typography.fontSizeBaseInPx + spacing.guPx(1)}px`,
                }}
              >
                <FontAwesomeIcon
                  icon="right-from-bracket"
                  color={!readOnly ? palette.gold : palette.smoke.dark}
                />
              </IconWrapper>
              <Description
                style={{
                  fontSize: typography.fontSizeSmall,
                  fontStyle: 'italic',
                  margin: `0 ${spacing.gu(1)}rem`,
                  whiteSpace: 'nowrap',
                }}
              >
                <T>ParticipantsList:ParticipantFormFields.checkOut</T>
              </Description>
            </>
          )}
        </FlexRow>
      </ColumnWrapper>
    </>
  )
}

const ColumnWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(4)}rem;
  `}

  ${({ disabled }) => disabled && `opacity: 0.7`};
`

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`
