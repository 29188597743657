import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import { useReservationsGridState } from '../../../ReservationsGridState'

type Props = {
  children: ReactNode
}

export default function GridGroupSectionPlaceholder({ children }: Props) {
  const {
    calendarVariables: { COLUMN_HEADER_WIDTH, ROW_HEIGHT },
  } = useReservationsGridState()

  return (
    <Wrapper>
      <Content style={{ minHeight: ROW_HEIGHT }}>
        <ColumnSpacer width={COLUMN_HEADER_WIDTH} />
        <FlexRow
          alignItems="center"
          style={{ minWidth: COLUMN_HEADER_WIDTH * 2 }}
        >
          {children}
        </FlexRow>
      </Content>
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
`

const Content = styled.div`
  position: sticky;
  left: 0;
  display: flex;
`

const ColumnSpacer = styled.div<{ width: number }>`
  flex: 0 0 ${({ width }) => (width ? `${width}px` : 'auto')};

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.dark};
    margin-right: ${theme.spacing.gutter};
  `}
`
