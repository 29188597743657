import { gql, useMutation } from '@apollo/client'

import {
  ParticipantVstSetDatesMutation,
  ParticipantVstSetDatesMutationVariables,
} from '~generated-types'

import { ParticipantVst } from '../ParticipantsList.fragments'

export type {
  ParticipantVstSetDatesMutation,
  ParticipantVstSetDatesMutationVariables,
}

export const SET_VST_DATES = gql`
  ${ParticipantVst}
  mutation ParticipantVSTSetDates($input: ParticipantVstSetDatesInput!) {
    participantVstSetDates(input: $input) {
      participantId
      vst {
        ...ParticipantVst
      }
    }
  }
`

export function useVSTSetDates() {
  return useMutation<
    ParticipantVstSetDatesMutation,
    ParticipantVstSetDatesMutationVariables
  >(SET_VST_DATES)
}

export default useVSTSetDates
