import { gql } from '@apollo/client'

import customerBase from './customerBase'

export default gql`
  ${customerBase}

  fragment CustomerOrganization on CustomerOrganization {
    ...CustomerBase
    contacts {
      email
      firstName
      id
      lastName
      notes
      phone
    }
    defaultContactId
    eInvoicingAddress {
      address
      operator
    }
    organization {
      businessId
      name
    }
  }
`
