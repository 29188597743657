import { ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import { Canvas } from '@/components/Canvas'

type Props = {
  children: ReactNode
  noBottomControls?: boolean
  width?: string
}
export const SideDrawer = ({ children, noBottomControls, width }: Props) => (
  <SideDrawerCanvas
    zIndex={2}
    noBottomControls={noBottomControls}
    width={width}
  >
    <Overflow>{children}</Overflow>
  </SideDrawerCanvas>
)

const popIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
`
export const SideDrawerCanvas = styled(Canvas)<any>`
  position: fixed;
  z-index: 4020;
  top: 0;
  right: 0;
  bottom: 0;
  /* allows two inputs next to each other and a gutterBig for padding */
  /* (2 * minWidth(Input) + (2 * gutterBig) */
  width: ${(props) =>
    props.width ? props.width : `${props.theme.spacing.gu(92)}rem`};
  max-width: 100%;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(50)}rem;
  `}

  padding-bottom: ${({ noBottomControls, theme }) =>
    noBottomControls ? '0' : `${theme.spacing.gu(9)}rem`};

  animation: ${popIn} 0.4s cubic-bezier(0, 0.83, 0.75, 0.91);
`
const Overflow = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
