import { T } from '@/modules/Language'

import { ActionButton } from './ActionButton'

type Props = {
  selectedAmount: number
  isDatesSame?: boolean
  isProcessing: boolean
  handleApply: () => void
  handleCancel: () => void
  readOnly?: boolean
}

export const ActionButtons = ({
  selectedAmount,
  isDatesSame,
  isProcessing,
  handleApply,
  handleCancel,
  readOnly,
}: Props) => (
  <>
    <ActionButton
      icon="check"
      color="success"
      disabled={readOnly || !selectedAmount || !!isDatesSame}
      loading={isProcessing}
      onClick={handleApply}
    >
      <T>ResourceReservations:reservation.apply</T>
    </ActionButton>
    <ActionButton
      icon="xmark"
      color="danger"
      disabled={readOnly}
      onClick={handleCancel}
    >
      <T>common:action.close</T>
    </ActionButton>
  </>
)
