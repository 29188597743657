import { FilterSection } from '@/components/ElasticFilterSearchList'
import { Option, ThemedMultiSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

export interface TaskTargetSelectorProps {
  setTaskTarget: (targetId: string[]) => void
  taskTargetOptions: Array<{ id: string; label: string }>
  taskTargetSelection: string[]
}

export const TaskTargetSelector = ({
  setTaskTarget,
  taskTargetOptions,
  taskTargetSelection,
}: TaskTargetSelectorProps) => {
  const { spacing } = useTheme()

  const selectorOptions = taskTargetOptions
    .map((x) => taskTargetToOption(x))
    .sort(generateCompareFn('label'))

  return (
    <FilterSection
      label={<T>ResourceReservationsCalendar:TaskTargetSelector.title</T>}
      render={() => (
        <ThemedMultiSelect
          noOptionsPlaceholder={() => (
            <T>ResourceReservationsCalendar:TaskTargetSelector.empty</T>
          )}
          modalWidth={`calc(300px - ${spacing.gu(6)}rem)`}
          options={selectorOptions}
          placeholder={
            <T>ResourceReservationsCalendar:TaskTargetSelector.placeholder</T>
          }
          selectedValues={taskTargetSelection}
          setSelectedValues={setTaskTarget}
          withSelectAll
        />
      )}
    />
  )
}

export default TaskTargetSelector

////////////

const taskTargetToOption = ({
  id,
  label,
}: {
  id: string
  label: string
}): Option => ({
  label,
  value: id,
})
