import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  Action,
  ActionButton,
  SectionSeparator,
} from '@/modules/Order/components'
import { orderServices } from '@/modules/Order/services'
import {
  Confirmation,
  ConfirmationValidatedAction as CVA,
} from '@/modules/Order/types'

import {
  DocumentFileStatus,
  OrderConfirmationAction as CA,
} from '~generated-types'

type ValidatedActions = {
  acceptAction?: CVA
  deleteAction?: CVA
}

type Props = {
  confirmation: Confirmation
  openDocumentModal: () => void
  orderId: string
}

export const ActionsSection = ({
  confirmation: {
    document: confirmationDocument,
    id,
    lifecycle: { validatedActions },
    type,
  },
  openDocumentModal,
  orderId,
}: Props) => {
  const { removeConfirmation, triggerConfirmationAction } =
    orderServices.confirmationService()

  const [processingTarget, setProcessingTarget] = useState<CA | null>(null)

  const { acceptAction, deleteAction } = useMemo(
    () =>
      validatedActions.reduce((acc: ValidatedActions, o) => {
        switch (o.action) {
          case CA.Accept:
            return { ...acc, acceptAction: o }
          case CA.Delete:
            return { ...acc, deleteAction: o }
          default:
            return acc
        }
      }, {}),
    [validatedActions]
  )

  const handleDeleteConfirmation = () => {
    setProcessingTarget(CA.Delete)
    removeConfirmation(id, orderId).then(
      (isRemoved) => !isRemoved && setProcessingTarget(null)
    )
  }

  const handleAcceptConfirmation = () => {
    setProcessingTarget(CA.Accept)
    triggerConfirmationAction(CA.Accept, id, orderId).finally(() =>
      setProcessingTarget(null)
    )
  }

  return (
    <Wrapper>
      {acceptAction && (
        <>
          <Gutter type={[0, 1.5, 0, 0]}>
            <Action
              isProcessing={processingTarget === CA.Accept}
              onClick={handleAcceptConfirmation}
              phase={type}
              validatedAction={acceptAction}
            />
          </Gutter>

          <Separator light />
        </>
      )}

      {confirmationDocument && (
        <Gutter type={[0, 1.5, 0, 0]}>
          <ActionButton
            action="SHOW_DOCUMENT"
            label={
              <T>{`Orders:Documents.action.${
                confirmationDocument.fileStatus === DocumentFileStatus.Final
                  ? 'showDocument'
                  : 'showDocumentPreview'
              }`}</T>
            }
            onClick={openDocumentModal}
          />
        </Gutter>
      )}

      <Spacer />

      {deleteAction && (
        <Action
          isProcessing={processingTarget === CA.Delete}
          onClick={handleDeleteConfirmation}
          phase={type}
          validatedAction={deleteAction}
        />
      )}
    </Wrapper>
  )
}

//////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Separator = styled(SectionSeparator)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`
