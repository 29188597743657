import { useEffect, useRef } from 'react'

export default function useInterval(
  callback: (...args: Array<any>) => any,
  delay: number
) {
  const savedCallback =
    useRef<(...args: Array<any>) => any | null | undefined>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
