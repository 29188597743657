// Returns a Compare Function to be used with Array.sort()
// Also supports nested properties
// ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#Description
const generateCompareFn =
  (
    property: string | string[],
    reversed?: boolean
  ): ((
    a: {
      [key: string]: any
    },
    b: {
      [key: string]: any
    }
  ) => number) =>
  (a = {}, b = {}) => {
    let [head, ...tail] = Array.isArray(property) ? property : [property]

    let comparisonValue = compare(a, b, head, reversed)

    while (!!tail.length && comparisonValue === 0) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[head, ...tail] = tail
      comparisonValue = compare(a, b, head, reversed)
    }

    return comparisonValue
  }

export default generateCompareFn

const compare = (
  a: {
    [key: string]: any
  },
  b: {
    [key: string]: any
  },
  property: string,
  reversed: boolean | null | undefined
) => {
  const aValue = getValue(a, property) ?? null
  const bValue = getValue(b, property) ?? null

  // Sort A after B if A is falsy and B is not
  if (aValue === null && bValue !== null) {
    return reversed ? -1 : 1
  }

  // Sort B after A if B is falsy and A is not
  if (aValue !== null && bValue === null) {
    return reversed ? 1 : -1
  }

  // Special empty string handling (i.e. empty values at the back)
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    if (!aValue && bValue) {
      return reversed ? -1 : 1
    }

    if (aValue && !bValue) {
      return reversed ? 1 : -1
    }
  }

  if (aValue !== null && bValue !== null && aValue < bValue) {
    return reversed ? 1 : -1
  }

  if (aValue !== null && bValue !== null && aValue > bValue) {
    return reversed ? -1 : 1
  }

  return 0
}

const getValue = (
  o: {
    [key: string]: any
  },
  property: string
) => {
  let value = o

  for (const part of property.split('.')) {
    value = value?.[part]
  }

  return value
}
