import React, { useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

import {
  ParticipantServiceFragment,
  SalesParticipantFullFragment,
} from '~generated-types'

import { ParticipantAdditionalInfo } from './ParticipantAdditionalInfo'
import { ParticipantMainInfo } from './ParticipantMainInfo'

type Props = {
  participantBasicData: SalesParticipantFullFragment
  services?: ParticipantServiceFragment[]
  readOnly?: boolean
  participantContextComponent: React.ReactNode
  extraRemoveOptions?: {
    key: string
    disabled: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  }[][]
  isVSTEnabled?: boolean
  rootRoomId?: string
  isSelectable?: boolean
  isSelected?: boolean | undefined
  hideAdditional?: boolean
  notRemovable?: boolean
  select?: ((select: boolean) => void) | undefined
}

export const ParticipantRow = ({
  participantBasicData,
  services,
  readOnly,
  participantContextComponent,
  extraRemoveOptions,
  isVSTEnabled,
  rootRoomId,
  isSelectable,
  isSelected,
  hideAdditional,
  notRemovable,
  select,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <Wrapper noPadding>
      <HeaderWrapper>
        <ParticipantMainInfo
          participant={participantBasicData}
          showMore={() => setIsExpanded(!isExpanded)}
          extraRemoveOptions={extraRemoveOptions}
          rootRoomId={rootRoomId}
          isSelectable={isSelectable}
          isSelected={isSelected}
          isVSTEnabled={isVSTEnabled}
          hideAdditional={hideAdditional || !services}
          readOnly={readOnly}
          notRemovable={notRemovable}
          select={select}
        >
          {participantContextComponent}
        </ParticipantMainInfo>
      </HeaderWrapper>

      {isExpanded && (
        <ContentWrapper>
          {hideAdditional || !services ? (
            <></>
          ) : (
            <ParticipantAdditionalInfo
              participant={participantBasicData}
              services={services}
              readOnly={readOnly}
            />
          )}
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    background-color: ${theme.palette.white};
  `}

  &:not(:first-child) {
    margin-top: -1px;
  }
`

const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(1)}rem;
    padding-left: 0;
  `}
`

const ContentWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    border-top: 0;
  `}
`
