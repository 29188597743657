import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexRow } from '@/components/Layout'
import { ParticipantSales, Sales } from '@/modules/FrontDesk'
import { T } from '@/modules/Language'
import { SalesTypeChip } from '@/modules/Sales'
import { useTheme } from '@/theme'

import { PaymentStatus, SalesType } from '~generated-types'

import { SalesLabel } from '../common'

type Props = {
  sales: Sales | ParticipantSales
}

export const PaymentAgreementRow = ({
  sales: { id, facet, name, orderNumber, orders, type, paymentAgreement },
}: Props) => {
  const { palette, spacing } = useTheme()

  const isPaid = orders.every(
    ({ paymentInfo }) => paymentInfo.status === PaymentStatus.Paid
  )

  const chipColor = isPaid ? palette.success.dark : palette.danger.dark

  return (
    <FlexRow style={{ marginTop: `${spacing.gu(1)}rem` }}>
      {type !== SalesType.Sales && (
        <>
          <NoWrapSpan style={{ width: `${spacing.gu(36)}rem` }}>
            <SalesTypeChip facet={facet} type={type} />
            <SalesLink
              rel="noopener noreferrer"
              target="_blank"
              to={`/sales/details/${id}`}
            >
              <SalesLabel name={name} orderNumber={orderNumber} type={type} />
            </SalesLink>
          </NoWrapSpan>
          <Spacer />
        </>
      )}

      <NoWrapSpan style={{ width: `${spacing.gu(22)}rem` }}>
        <span title={paymentAgreement?.name}>
          {paymentAgreement?.name ?? '–'}
        </span>
      </NoWrapSpan>

      <Spacer />

      {orders.length ? (
        <Chip
          customColor={{ fill: chipColor, stroke: chipColor }}
          size="xs"
          variant="outlined"
        >
          <T>{`Orders:PaymentStatus.${isPaid ? 'PAID' : 'UNPAID'}`}</T>
        </Chip>
      ) : (
        <Placeholder>
          <T>FrontDesk:DailyRoomReservations.payment.noOrders</T>
        </Placeholder>
      )}
    </FlexRow>
  )
}

////////////

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const SalesLink = styled(Link)`
  text-decoration: none;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterSmall};
    color: ${theme.palette.primary.dark};
  `};
`

const Spacer = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `};
`

const NoWrapSpan = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
