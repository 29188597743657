import styled, { css } from 'styled-components/macro'

import { PropsWithTheme } from '@/theme'

export interface FormFieldProps {
  row?: boolean
  whitespace?: 'gutter' | 'gutterSmall' | undefined
  width?: number
}

const findWhitespace = (props: PropsWithTheme<FormFieldProps>) => {
  if (!props.whitespace) {
    return
  }

  switch (props.whitespace) {
    case 'gutter':
      return `padding-bottom: ${props.theme.spacing.gutter};`
    case 'gutterSmall':
      return `padding-bottom: ${props.theme.spacing.gutterSmall};`
    default:
      return
  }
}

const getWidth = ({ theme, width }: PropsWithTheme<FormFieldProps>): string => {
  switch (width) {
    case 1:
      return theme.spacing.gu(45) + 'rem'
    case 2:
      return theme.spacing.gu(90) + 'rem'
    case 3:
      return theme.spacing.gu(135) + 'rem'
    case 4:
      return theme.spacing.gu(180) + 'rem'
    default:
      return theme.spacing.gu(45) + 'rem'
  }
}

export const FormField = styled.div<FormFieldProps>`
  display: flex;
  flex-direction: ${(props: FormFieldProps): string =>
    props.row ? 'row' : 'column'};
  align-items: stretch;
  position: relative;

  ${({ theme }) => css`
    padding: 0 0 ${theme.spacing.gutterBig} 0;
  `}

  max-width: ${(props): string => getWidth(props)};

  &:last-child {
    margin-right: 0;
    padding-bottom: 0;
  }

  ${findWhitespace}
`
