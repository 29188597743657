import React from 'react'
import { Moment } from 'moment'

import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

type Props = {
  closedAt: Moment | null | undefined
  createdAt: Moment
}

export const LastActionInfo = ({ closedAt, createdAt }: Props) => (
  <Tooltip
    content={(closedAt || createdAt).format('LLL')}
    delay={[1000, 300]}
    placement="bottom"
    trigger={(triggerProps) => (
      <span {...triggerProps}>
        {closedAt ? (
          <T l10n="Tasks:Task.closedAt" when={closedAt.fromNow()}></T>
        ) : (
          <T l10n="Tasks:Task.createdAt" when={createdAt.fromNow()}></T>
        )}
      </span>
    )}
  />
)
