import styled, { css } from 'styled-components/macro'

import { PropsWithTheme, Theme } from '@/theme'

type GutterType = 'gutterSmall' | 'gutter' | 'gutterBig' | number
export type GutterOptions = GutterType | GutterType[]
type Props = {
  flex?: number | string
  ignoreInPrint?: boolean
  type?: GutterOptions
}
export const Gutter = styled.div<Props>`
  ${({ flex }) => (flex != null ? `flex: ${flex};` : '')}
  padding: ${(props) => reconcileGutter(props)};

  @media print {
    ${({ ignoreInPrint }) =>
      !!ignoreInPrint &&
      css`
        padding: 0;
      `}
  }
`

// determine whether the option is in plural (array) or singular form
const reconcileGutter = (props: PropsWithTheme<Props>) => {
  if (Array.isArray(props.type)) {
    if (props.type.length > 4) {
      throw new Error(
        'GutterType length must not exceed 4 when used as an array'
      )
    }

    return props.type.map((type) => numberOrString(type, props.theme)).join(' ')
  } else {
    return props.type ? numberOrString(props.type, props.theme) : '0'
  }
}

// determine whether a single option is a number or a string
const numberOrString = (type: GutterType, theme: Theme): string =>
  typeof type === 'number'
    ? getGutterForNumber(type, theme)
    : getGutterForType(type, theme)

// find a gutter value for a number
const getGutterForNumber = (type: number, theme: Theme): string =>
  `${theme.spacing.gu(type)}rem`

// find a gutter value for a string
const getGutterForType = (type: GutterType, theme: Theme): string => {
  const gutter = theme.spacing.gutter
  const gutterSmall = theme.spacing.gutterSmall
  const gutterBig = theme.spacing.gutterBig

  switch (type) {
    // try some of the preferred gutter sizes
    case 'gutter':
      return gutter
    case 'gutterSmall':
      return gutterSmall
    case 'gutterBig':
      return gutterBig
    // use the string as is if none match
    // (this option should mostly be used for testing and very special cases,
    // please always prefer the named options)
    default:
      return '0'
  }
}
