import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

export const LastUsedSalesModalHeader = () => (
  <Wrapper>
    <Section width="auto" />
    <Section>
      <T>SalesSearchList:ListHeader.name</T>
    </Section>
    <Section>
      <T>SalesSearchList:ListHeader.customer</T>
    </Section>
    <Section width="11rem">
      <T>SalesSearchList:ListHeader.estimatedDates</T>
    </Section>
  </Wrapper>
)
