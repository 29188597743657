import { ById } from '@/common/types'

import { Invoice as InvoiceFull, OrderType as Order, Payment } from '../types'

type StateType<T> = T | ((data: T) => T)

type Params = {
  invoiceId: string | null
  modifyPayments: (payments: Payment[]) => Payment[]
  orderId: string
  setOrdersById: (ordersById: StateType<ById<Order>>) => void
}

export const updateStatePayments = ({
  invoiceId,
  modifyPayments,
  orderId,
  setOrdersById,
}: Params) =>
  setOrdersById((ordersById) => {
    const order = ordersById[orderId]

    const invoices = order.invoices.map((i) => {
      const isInvoiceFull = (i as InvoiceFull).payments !== undefined

      if (isInvoiceFull && i.id === invoiceId) {
        const invoice = i as InvoiceFull

        return { ...invoice, payments: modifyPayments(invoice.payments) }
      }

      return i
    })

    return { ...ordersById, [orderId]: { ...order, invoices } }
  })
