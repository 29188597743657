import { gql, useMutation } from '@apollo/client'

import {
  RemoveWebshopTokenMutation,
  RemoveWebshopTokenMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.salesWebshopSettings}

  mutation RemoveWebshopToken($input: SalesRemoveWebshopSettingsInput!) {
    salesRemoveWebshopToken(input: $input) {
      id
      webshopSettings {
        ...SalesWebshopSettings
      }
    }
  }
`

export const useRemoveWebshopTokenMutation = () =>
  useMutation<RemoveWebshopTokenMutation, RemoveWebshopTokenMutationVariables>(
    MUTATION
  )
