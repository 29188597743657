import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { SalesCopyComponent as C } from '~generated-types'

import { Checkbox } from '../components'

type Props = {
  components: C[]
  setComponents: (components: C[]) => void
}

export const BasicDetailsSection = ({ components, setComponents }: Props) => {
  const toggleComponent = (target: C) =>
    setComponents(
      components.includes(target)
        ? components.filter((c) => c !== target)
        : [...components, target]
    )

  const toggleComponents = (target: C[]) =>
    setComponents(
      target.every((c) => components.includes(c))
        ? components.filter((c) => !target.includes(c))
        : [...components, ...target]
    )

  return (
    <Wrapper>
      <Title>
        <T>SalesDetails:CopySale.label.BASIC_DETAILS</T>
      </Title>

      <FlexRow wrap="wrap">
        <Checkbox
          checked={components.includes(C.Dimensions)}
          label={C.Dimensions}
          onChange={() => toggleComponent(C.Dimensions)}
        />

        <Checkbox
          checked={[C.SalesSettings, C.SalesSettingsParticipantGroup].every(
            (c) => components.includes(c)
          )}
          label={C.SalesSettings}
          onChange={() =>
            toggleComponents([C.SalesSettings, C.SalesSettingsParticipantGroup])
          }
        />

        <Checkbox
          checked={components.includes(C.SalesNotes)}
          label={C.SalesNotes}
          onChange={() => toggleComponent(C.SalesNotes)}
        />
      </FlexRow>
    </Wrapper>
  )
}

/////

const Title = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`
