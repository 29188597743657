import { gql } from '@apollo/client'

import { productFragments } from '../fragments'

export default gql`
  ${productFragments.salesProduct}

  query SalesProductById($id: ID!) {
    salesProduct(id: $id) {
      ...SalesProduct
    }
  }
`
