import { CSSProperties, MouseEvent, ReactNode } from 'react'

import { ND } from '@/components/NullDefinition'

import type { CellProps } from './common/types'
import { ListTD } from './common'

type Props = CellProps & {
  children?: ReactNode
  minimalRightPadding?: boolean
  style?: CSSProperties
  usePlaceholder?: ReactNode
  width?: string
  onClick?: (e: MouseEvent) => void
}

const ListItemSection = ({
  children,
  usePlaceholder,
  onClick,
  ...rest
}: Props) => (
  <ListTD {...rest} onClick={(e) => (onClick ? onClick(e) : undefined)}>
    {children || <ND>{usePlaceholder || '—'}</ND>}
  </ListTD>
)

export default ListItemSection
