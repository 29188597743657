import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

export const ModalSheet = styled(FlexColumn)<{ isOpen: boolean }>`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 501;

  transform: translateY(100%);
  transition: transform 300ms ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0%);
    `}

  ${({ theme }) => css`
    top: ${theme.spacing.gu(10)}rem;
    background: ${theme.palette.white};
    height: calc(100% - ${theme.spacing.gu(10)}rem);
  `}
`
