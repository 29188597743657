import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontColor, FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { AccommodationIssueFragment } from '~generated-types'

import { ActionButtons, CheckboxSelector, NewDateSelector } from './components'

type Props = {
  readOnly?: boolean
  onApply: (days: number, minutes: number) => Promise<void>
  onCancel: () => void
  startDate?: string
  totalSelectionsAmount: number
  selectedAmount: number
  issues?: AccommodationIssueFragment[]
  onSelectAll: (selectAll: boolean) => void
  hideMinutes?: boolean
}

export const ReservationsOffset = ({
  readOnly,
  onApply,
  onCancel,
  startDate,
  totalSelectionsAmount,
  selectedAmount,
  issues,
  onSelectAll,
  hideMinutes,
}: Props) => {
  const theme = useTheme()

  const [isProcessing, setProcessing] = useState(false)
  const [daysToMoveState, setDaysToMove] = useState(0)
  const [minsToMoveState, setMinsToMove] = useState(0)
  const [newDateState, setNewDate] = useState('')

  useEffect(() => {
    !!totalSelectionsAmount && setNewDate(moment(startDate).format())
  }, [startDate])

  useEffect(() => {
    const days = Math.floor(
      moment.duration(moment(newDateState).diff(startDate)).asDays()
    )
    const minutes =
      moment.duration(moment(newDateState).diff(startDate)).asMinutes() -
      days * 24 * 60

    setDaysToMove(days)
    setMinsToMove(minutes)
  }, [startDate, newDateState])

  const isIndeterminate =
    !!selectedAmount && selectedAmount < totalSelectionsAmount
  const startDateFormatted = startDate
    ? moment(startDate).format(
        hideMinutes ? 'dd. DD.MM.YY' : 'dd. DD.MM.YY, HH:mm'
      )
    : undefined
  const isDatesSame =
    !!startDate && !!newDateState && moment(startDate).isSame(newDateState)
  const minutesToMove = minsToMoveState * (minsToMoveState < 0 ? -1 : 1)
  const daysToMove = daysToMoveState * (daysToMoveState < 0 ? -1 : 1)
  const isMovingForward =
    minsToMoveState > 0 || daysToMoveState > 0
      ? true
      : minsToMoveState < 0 || daysToMoveState < 0
      ? false
      : null

  const handleChangeDate = (date?: Moment | null) => {
    if (!date) return

    const time = moment(newDateState).format('HH:mm').split(':')
    setNewDate(
      date.set({ hour: Number(time[0]), minute: Number(time[1]) }).format()
    )
  }

  const handleSetTimes = (newTime: string) => {
    const time = newTime.split(':')
    setNewDate(
      moment(newDateState || startDate)
        .set({ hour: Number(time[0]), minute: Number(time[1]) })
        .format()
    )
  }

  const handleApply = () => {
    setProcessing(true)

    onApply(daysToMoveState, minsToMoveState).finally(() =>
      setProcessing(false)
    )
  }

  const handleCancel = () => {
    setDaysToMove(0)
    setMinsToMove(0)
    setNewDate('')

    onCancel()
  }

  return (
    <Wrapper alignItems="center">
      <CheckboxSelector
        isChecked={selectedAmount === totalSelectionsAmount}
        isIndeterminate={isIndeterminate}
        amountSelected={selectedAmount}
        totalEntries={totalSelectionsAmount}
        handleSelect={onSelectAll}
      />

      {startDate && (
        <>
          <VerticalDivider />

          <FlexRow justifyContent="center">
            <FlexColumn noPadding alignItems="flex-start">
              <Title>
                <T>ResourceReservations:reservation.from</T>
              </Title>

              <FontColor light>
                <FontWeight>{startDateFormatted}</FontWeight>
              </FontColor>
            </FlexColumn>

            <VerticalDivider />

            <NewDateSelector
              hideMinutes={hideMinutes}
              newDate={newDateState}
              startDate={startDate}
              sectionTitle={
                <Title>
                  <T>ResourceReservations:reservation.to</T>
                </Title>
              }
              handleChangeDate={handleChangeDate}
              handleSetTimes={handleSetTimes}
            />

            <VerticalDivider />

            {newDateState && (
              <FlexColumn noPadding alignItems="flex-start">
                <Title>
                  <T>ResourceReservations:reservation.moving</T>
                </Title>

                <FontColor light>
                  <FontWeight>
                    {daysToMove} <T>enums:quantityUnit.abbrev.DAY</T>
                    {!hideMinutes && (
                      <>
                        , {Math.floor(minutesToMove / 60).toString()} :{' '}
                        {(minutesToMove % 60).toString().padStart(2, '0')}{' '}
                        <T>enums:quantityUnit.abbrev.HOUR</T>
                      </>
                    )}{' '}
                    {isMovingForward === true ? (
                      <T>ResourceReservations:reservation.moveForward</T>
                    ) : (
                      isMovingForward === false && (
                        <T>ResourceReservations:reservation.moveBack</T>
                      )
                    )}
                  </FontWeight>
                </FontColor>
              </FlexColumn>
            )}
          </FlexRow>
        </>
      )}

      <span style={{ flex: 1 }} />

      <FlexRow alignItems="center">
        {issues?.map((issue) => (
          <FontWeight
            key={issue.key}
            style={{ padding: `${theme.spacing.gutterSmall} 0` }}
          >
            <InfoIconWrapper>
              {issue.level === 'INFO' ? (
                <FontAwesomeIcon
                  icon="circle-info"
                  color={theme.palette.primary.main}
                />
              ) : (
                <FontAwesomeIcon
                  icon="triangle-exclamation"
                  color={
                    issue.level === 'ERROR'
                      ? theme.palette.danger.main
                      : theme.palette.warning.dark
                  }
                />
              )}
            </InfoIconWrapper>
            <T>{`IssueCodes:${issue.code}`}</T>
          </FontWeight>
        ))}

        <div style={{ margin: `0 ${theme.spacing.gu(1)}rem` }} />

        <ActionButtons
          selectedAmount={selectedAmount}
          isDatesSame={isDatesSame}
          isProcessing={isProcessing}
          handleApply={handleApply}
          handleCancel={handleCancel}
          readOnly={readOnly}
        />
      </FlexRow>
    </Wrapper>
  )
}

////////

const Wrapper = styled(FlexRow)`
  border-radius: 6px;
  align-items: stretch;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
    height: ${theme.spacing.guPx(8) + 2}px;
    margin: 0 0 ${theme.spacing.gu(2)}rem;
  `}
`

const VerticalDivider = styled.div`
  width: 1px;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`

const InfoIconWrapper = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
