import styled, { css } from 'styled-components/macro'

import { ActionDropdownClickable } from './ActionDropdownClickable'

export const ActionDropdownButton = styled(ActionDropdownClickable)`
  display: block;
  width: 100%;
  text-align: left;
  border: none;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizeBase};
  `}
`
