import { gql, useMutation } from '@apollo/client'

import {
  AddWebshopTokenMutation,
  AddWebshopTokenMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.salesWebshopSettings}

  mutation AddWebshopToken($input: SalesAddWebshopSettingsInput!) {
    salesAddWebshopToken(input: $input) {
      id
      webshopSettings {
        ...SalesWebshopSettings
      }
    }
  }
`

export const useAddWebshopTokenMutation = () =>
  useMutation<AddWebshopTokenMutation, AddWebshopTokenMutationVariables>(
    MUTATION
  )
