import { gql, useMutation } from '@apollo/client'

import { ParticipantServiceFragment } from '@/modules/ParticipantsList/ParticipantsList.fragments'

import {
  SalesParticipantSetAccommodationMutation,
  SalesParticipantSetAccommodationMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantSetAccommodationMutation,
  SalesParticipantSetAccommodationMutationVariables,
}

export const SALES_PARTICIPANT_SET_ACCOMMODATION = gql`
  ${ParticipantServiceFragment}
  mutation SalesParticipantSetAccommodation(
    $input: SalesParticipantSetAccommodationInput!
  ) {
    salesParticipantSetAccommodation(input: $input) {
      service {
        ...ParticipantService
      }
    }
  }
`

export function useSalesParticipantSetAccommodation() {
  return useMutation<
    SalesParticipantSetAccommodationMutation,
    SalesParticipantSetAccommodationMutationVariables
  >(SALES_PARTICIPANT_SET_ACCOMMODATION)
}

export default useSalesParticipantSetAccommodation
