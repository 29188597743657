import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useDialogService } from '@/components/DialogService'
import { IconButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { AccommodationTargetDeleteInput } from '~generated-types'

import { DeleteReservationsConfirmation } from './common/DeleteReservationsConfirmation'

type Props = {
  targetId: string
  isDefaultTarget: boolean
  readOnly?: boolean
  handleRemoveTarget: (input: AccommodationTargetDeleteInput) => Promise<void>
}

export const TargetRemoveButton = ({
  targetId,
  isDefaultTarget,
  readOnly,
  handleRemoveTarget,
}: Props) => {
  const theme = useTheme()
  const { language } = useLanguageContext()
  const { confirm } = useDialogService()

  const [deleteReservations, setDeleteReservations] = useState<boolean>(false)
  const [performRemove, setPerformRemove] = useState<boolean>(false)

  useEffect(() => {
    performRemove &&
      handleRemoveTarget({
        deleteReservations,
        id: targetId,
      })
    setPerformRemove(false)
  }, [performRemove])

  const onRemoveClick = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.continue</T>,
      description: (
        <DeleteReservationsConfirmation setConfirm={setDeleteReservations} />
      ),
      title: <T>Accommodation:TargetGroup.deleteTarget</T>,
    })
      .then(() => {
        setPerformRemove(true)
      })
      .catch(() => null)

  return (
    <Button>
      {!isDefaultTarget && (
        <Tooltip
          content={translate(
            'Accommodation:TargetGroup.deleteTarget',
            language
          )}
          trigger={(triggerProps) => (
            <div {...triggerProps}>
              <IconButton
                disabled={readOnly}
                color="transparent"
                icon="trash"
                onClick={onRemoveClick}
                style={{
                  color: theme.palette.danger.main,
                }}
              />
            </div>
          )}
        />
      )}
    </Button>
  )
}

const Button = styled(FlexRow)`
  align-items: center;
  width: 25px;
`
