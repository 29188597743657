import { Ref, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { DateRangePicker } from '@/components/TimeControls'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { CheckInType, CheckOutType } from '~generated-types'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import { DateRange } from '../SalesReservationManager.types'

type Props = {
  checkIn: {
    date: string
    type: CheckInType
  }
  checkOut: {
    date: string
    type: CheckOutType
  }
  reservationId: string
}

export const RangePicker = ({ checkIn, checkOut, reservationId }: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const { handleUpdateDates } = useReservationListContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [checkInType, setCheckInType] = useState<CheckInType>(checkIn.type)
  const [checkOutType, setCheckOutType] = useState<CheckOutType>(checkOut.type)
  const [range, setRange] = useState<DateRange | null>(
    checkIn.date && checkOut.date
      ? {
          from: moment(checkIn.date),
          to: moment(checkOut.date),
        }
      : null
  )

  useEffect(() => {
    setRange(
      checkIn.date && checkOut.date
        ? {
            from: moment(checkIn.date),
            to: moment(checkOut.date),
          }
        : null
    )
  }, [checkIn, checkOut])

  const updateDates = () =>
    range &&
    handleUpdateDates({ checkInType, checkOutType, range, reservationId }).then(
      () =>
        setRange(
          checkIn.date && checkOut.date
            ? {
                from: moment(checkIn.date),
                to: moment(checkOut.date),
              }
            : null
        )
    )

  const renderDateRange = () =>
    range ? (
      <FlexRow alignItems="center">
        {checkInType === CheckInType.Early && (
          <FontAwesomeIcon
            icon="sun"
            color={theme.palette.text.light}
            size="sm"
            style={{ marginRight: theme.spacing.gutterSmall }}
          />
        )}
        {range.from.format('dd, D.M.YYYY')}
        <span style={{ margin: `0 ${theme.spacing.gu(1)}rem` }}>–</span>
        {range.to.format('dd, D.M.YYYY')}
        {checkOutType === CheckOutType.Late && (
          <FontAwesomeIcon
            icon="moon"
            color={theme.palette.text.light}
            size="xs"
            style={{ marginLeft: theme.spacing.gutterSmall }}
          />
        )}
      </FlexRow>
    ) : (
      translate(
        'Accommodation:SalesReservationManager.rangePlaceholder',
        language
      )
    )

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <DateRangePicker
            setValue={(range) => {
              range && setRange(range)
            }}
            value={range}
          />
          <InlineModalFooter>
            <CheckInOutWrapper flex={1}>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkIn</T>
                </Title>
                <CheckboxInput
                  checked={checkInType === CheckInType.Early}
                  noMargin
                  onChange={() =>
                    setCheckInType(
                      checkInType === CheckInType.Early
                        ? CheckInType.Standard
                        : CheckInType.Early
                    )
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckInType.Early}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkOut</T>
                </Title>
                <CheckboxInput
                  checked={checkOutType === CheckOutType.Late}
                  noMargin
                  onChange={() =>
                    setCheckOutType(
                      checkOutType === CheckOutType.Late
                        ? CheckOutType.Standard
                        : CheckOutType.Late
                    )
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckOutType.Late}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
            </CheckInOutWrapper>
          </InlineModalFooter>
        </InlineModal>
      }
      onClose={() => {
        updateDates()
        setModalOpen(false)
      }}
      referenceElement={({ ref }) => (
        <ToggleFeatures
          isEmpty={!range}
          onClick={() => setModalOpen(true)}
          ref={ref as Ref<HTMLButtonElement> | undefined}
        >
          {renderDateRange()}
        </ToggleFeatures>
      )}
    />
  )
}

////////////

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckInOutWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const ToggleFeatures = styled.button<any>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}

  border: 1px solid
    ${(props) =>
    props.isEmpty
      ? props.theme.palette.danger.main
      : props.theme.palette.smoke.dark};
  border-radius: 6px;
  color: ${(props) =>
    props.isEmpty
      ? props.theme.palette.text.lighter
      : props.theme.palette.text.light};
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing.guPx(1) - 1}px ${theme.spacing.gu(1)}rem;
  `}

  outline: none;
  width: 100%;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
      border: 1px solid ${theme.palette.primary.main};
    `}
  }
`
