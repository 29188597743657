import { useQuery } from '@apollo/client'

import type {
  DimensionHierarchyQuery as QueryData,
  DimensionHierarchyQueryVariables as QueryVariables,
} from '~generated-types'

import { dimensionQueries } from '../queries'
import { Hierarchy } from '../types'

type Params = {
  dimensionId: string
}

export const useDimensionHierarchy = ({ dimensionId }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    dimensionQueries.GET_DIMENSION_HIERARCHY,
    {
      variables: { dimensionId },
    }
  )

  const hierarchy: Hierarchy | null = data?.registry.dimensionHierarchy || null

  return {
    error,
    hierarchy,
    loading,
    refetch,
  }
}
