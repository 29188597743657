import { gql, useMutation } from '@apollo/client'

import type {
  DeletePurchasesMutation,
  DeletePurchasesMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeletePurchases($input: PurchaseProductMassDeleteInput!) {
    purchaseProductMassDelete(input: $input) {
      deleted
      id
    }
  }
`

export const useDeletePurchasesMutation = () =>
  useMutation<DeletePurchasesMutation, DeletePurchasesMutationVariables>(
    MUTATION
  )
