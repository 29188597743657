import ReactLoading from 'react-loading'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import useSalesTaskCountsByAssignee from '../useSalesTaskCountsByAssignee'

interface SalesTableTaskCountsCellProps {
  salesId: string
}

const UNASSIGNED_KEY = 'unassigned'

export const SalesTableTaskCountsCell = ({
  salesId,
}: SalesTableTaskCountsCellProps) => {
  const theme = useTheme()

  const { error, loading, taskCounts } = useSalesTaskCountsByAssignee({
    salesId,
  })

  return (
    <td>
      {loading ? (
        <ReactLoading
          color={theme.palette.coal.main}
          height={14}
          type={'spin'}
          width={14}
        />
      ) : error ? (
        '–'
      ) : (
        <FlexColumn noPadding>
          {Object.entries(taskCounts)
            .sort((a, b) => {
              if (a[0] === UNASSIGNED_KEY) {
                return 1
              } else if (b[0] === UNASSIGNED_KEY) {
                return -1
              }
              return a[0].localeCompare(b[0])
            })
            .map(([assignee, tasks]) => (
              <span
                key={`tasks-${salesId}-${assignee}`}
                style={{ whiteSpace: 'nowrap' }}
              >
                {assignee === UNASSIGNED_KEY ? (
                  <em>
                    <T>Listings:SalesListing.unassignedTask</T> {tasks}
                  </em>
                ) : (
                  <span>
                    {assignee} {tasks}
                  </span>
                )}
              </span>
            ))}
        </FlexColumn>
      )}
    </td>
  )
}

export default SalesTableTaskCountsCell
