import { useMemo } from 'react'

import { FilterSearchList } from '@/components/FilterSearchList'
import {
  ListHeader,
  ListHeaderSection,
  ListItem,
  ListItemSection,
} from '@/components/List'
import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import buildingHooks from '../hooks'
import { Building } from '../types'

export const BuildingList = () => {
  const { buildings, error, loading } = buildingHooks.useBuildings()

  const sorted = useMemo(
    () => buildings.sort(generateCompareFn('name')),
    [buildings]
  )

  return (
    <FilterSearchList
      allData={sorted}
      columnCount={2}
      error={error}
      loading={loading}
      renderListItem={(x: Building) => (
        <ListItem align="center" key={x.id}>
          <ListItemSection>{x.id}</ListItemSection>
          <ListItemSection>{x.name}</ListItemSection>
        </ListItem>
      )}
      renderListHeader={() => (
        <ListHeader>
          <ListHeaderSection>
            <T>Building:field.id</T>
          </ListHeaderSection>
          <ListHeaderSection>
            <T>Building:field.name</T>
          </ListHeaderSection>
        </ListHeader>
      )}
      searchFilter={(searchValue) =>
        ({ name }) =>
          name.toLowerCase().includes(searchValue.toLowerCase().trim())
        }
    />
  )
}
