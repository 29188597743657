import styled, { css } from 'styled-components/macro'

import Description from '../Description'

const DescriptionBig = styled(Description)`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBig};
  `}

  font-weight: 300;
`

export default DescriptionBig
