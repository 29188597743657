import { CSSProperties, ReactNode, Ref } from 'react'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Button, ButtonProps } from '@/components/Button'

export type IconButtonProps = Omit<ButtonProps, 'size'> & {
  children?: ReactNode
  icon?: IconProp
  iconColor?: string
  innerRef?: Ref<HTMLButtonElement>
  size?: SizeProp
  spin?: boolean
  tabIndex?: string
  style?: CSSProperties
}

type StyledIconButtonProps = IconButtonProps & {
  onlyIcon?: boolean
}

const StyledIconButton = styled(Button)<StyledIconButtonProps>`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizeSmall};
    height: ${theme.typography.fontSizeBaseInPx + theme.spacing.guPx(1) + 4}px;
  `}

  letter-spacing: 0;

  ${(props) => {
    if (props.icon) {
      return `padding-left: ${props.onlyIcon ? 0 : '0.25rem'};`
    }

    return 'padding-left: 0.5rem;'
  }}
  ${(props) => `padding-right: ${props.onlyIcon ? 0 : '0.5rem'};`}
`

export const IconButton = ({
  children,
  icon,
  iconColor,
  innerRef,
  size,
  spin,
  ...props
}: IconButtonProps) => (
  <StyledIconButton {...props} icon={icon} onlyIcon={!children} ref={innerRef}>
    {icon && (
      <IconWrapper>
        <FontAwesomeIcon
          color={iconColor}
          icon={icon}
          size={size}
          spin={spin}
        />
      </IconWrapper>
    )}
    {children}
  </StyledIconButton>
)

////////////

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    width: ${theme.typography.fontSizeBaseInPx + theme.spacing.guPx(1)}px;
  `}
`
