import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  pageBreak: boolean
  setPageBreak: (pageBreak: boolean) => void
}

export const PageBreakCheckbox = ({ pageBreak, setPageBreak }: Props) => (
  <CheckboxWrapper alignItems="center">
    <CheckboxInput
      checked={pageBreak}
      noMargin
      onChange={() => setPageBreak(!pageBreak)}
    >
      <CheckboxLabel>
        <T>ParticipantsList:PrintableList.pageBreak</T>
      </CheckboxLabel>
    </CheckboxInput>
  </CheckboxWrapper>
)

///////

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  height: 30px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(4)}rem;
  `}
`
