import { gql, useMutation } from '@apollo/client'

import type {
  UpdateSalesProductItemMutation,
  UpdateSalesProductItemMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.salesProductItem}

  mutation UpdateSalesProductItem($input: ProductUpdateItemInput!) {
    salesProductUpdateItem(input: $input) {
      ...SalesProductItem
    }
  }
`

export const useUpdateSalesProductItemMutation = () =>
  useMutation<
    UpdateSalesProductItemMutation,
    UpdateSalesProductItemMutationVariables
  >(MUTATION)
