import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ContentSidebar } from '@/components/Layout'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import {
  GenericReservation,
  PendingRoomReservation as PendingRoomReservationType,
} from '../SalesReservationManager.types'
import PendingRoomReservation from './PendingRoomReservation'
import PendingRoomTypeReservation from './PendingRoomTypeReservation'
import RoomReservation from './RoomReservation'
import RoomTypeReservation from './RoomTypeReservation'

type Props = {
  groupId: string
  reservations: GenericReservation[]
  focusedRoomId?: string
  setOpenSections: (arg0: { [sectionId: string]: boolean }) => void
}

export const Sidebar = ({
  groupId,
  reservations,
  focusedRoomId,
  setOpenSections,
}: Props) => {
  const theme = useTheme()

  const ref = useRef<any>(null)
  const { createReservation, processing } = useReservationListContext()
  const prevReservationsLength = usePreviousReservations(reservations.length)

  useEffect(() => {
    if (prevReservationsLength < reservations.length) {
      ref.current &&
        ref.current.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: 0,
        })
    }
  }, [reservations])

  const pendingRoomReservations = reservations.filter(
    (r) => r.__typename === 'PendingRoomReservation'
  )
  const pendingRoomTypeReservations = reservations.filter(
    (r) => r.__typename === 'PendingRoomTypeReservation'
  )

  const pendingReservations = [
    ...pendingRoomReservations,
    ...pendingRoomTypeReservations,
  ]

  const canAdd = pendingReservations.some(
    (r) =>
      (r.__typename === 'PendingRoomReservation' ||
        r.__typename === 'PendingRoomTypeReservation') &&
      (!r.range || r.range.from.isSame(r.range.to))
  )

  const pendingReservationsIds = pendingReservations.map((r) => r.id)

  const createAllReservations = (groupId: string, ids: string[]) =>
    ids.forEach((id: string) => createReservation(id, groupId))

  return (
    <ContentSidebar
      key="control-row"
      style={{ overflow: 'initial' }}
      width={`${theme.spacing.gu(60)}rem`}
    >
      <Wrapper alignItems="flex-end" flex={1} ref={ref}>
        {pendingReservations.length > 1 && (
          <InnocuousButton
            disabled={canAdd || processing}
            onClick={() =>
              createAllReservations(groupId, pendingReservationsIds)
            }
            style={{ marginBottom: theme.spacing.gutter }}
          >
            <PrimaryColor>
              <T>Accommodation:SalesReservationManager.confirmAll</T>
            </PrimaryColor>
          </InnocuousButton>
        )}
        {!reservations.length ? (
          <Placeholder>
            <T>Accommodation:SalesReservationManager.empty</T>
          </Placeholder>
        ) : (
          reservations.map((x) => {
            if (x.__typename === 'PendingRoomReservation') {
              return (
                <PendingRoomReservation
                  data={x}
                  key={`pending-room-${x.id}`}
                  groupId={groupId}
                />
              )
            }

            if (x.__typename === 'PendingRoomTypeReservation') {
              return (
                <PendingRoomTypeReservation
                  data={x}
                  key={`pending-roomType-${x.id}`}
                  groupId={groupId}
                />
              )
            }

            if (x.__typename === 'RoomReservation') {
              return (
                <RoomReservation
                  data={x}
                  key={`room-reservation-${x.id}`}
                  pendingReservations={
                    pendingRoomReservations as PendingRoomReservationType[]
                  }
                  focusedRoomId={focusedRoomId}
                  setOpenSections={setOpenSections}
                />
              )
            }

            if (x.__typename === 'RoomTypeReservation') {
              return (
                <RoomTypeReservation
                  data={x}
                  key={`roomType-reservation-${x.id}`}
                  pendingReservations={
                    pendingRoomReservations as PendingRoomReservationType[]
                  }
                  focusedRoomId={focusedRoomId}
                  setOpenSections={setOpenSections}
                />
              )
            }

            console.warn('Invalid GenericReservation', x)
            return null
          })
        )}
      </Wrapper>
    </ContentSidebar>
  )
}

////////////

const usePreviousReservations = (length: number) => {
  const ref = useRef<number>(0)

  useEffect(() => {
    ref.current = length
  })

  return ref.current
}

const Wrapper = styled(FlexColumn)`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(16)}rem;
  `}
`

const Placeholder = styled.span`
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
