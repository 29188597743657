import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FetchStates } from '@/common/types'
import { Chip, ChipColor } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Pagination } from '@/components/Pagination'
import { T, useLanguageContext } from '@/modules/Language'
import { getStateColor, getStateLabels } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'

import {
  ParticipantSortProperty as SortProperty,
  SalesType,
} from '~generated-types'

import {
  GroupTitle,
  MessageWrapper,
  NewParticipantsDivider,
} from './components/common'
import { ListTotals, LoadAllButton } from './components/ListFooter'
import {
  CreateParticipantButton,
  DataRowsSwitcher,
  DocumentsButton,
  FilterChips,
  FilterModal,
  OutdatedListWarning,
  ParticipantsAgeCategory,
  SearchInput,
  SortBy,
} from './components/ListHeading'
import { MassUpdateContainer } from './components/MassUpdate'
import { ParticipantsList } from './ParticipantsList'
import { Participant } from './ParticipantsList.types'
import { useParticipantsListContext } from './ParticipantsListState'

type Props = {
  readOnly?: boolean
}

export const ParticipantsListContainer = ({ readOnly }: Props) => {
  const { palette, spacing } = useTheme()
  const { language } = useLanguageContext()

  const {
    sort,
    fetchState,
    participants,
    outdatedParticipantsIds,
    salesId,
    saleType,
    pagination,
    pageSize,
    setPageSize,
    handleSwitchPage,
    showAllParticipants,
  } = useParticipantsListContext()

  const [dataRows, setDataRows] = useState({
    SERVICES: true,
    // eslint-disable-next-line
    PERSONAL: false,
    VST: false,
  })
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const groupedParticipants = useMemo(
    () =>
      [...participants]
        .filter(({ isNewParticipant }) => !isNewParticipant)
        .reduce((prev: Participant[][], participant, idx) => {
          const firstRoom = participant.services.find(
            (service) =>
              service.__typename === 'ServiceParticipantBed' &&
              service.participantRoom?.id
          )
          const prevFirstRoom = prev[prev.length - 1]?.[0].services.find(
            (service) =>
              service.__typename === 'ServiceParticipantBed' &&
              service.participantRoom?.id
          )
          if (
            !idx || sort[0].field === SortProperty.Sales
              ? participant.sales.id !== prev[prev.length - 1]?.[0]?.sales.id
              : sort[0].field === SortProperty.FirstRoomNumber
                ? !firstRoom && !prevFirstRoom
                  ? false
                  : firstRoom?.__typename === 'ServiceParticipantBed' &&
                      prevFirstRoom?.__typename === 'ServiceParticipantBed'
                    ? firstRoom.participantRoom?.roomReservation.request.room
                        .number !==
                      prevFirstRoom.participantRoom?.roomReservation.request
                        .room.number
                    : true
                : false
          ) {
            return prev.concat([[participant]])
          }
          prev[prev.length - 1].push(participant)
          return prev
        }, []),
    [participants, sort]
  )

  const newParticipants = [...participants].filter(
    ({ isNewParticipant }) => isNewParticipant
  )

  const handleSelect = (select: boolean, selectId: string) =>
    select
      ? setSelectedIds((current) => [...current, selectId])
      : setSelectedIds((current) => current.filter((id) => id !== selectId))

  const handleSelectAll = (selectAll: boolean) => {
    const participant = participants.find(({ id }) => id === selectedIds[0])
    const salesId = participant?.sales?.id

    return setSelectedIds(
      selectAll
        ? participants
            .map((p) => (p.sales.id === salesId ? p.id : ''))
            .filter(Boolean)
        : []
    )
  }

  const selectedParticipantsSale = participants.find(
    ({ id }) => id === selectedIds[0]
  )?.sales.id

  const isAllSelected =
    selectedIds.length ===
    participants.filter(({ sales }) => selectedParticipantsSale === sales.id)
      .length

  const isListOutdated = !!outdatedParticipantsIds.length

  const renderRoomName = (services: Participant['services']) => {
    const roomService = services.find(
      (service) =>
        service.__typename === 'ServiceParticipantBed' &&
        service.participantRoom?.id
    )

    if (roomService?.__typename === 'ServicePurchase') return
    const roomNumber =
      roomService?.participantRoom?.roomReservation.request.room.number

    return roomService ? (
      `#${roomNumber}`
    ) : (
      <T>ParticipantsList:ParticipantRooms.noRooms</T>
    )
  }

  const renderParticipants = () => {
    if (fetchState === FetchStates.ERROR) {
      return <MessageWrapper message={<T>ParticipantsList:error</T>} />
    }

    if (fetchState === FetchStates.LOADING) {
      return (
        <MessageWrapper
          message={
            <StyledSpinner
              color={palette.smoke.main}
              height={28}
              type={'spin'}
              width={28}
            />
          }
        />
      )
    }

    if (fetchState === FetchStates.IDLE && groupedParticipants.length) {
      return (
        <FlexColumn>
          {!!selectedIds.length && (
            <>
              <MassUpdateContainer
                selectedIds={selectedIds}
                isAllSelected={isAllSelected}
                selectAll={handleSelectAll}
              />
            </>
          )}
          {groupedParticipants.map((groupParticipants, idx) => {
            const {
              id,
              sales: {
                id: salesId,
                lifecycle: { state },
                name,
                orderNumber,
                type,
              },
              services,
            } = groupParticipants[0]

            return (
              <FlexColumn key={id}>
                {sort[0].field === SortProperty.Sales ? (
                  type !== 'EVENT' && (
                    <GroupTitle
                      chipTitle={<T>Sales:entity.type.ENROLLMENT</T>}
                      style={idx === 0 ? { marginTop: 0 } : undefined}
                      title={
                        <FlexRow alignItems="center">
                          <Chip
                            color={
                              getStateColor(state.systemState) as ChipColor
                            }
                            size="sm"
                            variant="outlined"
                            style={{ marginRight: `${spacing.gu(1)}rem` }}
                          >
                            {getStateLabels(state.names)[language]}
                          </Chip>

                          {`#${orderNumber}${name ? ` – ${name}` : ''}`}

                          <LinkWrapper
                            onClick={() =>
                              openSalesInNewTab(`/sales/details/${salesId}`)
                            }
                          >
                            <FontAwesomeIcon icon="arrow-up-right-from-square" />
                          </LinkWrapper>
                        </FlexRow>
                      }
                    />
                  )
                ) : sort[0].field === SortProperty.FirstRoomNumber ? (
                  <GroupTitle
                    chipTitle={<T>ParticipantsList:ParticipantRooms.room</T>}
                    style={idx === 0 ? { marginTop: 0 } : undefined}
                    title={renderRoomName(services)}
                  />
                ) : (
                  <></>
                )}
                <ParticipantsList
                  participants={groupParticipants}
                  readOnly={readOnly}
                  dataRows={dataRows}
                  selectedIds={selectedIds}
                  isSelectable={
                    selectedParticipantsSale
                      ? selectedParticipantsSale === salesId
                      : true
                  }
                  handleSelect={handleSelect}
                />
              </FlexColumn>
            )
          })}
        </FlexColumn>
      )
    }

    if (
      fetchState === FetchStates.IDLE &&
      (saleType !== SalesType.Event || !groupedParticipants?.length)
    ) {
      return <MessageWrapper message={<T>ParticipantsList:empty</T>} />
    }
  }

  return (
    <Wrapper noPadding flex={1}>
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="flex-start" justifyContent="center">
          <CreateParticipantButton readOnly={readOnly} />
          <Spacer />
          <SearchInput />
          <Spacer />
          <FilterModal />
          <Spacer />
          <SortBy />
        </FlexRow>
        <Spacer />
        {isListOutdated && <OutdatedListWarning />}
        <Spacer />
        <FlexRow>
          <ParticipantsAgeCategory salesId={salesId} />
          <DataRowsSwitcher dataRows={dataRows} setDataRows={setDataRows} />
          <DocumentsButton ownerId={salesId} />
        </FlexRow>
      </FlexRow>

      <FilterChips />

      <ListWrapper>{renderParticipants()}</ListWrapper>

      {!!newParticipants.length && (
        <>
          <NewParticipantsDivider />
          <ParticipantsList
            participants={newParticipants}
            readOnly={readOnly}
            dataRows={dataRows}
            selectedIds={selectedIds}
            isSelectable={false}
            handleSelect={handleSelect}
          />
        </>
      )}

      {fetchState !== FetchStates.LOADING && <ListTotals />}

      {fetchState !== FetchStates.LOADING && !showAllParticipants && (
        <FlexRow>
          <Pagination
            pagination={pagination}
            goToPage={handleSwitchPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            style={{ marginTop: 0 }}
          />
          <LoadAllButton />
        </FlexRow>
      )}
    </Wrapper>
  )
}

/////////

const ListWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const StyledSpinner = styled(ReactLoading)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem auto;
  `}
`

const Spacer = styled.div`
  width: ${({ theme }) => theme.spacing.gu(2)}rem;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    transition: 0.2s;
    margin: 0 ${({ theme }) => theme.spacing.gu(1)}rem;
  }

  &:hover > svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const Wrapper = styled(FlexColumn)`
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const openSalesInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

  if (newWindow) {
    newWindow.opener = null
  }
}
