import { ApolloQueryResult } from '@apollo/client'

import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import {
  ConfirmationByIdPayload,
  Document as DocumentType,
  InvoiceByIdPayload,
  OfferByIdPayload,
} from '@/modules/Order/types'

import { OrderPhaseType } from '~generated-types'

import { Section } from '../components'
import { Document } from './Document'

type RefetchPayload =
  | ConfirmationByIdPayload
  | InvoiceByIdPayload
  | OfferByIdPayload

type Props = {
  document: DocumentType | null
  freeText?: string | null
  isVisible: boolean
  onUpdateDocument: (document: DocumentType) => void
  orderId: string
  ownerId: string
  ownerType: OrderPhaseType
  readOnly: boolean
  refetch: () => Promise<ApolloQueryResult<RefetchPayload>>
}

export const DocumentContainer = ({ document, ...props }: Props) => (
  <Section flex={1}>
    {document ? (
      <Document document={document} {...props} />
    ) : (
      <FontColor lighter>
        <T>Orders:Documents.empty</T>
      </FontColor>
    )}
  </Section>
)
