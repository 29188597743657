import { ReactNode, useState } from 'react'
import styled from 'styled-components/macro'

import { DataField } from '@/components/DataField'
import { useDialogService } from '@/components/DialogService'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'
import {
  OverlappingError,
  Reservation,
  ResourceReservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import { useReservationsGridState } from '../../Calendar'

type Props = {
  readOnly: boolean
  reservationId: string
  resourceId: string
  resourceQuantity: number
  updateReservations: (reservations: Reservation[]) => void
}

export const Quantity = ({
  readOnly,
  reservationId,
  resourceId,
  resourceQuantity,
  updateReservations,
}: Props) => {
  const { spacing } = useTheme()
  const { confirm, warn } = useDialogService()
  const { changeResource } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })
  const { refetchAvailabilities } = useReservationsGridState()

  const [currentQuantity, setCurrentQuantity] =
    useState<number>(resourceQuantity)

  const handleChangeResource = (value: string | null) => {
    const doChangeResource = (allowOverbooking: boolean) =>
      changeResource(
        allowOverbooking,
        reservationId,
        resourceId,
        value && Number(value) > 0 ? Number(value) : 1
      )

    setCurrentQuantity(value && Number(value) > 0 ? Number(value) : 1)

    return doChangeResource(false)
      .then((data: void | ResourceReservation | OverlappingError | null) => {
        const errorData = data as OverlappingError

        if (errorData.message) {
          if (errorData.type === 'WARNING') {
            return renderConfirmDialog()
              .then(() => doChangeResource(true))
              .catch(() => setCurrentQuantity(resourceQuantity))
          }
          if (errorData.type === 'ERROR') {
            return renderWarningDialog().catch(() =>
              setCurrentQuantity(resourceQuantity)
            )
          }
        }
      })
      .then(() => refetchAvailabilities())
      .catch(() => undefined)
  }

  const renderConfirmDialog = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.continue</T>,
      description: (
        <T>ResourceReservationsCalendar:overlapWarning.description</T>
      ),
      title: <T>ResourceReservationsCalendar:overlapWarning.title</T>,
    })

  const renderWarningDialog = () =>
    warn({
      cancelLabel: <T>common:action.cancel</T>,
      description: <T>ResourceReservationsCalendar:overlapError.description</T>,
      title: <T>ResourceReservationsCalendar:overlapError.title</T>,
    })

  return (
    <InlineModalIconSection icon="cubes">
      <DataFieldWrapper>
        <DataField
          customValueRenderer={(valueLabel: ReactNode) => (
            <span>
              {valueLabel} <T>common:unit.PIECE</T>
            </span>
          )}
          disabled={readOnly}
          handleSubmit={(value: string | null) => handleChangeResource(value)}
          inputType="text"
          min={0}
          style={{
            height: '30px',
            marginLeft: `-${spacing.gu(1)}rem`,
            width: `calc(100% + ${spacing.gu(1)}rem)`,
          }}
          type="number"
          value={currentQuantity ? `${currentQuantity}` : '1'}
          valueStyle={{ height: '30px', padding: `${spacing.gu(1)}rem` }}
          variant="primary"
        />
      </DataFieldWrapper>
    </InlineModalIconSection>
  )
}

////////////

// Tiny visual improvement
const DataFieldWrapper = styled.div`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      height: 30px;
    }
  }
`
