import { ErrorInfo } from 'react'

import { useErrorLogger } from '@/components/Errors'
import { T } from '@/modules/Language'

import { Placeholder } from '../../common'

type Props = {
  error: Error
  info: ErrorInfo
}

export const SalesListErrorBoundaryContent = ({ error, info }: Props) => {
  useErrorLogger({ error, info })

  return (
    <Placeholder
      compact
      content={<T>common:error.common</T>}
      icon="circle-exclamation"
    />
  )
}
