import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { SalesCopyComponent as Component } from '~generated-types'

type Props = {
  checked: boolean
  disabled?: boolean
  label: Component
  onChange: () => void
}

export const Checkbox = ({ checked, disabled, label, onChange }: Props) => (
  <Wrapper alignItems="center" flex="none">
    <CheckboxInput
      checked={checked}
      disabled={disabled}
      noMargin
      onChange={onChange}
    >
      <Label>
        <T>{`SalesDetails:CopySale.label.${label}`}</T>
      </Label>
    </CheckboxInput>
  </Wrapper>
)

/////////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    width: 50%;
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Label = styled(FlexRow)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`
