import { useState } from 'react'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModalFooter,
  InlineModalHeader,
  InlineModalSection,
} from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { SalesType } from '~generated-types'

import TargetManager from './TargetManager'
import TargetsList from './TargetsOptions'

type SectionType = 'TARGETS' | 'TARGET'

type Section = {
  id: string
  type: SectionType
}

type Props = {
  salesId: string
  newTargetNamePreset?: string
  serviceId: string | null
  saleType: SalesType | null
  handleSetTarget: (input: any) => Promise<any>
  onClose: () => void
}

const TargetModalContent = ({
  salesId,
  newTargetNamePreset,
  serviceId,
  saleType,
  handleSetTarget,
  onClose,
}: Props) => {
  const [section, setSection] = useState<Section>({
    id: '',
    type: 'TARGETS',
  })

  return (
    <>
      {section.type === 'TARGET' && (
        <InlineModalHeader
          title={<T>Accommodation:TargetGroup.target</T>}
          onClose={onClose}
          onBackNavigate={() =>
            setSection({
              id: '',
              type: 'TARGETS',
            })
          }
        />
      )}

      <InlineModalSection style={{ flex: 1, margin: 0, padding: 0 }}>
        {section.type === 'TARGETS' ? (
          <TargetsList
            salesId={salesId}
            newTargetNamePreset={newTargetNamePreset}
            serviceId={serviceId}
            saleType={saleType}
            handleSetTarget={handleSetTarget}
            onClose={onClose}
            openTargetManager={(targetId: string) =>
              setSection({
                id: targetId,
                type: 'TARGET',
              })
            }
          />
        ) : (
          section.type === 'TARGET' && <TargetManager targetId={section.id} />
        )}
      </InlineModalSection>

      {section.type === 'TARGET' && (
        <InlineModalFooter justifyContent="flex-start">
          <InnocuousButton
            onClick={() => handleSetTarget({ targetId: section.id })}
          >
            <PrimaryColor>
              <T>Accommodation:TargetGroup.useTarget</T>
            </PrimaryColor>
          </InnocuousButton>
        </InlineModalFooter>
      )}
    </>
  )
}

export default TargetModalContent
