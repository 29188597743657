import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SaleAccommodationQuery } from '~generated-types'

import { AccommodationManager } from './AccommodationManager'

type Props = {
  reservationId: string
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']['request']['room']
  targetId?: string
  groupId?: string
  readOnly?: boolean
}

export const RoomNumber = ({
  reservationId,
  room,
  targetId,
  groupId,
  readOnly,
}: Props) => {
  const { palette } = useTheme()

  const [isManagerOpen, setManagerOpen] = useState(false)

  const isReadOnly = readOnly || !targetId || !groupId

  const roomPreview = {
    [`accommodationLevel-${room.roomType.accommodationLevel.id}`]: true,
    [`category-${room.roomType.category.id}`]: true,
    [`roomType-${room.roomType.id}`]: true,
  }

  return (
    <>
      <RoomWrapper
        alignItems={'center'}
        justifyContent={'center'}
        disabled={isReadOnly}
        onClick={() => !isReadOnly && setManagerOpen(true)}
      >
        <RoomLabel semiBold>
          <T>ParticipantsList:ParticipantRooms.room</T>
          {` #${room.number}, ${room.roomType.name}`}
        </RoomLabel>
        {!isReadOnly && (
          <FontAwesomeIcon icon="pen" color={palette.text.light} size="sm" />
        )}
      </RoomWrapper>

      {isManagerOpen && !readOnly && targetId && groupId && (
        <AccommodationManager
          groupId={groupId}
          targetId={targetId}
          roomPreview={roomPreview}
          focusedRoomId={reservationId}
          onCloseManager={() => setManagerOpen(false)}
        />
      )}
    </>
  )
}

///////////

const RoomWrapper = styled(FlexRow)<{ disabled?: boolean }>`
  border-radius: 8px;
  min-width: 15rem;
  max-width: 15rem;
  cursor: pointer;
  transition: 0.2s;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.light};
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}

  ${({ disabled }) => disabled && `cursor: not-allowed`};

  &:hover {
    ${({ disabled, theme }) =>
      !disabled && `background-color: ${theme.palette.smoke.main}`};
  }
`

const RoomLabel = styled(FontWeight)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
