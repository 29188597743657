import { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ById } from '@/common/types'
import { FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ListingTable, ListingTableSubheader } from '@/modules/Listing/common'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { PrintSize } from '../../types'
import { Building, RoomReservation } from '../types'
import { FreeRoomReservationsTableRow } from './FreeRoomReservationsTableRow'
import { LodgingRoomReservationsTableRow } from './LodgingRoomReservationsTableRow'
import { RoomReservationsTableRow } from './RoomReservationsTableRow'

interface Props {
  data: RoomReservation[]
  printSize: PrintSize
}

type RoomReservationsByBuilding = ById<{
  building: Building
  reservations: RoomReservation[]
}>

export const RoomReservationsTable = ({ data, printSize }: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const dataByBuilding = data.reduce(
    (acc: RoomReservationsByBuilding, val: RoomReservation) => {
      const building = val.room.building

      acc[building.id] = acc[building.id] || {
        building,
        reservations: [],
      }
      acc[building.id].reservations.push(val)

      return acc
    },
    {}
  )
  const dataByBuildingArr = Object.values(dataByBuilding).sort(
    generateCompareFn('building.name')
  )

  const renderTableRow = ({
    room,
    arrivingReservation,
    departingReservation,
    presentReservation,
  }: RoomReservation) => {
    if (!!presentReservation) {
      return (
        <LodgingRoomReservationsTableRow
          key={`lodging-room-reservation-row-${room.id}`}
          reservation={presentReservation}
          room={room}
        />
      )
    }

    if (!!arrivingReservation || !!departingReservation) {
      return (
        <RoomReservationsTableRow
          arrivingReservation={arrivingReservation}
          departingReservation={departingReservation}
          key={`room-reservation-row-${room.id}`}
          room={room}
        />
      )
    }

    if (!arrivingReservation && !departingReservation && !presentReservation) {
      return (
        <FreeRoomReservationsTableRow
          key={`free-room-reservation-row-${room.id}`}
          room={room}
        />
      )
    }

    return null
  }

  return (
    <ListingTable printSize={printSize} collapsePaddingRight>
      <thead>
        <tr>
          <th>
            <FlexRow alignItems="center" justifyContent="space-between">
              <T>Listings:RoomReservationsListing.field.room</T>
              <FontAwesomeIcon
                color={theme.palette.text.lighter}
                icon="broom"
                style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
              />
            </FlexRow>
          </th>
          <th className="horizontal-spacer" />
          <th>
            <T>Listings:RoomReservationsListing.field.reservation</T>
          </th>
          <th>
            <T>Listings:RoomReservationsListing.field.sales</T>
          </th>
          <th>
            <T>Listings:RoomReservationsListing.field.info</T>
          </th>
          <th className="horizontal-spacer" style={{ minWidth: 30 }} />
          <th>
            <T>Listings:RoomReservationsListing.field.reservation</T>
          </th>
          <th>
            <T>Listings:RoomReservationsListing.field.sales</T>
          </th>
          <th>
            <T>Listings:RoomReservationsListing.field.info</T>
          </th>
        </tr>
      </thead>
      <tbody>
        {dataByBuildingArr.map(({ building, reservations }) => (
          <Fragment key={`reservations-building-${building.id}`}>
            <ListingTableSubheader colSpan={9} printSize={printSize}>
              {building.name}{' '}
              <small style={{ marginLeft: theme.spacing.gutterBig }}>
                {translate('Listings:RoomReservationsListing.rooms', language, {
                  count: reservations.length,
                })}
              </small>
            </ListingTableSubheader>
            {reservations
              .sort(generateCompareFn(['room.number']))
              .map(renderTableRow)}
          </Fragment>
        ))}
      </tbody>
    </ListingTable>
  )
}

export default RoomReservationsTable
