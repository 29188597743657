import { ScheduledMealStatus } from '~generated-types'

import { SalesForPrintMeal } from '../../../../../types'

export const getGroupedMeals = (meals: SalesForPrintMeal[]) =>
  meals
    .filter((meal) => meal.status !== ScheduledMealStatus.ManuallyRemoved)
    .reduce((acc: SalesForPrintMeal[], curr) => {
      const similarMealIndex = acc.findIndex(
        (item) =>
          item.start === curr.start &&
          item.duration === curr.duration &&
          item.location?.name === curr.location?.name &&
          item.meal?.name === curr.meal?.name
      )

      if (similarMealIndex !== -1) {
        return acc.map((meal, mealIdx) =>
          mealIdx === similarMealIndex
            ? {
                ...meal,
                quantities: meal.quantities.map((q, idx) => ({
                  ...q,
                  quantity: q.quantity + curr.quantities[idx].quantity,
                })),
              }
            : meal
        )
      }

      return [...acc, curr]
    }, [])
