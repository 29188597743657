import { gql, useLazyQuery } from '@apollo/client'

import {
  UseTaskListQuery as QueryData,
  UseTaskListQueryVariables as QueryVariables,
} from '~generated-types'

export type ListingTask = QueryData['tasks'][0]
export type ListingTaskPurchase = ListingTask['purchaseProducts'][0]

const QUERY = gql`
  query UseTaskList($input: TasksInput!) {
    tasks(input: $input) {
      assignee {
        ... on User {
          email
          id
        }
        ... on UserGroup {
          id
          name
        }
      }
      description
      dueDate
      dueTime
      id
      number
      purchaseProducts {
        attributes {
          quantity
        }
        id
        product {
          id
          items {
            id
            unitPrice {
              id
              price
              vat {
                id
                vatRate
              }
            }
          }
          name
        }
        totalPrice {
          amountVatIncluded
        }
      }
      reservation {
        id
      }
      sales {
        customer {
          customer {
            customerNumber

            ... on CustomerPerson {
              person {
                firstName
                lastName
              }
            }

            ... on CustomerOrganization {
              organization {
                name
              }
            }
          }
        }
        facet {
          abbreviation
          color
          id
          name
        }
        id
        name
        orderNumber
        seller {
          id
          name
          shortName
        }
        type
      }
    }
  }
`

export const useTaskList = () => {
  const [loadTasks, { data, error, loading }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const salesTasks: ListingTask[] = data?.tasks || []

  return {
    error,
    loading,
    loadTasks,
    salesTasks,
  }
}
