import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import { TaskList } from './components'
import { TaskListContextProvider } from './TasksState'

const SECTION: SectionFeatures = {
  icon: 'list-check',
  key: 'tasks',
}
const TasksComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <TaskListContextProvider>
      <TaskList />
    </TaskListContextProvider>
  </SectionContainer>
)

export const Tasks = Object.freeze({
  component: TasksComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
