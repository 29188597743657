import { useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import {
  ListItem as Wrapper,
  ListItemSection as Section,
} from '@/components/List'
import { ElasticProductTitle } from '@/modules/Products/types'
import { useTheme } from '@/theme'

type Props = {
  data: ElasticProductTitle
  disabled: boolean
  isMulti: boolean
  isSelected: boolean
  onItemSelect: () => Promise<string | void | null>
}

export const ListItem = ({
  data: { code, name, sellers },
  disabled,
  isMulti,
  isSelected,
  onItemSelect,
}: Props) => {
  const { palette } = useTheme()

  const [isProcessing, setProcessing] = useState<boolean>(false)

  const handleItemSelect = () => {
    if (disabled) {
      return new Promise<void>((reject) => reject())
    }

    setProcessing(true)

    return isMulti
      ? onItemSelect().finally(() => setProcessing(false))
      : onItemSelect()
  }

  return (
    <Wrapper onClick={handleItemSelect} style={{ height: '40px' }}>
      <FirstSection>
        {isProcessing ? (
          <ReactLoading
            color={palette.primary.main}
            height={20}
            type="spin"
            width={20}
          />
        ) : (
          <FlexRow justifyContent="center">
            {isMulti && (
              <CheckboxInput checked={isSelected} readOnly noMargin />
            )}
          </FlexRow>
        )}
      </FirstSection>

      <Section>
        <Name>{name}</Name>
      </Section>

      <Section>
        <Code>{code}</Code>
      </Section>

      <Section style={{ whiteSpace: 'nowrap' }}>{sellers[0].name}</Section>
    </Wrapper>
  )
}

////////////

const Code = styled.code`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const Name = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const FirstSection = styled(Section)`
  ${({ theme }) => css`
    &&&& {
      padding-left: ${theme.spacing.gu(3)}rem;
      padding-right: ${theme.spacing.gu(1)}rem;
    }
  `}
`
