import { ReactNode } from 'react'

import { useGridContext } from '../GridContext'

type Props = {
  children: (arg0: { [key: string]: any }) => ReactNode
  columnIndexes: number[]
  gridId: string
  id: string
  rowIndexes: number[]
}

export const ItemData = ({
  children,
  columnIndexes,
  gridId,
  id,
  rowIndexes,
}: Props) => {
  const { getAllItemData, getRowDataByIndex, getColumnDataByIndex } =
    useGridContext()
  const columns = columnIndexes
    .map((columnIndex) => getColumnDataByIndex(columnIndex))
    .filter(Boolean)
  const item = getAllItemData(id)
  const rows = rowIndexes
    .map((rowIndex) => getRowDataByIndex(gridId, rowIndex))
    .filter(Boolean)

  return children({ columns, item: item ? item.data : null, rows })
}
