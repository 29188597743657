import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

import TBody from './elements/TBody'
import TR from './elements/TR'

type Props = {
  colSpan?: number
  content: ReactNode
  helper?: ReactNode
  icon?: IconProp
  size?: 'normal' | 'small'
  withBorderTop?: boolean
}

export const SpreadsheetContentPlaceholder = ({
  colSpan,
  content,
  helper,
  icon,
  size = 'normal',
  withBorderTop,
}: Props) => {
  const theme = useTheme()

  return (
    <TBody>
      <TR>
        <PlaceholderPosition
          colSpan={colSpan}
          size={size}
          withBorderTop={withBorderTop}
        >
          <Wrapper>
            {icon && (
              <FontAwesomeIcon
                color={theme.palette.text.lighter}
                icon={icon}
                size="3x"
                style={{ marginBottom: theme.spacing.gutterBig }}
              />
            )}
            <Content size={size}>{content}</Content>
            {helper && <Helper>{helper}</Helper>}
          </Wrapper>
        </PlaceholderPosition>
      </TR>
    </TBody>
  )
}

////////////

const PlaceholderPosition = styled.td<any>`
  &&& {
    padding: ${({ size, theme }) =>
      size === 'small' ? theme.spacing.gu(3) : theme.spacing.gu(10)}rem;
    ${({ withBorderTop }) =>
      withBorderTop &&
      `
      border-top-width: 1px;
      border-radius: 8px;
    `}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div<{ size: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ size, theme }) =>
    size === 'small'
      ? theme.typography.fontSizeBig
      : theme.typography.fontSizeBigger};
  margin-bottom: ${({ size, theme }) =>
    size === 'small' ? theme.spacing.gutterSmall : theme.spacing.gutter};
`

const Helper = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
  `}
`
