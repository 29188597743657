import { gql, useMutation } from '@apollo/client'

import { AccommodationTargetFragment } from '@/modules/Accommodation/Accommodation.fragments'

import {
  AccommodationTargetCreateMutation,
  AccommodationTargetCreateMutationVariables,
} from '~generated-types'

export type {
  AccommodationTargetCreateMutation,
  AccommodationTargetCreateMutationVariables,
}

export const ADD_ACCOMMODATION_TARGET = gql`
  ${AccommodationTargetFragment}

  mutation AccommodationTargetCreate($input: AccommodationTargetCreateInput!) {
    accommodationTargetCreate(input: $input) {
      target {
        ...AccommodationTarget
      }
    }
  }
`

export function useAccommodationTargetCreate() {
  return useMutation<
    AccommodationTargetCreateMutation,
    AccommodationTargetCreateMutationVariables
  >(ADD_ACCOMMODATION_TARGET)
}

export default useAccommodationTargetCreate
