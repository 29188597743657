import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { Room, useRoomReservationsContext } from '@/modules/FrontDesk'

import { Section } from '../common'
import { Participants, RoomStatus } from './components'

type Props = {
  flex: number
  isLast: boolean
  room: Room
}

export const RoomSection = ({ flex, isLast, room }: Props) => {
  const {
    beds,
    extraBeds,
    id,
    number,
    status: { occupants },
  } = room

  const { targetId } = useRoomReservationsContext()

  return (
    <Section
      alignItems="center"
      flex={flex}
      justifyContent="space-between"
      highlight={id === targetId}
      isLastLeft={isLast}
    >
      <Wrapper alignItems="flex-start" flex={1} noPadding>
        <RoomNumber title={number}>{number}</RoomNumber>
        <RoomBeds>
          {beds} + {extraBeds}
        </RoomBeds>
      </Wrapper>

      <Separator />

      <Participants participantRooms={occupants} id={id} />

      <Separator />

      <RoomStatus room={room} />
    </Section>
  )
}

////////////

const RoomBeds = styled.small`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `};
`

const RoomNumber = styled.span`
  display: block;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const Separator = styled.div`
  width: 1px;
  height: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.lighter};
  `};
`

const Wrapper = styled(FlexColumn)`
  width: 70px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `};
`
