import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

type ContextType = {
  addLastUsedSaleId: (id: string) => void
  lastUsedSalesIds: string[]
}

const LastUsedSalesContext = createContext<ContextType>({
  addLastUsedSaleId: () => undefined,
  lastUsedSalesIds: [],
})

type Props = {
  children: ReactNode
}

export const LastUsedSalesContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const [lastUsedSalesIds, setLastUsedSalesIds] = useState<string[]>(
    JSON.parse(localStorage.getItem('lastUsedSales') || '[]')
  )

  // Migrate data from "salesBasket" to "lastUsedSales"
  useEffect(() => {
    const prevSalesIds = JSON.parse(localStorage.getItem('salesBasket') || '[]')

    if (prevSalesIds.length) {
      localStorage.removeItem('salesBasket')
      setLastUsedSalesIds(prevSalesIds)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('lastUsedSales', JSON.stringify(lastUsedSalesIds))
  }, [lastUsedSalesIds])

  const addLastUsedSaleId = (saleId: string) => {
    setLastUsedSalesIds((current) =>
      [saleId, ...[...current].filter((id) => id !== saleId)].slice(0, 10)
    )
  }

  contextValueRef.current = {
    addLastUsedSaleId,
    lastUsedSalesIds,
  }

  return (
    <LastUsedSalesContext.Provider value={contextValueRef.current}>
      {children}
    </LastUsedSalesContext.Provider>
  )
}

export const useLastUsedSalesContext = () => useContext(LastUsedSalesContext)
