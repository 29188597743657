import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'

export const AccommodationLevelChip = styled(Chip)`
  align-self: flex-end;

  ${({ theme }) => css`
    padding: ${theme.spacing.guPx(1) - 6}px ${theme.spacing.guPx(1) - 2}px;
    margin-bottom: ${theme.spacing.guPx(1) - 3}px;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

export const Button = styled.button<{
  borderRadius: string
  flex?: string
  padding?: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({ flex }) => flex || 1};
  background: transparent;
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  cursor: pointer;
  font-weight: 600;
  height: 100%;
  outline: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
  `}

  padding: ${({ padding, theme }) =>
    padding || ` ${theme.spacing.gu(1)}rem ${theme.spacing.gutter}`};

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

export const ContentWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

export const Dates = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-top: ${theme.spacing.guPx(1) - 5}px;
  `}
`

export const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
  `}
`

export const Label = styled.span`
  font-weight: 600;
`

export const ModalContentWrapper = styled.div`
  border-radius: 4px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(1)}rem;
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

export const NoteWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gutterSmall};
    margin-bottom: -${theme.spacing.gutterSmall};
  `}
`

export const NumberInput = styled(Input)`
  &&& {
    ${({ theme }) => css`
      border: 1px solid ${theme.palette.white};
      color: ${theme.palette.primary.dark};
      font-size: ${theme.typography.fontSizeSmall};
      height: ${theme.spacing.gu(3)}rem;
      max-width: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gutterSmall};
    `}

    text-align: end;

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const TextDivider = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

export const TotalNumber = styled(FlexRow)<{ width?: string }>`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
    height: ${theme.spacing.gu(3)}rem;
  `}

  width: ${({ theme, width }) => width ?? `${theme.spacing.gu(9)}rem`};
`

export const TotalNumberBold = styled(TotalNumber)`
  font-weight: 600;

  ${({ theme }) => css`
    width: ${theme.spacing.gutter};
  `}
`

export const TrashIconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}

  cursor: pointer;
`

export const VerticalDivider = styled.div`
  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.light};
  `}
`

export const Wrapper = styled(FlexRow)<{
  dashedBorder?: boolean
  nestedRoom?: boolean
}>`
  height: ${({ nestedRoom, theme }) =>
    nestedRoom ? '100%' : `${theme.spacing.gu(10)}rem`};

  border-top-width: 1px;
  border-top-style: ${({ dashedBorder }) =>
    dashedBorder ? 'dashed' : 'solid'};
  border-top-color: ${({ theme }) => theme.palette.smoke.main};

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:first-child {
    border-top: none;
  }
`
