import { FlexColumn } from '@/components/Layout'

import { ListingReservationTask } from '../useReservationList'
import { ListingTask } from '../useTaskList'

type Props = {
  task: ListingTask | ListingReservationTask
}

export const TaskInfo = ({ task }: Props) => {
  const assigneeName =
    task.assignee?.__typename === 'User'
      ? task.assignee.email
      : task.assignee?.name

  return (
    <FlexColumn noPadding>
      <span>#{task.number}</span>
      {assigneeName ? <strong>{assigneeName}</strong> : null}
    </FlexColumn>
  )
}
