import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'

type EnrollmentTitleProps = {
  chipTitle: ReactNode
  style?: CSSProperties
  title: string | ReactNode
}

export const GroupTitle = ({
  chipTitle,
  style,
  title,
}: EnrollmentTitleProps) => (
  <Wrapper alignItems="center" style={style}>
    <Chip color="secondary" size="sm" variant="outlined">
      {chipTitle}
    </Chip>
    <FontWeight>{title}</FontWeight>
  </Wrapper>
)

///////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(2)}rem 0 ${theme.spacing.gu(1)}rem;
  `}
`
