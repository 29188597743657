import styled from 'styled-components/macro'

import { InlineFieldButton } from '@/components/ExtraButtons'

export const DataFieldButton = styled(InlineFieldButton)`
  padding: 0;
  border-left: solid 1px transparent;
  border-right: solid 1px transparent;
  overflow: hidden;
`
