import { Fragment, Ref, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import {
  Order,
  OrderSelectorData as Data,
  OrderSelectorQuery as Query,
  OrderSelectorVariables as Variables,
} from '@/components/OrderSelector'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { OrderStateChip } from '@/modules/Order'
import { orderServices } from '@/modules/Order/services'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import type {
  ProductPurchaseOrder,
  ProductPurchaseSales,
} from '@/modules/Products/types'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import { OrderState as OS } from '~generated-types'

type Props = {
  order: ProductPurchaseOrder
  purchaseId: string
  purchaseSales: ProductPurchaseSales
  readOnly: boolean
  suggestedName?: string
}

export const OrderSelector = ({
  order,
  purchaseId,
  purchaseSales,
  readOnly: purchaseReadOnly,
  suggestedName,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { createOrder } = orderServices.orderService()
  const { salesReadOnly, salesId, updatePurchase } =
    useSalesProductManagerContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const [fetchOrders, { data }] = useLazyQuery<Data, Variables>(Query, {
    fetchPolicy: 'network-only',
    variables: { id: salesId },
  })

  const selectedOrder = data?.sales.orders.find(({ id }) => id === order.id)
  const orders = data?.sales.orders.filter(({ id }) => id !== order.id)
  const readOnly = purchaseReadOnly || salesReadOnly

  const handleOpenModal = () => {
    fetchOrders()
    setModalOpen(true)
  }

  const handleSelectOrder = (orderId: string) => {
    updatePurchase({ orderId, purchaseId }).finally(() => setModalOpen(false))
  }

  const openSalesInNewTab = () => {
    const newWindow = window.open(
      `/sales/details/${purchaseSales.id}`,
      '_blank',
      'noopener,noreferrer'
    )

    if (newWindow) {
      newWindow.opener = null
    }
  }

  const getOrderLabel = ({
    customer,
    id,
    name,
    orderNumber,
    paymentInfo: {
      totalPrice: { amountVatIncluded },
    },
    lifecycle: { state },
  }: Order) => {
    const disabled = (state !== OS.Open && state !== OS.Correction) || readOnly
    const isSelected = id === order.id

    return (
      <Option
        alignItems="flex-start"
        disabled={disabled}
        isSelected={id === order.id}
        justifyContent="space-between"
        onClick={() => !disabled && !isSelected && handleSelectOrder(id)}
      >
        <FlexColumn alignItems="center" noPadding>
          <NameRow>
            <FontWeight className="order-number">#{orderNumber}</FontWeight>
            {name && <FontWeight> {name}</FontWeight>}
          </NameRow>

          {customer && (
            <NameRow style={{ marginTop: spacing.gutterSmall }}>
              {customer.details.type === 'ORGANIZATION' ? (
                <FontAwesomeIcon
                  fixedWidth
                  icon={['far', 'building']}
                  size="sm"
                />
              ) : (
                <FontAwesomeIcon fixedWidth icon="user" size="xs" />
              )}{' '}
              {customer.details.name}
            </NameRow>
          )}
        </FlexColumn>

        <FlexRow>
          <FontWeight style={{ marginLeft: `${spacing.gu(2)}rem` }}>
            {`${formatCurrency(amountVatIncluded)} €`}
          </FontWeight>
          <OrderStateChipWrapper>
            <OrderStateChip fixedWidth state={state} />
          </OrderStateChipWrapper>
        </FlexRow>
      </Option>
    )
  }

  return purchaseSales.id === salesId ? (
    <ModalContainer
      isOpen={isModalOpen && !!data}
      modal={
        <InlineModal>
          {selectedOrder && (
            <OptionsWrapper>{getOrderLabel(selectedOrder)}</OptionsWrapper>
          )}

          {orders && !!orders.length && !readOnly && (
            <>
              <Divider isFull />

              <OptionsWrapper style={{ maxHeight: 350, overflow: 'auto' }}>
                {orders.map((order, idx) => (
                  <Fragment key={order.id}>
                    {idx !== 0 && <Divider />}
                    {getOrderLabel(order)}
                  </Fragment>
                ))}
              </OptionsWrapper>
            </>
          )}

          {!readOnly && (
            <>
              <Divider isFull />

              <OptionsWrapper>
                <Option
                  onClick={() =>
                    createOrder(salesId, suggestedName).then(
                      (order) => order && handleSelectOrder(order.id)
                    )
                  }
                >
                  <NewOptionLabel>
                    + <T>Orders:Action.ORDER.CREATE</T> {suggestedName}
                  </NewOptionLabel>
                </Option>
              </OptionsWrapper>
            </>
          )}
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <Button
          onClick={handleOpenModal}
          ref={ref as Ref<HTMLButtonElement> | undefined}
        >
          #{order.orderNumber}
        </Button>
      )}
      zIndex={10006}
    />
  ) : (
    <Link justifyContent="center" onClick={openSalesInNewTab}>
      #{purchaseSales.orderNumber}{' '}
      <LinkIcon
        color={palette.primary.dark}
        icon="up-right-from-square"
        size="xs"
      />
    </Link>
  )
}

/////////

const Button = styled.button`
  cursor: pointer;
  border-radius: 4px;
  padding: 0;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gu(4)}rem;
    width: ${theme.spacing.gu(10)}rem;
    margin: 0 ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.lighter};
      cursor: not-allowed;
    `}
  }
`

const Divider = styled.div<{ isFull?: boolean }>`
  width: 100%;
  min-height: 1px;

  ${({ isFull, theme }) => css`
    background: ${isFull
      ? theme.palette.smoke.main
      : theme.palette.smoke.light};
    margin: ${isFull ? 0 : theme.spacing.gu(0.75)}rem 0;
  `}
`

const Link = styled(FlexRow)`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    width: ${theme.spacing.gu(13)}rem;
  `}

  &:hover {
    text-decoration: underline;
  }
`

const LinkIcon = styled(FontAwesomeIcon)`
  margin-bottom: 1px;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(0.75)}rem;
  `}
`

const NameRow = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  text-align: left;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    max-width: ${theme.spacing.gu(30)}rem;
  `}
`

const NewOptionLabel = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const Option = styled(FlexRow)<{ disabled?: boolean; isSelected?: boolean }>`
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.palette.primary.extraLighter};
      border: 1px solid ${theme.palette.primary.main};
      cursor: not-allowed;
    `}

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        background: ${theme.palette.primary.extraLighter};
      `}
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background: ${theme.palette.smoke.lighter};
      cursor: not-allowed;
    `}
`

const OptionsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    min-width: ${theme.spacing.gu(50)}rem;
  `}
`

const OrderStateChipWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gu(0.5)}rem;
    margin-left: ${theme.spacing.gu(2)}rem;
    margin-right: -${theme.spacing.gu(0.5)}rem;
    margin-top: -${theme.spacing.gu(0.5)}rem;
  `}
`
