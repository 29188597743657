import { Chip } from '@/components/Chip'
import { FlexRow } from '@/components/Layout'
import { Theme } from '@/theme'
import { formatCurrency } from '@/utils/currency'
import { typeGuardFactory } from '@/utils/types'

import { ArrivalsParticipant } from '../ArrivalsListing/useArrivalsForDate'
import { DeparturesParticipant } from '../DeparturesListing/useDeparturesForDate'

export const parseParticipantProduct = (
  { services }: ArrivalsParticipant | DeparturesParticipant,
  theme: Theme
) => {
  const participantBedService = services.find(
    typeGuardFactory('__typename', 'ServiceParticipantBed')
  )

  const purchase = participantBedService?.purchaseProduct

  return !!purchase ? (
    <FlexRow alignItems="center" justifyContent="space-between">
      <span style={{ marginRight: theme.spacing.gutter }}>
        {purchase.product.name}
      </span>
      <Chip color="default" noWrap size="xs" variant="outlined">
        {formatCurrency(purchase.totalPrice.amountVatIncluded)} €
      </Chip>
    </FlexRow>
  ) : (
    '—'
  )
}
