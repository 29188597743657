import { gql } from '@apollo/client'

import {
  ParticipantFragmentFull,
  TargetFragment,
} from './ParticipantsList.fragments'

export default Object.freeze({
  ACCOMMODATION_GROUP_TARGETS: gql`
    ${TargetFragment}

    query AccommodationGroupTargets($id: ID!) {
      accommodationGroup(id: $id) {
        id
        targets {
          ...Target
        }
      }
    }
  `,
  ACCOMMODATION_TARGETS: gql`
    query AccommodationTargets($salesId: ID!) {
      sales(id: $salesId) {
        accommodation {
          accommodationGroups {
            id
            name
            sortOrder
            targets {
              default
              id
              name
              sortOrder
            }
          }
          id
        }
        id
      }
    }
  `,
  LANGUAGES_REGISTRY: gql`
    query LanguagesRegistry($locale: Language) {
      registry {
        registryLanguages(locale: $locale) {
          code
          id
          name
        }
      }
    }
  `,
  NATIONALITIES_REGISTRY: gql`
    query NationalitiesRegistry {
      registry {
        countries {
          id
          name
          nationality
          frequentlyUsed
        }
      }
    }
  `,
  ORDERS_DATA: gql`
    query SaleOrdersData($id: ID!) {
      sales(id: $id) {
        id
        type
        orders(filterIsOpen: false) {
          id
          name
          orderNumber
          lifecycle {
            state
          }
        }
      }
    }
  `,
  PARTICIPANT_COUNTS: gql`
    query SalesParticipantCounts($id: ID!) {
      sales(id: $id) {
        id
        participantCounts {
          byAgeCategory {
            count
            ageCategory {
              key
              name
              shortName
            }
          }
        }
      }
    }
  `,
  PARTICIPANT_DEFAULT_VST_DATES: gql`
    query ParticipantDefaultVstDates($id: ID!) {
      salesParticipant(id: $id) {
        id
        vst {
          defaults {
            end
            start
          }
        }
      }
    }
  `,
  PARTICIPANTS_BY_ID: gql`
    ${ParticipantFragmentFull}

    query ParticipantsById($ids: [ID!]!) {
      salesParticipants(ids: $ids) {
        ...SalesParticipantFull
      }
    }
  `,
  ROOM_SUITABLE_PARTICIPANTS: gql`
    query SuitableParticipants($id: ID!, $input: SuitableParticipantsInput) {
      accommodationRoomReservation(id: $id) {
        id
        suitableParticipants(input: $input) {
          labels
          participant {
            id
            age
            ageCategory {
              abbreviation
              key
              name
            }
            ageSource
            birthday {
              year
              month
              date
            }
            firstName
            lastName
            gender
            services {
              id
              ... on ServiceParticipantBed {
                participantRoom {
                  id
                  roomReservation {
                    id
                    request {
                      room {
                        id
                        number
                      }
                    }
                  }
                }
              }
            }
            sales {
              id
            }
          }
        }
      }
    }
  `,
  SALE_AND_ENROLLMENTS_BASE: gql`
    query SaleAndEnrollmentsBase($id: ID!) {
      sales(id: $id) {
        id
        type
        orderNumber
        participantConnection {
          totalElements
        }
        enrollments {
          nodes {
            id
            type
            orderNumber
            name
            participantConnection {
              totalElements
            }
            customer {
              customer {
                id
                ... on CustomerPerson {
                  person {
                    firstName
                    lastName
                  }
                }
                ... on CustomerOrganization {
                  organization {
                    name
                  }
                }
              }
            }
          }
          totalPages
          totalElements
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `,
  SALES_GROUPS: gql`
    query SalesGroups($id: ID!) {
      sales(id: $id) {
        id
        groups {
          label
          values
        }
      }
    }
  `,
  SERVICE_SUITABLE_TARGETS: gql`
    ${TargetFragment}

    query ServiceSuitableTargets($input: SuitableTargetInput!) {
      serviceSuitableTargets(input: $input) {
        labels
        target {
          ...Target
        }
      }
    }
  `,
  SPECIAL_DIETARIES: gql`
    query SpecialDietaries {
      registry {
        allergies {
          name
        }
      }
    }
  `,
  SPECIAL_DIETS: gql`
    query SpecialDiets {
      registry {
        diets {
          name
        }
      }
    }
  `,
  SUITABLE_ROOMS: gql`
    query SaleAccommodation($input: SuitableRoomsInput!) {
      suitableRooms(input: $input) {
        roomReservation {
          id
          participantRooms {
            id
            participant {
              id
              firstName
              lastName
            }
          }
          target {
            id
            name
            sortOrder
            accommodationGroup {
              id
              name
              sortOrder
            }
          }
          request {
            beds
            extraBeds
            features {
              icon
              id
              name
              shortName
            }
            room {
              id
              number
              beds
              extraBeds
              status {
                cleaningStatus
              }
              roomType {
                id
                name
                accommodationLevel {
                  id
                  shortName
                }
                category {
                  id
                }
              }
            }
            checkIn {
              date
              type
            }
            checkOut {
              date
              type
            }
            info
          }
        }
      }
    }
  `,
  SUITABLE_ROOMS_BASE: gql`
    query SaleAccommodationBase($input: SuitableRoomsInput!) {
      suitableRooms(input: $input) {
        roomReservation {
          id
          participantRooms {
            id
            participant {
              id
              firstName
              lastName
            }
          }
          request {
            room {
              id
              number
            }
          }
        }
      }
    }
  `,
  TARGET_BY_ID: gql`
    ${TargetFragment}

    query TargetById($id: ID!) {
      accommodationTarget(id: $id) {
        ...Target
      }
    }
  `,
})
