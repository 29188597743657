import { ChangeEvent, KeyboardEvent } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input, Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'

type Props = {
  flex?: number
  onChange: (value: string) => void
  onSubmit: () => void
  title: string
  type?: 'input' | 'textarea'
  value: string
}

export const AddressSection = ({
  flex = 1,
  onChange,
  onSubmit,
  title,
  type = 'input',
  value,
}: Props) => (
  <AddressGroup flex={flex}>
    <Title>{title}</Title>
    {type === 'input' ? (
      <StyledInput
        autoComplete="none"
        onBlur={onSubmit}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            onSubmit()
          }
        }}
        value={value}
      />
    ) : (
      <Textarea
        autoComplete="none"
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e.target.value)
        }
        height={62} // 3 rows
        value={value}
        onBlur={onSubmit}
      />
    )}
  </AddressGroup>
)

const AddressGroup = styled(FlexColumn)`
  width: 100%;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const StyledInput = styled(Input)<any>`
  &&& {
    ${({ theme }) => css`
      border: 1px solid ${theme.palette.smoke.dark};
    `}
  }
`

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))<any>`
  &&& {
    line-height: 1.2;
    resize: vertical;

    min-height: ${({ height }) => height}px;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
      border-color: ${theme.palette.smoke.dark};
    `}
  }
`
