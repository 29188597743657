import { RefObject } from 'react'
import styled, { css } from 'styled-components/macro'

export interface InputProps {
  borderless?: boolean
  color?: string
  disabled?: boolean
  ref?: RefObject<HTMLInputElement>
}

export const Input = styled.input.attrs(() => ({
  autoComplete: 'off',
}))<InputProps>`
  &:not([type='checkbox']):not([type='radio']) {
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    outline: none;
    box-sizing: border-box;

    ${({ borderless, color, disabled, theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
      height: ${theme.spacing.gu(5)}rem;
      line-height: ${theme.spacing.gu(2)};
      color: ${disabled
        ? theme.palette.text.lighter
        : theme.palette.text.light};
      font-family: ${theme.typography.fontFamily};
      font-size: ${theme.typography.fontSizeBase};
      background: ${disabled ? theme.palette.smoke.light : theme.palette.white};
      border: 1px solid ${theme.palette.smoke.main};
      border-color: ${disabled
        ? theme.palette.smoke.main
        : color
          ? color
          : borderless
            ? 'transparent'
            : theme.palette.smoke.dark};
    `}

    border-radius: 6px;
    transition: all 0.1s ease-out;

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};

    &:focus,
    &:active {
      ${({ disabled, theme }) => css`
        background: ${theme.palette.smoke.light};
        border-color: ${disabled
          ? theme.palette.smoke.main
          : theme.palette.primary.main};
      `}
    }
  }
`

export default Input
