import styled, { keyframes } from 'styled-components/macro'

const popIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const DialogBackdrop = styled.div`
  position: fixed;
  z-index: 20001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(4, 14, 31, 0.4);
  animation: ${popIn} 0.4s cubic-bezier(0, 0.83, 0.75, 0.91);
`
