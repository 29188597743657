import { CustomerAddress } from '../types'

export default function parseAddressName({
  label,
  postalAddress,
}: CustomerAddress): string {
  const addressLine = postalAddress?.address1 || postalAddress?.address2 || ''

  return (
    label ||
    `${addressLine}${!!addressLine && !!postalAddress?.city ? ', ' : ''}${
      postalAddress?.city || ''
    }`
  )
}
