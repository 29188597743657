import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'

import { CheckInType, CheckOutType } from '~generated-types'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import { PendingRoomReservation as PendingRoomReservationT } from '../SalesReservationManager.types'
import { Icon } from './Icon'
import { RangePicker } from './RangePicker'
import { ReservationCard } from './ReservationCard'
import { ReservationDataRow } from './ReservationDataRow'
import { RoomHeader } from './RoomHeader'

type Props = {
  data: PendingRoomReservationT
  groupId: string
}

export const PendingRoomReservation = ({ data, groupId }: Props) => {
  const { createReservation, processing, removeReservation } =
    useReservationListContext()

  return (
    <ReservationCard
      onRemove={() => removeReservation(data.id)}
      title={
        <RoomHeader
          roomNumber={data.roomNumber}
          roomTypeName={data.roomTypeName}
        />
      }
      type="PENDING_ROOM_RESERVATION"
    >
      <Gutter type={1}>
        <ReservationDataRow header={<Icon icon={['far', 'calendar']} />}>
          <RangePicker
            checkIn={{
              date: data.range?.from.format('YYYY-MM-DD') || '',
              type: CheckInType.Standard,
            }}
            checkOut={{
              date: data.range?.to.format('YYYY-MM-DD') || '',
              type: CheckOutType.Standard,
            }}
            reservationId={data.id}
          />
        </ReservationDataRow>
        <FlexRow justifyContent="flex-end">
          <InnocuousButton
            disabled={
              !data.range || data.range.from.isSame(data.range.to) || processing
            }
            onClick={() => createReservation(data.id, groupId)}
          >
            <PrimaryColor>
              <T>Accommodation:SalesReservationManager.confirm</T>
            </PrimaryColor>
          </InnocuousButton>
        </FlexRow>
      </Gutter>
    </ReservationCard>
  )
}

export default PendingRoomReservation
