import React, { ReactNode, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import {
  ActionDropdownButton,
  ActionDropdownList,
  ActionDropdownListItem,
} from '@/components/ActionDropdown/components'
import { Dropdown, DropdownRenderProps } from '@/components/Dropdown'
import { useTheme } from '@/theme'
import { ElasticAggregation } from '@/utils/api'

import { buttonCss } from './../styles'

type Props = {
  activeItemsLabel: ReactNode
  aggregation: ElasticAggregation
  allItemsLabel: ReactNode
  field: 'active' | 'isOpen'
  inactiveItemsLabel: ReactNode
  setQuery: (...args: Array<any>) => any
  value: string | null | undefined
}

export const StatusToggle = ({
  activeItemsLabel,
  aggregation,
  allItemsLabel,
  field,
  inactiveItemsLabel,
  setQuery,
  value,
}: Props) => {
  const theme = useTheme()

  // @ts-ignore
  const closedBucket = aggregation.buckets.find(({ key }) => key === 0)
  // @ts-ignore
  const openBucket = aggregation.buckets.find(({ key }) => key === 1)

  const closedCount = closedBucket ? closedBucket.doc_count : 0
  const openCount = openBucket ? openBucket.doc_count : 0
  const totalCount = closedCount + openCount

  useEffect(() => {
    setQuery(getQuery(value, field))
  }, [field, setQuery, value])

  const renderOption = (
    filter: string | null | undefined,
    toggleDropdown: (...args: Array<any>) => any
  ) => (
    <ActionDropdownListItem key={`dropdown-item-${filter || 'all'}`}>
      <OptionButton
        onClick={(e: React.MouseEvent) => {
          setQuery(getQuery(filter, field))
          toggleDropdown(e)
        }}
        type="button"
      >
        {renderSelectorValue(filter)}
      </OptionButton>
    </ActionDropdownListItem>
  )

  const renderSelection = (filter: any) => {
    switch (filter) {
      case 'inactive':
        return inactiveItemsLabel
      case 'active':
        return activeItemsLabel
      default:
        return allItemsLabel
    }
  }

  const renderSelectorValue = (filter: any) => {
    const count =
      filter === 'active'
        ? openCount
        : filter === 'inactive'
        ? closedCount
        : totalCount

    return (
      <>
        <ActiveIndicator isActive={value === filter} />
        <OptionLabel>{renderSelection(filter)}</OptionLabel>
        <OptionQuantity>{count}</OptionQuantity>
      </>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <Dropdown
        noPadding
        positioning={{
          left: `calc(100% + ${theme.spacing.gu(1)}rem)`,
          top: '0',
        }}
        renderControl={({ toggleDropdown }) => (
          <StatusButton onClick={toggleDropdown}>
            <SelectionWrapper>{renderSelection(value)}</SelectionWrapper>
            <FontAwesomeIcon icon="caret-down" />
          </StatusButton>
        )}
        renderContent={({ toggleDropdown }: DropdownRenderProps) => (
          <ActionDropdownList>
            {renderOption(null, toggleDropdown)}
            {renderOption('active', toggleDropdown)}
            {renderOption('inactive', toggleDropdown)}
          </ActionDropdownList>
        )}
      />
    </div>
  )
}

////////////

type ActiveIndicatorProps = {
  isActive: boolean
}

const ActiveIndicator = ({ isActive }: ActiveIndicatorProps) => {
  const theme = useTheme()

  return (
    <span style={{ flex: '0 0 1.5rem' }}>
      {isActive && (
        <FontAwesomeIcon
          color={theme.palette.text.light}
          icon="check"
          size="xs"
        />
      )}
    </span>
  )
}

const OptionButton = styled(ActionDropdownButton)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 300px;
`

const OptionLabel = styled.span`
  flex: 1;
`

const OptionQuantity = styled.span`
  flex: 0 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
    margin-left: ${theme.spacing.gutterBig};
  `}
`

const StatusButton = styled.button`
  ${buttonCss};
`

const SelectionWrapper = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const getQuery = (value: string | null | undefined, field: string) => {
  if (value === 'active' || value === 'inactive') {
    return {
      query: {
        term: { [field]: value === 'active' },
      },
      value,
    }
  }

  return { value: null }
}
