import ReactLoading from 'react-loading'

import { FullScreenPlaceholder } from '@/components/FullScreenPlaceholder'
import { useTheme } from '@/theme'

export const FullScreenLoader = () => {
  const theme = useTheme()

  return (
    <FullScreenPlaceholder>
      <ReactLoading type={'bubbles'} color={theme.palette.smoke.dark} />
    </FullScreenPlaceholder>
  )
}

export default FullScreenLoader
