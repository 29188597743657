import { gql } from '@apollo/client'

export const AVAILABILITY_QUERY = gql`
  fragment RoomsAndBeds on RoomsAndBeds {
    beds
    extraBeds
    rooms
  }

  fragment DailyAccommodationStatus on DailyAccommodationStatus {
    confirmed {
      ...RoomsAndBeds
    }
    dates
    tentative {
      ...RoomsAndBeds
    }
    totalCapacity {
      ...RoomsAndBeds
    }
  }

  query AccommodationAvailability($input: AccommodationAvailabilityInput!) {
    accommodationAvailability(input: $input) {
      roomTypes {
        rooms {
          room {
            beds
            building {
              id
              name
            }
            extraBeds
            features {
              id
              name
              icon
              shortName
            }
            floor
            id
            internalInfo
            number
          }
          status {
            ...DailyAccommodationStatus
          }
        }
        roomType {
          accommodationLevel {
            id
            name
          }
          category {
            id
            name
          }
          id
          name
        }
        status {
          ...DailyAccommodationStatus
        }
      }
    }
  }
`
