import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { SalesProductManager } from '@/modules/Products'
import { ActionButton } from '@/modules/Products/components'
import { useSalesProductListContext } from '@/modules/Products/hooks'
import { SalesProduct as SalesProductType } from '@/modules/Products/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'
import { formatCurrency } from '@/utils/currency'

import {
  Feature,
  PurchaseProductAction as PPA,
  SalesType,
  TargetType,
} from '~generated-types'

import { Actions } from './Actions'
import { Settings } from './Settings'

type TargetTypeAmount = {
  type: TargetType
  amount: number
}

type Props = {
  isFirst: boolean
  isLast: boolean
  product: SalesProductType
}

export const SalesProduct = ({ isFirst, isLast, product }: Props) => {
  const { palette, spacing } = useTheme()
  const {
    data: {
      commissionRate,
      facet: { features },
      id: salesId,
      seller,
      type: salesType,
    },
    saleReadOnly,
  } = salesHooks.useSalesDetailsContext()
  const { addPurchase, updateProduct } = useSalesProductListContext()

  const { catalog, id, name, notes, purchases, sales, settings } = product

  const isCommissionEnabled = !!features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const sharedProductReadOnly = sales.id !== salesId

  const [hover, setHover] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [subfieldHover, setSubfieldHover] = useState<boolean>(false)

  const onSubfieldHover = (hover: boolean) => {
    setHover(!hover)
    setSubfieldHover(hover)
  }

  const mouseActionsHandler = {
    hover,
    onClick: () => (hover ? setModalOpen(true) : null),
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
    onMouseOver: () => (subfieldHover ? null : setHover(true)),
  }

  const subfieldMouseActionsHandler = {
    onMouseEnter: () => onSubfieldHover(true),
    onMouseLeave: () => onSubfieldHover(false),
  }

  const filteredPurchases = purchases.filter(
    ({ link }) => salesType === SalesType.Event || salesId === link.sales.id
  )

  const purchaseReadOnly = !!filteredPurchases.find(
    ({ status }) =>
      !status.validatedActions.find((a) => a.action === PPA.Update)?.valid
  )

  const totalPrice = +filteredPurchases
    .reduce((acc, { totalPrice }) => acc + totalPrice.amountVatIncluded, 0)
    .toFixed(2)

  const targetTypes = filteredPurchases.reduce(
    (acc: TargetTypeAmount[], { attributes: { quantity }, targetType }) => {
      if (acc.find(({ type }) => type === targetType)) {
        return acc.map((a) =>
          a.type === targetType ? { ...a, amount: a.amount + quantity } : a
        )
      }

      return [...acc, { amount: quantity, type: targetType }]
    },
    []
  )

  return (
    <>
      <Wrapper
        alignItems="center"
        isFirst={isFirst}
        isLast={isLast}
        {...mouseActionsHandler}
      >
        <NameContainer>
          {sharedProductReadOnly && (
            <Tooltip
              content={<T>Products:SalesProductList.eventProduct</T>}
              trigger={(triggerProps) => (
                <span {...triggerProps}>
                  <Icon fixedWidth icon="link" />
                </span>
              )}
            />
          )}

          {purchaseReadOnly && !sharedProductReadOnly && !saleReadOnly && (
            <Tooltip
              content={<T>Products:SalesProductList.lockedProduct</T>}
              trigger={(triggerProps) => (
                <span {...triggerProps}>
                  <Icon fixedWidth icon="lock" />
                </span>
              )}
            />
          )}

          <NameWrapper
            alignItems="flex-start"
            justifyContent="center"
            noPadding
          >
            <Name>{name}</Name>

            {notes && (
              <Tooltip
                content={notes}
                maxWidth={`${spacing.gu(36)}rem`}
                placement="bottom-start"
                trigger={(triggerProps) => (
                  <Notes {...triggerProps}>{notes}</Notes>
                )}
              />
            )}
          </NameWrapper>
        </NameContainer>

        <Divider style={{ margin: `0 ${spacing.gu(2)}rem` }} />

        <Catalog>{catalog.name}</Catalog>

        <Divider style={{ margin: `0 ${spacing.gu(2)}rem` }} />

        {!saleReadOnly && (
          <FlexRow {...subfieldMouseActionsHandler}>
            <ActionButton
              content={<T>Products:ProductManager.action.addPurchase</T>}
              icon="plus"
              iconColor={palette.primary.dark}
              onClick={() => addPurchase(product.id)}
              style={{ marginRight: `${spacing.gu(1)}rem` }}
            />
          </FlexRow>
        )}

        {targetTypes.sort(generateCompareFn('type')).map(({ amount, type }) => (
          <TargetTypesWrapper key={`target-type-${type}`} alignItems="center">
            <Tooltip
              content={
                <>
                  <T>{`Products:TargetType.type.${type}`}</T>
                  {amount > 1 && <TypeAmount>× {amount}</TypeAmount>}
                </>
              }
              trigger={(triggerProps) => (
                <Chip
                  color="primary"
                  size="sm"
                  variant="outlined"
                  {...triggerProps}
                >
                  <T>{`Products:TargetType.abbrev.${type}`}</T>
                  {amount > 1 && <TypeAmount> × {amount}</TypeAmount>}
                </Chip>
              )}
            />
          </TargetTypesWrapper>
        ))}

        <Spacer />

        {salesType === SalesType.Event && settings && (
          <FlexRow {...subfieldMouseActionsHandler}>
            <Settings
              id={id}
              readOnly={saleReadOnly}
              settings={settings}
              updateProduct={updateProduct}
            />
          </FlexRow>
        )}

        <Price>{formatCurrency(totalPrice)} €</Price>

        {!saleReadOnly && (
          <>
            <Divider
              style={{ margin: `0 ${spacing.gu(1)}rem 0 ${spacing.gu(2)}rem` }}
            />

            <FlexRow {...subfieldMouseActionsHandler}>
              <Actions
                allowDeleting={!sharedProductReadOnly && !purchaseReadOnly}
                catalog={catalog}
                productId={id}
              />
            </FlexRow>
          </>
        )}
      </Wrapper>

      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <SalesProductManager
            commission={commission}
            context="SALES"
            onClose={() => setModalOpen(false)}
            product={product}
            productId={product.id}
            readOnly={saleReadOnly}
            salesId={salesId}
            salesType={salesType}
            sellerId={seller?.id}
          />
        }
        onClose={() => setModalOpen(false)}
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

///////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(0.5)}rem 0 ${theme.spacing.gu(1)}rem;
    color: ${theme.palette.primary.dark};
  `}
`

const NameContainer = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
    max-width: ${theme.spacing.gu(36)}rem;
    min-width: ${theme.spacing.gu(36)}rem;
  `}
`

const Divider = styled.div`
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Name = styled.span`
  font-weight: 500;
`

const NameWrapper = styled(FlexColumn)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`

const Notes = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Catalog = styled.span`
  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(22)}rem;
  `}
`

const Price = styled.span`
  font-weight: 500;
  text-align: right;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(16)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const TypeAmount = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`

const TargetTypesWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexRow)<{
  hover: boolean
  isFirst: boolean
  isLast: boolean
}>`
  cursor: pointer;
  transition: 0.2s;
  margin-top: -1px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ isLast, isFirst }) => css`
    border-bottom-left-radius: ${isLast ? '6px' : '0px'};
    border-bottom-right-radius: ${isLast ? '6px' : '0px'};
    border-top-left-radius: ${isFirst ? '6px' : '0px'};
    border-top-right-radius: ${isFirst ? '6px' : '0px'};
  `}

  background-color: ${({ hover, theme }) =>
    hover ? theme.palette.smoke.lighter : theme.palette.white};
`
