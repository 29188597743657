import { Pagination } from '@/components/Pagination'

import { useEnrollmentsContext } from './EnrollmentsContext'

export const EnrollmentFooter = () => {
  const { goToPage, pageSize, setPageSize, pagination } =
    useEnrollmentsContext()

  return (
    <Pagination
      pagination={pagination}
      goToPage={goToPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  )
}
