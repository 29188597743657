import { RefObject, UIEvent, useEffect, useState } from 'react'
import { parse, stringify } from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticSales } from '@/modules/Sales/types'
import { useTheme } from '@/theme'

import { SalesPaymentInfoQuery } from '~generated-types'

import { ModalSheetOverlay, ScrollIndicator } from '../common'
import { SalesListItem } from './components/SalesListItem'
import { SalesModal } from './components/SalesModal'

type GraphQLSales = SalesPaymentInfoQuery['salesAll'][0]

type Props = {
  data: ElasticSales[]
  gqlData: GraphQLSales[]
  hasMoreData: boolean
  fetchMoreData: () => void
  showPrices: boolean
  scrollRef: RefObject<HTMLDivElement>
  totalResults: number
}

export const SalesList = ({
  data,
  gqlData,
  hasMoreData,
  fetchMoreData,
  showPrices,
  scrollRef,
  totalResults,
}: Props) => {
  const { palette } = useTheme()
  const { language } = useLanguageContext()

  const history = useHistory()

  const [targetSalesId, setTargetSalesId] = useState<string | null>(null)
  const [scrollTop, setScrollTop] = useState<number>(0)

  useEffect(() => {
    const { salesId } = parse(window.location.search || '')

    if (salesId && typeof salesId === 'string') {
      setTargetSalesId(salesId)
    }
  }, [])

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    if (scrollRef && scrollRef.current === event.target) {
      setScrollTop(event.currentTarget.scrollTop)
    }
  }

  const onItemClose = () => {
    setTargetSalesId(null)

    const { salesId, ...restSearch } = parse(window.location.search || '')

    history.push({
      pathname: window.location.pathname,
      search: stringify(restSearch),
    })
  }

  const onItemOpen = (salesId: string) => {
    setTargetSalesId(salesId)

    const prevSearch = parse(window.location.search || '')

    history.push({
      pathname: window.location.pathname,
      search: stringify({ salesId, ...prevSearch }),
    })
  }

  return (
    <>
      <ScrollIndicator hasScrolled={!!scrollTop} zIndex={2} />

      <ScrollWrapper
        id="scrollable-container"
        onScroll={onScroll}
        ref={scrollRef}
      >
        <InfiniteScroll
          dataLength={data.length}
          endMessage={
            <Totals justifyContent="center">
              <T>ElasticFilterSearchList:total</T>{' '}
              {translate('ElasticFilterSearchList:totalPostfix', language, {
                count: totalResults,
              })}
            </Totals>
          }
          hasMore={hasMoreData}
          loader={
            <Loader justifyContent="center">
              <ReactLoading
                color={palette.smoke.main}
                height={40}
                type="spin"
                width={40}
              />
            </Loader>
          }
          next={fetchMoreData}
          scrollableTarget="scrollable-container"
          style={{ overflow: 'hidden' }}
        >
          <FlexColumn>
            {data.map((item) => (
              <SalesListItem
                gqlItem={gqlData.find(({ id }) => id === item.id) || null}
                item={item}
                key={item.id}
                onClick={() => onItemOpen(item.id)}
              />
            ))}
          </FlexColumn>
        </InfiniteScroll>
      </ScrollWrapper>

      <SalesModal
        id={targetSalesId}
        onClose={onItemClose}
        showPrices={showPrices}
      />

      <ModalSheetOverlay isOpen={!!targetSalesId} />
    </>
  )
}

/////

const Loader = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(3)}rem 0;
  `}
`

const ScrollWrapper = styled(FlexColumn)`
  overflow: auto;

  ${({ theme }) => css`
    height: calc(100% - ${theme.spacing.gu(9)}rem);
    color: ${theme.palette.text.light};
  `}
`

const Totals = styled(FlexRow)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(2)}rem 0 ${theme.spacing.gu(4)}rem 0;
  `}
`
