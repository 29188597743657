import { gql } from '@apollo/client'

import { productFragments } from '../fragments'

export default gql`
  ${productFragments.catalogProduct}

  query CatalogProductById($id: ID!) {
    catalogProduct(id: $id) {
      ...CatalogProduct
    }
  }
`
