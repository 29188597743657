import { gql, Reference, useMutation } from '@apollo/client'

import type {
  DeleteMealMutation,
  DeleteMealMutationVariables,
} from '~generated-types'

import { MealFragment } from '../fragments'

const DELETE_MEAL_MUTATION = gql`
  ${MealFragment}

  mutation DeleteMeal($id: ID!) {
    scheduledMealDelete(id: $id) {
      ...Meal
    }
  }
`

type Params = {
  id: string
}

export const useDeleteMealMutation = ({ id }: Params) =>
  useMutation<DeleteMealMutation, DeleteMealMutationVariables>(
    DELETE_MEAL_MUTATION,
    {
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data && !data.scheduledMealDelete) {
          cache.modify({
            fields: {
              scheduledMealsBySales: (existingRefs, { readField }) =>
                existingRefs.filter(
                  (ref: Reference) => id !== readField('id', ref)
                ),
            },
          })
        }
      },
    }
  )
