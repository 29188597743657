import React, { useEffect } from 'react'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { Item } from './Item'

const itemSource = {
  beginDrag: (props: any) => {
    return props
  },
  endDrag: () => {
    return
  },
}

export const DraggableItem = DragSource(
  'ITEM',
  itemSource,
  (connect, monitor) => ({
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)<any>((props: any) => {
  const {
    columnIndexes,
    connectDragPreview,
    connectDragSource,
    gridId,
    id,
    isDragging,
    rowIndexes,
  } = props

  useEffect(() => {
    if (connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      // @ts-ignore
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
      })
    }
  }, [rowIndexes, columnIndexes, connectDragPreview])

  // @ts-ignore
  return connectDragSource(
    // We are using 'transform' later on which moves the content from its
    // original position. We need to 'nullify' the element in the original
    // position in order to avoid the situation where the user will accidentally
    // drag from the invisible root element.
    <div style={{ height: 0, width: 0 }}>
      <Item
        columnIndexes={columnIndexes}
        gridId={gridId}
        id={id}
        isDragging={isDragging}
        rowIndexes={rowIndexes}
      />
    </div>
  )
})
