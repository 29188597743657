import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'

export default function getDateRange(from: string, to: string): string[] {
  const iter = moment(from).startOf('day')
  const end = moment(to).startOf('day')
  const arr: string[] = []

  if (!iter.isValid() || !end.isValid()) {
    console.warn('Invalid dates')
    return arr
  }

  while (iter.isSameOrBefore(end)) {
    arr.push(iter.format(DATE_FORMAT))
    iter.add(1, 'days')
  }

  return arr
}
