import moment from 'moment'

export const getTimeString = (time: string) => time.slice(0, -3)

export const isTimeValid = (time: string) =>
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/.test(time)

export const scrollToSelectedTime = (time: string) => {
  const momentTime = moment(time, 'HH:mm')

  const isTimeFromOptions = momentTime.get('minutes') % 15 === 0

  const roundedTime = isTimeFromOptions
    ? time
    : momentTime.startOf('hour').add(30, 'minutes').format('HH:mm')

  setTimeout(() => {
    const selectedEl = document.getElementById(`time-option-${roundedTime}`)

    if (selectedEl) {
      selectedEl.scrollIntoView({ block: 'center' })
    }
  }, 0)
}

export const transformTime = (time: string) => {
  const timeString = time.replace(/\./g, ':')

  return timeString.includes(':')
    ? timeString
    : timeString.slice(0, -2) + ':' + timeString.slice(-2)
}
