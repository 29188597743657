import { gql, useQuery } from '@apollo/client'

import {
  UseResourceReservationQuery as QueryData,
  UseResourceReservationQueryVariables as QueryVariables,
} from '~generated-types'

import ResourceReservationFragment from '../fragments/resourceReservation'
import { ResourceReservation } from '../types'

const QUERY = gql`
  ${ResourceReservationFragment}

  query UseResourceReservation($id: ID!) {
    resourceReservation(id: $id) {
      ...ResourceReservation
    }
  }
`

type Params = {
  id: string
}

export function useResourceReservation({ id }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { id },
    }
  )

  const reservation: ResourceReservation | null | undefined = data
    ? data.resourceReservation
    : null

  return {
    error,
    loading,
    refetch,
    reservation,
  }
}

export default useResourceReservation
