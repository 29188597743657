import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableInput } from '@/components/DataTable'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  duration: number
  mealId: string
}

export const DurationInput = ({ duration, mealId }: Props) => {
  const { readOnly, updateProductItemMeal } = useSalesProductManagerContext()

  const [value, setValue] = useState<string>(`${duration}`)

  const handleUpdateDuration = () => {
    const durationValue = Number(value) || 0

    setValue(`${durationValue}`)

    return updateProductItemMeal({
      duration: durationValue,
      id: mealId,
    }).then((res) => !res && setValue(`${duration}`))
  }

  return (
    <Wrapper>
      <Input
        disabled={readOnly}
        onBlur={handleUpdateDuration}
        onChange={(e) => setValue(e.target.value)}
        onFocus={(e) => e.currentTarget.select()}
        endAdornment="min"
        showBorder
        flex="unset"
        type="number"
        value={value}
      />
    </Wrapper>
  )
}

/////////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gu(4.5)}rem 0 ${theme.spacing.gu(0.5)}rem;
      width: ${theme.spacing.gu(9)}rem;
    `}

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}

  span {
    ${({ theme }) => css`
      width: ${theme.spacing.gu(5)}rem;
    `}
  }
`
