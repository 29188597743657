import styled, { css } from 'styled-components/macro'

export const HeaderSectionSeparator = styled.div`
  border-radius: 2px;
  width: 1px;
  align-self: stretch;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
  `}
`
