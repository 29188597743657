import { gql } from '@apollo/client'

export const SetSalesGroupMutation = gql`
  mutation SetSalesGroup($input: SalesGroupsInput!) {
    salesSetGroups(input: $input) {
      id
      groups {
        label
        values
      }
    }
  }
`
