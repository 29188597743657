import { gql } from '@apollo/client'

import { salesFragments } from '../fragments'

export default gql`
  ${salesFragments.salesAssignee}

  query SalesAssigners {
    users {
      ...SalesAssignee
    }
  }
`
