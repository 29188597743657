import { gql } from '@apollo/client'

export default gql`
  mutation UpdateCustomerAddress($input: CustomerUpdateAddressInput!) {
    customerUpdateAddress(input: $input) {
      address {
        id
        label
        postalAddress {
          address1
          address2
          city
          country
          postcode
        }
      }
    }
  }
`
