import { gql, useMutation } from '@apollo/client'

import { ParticipantTargetFragment } from '@/modules/ParticipantsList/ParticipantsList.fragments'

import {
  TargetParticipantRoomMutation,
  TargetParticipantRoomMutationVariables,
} from '~generated-types'

export type {
  TargetParticipantRoomMutation,
  TargetParticipantRoomMutationVariables,
}

export const TARGET_PARTICIPANT_ROOM = gql`
  ${ParticipantTargetFragment}

  mutation TargetParticipantRoom($input: AccommodationRoomTargetInput!) {
    accommodationRoomTarget(input: $input) {
      roomReservation {
        id
        target {
          ...ParticipantTarget
        }
      }
    }
  }
`

export function useTargetParticipantRoom() {
  return useMutation<
    TargetParticipantRoomMutation,
    TargetParticipantRoomMutationVariables
  >(TARGET_PARTICIPANT_ROOM)
}

export default useTargetParticipantRoom
