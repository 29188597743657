import styled, { css } from 'styled-components/macro'

export const InputWrapper = styled.span`
  display: inline-flex;
  position: relative;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`

export default InputWrapper
