import { gql } from '@apollo/client'

export default gql`
  query WebshopThemes {
    registry {
      webshopThemes {
        id
        name
      }
    }
  }
`
