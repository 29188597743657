interface Props {
  colSpan: number
  hideFromPrint?: boolean
}

export const ListingTableRowSpacer = ({ colSpan, hideFromPrint }: Props) => (
  <tr
    className={hideFromPrint ? 'hide-from-print' : ''}
    style={{ pageBreakAfter: 'avoid' }}
  >
    <td
      colSpan={colSpan}
      style={{
        borderLeft: 'solid 1px white',
        borderRight: 'solid 1px white',
      }}
    />
  </tr>
)

export default ListingTableRowSpacer
