import { Participant, useUpdateParticipant } from '@/modules/FrontDesk'

import { SalesParticipantUpdateInput as UpdateInput } from '~generated-types'

import { Address, Age, Gender, Name } from './components'

type Props = {
  participant: Participant
}

export const ParticipantManager = ({ participant }: Props) => {
  const [update] = useUpdateParticipant()

  const handleUpdateParticipant = (input: UpdateInput) =>
    update({ variables: { input } }).catch(() => undefined)

  return (
    <>
      <Name
        participant={participant}
        updateParticipant={handleUpdateParticipant}
      />

      <Gender
        participant={participant}
        updateParticipant={handleUpdateParticipant}
      />

      <Age
        participant={participant}
        updateParticipant={handleUpdateParticipant}
      />

      <Address
        participant={participant}
        updateParticipant={handleUpdateParticipant}
      />
    </>
  )
}
