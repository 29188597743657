import { ReactNode } from 'react'
import { StateProvider } from '@appbaseio/reactivesearch'
import isEmpty from 'lodash.isempty'
import styled, { css } from 'styled-components/macro'

import { DimensionSelection } from './types'

type Props = {
  componentId: string
  placeholder: ReactNode
  selection: DimensionSelection
}

const CollapsedDimensionSelectorValue = ({ placeholder, selection }: Props) => (
  <StateProvider
    render={() => (
      <Wrapper>
        {isEmpty(selection) ? (
          <ValuePlaceholder>– {placeholder} –</ValuePlaceholder>
        ) : (
          Object.keys(selection)
            .sort()
            .map((dimension) => (
              <Row key={`collapsed-dimension-${dimension}`}>
                <Label>{dimension}</Label>
                <Value>{selection[dimension].join(', ')}</Value>
              </Row>
            ))
        )}
      </Wrapper>
    )}
  />
)

export default CollapsedDimensionSelectorValue

////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.smoke.lighter};
    border: 1px solid ${theme.palette.smoke.main};
  `}

  @media (max-width: 900px) {
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const Row = styled.div`
  display: inline-flex;
  align-items: baseline;
`

const Label = styled.span`
  flex: 0 0 25%;
  white-space: pre-wrap;
  text-align: right;
  font-variant-caps: all-small-caps;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Value = styled.span`
  font-weight: 400;
  flex: 1;
  white-space: pre-wrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const ValuePlaceholder = styled(Value)`
  font-style: italic;
  text-align: center;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
