import { Ref } from 'react'
import moment from 'moment'

import { InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { DatePickerControl } from '@/components/TimeControls'
import { T } from '@/modules/Language'

import { Input, InputWrapper, Title } from './common'

type Props = {
  date: string
  setDate: (date: string) => void
}

export const DateInput = ({ date, setDate }: Props) => (
  <InlineModalSection style={{ marginTop: `0` }}>
    <FlexColumn flex={1} noPadding>
      <Title>
        <T>Orders:Payments.field.paymentDate</T> *
      </Title>

      <DatePickerControl
        render={({ date, ref, toggleModal }) => (
          <InputWrapper
            ref={ref as Ref<HTMLDivElement>}
            style={{ cursor: 'pointer' }}
          >
            <Input
              onClick={toggleModal}
              readOnly
              showBorder
              type="text"
              value={date ? date.format('dd, D.M.YYYY') : ''}
            />
          </InputWrapper>
        )}
        setValue={(date) => setDate(moment(date).format('YYYY-MM-DD'))}
        value={date ? moment(date) : null}
      />
    </FlexColumn>
  </InlineModalSection>
)
