import { ById } from '@/common/types'
import { OrderSubscription, OrderType as Order } from '@/modules/Order/types'

type Props = {
  data?: OrderSubscription
  ordersById: ById<Order>
  setFocusedOrderId: (focusedOrderId: string | null) => void
  setOrder: (order: Order) => void
  setOrdersById: (orders: ById<Order>) => void
}

export const updateOrdersFromSubscription = ({
  data,
  ordersById,
  setFocusedOrderId,
  setOrder,
  setOrdersById,
}: Props) => {
  if (!data) {
    return
  }

  const { orderId, event } = data.order
  const order = ordersById[orderId]

  if (event.__typename === 'OrderEvent') {
    const { orderData, id, type } = event

    switch (type) {
      case 'CREATE':
        orderData &&
          setOrdersById({
            ...ordersById,
            [id]: { ...orderData, confirmations: [], invoices: [], offers: [] },
          })
        return
      case 'DELETE':
        if (id === orderId) {
          const { [orderId]: removedOrder, ...rest } = ordersById

          setOrdersById(rest)
          setFocusedOrderId(null)
        }
        return
      case 'UPDATE':
        if (
          order &&
          orderData &&
          orderData.auditLog.updatedAt > order.auditLog.updatedAt
        ) {
          setOrder({ ...order, ...orderData })
        }
        return
      default:
        return
    }
  }

  if (!order) {
    return
  }

  const { confirmations, invoices, offers } = order

  if (event.__typename === 'OrderConfirmationEvent') {
    const { confirmationData, id, type } = event

    switch (type) {
      case 'CREATE':
        const isAlreadyCreated = !!confirmations.find((c) => c.id === id)

        return setOrder({
          ...order,
          confirmations:
            !confirmationData || isAlreadyCreated
              ? confirmations
              : [...confirmations, confirmationData],
        })

      case 'DELETE':
        return setOrder({
          ...order,
          confirmations: confirmations.filter((c) => c.id !== id),
        })
      case 'UPDATE':
        return setOrder({
          ...order,
          confirmations: confirmations.map((c) => {
            if (c.id === id && confirmationData) {
              if (confirmationData.auditLog.updatedAt > c.auditLog.updatedAt) {
                return { ...c, ...confirmationData }
              }
            }

            return c
          }),
        })
      default:
        return
    }
  }

  if (event.__typename === 'OrderInvoiceEvent') {
    const { invoiceData, id, type } = event

    switch (type) {
      case 'CREATE':
        const isAlreadyCreated = !!invoices.find((i) => i.id === id)

        return setOrder({
          ...order,
          invoices:
            !invoiceData || isAlreadyCreated
              ? invoices
              : [...invoices, invoiceData],
        })
      case 'DELETE':
        return setOrder({
          ...order,
          invoices: invoices.filter((i) => i.id !== id),
        })
      case 'UPDATE':
        return setOrder({
          ...order,
          invoices: invoices.map((i) => {
            if (i.id === id && invoiceData) {
              if (invoiceData.auditLog.updatedAt > i.auditLog.updatedAt) {
                return { ...i, ...invoiceData }
              }
            }

            return i
          }),
        })
      default:
        return
    }
  }

  if (event.__typename === 'OrderOfferEvent') {
    const { offerData, id, type } = event

    switch (type) {
      case 'CREATE':
        const isAlreadyCreated = !!offers.find((o) => o.id === id)

        return setOrder({
          ...order,
          offers:
            !offerData || isAlreadyCreated ? offers : [...offers, offerData],
        })
      case 'DELETE':
        return setOrder({
          ...order,
          offers: offers.filter((o) => o.id !== id),
        })
      case 'UPDATE':
        return setOrder({
          ...order,
          offers: offers.map((o) => {
            if (o.id === id && offerData) {
              if (offerData.auditLog.updatedAt > o.auditLog.updatedAt) {
                return { ...o, ...offerData }
              }
            }

            return o
          }),
        })
      default:
        return
    }
  }
}
