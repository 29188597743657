import { gql, useQuery } from '@apollo/client'

import { AgeCategoriesQuery as QueryData } from '~generated-types'

import fragments from '../fragments'
import { AgeCategory } from '../types'

export const QUERY = gql`
  ${fragments.ageCategory}

  query AgeCategories {
    registry {
      ageCategories {
        ...AgeCategory
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useAgeCategories(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData>(QUERY, {
    fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
  })

  const ageCategories: AgeCategory[] = data ? data.registry.ageCategories : []

  return {
    ageCategories,
    error,
    loading,
    refetch,
  }
}
