import { useState } from 'react'
import { Decimal } from 'decimal.js'
import moment, { HTML5_FMT } from 'moment'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { InnocuousButton } from '@/components/ExtraButtons'
import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { DateRange, DateRangePicker } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { ListingLayout, PlaceholderLabel } from '@/modules/Listing/common'
import { PrintSize } from '@/modules/Listing/types'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { PaymentType } from '~generated-types'

import { PaymentsTable } from './components/PaymentsTable'
import type {
  PaymentAmountsByType,
  PointOfSaleDetails,
} from './usePaymentsForInterval'

interface PaymentsProps {
  error: boolean
  interval: DateRange
  loading: boolean
  payments: PointOfSaleDetails[]
  pointOfSalesIds: string[]
  setInterval: (target: DateRange) => void
  setPointOfSalesIds: (targetIds: string[]) => void
}

export const Payments = ({
  error,
  interval,
  loading,
  payments,
  pointOfSalesIds,
  setInterval,
  setPointOfSalesIds,
}: PaymentsProps) => {
  const [intervalState, setIntervalState] = useState<DateRange>(interval)

  const renderContent = (printSize: PrintSize) => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <PlaceholderLabel>
          — <T>Reports:Payments.error</T> —
        </PlaceholderLabel>
      )
    }

    if (!payments.length) {
      return (
        <PlaceholderLabel>
          — <T>Reports:Payments.empty</T> —
        </PlaceholderLabel>
      )
    }

    // With no filters, show all (default "Elasticlike" behaviour)
    const filteredPointsOfSales = !!pointOfSalesIds.length
      ? payments.filter((x) => pointOfSalesIds.includes(x.pointOfSale.id))
      : payments

    const filteredTotalAmounts = filteredPointsOfSales.reduce(
      (acc: PaymentAmountsByType, val) => {
        const vouchersByProvider = acc.vouchersByProvider

        Object.keys(val.amounts.vouchersByProvider).forEach((x) => {
          if (vouchersByProvider[x]) {
            vouchersByProvider[x] = vouchersByProvider[x].plus(
              val.amounts.vouchersByProvider[x]
            )
          } else {
            vouchersByProvider[x] = val.amounts.vouchersByProvider[x]
          }
        })

        return {
          [PaymentType.Cash]: val.amounts[PaymentType.Cash].plus(
            acc[PaymentType.Cash]
          ),
          [PaymentType.CreditCard]: val.amounts[PaymentType.CreditCard].plus(
            acc[PaymentType.CreditCard]
          ),
          [PaymentType.GiftCard]: val.amounts[PaymentType.GiftCard].plus(
            acc[PaymentType.GiftCard]
          ),
          vouchersByProvider,
        }
      },
      {
        [PaymentType.Cash]: new Decimal(0),
        [PaymentType.CreditCard]: new Decimal(0),
        [PaymentType.GiftCard]: new Decimal(0),
        vouchersByProvider: {},
      }
    )

    return (
      <PaymentsTable
        payments={filteredPointsOfSales}
        printSize={printSize}
        totalAmounts={filteredTotalAmounts}
      />
    )
  }

  const renderSidebar = () => (
    <>
      <FilterSection
        label={<T>Reports:Payments.filter.interval</T>}
        render={() => (
          <IntervalSelectorWrapper>
            <DateRangePicker
              maxWeekRange={5}
              setValue={(range) =>
                setIntervalState(range || { from: moment(), to: moment() })
              }
              value={intervalState}
            />
            <InnocuousButton
              onClick={() => setInterval(intervalState)}
              style={{}}
            >
              <PrimaryColor>
                <T>common:action.apply</T>
              </PrimaryColor>
            </InnocuousButton>
          </IntervalSelectorWrapper>
        )}
      />
      {(loading || !!payments.length) && (
        <FilterSection
          label={<T>Reports:Payments.filter.pointOfSale</T>}
          render={() => (
            <FlexColumn>
              {loading ? (
                <LoadingPlaceholder flex={1} size="md" />
              ) : (
                payments
                  .sort(
                    (a, b) =>
                      Object.keys(b.invoicesById).length -
                      Object.keys(a.invoicesById).length
                  )
                  .map(({ invoicesById, pointOfSale }) => (
                    <CheckboxWrapper
                      key={`point-of-sale-filter-${pointOfSale.id}`}
                    >
                      <CheckboxInput
                        checked={pointOfSalesIds.includes(pointOfSale.id)}
                        onChange={() =>
                          setPointOfSalesIds(
                            pointOfSalesIds.includes(pointOfSale.id)
                              ? pointOfSalesIds.filter(
                                  (x) => x !== pointOfSale.id
                                )
                              : [...pointOfSalesIds, pointOfSale.id]
                          )
                        }
                      >
                        <span style={{ flex: 1 }}>{pointOfSale.name}</span>
                        <CountLabel>
                          {Object.keys(invoicesById).length}
                        </CountLabel>
                      </CheckboxInput>
                    </CheckboxWrapper>
                  ))
              )}
            </FlexColumn>
          )}
        />
      )}
    </>
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderSidebar={renderSidebar}
      title={
        <span>
          <T>Reports:Payments.title</T>
          <small>
            {' | '}
            {formatDateRangeWithoutRepetition(
              intervalState.from.format(HTML5_FMT.DATE),
              intervalState.to.format(HTML5_FMT.DATE)
            )}
          </small>
        </span>
      }
    />
  )
}

////////////

const CheckboxWrapper = styled(FlexRow)`
  flex: 1;

  label {
    flex: 1;
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const CountLabel = styled.span`
  color: #797992;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`

const IntervalSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => css`
    margin: -${theme.spacing.gu(1)}rem;
  `}
`
