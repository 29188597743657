import { FilterSection } from '@/components/ElasticFilterSearchList'
import { DataSearch, MultiList, SingleList } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'

type Props = {
  componentIds: {
    [key: string]: string
  }
  getDefaultQuery: () => {
    [key: string]: any
  }
  URLParams?: boolean
}

const ListFilters = ({ componentIds, getDefaultQuery, URLParams }: Props) => {
  const { language } = useLanguageContext()

  return (
    <>
      <FilterSection
        label={<T>Customers:CustomerList.filter.search</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH}
            dataField={[
              'customerNumber.text',
              'defaultAddress.address1',
              'defaultAddress.city',
              'defaultAddress.postcode',
              'organization.businessId',
              'person.email',
              'person.phone',
              'searchOptimization.contactPersonEmails',
              'searchOptimization.contactPersonPhoneNumbers',
              'searchOptimization.contactPersons',
              'searchOptimization.name',
            ]}
            filterLabel={translate(
              'Customers:CustomerList.filter.search',
              language
            )}
            placeholder={translate(
              `Customers:CustomerList.filter.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            react={{
              and: [componentIds.TYPE],
            }}
            showClear
            showIcon
            URLParams={URLParams}
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Customers:CustomerList.field.type</T>}
        render={({ isCollapsed }) => (
          <SingleList
            componentId={componentIds.TYPE}
            dataField="customerType"
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'Customers:CustomerList.field.type',
              language
            )}
            isCollapsed={isCollapsed}
            l10nPrefix="Customers:CustomerList.field.types"
            missingLabel={translate(
              'Customers:CustomerList.field.typeMissing',
              language
            )}
            showMissing
            showSearch={false}
            URLParams={!!URLParams}
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Customers:CustomerList.field.labels</T>}
        render={({ isCollapsed }) => (
          <MultiList
            componentId={componentIds.LABEL}
            dataField="searchOptimization.labels"
            defaultQuery={getDefaultQuery}
            defaultValue={[]}
            filterLabel={translate(
              'Customers:CustomerList.field.labels',
              language
            )}
            isCollapsed={isCollapsed}
            missingLabel={translate(
              'Customers:CustomerList.filter.noLabels',
              language
            )}
            showCheckbox
            showMissing
            showSearch={false}
            URLParams={!!URLParams}
          />
        )}
      />
    </>
  )
}

export default ListFilters
