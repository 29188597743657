import { gql } from '@apollo/client'

import { customerFragments } from '@/modules/Registry/Customer/fragments'

import salesLifecycle from './salesLifecycle'

export default gql`
  ${customerFragments.customerReference}
  ${salesLifecycle}

  fragment EventEnrollment on Sales {
    id
    type
    orderNumber
    name
    notes
    customer {
      ...CustomerReference
    }
    locked
    lifecycle {
      ...SalesLifecycle
    }
    reservationDates {
      start
      end
    }
    estimatedDates {
      start
      end
    }
    estimatedParticipants
    participantConnection(input: { pagination: { size: 5, page: 0 } }) {
      totalElements
      nodes {
        id
        firstName
        lastName
      }
    }
    orders {
      paymentInfo {
        totalPrice {
          amountVatIncluded
        }
      }
    }
    paymentAgreement {
      id
      name
      code
    }
  }
`
