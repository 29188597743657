import { useQuery } from '@apollo/client'

import type {
  SalesProductsGroupsQuery as QueryData,
  SalesProductsGroupsQueryVariables as QueryVariables,
} from '~generated-types'

import { productQueries } from '../queries'

type Params = {
  salesId: string
  skip: boolean
}

export const useSalesProductsGroups = ({ salesId, skip }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    productQueries.GET_SALES_PRODUCTS_GROUPS,
    {
      skip,
      variables: { id: salesId },
    }
  )

  const groups = (data?.sales.products ?? []).reduce(
    (acc: string[], { group }) => {
      if (group && !acc.includes(group)) {
        acc = [...acc, group]
      }

      return acc
    },
    []
  )

  return {
    error,
    groups,
    loading,
    refetch,
  }
}
