import { lighten } from 'polished'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import {
  CalendarNestedResourceElastic,
  CalendarResourceElastic,
} from '@/modules/Registry/CalendarResources'
import { CalendarViewMode } from '@/modules/Reservations'

import { EllipsisLabel } from './EllipsisLabel'
import { LineLeader } from './LineLeader'
import { ResourceDisplayNote } from './ResourceDisplayNote'
import { ResourceDisplayTooltip } from './ResourceDisplayTooltip'

type Props = {
  isNested?: boolean
  isLastNested?: boolean
  isParent?: boolean
  parentResourceId?: string
  resource: CalendarResourceElastic | CalendarNestedResourceElastic
  viewMode: CalendarViewMode
}

export const ResourceDisplayName = ({
  isNested,
  isLastNested,
  isParent,
  parentResourceId,
  resource,
  viewMode,
}: Props) => {
  const { id, internalInfo, name } = resource

  const isDateMode = viewMode === 'DATE'

  const renderResourceName = () => (
    <FlexRow style={{ width: '100%' }}>
      <EllipsisLabel>{name}</EllipsisLabel>

      <ResourceDisplayNote
        id={id}
        note={internalInfo}
        parentResourceId={parentResourceId}
      />
    </FlexRow>
  )

  return (
    <>
      {isDateMode && (isNested || isParent) ? (
        <EllipsisLabelWrapper alignItems="center" flex={1} isParent={isParent}>
          {renderResourceName()}
        </EllipsisLabelWrapper>
      ) : (
        renderResourceName()
      )}

      {isDateMode && isNested && <LineLeader fullHeight={!isLastNested} />}

      {!isNested && (
        <ResourceDisplayTooltip
          resource={resource as CalendarResourceElastic}
        />
      )}
    </>
  )
}

////////////

const EllipsisLabelWrapper = styled(FlexRow)<{ isParent?: boolean }>`
  border-radius: 4px;

  ${({ isParent, theme }) => css`
    background: ${isParent
      ? lighten(0.01, theme.palette.primary.extraLighter)
      : lighten(0.01, theme.palette.smoke.lighter)};
    padding: ${theme.spacing.gu(1)}rem;
    padding-right: 0;
  `}
`
