import { ChangeEvent, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { Right } from '@/components/Layout'
import { notify } from '@/components/NotificationService'
import { FontColor } from '@/components/Typography'
import { attachmentHooks } from '@/modules/Attachments/hooks'
import { Attachment } from '@/modules/Attachments/types'
import { translate, useLanguageContext } from '@/modules/Language'
import { T } from '@/modules/Language'
import {
  ConfirmationByIdPayload,
  InvoiceByIdPayload,
  OfferByIdPayload,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'
import { APIBaseUrl, apiCall } from '@/utils/api'

type RefetchPayload =
  | ConfirmationByIdPayload
  | InvoiceByIdPayload
  | OfferByIdPayload

type Props = {
  close: () => void
  documentId: string
  documentAttachments: Attachment[]
  onUpdateAttachments: (attachments: Attachment[]) => void
  refetch: () => Promise<ApolloQueryResult<RefetchPayload>>
}

export const AttachmentsModal = ({
  close,
  documentId,
  documentAttachments,
  onUpdateAttachments,
  refetch,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const {
    data: { attachments },
  } = salesHooks.useSalesDetailsContext()
  const { copyAttachmentToDocument } = attachmentHooks.useAttachment()

  const [isUploading, setUploading] = useState<boolean>(false)
  const [processingId, setProcessingId] = useState<string>('')

  const handleCopyAttachment = (id: string, fileName: string) => {
    setProcessingId(id)
    copyAttachmentToDocument(documentId, fileName)
      .then(({ data }) => {
        data && onUpdateAttachments([...documentAttachments, data.fileCopyTo])
        setProcessingId('')
        close()
      })
      .catch((err) => console.error(err))
  }

  const notifyError = (path: string) =>
    notify({
      content: translate(`Attachments:${path}`, language),
      type: 'ERROR',
    })

  const handleUploadAttachment = ({
    target: { validity, files },
  }: ChangeEvent<HTMLInputElement>) => {
    if (!files?.length) {
      return
    }

    const [file] = files

    if (documentAttachments.map((a) => a.fileName).includes(file.name)) {
      return notifyError('uploadDuplicatedError')
    }

    if (validity.valid) {
      setUploading(true)

      const data = new FormData()
      data.append('file', file)

      apiCall(`${APIBaseUrl}/documents/${documentId}/files/attachments`, {
        body: data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        method: 'POST',
      })
        .then(({ data, ok }) => {
          if (!ok || !data) {
            setUploading(false)
            return notifyError('uploadError')
          }

          refetch()
          close()
        })
        .catch(() => {
          setUploading(false)
          notifyError('uploadError')
        })
    }
  }

  return (
    <InlineModal style={{ minWidth: '300px' }}>
      <InlineModalTitle>
        <T>Attachments:salesAttachments</T>
      </InlineModalTitle>

      <InlineModalLine />

      <InlineModalContent
        style={{ padding: `${spacing.gu(1)}rem ${spacing.gutter}` }}
      >
        {attachments.length ? (
          attachments.map((a: Attachment, index: number) => (
            <FlexRow alignItems="center" flex={1} key={a.id}>
              <Number>{index + 1}.</Number>
              <Name>{a.fileName}</Name>
              {processingId === a.id ? (
                <LoaderWrapper>
                  <ReactLoading
                    color={palette.smoke.main}
                    height={18}
                    type="spin"
                    width={18}
                  />
                </LoaderWrapper>
              ) : (
                <IconButton
                  compact
                  disabled={
                    !!documentAttachments.find(
                      (da: Attachment) => da.fileName === a.fileName
                    ) || !!processingId
                  }
                  noNudge
                  onClick={() => handleCopyAttachment(a.id, a.fileName)}
                >
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="plus"
                    size="sm"
                  />
                </IconButton>
              )}
            </FlexRow>
          ))
        ) : (
          <FlexRow
            alignItems="center"
            justifyContent="center"
            style={{ height: spacing.gutterBig }}
          >
            <FontColor lighter>
              – <T>Attachments:noSalesAttachments</T> –
            </FontColor>
          </FlexRow>
        )}
      </InlineModalContent>
      <InlineModalFooter>
        <input
          accept=".pdf, .jpeg, .jpg, .gif, .tiff, .png"
          hidden
          id="upload"
          onChange={handleUploadAttachment}
          required
          type="file"
        />
        <UploadButton disabled={isUploading} htmlFor="upload">
          <PrimaryColor>
            <T>Attachments:uploadNewAttachment</T>
          </PrimaryColor>
        </UploadButton>

        <Right>
          <InnocuousButton compact onClick={close}>
            <T>common:action.close</T>
          </InnocuousButton>
        </Right>
      </InlineModalFooter>
    </InlineModal>
  )
}

///////

const IconButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    width: ${theme.spacing.gutterBig};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const InlineModalTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
    margin: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(1)}rem;
  `}
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    width: ${theme.spacing.gutterBig};
  `}
`

const Name = styled.div`
  flex: 1;
`

const Number = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    width: ${theme.spacing.gu(3)}rem;
  `}
`

const UploadButton = styled.label<{ disabled?: boolean }>`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    color: ${theme.palette.text.light};
  `}

  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 4px;
  background: transparent;
  border: none;
  height: auto;

  justify-content: center;
  transition: min-width 300 ease;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`
