import { ChangeEvent, Ref, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { Textarea } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import { IconButton } from '../common'

type Props = {
  id: string
  note: string | null
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const ResourceNote = ({
  id,
  note,
  reservationId,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { updateResource } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [noteValue, setNoteValue] = useState<string>('')

  const noteString = note ?? ''

  useEffect(() => {
    noteString !== noteValue && setNoteValue(noteString)
  }, [note])

  const handleUpdateResource = () =>
    noteString !== noteValue && updateResource(reservationId, id, noteValue)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
            <StyledTextarea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setNoteValue(e.target.value)
              }
              autoFocus
              value={noteValue || ''}
              onBlur={handleUpdateResource}
              style={{ height: 200, width: 360 }}
            />
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <IconButton
          ref={ref as Ref<HTMLButtonElement>}
          onClick={() => setModalOpen(true)}
        >
          <FontAwesomeIcon
            color={note ? palette.warning.dark : palette.smoke.extraDark}
            icon={['far', 'clipboard']}
          />
        </IconButton>
      )}
      zIndex={10003}
    />
  )
}

///////

const StyledTextarea = styled(Textarea)`
  &:not([type='checkbox']):not([type='radio']) {
    padding: 10px;
  }
`
