import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chip, ChipProps } from '@/components/Chip'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

import { ResourceReservationCustomerVisibility as Visibility } from '~generated-types'

type Props = ChipProps & {
  onClick?: () => void
  visibility: Visibility
  disabled?: boolean
}

const getChipColor = (visibility: Visibility) => {
  switch (visibility) {
    case Visibility.ResourceNameHidden:
      return 'secondary'
    case Visibility.Visible:
      return 'primary'
    case Visibility.Hidden:
    default:
      return 'disabled'
  }
}

const getChipVariant = (visibility: Visibility) => {
  switch (visibility) {
    case Visibility.ResourceNameHidden:
      return 'default'
    case Visibility.Visible:
      return 'default'
    case Visibility.Hidden:
    default:
      return 'outlined'
  }
}

const getChipIcon = (visibility: Visibility) => {
  switch (visibility) {
    case Visibility.ResourceNameHidden:
      return 'eye-low-vision'
    case Visibility.Visible:
      return 'eye'
    case Visibility.Hidden:
    default:
      return 'eye-slash'
  }
}

export const CustomerVisibilityChip = ({
  onClick,
  visibility,
  disabled,
  ...rest
}: Props) => (
  <Tooltip
    content={
      <T l10n={`ResourceReservations:customerVisibilityChip.${visibility}`} />
    }
    placement="left"
    trigger={(triggerProps) => (
      <Chip
        color={getChipColor(visibility)}
        onClick={(e) => {
          if (disabled) {
            return
          } else if (!!onClick) {
            e.preventDefault()
            e.stopPropagation()
            onClick()
          }
        }}
        size="xs"
        variant={getChipVariant(visibility)}
        {...rest}
        {...triggerProps}
      >
        <FontAwesomeIcon fixedWidth icon={getChipIcon(visibility)} />
      </Chip>
    )}
  />
)
