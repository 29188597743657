import { gql } from '@apollo/client'

import {
  AccommodationSettingsFragment,
  RoomReservationFragment,
  RoomTypeReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

export const CREATE_ROOM_RESERVATION = gql`
  ${RoomReservationFragment}
  ${AccommodationSettingsFragment}

  mutation CreateRoomReservation(
    $input: AccommodationGroupCreateRoomReservationInput!
  ) {
    accommodationGroupCreateRoomReservation(input: $input) {
      ... on AccommodationRoomReservationPayloadOk {
        roomReservation {
          ...RoomReservation
        }
        accommodationGroup {
          ...AccommodationSettings
        }
      }
      ... on RoomReservationOverlappingError {
        message
      }
      ... on RoomReservationCapacityRestricted {
        message
      }
    }
  }
`

export const CREATE_ROOM_TYPE_RESERVATION = gql`
  ${RoomTypeReservationFragment}
  ${AccommodationSettingsFragment}

  mutation CreateRoomTypeReservation(
    $input: AccommodationGroupCreateRoomTypeReservationInput!
  ) {
    accommodationGroupCreateRoomTypeReservation(input: $input) {
      roomTypeReservation {
        ...RoomTypeReservation
      }
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export const SET_GROUP_NAME = gql`
  mutation AccommodationGroupSetName($input: AccommodationGroupSetNameInput!) {
    accommodationGroupSetName(input: $input) {
      accommodationGroup {
        id
        name
      }
    }
  }
`

export const REMOVE_GROUP = gql`
  mutation AccommodationGroupDelete($input: AccommodationGroupDelete!) {
    accommodationGroupDelete(input: $input) {
      id
      deleted
    }
  }
`
