import moment, { Moment } from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { FeatureChip } from '@/modules/Listing/common'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'
import { typeGuardFactory } from '@/utils/types'

import { CheckInOutTimes } from '../../common'
import type {
  ArrivalsParticipant,
  ArrivalsRoomReservation,
} from '../useArrivalsForDate'
import { EventsTableBedChart } from './EventsTableBedChart'

type Props = {
  participants: ArrivalsParticipant[]
  room: ArrivalsRoomReservation
  targetDate: Moment
}

export const EventsTableRoomRow = ({
  participants,
  room,
  targetDate,
}: Props) => {
  const { spacing, palette } = useTheme()

  const totalBeds = room.request.beds + room.request.extraBeds
  const hasMoreParticipants = checkParticipantsAndBeds(
    totalBeds,
    participants,
    targetDate
  )
  return (
    <tr>
      <td>
        <FlexColumn noPadding>
          <FontWeight bold style={{ marginRight: `${spacing.gutter}` }}>
            #{room.request.room.number}
          </FontWeight>
          {'  '}
          <small>
            <FontWeight normal>
              {room.request.room.beds} + {room.request.room.extraBeds}
            </FontWeight>
          </small>
        </FlexColumn>
      </td>
      <td>
        <FlexColumn noPadding>
          <span
            style={{
              color: hasMoreParticipants
                ? palette.danger.main
                : palette.text.main,
            }}
          >
            {room.request.beds} + {room.request.extraBeds}
          </span>
          {room.request.features.length ? (
            <FlexRow
              alignItems="center"
              style={{ marginTop: `${spacing.gu(1)}rem` }}
              wrap="wrap"
            >
              {[...room.request.features]
                .sort(generateCompareFn('name'))
                .map((feature) => (
                  <FeatureChip
                    feature={feature}
                    key={`feature-${feature.id}`}
                  />
                ))}
            </FlexRow>
          ) : null}
        </FlexColumn>
      </td>
      <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
        {room.target?.name || '—'}
      </td>
      <td colSpan={4}>
        <EventsTableBedChart
          participants={participants}
          totalBeds={totalBeds}
        />
      </td>
      <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
        {room.request.info || '—'}
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <CheckInOutTimes
          checkIn={room.request.checkIn}
          checkOut={room.request.checkOut}
        />
      </td>
    </tr>
  )
}

////////////

const checkParticipantsAndBeds = (
  beds: number,
  participants: ArrivalsParticipant[],
  date: Moment
) => {
  const activeParticipants = participants.filter((participant) =>
    participant.services
      .filter(typeGuardFactory('__typename', 'ServiceParticipantBed'))
      .find(
        (service) =>
          moment(service.dates?.checkIn.date).isSameOrBefore(date, 'day') &&
          moment(service.dates?.checkOut.date).isAfter(date, 'day')
      )
  )

  return activeParticipants.length > beds
}
