import moment from 'moment'

export const formatDate = (date: { start: string; end: string }) => {
  const isSameDate = moment(date.start).isSame(moment(date.end), 'day')
  const isSameYear = moment(date.start).isSame(moment(date.end), 'year')

  return isSameDate
    ? moment(date.start).format('D.M.YY')
    : `${moment(date.start).format(isSameYear ? 'D.M' : 'D.M.YY')} – ${moment(
        date.end
      ).format('D.M.YY')}`
}
