import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Canvas } from '../Canvas'

const CanvasWithBottomBorder = styled(Canvas)`
  position: relative;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

type Props = {
  children: ReactNode
}
export const ControlRow = ({ children }: Props) => (
  <CanvasWithBottomBorder>{children}</CanvasWithBottomBorder>
)
