import { gql, useMutation } from '@apollo/client'

import {
  RemovePublicRoomMutation,
  RemovePublicRoomMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation RemovePublicRoom($input: PublicRoomRemoveInput!) {
    publicRoomRemove(input: $input) {
      deleted
      id
    }
  }
`

export const useRemovePublicRoomMutation = () =>
  useMutation<RemovePublicRoomMutation, RemovePublicRoomMutationVariables>(
    MUTATION
  )
