import { gql } from '@apollo/client'

import eventSales from '../fragments/eventSales'

export default gql`
  ${eventSales}

  query EventEnrollments($id: ID!, $input: SalesEnrollmentsInput) {
    sales(id: $id) {
      id
      type
      enrollments(input: $input) {
        ...EventSales
      }
    }
  }
`
