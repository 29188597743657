import { gql } from '@apollo/client'

import { orderFragments } from '../fragments'

export default gql`
  ${orderFragments.document}

  subscription Document($input: DocumentFilterInput!) {
    document(input: $input) {
      documentId
      event {
        ... on DocumentEvent {
          data {
            ...Document
          }
          id
          type
        }
      }
    }
  }
`
