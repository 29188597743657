import moment from 'moment'
import { lighten } from 'polished'
import styled from 'styled-components/macro'

import {
  RoomCleaningStatus,
  RoomStatus,
  RoomStatusChanges,
} from '@/modules/Reservations/components/RoomLayout'

type Props = {
  date: string
  status?: RoomStatus
}

export const CleaningStatus = ({ date, status }: Props) => {
  if (!status) {
    return null
  }

  const { cleaningStatus, cleaningStatusChanges } = status

  const changes = cleaningStatusChanges.find(
    (status: RoomStatusChanges) => status.date === date
  )

  return (
    <>
      {date === moment().format('YYYY-MM-DD') && !changes && (
        <Dot status={cleaningStatus} />
      )}

      {!!cleaningStatusChanges.length && changes && (
        <Dot status={changes.newCleaningStatus} isChanged />
      )}
    </>
  )
}

const Dot = styled.div<{
  status: RoomCleaningStatus | null
  isChanged?: boolean
}>`
  background: ${({ status, isChanged, theme }) =>
    status === RoomCleaningStatus.Dirty
      ? lighten(0.44, theme.palette.danger.main)
      : isChanged
      ? theme.palette.success.main
      : lighten(0.39, theme.palette.success.main)};
  border: 1px solid
    ${({ status, theme }) =>
      status === RoomCleaningStatus.Dirty
        ? theme.palette.danger.main
        : theme.palette.success.main};
  border-radius: 5px;
  height: 10px;
  width: 10px;
`
