import { gql } from '@apollo/client'

export const ResourceReservationResourceFragment = gql`
  fragment ResourceReservationResource on Resource {
    id
    internalInfo
    isPooled
    name
    type
  }
`

export default ResourceReservationResourceFragment
