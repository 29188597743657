import { CSSProperties } from 'react'
import styled from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'

type Props = {
  isSelected: boolean
  style?: CSSProperties
  toggleSelection: () => void
}

export const RowSelector = ({ isSelected, style, toggleSelection }: Props) => (
  <SelectorWrapper style={style}>
    <CheckboxInput
      checked={isSelected}
      labelStyle={{ position: 'absolute', top: 0 }}
      onChange={() => toggleSelection()}
    />
  </SelectorWrapper>
)

////////////

const SelectorWrapper = styled.span`
  position: relative;
  margin-right: 32px;

  @media print {
    display: none;
  }
`
