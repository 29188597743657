import { gql, useMutation } from '@apollo/client'

import type {
  CreateCatalogProductMutation,
  CreateCatalogProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProduct}

  mutation CreateCatalogProduct($input: CatalogProductCreateInput!) {
    catalogProductCreate(input: $input) {
      ...CatalogProduct
    }
  }
`

export const useCreateCatalogProductMutation = () =>
  useMutation<
    CreateCatalogProductMutation,
    CreateCatalogProductMutationVariables
  >(MUTATION)
