import styled, { css } from 'styled-components/macro'

export const SecondaryLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    line-height: ${theme.typography.fontSizeSmall};
  `}
`
