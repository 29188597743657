import { useState } from 'react'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  setConfirm: (confirm: boolean) => void
}

export const DeleteReservationsConfirmation = ({ setConfirm }: Props) => {
  const theme = useTheme()

  const [deleteReservations, setDeleteReservations] = useState<boolean>(false)

  return (
    <FlexRow alignItems={'center'} style={{ minWidth: '300px' }}>
      <CheckboxInput
        checked={deleteReservations}
        noMargin
        onChange={() => {
          setConfirm(!deleteReservations)
          setDeleteReservations((current) => !current)
        }}
      />
      <FontWeight
        style={{
          marginLeft: `${theme.spacing.gu(1)}rem`,
          paddingTop: '2px',
        }}
      >
        <T>Accommodation:TargetGroup.deleteReservations</T>
      </FontWeight>
    </FlexRow>
  )
}
