import { ApolloError } from '@apollo/client'

import { DateRange } from '@/components/TimeControls'
import { T } from '@/modules/Language'

import {
  AccommodationLevelAvailability,
  AvailabilityTableProps,
} from '../../AvailabilityCalendar.types'
import { AccommodationLevelSection } from './AccommodationLevelSection'
import { AvailabilityTotals } from './AvailabilityTotals'
import {
  ColumnGroup,
  LoadingRow,
  PlaceholderRow,
  ScrollTable,
} from './ScrollTable'

type Props = AvailabilityTableProps & {
  data: AccommodationLevelAvailability[]
  displayRange: DateRange
  error?: ApolloError
  handleOnSelectRoom?: (arg0: {
    roomId: string
    roomNumber: string
    roomTypeName: string
  }) => void
  handleOnSelectRoomType?: (arg0: {
    roomTypeId: string
    roomTypeName: string
  }) => void
  loading: boolean
}

export const AvailabilityTable = ({
  data,
  displayRange,
  error,
  handleOnSelectRoom,
  handleOnSelectRoomType,
  loading,
  ...availabilityTableProps
}: Props) => {
  const columnGroups = getColumnGroups(displayRange)

  const tableProps = Object.freeze({
    cellWidth: '54px',
    columnGroups,
    columnHeaderWidth: '380px',
    rowHeight: '32px',
  })

  return (
    <ScrollTable {...tableProps}>
      {loading ? (
        <LoadingRow {...tableProps} />
      ) : error || !data.length ? (
        <PlaceholderRow {...tableProps}>
          <T>Accommodation:AvailabilityCalendar.error</T>
        </PlaceholderRow>
      ) : (
        <>
          {data.map((x, idx) => (
            <AccommodationLevelSection
              data={x}
              handleOnSelectRoom={handleOnSelectRoom}
              handleOnSelectRoomType={handleOnSelectRoomType}
              isFirst={idx === 0}
              isLast={idx + 1 === data.length}
              key={`accommodation-level-section-${x.accommodationLevel.name}`}
              {...availabilityTableProps}
              {...tableProps}
            />
          ))}
          <AvailabilityTotals
            data={data}
            mode={availabilityTableProps.mode}
            {...tableProps}
          />
        </>
      )}
    </ScrollTable>
  )
}

////////////

const getColumnGroups = ({ from, to }: DateRange): ColumnGroup[] => {
  const firstDay = from.clone().startOf('day')
  const lastDay = to.clone().startOf('day')

  // Use weekday 4 to get the year correct for weeks during the
  // end/beginning of a year
  const weekIterator = firstDay.clone().isoWeekday(4)
  const lastWeek = lastDay.clone().isoWeekday(4)

  const grouped = []

  while (weekIterator.isValid() && weekIterator.isSameOrBefore(lastWeek)) {
    const items = []
    const dayIterator = weekIterator.clone().isoWeekday(1)

    for (let i = 0; i < 7; i++) {
      if (dayIterator.isSameOrAfter(firstDay)) {
        items.push({
          key: dayIterator.format('YYYY-MM-DD'),
          label: dayIterator.format('DD.MM.'),
        })
      }

      dayIterator.add(1, 'day')
    }

    const key = weekIterator.format('YYYY[W]WW')
    const label = (
      <span>
        <T>common:time.weekAbbreviated</T> {weekIterator.isoWeek()}
      </span>
    )

    grouped.push({ items, key, label })
    weekIterator.add(1, 'week')
  }

  return grouped
}
