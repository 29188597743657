import { gql, useMutation } from '@apollo/client'

import {
  SaleVstSetAttributesMutation,
  SaleVstSetAttributesMutationVariables,
} from '~generated-types'

import SalesVstFragment from '../fragments/salesVst'

const MUTATION = gql`
  ${SalesVstFragment}

  mutation SaleVstSetAttributes($input: SalesVstSetAttributesInput!) {
    salesVstSetAttributes(input: $input) {
      salesId
      vst {
        ...SalesVst
      }
    }
  }
`

export const useSaleVstSetAttributes = () =>
  useMutation<
    SaleVstSetAttributesMutation,
    SaleVstSetAttributesMutationVariables
  >(MUTATION)
