import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { Reservation } from '../../types'

type ReservationType = 'ARRIVING' | 'DEPARTING' | 'LODGING'

interface Props {
  reservation: Reservation | null
  type: ReservationType
}

export const ReservationCell = ({ reservation, type }: Props) => {
  const theme = useTheme()

  if (!reservation) {
    return <td />
  }

  const {
    request: { beds, checkIn, checkOut, extraBeds },
  } = reservation

  return (
    <td>
      <FlexRow alignItems="center">
        <FontAwesomeIcon
          color={theme.palette.text.lighter}
          icon={getReservationTypeIcon(type)}
          style={{ marginRight: `${theme.spacing.gu(2)}rem` }}
        />
        <FlexColumn noPadding alignItems="flex-start">
          <span style={{ whiteSpace: 'nowrap' }}>
            {checkIn.type === CheckInTypes.EARLY && (
              <FontAwesomeIcon
                color={theme.palette.primary.dark}
                icon="sun"
                size="xs"
                style={{ marginRight: `${theme.spacing.gu(0.5)}rem` }}
              />
            )}
            {formatDateRangeWithoutRepetition(
              checkIn.date,
              checkOut.date,
              'short'
            )}
            {checkOut.type === CheckOutTypes.LATE && (
              <FontAwesomeIcon
                color={theme.palette.primary.dark}
                icon="moon"
                size="sm"
                style={{ marginLeft: `${theme.spacing.gu(0.5)}rem` }}
              />
            )}
          </span>
          <small>
            {beds} + {extraBeds}
          </small>
        </FlexColumn>
      </FlexRow>
    </td>
  )
}

///////

const getReservationTypeIcon = (type: ReservationType) => {
  switch (type) {
    case 'ARRIVING':
      return 'plane-arrival'
    case 'DEPARTING':
      return 'plane-departure'
    case 'LODGING':
      return 'bed'
    default:
      return 'info'
  }
}
