import i18n from 'i18next'
// Import moment with supported locales (in addition to default [=en])
import moment from 'moment'
import { initReactI18next } from 'react-i18next'

// eslint-disable-next-line
import 'moment/locale/fi'

const formatter = (value: any, format: string): string => {
  if (value instanceof Date) {
    return moment(value).format(format)
  }

  return value
}

export const localeList = ['fi', 'en']
export const defaultLocale = localStorage.getItem('language') || localeList[0]

export const defaultConfig = {
  debug: false,
  defaultNS: 'common',
  fallbackLng: localeList[1],
  fallbackNS: 'common',
  interpolation: {
    escapeValue: false, // not needed for react!!
    format: formatter,
    prefix: '__',
    suffix: '__',
  },
  lng: defaultLocale,
  whitelist: localeList,
}

i18n.on('languageChanged', function (locale: any) {
  moment.locale(locale)
})

// @ts-ignore
i18n.use(initReactI18next).init(Object.assign({}, defaultConfig))
moment.locale(defaultLocale)

export default i18n
