import { gql } from '@apollo/client'

export default gql`
  fragment AgeCategory on AgeCategory {
    key
    name
    shortName
    sortOrder
  }
`
