import {
  FocusEvent,
  KeyboardEvent,
  RefObject,
  useEffect,
  useState,
} from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { useDialogService } from '@/components/DialogService'
import { Input as InputBase } from '@/components/FormControls'
import {
  InlineModalDescription,
  InlineModalIconSection,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import {
  OverlappingError,
  Reservation,
  ResourceReservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'

import { useReservationsGridState } from '../../../Calendar'
import { EditButton } from '../common'
import { TimeOptionsModal } from './TimeOptionsModal'
import {
  getDurationString,
  isTimeValid,
  scrollToSelectedTime,
  transformTime,
  validateTimes,
} from './utils'

type Props = {
  allowSameTime: boolean
  end: string
  isPooled: boolean
  readOnly: boolean
  reservationId: string
  start: string
  updateReservations: (reservations: Reservation[]) => void
}

export const Time = ({
  allowSameTime,
  end,
  isPooled,
  readOnly,
  reservationId,
  start,
  updateReservations,
}: Props) => {
  const { confirm, warn } = useDialogService()
  const { refetchAvailabilities } = useReservationsGridState()
  const { setTimes } = resourceReservationHooks.useResourceReservationMutations(
    {
      updateReservations,
    }
  )

  const [isEndEdit, setEndEdit] = useState<boolean>(false)
  const [isStartEdit, setStartEdit] = useState<boolean>(false)

  const [endValue, setEndValue] = useState<string>('')
  const [startValue, setStartValue] = useState<string>('')

  const endTime = moment(end).format('HH:mm')
  const startTime = moment(start).format('HH:mm')

  useEffect(() => {
    endTime !== endValue && setEndValue(endTime)
  }, [endTime])

  useEffect(() => {
    startTime !== startValue && setStartValue(startTime)
  }, [startTime])

  const handleSetTime = (target: 'end' | 'start', time: string) => {
    const input = validateTimes({ allowSameTime, end, start, target, time })

    const doUpdateTimes = (allowOverbooking: boolean) =>
      setTimes({ allowOverbooking, reservationId, ...input })

    doUpdateTimes(false)
      .then((data: void | ResourceReservation | OverlappingError | null) => {
        const errorData = data as OverlappingError

        if (errorData.message) {
          if (errorData.type === 'WARNING') {
            return renderConfirmDialog()
              .then(() => doUpdateTimes(true))
              .catch(() => undefined)
          }
          if (errorData.type === 'ERROR') {
            return renderWarningDialog().catch(() => undefined)
          }
        }
      })
      .then(() => isPooled && refetchAvailabilities())
      .catch(() => undefined)
      .finally(() => {
        setEndEdit(false)
        setStartEdit(false)
      })
  }

  const renderConfirmDialog = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.continue</T>,
      description: (
        <T>ResourceReservationsCalendar:overlapWarning.description</T>
      ),
      title: <T>ResourceReservationsCalendar:overlapWarning.title</T>,
    })

  const renderWarningDialog = () =>
    warn({
      cancelLabel: <T>common:action.cancel</T>,
      description: <T>ResourceReservationsCalendar:overlapError.description</T>,
      title: <T>ResourceReservationsCalendar:overlapError.title</T>,
    })

  const handleSetEndTime = (e: KeyboardEvent | FocusEvent) => {
    const value = transformTime(endValue)

    if (value !== endTime) {
      if (isTimeValid(value)) {
        handleSetTime('end', value)
      } else {
        setEndValue(endTime)
      }
    }

    if (e.type === 'keydown' || (e as FocusEvent).relatedTarget) {
      setEndEdit(false)
    }
  }

  const handleSetStartTime = (e: KeyboardEvent | FocusEvent) => {
    const value = transformTime(startValue)

    if (value !== startTime) {
      if (isTimeValid(value)) {
        handleSetTime('start', value)
      } else {
        setStartValue(startTime)
      }
    }

    if (e.type === 'keydown' || (e as FocusEvent).relatedTarget) {
      setStartEdit(false)
    }
  }

  return (
    <InlineModalIconSection icon={['far', 'clock']}>
      <InlineModalDescription>
        <FlexRow alignItems="center">
          {isStartEdit ? (
            <ModalContainer
              isOpen={isStartEdit}
              modal={
                <TimeOptionsModal
                  currentTime={startTime}
                  setTime={(time) => handleSetTime('start', time)}
                />
              }
              onClose={() => setStartEdit(false)}
              placement="bottom-start"
              referenceElement={({ ref }) => (
                <Input
                  autoFocus
                  onBlur={handleSetStartTime}
                  onChange={(e) => setStartValue(e.target.value)}
                  onFocus={(e) => e.currentTarget.select()}
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleSetStartTime(e)
                    }
                  }}
                  placeholder="00:00"
                  ref={ref as RefObject<HTMLInputElement>}
                  value={startValue}
                />
              )}
              zIndex={10004}
            />
          ) : (
            <Button
              disabled={readOnly}
              onClick={() => {
                setStartEdit(true)
                scrollToSelectedTime(startValue)
              }}
            >
              {startTime}
            </Button>
          )}

          {'–'}

          {isEndEdit ? (
            <ModalContainer
              isOpen={isEndEdit}
              modal={
                <TimeOptionsModal
                  allowSameTime={allowSameTime}
                  boundaryTime={startTime}
                  currentTime={endTime}
                  setTime={(time) => handleSetTime('end', time)}
                />
              }
              onClose={() => setEndEdit(false)}
              placement="bottom-start"
              referenceElement={({ ref }) => (
                <Input
                  autoFocus
                  onBlur={handleSetEndTime}
                  onChange={(e) => setEndValue(e.target.value)}
                  onFocus={(e) => e.currentTarget.select()}
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleSetEndTime(e)
                    }
                  }}
                  placeholder="00:00"
                  ref={ref as RefObject<HTMLInputElement>}
                  style={{ margin: 0 }}
                  value={endValue}
                />
              )}
              zIndex={10004}
            />
          ) : (
            <Button
              disabled={readOnly}
              onClick={() => {
                setEndEdit(true)
                scrollToSelectedTime(endValue)
              }}
              style={{ margin: 0 }}
            >
              {endTime}
            </Button>
          )}

          <Duration>{getDurationString(startTime, endTime)}</Duration>
        </FlexRow>
      </InlineModalDescription>
    </InlineModalIconSection>
  )
}

////////

const Button = styled(EditButton)`
  justify-content: center;
  flex: unset;
  width: 53px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Duration = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Input = styled(InputBase)`
  &:not([type='checkbox']):not([type='radio']) {
    flex: unset;

    border-radius: 4px;
    height: 30px;
    text-align: center;
    width: 53px;

    ${({ theme }) => css`
      margin-left: -${theme.spacing.gu(1)}rem;
    `}
  }
`
