import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import { SalesPrintConfig, SalesPrintConfigKey } from '../../../../../types'
import { SectionVisibilityToggle } from './SectionVisibilityToggle'

type Props = {
  isOpen: boolean
  parentKey?: SalesPrintConfigKey
  parentVisible?: boolean
  section: SalesPrintConfig
  setOpen: (isOpen: boolean) => void
}

export const PrintSaleSectionHeader = ({
  isOpen,
  parentKey,
  parentVisible,
  section: { key, visible },
  setOpen,
}: Props) => {
  const { salesPrintConfig, updateSalesPrintConfig } =
    salesHooks.useSalesDetailsContext()

  const [isHover, setHover] = useState<boolean>(false)

  const handleUpdateVisibility = () =>
    updateSalesPrintConfig(
      salesPrintConfig,
      parentKey ? `${parentKey}.${key}` : key
    )

  const isDisabled = parentVisible !== undefined && !parentVisible
  const isVisible =
    parentVisible === undefined ? visible : visible && parentVisible

  return (
    <Wrapper
      alignItems="center"
      flex={1}
      justifyContent="space-between"
      isNested={!!parentKey}
    >
      <FlexRow
        onClick={() => setOpen(!isOpen)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ChevronIcon
          icon="chevron-right"
          hover={isHover ? 1 : undefined}
          open={isOpen}
        />

        <Title isActive={isVisible}>
          <T>{`SalesDetails:PrintSale.label.${key}`}</T>
        </Title>
      </FlexRow>

      <SectionVisibilityToggle
        disabled={isDisabled}
        onClick={() => handleUpdateVisibility()}
        visible={isVisible}
      />
    </Wrapper>
  )
}

/////

const ChevronIcon = styled(FontAwesomeIcon)<{
  hover?: number
  loading?: number
  open: boolean
}>`
  transition: 0.25s;
  width: 18px;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ open }) =>
    open &&
    css`
      transform: rotate(90deg);
    `}

  ${({ hover, theme }) =>
    hover &&
    css`
      color: ${theme.palette.text.main};
    `}
`

const Title = styled(Label)<{ isActive: boolean }>`
  margin-top: 0;
  margin-bottom: 0;

  ${({ isActive, theme }) => css`
    color: ${isActive ? theme.palette.text.light : theme.palette.text.lighter};
  `}
`

const Wrapper = styled(FlexRow)<{ isNested: boolean }>`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(6)}rem;
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}

  ${({ isNested, theme }) =>
    isNested &&
    css`
      padding-left: ${theme.spacing.gu(1)}rem;
    `}
`
