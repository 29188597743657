import GET_CATALOG_PRODUCT_BY_ID from './catalogProductById'
import GET_SALES_ACCOMMODATION_GROUPS from './salesAccommodationGroups'
import GET_SALES_PRODUCT_BY_ID from './salesProductById'
import GET_SALES_PRODUCTS from './salesProducts'
import GET_SALES_PRODUCTS_COMPACT from './salesProductsCompact'
import GET_SALES_PRODUCTS_GROUPS from './salesProductsGroups'

export const productQueries = Object.freeze({
  GET_CATALOG_PRODUCT_BY_ID,
  GET_SALES_ACCOMMODATION_GROUPS,
  GET_SALES_PRODUCT_BY_ID,
  GET_SALES_PRODUCTS,
  GET_SALES_PRODUCTS_COMPACT,
  GET_SALES_PRODUCTS_GROUPS,
})
