import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { TreeLine } from './TreeLine'
import { ScrollTableProps } from './types'

type Props = ScrollTableProps & {
  isExpanded?: boolean
  isLastRow?: boolean
  renderContent: () => ReactNode
  renderHeader: () => ReactNode
}

export const PrimaryDataRow = ({
  renderContent,
  renderHeader,
  ...props
}: Props) => (
  <Wrapper>
    <TreeLine
      index={0}
      isVisible={props.isExpanded}
      hideSideLine
      style={{ flex: '0 0 40px' }}
    />
    <Header {...props}>{renderHeader()}</Header>
    <Content {...props}>{renderContent()}</Content>
  </Wrapper>
)

////////////

const Content = styled.div<{
  isExpanded?: boolean
  isLastRow?: boolean
  rowHeight: string
}>`
  height: ${({ rowHeight }) => rowHeight};
  display: inline-flex;
  width: 100%;
  z-index: 2999;

  ${({ isExpanded, isLastRow, theme }) =>
    isExpanded && isLastRow
      ? `border-bottom: 1px solid ${theme.palette.smoke.dark};`
      : ''}
`

const Header = styled.div<{
  columnHeaderWidth: string
  isExpanded?: boolean
  isLastRow?: boolean
  rowHeight: string
}>`
  position: sticky;
  left: 40px;
  flex: 0 0 ${({ columnHeaderWidth }) => `calc(${columnHeaderWidth} - 40px )`};
  display: flex;
  flex-direction: column;
  z-index: 3000;
  height: ${({ rowHeight }) => rowHeight};
  padding: 10px 10px;
  text-align: right;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    background: ${theme.palette.white};
    border-left: 1px solid ${theme.palette.smoke.dark};
    border-right: 1px solid ${theme.palette.smoke.dark};
  `}

  ${({ isExpanded, isLastRow, theme }) =>
    isExpanded && isLastRow
      ? `border-bottom: 1px solid ${theme.palette.smoke.dark};`
      : ''}
`

const Wrapper = styled.section`
  display: inline-flex;
  flex-direction: row;
  position: relative;
`
