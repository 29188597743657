import { FC } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import type {
  AccommodationForSalesPrintFragment,
  AccommodationRoomFeatureForSalesPrintFragment,
  AccommodationRoomReservationForSalesPrintFragment,
  AccommodationRoomTypeReservationForSalesPrintFragment,
  CustomerReferenceFragment,
  IssueLevel,
  MealForSalesPrintFragment,
  ParticipantForSalesPrintFragment,
  ParticipantForSalesPrintServiceBedFragment,
  PurchaseForSalesPrintFragment,
  ResourceReservationForSalesPrintFragment,
  SalesDetailsForPrintQuery,
  SalesDetailsQuery,
  SalesIssuesQuery,
  SalesStateOptionsQuery,
  TaskForSalesPrintFragment,
  UpdateSalesAttributesMutation,
} from '~generated-types'

export type SalesDetails = SalesDetailsQuery['sales']
export type SalesEvent = NonNullable<SalesDetails['event']>
export type SalesFacet = SalesDetails['facet']
export type WebshopSettings = SalesDetailsQuery['sales']['webshopSettings']
export type SalesIssues = SalesIssuesQuery['salesIssues']
export type SalesStateOption =
  SalesStateOptionsQuery['sales']['lifecycle']['stateOptions'][0]
export type Issue = SalesIssues['salesIssues'][0]

export type UpdateAttributesResponse =
  UpdateSalesAttributesMutation['salesUpdateAttributes']

export type { SalesType } from '~generated-types'

export type SectionFeatures = {
  icon: IconProp
  key: string
}

export type SalesRoute = SectionFeatures & { component: FC; issue?: IssueLevel }

export type SalesForPrint = SalesDetailsForPrintQuery
export type SalesForPrintAccommodation = AccommodationForSalesPrintFragment
export type SalesForPrintAccommodationRoomFeature =
  AccommodationRoomFeatureForSalesPrintFragment
export type SalesForPrintAccommodationRoomReservation =
  AccommodationRoomReservationForSalesPrintFragment
export type SalesForPrintAccommodationRoomTypeReservation =
  AccommodationRoomTypeReservationForSalesPrintFragment
export type SalesForPrintAccommodationTarget = NonNullable<
  SalesForPrintAccommodationRoomReservation['target']
>
export type SalesForPrintCustomer = CustomerReferenceFragment
export type SalesForPrintMeal = MealForSalesPrintFragment
export type SalesForPrintParticipant = ParticipantForSalesPrintFragment
export type SalesForPrintParticipantServiceBed =
  ParticipantForSalesPrintServiceBedFragment
export type SalesForPrintPurchase = PurchaseForSalesPrintFragment
export type SalesForPrintResourceReservation =
  ResourceReservationForSalesPrintFragment
export type SalesForPrintResourceReservationWithTasks =
  SalesForPrintResourceReservation & { tasks: SalesForPrintTask[] }
export type SalesForPrintTask = TaskForSalesPrintFragment

export type SalesPrintConfig = {
  disabled: boolean
  key: SalesPrintConfigKey
  sections?: SalesPrintConfig[]
  group?: {
    by: SalesPrintConfigKey
    options: SalesPrintConfigKey[]
  }
  visible: boolean
}

export enum SalesPrintConfigKey {
  Accommodation = 'ACCOMMODATION',
  Age = 'AGE',
  Allegries = 'ALLERGIES',
  Assignee = 'ASSIGNEE',
  BasicDetails = 'BASIC_DETAILS',
  Customer = 'CUSTOMER',
  Dates = 'DATES',
  Features = 'FEATURES',
  Group = 'GROUP',
  Location = 'LOCATION',
  Meals = 'MEALS',
  Name = 'NAME',
  Notes = 'NOTES',
  Participants = 'PARTICIPANTS',
  Product = 'PRODUCT',
  Price = 'PRICE',
  Program = 'PROGRAM',
  Purchases = 'PURCHASES',
  Quantity = 'QUANTITY',
  Request = 'REQUEST',
  Reservations = 'RESERVATIONS',
  Resource = 'RESOURCE',
  Room = 'ROOM',
  Subject = 'SUBJECT',
  Target = 'TARGET',
  Tasks = 'TASKS',
  Time = 'TIME',
}
