import styled, { css } from 'styled-components/macro'

import { borderColor } from './styles'

type Props = {
  separator?: 'none' | 'solid' | 'dashed'
  variant?: 'subheader' | 'subheader-full' | 'default'
  highlight?: boolean
}

const subheaderStyles = css`
  &&& > td {
    border-bottom-width: 1px;
    font-weight: 500;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}
  }

  & > td:first-child,
  & > td:last-child {
    position: relative;
  }
`

const subheaderHideBorderEdges = css`
  &:not(:last-child) > td:first-child::after,
  &:not(:last-child) > td:last-child::after {
    content: ' ';
    position: absolute;
    background: inherit;
    bottom: -1px;
    height: 1px;

    ${({ theme }) => css`
      width: ${theme.spacing.gutter};
    `}
  }

  & > td:first-child::after {
    left: 0;
  }

  & > td:last-child::after {
    right: 0;
  }
`

const reconcileSeparator = ({ separator }: Props) => {
  switch (separator) {
    case 'solid':
      return css`
        &:not(:last-child) > td {
          border-bottom: solid 1px ${borderColor};
        }
      `
    case 'dashed':
      return css`
        &:not(:last-child) > td {
          border-bottom: dashed 1px ${borderColor};
        }
      `
    case 'none':
    default:
      return ''
  }
}

const reconcileVariant = ({ variant }: Props) => {
  switch (variant) {
    case 'subheader':
      return [...subheaderStyles, ...subheaderHideBorderEdges]
    case 'subheader-full':
      return subheaderStyles
    case 'default':
    default:
      return ''
  }
}

const reconcileHighlight = ({ highlight }: Props) =>
  highlight
    ? css`
        td {
          background-color: #fffbe6;
        }
      `
    : ''

export const DataTableRow = styled.tr<Props>`
  ${(props) => reconcileSeparator(props)}
  ${(props) => reconcileVariant(props)}
  ${(props) => reconcileHighlight(props)}
`
