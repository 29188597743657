import type { PaletteOptions } from '../types'

export default function mapStrapiPaletteConfigToPaletteOptions(
  input: Record<string, unknown>
): PaletteOptions {
  const options: PaletteOptions = {}

  if (input.accent1) options.accent1 = input.accent1 as string
  if (input.accent2) options.accent2 = input.accent2 as string
  if (input.accent3) options.accent3 = input.accent3 as string
  if (input.black) options.black = input.black as string
  if (input.danger) options.danger = input.danger as string
  if (input.primary) options.primary = input.primary as string
  if (input.secondary) options.secondary = input.secondary as string
  if (input.success) options.success = input.success as string
  if (input.warning) options.warning = input.warning as string
  if (input.white) options.white = input.white as string

  options.text = {}

  if (input.text_dark) options.text.dark = input.text_dark as string
  if (input.text_light) options.text.light = input.text_light as string
  if (input.text_lighter) options.text.lighter = input.text_lighter as string
  if (input.text_main) options.text.main = input.text_main as string
  if (input.text_white) options.text.white = input.text_white as string

  return options
}
