import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'

import { T } from '@/modules/Language'

type NotificationType = 'ERROR' | 'INFO' | 'SUCCESS' | 'WARNING'

type Props = {
  translationPath?: string
  content?: string | ReactNode
  type: NotificationType
}

export const notify = ({ content, translationPath, type }: Props) => {
  const toastContent = translationPath ? <T>{translationPath}</T> : content

  switch (type) {
    case 'ERROR':
      return toast.error(toastContent, {
        icon: getIcon('triangle-exclamation'),
      })
    case 'SUCCESS':
      return toast.success(toastContent, {
        icon: getIcon(['far', 'check-circle']),
      })
    case 'WARNING':
      return toast.warning(toastContent, {
        icon: getIcon('triangle-exclamation'),
      })
    default:
    case 'INFO':
      return toast.info(toastContent, {
        icon: getIcon('info'),
      })
  }
}

const getIcon = (icon: IconProp) => (
  <FontAwesomeIcon fixedWidth icon={icon} size="lg" />
)
