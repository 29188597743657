import moment from 'moment'

export const formatMealTime = (date: string, duration: number | null) => {
  if (date && moment(date).isValid()) {
    return duration
      ? `${moment(date).format('HH:mm')} – ${moment(date)
          .add(duration, 'minutes')
          .format('HH:mm')}`
      : moment(date).format('HH:mm')
  }

  return ''
}
