import { gql } from '@apollo/client'

export default gql`
  fragment OrderCustomer on OrderCustomer {
    details {
      address {
        address1
        address2
        city
        country
        postcode
      }
      businessId
      contactPerson
      customerNumber
      email
      name
      phone
      type
    }
    detailsLocked
    inheritedCustomer {
      customer {
        customerNumber
      }
    }
  }
`
