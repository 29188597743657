import styled, { css } from 'styled-components/macro'

export const ScrollIndicator = styled.div<{
  hasScrolled: boolean
  zIndex: number
}>`
  width: 100%;
  position: relative;

  transition: opacity ease 0.3s;

  ${({ hasScrolled, theme, zIndex }) => css`
    background: ${theme.palette.smoke.main};
    opacity: ${hasScrolled ? 1 : 0};
    z-index: ${zIndex};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    width: 100%;
    height: 10px;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  }
`
