import { Ref } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import {
  DateRange as MomentDateRange,
  DateRangePickerControl,
} from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import { SalesEventSettingsInput } from '~generated-types'

type DateRange = {
  from: string
  to: string
}

type Props = {
  enrollEnd: string | null
  enrollStart: string | null
  setEventSettings: (input: Partial<SalesEventSettingsInput>) => Promise<any>
  refresh: () => Promise<any>
}

export const EnrollmentInterval = ({
  enrollEnd,
  enrollStart,
  setEventSettings,
  refresh,
}: Props) => {
  const { saleReadOnly: readOnly } = salesHooks.useSalesDetailsContext()

  const value: DateRange | null =
    enrollEnd && enrollStart
      ? {
          from: enrollStart,
          to: enrollEnd,
        }
      : null

  const handleChangeDateRange = (dateRange?: MomentDateRange | null) =>
    setEventSettings({
      enrollEnd: dateRange ? dateRange.to.format('YYYY-MM-DD') : null,
      enrollStart: dateRange ? dateRange.from.format('YYYY-MM-DD') : null,
    }).then(() => refresh())

  const renderValue = (value: DateRange) => {
    const from = moment(value.from)
    const to = moment(value.to)

    return `${from.format(
      from.isSame(to, 'year') ? 'dd, D.M' : 'dd, D.M.YY'
    )} – ${to.format('dd, D.M.YY')}`
  }

  return (
    <Section noPadding>
      <Label>
        <T>SalesDetails:eventSettings.enrollmentInterval</T>
      </Label>
      <DateRangePickerControl
        clearable
        placement="bottom-start"
        render={({ openModal, ref }) => (
          <EditButton
            disabled={readOnly}
            innerRef={ref as Ref<HTMLButtonElement>}
            onClick={openModal}
          >
            {value ? (
              renderValue(value)
            ) : (
              <Placeholder>
                <T>SalesDetails:eventSettings.noDates</T>
              </Placeholder>
            )}
          </EditButton>
        )}
        setValue={handleChangeDateRange}
        value={
          value
            ? {
                from: moment(value.from),
                to: moment(value.to),
              }
            : null
        }
      />
    </Section>
  )
}

////////

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(30)}rem;
  `}
`
