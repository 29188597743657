import { ReactNode, useState } from 'react'
import { SelectedFilters } from '@appbaseio/reactivesearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Label as LabelBase } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

type RenderProps = {
  isCollapsed: boolean
}

type Props = {
  canCollapse?: boolean
  componentId?: string
  isCollapsedInitially?: boolean
  label?: ReactNode
  render: (props: RenderProps) => ReactNode
}

export const SalesListFilterSection = ({
  canCollapse,
  componentId,
  isCollapsedInitially,
  label,
  render,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(!isCollapsedInitially)

  return (
    <Wrapper>
      {canCollapse ? (
        <FlexRow alignItems="center" justifyContent="space-between">
          <Button onClick={() => setIsOpen((x) => !x)}>
            {canCollapse && (
              <Icon icon="angle-up" fixedWidth rotation={isOpen ? 180 : 90} />
            )}
            {label && <Label>{label}</Label>}
          </Button>

          <SelectedFilters
            render={({ setValue, selectedValues }) => {
              const currentValue = Object.keys(selectedValues)
                .map((key) => ({ ...selectedValues[key], key }))
                .find((v) => v.key === componentId)

              const isSelected =
                currentValue && currentValue.value && currentValue.value.length

              return isSelected ? (
                <ClearButton onClick={() => setValue(componentId, null)}>
                  <T>Mobile:salesList.clear</T>
                </ClearButton>
              ) : null
            }}
          />
        </FlexRow>
      ) : (
        <Button disabled>{label && <Label>{label}</Label>}</Button>
      )}

      {render({ isCollapsed: !isOpen })}
    </Wrapper>
  )
}

////////////

const Button = styled(FlexRow)<{ disabled?: boolean }>`
  border-radius: 4px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin: ${theme.spacing.gu(0.5)}rem -${theme.spacing.gu(0.5)}rem;
    margin-bottom: ${theme.spacing.gu(1)}rem;
    padding: ${theme.spacing.gu(0.5)}rem;
  `}

  ${({ disabled, theme }) =>
    !disabled &&
    css`
      &:hover,
      &:active {
        background-color: transparent;
        color: ${theme.palette.text.light};
      }
    `}
`

const ClearButton = styled(Button)`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
  `}
`

const Icon = styled(FontAwesomeIcon)`
  transition: 0.25s;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(0.5)}rem;
    color: ${theme.palette.text.light};
  `}
`

const Label = styled(LabelBase)`
  color: inherit;
  margin: 0;
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(2)}rem;
  `}

  &:last-child {
    padding-bottom: 0;
  }
`
