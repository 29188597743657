import styled, { css } from 'styled-components/macro'

export const InlineModalTitle = styled.p`
  ${({ theme }) => css`
    margin: -2px 0 ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
  `}

  font-weight: 500;
  text-align: left;
`
