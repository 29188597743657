import { MouseEvent, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { IconButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import {
  ListItem as Wrapper,
  ListItemSection as Section,
} from '@/components/List'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { ElasticCatalogProduct } from '@/modules/Products/types'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

type Props = {
  data: ElasticCatalogProduct
  onItemOpen: () => void
  onItemRemove: () => Promise<void>
}

export const ListItem = ({
  data: { catalog, items, name, notes, unitPrice },
  onItemOpen,
  onItemRemove,
}: Props) => {
  const { palette } = useTheme()

  const [isRemoving, setRemoving] = useState<boolean>(false)

  const isPacket = items.length > 1

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setRemoving(true)

    onItemRemove().finally(() => setRemoving(false))
  }

  const getTotalVatRate = () => {
    if (!items.length) {
      return '–'
    }

    if (!isPacket) {
      return `${items[0].vatRate} %`
    }

    const sortedVatRates = items.map(({ vatRate }) => vatRate).sort()

    return sortedVatRates[0] === sortedVatRates[sortedVatRates.length - 1] ? (
      `${sortedVatRates[0]} %`
    ) : (
      <T>Products:CatalogProductList.field.multiVat</T>
    )
  }

  return (
    <Wrapper onClick={onItemOpen} style={{ height: '40px' }}>
      <FirstSection>
        <span />
      </FirstSection>

      <Section>
        <FlexRow alignItems="center" justifyContent="space-between">
          <Name>{name}</Name>
          <FlexRow>
            {isPacket && (
              <Chip color="primary" size="xs" variant="outlined">
                {items.length} <T>Products:CatalogProductList.field.items</T>
              </Chip>
            )}
            <Chip color="primary" size="xs" variant="outlined">
              <T>{`Products:ProductType.${isPacket ? 'PACKET' : 'PRODUCT'}`}</T>
            </Chip>
          </FlexRow>
        </FlexRow>
      </Section>

      <Section align="right" style={{ whiteSpace: 'nowrap' }}>
        {formatPrice(unitPrice)} €
      </Section>

      <Section align="right" style={{ whiteSpace: 'nowrap' }}>
        {getTotalVatRate()}
      </Section>

      <Section style={{ whiteSpace: 'nowrap' }}>{catalog.name}</Section>

      <DescriptionSection>
        {notes && (
          <Tooltip
            content={notes}
            placement="bottom"
            maxWidth={250}
            trigger={(triggerProps) => (
              <Description {...triggerProps}>{notes}</Description>
            )}
          />
        )}
      </DescriptionSection>

      <LastSection>
        {isRemoving ? (
          <ReactLoading
            color={palette.smoke.dark}
            height={22}
            type="spin"
            width={22}
          />
        ) : (
          <IconButton
            color="transparent"
            icon="trash"
            onClick={handleRemove}
            style={{ color: palette.danger.main }}
          />
        )}
      </LastSection>
    </Wrapper>
  )
}

////////////

const formatPrice = (priceValue: any): string =>
  typeof priceValue === 'number' ? formatCurrency(priceValue) : '–'

const DescriptionSection = styled(Section)`
  max-width: 250px;
  width: 250px;
`

const Description = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Name = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const FirstSection = styled(Section)`
  ${({ theme }) => css`
    &&&& {
      padding-left: ${theme.spacing.gu(3)}rem;
      padding-right: ${theme.spacing.gu(1)}rem;
    }
  `}
`

const LastSection = styled(Section)`
  ${({ theme }) => css`
    &&&& {
      padding-right: ${theme.spacing.gu(2)}rem;
    }
  `}
`
