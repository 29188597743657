import { parseToHsl, setLightness } from 'polished'

import { ColorLightness } from '../enums'

export default function adjustLightness(
  color: string,
  targetLevel: ColorLightness
) {
  const { lightness } = parseToHsl(color)

  const diffToBlack = Math.abs(0.15 - lightness)
  const diffToWhite = 0.95 - lightness

  const darken = targetLevel < 0

  const correction = darken
    ? (diffToBlack / 4) * targetLevel
    : (diffToWhite / 6) * (targetLevel + 2)

  return setLightness(lightness + correction)(color)
}
