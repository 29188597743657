import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DangerColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'

type Props = {
  handleDelete: (
    groupId: string,
    keepReservations: boolean
  ) => Promise<void | undefined>
  groupId: string
  isProcessing: boolean
}

export const GroupActions = ({
  handleDelete,
  isProcessing,
  groupId,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <RemoveButton
            disabled={isProcessing}
            onClick={() => handleDelete(groupId, false)}
          >
            <T>ReservationModal:action.deleteGroupAndKeepReservations</T>
          </RemoveButton>
          <RemoveButton
            disabled={isProcessing}
            onClick={() => handleDelete(groupId, true)}
          >
            <DangerColor>
              <T>ReservationModal:action.deleteGroupAndReservations</T>
            </DangerColor>
          </RemoveButton>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <InnocuousButton
          disabled={isProcessing}
          innerRef={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setModalOpen(true)}
        >
          <DangerColor>
            <T>ReservationModal:action.deleteGroup</T>
          </DangerColor>
        </InnocuousButton>
      )}
      zIndex={10003}
    />
  )
}

const RemoveButton = styled(InnocuousButton).attrs(() => ({
  noMargin: true,
  noNudge: true,
}))`
  ${({ theme }) => css`
    justify-content: flex-start;
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}
`
