import { lighten } from 'polished'
import styled, { css } from 'styled-components/macro'

import type { Palette } from '@/theme'

import { CellStatus, Emphasis } from '../../../AvailabilityCalendar.types'

export const CellBase = styled.div<{
  cellWidth: string
  depth?: number
  emphasis?: Emphasis | null
  isBold?: boolean
  isLast?: boolean
  status?: CellStatus
}>`
  position: relative;
  width: ${({ cellWidth }) => cellWidth};
  flex: 0 0 ${({ cellWidth }) => cellWidth};
  height: 100%;
  padding: 10px 10px;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    border-left: 1px solid ${theme.palette.smoke.extraDark};
  `}

  ${({ isBold }) => css`
    ${isBold && 'font-weight: 600'};
  `}

 
  text-align: right;

  ${({ isLast, theme }) => css`
    ${isLast && `border-right: 1px solid ${theme.palette.smoke.dark};`}
  `}

  &:first-child {
    border-left-width: 0;
  }

  &:nth-child(7n + 8) {
    ${({ theme }) => css`
      border-left-color: ${theme.palette.coal.light};
    `}
  }

  ${({ depth, status, theme }) =>
    `background: ${
      status
        ? getCellColor(status, depth ?? 0, theme.palette)
        : theme.palette.white
    };`}
  && {
    ${({ emphasis }) => {
      switch (emphasis) {
        case 'FULL':
          return css`
            ${({ theme }) => css`
              border-top: solid 2px ${theme.palette.primary.main};
              border-right: solid 2px ${theme.palette.primary.main};
              border-bottom: solid 2px ${theme.palette.primary.main};
              border-left: solid 2px ${theme.palette.primary.main};
            `}

            padding-top: 8px;
            padding-right: 8px;
            padding-bottom: 8px;
            padding-left: 8px;
          `
        case 'START':
          return css`
            ${({ theme }) => css`
              border-top: solid 2px ${theme.palette.primary.main};
              border-bottom: solid 2px ${theme.palette.primary.main};
              border-left: solid 2px ${theme.palette.primary.main};
            `}

            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 8px;
          `
        case 'MIDDLE':
          return css`
            ${({ theme }) => css`
              border-top: solid 2px ${theme.palette.primary.main};
              border-bottom: solid 2px ${theme.palette.primary.main};
            `}

            padding-top: 8px;
            padding-bottom: 8px;
          `
        case 'END':
          return css`
            ${({ theme }) => css`
              border-top: solid 2px ${theme.palette.primary.main};
              border-right: solid 2px ${theme.palette.primary.main};
              border-bottom: solid 2px ${theme.palette.primary.main};
            `}

            padding-top: 8px;
            padding-right: 8px;
            padding-bottom: 8px;
          `
        default:
          return ''
      }
    }}
  }
`

export const ColumnHeaderBase = styled.div<{
  columnHeaderWidth: string
  rowHeight: string
}>`
  position: sticky;
  left: 0;
  flex: 0 0 ${({ columnHeaderWidth }) => columnHeaderWidth};
  display: flex;
  flex-direction: column;
  z-index: 3000;
  height: ${({ rowHeight }) => rowHeight};
  padding: 10px 10px;
  text-align: right;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    background: ${theme.palette.white};
    border-right: 1px solid ${theme.palette.smoke.dark};
  `}
`

export const RowBase = styled.div`
  display: inline-flex;
  flex-direction: row;

  &:last-child {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.palette.smoke.dark};
    `}
  }
`

export const RowContentBase = styled.div<{ rowHeight: number }>`
  height: ${({ rowHeight }) => rowHeight};
  display: inline-flex;
  width: 100%;

  &:not(:last-child) {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.palette.smoke.dark};
    `}
  }
`

const getCellColor = (status: CellStatus, depth: number, palette: Palette) => {
  switch (status) {
    case CellStatus.VACANT:
      return lighten(depth / 55, '#e4f2e2')
    case CellStatus.OCCUPIED:
      return lighten(depth / 55, '#ffd8d0')
    case CellStatus.UNAVAILABLE:
      return getGrayAccent(depth)
    case CellStatus.PARTIAL:
    default:
      return palette.white
  }
}

const getGrayAccent = (depth: number) => {
  switch (depth) {
    case 3:
      return '#f6f6f6'
    case 2:
      return '#f0f0f0'
    case 1:
      return '#ebebeb'
    case 0:
    default:
      return '#e5e5e5'
  }
}
