import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'

import { Title } from './Title'

type Props = {
  children: ReactNode
  isEditable?: boolean
  noMargin?: boolean
  title?: ReactNode
}

export const Subsection = ({
  children,
  isEditable,
  noMargin,
  title,
}: Props) => (
  <Wrapper noMargin={noMargin} noPadding>
    <Title>{title}</Title>

    {isEditable ? (
      children
    ) : (
      <StaticDataWrapper alignItems="center">{children}</StaticDataWrapper>
    )}
  </Wrapper>
)

////////

const StaticDataWrapper = styled(FlexRow)`
  font-weight: 500;
  height: 30px;

  ${({ theme }) => `
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ noMargin?: boolean }>`
  ${({ noMargin, theme }) => `
    margin-bottom: ${theme.spacing.gu(noMargin ? 0 : 1)}rem;
  `}
`
