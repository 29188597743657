import { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { orderQueries } from '@/modules/Order/queries'
import {
  InvoiceWithPaymentsByIdPayload,
  InvoiceWithPaymentsByIdVariables,
  Payment,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import { PaymentState } from '~generated-types'

import { PaymentRow } from './PaymentRow'

type Props = {
  invoiceId: string
  orderId: string
  payments: Payment[]
}

export const Payments = ({ invoiceId, orderId, payments }: Props) => {
  const { palette } = useTheme()

  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [isRefreshed, setRefreshed] = useState<boolean>(false)

  const resetRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [refetchInvoice] = useLazyQuery<
    InvoiceWithPaymentsByIdPayload,
    InvoiceWithPaymentsByIdVariables
  >(orderQueries.INVOICE_WITH_PAYMENTS_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ invoice }) => {
      setOrdersById((ordersById) => ({
        ...ordersById,
        [orderId]: {
          ...ordersById[orderId],
          invoices: ordersById[orderId].invoices.map((i) =>
            i.id === invoiceId ? { ...i, ...invoice } : i
          ),
        },
      }))
      setRefreshed(true)
      resetRef.current = setTimeout(() => setRefreshed(false), 2000)
    },
    variables: { id: invoiceId },
  })

  useEffect(() => {
    return () => {
      if (resetRef.current) clearTimeout(resetRef.current)
    }
  }, [resetRef])

  const paymentsArr = payments
    .filter(
      ({ state }) =>
        state === PaymentState.Paid || state === PaymentState.PaidPending
    )
    .sort(
      (a, b) =>
        moment(a.auditLog.createdAt).valueOf() -
        moment(b.auditLog.createdAt).valueOf()
    )

  return (
    <Wrapper>
      <FlexRow alignItems="center" justifyContent="space-between">
        <Title>
          <T>Orders:Payments.payments</T>
        </Title>

        <Tooltip
          content={<T>Orders:Payments.actions.refresh</T>}
          placement="left"
          trigger={(triggerProps) => (
            <div {...triggerProps}>
              <RefreshButton onClick={() => refetchInvoice()}>
                <FontAwesomeIcon
                  color={
                    isRefreshed ? palette.success.main : palette.text.lighter
                  }
                  icon={isRefreshed ? 'check' : 'arrows-rotate'}
                />
              </RefreshButton>
            </div>
          )}
        />
      </FlexRow>

      <PaymentsWrapper>
        {!paymentsArr.length ? (
          <Placeholder>
            <span>
              <T>Orders:Payments.modal.emptyTitle</T>
            </span>
            <span>
              <T>Orders:Payments.modal.emptyLabel</T>
            </span>
          </Placeholder>
        ) : (
          paymentsArr.map((payment) => (
            <PaymentRow
              invoiceId={invoiceId}
              key={payment.id}
              orderId={orderId}
              payment={payment}
            />
          ))
        )}
      </PaymentsWrapper>
    </Wrapper>
  )
}

///////

const PaymentsWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  display: flex;
  flex-direction: column;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const RefreshButton = styled(InnocuousButton)`
  height: 24px;
  width: 24px;

  && {
    ${({ theme }) => css`
      margin-right: -${theme.spacing.gu(1)}rem;
    `}
  }
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
  `}
`
