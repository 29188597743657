import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import { VSTDetailsModal } from './VSTDetailsModal'

type Props = {
  isVSTWarning: boolean
  participant: FetchedParticipant
  readOnly?: boolean
}

export const Education = ({ isVSTWarning, participant, readOnly }: Props) => {
  const { spacing, palette } = useTheme()
  const { education } = participant

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <ModalContainer
      referenceElement={({ ref }) => (
        <ServiceItemSquared
          ref={ref}
          disabled={readOnly}
          isWarning={isVSTWarning}
          minWidth={`${spacing.gu(16)}rem`}
          onClick={readOnly ? () => null : () => setModalOpen(true)}
          renderContent={() =>
            education ? (
              <span>
                <T>{`enums:education.${education}`}</T>
              </span>
            ) : (
              <>
                <Label>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon={'plus'}
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                  <T>ParticipantsList:PersonalFields.education</T>
                </Label>
              </>
            )
          }
        />
      )}
      onClose={() => setModalOpen(false)}
      isOpen={isModalOpen}
      modal={<VSTDetailsModal participant={participant} />}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}
