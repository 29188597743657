import { gql, useMutation } from '@apollo/client'

import type {
  ExtractPurchaseMutation,
  ExtractPurchaseMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productPurchase}

  mutation ExtractPurchase($input: PurchaseProductExtractInput!) {
    purchaseProductExtract(input: $input) {
      extracted {
        ...ProductPurchase
      }
      source {
        ...ProductPurchase
      }
    }
  }
`

export const useExtractPurchaseMutation = () =>
  useMutation<ExtractPurchaseMutation, ExtractPurchaseMutationVariables>(
    MUTATION
  )
