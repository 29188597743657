import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import {
  EmphasisByDate,
  Mode,
  RoomAvailability,
} from '../../AvailabilityCalendar.types'
import { BooleanStatusRow } from './BooleanStatusRow'
import { RoomFeatures } from './RoomFeatures'
import { RoomNote } from './RoomNote'
import { IndentedDataRow, ScrollTableProps } from './ScrollTable'

type Props = ScrollTableProps & {
  data: RoomAvailability
  emphasis: EmphasisByDate | null | undefined
  handleOnSelectRoom?: () => void
  isLastCategory: boolean
  isLastRoomType: boolean
  isLastRow: boolean
  mode: Mode
}

export const RoomSection = ({
  data: {
    room: { beds, extraBeds, features, id, internalInfo, number },
    status,
  },
  emphasis,
  handleOnSelectRoom,
  isLastCategory,
  isLastRoomType,
  isLastRow,
  mode,
  ...tableProps
}: Props) => (
  <IndentedDataRow
    indents={[!isLastCategory, !isLastRoomType, true]}
    isLastRow={isLastRow}
    onSelect={handleOnSelectRoom}
    renderContent={() => (
      <BooleanStatusRow
        emphasis={emphasis}
        mode={mode}
        property="rooms"
        status={status}
        {...tableProps}
      />
    )}
    renderHeader={() => (
      <Row
        columnHeaderWidth={tableProps.columnHeaderWidth}
        indents={[!isLastCategory, !isLastRoomType, true]}
        isPlusShown={!!handleOnSelectRoom}
      >
        <RoomNote id={id} note={internalInfo} />

        <RoomNumber>{number}</RoomNumber>

        <Divider>|</Divider>

        <RoomSize>
          {beds}+{extraBeds}
        </RoomSize>

        {!!features.length && (
          <>
            <Divider>|</Divider>
            <RoomFeatures features={features} />
          </>
        )}
      </Row>
    )}
    {...tableProps}
  />
)

///////

const Divider = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
const Row = styled(FlexRow)<{
  columnHeaderWidth: string
  indents: boolean[]
  isPlusShown: boolean
}>`
  margin-left: -2px;
  width: ${({ columnHeaderWidth, indents, isPlusShown }) =>
    `calc(${columnHeaderWidth} - ${30 * indents.length}px - ${
      isPlusShown ? 60 : 30
    }px - 1px)`};
`

const RoomNumber = styled.span`
  font-weight: 500;
  white-space: nowrap;
`

const RoomSize = styled.span`
  text-align: center;
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
