import { gql } from '@apollo/client'

import {
  RoomReservationFragment,
  RoomTypeReservationFragment,
} from './RoomLayout.fragments'

export default Object.freeze({
  ROOM_CLEANING_STATUSES: gql`
    query RoomCleaningStatuses(
      $ids: [ID!]!
      $from: LocalDate!
      $to: LocalDate!
    ) {
      registry {
        roomsByIds(ids: $ids) {
          id
          status {
            cleaningStatus
            cleaningStatusChanges(
              from: $from
              to: $to
              onlyLatestChangePerDate: true
            ) {
              id
              date
              newCleaningStatus
            }
          }
        }
      }
    }
  `,
  ROOM_FEATURES: gql`
    query RoomFeatures {
      registry {
        roomFeatures {
          id
          name
          shortName
          icon
        }
      }
    }
  `,
  SALE_RESERVATIONS: gql`
    ${RoomReservationFragment}
    ${RoomTypeReservationFragment}

    query SaleRoomTypeAndRoomReservations($id: ID!) {
      sales(id: $id) {
        accommodation {
          accommodationGroups {
            id
            name
            roomTypeReservations {
              ...RoomLayoutRoomTypeReservation
            }
            roomReservations(includeRoomTypeRooms: false) {
              ...RoomLayoutRoomReservation
            }
            sortOrder
            targets {
              default
              id
              name
              sortOrder
              targetedReservations {
                roomTypeReservations {
                  ...RoomLayoutRoomTypeReservation
                }
                roomReservations(includeRoomTypeRooms: false) {
                  ...RoomLayoutRoomReservation
                }
              }
            }
          }
          id
        }
        id
      }
    }
  `,
})
