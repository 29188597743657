import { gql, useMutation } from '@apollo/client'

import {
  SetResourceReservationGroupMutation,
  SetResourceReservationGroupMutationVariables,
} from '~generated-types'

import ResourceReservationFragment from '../fragments/resourceReservation'

export type {
  SetResourceReservationGroupMutation,
  SetResourceReservationGroupMutationVariables,
}

export const SET_RESOURCE_RESERVATION_GROUP_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationGroup(
    $input: ResourceReservationSetGroupInput!
  ) {
    resourceReservationSetGroup(input: $input) {
      group {
        end
        id
        name
        number
        start
      }
      reservation {
        ...ResourceReservation
      }
    }
  }
`

export function useSetResourceReservationGroupMutation() {
  return useMutation<
    SetResourceReservationGroupMutation,
    SetResourceReservationGroupMutationVariables
  >(SET_RESOURCE_RESERVATION_GROUP_MUTATION)
}

export default useSetResourceReservationGroupMutation
