import { CSSProperties, ReactNode } from 'react'
import { darken, desaturate, lighten } from 'polished'
import styled, { css } from 'styled-components/macro'

import type { PropsWithTheme, Theme } from '@/theme'

export type CustomColor = {
  fill: string
  stroke: string
}

type Color =
  | 'accent1'
  | 'accent2'
  | 'accent3'
  | 'danger'
  | 'default'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
type Size = 'xs' | 'sm' | 'md'
type Variant = 'default' | 'outlined'

export type ChipProps = {
  color?: Color
  customColor?: CustomColor
  children?: ReactNode
  flex?: boolean
  noWrap?: boolean
  size?: Size
  style?: CSSProperties
  variant?: Variant
}

export type ChipColor = Color

export const Chip = styled.span<ChipProps>`
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(1)}rem;
  `}

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }

  font-weight: 600;
  line-height: 1;

  text-align: center;
  text-transform: uppercase;

  ${(props) =>
    props.flex
      ? css`
          flex: 1;
          text-align: center;
        `
      : ''}

  ${({ noWrap }) =>
    noWrap
      ? css`
          white-space: nowrap;
        `
      : ''}

  ${(props) => colorReconciler(props)}
  ${(props) => sizeReconciler(props)}
`

export default Chip

////////////

const getDefaultDark = (theme: Theme) =>
  darken(0.02, desaturate(0.04, theme.palette.text.light))
const getDefaultLight = (theme: Theme) =>
  lighten(0.44, theme.palette.text.light)

const isOutlined = (variant: Variant | null | undefined) =>
  variant === 'outlined'

const colorReconciler = ({
  color,
  customColor,
  theme,
  variant,
}: PropsWithTheme<ChipProps>) => {
  if (!!customColor) {
    return css`
      border-color: ${isOutlined(variant) ? customColor.stroke : 'transparent'};
      background: ${isOutlined(variant) ? 'transparent' : customColor.fill};
      color: ${customColor.stroke};
    `
  }

  switch (color) {
    case 'accent1':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.accent1.dark
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.accent1.extraLighter};
        color: ${theme.palette.accent1.dark};
      `
    case 'accent2':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.accent2.extraDark
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.accent2.lighter};
        color: ${theme.palette.accent2.extraDark};
      `
    case 'accent3':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.accent3.extraDark
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.accent3.lighter};
        color: ${theme.palette.accent3.extraDark};
      `
    case 'danger':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.danger.darker
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.danger.lighter};
        color: ${theme.palette.danger.darker};
      `
    case 'disabled':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.text.lighter
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.smoke.lighter};
        color: ${theme.palette.text.lighter};
      `
    case 'primary':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.primary.dark
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.primary.extraLight};
        color: ${theme.palette.primary.dark};
      `
    case 'secondary':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.secondary.darker
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.secondary.lighter};
        color: ${theme.palette.secondary.darker};
      `
    case 'success':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.success.darker
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.success.lighter};
        color: ${theme.palette.success.darker};
      `
    case 'warning':
      return css`
        border-color: ${isOutlined(variant)
          ? theme.palette.warning.darker
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.warning.lighter};
        color: ${theme.palette.warning.darker};
      `
    case 'default':
    default:
      return css`
        border-color: ${isOutlined(variant)
          ? getDefaultDark(theme)
          : 'transparent'};
        background: ${isOutlined(variant)
          ? 'transparent'
          : getDefaultLight(theme)};
        color: ${getDefaultDark(theme)};
      `
  }
}

const sizeReconciler = ({ theme, size }: PropsWithTheme<ChipProps>) => {
  switch (size) {
    case 'xs':
      return css`
        font-size: 0.7rem;
        line-height: 0.7rem;
        padding: 3px 4px;

        @media print {
          font-size: 7pt;
        }

        &:not(:last-child) {
          margin-right: ${theme.spacing.gu(0.5)}rem;
        }
      `
    case 'sm':
      return css`
        font-size: 0.75rem;
        line-height: 0.75rem;

        @media print {
          font-size: 8pt;
        }
      `
    case 'md':
    default:
      return css`
        border-width: 2px;
        font-size: 0.85rem;
        line-height: 0.85rem;

        @media print {
          font-size: 8pt;
        }
      `
  }
}
