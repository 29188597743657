import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input, InputProps } from '@/components/FormControls'

type Props = {
  background?: string
  endAdornment?: ReactNode
  isProcessing?: boolean
  showBorder?: boolean
  startAdornment?: ReactNode
} & InputProps

const getAdornmentSpace = (endAdornment?: ReactNode) => {
  if (typeof endAdornment === 'string') {
    switch (endAdornment.length) {
      case 1:
        return 3
      case 2:
        return 3.5
      case 3:
      default:
        return 4
    }
  }

  return 4
}

export const DataTableInputBase = styled(Input)<Props>`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(1)}rem;
    `}

    height: 1.75rem;

    text-align: inherit;

    border-radius: 4px;

    /* Only hide the border on non-default states */
    ${({ color, showBorder }) =>
      !color && !showBorder && 'border-color: transparent;'}

    ${({ background }) =>
      background &&
      css`
        background: ${background};
      `}

    ${({ isProcessing }) =>
      isProcessing &&
      css`
        ${({ theme }) => css`
          padding-right: ${theme.spacing.gu(3)}rem;
        `}
      `}

    ${({ endAdornment, theme }) =>
      endAdornment &&
      css<any>`
        padding-right: ${getAdornmentSpace(endAdornment) *
        theme.spacing.gu(1)}rem;
      `}

    ${({ startAdornment, theme }) =>
      startAdornment &&
      css`
        padding-left: ${theme.spacing.gu(3)}rem;
      `}

    ${({ borderless, color, disabled, theme }) => css`
      border-color: ${disabled
        ? theme.palette.smoke.main
        : color
          ? color
          : borderless
            ? 'transparent'
            : theme.palette.smoke.dark};
    `}
  }
`
