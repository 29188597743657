import { useMemo } from 'react'

import { FilterSearchList } from '@/components/FilterSearchList'
import { generateCompareFn } from '@/utils/arrays'

import { roomHooks } from '../../hooks'
import { Room } from '../../types'
import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'

export const RoomList = () => {
  const { error, loading, rooms } = roomHooks.useRooms()

  const sorted = useMemo(
    () => [...rooms].sort(generateCompareFn('number')),
    [rooms]
  )

  return (
    <FilterSearchList
      allData={sorted}
      columnCount={7}
      error={error}
      loading={loading}
      renderListItem={(x: Room) => <ListItem key={x.id} data={x} />}
      renderListHeader={() => <ListHeader />}
      searchFilter={(searchValue) =>
        ({ building, number, roomType }) =>
          building.name
            .toLowerCase()
            .includes(searchValue.toLowerCase().trim()) ||
          number.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
          roomType.name.toLowerCase().includes(searchValue.toLowerCase().trim())
        }
    />
  )
}
