import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales/hooks'

export const SalesDetailsRow = () => {
  const { data } = salesHooks.useSalesDetailsContext()

  if (!data) {
    return null
  }

  const {
    accommodation,
    customer,
    estimatedDates,
    estimatedParticipants,
    orderNumber,
  } = data

  const accommodationSummary = accommodation.accommodationGroups.reduce(
    (acc, group) => ({
      beds: acc.beds + group.status.reservedDetailed.beds,
      extraBeds: acc.extraBeds + group.status.reservedDetailed.extraBeds,
      rooms: acc.rooms + group.status.reservedDetailed.rooms,
    }),
    {
      beds: 0,
      extraBeds: 0,
      rooms: 0,
    }
  )

  const customerData = customer?.customer

  const customerName =
    customerData?.__typename === 'CustomerOrganization'
      ? customerData.organization.name
      : customerData?.__typename === 'CustomerPerson'
        ? `${customerData.person.firstName} ${customerData.person.lastName}`
        : ''

  return (
    <FlexRow alignItems="center">
      {orderNumber && <Sale>{`#${orderNumber}`}</Sale>}
      {customerName && <Customer>{`– ${customerName}`}</Customer>}

      <Separator />

      <Section>
        <Icon icon={['far', 'calendar']} />
        {estimatedDates && estimatedDates.start && estimatedDates.end
          ? `${moment(estimatedDates.start).format('dd, D.M.YY')} – ${moment(
              estimatedDates.end
            ).format('dd, D.M.YY')}`
          : '–'}
      </Section>

      <Separator />

      <Section>
        <Icon icon="user-group" />
        {estimatedParticipants || '–'}
      </Section>

      <Separator />

      <Section>
        <Icon icon="door-open" />
        {accommodationSummary.rooms}
      </Section>

      <Separator />

      <Section>
        <Icon icon="bed" />
        {accommodationSummary.beds} + {accommodationSummary.extraBeds}
      </Section>
    </FlexRow>
  )
}

export default SalesDetailsRow

///////

const Sale = styled.span`
  font-weight: 500;
  white-space: pre-wrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Customer = styled(Sale)`
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(28)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Separator = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
    border-left: 1px solid ${theme.palette.smoke.main};
    height: ${theme.spacing.gu(3)}rem;
  `}
`

const Section = styled(FlexRow)`
  font-weight: 500;
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
