import { SalesForPrintParticipant } from '../../../../../types'

export type ParticipantsByRoom = {
  participants: SalesForPrintParticipant[]
  room: string
}

export const groupByRoom = (participants: SalesForPrintParticipant[]) =>
  Object.values(
    participants.reduce((acc: { [room: string]: ParticipantsByRoom }, p) => {
      const accommodationServices = p.services.filter(
        (s) => s.__typename === 'ServiceParticipantBed'
      )
      const purchaseServices = p.services.filter(
        (s) => s.__typename === 'ServicePurchase'
      )

      if (!accommodationServices.length) {
        acc['NO_ROOM'] = {
          participants: [...(acc['NO_ROOM']?.participants ?? []), p],
          room: 'NO_ROOM',
        }
      }

      p.services.forEach((s) => {
        if (s.__typename === 'ServiceParticipantBed') {
          const room =
            s.participantRoom?.roomReservation.request.room.number ?? 'NO_ROOM'

          acc[room] = {
            participants: [
              ...(acc[room]?.participants ?? []),
              {
                ...p,
                services: [s, ...purchaseServices],
              },
            ],
            room,
          }
        }
      })

      return acc
    }, {})
  ).sort((a, b) =>
    a.room === 'NO_ROOM'
      ? 1
      : b.room === 'NO_ROOM'
      ? -1
      : a.room > b.room
      ? 1
      : -1
  )
