import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input, Label } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import {
  FetchedParticipant,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'

import { isSameOption } from '../utils'
import {
  EducationSelector,
  LanguageSelectorWrapper,
  LifeStageSelector,
} from './components'

type Props = {
  selectedParticipants: FetchedParticipant[]
}

export const VSTDetailsModal = ({ selectedParticipants }: Props) => {
  const { language } = useLanguageContext()
  const { handleChangeParticipantData } = useParticipantsListContext()

  const birthday = isSameOption(selectedParticipants, 'birthday')
    ? selectedParticipants[0]?.birthday || null
    : null

  const [yearValue, setYearValue] = useState<number | undefined>(birthday?.year)
  const [isLoading, setLoading] = useState<boolean>(false)

  const handleMassChangeParticipantData = (
    data: Partial<FetchedParticipant>
  ) => {
    setLoading(true)

    return Promise.all(
      selectedParticipants.map(
        async ({ id }) =>
          await handleChangeParticipantData(
            {
              ...data,
            },
            id
          )
      )
    ).then(() => setLoading(false))
  }

  const handleUpdateYear = () => {
    setLoading(true)

    return Promise.all(
      selectedParticipants.map(
        async ({ id }) =>
          await handleChangeParticipantData(
            {
              birthday: yearValue ? { year: yearValue } : null,
            },
            id
          )
      )
    ).then(() => setLoading(false))
  }

  return (
    <ModalContainer>
      <Section>
        <FlexColumn noPadding flex={1}>
          <FlexColumn style={{ width: '100%' }}>
            <Title>
              {translate('ParticipantsList:ListHeader.year', language)}
            </Title>
            <StyledInput
              type="number"
              min="1900"
              max="2099"
              step="1"
              onChange={(e) => setYearValue(parseInt(e.target.value, 10))}
              onFocus={(e) => e.currentTarget.select()}
              onBlur={handleUpdateYear}
              value={yearValue}
              disabled={isLoading}
            />
          </FlexColumn>
          <EducationSelector
            handleUpdateParticipan={handleMassChangeParticipantData}
            selectedParticipants={selectedParticipants}
            readOnly={isLoading}
          />
          <LanguageSelectorWrapper
            handleUpdateParticipan={handleMassChangeParticipantData}
            selectedParticipants={selectedParticipants}
            readOnly={isLoading}
          />
          <LifeStageSelector
            handleUpdateParticipan={handleMassChangeParticipantData}
            selectedParticipants={selectedParticipants}
            readOnly={isLoading}
          />
        </FlexColumn>
      </Section>
    </ModalContainer>
  )
}

const ModalContainer = styled(InlineModal)`
  max-height: 100vh;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(65)}rem;
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const Section = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter} 0;
  `}
`

const StyledInput = styled(Input)`
  &:not([type='checkbox']):not([type='radio']) {
    border-radius: 4px;

    ${({ theme }) => css`
      border: 1px solid ${theme.palette.smoke.dark};
      margin-bottom: ${theme.spacing.gu(2)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
