import styled, { css } from 'styled-components/macro'

export const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBig};
  `}
`
