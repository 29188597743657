import { SalesDimension } from '@/modules/Dimensions/types'

export type Hierarchy = {
  childs: Hierarchy[]
  isActive: boolean
  isExpanded: boolean
  isIndeterminate?: boolean
  key: string
  levelKey?: string
  name: string
  nestingChilds: number
  nestingLevel: number
  path?: string
  rootId: number
  uniqueKey: string
}

// This function expands / narrows the element to show / hide nested elements, and also calculates the length of the vertical line (LineLeader) near these elements.

export const expandItem = (
  key: string,
  data: Hierarchy[],
  setData: (data: Hierarchy[]) => void
) => {
  let parentItemKey = ''
  let allNestingChilds = 0

  const findItem = (
    item: Hierarchy,
    itemKey: string,
    parentKey: string
  ): Hierarchy => {
    if (item.key === itemKey) {
      parentItemKey = parentKey

      if (item.isExpanded) {
        allNestingChilds = allNestingChilds - item.nestingChilds
        return {
          ...item,
          childs: item.childs.map((i) => childsMap(i)),
          isExpanded: false,
          nestingChilds: 0,
        }
      }

      const nestingChilds = item.childs.length + item.nestingChilds
      allNestingChilds = nestingChilds

      return {
        ...item,
        isExpanded: true,
        nestingChilds,
      }
    }

    if (item.childs.length) {
      return {
        ...item,
        childs: item.childs.map((i) => findItem(i, itemKey, item.key)),
      }
    }

    return item
  }

  const childsMap = (item: Hierarchy): Hierarchy => ({
    ...item,
    childs: item.childs.map((i) => childsMap(i)),
    isExpanded: false,
    nestingChilds: 0,
  })

  const findParent = (
    item: Hierarchy,
    itemKey: string,
    parentKey: string
  ): Hierarchy => {
    if (item.key === itemKey) {
      parentItemKey = parentKey

      return {
        ...item,
        nestingChilds: item.nestingChilds + allNestingChilds,
      }
    }

    if (item.childs.length) {
      return {
        ...item,
        childs: item.childs.map((i) => findParent(i, itemKey, item.key)),
      }
    }

    return item
  }

  let newData = data.map((item) => findItem(item, key, ''))

  while (parentItemKey) {
    const parentKey = parentItemKey
    newData = newData.map((item) => findParent(item, parentKey, ''))
  }

  setData(newData)
}

export const setInitialActive = (
  setValues: string[],
  hierarchy: Hierarchy[],
  setHierarchy: (data: Hierarchy[]) => void
) => {
  let newHierarchy = hierarchy

  setValues.forEach((set) => {
    newHierarchy = activateCheckboxItem(set, newHierarchy, null, null)
  })

  setHierarchy(newHierarchy)
}

// This function activates an element and set indeterminate.

export const activateCheckboxItem = (
  key: string,
  data: Hierarchy[],
  setData: null | ((data: Hierarchy[]) => void),
  onCheck: null | ((data: Hierarchy[]) => void)
) => {
  let parentItemKey = ''
  const indeterminateParents: { [key: string]: Partial<Hierarchy>[] } = {}

  const setChild = (item: Hierarchy, isActive: boolean): Hierarchy => ({
    ...item,
    childs: item.childs.map((i) => setChild(i, isActive)),
    isActive,
    isIndeterminate: false,
  })

  const setParent = (
    item: Hierarchy,
    itemKey: string,
    parentKey: string
  ): Hierarchy => {
    if (item.key === itemKey) {
      parentItemKey = parentKey

      const isParentActive = indeterminateParents[item.key].every(
        ({ isActive }) => isActive
      )
      const isParentIndeterminate = indeterminateParents[item.key].some(
        ({ isActive, isIndeterminate }) => isActive || isIndeterminate
      )

      indeterminateParents[parentKey] =
        indeterminateParents[parentKey] &&
        indeterminateParents[parentKey].map((child) =>
          child.key === item.key
            ? {
                ...child,
                isActive: isParentActive,
                isIndeterminate: isParentActive ? false : isParentIndeterminate,
              }
            : child
        )

      return {
        ...item,
        isActive: isParentActive,
        isIndeterminate: isParentActive ? false : isParentIndeterminate,
      }
    }

    if (item.childs.length) {
      return {
        ...item,
        childs: item.childs.map((i) => setParent(i, itemKey, item.key)),
      }
    }

    return item
  }

  const findItem = (
    item: Hierarchy,
    itemKey: string,
    parentKey: string
  ): Hierarchy => {
    indeterminateParents[item.key] = []

    if (parentKey) {
      indeterminateParents[parentKey].push({
        isActive: item.key === itemKey ? !item.isActive : item.isActive,
        isIndeterminate: item.key === itemKey ? false : item.isIndeterminate,
        key: item.key,
      })
    }

    if (item.key === itemKey) {
      parentItemKey = parentKey

      return {
        ...item,
        childs: item.childs.map((i) => setChild(i, !item.isActive)),
        isActive: !item.isActive,
        isIndeterminate: false,
      }
    }

    if (item.childs.length) {
      return {
        ...item,
        childs: item.childs.map((i) => findItem(i, itemKey, item.key)),
      }
    }

    return item
  }

  let newData = data.map((item) => findItem(item, key, ''))

  while (parentItemKey) {
    const parentKey = parentItemKey
    newData = newData.map((item) => setParent(item, parentKey, ''))
  }

  if (setData) {
    setData(newData)
    onCheck && onCheck(newData)
  }

  return newData
}

// This function activates an element (several elements), and stores the activated elements in a separate array.

export const activateRadioButtonItem = (
  uniqueKey: string,
  data: Hierarchy[],
  selectedItems: SalesDimension[],
  setData: (data: Hierarchy[]) => void,
  setSelectedItems: (items: SalesDimension[]) => void
) => {
  let parentItemKey = ''
  let selectedArr = selectedItems.map((item: SalesDimension) => {
    if (item.dimension.dimensionHierarchyRoot) {
      return {
        ...item,
        selectedLabel: null,
      }
    }

    return item
  })

  const findItem = (
    item: Hierarchy,
    itemKey: string,
    parentKey: string
  ): Hierarchy => {
    if (item.uniqueKey === itemKey) {
      parentItemKey = parentKey

      selectedArr = selectedArr.map((selectedItem) => {
        if (selectedItem.dimension.id === item.levelKey) {
          return {
            ...selectedItem,
            selectedLabel: {
              ...selectedItem.selectedLabel,
              __typename: 'DimensionLabel',
              id: item.key,
              name: item.name,
            },
          }
        }

        return selectedItem
      })

      return {
        ...item,
        isActive: true,
      }
    }

    if (item.childs.length) {
      return {
        ...item,
        childs: item.childs.map((i) => findItem(i, itemKey, item.uniqueKey)),
        isActive: false,
      }
    }

    return {
      ...item,
      isActive: false,
    }
  }

  let newData = data.map((item) => findItem(item, uniqueKey, ''))

  while (parentItemKey) {
    const parentKey = parentItemKey
    newData = newData.map((item) => findItem(item, parentKey, ''))
  }

  setSelectedItems(selectedArr)
  setData(newData)
}
