import { ChangeEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Form, Formik, FormikHelpers } from 'formik'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { DangerColor } from '@/components/Colors'
import { ErrorContainer, ErrorTitle } from '@/components/Errors'
import { Label, Select } from '@/components/FormControls'
import { FormField } from '@/components/FormWrappers'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { H3 } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'
import { FormikRenderProps } from '@/utils/forms'

import {
  CustomerContactInput,
  CustomerOrganizationDataInput,
  EInvoicingAddressInput,
  PersonInput,
  PostalAddressInput,
} from '~generated-types'

import {
  BasicDetailsOrganization,
  basicDetailsOrganizationCreateSchema,
  BasicDetailsPerson,
  basicDetailsPersonCreateSchema,
} from '../forms'
import {
  CreateCustomerOrganizationPayload,
  CreateCustomerOrganizationVariables,
  CreateCustomerPersonPayload,
  CreateCustomerPersonVariables,
  customerMutations,
} from '../mutations'
import { BackToListButton } from './BackToListButton'

type SubmitValues = {
  defaultAddress: PostalAddressInput
  defaultCustomerContact: CustomerContactInput
  eInvoicingAddress: EInvoicingAddressInput
  organization: CustomerOrganizationDataInput
  person: PersonInput
}

type Props = {
  hideHeader?: boolean
  onCreated: (args: {
    customerId: string
    customerNumber: string
    defaultAddressId: string | null | undefined
    defaultContactId: string | null | undefined
  }) => void
  onNavigateBack?: () => void
}

type CustomerType = '' | 'ORGANIZATION' | 'PERSON'

export const CreateCustomer = ({
  hideHeader,
  onCreated,
  onNavigateBack,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const [type, setType] = useState<CustomerType>('')

  const [createCustomerOrganization] = useMutation<
    CreateCustomerOrganizationPayload,
    CreateCustomerOrganizationVariables
  >(customerMutations.CREATE_CUSTOMER_ORGANIZATION)
  const [createCustomerPerson] = useMutation<
    CreateCustomerPersonPayload,
    CreateCustomerPersonVariables
  >(customerMutations.CREATE_CUSTOMER_PERSON)

  const handleCreateOrganization = (
    variables: CreateCustomerOrganizationVariables
  ) =>
    createCustomerOrganization({ variables }).then(({ data }) => {
      const customerId =
        data?.customerOrganizationCreate?.customer?.id ?? 'invalid-id'
      const customerNumber =
        data?.customerOrganizationCreate?.customer.customerNumber ?? '-1'
      const defaultAddressId =
        data?.customerOrganizationCreate?.customer?.defaultAddressId
      const defaultContactId =
        data?.customerOrganizationCreate?.customer?.defaultContactId

      onCreated({
        customerId,
        customerNumber,
        defaultAddressId,
        defaultContactId,
      })
    })

  const handleCreatePerson = (variables: CreateCustomerPersonVariables) =>
    createCustomerPerson({ variables }).then(({ data }) => {
      const customerId =
        data?.customerPersonCreate?.customer?.id ?? 'invalid-id'
      const customerNumber =
        data?.customerPersonCreate?.customer?.customerNumber ?? '-1'
      const defaultAddressId =
        data?.customerPersonCreate?.customer?.defaultAddressId

      onCreated({
        customerId,
        customerNumber,
        defaultAddressId,
        defaultContactId: null,
      })
    })

  const handleSubmit = (
    values: SubmitValues,
    { setStatus, setSubmitting }: FormikHelpers<SubmitValues>
  ) => {
    const doUpdate = () =>
      type === 'ORGANIZATION'
        ? handleCreateOrganization({
            input: {
              defaultAddress: {
                label: null,
                postalAddress: {
                  address1: values?.defaultAddress?.address1,
                  address2: values?.defaultAddress?.address2,
                  city: values?.defaultAddress?.city,
                  country: values?.defaultAddress?.country,
                  postcode: values?.defaultAddress?.postcode,
                },
              },
              defaultCustomerContact: {
                email: values?.defaultCustomerContact?.email || null,
                firstName: values?.defaultCustomerContact?.firstName,
                lastName: values?.defaultCustomerContact?.lastName,
                phone: values?.defaultCustomerContact?.phone,
              },
              eInvoicingAddress: values?.eInvoicingAddress
                ? {
                    address: values.eInvoicingAddress.address || '',
                    operator: values.eInvoicingAddress.operator || '',
                  }
                : null,
              organization: {
                businessId: values?.organization?.businessId,
                name: values?.organization?.name ?? '',
              },
              published: true,
            },
          })
        : handleCreatePerson({
            input: {
              defaultAddress: {
                label: null,
                postalAddress: {
                  address1: values?.defaultAddress?.address1,
                  address2: values?.defaultAddress?.address2,
                  city: values?.defaultAddress?.city,
                  country: values?.defaultAddress?.country,
                  postcode: values?.defaultAddress?.postcode,
                },
              },
              person: {
                email: values?.person?.email || null,
                firstName: values?.person?.firstName,
                lastName: values?.person?.lastName,
                phone: values?.person?.phone,
              },
              published: true,
            },
          })

    return doUpdate()
      .then(() => setStatus(null))
      .catch(() => setStatus('error'))
      .finally(() => setSubmitting(false))
  }

  return (
    <Gutter type={[2, 0, 3]}>
      <Wrapper>
        {onNavigateBack && <BackToListButton onClick={onNavigateBack} />}

        {!hideHeader && (
          <HeaderWrapper>
            <Header>
              <T>Customers:CreateCustomer.title</T>
            </Header>
          </HeaderWrapper>
        )}

        <Gutter type={[0, 0, 10]}>
          <FormField>
            <Label>
              <T>Customers:CreateCustomer.type.title</T>
            </Label>

            <Select
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setType(e.target.value as CustomerType)
              }
              value={type}
            >
              <option disabled value={''}>
                {translate(
                  'Customers:CreateCustomer.type.placeholder',
                  language
                )}
              </option>
              <option value={'ORGANIZATION'}>
                {translate(
                  'Customers:CreateCustomer.types.ORGANIZATION',
                  language
                )}
              </option>
              <option value={'PERSON'}>
                {translate('Customers:CreateCustomer.types.PERSON', language)}
              </option>
            </Select>
          </FormField>

          {type && (
            <Formik
              initialValues={getInitialData()}
              onSubmit={handleSubmit}
              validationSchema={
                type === 'PERSON'
                  ? basicDetailsPersonCreateSchema
                  : basicDetailsOrganizationCreateSchema
              }
            >
              {(formikRenderProps: FormikRenderProps) => {
                const showSubmitError =
                  !formikRenderProps.isSubmitting &&
                  formikRenderProps?.status === 'error'

                return (
                  <Form>
                    {showSubmitError && (
                      <ErrorContainer gutter="gutter">
                        <ErrorTitle>
                          <T>Customers:EditCustomer.submitError</T>
                        </ErrorTitle>
                      </ErrorContainer>
                    )}
                    {type === 'PERSON' ? (
                      <BasicDetailsPerson
                        disableAutosave
                        formikRenderProps={formikRenderProps}
                        showDefaultAddress
                      />
                    ) : (
                      <BasicDetailsOrganization
                        disableAutosave
                        formikRenderProps={formikRenderProps}
                        showDefaultSubForms
                      />
                    )}
                    <FlexRow alignItems="center">
                      <Button
                        color="primary"
                        disabled={
                          formikRenderProps.isSubmitting ||
                          !formikRenderProps.isValid
                        }
                        type="submit"
                      >
                        <T>Customers:CreateCustomer.create</T>
                      </Button>

                      {formikRenderProps.isSubmitting && (
                        <SavingWrapper>
                          <ReactLoading
                            color={palette.smoke.dark}
                            height={16}
                            type="spin"
                            width={16}
                          />
                        </SavingWrapper>
                      )}

                      {showSubmitError && (
                        <DangerColor>
                          <T>Customers:CreateCustomer.submitError</T>
                        </DangerColor>
                      )}
                    </FlexRow>
                  </Form>
                )
              }}
            </Formik>
          )}
        </Gutter>
      </Wrapper>
    </Gutter>
  )
}

////////////

const getInitialData = () => ({
  defaultAddress: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    postcode: '',
  },
  defaultCustomerContact: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  },
  eInvoicingAddress: {
    address: '',
    operator: '',
  },
  organization: {
    businessId: '',
    name: '',
  },
  person: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  },
})

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.palette.smoke.main};
    margin-top: ${theme.spacing.gu(1)}rem;
    margin-bottom: ${theme.spacing.gutter};
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Header = styled(H3)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const SavingWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`
