import moment from 'moment'

export const getDateRange = (end: string, start: string) => {
  const isSameDate = moment(start).isSame(end, 'day')

  const toDate = (date: string) => moment(date).format('dd D.M.YY')
  const toTime = (date: string) => moment(date).format('HH:mm')

  return isSameDate
    ? `${toDate(start)}, ${toTime(start)} – ${toTime(end)}`
    : `${toDate(start)}, ${toTime(start)} – ${toDate(end)}, ${toTime(end)}`
}
