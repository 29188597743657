import React, { ReactNode } from 'react'

import { ListTHead, ListTR } from './common'

type Props = {
  children: ReactNode
}

const ListHeader = ({ children, ...rest }: Props) => (
  <ListTHead {...rest}>
    <ListTR>{children}</ListTR>
  </ListTHead>
)

export default ListHeader
