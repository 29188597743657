import { gql } from '@apollo/client'

export default gql`
  fragment CatalogProductOld on CatalogProductOld {
    id
    type
    salesType
    code
    name
    vatRate
    unitPrice
    quantityUnit
    catalog {
      id
      name
    }
  }
`
