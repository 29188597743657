import { useState } from 'react'

import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { Gutter } from '@/components/Layout'
import type { SortOption } from '@/components/Reactivesearch'
import { translate, useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { ElasticSales } from '@/modules/Sales/types'

import { SalesType } from '~generated-types'

import ListFilters from './components/ListFilters'
import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'

const SALES_INDEX = 'sales.sales'

const ComponentIds = Object.freeze({
  DATES: 'dates',
  LIST: 'page',
  PARTICIPANTS: 'participants',
  SEARCH: 'sales-search',
  SELLER: 'seller',
  STATE: 'state',
})

type SalesSelectorProps = {
  onSelect: (itemId: string) => void | Promise<void>
  types: SalesType[]
}

export const SalesSelector = ({ onSelect, types }: SalesSelectorProps) => {
  const { language } = useLanguageContext()

  const { salesStatesByKey } = salesHooks.useSalesStates()

  const sortOptions: SortOption[] = [
    {
      dataField: 'orderNumber',
      direction: 'desc',
      label: translate('SalesSearchList:SortOptions.orderNumberDesc', language),
    },
    {
      dataField: 'estimatedDates.start',
      direction: 'asc',
      label: translate(
        'SalesSearchList:SortOptions.estimatedDatesAsc',
        language
      ),
    },
    {
      dataField: 'estimatedDates.start',
      direction: 'desc',
      label: translate(
        'SalesSearchList:SortOptions.estimatedDatesDesc',
        language
      ),
    },
    {
      dataField: 'customerName.keyword',
      direction: 'asc',
      label: translate('SalesSearchList:SortOptions.customerNameAsc', language),
    },
    {
      dataField: 'name.keyword',
      direction: 'asc',
      label: translate('SalesSearchList:SortOptions.nameAsc', language),
    },
    {
      dataField: 'participants',
      direction: 'desc',
      label: translate(
        'SalesSearchList:SortOptions.participantsDesc',
        language
      ),
    },
    {
      dataField: 'participants',
      direction: 'asc',
      label: translate('SalesSearchList:SortOptions.participantsAsc', language),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { [sortProperty.dataField]: sortProperty.direction },
        { orderNumber: 'desc' },
      ]
    : { orderNumber: 'desc' }

  const defaultQueryFn = () => ({
    query: {
      bool: {
        must: [
          {
            terms: {
              type: types,
            },
          },
        ],
      },
    },
  })

  if (!salesStatesByKey) {
    return null
  }

  return (
    <Gutter type={[0]} style={{ width: '100%' }}>
      <ElasticFilterSearchList
        columnCount={7}
        compact
        indexName={SALES_INDEX}
        reactiveListProps={{
          componentId: ComponentIds.LIST,
          dataField: 'name',
          defaultQuery: () => ({
            ...defaultQueryFn(),
            sort,
          }),
          react: {
            and: [
              ComponentIds.DATES,
              ComponentIds.PARTICIPANTS,
              ComponentIds.SELLER,
              ComponentIds.SEARCH,
              ComponentIds.STATE,
            ],
          },
          size: 10,
        }}
        renderListFilters={() => (
          <ListFilters
            componentIds={ComponentIds}
            getDefaultQuery={defaultQueryFn}
            salesStatesByKey={salesStatesByKey}
            URLParams
          />
        )}
        renderListHeader={() => <ListHeader />}
        renderListItem={(i: ElasticSales, index: number) => (
          <ListItem
            context={types[0]}
            data={i}
            isFirst={index === 0}
            key={i.id}
            onClick={() => onSelect(i.id)}
          />
        )}
        sortProps={{
          options: sortOptions,
          setValue: setSortProperty,
          value: sortProperty,
        }}
      />
    </Gutter>
  )
}

export default SalesSelector
