import { CSSProperties } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ControlProps, OptionTypeBase } from 'react-select'

import { InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import {
  PaymentTypeOption,
  usePaymentTypeOptions,
} from '@/modules/Registry/PaymentTypeOption'
import { Palette, useTheme } from '@/theme'

import { PaymentType } from '~generated-types'

import { Title } from './common'

type DynamicType = PaymentTypeOption['dynamicType']
type OtherPayment = Extract<DynamicType, { __typename: 'OtherPaymentType' }>

type Props = {
  typeId: string
  sellerId: string
  setTypeId: (typeId: string) => void
}

export const PaymentTypeSelector = ({ typeId, sellerId, setTypeId }: Props) => {
  const { palette, spacing } = useTheme()

  const { paymentTypeOptions, loading } = usePaymentTypeOptions({
    forceRefetch: true,
    input: { sellerId },
  })

  const validations = paymentTypeOptions.filter(
    ({ type }) => type === PaymentType.Other
  )

  const otherPayments = validations.map((validation) => ({
    ...validation,
    dynamicType: validation.dynamicType as OtherPayment,
  }))

  const options = otherPayments.map(({ dynamicType, enabled, reason }) => ({
    isDisabled: !enabled,
    label: enabled ? (
      dynamicType.name
    ) : (
      <Tooltip
        content={<T>{`IssueCodes:${reason?.code}`}</T>}
        maxWidth={`${spacing.gu(34)}rem`}
        placement="top"
        trigger={(triggerProps) => (
          <div {...triggerProps} style={{ display: 'flex' }}>
            <span>{dynamicType.name}</span>
            <span style={{ flex: 1 }} />
            <FontAwesomeIcon icon={['far', 'circle-question']} />
          </div>
        )}
      />
    ),
    value: dynamicType.id,
  }))

  return (
    <InlineModalSection style={{ flex: 3, marginTop: 0 }}>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.type</T> *
        </Title>

        {otherPayments.length ? (
          <ThemedSelect
            extraStyles={getExtraStyles(palette)}
            isLoading={loading}
            menuPortalTarget={document.body}
            name="other-payment-type-selector"
            onChange={(option?: Option | null) =>
              setTypeId(option?.value || '')
            }
            options={options}
            placeholder=""
            value={
              typeId ? options.find((o: Option) => o.value === typeId) : null
            }
          />
        ) : (
          <Tooltip
            content={<T>Orders:Payments.tooltip.otherPaymentType</T>}
            maxWidth={`${spacing.gu(34)}rem`}
            trigger={(triggerProps) => (
              <div {...triggerProps} style={{ width: '100%' }}>
                <ThemedSelect
                  isDisabled
                  extraStyles={getExtraStyles(palette)}
                  name="other-payment-type-selector-disabled"
                  onChange={() => null}
                  options={[]}
                  placeholder=""
                />
              </div>
            )}
          />
        )}
      </FlexColumn>
    </InlineModalSection>
  )
}

////////

const getExtraStyles = (palette: Palette) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
  control: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    '&:hover': {
      background: palette.smoke.lighter,
    },
    borderColor: isDisabled ? palette.smoke.dark : styles.borderColor,
    borderRadius: '6px',
    cursor: 'pointer',
    height: '35px',
    minHeight: '35px',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    padding: '6px',
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    color: palette.text.light,
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    padding: '2px 6px',
  }),
})
