import styled, { css } from 'styled-components/macro'

const ListTBody = styled.tbody`
  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    color: ${theme.palette.text.main};
  `}

  font-weight: 400;

  & > tr,
  & > tr > td,
  & > tr > td::after {
    transition: all ease-in-out 50ms;
  }

  & > tr {
    cursor: pointer;
    position: relative;
  }

  & > tr > td::after {
    content: ' ';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    height: 1px;
    opacity: 0;

    ${({ theme }) => css`
      background-color: ${theme.palette.primary.light};
    `}
  }

  & > tr > td {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.dark};
    `}
    border-radius: 0;
    border-style: solid;

    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 1px;
    border-right-width: 0;

    position: relative;

    &:first-child {
      border-left-width: 1px;

      ${({ theme }) => css`
        padding-left: ${theme.spacing.gu(5)}rem;
      `}
    }

    &:last-child {
      border-right-width: 1px;

      ${({ theme }) => css`
        padding-right: ${theme.spacing.gu(5)}rem;
      `}
    }
  }

  & > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }

  & > tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }

  &:not(:first-of-type) {
    & > tr:first-child > td {
      border-top-width: 1px;
    }

    & > tr:first-child > td:first-child {
      border-top-left-radius: 8px;
    }

    & > tr:first-child > td:last-child {
      border-top-right-radius: 8px;
    }
  }

  & > tr:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.primary.extraLight};
    `}

    z-index: 500;

    & > td::after {
      opacity: 1;
    }

    & > td {
      ${({ theme }) => css`
        border-color: ${theme.palette.primary.light};
      `}
    }
  }
`

export default ListTBody
