import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { InputEditor, ToggleEditableValue } from '@/components/ToggleHelpers'
import { orderServices } from '@/modules/Order/services'
import { useTheme } from '@/theme'

type Props = {
  name: string | null
  orderId: string
  readOnly: boolean
}

export const NameInput = ({ name, orderId, readOnly }: Props) => {
  const { typography, spacing } = useTheme()

  const { updateOrder } = orderServices.orderService()

  return (
    <Wrapper>
      <ToggleEditableValue
        disabled={readOnly}
        mode="input"
        placeholder=""
        renderEditor={(onClose) => (
          <InputEditor
            close={onClose}
            handleSubmit={(name: string | null) => updateOrder(orderId, name)}
            inputComponent={Input}
            type="text"
            value={name || null}
            style={{
              fontSize: typography.fontSizeBase2,
              height: spacing.gutterBig,
            }}
          />
        )}
        value={name ? <ValueLabel>{name}</ValueLabel> : null}
        valueWrapperStyle={{
          maxWidth: `${spacing.gu(26)}rem`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      />
    </Wrapper>
  )
}

////////////

const Wrapper = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(30)}rem;
    margin-right: ${theme.spacing.gutter};
  `}

  & > div {
    width: 100%;
    margin-left: 0;
  }
`

const ValueLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
