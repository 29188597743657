import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { Spacing, useTheme } from '@/theme'

import { EventVisibility, SalesEventSettingsInput } from '~generated-types'

const TRANSLATION_PATH = 'SalesDetails:eventSettings.visibilities'

type Props = {
  setEventSettings: (input: Partial<SalesEventSettingsInput>) => Promise<any>
  visibility: EventVisibility
}

export const WebshopVisibility = ({ setEventSettings, visibility }: Props) => {
  const { spacing } = useTheme()
  const { refresh, saleReadOnly: readOnly } =
    salesHooks.useSalesDetailsContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const options = [
    {
      label: <T>{`${TRANSLATION_PATH}.${EventVisibility.Hidden}`}</T>,
      value: EventVisibility.Hidden,
    },
    {
      label: <T>{`${TRANSLATION_PATH}.${EventVisibility.Private}`}</T>,
      value: EventVisibility.Private,
    },
    {
      label: <T>{`${TRANSLATION_PATH}.${EventVisibility.Public}`}</T>,
      value: EventVisibility.Public,
    },
  ]

  const handleChangeVisibility = (option?: Option | null) => {
    if (option) {
      setProcessing(true)

      setEventSettings({ visibility: option.value as EventVisibility })
        .then(() => refresh())
        .catch(() => undefined)
        .finally(() => {
          setProcessing(false)
          setEditMode(false)
        })
    }
  }

  return (
    <Section noPadding>
      <Label>
        <T>SalesDetails:eventSettings.visibility</T>
      </Label>

      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={processing}
          isDisabled={readOnly}
          menuIsOpen={isEditMode}
          name="language-selector"
          noOptionsMessage={() => <T>SalesDetails:Language.noLanguages</T>}
          onChange={handleChangeVisibility}
          onBlur={() => setEditMode(false)}
          options={options}
          placeholder="—"
          value={{
            label: <T>{`${TRANSLATION_PATH}.${visibility}`}</T>,
            value: visibility,
          }}
        />
      ) : (
        <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
          <T>{`${TRANSLATION_PATH}.${visibility}`}</T>
        </EditButton>
      )}
    </Section>
  )
}

////////////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(30)}rem;
  `}
`
