import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput, Label } from '@/components/FormControls'
import { InlineModal } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { CategorySelection } from '@/modules/Reservations'
import { useTheme } from '@/theme'

import CategorySelector from './CategorySelector'
import EditableGroupName from './EditableGroupName'
import IconButton from './SelectorIconButton'

type Props = {
  data: CategorySelection
  disabled?: boolean
  expandInitially?: boolean
  idx: number
  remove: (...args: Array<any>) => any
  // supports partial update
  update: (update: { [key: string]: any }) => void
}

const CategorySearchGroup = ({
  data: { active, categoryPaths, id, isRestrictedMode, label },
  disabled,
  expandInitially,
  idx,
  remove,
  update,
}: Props) => {
  const theme = useTheme()

  const EXPAND_STORAGE_KEY = `CategorySearchGroup.isExpanded-${id}`
  const storedStatus = sessionStorage.getItem(EXPAND_STORAGE_KEY)

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(
    typeof storedStatus === 'string'
      ? storedStatus === 'true'
      : !!expandInitially
  )

  const handleToggleExpand = () => {
    setIsExpanded((current) => {
      sessionStorage.setItem(EXPAND_STORAGE_KEY, Boolean(!current).toString())

      return !current
    })
  }

  return (
    <Wrapper key={`category-search-group-${id}`}>
      <Header>
        <IconButton
          onClick={handleToggleExpand}
          style={{ marginRight: theme.spacing.gutterSmall }}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={isExpanded && !disabled ? 'angle-down' : 'angle-right'}
            size="lg"
          />
        </IconButton>
        <EditableGroupName
          placeholder={
            <Placeholder>
              <T>ResourceReservationsCalendar:ResourceSearchGroup.title</T> #
              {idx + 1}
            </Placeholder>
          }
          setValue={(label) => update({ label })}
          value={label}
        />
        <IconButton onClick={() => update({ active: !active })}>
          <FontAwesomeIcon
            fixedWidth
            icon={active ? ['far', 'eye'] : ['far', 'eye-slash']}
          />
        </IconButton>
        <ModalContainer
          isOpen={isModalOpen}
          modal={
            <InlineModal>
              <CheckboxWrapper>
                <CheckboxInput
                  checked={isRestrictedMode}
                  onChange={() =>
                    update({ isRestrictedMode: !isRestrictedMode })
                  }
                />
                <CustomLabel
                  onClick={() =>
                    update({ isRestrictedMode: !isRestrictedMode })
                  }
                >
                  <T>ResourceReservationsCalendar:ResourceSearchGroup.mode</T>
                </CustomLabel>
              </CheckboxWrapper>
            </InlineModal>
          }
          onClose={() => setModalOpen(false)}
          placement="bottom"
          referenceElement={({ ref }) => (
            <IconButton
              color={
                isRestrictedMode
                  ? theme.palette.primary.main
                  : theme.palette.text.lighter
              }
              onClick={() => setModalOpen(true)}
              ref={ref as Ref<HTMLButtonElement> | undefined}
            >
              <FontAwesomeIcon fixedWidth size="xs" icon="filter" />
            </IconButton>
          )}
        />
        <IconButton onClick={() => remove()}>
          <FontAwesomeIcon fixedWidth icon="xmark" />
        </IconButton>
      </Header>
      <Content>
        <CategorySelector
          initialSelection={categoryPaths}
          isCollapsed={!isExpanded || !!disabled}
          isRestrictedMode={isRestrictedMode}
          onUpdateCategories={(categoryPaths) => {
            update({ categoryPaths })
          }}
          onUpdateResources={(resourceIds) => {
            update({ resourceIds })
          }}
          selectorId={id}
        />
      </Content>
    </Wrapper>
  )
}

export default CategorySearchGroup

////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Header = styled(FlexRow)`
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(0.5)}rem;
  `}
`

const Placeholder = styled.span`
  font-weight: 300;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem;
  `}
`

const CustomLabel = styled(Label)`
  font-weight: 400;
`

const CheckboxWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(1.5)}rem ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
    `}
  }
`
