import styled, { css } from 'styled-components/macro'

interface ControlRowButtonProps {
  fixedIconWidth?: boolean
  forModal?: boolean
  isSelected?: boolean
}

export const ControlRowButton = styled.button<ControlRowButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.palette.primary.main : theme.palette.smoke.dark};
  border-radius: 4px;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.primary.main : theme.palette.text.light};
  font-weight: 600;
  cursor: pointer;
  height: 38px;
  width: ${({ fixedIconWidth }) => (fixedIconWidth ? '38px' : 'auto')};
  margin-right: ${({ forModal, theme }) =>
    forModal ? 0 : `${theme.spacing.gu(1)}rem`};
  margin-bottom: ${({ forModal, theme }) =>
    forModal ? `${theme.spacing.gu(1)}rem` : 0};
  outline: none;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &:last-child {
    margin-right: 0;
  }
`
