import styled, { css } from 'styled-components/macro'

import { Chip as BaseChip } from '@/components/Chip'
import { InlineModalLine } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { MarginalTax } from '@/modules/Products/types'

import { CommissionMode } from '~generated-types'

import {
  CommissionModeSection,
  InternalCostSection,
  InternalPurchaseSection,
  MarginalTaxSection,
} from './components'

type Props = {
  commission: number | null
  commissionMode: CommissionMode
  id: string
  internalCost: number | null
  internalPurchase: number | null
  isLast: boolean
  marginalTax: MarginalTax | null
}

export const CostingSection = ({
  commission,
  commissionMode,
  id,
  internalCost,
  internalPurchase,
  isLast,
  marginalTax,
}: Props) => (
  <>
    <InlineModalLine dashed style={{ margin: 0 }} />

    <Wrapper isLast={isLast}>
      <FlexRow alignItems="center">
        <Chip size="sm" variant="outlined" color="danger">
          <T>Products:ProductManager.pricing.costing</T>
        </Chip>
      </FlexRow>

      <Spacer />

      <FlexRow>
        {commission !== null && (
          <CommissionModeSection commissionMode={commissionMode} id={id} />
        )}

        {internalCost !== null && (
          <>
            {commission !== null && <Divider />}

            <InternalCostSection id={id} internalCost={internalCost} />
          </>
        )}

        {marginalTax && (
          <>
            {(commission !== null || internalCost !== null) && <Divider />}

            <MarginalTaxSection id={id} marginalTax={marginalTax} />
          </>
        )}

        {internalPurchase !== null && (
          <>
            {(commission !== null || internalCost !== null || marginalTax) && (
              <Divider />
            )}

            <InternalPurchaseSection
              id={id}
              internalPurchase={internalPurchase}
              marginalTax={marginalTax}
            />
          </>
        )}
      </FlexRow>
    </Wrapper>
  </>
)

/////////

const Chip = styled(BaseChip)`
  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const Divider = styled.div`
  height: 100%;
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`

const Spacer = styled.div`
  flex: 1;
`

const Wrapper = styled.div<{ isLast: boolean }>`
  display: flex;

  height: 100%;

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    `}

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
    background: ${theme.palette.smoke.extraLight};
  `}
`
