import { gql, useQuery } from '@apollo/client'

import { CatalogsQuery as QueryData } from '~generated-types'

import catalogFragments from '../fragments'
import { Catalog } from '../types'

export const QUERY = gql`
  ${catalogFragments.catalog}

  query Catalogs {
    registry {
      catalogs {
        ...Catalog
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useCatalogs(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, any>(QUERY, {
    fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
  })

  const catalogs: Catalog[] = data ? data.registry.catalogs : []

  return {
    catalogs,
    error,
    loading,
    refetch,
  }
}
