import moment from 'moment'

import { ById } from '@/common/types'
import { Reservation } from '@/modules/Reservations/ResourceReservation'

import { ResourceReservationIdsByResource } from '../ReservationsGridState'

export const groupReservationsByResource = (reservations: ById<Reservation>) =>
  Object.keys(reservations)
    .map((id) => reservations[id])
    .reduce(
      (acc: ResourceReservationIdsByResource, { id, resource, start }) => {
        const dateKey = moment(start).format('YYYY-MM-DD')

        if (!resource?.id) {
          console.warn('Missing resource id for reservation', id)
          return acc
        }

        acc[resource.id] = acc[resource.id] || {}
        acc[resource.id][dateKey] = acc[resource.id][dateKey] || []
        acc[resource.id][dateKey].push(id)

        return acc
      },
      {}
    )
