import { ReactiveComponent } from '@appbaseio/reactivesearch'

import { T } from '@/modules/Language'

import { StatusToggle } from './StatusToggle'

type Props = {
  componentIds: {
    [key: string]: string
  }
  context: 'CATALOG'
  getDefaultQuery: () => {
    [key: string]: any
  }
  URLParams?: boolean
}

export const StatusToggleContainer = ({
  componentIds,
  context,
  getDefaultQuery,
  URLParams,
}: Props) => {
  switch (context) {
    case 'CATALOG':
      return (
        <ReactiveComponent
          componentId={componentIds.STATE}
          defaultQuery={() => ({
            ...getDefaultQuery(),
            aggs: {
              active: {
                terms: {
                  field: 'active',
                },
              },
            },
          })}
          react={{
            and: [componentIds.SEARCH],
          }}
          render={({ aggregations, setQuery, value }) =>
            aggregations && aggregations.active ? (
              <StatusToggle
                activeItemsLabel={<T>Catalog:CatalogState.active</T>}
                aggregation={aggregations.active}
                allItemsLabel={<T>Catalog:CatalogState.all</T>}
                field={'active'}
                inactiveItemsLabel={<T>Catalog:CatalogState.inactive</T>}
                setQuery={setQuery}
                value={value}
              />
            ) : null
          }
          showFilter={false}
          URLParams={URLParams}
        />
      )
    default:
      return <></>
  }
}
