import { gql, useMutation } from '@apollo/client'

import {
  DownloadDocumentMutation,
  DownloadDocumentMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DownloadDocument($input: DocumentDownloadInput!) {
    documentDownload(input: $input) {
      id
    }
  }
`

export const useDownloadDocumentMutation = () =>
  useMutation<DownloadDocumentMutation, DownloadDocumentMutationVariables>(
    MUTATION
  )
