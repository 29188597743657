import { gql } from '@apollo/client'

import { customerFragments } from '@/modules/Registry/Customer/fragments'

import { salesFragments } from '../fragments'

export default gql`
  ${customerFragments.customerReference}
  ${salesFragments.salesForPrintAccommodation}
  ${salesFragments.salesForPrintMeal}
  ${salesFragments.salesForPrintParticipant}
  ${salesFragments.salesForPrintResourceReservation}
  ${salesFragments.salesForPrintTask}

  query SalesDetailsForPrint(
    $id: ID!
    $withAccommodation: Boolean!
    $withMeals: Boolean!
    $withParticipants: Boolean!
    $withReservations: Boolean!
    $withTasks: Boolean!
  ) {
    sales(id: $id) {
      accommodation @include(if: $withAccommodation) {
        ...AccommodationForSalesPrint
      }
      customer {
        ...CustomerReference
      }
      estimatedDates {
        end
        start
      }
      facet {
        features {
          feature
        }
        id
      }
      id
      name
      notes
      orderNumber
      products {
        id
        purchases {
          id
          product {
            name
          }
          attributes {
            quantity
          }
        }
      }
      participantConnection(input: { pagination: { size: 10000 } })
        @include(if: $withParticipants) {
        nodes {
          ...ParticipantForSalesPrint
        }
      }
      reservationDates {
        end
        start
      }
      resourceReservations @include(if: $withReservations) {
        ...ResourceReservationForSalesPrint
      }
      lifecycle {
        state {
          key
          names {
            locale
            content
          }
          systemState
        }
      }
      tasks(input: { types: [OTHER] }) @include(if: $withTasks) {
        ...TaskForSalesPrint
      }
      type
    }
    scheduledMealsBySales(salesId: $id) @include(if: $withMeals) {
      ...MealForSalesPrint
    }
  }
`
