import { gql } from '@apollo/client'

import productPurchaseLink from './productPurchaseLink'
import productPurchaseStatus from './productPurchaseStatus'

export default gql`
  ${productPurchaseLink}
  ${productPurchaseStatus}

  fragment ProductPurchase on PurchaseProduct {
    attributes {
      duration {
        from
        to
      }
      quantity
    }
    auditLog {
      createdAt
    }
    id
    link {
      ...ProductPurchaseLink
    }
    order {
      id
      lifecycle {
        state
      }
      orderNumber
      paymentInfo {
        status
      }
    }
    product {
      id
      name
    }
    status {
      ...ProductPurchaseStatus
    }
    targetType
    totalPrice {
      amount
      amountVatIncluded
      vatAmount
    }
  }
`
