import { gql } from '@apollo/client'

import confirmation from './confirmation'
import invoice from './invoice'
import offer from './offer'
import orderInfo from './orderInfo'
import orderPacket from './orderPacket'

export default gql`
  ${confirmation}
  ${invoice}
  ${offer}
  ${orderInfo}
  ${orderPacket}

  fragment Order on Order {
    ...OrderInfo

    items {
      ...OrderPacket
    }
    confirmations {
      ...Confirmation
    }
    invoices {
      ...Invoice
    }
    offers {
      ...Offer
    }
  }
`
