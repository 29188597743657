import { CategorySet } from '@/modules/Registry/Category'

type SelectedPathsBySet = {
  [setId: string]: string[]
}

export const groupPathsBySetId = (
  paths: string[],
  sets: CategorySet[]
): SelectedPathsBySet => {
  const getTargetSetId = (path: string): string | null | undefined =>
    sets.find(({ rootCategories }) =>
      rootCategories.find(({ id }) => path.startsWith(`/${id}`))
    )?.id

  return paths.reduce((acc, path) => {
    const setId = getTargetSetId(path)

    return setId
      ? { ...acc, [setId]: [...(acc[setId as keyof typeof acc] || []), path] }
      : acc
  }, {})
}
