import styled, { css } from 'styled-components/macro'

export const OrderItemsSpacer = () => (
  <tr>
    <TD colSpan={10} />
  </tr>
)

/////

const TD = styled.td`
  && {
    ${({ theme }) => css`
      border-left: solid 1px ${theme.palette.white};
      border-right: solid 1px ${theme.palette.white};
      height: ${theme.spacing.gu(2)}rem;
    `}
  }
`
