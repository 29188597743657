import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'

export const NumberInput = styled(Input).attrs(() => ({
  step: 1,
  type: 'number',
}))`
  &&& {
    ${({ theme }) => css`
      height: ${theme.spacing.gu(2.5)}rem;
      max-width: ${theme.spacing.gu(5)}rem;
    `}

    padding: 0;
    text-align: center;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
`
