import styled, { css } from 'styled-components/macro'

const TreeRowLabel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  line-height: 1;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

export default TreeRowLabel
