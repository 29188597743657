import { gql } from '@apollo/client'

import customer from './customer'

export default gql`
  ${customer}

  fragment CustomerReference on CustomerReference {
    address {
      id
    }
    contact {
      id
    }
    customer {
      ...Customer
    }
  }
`
