import VisibilitySensor from 'react-visibility-sensor'
import styled, { css } from 'styled-components/macro'

import { ElasticSales } from '@/modules/Sales/types'

import { SalesPaymentInfoQuery } from '~generated-types'

import { Customer, Dates, Name } from './components'

type GraphQLSales = SalesPaymentInfoQuery['salesAll'][0]

type Props = {
  gqlItem: GraphQLSales | null
  item: ElasticSales
  onClick: () => void
}

export const SalesListItem = ({ gqlItem, item, onClick }: Props) => (
  <VisibilitySensor
    onChange={() => undefined}
    partialVisibility
    offset={{ bottom: -350, top: -350 }}
  >
    {({ isVisible }) =>
      isVisible ? (
        <Wrapper onClick={onClick}>
          <Name item={item} />

          <Dates item={item} />

          <Customer gqlItem={gqlItem} item={item} />
        </Wrapper>
      ) : (
        <Placeholder />
      )
    }
  </VisibilitySensor>
)

////////////

const Placeholder = styled.div`
  height: 100px;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled.button`
  display: flex;
  align-items: unset;
  border-radius: 10px;
  min-height: 100px;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    font-size: ${theme.typography.fontSizeSmall};
    padding: ${theme.spacing.gu(2)}rem;
    margin: 0 ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(2)}rem;
  `}
`
