import { gql } from '@apollo/client'

export default gql`
  mutation CreateCustomerOrganization(
    $input: CustomerOrganizationCreateInput!
  ) {
    customerOrganizationCreate(input: $input) {
      customer {
        customerNumber
        defaultAddressId
        defaultContactId
        id
      }
    }
  }
`
