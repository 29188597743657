import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

import type {
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from '~generated-types'

const UPDATE_DOCUMENT_MUTATION = gql`
  ${orderFragments.document}

  mutation UpdateDocument($input: DocumentUpdateInput!) {
    documentUpdate(input: $input) {
      ...Document
    }
  }
`

export const useUpdateDocumentMutation = () =>
  useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(
    UPDATE_DOCUMENT_MUTATION
  )
