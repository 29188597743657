import styled, { css } from 'styled-components/macro'

export const InlineModalSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 0;
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`
