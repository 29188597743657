import { Fragment, Ref, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { ActionButton } from '@/modules/Order/components'
import { orderServices } from '@/modules/Order/services'
import { InvoiceByIdPayload, InvoiceSellerProduct } from '@/modules/Order/types'
import { formatCurrency } from '@/utils/currency'

import {
  InvoiceAction as IA,
  OrderPacketSourceType as OPST,
} from '~generated-types'

type Props = {
  invoiceId: string
  products: InvoiceSellerProduct[]
  refetch: () => Promise<ApolloQueryResult<InvoiceByIdPayload>>
  salesId: string
  type: OPST
}

export const OrderItemSelector = ({
  invoiceId,
  products,
  refetch,
  salesId,
  type,
}: Props) => {
  const { addPurchaseFromProduct } = orderServices.invoiceService()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const handleAddPurchase = (productTitleId: string) => {
    setProcessing(true)

    addPurchaseFromProduct({
      add: { link: { salesId } },
      invoice: { invoiceId, serviceFee: type === OPST.ServiceFee },
      productTitleId,
    })
      .then(refetch)
      .then(() => {
        setProcessing(false)
        setModalOpen(false)
      })
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <Modal>
          {products.map(
            ({ catalog, id, name, quantityUnit, unitPrice, vatRate }, idx) => (
              <Fragment key={id}>
                {idx !== 0 && <Separator />}

                <Option
                  justifyContent="space-between"
                  noPadding
                  onClick={() => handleAddPurchase(id)}
                >
                  <FontWeight semiBold>{name}</FontWeight>
                  <FontWeight light style={{ whiteSpace: 'pre' }}>
                    {catalog.name}
                    {'  •  '}
                    {vatRate}%{'  •  '}
                    {formatCurrency(unitPrice)}€{' / '}
                    <T l10n={`enums:quantityUnit.abbrev.${quantityUnit}`} />
                  </FontWeight>
                </Option>
              </Fragment>
            )
          )}
        </Modal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <ActionButton
          action={type === OPST.ServiceFee ? 'ADD_SERVICE_FEE' : IA.UpdateLines}
          innerRef={ref as Ref<HTMLButtonElement>}
          isProcessing={isProcessing}
          label={
            <T>{`Orders:OrderItems.${
              type === OPST.ServiceFee ? 'addServiceFee' : 'addManualProduct'
            }`}</T>
          }
          onClick={() => setModalOpen(true)}
        />
      )}
    />
  )
}

//////

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Option = styled(FlexColumn)`
  border-radius: 4px;
  cursor: pointer;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(6.5)}rem;
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const Separator = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`
