import { gql, useMutation } from '@apollo/client'

import {
  UpdateAccommodationTargetMutation,
  UpdateAccommodationTargetMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation UpdateAccommodationTarget($input: AccommodationTargetUpdateInput!) {
    accommodationTargetUpdate(input: $input) {
      target {
        id
        nonBlockingCapacity
      }
    }
  }
`

export const useUpdateAccommodationTargetMutation = () =>
  useMutation<
    UpdateAccommodationTargetMutation,
    UpdateAccommodationTargetMutationVariables
  >(MUTATION)
