import { gql } from '@apollo/client'

import { productFragments } from '@/modules/Products/fragments'

export const SalesResourceReservationPurchaseFragment = gql`
  ${productFragments.productPurchaseStatus}

  fragment SalesResourceReservationPurchase on PurchaseProduct {
    auditLog {
      createdAt
    }
    id
    product {
      id
      name
    }
    status {
      ...ProductPurchaseStatus
    }
    totalPrice {
      amount
      amountVatIncluded
      vatAmount
    }
  }
`

export default SalesResourceReservationPurchaseFragment
