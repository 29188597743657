import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Action, SectionSeparator } from '@/modules/Order/components'
import { orderContexts } from '@/modules/Order/contexts'
import { orderServices } from '@/modules/Order/services'
import { OrderValidatedAction } from '@/modules/Order/types'

import { OrderAction as OA } from '~generated-types'

type Props = {
  actions: OrderValidatedAction[]
  noPhases: boolean
  orderId: string
}

export const OtherPhaseActions = ({ actions, noPhases, orderId }: Props) => {
  const { setSelectedPhaseId } = orderContexts.useOrderView()
  const { triggerCreateAction } = orderServices.orderService()

  const [processingTarget, setProcessingTarget] = useState<OA | null>(null)

  const createConfirmationAction = actions.find(
    (a) => a.action === OA.CreateConfirmation
  )
  const createOfferAction = actions.find((a) => a.action === OA.CreateOffer)

  const isConfirmationValid = createConfirmationAction?.valid
  const isOfferValid = createOfferAction?.valid

  const hideActionsRow = !noPhases && !isConfirmationValid && !isOfferValid

  const handleCreate = (action: OA) => {
    setProcessingTarget(action)

    triggerCreateAction(orderId, action).then((data) => {
      data && setSelectedPhaseId(data.id)
      setProcessingTarget(null)
    })
  }

  if (hideActionsRow) {
    return null
  }

  return (
    <Wrapper withBorder={!noPhases}>
      {isConfirmationValid || isOfferValid ? (
        <>
          {createOfferAction && isOfferValid && (
            <Action
              isProcessing={processingTarget === OA.CreateOffer}
              onClick={() => handleCreate(OA.CreateOffer)}
              phase="ORDER"
              validatedAction={createOfferAction}
            />
          )}

          {isOfferValid && isConfirmationValid && <Separator light />}

          {createConfirmationAction && isConfirmationValid && (
            <Action
              isProcessing={processingTarget === OA.CreateConfirmation}
              onClick={() => handleCreate(OA.CreateConfirmation)}
              phase="ORDER"
              validatedAction={createConfirmationAction}
            />
          )}
        </>
      ) : (
        <Placeholder>
          <T>Orders:OrdersForOwner.emptyOfferAndConfirmations</T>
        </Placeholder>
      )}
    </Wrapper>
  )
}

/////

const Placeholder = styled.span`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled(SectionSeparator)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexRow)<{ withBorder: boolean }>`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ theme, withBorder }) =>
    withBorder &&
    css`
      border-top: 1px solid ${theme.palette.smoke.main};
    `}
`
