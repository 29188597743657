import React, { useRef } from 'react'

import { AllItemData, useGridContext } from '../GridContext'
import { ensureInteger } from '../helpers'
import {
  ColumnDetails,
  RowDetails,
  usePresentationState,
} from '../PresentationState'
import { CustomDragLayer } from './CustomDragLayer'
import { DropContainer } from './DropContainer'

type Props = {
  columns: ColumnDetails[]
  gridId: string
  items: AllItemData[]
  rows: RowDetails[]
}

export const GridSection = ({ columns, gridId, items, rows }: Props) => {
  const gridEl = useRef(null)
  const { columnWidth, onDrop, rowHeight, snapToGridCellCenter } =
    usePresentationState()
  const {
    getItemData,
    setItemPosition,
    getColumnDataByIndex,
    getRowDataByIndex,
  } = useGridContext()

  return (
    <div
      style={{
        display: 'inline-flex',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
      }}
    >
      <DropContainer
        rows={rows}
        columns={columns}
        positionItems={items}
        gridRef={gridEl}
        gridId={gridId}
        snapToGrid={snapToGridCellCenter}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
        onDrop={(item: any, topOffset: any, leftOffset: any) => {
          const startRowIndex = ensureInteger(topOffset / rowHeight)
          const overlapsWithRowsCount = item.rowIndexes.length
          const overlapsWithRowIndexes = Array.from(
            { length: overlapsWithRowsCount },
            (x, i) => i + startRowIndex
          )

          const startColumnIndex = ensureInteger(leftOffset / columnWidth)
          const overlapsWithColumnsCount = item.columnIndexes.length
          const overlapsWithColumnIndexes = Array.from(
            { length: overlapsWithColumnsCount },
            (x, i) => i + startColumnIndex
          )

          const itemData = getItemData(item.id)
          const columns = overlapsWithColumnIndexes
            .map((index) => getColumnDataByIndex(index))
            .filter(Boolean)
          const rows = overlapsWithRowIndexes
            .map((rowIndex) => getRowDataByIndex(gridId, rowIndex))
            .filter(Boolean)

          const actionData: any = { columns, gridId, item: itemData, rows }

          // Optimisticly set item position
          setItemPosition(
            gridId,
            item.id,
            overlapsWithRowIndexes,
            overlapsWithColumnIndexes
          )

          onDrop(actionData)
        }}
      />
      {/* @ts-ignore */}
      <CustomDragLayer gridId={gridId} />
    </div>
  )
}
