import { CSSProperties, useState } from 'react'
import { useQuery } from '@apollo/client'

import { EditButton } from '@/components/ExtraButtons'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { Spacing, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { WebshopRoomsQuery } from '~generated-types'

import { usePublishContext } from '../../../PublishState'
import { publishQueries } from '../../../queries'
import { PublicRoom } from '../../../types'
import { ContentPlaceholder, Section, TruncateContent } from '../../common'

type Props = {
  publicRoom: PublicRoom | null
  targetId: string
}

export const WebshopRoomSelector = ({ publicRoom, targetId }: Props) => {
  const { spacing } = useTheme()
  const { readOnly, updatePublicRoom } = usePublishContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const { data, loading } = useQuery<WebshopRoomsQuery>(
    publishQueries.webshopRooms,
    {
      skip: !isEditMode,
    }
  )

  const options = [...(data?.registry.webshopRooms ?? [])]
    .sort(generateCompareFn('internalName'))
    .map((room) => ({
      label: room.internalName,
      value: room.id,
    }))

  const handleUpdatePublicRoom = (option?: Option | null) => {
    if (option) {
      updatePublicRoom({
        roomId: publicRoom?.id,
        targetId,
        update: { roomMarketingId: option.value },
      }).finally(() => setEditMode(false))
    }
  }

  const webshopRoom = publicRoom?.roomMarketing

  return (
    <Section flex={1.75}>
      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={loading}
          isDisabled={readOnly}
          menuIsOpen={isEditMode && !loading}
          name="webshop-room-selector"
          noOptionsMessage={() => (
            <T>Publish:Rooms.webshopRoom.noRoomsAvailable</T>
          )}
          onChange={handleUpdatePublicRoom}
          onBlur={() => setEditMode(false)}
          options={options}
          placeholder={<T>Publish:Rooms.webshopRoom.noRoomsSelected</T>}
          value={
            webshopRoom
              ? { label: webshopRoom.internalName, value: webshopRoom.id }
              : null
          }
        />
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          style={{ flex: 'unset' }}
        >
          <TruncateContent
            content={
              webshopRoom?.internalName || (
                <ContentPlaceholder>
                  <T>Publish:Rooms.webshopRoom.noRoomsSelected</T>
                </ContentPlaceholder>
              )
            }
          />
        </EditButton>
      )}
    </Section>
  )
}

///////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
})
