import { CSSProperties } from 'react'
import styled from 'styled-components/macro'

type Props = {
  extraLight?: boolean
  light?: boolean
  normal?: boolean
  semiBold?: boolean
  bold?: boolean
  extraBold?: boolean
  black?: boolean
  highlight?: boolean
  style?: CSSProperties
}

const findBoldness = ({
  extraLight,
  light,
  normal,
  semiBold,
  bold,
  extraBold,
  black,
}: Props): number => {
  if (extraLight) return 200
  if (light) return 300
  if (normal) return 400
  if (semiBold) return 500
  if (bold) return 600
  if (extraBold) return 700
  if (black) return 800
  return 500
}

// The most common usecase for this component is probably for bolding so it's
// set as the default option.
const FontWeight = styled.span<Props>`
  font-weight: ${(props) => findBoldness(props)};
  color: ${(props) =>
    props.highlight ? props.theme.palette.text.dark : 'inherit'};
`

export default FontWeight
