import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  isSelected?: boolean
  isDisabled?: boolean
  onClick?: (newValue: boolean) => void
}

export const CheckBox = ({ isSelected, isDisabled, onClick }: Props) => {
  const { palette } = useTheme()

  const handleClick = () => {
    if (isDisabled) {
      return
    }

    onClick && onClick(!isSelected)
  }

  return (
    <FlexRow alignItems="center">
      <CheckBoxContainer isDisabled={isDisabled} onClick={handleClick}>
        {isSelected && (
          <FontAwesomeIcon
            color={palette.primary.main}
            fixedWidth
            size="sm"
            icon={'check'}
          />
        )}
      </CheckBoxContainer>
    </FlexRow>
  )
}

const CheckBoxContainer = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}

  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  ${({ isDisabled, theme }) =>
    isDisabled
      ? `background-color: ${theme.palette.smoke.main}`
      : ` &:hover {
    background-color: ${theme.palette.smoke.lighter};
  }`}
`
