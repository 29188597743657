import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { usePublishContext } from '../../../PublishState'

export const AddLinkButton = () => {
  const { readOnly, salesId, addWebshopToken } = usePublishContext()

  return (
    <InnocuousButton
      noNudge
      compact
      onClick={() => addWebshopToken({ salesId })}
      disabled={readOnly}
    >
      <PrimaryColor>
        + <T>Publish:Permissions.link.add</T>
      </PrimaryColor>
    </InnocuousButton>
  )
}
