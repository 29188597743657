import { ComponentType } from 'react'
import styled, { css } from 'styled-components/macro'

export default styled.div<{ width: number }>`
  position: sticky;
  left: 0;
  flex: 0 0 ${({ width }) => (width ? `${width}px` : 'auto')};
  max-width: ${({ width }) => (width ? `${width}px` : 'none')};
  display: flex;
  flex-direction: column;
  z-index: 3000;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.gu(2)}rem;
    background: ${theme.palette.white};
    border-right: 1px solid ${theme.palette.smoke.dark};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 1px;
    right: calc(-1rem - 1px);
    width: 1rem;
    z-index: 100;
    pointer-events: none;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
` as ComponentType<any>
