import * as yup from 'yup'

import { Label, TextInput } from '@/components/FormControls'
import { FormikFormFieldHelper } from '@/components/FormControls'
import {
  FormField,
  FormFieldTuple,
  FormSection,
  FormSectionSubtitle,
} from '@/components/FormWrappers'
import { T } from '@/modules/Language'
import { emailRegex } from '@/utils/forms'

export const organizationDefaultContactSchema = yup
  .object()
  .shape({
    email: yup.string().matches(emailRegex, 'invalid').nullable(),
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    phone: yup.string().nullable(),
  })
  .nullable()

export const OrganizationDefaultContact = () => (
  <>
    <FormSection>
      <FormSectionSubtitle>
        <T>Customers:contact.title</T>
      </FormSectionSubtitle>
      <FormFieldTuple>
        <FormField>
          <Label>
            <T>Customers:contact.firstName</T>*
          </Label>
          <FormikFormFieldHelper
            defaultValue={''}
            errorDescriptions={{
              required: <T>Customers:validation.contact.firstName.required</T>,
            }}
            inputComponent={TextInput}
            name="defaultCustomerContact.firstName"
          />
        </FormField>
        <FormField>
          <Label>
            <T>Customers:contact.lastName</T>*
          </Label>
          <FormikFormFieldHelper
            defaultValue={''}
            errorDescriptions={{
              required: <T>Customers:validation.contact.lastName.required</T>,
            }}
            inputComponent={TextInput}
            name="defaultCustomerContact.lastName"
          />
        </FormField>
      </FormFieldTuple>
      <FormField>
        <Label>
          <T>Customers:contact.email</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          errorDescriptions={{
            invalid: <T>Customers:validation.contact.email.invalid</T>,
          }}
          inputComponent={TextInput}
          name="defaultCustomerContact.email"
        />
      </FormField>
      <FormField>
        <Label>
          <T>Customers:contact.phone</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="defaultCustomerContact.phone"
        />
      </FormField>
    </FormSection>
  </>
)
