import moment from 'moment'

export default function getDateInFormat(date: string, format: string) {
  const momentDate = moment(date)

  if (!momentDate.isValid()) {
    throw new Error('Date string is not moment compliant')
  }

  return moment(date).format(format)
}
