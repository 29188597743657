import { gql, useMutation } from '@apollo/client'

import {
  SetRoomReservationCleaningStatusMutation,
  SetRoomReservationCleaningStatusMutationVariables,
} from '~generated-types'

export type {
  SetRoomReservationCleaningStatusMutation,
  SetRoomReservationCleaningStatusMutationVariables,
}

export const SET_ROOM_RESERVATION_CLEANING_STATUS = gql`
  mutation SetRoomReservationCleaningStatus($input: RoomSetStatusInput!) {
    roomSetStatus(input: $input) {
      id
      newCleaningStatus
    }
  }
`

export function useSetRoomReservationCleaningStatus() {
  return useMutation<
    SetRoomReservationCleaningStatusMutation,
    SetRoomReservationCleaningStatusMutationVariables
  >(SET_ROOM_RESERVATION_CLEANING_STATUS)
}

export default useSetRoomReservationCleaningStatus
