import { gql, useMutation } from '@apollo/client'

import {
  UpdateSalesCommissionMutation,
  UpdateSalesCommissionMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation UpdateSalesCommission($input: SalesUpdateCommissionInput!) {
    salesUpdateCommission(input: $input) {
      commissionRate
      id
    }
  }
`

export const useUpdateSalesCommissionMutation = () =>
  useMutation<
    UpdateSalesCommissionMutation,
    UpdateSalesCommissionMutationVariables
  >(MUTATION)
