import { ReactNode, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow, Gutter } from '@/components/Layout'
import { P } from '@/components/Typography'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { taskHooks } from '@/modules/Task/hooks'
import { useTheme } from '@/theme'

import { useTaskListContext } from '../TasksState'
import { TaskList } from './TaskList'

export const TaskListContainer = () => {
  const { palette, spacing } = useTheme()

  const { error, isLoading, tasks, setTasks } = useTaskListContext()
  const {
    data: { id },
    saleReadOnly: readOnly,
  } = salesHooks.useSalesDetailsContext()
  const { createTask } = taskHooks.useTaskMutations({ onUpdateTasks: setTasks })

  const [processing, setProcessing] = useState<boolean>(false)
  const [targetTaskNumber, setTargetTaskNumber] = useState<number | null>(null)

  const handleCreatetask = () => {
    setProcessing(true)
    createTask(id)
      .then((task) => setTargetTaskNumber(task?.number || null))
      .finally(() => setProcessing(false))
  }

  const renderCreateTaskButton = () => (
    <InnocuousButton
      compact
      disabled={readOnly || !!error || processing}
      onClick={handleCreatetask}
    >
      <PrimaryColor>
        + <T>Tasks:ListControls.create</T>
      </PrimaryColor>
    </InnocuousButton>
  )

  return (
    <>
      {!tasks.length && isLoading && (
        <ReactLoading
          color={palette.smoke.main}
          height={24}
          type="spin"
          width={24}
        />
      )}

      {!tasks.length && !isLoading && !error && (
        <FlexRow
          justifyContent="space-between"
          style={{ margin: `0 ${spacing.gutter} ${spacing.gu(1)}rem 0` }}
        >
          <Placeholder>
            <T>Tasks:SalesTasks.empty</T>
          </Placeholder>
          {renderCreateTaskButton()}
        </FlexRow>
      )}

      {error && (
        <Placeholder>
          <T>Tasks:SalesTasks.error</T>
        </Placeholder>
      )}

      {!isLoading && !!tasks.length && (
        <FlexRow
          justifyContent="flex-end"
          style={{ margin: `0 ${spacing.gutter} ${spacing.gu(1)}rem 0` }}
        >
          {renderCreateTaskButton()}
        </FlexRow>
      )}

      <TaskList
        onUpdateTasks={setTasks}
        readOnly={readOnly}
        setTargetTaskNumber={setTargetTaskNumber}
        targetTaskNumber={targetTaskNumber}
        tasks={tasks}
      />
    </>
  )
}

////////////

type PlaceholderProps = {
  children: ReactNode
}

const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </Gutter>
)

const PlaceholderLabel = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`
