import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  id: string
  notes: string | null
}

export const NotesTextarea = ({ id, notes }: Props) => {
  const { readOnly, updateProduct } = useSalesProductManagerContext()

  const [value, setValue] = useState<string>(notes ?? '')

  const handleUpdateNotes = () => updateProduct({ id, notes: value })

  return (
    <FlexColumn noPadding>
      <Title>
        <T>Products:ProductManager.pricing.description</T>
      </Title>

      <Textarea
        disabled={readOnly}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setValue(e.target.value)
        }
        value={value || ''}
        onBlur={() => !readOnly && handleUpdateNotes()}
      />
    </FlexColumn>
  )
}

//////

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))<any>`
  &&& {
    line-height: 1.2;
    resize: vertical;

    min-height: 69px;
    padding: 8px 10px;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
    `}

    &:disabled {
      cursor: not-allowed;

      ${({ theme }) => css`
        background: ${theme.palette.smoke.extraLight};
      `}
    }
  }
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
