import { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import * as salesMutations from '@/modules/Sales/mutations'
import { SalesAssignersPayload, salesQueries } from '@/modules/Sales/queries'

import { SalesAssigneeFragment as SalesAssignee } from '~generated-types'

import { useSalesDetailsContext } from '../../../../../SalesDetailsState'
import { AssigneeOption } from './AssigneeOption'
import { AssigneeSearchInput } from './AssigneeSearchInput'

type Props = {
  onClose: () => void
}

export const AssigneeModal = ({ onClose }: Props) => {
  const {
    data: { assignee, id: salesId },
    refresh,
  } = useSalesDetailsContext()

  const [searchValue, setSearchValue] = useState<string>('')

  const { data, error } = useQuery<SalesAssignersPayload>(
    salesQueries.SALES_ASSIGNERS
  )

  const [setSalesAssignee] = salesMutations.useSetSalesAssigneeMutation()

  const handleSetSalesAssignee = (assignee: string) => {
    setSalesAssignee({
      variables: {
        input: { assignee, salesId },
      },
    })
      .then(refresh)
      .then(onClose)
  }

  if (!data || error) {
    return null
  }

  const filteredUsers = data.users
    .filter((user) => {
      const fullName = getFullName(user)
      return fullName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    })
    .sort((a, b) => getFullName(a).localeCompare(getFullName(b)))

  return (
    <Modal>
      <AssigneeSearchInput
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <Divider />

      <OptionsWrapper>
        {filteredUsers.length ? (
          filteredUsers.map((user) => (
            <AssigneeOption
              assignee={user}
              isSelected={user.id === assignee?.id}
              key={user.id}
              onClick={() => handleSetSalesAssignee(user.id)}
            />
          ))
        ) : (
          <Placeholder justifyContent="center">
            <T>SalesDetails:assignee.empty</T>
          </Placeholder>
        )}
      </OptionsWrapper>
    </Modal>
  )
}

////////

const getFullName = ({ firstName, lastName }: SalesAssignee) =>
  `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`

const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
  `}
`

const Modal = styled(InlineModal)`
  min-width: 220px;
  overflow: hidden;
`

const OptionsWrapper = styled(FlexColumn)`
  overflow: auto;

  ${({ theme }) => css`
    max-height: ${theme.spacing.gu(39)}rem;
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Placeholder = styled(FlexRow)`
  font-style: italic;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
    color: ${theme.palette.text.lighter};
  `}
`
