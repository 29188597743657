import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import {
  useRoomTypeReservationSetBedQuantity,
  useRoomTypeReservationSetRoomQuantity,
} from '@/modules/Accommodation/SalesReservationManager/mutations'
import { useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { ReservationsForDateQuery as ReservationsForDate } from '~generated-types'

import { RefetchResponce } from '../../../../../AvailabilityCalendar.types'
import { BedsAmount } from './BedsAmount'
import { RoomsAmount } from './RoomsAmount'

type Reservation =
  ReservationsForDate['accommodationRoomTypeReservations']['reservations'][0]

type Props = {
  reservations: Reservation[]
  refetchAvailability: () => Promise<RefetchResponce>
}

export const RoomTypeReservations = ({
  reservations,
  refetchAvailability,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { checkIconValidity } = useGlobalIconsContext()

  const [updateRooms] = useRoomTypeReservationSetRoomQuantity()
  const [updateBeds] = useRoomTypeReservationSetBedQuantity()

  const handleUpdateRooms = (id: string, rooms: number) =>
    updateRooms({ variables: { input: { id, rooms } } }).then(() => {
      refetchAvailability()
    })

  const handleUpdateBeds = (id: string, beds: number, extraBeds: number) =>
    updateBeds({ variables: { input: { beds, extraBeds, id } } }).then(() => {
      refetchAvailability()
    })

  return (
    <>
      {reservations.map(({ id, request }) => (
        <ReservationWrapper key={id}>
          <DateWrapper>
            <CheckTypeWrapper style={{ marginRight: `${spacing.gu(1)}rem` }}>
              {request.checkIn.type === 'EARLY' && (
                <FontAwesomeIcon
                  color={palette.primary.main}
                  size="sm"
                  icon="sun"
                />
              )}
            </CheckTypeWrapper>

            {moment(request.checkIn.date).format('dd DD.MM.')}
            {' – '}
            {moment(request.checkOut.date).format('dd DD.MM.YYYY')}

            <CheckTypeWrapper style={{ marginLeft: `${spacing.gu(1)}rem` }}>
              {request.checkOut.type === 'LATE' && (
                <FontAwesomeIcon
                  color={palette.primary.main}
                  icon="moon"
                  size="sm"
                />
              )}
            </CheckTypeWrapper>
          </DateWrapper>

          <RoomsAmount
            rooms={request.rooms}
            changeAmount={(rooms) => handleUpdateRooms(id, rooms)}
          />

          <BedsAmount
            usedBeds={request.beds}
            totalBeds={(request.roomType.beds || 0) * request.rooms}
            usedExtraBeds={request.extraBeds}
            totalExtraBeds={(request.roomType.extraBeds || 0) * request.rooms}
            changeBedsAmount={(beds, extraBeds) =>
              handleUpdateBeds(id, beds, extraBeds)
            }
          />

          <FlexRow>
            {[...request.features]
              .sort(generateCompareFn('name'))
              .map((feature) => (
                <Tooltip
                  key={feature.id}
                  content={feature.name}
                  trigger={(triggerProps) => (
                    <div
                      {...triggerProps}
                      style={{ marginRight: spacing.gutterSmall }}
                    >
                      {checkIconValidity(feature.icon) ? (
                        <FontAwesomeIcon
                          color={palette.coal.main}
                          icon={feature.icon as IconProp}
                          size="1x"
                        />
                      ) : (
                        feature.shortName
                      )}
                    </div>
                  )}
                />
              ))}
          </FlexRow>
        </ReservationWrapper>
      ))}
    </>
  )
}

/////////

const DateWrapper = styled.div`
  display: flex;
  width: 15rem;
  text-align: center;
`

const ReservationWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
  `}

  width: 100%;
  transition: 0.2s;
  height: 100%;
  align-items: center;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`

const CheckTypeWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gutter};
  `}
`
