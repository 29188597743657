import { useTheme } from '@/theme'

type Props = {
  hasPrimaryNote: boolean
  hasSecondaryNote: boolean
}

export const NotesIcon = ({ hasPrimaryNote, hasSecondaryNote }: Props) => {
  const { palette } = useTheme()

  return (
    <svg width={24} height={24} viewBox="0 0 21 21">
      <path
        d="M8,3h8c0.6,0,1,0.4,1,1v10c0,0.6-0.4,1-1,1h-5.7L7,11.5V4C7,3.4,7.4,3,8,3z"
        fill={palette.white}
      />
      <path
        d="M10.3,15H16c0.6,0,1-0.4,1-1V4c0-0.6-0.4-1-1-1H8C7.4,3,7,3.4,7,4v7.5 M10.3,15v-2.5c0-0.6-0.4-1-1-1H7 M10.3,15L7,11.5"
        fill={hasSecondaryNote ? palette.primary.extraLight : palette.white}
        stroke={palette.text.light}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M5,6h8c0.6,0,1,0.4,1,1v10c0,0.6-0.4,1-1,1H7.3L4,14.5V7C4,6.4,4.4,6,5,6z"
        fill={palette.white}
      />
      <path
        d="M7.3,18H13c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1H5C4.4,6,4,6.4,4,7v7.5 M7.3,18v-2.5c0-0.6-0.4-1-1-1H4 M7.3,18
          L4,14.5"
        fill={hasPrimaryNote ? palette.primary.extraLight : palette.white}
        stroke={palette.text.light}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  )
}
