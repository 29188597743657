import { gql, useMutation } from '@apollo/client'

import {
  RemoveResourceReservationGroupMutation,
  RemoveResourceReservationGroupMutationVariables,
} from '~generated-types'

export type {
  RemoveResourceReservationGroupMutation,
  RemoveResourceReservationGroupMutationVariables,
}

export const REMOVE_RESOURCE_RESERVATION_GROUP_MUTATION = gql`
  mutation RemoveResourceReservationGroup(
    $input: ResourceReservationDeleteGroupInput!
  ) {
    resourceReservationDeleteGroup(input: $input) {
      reservationGroupId
      deleted
    }
  }
`

export function useRemoveResourceReservationGroupMutation() {
  return useMutation<
    RemoveResourceReservationGroupMutation,
    RemoveResourceReservationGroupMutationVariables
  >(REMOVE_RESOURCE_RESERVATION_GROUP_MUTATION)
}

export default useRemoveResourceReservationGroupMutation
