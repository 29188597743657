import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLazyQuery } from '@apollo/client'

import { FetchState, FetchStates } from '@/common/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useCreateEnrollmentMutation } from '@/modules/Sales/mutations'
import { salesQueries } from '@/modules/Sales/queries'
import { ACTIVE_STATES, EventEnrollment } from '@/modules/Sales/types'
import { generateCompareFn } from '@/utils/arrays'

import { SalesSource } from '~generated-types'

export type Pagination = {
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  totalElements: number
  totalPages: number
}

type EnrollmentsContextType = {
  // Data
  activeStates: string[] | null
  enrollments: EventEnrollment[]
  pageSize: number
  pagination: Pagination
  fetchState: FetchState
  inEnrollmentsContext: boolean

  // Methods
  goToPage: (
    page: number,
    pageSize: number,
    enrollmentStates?: string[]
  ) => void
  handleSetActiveStates: (states: string[]) => void
  addEnrollment: () => Promise<string | undefined>
  refresh: () => Promise<any>
  setPageSize: (pageSize: number) => void
}

const EnrollmentsContext = createContext<EnrollmentsContextType>({
  activeStates: null,
  addEnrollment: Promise.reject,
  enrollments: [],
  fetchState: FetchStates.ERROR,
  goToPage: () => undefined,
  handleSetActiveStates: () => undefined,
  inEnrollmentsContext: false,
  pageSize: 5,
  pagination: {
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  },
  refresh: Promise.reject,
  setPageSize: () => undefined,
})

type ProviderProps = {
  children: ReactNode
  eventId: string
}

export const EnrollmentsContextProvider = ({
  children,
  eventId,
}: ProviderProps) => {
  const contextValueRef = useRef<EnrollmentsContextType | null | undefined>(
    null
  )

  const { salesStates } = salesHooks.useSalesStates()

  const [fetchState, setFetchState] = useState<FetchState>(FetchStates.LOADING)
  const [enrollments, setEnrollments] = useState<EventEnrollment[]>([])
  const [pageSize, setPageSize] = useState<number>(20)
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  })
  const [activeStates, setActiveStates] = useState<string[] | null>(null)

  const [createEnrollment] = useCreateEnrollmentMutation()

  const [loadEnrollments, { data, error, loading, refetch }] = useLazyQuery(
    salesQueries.EVENT_ENROLLMENTS,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (salesStates) {
      setActiveStates(
        salesStates
          .filter(({ systemState }) => ACTIVE_STATES.includes(systemState))
          .map(({ key }) => key)
      )
    }
  }, [JSON.stringify(salesStates)])

  useEffect(() => {
    if (activeStates) {
      goToPage(0, pageSize)
    }
  }, [JSON.stringify(activeStates)])

  useEffect(() => {
    data?.sales?.enrollments &&
      setEnrollments(
        [...data.sales.enrollments.nodes]
          .sort(generateCompareFn('orderNumber'))
          .map((enrollment: EventEnrollment) => ({
            ...enrollment,
            eventId: data.sales.id,
          }))
      )

    if (data?.sales?.enrollments) {
      const { totalPages, totalElements, hasNextPage, hasPreviousPage } =
        data.sales.enrollments

      setPagination((pagination) => ({
        currentPage: pagination.currentPage,
        hasNextPage,
        hasPreviousPage,
        totalElements,
        totalPages,
      }))
    }

    data?.sales && setFetchState(FetchStates.IDLE)
    error && setFetchState(FetchStates.ERROR)
  }, [data, error, loading, pageSize])

  const refresh = () => refetch()

  const handleSetActiveStates = (states: string[]) => {
    setActiveStates(states)
    goToPage(0, pageSize, states)
  }

  const goToPage = (
    page: number,
    pageSize: number,
    enrollmentStates?: string[]
  ) => {
    loadEnrollments({
      variables: {
        id: eventId,
        input: {
          enrollmentStates: enrollmentStates || activeStates,
          page: {
            page,
            size: pageSize,
          },
        },
      },
    })
    setPagination((pagination) => ({
      ...pagination,
      currentPage: page,
    }))
  }

  const addEnrollment = () =>
    createEnrollment({
      variables: {
        enrollmentsInput: {
          enrollmentStates: activeStates,
          page: {
            page: 0,
            size: pageSize,
          },
        },
        input: {
          eventId,
          name: '',
          source: SalesSource.Admin,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          setEnrollments((current) => [...current, data.salesCreateEnrollment])

          const totalPages =
            data.salesCreateEnrollment.event?.enrollments?.totalPages || 1

          const lastPage = totalPages - 1

          pagination.currentPage !== lastPage && goToPage(lastPage, pageSize)

          setPagination((pagination) => ({
            ...pagination,
            currentPage: lastPage,
            totalPages,
          }))

          return data.salesCreateEnrollment.id
        }

        return undefined
      })
      .catch(() => undefined)

  contextValueRef.current = {
    activeStates,
    addEnrollment,
    enrollments,
    fetchState,
    goToPage,
    handleSetActiveStates,
    inEnrollmentsContext: true,
    pageSize,
    pagination,
    refresh,
    setPageSize,
  }

  return (
    <EnrollmentsContext.Provider value={contextValueRef.current}>
      {children}
    </EnrollmentsContext.Provider>
  )
}

export const useEnrollmentsContext = () => useContext(EnrollmentsContext)
