import { useQuery } from '@apollo/client'

import type {
  SalesProductsQuery as QueryData,
  SalesProductsQueryVariables as QueryVariables,
} from '~generated-types'

import { productQueries } from '../queries'
import { SalesProduct } from '../types'

type Params = {
  salesId: string
}

export const useSalesProducts = ({ salesId }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    productQueries.GET_SALES_PRODUCTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: salesId },
    }
  )

  const products: SalesProduct[] = data?.sales.products || []

  return {
    error,
    loading,
    products,
    refetch,
  }
}
