import styled, { css } from 'styled-components/macro'

export const Placeholder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
