import { ComponentType } from 'react'
import styled, { css } from 'styled-components/macro'

export default styled.button<{ color?: string }>`
  display: flex;
  background: transparent;
  border: none;
  color: ${({ color, theme }) => (color ? color : theme.palette.text.lighter)};
  padding: 0;
  outline: none;
  cursor: pointer;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.guPx(1) - 4}px;
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}
  }
` as ComponentType<any>
