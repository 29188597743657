import * as yup from 'yup'

export const postalAddressYupSchema = yup.object().shape({
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  postcode: yup.string().nullable(),
})

export const postalAddressInitialValues = {
  address1: '',
  address2: '',
  city: '',
  country: null,
  postcode: '',
}
