import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { useDialogService } from '@/components/DialogService'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import type { Theme } from '@/theme'

import { PendingRoomReservation } from '../SalesReservationManager.types'
import { LineLeader } from './LineLeader'
import { ReservationRoomSelector } from './ReservationRoomSelector'

type ReservationType =
  | 'NESTED_ROOM_RESERVATION'
  | 'PENDING_ROOM_RESERVATION'
  | 'PENDING_ROOM_TYPE_RESERVATION'
  | 'ROOM_RESERVATION'
  | 'ROOM_TYPE_RESERVATION'

type Props = {
  children: ReactNode
  confirmRemoval?: boolean
  isLast?: boolean
  onRemove?: () => void
  setOpenSections?: (arg0: { [sectionId: string]: boolean }) => void
  pendingReservations?: PendingRoomReservation[]
  changeReservationRoom?: (roomId: string, roomNumber: string) => Promise<void>
  title: ReactNode
  type: ReservationType
  focused?: boolean
}

export const ReservationCard = ({
  children,
  confirmRemoval,
  isLast,
  onRemove,
  setOpenSections,
  pendingReservations,
  changeReservationRoom,
  type,
  title,
  focused,
}: Props) => {
  const { confirm } = useDialogService()

  const handleRemove = () => {
    if (!confirmRemoval) {
      onRemove && onRemove()
    } else {
      confirm({
        cancelLabel: <T>common:action.cancel</T>,
        confirmLabel: <T>common:action.remove</T>,
        description: (
          <T>Accommodation:SalesReservationManager.confirmRemoval</T>
        ),
      })
        .then(() => onRemove && onRemove())
        .catch(() => undefined)
    }
  }

  return (
    <FlexRow style={{ width: '100%' }}>
      {type === 'NESTED_ROOM_RESERVATION' && (
        <LineLeader fullHeight={!isLast} />
      )}
      <CardWrapper focused={focused}>
        <Header alignItems="center" justifyContent="space-between" type={type}>
          <span style={{ flex: 1 }}>{title}</span>
          {changeReservationRoom && pendingReservations && (
            <ReservationRoomSelector
              pendingReservations={pendingReservations}
              handleChangeReservationRoom={changeReservationRoom}
            />
          )}
          {setOpenSections && (
            <IconButton
              color="transparent"
              onClick={setOpenSections}
              size="small"
            >
              <FontAwesomeIcon icon={['far', 'eye']} size="lg" />
            </IconButton>
          )}
          {onRemove && (
            <IconButton
              color="transparent"
              onClick={() => handleRemove()}
              size="small"
            >
              <FontAwesomeIcon icon="xmark" />
            </IconButton>
          )}
        </Header>
        {children}
      </CardWrapper>
    </FlexRow>
  )
}

////////////

const getReservationColor = (type: ReservationType, theme: Theme) => {
  switch (type) {
    case 'PENDING_ROOM_RESERVATION':
    case 'PENDING_ROOM_TYPE_RESERVATION':
      // Light green background color for pending reservations
      return '#f3fbf2'
    default:
      return theme.palette.primary.extraLighter
  }
}

const CardWrapper = styled.div<{ focused?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    border-radius: ${theme.spacing.guPx(1)}px;
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  border: solid
    ${({ focused, theme }) =>
    focused
      ? `2px ${theme.palette.primary.light}`
      : `1px ${theme.palette.smoke.dark}`};
  overflow: auto;
`

const Header = styled(FlexRow)<{ type: ReservationType }>`
  background: ${({ theme, type }) => getReservationColor(type, theme)};

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const IconButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`
