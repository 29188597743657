import { T } from '@/modules/Language'

import { MobileSalesRoomReservation } from '../../../../../../types'
import { Features } from '../../../Accommodation/components'
import { Info, InfoTitle, InfoWrapper } from '../../../components'

type Props = {
  roomReservation: MobileSalesRoomReservation
}

export const RoomDetails = ({
  roomReservation: {
    request: {
      features,
      info,
      room: { internalInfo },
    },
  },
}: Props) => (
  <>
    {!!info && (
      <InfoWrapper>
        <InfoTitle>
          <T>SalesDetails:PrintSale.label.RESERVATION_NOTES</T>
        </InfoTitle>

        <Info>{info}</Info>
      </InfoWrapper>
    )}

    {!!features.length && (
      <InfoWrapper>
        <InfoTitle>
          <T>SalesDetails:PrintSale.label.FEATURES</T>
        </InfoTitle>

        <Info>
          <Features features={features} />
        </Info>
      </InfoWrapper>
    )}

    {!!internalInfo && (
      <InfoWrapper>
        <InfoTitle>
          <T>SalesDetails:PrintSale.label.ROOM_INFO</T>
        </InfoTitle>

        <Info>{internalInfo}</Info>
      </InfoWrapper>
    )}
  </>
)
