import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { DialogServiceProvider } from '@/components/DialogService'
import { ErrorBoundary } from '@/components/Errors'
import { NotificationService } from '@/components/NotificationService'
import { WillbaApolloProvider } from '@/config/graphql'
import { KeycloakProvider } from '@/config/keycloak'
import { WillbaConfigContextProvider } from '@/config/WillbaConfigContext'
import { App } from '@/modules/App'
import { AuthProvider } from '@/modules/Auth'
import { LanguageContextProvider } from '@/modules/Language'
import { LastUsedSalesContextProvider } from '@/modules/Sales/contexts'
import {
  GlobalIconsContextProvider,
  GlobalStyles,
  WillbaThemeProvider,
} from '@/theme'

import './config'

const Root = () => (
  <KeycloakProvider>
    <WillbaApolloProvider>
      <WillbaConfigContextProvider>
        <WillbaThemeProvider>
          <NotificationService />
          <GlobalStyles />
          <GlobalIconsContextProvider>
            <ErrorBoundary>
              <DialogServiceProvider>
                <LanguageContextProvider>
                  <AuthProvider>
                    <DndProvider backend={HTML5Backend}>
                      <Router>
                        <LastUsedSalesContextProvider>
                          <App />
                        </LastUsedSalesContextProvider>
                      </Router>
                    </DndProvider>
                  </AuthProvider>
                </LanguageContextProvider>
              </DialogServiceProvider>
            </ErrorBoundary>
          </GlobalIconsContextProvider>
        </WillbaThemeProvider>
      </WillbaConfigContextProvider>
    </WillbaApolloProvider>
  </KeycloakProvider>
)

ReactDOM.render(<Root />, document.getElementById('root') as any)
