import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'

import { DropdownButton } from '@/components/ExtraButtons'
import { notify } from '@/components/NotificationService'
import { T } from '@/modules/Language'
import { PrintSize } from '@/modules/Listing/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import generateKeycardExcel from './generateKeycardExcel'
import { PrintableArrivalsList } from './PrintableArrivalsList'
import { PrintableDeparturesList } from './PrintableDeparturesList'

export interface PrintButtonProps {
  targetDate: Moment
}

export const PrintButton = ({ targetDate }: PrintButtonProps) => {
  const { spacing } = useTheme()
  const { salesStates } = salesHooks.useSalesStates()

  const client = useApolloClient()

  const [processingExcel, setProcessingExcel] = useState(false)
  const [showArrivingPrintList, setShowArrivingPrintList] = useState(false)
  const [showDepartingPrintList, setShowDepartingPrintList] = useState(false)

  const handleDownloadKeycardExcel = () => {
    if (salesStates) {
      setProcessingExcel(true)

      generateKeycardExcel({ client, salesStates, targetDate })
        .catch((err) => {
          console.error('Keycard PDF Generation Error', err)
          notify({ content: <T>common:error.common</T>, type: 'ERROR' })
        })
        .finally(() => setProcessingExcel(false))
    }
  }

  const options = [
    {
      label: (
        <>
          <FontAwesomeIcon
            fixedWidth
            icon="plane-arrival"
            size="sm"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>FrontDesk:DailyCustomerList.print.arrivals</T>
        </>
      ),
      onClick: () => setShowArrivingPrintList(true),
    },
    {
      label: (
        <>
          <FontAwesomeIcon
            fixedWidth
            icon="plane-departure"
            size="sm"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>FrontDesk:DailyCustomerList.print.departing</T>
        </>
      ),
      onClick: () => setShowDepartingPrintList(true),
    },
    {
      disabled: processingExcel,
      label: (
        <>
          <FontAwesomeIcon
            fixedWidth
            icon={['far', 'file-excel']}
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>FrontDesk:DailyCustomerList.print.keycards</T>
        </>
      ),
      onClick: handleDownloadKeycardExcel,
    },
  ]

  return (
    <>
      <DropdownButton options={options}>
        <FontAwesomeIcon
          icon="print"
          style={{ marginRight: `${spacing.gu(0.5)}rem` }}
        />
        <T>FrontDesk:DailyCustomerList.print.title</T>
      </DropdownButton>
      {showArrivingPrintList && (
        <PrintableArrivalsList
          onClose={() => {
            setShowArrivingPrintList(false)
          }}
          printSize={PrintSize.Default}
          targetDate={targetDate}
        />
      )}
      {showDepartingPrintList && (
        <PrintableDeparturesList
          onClose={() => {
            setShowDepartingPrintList(false)
          }}
          printSize={PrintSize.Default}
          targetDate={targetDate}
        />
      )}
    </>
  )
}
