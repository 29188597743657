import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components/macro'

interface Props {
  children?: ReactNode
  noCross?: boolean
  onClick: (...args: Array<any>) => any
  style?: CSSProperties
}

export const SelectedFiltersButton = ({
  children,
  noCross,
  onClick,
  style,
}: Props) => (
  <StyledButton onClick={onClick} style={style}>
    <span>{children}</span>
    {!noCross && <span>✕</span>}
  </StyledButton>
)

export default SelectedFiltersButton

////////////

const StyledButton = styled.button`
  border: 1px solid #c8ccd0;
  border-radius: 6px;
  color: #282831;
  font-family: 'proxima-nova', sans-serif;
  font-size: 0.875rem;
  margin: 2px 3px;
  padding: 5px 8px;
  font-size: 0.85rem;
  position: relative;
  display: inline-flex;
  height: 30px;

  & > span {
    line-height: 1.2rem;
  }

  & > span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 26px;
  }
  & > span:first-child:last-child {
    margin-right: 2px;
  }
  & > span:last-child:not(:first-child) {
    display: flex;
    height: 100%;
    top: 0;
    right: 8px;
    position: absolute;
    align-items: center;
    border-left: 1px solid #c8ccd0;
    padding-left: 8px;
    margin-left: 8px;
  }
`
