import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import MassUpdateRow from './MassUpdateRow'

type Props = {
  selectedIds: string[]
  isAllSelected: boolean
  selectAll: (selectAll: boolean) => void
}

const MassUpdateContainer = ({
  selectedIds,
  isAllSelected,
  selectAll,
}: Props) => {
  const { palette, typography } = useTheme()

  return (
    <>
      <HorizontalDivider />
      <FlexRow alignItems="center" style={{ marginBottom: '0.5rem' }}>
        <IconWrapper>
          <FontAwesomeIcon icon="info" color={palette.white} />
        </IconWrapper>
        <FontWeight semiBold style={{ fontSize: typography.fontSizeBase }}>
          <T>ParticipantsList:MassUpdate.message</T>
        </FontWeight>
      </FlexRow>
      <MassUpdateRow
        selectedIds={selectedIds}
        isAllSelected={isAllSelected}
        selectAll={selectAll}
      />
    </>
  )
}

export default MassUpdateContainer

const HorizontalDivider = styled.div`
  height: 2px;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.main};
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  border-radius: 20px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.primary.main};
  `}
`
