import {
  SalesPrintConfig as Config,
  SalesPrintConfigKey as Key,
} from '../types'

export const SALES_PRINT_CONFIG: Config[] = [
  {
    disabled: false,
    key: Key.BasicDetails,
    sections: [
      { disabled: false, key: Key.Customer, visible: true },
      { disabled: false, key: Key.Dates, visible: true },
      { disabled: false, key: Key.Notes, visible: true },
    ],
    visible: true,
  },
  {
    disabled: false,
    group: {
      by: Key.Name,
      options: [Key.Name, Key.Group, Key.Room, Key.Target],
    },
    key: Key.Participants,
    sections: [
      { disabled: true, key: Key.Name, visible: true },
      { disabled: false, key: Key.Age, visible: true },
      { disabled: false, key: Key.Target, visible: true },
      { disabled: false, key: Key.Dates, visible: true },
      { disabled: false, key: Key.Room, visible: true },
      { disabled: false, key: Key.Group, visible: true },
      { disabled: false, key: Key.Product, visible: true },
      { disabled: false, key: Key.Request, visible: true },
      { disabled: false, key: Key.Notes, visible: true },
      { disabled: false, key: Key.Allegries, visible: true },
    ],
    visible: true,
  },
  {
    disabled: false,
    key: Key.Accommodation,
    sections: [
      { disabled: true, key: Key.Room, visible: true },
      { disabled: false, key: Key.Target, visible: true },
      { disabled: false, key: Key.Dates, visible: true },
      { disabled: false, key: Key.Features, visible: true },
      { disabled: false, key: Key.Notes, visible: true },
    ],
    visible: true,
  },
  {
    disabled: false,
    key: Key.Program,
    sections: [
      {
        disabled: false,
        key: Key.Reservations,
        sections: [
          { disabled: true, key: Key.Time, visible: true },
          { disabled: false, key: Key.Resource, visible: true },
          { disabled: false, key: Key.Subject, visible: true },
          { disabled: false, key: Key.Product, visible: true },
          { disabled: false, key: Key.Group, visible: true },
          { disabled: false, key: Key.Notes, visible: true },
        ],
        visible: true,
      },
      {
        disabled: false,
        key: Key.Meals,
        sections: [
          { disabled: true, key: Key.Time, visible: true },
          { disabled: false, key: Key.Location, visible: true },
          { disabled: false, key: Key.Name, visible: true },
          { disabled: false, key: Key.Quantity, visible: true },
          { disabled: false, key: Key.Notes, visible: true },
        ],
        visible: true,
      },
      {
        disabled: false,
        key: Key.Tasks,
        sections: [
          { disabled: true, key: Key.Time, visible: true },
          { disabled: false, key: Key.Name, visible: true },
          { disabled: false, key: Key.Assignee, visible: true },
          { disabled: false, key: Key.Subject, visible: true },
          { disabled: false, key: Key.Product, visible: true },
        ],
        visible: true,
      },
    ],
    visible: true,
  },
]

export const checkSalesPrintConfigVisibility = (
  config: Config[],
  path: string
): boolean => {
  const [key, ...nextPath] = path.split('.')

  return config.reduce((acc: boolean, c) => {
    if (c.key !== key) {
      return acc
    }

    if (!nextPath.length) {
      return c.visible
    }

    return checkSalesPrintConfigVisibility(c.sections ?? [], nextPath.join('.'))
  }, true)
}

export const getSalesPrintQueryConditions = (c: Config[]) => ({
  withAccommodation: checkSalesPrintConfigVisibility(c, Key.Accommodation),
  withMeals:
    checkSalesPrintConfigVisibility(c, Key.Program) &&
    checkSalesPrintConfigVisibility(c, `${Key.Program}.${Key.Meals}`),
  withParticipants: checkSalesPrintConfigVisibility(c, Key.Participants),
  withProgram: checkSalesPrintConfigVisibility(c, Key.Program),
  withReservations:
    checkSalesPrintConfigVisibility(c, Key.Program) &&
    checkSalesPrintConfigVisibility(c, `${Key.Program}.${Key.Reservations}`),
  withTasks:
    checkSalesPrintConfigVisibility(c, Key.Program) &&
    checkSalesPrintConfigVisibility(c, `${Key.Program}.${Key.Tasks}`),
})

export const modifySalesPrintConfig = (
  config: Config[],
  path: string
): Config[] => {
  const [key, ...nextPath] = path.split('.')

  return config.map((c) =>
    c.key === key
      ? {
          ...c,
          sections: nextPath.length
            ? modifySalesPrintConfig(c.sections ?? [], nextPath.join('.'))
            : c.sections,
          visible: nextPath.length ? c.visible : !c.visible,
        }
      : c
  )
}
