import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { T, useLanguageContext } from '@/modules/Language'

type Props = {
  date?: string
}

export const SectionTitle = ({ date }: Props) => {
  const { language } = useLanguageContext()

  return (
    <Title>
      <tr>
        <Date>
          {date ? (
            moment(date).locale(language).format('MMMM Do')
          ) : (
            <T>SalesDetails:PrintSale.noDate</T>
          )}
        </Date>
        <Weekday>
          {date && moment(date).locale(language).format('dddd')}
        </Weekday>
      </tr>
    </Title>
  )
}

////////////

const Title = styled.thead`
  font-size: 1.25rem;
  font-weight: 600;
  page-break-after: avoid;
`

const Date = styled.td`
  text-align: end;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(0.5)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(22)}rem;
  `}
`

const Weekday = styled.td`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`
