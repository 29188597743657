import { RefObject, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { DateRangePickerControl } from '@/components/TimeControls'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useRoomLayoutContext } from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'

import { AccommodationLevelSelector } from '../../ControlRow/components'

type Props = {
  targetLevelIds: string[]
  setTargetLevelIds: (targetLevelIds: string[]) => void
}

export const Filters = ({ targetLevelIds, setTargetLevelIds }: Props) => {
  const theme = useTheme()

  const {
    onSetCurrentSalesDateRange,
    onSetTargetSalesDateRange,
    targetSalesDateRange,
  } = useRoomLayoutContext()

  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false)

  return (
    <>
      <Wrapper>
        <Gutter type={1}>
          <Tooltip
            content={<T>RoomLayout:sidebar.filter.currentWeek</T>}
            trigger={(triggerProps) => (
              <Button onClick={onSetCurrentSalesDateRange} {...triggerProps}>
                <FontAwesomeIcon icon={['far', 'calendar-check']} />
              </Button>
            )}
          />
        </Gutter>
        <DateRangeWrapper flex={1} justifyContent="center">
          <DateRangePickerControl
            maxWeekRange={3}
            numberOfMonths={1}
            render={({ openModal, range, ref }) => (
              <Button
                onClick={openModal}
                ref={ref as RefObject<HTMLButtonElement>}
              >
                {range &&
                  `${range.from.format('dd, D.M.YY')} – ${range.to.format(
                    'dd, D.M.YY'
                  )}`}
              </Button>
            )}
            setValue={(range) => onSetTargetSalesDateRange(range)}
            value={targetSalesDateRange}
          />
        </DateRangeWrapper>
        <Gutter type={1}>
          <Button onClick={() => setFiltersOpen(!isFiltersOpen)}>
            <FontAwesomeIcon
              color={
                isFiltersOpen
                  ? theme.palette.primary.main
                  : theme.palette.text.light
              }
              icon="filter"
            />
          </Button>
        </Gutter>
      </Wrapper>
      {isFiltersOpen && (
        <HiddenFiltersWrapper isFiltersOpen={isFiltersOpen}>
          <Label>
            <T>RoomLayout:sidebar.filter.accommodationLevel.title</T>
          </Label>
          <AccommodationLevelSelector
            fullWidth
            targetLevelIds={targetLevelIds}
            setTargetLevelIds={setTargetLevelIds}
          />
        </HiddenFiltersWrapper>
      )}
    </>
  )
}

////////////

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  height: 100%;
  outline: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const DateRangeWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
    border-right: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const HiddenFiltersWrapper = styled.div<{ isFiltersOpen: boolean }>`
  border-bottom: ${({ isFiltersOpen, theme }) =>
    isFiltersOpen ? `1px solid ${theme.palette.smoke.main}` : 0};

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`

const Label = styled.div`
  margin-bottom: 0.25rem;
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    background: ${theme.palette.smoke.extraLight};
  `}
`
