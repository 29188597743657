import styled, { css } from 'styled-components/macro'

import type { Palette } from '@/theme'

import { ActionType } from './types'

export const getActionColor = (
  action: ActionType,
  palette: Palette
): string => {
  switch (action) {
    case 'ACCEPT':
      return palette.success.main
    case 'CANCEL':
    case 'DELETE':
    case 'REJECT_OFFER_ITERATE':
      return palette.danger.main
    case 'ADD_MANUAL_PAYMENT':
    case 'ADD_MANUAL_REFERENCE_TRANSFER_PAYMENT':
    case 'ADD_OTHER_PAYMENT':
    case 'ADD_SERVICE_FEE':
    case 'CORRECT_INVOICE':
    case 'CREATE_AUTOMATIC_ADVANCE':
    case 'CREATE_CONFIRMATION':
    case 'CREATE_INVOICE':
    case 'CREATE_MANUAL_ADVANCE':
    case 'CREATE_OFFER':
    case 'CREATE_REFUND':
    case 'CREATE_REFUND_UNPAID_ADVANCES':
    case 'MARK_WAIT_FOR_APPROVAL':
    case 'LOAD_COSTING':
    case 'PRINT_INVOICE':
    case 'RE_INVOICE':
    case 'SEND_INVOICE':
    case 'SHOW_DOCUMENT':
    case 'SHOW_ORIGINAL_INVOICE':
    case 'UPDATE_LINES':
      return palette.primary.main
    case 'ROLL_BACK_LATEST':
    case 'CANCEL_ORDER':
    default:
      return palette.text.light
  }
}

export const getActionIcon = (action: ActionType) => {
  switch (action) {
    case 'ACCEPT':
    case 'PRINT_INVOICE':
    case 'SEND_INVOICE':
      return 'check'
    case 'ADD_MANUAL_PAYMENT':
    case 'ADD_MANUAL_REFERENCE_TRANSFER_PAYMENT':
    case 'ADD_OTHER_PAYMENT':
      return 'euro-sign'
    case 'CORRECT_INVOICE':
      return 'pen'
    case 'ADD_SERVICE_FEE':
    case 'CREATE_AUTOMATIC_ADVANCE':
    case 'CREATE_CONFIRMATION':
    case 'CREATE_INVOICE':
    case 'CREATE_MANUAL_ADVANCE':
    case 'CREATE_OFFER':
    case 'CREATE_REFUND_UNPAID_ADVANCES':
    case 'UPDATE_LINES':
      return 'plus'
    case 'DELETE':
      return 'trash'
    case 'MARK_WAIT_FOR_APPROVAL':
      return ['far', 'hourglass']
    case 'CANCEL':
    case 'CANCEL_ORDER':
    case 'REJECT_OFFER_ITERATE':
      return 'xmark'
    case 'CREATE_REFUND':
      return 'angle-down'
    case 'RE_INVOICE':
    case 'ROLL_BACK_LATEST':
      return 'rotate-left'
    case 'SHOW_DOCUMENT':
      return ['far', 'file-lines']
    case 'SHOW_ORIGINAL_INVOICE':
    default:
      return 'info'
  }
}

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(2)}rem;
  `}
`

export const Label = styled.span`
  ${({ theme }) => css`
    line-height: ${theme.spacing.guPx(2) - 1}px;
  `}
`
