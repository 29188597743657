import { CatalogProductManager } from './CatalogProductManager'
import { CatalogProductManagerContextProvider } from './CatalogProductManagerState'

type Props = {
  onClose: () => void
  productId: string
  sellerId?: string
}

export const CatalogProductManagerContainer = ({
  onClose,
  ...props
}: Props) => (
  <CatalogProductManagerContextProvider {...props}>
    <CatalogProductManager onClose={onClose} />
  </CatalogProductManagerContextProvider>
)
