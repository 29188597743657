export default function parseTimeFromString(
  input: string
): { hour: number; minute: number } | null {
  let hour = 0
  let minute = 0

  if (!input) {
    return null
  }

  if (input.includes(':')) {
    const [hourStr, minuteStr] = input.split(':')

    hour = parseInt(hourStr, 10) || hour
    minute = parseInt(minuteStr, 10) || minute
  } else if (input.length === 1 || input.length === 2) {
    hour = parseInt(input, 10) || hour
    minute = 0
  } else if (input.length === 3) {
    hour = parseInt(input.substring(0, 1), 10) || hour
    minute = parseInt(input.substring(1, 3), 10) || minute
  } else if (input.length > 3) {
    hour = parseInt(input.substring(0, 2), 10) || hour
    minute = parseInt(input.substring(2, 4), 10) || minute
  }

  return {
    hour: Math.max(0, Math.min(23, hour)),
    minute: Math.max(0, Math.min(59, minute)),
  }
}
