import { gql } from '@apollo/client'

export const USER_GROUPS_QUERY = gql`
  query UserGroups {
    registry {
      userGroups {
        id
        name
      }
    }
  }
`
