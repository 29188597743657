import React from 'react'
import styled from 'styled-components/macro'

import { DataTableInputBase } from './DataTableInputBase'

export const DataTableSelect = (props: { [key: string]: any }) => (
  <InputWrapper flex={props.flex}>
    <SelectM {...props} />
  </InputWrapper>
)

////////////

const InputWrapper = styled.form<any>`
  display: flex;
  ${({ flex }) => flex && `flex: ${flex}`};
  justify-content: stretch;
  cursor: pointer;
`

export const SelectM = styled(DataTableInputBase).attrs(() => ({
  as: 'select',
}))``
