import styled from 'styled-components/macro'

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`

export const MealTypeLabel = styled.span`
  font-size: 110%;
  font-weight: 600;
`

export const RestaurantLabel = styled.span`
  font-size: 120%;
  font-weight: 600;
`
