import styled, { css } from 'styled-components/macro'

export const InlineModalDescription = styled.div`
  margin: 0;

  font-weight: 400;
  line-height: 1.2;
  text-align: left;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    color: ${theme.palette.text.light};
  `}
`
