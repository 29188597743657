import React, { ReactNode } from 'react'

import Table from './elements/Table'
import THead from './elements/THead'
import TR from './elements/TR'

type Props = {
  children: ReactNode
  headerCells?: ReactNode
}

export const Spreadsheet = ({ children, headerCells, ...rest }: Props) => (
  <Table {...rest}>
    {headerCells && (
      <THead>
        <TR>{headerCells}</TR>
      </THead>
    )}
    {children}
  </Table>
)
