import { gql, useMutation } from '@apollo/client'

import {
  SetSalesAssigneeMutation,
  SetSalesAssigneeMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation SetSalesAssignee($input: SalesUpdateAssigneeInput!) {
    salesAssignee(input: $input) {
      id
    }
  }
`

export const useSetSalesAssigneeMutation = () =>
  useMutation<SetSalesAssigneeMutation, SetSalesAssigneeMutationVariables>(
    MUTATION
  )
