import { gql } from '@apollo/client'

export default gql`
  query WebshopRooms {
    registry {
      webshopRooms {
        id
        internalName
      }
    }
  }
`
