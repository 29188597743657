import { useQuery } from '@apollo/client'

import type {
  SalesProductByIdQuery as QueryData,
  SalesProductByIdQueryVariables as QueryVariables,
} from '~generated-types'

import { productQueries } from '../queries'
import { SalesProduct } from '../types'

type Params = {
  id: string
  skip: boolean
}

export const useSalesProductById = ({ id, skip }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    productQueries.GET_SALES_PRODUCT_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      skip,
      variables: { id },
    }
  )

  const product: SalesProduct | null = data?.salesProduct || null

  return {
    error,
    loading,
    product,
    refetch,
  }
}
