/**
 * GENERATED
 */
import type { SalesForIsoWeekQuery } from '~generated-types'

export type SalesForIsoWeekSales = SalesForIsoWeekQuery['sales'][0]
export type Task = any

/**
 * MANUAL
 */
export enum Mode {
  CONCISE = 'CONCISE',
  FULL = 'FULL',
}
