import { ChangeEvent, useState } from 'react'
import Collapse from '@kunukn/react-collapse'
import styled, { css } from 'styled-components/macro'

import { DataTableSelect } from '@/components/DataTable'
import { Label } from '@/components/FormControls'
import { InlineModalLine } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  SalesPrintConfig as Config,
  SalesPrintConfigKey as Key,
} from '../../../../../types'
import { Checkbox } from './Checkbox'
import { PrintSaleSectionHeader } from './PrintSaleSectionHeader'
import { PrintSaleSubsection } from './PrintSaleSubsection'

type Props = {
  section: Config
}

export const PrintSaleSection = ({ section }: Props) => {
  const { group, key: parentKey, sections, visible: parentVisible } = section
  const { language } = useLanguageContext()

  const {
    salesPrintConfig,
    updateSalesPrintConfig: updateConfig,
    updateSalesPrintConfigGrouping: updateConfigGrouping,
  } = salesHooks.useSalesDetailsContext()

  const [isOpen, setOpen] = useState<boolean>(false)

  const handleUpdateVisibility = (key: Key) => {
    updateConfig(salesPrintConfig, `${parentKey}.${key}`)

    if (group?.by === key) {
      updateConfigGrouping(parentKey, Key.Name)
    }
  }

  return (
    <FlexColumn noPadding>
      <PrintSaleSectionHeader
        isOpen={isOpen}
        section={section}
        setOpen={setOpen}
      />

      <CollapseWrapper isOpen={isOpen}>
        {sections && !!sections[0].sections?.length ? (
          sections.map((section, idx) => (
            <PrintSaleSubsection
              isLast={idx === sections.length - 1}
              key={section.key}
              parentKey={parentKey}
              parentVisible={parentVisible}
              section={section}
            />
          ))
        ) : (
          <Section>
            <FlexRow wrap="wrap">
              {sections &&
                sections.map(({ disabled, key, visible }) => (
                  <Checkbox
                    checked={visible}
                    disabled={!parentVisible || disabled}
                    key={key}
                    label={key}
                    onChange={() => handleUpdateVisibility(key)}
                  />
                ))}
            </FlexRow>

            {group && (
              <SelectWrapper alignItems="center" justifyContent="flex-end">
                <SelectLabel>
                  <T>SalesDetails:PrintSale.groupBy</T>
                </SelectLabel>

                <Select
                  disabled={!parentVisible}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    updateConfigGrouping(parentKey, e.target.value as Key)
                  }
                  showBorder
                  value={group.by}
                >
                  {group.options
                    .filter(
                      (o) => sections?.find(({ key }) => key === o)?.visible
                    )
                    .map((o) => (
                      <option key={o} value={o}>
                        {translate(
                          `SalesDetails:PrintSale.label.${o}`,
                          language
                        )}
                      </option>
                    ))}
                </Select>
              </SelectWrapper>
            )}
          </Section>
        )}
      </CollapseWrapper>

      <InlineModalLine style={{ margin: 0 }} />
    </FlexColumn>
  )
}

/////

const CollapseWrapper = styled(Collapse)`
  transition: height 0.25s;
  overflow: visible;
`

const Select = styled(DataTableSelect)`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(0.5)}rem;
    `}
  }
`

const SelectLabel = styled(Label)`
  margin: 0;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const SelectWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem ${theme.spacing.gu(1.5)}rem;
  `}
`
