import styled, { css } from 'styled-components/macro'

import { DataField } from '@/components/DataField'
import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { Participant } from '@/modules/FrontDesk'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SalesParticipantUpdateInput as UpdateInput } from '~generated-types'

import { Placeholder } from '../../common'

type Props = {
  participant: Participant
  updateParticipant: (input: UpdateInput) => Promise<any>
}

export const Name = ({
  participant: {
    firstName,
    id,
    lastName,
    sales: { locked },
  },
  updateParticipant,
}: Props) => {
  const { spacing } = useTheme()

  const style = {
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
  }

  const valueStyle = {
    height: '30px',
    padding: `${spacing.gu(1)}rem`,
  }

  return (
    <InlineModalIconSection icon="user">
      <FlexRow>
        <DataFieldWrapper>
          <DataField
            customValueRenderer={(value) => <Value>{value}</Value>}
            disabled={locked}
            handleSubmit={(v) => updateParticipant({ id, lastName: v })}
            hideEditIcon
            inputType="text"
            placeholder={
              <Placeholder>
                <T>FrontDesk:DailyRoomReservations.participants.setLastName</T>
              </Placeholder>
            }
            style={style}
            value={lastName}
            valueStyle={valueStyle}
            variant="primary"
          />
        </DataFieldWrapper>

        <Spacer />

        <DataFieldWrapper>
          <DataField
            customValueRenderer={(value) => <Value>{value}</Value>}
            disabled={locked}
            handleSubmit={(v) => updateParticipant({ firstName: v, id })}
            hideEditIcon
            inputType="text"
            placeholder={
              <Placeholder>
                <T>FrontDesk:DailyRoomReservations.participants.setFirstName</T>
              </Placeholder>
            }
            style={style}
            value={firstName}
            valueStyle={valueStyle}
            variant="primary"
          />
        </DataFieldWrapper>
      </FlexRow>
    </InlineModalIconSection>
  )
}

////

// Tiny visual improvement
const DataFieldWrapper = styled.div`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      height: 30px;
    }
  }
`

const Spacer = styled.span`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(1)}rem;
  `}
`

const Value = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
