import styled, { css } from 'styled-components/macro'

import { EditButton as EditButtonBase } from '@/components/ExtraButtons'

export const EditButton = styled(EditButtonBase)`
  flex: unset;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  &:disabled {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }

  @media (max-width: 1520px) {
    .edit-button-icon {
      display: none;
    }
  }
`
