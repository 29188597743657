import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import { AddressModal } from './AddressModal'

type AddressType = {
  address1: string | null
  address2: string | null
  city: string | null
  postcode: string | null
  country: string | null
}

type Props = {
  address?: FetchedParticipant['postalAddress']
  nationality: string
  participantLanguage: string
  handleUpdateAddress: (
    address: AddressType
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateLanguage: (
    language: string | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateNationality: (
    nationality: string | null
  ) => Promise<FetchedParticipant | undefined>
  readOnly?: boolean
}

const Address = ({
  address,
  nationality,
  participantLanguage,
  handleUpdateAddress,
  handleUpdateLanguage,
  handleUpdateNationality,
  readOnly,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { address1, city, postcode, country } = address || {}

  const [isModalOpen, setModalOpen] = useState(false)

  const isEmptyAddress =
    !address || (!address1 && !city && !postcode && !country)

  return (
    <>
      <ModalContainer
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            ref={ref}
            disabled={readOnly}
            minWidth={`${spacing.gu(16)}rem`}
            onClick={readOnly ? () => null : () => setModalOpen(true)}
            renderContent={() =>
              !isEmptyAddress || nationality ? (
                <>
                  {!isEmptyAddress && (
                    <FlexColumn noPadding>
                      <AddressSpan>{address1}</AddressSpan>
                      <AddressSpan>
                        {postcode}
                        {city && postcode && ', '}
                        {city}
                        {(city || postcode) && country && ', '}
                        {country}
                      </AddressSpan>
                    </FlexColumn>
                  )}
                  {nationality && isEmptyAddress && (
                    <AddressSpan>{nationality}</AddressSpan>
                  )}
                </>
              ) : (
                <>
                  <Label>
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      icon={'plus'}
                      style={{ marginRight: `${spacing.gu(1)}rem` }}
                    />
                    <T>ParticipantsList:PersonalFields.addressAndCountry</T>
                  </Label>
                </>
              )
            }
          />
        )}
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        modal={
          <AddressModal
            address={address}
            nationality={nationality}
            participantLanguage={participantLanguage}
            handleUpdateAddress={handleUpdateAddress}
            handleUpdateLanguage={handleUpdateLanguage}
            handleUpdateNationality={handleUpdateNationality}
          />
        }
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

export default Address

const AddressSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`
