import {
  AccommodationGroup,
  ElasticSaleStatus,
  RoomReservation,
  RoomTypeReservation,
  Sale,
  Target,
} from '../'

const getTotalToplevelRoomBeds = (
  roomReservations: RoomReservation[],
  item: string
) =>
  roomReservations.reduce(
    // @ts-ignore
    (acc: number, r: RoomReservation) => acc + r.request[item],
    0
  )

const getTotalBeds = (
  roomTypeReservations: RoomTypeReservation[],
  roomReservations: RoomReservation[],
  item: string
) => {
  const totalNestedRoomBeds = roomTypeReservations.reduce(
    (acc: number, r: RoomTypeReservation) =>
      acc +
      r.roomReservations.reduce(
        // @ts-ignore
        (acc: number, r: RoomReservation) => acc + r.request[item],
        0
      ),
    0
  )

  return getTotalToplevelRoomBeds(roomReservations, item) + totalNestedRoomBeds
}

const getTotalRequestedBeds = (
  roomTypeReservations: RoomTypeReservation[],
  roomReservations: RoomReservation[],
  item: string
) => {
  const totalNestedRoomBeds = roomTypeReservations.reduce(
    // @ts-ignore
    (acc: number, r: RoomTypeReservation) => acc + r.request[item],
    0
  )

  return getTotalToplevelRoomBeds(roomReservations, item) + totalNestedRoomBeds
}

const getTotalRooms = (
  roomTypeReservations: RoomTypeReservation[],
  roomReservations: RoomReservation[]
) => {
  const totalNestedRooms = roomTypeReservations.reduce(
    (acc: number, r: RoomTypeReservation) => acc + r.roomReservations.length,
    0
  )

  return roomReservations.length + totalNestedRooms
}

const getRequestedTotalRooms = (
  roomTypeReservations: RoomTypeReservation[],
  roomReservations: RoomReservation[]
) => {
  const totalNestedRooms = roomTypeReservations.reduce(
    (acc: number, r: RoomTypeReservation) => acc + r.request.rooms,
    0
  )

  return roomReservations.length + totalNestedRooms
}

const getStatus = (reservations: RoomTypeReservation[]): ElasticSaleStatus =>
  reservations.every((r: RoomTypeReservation) => r.fulfilledByRooms)
    ? 'READY'
    : reservations.some((r: RoomTypeReservation) => r.fulfilledByRooms)
    ? 'IN_PROGRESS'
    : 'NOT_READY'

export const getSaleDataFromGraphQL = ({
  accommodationGroups,
  customerName,
  customerNumber,
  estimatedDates,
  name,
  orderNumber,
}: Sale) => {
  const allRoomReservations = accommodationGroups.reduce(
    (acc: RoomReservation[], group: AccommodationGroup) => [
      ...acc,
      ...group.targets.reduce(
        (acc: RoomReservation[], target: Target) => [
          ...acc,
          ...(target.gqlRoomReservations || []),
        ],
        []
      ),
    ],
    []
  )

  const allRoomTypeReservations = accommodationGroups.reduce(
    (acc: RoomTypeReservation[], group: AccommodationGroup) => [
      ...acc,
      ...group.targets.reduce(
        (acc: RoomTypeReservation[], target: Target) => [
          ...acc,
          ...(target.gqlRoomTypeReservations || []),
        ],
        []
      ),
    ],
    []
  )

  return {
    customerName: customerName || null,
    customerNumber: customerNumber || null,
    estimatedDates: estimatedDates || null,
    requestedTotalNumbers: {
      beds: getTotalRequestedBeds(
        allRoomTypeReservations,
        allRoomReservations,
        'beds'
      ),
      extraBeds: getTotalRequestedBeds(
        allRoomTypeReservations,
        allRoomReservations,
        'extraBeds'
      ),
      rooms: getRequestedTotalRooms(
        allRoomTypeReservations,
        allRoomReservations
      ),
    },
    saleName: name || null,
    saleNumber: orderNumber,
    status: getStatus(allRoomTypeReservations),
    totalNumbers: {
      beds: getTotalBeds(allRoomTypeReservations, allRoomReservations, 'beds'),
      extraBeds: getTotalBeds(
        allRoomTypeReservations,
        allRoomReservations,
        'extraBeds'
      ),
      rooms: getTotalRooms(allRoomTypeReservations, allRoomReservations),
    },
  }
}
