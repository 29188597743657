import { LabelTruncate } from '../../SalesListing/components/LabelTruncate'
import { PrintLink } from '../PrintLink'
import { TD } from '../TD'

interface GenericCustomerOrganization {
  __typename: 'CustomerOrganization'
  customerNumber: string
  id: string
  organization: {
    name: string | null
  }
  noWrap?: boolean
}

interface GenericCustomerPerson {
  __typename: 'CustomerPerson'
  customerNumber: string
  id: string
  person: {
    firstName: string
    lastName: string
  }
  noWrap?: boolean
}

type GenericCustomer = GenericCustomerOrganization | GenericCustomerPerson

export const CustomerCell = (customer: GenericCustomer) => {
  const customerName =
    customer && customer.__typename === 'CustomerOrganization'
      ? customer.organization.name
      : parseName({
          firstName: customer?.person.firstName || '',
          lastName: customer?.person.lastName || '',
        })

  const label = `${
    customer?.customerNumber ? `#${customer?.customerNumber} ` : ''
  } ${customerName || '—'}
  `

  return customer ? (
    customer.noWrap ? (
      <LabelTruncate title={label}>
        <PrintLink to={`/registry/customers/${customer?.customerNumber}`}>
          {label}
        </PrintLink>
      </LabelTruncate>
    ) : (
      <TD>
        <PrintLink to={`/registry/customers/${customer?.customerNumber}`}>
          {label}
        </PrintLink>
      </TD>
    )
  ) : (
    <TD>'—'</TD>
  )
}

export default CustomerCell

////////////

const parseName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`
