import { useState } from 'react'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { CheckInType, CheckOutType } from '~generated-types'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import { PendingRoomTypeReservation as PendingRoomTypeReservationT } from '../SalesReservationManager.types'
import { Icon } from './Icon'
import { NumberInput } from './NumberInput'
import { RangePicker } from './RangePicker'
import { ReservationCard } from './ReservationCard'
import { ReservationDataRow } from './ReservationDataRow'
import { Title } from './Title'

type Props = {
  data: PendingRoomTypeReservationT
  groupId: string
}

export const PendingRoomTypeReservation = ({ data, groupId }: Props) => {
  const theme = useTheme()

  const {
    createReservation,
    handleUpdateRoomQuantity,
    processing,
    removeReservation,
  } = useReservationListContext()

  const [rooms, setRooms] = useState<number>(data.rooms)

  return (
    <ReservationCard
      onRemove={() => removeReservation(data.id)}
      title={
        <ReservationDataRow
          centerAlignContent
          header={
            <FlexRow alignItems="center">
              <NumberInput
                min={0}
                onBlur={() =>
                  handleUpdateRoomQuantity({ reservationId: data.id, rooms })
                }
                onChange={(e) => setRooms(Number(e.target.value))}
                onFocus={(e) => e.currentTarget.select()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleUpdateRoomQuantity({ reservationId: data.id, rooms })
                  }
                }}
                value={`${rooms}`}
              />
              <span style={{ marginRight: theme.spacing.gutterSmall }}>
                {' '}
                ✕{' '}
              </span>
            </FlexRow>
          }
        >
          <Title>{data.roomTypeName}</Title>
        </ReservationDataRow>
      }
      type="PENDING_ROOM_TYPE_RESERVATION"
    >
      <Gutter type={1}>
        <ReservationDataRow header={<Icon icon={['far', 'calendar']} />}>
          <RangePicker
            checkIn={{
              date: data.range?.from.format('YYYY-MM-DD') || '',
              type: CheckInType.Standard,
            }}
            checkOut={{
              date: data.range?.to.format('YYYY-MM-DD') || '',
              type: CheckOutType.Standard,
            }}
            reservationId={data.id}
          />
        </ReservationDataRow>
        <FlexRow justifyContent="flex-end">
          <InnocuousButton
            disabled={
              !data.range || data.range.from.isSame(data.range.to) || processing
            }
            onClick={() => createReservation(data.id, groupId)}
          >
            <PrimaryColor>
              <T>Accommodation:SalesReservationManager.confirm</T>
            </PrimaryColor>
          </InnocuousButton>
        </FlexRow>
      </Gutter>
    </ReservationCard>
  )
}

export default PendingRoomTypeReservation
