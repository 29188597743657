import { gql, useMutation } from '@apollo/client'

import type {
  UpdateCatalogProductMutation,
  UpdateCatalogProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProduct}

  mutation UpdateCatalogProduct($input: CatalogProductUpdateInput!) {
    catalogProductUpdate(input: $input) {
      ...CatalogProduct
    }
  }
`

export const useUpdateCatalogProductMutation = () =>
  useMutation<
    UpdateCatalogProductMutation,
    UpdateCatalogProductMutationVariables
  >(MUTATION)
