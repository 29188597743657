import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import { ACTIVE_STATES } from '@/modules/Sales/types'

import type {
  SalesForDateQuery as QueryData,
  SalesForDateQueryVariables as QueryVariables,
} from '~generated-types'

import salesForDateSales from '../fragments/salesForDateSales'
import { SalesForDate } from '../types'

const QUERY = gql`
  ${salesForDateSales}

  query SalesForDate($date: LocalDate!) {
    salesForDate(input: { date: $date }) {
      ...SalesForDateSales
    }
  }
`

type Params = {
  date: Moment
  forceRefetch?: boolean
}

export const useSalesForDate = ({ forceRefetch, date }: Params) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  })

  const sales: SalesForDate[] = data
    ? [...data.salesForDate].filter(({ lifecycle }) =>
        ACTIVE_STATES.includes(lifecycle.state.systemState)
      )
    : []

  return {
    error,
    loading,
    sales,
  }
}
