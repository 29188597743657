import moment from 'moment'

export default function isToday(date: string | moment.Moment): boolean {
  const momentDate = moment(date)

  if (!momentDate.isValid()) {
    throw Error('isToday was given an invalid date string')
  }

  return moment().format('YYYY-MM-DD') === momentDate.format('YYYY-MM-DD')
}
