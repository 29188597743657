import styled, { css } from 'styled-components/macro'

import { borderColor } from './styles'

export const DataTableBody = styled.tbody<{ doubleSpacing?: boolean }>`
  ${({ theme }) => css`
    color: ${theme.palette.text.dark};
  `}

  & > tr > td {
    border-style: solid;
    border-color: ${borderColor};
    border-width: 0;
  }

  & > tr > td:first-child {
    border-left-width: 1px;
  }
  & > tr > td:last-child {
    border-right-width: 1px;
  }

  & > tr:first-child > td {
    border-top-width: 1px;
  }

  & > tr:last-child > td {
    border-bottom-width: 1px;
  }

  & > tr:first-child > td:first-child {
    border-top-left-radius: 8px;
  }

  & > tr:first-child > td:last-child {
    border-top-right-radius: 8px;
  }

  & > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }

  & > tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }

  /* Add spacing between rowgroups */
  &:not(:last-child)::after {
    content: ' ';
    ${({ doubleSpacing, theme }) => css`
      height: ${doubleSpacing ? theme.spacing.gu(2) : theme.spacing.gu(1)}rem;
    `}
    display: table-row;
  }
`
