import {
  FocusEvent,
  KeyboardEvent,
  RefObject,
  useEffect,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Input as InputBase } from '@/components/FormControls'
import { InlineModalIconSection } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { TimeOptionsModal } from '@/modules/Reservations'
import { useTheme } from '@/theme'

import { EditButton, Placeholder } from './common'
import {
  getTimeString,
  isTimeValid,
  scrollToSelectedTime,
  transformTime,
} from './utils'

type Props = {
  dueTime: string | null
  readOnly: boolean
  updateDueTime: (dueTime: string) => Promise<void>
}

export const DueTime = ({ dueTime, readOnly, updateDueTime }: Props) => {
  const { palette } = useTheme()

  const time = dueTime ? getTimeString(dueTime) : '00:00'

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    time !== value && setValue(time)
  }, [time])

  const handleUpdateDueTime = (newTime: string) =>
    updateDueTime(`${newTime}:00`)
      .catch(() => undefined)
      .finally(() => {
        setEditMode(false)
        setHover(false)
      })

  const handleSetTime = (e: KeyboardEvent | FocusEvent) => {
    const currentValue = transformTime(value)

    if (currentValue !== time) {
      if (isTimeValid(currentValue)) {
        handleUpdateDueTime(currentValue)
      } else {
        setValue(time)
      }
    }

    if (e.type === 'keydown' || (e as FocusEvent).relatedTarget) {
      setEditMode(false)
      setHover(false)
    }
  }

  return (
    <InlineModalIconSection icon={['far', 'clock']}>
      {isEditMode ? (
        <ModalContainer
          isOpen={isEditMode}
          modal={
            <TimeOptionsModal
              currentTime={time}
              setTime={handleUpdateDueTime}
            />
          }
          onClose={() => setEditMode(false)}
          placement="bottom-start"
          referenceElement={({ ref }) => (
            <Input
              autoFocus
              onBlur={handleSetTime}
              onChange={(e) => setValue(e.target.value)}
              onFocus={(e) => e.currentTarget.select()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSetTime(e)
                }
              }}
              placeholder="00:00"
              ref={ref as RefObject<HTMLInputElement>}
              value={value}
            />
          )}
          zIndex={10004}
        />
      ) : dueTime ? (
        <CompactEditButton
          disabled={readOnly}
          onClick={() => {
            setEditMode(true)
            scrollToSelectedTime(time)
          }}
        >
          {time}
        </CompactEditButton>
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Placeholder>
            <T>Tasks:Task.dueTimePlaceholder</T>
          </Placeholder>

          {isHover && (
            <FontAwesomeIcon
              color={palette.text.lighter}
              icon="pen"
              size="sm"
            />
          )}
        </EditButton>
      )}
    </InlineModalIconSection>
  )
}

////////

const CompactEditButton = styled(EditButton)`
  justify-content: center;
  flex: unset;
  width: 53px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Input = styled(InputBase)`
  &:not([type='checkbox']):not([type='radio']) {
    flex: unset;

    border-radius: 4px;
    height: 30px;
    text-align: center;
    width: 53px;

    ${({ theme }) => css`
      margin-left: -${theme.spacing.gu(1)}rem;
    `}
  }
`
