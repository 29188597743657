import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { ModalSelector } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import {
  EventEnrollment,
  ParticipantsListQueries,
} from '@/modules/ParticipantsList'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import {
  SaleAndEnrollmentsBaseQuery,
  SaleAndEnrollmentsBaseQueryVariables,
  TargetByIdQuery,
} from '~generated-types'

type Props = {
  target: NonNullable<TargetByIdQuery['accommodationTarget']>
  readOnly?: boolean
  handleTargetAddSales: (input: any) => Promise<any>
  handleTargetRemoveSales: (input: any) => Promise<any>
}

export const ShareToSalesSelector = ({
  target,
  readOnly,
  handleTargetAddSales,
  handleTargetRemoveSales,
}: Props) => {
  const theme = useTheme()

  const { data: salesData } = salesHooks.useSalesDetailsContext()

  const { id, event } = salesData || {}

  const [loadProductsList, { data, loading, error }] = useLazyQuery<
    SaleAndEnrollmentsBaseQuery,
    SaleAndEnrollmentsBaseQueryVariables
  >(ParticipantsListQueries.SALE_AND_ENROLLMENTS_BASE, {
    fetchPolicy: 'no-cache',
  })

  const getCustomerName = (
    customer: NonNullable<EventEnrollment['customer']>['customer'] | null
  ) =>
    customer?.__typename === 'CustomerOrganization'
      ? customer?.organization.name
      : customer?.__typename === 'CustomerPerson'
        ? `${customer?.person.firstName} ${customer?.person.lastName}`
        : ''

  const openModal = (setModalOpen: (isOpen: boolean) => void) =>
    !readOnly
      ? () => {
          setModalOpen(true)
          loadProductsList({
            variables: {
              id: event?.id || id,
            },
          })
        }
      : () => null

  const options = (data?.sales.enrollments?.nodes || []).map((enrollment) => ({
    disabled: false,
    label: (
      <FlexRow>
        <TextWrapper>
          {`#${enrollment.orderNumber}${
            enrollment.name ? ` – ${enrollment.name}` : ''
          }`}
        </TextWrapper>
        {enrollment.customer?.customer && (
          <TextWrapper>
            <FontAwesomeIcon
              fixedWidth
              icon={
                enrollment.customer?.customer.__typename ===
                'CustomerOrganization'
                  ? ['far', 'building']
                  : 'user'
              }
              size="sm"
              style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
            />
            {getCustomerName(enrollment.customer?.customer || null)}
          </TextWrapper>
        )}
      </FlexRow>
    ),
    searchValue: `#${enrollment.orderNumber}${
      enrollment.name ? ` – ${enrollment.name}` : ''
    } ${getCustomerName(enrollment.customer?.customer || null)}`,
    selected: !!target.sharedTo.find(({ id }) => id === enrollment.id),
    value: enrollment.id,
  }))

  return (
    <ModalSelector
      type={'checkbox'}
      options={options}
      handleChange={(value, isSelected) =>
        isSelected
          ? handleTargetAddSales({ id: target.id, salesId: value })
          : handleTargetRemoveSales({ id: target.id, salesId: value })
      }
      referenceElement={(ref, setModalOpen) => (
        <SharedToWrapper
          ref={ref}
          disabled={readOnly}
          onClick={openModal(setModalOpen)}
        >
          {target.sharedTo.length ? (
            <>
              <FontAwesomeIcon
                color={theme.palette.primary.main}
                fixedWidth
                icon={'lock'}
                size="sm"
              />
              <FontWeight normal>
                {target.sharedTo.map(
                  (sale: any, index: number) =>
                    `${index !== 0 ? ', ' : ''}#${sale.orderNumber}`
                )}
              </FontWeight>
            </>
          ) : (
            <FontWeight normal style={{ fontStyle: 'italic' }}>
              <T>Accommodation:TargetGroup.shared</T>
            </FontWeight>
          )}
        </SharedToWrapper>
      )}
      loading={loading}
      error={error}
    />
  )
}

const SharedToWrapper = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.text.lighter : theme.palette.text.light};
  transition: 0.3s;
  min-width: 15rem;
  max-width: 15rem;
  text-align: center;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;

    & svg {
      margin-right: ${theme.spacing.gu(1)}rem;
    }
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const TextWrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 14rem;
`
