import styled, { css } from 'styled-components/macro'

import { Chip, ChipColor } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { ElasticSales } from '@/modules/Sales/types'
import { getStateColor } from '@/modules/Sales/utils'

import { SalesSystemState } from '~generated-types'

type Props = {
  item: ElasticSales
}

export const Name = ({ item: { facet, name, orderNumber, state } }: Props) => {
  const { salesStatesByKey } = salesHooks.useSalesStates()
  const { language } = useLanguageContext()

  return (
    <Wrapper
      alignItems="flex-start"
      flex={1}
      noPadding
      justifyContent="space-between"
    >
      <FlexColumn noPadding>
        <OrderNumberWrapper>
          #{orderNumber}
          {'  |  '}
          <Facet>{facet.abbreviation}</Facet>
        </OrderNumberWrapper>

        {name && <SalesName>{name}</SalesName>}
      </FlexColumn>

      {salesStatesByKey && (
        <SalesStateChip
          color={getStateColor(state as SalesSystemState) as ChipColor}
          size="xs"
          variant="outlined"
        >
          {salesStatesByKey[state][language]}
        </SalesStateChip>
      )}
    </Wrapper>
  )
}

////////////

const Facet = styled.span`
  font-weight: 600;
`

const OrderNumberWrapper = styled(FlexRow)`
  white-space: pre;
`

const SalesName = styled.span`
  font-weight: 600;
  margin-top: 3px;
  text-align: start;
  overflow-wrap: anywhere;
`

const SalesStateChip = styled(Chip)`
  width: fit-content;
  margin-top: 5px;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`
