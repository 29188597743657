import { get } from 'dot-prop'

import type { FontWeight, TypographyOptions } from '../types'

export default function mapStrapiTypographyConfigToTypographyOptions(
  input: Record<string, unknown>
): TypographyOptions {
  const options: TypographyOptions = {}

  const bodyFontFamily: Record<string, unknown> | undefined = get(
    input,
    'body_font_family'
  )
  const headlineFontFamily: Record<string, unknown> | undefined = get(
    input,
    'headline_font_family'
  )

  if (bodyFontFamily) {
    options.defaultFontWeight = parseFontWeight(
      bodyFontFamily.default_font_weight as number
    )
    options.fontFamily = bodyFontFamily.font_family_value as string

    // Set heading values to the body value and override later if needed
    options.defaultFontWeightHeading = parseFontWeight(
      bodyFontFamily.default_font_weight as number
    )
    options.fontFamilyHeading = bodyFontFamily.font_family_value as string
  }

  if (headlineFontFamily) {
    options.defaultFontWeightHeading = parseFontWeight(
      headlineFontFamily.default_font_weight as number
    )
    options.fontFamilyHeading = headlineFontFamily.font_family_value as string
  }

  if (input.font_size_base_in_px)
    options.fontSizeBaseInPx = input.font_size_base_in_px as number

  return options
}

////////////

const parseFontWeight = (input: number): FontWeight => {
  switch (input) {
    case 100:
    case 200:
    case 300:
    case 400:
    case 500:
    case 600:
    case 700:
    case 800:
    case 900:
      return input as FontWeight
    default:
      return 400
  }
}
