import { gql } from '@apollo/client'

export const ListingRoomFeatureFragment = gql`
  fragment ListingRoomFeature on RoomLabel {
    icon
    id
    name
    shortName
  }
`
