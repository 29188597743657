import { gql } from '@apollo/client'

export default gql`
  fragment SalesVst on SalesVst {
    mainCode {
      id
      code
      title
    }
    missingFields
    otherLesson {
      hours
      fee
    }
    participantLessonSummary {
      hours
      fee
    }
    participantVstSummary {
      ageCategory {
        key
        name
      }
      dayVisitor
      gender
      vstDays
      vstParticipantCount
      nonVstDays
      nonVstParticipantCount
    }
    sideCode {
      id
      code
      title
    }
    state
    totalErrorParticipants
    totalIncompleteParticipants
    vstDocumentConfig {
      documentSigner {
        firstName
        id
        lastName
        sortOrder
      }
      showSignature
    }
  }
`
