import { useState } from 'react'
import moment from 'moment'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { translate, useLanguageContext } from '@/modules/Language'
import { AgeReference, ParticipantBirthday } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import {
  AgeField,
  BirthdayInput,
  SalesParticipantFullFragment,
} from '~generated-types'

import ParticipantAgeModal from './ParticipantAgeModal'

type ParticipantAgeCategory = SalesParticipantFullFragment['ageCategory']

type Props = {
  age: number | null
  ageCategory: ParticipantAgeCategory | null
  ageSource: AgeField | null
  birthday: ParticipantBirthday | null
  handleChange: (
    age: number | null,
    ageCategoryKey: string | null,
    birthday: BirthdayInput | null
  ) => Promise<any>
  isVSTError?: boolean
  readOnly?: boolean
}

const ParticipantAge = ({
  age,
  ageCategory,
  ageSource,
  birthday,
  handleChange,
  isVSTError,
  readOnly,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)
  const [processing, setProcessing] = useState(false)

  const handleSubmit = ({ age, ageCategoryKey, birthday }: AgeReference) => {
    setProcessing(true)

    return handleChange(age, ageCategoryKey, birthday).then(() => {
      setProcessing(false)
    })
  }

  const renderAgeValue = () => {
    if (birthday?.date) {
      const formattedBirthday = moment()
        .year(birthday.year)
        .month((birthday.month ?? 1) - 1)
        .date(birthday.date ?? 1)
        .format('DD.MM.YYYY')

      return ageCategory?.abbreviation
        ? `${formattedBirthday}, ${ageCategory?.abbreviation}`
        : formattedBirthday
    }

    if (birthday?.year) {
      const formattedYear = moment().year(birthday.year).format('YYYY')

      return ageCategory?.abbreviation
        ? `${formattedYear}, ${ageCategory?.abbreviation}`
        : formattedYear
    }

    if (age) {
      return `${age} ${translate(
        'ParticipantsList:ParticipantFormFields.years',
        language
      )}`
    }

    if (ageCategory) {
      return ageCategory?.name
    }

    return (
      <FontWeight
        style={{
          color: palette.smoke.dark,
          fontStyle: 'italic',
        }}
      >
        {translate('ParticipantsList:ParticipantFormFields.age', language)}
      </FontWeight>
    )
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <ParticipantAgeModal
          age={age}
          ageCategory={ageCategory}
          ageSource={ageSource}
          birthday={birthday}
          handleSubmit={handleSubmit}
        />
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <AgeWrapper
          disabled={readOnly}
          isError={isVSTError}
          onClick={
            !readOnly
              ? () => {
                  setModalOpen(true)
                }
              : () => null
          }
          ref={ref}
        >
          {!processing ? (
            renderAgeValue()
          ) : (
            <ReactLoading
              color={palette.smoke.main}
              height={18}
              width={18}
              type="spin"
            />
          )}
        </AgeWrapper>
      )}
    />
  )
}

export default ParticipantAge

//////

const AgeWrapper = styled.div<{ disabled?: boolean; isError?: boolean }>`
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  padding: 0 2px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
  `}

  ${({ disabled, isError, theme }) =>
    css`
      background-color: ${disabled
        ? theme.palette.smoke.light
        : isError
        ? '#fff2ef'
        : theme.palette.white};
    `};

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
