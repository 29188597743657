import styled, { css } from 'styled-components/macro'

const TBody = styled.tbody`
  background-color: white;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
  `}

  & > tr > td {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.dark};
    `}

    border-radius: 0;
    border-style: solid;

    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 1px;
    border-right-width: 0;

    position: relative;

    &:first-child {
      border-left-width: 1px;

      ${({ theme }) => css`
        padding-left: ${theme.spacing.gu(2)}rem;
      `}
    }

    &:last-child {
      border-right-width: 1px;

      ${({ theme }) => css`
        padding-right: ${theme.spacing.gu(2)}rem;
      `}
    }
  }

  & > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }

  & > tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }

  &:not(:first-of-type) {
    & > tr:first-child > td {
      border-top-width: 1px;
    }

    & > tr:first-child > td:first-child {
      border-top-left-radius: 8px;
    }

    & > tr:first-child > td:last-child {
      border-top-right-radius: 8px;
    }
  }

  /* Add spacing between rowgroups */
  &:not(:last-child)::after {
    content: ' ';
    display: table-row;

    ${({ theme }) => css`
      height: ${theme.spacing.gu(1)}rem;
    `}
  }
`

export default TBody
