import { gql } from '@apollo/client'

export default gql`
  query SalesStates {
    registry {
      salesStates {
        isConfirmed
        key
        names {
          locale
          content
        }
        processOrder
        disabled
        systemState
      }
    }
  }
`
