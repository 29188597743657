import { ApolloError, gql, useQuery } from '@apollo/client'

import {
  ParticipantsForPrintQuery as QueryData,
  ParticipantsForPrintQueryVariables as QueryVariables,
} from '~generated-types'

import { ParticipantForPrint } from '../ParticipantsList.types'

const ParticipantsForPrintServiceBedFragment = gql`
  fragment ParticipantsForPrintServiceBed on ServiceParticipantBed {
    dates {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
    }
    id
    participantRoom {
      id
      roomReservation {
        id
        request {
          room {
            id
            number
          }
        }
      }
    }
  }
`

const QUERY = gql`
  ${ParticipantsForPrintServiceBedFragment}

  query ParticipantsForPrint($salesId: ID!) {
    sales(id: $salesId) {
      id
      participantConnection(input: { pagination: { size: 10000 } }) {
        nodes {
          firstName
          id
          lastName
          group
          services {
            ...ParticipantsForPrintServiceBed
          }
        }
      }
    }
  }
`

type Props = {
  salesId: string
}

type Hook = {
  error?: ApolloError
  loading: boolean
  participants: ParticipantForPrint[]
}

export const useParticipantsForPrint = ({ salesId }: Props): Hook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
    variables: { salesId },
  })

  const participants = data?.sales.participantConnection.nodes ?? []

  return { error, loading, participants }
}
