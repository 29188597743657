import { gql, useMutation } from '@apollo/client'

import {
  ServiceDeleteMutation,
  ServiceDeleteMutationVariables,
} from '~generated-types'

export type { ServiceDeleteMutation, ServiceDeleteMutationVariables }

export const SERVICE_DELETE = gql`
  mutation ServiceDelete($id: ID!) {
    serviceDelete(id: $id) {
      id
      deleted
      participant {
        id
      }
    }
  }
`

export function useServiceDelete() {
  return useMutation<ServiceDeleteMutation, ServiceDeleteMutationVariables>(
    SERVICE_DELETE
  )
}

export default useServiceDelete
