import { FC, ReactElement, useState } from 'react'
import { get } from 'dot-prop'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import {
  DataSearch,
  ReactiveBase,
  ReactiveList,
} from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticBaseUrl } from '@/utils/api'

interface Props {
  onChangeMatchingSales: (salesIds: null | string[]) => void
}

export const FreeTextSearch: FC<Props> = ({
  onChangeMatchingSales,
}): ReactElement => {
  const { language } = useLanguageContext()
  const [currentValue, setCurrentValue] = useState<string | null>(null)
  const [currentMatch, setCurrentMatch] = useState<string[] | null>([])

  return (
    <ReactiveBase app="sales.sales,sales.participant" url={ElasticBaseUrl}>
      <FilterSection
        forceBottomSpacing
        label={<T>FrontDesk:DailyCustomerList.search.title</T>}
        render={() => (
          <>
            <DataSearch
              autosuggest={false}
              componentId="SEARCH"
              dataField={[
                'customerName',
                'firstName',
                'lastName',
                'name',
                'orderNumber.keyword',
              ]}
              defaultQuery={() => ({})}
              onChange={(value, triggerQuery) => {
                setCurrentValue(value || null)
                triggerQuery()
              }}
              placeholder={translate(
                'FrontDesk:DailyCustomerList.search.placeholder',
                language
              )}
              queryFormat={'and'}
              showClear
              showIcon
              value={currentValue || ''}
              URLParams={false}
            />
            <ReactiveList
              componentId="SEARCH_RESULTS"
              dataField={'id'}
              includeFields={['id', 'sales.id']}
              // prevents search without a search value
              onData={({ data }: AnyObject) => {
                if (!currentValue) {
                  setCurrentMatch(null)
                  onChangeMatchingSales(null)
                  return null
                }

                const onlyUnique = (value: string, index: number, self: any) =>
                  self.indexOf(value) === index

                const salesIds: string[] = []

                if (Array.isArray(data)) {
                  data.forEach((x: any) => {
                    if (x._index === 'sales.participant') {
                      const salesId: string | undefined = get(x, 'sales.id')

                      if (salesId) {
                        salesIds.push(salesId)
                      }
                    }

                    if (x._index === 'sales.sales') {
                      salesIds.push(x.id)
                    }
                  })
                }

                const sortedUniqueIds = salesIds.filter(onlyUnique).sort()

                if (
                  JSON.stringify(sortedUniqueIds) !==
                  JSON.stringify(currentMatch)
                ) {
                  setCurrentMatch(sortedUniqueIds)
                  onChangeMatchingSales(sortedUniqueIds)
                }
              }}
              onQueryChange={function (prevQuery: any, nextQuery: any) {
                if ('match_all' in nextQuery['query']) {
                  onChangeMatchingSales(null)
                  nextQuery['query'] = { match_none: {} }
                }
              }}
              pagination={false}
              react={{
                and: ['SEARCH'],
              }}
              render={() => null}
              renderError={() => null}
              renderNoResults={() => null}
              showLoader={false}
              showResultStats={false}
              size={10000}
            />
          </>
        )}
      />
    </ReactiveBase>
  )
}
