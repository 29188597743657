import { gql, useMutation } from '@apollo/client'

import {
  SetCheckOutMutation,
  SetCheckOutMutationVariables,
} from '~generated-types'

export type { SetCheckOutMutation, SetCheckOutMutationVariables }

export const SET_CHECK_OUT = gql`
  mutation SetCheckOut($input: MarkCheckedInput!) {
    salesParticipantCheckOut(input: $input) {
      id
      checkOut
    }
  }
`

export function useSetCheckOut() {
  return useMutation<SetCheckOutMutation, SetCheckOutMutationVariables>(
    SET_CHECK_OUT
  )
}

export default useSetCheckOut
