import { gql } from '@apollo/client'

import document from './document'
import offerInfo from './offerInfo'

export default gql`
  ${document}
  ${offerInfo}

  fragment Offer on Offer {
    ...OfferInfo

    document {
      ...Document
    }
  }
`
