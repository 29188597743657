import { ErrorInfo } from 'react'
import styled, { css } from 'styled-components/macro'

import { useErrorLogger } from '@/components/Errors'
import { T } from '@/modules/Language'

type Props = {
  error: Error
  info: ErrorInfo
}

export const SectionErrorBoundaryContent = ({ error, info }: Props) => {
  useErrorLogger({ error, info })

  return (
    <ErrorSection>
      <T>common:error.common</T>
    </ErrorSection>
  )
}

/////

const ErrorSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterBig};
    background-color: ${theme.palette.white};
    border: 2px solid ${theme.palette.danger.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
