import { gql, useMutation } from '@apollo/client'

import {
  SetSalesCustomerAddressMutation,
  SetSalesCustomerAddressMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation SetSalesCustomerAddress($input: SalesSetCustomerAddressInput!) {
    salesSetCustomerAddress(input: $input) {
      sales {
        id
      }
    }
  }
`

export const useSetSalesCustomerAddressMutation = () =>
  useMutation<
    SetSalesCustomerAddressMutation,
    SetSalesCustomerAddressMutationVariables
  >(MUTATION)
