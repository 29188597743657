import { Fragment, memo } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { Right } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { P } from '@/components/Typography'
import { ElasticFeature } from '@/modules/Reservations/components/RoomLayout/types'
import { Theme, useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

type Variant = 'filled' | 'outlined'

type Props = {
  color: string
  isFocused?: boolean
  isTranslucent?: boolean
  item: {
    [key: string]: any
  }
  leftOffset: number
  onClick?: (...args: Array<any>) => any
  variant: Variant
}

export const ReservationBlock = memo<Props>(
  ({
    color,
    isFocused,
    isTranslucent,
    item,
    leftOffset,
    onClick,
    variant,
  }: Props) => {
    const theme = useTheme()

    const { checkIconValidity } = useGlobalIconsContext()

    return (
      <Tooltip
        content={
          <P>
            <span>
              <FontAwesomeIcon
                icon="bed"
                style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
              />
              {`${item.request.beds} + ${item.request.extraBeds}`}
            </span>
            {!!item.request.features.length && (
              <span style={{ marginLeft: `${theme.spacing.gu(2)}rem` }}>
                {[...item.request.features]
                  .sort(generateCompareFn('name'))
                  .map((feature: ElasticFeature) => (
                    <Fragment key={feature.id}>
                      {checkIconValidity(feature.icon) ? (
                        <FontAwesomeIcon
                          fixedWidth
                          icon={feature.icon as IconProp}
                          style={{ marginRight: theme.spacing.gutterSmall }}
                        />
                      ) : (
                        <ShortName>{feature.shortName}</ShortName>
                      )}
                    </Fragment>
                  ))}
              </span>
            )}
          </P>
        }
        trigger={(triggerProps) => (
          <Wrapper {...triggerProps} offset={leftOffset} onClick={onClick}>
            <ReservationBg
              color={color}
              className="draggable-item"
              isFocused={isFocused}
              isTranslucent={isTranslucent}
              variant={variant}
            >
              <>
                <VerticalLine color={color} variant={variant} />
                {item.request.checkIn.type === CheckInTypes.EARLY && (
                  <FontAwesomeIcon
                    icon="sun"
                    color={pickColor(color, variant, theme)}
                    size="sm"
                    style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
                  />
                )}
              </>
              <Text color={color} variant={variant}>
                {item.sales.customerName ?? ''}{' '}
                <Link
                  color={pickColor(color, variant, theme)}
                  href={`/sales/details/${item.sales.id}?view[0]=accommodation`}
                >
                  #{item.sales.number}
                </Link>
              </Text>
              <Right style={{ alignItems: 'center' }}>
                {item.request.checkOut.type === CheckOutTypes.LATE && (
                  <FontAwesomeIcon
                    icon="moon"
                    color={pickColor(color, variant, theme)}
                    size="xs"
                    style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                  />
                )}
                <VerticalLine color={color} variant={variant} />
              </Right>
            </ReservationBg>
          </Wrapper>
        )}
      />
    )
  }
)

const pickColor = (color: string, variant: Variant, theme: Theme) =>
  variant !== 'filled' ? color : theme.palette.white

const pickFillColor = (color: string, variant: Variant, theme: Theme) =>
  variant === 'filled' ? color : theme.palette.white

const ReservationBg = styled.div<{
  color: string
  isFocused?: boolean
  isTranslucent?: boolean
  variant: Variant
}>`
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 3px;

  background: ${({ color, theme, variant }) =>
    pickFillColor(color, variant, theme)};
  border-radius: 4px;
  border: 1px solid ${({ color }) => color};
  transition: 0.2s ease-in;
  cursor: grab;

  ${({ theme }) => css`
    box-shadow: 0 0 0 1px ${theme.palette.white};
  `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      box-shadow:
        0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.2);
    `}

  ${({ isTranslucent }) =>
    isTranslucent &&
    css`
      opacity: 0.5;
    `}
`

const Link = styled.a<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ShortName = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const Text = styled.span<{ color: string; variant: Variant }>`
  overflow: hidden;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  color: ${({ color, theme, variant }) => pickColor(color, variant, theme)};
`

const VerticalLine = styled.div<{ color: string; variant: Variant }>`
  height: 18px;
  width: 1px;
  background: ${({ color, theme, variant }) =>
    pickColor(color, variant, theme)};
`

const Wrapper = styled.div<{ offset: number }>`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1px;
  margin-right: ${({ offset }) => offset * 2}px;
`
