import { gql } from '@apollo/client'

export const SetSalesFacetMutation = gql`
  mutation SetSalesFacet($input: SalesUpdateFacetInput!) {
    salesUpdateFacet(input: $input) {
      id
      facet {
        abbreviation
        id
        name
        salesType
      }
    }
  }
`
