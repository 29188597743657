import { useRef } from 'react'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { WarningBar } from '@/components/WarningBar'
import { T, useLanguageContext } from '@/modules/Language'
import { useCancelSaleMutation } from '@/modules/Sales/mutations'

import { SalesSystemState } from '~generated-types'

import { useSalesDetailsContext } from '../SalesDetailsState'
import { CancelDescription } from './Header/components/Actions'

export const WarningBanners = () => {
  const { confirm } = useDialogService()
  const { language } = useLanguageContext()

  const {
    data: {
      id: salesId,
      lifecycle: {
        specifier,
        state: { systemState },
        stateOptions,
      },
    },
    saleReadOnly,
    refreshVatEnabled,
    refreshProductsVat,
    refreshingProductsVat,
  } = useSalesDetailsContext()

  const selectedCancelReason = useRef<null | string>(null)

  const [cancelSale] = useCancelSaleMutation()

  const isCancelProcessState = systemState === SalesSystemState.CancelProcess
  const cancelAction = stateOptions.find(
    ({ state }) => state.systemState === SalesSystemState.Cancelled
  )

  const isCancelSaleValid = isCancelProcessState && cancelAction?.valid

  const handleCancelSale = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.continue</T>
        </DangerColor>
      ),
      description: (
        <CancelDescription
          language={language}
          setSeletedReason={(reason: string) =>
            (selectedCancelReason.current = reason)
          }
          specifier={specifier}
        />
      ),
      title: <T>SalesDetails:Lifecycle.title.CANCEL</T>,
    })
      .then(() =>
        cancelSale({
          variables: {
            input: { salesId, specifier: selectedCancelReason.current },
          },
        })
      )
      .catch(() => null)
      .finally(() => (selectedCancelReason.current = null))

  return (
    <>
      {refreshVatEnabled && (
        <WarningBar
          actionColor="primary"
          actionLabel={<T>SalesDetails:warning.outdatedVat.action</T>}
          indicationMessage={<T>SalesDetails:warning.outdatedVat.guidance</T>}
          infoMessage={<T>SalesDetails:warning.outdatedVat.info</T>}
          loading={refreshingProductsVat}
          onClick={() => refreshProductsVat(salesId)}
          readOnly={saleReadOnly}
        />
      )}

      {isCancelSaleValid && (
        <WarningBar
          actionColor="danger"
          actionLabel={<T>SalesDetails:warning.cancelSale.action</T>}
          infoMessage={<T>SalesDetails:warning.cancelSale.info</T>}
          loading={refreshingProductsVat}
          onClick={handleCancelSale}
        />
      )}
    </>
  )
}
