import accommodationTargets from './accommodationTargets'
import webshopAvailableScopes from './webshopAvailableScopes'
import webshopContacts from './webshopContacts'
import webshopRooms from './webshopRooms'
import webshopThemes from './webshopThemes'

export const publishQueries = Object.freeze({
  accommodationTargets,
  webshopAvailableScopes,
  webshopContacts,
  webshopRooms,
  webshopThemes,
})
