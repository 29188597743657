import { gql, useMutation } from '@apollo/client'

import type {
  UpdateDocumentUserAttributeMutation,
  UpdateDocumentUserAttributeMutationVariables,
} from '~generated-types'

const UPDATE_DOCUMENT_USER_ATTRIBUTE_MUTATION = gql`
  mutation UpdateDocumentUserAttribute(
    $input: DocumentUserAttributeUpdateInput!
  ) {
    documentUserAttributeUpdate(input: $input) {
      userAttribute {
        key
        name
        editorValue
        templateValue
        active
      }
    }
  }
`

export const useUpdateDocumentUserAttributeMutation = () =>
  useMutation<
    UpdateDocumentUserAttributeMutation,
    UpdateDocumentUserAttributeMutationVariables
  >(UPDATE_DOCUMENT_USER_ATTRIBUTE_MUTATION)
