import styled, { css } from 'styled-components/macro'

interface Props {
  flexDirection?: string
  last?: boolean
}

export const FormSection = styled.div<Props>`
  display: flex;
  padding: 0;

  &:first-child {
    padding-top: 0;
  }

  ${({ flexDirection }) => css`
    flex-direction: ${flexDirection ? flexDirection : 'column'};
  `}

  ${({ last, theme }) => css`
    margin-bottom: ${last ? '' : `${theme.spacing.gu(4)}rem`};
  `}
`
