import { gql, useMutation } from '@apollo/client'

import {
  SetVstSignerMutation,
  SetVstSignerMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation SetVstSigner($input: SalesVstSetSignerInput!) {
    salesVstSetSigner(input: $input) {
      firstName
      id
      lastName
      sortOrder
    }
  }
`

export const useSetVstSignerMutation = () =>
  useMutation<SetVstSignerMutation, SetVstSignerMutationVariables>(MUTATION)
