import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { IconButton as IconButtonBase } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  SalesDetailsForPrintPayload as QueryPayload,
  SalesDetailsForPrintVariables as QueryVariables,
  salesQueries,
} from '@/modules/Sales/queries'

import { Loader, ModalSheet, Placeholder } from '../../../common'
import { SalesModalContent } from './SalesModalContent'

type Props = {
  id: string | null
  onClose: () => void
  showPrices: boolean
}

const QUERY_CONFIG = {
  withAccommodation: true,
  withMeals: true,
  withParticipants: true,
  withReservations: true,
  withTasks: true,
}

export const SalesModal = ({ id, onClose, showPrices }: Props) => {
  const { data, error, loading } = useQuery<QueryPayload, QueryVariables>(
    salesQueries.SALES_DETAILS_FOR_PRINT,
    {
      fetchPolicy: 'network-only',
      skip: !id,
      variables: { id: id ?? '', ...QUERY_CONFIG },
    }
  )

  const renderContent = useMemo(() => {
    if (loading) {
      return <Loader compact />
    }

    if (!!error) {
      return (
        <Placeholder
          compact
          content={<T>Mobile:sales.error</T>}
          icon="circle-exclamation"
        />
      )
    }

    return data && <SalesModalContent data={data} showPrices={showPrices} />
  }, [data, loading, error])

  return (
    <ModalSheet isOpen={!!id}>
      <Header alignItems="center">
        <IconButton
          color="transparent"
          icon="arrow-left"
          noNudge
          onClick={onClose}
        />
        <Title>
          <T>Mobile:sales.title</T>
        </Title>
      </Header>

      {renderContent}
    </ModalSheet>
  )
}

/////

const Header = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const IconButton = styled(IconButtonBase)`
  justify-content: center;
  height: 32px;
  width: 32px;
  border: none;
  font-size: 1.5rem;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  &&:hover, &&:focus, &&:active {
    background: transparent;
  }
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
