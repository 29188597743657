import { useQuery } from '@apollo/client'

import { salesQueries, SalesStatesPayload } from '../queries'
import { SalesStatesByKey } from '../types'
import { getStateLabels } from '../utils'

type SalesStates = SalesStatesPayload['registry']['salesStates']
export const useSalesStates = () => {
  const { data, error, loading, refetch } = useQuery<SalesStatesPayload>(
    salesQueries.SALES_STATES,
    {
      fetchPolicy: 'cache-first',
    }
  )

  const salesStates: SalesStates | null = data
    ? data.registry.salesStates
    : null

  const salesStatesByKey: SalesStatesByKey | null = data
    ? data.registry.salesStates.reduce(
        (acc, { names, key }) => ({
          ...acc,
          [key]: getStateLabels(names),
        }),
        {}
      )
    : null

  return {
    error,
    loading,
    refetch,
    salesStates,
    salesStatesByKey,
  }
}
