import styled from 'styled-components/macro'

import InputBase from '../elements/InputBase'

const Select = styled(InputBase).attrs(() => ({
  as: 'select',
}))<any>`
  -webkit-appearance: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
`

export default Select
