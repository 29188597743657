import { gql } from '@apollo/client'

export default gql`
  query DimensionShortcuts($target: DimensionTarget) {
    registry {
      dimensionShortcuts(target: $target) {
        id
        name
      }
    }
  }
`
