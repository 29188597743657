import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { InlineModal } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { ageCategoryHooks } from '@/modules/Registry/AgeCategory'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  AgeCategoryFragment as AgeCategory,
  ConditionRuleType,
} from '~generated-types'

import { Button } from './common'

type Props = {
  ageCategories: AgeCategory[]
  id: string
}

export const AgeCategorySelector = ({ ageCategories, id }: Props) => {
  const { palette, spacing } = useTheme()
  const { readOnly, updateProductItemRule } = useSalesProductManagerContext()

  const { ageCategories: allAgeCategories, loading } =
    ageCategoryHooks.useAgeCategories()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedAgeCategories, setSelectedAgeCategories] =
    useState<AgeCategory[]>(ageCategories)

  const handleSetRuleValue = () =>
    updateProductItemRule({
      condition: {
        ageCategoryIds: selectedAgeCategories.map(({ key }) => key),
        ruleType: ConditionRuleType.AgeCategory,
      },
      productRuleId: id,
    }).then(() => setModalOpen(false))

  const handleUpdateAgeCategories = (ageCategory: AgeCategory) =>
    setSelectedAgeCategories(
      selectedAgeCategories.find(({ key }) => key === ageCategory.key)
        ? selectedAgeCategories.filter(({ key }) => key !== ageCategory.key)
        : [...selectedAgeCategories, ageCategory]
    )

  const renderAgeCategories = () =>
    ageCategories.length === allAgeCategories.length ? (
      <T>Products:ProductManager.pricing.rule.all</T>
    ) : (
      [...ageCategories]
        .sort(generateCompareFn('sortOrder'))
        .map(({ name }) => name)
        .join(', ')
    )

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <Section>
            {[...allAgeCategories]
              .sort(generateCompareFn('sortOrder'))
              .map((category, idx) => {
                const isSelected = !!selectedAgeCategories.find(
                  ({ key }) => key === category.key
                )

                return (
                  <>
                    {idx !== 0 && <Spacer />}

                    <CheckboxInput
                      checked={isSelected}
                      disabled={readOnly}
                      noMargin
                      onChange={() => handleUpdateAgeCategories(category)}
                    >
                      <CheckboxLabel>{category.name}</CheckboxLabel>
                    </CheckboxInput>
                  </>
                )
              })}
          </Section>
        </InlineModal>
      }
      onClose={handleSetRuleValue}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          disabled={readOnly || loading}
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setModalOpen(true)}
        >
          {loading ? (
            <ReactLoading
              color={palette.smoke.dark}
              height={spacing.gutter}
              type="spin"
              width={spacing.gutter}
            />
          ) : ageCategories.length ? (
            renderAgeCategories()
          ) : (
            <FontAwesomeIcon
              color={palette.primary.main}
              fixedWidth
              size="sm"
              icon="plus"
            />
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

///////

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`
