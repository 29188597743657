import { SalesForPrintResourceReservation } from '../../../../../types'

export const getDimensionValues = (
  input: SalesForPrintResourceReservation['dimensions']
) => {
  const dimensions =
    input && Array.isArray(input && input.selection) ? input.selection : []

  return dimensions
    .map((x) => !!x && !!x.selectedLabel && x.selectedLabel.name)
    .filter((x) => !!x)
}
