import { gql, useMutation } from '@apollo/client'

import type {
  AddPurchaseFromProductMutation,
  AddPurchaseFromProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productPurchase}

  mutation AddPurchaseFromProduct(
    $input: PurchaseProductAddFromProductTitleInput!
  ) {
    purchaseProductAddFromProductTitle(input: $input) {
      ...ProductPurchase
    }
  }
`

export const useAddPurchaseFromProductMutation = () =>
  useMutation<
    AddPurchaseFromProductMutation,
    AddPurchaseFromProductMutationVariables
  >(MUTATION)
