import styled, { css } from 'styled-components/macro'

interface Props {
  noGrow?: boolean
  noShrink?: boolean
  width?: number | string
}

export const ListItemColumn = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: ${({ width }) => (width !== undefined ? `0 0 ${width}` : 'auto')};

  ${({ noGrow }) =>
    noGrow
      ? css`
          flex-grow: 0;
        `
      : ''}
  ${({ noShrink }) =>
    noShrink
      ? css`
          flex-shrink: 0;
        `
      : ''}

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
    padding-right: ${theme.spacing.gutter};
    padding-bottom: ${theme.spacing.gu(1)}rem;
    padding-left: ${theme.spacing.gu(1)}rem;
  `}
`
