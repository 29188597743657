import { CSSProperties, ReactNode, useState } from 'react'
import { Resizable } from 're-resizable'

import { ensureInteger } from '../helpers'
import { usePresentationState } from '../PresentationState'

type Offset = {
  left: number
  right: number
}

type Props = {
  children: ReactNode
  columnIndexes: number[]
  externalStyles: CSSProperties
  onResizeEnd: (renderProps: {
    rowIndexes: number[]
    columnIndexes: number[]
  }) => void
  offset: Offset
  position: {
    x: number
    y: number
  }
  rowIndexes: number[]
}

export const ItemResizable = ({
  children,
  columnIndexes,
  externalStyles,
  offset,
  onResizeEnd: onResizeEndCallback,
  position,
  rowIndexes,
}: Props) => {
  const [resizeLeftDelta, setResizeLeftDelta] = useState(0)
  const [resizeTopDelta, setResizeTopDelta] = useState(0)
  const {
    rowHeight,
    columnWidth,
    minColumnSpan = 1,
    minRowSpan = 1,
    processing,
  } = usePresentationState()

  const itemLeftCoordinate = position.x - resizeLeftDelta + offset.left
  const itemTopCoordinate = position.y - resizeTopDelta
  const transform = `translate3d(${itemLeftCoordinate}px, ${itemTopCoordinate}px, 0)`
  const positionStyles = {
    pointerEvents: processing ? 'none' : 'auto',
    transform,
    WebkitTransform: transform,
  }

  const onResizeEnd = (e: any, delta: any, action: any) => {
    const rowDelta = ensureInteger(delta.height / rowHeight)
    const columnDelta = ensureInteger(delta.width / columnWidth)
    const [nextRowIndexes, nextColumnIndexes] = getNewColumnIndexes(
      rowIndexes,
      columnIndexes,
      rowDelta,
      columnDelta,
      action
    )

    onResizeEndCallback({
      columnIndexes: nextColumnIndexes,
      rowIndexes: nextRowIndexes,
    })
  }

  const minWidth =
    columnWidth * minColumnSpan + offset.left && offset.right
      ? offset.left + offset.right + 60
      : columnWidth

  return (
    <ResizableItem
      grid={[columnWidth, rowHeight]}
      minHeight={rowHeight * minRowSpan}
      minWidth={minWidth}
      offset={offset}
      onResizeEnd={onResizeEnd}
      onResize={({ verticalDelta, horizontalDelta }) => {
        setResizeTopDelta(verticalDelta)
        setResizeLeftDelta(horizontalDelta)
      }}
      originalSize={{
        height: rowIndexes.length * rowHeight,
        width: columnIndexes.length * columnWidth,
      }}
      style={{ ...positionStyles, ...externalStyles }}
    >
      {children}
    </ResizableItem>
  )
}

type ResizeAction =
  | 'DEC_TOP'
  | 'ADD_TOP'
  | 'DEC_RIGHT'
  | 'ADD_RIGHT'
  | 'DEC_BOTTOM'
  | 'ADD_BOTTOM'
  | 'DEC_LEFT'
  | 'ADD_LEFT'
  | 'NO_CHANGE'

type ResizableItemProps = {
  children: ReactNode
  style: {
    [key: string]: any
  }
  originalSize: {
    width: number | string
    height: number | string
  }
  minWidth: number
  minHeight: number
  grid: [number, number]
  offset: Offset
  onResizeEnd: (
    e: MouseEvent | TouchEvent,
    delta: {
      width: number
      height: number
    },
    action: ResizeAction
  ) => void
  onResize: (arg0: { horizontalDelta: number; verticalDelta: number }) => void
}

const ResizableItem = ({
  children,
  style,
  originalSize,
  minWidth,
  minHeight,
  grid,
  offset,
  onResizeEnd,
  onResize,
}: ResizableItemProps) => {
  const defaultResizeDirections = {
    bottom: true,
    left: true,
    right: true,
    top: true,
  }
  const { itemResizeDirections = defaultResizeDirections } =
    usePresentationState()

  return (
    <Resizable
      handleStyles={{
        left: { left: 0, zIndex: 10003 },
        right: { right: offset.left + offset.right, zIndex: 10003 },
      }}
      style={style}
      size={originalSize}
      onResizeStart={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      onResize={(e, direction, component, d) => {
        e.stopPropagation()
        e.preventDefault()

        if (direction === 'top' || direction === 'left') {
          onResize({ horizontalDelta: d.width, verticalDelta: d.height })
        }
      }}
      onResizeStop={(e, direction, component, delta) => {
        if (direction === 'top' || direction === 'left') {
          onResize({ horizontalDelta: 0, verticalDelta: 0 })
        }

        const action: ResizeAction = ((direction, columnDelta, rowDelta) => {
          if (rowDelta < 0 && direction === 'top') {
            return 'DEC_TOP'
          }

          if (rowDelta > 0 && direction === 'top') {
            return 'ADD_TOP'
          }

          if (columnDelta < 0 && direction === 'right') {
            return 'DEC_RIGHT'
          }

          if (columnDelta > 0 && direction === 'right') {
            return 'ADD_RIGHT'
          }

          if (rowDelta < 0 && direction === 'bottom') {
            return 'DEC_BOTTOM'
          }

          if (rowDelta > 0 && direction === 'bottom') {
            return 'ADD_BOTTOM'
          }

          if (columnDelta < 0 && direction === 'left') {
            return 'DEC_LEFT'
          }

          if (columnDelta > 0 && direction === 'left') {
            return 'ADD_LEFT'
          }

          return 'NO_CHANGE'
        })(direction, delta.width, delta.height)

        if (action === 'NO_CHANGE') {
          return
        }

        onResizeEnd(e, delta, action)
      }}
      grid={grid}
      minWidth={minWidth}
      minHeight={minHeight}
      enable={{
        bottom: itemResizeDirections.bottom,
        bottomLeft: false,
        bottomRight: false,
        left: itemResizeDirections.left,
        right: itemResizeDirections.right,
        top: itemResizeDirections.top,
        topLeft: false,
        topRight: false,
      }}
    >
      {children}
    </Resizable>
  )
}

function getNewColumnIndexes(
  rowIndexes: number[],
  columnIndexes: number[],
  rowDelta: number,
  columnDelta: number,
  action: ResizeAction
): [number[], number[]] {
  if (action === 'DEC_TOP') {
    return [rowIndexes.slice(Math.abs(rowDelta)), columnIndexes]
  }

  if (action === 'ADD_TOP') {
    const nextFirstRowIndex = rowIndexes[0] - rowDelta
    const nextRowCount = rowIndexes.length + rowDelta
    const nextRows = Array.from(
      { length: nextRowCount },
      (x, i) => nextFirstRowIndex + i
    )

    return [nextRows, columnIndexes]
  }

  if (action === 'DEC_RIGHT') {
    const nextLastColumnIndex = columnIndexes.length - Math.abs(columnDelta)

    return [rowIndexes, columnIndexes.slice(0, nextLastColumnIndex)]
  }

  if (action === 'ADD_RIGHT') {
    const startColumnIndex = columnIndexes[0]
    const nextColumnCount = columnIndexes.length + columnDelta
    const nextColumns = Array.from(
      { length: nextColumnCount },
      (x, i) => i + startColumnIndex
    )

    return [rowIndexes, nextColumns]
  }

  if (action === 'DEC_BOTTOM') {
    const nextLastRowIndex = rowIndexes.length - Math.abs(rowDelta)

    return [rowIndexes.slice(0, nextLastRowIndex), columnIndexes]
  }

  if (action === 'ADD_BOTTOM') {
    const nextFirstRowIndex = rowIndexes[0]
    const nextRowCount = rowIndexes.length + rowDelta
    const nextRows = Array.from(
      { length: nextRowCount },
      (x, i) => nextFirstRowIndex + i
    )

    return [nextRows, columnIndexes]
  }

  if (action === 'DEC_LEFT') {
    return [rowIndexes, columnIndexes.slice(Math.abs(columnDelta))]
  }

  if (action === 'ADD_LEFT') {
    const nextStartColumnIndex = columnIndexes[0] - columnDelta
    const nextColumnCount = columnIndexes.length + columnDelta
    const nextColumns = Array.from(
      { length: nextColumnCount },
      (x, i) => i + nextStartColumnIndex
    )

    return [rowIndexes, nextColumns]
  }

  return [[], []]
}
