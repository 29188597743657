import { useQuery } from '@apollo/client'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import type {
  ReservationsForDateQuery as ReservationsForDate,
  ReservationsForDateQueryVariables as ReservationsForDateVariables,
} from '~generated-types'

import { RefetchResponce } from '../../../../AvailabilityCalendar.types'
import {
  ReservationsContainer,
  RoomReservations,
  RoomTypeReservations,
} from './components'
import { ReservationsForDateQuery } from './ReservationsForDate.query'

type RoomType = {
  id: string
  name: string
}

type Props = {
  dateKey: string
  roomTypes: RoomType[]
  refetchAvailability: () => Promise<RefetchResponce>
}

export const ReservationsInfo = ({
  dateKey,
  roomTypes,
  refetchAvailability,
}: Props) => {
  const { palette } = useTheme()

  const { data, loading } = useQuery<
    ReservationsForDate,
    ReservationsForDateVariables
  >(ReservationsForDateQuery, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        date: dateKey,
        roomTypeIds: roomTypes.map((roomType) => roomType.id),
      },
    },
  })

  return data ? (
    <FlexColumn noPadding>
      <ReservationsContainer
        reservations={data.accommodationRoomTypeReservations.reservations.filter(
          (r) => !r.fulfilledByRooms
        )}
        title={<T>Accommodation:AvailabilityCalendar.roomTypeReservations</T>}
        renderSaleReservations={(reservations) => (
          <RoomTypeReservations
            reservations={reservations}
            refetchAvailability={refetchAvailability}
          />
        )}
      />
      <Spacer />
      <ReservationsContainer
        reservations={data.accommodationRoomReservations.reservations}
        title={<T>Accommodation:AvailabilityCalendar.roomReservations</T>}
        renderSaleReservations={(reservations) => (
          <RoomReservations reservations={reservations} />
        )}
      />
    </FlexColumn>
  ) : loading ? (
    <ReactLoading
      color={palette.smoke.dark}
      height={14}
      type="bubbles"
      width={14}
    />
  ) : (
    <T>common:error.common</T>
  )
}

const Spacer = styled.div`
  width: 100%;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0;
    border-bottom: 2px solid ${theme.palette.smoke.light};
  `}
`
