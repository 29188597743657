import { CSSProperties, forwardRef, ReactNode, Ref } from 'react'
import styled, { keyframes } from 'styled-components/macro'

import { Canvas } from '@/components/Canvas'

type Props = {
  children: ReactNode
  style?: CSSProperties
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: initial;
  }
`

const Transition: any = styled.div`
  animation: ${appearFromLeft} 0.2s forwards;
`

export const InlineModal = forwardRef<Props, typeof Transition>(
  ({ children, style = {}, ...rest }: Props, ref) => (
    <Transition style={style}>
      <Card
        {...rest}
        zIndex={2}
        ref={ref as Ref<HTMLDivElement>}
        style={{ ...style }}
      >
        {children}
      </Card>
    </Transition>
  )
)

const Card = styled(Canvas)`
  border-radius: 6px;
  max-height: 92vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  transition: ease all 300ms;
`
