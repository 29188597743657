import { useMemo } from 'react'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { Option, ThemedMultiSelect } from '@/components/ThemedSelect'
import { useRoomReservationsContext } from '@/modules/FrontDesk'
import { T } from '@/modules/Language'
import { buildingHooks } from '@/modules/Registry/Building'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

export const BuildingFilter = () => {
  const { spacing } = useTheme()
  const { buildings, loading } = buildingHooks.useBuildings()
  const {
    buildings: selectedBuildings,
    layoutVariables: { SIDEBAR_WIDTH },
    setBuildings: setSelectedBuildings,
  } = useRoomReservationsContext()

  const options: Option[] = useMemo(
    () =>
      buildings
        .map(({ id, name }) => ({
          label: name,
          searchValue: name,
          value: id,
        }))
        .sort(generateCompareFn('label')),
    [buildings]
  )

  return (
    <FilterSection
      label={<T>FrontDesk:DailyRoomReservations.building.title</T>}
      render={() => (
        <ThemedMultiSelect
          loading={loading}
          modalWidth={`calc(${SIDEBAR_WIDTH}px - ${spacing.gu(4)}rem - 1px)`}
          noOptionsPlaceholder={
            <T>FrontDesk:DailyRoomReservations.building.noOptions</T>
          }
          options={options}
          placeholder={<T>FrontDesk:DailyRoomReservations.all</T>}
          selectedValues={selectedBuildings}
          setSelectedValues={setSelectedBuildings}
          withSearch
          withSelectAll
        />
      )}
    />
  )
}
