import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import Note from '@/components/TextNoteModal'
import { Tooltip } from '@/components/Tooltip'
import { ValidationIndicator } from '@/components/ValidationIndicator'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'
import { getIssueColor, useTheme } from '@/theme'

import { IssueLevel } from '~generated-types'

import { useReservationListContext } from '../../ReservationListState'
import {
  GroupTarget,
  Reservation,
  SalesRoomReservation,
  SalesRoomTypeReservationWithRoomReservations as SalesRoomTypeReservation,
} from '../../SalesReservationList.types'
import {
  Beds,
  CleaningStatus,
  Features,
  FullfilledCheckBox,
  LineLeader,
  Participants,
  RoomNote,
  TargetSelector,
  Title,
  TotalRoomTypeBeds,
  VisitDates,
} from './components'

type Props = {
  reservation: Reservation
  isFulfilled?: boolean
  isNested?: boolean
  hasNested?: boolean
  isLastNested?: boolean
  targets: GroupTarget[]
  readOnly?: boolean
}

export const ReservationRow = ({
  reservation,
  isFulfilled,
  isNested,
  hasNested,
  isLastNested,
  targets,
  readOnly,
}: Props) => {
  const { palette } = useTheme()

  const {
    id,
    request: { beds, extraBeds, features, checkIn, checkOut, info: notes },
    target,
  } = reservation

  const roomReservation = reservation as SalesRoomReservation
  const roomTypeReservation = reservation as SalesRoomTypeReservation

  const roomTypeIsFullfilled = roomTypeReservation.fulfilledByRooms

  const nonBlockingCapacity = targets.find(
    ({ id }) => id === target?.id
  )?.nonBlockingCapacity

  const {
    setNeeds,
    handleTargetRoom,
    handleTargetRoomType,
    handleUpdateDates,
    setFulfilledByRooms,
  } = useReservationListContext()

  const handleSetFulfilledByRooms = () =>
    setFulfilledByRooms({
      fulfilledByRooms: !roomTypeIsFullfilled,
      reservationId: id,
    })

  const handleUpdateNote = (note: string) =>
    setNeeds({
      featureIds: features.map((f: RoomFeature) => f.id),
      note,
      reservationId: id,
    })

  const handleUpdateFeatures = (featureIds: string[]) =>
    setNeeds({
      featureIds,
      note: notes,
      reservationId: id,
    })

  const handleChangeTarget = (targetId: string, rooms?: number) =>
    reservation.__typename === 'RoomTypeReservation'
      ? handleTargetRoomType({
          rooms,
          roomTypeReservationId: id,
          targetId,
        })
      : handleTargetRoom({
          roomReservationId: id,
          targetId,
        })

  const getParticipantsAmount = () => {
    const getUniqueParticipantsAmount = (
      participants: SalesRoomReservation['participantRooms']
    ) => new Set(participants.map(({ participant }) => participant.id)).size

    switch (reservation.__typename) {
      case 'RoomReservation':
        return getUniqueParticipantsAmount(roomReservation.participantRooms)
      case 'RoomTypeReservation':
        return roomTypeReservation.roomReservations.reduce(
          (acc: number, r) =>
            acc + getUniqueParticipantsAmount(r.participantRooms),
          0
        )
      default:
        return 0
    }
  }

  const roomTitle =
    reservation.__typename !== 'RoomTypeReservation' ? (
      <>
        {`#${reservation.request.room.number} | `}
        <small>{reservation.request.room.roomType.name}</small>
      </>
    ) : (
      `${reservation.request.rooms} ✕ ${reservation.request.roomType.name}`
    )

  const issueLevel = roomReservation.issues?.reduce(
    (acc: IssueLevel | null, { level }) => {
      if (level === IssueLevel.Error) {
        return level
      }

      if (level === IssueLevel.Warning && !acc) {
        return level
      }

      return acc
    },
    null
  )

  const reservationBackground = nonBlockingCapacity
    ? '#F7F7F7'
    : issueLevel && getIssueColor(issueLevel, palette).background

  return (
    <Wrapper noMarginBottom={hasNested || (isNested && !isLastNested)}>
      {isNested && <LineLeader fullHeight={!isLastNested} />}

      <ReservationRowWrapper
        background={reservationBackground}
        isNested={isNested}
        hasNested={hasNested}
        isLastNested={isLastNested}
      >
        {!isNested && (
          <FullfilledCheckBox
            isRoomType={reservation.__typename === 'RoomTypeReservation'}
            isFullfilled={roomTypeIsFullfilled}
            isDisabled={
              (reservation.__typename === 'RoomTypeReservation' &&
                !reservation.roomReservations.length) ||
              readOnly
            }
            handleSetFulfilledByRooms={handleSetFulfilledByRooms}
          />
        )}

        <ReservationTitle>
          {reservation.__typename === 'RoomReservation' && (
            <RoomNote
              id={reservation.request.room.id}
              note={reservation.request.room.internalInfo}
            />
          )}

          <Tooltip
            content={roomTitle}
            trigger={(triggerProps) => (
              <span {...triggerProps}>
                {reservation.__typename !== 'RoomTypeReservation' ? (
                  <>
                    {`#${reservation.request.room.number} | `}
                    <small>{reservation.request.room.roomType.name}</small>
                  </>
                ) : (
                  `${reservation.request.rooms} ✕ ${reservation.request.roomType.name}`
                )}
              </span>
            )}
          />
        </ReservationTitle>

        <IconWrapper>
          {reservation.__typename !== 'RoomTypeReservation' && (
            <CleaningStatus
              checkInDate={checkIn.date}
              checkOutDate={checkOut.date}
              cleaningStatus={reservation.request.room.status.cleaningStatus}
            />
          )}
        </IconWrapper>

        <VisitDates
          checkIn={checkIn}
          checkOut={checkOut}
          iconWrapper={IconWrapper}
          handleUpdateDates={(dates) =>
            handleUpdateDates({
              ...dates,
              reservationId: reservation.id,
            })
          }
        />

        <VerticalDivider />

        <BedsWrapper>
          {reservation.__typename === 'RoomTypeReservation' && (
            <Beds
              beds={beds}
              extraBeds={extraBeds}
              reservationId={id}
              totalBeds={
                (reservation.request.roomType.beds || 0) *
                reservation.request.rooms
              }
              totalExtraBeds={
                (reservation.request.roomType.extraBeds || 0) *
                reservation.request.rooms
              }
              readOnly={readOnly}
            />
          )}
        </BedsWrapper>

        <VerticalDivider />

        {reservation.__typename === 'RoomTypeReservation' ? (
          <TotalRoomTypeBeds
            fulfilledByRooms={reservation.fulfilledByRooms}
            roomReservations={reservation.roomReservations}
          />
        ) : (
          <Beds
            beds={beds}
            extraBeds={extraBeds}
            isFulfilled={isFulfilled}
            isNested={isNested}
            reservationId={id}
            totalBeds={reservation.request.room.beds}
            totalExtraBeds={reservation.request.room.extraBeds}
            readOnly={readOnly}
          />
        )}

        <VerticalDivider />

        <span style={{ flex: 1 }} />

        <VerticalDivider />

        {!!roomReservation.issues?.length && (
          <>
            {roomReservation.issues.map(({ code, key, level }) => (
              <ValidationIndicator code={code} key={key} level={level} />
            ))}
            <VerticalDivider />
          </>
        )}

        <Participants
          participantsAmount={getParticipantsAmount()}
          totalBeds={Number(beds) + Number(extraBeds) || 0}
        />

        <VerticalDivider />

        <Features
          selectedFeatures={features}
          readOnly={readOnly}
          handleUpdateFeatures={handleUpdateFeatures}
        />

        <VerticalDivider />

        <Note
          note={notes || ''}
          updateNote={handleUpdateNote}
          readOnly={readOnly}
        />

        <VerticalDivider />

        <TargetSelector
          targets={targets}
          roomTypeRooms={
            reservation.__typename === 'RoomTypeReservation'
              ? reservation.request.rooms
              : undefined
          }
          readOnly={readOnly}
          isNested={isNested}
          isLast={isNested ? isLastNested : !hasNested}
          targetAccommodation={handleChangeTarget}
        />
      </ReservationRowWrapper>
    </Wrapper>
  )
}

export default ReservationRow

const Wrapper = styled(FlexRow)<{
  noMarginBottom?: boolean
}>`
  height: 28px;

  ${({ noMarginBottom, theme }) =>
    !noMarginBottom &&
    `
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const ReservationRowWrapper = styled.div<{
  background: string | null
  isNested?: boolean
  hasNested?: boolean
  isLastNested?: boolean
}>`
  border: 1px solid
    ${({ isNested, theme }) =>
      isNested ? theme.palette.smoke.main : theme.palette.smoke.dark};
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ background }) => background && `background: ${background};`}

  ${({ isNested }) =>
    isNested &&
    `
    border-top: 0;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  `}

  ${({ hasNested }) =>
    hasNested &&
    `
    border-bottom-right-radius: 0px;
  `}

  ${({ isNested, isLastNested }) =>
    isNested &&
    !isLastNested &&
    `
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  `}
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    width: ${theme.spacing.gutterBig};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.light};
  `}
`

const BedsWrapper = styled.div`
  align-self: stretch;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(25)}rem;
  `}
`

const ReservationTitle = styled(Title)`
  display: flex;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
    max-width: ${theme.spacing.gu(26)}rem;
    width: ${theme.spacing.gu(26)}rem;
  `}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
