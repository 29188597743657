import styled, { css } from 'styled-components/macro'

import P from '../P'

const Description = styled(P)`
  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(75)}rem;
    margin-bottom: ${theme.spacing.gutter};
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
  `}

  font-weight: 300;
  line-height: 1.45;
`

export default Description
