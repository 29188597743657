import { ReactNode } from 'react'
import Select from 'react-select'

import { useTheme } from '@/theme'

import { getCustomCompactStyles, getCustomStyles, getTheme } from './common'
import { filterOption } from './filterOption'
import { ChangeAction, Option, OptionsGroup } from './types'

type SingleSelectAction = {
  action: ChangeAction
  name: string
  option: null
}

type Props = {
  autoFocus?: boolean
  blurInputOnSelect?: boolean
  className?: string
  classNamePrefix?: string
  disabled?: boolean
  extraStyles?: Record<string, unknown>
  formatGroupLabel?: (group: { [key: string]: any }) => JSX.Element
  isClearable?: boolean
  isCompact?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isSearchable?: boolean
  loadingMessage?: () => ReactNode
  menuIsOpen?: boolean
  menuPortalTarget?: HTMLElement
  name: string
  noOptionsMessage?: () => ReactNode
  onBlur?: () => void
  onMenuOpen?: () => void
  onChange: (
    selectedValue: Option | null | undefined,
    onChangeAction: SingleSelectAction
  ) => void
  options: (Option | OptionsGroup)[]
  placeholder: ReactNode
  value?: Option | null | undefined
}

export const ThemedSelect = ({ extraStyles, isCompact, ...rest }: Props) => {
  const theme = useTheme()

  return (
    // @ts-ignore
    <Select
      filterOption={filterOption}
      isMulti={false}
      styles={
        isCompact
          ? { ...getCustomCompactStyles(theme), ...extraStyles }
          : { ...getCustomStyles(theme), ...extraStyles }
      }
      theme={getTheme(theme)}
      {...rest}
    />
  )
}
