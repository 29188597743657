import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'

import {
  ParticipantSortProperty as Property,
  SalesType,
  SortOrder as Order,
} from '~generated-types'

import { useParticipantsListContext } from '../../ParticipantsListState'

export const SortBy = () => {
  const { saleType, sort, setSort } = useParticipantsListContext()

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const eventOptions =
    saleType === SalesType.Event
      ? [
          {
            label: <T>{`ParticipantsList:sorting.type.${Property.Sales}`}</T>,
            value: Property.Sales,
          },
        ]
      : []

  const options = [
    {
      label: <T>{`ParticipantsList:sorting.type.${Property.LastName}`}</T>,
      value: Property.LastName,
    },
    {
      label: (
        <T>{`ParticipantsList:sorting.type.${Property.FirstRoomNumber}`}</T>
      ),
      value: Property.FirstRoomNumber,
    },
    ...eventOptions,
  ]

  const handleSetSortProperty = (field: Property) => {
    setSort([{ field, order: Order.Asc }])
    setIsEditing(false)
  }

  const handleSetSortOrder = (field: Property) => {
    setSort([
      {
        field,
        order: sort[0].order === Order.Asc ? Order.Desc : Order.Asc,
      },
    ])
  }

  return (
    <ModalContainer
      isOpen={isEditing}
      modal={
        <InlineModal>
          <OptionsWrapper>
            {options.map((option) => (
              <FlexRow key={option.value}>
                <Option
                  onClick={() => handleSetSortProperty(option.value)}
                  style={{ flex: 1 }}
                >
                  {option.label}
                </Option>
                <Option onClick={() => handleSetSortOrder(option.value)}>
                  <FontAwesomeIcon
                    icon={
                      sort[0].field === option.value
                        ? sort[0].order === Order.Asc
                          ? 'caret-down'
                          : 'caret-up'
                        : 'caret-down'
                    }
                  />
                </Option>
              </FlexRow>
            ))}
          </OptionsWrapper>
        </InlineModal>
      }
      onClose={() => setIsEditing(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <InnocuousButton
          innerRef={ref as Ref<HTMLButtonElement> | undefined}
          noNudge
          onClick={() => setIsEditing(true)}
          style={{ textTransform: 'unset' }}
        >
          <PrimaryColor>
            <Icon icon="sort" size="sm" />
            {sort[0] ? (
              <>
                <T>{`ParticipantsList:sorting.type.${sort[0].field}`}</T> (
                <T>
                  {`ParticipantsList:sorting.order.${
                    sort[0].order === Order.Asc ? 'ASC' : 'DESC'
                  }`}
                </T>
                )
              </>
            ) : (
              <Placeholder>
                <T>ParticipantsList:sorting.title</T>
              </Placeholder>
            )}
          </PrimaryColor>
        </InnocuousButton>
      )}
    />
  )
}

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`

const OptionsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Option = styled.div`
  transition: 0.2s;
  cursor: pointer;
  user-select: none;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;

    &:hover {
      background-color: ${theme.palette.smoke.main};
    }
  `}
`

const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.extraDark};
  `}
`
