import { useQuery } from '@apollo/client'

import type {
  SalesProductsCompactQuery as QueryData,
  SalesProductsCompactQueryVariables as QueryVariables,
} from '~generated-types'

import { productQueries } from '../queries'
import { SalesProductCompact } from '../types'

type Params = {
  salesId: string
  skip?: boolean
}

export const useSalesProductsCompact = ({ salesId, skip }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    productQueries.GET_SALES_PRODUCTS_COMPACT,
    {
      fetchPolicy: 'cache-and-network',
      skip,
      variables: { id: salesId },
    }
  )

  const products: SalesProductCompact[] = data?.sales.products || []

  return {
    error,
    loading,
    products,
    refetch,
  }
}
