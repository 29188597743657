import { gql } from '@apollo/client'

export default gql`
  query SalesStateReasons {
    registry {
      salesStateReasons {
        key
        names {
          locale
          content
        }
      }
    }
  }
`
