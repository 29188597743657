import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { TreeRow } from '@/components/TreeSelector'
import { T } from '@/modules/Language'
import { Dimension, DimensionLabel } from '@/modules/Registry/Dimension'

import CollapsedDimensionSelectorValue from './CollapsedDimensionSelectorValue'
import DimensionNode from './DimensionNode'
import transformSelectionToElasticQuery from './transformSelectionToElasticQuery'
import transformSelectionToSelectorValue from './transformSelectionToSelectorValue'
import {
  DimensionLabelKey,
  DimensionLabelKeyMap,
  DimensionSelection,
} from './types'

interface Props {
  componentId: string
  dimensions: Dimension[]
  documentCounts: {
    [key: string]: number
  }
  hideCounts?: boolean
  hideEmpty?: boolean
  isCollapsed?: boolean
  setQuery: (...args: Array<any>) => any
  value: DimensionSelection
}

const DimensionFilter: FC<Props> = ({
  componentId,
  dimensions,
  documentCounts,
  hideCounts,
  hideEmpty,
  isCollapsed,
  setQuery,
  value,
}): ReactElement => {
  const isFirstRender = useRef<boolean>(true)

  const [selection, setSelection] = useState<DimensionSelection>(value)

  const dimensionKeyMap = dimensions.reduce(
    (acc: DimensionLabelKeyMap, { labels, name }) => ({
      ...acc,
      [name]: labels.reduce(
        (acc: DimensionLabelKey, { id, name }) => ({
          ...acc,
          [name]: id,
        }),
        {}
      ),
    }),
    {}
  )

  useEffect(() => {
    // Update the query on first renders (initialisation)
    if (isFirstRender.current) {
      isFirstRender.current = false
      setQuery(getQuery(dimensionKeyMap, value))
    }

    // Include this to react to Clear Filters -actions
    if (JSON.stringify(value) !== JSON.stringify(selection)) {
      setQuery(getQuery(dimensionKeyMap, value))
      setSelection(value)
    }
  }, [JSON.stringify(value)])

  if (isCollapsed) {
    return (
      <CollapsedDimensionSelectorValue
        componentId={componentId}
        placeholder={<T>Reactivesearch:DimensionSelector.empty</T>}
        selection={selection}
      />
    )
  }

  return (
    <Wrapper>
      {!dimensions.length && (
        <TreeRow>
          <ErrorLabel>
            <T>Reactivesearch:DimensionSelector.error</T>
          </ErrorLabel>
        </TreeRow>
      )}
      {!!dimensions.length &&
        dimensions.map((dimension) => (
          <DimensionNode
            data={dimension}
            documentCounts={documentCounts}
            hideCounts={hideCounts}
            hideEmpty={hideEmpty}
            key={`dimension-node-${dimension.id}`}
            openInitially={dimensions.length === 1}
            selection={selection}
            toggleDimensionLabel={(
              dimension: Dimension,
              label: DimensionLabel
            ) => {
              const next = updateSelection(value, dimension, label)

              setQuery(getQuery(dimensionKeyMap, next))
              setSelection(next)
            }}
          />
        ))}
    </Wrapper>
  )
}

export default DimensionFilter

////////////

const ErrorLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  font-style: italic;
`

const getQuery = (
  labelKeys: DimensionLabelKeyMap,
  selection: DimensionSelection
) => {
  const query = transformSelectionToElasticQuery(labelKeys, selection)
  const value = transformSelectionToSelectorValue(selection)

  return query ? { query, value } : { value }
}

const updateSelection = (
  selection: DimensionSelection,
  { name: dName }: Dimension,
  { name: lName }: DimensionLabel
): DimensionSelection => {
  const next = { ...selection }

  next[dName] = next[dName] || []

  if (next[dName].includes(lName)) {
    next[dName] = next[dName].filter((x) => x !== lName)
  } else {
    next[dName].push(lName)
  }

  return next
}

const Wrapper = styled.div`
  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;

    @media (max-width: 900px) {
      font-size: ${theme.typography.fontSizeBase2};
    }
  `}

  border-radius: 4px;
  max-height: 500px;
  overflow: auto;
`
