export const CheckInTypes = Object.freeze({
  EARLY: 'EARLY',
  LATE: 'LATE',
  STANDARD: 'STANDARD',
})

export const CheckOutTypes = Object.freeze({
  LATE: 'LATE',
  STANDARD: 'STANDARD',
})

export type CheckInType = keyof typeof CheckInTypes
export type CheckOutType = keyof typeof CheckOutTypes
