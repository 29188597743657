import { gql, useMutation } from '@apollo/client'

import {
  UpdateResourceMutation,
  UpdateResourceMutationVariables,
} from '~generated-types'

import ResourceReservationResourceFragment from '../fragments/resourceReservationResource'

export type { UpdateResourceMutation, UpdateResourceMutationVariables }

export const UPDATE_RESOURCE_MUTATION = gql`
  ${ResourceReservationResourceFragment}

  mutation UpdateResource($input: ResourceUpdateInput!) {
    resourceUpdate(input: $input) {
      ...ResourceReservationResource
    }
  }
`

export function useUpdateResourceMutation() {
  return useMutation<UpdateResourceMutation, UpdateResourceMutationVariables>(
    UPDATE_RESOURCE_MUTATION
  )
}

export default useUpdateResourceMutation
