import styled, { css } from 'styled-components/macro'

import { LineLeader } from './LineLeader'

export const ResourceDisplayNamePlaceholder = () => (
  <Wrapper>
    <LineLeader fullHeight isPlaceholder />
  </Wrapper>
)

////////////

const Wrapper = styled.div`
  height: 100%;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(3.5)}rem;
  `}
`
