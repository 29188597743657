import styled from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

type Props = {
  alignItems?: string
  justifyContent?: string
  width?: number
  withBorder?: boolean
}

export const Cell = styled(FlexRow)<Props>`
  height: 20px;

  align-items: ${({ alignItems }) => alignItems || 'center'}};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'}};
  width: ${({ theme, width }) => theme.spacing.gu(width || 18)}rem;

  border-right: ${({ theme, withBorder }) =>
    withBorder ? `1px solid ${theme.palette.smoke.light}` : 'none'};
`
