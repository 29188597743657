import { gql } from '@apollo/client'

export default gql`
  fragment SalesPublisedProducts on SalesProduct {
    id
    name
    settings {
      accommodationGroup {
        id
        name
      }
      published
      purchaseDates {
        end
        start
      }
    }
  }
`
