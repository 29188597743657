import { FlexColumn, FlexRow } from '@/components/Layout'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { MarginalTax } from '@/modules/Products/types'

import { AmountInput } from './AmountInput'
import { DeleteIcon, Title } from './common'

type Props = {
  id: string
  internalPurchase: number
  marginalTax: MarginalTax | null
}

export const InternalPurchaseSection = ({
  id,
  internalPurchase,
  marginalTax,
}: Props) => {
  const { readOnly, updateProductItem } = useSalesProductManagerContext()
  const { isSalesInternalPriceEnabled } = useWillbaConfigContext()

  return (
    <FlexColumn>
      <Title>
        <T>Products:ProductManager.pricing.internalPurchase</T>
      </Title>

      <FlexRow alignItems="center">
        <AmountInput
          amount={internalPurchase}
          disabled={readOnly || !isSalesInternalPriceEnabled}
          updateAmount={(amount) =>
            updateProductItem({ id, internalPurchase: amount })
          }
        />

        {!marginalTax && !readOnly && isSalesInternalPriceEnabled && (
          <DeleteIcon
            icon="xmark"
            onClick={() => updateProductItem({ id, internalPurchase: null })}
          />
        )}
      </FlexRow>
    </FlexColumn>
  )
}
