import React from 'react'
import { darken } from 'polished'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

import { useReservationListContext } from '../../../ReservationListState'
import { BedsInput } from './BedsInput'

type UpdateRoomBedsInput = {
  beds: number
  extraBeds: number
  reservationId: string
}

type Props = {
  beds: number
  extraBeds: number
  isFulfilled?: boolean
  isNested?: boolean
  reservationId: string
  totalBeds: number
  totalExtraBeds: number
  readOnly?: boolean
  updateRoomBeds?: (input: UpdateRoomBedsInput) => Promise<any>
}

export const Beds = ({
  beds,
  extraBeds,
  updateRoomBeds: extarnalUpdateBedQuantity,
  isFulfilled,
  isNested,
  reservationId,
  totalBeds,
  totalExtraBeds,
  readOnly,
}: Props) => {
  const { palette } = useTheme()

  const { updateBedQuantity } = useReservationListContext()

  const getInputColor = (isFulfilled?: boolean, isNested?: boolean) =>
    isNested
      ? isFulfilled
        ? palette.success.main
        : darken(0.1, palette.gold)
      : null

  const handleUpdateBeds = ({
    bedsValue,
    extraBedsValue,
  }: {
    bedsValue?: number
    extraBedsValue?: number
  }) => {
    const input = {
      beds: bedsValue ?? beds,
      extraBeds: extraBedsValue ?? extraBeds,
      reservationId,
    }

    return extarnalUpdateBedQuantity
      ? extarnalUpdateBedQuantity(input)
      : updateBedQuantity(input)
  }

  return (
    <Wrapper background={getInputColor(isFulfilled, isNested)}>
      <CountWrapper>
        <BedsInput
          value={beds}
          availableAmount={totalBeds}
          readOnly={readOnly}
          textColor={getInputColor(isFulfilled, isNested)}
          icon="bed"
          onUpdate={(bedsValue) => handleUpdateBeds({ bedsValue })}
        />
      </CountWrapper>
      <Plus background={getInputColor(isFulfilled, isNested)}>{' + '}</Plus>
      <CountWrapper>
        <BedsInput
          value={extraBeds}
          availableAmount={totalExtraBeds}
          readOnly={readOnly}
          textColor={getInputColor(isFulfilled, isNested)}
          icon="couch"
          onUpdate={(extraBedsValue) => handleUpdateBeds({ extraBedsValue })}
        />
      </CountWrapper>
    </Wrapper>
  )
}

/////

const Wrapper = styled.div<{ background: string | null }>`
  display: flex;
  justify-content: center;
  align-self: stretch;
  height: 100%;
  color: ${({ background }) => background};
`

const CountWrapper = styled.div`
  text-align: center;
`

const Plus = styled.span<{ background: string | null }>`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(1)}rem;
  `}
  color: ${({ background }) => background};
  text-align: center;
  align-self: center;
`
