import { ReactNode, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment-timezone'
import styled, { css } from 'styled-components/macro'

import { LinkLikeButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { useTheme } from '@/theme'

import CheckTime from './CheckTime'

type Props = {
  time: string | null
  icon: IconProp
  placeholder: ReactNode
  handleUpdateTime: (time: null | string) => Promise<void>
  setTimeState: (time: null | string) => void
  disabled?: boolean
  dateToUpdate: string | null
  defaultHour: number
  isToday: boolean
}

export const CheckContainer = ({
  time,
  icon,
  placeholder,
  handleUpdateTime,
  setTimeState,
  disabled,
  dateToUpdate,
  defaultHour,
  isToday,
}: Props) => {
  const { palette, spacing, typography } = useTheme()

  const { timezone } = useWillbaConfigContext()

  const [isUnsaved, setUnsaved] = useState(false)

  const transformDate = (date: Moment) => ({
    date: date,
    time: {
      hour: date.format('HH'),
      minute: date.format('mm'),
    },
  })

  const handleSaveTime = () => {
    handleUpdateTime(time)
    setUnsaved(false)
  }

  return time ? (
    <FlexRow alignItems="center">
      <FontAwesomeIcon icon={icon} color={palette.success.main} />
      <CheckTime
        checkTimeValue={transformDate(moment(time))}
        setCheckTime={(time) => {
          setTimeState(time)
          setUnsaved(true)
        }}
      />
      {isUnsaved ? (
        <IconWrapper onClick={handleSaveTime} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon="check" color={palette.success.main} />
        </IconWrapper>
      ) : (
        <IconWrapper
          onClick={() => handleUpdateTime(null)}
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon="xmark" color={palette.danger.main} />
        </IconWrapper>
      )}
    </FlexRow>
  ) : (
    <LinkLikeButton
      style={{
        padding: `${spacing.gu(1)}rem ${spacing.gu(1.5)}rem`,
      }}
      onClick={() =>
        handleUpdateTime(
          isToday
            ? moment().tz(timezone).format('YYYY-MM-DDTHH:mm:ss')
            : moment(dateToUpdate)
                .set({ hour: defaultHour })
                .format('YYYY-MM-DDTHH:mm:ss')
        )
      }
      disabled={disabled}
    >
      <FlexRow alignItems="center">
        <IconWrapper
          style={{
            cursor: 'pointer',
            width: `${typography.fontSizeBaseInPx + spacing.guPx(1)}px`,
          }}
        >
          <FontAwesomeIcon icon={icon} color={palette.gold} />
        </IconWrapper>
        <Placeholder>{placeholder}</Placeholder>
      </FlexRow>
    </LinkLikeButton>
  )
}

const Placeholder = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `};
`

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`
