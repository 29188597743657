import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLazyQuery } from '@apollo/client'
import moment, { Moment } from 'moment'
import query from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { ById } from '@/common/types'
import {
  CalendarNestedResourceElastic,
  CalendarResourceElastic,
  CalendarResourcesService,
} from '@/modules/Registry/CalendarResources'
import {
  resourceReservationMutations,
  resourceReservationQueries,
} from '@/modules/Reservations/ResourceReservation'
import { generateCompareFn } from '@/utils/arrays'

import {
  ResourceReservationResourcesBySalesQuery as ResourcesQuery,
  ResourceReservationResourcesBySalesQueryVariables as ResourcesVariables,
} from '~generated-types'

type ReservationWithResource =
  ResourcesQuery['sales']['resourceReservations'][0]

export type CalendarViewMode = 'DATE' | 'WEEK'

export type Resource = CalendarNestedResourceElastic | CalendarResourceElastic

export type CategorySelection = {
  active: boolean
  categoryPaths: string[]
  id: string
  isRestrictedMode: boolean
  label: string | null | undefined
  resourceIds: string[]
}

/**
 * Clarification on contents:
 *  - selectedCategories  = category queries (filters)
 *  - selectedResources   = specifically selected resources (filters)
 *  - focusedResources    = resources for the target owner (for the interval)
 *  - nonFocusedResources = combination of resources from active selectedCategories &
 *                          selectedResources
 *  - targetResources      = combination of resources from focused & nonFocused
 *  - allResources         = all available resources in the system
 */
type ContextType = {
  // Data
  allResources: CalendarResourceElastic[]
  allResourcesById: ById<CalendarResourceElastic>
  error: Error | null
  fetching: boolean
  focusedResources: CalendarResourceElastic[]
  isReservationsLocked: boolean
  nonFocusedResources: CalendarResourceElastic[]
  ownerId: string | null
  reservationInterval: {
    end: Moment
    start: Moment
  }
  selectedCategories: CategorySelection[]
  selectedResources: CalendarResourceElastic[]
  showFocusedResources: boolean
  targetDate: Moment
  targetResources: Resource[]
  targetResourcesById: ById<CalendarResourceElastic>
  viewMode: CalendarViewMode

  // Methods
  addCategorySelection: () => CategorySelection
  addResourceSelection: (resourceId: string) => void
  refresh: (...args: Array<any>) => any
  removeCategorySelection: (selectionId: string) => void
  removeResourceSelection: (resourceId: string) => void
  setReservationsLocked: (isReservationsLocked: boolean) => void
  setShowFocusedResources: (showFocusedResources: boolean) => void
  setTargetDate: (targetDate: Moment) => void
  setViewMode: (targetMode: CalendarViewMode) => void
  updateCategorySelection: (
    id: string,
    update: {
      [key: string]: any
    }
  ) => void
  updateResourceNote: (
    id: string,
    note: string,
    parentResourceId?: string
  ) => Promise<void | undefined>
}

const CalendarContext = createContext<ContextType>({
  addCategorySelection: () => getEmptyCategorySelection(),
  addResourceSelection: () => undefined,
  allResources: [],
  allResourcesById: {},
  error: new Error('Uninitialised calendar context'),
  fetching: false,
  focusedResources: [],
  isReservationsLocked: false,
  nonFocusedResources: [],
  ownerId: null,
  refresh: () => undefined,
  removeCategorySelection: () => undefined,
  removeResourceSelection: () => undefined,
  reservationInterval: {
    end: moment(),
    start: moment(),
  },
  selectedCategories: [],
  selectedResources: [],
  setReservationsLocked: () => undefined,
  setShowFocusedResources: () => undefined,
  setTargetDate: () => undefined,
  setViewMode: () => undefined,
  showFocusedResources: false,
  targetDate: moment(),
  targetResources: [],
  targetResourcesById: {},
  updateCategorySelection: () => undefined,
  updateResourceNote: Promise.reject,
  viewMode: 'DATE',
})

export type CalendarStateProviderProps = {
  initialDate?: Moment
  ownerId: string | null
  URLParams?: boolean
}

type ProviderProps = CalendarStateProviderProps & {
  children: ReactNode
}

export const CalendarStateProvider = ({
  children,
  initialDate,
  ownerId,
  URLParams,
}: ProviderProps) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const calendarId = ownerId || 'DEFAULT'

  const contextValueRef = useRef<ContextType | null | undefined>(null)

  const [loadResourcesForOwner, resourcesForOwner] = useLazyQuery<
    ResourcesQuery,
    ResourcesVariables
  >(resourceReservationQueries.RESOURCE_RESERVATION_RESOURCES_BY_SALES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const [updateResource] =
    resourceReservationMutations.useUpdateResourceMutation()

  /* --- URL PARSING --- */

  const searchObject = query.parse(search, {
    arrayFormat: 'comma',
  })
  const {
    date: searchDate,
    mode: searchMode,
    resources: searchResourceIds,
  } = searchObject

  const parsedSearchDate = searchDate
    ? moment(String(searchDate)).startOf('day')
    : undefined
  const today = moment().startOf('day')

  /* --- STATE DATA --- */

  const [refreshTicker, setRefreshTicker] = useState<number>(0)

  const [resourcesError, setResourcesError] = useState<Error | null>(null)
  const [allResourcesById, setAllResourcesById] = useState<
    ById<CalendarResourceElastic>
  >({})
  const [allResourcesReady, setAllResourcesReady] = useState<boolean>(false)
  const [focusedResourcesReady, setFocusedResourcesReady] =
    useState<boolean>(false)

  const [focusedResourcesIds, setFocusedResourcesIds] = useState<string[]>([])
  const [selectedCategories, setSelectedCategories] = useState<
    CategorySelection[]
  >([])
  const [selectedResourceIds, setSelectedResourceIds] = useState<
    Array<string | null>
  >(
    searchResourceIds
      ? Array.isArray(searchResourceIds)
        ? searchResourceIds
        : [searchResourceIds]
      : []
  )

  const [showFocusedResources, setShowFocusedResources] =
    useState<boolean>(true)

  const [targetDate, setTargetDate] = useState<Moment>(
    parsedSearchDate || initialDate || today
  )

  const [viewMode, setViewMode] = useState<CalendarViewMode>(
    searchMode === 'WEEK' ? 'WEEK' : 'DATE'
  )

  const [isReservationsLocked, setReservationsLocked] = useState<boolean>(true)

  const [end, setEnd] = useState<Moment>(targetDate)
  const [start, setStart] = useState<Moment>(targetDate)

  const focusedResources = [
    ...new Set(
      focusedResourcesIds.map(
        (id) =>
          Object.values(allResourcesById).find((val) => {
            if (val.id === id) {
              return true
            }
            if (val.nestedResources?.length) {
              return !!val.nestedResources.find((r) => r.id === id)
            }
            return false
          }) as CalendarResourceElastic
      )
    ),
  ]
    .filter(Boolean)
    .sort(generateCompareFn('name'))

  const focusedResourcesById = focusedResources.reduce(
    (acc, val) => ({ ...acc, [val.id]: val }),
    {}
  )

  const selectedResources = selectedResourceIds
    .map((id) => allResourcesById[`${id}`])
    .filter(Boolean)
    .sort(generateCompareFn('name'))

  const selectedResourcesById = selectedResources.reduce(
    (acc, val) => ({ ...acc, [val.id]: val }),
    {}
  )

  const selectedCategoryResourcesById = selectedCategories
    .filter(({ active }) => active)
    .flatMap(({ resourceIds }) => resourceIds)
    .map((id) => allResourcesById[id])
    .filter(Boolean)
    .reduce((acc, val) => ({ ...acc, [val.id]: val }), {})

  const nonFocusedResourcesById = {
    ...selectedCategoryResourcesById,
    ...selectedResourcesById,
  }

  const nonFocusedResources = Object.keys(nonFocusedResourcesById)
    .map(
      (id) =>
        nonFocusedResourcesById[id as keyof typeof nonFocusedResourcesById]
    )
    .sort(generateCompareFn('name'))

  const targetResourcesById = {
    ...focusedResourcesById,
    ...nonFocusedResourcesById,
  }

  const targetResources = Object.keys(targetResourcesById)
    .map((id) => targetResourcesById[id as keyof typeof targetResourcesById])
    .reduce((acc: Resource[], resource: CalendarResourceElastic) => {
      acc.push(resource)
      resource.nestedResources?.map((res) => acc.push(res))
      return acc
    }, [])
    .sort(generateCompareFn('name'))

  /* --- STATE METHODS --- */

  const addCategorySelection = (): CategorySelection => {
    const selection = getEmptyCategorySelection()

    setSelectedCategories((current) => {
      const next = [...current, selection]

      storeCategorySelections(calendarId, next)

      return next
    })

    return selection
  }

  const addResourceSelection = (resourceId: string) =>
    setSelectedResourceIds((current) =>
      current.includes(resourceId) ? current : [...current, resourceId]
    )

  const refresh = () => setRefreshTicker((current) => current + 1)

  const removeCategorySelection = (selectionId: string) =>
    setSelectedCategories((current) => {
      const filtered = current.filter(({ id }) => id !== selectionId)

      storeCategorySelections(calendarId, filtered)

      return filtered
    })

  const removeResourceSelection = (resourceId: string) =>
    setSelectedResourceIds((current) =>
      current.filter((id) => id !== resourceId)
    )

  const updateCategorySelection = (
    id: string,
    update: {
      [key: string]: any
    }
  ) =>
    setSelectedCategories((current) => {
      const selectionIdx = current.findIndex((x) => x.id === id)
      const next = [...current]

      if (selectionIdx < 0) {
        console.warn('Invalid category selection id')
        return next
      }

      next[selectionIdx] = { ...current[selectionIdx], ...update }
      storeCategorySelections(calendarId, next)

      return next
    })

  const updateResourceNote = (
    id: string,
    note: string,
    parentResourceId?: string
  ) =>
    updateResource({ variables: { input: { id, internalInfo: note } } })
      .then(({ data }) => {
        if (data) {
          const newInternalInfo = data.resourceUpdate.internalInfo ?? undefined

          if (parentResourceId) {
            const parentResource = allResourcesById[parentResourceId]
            const nestedResources = parentResource.nestedResources ?? []

            setAllResourcesById({
              ...allResourcesById,
              [parentResourceId]: {
                ...parentResource,
                nestedResources: nestedResources.map((nr) =>
                  nr.id === id ? { ...nr, internalInfo: newInternalInfo } : nr
                ),
              },
            })
          } else {
            setAllResourcesById({
              ...allResourcesById,
              [id]: {
                ...allResourcesById[id],
                internalInfo: newInternalInfo,
              },
            })
          }
        }
      })
      .catch(() => undefined)

  /* --- LIFECYCLE HOOKS --- */

  useEffect(() => {
    setEnd(
      viewMode === 'WEEK'
        ? targetDate.clone().endOf('isoWeek')
        : targetDate.clone().endOf('day')
    )
    setStart(
      viewMode === 'WEEK'
        ? targetDate.clone().startOf('isoWeek')
        : targetDate.clone().startOf('day')
    )
  }, [targetDate, viewMode])

  // Manage URL
  useEffect(() => {
    const updateSearch = (date: Moment, mode: CalendarViewMode) => {
      // TODO Add categories to the path

      const prevSearch = search.slice(1)
      const nextSearch = query.stringify(
        {
          date: date.format('YYYY-MM-DD'),
          mode,
          resources: selectedResourceIds,
        },
        { arrayFormat: 'comma' }
      )

      if (prevSearch !== nextSearch) {
        history.replace(`${pathname}?${nextSearch}`)
      }
    }

    if (URLParams) {
      updateSearch(targetDate, viewMode)
    }
  }, [
    history,
    pathname,
    search,
    selectedResourceIds,
    targetDate,
    URLParams,
    viewMode,
  ])

  // Resolve details for all resources
  useEffect(() => {
    CalendarResourcesService.findAll()
      .then((resources) =>
        setAllResourcesById(
          resources.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
        )
      )
      .catch((err) => setResourcesError(err))
      .finally(() => setAllResourcesReady(true))
  }, [refreshTicker])

  useEffect(() => {
    const { data, loading, error } = resourcesForOwner

    const resourcesIds =
      data?.sales.resourceReservations.reduce(
        (ids: string[], { resource }: ReservationWithResource) =>
          resource ? [...ids, resource.id] : ids,
        []
      ) ?? []

    error &&
      console.warn(
        'Failed to resolve pre-existing reservation resource ids',
        error
      )

    setFocusedResourcesIds(
      resourcesIds.length ? [...new Set(resourcesIds)] : []
    )

    !loading && !error && setFocusedResourcesReady(true)
  }, [resourcesForOwner])

  // Resolve details for focused reservations (i.e. group or owner)
  useEffect(() => {
    if (ownerId) {
      loadResourcesForOwner({
        variables: {
          dates: {
            end: end.format('YYYY-MM-DD'),
            start: start.format('YYYY-MM-DD'),
          },
          id: ownerId,
        },
      })
    } else {
      setFocusedResourcesReady(true)
    }
  }, [end, ownerId, refreshTicker, start])

  // Load stored category selections
  useEffect(() => {
    const storedSelections = loadStoredCategorySelections(calendarId)

    setSelectedCategories(
      storedSelections.length ? storedSelections : [getEmptyCategorySelection()]
    )
  }, [calendarId])

  const allResources = Object.keys(allResourcesById)
    .map((id) => allResourcesById[id])
    .sort(generateCompareFn('name'))

  contextValueRef.current = {
    addCategorySelection,
    addResourceSelection,
    allResources,
    allResourcesById,
    error: resourcesError,
    fetching: !allResourcesReady || !focusedResourcesReady,
    focusedResources,
    isReservationsLocked,
    nonFocusedResources,
    ownerId,
    refresh,
    removeCategorySelection,
    removeResourceSelection,
    reservationInterval: {
      end,
      start,
    },
    selectedCategories,
    selectedResources,
    setReservationsLocked,
    setShowFocusedResources,
    setTargetDate,
    setViewMode,
    showFocusedResources: !!ownerId && showFocusedResources,
    targetDate,
    targetResources,
    targetResourcesById,
    updateCategorySelection,
    updateResourceNote,
    viewMode,
  }

  return (
    <CalendarContext.Provider value={contextValueRef.current}>
      {children}
    </CalendarContext.Provider>
  )
}

export const useCalendarState = () => useContext(CalendarContext)

////////////

const getEmptyCategorySelection = (): CategorySelection => ({
  active: true,
  categoryPaths: [],
  id: uuidv4(),
  isRestrictedMode: false,
  label: null,
  resourceIds: [],
})

const loadStoredCategorySelections = (
  calendarId: string
): CategorySelection[] => {
  const CATEGORY_STORAGE_KEY = `selectedCategories-${calendarId}`

  try {
    const stored = JSON.parse(localStorage.getItem(CATEGORY_STORAGE_KEY) || '')

    if (!Array.isArray(stored)) {
      console.warn('Failed to parse stored category selections [not an array]')
      localStorage.removeItem(CATEGORY_STORAGE_KEY)
      return []
    }

    return stored.map((x) => ({
      active: !!x.active,
      categoryPaths: Array.isArray(x.categoryPaths) ? x.categoryPaths : [],
      id: x.id || uuidv4(),
      isRestrictedMode: x.isRestrictedMode,
      label: x.label,
      resourceIds: Array.isArray(x.resourceIds) ? x.resourceIds : [],
    }))
  } catch (err) {
    console.warn('Failed to parse stored category selections [parse error]')
    localStorage.removeItem(CATEGORY_STORAGE_KEY)
    return []
  }
}

const storeCategorySelections = (
  calendarId: string,
  selections: CategorySelection[]
) => {
  const CATEGORY_STORAGE_KEY = `selectedCategories-${calendarId}`

  localStorage.setItem(CATEGORY_STORAGE_KEY, JSON.stringify(selections))
}
