import { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ActionDropdown, ActionDropdownItem } from '@/components/ActionDropdown'
import { IconButton } from '@/components/ExtraButtons'
import {
  InputCell,
  RadioButtonCell,
  SpreadsheetTD,
  SpreadsheetTR,
} from '@/components/Spreadsheet'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { emailRegex } from '@/utils/forms'

import { Customer, OrganizationCustomerContact } from '../../../../types'

type Props = {
  data: OrganizationCustomerContact
  focusedColumn: null | number
  isDefault: boolean
  isSelected: boolean
  onPressKey: (cellIdx: number, key: string) => void
  onRemove: () => Promise<string | undefined>
  onSelect?: (id: string) => void
  onSetAsDefault: () => Promise<Partial<Customer> | undefined>
  onUpdate: (
    attribute: string,
    value: string
  ) => Promise<OrganizationCustomerContact | undefined>
}

export const ContactRow = ({
  data: { id, email, firstName, lastName, notes, phone },
  focusedColumn,
  isDefault,
  isSelected,
  onPressKey,
  onRemove,
  onSelect,
  onSetAsDefault,
  onUpdate,
}: Props) => {
  const { palette } = useTheme()

  const menuRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (focusedColumn === 6) {
      menuRef && menuRef.current && menuRef.current.focus()
    }
  }, [focusedColumn])

  const getBaseProps = (columnIdx: number) => ({
    focus: focusedColumn === columnIdx,
    onPressKey: (key: string) => onPressKey(columnIdx, key),
    placeholder: '—',
  })

  const handlePropChangeFn = (property: string) => (value: string) =>
    onUpdate(property, value)

  const actions: ActionDropdownItem[] = isDefault
    ? [
        {
          icon: 'trash',
          label: <T>Customers:contacts.remove</T>,
          onClick: onRemove,
        },
      ]
    : [
        {
          icon: 'bookmark',
          label: <T>Customers:contacts.setAsDefault</T>,
          onClick: onSetAsDefault,
        },
        {
          icon: 'trash',
          label: <T>Customers:contacts.remove</T>,
          onClick: onRemove,
        },
      ]

  return (
    <SpreadsheetTR>
      {!!onSelect && (
        <RadioButtonCell checked={isSelected} onChange={onSelect} value={id} />
      )}
      <InputCell
        {...getBaseProps(0)}
        endAdornment={
          isDefault ? (
            <Tooltip
              content={<T>Customers:contacts.default</T>}
              trigger={(triggerProps) => (
                <span {...triggerProps}>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="bookmark"
                  />
                </span>
              )}
            />
          ) : null
        }
        onSubmit={handlePropChangeFn('lastName')}
        type="text"
        value={lastName}
      />
      <InputCell
        {...getBaseProps(1)}
        onSubmit={handlePropChangeFn('firstName')}
        type="text"
        value={firstName}
      />
      <InputCell
        {...getBaseProps(2)}
        onSubmit={handlePropChangeFn('email')}
        type="text"
        validationFn={async (value) => (value ? emailRegex.test(value) : true)}
        value={email}
      />
      <InputCell
        {...getBaseProps(3)}
        onSubmit={handlePropChangeFn('phone')}
        type="text"
        value={phone}
      />
      <InputCell
        {...getBaseProps(4)}
        onSubmit={handlePropChangeFn('notes')}
        type="text"
        value={notes}
      />
      <SpreadsheetTD align="right">
        <ActionDropdown
          items={actions}
          renderToggle={({ toggleDropdown }) => (
            <IconButton
              icon="ellipsis-vertical"
              innerRef={menuRef}
              onClick={toggleDropdown}
              onKeyDown={({ key }) => onPressKey(6, key)}
              variant="borderless"
            />
          )}
        />
      </SpreadsheetTD>
    </SpreadsheetTR>
  )
}
