import {
  AccommodationLevelAvailability,
  RawRoomTypeAvailability,
} from '../AvailabilityCalendar.types'
import { combineDailyAvailabilities } from './combineDailyAvailabilities'
import { groupRawAvailabilityDataByDate } from './groupRawAvailabilityDataByDate'
import { groupRoomTypeAvailabilityByLevelsAndCategories } from './groupRoomTypeAvailabilityByLevelsAndCategories'

export const groupAvailabilityData = (
  input: RawRoomTypeAvailability[]
): AccommodationLevelAvailability[] => {
  const availabilityByDate = groupRawAvailabilityDataByDate(input)

  const groupedByLevelsAndCategories =
    groupRoomTypeAvailabilityByLevelsAndCategories(availabilityByDate)

  return groupedByLevelsAndCategories.map(
    ({ accommodationLevel, categories }) => {
      const categoriesWithStatus = categories.map(({ category, roomTypes }) => {
        const roomTypeAvailabilities = roomTypes.map(({ status }) => status)
        const status = combineDailyAvailabilities(roomTypeAvailabilities)

        return { category, roomTypes, status }
      })

      const categoryAvailabilities = categoriesWithStatus.map(
        ({ status }) => status
      )
      const status = combineDailyAvailabilities(categoryAvailabilities)

      return {
        accommodationLevel,
        categories: categoriesWithStatus,
        status,
      }
    }
  )
}
