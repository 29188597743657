import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ApolloCache, ApolloError } from '@apollo/client'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { FlexColumn } from '@/components/Layout'
import { notify } from '@/components/NotificationService'
import { T } from '@/modules/Language'

import {
  ConditionRuleType,
  ExtractPurchaseMutation,
  ExtractTargetRoom,
  ProductMealInput,
  ProductRuleUpdateInput,
  ProductUpdateItemInput,
  PurchaseProductAction,
  PurchaseProductUpdateInput,
  SalesProductUpdateInput,
  SalesType,
  TargetType,
} from '~generated-types'

import { useSalesProductById } from '../../hooks'
import {
  useAddPurchaseFromSalesProductMutation,
  useAddSalesProductItemMutation,
  useAddSalesProductItemRuleMutation,
  useCopySalesProductToCatalogMutation,
  useCopySalesProductToSalesMutation,
  useCreateSalesProductMutation,
  useDeletePurchaseMutation,
  useDeleteSalesProductItemMutation,
  useDeleteSalesProductItemRuleMutation,
  useDeleteSalesProductMutation,
  useExtractPurchaseMutation,
  useUpdatePurchaseMutation,
  useUpdateSalesProductItemMealMutation,
  useUpdateSalesProductItemMutation,
  useUpdateSalesProductItemRuleMutation,
  useUpdateSalesProductMutation,
} from '../../mutations'
import { productQueries } from '../../queries'
import {
  CatalogProduct,
  Context,
  ProductMeal,
  ProductPurchase,
  ProductRule,
  SalesProduct,
  SalesProductItem,
  SalesProductsPayload,
} from '../../types'

type ExtractPurchaseInput = {
  purchaseId: string
  quantity?: number
  rooms?: ExtractTargetRoom[]
}

type ContextType = {
  // Data
  commission: number | null
  context: Context
  error?: ApolloError
  isPricingLocked: boolean
  isSinglePurchase: boolean
  loading: boolean
  product: SalesProduct | null
  readOnly: boolean
  salesId: string
  salesReadOnly: boolean
  salesType: SalesType
  sellerId?: string
  targetPurchaseId?: string
  // Methods
  addProductItem: (
    productId: string,
    catalogProductId: string
  ) => Promise<SalesProductItem[] | undefined>
  addProductItemRule: (itemId: string) => Promise<ProductRule | undefined>
  addPurchase: (productId: string) => Promise<ProductPurchase | undefined>
  copyProduct: (productId: string) => Promise<SalesProduct | undefined>
  createProduct: (catalogProductId: string) => Promise<SalesProduct | undefined>
  deleteProduct: (productId: string) => Promise<string | undefined>
  deleteProductItem: (itemId: string) => Promise<string | undefined>
  deleteProductItemRule: (
    itemId: string,
    ruleId: string
  ) => Promise<string | undefined>
  deleteProductItemRuleWithConfirm: (
    itemId: string,
    ruleId: string
  ) => Promise<string | undefined>
  deleteProductItemWithConfirm: (itemId: string) => Promise<string | undefined>
  deleteProductWithConfirm: (productId: string) => Promise<string | undefined>
  deletePurchase: (purchaseId: string) => Promise<string | undefined>
  deletePurchaseWithConfirm: (purchaseId: string) => Promise<string | undefined>
  extractPurchase: (
    input: ExtractPurchaseInput
  ) => Promise<ExtractPurchaseMutation['purchaseProductExtract'] | undefined>
  saveProduct: (
    productId: string,
    catalogId: string
  ) => Promise<CatalogProduct | undefined>
  setPricingLocked: (isPricingLocked: boolean) => void
  updateProduct: (
    input: SalesProductUpdateInput
  ) => Promise<SalesProduct | undefined>
  updateProductItem: (
    input: ProductUpdateItemInput
  ) => Promise<SalesProductItem | undefined>
  updateProductItemMeal: (
    input: ProductMealInput
  ) => Promise<ProductMeal | undefined>
  updateProductItemRule: (
    input: ProductRuleUpdateInput
  ) => Promise<ProductRule | undefined>
  updatePurchase: (
    input: PurchaseProductUpdateInput
  ) => Promise<ProductPurchase | undefined>
}

const SalesProductManagerContext = createContext<ContextType>({
  addProductItem: () => Promise.reject(),
  addProductItemRule: () => Promise.reject(),
  addPurchase: () => Promise.reject(),
  commission: null,
  context: 'GLOBAL',
  copyProduct: () => Promise.reject(),
  createProduct: () => Promise.reject(),
  deleteProduct: () => Promise.reject(),
  deleteProductItem: () => Promise.reject(),
  deleteProductItemRule: () => Promise.reject(),
  deleteProductItemRuleWithConfirm: () => Promise.reject(),
  deleteProductItemWithConfirm: () => Promise.reject(),
  deleteProductWithConfirm: () => Promise.reject(),
  deletePurchase: () => Promise.reject(),
  deletePurchaseWithConfirm: () => Promise.reject(),
  error: undefined,
  extractPurchase: () => Promise.reject(),
  isPricingLocked: false,
  isSinglePurchase: false,
  loading: false,
  product: null,
  readOnly: false,
  salesId: '',
  salesReadOnly: false,
  salesType: SalesType.Sales,
  saveProduct: () => Promise.reject(),
  sellerId: undefined,
  setPricingLocked: () => undefined,
  targetPurchaseId: undefined,
  updateProduct: () => Promise.reject(),
  updateProductItem: () => Promise.reject(),
  updateProductItemMeal: () => Promise.reject(),
  updateProductItemRule: () => Promise.reject(),
  updatePurchase: () => Promise.reject(),
})

type Props = {
  commission: number | null
  children: ReactNode
  context: Context
  onClose: () => void
  product?: SalesProduct
  productId: string
  readOnly: boolean
  salesId: string
  salesType: SalesType
  sellerId?: string
  targetPurchaseId?: string
}

export const SalesProductManagerContextProvider = ({
  children,
  commission,
  context,
  onClose,
  product: externalProduct,
  productId,
  readOnly: salesReadOnly,
  salesId,
  salesType,
  sellerId,
  targetPurchaseId,
}: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { confirm } = useDialogService()

  const {
    error,
    loading: productsLoading,
    product,
    refetch,
  } = useSalesProductById({ id: productId, skip: !!externalProduct })

  const [loading, setLoading] = useState<boolean>(false)
  const [isPricingLocked, setPricingLocked] = useState<boolean>(
    !!targetPurchaseId
  )

  const sharedProductReadOnly = useMemo(() => {
    if (externalProduct) {
      return externalProduct.sales.id !== salesId
    }

    if (product) {
      return product.sales.id !== salesId
    }

    return false
  }, [externalProduct, product])

  const purchaseReadOnly = useMemo(() => {
    const currentProduct = externalProduct ?? product

    if (currentProduct) {
      return !!currentProduct.purchases.find(
        ({ status }) =>
          !status.validatedActions.find(
            (a) => a.action === PurchaseProductAction.Update
          )?.valid
      )
    }

    return false
  }, [externalProduct, product])

  const isSinglePurchase = useMemo(() => {
    const currentProduct = externalProduct ?? product

    if (currentProduct) {
      return currentProduct.purchases.length === 1
    }

    return false
  }, [externalProduct, product])

  const targetPurchaseReadOnly =
    !!targetPurchaseId && isPricingLocked && !isSinglePurchase

  const readOnly =
    salesReadOnly ||
    purchaseReadOnly ||
    sharedProductReadOnly ||
    targetPurchaseReadOnly

  // Product mutations
  const [createProductMutation] = useCreateSalesProductMutation()
  const [copyProductMutation] = useCopySalesProductToSalesMutation()
  const [deleteProductMutation] = useDeleteSalesProductMutation()
  const [saveProductMutation] = useCopySalesProductToCatalogMutation()
  const [updateProductMutation] = useUpdateSalesProductMutation()

  // Product item mutations
  const [addProductItemMutation] = useAddSalesProductItemMutation()
  const [deleteProductItemMutation] = useDeleteSalesProductItemMutation()
  const [updateProductItemMutation] = useUpdateSalesProductItemMutation()

  // Product meal mutations
  const [updateProductItemMealMutation] =
    useUpdateSalesProductItemMealMutation()

  // Product rule mutations
  const [addProductItemRuleMutation] = useAddSalesProductItemRuleMutation()
  const [deleteProductItemRuleMutation] =
    useDeleteSalesProductItemRuleMutation()
  const [updateProductItemRuleMutation] =
    useUpdateSalesProductItemRuleMutation()

  // Purchase mutations
  const [addPurchaseMutation] = useAddPurchaseFromSalesProductMutation()
  const [deletePurchaseMutation] = useDeletePurchaseMutation()
  const [extractPurchaseMutation] = useExtractPurchaseMutation()
  const [updatePurchaseMutation] = useUpdatePurchaseMutation()

  useEffect(() => {
    setLoading(productsLoading)
  }, [productsLoading])

  const addProductItem = (productId: string, catalogProductId: string) =>
    addProductItemMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: cachedSales.sales.products.map((p) =>
                p.id === productId
                  ? { ...p, items: [...p.items, ...data.salesProductAddItems] }
                  : p
              ),
            })
          )
        }
      },
      variables: { input: { catalogProductId, id: productId } },
    })
      .then(({ data }) => data?.salesProductAddItems)
      .catch(() => undefined)

  const addProductItemRule = (itemId: string) =>
    addProductItemRuleMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: cachedSales.sales.products.map((p) =>
                p.id === productId
                  ? {
                      ...p,
                      items: p.items.map((i) =>
                        i.id === itemId
                          ? {
                              ...i,
                              rules: [...i.rules, data.salesProductItemAddRule],
                            }
                          : i
                      ),
                    }
                  : p
              ),
            })
          )
        }
      },
      variables: {
        input: { id: itemId, ruleType: ConditionRuleType.Date },
      },
    })
      .then(({ data }) => data?.salesProductItemAddRule)
      .catch(() => undefined)

  const addPurchase = (productId: string) =>
    addPurchaseMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: cachedSales.sales.products.map((p) =>
                p.id === productId
                  ? {
                      ...p,
                      purchases: [
                        ...p.purchases,
                        data.purchaseProductAddFromSalesProduct,
                      ],
                    }
                  : p
              ),
            })
          )
        }
      },
      variables: {
        input: { add: { link: { salesId } }, salesProductId: productId },
      },
    })
      .then(({ data }) => data?.purchaseProductAddFromSalesProduct)
      .catch(() => undefined)

  const copyProduct = (productId: string) =>
    copyProductMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: [
                ...cachedSales.sales.products,
                data.salesProductCopyToSales,
              ],
            })
          )
        }
      },
      variables: { input: { id: productId, salesId } },
    })
      .then(({ data }) => {
        if (data) {
          notify({
            content: (
              <FlexColumn>
                <span style={{ fontWeight: 500, marginBottom: 2 }}>
                  {data.salesProductCopyToSales.name}
                </span>
                <T>Products:Notification.copyProduct</T>
              </FlexColumn>
            ),
            type: 'SUCCESS',
          })
        }

        return data?.salesProductCopyToSales
      })
      .catch(() => undefined)

  const createProduct = (catalogProductId: string) =>
    createProductMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: [
                ...cachedSales.sales.products,
                data.salesProductCreate,
              ],
            })
          )
        }
      },
      variables: { input: { catalogProductId, salesId } },
    })
      .then(({ data }) => data?.salesProductCreate)
      .catch(() => undefined)

  const deleteProduct = (productId: string) =>
    deleteProductMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'SalesProduct',
            id: data.salesProductDelete.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: productId },
    })
      .then(({ data }) => {
        onClose()
        return data?.salesProductDelete.id
      })
      .catch(() => undefined)

  const deleteProductWithConfirm = (productId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deleteProduct.description</T>,
      title: <T>Products:Confirmation.deleteProduct.title</T>,
    })
      .then(() => deleteProduct(productId))
      .catch(() => undefined)

  const deleteProductItem = (itemId: string) =>
    deleteProductItemMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'SalesProductItem',
            id: data.salesProductDeleteItem.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: itemId },
    })
      .then(({ data }) => data?.salesProductDeleteItem.id)
      .catch(() => undefined)

  const deleteProductItemWithConfirm = (itemId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deleteProductItem.description</T>,
      title: <T>Products:Confirmation.deleteProductItem.title</T>,
    })
      .then(() => deleteProductItem(itemId))
      .catch(() => undefined)

  const deleteProductItemRule = (itemId: string, ruleId: string) =>
    deleteProductItemRuleMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'ProductRule',
            id: data.salesProductItemDeleteRule.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { productRuleId: ruleId },
    })
      .then(({ data }) => data?.salesProductItemDeleteRule.id)
      .catch(() => undefined)

  const deleteProductItemRuleWithConfirm = (itemId: string, ruleId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deleteProductRule.description</T>,
      title: <T>Products:Confirmation.deleteProductRule.title</T>,
    })
      .then(() => deleteProductItemRule(itemId, ruleId))
      .catch(() => undefined)

  const deletePurchase = (purchaseId: string) =>
    deletePurchaseMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'PurchaseProduct',
            id: data.purchaseProductDelete.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: purchaseId },
    })
      .then(({ data }) => {
        targetPurchaseId && onClose()
        return data?.purchaseProductDelete.id
      })
      .catch(() => undefined)

  const deletePurchaseWithConfirm = (purchaseId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deletePurchase.description</T>,
      title: <T>Products:Confirmation.deletePurchase.title</T>,
    })
      .then(() => deletePurchase(purchaseId))
      .catch(() => undefined)

  const extractPurchase = ({
    purchaseId,
    quantity,
    rooms,
  }: ExtractPurchaseInput) =>
    extractPurchaseMutation({
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          const getPurchases = (purchases: ProductPurchase[]) => {
            const { source, extracted } = data.purchaseProductExtract

            const newPurchases = [...purchases, ...extracted]

            if (source) {
              return newPurchases.map((p) => (p.id === purchaseId ? source : p))
            }

            return newPurchases.filter(({ id }) => id !== purchaseId)
          }

          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: cachedSales.sales.products.map((p) =>
                p.id === productId
                  ? { ...p, purchases: getPurchases(p.purchases) }
                  : p
              ),
            })
          )
        }
      },
      variables: {
        input: { purchaseId, quantity, rooms, target: TargetType.Participant },
      },
    })
      .then(({ data }) => data?.purchaseProductExtract)
      .catch(() => undefined)

  const saveProduct = (productId: string, catalogId: string) =>
    saveProductMutation({
      variables: { input: { copyTo: { catalogId }, id: productId } },
    })
      .then(({ data }) => {
        if (data) {
          notify({
            content: (
              <FlexColumn>
                <span style={{ fontWeight: 500, marginBottom: 2 }}>
                  {data.salesProductCopyToCatalog.name}
                </span>
                <T>Products:Notification.saveProduct</T>
                <span style={{ fontWeight: 500, marginTop: 2 }}>
                  {data.salesProductCopyToCatalog.catalog.name}
                </span>
              </FlexColumn>
            ),
            type: 'SUCCESS',
          })
        }

        return data?.salesProductCopyToCatalog
      })
      .catch(() => undefined)

  const updateProduct = (input: SalesProductUpdateInput) =>
    updateProductMutation({ variables: { input } })
      .then(({ data }) => data?.salesProductUpdate)
      .catch(() => undefined)

  const updateProductItem = (input: ProductUpdateItemInput) =>
    updateProductItemMutation({ variables: { input } })
      .then(({ data }) => {
        refetch()
        return data?.salesProductUpdateItem
      })
      .catch(() => undefined)

  const updateProductItemMeal = (input: ProductMealInput) =>
    updateProductItemMealMutation({ variables: { input } })
      .then(({ data }) => {
        refetch()
        return data?.salesProductItemUpdateMeal
      })
      .catch(() => undefined)

  const updateProductItemRule = (input: ProductRuleUpdateInput) =>
    updateProductItemRuleMutation({ variables: { input } })
      .then(({ data }) => {
        refetch()
        return data?.salesProductItemUpdateRule
      })
      .catch(() => undefined)

  const updatePurchase = (input: PurchaseProductUpdateInput) =>
    updatePurchaseMutation({ variables: { input } })
      .then(({ data }) => data?.purchaseProductUpdate)
      .catch(() => undefined)

  contextValueRef.current = {
    addProductItem,
    addProductItemRule,
    addPurchase,
    commission,
    context,
    copyProduct,
    createProduct,
    deleteProduct,
    deleteProductItem,
    deleteProductItemRule,
    deleteProductItemRuleWithConfirm,
    deleteProductItemWithConfirm,
    deleteProductWithConfirm,
    deletePurchase,
    deletePurchaseWithConfirm,
    error,
    extractPurchase,
    isPricingLocked,
    isSinglePurchase,
    loading,
    product: externalProduct ?? product,
    readOnly,
    salesId,
    salesReadOnly,
    salesType,
    saveProduct,
    sellerId,
    setPricingLocked,
    targetPurchaseId,
    updateProduct,
    updateProductItem,
    updateProductItemMeal,
    updateProductItemRule,
    updatePurchase,
  }

  return (
    <SalesProductManagerContext.Provider value={contextValueRef.current}>
      {children}
    </SalesProductManagerContext.Provider>
  )
}

export const useSalesProductManagerContext = () =>
  useContext(SalesProductManagerContext)

/////

const getCachedSales = (cache: ApolloCache<any>, salesId: string) =>
  cache.readQuery<SalesProductsPayload>({
    query: productQueries.GET_SALES_PRODUCTS,
    variables: { id: salesId },
  })

const updateSalesProductsCache = ({
  cache,
  products,
}: {
  cache: SalesProductsPayload
  products: SalesProduct[]
}) => ({
  data: {
    ...cache,
    sales: {
      ...cache.sales,
      products,
    },
  },
  query: productQueries.GET_SALES_PRODUCTS,
})
