import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

type Props = {
  editorState: EditorState
  maxHeight?: number
  minHeight?: number
  readOnly?: boolean
  setEditorState: (editorState: EditorState) => void
}

export const RichTextEditor = ({
  editorState,
  maxHeight = 300,
  minHeight = 100,
  readOnly,
  setEditorState,
}: Props) => (
  <Editor
    editorState={editorState}
    editorStyle={{
      maxHeight,
      minHeight,
    }}
    onEditorStateChange={(value) => setEditorState(value)}
    readOnly={readOnly}
    toolbar={{
      inline: {
        options: ['bold', 'italic', 'strikethrough'],
      },
      list: {
        options: ['unordered'],
      },
      options: ['inline', 'blockType', 'list'],
    }}
    wrapperStyle={{
      width: '100%',
    }}
  />
)
