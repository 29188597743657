import styled, { css } from 'styled-components/macro'

export const ActionDropdownList = styled.ul`
  list-style: none;
  width: max-content;
  min-width: 200px;
  padding: 0;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutterSmall} 0;
  `}
`
