import {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  ReactNode,
  Ref,
  SyntheticEvent,
  useEffect,
  useRef,
} from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'
import { useMergeRefs } from 'use-callback-ref'

import { useTheme } from '@/theme'

import { DataTableInputBase } from './DataTableInputBase'

type Props = {
  autoFocus?: boolean
  background?: string
  disabled?: boolean
  endAdornment?: ReactNode
  flex?: number | string
  focusOnMount?: boolean
  hasError?: boolean
  innerRef?: Ref<HTMLInputElement>
  isProcessing?: boolean
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: SyntheticEvent) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onSubmit?: (event: SyntheticEvent) => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  placeholder?: any
  readOnly?: boolean
  showBorder?: boolean
  startAdornment?: ReactNode
  style?: CSSProperties
  type?: string
  value?: string
}

export const DataTableInput = ({
  disabled,
  flex,
  focusOnMount,
  hasError,
  innerRef,
  isProcessing,
  onSubmit,
  onMouseOver,
  onMouseOut,
  ...props
}: Props) => {
  const { palette } = useTheme()

  const inputEl = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusOnMount) {
      inputEl && inputEl.current && inputEl.current.focus()
    }
  }, [focusOnMount, inputEl])

  const handleFocus = (focusPosition: 'end' | 'start') => {
    const input = inputEl && inputEl.current

    if (input !== null) {
      const valueLength = input.value.length

      input.focus()

      if (input.type === 'text') {
        if (focusPosition === 'end') {
          input.selectionStart = valueLength
          input.selectionEnd = valueLength
        } else {
          input.selectionStart = 0
          input.selectionEnd = 0
        }
      }
    }
  }

  return (
    <InputWrapper
      flex={flex}
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault()
        onSubmit && onSubmit(event)
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <DataTableInputBase
        {...props}
        color={hasError ? palette.danger.main : undefined}
        disabled={disabled}
        isProcessing={isProcessing}
        ref={useMergeRefs([inputEl, innerRef as Ref<HTMLInputElement>])}
      />
      {props.endAdornment && (
        <EndAdornmentWrapper
          disabled={disabled}
          onClick={() => handleFocus('end')}
        >
          {isProcessing ? (
            <div>
              <StyledLoader
                color={palette.primary.light}
                height={14}
                type="spin"
                width={14}
              />
            </div>
          ) : (
            !!props.endAdornment && props.endAdornment
          )}
        </EndAdornmentWrapper>
      )}
      {props.startAdornment && (
        <StartAdornmentWrapper
          disabled={disabled}
          onClick={() => handleFocus('start')}
        >
          {props.startAdornment}
        </StartAdornmentWrapper>
      )}
    </InputWrapper>
  )
}

////////////

const adornment = css<{ disabled?: boolean }>`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  ${({ theme }) => css`
    line-height: ${theme.typography.fontSizeBase};
    width: ${theme.spacing.gu(4)}rem;
    color: ${theme.palette.text.light};
  `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.palette.text.lighter};
    `}
`

const EndAdornmentWrapper = styled.span`
  ${adornment}
  right: 0;
`
const StartAdornmentWrapper = styled.span`
  ${adornment}
  left: 0;
`

const InputWrapper = styled.form<any>`
  flex: ${({ flex }) => `${flex ?? 1}`};
  display: flex;
  justify-content: stretch;
  position: relative;
`

const StyledLoader = styled(ReactLoading)`
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`
