import EVENT_ENROLLMENTS from './eventEnrollments'
import LAST_USED_SALES from './lastUsedSales'
import REFRESH_VAT_ENABLED from './refreshVatEnabled'
import SALES_ASSIGNERS from './salesAssigners'
import SALES_DETAILS from './salesDetails'
import SALES_DETAILS_FOR_PRINT from './salesDetailsForPrint'
import SALES_ISSUES from './salesIssues'
import SALES_PAYMENT_INFO from './salesPaymentInfo'
import SALES_STATE_OPTIONS from './salesStateOptions'
import SALES_STATE_REASONS from './salesStateReasons'
import SALES_STATES from './salesStates'

export const salesQueries = Object.freeze({
  EVENT_ENROLLMENTS,
  LAST_USED_SALES,
  REFRESH_VAT_ENABLED,
  SALES_ASSIGNERS,
  SALES_DETAILS,
  SALES_DETAILS_FOR_PRINT,
  SALES_ISSUES,
  SALES_PAYMENT_INFO,
  SALES_STATE_OPTIONS,
  SALES_STATE_REASONS,
  SALES_STATES,
})

export type {
  LastUsedSalesQuery as LastUsedSalesPayload,
  LastUsedSalesQueryVariables as LastUsedSalesVariables,
  RefreshVatEnabledQuery as RefreshVatEnabledPayload,
  RefreshVatEnabledQueryVariables as RefreshVatEnabledVariables,
  SalesAssignersQuery as SalesAssignersPayload,
  SalesDetailsQuery as SalesDetailsPayload,
  SalesDetailsQueryVariables as SalesDetailsVariables,
  SalesDetailsForPrintQuery as SalesDetailsForPrintPayload,
  SalesDetailsForPrintQueryVariables as SalesDetailsForPrintVariables,
  SalesIssuesQuery as SalesIssuesPayload,
  SalesIssuesQueryVariables as SalesIssuesVariables,
  SalesPaymentInfoQuery as SalesPaymentInfoPayload,
  SalesPaymentInfoQueryVariables as SalesPaymentInfoVariables,
  SalesStateOptionsQuery as SalesStateOptionsPayload,
  SalesStateOptionsQueryVariables as SalesStateOptionsVariables,
  SalesStateReasonsQuery as SalesStateReasonsPayload,
  SalesStateReasonsQueryVariables as SalesStateReasonsVariables,
  SalesStatesQuery as SalesStatesPayload,
  SalesStatesQueryVariables as SalesStatesVariables,
} from '~generated-types'
