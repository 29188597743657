import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import {
  MealRow,
  useDeleteMealMutation,
  useResetMealMutation,
  useRestoreMealMutation,
} from '@/modules/Meals'
import { useTheme } from '@/theme'

import { ScheduledMealStatus as Status } from '~generated-types'

import { Row, Section } from '../../../common'

type Props = {
  meal: MealRow
}

export const ActionRow = ({ meal: { ids, statuses } }: Props) => {
  const { spacing, palette } = useTheme()

  const [deleteMeal] = useDeleteMealMutation({ id: ids[0] })
  const [resetToDefaut] = useResetMealMutation()
  const [restoreMeal] = useRestoreMealMutation()

  const handleDeleteMeal = (id: string) =>
    deleteMeal({ variables: { id } }).catch(() => undefined)

  const handleRestoreMeal = (id: string) =>
    restoreMeal({ variables: { id } }).catch(() => undefined)

  const handleResetToDefaut = (id: string) =>
    resetToDefaut({ variables: { id } }).catch(() => undefined)

  const isRemoved = statuses.includes(Status.ManuallyRemoved)

  return (
    <Row>
      <Section width={`${spacing.gu(5)}rem`}>
        <Tooltip
          content={
            isRemoved ? (
              <T>SalesDetails:meals.action.restore</T>
            ) : (
              <T>SalesDetails:meals.action.remove</T>
            )
          }
          placement="left"
          trigger={(triggerProps) => (
            <span {...triggerProps}>
              <IconButton
                onClick={() =>
                  isRemoved
                    ? handleRestoreMeal(ids[0])
                    : handleDeleteMeal(ids[0])
                }
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={isRemoved ? 'rotate-right' : 'trash'}
                />
              </IconButton>
            </span>
          )}
        />
      </Section>

      {(statuses.includes(Status.ManuallyUpdated) ||
        statuses.includes(Status.QuantityUpdated)) && (
        <Section width={`${spacing.gu(5)}rem`}>
          <Tooltip
            content={<T>SalesDetails:meals.action.reset</T>}
            placement="right"
            trigger={(triggerProps) => (
              <span {...triggerProps}>
                <IconButton onClick={() => handleResetToDefaut(ids[0])}>
                  <FontAwesomeIcon
                    size="sm"
                    icon="rotate-right"
                    color={palette.primary.main}
                  />
                </IconButton>
              </span>
            )}
          />
        </Section>
      )}
    </Row>
  )
}

////////////

const IconButton = styled(Button)`
  display: flex;
  justify-content: center;
  background: transparent;
  border: none;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
    height: ${theme.spacing.gu(4)}rem;
    color: ${theme.palette.danger.main};
  `}

  &:not(:disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:not(:disabled):active,
  &:not(:disabled):focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`
