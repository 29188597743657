import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'

type Props = {
  handleUpload: (files: FileList) => void
}

export const FileDropArea = ({ handleUpload }: Props) => {
  const [highlightDropArea, setHighlightDropArea] = useState<boolean>(false)

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setHighlightDropArea(e.type === 'dragover' || e.type === 'dragenter')

    e.type === 'drop' && handleUpload(e.dataTransfer.files)
  }

  return (
    <DropArea
      highlight={highlightDropArea}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrag}
    >
      <FileInput
        id="file"
        type="file"
        accept=".xls,.xlsx"
        onChange={(e) => e.target.files && handleUpload(e.target.files)}
      />
      <FlexColumn>
        <UploadIcon icon="arrow-up-from-bracket" size="4x" />

        <Description>
          <FontWeight semiBold>
            <span>
              <T>ParticipantsList:Import.dragHere</T>
            </span>
          </FontWeight>

          <label htmlFor="file">
            <span>
              <T>ParticipantsList:Import.uploadFile</T>
            </span>
          </label>
        </Description>

        <Description>
          <FontWeight light>
            <T>ParticipantsList:Import.fileTypeDescription</T>
          </FontWeight>
        </Description>
      </FlexColumn>
    </DropArea>
  )
}

//////////

const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin-bottom: 0;

    & > label > span {
      display: inline-block;
      padding: ${theme.spacing.gu(1)}rem;
      color: ${theme.palette.primary.main};
      font-weight: 600;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        color: ${theme.palette.primary.dark};
      }
    }
  `}
`

const DropArea = styled.div<{ highlight?: boolean }>`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  ${({ theme }) => css`
    border: 2px dashed ${theme.palette.smoke.main};
    background-color: ${theme.palette.smoke.extraLight};
    margin-top: ${theme.spacing.gu(1)}rem;
  `}

  ${({ highlight, theme }) =>
    highlight &&
    css`
      border-color: ${theme.palette.smoke.dark};
      background-color: ${theme.palette.smoke.lighter};
    `}
`

const FileInput = styled.input`
  display: none;
`

const UploadIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
  `}
`
