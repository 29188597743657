import moment, { Moment } from 'moment'

import { typeGuardFactory } from '@/utils/types'

import { ArrivalsParticipant } from '../ArrivalsListing/useArrivalsForDate'
import { ParticipantsMode } from '../common/PrintSettings'
import { DeparturesParticipant } from '../DeparturesListing/useDeparturesForDate'

export const getUniqueParticipants = (
  data: ArrivalsParticipant[] | DeparturesParticipant[],
  date: Moment,
  participantsMode?: ParticipantsMode,
  participantsFor?: string
) => {
  const uniqueParticipants = data.reduce(
    (acc: ArrivalsParticipant[] | DeparturesParticipant[], participant) => {
      participant.services.map((service) =>
        acc.push({ ...participant, services: [service] })
      )
      return acc
    },
    []
  )

  const arrivingParticipants = uniqueParticipants.filter((participant) =>
    participant.services
      .filter(typeGuardFactory('__typename', 'ServiceParticipantBed'))
      .find((service) =>
        moment(service.dates?.checkIn.date).isSame(date, 'day')
      )
  )

  const departingParticipants = uniqueParticipants.filter((participant) =>
    participant.services
      .filter(typeGuardFactory('__typename', 'ServiceParticipantBed'))
      .find((service) =>
        moment(service.dates?.checkOut.date).isSame(date, 'day')
      )
  )

  const eventRoomBedsParticipants = uniqueParticipants.filter((participant) =>
    participant.services
      .filter(typeGuardFactory('__typename', 'ServiceParticipantBed'))
      .find(
        (service) =>
          (moment(service.dates?.checkIn.date).isSameOrBefore(date, 'day') ||
            moment(service.dates?.checkIn.date).isSameOrAfter(date, 'day')) &&
          !moment(service.dates?.checkOut.date).isSameOrBefore(date, 'day')
      )
  )

  switch (participantsMode) {
    case ParticipantsMode.All:
      return uniqueParticipants

    case ParticipantsMode.ArrivingTODAY:
      return participantsFor === 'ROOM_BEDS'
        ? eventRoomBedsParticipants
        : arrivingParticipants

    case ParticipantsMode.DeparturesTODAY:
      return departingParticipants

    default:
      return []
  }
}
