import { gql } from '@apollo/client'

export default gql`
  mutation UpdateOrganizationCustomerContact(
    $input: CustomerOrganizationUpdateContactInput!
  ) {
    customerOrganizationUpdateContact(input: $input) {
      contact {
        email
        firstName
        id
        lastName
        notes
        phone
      }
    }
  }
`
