import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ProductPurchaseLink } from '@/modules/Products/types'
import { UserGroup } from '@/modules/Registry/UserGroup'

import { TargetType as TT } from '~generated-types'

type Props = {
  link: ProductPurchaseLink
  targetType: TT
}

export const TargetInfo = ({ link, targetType }: Props) => {
  switch (targetType) {
    case TT.Participant:
      if (!link.participant) {
        return null
      }

      const { firstName, lastName } = link.participant

      return firstName || lastName ? (
        <Label>
          {`${firstName}${firstName && lastName ? ' ' : ''}${lastName}`}
        </Label>
      ) : (
        <Placeholder>
          <T>ParticipantsList:unnamedParticipant</T>
        </Placeholder>
      )
    case TT.Reservation:
      if (!link.reservation) {
        return null
      }

      const { resource, resourceQuantity } = link.reservation

      return (
        <Label>
          {resource?.isPooled && resourceQuantity > 1
            ? `${resourceQuantity} x `
            : ''}
          {resource?.name ?? <T>ResourceReservations:resource.program</T>}
        </Label>
      )
    case TT.Task:
      if (link.task) {
        return <Label>{(link.task.assignee as UserGroup)?.name}</Label>
      }

      return null
    case TT.Invoice:
    case TT.PurchaseOrder:
    default:
      return null
  }
}

///////

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Label = styled(FlexRow)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(26)}rem;
  `}
`
