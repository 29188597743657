import { InputWrapper } from '../InputWrapper'
import { PrimitiveTextInput } from '../PrimitiveTextInput'

export const TextInput = (props: any) => (
  <InputWrapper>
    <PrimitiveTextInput {...props} />
  </InputWrapper>
)

export default TextInput
