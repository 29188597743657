import { createContext, ReactNode, useContext, useRef, useState } from 'react'

import { OrderPhaseType as OPT } from '~generated-types'

type OrderView = OPT.Invoice | OPT.Offer

type StateType<T> = T | ((data: T) => T)

type ContextType = {
  orderView: OrderView
  selectedPhaseId: string | null
  setOrderView: (orderView: StateType<OrderView>) => void
  setSelectedPhaseId: (phaseId: StateType<string | null>) => void
}

const OrderViewContext = createContext<ContextType>({
  orderView: OPT.Invoice,
  selectedPhaseId: null,
  setOrderView: () => undefined,
  setSelectedPhaseId: () => undefined,
})

type Props = {
  children: ReactNode
}

export const OrderViewProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const [orderView, setOrderView] = useState<OrderView>(OPT.Invoice)
  const [selectedPhaseId, setSelectedPhaseId] = useState<string | null>(null)

  contextValueRef.current = {
    orderView,
    selectedPhaseId,
    setOrderView,
    setSelectedPhaseId,
  }

  return (
    <OrderViewContext.Provider value={contextValueRef.current}>
      {children}
    </OrderViewContext.Provider>
  )
}

export const useOrderView = () => useContext(OrderViewContext)
