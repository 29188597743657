import React, { useEffect, useState } from 'react'

import { FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import TextInput from './common/TextInput'

type Props = {
  firstName: string
  lastName: string
  readOnly?: boolean
  handleChange: (firstName: string, lastName: string) => Promise<any>
}

const ParticipantName = ({
  firstName,
  lastName,
  readOnly,
  handleChange,
}: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const [first, setFirst] = useState(firstName)
  const [last, setLast] = useState(lastName)

  useEffect(() => {
    firstName !== first && setFirst(firstName)
    lastName !== last && setLast(lastName)
  }, [firstName, lastName])

  return (
    <FlexRow
      style={{
        flex: 1,
        position: 'relative',
        width: `${spacing.gu(24)}rem`,
      }}
      justifyContent={'space-between'}
      alignItems={'stretch'}
      flex={1}
    >
      <TextInput
        disabled={readOnly}
        value={last}
        placeholder={translate(
          'ParticipantsList:ParticipantFormFields.lastName',
          language
        )}
        onSubmit={(lastName) => handleChange(first, lastName)}
        onChange={setLast}
      />
      <TextInput
        disabled={readOnly}
        value={first}
        placeholder={translate(
          'ParticipantsList:ParticipantFormFields.firstName',
          language
        )}
        onSubmit={(firstName) => handleChange(firstName, last)}
        onChange={setFirst}
      />
    </FlexRow>
  )
}

export default ParticipantName
