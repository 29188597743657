import { ChangeEvent, useState } from 'react'

import { Input, Label } from '@/components/FormControls'
import { FormField } from '@/components/FormWrappers'
import { T } from '@/modules/Language'

type LabelSelectorProps = {
  onChange: (newValue: string) => Promise<void>
  value: string
}

export const PublicName = ({ onChange, value }: LabelSelectorProps) => {
  const [valueState, setValue] = useState<string>(value)

  return (
    <FormField>
      <Label>
        <T>Customers:publicName</T>
      </Label>
      <Input
        onBlur={() => onChange(valueState)}
        onSubmit={() => onChange(valueState)}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.value)
        }
        type="text"
        value={valueState}
      />
    </FormField>
  )
}
