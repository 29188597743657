import { gql } from '@apollo/client'

import customerOrganization from './customerOrganization'
import customerPerson from './customerPerson'

export default gql`
  ${customerOrganization}
  ${customerPerson}

  fragment Customer on Customer {
    ...CustomerOrganization
    ...CustomerPerson
  }
`
