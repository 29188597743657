import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  children?: ReactNode
  height: number
}

export const ColumnHeader = ({ children, height }: Props) => (
  <Wrapper height={height}>{children}</Wrapper>
)

const Wrapper = styled.div<{ height: number }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: ${({ height }) => height}px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    background: ${theme.palette.white};
  `}
`
