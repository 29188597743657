import { gql, useMutation } from '@apollo/client'

import type {
  UpdatePurchaseMutation,
  UpdatePurchaseMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productPurchase}

  mutation UpdatePurchase($input: PurchaseProductUpdateInput!) {
    purchaseProductUpdate(input: $input) {
      ...ProductPurchase
    }
  }
`

export const useUpdatePurchaseMutation = () =>
  useMutation<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>(MUTATION)
