import { gql, useMutation } from '@apollo/client'

import {
  DeleteSaleMutation,
  DeleteSaleMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteSale($input: SalesDeleteInput!) {
    salesDelete(input: $input) {
      id
      deleted
    }
  }
`

export const useDeleteSaleMutation = () =>
  useMutation<DeleteSaleMutation, DeleteSaleMutationVariables>(MUTATION)
