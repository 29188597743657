import { gql } from '@apollo/client'

export default gql`
  query LastUsedSales($ids: [ID!]!) {
    salesAll(ids: $ids) {
      id
      type
      name
      orderNumber
      estimatedDates {
        start
        end
      }
      reservationDates {
        start
        end
      }
      facet {
        id
        abbreviation
        color
      }
      lifecycle {
        state {
          key
          names {
            locale
            content
          }
          systemState
        }
      }
      customer {
        customer {
          customerNumber
          ... on CustomerPerson {
            person {
              firstName
              lastName
            }
          }
          ... on CustomerOrganization {
            organization {
              name
            }
          }
        }
      }
    }
  }
`
