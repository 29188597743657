import { gql, useMutation } from '@apollo/client'

import {
  SalesParticipantDeleteMutation,
  SalesParticipantDeleteMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantDeleteMutation,
  SalesParticipantDeleteMutationVariables,
}

export const SALES_PARTICIPANT_DELETE = gql`
  mutation SalesParticipantDelete($input: SalesParticipantDeleteInput!) {
    salesParticipantDelete(input: $input) {
      id
      deleted
      sales {
        id
        participantConnection {
          totalElements
        }
      }
    }
  }
`

export function useSalesParticipantDelete() {
  return useMutation<
    SalesParticipantDeleteMutation,
    SalesParticipantDeleteMutationVariables
  >(SALES_PARTICIPANT_DELETE)
}

export default useSalesParticipantDelete
