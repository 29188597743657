import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { DataRows, Rows } from '@/modules/ParticipantsList'
import { salesHooks } from '@/modules/Sales/hooks'
import { Theme } from '@/theme'

import { Feature } from '~generated-types'

type Props = {
  dataRows: DataRows
  setDataRows: (rows: DataRows) => void
}

export const DataRowsSwitcher = ({ dataRows, setDataRows }: Props) => {
  const {
    data: {
      facet: { features },
      vst,
    },
  } = salesHooks.useSalesDetailsContext()

  const [doubleClickTimer, setDoubleClickTimer] = useState<{
    row: string
  } | null>(null)

  useEffect(() => {
    doubleClickTimer !== null &&
      setTimeout(() => setDoubleClickTimer(null), 300)
  }, [doubleClickTimer])

  const isVSTFeatureEnabled = !!features.find((f) => f.feature === Feature.Vst)
  const isVSTEnabled =
    isVSTFeatureEnabled && vst && (vst.mainCode || vst.sideCode)

  const handleSelect = (row: Rows) => {
    const doubleClicked = doubleClickTimer?.row === row

    setDataRows(
      doubleClicked
        ? {
            SERVICES: false,
            // eslint-disable-next-line
            PERSONAL: false,
            VST: false,
            // eslint-disable-next-line
            [row]: true,
          }
        : {
            ...dataRows,
            [row]: !dataRows[row],
          }
    )

    setDoubleClickTimer({ row })
  }

  return (
    <Wrapper justifyContent="flex-end">
      <DataRowSwithcher
        dataRow={'SERVICES'}
        isSelected={dataRows['SERVICES']}
        onClick={() => handleSelect('SERVICES')}
      >
        <T>ParticipantsList:ParticipantProducts.products</T>
      </DataRowSwithcher>

      <DataRowSwithcher
        dataRow={'PERSONAL'}
        isSelected={dataRows['PERSONAL']}
        onClick={() => handleSelect('PERSONAL')}
      >
        <T>ParticipantsList:PersonalFields.personal</T>
      </DataRowSwithcher>

      {isVSTEnabled && (
        <DataRowSwithcher
          dataRow={'VST'}
          isSelected={dataRows['VST']}
          onClick={() => handleSelect('VST')}
        >
          <T>SalesDetails:section.vst</T>
        </DataRowSwithcher>
      )}
    </Wrapper>
  )
}

const DataRowSwithcher = styled.div<{ isSelected?: boolean; dataRow: Rows }>`
  display: flex;
  align-items: center;
  user-select: none;

  border-bottom: 4px solid
    ${({ isSelected, theme, dataRow }) =>
      isSelected ? getDataRowColor(dataRow, theme) : 'transparent'};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.primary.dark : theme.palette.text.light};
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  z-index: 0;
  align-items: stretch;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(6)}rem;
  `}
`

export const getDataRowColor = (dataRow: Rows, theme: Theme) => {
  switch (dataRow) {
    case 'PERSONAL':
      return theme.palette.primary.light
    case 'SERVICES':
      return theme.palette.danger.lighter
    case 'VST':
      return theme.palette.secondary.light
  }
}
