import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

type Props = {
  participantsAmount: number
  totalBeds: number
}

export const Participants = ({ participantsAmount, totalBeds }: Props) => {
  const theme = useTheme()

  const color =
    participantsAmount !== 0 && totalBeds === participantsAmount
      ? theme.palette.success.main
      : theme.palette.danger.main

  return (
    <ParticipantsWrapper color={color}>
      <FontAwesomeIcon icon="user" size="xs" color={theme.palette.text.light} />
      {participantsAmount || 0}
    </ParticipantsWrapper>
  )
}

export default Participants

type ParticipantsWrapperProps = {
  color: string
}

const ParticipantsWrapper = styled.div<ParticipantsWrapperProps>`
  color: ${({ color }) => color};
  font-weight: 500;
  text-align: center;

  ${({ theme }) => css`
    & svg {
      margin-right: ${theme.spacing.gu(1)}rem;
    }

    width: ${theme.spacing.gu(6)}rem;
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
