import { SalesType } from '~generated-types'

import { Context, SalesProduct } from '../../types'
import { SalesProductManager } from './SalesProductManager'
import { SalesProductManagerContextProvider } from './SalesProductManagerState'

type Props = {
  commission: number | null
  context: Context
  onClose: () => void
  product?: SalesProduct
  productId: string
  readOnly: boolean
  salesId: string
  salesType: SalesType
  sellerId?: string
  targetPurchaseId?: string
}

export const SalesProductManagerContainer = ({ onClose, ...props }: Props) => (
  <SalesProductManagerContextProvider {...props} onClose={onClose}>
    <SalesProductManager onClose={onClose} />
  </SalesProductManagerContextProvider>
)
