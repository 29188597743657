import { Fragment } from 'react'
import { Moment } from 'moment'
import styled, { css } from 'styled-components'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ListingTitle } from '@/modules/Listing/common'

import { PrintSize } from '../../../types'
import { ListType } from '../../usePacketMealListControls'
import {
  ListingMealType,
  ListingRestaurant,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { RestaurantMeals } from './RestaurantMeals'
import { RestaurantMealsPlanning } from './RestaurantMealsPlanning'

type Props = {
  data: {
    [restaurantKey: string]: {
      [mealKey: string]: ListingScheduledMeal[]
    }
  }
  mealTypes: {
    [mealKey: string]: ListingMealType
  }
  printSize: PrintSize
  restaurants: ListingRestaurant[]
  date: Moment
  listType: ListType
}

export const DailyMeals = ({
  data,
  mealTypes,
  printSize,
  restaurants,
  date,
  listType,
}: Props) => {
  const mealsWidouthEmptyQuantity = Object.fromEntries(
    Object.entries(data)
      .map(([key, value]) => [
        key,
        Object.fromEntries(
          Object.entries(value).filter(([_, meals]) =>
            meals.some((meal) => meal.totalQuantity !== 0)
          )
        ),
      ])
      .filter(([_, value]) => Object.keys(value).length > 0)
  )
  const mealType = Object.keys(mealTypes).map((x) => mealTypes[x])

  return (
    <>
      {restaurants.map((restaurant) => (
        <Fragment key={`daily-meals-title-${restaurant.id}`}>
          {mealsWidouthEmptyQuantity[restaurant.id] && (
            <FlexRow
              alignItems="center"
              justifyContent="space-between"
              style={{ pageBreakAfter: 'avoid' }}
            >
              <Title isFirst>{restaurant.name}</Title>
              <Title isFirst>
                <T>Listings:PacketMealListing.field.meals</T>
                {' – '}
                {date.format('dd D.M.YYYY')}
              </Title>
            </FlexRow>
          )}

          {listType === 'PLANNING' ? (
            <RestaurantMealsPlanning
              data={mealsWidouthEmptyQuantity[restaurant.id] || {}}
              mealTypes={mealType}
              restaurant={restaurant}
              printSize={printSize}
            />
          ) : (
            <RestaurantMeals
              data={mealsWidouthEmptyQuantity[restaurant.id] || {}}
              mealTypes={mealType}
              restaurant={restaurant}
              printSize={printSize}
            />
          )}
        </Fragment>
      ))}
    </>
  )
}

////////////

const Title = styled(ListingTitle)<{ isFirst?: boolean }>`
  ${({ isFirst }) => css`
    break-before: ${isFirst ? 'auto' : 'page'};
  `}

  @media print {
    margin-top: 0;
  }
`
