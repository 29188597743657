import styled from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { InputEditor, ToggleEditableValue } from '@/components/ToggleHelpers'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'

import { Cell } from '../Cell'

type Props = HOCProps & {
  value?: string
  handleSubmit: (newValue: string | null) => Promise<void>
  endAdornment: React.ReactNode
  placeholder: React.ReactNode
}

export const NumberInput = ({
  value,
  handleSubmit,
  onChange,
  endAdornment,
  placeholder,
}: Props) => {
  const { spacing } = useTheme()

  const inputToggleStyles = {
    height: '28px',
    margin: `${spacing.gu(1)}rem 0`,
    padding: `0 ${spacing.gu(1)}rem`,
    width: '100%',
  }

  const inputStyles = {
    height: '28px',
    margin: `0 -${spacing.gu(1)}rem`,
    maxWidth: `calc(100% + ${spacing.gu(2)}rem)`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }

  return (
    <ToggleEditableValue
      hideEditIcon
      mode="input"
      renderEditor={(onClose) => (
        <Cell style={{ height: '28px' }} width={22}>
          <InputEditor
            close={onClose}
            handleSubmit={() => handleSubmit(value || '')}
            onChange={onChange}
            onFocus={(e) => e.currentTarget.select()}
            inputComponent={Input}
            type="text"
            value={value || ''}
            style={inputStyles}
          />
        </Cell>
      )}
      value={
        <Cell justifyContent="flex-start" width={22}>
          {Number(value) ? (
            <>
              <Cell justifyContent="flex-start" width={12}>
                <T>ParticipantsList:VST.other</T>
              </Cell>
              <Cell justifyContent="flex-end" width={10}>
                {value || 0} {endAdornment}
              </Cell>
            </>
          ) : (
            <OtherPlaceholder>{placeholder}</OtherPlaceholder>
          )}
        </Cell>
      }
      style={inputToggleStyles}
    />
  )
}

export const FormatNumberInput = withPriceInputLogic(NumberInput)

const OtherPlaceholder = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.palette.smoke.extraDark};
`
