import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FetchState, FetchStates } from '@/common/types'
import { FlexRow } from '@/components/Layout'
import { H4 } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  RoomLayoutService,
  Sale,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { salesHooks } from '@/modules/Sales/hooks'
import { ACTIVE_STATES } from '@/modules/Sales/types'
import { getStatesBySystemStates } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { Filters, SaleCard } from './components'

export const Sidebar = () => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const { salesStates } = salesHooks.useSalesStates()
  const { onSetSales, sales, targetSalesDateRange, targetSalesId } =
    useRoomLayoutContext()

  const isSingleMode = !!targetSalesId

  const [fetchingState, setFetching] = useState<FetchState>(FetchStates.LOADING)
  const [targetAccommodationLevelIds, setTargetAccommodationLevelIds] =
    useState<string[]>([])

  useEffect(() => {
    if (!isSingleMode && salesStates) {
      const targetSalesStates = getStatesBySystemStates(
        salesStates,
        ACTIVE_STATES
      )

      RoomLayoutService.fetchSales(
        targetAccommodationLevelIds,
        targetSalesDateRange,
        targetSalesStates
      ).then(({ data, ok }: { data: Sale[]; ok: boolean }) => {
        ok
          ? data.length
            ? setFetching(FetchStates.IDLE)
            : setFetching(FetchStates.EMPTY)
          : setFetching(FetchStates.ERROR)
        onSetSales(data)
      })
    }
  }, [salesStates, targetAccommodationLevelIds, targetSalesDateRange])

  useEffect(() => {
    if (isSingleMode) {
      RoomLayoutService.fetchTargetSales(targetSalesId).then(
        ({ data, ok }: { data: Sale | null | undefined; ok: boolean }) => {
          ok
            ? data
              ? setFetching(FetchStates.IDLE)
              : setFetching(FetchStates.EMPTY)
            : setFetching(FetchStates.ERROR)
          onSetSales(data ? [data] : [])
        }
      )
    }
  }, [targetSalesId])

  return (
    <Wrapper>
      {!isSingleMode && (
        <>
          <Header>
            <T>RoomLayout:sidebar.header</T>
          </Header>

          <Filters
            targetLevelIds={targetAccommodationLevelIds}
            setTargetLevelIds={setTargetAccommodationLevelIds}
          />
        </>
      )}

      {fetchingState === FetchStates.LOADING && (
        <SaleListWrapper>
          <Placeholder>
            <ReactLoading color={palette.primary.main} type="spin" />
          </Placeholder>
        </SaleListWrapper>
      )}

      {fetchingState === FetchStates.EMPTY && (
        <SaleListWrapper>
          <Placeholder>
            <T>RoomLayout:sidebar.empty</T>
          </Placeholder>
        </SaleListWrapper>
      )}

      {fetchingState === FetchStates.ERROR && (
        <SaleListWrapper>
          <Placeholder>
            <T>common:state.error</T>
          </Placeholder>
        </SaleListWrapper>
      )}

      {fetchingState === FetchStates.IDLE && (
        <SaleListWrapper>
          {!isSingleMode && (
            <CustomersTotal justifyContent="center">
              {translate('RoomLayout:sidebar:customer', language, {
                count: sales.length,
              })}
            </CustomersTotal>
          )}

          {[...sales].sort(generateCompareFn('id')).map((sale: Sale) => (
            <SaleCard isSingleMode={isSingleMode} key={sale.id} sale={sale} />
          ))}
        </SaleListWrapper>
      )}
    </Wrapper>
  )
}

////////////

const CustomersTotal = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Header = styled(H4)`
  font-variant: all-small-caps;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gutter};
  `}
`

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(40)}rem;
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`

const SaleListWrapper = styled.div`
  height: 100%;
  overflow-y: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gu(1.5)}rem
      ${theme.spacing.gu(15)}rem;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border-left: 1px solid ${theme.palette.smoke.main};
    width: ${theme.spacing.gu(60)}rem;
  `}

  @media print {
    display: none;
  }
`
