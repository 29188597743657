import { useMutation } from '@apollo/client'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'

import { FileOwner } from '~generated-types'

import {
  attachmentMutations,
  CopyAttachmentPayload,
  CopyAttachmentVariables,
  RemoveAttachmentPayload,
  RemoveAttachmentVariables,
  RenameAttachmentPayload,
  RenameAttachmentVariables,
} from '../mutations'
import { attachmentServices } from '../services'

export default function useAttachment() {
  const { confirm } = useDialogService()
  const {
    data: { id: ownerId },
  } = salesHooks.useSalesDetailsContext()

  const [copyAttachmentMutation] = useMutation<
    CopyAttachmentPayload,
    CopyAttachmentVariables
  >(attachmentMutations.COPY)
  const [removeAttachmentMutation] = useMutation<
    RemoveAttachmentPayload,
    RemoveAttachmentVariables
  >(attachmentMutations.REMOVE)
  const [renameAttachmentMutation] = useMutation<
    RenameAttachmentPayload,
    RenameAttachmentVariables
  >(attachmentMutations.RENAME)

  const copyAttachmentToDocument = (documentId: string, fileName: string) =>
    copyAttachmentMutation({
      variables: {
        input: {
          id: {
            fileName,
            folder: 'attachments',
            ownerId,
            ownerType: FileOwner.Sales,
          },
          target: {
            fileName,
            folder: 'attachments',
            ownerId: documentId,
            ownerType: FileOwner.Document,
          },
        },
      },
    })

  const downloadAttachment = async (
    fileName: string,
    folder: string,
    ownerId: string,
    ownerType: string
  ) => {
    const link = document.createElement('a')
    link.download = fileName
    link.href = await attachmentServices.download(
      fileName,
      folder,
      ownerId,
      ownerType
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getAttachmentUrl = (
    fileName: string,
    folder: string,
    ownerId: string,
    ownerType: string
  ) => attachmentServices.download(fileName, folder, ownerId, ownerType)

  const removeAttachment = (
    fileName: string,
    ownerId: string,
    ownerType: FileOwner
  ) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Attachments:confirmRemoval.description</T>,
      title: <T>Attachments:confirmRemoval.title</T>,
    }).then(() =>
      removeAttachmentMutation({
        variables: {
          input: {
            id: {
              fileName,
              folder: 'attachments',
              ownerId,
              ownerType,
            },
          },
        },
      })
    )

  const renameAttachment = (
    fileName: string,
    newFileName: string,
    ownerId: string,
    ownerType: FileOwner
  ) =>
    renameAttachmentMutation({
      variables: {
        input: {
          fileName: newFileName,
          id: {
            fileName,
            folder: 'attachments',
            ownerId,
            ownerType,
          },
        },
      },
    })

  const uploadAttachment = (
    file: any,
    fileName: string,
    ownerId: string,
    ownerType: FileOwner
  ) => new Promise(() => console.log(file, fileName, ownerId, ownerType))
  // uploadAttachmentMutation({
  //   variables: {
  //     file,
  //     id: {
  //       fileName,
  //       folder: 'attachments',
  //       ownerId,
  //       ownerType,
  //     },
  //   },
  // })

  return {
    copyAttachmentToDocument,
    downloadAttachment,
    getAttachmentUrl,
    removeAttachment,
    renameAttachment,
    uploadAttachment,
  }
}
