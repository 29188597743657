import { createContext, ReactNode, useContext, useRef } from 'react'
import { ApolloError } from '@apollo/client'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T } from '@/modules/Language'
import { useSalesProducts } from '@/modules/Products/hooks'
import { SalesProduct } from '@/modules/Products/types'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  AccommodationTargetsForPublishQuery as AccommodationTargetsPayload,
  AccommodationTargetUpdateInput,
  AddPublicRoomMutation,
  AddWebshopContactsMutation,
  AddWebshopTokenMutation,
  PublicRoomAddInput,
  PublicRoomFragment,
  PublicRoomInput,
  PublicRoomRemoveInput,
  RemovePublicRoomMutation,
  RemoveWebshopContactsMutation,
  RemoveWebshopTokenMutation,
  SalesAddWebshopSettingsInput,
  SalesProductUpdateInput,
  SalesRemoveWebshopSettingsInput,
  SalesWebshopContactInput,
  SalesWebshopSettingsInput,
  UpdateAccommodationTargetMutation,
  UpdatePublicRoomMutation,
  UpdatePublishedProductMutation,
  UpdateWebshopSettingsMutation,
} from '~generated-types'

import { SalesDetails } from '../../types'
import { useAccommodationTargets, useSalesWebshopSettings } from './hooks'
import {
  useAddPublicRoomMutation,
  useAddWebshopContactsMutation,
  useAddWebshopTokenMutation,
  useRemovePublicRoomMutation,
  useRemoveWebshopContactsMutation,
  useRemoveWebshopTokenMutation,
  useUpdateAccommodationTargetMutation,
  useUpdatePublicRoomMutation,
  useUpdatePublishedProductMutation,
  useUpdateWebshopSettings,
} from './mutations'
import { publishQueries } from './queries'
import { AccommodationTarget, WebshopSettings } from './types'

type PublicRoomUpdateInput = {
  roomId?: string
  targetId: string
  update: PublicRoomInput
}

type ContextType = {
  // Data
  accommodationTargets: AccommodationTarget[]
  accommodationTargetsError?: ApolloError
  accommodationTargetsLoading: boolean
  customer: SalesDetails['customer']
  error?: ApolloError
  loading: boolean
  readOnly: boolean
  salesId: string
  salesProducts: SalesProduct[]
  salesProductsError?: ApolloError
  salesProductsLoading: boolean
  salesWebshopSettings: WebshopSettings | null

  // Methods
  addPublicRoom: (
    input: PublicRoomAddInput
  ) => Promise<AddPublicRoomMutation | null | undefined>
  addWebshopContacts: (
    input: SalesWebshopContactInput
  ) => Promise<AddWebshopContactsMutation | null | undefined>
  addWebshopToken: (
    input: SalesAddWebshopSettingsInput
  ) => Promise<AddWebshopTokenMutation | null | undefined>
  removePublicRoom: (
    input: PublicRoomRemoveInput
  ) => Promise<RemovePublicRoomMutation | null | undefined>
  removeWebshopContacts: (
    input: SalesWebshopContactInput
  ) => Promise<RemoveWebshopContactsMutation | null | undefined>
  removeWebshopToken: (
    input: SalesRemoveWebshopSettingsInput
  ) => Promise<RemoveWebshopTokenMutation | null | undefined>
  unpublishProduct: (
    productId: string
  ) => Promise<UpdatePublishedProductMutation | null | undefined>
  updateAccommodationTarget: (
    input: AccommodationTargetUpdateInput
  ) => Promise<UpdateAccommodationTargetMutation | null | undefined>
  updatePublicRoom: (
    input: PublicRoomUpdateInput
  ) => Promise<UpdatePublicRoomMutation | null | undefined>
  updatePublishedProduct: (
    input: SalesProductUpdateInput
  ) => Promise<UpdatePublishedProductMutation | null | undefined>
  updateWebshopSettings: (
    input: SalesWebshopSettingsInput
  ) => Promise<UpdateWebshopSettingsMutation | null | undefined>
}

const PublishContext = createContext<ContextType>({
  accommodationTargets: [],
  accommodationTargetsError: undefined,
  accommodationTargetsLoading: false,
  addPublicRoom: () => Promise.reject(),
  addWebshopContacts: () => Promise.reject(),
  addWebshopToken: () => Promise.reject(),
  customer: null,
  error: undefined,
  loading: false,
  readOnly: false,
  removePublicRoom: () => Promise.reject(),
  removeWebshopContacts: () => Promise.reject(),
  removeWebshopToken: () => Promise.reject(),
  salesId: '',
  salesProducts: [],
  salesProductsError: undefined,
  salesProductsLoading: false,
  salesWebshopSettings: null,
  unpublishProduct: () => Promise.reject(),
  updateAccommodationTarget: () => Promise.reject(),
  updatePublicRoom: () => Promise.reject(),
  updatePublishedProduct: () => Promise.reject(),
  updateWebshopSettings: () => Promise.reject(),
})

export const PublishContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { confirm } = useDialogService()

  // Sale details
  const {
    data: { id: salesId, customer },
  } = salesHooks.useSalesDetailsContext()

  // Sale webshop settings
  const {
    error: webshopSettingsError,
    loading: webshopSettingsLoading,
    readOnly,
    salesWebshopSettings,
  } = useSalesWebshopSettings({ salesId })

  // Sale products
  const {
    error: salesProductsError,
    loading: salesProductsLoading,
    products: salesProducts,
  } = useSalesProducts({ salesId })

  // Published rooms
  const {
    error: accommodationTargetsError,
    loading: accommodationTargetsLoading,
    accommodationTargets,
  } = useAccommodationTargets({ salesId })

  const [addPublicRoomMutation] = useAddPublicRoomMutation()
  const [addWebshopContactsMutation] = useAddWebshopContactsMutation()
  const [addWebshopTokenMutation] = useAddWebshopTokenMutation()
  const [removePublicRoomMutation] = useRemovePublicRoomMutation()
  const [removeWebshopContactsMutation] = useRemoveWebshopContactsMutation()
  const [removeWebshopTokenMutation] = useRemoveWebshopTokenMutation()
  const [updateAccommodationTargetMutation] =
    useUpdateAccommodationTargetMutation()
  const [updatePublicRoomMutation] = useUpdatePublicRoomMutation()
  const [updatePublishedProductMutation] = useUpdatePublishedProductMutation()
  const [updateWebshopSettingsMutation] = useUpdateWebshopSettings()

  const addPublicRoom = (input: PublicRoomAddInput) =>
    addPublicRoomMutation({
      update(cache, { data }) {
        const cachedData = cache.readQuery<AccommodationTargetsPayload>({
          query: publishQueries.accommodationTargets,
          variables: { salesId },
        })

        if (cachedData && data && input.add?.targetId) {
          cache.writeQuery<AccommodationTargetsPayload>({
            data: updateAccommodationTargetCache(
              cachedData,
              data.publicRoomAdd.publicRoom,
              input.add.targetId
            ),
            query: publishQueries.accommodationTargets,
          })
        }
      },
      variables: { input },
    })
      .then(({ data }) => data)
      .catch(() => undefined)

  const addWebshopContacts = (input: SalesWebshopContactInput) =>
    addWebshopContactsMutation({ variables: { input } })
      .then(({ data }) => data)
      .catch(() => undefined)

  const addWebshopToken = (input: SalesAddWebshopSettingsInput) =>
    addWebshopTokenMutation({ variables: { input } })
      .then(({ data }) => data)
      .catch(() => undefined)

  const removePublicRoom = (input: PublicRoomRemoveInput) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Publish:Confirmation.removePublicRoom.description</T>,
      title: <T>Publish:Confirmation.removePublicRoom.title</T>,
    })
      .then(() =>
        removePublicRoomMutation({
          update(cache, { data }) {
            if (data) {
              const normalizedId = cache.identify({
                __typename: 'PublicRoom',
                id: data.publicRoomRemove.id,
              })
              cache.evict({ id: normalizedId })
              cache.gc()
            }
          },
          variables: { input },
        })
          .then(({ data }) => data)
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const removeWebshopContacts = (input: SalesWebshopContactInput) =>
    removeWebshopContactsMutation({ variables: { input } })
      .then(({ data }) => data)
      .catch(() => undefined)

  const removeWebshopToken = (input: SalesRemoveWebshopSettingsInput) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Publish:Confirmation.removelink.description</T>,
      title: <T>Publish:Confirmation.removelink.title</T>,
    })
      .then(() =>
        removeWebshopTokenMutation({ variables: { input } })
          .then(({ data }) => data)
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const unpublishProduct = (productId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Publish:Products.unpublish</T>
        </DangerColor>
      ),
      description: <T>Publish:Confirmation.unpublishProduct.description</T>,
      title: <T>Publish:Confirmation.unpublishProduct.title</T>,
    })
      .then(() =>
        updatePublishedProductMutation({
          variables: {
            input: { id: productId, settings: { published: false } },
          },
        })
          .then(({ data }) => data)
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const updateAccommodationTarget = (input: AccommodationTargetUpdateInput) =>
    updateAccommodationTargetMutation({ variables: { input } })
      .then(({ data }) => data)
      .catch(() => undefined)

  const updatePublicRoom = ({
    roomId,
    targetId,
    update,
  }: PublicRoomUpdateInput) => {
    const doUpdate = (id: string) =>
      updatePublicRoomMutation({ variables: { input: { id, update } } })
        .then(({ data }) => data)
        .catch(() => undefined)

    if (!roomId) {
      return addPublicRoom({ add: { targetId }, salesId }).then(
        (data) => data && doUpdate(data.publicRoomAdd.publicRoom.id)
      )
    }

    return doUpdate(roomId)
  }

  const updatePublishedProduct = (input: SalesProductUpdateInput) =>
    updatePublishedProductMutation({ variables: { input } })
      .then(({ data }) => data)
      .catch(() => undefined)

  const updateWebshopSettings = (input: SalesWebshopSettingsInput) =>
    updateWebshopSettingsMutation({ variables: { input } })
      .then(({ data }) => data)
      .catch(() => undefined)

  contextValueRef.current = {
    accommodationTargets,
    accommodationTargetsError,
    accommodationTargetsLoading,
    addPublicRoom,
    addWebshopContacts,
    addWebshopToken,
    customer,
    error: webshopSettingsError,
    loading: webshopSettingsLoading,
    readOnly: readOnly || false,
    removePublicRoom,
    removeWebshopContacts,
    removeWebshopToken,
    salesId,
    salesProducts,
    salesProductsError,
    salesProductsLoading,
    salesWebshopSettings,
    unpublishProduct,
    updateAccommodationTarget,
    updatePublicRoom,
    updatePublishedProduct,
    updateWebshopSettings,
  }

  return (
    <PublishContext.Provider value={contextValueRef.current}>
      {children}
    </PublishContext.Provider>
  )
}

export const usePublishContext = () => useContext(PublishContext)

////////

const updateAccommodationTargetCache = (
  cache: AccommodationTargetsPayload,
  publicRoom: PublicRoomFragment,
  targetId: string
) => ({
  ...cache,
  sales: {
    ...cache.sales,
    accommodation: {
      ...cache.sales.accommodation,
      accommodationTargets: cache.sales.accommodation.accommodationTargets.map(
        (t) =>
          t.id === targetId
            ? { ...t, publicRooms: [...t.publicRooms, publicRoom] }
            : t
      ),
    },
  },
})
