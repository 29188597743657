import { gql } from '@apollo/client'

export default gql`
  fragment PublicRoom on PublicRoom {
    auditLog {
      createdAt
    }
    availableFrom
    availableTo
    minGuests
    maxGuests
    id
    product {
      id
      name
    }
    publishedFrom
    publishedTo
    roomMarketing {
      id
      internalName
    }
  }
`
