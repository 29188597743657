import { gql, useMutation } from '@apollo/client'

import {
  RefreshProductsVatMutation,
  RefreshProductsVatMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation RefreshProductsVat($input: SalesProductsRefreshVatInput!) {
    salesProductsRefreshVat(input: $input) {
      id
    }
  }
`

export const useRefreshProductsVat = () =>
  useMutation<RefreshProductsVatMutation, RefreshProductsVatMutationVariables>(
    MUTATION
  )
