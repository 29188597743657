import styled from 'styled-components/macro'

type Props = {
  label: string
  onClick: (...args: Array<any>) => any
}

export const SelectedResourceButton = ({ label, onClick }: Props) => (
  <StyledButton onClick={onClick}>
    <span>{label}</span>
    <span>✕</span>
  </StyledButton>
)

////////////

const StyledButton = styled.button<any>`
  border: 1px solid #c8ccd0;
  border-radius: 6px;
  color: #282831;
  font-family: 'proxima-nova', sans-serif;
  font-size: 0.875rem;
  margin: 2px 3px;
  padding: 5px 8px;
  font-size: 0.85rem;
  position: relative;
  display: inline-flex;
  height: 30px;

  & > span {
    line-height: 1.2rem;
  }

  & > span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 26px;
  }
  & > span:last-child {
    display: flex;
    height: 100%;
    top: 0;
    right: 8px;
    position: absolute;
    align-items: center;
    border-left: 1px solid #c8ccd0;
    padding-left: 8px;
    margin-left: 8px;
  }
`
