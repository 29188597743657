import styled from 'styled-components/macro'

const LiveUpdateIndicator = styled.div<{
  updated?: boolean
  editorColor?: string
}>`
  border-bottom: 2px solid
    ${({ updated, editorColor }) =>
      updated ? `${editorColor ?? `transparent`}` : `transparent`};
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  transition: border ease-out ${({ updated }) => (updated ? '0.5s' : '2s')};
  width: 100%;
`

export default LiveUpdateIndicator
