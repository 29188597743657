import { Glossary } from './glossary.types'

export const fi: Glossary = {
  address1: ['katuosoite 1', 'katuosoite'],
  address2: ['katuosoite 2'],
  age: ['ikä'],
  allergyDescription: ['allergian kuvaus', 'allergia', 'allergiat'],
  birthday: ['syntymäaika', 'syntymäpäivä'],
  city: ['postitoimipaikka'],
  country: ['maa'],
  drugAllergyDescription: ['lääkeaineallergiat', 'lääkeaineallergia'],
  education: ['koulutus', 'koulutusaste'],
  firstName: ['etunimi'],
  gender: ['sukupuoli'],
  group: ['ryhmä'],
  info: ['lisä', 'lisätiedot'],
  language: ['kieli', 'äidinkieli'],
  lastName: ['sukunimi'],
  lifeStage: ['elämänvaihe', 'pääasiallinen toiminta'],
  nationality: ['kansallisuus'],
  postcode: ['postinumero'],
  roomNumber: ['huonenumero', 'huone'],
}
