import { apiCall, ElasticBaseUrl, Response } from '@/utils/api'

import { ElasticAggregations, ElasticSearchResult } from './types'

function elasticApiAggregations<T>(
  index: string,
  queryObject: {
    [key: string]: any
  }
): Promise<Response<ElasticAggregations | null | undefined>> {
  return apiCall<ElasticSearchResult<T>>(
    `${ElasticBaseUrl}/${index}/_search/`,
    {
      body: JSON.stringify(queryObject),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(({ data, ok, ...rest }) => ({
    ...rest,
    data: ok ? data?.aggregations || {} : null,
    ok,
  }))
}

export default elasticApiAggregations
