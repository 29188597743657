import { gql } from '@apollo/client'

export default gql`
  fragment OrderProduct on OrderProduct {
    code
    id
    marginalTax {
      active
      id
    }
    name
    quantity
    totalPrice {
      amount
      amountVatIncluded
      vatAmount
    }
    unitPrice {
      id
      price
      type
      vat {
        id
        vatRate
      }
    }
    validationStatus {
      issues {
        code
        key
        level
      }
    }
  }
`
