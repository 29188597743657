import { ComponentType, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  onClick?: (...args: Array<any>) => any
  children?: ReactNode
  selected?: boolean
}
export const ContentSidebarAction = styled.button<any>`
  margin: 0;
  padding: 0;
  width: 52px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
  `}

  font-weight: 500;
  text-transform: uppercase;

  background: none;
  border: none;
  border-left: 1px solid transparent;

  cursor: pointer;

  ${(props) =>
    props.selected &&
    `
  background: #F6F7F9;
  border-left: 1px solid ${props.theme.palette.smoke.main};
  `}
` as ComponentType<Props>
