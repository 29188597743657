import { Ref, useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'

import { DateRangePickerControl } from '@/components/TimeControls'

import { DataTableInput } from './DataTableInput'

type Props = {
  disabled?: boolean
  endDate: string | null | undefined
  onIntervalChanged: (
    startDate: string | null | undefined,
    endDate: string | null | undefined
  ) => void
  placeholder?: string
  startDate: string | null | undefined
}

export const DataTableIntervalPicker = ({
  disabled,
  endDate: propsEndDate,
  onIntervalChanged,
  placeholder,
  startDate: propsStartDate,
}: Props) => {
  const isFirstRender = useRef<boolean>(true)
  const [endDate, setEndDate] = useState<string | null | undefined>(
    propsEndDate
  )
  const [startDate, setStartDate] = useState<string | null | undefined>(
    propsStartDate
  )

  const hasError = (endDate && !startDate) || (!endDate && startDate)

  const handleSubmit = useCallback(() => {
    if (!hasError) {
      onIntervalChanged(startDate, endDate)
    } else {
      console.warn('Invalid interval selection', startDate, endDate)
    }
  }, [endDate, hasError, onIntervalChanged, startDate])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      handleSubmit()
    }
  }, [endDate, startDate])

  useEffect(() => {
    setEndDate(propsEndDate)
    setStartDate(propsStartDate)
  }, [propsEndDate, propsStartDate])

  return (
    <PickerWrapper>
      <DateRangePickerControl
        numberOfMonths={2}
        placement="bottom-start"
        render={({ range, ref, openModal }) => {
          const valueLabel =
            range &&
            `${startDate ? moment(range.from).format('L') : ''}${
              endDate ? `–${moment(range.to).format('L')}` : ''
            }`

          return (
            <DataTableInput
              disabled={disabled}
              innerRef={ref as Ref<HTMLInputElement>}
              onClick={openModal}
              readOnly
              hasError={!!hasError}
              value={valueLabel || placeholder}
            />
          )
        }}
        setValue={(range) => {
          if (range) {
            setEndDate(range.to.format('YYYY-MM-DD'))
            setStartDate(range.from.format('YYYY-MM-DD'))
          }
        }}
        value={
          endDate && startDate
            ? {
                from: moment(startDate),
                to: moment(endDate),
              }
            : null
        }
      />
    </PickerWrapper>
  )
}

////////////

const PickerWrapper = styled.span`
  display: flex;
  position: relative;

  & td {
    font-weight: 400;
  }
`
