import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

type Props = {
  compact?: boolean
  content: ReactNode
  icon: IconProp
}

export const Placeholder = ({ compact, content, icon }: Props) => (
  <Wrapper
    alignItems="center"
    compact={compact}
    flex={1}
    justifyContent="center"
  >
    <Icon icon={icon} size="3x" />
    <Content>{content}</Content>
  </Wrapper>
)

////////////

const Content = styled.div`
  font-style: italic;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ compact?: boolean }>`
  height: 100%;
  margin: auto 0;

  ${({ compact, theme }) => css`
    padding: 0 ${theme.spacing.gu(4)}rem
      ${theme.spacing.gu(compact ? 5 : 20)}rem ${theme.spacing.gu(4)}rem;
  `}
`
