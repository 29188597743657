import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { H3 } from '@/components/Typography'

type Props = {
  children: ReactNode
  flex?: number
  style?: CSSProperties
  title: ReactNode
}

export const ContentSection = ({ children, title, ...props }: Props) => (
  <Wrapper {...props}>
    {title && <Title>{title}</Title>}
    {children}
  </Wrapper>
)

////////////

const Wrapper = styled.div<{ flex?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex ?? '1 1 auto'};

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gu(4)}rem
      ${theme.spacing.gu(4)}rem;
  `}
`

const Title = styled(H3)`
  display: flex;
  align-items: baseline;
  font-variant: all-small-caps;
  font-weight: 500;
`
