import { Ref, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontColor } from '@/components/Typography'
import { Attachment } from '@/modules/Attachments/types'
import { T } from '@/modules/Language'
import {
  EditButton,
  Title as TitleBase,
} from '@/modules/Order/components/Sections'
import {
  ConfirmationByIdPayload,
  InvoiceByIdPayload,
  OfferByIdPayload,
} from '@/modules/Order/types'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { AttachmentEditor, AttachmentsModal } from './components'

type RefetchPayload =
  | ConfirmationByIdPayload
  | InvoiceByIdPayload
  | OfferByIdPayload

type Props = {
  attachments: Attachment[]
  documentId: string
  onUpdateAttachments: (attachments: Attachment[]) => void
  readOnly: boolean
  refetch: () => Promise<ApolloQueryResult<RefetchPayload>>
}

export const Attachments = ({
  attachments,
  documentId,
  onUpdateAttachments,
  readOnly,
  refetch,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <FlexColumn>
      <Title>
        <T>Attachments:title</T>
      </Title>

      {!attachments.length && readOnly && (
        <Placeholder lighter>
          <T>Attachments:noAttachments</T>
        </Placeholder>
      )}

      <FlexColumn flex={1}>
        {!!attachments.length &&
          [...attachments]
            .sort(generateCompareFn('id'))
            .map((attachment: Attachment) => (
              <AttachmentEditor
                attachment={attachment}
                attachments={attachments}
                documentId={documentId}
                key={attachment.id}
                onUpdateAttachments={onUpdateAttachments}
                readOnly={readOnly}
              />
            ))}

        {!readOnly && (
          <ModalContainer
            isOpen={isModalOpen}
            modal={
              <AttachmentsModal
                close={() => setModalOpen(false)}
                documentAttachments={attachments}
                documentId={documentId}
                onUpdateAttachments={onUpdateAttachments}
                refetch={refetch}
              />
            }
            onClose={() => setModalOpen(false)}
            placement="bottom-start"
            referenceElement={({ ref }) => (
              <EditButton
                disabled={readOnly}
                onClick={() => setModalOpen(true)}
                innerRef={ref as Ref<HTMLButtonElement> | undefined}
                style={{
                  marginBottom: `-${spacing.gu(1)}rem`,
                  marginLeft: `-${spacing.gu(1)}rem`,
                }}
              >
                <PrimaryLabel>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="plus"
                    size="xs"
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                  <T>Attachments:add</T>
                </PrimaryLabel>
              </EditButton>
            )}
          />
        )}
      </FlexColumn>
    </FlexColumn>
  )
}

//////

const Placeholder = styled(FontColor)`
  font-style: italic;
`

const PrimaryLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const Title = styled(TitleBase)`
  margin-left: 0;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`
