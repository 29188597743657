import { useEffect, useState } from 'react'
import moment from 'moment'

import { FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  FetchedParticipant,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { CheckBox } from '@/modules/ParticipantsList/components/common'
import { useTheme } from '@/theme'

import { SalesType } from '~generated-types'

import {
  Education,
  FormatNumberInput,
  Language,
  LifeStage,
  VSTCompactDetails,
  VSTDates,
  Year,
} from './components'

type Props = {
  participant: FetchedParticipant
  readOnly: boolean
}

export const ParticipantVST = ({ participant, readOnly }: Props) => {
  const { spacing } = useTheme()
  const { language: lang } = useLanguageContext()
  const {
    saleType,
    salesId,
    handleSetVSTAttributes,
    handleSetVSTDates,
    handleSetVSTDatesDefault,
  } = useParticipantsListContext()

  const { birthday, education, id, language, lifeStage, vst } = participant

  const [isCompact, setCompact] = useState<boolean>(false)

  useEffect(() => {
    const onResize = () => setCompact(window.innerWidth < 1720)

    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const dates =
    vst?.end && vst?.start
      ? {
          end: moment(vst.end),
          start: moment(vst.start),
        }
      : null

  const year = birthday?.year

  const isVSTIncluded = !!vst?.included
  const isVSTWarning = !year || !education || !language || !lifeStage

  const contextSalesId =
    saleType === SalesType.Event ? { contextSalesId: salesId } : {}

  return (
    <FlexRow>
      <CheckBox
        isSelected={vst?.included}
        onClick={() =>
          handleSetVSTAttributes({
            included: !vst?.included,
            participantId: id,
          })
        }
      />

      <FlexRow
        alignItems="center"
        justifyContent="center"
        style={{ width: `${spacing.gu(5)}rem` }}
      >
        <T>SalesDetails:section.vst</T>
      </FlexRow>

      <VSTDates
        isVSTError={isVSTIncluded && !dates}
        participantId={id}
        vstDays={vst?.vstDays}
        dates={dates}
        handleChange={(dates, updateAll) =>
          handleSetVSTDates({
            end: dates?.end || null,
            participantId: id,
            start: dates?.start || null,
            updateAll,
            ...contextSalesId,
          })
        }
        handleSetDefault={(updateAll) =>
          handleSetVSTDatesDefault({
            participantId: id,
            setDefaultEnd: true,
            setDefaultStart: true,
            updateAll,
            ...contextSalesId,
          })
        }
      />

      {isCompact ? (
        <VSTCompactDetails
          isVSTWarning={isVSTIncluded && isVSTWarning}
          participant={participant}
          readOnly={readOnly}
        />
      ) : (
        <FlexRow>
          <Year
            isVSTWarning={isVSTIncluded && !birthday?.year}
            participant={participant}
            readOnly={readOnly}
          />
          <Education
            isVSTWarning={isVSTIncluded && !education}
            participant={participant}
            readOnly={readOnly}
          />
          <Language
            isVSTWarning={isVSTIncluded && !language}
            participant={participant}
            readOnly={readOnly}
          />
          <LifeStage
            isVSTWarning={isVSTIncluded && !lifeStage}
            participant={participant}
            readOnly={readOnly}
          />
        </FlexRow>
      )}

      <FlexRow style={{ width: `${spacing.gu(30)}rem` }}>
        <FormatNumberInput
          placeholder={translate('ParticipantsList:VST.lessons', lang)}
          value={`${vst?.lesson?.hours || ''}`}
          targetUnit={'h'}
          handleUpdate={(newValue?: string | null) =>
            handleSetVSTAttributes({
              lessonHours: Number(newValue) || 0,
              participantId: id,
            })
          }
        />

        <FormatNumberInput
          placeholder={translate('ParticipantsList:VST.reward', lang)}
          withBorder
          value={`${vst?.lesson?.fee || ''}`}
          targetUnit={'€'}
          handleUpdate={(newValue?: string | null) =>
            handleSetVSTAttributes({
              lessonFee: Number(newValue) || 0,
              participantId: id,
            })
          }
        />
      </FlexRow>
    </FlexRow>
  )
}
