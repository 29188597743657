import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

import type {
  CreateOrderMutation,
  CreateOrderMutationVariables,
} from '~generated-types'

const CREATE_ORDER_MUTATION = gql`
  ${orderFragments.order}

  mutation CreateOrder($input: OrderCreateInput!) {
    orderCreate(input: $input) {
      ...Order
    }
  }
`

export const useCreateOrderMutation = () =>
  useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CREATE_ORDER_MUTATION
  )
