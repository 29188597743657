import styled, { css } from 'styled-components/macro'

type Props = {
  title: string
}

export const OrderItemsTitle = ({ title }: Props) => (
  <TR>
    <td colSpan={10}>{title}</td>
  </TR>
)

/////

const TR = styled.tr`
  && {
    ${({ theme }) => css`
      height: 30px;
      background: ${theme.palette.smoke.lighter};
    `}

    td {
      font-variant-caps: all-small-caps;

      ${({ theme }) => css`
        color: ${theme.palette.text.light};
      `}
    }
  }
`
