import type { Theme } from '@/theme'

export const getTheme = (theme: Theme) => ({
  colors: {
    alertColor: theme.palette.warning.main,
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.smoke.dark,
    primaryColor: theme.palette.primary.main,
    primaryTextColor: theme.palette.text.main,
    textColor: theme.palette.text.main,
    titleColor: theme.palette.text.main,
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: theme.typography.fontSizeBase,
  },
})
