import {
  ChangeEvent,
  ComponentType,
  CSSProperties,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Input } from '@/components/FormControls'

type Props = {
  close: () => void
  handleSubmit: (newValue: string | null) => Promise<any>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  inputComponent?: ComponentType<any>
  style?: CSSProperties
  type?: 'email' | 'number' | 'text' | 'time'
  value: string | null
}

export const InputEditor = ({
  close,
  handleSubmit,
  onChange,
  inputComponent,
  style,
  type = 'text',
  value,
  ...rest
}: Props) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const [editedValue, setEditedValue] = useState<string>(value || '')

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.focus()
  }, [inputEl])

  const submitEditedValue = () => {
    handleSubmit(editedValue || null)
      .then(() => close())
      .catch(() => undefined)
  }

  const InputEl = inputComponent || Input

  return (
    <InputEl
      {...rest}
      onBlur={() => submitEditedValue()}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e)
        setEditedValue(e.target.value)
      }}
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          submitEditedValue()
        }
      }}
      ref={inputEl}
      style={style}
      type={type}
      value={onChange ? value || '' : editedValue}
    />
  )
}
