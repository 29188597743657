import styled, { css } from 'styled-components/macro'

import Label from '../Label'

const Legend = styled(styled(Label).attrs(() => ({ as: 'legend' }))``)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
  `}

  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
`

export default Legend
