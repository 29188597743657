import { gql, useMutation } from '@apollo/client'

import {
  RemoveResourceReservationMutation,
  RemoveResourceReservationMutationVariables,
} from '~generated-types'

export type {
  RemoveResourceReservationMutation,
  RemoveResourceReservationMutationVariables,
}

export const REMOVE_RESOURCE_RESERVATION_MUTATION = gql`
  mutation RemoveResourceReservation($input: ResourceReservationDeleteInput!) {
    resourceReservationDelete(input: $input) {
      id
      deleted
    }
  }
`

export function useRemoveResourceReservationMutation() {
  return useMutation<
    RemoveResourceReservationMutation,
    RemoveResourceReservationMutationVariables
  >(REMOVE_RESOURCE_RESERVATION_MUTATION)
}

export default useRemoveResourceReservationMutation
