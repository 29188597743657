import { DangerColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { UpdatePublishedProductMutation } from '~generated-types'

type Props = {
  productId: string
  readOnly: boolean
  unpublishProduct: (
    productId: string
  ) => Promise<UpdatePublishedProductMutation | null | undefined>
}

export const UnpublishProductButton = ({
  productId,
  readOnly,
  unpublishProduct,
}: Props) => {
  const { typography } = useTheme()

  return (
    <InnocuousButton
      disabled={readOnly}
      noNudge
      compact
      size="small"
      onClick={() => unpublishProduct(productId)}
    >
      <DangerColor style={{ fontSize: typography.fontSizeSmaller }}>
        <T>Publish:Products.unpublish</T>
      </DangerColor>
    </InnocuousButton>
  )
}
