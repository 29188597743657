import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components/macro'

const popIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ShadowCss = css`
  position: fixed;
  z-index: 4010;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: transparent;
  animation: ${popIn} 0.4s cubic-bezier(0, 0.83, 0.75, 0.91);
`

export const Shadow = styled.div`
  ${ShadowCss}
`

export const SideDrawerShadow = styled(Link)`
  ${ShadowCss}
  background: rgba(4, 14, 31, 0.4);

  @media print {
    display: none;
  }
`
