import { CSSProperties, MouseEvent, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { getBaseStyles } from './getBaseStyles'

type Props = {
  onBackNavigate?: (event: MouseEvent) => void
  onClose?: (event: MouseEvent) => void
  style?: CSSProperties
  title?: ReactNode
}

export const InlineModalHeader = ({
  onBackNavigate,
  onClose,
  style,
  title,
  ...props
}: Props) => (
  <Wrapper style={style} {...props}>
    <ButtonContainer>
      {onBackNavigate && (
        <Button onClick={(e: MouseEvent) => onBackNavigate(e)}>
          <FontAwesomeIcon icon="arrow-left" />
        </Button>
      )}
    </ButtonContainer>
    <Title>{title}</Title>
    <ButtonContainer>
      {onClose && (
        <Button onClick={(e: MouseEvent) => onClose(e)}>
          <FontAwesomeIcon icon="xmark" />
        </Button>
      )}
    </ButtonContainer>
  </Wrapper>
)

////////////

const ButtonContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    width: ${getBaseStyles(theme).iconColumnWidth};
  `}
`

const Button = styled.button`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
  `}

  ${({ theme }) => css`
    height: ${getBaseStyles(theme).rowHeight};
    width: ${getBaseStyles(theme).rowHeight};
  `}

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const Title = styled.span`
  flex: 1;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem
      ${theme.spacing.gu(0.5)}rem;
  `}
`
