import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components'

import { FlexColumn } from '@/components/Layout'

import { ListingReservationTask } from '../useReservationList'
import { ListingTask } from '../useTaskList'
import { formatDueDate, formatDueTime } from '../utils'

type Props = {
  task: ListingTask | ListingReservationTask
  reservationDate?: Moment
  showEmpty?: boolean
}

export const TaskDue = ({ task, reservationDate, showEmpty }: Props) => {
  return (
    <FlexColumn noPadding>
      {task.dueDate &&
        reservationDate &&
        !moment(task.dueDate).isSame(reservationDate, 'day') && (
          <span>
            <Icon fixedWidth icon={['far', 'calendar']} size="sm" />
            {formatDueDate(task.dueDate)}
          </span>
        )}
      {task.dueTime && (
        <span>
          <Icon fixedWidth icon={['far', 'clock']} size="sm" />
          {formatDueTime(task.dueTime)}
        </span>
      )}
      {showEmpty && (!task.dueTime || !task.dueDate) && '–'}
    </FlexColumn>
  )
}

///////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
