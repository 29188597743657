import { gql } from '@apollo/client'

import confirmationInfo from './confirmationInfo'
import document from './document'

export default gql`
  ${confirmationInfo}
  ${document}

  fragment Confirmation on OrderConfirmation {
    ...ConfirmationInfo

    document {
      ...Document
    }
  }
`
