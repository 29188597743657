import styled, { css } from 'styled-components/macro'

import { CheckboxInput, Label } from '@/components/FormControls'
import { T } from '@/modules/Language'

type Props = {
  mode: 'DATE' | 'WEEK'
  setShowFocusedResources: (showFocusedResources: boolean) => void
  showFocusedResources: boolean
}

export const FocusedResourcesCheckbox = ({
  mode,
  setShowFocusedResources,
  showFocusedResources,
}: Props) => (
  <CheckboxWrapper>
    <CheckboxInput
      noMargin
      checked={mode !== 'WEEK' && showFocusedResources}
      disabled={mode === 'WEEK'}
      onChange={() => setShowFocusedResources(!showFocusedResources)}
    />
    <CheckboxLabel
      disabled={mode === 'WEEK'}
      onClick={() => setShowFocusedResources(!showFocusedResources)}
    >
      <T>ResourceReservationsCalendar:focusedResources.header</T>
    </CheckboxLabel>
  </CheckboxWrapper>
)

////////////

const CheckboxLabel = styled(Label)`
  cursor: pointer;
  font-weight: 400;

  ${({ theme }) => css`
    margin: 0 0 0 ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
    `}
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
