import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { useTheme } from '@/theme'

import { RoomCleaningStatus } from '~generated-types'

type Props = {
  checkInDate: string
  checkOutDate: string
  cleaningStatus: RoomCleaningStatus | null
}

export const CleaningStatus = ({
  checkInDate,
  checkOutDate,
  cleaningStatus,
}: Props) => {
  const { palette } = useTheme()

  return moment().isSame(checkInDate, 'day') ||
    moment().isSame(checkOutDate, 'day') ? (
    <FontAwesomeIcon
      color={
        cleaningStatus
          ? cleaningStatus === RoomCleaningStatus.Clean
            ? palette.success.main
            : palette.danger.main
          : palette.smoke.dark
      }
      fixedWidth
      icon="broom"
      size="sm"
    />
  ) : null
}
