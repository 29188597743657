import { gql } from '@apollo/client'

import {
  AccommodationGroupFragment,
  AccommodationSettingsFragment,
  AccommodationTargetFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

export const EventReservationMutations = Object.freeze({
  ACCOMMODATION_TARGET_ADD_SALES: gql`
    ${AccommodationTargetFragment}

    mutation AccommodationTargetAddSales(
      $input: AccommodationTargetSalesInput!
    ) {
      accommodationTargetAddSales(input: $input) {
        target {
          ...AccommodationTarget
        }
      }
    }
  `,
  ACCOMMODATION_TARGET_REMOVE_SALES: gql`
    ${AccommodationTargetFragment}

    mutation AccommodationTargetRemoveSales(
      $input: AccommodationTargetSalesInput!
    ) {
      accommodationTargetRemoveSales(input: $input) {
        target {
          ...AccommodationTarget
        }
      }
    }
  `,
  ADD_ACCOMMODATION_GROUP: gql`
    ${AccommodationGroupFragment}

    mutation AccommodationGroupCreate($input: AccommodationGroupCreate!) {
      accommodationGroupCreate(input: $input) {
        accommodationGroup {
          ...AccommodationGroup
        }
      }
    }
  `,
  SET_ACCOMMODATION_GROUP_SETTINGS: gql`
    ${AccommodationSettingsFragment}

    mutation AccommodationGroupSetSettings(
      $input: AccommodationGroupSetSettingsInput!
    ) {
      accommodationGroupSetSettings(input: $input) {
        accommodationGroup {
          ...AccommodationSettings
        }
      }
    }
  `,
  UPDATE_ACCOMMODATION_TARGET: gql`
    ${AccommodationTargetFragment}

    mutation AccommodationTargetUpdate(
      $input: AccommodationTargetUpdateInput!
    ) {
      accommodationTargetUpdate(input: $input) {
        target {
          ...AccommodationTarget
        }
      }
    }
  `,
})
