import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { EditButton } from '@/modules/Order/components/Sections'
import { orderMutations } from '@/modules/Order/mutations'
import { Document as DocumentType } from '@/modules/Order/types'
import { Language, useLanguages } from '@/modules/Registry/Language'
import { Spacing, useTheme } from '@/theme'

import { Section, SubTitle } from './common'

type Props = {
  document: DocumentType
  onUpdateDocument: (document: DocumentType) => void
}

export const LanguageSelector = ({
  document: { id, language, name },
  onUpdateDocument,
}: Props) => {
  const { spacing } = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const { languages: fetchedLanguages, loading } = useLanguages({
    skip: !isEditMode,
  })

  const [updateDocument] = orderMutations.useUpdateDocumentMutation()

  const onSetDocumentLanguage = (selectedOption: Option | null | undefined) => {
    const updatedLanguage = selectedOption
      ? selectedOption.value || null
      : language

    if (updatedLanguage === language) {
      setEditMode(false)
      return
    }

    setProcessing(true)

    return updateDocument({
      variables: { input: { id, language: updatedLanguage, name } },
    })
      .then(({ data }) => data && onUpdateDocument(data.documentUpdate))
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        setEditMode(false)
      })
  }

  const options = [
    ...fetchedLanguages.map((l: Language) => ({
      label: <T>{`common:language.${l}`}</T>,
      value: l,
    })),
    {
      label: <T>Orders:Documents.language.salesDefault</T>,
      value: '',
    },
  ]

  const languageLabel = language ? (
    <T>{`common:language.${language}`}</T>
  ) : (
    <T>Orders:Documents.language.salesDefault</T>
  )

  return (
    <Section>
      <SubTitle>
        <T>Orders:Documents.language.title</T>
      </SubTitle>

      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={isProcessing || loading}
          menuIsOpen={isEditMode}
          name="document-language-selector"
          noOptionsMessage={() => <T>Orders:Documents.language.noLanguages</T>}
          onBlur={() => setEditMode(false)}
          onChange={(selected: Option | null | undefined) =>
            onSetDocumentLanguage(selected)
          }
          options={options}
          placeholder="—"
          value={{
            label: languageLabel,
            value: language ?? '',
          }}
        />
      ) : (
        <StyledEditButton onClick={() => setEditMode(true)}>
          {languageLabel}
        </StyledEditButton>
      )}
    </Section>
  )
}

////////

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
  }),
})

const StyledEditButton = styled(EditButton)`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    margin-left: -${theme.spacing.gu(1)}rem;
    height: 30px;
  `}
`
