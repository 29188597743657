import styled, { css } from 'styled-components/macro'

import { IconButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { ChangelogList } from './ChangelogList'

type PlacementProps = {
  /** Offset from the bottom of the viewport. */
  offsetBottom?: number
  /** Offset from the top of the viewport. */
  offsetTop?: number
  /** Is the overlay visible. */
  isVisible: boolean
}

type Props = PlacementProps & {
  /** Close the overlay. */
  onClose: () => void
  /** ID for the target entity. */
  target: string
}

const OVERLAY_WIDTH = 420

/**
 * A modal overlay containing a changelog list for the provided target.
 */
export const ChangelogOverlay = ({
  onClose,
  target,
  ...placementProps
}: Props) => (
  <Placement {...placementProps}>
    <Wrapper>
      <Header>
        <T>Changelog:title</T>
        <div>
          <IconButton color="transparent" disabled icon="magnifying-glass" />
          <IconButton color="transparent" disabled icon="sliders" />
          <IconButton
            color="transparent"
            icon="xmark"
            onClick={() => onClose()}
          />
        </div>
      </Header>
      <Content>
        {placementProps.isVisible && <ChangelogList target={target} />}
      </Content>
    </Wrapper>
  </Placement>
)

////////////

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(10)}rem;
  `}
`

const Header = styled.div`
  align-items: baseline;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  position: sticky;
  top: 0;

  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
    padding: ${theme.spacing.gutter};
  `}
`

const Placement = styled.div<PlacementProps>`
  position: fixed;
  top: ${({ offsetTop }) => (offsetTop ? `${offsetTop}px` : 0)};
  right: -${OVERLAY_WIDTH}px;
  bottom: ${({ offsetBottom }) => (offsetBottom ? `${offsetBottom}px` : 0)};
  transition: all 300ms ease-in-out;
  width: ${OVERLAY_WIDTH}px;
  z-index: 4009;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ isVisible }) =>
    isVisible
      ? css`
          transform: translateX(-${OVERLAY_WIDTH}px);
        `
      : ''}

  @media print {
    display: none;
  }
`

const Wrapper = styled.div`
  border-radius: 6px;
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.09),
    0 1px 18px 0 rgba(0, 0, 0, 0.08),
    0 3px 5px -1px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
  `}
`
