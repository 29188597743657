import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

export const Sidebar = styled(FlexColumn)<{ isOpen: boolean }>`
  justify-content: flex-start;
  transition: width 1s;
  height: 100%;
  width: 260px;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  z-index: 502;

  transform: translateX(100%);
  transition: transform 300ms linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: none;
    `}
`
