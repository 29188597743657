import styled, { css } from 'styled-components/macro'

import { ToggleButtonHelper } from '@/components/ToggleHelpers'

import { Customer } from '../types'
import { CustomerAddressSelector } from './CustomerAddressSelector'
import { CustomerAddressView } from './CustomerAddressView'

type Props = {
  customer: Customer
  editInRegistry: () => void
  readOnly?: boolean
  selectedAddressId: string | null | undefined
  setAddress: (address: string | null) => Promise<void>
}

export const CustomerAddressEditable = ({
  customer: { addresses, defaultAddressId },
  editInRegistry,
  readOnly,
  selectedAddressId,
  setAddress,
}: Props) => {
  const selectedAddress =
    addresses.find(({ id }) => id === selectedAddressId) || null

  return (
    <>
      <CustomerAddressSelector
        addresses={addresses}
        defaultAddressId={defaultAddressId}
        editInRegistry={editInRegistry}
        readOnly={readOnly}
        selectedAddress={selectedAddress}
        setAddress={setAddress}
      />

      {!!selectedAddress && (
        <StyledToggleButtonHelper
          disabled={readOnly}
          flex
          handleOnClick={editInRegistry}
          value={
            <CustomerAddressView address={selectedAddress.postalAddress} />
          }
        />
      )}
    </>
  )
}

///////

const StyledToggleButtonHelper = styled(ToggleButtonHelper)`
  ${({ theme }) => css`
    margin-top: -${theme.spacing.gutterSmall};
  `}
`
