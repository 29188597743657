import { Room } from '../types'
import { RoomCell, SpacerCell } from './Cells'

interface Props {
  room: Room
}

export const FreeRoomReservationsTableRow = ({ room }: Props) => (
  <tr>
    <RoomCell room={room} />
    <SpacerCell />
    <td colSpan={3} />
    <SpacerCell />
    <td colSpan={3} />
  </tr>
)
