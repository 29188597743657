import { gql } from '@apollo/client'

import eventEnrollment from './eventEnrollment'

export default gql`
  ${eventEnrollment}

  fragment EventSales on SalesConnection {
    nodes {
      ...EventEnrollment
    }
    totalPages
    totalElements
    hasNextPage
    hasPreviousPage
  }
`
