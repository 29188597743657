import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableInput } from '@/components/DataTable'
import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  code: string
  setCode: (amount: string) => void
}

export const GiftCardCodeInput = ({ code, setCode }: Props) => {
  const [giftCodeError, setGiftCodeError] = useState<boolean>(false)

  const checkAmountError = () => setGiftCodeError(!code)

  return (
    <Wrapper flex={1}>
      <SubTitle>
        <T>Orders:Payments.field.giftCardCode</T>
      </SubTitle>

      <Input
        hasError={giftCodeError}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        onBlur={checkAmountError}
        showBorder
        value={code}
      />
    </Wrapper>
  )
}

///////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    border-radius: 6px;
    height: 35px;
  }
`

const SubTitle = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}
`
