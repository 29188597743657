import { gql, useMutation } from '@apollo/client'

import type {
  DeleteSalesProductMutation,
  DeleteSalesProductMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteSalesProduct($id: ID!) {
    salesProductDelete(id: $id) {
      deleted
      id
    }
  }
`

export const useDeleteSalesProductMutation = () =>
  useMutation<DeleteSalesProductMutation, DeleteSalesProductMutationVariables>(
    MUTATION
  )
