import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Canvas } from '../Canvas'

const CanvasWithLeftBorder = styled(Canvas)<any>`
  flex-shrink: 0;
  flex-grow: 0;
  width: ${(props) =>
    (props && props.width) || `${props.theme.spacing.gu(40)}rem`};
  overflow: auto;
  z-index: 4000;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
  `}

  @media print {
    display: none;
  }
`

type Props = {
  children: ReactNode
  style?: CSSProperties
  width?: string | null | undefined
}
export const ContentSidebar = ({ children, style = {}, width }: Props) => (
  <CanvasWithLeftBorder style={style} width={width}>
    {children}
  </CanvasWithLeftBorder>
)
