import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import { CheckInOut, VSTDetails } from './components'
import MainInfo from './MainInfo'

type Props = {
  selectedIds: string[]
  isAllSelected: boolean
  selectAll: (selectAll: boolean) => void
}

const MassUpdateRow = ({ selectedIds, isAllSelected, selectAll }: Props) => (
  <Wrapper>
    <MainInfo
      selectedIds={selectedIds}
      isAllSelected={isAllSelected}
      selectAll={selectAll}
    />

    <Divider />
    <FlexRow alignItems={'center'} justifyContent={'flex-start'} flex={1}>
      <span style={{ flex: 1 }} />

      <VSTDetails selectedIds={selectedIds} />
      <CheckInOut selectedIds={selectedIds} />
    </FlexRow>
  </Wrapper>
)

export default MassUpdateRow

const Divider = styled(FlexRow)`
  height: 1px;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(1)}rem;
  `};
`

const Wrapper = styled.div`
  border-radius: 6px;
  background-color: #fffeef;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    padding-left: 0;
    border: 1px solid ${theme.palette.smoke.dark};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`
