import { gql, useMutation } from '@apollo/client'

import {
  UpdatePublishedProductMutation,
  UpdatePublishedProductMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation UpdatePublishedProduct($input: SalesProductUpdateInput!) {
    salesProductUpdate(input: $input) {
      id
      settings {
        published
        description
        purchaseDates {
          end
          start
        }
        accommodationGroup {
          id
          name
          sortOrder
        }
      }
    }
  }
`

export const useUpdatePublishedProductMutation = () =>
  useMutation<
    UpdatePublishedProductMutation,
    UpdatePublishedProductMutationVariables
  >(MUTATION)
