import React from 'react'

import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

const ListHeader = () => (
  <Wrapper>
    <Section width="7rem" />
    <Section>
      <T>Tasks:ListHeader.description</T>
    </Section>
    <Section>
      <T>Tasks:ListHeader.assignee</T>
    </Section>
    <Section width="11rem">
      <T>Tasks:ListHeader.due</T>
    </Section>
    <Section>
      <T>Tasks:ListHeader.reservation</T>
    </Section>
    <Section>
      <T>Tasks:ListHeader.sales</T>
    </Section>
  </Wrapper>
)

export default ListHeader
