import { gql } from '@apollo/client'

export const TaskAssigneeFragment = gql`
  fragment TaskAssignee on TaskAssignee {
    ... on User {
      id
    }

    ... on UserGroup {
      id
      name
    }
  }
`

export default TaskAssigneeFragment
