import { createGlobalStyle, css } from 'styled-components/macro'

export const paginationContainerClassName = 'pagination-container'
export const paginationButtonClassName = 'pagination-button'

const paginationButtonCss = css`
  &&& {
    height: 2.5rem;

    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gutter};
      font-size: ${theme.typography.fontSizeBase};
      color: ${theme.palette.text.main};
    `}

    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.025rem;
    border-radius: 0;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.dark};
      `}
    }

    &.active {
      ${({ theme }) => css`
        color: ${theme.palette.white};
        background-color: ${theme.palette.primary.dark};
      `}
    }

    &:first-child {
      text-indent: -9999px;

      &::after {
        content: '←';
        display: inline;
        text-indent: 0;
      }
    }

    &:last-child {
      text-indent: -9999px;

      &::after {
        content: '→';
        display: inline;
        text-indent: 0;
      }
    }
  }
`

const paginationContainerCss = css`
  &&& {
    ${({ theme }) => css`
      margin: ${theme.spacing.gutterBig} 0;
    `}
  }
`

export const GlobalPaginationStyles = createGlobalStyle`
  .${paginationButtonClassName} {
    ${paginationButtonCss}
  }

  .${paginationContainerClassName} {
    ${paginationContainerCss}
  }
`
