import { useState } from 'react'

import { ModalContainer } from '@/components/Modal'
import { Action } from '@/modules/Order/components'
import { InvoiceValidatedAction as IVA } from '@/modules/Order/types'

import { OrderPhaseType as Phase } from '~generated-types'

import { RefundActionsModal } from './RefundActionsModal'

type Props = {
  action: IVA
  invoiceId: string
  isProcessing: boolean
  orderId: string
  phase: Phase
}

export const RefundActions = ({
  action,
  invoiceId,
  isProcessing,
  orderId,
  phase,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <RefundActionsModal
          invoiceId={invoiceId}
          onClose={() => setModalOpen(false)}
          orderId={orderId}
          type={phase}
        />
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Action
          isProcessing={isProcessing}
          innerRef={ref}
          onClick={() => setModalOpen(true)}
          phase={phase}
          validatedAction={action}
        />
      )}
    />
  )
}
