import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@kunukn/react-collapse'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'

export const ChevronIcon = styled(FontAwesomeIcon)`
  transition: 0.25s;
  height: 10px;
  width: 10px;

  margin-left: 5px;
  margin-right: 1px;
  margin-top: 3px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

export const Description = styled.span`
  overflow-wrap: anywhere;
`

export const Details = styled(FlexColumn)`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

export const DetailsTitle = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(0.5)}rem 0;
  `};
`

export const DetailsWrapper = styled(Collapse)`
  transition: height 0.25s;
  overflow: visible;
  width: 100%;
`

export const Price = styled.span`
  font-weight: 600;
  text-wrap: nowrap;
  margin-top: 1px;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    padding-left: ${theme.spacing.gu(1)}rem;
  `};
`

export const Time = styled.span`
  text-align: end;

  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(2)}rem;
    min-width: ${theme.spacing.gu(16)}rem;
  `}
`

export const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
  `}
`
