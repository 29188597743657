import { useMutation } from '@apollo/client'
import moment from 'moment'
import { darken, desaturate } from 'polished'

import { useDialogService } from '@/components/DialogService'
import {
  ActionData,
  AllItemData,
  GridContextProvider,
  GridSection,
  PresentationState,
} from '@/components/Grid'
import { FlexRow } from '@/components/Layout'
import { AccommodationQueries } from '@/modules/Accommodation/SalesReservationList/ReservationList.queries'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  ElasticRoom,
  ElasticRoomReservation,
  RoomLayoutMutations as Mutations,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'

import {
  Cell,
  CleaningStatus,
  ColumnHeader,
  ContentWrapper,
  HeaderLabelWrapper,
  HeaderWrapper,
  ReservationBlock,
} from './components'
import { calculateOverlaps, getColumns, mapDataToPositions } from './utils'

type Props = {
  groupId: string
  isLast: boolean
  isLoading: boolean
  isProcessing: boolean
  reservationsByRoom: ElasticRoomReservation[]
  reservations: ElasticRoomReservation[]
  section: ElasticRoom
  setProcessing: (...args: Array<any>) => any
  updateRoomReservation: (...args: Array<any>) => any
}

export const RoomSection = ({
  groupId,
  isLast,
  isLoading,
  isProcessing,
  reservationsByRoom,
  reservations,
  section,
  setProcessing,
  updateRoomReservation,
}: Props) => {
  const { confirm, warn } = useDialogService()
  const { language } = useLanguageContext()
  const theme = useTheme()

  const {
    layoutVariables: { COLUMN_HEADER_WIDTH, COLUMN_WIDTH, ROW_HEIGHT },
    refreshCalendar,
    refreshSidebar,
    selectedRoomType,
    targetCalendarDateRange,
    targetSalesId,
  } = useRoomLayoutContext()

  const [createReservation] = useMutation(Mutations.CREATE_ROOM_RESERVATION, {
    // Manually update GraphQL cache after creating
    update(cache, { data }) {
      if (targetSalesId) {
        const salesCache: any = cache.readQuery({
          query: AccommodationQueries.SALE_RESERVATIONS,
          variables: { id: targetSalesId },
        })

        if (salesCache?.sales) {
          const newSales = {
            ...salesCache,
            sales: {
              ...salesCache.sales,
              accommodation:
                data?.accommodationRoomTypeReservationReserveRoom.accommodation,
            },
          }

          cache.writeQuery({
            data: { ...newSales },
            query: AccommodationQueries.SALE_RESERVATIONS,
          })
        }
      }
    },
  })
  const [setDates] = useMutation(Mutations.SET_ROOM_RESERVATION_DATES)
  const [setRoom] = useMutation(Mutations.SET_ROOM_RESERVATION_ROOM)

  const gridId = `building-${groupId}.room-${section.id}`

  const { rowCount, overlapOffsets } = calculateOverlaps(reservationsByRoom)

  const allReservationsById = reservations.reduce(
    (acc, val: ElasticRoomReservation) => ({
      ...acc,
      [val.id]: {
        ...val,
        groupId: val.id,
        id: val.id,
      },
    }),
    {}
  )

  const reservationsById = reservationsByRoom.reduce(
    (acc, val: ElasticRoomReservation) => ({
      ...acc,
      [val.id]: {
        ...val,
        groupId: val.id,
        id: val.id,
      },
    }),
    {}
  )

  const updateReservationDates = ({ columns, item }: ActionData) => {
    const {
      id,
      request: { checkIn, checkOut },
      room,
    } = item

    // Update calendar reservation before request
    updateRoomReservation({
      ...item,
      request: {
        ...item.request,
        checkIn: {
          ...item.request.checkIn,
          date: columns[0].id,
        },
        checkOut: {
          ...item.request.checkOut,
          date: columns[columns.length - 1].id,
        },
      },
    })

    const doSetDates = (allowOverlapping: boolean) =>
      setDates({
        variables: {
          input: {
            allowOverlapping,
            checkIn: {
              date: columns[0].id,
              type: checkIn.type,
            },
            checkOut: {
              date: columns[columns.length - 1].id,
              type: checkOut.type,
            },
            id,
          },
        },
      })

    doSetDates(false)
      .then((res) => {
        res.data?.accommodationRoomReservationSetDates.__typename ===
        'RoomReservationOverlappingError'
          ? confirm({
              cancelLabel: <T>common:action.cancel</T>,
              confirmLabel: <T>common:action.continue</T>,
              description: translate(
                'RoomLayout:calendar.overlapping.description',
                language,
                {
                  roomNumber: `#${room.number}`,
                }
              ),
              title: <T>RoomLayout:calendar.overlapping.title</T>,
            })
              .then(() => doSetDates(true))
              .then(() =>
                setTimeout(() => {
                  refreshCalendar()
                  refreshSidebar()
                  setProcessing(false)
                }, 500)
              )
              .catch(() => {
                refreshCalendar()
                refreshSidebar()
                setProcessing(false)
              })
          : res.data?.accommodationRoomReservationSetDates.__typename ===
            'RoomReservationCapacityRestricted'
          ? warn({
              cancelLabel: <T>common:action.cancel</T>,
              description: <T>RoomLayout:calendar.restricted.description</T>,
              title: <T>RoomLayout:calendar.restricted.title</T>,
            }).catch(() => {
              refreshCalendar()
              refreshSidebar()
              setProcessing(false)
            })
          : setTimeout(() => {
              refreshCalendar()
              refreshSidebar()
              setProcessing(false)
            }, 500)
      })
      .catch(() => setProcessing(false))
  }

  const updateReservationRoom = ({ item, rows }: ActionData) => {
    const { id } = item
    const newRoom = rows[0].data

    // Update calendar reservation before request
    updateRoomReservation({
      ...item,
      room: newRoom,
    })

    const doSetRoom = (allowOverlapping: boolean) =>
      setRoom({
        variables: {
          input: {
            allowOverlapping,
            id,
            roomId: newRoom.id,
          },
        },
      })

    doSetRoom(false)
      .then((res) => {
        res.data?.accommodationRoomReservationSetRoom.message
          ? confirm({
              cancelLabel: <T>common:action.cancel</T>,
              confirmLabel: <T>common:action.continue</T>,
              description: translate(
                'RoomLayout:calendar.overlapping.description',
                language,
                {
                  roomNumber: `#${newRoom.number}`,
                }
              ),
              title: <T>RoomLayout:calendar.overlapping.title</T>,
            })
              .then(() => doSetRoom(true))
              .then(() =>
                setTimeout(() => {
                  refreshCalendar()
                  refreshSidebar()
                  setProcessing(false)
                }, 500)
              )
              .catch(() => {
                refreshCalendar()
                refreshSidebar()
                setProcessing(false)
              })
          : setTimeout(() => {
              refreshCalendar()
              refreshSidebar()
              setProcessing(false)
            }, 500)
      })
      .catch(() => setProcessing(false))
  }

  const handleCreate = (room: ElasticRoom, roomTypeReservationId: string) => {
    setProcessing(true)

    const doCreateReservation = (allowOverlapping: boolean) =>
      createReservation({
        variables: {
          input: {
            allowOverlapping,
            roomId: room.id,
            roomTypeReservationId,
          },
        },
      })

    doCreateReservation(false)
      .then((res) => {
        res.data?.accommodationRoomTypeReservationReserveRoom.__typename ===
        'RoomReservationOverlappingError'
          ? confirm({
              cancelLabel: <T>common:action.cancel</T>,
              confirmLabel: <T>common:action.continue</T>,
              description: translate(
                'RoomLayout:calendar.overlapping.description',
                language,
                {
                  roomNumber: `#${room.number}`,
                }
              ),
              title: <T>RoomLayout:calendar.overlapping.title</T>,
            })
              .then(() => doCreateReservation(true))
              .then(() =>
                setTimeout(() => {
                  refreshCalendar()
                  refreshSidebar()
                  setProcessing(false)
                }, 500)
              )
              .catch(() => {
                refreshCalendar()
                refreshSidebar()
                setProcessing(false)
              })
          : res.data?.accommodationRoomTypeReservationReserveRoom.__typename ===
            'RoomReservationCapacityRestricted'
          ? warn({
              cancelLabel: <T>common:action.cancel</T>,
              description: <T>RoomLayout:calendar.restricted.description</T>,
              title: <T>RoomLayout:calendar.restricted.title</T>,
            }).catch(() => {
              refreshCalendar()
              refreshSidebar()
              setProcessing(false)
            })
          : setTimeout(() => {
              refreshCalendar()
              refreshSidebar()
              setProcessing(false)
            }, 500)
      })
      .catch(() => setProcessing(false))
  }

  const handleOnDrop = (actionData: ActionData) => {
    const { item, rows } = actionData

    if (!rows.length) {
      return
    }

    setProcessing(true)

    const prevRoomId = item.room.id
    const nextRoomId = rows[0].data.id

    prevRoomId !== nextRoomId
      ? updateReservationRoom(actionData)
      : updateReservationDates(actionData)
  }

  const handleOnResize = (actionData: ActionData) => {
    setProcessing(true)
    updateReservationDates(actionData)
  }

  const getBlockColor = (sales: { [key: string]: any }) => {
    const defaultColor = darken(
      0.02,
      desaturate(0.04, theme.palette.text.light)
    )

    if (targetSalesId && targetSalesId !== sales?.id) {
      return defaultColor
    }

    switch (sales?.type) {
      case 'ENROLLMENT':
        return theme.palette.secondary.darker
      case 'EVENT':
        return theme.palette.accent1.dark
      case 'SALES':
        return theme.palette.primary.dark
      default:
        return defaultColor
    }
  }

  const renderItem = ({
    isDragging,
    isDragPreview,
    itemData: item,
    leftOffset,
  }: any) =>
    item ? (
      <ReservationBlock
        color={getBlockColor(item.sales)}
        isFocused={!!isDragPreview && isDragging}
        isTranslucent={isDragging && !isDragPreview}
        item={item}
        leftOffset={leftOffset}
        variant="outlined"
      />
    ) : null

  const rows = Array(rowCount)
    .fill(0)
    .map((_, index) => ({
      data: section,
      gridId,
      id: `room-${section.id}-${index}`,
      index,
    }))

  const renderHeader = () =>
    rows.map(({ id, data }, idx: number) =>
      idx === 0 ? (
        <ColumnHeader height={ROW_HEIGHT} key={`row-header-${id}`}>
          <HeaderLabelWrapper
            isProcessing={isProcessing || isLoading}
            onPlay={() => handleCreate(data, selectedRoomType.reservationId)}
            room={data}
          />
        </ColumnHeader>
      ) : (
        <ColumnHeader height={ROW_HEIGHT} key={`row-header-${id}`} />
      )
    )

  const renderRows = (items: AllItemData[]) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ContentWrapper height={ROW_HEIGHT * rows.length} isLast={isLast}>
        <GridSection
          columns={getColumns(targetCalendarDateRange)}
          items={items}
          gridId={gridId}
          rows={rows}
        />
      </ContentWrapper>
    </div>
  )

  return (
    <PresentationState
      columnWidth={COLUMN_WIDTH}
      itemResizeDirections={{
        bottom: false,
        left: true,
        right: true,
        top: false,
      }}
      onDrop={handleOnDrop}
      onEmptyDragEnd={() => undefined}
      onResize={handleOnResize}
      processing={isProcessing || isLoading}
      renderCell={(e) => (
        <Cell isToday={e.column.id === moment().format('YYYY-MM-DD')}>
          <CleaningStatus date={e.column.id} status={section.status} />
        </Cell>
      )}
      renderItem={renderItem}
      renderItemPreview={() => <></>}
      rowHeight={ROW_HEIGHT}
    >
      <GridContextProvider
        data={reservationsById}
        extraDataContext={allReservationsById}
        // @ts-ignore
        mapDataToPositions={(reservation: ElasticRoomReservation) =>
          mapDataToPositions(
            gridId,
            overlapOffsets,
            reservation,
            targetCalendarDateRange
          )
        }
        // @ts-ignore
        mapItemIdToDataId={(itemId: string) => itemId.split('.').pop()}
        rows={rows}
        columns={getColumns(targetCalendarDateRange)}
      >
        {({ items }) => (
          <FlexRow>
            <HeaderWrapper width={COLUMN_HEADER_WIDTH}>
              {renderHeader()}
            </HeaderWrapper>
            {renderRows(items)}
          </FlexRow>
        )}
      </GridContextProvider>
    </PresentationState>
  )
}
