import { Chip } from '@/components/Chip'
import { T } from '@/modules/Language'

type Props = {
  flex?: boolean
  size?: 'md' | 'sm'
  type: ('ORGANIZATION' | 'PERSON') | null | undefined
}

export const CustomerTypeBadge = ({ flex, size, type }: Props) =>
  type ? (
    <Chip
      color={type === 'ORGANIZATION' ? 'primary' : 'secondary'}
      flex={flex}
      size={size ?? 'md'}
    >
      <T l10n={`Customers:CustomerTypeBadge.${type}`} />
    </Chip>
  ) : null
