import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

import type {
  TriggerOfferActionMutation,
  TriggerOfferActionMutationVariables,
} from '~generated-types'

const TRIGGER_OFFER_ACTION_MUTATION = gql`
  ${orderFragments.offer}

  mutation TriggerOfferAction($input: OfferPerformActionInput!) {
    orderOfferAction(input: $input) {
      offer {
        ...Offer
      }
    }
  }
`

export const useTriggerOfferActionMutation = () =>
  useMutation<TriggerOfferActionMutation, TriggerOfferActionMutationVariables>(
    TRIGGER_OFFER_ACTION_MUTATION
  )
