import { gql } from '@apollo/client'

export default Object.freeze({
  PARTICIPANTS: gql`
    subscription ParticipantsPersonal($filter: ParticipantFilterInput!) {
      participant(filter: $filter) {
        participantId
        salesId
        event {
          ... on ParticipantEvent {
            id
            type
            data {
              id
              sortOrder
              firstName
              lastName
              visitStatus {
                isDayVisitor
                dayVisitorDates {
                  start
                  end
                }
              }
              gender
              birthday {
                year
                month
                date
              }
              age
              ageCategory {
                abbreviation
                key
                name
              }
              ageSource
              additionalInfo
              nationality
              accommodationRequest
              allergyDescription
              roomFriends
              allergies
            }
          }
          ... on ServiceParticipantEvent {
            id
            type
          }
        }
      }
    }
  `,
  ROOMS: gql`
    subscription ParticipantsRoomsSubscription(
      $filter: AccommodationReservationFilterInput!
    ) {
      accommodationReservation(filter: $filter) {
        salesId
        accommodationGroupId
        event {
          ... on RoomEvent {
            id
          }
        }
      }
    }
  `,
})
