import styled, { css } from 'styled-components/macro'

import { DataField } from '@/components/DataField'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { GroupType } from '../Group'

type Props = {
  label: string | null
  setGroup: (groupFields: Partial<GroupType>) => Promise<any>
}

export const GroupLabelSection = ({ label, setGroup }: Props) => {
  const { spacing, typography } = useTheme()

  return (
    <InlineModalIconSection icon="user-group">
      <DataFieldWrapper>
        <DataField
          handleSubmit={(newLabel) => setGroup({ label: newLabel || '' })}
          inputType="text"
          lineHeight="1.3"
          placeholder={
            <LabelPlaceholder>
              <T>SalesDetails:Settings.group.labelPlaceholder</T>
            </LabelPlaceholder>
          }
          size={typography.fontSizeBase2}
          style={{
            height: '30px',
            marginLeft: `-${spacing.gu(1)}rem`,
            width: `calc(100% + ${spacing.gu(1)}rem)`,
          }}
          value={label}
          valueStyle={{ padding: `${spacing.gu(1)}rem` }}
          variant="primary"
        />
      </DataFieldWrapper>
    </InlineModalIconSection>
  )
}

/////

const LabelPlaceholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const DataFieldWrapper = styled.div`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      height: 30px;

      ${({ theme }) => css`
        font-size: ${theme.typography.fontSizeBase2};
      `}
    }
  }
`
