import { ChangeEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { Textarea } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

import { usePublishContext } from '../../../PublishState'
import { WebshopSettingsToken } from '../../../types'

type Props = {
  token: WebshopSettingsToken
}

export const LinkDescription = ({
  token: { scopes, token, tokenDescription, completedAt },
}: Props) => {
  const { palette, spacing } = useTheme()
  const { readOnly, salesId, updateWebshopSettings } = usePublishContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [description, setDescription] = useState<string>(tokenDescription || '')

  // Set the sale token description
  const handleChangeDescription = (description: string) => {
    if (description !== tokenDescription) {
      updateWebshopSettings({
        salesSettings: {
          salesId,
          scopes,
          tokenDescription: description,
          tokenId: token,
        },
      }).finally(() => setModalOpen(false))
    }
  }

  const isReadOnly = !!(readOnly || completedAt)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
            <Textarea
              disabled={isReadOnly}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              autoFocus
              value={description || ''}
              onBlur={() => !isReadOnly && handleChangeDescription(description)}
              style={{ height: 200, width: 360 }}
            />
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <IconWrapper ref={ref} onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon
            color={description ? palette.warning.dark : palette.text.light}
            icon={['far', 'note-sticky']}
            size="lg"
            style={{ margin: `${spacing.gu(0.5)}rem ${spacing.gu(1)}rem` }}
          />
        </IconWrapper>
      )}
      zIndex={10003}
    />
  )
}

///////

const IconWrapper = styled(FlexRow)`
  width: fit-content;
  display: flex;
  align-items: center;
  align-self: stretch;

  cursor: pointer;
  transition: 0.2s;

  ${({ theme }) => css`
    &:hover {
      background-color: ${theme.palette.smoke.light};
    }
  `}
`
