import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  PaymentTerminal,
  usePaymentTerminals,
} from '@/modules/Registry/PaymentTerminal'
import { PaymentTypeOption } from '@/modules/Registry/PaymentTypeOption'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { PaymentType as PaymentMethod } from '~generated-types'

import { PaymentType } from '../../../types'
import {
  Button,
  Dropdown,
  DropdownArrowButton,
  DropdownButton,
  DropdownWrapper,
  Icon,
  Label,
  LabelTruncate,
  Modal,
  OptionButton,
  TerminalIndicator,
} from '../common'

type Props = {
  paymentType: PaymentType | null
  pointOfSaleId: string | null
  readOnly: boolean
  setPaymentType: (paymentType: PaymentType | null) => void
  validation: PaymentTypeOption | null
}

export const CreditCardPaymentButton = ({
  paymentType,
  pointOfSaleId,
  readOnly,
  setPaymentType,
  validation,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const isPaymentTypeSelected = paymentType?.method === PaymentMethod.CreditCard
  const isPaymentDisabled = validation && !validation.enabled

  if (isPaymentDisabled) {
    return (
      <Tooltip
        content={<T>{`IssueCodes:${validation.reason?.code}`}</T>}
        maxWidth={`${spacing.gu(25)}rem`}
        trigger={(triggerProps) => (
          <Button disabled isSelected={isPaymentTypeSelected} {...triggerProps}>
            <Icon fixedWidth icon="credit-card" />
            <Label>
              <T>Orders:Payments.type.CREDIT_CARD</T>
            </Label>
            <span style={{ flex: 1 }} />
            <Icon icon={['far', 'circle-question']} />
          </Button>
        )}
      />
    )
  }

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isShowOtherTerminals, setShowOtherTerminals] = useState<boolean>(false)
  const [terminals, setTerminals] = useState<PaymentTerminal[]>([])
  const [selectedTerminal, setSelectedTerminal] =
    useState<PaymentTerminal | null>(null)

  const { loading, paymentTerminals } = usePaymentTerminals()

  useEffect(() => {
    if (paymentTerminals) {
      const storedTerminal: PaymentTerminal | null = JSON.parse(
        localStorage.getItem('paymentTerminal') || 'null'
      )
      const isStoredTerminalConnected = paymentTerminals.find(
        (t) => t.id === storedTerminal?.id
      )?.terminalConnected

      setTerminals(paymentTerminals)

      if (isStoredTerminalConnected) {
        setSelectedTerminal(storedTerminal)
      }
    }
  }, [paymentTerminals])

  const handleSetPaymentType = () =>
    !isPaymentTypeSelected
      ? selectedTerminal
        ? setPaymentType({
            method: PaymentMethod.CreditCard,
            terminalId:
              selectedTerminal.id === 'MANUAL'
                ? undefined
                : selectedTerminal.id,
          })
        : setModalOpen(true)
      : setPaymentType(null)

  const handleSelectPaymentTerminal = (terminal: PaymentTerminal) => {
    setSelectedTerminal(terminal)
    setPaymentType({
      method: PaymentMethod.CreditCard,
      terminalId: terminal.id === 'MANUAL' ? undefined : terminal.id,
    })
    setShowOtherTerminals(false)
    setModalOpen(false)
    localStorage.setItem('paymentTerminal', JSON.stringify(terminal))
  }

  const mainTerminals = terminals.filter(
    (t) => t.pointOfSale?.id === pointOfSaleId
  )
  const otherTerminals = terminals.filter(
    (t) => t.pointOfSale?.id !== pointOfSaleId
  )
  const manualTerminal: PaymentTerminal = {
    __typename: 'PaymentTerminal',
    id: 'MANUAL',
    name: translate('Orders:Payments.terminal.manual', language),
    pointOfSale: null,
    terminalConnected: true,
  }

  return (
    <DropdownWrapper>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <Modal>
            <FlexColumn noPadding>
              {mainTerminals.sort(generateCompareFn('name')).map((t) => (
                <OptionButton
                  disabled={!t.terminalConnected}
                  isSelected={selectedTerminal?.id === t.id}
                  key={`terminal-${t.id}`}
                  onClick={() =>
                    t.terminalConnected && handleSelectPaymentTerminal(t)
                  }
                >
                  {t.name}
                  <TerminalIndicator isAvailable={t.terminalConnected} />
                </OptionButton>
              ))}

              <OptionButton
                isSelected={selectedTerminal?.id === 'MANUAL'}
                key="terminal-manual"
                onClick={() => handleSelectPaymentTerminal(manualTerminal)}
              >
                {manualTerminal.name}
              </OptionButton>

              {!!otherTerminals.length &&
                (!isShowOtherTerminals ? (
                  <OptionButton
                    key="show-other-terminals"
                    onClick={() => setShowOtherTerminals(true)}
                  >
                    <LightLabel>
                      <T>Orders:Payments.terminal.showOther</T>
                    </LightLabel>
                  </OptionButton>
                ) : (
                  otherTerminals.sort(generateCompareFn('name')).map((t) => (
                    <OptionButton
                      disabled={!t.terminalConnected}
                      isSelected={selectedTerminal?.id === t.id}
                      key={`other-terminal-${t.id}`}
                      onClick={() =>
                        t.terminalConnected && handleSelectPaymentTerminal(t)
                      }
                    >
                      {t.name}
                      <TerminalIndicator isAvailable={t.terminalConnected} />
                    </OptionButton>
                  ))
                ))}
            </FlexColumn>
          </Modal>
        }
        onClose={() => {
          setModalOpen(false)
          setShowOtherTerminals(false)
        }}
        placement="top"
        referenceElement={({ ref }) => (
          <Dropdown ref={ref} isSelected={isPaymentTypeSelected}>
            <DropdownButton
              disabled={readOnly}
              isSelected={isPaymentTypeSelected}
              onClick={handleSetPaymentType}
            >
              <Icon fixedWidth icon="credit-card" />
              <FlexColumn noPadding alignItems="flex-start">
                <Label>
                  <T>Orders:Payments.type.CREDIT_CARD</T>
                </Label>

                {selectedTerminal && (
                  <LabelTruncate title={selectedTerminal.name}>
                    {selectedTerminal.name}
                  </LabelTruncate>
                )}
              </FlexColumn>
            </DropdownButton>

            <DropdownArrowButton
              disabled={readOnly}
              isSelected={isPaymentTypeSelected}
              onClick={() => setModalOpen(true)}
            >
              {loading ? (
                <ReactLoading
                  color={palette.smoke.dark}
                  height={18}
                  type="spin"
                  width={18}
                />
              ) : (
                <FontAwesomeIcon icon="chevron-down" />
              )}
            </DropdownArrowButton>
          </Dropdown>
        )}
      />
    </DropdownWrapper>
  )
}

/////////

const LightLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
