import { gql, useQuery } from '@apollo/client'

import {
  UseSalesAccommodationCountsQuery as QueryData,
  UseSalesAccommodationCountsQueryVariables as QueryVariables,
} from '~generated-types'

const QUERY = gql`
  query UseSalesAccommodationCounts($id: ID!) {
    sales(id: $id) {
      accommodation {
        accommodationGroups {
          id
          status {
            reservedDetailed {
              beds
              extraBeds
              rooms
            }
          }
        }
        id
      }
      id
    }
  }
`

interface UseSalesAccommodationCountsProps {
  salesId: string
}

interface AccommodationCounts {
  beds: number
  extraBeds: number
  rooms: number
}

interface UseSalesAccommodationCountsHook {
  counts: AccommodationCounts
  error: boolean
  loading: boolean
}

const useSalesAccommodationCounts = ({
  salesId,
}: UseSalesAccommodationCountsProps): UseSalesAccommodationCountsHook => {
  const {
    data,
    error: apolloError,
    loading,
  } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: salesId,
    },
  })

  const counts = data
    ? data.sales?.accommodation?.accommodationGroups?.reduce(
        ({ beds, extraBeds, rooms }: AccommodationCounts, val) => {
          return {
            beds: beds + val.status.reservedDetailed.beds,
            extraBeds: extraBeds + val.status.reservedDetailed.extraBeds,
            rooms: rooms + val.status.reservedDetailed.rooms,
          }
        },
        {
          beds: 0,
          extraBeds: 0,
          rooms: 0,
        }
      )
    : {
        beds: 0,
        extraBeds: 0,
        rooms: 0,
      }

  return {
    counts,
    error: !!apolloError,
    loading,
  }
}

export default useSalesAccommodationCounts
