import { ReactNode, useMemo } from 'react'
import { ApolloProvider } from '@apollo/client'

import { useKeycloakContext } from '@/config/keycloak'

import { createApolloClient } from './createApolloClient'

interface Props {
  children: ReactNode
}

export function WillbaApolloProvider({ children }: Props) {
  const { openRefreshModal } = useKeycloakContext()

  const apolloClient = useMemo(() => {
    const handleNetworkError = (statusCode: number) => {
      if (statusCode === 401) {
        openRefreshModal()
      }

      console.warn('Unhandled network error', statusCode)
    }

    return createApolloClient({ handleNetworkError })
  }, [])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default WillbaApolloProvider
