import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

import { NumberInput } from './NumberInput'

type Props = {
  usedBeds: number
  totalBeds: number
  usedExtraBeds: number
  totalExtraBeds: number
  changeBedsAmount?: (beds: number, extraBeds: number) => Promise<void>
}

export const BedsAmount = ({
  usedBeds,
  totalBeds,
  usedExtraBeds,
  totalExtraBeds,
  changeBedsAmount,
}: Props) => {
  const { spacing } = useTheme()

  const [bedsAmound, setBedsAmount] = useState(usedBeds)
  const [extraBedsAmound, setExtraBedsAmount] = useState(usedExtraBeds)

  useEffect(() => {
    usedBeds !== bedsAmound && setBedsAmount(usedBeds)
    usedExtraBeds !== extraBedsAmound && setBedsAmount(usedExtraBeds)
  }, [usedBeds, usedExtraBeds])

  return (
    <FlexRow>
      <UsageWrapper>
        {changeBedsAmount ? (
          <NumberInput
            min={0}
            onBlur={() => changeBedsAmount(bedsAmound, extraBedsAmound)}
            onChange={(e) => setBedsAmount(Number(e.target.value))}
            onFocus={(e) => e.currentTarget.select()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // @ts-ignore
                e.target.blur()
              }
            }}
            value={`${bedsAmound}`}
          />
        ) : (
          <span>{usedBeds}</span>
        )}
        <span>{` / ${totalBeds}`}</span>
      </UsageWrapper>

      <span style={{ width: `${spacing.gu(1)}rem` }}>{' + '}</span>

      <UsageWrapper>
        {changeBedsAmount ? (
          <NumberInput
            min={0}
            onBlur={() => changeBedsAmount(bedsAmound, extraBedsAmound)}
            onChange={(e) => setExtraBedsAmount(Number(e.target.value))}
            onFocus={(e) => e.currentTarget.select()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // @ts-ignore
                e.target.blur()
              }
            }}
            value={`${extraBedsAmound}`}
          />
        ) : (
          <span>{usedExtraBeds}</span>
        )}
        <span>{` / ${totalExtraBeds}`}</span>
      </UsageWrapper>
    </FlexRow>
  )
}

/////////

const UsageWrapper = styled.div`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(11)}rem;
  `}
`
