import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import type { PropsWithTheme } from '@/theme'

import { borderColor, highlightBackground, highlightColor } from './styles'

export type ColorValue = 'default' | 'highlighted' | 'column-header'
export type PaddingValue = 'compact' | 'default' | 'none'
type WidthValue = number | string

export type Props = {
  align?: 'left' | 'center' | 'right' | 'justify'
  children?: ReactNode
  color?: ColorValue
  colSpan?: number
  forcedWidth?: WidthValue
  minWidth?: WidthValue
  padding?: PaddingValue | PaddingValue[]
  rowSpan?: number
  style?: CSSProperties
  wrapContent?: boolean
  verticalAlign?:
    | 'baseline'
    | 'bottom'
    | 'inherit'
    | 'justify'
    | 'middle'
    | 'top'
}

const reconcileColor = (color: ColorValue | null | undefined) => {
  switch (color) {
    case 'highlighted':
      return css<any>`
        && {
          background: ${highlightBackground};
          color: ${highlightColor};
          font-weight: 600;
        }
      `
    case 'column-header':
      return css<any>`
        background: ${borderColor};
        color: inherit;
      `
    case 'default':
    default:
      return css`
        ${({ theme }) => css`
          background: ${theme.palette.white};
        `}

        color: inherit;
      `
  }
}

const reconcilePadding = ({ padding, theme }: PropsWithTheme<Props>) => {
  const hPadding = {
    compact: `${theme.spacing.gu(1)}rem`,
    default: `${theme.spacing.gutter}`,
    none: 0,
  }
  const vPadding = {
    compact: `${theme.spacing.gu(0.5)}rem`,
    default: `${theme.spacing.gu(1)}rem`,
    none: 0,
  }

  if (Array.isArray(padding)) {
    if (padding.length === 2) {
      const vertical = vPadding[padding[0]]
      const horizontal = hPadding[padding[1]]

      return `${vertical} ${horizontal}`
    }

    if (padding.length === 4) {
      const top = vPadding[padding[0]]
      const right = hPadding[padding[1]]
      const bottom = vPadding[padding[2]]
      const left = hPadding[padding[3]]

      return `${top} ${right} ${bottom} ${left}`
    }

    console.debug('Invalid cell padding configuration', padding)
    return `${vPadding['default']} ${hPadding['default']}`
  }

  return `${vPadding[padding || 'default']} ${hPadding[padding || 'default']}`
}

const reconcileWidth = ({ forcedWidth, minWidth }: any) => {
  if (forcedWidth) {
    switch (typeof forcedWidth) {
      case 'string':
        return css<any>`
          max-width: ${forcedWidth};
          min-width: ${forcedWidth};
          width: ${forcedWidth};
        `
      case 'number':
        return css<any>`
          max-width: ${forcedWidth}px;
          min-width: ${forcedWidth}px;
          width: ${forcedWidth}px;
        `
      default:
        return ''
    }
  } else if (minWidth) {
    switch (typeof minWidth) {
      case 'string':
        return css<any>`
          min-width: ${minWidth};
        `
      case 'number':
        return css<any>`
          min-width: ${minWidth}px;
        `
      default:
        return ''
    }
  }

  return ''
}

export const DataTableCell = styled.td<Props>`
  line-height: 1.75rem;
  padding: ${(props) => reconcilePadding(props)};
  text-align: ${(props) => props.align || 'inherit'};
  vertical-align: ${(props) => props.verticalAlign || 'inherit'};
  white-space: ${(props) => (props.wrapContent ? 'normal' : 'nowrap')};

  ${(props) => {
    switch (typeof props.forcedWidth) {
      case 'string':
        return css<any>`
          max-width: ${props.forcedWidth};
          min-width: ${props.forcedWidth};
          width: ${props.forcedWidth};
        `
      case 'number':
        return css<any>`
          max-width: ${props.forcedWidth}px;
          min-width: ${props.forcedWidth}px;
          width: ${props.forcedWidth}px;
        `
      default:
        return ''
    }
  }}

  ${(props) => reconcileColor(props.color)}
  ${(props) => reconcileWidth(props)}
`
