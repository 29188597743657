import { gql, useMutation } from '@apollo/client'

import {
  SetResourceReservationSalesMutation,
  SetResourceReservationSalesMutationVariables,
} from '~generated-types'

import ResourceReservationFragment from '../fragments/resourceReservation'

export type {
  SetResourceReservationSalesMutation,
  SetResourceReservationSalesMutationVariables,
}

export const SET_RESOURCE_RESERVATION_SALES_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationSales(
    $input: ResourceReservationSetSalesInput!
  ) {
    resourceReservationSetSales(input: $input) {
      ...ResourceReservation
    }
  }
`

export function useSetResourceReservationSalesMutation() {
  return useMutation<
    SetResourceReservationSalesMutation,
    SetResourceReservationSalesMutationVariables
  >(SET_RESOURCE_RESERVATION_SALES_MUTATION)
}

export default useSetResourceReservationSalesMutation
