import { ChangeEvent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { orderServices } from '@/modules/Order/services'

import { Title as TitleBase } from '../../components'

type Props = {
  freeText: string | null
  ownerId: string
  orderId: string
  readOnly: boolean
}

export const FreeTextInput = ({
  freeText,
  ownerId,
  orderId,
  readOnly,
}: Props) => {
  const { updateInvoice } = orderServices.invoiceService()

  const [freeTextValue, setFreeTextValue] = useState<string>('')

  useEffect(() => {
    setFreeTextValue(freeText || '')
  }, [freeText])

  const handleSetFreeText = () =>
    updateInvoice({ freeText: freeTextValue || null, id: ownerId }, orderId)

  return (
    <FlexColumn>
      <Title>
        <T>Orders:Documents.freeText</T>
      </Title>

      <Textarea
        disabled={readOnly}
        onBlur={handleSetFreeText}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFreeTextValue(e.target.value)
        }
        value={freeTextValue}
      />
    </FlexColumn>
  )
}

///////

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))`
  &&& {
    line-height: 1.2;
    resize: vertical;

    min-height: 90px;
    padding: 10px;
    width: 100%;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
    `}
    }
  }
`

const Title = styled(TitleBase)`
  margin-left: 0;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
