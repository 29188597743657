import { gql, useQuery } from '@apollo/client'

import type { ResourceConfigQuery } from '~generated-types'

export type ResourceConfig = ResourceConfigQuery['registry']['resourceConfig']

const QUERY = gql`
  query ResourceConfig {
    registry {
      resourceConfig {
        displayOption {
          roomMessageDisplayOption
        }
      }
    }
  }
`

export function useResourceConfig() {
  const { data, error, loading } = useQuery<ResourceConfigQuery>(QUERY, {
    fetchPolicy: 'no-cache',
  })

  const resourceConfig: ResourceConfig | null =
    data?.registry.resourceConfig ?? null

  return {
    error,
    loading,
    resourceConfig,
  }
}

export default useResourceConfig
