import * as yup from 'yup'

import { Label, TextInput } from '@/components/FormControls'
import { FormikFormFieldHelper } from '@/components/FormControls'
import {
  FormField,
  FormFieldTuple,
  FormSection,
  FormSectionSubtitle,
} from '@/components/FormWrappers'
import { T } from '@/modules/Language'

export const customerDefaultAddressSchema = yup
  .object()
  .shape({
    address1: yup.string().nullable(),
    address2: yup.string().nullable(),
    city: yup.string().nullable(),
    country: yup.string().nullable(),
    postcode: yup.string().nullable(),
  })
  .nullable()

export const CustomerDefaultAddress = () => (
  <>
    <FormSection>
      <FormSectionSubtitle>
        <T>Customers:address.title</T>
      </FormSectionSubtitle>
      <FormField>
        <Label>
          <T>Customers:address.address</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="defaultAddress.address1"
        />
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="defaultAddress.address2"
        />
      </FormField>
      <FormFieldTuple>
        <FormField>
          <Label>
            <T>Customers:address.postcode</T>
          </Label>
          <FormikFormFieldHelper
            defaultValue={''}
            inputComponent={TextInput}
            name="defaultAddress.postcode"
          />
        </FormField>
        <FormField>
          <Label>
            <T>Customers:address.city</T>
          </Label>
          <FormikFormFieldHelper
            defaultValue={''}
            inputComponent={TextInput}
            name="defaultAddress.city"
          />
        </FormField>
      </FormFieldTuple>
      <FormField>
        <Label>
          <T>Customers:address.country</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="defaultAddress.country"
        />
      </FormField>
    </FormSection>
  </>
)
