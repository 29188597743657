import { Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import {
  InlineModal,
  InlineModalLine,
  InlineModalSection,
} from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { DatePicker } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { useTheme } from '@/theme'

import { ConditionRuleType } from '~generated-types'

import { Button } from './common'

type Props = {
  id: string
  dates: string[]
}

export const DateSelector = ({ id, dates: selectedDates }: Props) => {
  const { palette } = useTheme()
  const { updateProductItemRule } = useCatalogProductManagerContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedMomentDates, setSelectedMomentDates] = useState<Moment[]>(
    selectedDates.map((d) => moment(d)).sort((a, b) => a.diff(b))
  )

  const handleSetRuleValue = () => {
    updateProductItemRule({
      condition: {
        dates: selectedMomentDates.map((d) => d.format('YYYY-MM-DD')),
        ruleType: ConditionRuleType.Date,
      },
      productRuleId: id,
    }).then(() => setModalOpen(false))
  }

  const onSetValue = (
    value: Moment | null | undefined,
    modifier: {
      [key: string]: any
    }
  ) => {
    setSelectedMomentDates(
      value
        ? modifier?.selected
          ? selectedMomentDates.filter(
              (d) => d.format('YYYY-MM-DD') !== value.format('YYYY-MM-DD')
            )
          : [...selectedMomentDates, value].sort((a, b) => a.diff(b))
        : selectedMomentDates
    )
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <SelectedDatesWrapper>
            <Title>
              <T>Products:ProductManager.pricing.rule.selectedDates</T>
            </Title>

            <div>
              {selectedMomentDates.length ? (
                selectedMomentDates.map((day, index: number) => (
                  <span key={index}>
                    {day.format('D.M')}
                    {index !== selectedMomentDates.length - 1 && (
                      <span style={{ color: palette.text.lighter }}>
                        {' • '}
                      </span>
                    )}
                  </span>
                ))
              ) : (
                <span style={{ color: palette.text.lighter }}>{'–'}</span>
              )}
            </div>
          </SelectedDatesWrapper>

          <InlineModalLine />

          <DatePickerWrapper>
            <DatePicker setValue={onSetValue} value={selectedMomentDates} />
          </DatePickerWrapper>
        </InlineModal>
      }
      onClose={handleSetRuleValue}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setModalOpen(true)}
        >
          {selectedDates.length ? (
            selectedDates.length < 4 ? (
              selectedDates.map((date: string, index: number) => (
                <span key={index}>
                  {moment(date).format('D.M')}
                  {index !== selectedDates.length - 1 && ', '}
                </span>
              ))
            ) : (
              <span>
                {moment(selectedDates[0]).format('D.M')}
                {' ... '}
                {moment(selectedDates[selectedDates.length - 1]).format('D.M')}
              </span>
            )
          ) : (
            <FontAwesomeIcon
              color={palette.primary.main}
              fixedWidth
              size="sm"
              icon="plus"
            />
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

///////

const DatePickerWrapper = styled(InlineModalSection)`
  margin: 0;
  padding: 0;
`

const SelectedDatesWrapper = styled(InlineModalSection)`
  flex-direction: column;
  margin: 0;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(38)}rem;
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.div`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`
