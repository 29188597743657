import {
  useAddPurchaseFromCatalogProductMutation,
  useAddPurchaseFromSalesProductMutation,
  useDeletePurchaseMutation,
} from '@/modules/Products/mutations'

import { AssigneeInput as TaskAssignee } from '~generated-types'

import {
  useCloseTaskMutation,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useOpenTaskMutation,
  useSetTaskAssigneeMutation,
  useUpdateTaskMutation,
} from '../mutations'
import { Task } from '../types'

type UpdateTasksType = (tasks: Task[]) => void
type UpdateTasksFromCurrentType = (tasks: (current: Task[]) => Task[]) => void

type Params = {
  onUpdateTasks: UpdateTasksType | UpdateTasksFromCurrentType
}

export function useTaskMutations({ onUpdateTasks }: Params) {
  const [handleCreateTask] = useCreateTaskMutation()
  const [handleRemoveTask] = useDeleteTaskMutation()
  const [handleCloseTask] = useCloseTaskMutation()
  const [handleOpenTask] = useOpenTaskMutation()
  const [handleSetTaskAssignee] = useSetTaskAssigneeMutation()
  const [handleUpdateTask] = useUpdateTaskMutation()

  // Purchase mutations
  const [handleAddPurchaseFromSalesProduct] =
    useAddPurchaseFromSalesProductMutation()
  const [handleAddPurchaseFromCatalogProduct] =
    useAddPurchaseFromCatalogProductMutation()
  const [handleRemovePurchase] = useDeletePurchaseMutation()

  //
  // Helpers
  //

  const onUpdateTasksFromCurrent = onUpdateTasks as UpdateTasksFromCurrentType

  const onUpdateTask = (taskId: string, newTask?: Task) =>
    onUpdateTasksFromCurrent((current: Task[]) =>
      current.map((t) => (t.id === taskId ? newTask || t : t))
    )

  //
  // Task mutations
  //

  const closeTask = (taskId: string) =>
    handleCloseTask({ variables: { id: taskId } })
      .then(({ data }) => onUpdateTask(taskId, data?.closeTask))
      .catch(() => undefined)

  const createTask = (salesId?: string) => {
    const input = salesId ? { input: { salesId } } : { input: {} }

    return handleCreateTask({ variables: input })
      .then(({ data }) => {
        data &&
          onUpdateTasksFromCurrent((current: Task[]) => [
            ...current,
            data.createTask,
          ])

        return data?.createTask || null
      })
      .catch(() => undefined)
  }

  const openTask = (taskId: string) =>
    handleOpenTask({ variables: { id: taskId } })
      .then(({ data }) => onUpdateTask(taskId, data?.openTask))
      .catch(() => undefined)

  const removeTask = (taskId: string) =>
    handleRemoveTask({ variables: { id: taskId } })
      .then(({ data }) => {
        data?.deleteTask.deleted &&
          onUpdateTasksFromCurrent((current: Task[]) =>
            current.filter((t) => t.id !== taskId)
          )
      })
      .catch(() => undefined)

  const setTaskAssignee = (
    taskId: string,
    assignee: TaskAssignee | null | undefined
  ) =>
    handleSetTaskAssignee({
      variables: { input: { assignee, id: taskId } },
    })
      .then(({ data }) => onUpdateTask(taskId, data?.taskSetAssignee))
      .catch(() => undefined)

  const updateTask = (task: Task, input: Record<string, unknown>) => {
    const { name, description, dueDate, dueTime, id } = task

    return handleUpdateTask({
      variables: {
        id,
        input: {
          description,
          dueDate,
          dueTime,
          name,
          ...input,
        },
      },
    })
      .then(({ data }) => onUpdateTask(id, data?.updateTask))
      .catch(() => undefined)
  }

  //
  // Task purchase mutations
  //

  const addTaskPurchaseFromCatalogProduct = ({
    catalogProductId,
    salesId,
    taskId,
  }: {
    catalogProductId: string
    salesId: string
    taskId: string
  }) =>
    handleAddPurchaseFromCatalogProduct({
      variables: {
        input: { add: { link: { salesId, taskId } }, catalogProductId },
      },
    })
      .then(({ data }) => {
        data &&
          onUpdateTasksFromCurrent((current: Task[]) =>
            current.map((t) =>
              t.id === taskId
                ? {
                    ...t,
                    purchaseProducts: [
                      ...t.purchaseProducts,
                      data.purchaseProductAddFromCatalogProduct,
                    ],
                  }
                : t
            )
          )
      })
      .catch(() => undefined)

  const addTaskPurchaseFromSalesProduct = ({
    salesProductId,
    salesId,
    taskId,
  }: {
    salesProductId: string
    salesId: string
    taskId: string
  }) =>
    handleAddPurchaseFromSalesProduct({
      variables: {
        input: { add: { link: { salesId, taskId } }, salesProductId },
      },
    })
      .then(({ data }) => {
        data &&
          onUpdateTasksFromCurrent((current: Task[]) =>
            current.map((t) =>
              t.id === taskId
                ? {
                    ...t,
                    purchaseProducts: [
                      ...t.purchaseProducts,
                      data.purchaseProductAddFromSalesProduct,
                    ],
                  }
                : t
            )
          )
      })
      .catch(() => undefined)

  const removeTaskPurchase = ({
    purchaseId,
    taskId,
  }: {
    purchaseId: string
    taskId: string
  }) =>
    handleRemovePurchase({ variables: { id: purchaseId } })
      .then(({ data }) => {
        data?.purchaseProductDelete?.deleted &&
          onUpdateTasksFromCurrent((current: Task[]) =>
            current.map((t) =>
              t.id === taskId
                ? {
                    ...t,
                    purchaseProducts: t.purchaseProducts.filter(
                      (p) => p.id !== purchaseId
                    ),
                  }
                : t
            )
          )
      })
      .catch(() => undefined)

  return {
    addTaskPurchaseFromCatalogProduct,
    addTaskPurchaseFromSalesProduct,
    closeTask,
    createTask,
    openTask,
    removeTask,
    removeTaskPurchase,
    setTaskAssignee,
    updateTask,
  }
}

export default useTaskMutations
