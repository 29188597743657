import { gql, useMutation } from '@apollo/client'

import type {
  UpdateMealMutation,
  UpdateMealMutationVariables,
} from '~generated-types'

import { MealFragment } from '../fragments'

const UPDATE_MEAL_MUTATION = gql`
  ${MealFragment}

  mutation UpdateMeal($input: ScheduledMealUpdateInput!) {
    scheduledMealUpdate(input: $input) {
      scheduledMeals {
        ...Meal
      }
    }
  }
`

export const useUpdateMealMutation = () =>
  useMutation<UpdateMealMutation, UpdateMealMutationVariables>(
    UPDATE_MEAL_MUTATION
  )
