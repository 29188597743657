import React from 'react'

import { Gutter } from '@/components/Layout'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import {
  PendingRoomReservation,
  SalesRoomReservation,
} from '../SalesReservationManager.types'
import { AdditionalInfo } from './AdditionalInfo'
import { Beds } from './Beds'
import { Features } from './Features'
import { Icon } from './Icon'
import { RangePicker } from './RangePicker'
import { ReservationCard } from './ReservationCard'
import { ReservationDataRow } from './ReservationDataRow'
import { RoomHeader } from './RoomHeader'

type Props = {
  data: SalesRoomReservation
  pendingReservations?: PendingRoomReservation[]
  focusedRoomId?: string
  setOpenSections: (arg0: { [sectionId: string]: boolean }) => void
}

export const RoomReservation = ({
  data,
  pendingReservations,
  focusedRoomId,
  setOpenSections,
}: Props) => {
  const {
    id,
    request: { beds, checkIn, checkOut, extraBeds, features, info, room },
  } = data

  const { removeReservation, handleSetRoomReservationRoom } =
    useReservationListContext()

  return (
    <ReservationCard
      confirmRemoval
      focused={focusedRoomId === data.id}
      onRemove={() => removeReservation(id)}
      setOpenSections={() =>
        setOpenSections({
          [`accommodationLevel-${room.roomType.accommodationLevel.id}`]: true,
          [`category-${room.roomType.category.id}`]: true,
          [`roomType-${room.roomType.id}`]: true,
        })
      }
      pendingReservations={pendingReservations}
      changeReservationRoom={(roomId, roomNumber) =>
        handleSetRoomReservationRoom(
          {
            id: data.id,
            roomId,
          },
          roomNumber
        )
      }
      title={
        <RoomHeader
          roomNumber={room.number}
          roomTypeName={room.roomType.name}
        />
      }
      type="ROOM_RESERVATION"
    >
      <Gutter type={1}>
        <ReservationDataRow header={<Icon icon={['far', 'calendar']} large />}>
          <RangePicker
            checkIn={checkIn}
            checkOut={checkOut}
            reservationId={id}
          />
        </ReservationDataRow>
        <ReservationDataRow header={<Icon icon="bed" />}>
          <Beds
            beds={beds}
            totalBeds={room.beds}
            extraBeds={extraBeds}
            totalExtraBeds={room.extraBeds}
            reservationId={id}
          />
        </ReservationDataRow>
        <ReservationDataRow header={<Icon icon={['far', 'clipboard']} large />}>
          <Features
            note={info || ''}
            reservationId={id}
            selectedFeatures={features}
          />
        </ReservationDataRow>
        <ReservationDataRow header={<Icon icon="pen" />}>
          <AdditionalInfo
            features={features}
            note={info || ''}
            reservationId={id}
          />
        </ReservationDataRow>
      </Gutter>
    </ReservationCard>
  )
}

export default RoomReservation
