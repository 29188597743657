import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { NotFound } from '@/components/NotFound'
import { PageRoute } from '@/components/Page'
import { globalRoutes } from '@/config/globalRoutes'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'

import { NavInternalViewType as Type, NavItemType } from '~generated-types'

import { OpenAdvances } from './OpenAdvances'
import { Payments } from './Payments'
import { Reservations } from './Reservations'

export const ReportsRoutes = () => {
  const { path } = useRouteMatch()
  const { allNavDropdownInternalTypes } = useWillbaConfigContext()

  return (
    <Switch>
      <Redirect exact from={path} to={globalRoutes[Type.ReportPayment]} />

      {allNavDropdownInternalTypes.includes(Type.ReportPayment) && (
        <PageRoute
          component={Payments}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Report}`,
            `Navigation:navDropdownItem.${Type.ReportPayment}`,
          ]}
          path={globalRoutes[Type.ReportPayment]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.ReportReservation) && (
        <PageRoute
          component={Reservations}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Report}`,
            `Navigation:navDropdownItem.${Type.ReportReservation}`,
          ]}
          path={globalRoutes[Type.ReportReservation]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.ReportOpenAdvance) && (
        <PageRoute
          component={OpenAdvances}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Report}`,
            `Navigation:navDropdownItem.${Type.ReportOpenAdvance}`,
          ]}
          path={globalRoutes[Type.ReportOpenAdvance]}
        />
      )}

      <PageRoute pageTitle="404">
        <NotFound />
      </PageRoute>
    </Switch>
  )
}

export default ReportsRoutes
