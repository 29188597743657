import { ParticipantsList } from '@/modules/ParticipantsList'

import { useCompactViewContext } from '../../SaleCompactView/CompactViewContainer'
import { SectionContainer } from '../components'
import { useSalesDetailsContext } from '../SalesDetailsState'
import { SectionFeatures } from '../types'

const SECTION: SectionFeatures = {
  icon: 'user-group',
  key: 'participants',
}

const ParticipantsComponent = () => {
  const { data, saleReadOnly } = useSalesDetailsContext()
  const { salesId } = useCompactViewContext()

  return (
    <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
      <ParticipantsList ownerId={salesId || data.id} readOnly={saleReadOnly} />
    </SectionContainer>
  )
}

export const Participants = Object.freeze({
  component: ParticipantsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
