import { draftToMarkdown } from 'markdown-draft-js'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { salesHooks } from '@/modules/Sales/hooks'

import { SalesForPrint, SalesPrintConfigKey as Key } from '../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../utils/salesPrintConfig'
import { Customer } from './Customer'
import { Notes } from './Notes'

type Props = {
  sales: SalesForPrint['sales']
}

export const BasicDetails = ({
  sales: { customer, estimatedDates, notes, reservationDates },
}: Props) => {
  const { salesPrintConfig: config } = salesHooks.useSalesDetailsContext()

  const salesDates = estimatedDates
    ? formatDate(estimatedDates)
    : reservationDates
      ? formatDate(reservationDates)
      : null

  const notesMarkdown = notes ? draftToMarkdown(JSON.parse(notes)) : ''

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.BasicDetails}.${key}`)

  const showCustomer = !!customer && checkVisibility(Key.Customer)
  const showDates = checkVisibility(Key.Dates)
  const showNotes = !!notesMarkdown && checkVisibility(Key.Notes)

  return (
    <>
      {showDates && <Dates>{salesDates}</Dates>}

      {showCustomer && (
        <>
          <Spacer />
          <Customer customer={customer} />
        </>
      )}

      {showNotes && (
        <>
          <Spacer />
          <Notes markdown={notesMarkdown} />
        </>
      )}
    </>
  )
}

////////////

const formatDate = (date: { start: string; end: string }) => {
  const isSameDate = moment(date.start).isSame(moment(date.end), 'day')
  const isSameYear = moment(date.start).isSame(moment(date.end), 'year')

  return isSameDate
    ? moment(date.start).format('DD.MM.YY')
    : `${moment(date.start).format(
        isSameYear ? 'DD.MM' : 'DD.MM.YY'
      )} – ${moment(date.end).format('DD.MM.YY')}`
}

const Dates = styled.span`
  font-size: 1.25rem;
  font-weight: 500;
`

const Spacer = styled.span`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(5)}rem;
  `}
`
