import { gql, useMutation } from '@apollo/client'

import type {
  CreateMealMutation,
  CreateMealMutationVariables,
} from '~generated-types'

import { MealFragment } from '../fragments'

const CREATE_MEAL_MUTATION = gql`
  ${MealFragment}

  mutation CreateMeal($input: ScheduledMealCreateInput!) {
    scheduledMealCreate(input: $input) {
      ...Meal
    }
  }
`

export const useCreateMealMutation = () =>
  useMutation<CreateMealMutation, CreateMealMutationVariables>(
    CREATE_MEAL_MUTATION,
    {
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        if (data) {
          cache.modify({
            fields: {
              scheduledMealsBySales: (previous, { toReference }) => [
                ...previous,
                toReference(data.scheduledMealCreate),
              ],
            },
          })
        }
      },
    }
  )
