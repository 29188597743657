import styled, { css } from 'styled-components/macro'

type Props = {
  column?: boolean
}

export const InputDescription = styled.span<Props>`
  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(45)}rem;
    margin-bottom: ${theme.spacing.gutterSmall};
    padding: 0 ${theme.spacing.gu(1)}rem;

    font-size: ${theme.typography.fontSizeSmall};
    font-style: italic;
    color: ${theme.palette.text.light};

    &:last-child {
      margin-bottom: -${theme.typography.fontSizeBaseInPx}px;
    }
  `}
`

export default InputDescription
