import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'
import moment, { Moment } from 'moment'

import { useRouteDate, useRouteValue } from '@/utils/hooks'

import { useRoomReservationsForDate } from './hooks'
import {
  CleaningStatus,
  Participant,
  ParticipantRoom,
  Reservation,
  ReservationType,
  Room,
  RoomReservation,
  RoomStatus,
  Sales,
} from './types'

type LayoutVariables = {
  SIDEBAR_WIDTH: number
}

type ContextType = {
  // Data
  availableParticipants: Participant[]
  availableSales: Sales[]
  buildings: string[]
  cleaningStatuses: CleaningStatus[]
  date: Moment
  error?: ApolloError
  isFiltersOpen: boolean
  layoutVariables: LayoutVariables
  loading: boolean
  reservationTypes: ReservationType[]
  roomReservations: RoomReservation[]
  roomStatuses: RoomStatus[]
  selectedParticipant: Participant | null
  selectedSale: Sales | null
  targetId: string | null

  // Methods
  isArrivingReservation: (reservation: Reservation) => boolean
  isDepartingReservation: (reservation: Reservation) => boolean
  isParticipantsCorrect: (roomId: string) => boolean
  isPresentReservation: (reservation: Reservation) => boolean
  setBuildings: (buildings: string[]) => void
  setCleaningStatuses: (statuses: CleaningStatus[]) => void
  setDate: (date: Moment) => void
  setFiltersOpen: (isOpen: boolean) => void
  setRoomStatuses: (statuses: RoomStatus[]) => void
  setReservationTypes: (types: ReservationType[]) => void
  setSelectedParticipant: (sale: Participant | null) => void
  setSelectedSale: (sale: Sales | null) => void
  setTargetId: (id: string | null) => void
  updateRoom: (room: Room) => void
}

const RoomReservationsContext = createContext<ContextType>({
  availableParticipants: [],
  availableSales: [],
  buildings: [],
  cleaningStatuses: [],
  date: moment(),
  error: undefined,
  isArrivingReservation: () => false,
  isDepartingReservation: () => false,
  isFiltersOpen: false,
  isParticipantsCorrect: () => false,
  isPresentReservation: () => false,
  layoutVariables: {
    SIDEBAR_WIDTH: 280,
  },
  loading: false,
  reservationTypes: [],
  roomReservations: [],
  roomStatuses: [],
  selectedParticipant: null,
  selectedSale: null,
  setBuildings: () => undefined,
  setCleaningStatuses: () => undefined,
  setDate: () => undefined,
  setFiltersOpen: () => undefined,
  setReservationTypes: () => undefined,
  setRoomStatuses: () => undefined,
  setSelectedParticipant: () => undefined,
  setSelectedSale: () => undefined,
  setTargetId: () => undefined,
  targetId: null,
  updateRoom: () => undefined,
})

type Props = {
  children: ReactNode
}

export const RoomReservationsContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const LAYOUT_VARIABLES = Object.freeze({
    SIDEBAR_WIDTH: 280,
  })

  const { date, setDate } = useRouteDate()
  const { setValue: setBuildings, value: buildings } = useRouteValue({
    multi: true,
    routeKey: 'building',
  })
  const { setValue: setCleaningStatuses, value: cleaningStatuses } =
    useRouteValue({
      multi: true,
      routeKey: 'cleaningStatus',
      whitelist: [CleaningStatus.Clean, CleaningStatus.Dirty],
    })
  const { setValue: setRoomStatuses, value: roomStatuses } = useRouteValue({
    multi: true,
    routeKey: 'roomStatus',
    whitelist: [RoomStatus.Occupied, RoomStatus.Reserved, RoomStatus.Vacant],
  })
  const { setValue: setReservationTypes, value: reservationTypes } =
    useRouteValue({
      multi: true,
      routeKey: 'type',
      whitelist: [
        ReservationType.Arrivinng,
        ReservationType.Departing,
        ReservationType.Pending,
      ],
    })

  const {
    availableParticipants: availableParticipantsData,
    availableSales: availableSalesData,
    error,
    loading: fetching,
    roomReservations: roomReservationsData,
  } = useRoomReservationsForDate({ date })

  const [availableParticipants, setAvailableParticipants] = useState<
    Participant[]
  >(availableParticipantsData)
  const [availableSales, setAvailableSales] =
    useState<Sales[]>(availableSalesData)
  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [roomReservations, setRoomReservations] =
    useState<RoomReservation[]>(roomReservationsData)
  const [targetId, setTargetId] = useState<string | null>(null)
  const [selectedParticipant, setParticipant] = useState<Participant | null>(
    null
  )
  const [selectedSale, setSale] = useState<Sales | null>(null)

  const setSelectedParticipant = (participant: Participant | null) => {
    setParticipant(participant || null)
    localStorage.setItem(
      'roomReservationsParticipantFilter',
      JSON.stringify(participant)
    )
  }

  const setSelectedSale = (sale: Sales | null) => {
    setSale(sale || null)
    localStorage.setItem('roomReservationsSalesFilter', JSON.stringify(sale))
  }

  const isArrivingReservation = ({ estimatedCheckIn }: Reservation) =>
    date.isSame(estimatedCheckIn, 'day')

  const isDepartingReservation = ({ estimatedCheckOut }: Reservation) =>
    date.isSame(estimatedCheckOut, 'day')

  const isPresentReservation = ({
    estimatedCheckIn,
    estimatedCheckOut,
  }: Reservation) =>
    date.isAfter(estimatedCheckIn, 'day') &&
    date.isBefore(estimatedCheckOut, 'day')

  const getParticipantIds = (roomParticipants: ParticipantRoom[]) => [
    ...new Set(roomParticipants.map(({ participant }) => participant.id)),
  ]

  const isParticipantsCorrect = (roomId: string) => {
    const currentRoomReservation = roomReservationsData.find(
      ({ room }) => room.id === roomId
    )

    if (currentRoomReservation) {
      const { reservations, room } = currentRoomReservation

      const participantIds = getParticipantIds(room.status.occupants)
      const arrivingParticipantIds = getParticipantIds(
        reservations.find(isArrivingReservation)?.participantRooms || []
      )
      const departingParticipantIds = getParticipantIds(
        reservations.find(isDepartingReservation)?.participantRooms || []
      )
      const presentParticipantIds = getParticipantIds(
        reservations.find(isPresentReservation)?.participantRooms || []
      )

      return (
        participantIds.every((id) => departingParticipantIds.includes(id)) ||
        participantIds.every((id) => arrivingParticipantIds.includes(id)) ||
        participantIds.every((id) => presentParticipantIds.includes(id))
      )
    }

    return false
  }

  const updateRoom = (room: Room) =>
    setRoomReservations((roomReservations) =>
      roomReservations.map((r) => ({
        ...r,
        room: r.room.id === room.id ? room : r.room,
      }))
    )

  useEffect(() => {
    setRoomReservations(roomReservationsData)
  }, [roomReservationsData])

  useEffect(() => {
    setAvailableParticipants(availableParticipantsData)
  }, [availableParticipantsData])

  useEffect(() => {
    setAvailableSales(availableSalesData)
  }, [availableSalesData])

  useEffect(() => {
    setLoading(fetching)
  }, [fetching])

  useEffect(() => {
    setSale(
      JSON.parse(localStorage.getItem('roomReservationsSalesFilter') || 'null')
    )
  }, [])

  contextValueRef.current = {
    availableParticipants,
    availableSales,
    buildings: (buildings as string[]) || [],
    cleaningStatuses: (cleaningStatuses as CleaningStatus[]) || [],
    date,
    error,
    isArrivingReservation,
    isDepartingReservation,
    isFiltersOpen,
    isParticipantsCorrect,
    isPresentReservation,
    layoutVariables: LAYOUT_VARIABLES,
    loading,
    reservationTypes: (reservationTypes as ReservationType[]) || [],
    roomReservations,
    roomStatuses: (roomStatuses as RoomStatus[]) || [],
    selectedParticipant,
    selectedSale,
    setBuildings,
    setCleaningStatuses,
    setDate,
    setFiltersOpen,
    setReservationTypes,
    setRoomStatuses,
    setSelectedParticipant,
    setSelectedSale,
    setTargetId,
    targetId,
    updateRoom,
  }

  return (
    <RoomReservationsContext.Provider value={contextValueRef.current}>
      {children}
    </RoomReservationsContext.Provider>
  )
}

export const useRoomReservationsContext = () =>
  useContext(RoomReservationsContext)
