import { T } from '@/modules/Language'

type CustomLabels = {
  [key: string]: {
    [locale: string]: string
  }
}

export const customLabelItemRenderer =
  (customLabels: CustomLabels, locale: string) =>
  (label: string, count: number) => (
    <span>
      <span>{customLabels[label][locale]}</span>
      <span>{count}</span>
    </span>
  )

export const l10nListItemRenderer =
  (l10nPrefix: string) => (label: string, count: number) => (
    <span>
      <span>
        <T l10n={`${l10nPrefix}.${label}`}></T>
      </span>
      <span>{count}</span>
    </span>
  )
