import { ApolloQueryResult } from '@apollo/client'

import type { AccommodationAvailabilityQuery } from '~generated-types'

type AccommodationAvailability =
  AccommodationAvailabilityQuery['accommodationAvailability']

type AvailabilityStatus = AccommodationAvailability['roomTypes'][0]['status'][0]
type RoomType = AccommodationAvailability['roomTypes'][0]['roomType']
type Room = AccommodationAvailability['roomTypes'][0]['rooms'][0]['room']

export type AccommodationLevel = RoomType['accommodationLevel']
export type RoomTypeCategory = RoomType['category']

type AvailabilityByDate = {
  [dateKey: string]: AvailabilityStatus
}

type Emphasis = 'FULL' | 'START' | 'END' | 'MIDDLE' | 'NONE'

type EmphasisByDate = {
  [dateKey: string]: Emphasis
}

type EmphasisDetails = {
  rooms: {
    [roomId: string]: EmphasisByDate
  }
  roomTypes: {
    [roomTypeId: string]: EmphasisByDate
  }
}

type Mode = 'confirmed' | 'tentative'

type RoomAvailability = {
  room: Room
  status: AvailabilityByDate
}

type RoomTypeAvailability = {
  rooms: RoomAvailability[]
  roomType: RoomType
  status: AvailabilityByDate
}

type CategoryAvailability = {
  category: RoomTypeCategory
  roomTypes: RoomTypeAvailability[]
  status: AvailabilityByDate
}

type AccommodationLevelAvailability = {
  accommodationLevel: AccommodationLevel
  categories: CategoryAvailability[]
  status: AvailabilityByDate
}

type GroupedRoomTypeAvailability = {
  accommodationLevel: AccommodationLevel
  categories: Array<{
    category: RoomTypeCategory
    roomTypes: RoomTypeAvailability[]
  }>
}

export type RefetchResponce = ApolloQueryResult<AccommodationAvailabilityQuery>

type AvailabilityTableProps = {
  emphasis: EmphasisDetails
  mode: Mode
  openSections: {
    [sectionId: string]: boolean
  }
  refetchAvailability: () => Promise<RefetchResponce>
  sectionClose?: (sectionId: string) => void
  sectionOpen?: (sectionId: string) => void
  toggleSectionOpen: (sectionId: string) => void
}

export enum CellStatus {
  OCCUPIED = 'OCCUPIED',
  PARTIAL = 'PARTIAL',
  UNAVAILABLE = 'UNAVAILABLE',
  VACANT = 'VACANT',
}

export type {
  AccommodationLevelAvailability,
  AvailabilityByDate,
  AvailabilityStatus,
  AvailabilityTableProps,
  CategoryAvailability,
  GroupedRoomTypeAvailability,
  Emphasis,
  EmphasisByDate,
  EmphasisDetails,
  Mode,
  RoomAvailability,
  RoomTypeAvailability,
}

export type { AccommodationAvailabilityQuery as AccommodationAvailabilityQueryData } from '~generated-types'

export type RawRoomTypeAvailability = AccommodationAvailability['roomTypes'][0]
export type RawAvailabilityStatus = RawRoomTypeAvailability['status'][0]
