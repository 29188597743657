import styled, { css } from 'styled-components/macro'

export const MaxWidthWithPadding = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(5)}rem;
    max-width: ${theme.spacing.gu(80)}rem;
  `}
`

export default MaxWidthWithPadding
