import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useCalendarState } from '@/modules/Reservations'
import { useTheme } from '@/theme'

import { useReservationsGridState } from '../ReservationsGrid'
import { SalesDetailsRow } from './SalesDetailsRow'
import { ShortcutKeysInfo } from './ShortcutKeysInfo'

export const ViewControls = () => {
  const theme = useTheme()

  const { calendarDensity, setCalendarDensity } = useReservationsGridState()
  const { isReservationsLocked, setReservationsLocked, setViewMode, viewMode } =
    useCalendarState()

  return (
    <Wrapper alignItems="center">
      <SalesDetailsRow />

      <Spacer />

      <FlexRow alignItems="center">
        <Tooltip
          content={
            <T>{`ResourceReservationsCalendar:lockTooltip.${
              isReservationsLocked ? 'unlock' : 'lock'
            }`}</T>
          }
          trigger={(triggerProps) => (
            <span
              onClick={() => setReservationsLocked(!isReservationsLocked)}
              {...triggerProps}
            >
              <LockIcon
                fixedWidth
                icon={isReservationsLocked ? 'lock' : 'lock-open'}
                isLocked={isReservationsLocked}
                size="lg"
              />
            </span>
          )}
        />

        {/* VIEW MODE */}
        <ButtonGroup style={{ background: theme.palette.white }}>
          <ButtonGroupButton
            noNudge
            size="small"
            onClick={() => setViewMode('DATE')}
            selected={viewMode === 'DATE'}
          >
            <T>ResourceReservationsCalendar:viewMode.day</T>
          </ButtonGroupButton>
          <ButtonGroupButton
            noNudge
            size="small"
            onClick={() => setViewMode('WEEK')}
            selected={viewMode === 'WEEK'}
          >
            <T>ResourceReservationsCalendar:viewMode.week</T>
          </ButtonGroupButton>
        </ButtonGroup>

        {/* CALENDAR DENSITY */}
        <ButtonGroup style={{ background: theme.palette.white }}>
          <ButtonGroupButton
            noNudge
            size="small"
            onClick={() => setCalendarDensity('S')}
            selected={calendarDensity === 'S'}
          >
            S
          </ButtonGroupButton>
          <ButtonGroupButton
            noNudge
            size="small"
            onClick={() => setCalendarDensity('M')}
            selected={calendarDensity === 'M'}
          >
            M
          </ButtonGroupButton>
          <ButtonGroupButton
            noNudge
            size="small"
            onClick={() => setCalendarDensity('L')}
            selected={calendarDensity === 'L'}
          >
            L
          </ButtonGroupButton>
        </ButtonGroup>

        <ShortcutKeysInfo />
      </FlexRow>
    </Wrapper>
  )
}

///////

const Spacer = styled.div`
  min-width: 68px;
  flex: 1;
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig}
      ${theme.spacing.gutter} ${theme.spacing.gu(8)}rem;
    border-bottom: 1px solid ${theme.palette.smoke.main};
    background: ${theme.palette.smoke.extraLight};
  `}
`

const LockIcon = styled(({ isLocked, ...props }) => (
  <FontAwesomeIcon {...props} />
))`
  cursor: pointer;
  margin-right: ${({ isLocked }) => (isLocked ? 2 : 0)}px;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
    `}
  }
`
