import { ReactNode } from 'react'

import { documentHooks, DownloadableDocument } from '@/modules/Document'

type RenderProps = {
  documents: DownloadableDocument[]
  error: boolean
  loading: boolean
}

type Props = {
  ownerId: string
  render: (arg0: RenderProps) => ReactNode
  templateType: string
}

const DocumentPDFDownloader = ({ ownerId, render, templateType }: Props) => {
  const { documentsByTemplateType, error, loading } =
    documentHooks.useOwnerDocuments({
      ownerId,
    })

  const documents = documentsByTemplateType[templateType] || []

  return render({
    documents,
    error,
    loading,
  })
}

export default DocumentPDFDownloader

export type DocumentPDFDownloaderRenderProps = RenderProps
