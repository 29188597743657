import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ActionButton } from '@/modules/Order/components'
import { orderServices } from '@/modules/Order/services'
import { Invoice, InvoiceByIdPayload } from '@/modules/Order/types'
import { CatalogProductPicker } from '@/modules/Products'

import {
  InvoiceAction as IA,
  InvoiceState as IS,
  OrderPacketSourceType as OPST,
} from '~generated-types'

import { SectionSeparator } from '../../components'
import { OrderItemSelector } from './OrderItemSelector'

type Props = {
  invoice: Invoice
  refetch: () => Promise<ApolloQueryResult<InvoiceByIdPayload>>
  salesId: string
}

export const OrderItemsActions = ({
  invoice: {
    id: invoiceId,
    items,
    lifecycle: { state, validatedActions },
    seller,
  },
  refetch,
  salesId,
}: Props) => {
  const { addPurchaseFromCatalogProduct } = orderServices.invoiceService()

  const [productPickerType, setProductPickerType] = useState<OPST | null>(null)

  const addManualProductAction = validatedActions.find(
    (a) => a.action === IA.UpdateLines
  )

  const serviceFeeItems = items.filter((i) => i.source.type === OPST.ServiceFee)

  const isServiceFeeValid = state === IS.Draft && !serviceFeeItems.length
  const isManualItemValid = addManualProductAction?.valid

  const showActions = isManualItemValid || isServiceFeeValid || !!items.length

  const advanceProducts = seller?.productsConfig.advanceProducts ?? []
  const serviceFeeProducts = seller?.productsConfig.serviceFeeProducts ?? []

  const handleAddPurchase = (catalogProductId: string, type: OPST) =>
    addPurchaseFromCatalogProduct({
      add: { link: { salesId } },
      catalogProductId,
      invoice: { invoiceId, serviceFee: type === OPST.ServiceFee },
    })
      .then(refetch)
      .then(() => setProductPickerType(null))

  return (
    <>
      {showActions && (
        <FlexRow>
          {addManualProductAction &&
            isManualItemValid &&
            (advanceProducts.length ? (
              <OrderItemSelector
                invoiceId={invoiceId}
                products={advanceProducts}
                refetch={refetch}
                salesId={salesId}
                type={OPST.Manual}
              />
            ) : (
              <ActionButton
                action={addManualProductAction.action}
                label={<T>Orders:OrderItems.addManualProduct</T>}
                onClick={() => setProductPickerType(OPST.Manual)}
              />
            ))}

          {isManualItemValid && isServiceFeeValid && <Separator light />}

          {isServiceFeeValid &&
            (serviceFeeProducts.length ? (
              <OrderItemSelector
                invoiceId={invoiceId}
                products={serviceFeeProducts}
                refetch={refetch}
                salesId={salesId}
                type={OPST.ServiceFee}
              />
            ) : (
              <ActionButton
                action="ADD_SERVICE_FEE"
                label={<T>Orders:OrderItems.addServiceFee</T>}
                onClick={() => setProductPickerType(OPST.ServiceFee)}
              />
            ))}
        </FlexRow>
      )}

      {productPickerType && (
        <CatalogProductPicker
          onItemSelect={(id) => handleAddPurchase(id, productPickerType)}
          onClose={() => setProductPickerType(null)}
          sellerId={seller?.id}
        />
      )}
    </>
  )
}

//////

const Separator = styled(SectionSeparator)`
  height: 100%;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1.5)}rem;
  `}
`
