import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ActionDropdownClickable } from './ActionDropdownClickable'

const LinkBase = styled(ActionDropdownClickable)`
  text-decoration: none;
`
export const ActionDropdownLink = styled(LinkBase).attrs(() => ({
  as: 'a',
}))<AnyObject>``
export const ActionDropdownRRLink = styled(LinkBase).attrs(() => ({
  as: Link,
}))<AnyObject>``
