import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import { EducationLevel, ParticipantLifeStage } from '~generated-types'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import { EducationAndLifeStageModal } from './EducationAndLifeStageModal'

type Props = {
  education: EducationLevel | null
  lifeStage: ParticipantLifeStage | null
  handleUpdateEducation: (
    education: EducationLevel | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateLifeStage: (
    lifeStage: ParticipantLifeStage | null
  ) => Promise<FetchedParticipant | undefined>
  readOnly?: boolean
}

const EducationAndLifeStage = ({
  education,
  lifeStage,
  handleUpdateEducation,
  handleUpdateLifeStage,
  readOnly,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalContainer
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            ref={ref}
            disabled={readOnly}
            minWidth={`${spacing.gu(16)}rem`}
            onClick={readOnly ? () => null : () => setModalOpen(true)}
            renderContent={() =>
              education || lifeStage ? (
                <>
                  {education && (
                    <span>
                      <T>{`enums:education.${education}`}</T>
                    </span>
                  )}
                  {lifeStage && (
                    <span>
                      <T>{`enums:lifeStage.${lifeStage}`}</T>
                    </span>
                  )}
                </>
              ) : (
                <FlexRow>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon={'plus'}
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                  <Label>
                    <T>ParticipantsList:PersonalFields.educationAndLifeStage</T>
                  </Label>
                </FlexRow>
              )
            }
          />
        )}
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        modal={
          <EducationAndLifeStageModal
            education={education}
            lifeStage={lifeStage}
            handleUpdateEducation={handleUpdateEducation}
            handleUpdateLifeStage={handleUpdateLifeStage}
          />
        }
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

export default EducationAndLifeStage
