import styled, { css } from 'styled-components/macro'

type Props = {
  disabled?: boolean
  color?: string
}

export const InputIndicatorBase = styled.span<Props>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.guPx(1)}px;
    width: 16px;
    height: 16px;
  `}

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.dark};
  `}

  border-color: ${({ color, disabled, theme }) =>
    disabled ? theme.palette.smoke.main : color || theme.palette.smoke.dark};
  ${({ theme }) => css`
    border-radius: ${theme.spacing.guPx(1) - 4}px;
  `}
  background: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.light
      : props.theme.palette.white};

  &::before {
    content: '';
  }
`

export default InputIndicatorBase
