import { gql } from '@apollo/client'

const accommodationRoomFeatureFragment = gql`
  fragment AccommodationRoomFeatureForSalesPrint on RoomLabel {
    icon
    id
    name
    shortName
  }
`

export const accommodationRoomReservationFragment = gql`
  ${accommodationRoomFeatureFragment}

  fragment AccommodationRoomReservationForSalesPrint on RoomReservation {
    id
    request {
      beds
      checkIn {
        date
      }
      checkOut {
        date
      }
      extraBeds
      features {
        ...AccommodationRoomFeatureForSalesPrint
      }
      info
      room {
        id
        internalInfo
        number
      }
    }
    target {
      default
      id
      name
      sortOrder
    }
  }
`

const accommodationRoomTypeReservationFragment = gql`
  ${accommodationRoomFeatureFragment}
  ${accommodationRoomReservationFragment}

  fragment AccommodationRoomTypeReservationForSalesPrint on RoomTypeReservation {
    id
    request {
      beds
      checkIn {
        date
      }
      checkOut {
        date
      }
      extraBeds
      features {
        ...AccommodationRoomFeatureForSalesPrint
      }
      info
      rooms
      roomType {
        id
        name
      }
    }
    roomReservations {
      ...AccommodationRoomReservationForSalesPrint
    }
    target {
      default
      id
      name
      sortOrder
    }
  }
`

export default gql`
  ${accommodationRoomReservationFragment}
  ${accommodationRoomTypeReservationFragment}

  fragment AccommodationForSalesPrint on SalesAccommodation {
    id
    roomReservations(includeRoomTypeRooms: false) {
      ...AccommodationRoomReservationForSalesPrint
    }
    roomTypeReservations {
      ...AccommodationRoomTypeReservationForSalesPrint
    }
  }
`
