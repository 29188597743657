import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Mode } from '../../AvailabilityCalendar.types'
import { FilterWrapper } from './FilterWrapper'

type Props = {
  mode: Mode
  setMode: (mode: Mode) => void
}

export const ModeSelector = ({ mode, setMode }: Props) => {
  const theme = useTheme()

  const modeOptions = [
    {
      label: <T>Accommodation:AvailabilityCalendar.modes.tentative</T>,
      value: 'tentative',
    },
    {
      label: <T>Accommodation:AvailabilityCalendar.modes.confirmed</T>,
      value: 'confirmed',
    },
  ]

  return (
    <FilterWrapper
      label={<T>Accommodation:AvailabilityCalendar.mode</T>}
      width={`${theme.spacing.gu(32)}rem`}
    >
      <ThemedSelect
        name="calendar-mode-selector"
        onChange={(selected: Option | null | undefined) =>
          setMode(selected?.value as Mode)
        }
        options={modeOptions}
        placeholder=""
        value={{
          label: <T>{`Accommodation:AvailabilityCalendar.modes.${mode}`}</T>,
          value: mode,
        }}
      />
    </FilterWrapper>
  )
}
