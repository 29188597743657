import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { CustomerManager } from '@/modules/Registry/Customer'
import { customerHooks } from '@/modules/Registry/Customer/hooks'
import { CustomerTarget } from '@/modules/Registry/Customer/types'
import { salesHooks } from '@/modules/Sales/hooks'
import * as salesMutations from '@/modules/Sales/mutations'

import { ContentSection } from '../../../components'
import SalesCustomerPicker from './SalesCustomerPicker'

export const Customer = () => {
  const {
    data: { id: salesId },
    saleReadOnly: readOnly,
  } = salesHooks.useSalesDetailsContext()

  const { customer, refetch } = customerHooks.useSalesCustomer({ salesId })

  const [setCustomerAddress] =
    salesMutations.useSetSalesCustomerAddressMutation()
  const [setCustomerContact] =
    salesMutations.useSetSalesCustomerContactMutation()
  const [setSalesCustomer] = salesMutations.useSetSalesCustomerMutation()

  const setAddress = (id: string | null) =>
    setCustomerAddress({
      variables: { input: { addressId: id, salesId } },
    })
      .then(() => {
        refetch()

        return undefined
      })
      .catch(() => undefined)

  const setContact = (id: string | null) =>
    setCustomerContact({
      variables: { input: { customerContactId: id, salesId } },
    })
      .then(() => {
        refetch()

        return undefined
      })
      .catch(() => undefined)

  const setCustomer = (
    target: {
      customer: CustomerTarget | null
      addressId?: string | null
      contactId?: string | null
    } | null
  ) =>
    setSalesCustomer({
      variables: {
        input: {
          addressId: target?.addressId,
          customerContactId: target?.contactId,
          customerId: target?.customer?.id,
          salesId,
        },
      },
    })
      .then(() => {
        refetch()

        return undefined
      })
      .catch(() => undefined)

  return (
    <ContentSection title={<T>Customers:title</T>} flex={3}>
      <CustomerWrapper alignItems="flex-start">
        <CustomerManager
          renderContent={(renderProps) => (
            <SalesCustomerPicker readOnly={readOnly} {...renderProps} />
          )}
          selectedAddressId={customer?.address?.id}
          selectedContactId={customer?.contact?.id}
          selectedCustomer={
            customer
              ? {
                  customerNumber: customer.customer.customerNumber,
                  id: customer.customer.id,
                }
              : null
          }
          setAddress={setAddress}
          setContact={setContact}
          setCustomer={setCustomer}
        />
      </CustomerWrapper>
    </ContentSection>
  )
}

const CustomerWrapper = styled(FlexColumn)`
  flex: 1;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem;
  `}
`
