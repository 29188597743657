import { CalendarDensity } from '../ReservationsGridState'

export const getColumnWidthByDensity = (
  columnWidth: number,
  density: CalendarDensity
) => {
  switch (density) {
    case 'L':
      return columnWidth + 20
    case 'M':
      return columnWidth + 10
    case 'S':
    default:
      return columnWidth
  }
}
