import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { ValidationIndicator } from '@/components/ValidationIndicator'
import { UserGroup } from '@/modules/Registry/UserGroup'
import { TaskManager } from '@/modules/Task'
import { Task } from '@/modules/Task/types'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import { Name, Price, Quantity, Separator, TimeInterval } from './common'

type Props = {
  isLast: boolean
  onUpdateTasks: (tasks: Task[]) => void
  readOnly: boolean
  setTargetTaskNumber: (targetTaskNumber: number | null) => void
  targetTaskNumber: number | null
  task: Task
}

// TODO Purchase quantity

export const TaskRow = ({
  isLast,
  onUpdateTasks,
  readOnly,
  setTargetTaskNumber,
  targetTaskNumber,
  task,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { assignee, description, dueDate, dueTime, isOpen, number } = task

  const purchases = [...task.purchaseProducts].sort(
    (a, b) =>
      moment(a.auditLog.createdAt).valueOf() -
      moment(b.auditLog.createdAt).valueOf()
  )

  return (
    <ModalContainer
      isOpen={targetTaskNumber === number}
      modal={
        <TaskManager
          context="SALES"
          onClose={() => setTargetTaskNumber(null)}
          onUpdateTasks={onUpdateTasks}
          readOnly={readOnly}
          task={task}
          taskNumber={number}
        />
      }
      onClose={() => setTargetTaskNumber(null)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Row
          isLast={isLast}
          isOpen={targetTaskNumber === number}
          onClick={() => setTargetTaskNumber(number)}
          ref={ref}
        >
          <FlexColumn noPadding>
            <FlexRow alignItems="center">
              <Name customMargin={`0 ${spacing.gu(1)}rem`} width={23}>
                {(assignee as UserGroup)?.name}
              </Name>

              <FontAwesomeIcon
                color={isOpen ? palette.smoke.extraDark : palette.success.main}
                fixedWidth
                icon={isOpen ? ['far', 'hourglass'] : 'check'}
                size="sm"
                style={{ marginRight: spacing.gutter, width: spacing.gutter }}
              />

              <Separator />

              <TimeInterval>
                {dueDate && getTaskDateLabel(dueDate, dueTime)}
              </TimeInterval>

              <Separator />

              <Name compactWidth={34} width={46}>
                {description}
              </Name>

              <Separator transparent />

              <Separator />

              {purchases[0] ? (
                <>
                  <Name compactWidth={18} width={26}>
                    {purchases[0].status.issues.map(({ code, key, level }) => (
                      <ValidationIndicatorWrapper key={`${key}-${code}`}>
                        <ValidationIndicator
                          code={code}
                          level={level}
                          style={{ margin: `0 ${spacing.gutterSmall}` }}
                        />
                      </ValidationIndicatorWrapper>
                    ))}
                    {purchases[0].product.name}
                  </Name>

                  <Quantity>
                    {/* <>
                      {purchases[0].quantity}{' '}
                      <T>enums:quantityUnit.abbrev.PIECE</T>
                    </> */}
                  </Quantity>

                  <Price>
                    {formatCurrency(purchases[0].totalPrice.amountVatIncluded)}{' '}
                    €
                  </Price>
                </>
              ) : (
                <>
                  <Name compactWidth={18} width={26} />

                  <Quantity />

                  <Price />
                </>
              )}
            </FlexRow>

            {purchases
              .slice(1)
              .map(({ id, product: { name }, status, totalPrice }) => (
                <FlexRow alignItems="center" key={id}>
                  <Name width={25} />

                  <Separator />

                  <TimeInterval />

                  <Separator />

                  <Name compactWidth={34} width={46} />

                  <Separator transparent />

                  <Separator />

                  <FlexRow
                    alignItems="center"
                    style={{ paddingTop: `${spacing.guPx(1) - 4}px` }}
                  >
                    <Name compactWidth={18} width={26}>
                      {status.issues.map(({ code, key, level }) => (
                        <ValidationIndicatorWrapper key={`${key}-${code}`}>
                          <ValidationIndicator
                            code={code}
                            level={level}
                            style={{ margin: `0 ${spacing.gutterSmall}` }}
                          />
                        </ValidationIndicatorWrapper>
                      ))}
                      {name}
                    </Name>

                    <Quantity>
                      {/* {quantity} <T>enums:quantityUnit.abbrev.PIECE</T> */}
                    </Quantity>

                    <Price>
                      {formatCurrency(totalPrice.amountVatIncluded)} €
                    </Price>
                  </FlexRow>
                </FlexRow>
              ))}
          </FlexColumn>
        </Row>
      )}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}

////////////

const getTaskTime = (time: string) => time.slice(0, -3)

const getTaskDate = (date: string) => moment(date).format('D.M.YYYY')

const getTaskDateLabel = (dueDate: string, dueTime: string | null) =>
  dueTime
    ? `${getTaskDate(dueDate)}, ${getTaskTime(dueTime)}`
    : getTaskDate(dueDate)

const Row = styled(FlexRow)<{ isLast?: boolean; isOpen?: boolean }>`
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gu(1)}rem
      ${theme.spacing.gutter};
  `}

  ${({ isOpen, theme }) =>
    isOpen &&
    `
      border-color: ${theme.palette.primary.dark};
      box-shadow: 0 0 2px 0 ${theme.palette.primary.dark};
    `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
