import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { Group as GroupType } from '../utils/getProgramByDateKey'
import { Time, Wrapper } from './common'
import { ReservationRow } from './ReservationRow'

type Props = {
  data: GroupType
  showAllResources: boolean
  showPrices: boolean
}

export const ReservationGroupRow = ({
  data: { name, reservations },
  ...props
}: Props) => (
  <>
    <Spacer />

    <Wrapper>
      <Time />
      <Group>{name || <T>SalesDetails:PrintSale.label.GROUP</T>}</Group>
    </Wrapper>

    {reservations
      .sort(generateCompareFn(['start', 'resource.name']))
      .map((x) => (
        <ReservationRow data={x} key={x.id} {...props} />
      ))}

    <Spacer />
    <Spacer />
  </>
)

//////

const Group = styled.div`
  font-weight: 500;
  text-transform: uppercase;
`

const Spacer = styled.div`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(2)}rem;
  `}
`
