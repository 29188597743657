import styled, { css } from 'styled-components/macro'

const EndAdornmentWrapper = styled.span`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  right: 0;
  top: 0;
  padding-right: inherit;
  pointer-events: none;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
    color: ${theme.palette.text.light};
  `}
`

export default EndAdornmentWrapper
