import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import moment, { Moment } from 'moment'

import { DateRange } from '@/components/TimeControls'

import type {
  AccommodationAvailabilityQuery,
  AccommodationAvailabilityQueryVariables,
} from '~generated-types'

import { AvailabilityCalendar } from './AvailabilityCalendar'
import { AVAILABILITY_QUERY } from './AvailabilityCalendar.query'
import { EmphasisDetails, Mode } from './AvailabilityCalendar.types'
import { AvailabilityCalendarLoader } from './components'
import { groupAvailabilityData } from './utils'

type OpenSections = {
  [sectionId: string]: boolean
}

type Props = {
  defaultRange?: DateRange | null
  emphasis?: EmphasisDetails
  handleOnSelectRoom?: (arg0: {
    roomId: string
    roomNumber: string
    roomTypeName: string
  }) => void
  handleOnSelectRoomType?: (arg0: {
    roomTypeId: string
    roomTypeName: string
  }) => void
  openSectionsFromSidebar?: OpenSections
  refreshTicker?: number
}

export const AvailabilityCalendarContainer = ({
  defaultRange,
  emphasis,
  handleOnSelectRoom,
  handleOnSelectRoomType,
  openSectionsFromSidebar,
  refreshTicker,
}: Props) => {
  const [calendarMode, setCalendarMode] = useState<Mode>('tentative')
  const [isRefetching, setRefetching] = useState<boolean>(false)
  const [openSections, setOpenSections] = useState<OpenSections>({})
  const [rangeEnd, setRangeEnd] = useState<Moment>(getRangeEnd(defaultRange))
  const [rangeStart, setRangeStart] = useState<Moment>(
    getRangeStart(defaultRange)
  )

  useEffect(() => {
    if (openSectionsFromSidebar) {
      setOpenSections(openSectionsFromSidebar)
    }
  }, [openSectionsFromSidebar])

  const setDisplayRange = ({ from, to }: DateRange) => {
    setRangeEnd(to.clone())
    setRangeStart(from.clone())
  }

  const toggleSectionOpen = (sectionId: string) =>
    setOpenSections((current) => ({
      ...current,
      [sectionId]: !current[sectionId],
    }))

  const sectionOpen = (sectionId: string) =>
    setOpenSections((current) => ({
      ...current,
      [sectionId]: true,
    }))

  const sectionClose = (sectionId: string) =>
    setOpenSections((current) => ({
      ...current,
      [sectionId]: false,
    }))

  const {
    data: rawData,
    error,
    loading,
    refetch,
  } = useQuery<
    AccommodationAvailabilityQuery,
    AccommodationAvailabilityQueryVariables
  >(AVAILABILITY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        endDate: rangeEnd.format('YYYY-MM-DD'),
        startDate: rangeStart.format('YYYY-MM-DD'),
      },
    },
  })

  useEffect(() => {
    if (refreshTicker && refreshTicker > 0) {
      setRefetching(true)
      refetch && refetch().then(() => setRefetching(false))
    }
  }, [refetch, refreshTicker])

  const data = rawData
    ? groupAvailabilityData(rawData.accommodationAvailability.roomTypes)
    : []

  return (
    <>
      {isRefetching && <AvailabilityCalendarLoader />}

      <AvailabilityCalendar
        data={data}
        displayRange={{ from: rangeStart.clone(), to: rangeEnd.clone() }}
        emphasis={emphasis || { rooms: {}, roomTypes: {} }}
        error={error}
        handleOnSelectRoom={handleOnSelectRoom}
        handleOnSelectRoomType={handleOnSelectRoomType}
        loading={loading}
        mode={calendarMode}
        openSections={openSections}
        setDisplayRange={setDisplayRange}
        setMode={setCalendarMode}
        toggleSectionOpen={toggleSectionOpen}
        sectionOpen={sectionOpen}
        sectionClose={sectionClose}
        refetchAvailability={refetch}
      />
    </>
  )
}

/////

const getRangeEnd = (defaultRange?: DateRange | null) => {
  if (!defaultRange) {
    return moment().add(1, 'weeks').endOf('isoWeek')
  }

  const end = defaultRange.to.clone().endOf('isoWeek')

  // Max availability range is 6 weeks = 6 * 7 days = 42.
  // If days diff is 42 or more, whole range will be 42 or more days, so it must be shortened
  if (end.diff(getRangeStart(defaultRange), 'days') >= 42) {
    return defaultRange.from.clone().add(5, 'weeks').endOf('isoWeek')
  }

  return end
}

const getRangeStart = (defaultRange?: DateRange | null) => {
  if (!defaultRange) {
    return moment().startOf('isoWeek')
  }

  return defaultRange.from.clone().startOf('isoWeek')
}
