import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import {
  SalesForPrintAccommodationRoomReservation,
  SalesForPrintAccommodationRoomTypeReservation,
} from '../../../../types'
import { RoomReservation } from './RoomReservation'
import { RoomTypeReservation } from './RoomTypeReservation'

type Props = {
  roomReservations: SalesForPrintAccommodationRoomReservation[]
  roomTypeReservations: SalesForPrintAccommodationRoomTypeReservation[]
}

export const Accommodation = ({
  roomReservations,
  roomTypeReservations,
}: Props) => (
  <>
    <Title>
      <T>SalesDetails:PrintSale.accommodationReservations</T>
    </Title>

    {!roomReservations.length && !roomTypeReservations.length ? (
      <Placeholder>
        – <T>SalesDetails:PrintSale.noRoomReservations</T> –
      </Placeholder>
    ) : (
      <>
        {[...roomTypeReservations]
          .sort(generateCompareFn('request.roomType.name'))
          .map((r) => (
            <RoomTypeReservation key={r.id} reservation={r} />
          ))}

        {[...roomReservations]
          .sort(generateCompareFn('request.room.number'))
          .map((r) => (
            <RoomReservation key={r.id} reservation={r} />
          ))}
      </>
    )}
  </>
)

////////////

const Placeholder = styled.span`
  font-size: 1.25rem;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled.span`
  font-size: 1.375rem;
  font-weight: 600;
  page-break-after: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
    margin-top: ${theme.spacing.gu(5)}rem;
  `}
`
