import { gql } from '@apollo/client'

export default gql`
  fragment OfferInfo on Offer {
    auditLog {
      createdAt
      updatedAt
    }
    customer {
      address {
        address1
        address2
        city
        country
        postcode
      }
      businessId
      contactPerson
      customerNumber
      email
      name
      phone
      type
    }
    id
    lifecycle {
      state
      validatedActions {
        action
        issues {
          code
        }
        valid
      }
    }
    seller {
      id
      name
      shortName
    }
    totals {
      amount
      amountVatIncluded
      vatAmount
    }
    type
    validTo
    validToDuration
    validToDurationOptions
    version
  }
`
