import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

import { SalesDimension } from '../../../types'
import { DimensionHierarchyModal } from './DimensionHierarchyModal'
import { ValueReadOnly } from './ValueReadOnly'
import { ValueWrapper } from './ValueWrapper'

type Props = {
  dimension: SalesDimension
  salesId: string
  dimensions: SalesDimension[]
  disabled?: boolean
  readOnly?: boolean
}

export const DimensionHierarchy = ({
  dimension,
  salesId,
  dimensions,
  disabled,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const selected = dimensions.filter(
    (d: SalesDimension) => d.dimension.dimensionHierarchyRoot
  )

  const label = selected.length
    ? selected
        .map((d: SalesDimension) => d.selectedLabel?.name)
        .filter(Boolean)
        .join(' | ')
    : ''

  return readOnly ? (
    <FlexColumn style={{ padding: `${spacing.gu(0.5)}rem` }}>
      <ValueReadOnly
        label={label}
        placeholder={readOnly ? dimension.dimension.hierarchyName || '' : ''}
        disabled={disabled}
        isInvalid={dimension.dimension.required && !dimension.selectedLabel}
      />
    </FlexColumn>
  ) : (
    <Wrapper flex={1}>
      <DimensionName>
        {dimension.dimension.hierarchyName || dimension.dimension.name}
      </DimensionName>

      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <DimensionHierarchyModal
            dimension={dimension}
            onClose={() => setModalOpen(false)}
            salesId={salesId}
            dimensions={dimensions}
          />
        }
        onClose={() => setModalOpen(false)}
        referenceElement={({ ref }) => (
          <div ref={ref}>
            <ValueWrapper
              label={label}
              isInvalid={
                dimension.dimension.required && !dimension.selectedLabel
              }
              setEditing={setModalOpen}
            />
          </div>
        )}
      />
    </Wrapper>
  )
}

///////

const DimensionName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.smoke.dark};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  max-width: 50%;
  min-width: 50%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
