import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

export const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig}
      ${theme.spacing.gu(1)}rem ${theme.spacing.gutterBig};
  `}
`

export const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
  `}
`

export const SubTitle = styled(Title)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`
