import styled from 'styled-components/macro'

import { T } from '@/modules/Language'
import { useCalendarState } from '@/modules/Reservations'

import Placeholder from './components/GridContentPlaceholder'
import GridGroup from './components/GridGroup'
import Header from './components/GridHeader'
import Loader from './components/GridLoadingIndicator'
import { useReservationsGridState } from './ReservationsGridState'

const ReservationsGrid = () => {
  const { ownerId } = useCalendarState()
  const {
    error,
    fetching,
    initialScrollRef,
    gridGroups: allGridGroups,
    scrolledCalendarWrapperRef,
  } = useReservationsGridState()

  const areAllGroupsNotEmpty = allGridGroups.some(
    (group) => group?.sections.length
  )
  const gridGroups = ownerId || areAllGroupsNotEmpty ? allGridGroups : []

  return (
    <GridWrapper>
      {/* Show an overlayed loader when fetching data */}
      {fetching && <Loader />}

      <ScrollWrapper ref={initialScrollRef}>
        <ContentWrapper ref={scrolledCalendarWrapperRef}>
          <Header />

          {error ? (
            <Placeholder>
              <T>ResourceReservationsCalendar:state.error</T>
            </Placeholder>
          ) : !gridGroups.length ? (
            <Placeholder>
              <T>ResourceReservationsCalendar:state.empty</T>
            </Placeholder>
          ) : (
            gridGroups.map((group, idx) =>
              group ? (
                <GridGroup
                  emptyPlaceholder={group.emptyPlaceholder}
                  hasError={!!group.error}
                  isFetching={group.fetching}
                  isFirst={idx === 0}
                  isFocused={group.isFocused}
                  isLast={idx === gridGroups.length - 1}
                  key={`calendar-grid-group-${group.id}`}
                  sections={group.sections}
                  title={group.title}
                  titlePlaceholder={group.titlePlaceholder}
                />
              ) : null
            )
          )}
        </ContentWrapper>
      </ScrollWrapper>
    </GridWrapper>
  )
}

export default ReservationsGrid

////////////

const ContentWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-height: 100%;
`

const GridWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
`

const ScrollWrapper = styled.div`
  overflow: auto;
  flex: 1;
  position: relative;
`
