import { gql, useMutation } from '@apollo/client'

import { ParticipantFragmentFull } from '@/modules/ParticipantsList/ParticipantsList.fragments'

import {
  SalesParticipantMoveToSalesMutation,
  SalesParticipantMoveToSalesMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantMoveToSalesMutation,
  SalesParticipantMoveToSalesMutationVariables,
}

export const SALES_PARTICIPANT_MOVE_TO_SALES = gql`
  ${ParticipantFragmentFull}

  mutation SalesParticipantMoveToSales(
    $input: SalesParticipantMoveToSalesInput!
  ) {
    salesParticipantMoveToSales(input: $input) {
      participant {
        ...SalesParticipantFull
      }
    }
  }
`

export function useSalesParticipantMoveToSales() {
  return useMutation<
    SalesParticipantMoveToSalesMutation,
    SalesParticipantMoveToSalesMutationVariables
  >(SALES_PARTICIPANT_MOVE_TO_SALES)
}

export default useSalesParticipantMoveToSales
