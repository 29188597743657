import { gql } from '@apollo/client'

import invoiceInfo from './invoiceInfo'
import payment from './payment'

export default gql`
  ${invoiceInfo}
  ${payment}

  fragment InvoiceWithPayments on Invoice {
    ...InvoiceInfo

    payments(states: [FAILED, IN_PROGRESS, PAID, PAID_PENDING]) {
      ...Payment
    }
  }
`
