import React, {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react'

interface RenderProps {
  ref: RefObject<HTMLElement>
}

interface Props {
  onOutsideClick: (event: MouseEvent) => unknown
  render: (props: RenderProps) => ReactElement
}

const OutsideClickHelper: React.FC<Props> = ({
  onOutsideClick,
  render,
}): ReactElement => {
  const ref = useRef<HTMLElement | null>(null)

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const { target } = event

      if (
        ref &&
        ref.current &&
        target instanceof Node &&
        !ref.current.contains(target)
      ) {
        onOutsideClick(event)
      }
    },
    [onOutsideClick]
  )

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true)

    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [handleOutsideClick])

  return render({ ref })
}

export default OutsideClickHelper
