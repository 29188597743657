import { useMemo, useState } from 'react'
import { ReactNode } from 'react'
import moment from 'moment'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { P } from '@/components/Typography'
import { T } from '@/modules/Language'
import { ReservationManager } from '@/modules/Reservations'
import {
  OverlappingError,
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import { useResourceReservationListContext } from '../../ReservationsState'
import { OpenCalendarButton, ProgramReservationButton } from './components'
import { ReservationList } from './ReservationList'

type Props = {
  readOnly: boolean
}

export const ReservationListContainer = ({ readOnly }: Props) => {
  const { palette, spacing } = useTheme()
  const { error, isLoading, openCalendar, reservations, setReservations } =
    useResourceReservationListContext()
  const {
    data: { estimatedDates, id, reservationDates },
  } = salesHooks.useSalesDetailsContext()

  const [isProgramReservationLoading, setProgramReservationLoading] =
    useState<boolean>(false)
  const [programReservationId, setProgramReservationId] = useState<
    string | null
  >(null)

  const { createReservation } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations: setReservations,
    })

  const programReservation = useMemo(
    () => reservations.find(({ id }) => id === programReservationId),
    [reservations, programReservationId]
  )

  const addProgramReservation = () => {
    const { end, start } = getReservationDates(
      estimatedDates || reservationDates
    )

    setProgramReservationLoading(true)
    createReservation({
      allowOverbooking: false,
      end: end,
      salesId: id,
      start: start,
    })
      .then((data: void | Reservation | OverlappingError | null) => {
        const reservation = data as Reservation

        if (reservation?.id) {
          setProgramReservationId(reservation.id)
        }
      })
      .finally(() => setProgramReservationLoading(false))
  }

  return (
    <>
      {!reservations.length && isLoading && (
        <ReactLoading
          color={palette.smoke.main}
          height={24}
          type="spin"
          width={24}
        />
      )}

      {!reservations.length && !isLoading && !error && (
        <FlexRow
          justifyContent="space-between"
          style={{ marginRight: `${spacing.gu(1)}rem` }}
        >
          <Placeholder>
            <T>ResourceReservations:state.empty</T>
          </Placeholder>

          <FlexRow>
            <ProgramReservationButton
              isLoading={isProgramReservationLoading}
              onClick={addProgramReservation}
              readOnly={readOnly}
            />

            <OpenCalendarButton
              disabled={readOnly}
              onClick={() => openCalendar({ target: 'MAIN' })}
            />
          </FlexRow>
        </FlexRow>
      )}

      {error && (
        <FlexRow
          justifyContent="space-between"
          style={{ marginRight: `${spacing.gu(1)}rem` }}
        >
          <Placeholder>
            <T>ResourceReservations:state.error</T>
          </Placeholder>

          <OpenCalendarButton
            disabled={readOnly}
            onClick={() => openCalendar({ target: 'MAIN' })}
          />
        </FlexRow>
      )}

      <ReservationList
        addProgramReservation={addProgramReservation}
        isProgramReservationLoading={isProgramReservationLoading}
        openCalendar={openCalendar}
        reservations={reservations}
        readOnly={readOnly}
        setReservations={setReservations}
      />

      {programReservation && (
        <ModalContainer
          isOpen={!!programReservationId}
          modal={
            <ReservationManager
              context="SALES"
              group={programReservation.group}
              onClose={() => setProgramReservationId(null)}
              reservation={programReservation}
              readOnly={readOnly}
              updateReservations={setReservations}
            />
          }
          onClose={() => setProgramReservationId(null)}
          placement="bottom"
          referenceElement={() => null}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />
      )}
    </>
  )
}

////////////

type Dates = {
  end: string
  start: string
}

const getReservationDates = (dates: Dates | null) => {
  if (dates) {
    return {
      end: `${dates.start}T09:00:00`,
      start: `${dates.start}T08:00:00`,
    }
  }

  return {
    end: `${moment().format('YYYY-MM-DD')}T09:00:00`,
    start: `${moment().format('YYYY-MM-DD')}T08:00:00`,
  }
}

type PlaceholderProps = {
  children: ReactNode
}

const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </Gutter>
)

const PlaceholderLabel = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`
