import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { PaymentTypeOption } from '@/modules/Registry/PaymentTypeOption'
import { useTheme } from '@/theme'

import { PaymentType as PaymentMethod } from '~generated-types'

import { PaymentType } from '../../../types'
import {
  Button,
  Dropdown,
  DropdownArrowButton,
  DropdownButton,
  DropdownWrapper,
  Icon,
  Label,
  LabelTruncate,
  Modal,
  OptionButton,
} from '../common'

type DynamicType = PaymentTypeOption['dynamicType']
type VoucherProvider = Extract<DynamicType, { __typename: 'VoucherProvider' }>

type Props = {
  loading: boolean
  paymentType: PaymentType | null
  readOnly: boolean
  setPaymentType: (paymentType: PaymentType | null) => void
  validations: PaymentTypeOption[]
}

export const VoucherPaymentButton = ({
  loading,
  paymentType,
  readOnly,
  setPaymentType,
  validations,
}: Props) => {
  const { palette, spacing } = useTheme()

  const isPaymentTypeSelected = paymentType?.method === PaymentMethod.Voucher

  const voucherProviders = validations.map((validation) => ({
    ...validation,
    dynamicType: validation.dynamicType as VoucherProvider,
  }))

  if (!loading && !voucherProviders?.length) {
    return (
      <Tooltip
        content={<T>Orders:Payments.tooltip.voucherProviders</T>}
        maxWidth={`${spacing.gu(25)}rem`}
        trigger={(triggerProps) => (
          <Button disabled isSelected={isPaymentTypeSelected} {...triggerProps}>
            <Icon fixedWidth icon="money-check-dollar" />
            <Label>
              <T>Orders:Payments.type.VOUCHER</T>
            </Label>
            <span style={{ flex: 1 }} />
            <Icon icon={['far', 'circle-question']} />
          </Button>
        )}
      />
    )
  }

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedProvider, setSelectedProvider] =
    useState<VoucherProvider | null>(null)

  const handleSetPaymentType = () =>
    !isPaymentTypeSelected
      ? selectedProvider
        ? setPaymentType({
            method: PaymentMethod.Voucher,
            providerId: selectedProvider.id,
          })
        : setModalOpen(true)
      : setPaymentType(null)

  const handleSelectProvider = (provider: VoucherProvider) => {
    setSelectedProvider(provider)
    setPaymentType({ method: PaymentMethod.Voucher, providerId: provider.id })
    setModalOpen(false)
  }

  return (
    <DropdownWrapper>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <Modal>
            <FlexColumn noPadding>
              {voucherProviders.map(({ dynamicType, enabled, reason }) =>
                enabled ? (
                  <OptionButton
                    isSelected={selectedProvider?.id === dynamicType.id}
                    key={`voucher-provider-${dynamicType.id}`}
                    onClick={() => handleSelectProvider(dynamicType)}
                  >
                    {dynamicType.name}
                  </OptionButton>
                ) : (
                  <Tooltip
                    content={<T>{`IssueCodes:${reason?.code}`}</T>}
                    maxWidth={`${spacing.gu(25)}rem`}
                    placement="right"
                    trigger={(triggerProps) => (
                      <OptionButton
                        {...triggerProps}
                        disabled
                        isSelected={selectedProvider?.id === dynamicType.id}
                        key={`voucher-provider-${dynamicType.id}`}
                      >
                        <span>{dynamicType.name}</span>
                        <span style={{ flex: 1 }} />
                        <FontAwesomeIcon icon={['far', 'circle-question']} />
                      </OptionButton>
                    )}
                  />
                )
              )}
            </FlexColumn>
          </Modal>
        }
        onClose={() => setModalOpen(false)}
        placement="top"
        referenceElement={({ ref }) => (
          <Dropdown ref={ref} isSelected={isPaymentTypeSelected}>
            <DropdownButton
              disabled={readOnly}
              isSelected={isPaymentTypeSelected}
              onClick={handleSetPaymentType}
            >
              <Icon fixedWidth icon="money-check-dollar" />
              <FlexColumn noPadding alignItems="flex-start">
                <Label>
                  <T>Orders:Payments.type.VOUCHER</T>
                </Label>

                {selectedProvider && (
                  <LabelTruncate title={selectedProvider.name}>
                    {selectedProvider.name}
                  </LabelTruncate>
                )}
              </FlexColumn>
            </DropdownButton>

            <DropdownArrowButton
              disabled={readOnly}
              isSelected={isPaymentTypeSelected}
              onClick={() => setModalOpen(true)}
            >
              {loading ? (
                <ReactLoading
                  color={palette.smoke.dark}
                  height={18}
                  type="spin"
                  width={18}
                />
              ) : (
                <FontAwesomeIcon icon="chevron-down" />
              )}
            </DropdownArrowButton>
          </Dropdown>
        )}
      />
    </DropdownWrapper>
  )
}
