import { gql, useMutation } from '@apollo/client'

import {
  CreateEnrollmentMutation,
  CreateEnrollmentMutationVariables,
} from '~generated-types'

import eventEnrollment from '../fragments/eventEnrollment'

const MUTATION = gql`
  ${eventEnrollment}

  mutation CreateEnrollment(
    $input: SalesCreateEnrollmentInput!
    $enrollmentsInput: SalesEnrollmentsInput
  ) {
    salesCreateEnrollment(input: $input) {
      ...EventEnrollment
      event {
        enrollments(input: $enrollmentsInput) {
          totalPages
          totalElements
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

export const useCreateEnrollmentMutation = () =>
  useMutation<CreateEnrollmentMutation, CreateEnrollmentMutationVariables>(
    MUTATION
  )
