import { CSSProperties } from 'react'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'

export enum PrintMode {
  Participants = 'PARTICIPANTS',
  Rooms = 'ROOMS',
  ParticipantGroup = 'PARTICIPANT_GROUP',
}

type Props = {
  printMode: PrintMode
  setPrintMode: (mode: PrintMode) => void
  groupsLabel: string | null
}

export const PrintSettings = ({
  printMode,
  setPrintMode,
  groupsLabel,
}: Props) => (
  <Wrapper alignItems="flex-start">
    <Label>
      <T>ParticipantsList:PrintableList.printMode.title</T>
    </Label>
    <ThemedSelect
      extraStyles={{
        container: (styles: CSSProperties) => ({
          ...styles,
          width: '100%',
        }),
      }}
      isCompact
      name="print-mode-selector"
      placeholder=""
      onChange={(selected?: Option | null) =>
        setPrintMode(selected?.value as PrintMode)
      }
      options={Object.values(PrintMode).map((printMode) =>
        getPrintModeOption(printMode, groupsLabel)
      )}
      value={getPrintModeOption(printMode, groupsLabel)}
    />
  </Wrapper>
)

////////////

const getPrintModeOption = (mode: PrintMode, groupsLabel: string | null) => ({
  label:
    mode === PrintMode.ParticipantGroup && groupsLabel ? (
      groupsLabel
    ) : (
      <T>{`ParticipantsList:PrintableList.printMode.${mode}`}</T>
    ),
  value: mode,
})

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(27)}rem;
    margin-right: ${theme.spacing.gutter};
  `}
`
