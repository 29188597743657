import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { useRoomLayoutContext } from '@/modules/Reservations/components/RoomLayout'

import { Week } from '../../types'
import {
  AccommodationLevelSelector,
  BuildingSelector,
  DateRangeSelector,
  Features,
  PrintButton,
  RoomSizeSelector,
} from './components'

interface ControlRowProps {
  openPrintableVersion: (week: Week) => void
}

export const ControlRow = ({ openPrintableVersion }: ControlRowProps) => {
  const {
    onSetTargetAccommodationLevelIds,
    onSetTargetBuildingIds,
    onSetTargetCalendarDateRange,
    onSetTargetFeatureIds,
    onSetTargetRoomSize,
    targetAccommodationLevelIds,
    targetBuildingIds,
    targetCalendarDateRange,
    targetFeatureIds,
    targetRoomSize,
  } = useRoomLayoutContext()

  return (
    <Wrapper>
      <FlexRow>
        <DateRangeSelector
          targetDateRange={targetCalendarDateRange}
          setTargetDateRange={onSetTargetCalendarDateRange}
        />

        <Spacer />

        <BuildingSelector
          targetBuildingIds={targetBuildingIds}
          setTargetBuildingIds={onSetTargetBuildingIds}
        />

        <AccommodationLevelSelector
          targetLevelIds={targetAccommodationLevelIds}
          setTargetLevelIds={onSetTargetAccommodationLevelIds}
        />

        <RoomSizeSelector
          targetRoomSize={targetRoomSize}
          setTargetRoomSize={onSetTargetRoomSize}
        />

        <Features
          targetFeatureIds={targetFeatureIds}
          setTargetFeatureIds={onSetTargetFeatureIds}
        />

        <PrintButton openPrintableVersion={openPrintableVersion} />
      </FlexRow>
    </Wrapper>
  )
}

////////////

const Spacer = styled.span`
  flex: 1;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    border-bottom: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gutter};
    padding-left: ${theme.spacing.gu(3)}rem;
  `}
`
