import { ChangeEvent, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Textarea } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { useRoomLayoutContext } from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'

type Props = {
  buildingId: string
  id: string
  note?: string | null
}

export const RoomNote = ({ buildingId, id, note }: Props) => {
  const { spacing } = useTheme()

  const { onUpdateRoom } = useRoomLayoutContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [noteValue, setNoteValue] = useState<string>('')

  const noteString = note ?? ''

  useEffect(() => {
    noteString !== noteValue && setNoteValue(noteString)
  }, [note])

  const handleUpdateRoom = () =>
    noteString !== noteValue && onUpdateRoom(id, buildingId, noteValue)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
            <StyledTextarea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setNoteValue(e.target.value)
              }
              autoFocus
              value={noteValue || ''}
              onBlur={handleUpdateRoom}
              style={{ height: 200, width: 360 }}
            />
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <FlexRow ref={ref} onClick={() => setModalOpen(true)}>
          <NoteIcon icon={['far', 'clipboard']} $isEmpty={!note} />
        </FlexRow>
      )}
    />
  )
}

////////////

const NoteIcon = styled(FontAwesomeIcon)<{ $isEmpty: boolean }>`
  cursor: pointer;

  ${({ $isEmpty, theme }) => css`
    color: ${$isEmpty ? theme.palette.smoke.dark : theme.palette.warning.dark};
    margin: 0 ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ $isEmpty, theme }) => css`
      color: ${$isEmpty
        ? theme.palette.smoke.extraDark
        : theme.palette.warning.darker};
    `}
  }
`

const StyledTextarea = styled(Textarea)`
  &:not([type='checkbox']):not([type='radio']) {
    padding: 10px;
  }
`
