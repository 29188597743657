import { MouseEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { UserGroup } from '@/modules/Registry/UserGroup'
import {
  useCloseTaskMutation,
  useOpenTaskMutation,
} from '@/modules/Task/mutations'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import { MobileSalesTask } from '../../../../../types'
import {
  ChevronIcon,
  Description,
  Details,
  DetailsTitle,
  DetailsWrapper,
  Price,
  Time,
} from './common'

type Props = {
  isLast?: boolean
  data: MobileSalesTask
  reservationStart?: string
  showPrices: boolean
}

export const TaskRow = ({
  isLast,
  data,
  reservationStart,
  showPrices,
}: Props) => {
  const { palette } = useTheme()

  const {
    assignee,
    description,
    dueDate,
    dueTime,
    id,
    isOpen,
    name,
    purchaseProducts: products,
  } = data

  const [isExpanded, setExpanded] = useState<boolean>(false)

  const [handleCloseTask] = useCloseTaskMutation()
  const [handleOpenTask] = useOpenTaskMutation()

  const reservationDate = reservationStart ? moment(reservationStart) : null
  const taskDate = dueDate ? moment(dueDate) : null
  const taskTime = dueTime ? dueTime.slice(0, -3) : '–'

  const timeLabel = `${
    taskDate && reservationDate && !reservationDate.isSame(taskDate, 'day')
      ? taskDate.format('DD.MM. ')
      : ''
  }${taskTime}`

  const showAssignee = !!assignee
  const showSubject = !!description
  const showName = !!name
  const showProducts = !!products.length

  const isExpandable = showProducts || showSubject

  const handleChangeTask = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()

    return isOpen
      ? handleCloseTask({ variables: { id } })
      : handleOpenTask({ variables: { id } })
  }

  return (
    <Wrapper isLast={isLast}>
      <Time>{timeLabel}</Time>

      <FlexColumn alignItems="flex-start" noPadding style={{ width: '100%' }}>
        <FlexRow style={{ width: '100%' }}>
          <StatusIcon
            color={isOpen ? palette.smoke.extraDark : palette.success.main}
            icon={isOpen ? ['far', 'circle'] : ['far', 'circle-check']}
            onClick={handleChangeTask}
          />

          <FlexRow
            onClick={() => isExpandable && setExpanded(!isExpanded)}
            style={{ width: '100%' }}
          >
            <span style={{ width: '100%' }}>
              {showName && <span style={{ fontWeight: 500 }}>{name}</span>}

              {showAssignee && showName && (
                <span style={{ whiteSpace: 'pre-wrap' }}>{'  –  '}</span>
              )}

              {showAssignee && <span>{(assignee as UserGroup).name}</span>}
            </span>

            {isExpandable && (
              <ChevronIcon icon="chevron-up" rotation={isExpanded ? 180 : 90} />
            )}
          </FlexRow>
        </FlexRow>

        <DetailsWrapper isOpen={isExpanded}>
          <Details>
            {showSubject && (
              <>
                <DetailsTitle>
                  <T>SalesDetails:PrintSale.label.SUBJECT</T>
                </DetailsTitle>

                <Description>{description}</Description>
              </>
            )}

            {showProducts && (
              <>
                <DetailsTitle>
                  <T>SalesDetails:PrintSale.label.PRODUCT</T>
                </DetailsTitle>

                <FlexColumn>
                  {products.map(({ attributes, id, product, totalPrice }) => (
                    <FlexRow flex={1} justifyContent="space-between" key={id}>
                      <span>
                        {attributes.quantity && `${attributes.quantity} × `}

                        <span>{product.name}</span>
                      </span>

                      {showPrices && (
                        <Price>
                          {formatCurrency(totalPrice.amountVatIncluded)} €
                        </Price>
                      )}
                    </FlexRow>
                  ))}
                </FlexColumn>
              </>
            )}
          </Details>
        </DetailsWrapper>
      </FlexColumn>
    </Wrapper>
  )
}

////////////

const StatusIcon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
  margin-right: 5px;
`

const Wrapper = styled(FlexRow)<{ isLast?: boolean }>`
  ${({ isLast, theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
    padding-bottom: ${theme.spacing.gu(isLast ? 1 : 0)}rem;
  `}
`
