import {
  ElasticRoom,
  ElasticRoomReservation,
} from '@/modules/Reservations/components/RoomLayout'

import { calculateRoomReservationRows } from '../utils'
import PrintReservationBlock from './PrintReservationBlock'
import RoomRowHeader from './RoomRowHeader'

interface RoomReservationsProps {
  isoWeek: number
  isoWeekYear: number
  reservations: ElasticRoomReservation[]
  room: ElasticRoom
}

export const RoomReservations = ({
  isoWeek,
  isoWeekYear,
  reservations,
  room,
}: RoomReservationsProps) => {
  const rows = calculateRoomReservationRows({
    isoWeek,
    isoWeekYear,
    reservations,
  })

  return (
    <tbody>
      {rows.map((columns, rowIdx) => (
        <tr key={`room-reservations-row-${room.id}-${rowIdx}`}>
          <td colSpan={4}>{rowIdx === 0 && <RoomRowHeader room={room} />}</td>
          {columns.map(
            ({ colSpan, overflowEnd, overflowStart, reservation }, colIdx) => (
              <td
                className="compact"
                colSpan={colSpan}
                key={`room-reservations-row-${room.id}-${rowIdx}-column-${colIdx}`}
              >
                {reservation && (
                  <PrintReservationBlock
                    overflowEnd={overflowEnd}
                    overflowStart={overflowStart}
                    reservation={reservation}
                  />
                )}
              </td>
            )
          )}
        </tr>
      ))}
    </tbody>
  )
}

export default RoomReservations
