import moment from 'moment'

import { typeGuardFactory } from '@/utils/types'

import {
  ArrivalsParticipant,
  ArrivalsRoomReservation,
} from '../ArrivalsListing/useArrivalsForDate'
import {
  DeparturesParticipant,
  DeparturesRoomReservation,
} from '../DeparturesListing/useDeparturesForDate'

export const getCustomDate = (
  participant: ArrivalsParticipant | DeparturesParticipant,
  room: ArrivalsRoomReservation | DeparturesRoomReservation
) =>
  participant?.services
    .filter(typeGuardFactory('__typename', 'ServiceParticipantBed'))
    .find((service) => {
      const participantCheckIn = service.dates?.checkIn.date
      const participantCheckOut = service.dates?.checkOut.date
      const roomCheckIn = room.request.checkIn.date
      const roomCheckOut = room.request.checkOut.date

      return (
        !moment(participantCheckIn).isSame(roomCheckIn, 'day') ||
        !moment(participantCheckOut).isSame(roomCheckOut, 'day')
      )
    })
