import React from 'react'
import styled, { css } from 'styled-components/macro'

import { DisabledColor } from '@/components/Colors'
import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { CollapsedFilterValue } from '@/components/Reactivesearch'
import {
  CountLabel,
  TreeEndCap,
  TreePadding,
  TreeRow,
  TreeRowLabel,
} from '@/components/TreeSelector'
import { T } from '@/modules/Language'

import { ChecksSelection, ChecksTarget, CheckState } from './types'

interface Props {
  isCollapsed?: boolean
  componentId: string
  targets: ChecksTarget[]
  toggleValue: (label: string, state: CheckState) => void
  value: ChecksSelection
}

export const ChecksListFilter = ({
  componentId,
  isCollapsed,
  targets,
  toggleValue,
  value,
}: Props) => {
  if (isCollapsed) {
    return (
      <CollapsedFilterValue
        componentId={componentId}
        placeholder={<T>Reactivesearch:unfiltered</T>}
      />
    )
  }

  return (
    <Wrapper>
      {!targets.length ? (
        <DisabledColor>
          <T>Reactivesearch:error</T>
        </DisabledColor>
      ) : (
        <>
          {targets.map(({ counts, label }) => (
            <React.Fragment key={`checks-target-row-${label}`}>
              <TreeHeaderRow>
                <TreeRowLabel>{label}</TreeRowLabel>
                <CountLabel>{counts.total}</CountLabel>
              </TreeHeaderRow>
              <TreeRow>
                <TreePadding mode="full" />
                <TreeEndCap />
                <CheckboxWrapper>
                  <CheckboxInput
                    checked={!!value[label]?.includes(CheckState.Open)}
                    noMargin
                    onChange={() => toggleValue(label, CheckState.Open)}
                  >
                    <TreeRowLabel>
                      <T
                        l10n={`InvoicesSearchList:ListHeader.checksOptions.${CheckState.Open}`}
                      />
                    </TreeRowLabel>
                    <CountLabel>{counts[CheckState.Open]}</CountLabel>
                  </CheckboxInput>
                </CheckboxWrapper>
              </TreeRow>
              <TreeRow>
                <TreePadding mode="half" />
                <TreeEndCap />
                <CheckboxWrapper>
                  <CheckboxInput
                    checked={!!value[label]?.includes(CheckState.Ready)}
                    noMargin
                    onChange={() => toggleValue(label, CheckState.Ready)}
                  >
                    <TreeRowLabel>
                      <T
                        l10n={`InvoicesSearchList:ListHeader.checksOptions.${CheckState.Ready}`}
                      />
                    </TreeRowLabel>
                    <CountLabel>{counts[CheckState.Ready]}</CountLabel>
                  </CheckboxInput>
                </CheckboxWrapper>
              </TreeRow>
            </React.Fragment>
          ))}
        </>
      )}
    </Wrapper>
  )
}

////////////

const CheckboxWrapper = styled(FlexRow)`
  flex: 1;

  label {
    flex: 1;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const TreeHeaderRow = styled(TreeRow)`
  font-weight: 500;
  margin-top: 2px;
  margin-right: -4px;
  padding: 3px 4px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
  `}

  border-radius: 4px;
  max-height: 500px;
  overflow: auto;
`
