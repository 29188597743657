import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'

import { Group as GroupType } from '../utils/getProgramByDateKey'
import { ReservationRow } from './ReservationRow'

type Props = {
  data: GroupType
}

export const ReservationGroupRow = ({
  data: { name, reservations },
}: Props) => (
  <>
    <Spacer />

    <tr>
      <Time />
      <Group>{name || <T>SalesDetails:PrintSale.label.GROUP</T>}</Group>
    </tr>

    {reservations
      .sort(generateCompareFn(['start', 'resource.name']))
      .map((x) => (
        <ReservationRow data={x} key={x.id} />
      ))}

    <Spacer />
  </>
)

//////

const Time = styled.td`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(22)}rem;
  `}
`

const Group = styled.td`
  font-weight: 500;
  text-transform: uppercase;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Spacer = styled.tr`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(2)}rem;
  `}
`
