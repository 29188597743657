import { gql } from '@apollo/client'

export default gql`
  fragment InvoiceInfo on Invoice {
    auditLog {
      bookkeepingTransferred
      createdAt
      invoiceAccepted
      updatedAt
    }
    config {
      packetProducts
      participantInfo
    }
    customer {
      address {
        address1
        address2
        city
        country
        postcode
      }
      businessId
      contactPerson
      customerNumber
      email
      name
      phone
      type
    }
    dueDate
    dueDateDuration
    dueDateDurationOptions
    freeText
    id
    invoiceDate
    invoiceNumber
    lifecycle {
      isChecked
      state
      validatedActions {
        action
        issues {
          code
        }
        valid
      }
    }
    paymentInfo {
      paid
      payableAmount
      status
      totalPrice {
        amount
        amountVatIncluded
        vatAmount
      }
    }
    paymentType
    referenceNumber
    refundDetails {
      action
      originalInvoice {
        id
      }
    }
    seller {
      id
      name
      productsConfig {
        advanceProducts {
          catalog {
            id
            name
          }
          id
          name
          quantityUnit
          unitPrice
          vatRate
        }
        serviceFeeProducts {
          catalog {
            id
            name
          }
          id
          name
          quantityUnit
          unitPrice
          vatRate
        }
      }
      shortName
    }
    sellerContact
    type
    valueDate
    version
  }
`
