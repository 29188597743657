import styled, { css } from 'styled-components/macro'

const ServiceItemEmptyLabel = styled.span`
  font-weight: 500;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    padding-left: ${theme.spacing.gu(0.5)}rem;
  `}
`

export default ServiceItemEmptyLabel
