import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { FlexColumn, FlexRow, Gutter } from '@/components/Layout'
import { P } from '@/components/Typography'

//////////// Truncate content on multiple lines

export const TruncateContent = ({
  content,
  linesToShow,
}: {
  content: ReactNode | string
  linesToShow?: number
}) => {
  return (
    <ContentWrapper>
      <Truncate linesToShow={linesToShow}>{content}</Truncate>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.span`
  display: grid;
  flex: auto;
`
const Truncate = styled.span<{ linesToShow?: number }>`
  display: -webkit-box;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;

  ${({ linesToShow }) => css`
    -webkit-line-clamp: ${linesToShow || 1};
  `}
`

//////////// List components

export const ListContainerRow = styled(FlexRow)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};

    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1)}rem;
  `}
`

export const ListContainerColumn = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1.5)}rem;
  `}
`

export const ListRow = styled(FlexRow)<{ noBorder?: boolean }>`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1.5)}rem;
    padding-top: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ noBorder, theme }) =>
    !noBorder &&
    css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
`

export const RowWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(4)}rem;
  `}
`

export const Section = styled(FlexColumn)<{
  noRightMargin?: boolean
  noMargin?: boolean
  noLeftMargin?: boolean
  minWidth?: string
}>`
  justify-content: center;

  ${({ theme, noMargin, noLeftMargin, noRightMargin, minWidth }) => css`
    ${noMargin
      ? `margin: 0`
      : noLeftMargin
      ? `margin: 0 ${theme.spacing.gu(2)}rem 0 0;`
      : noRightMargin
      ? `margin: 0 0 0 ${theme.spacing.gu(2)}rem ;`
      : `margin: 0 ${theme.spacing.gu(2)}rem;`};

    ${minWidth ? `${minWidth}` : ``};
  `};
`

export const VerticalDivider = styled.div<{ isTransparent?: boolean }>`
  height: 100%;
  width: 1px;

  ${({ theme, isTransparent }) => css`
    background: ${isTransparent ? 'transparent' : theme.palette.smoke.light};
  `}
`
export const HorizontalDivider = styled.div<{ isTransparent?: boolean }>`
  height: 1px;
  width: 100%;

  ${({ theme, isTransparent }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
    background: ${isTransparent ? 'transparent' : theme.palette.smoke.light};
  `}
`

export const ListPlaceholder = ({ children }: { children: ReactNode }) => (
  <Gutter type={[2, 3]}>
    <Label>{children}</Label>
  </Gutter>
)

const Label = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

export const ContentPlaceholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
