import { gql } from '@apollo/client'

export default gql`
  mutation UpdateOrganizationCustomerEInvoicingAddress(
    $input: CustomerOrganizationUpdateEInvoicingAddressInput!
  ) {
    customerOrganizationUpdateEInvoicingAddress(input: $input) {
      customer {
        eInvoicingAddress {
          address
          operator
        }
        id
      }
    }
  }
`
