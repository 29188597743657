import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

import {
  SaleAccommodationQuery,
  SalesParticipantFullFragment,
} from '~generated-types'

import { ParticipantRowWithServices } from '../../ParticipantsRowWithServices'
import { MainInfoPlaceholder } from '../../Placeholders'

type Props = {
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']
  roomParticipants: SalesParticipantFullFragment[]
  readOnly?: boolean
  placeholdersAmount?: number
}

const RoomViewContent = ({
  room,
  roomParticipants,
  readOnly,
  placeholdersAmount,
}: Props) => (
  <ContentWrapper>
    {roomParticipants.map((participant) => {
      const participantFiltered = {
        ...participant,
        services: participant.services.filter(
          (service) =>
            service.__typename === 'ServicePurchase' ||
            (service.__typename === 'ServiceParticipantBed' &&
              service.participantRoom?.roomReservation.id === room.id)
        ),
      }

      return (
        <ParticipantRowWithServices
          key={`${room.id}-${participant.id}`}
          participant={participantFiltered}
          rootRoomId={room.id}
          readOnly={readOnly}
          isEventParticipant={false}
        />
      )
    })}

    {!!placeholdersAmount &&
      new Array(placeholdersAmount)
        .fill(0)
        .map((_, index) => (
          <MainInfoPlaceholder key={index} rootRoomId={room.id} />
        ))}
  </ContentWrapper>
)

export default RoomViewContent

const ContentWrapper = styled(FlexColumn)`
  border-top: 0;
  box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 9%);

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.smoke.lighter};
  `}

  & > div:last-of-type {
    margin-bottom: -1px;
  }
`
