import moment, { Moment } from 'moment'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

interface UseRouteIsoWeekHook {
  setWeek: (week: number, year: number) => void
  week: number
  year: number
}

const useRouteIsoWeek = (): UseRouteIsoWeekHook => {
  const history = useHistory()
  const { search } = useLocation()
  const { path } = useRouteMatch()
  const { week: searchWeek, year: searchYear, ...searchRest } = parse(search)
  const MOMENT_FORMAT = 'YYYY[W}W'

  if (
    searchWeek &&
    searchYear &&
    !moment(`${searchYear}W${searchWeek}`, MOMENT_FORMAT).isValid()
  ) {
    const nextSearch = stringify({ ...searchRest }).trim()

    history.replace(
      nextSearch ? `${path}?${stringify({ ...searchRest })}` : path
    )
  }

  const refDate: Moment =
    searchWeek &&
    searchYear &&
    moment(`${searchYear}W${searchWeek}`, MOMENT_FORMAT).isValid()
      ? moment(`${searchYear}W${searchWeek}`, MOMENT_FORMAT)
      : moment()

  const handleSetWeek = (week: number, year: number) => {
    const date = moment(`${year}W${week}`, MOMENT_FORMAT)

    history.replace(
      date.isValid()
        ? `${path}?${stringify({
            ...parse(search),
            week,
            year,
          })}`
        : `${path}?${stringify({
            ...parse(search),
          })}`
    )
  }

  return {
    setWeek: handleSetWeek,
    week: refDate.isoWeek(),
    year: refDate.isoWeekYear(),
  }
}

export default useRouteIsoWeek
