import { Label } from '@/components/FormControls'
import { FormField } from '@/components/FormWrappers'
import { Option, ThemedMultiSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import {
  CustomerLabel,
  customerLabelHooks,
} from '@/modules/Registry/CustomerLabel'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

type LabelSelectorProps = {
  onChange: (newValue: string[]) => Promise<void>
  /**
   * Value = Selected labeld ids
   */
  value: string[]
}

export const LabelSelector = ({ onChange, value }: LabelSelectorProps) => {
  const { labels, loading } = customerLabelHooks.useCustomerLabels({})
  const { spacing } = useTheme()

  const labelToOption = ({ id, name }: CustomerLabel): Option => ({
    label: name,
    value: id,
  })

  const labelOptions: Option[] = labels
    .sort(generateCompareFn('name'))
    .map(labelToOption)

  return (
    <FormField>
      <Label>
        <T>Customers:labels.title</T>
      </Label>
      <ThemedMultiSelect
        labelsAmount={2}
        loading={loading}
        modalWidth={`${spacing.gu(45)}rem`}
        noOptionsPlaceholder={<T>Customers:labels.empty</T>}
        options={labelOptions}
        placeholder={<T>Customers:labels.placeholder</T>}
        selectedValues={value}
        setSelectedValues={onChange}
      />
    </FormField>
  )
}
