import { gql, useMutation } from '@apollo/client'

import type {
  CopySalesProductToSalesMutation,
  CopySalesProductToSalesMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.salesProduct}

  mutation CopySalesProductToSales($input: SalesProductCopyToSalesInput!) {
    salesProductCopyToSales(input: $input) {
      ...SalesProduct
    }
  }
`

export const useCopySalesProductToSalesMutation = () =>
  useMutation<
    CopySalesProductToSalesMutation,
    CopySalesProductToSalesMutationVariables
  >(MUTATION)
