import { gql, useMutation } from '@apollo/client'

import type {
  DeleteCatalogProductMutation,
  DeleteCatalogProductMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteCatalogProduct($id: ID!) {
    catalogProductDelete(id: $id) {
      deleted
      id
    }
  }
`

export const useDeleteCatalogProductMutation = () =>
  useMutation<
    DeleteCatalogProductMutation,
    DeleteCatalogProductMutationVariables
  >(MUTATION)
