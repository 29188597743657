import { MultiList as LibraryMultiList } from '@appbaseio/reactivesearch'
import { MultiList as MultiListProps } from '@appbaseio/reactivesearch/lib/components/list/MultiList'
import { createGlobalStyle } from 'styled-components/macro'

import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T, translate, useLanguageContext } from '@/modules/Language'

import { CollapsedFilterValue } from './CollapsedFilterValue'
import { inputCss, listCss } from './styles'
import { customLabelItemRenderer, l10nListItemRenderer } from './utils'

export const inputClassName = 'rs-multi-list-input'
export const labelClassName = 'rs-multi-list-label'
export const wrapperClassName = 'rs-multi-list-wrap'

type CustomLabels = {
  [key: string]: {
    [locale: string]: string
  }
}

interface Props extends MultiListProps {
  customLabels?: CustomLabels | null
  isCollapsed?: boolean
  l10nPrefix?: string
}

export const MultiList = ({
  customLabels,
  isCollapsed,
  l10nPrefix,
  ...props
}: Props) => {
  const { language } = useLanguageContext()

  const getCustomRenderProps = () => {
    if (customLabels) {
      return {
        renderItem: customLabelItemRenderer(customLabels, language),
      }
    }

    if (l10nPrefix) {
      return {
        renderItem: l10nListItemRenderer(l10nPrefix),
      }
    }

    return {}
  }

  const translateLabel = (key: string) => {
    if (customLabels && customLabels[key]) {
      return customLabels[key][language]
    }

    if (l10nPrefix) {
      return translate(`${l10nPrefix}.${key}`, language)
    }

    return key
  }

  if (customLabels === null) {
    return <LoadingPlaceholder size="lg" />
  }

  return (
    <>
      <MultiListStyles />

      {isCollapsed && (
        <CollapsedFilterValue
          componentId={props.componentId}
          placeholder={<T>Reactivesearch:unfiltered</T>}
          transformaValue={(value) =>
            !!value
              ? value
                  .split(',')
                  .map((x) => x.trim())
                  .map((x) => translateLabel(x))
                  .sort()
                  .join(', ')
              : ''
          }
        />
      )}

      <LibraryMultiList
        {...props}
        {...getCustomRenderProps()}
        className={wrapperClassName}
        innerClass={{ input: inputClassName }}
        loader={!isCollapsed ? <LoadingPlaceholder size="lg" /> : null}
        renderError={() =>
          !isCollapsed ? (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          ) : null
        }
        renderNoResults={() =>
          !isCollapsed ? (
            <DisabledColor>
              <T>Reactivesearch:empty</T>
            </DisabledColor>
          ) : null
        }
        style={isCollapsed ? { display: 'none' } : {}}
      />
    </>
  )
}

////////////

const MultiListStyles = createGlobalStyle`
  .${wrapperClassName} {
    ${listCss}
  }

  .${inputClassName} {
    ${inputCss}
  }
`
