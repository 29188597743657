import styled, { css } from 'styled-components/macro'

export const Placeholder = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`
