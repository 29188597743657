import styled from 'styled-components/macro'

import { ReservationsGrid } from './ReservationsGrid'
import ViewControls from './ViewControls'

export const Calendar = () => (
  <CalendarWrapper>
    <ViewControls />
    <ReservationsGrid />
  </CalendarWrapper>
)

////////////

const CalendarWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
