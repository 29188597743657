import { gql, useQuery } from '@apollo/client'

import {
  UseSalesTasksQuery as QueryData,
  UseSalesTasksQueryVariables as QueryVariables,
} from '~generated-types'

import { taskFragments } from '../fragments'
import { Task } from '../types'

const QUERY = gql`
  ${taskFragments.task}

  query UseSalesTasks($salesId: ID!) {
    tasksBySales(salesId: $salesId) {
      ...Task
    }
  }
`

type Params = {
  salesId: string
}

export function useSalesTasks({ salesId }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { salesId },
    }
  )

  const tasks: Task[] = data ? data.tasksBySales || [] : []

  return {
    error,
    loading,
    refetch,
    tasks,
  }
}

export default useSalesTasks
