import React from 'react'

import { ListItemSection } from '@/components/List'

type Props = {
  assignee: string | null | undefined
}

const AssigneeInfo = ({ assignee }: Props) => (
  <ListItemSection>{assignee}</ListItemSection>
)

export default AssigneeInfo
