import React, { useEffect, useState } from 'react'

import { DataTableInput } from '@/components/DataTable'
import { useTheme } from '@/theme'

type Props = {
  value?: number | string | null
  targetUnit: string
  handleUpdate?: (newValue: string | null | undefined) => Promise<any>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  withBorder?: boolean
  placeholder?: string
}

export const NumberInput = ({
  value,
  targetUnit,
  handleUpdate,
  onChange,
  withBorder,
  placeholder,
}: Props) => {
  const { palette } = useTheme()
  const valueStr = `${value || ''}`

  const [hasError, setHasError] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(valueStr)
  const [isHovered, setHovered] = useState(false)

  const numberInputStyles = {
    background: isHovered ? palette.smoke.light : palette.white,
    border: 0,
    borderLeft: withBorder ? `1px solid ${palette.smoke.light}` : 0,
    borderRadius: 0,
    borderRight: withBorder ? `1px solid ${palette.smoke.light}` : 0,
    height: 35,
    textAlign: 'right' as const,
    transition: '0.2s',
  }

  useEffect(() => {
    setInputValue(valueStr)
  }, [valueStr])

  const handleChange = () => {
    setIsProcessing(true)
    setHasError(false)

    handleUpdate &&
      handleUpdate(inputValue)
        .catch(() => setHasError(true))
        .finally(() => setIsProcessing(false))

    !handleUpdate && setIsProcessing(false)
  }

  return (
    <>
      <DataTableInput
        disabled={isProcessing}
        endAdornment={inputValue ? targetUnit : ''}
        hasError={hasError}
        placeholder={placeholder}
        onBlur={handleChange}
        onChange={(e) => {
          !onChange && setInputValue(e.target.value)
          onChange && onChange(e)
        }}
        onFocus={(e) => e.currentTarget.select()}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onSubmit={handleChange}
        type={'text'}
        value={onChange ? valueStr : inputValue}
        style={numberInputStyles}
      />
    </>
  )
}
