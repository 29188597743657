import { gql } from '@apollo/client'

import { taskFragments } from '@/modules/Task/fragments'

import ResourceReservationResource from './resourceReservationResource'
import SalesResourceReservationPurchase from './salesResourceReservationPurchase'

export const SalesResourceReservationFragment = gql`
  ${ResourceReservationResource}
  ${SalesResourceReservationPurchase}
  ${taskFragments.taskAssignee}

  fragment SalesResourceReservation on ResourceReservation {
    color
    customerVisibility
    description
    dimensions {
      id
      selection {
        selectedLabel {
          active
          description
          id
          name
        }
      }
    }
    end
    group {
      end
      id
      name
      number
      start
    }
    id
    internalNote
    issues {
      key
      code
      level
    }
    resource {
      ...ResourceReservationResource
    }
    purchaseProducts {
      ...SalesResourceReservationPurchase
    }
    displayMessage
    resourceQuantity
    start
    tasks {
      assignee {
        ...TaskAssignee
      }
      description
      dueDate
      dueTime
      id
      isOpen
      name
      number
      purchaseProducts {
        ...SalesResourceReservationPurchase
      }
    }
  }
`

export default SalesResourceReservationFragment
