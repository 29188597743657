import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ReservationsForDateQuery } from '~generated-types'

type Sale =
  | ReservationsForDateQuery['accommodationRoomTypeReservations']['reservations'][0]['accommodationGroup']['sales']
  | ReservationsForDateQuery['accommodationRoomReservations']['reservations'][0]['accommodationGroup']['sales']

type Props = {
  sale: Sale
}

export const SaleInfo = ({ sale }: Props) => {
  const { palette, spacing } = useTheme()

  const openSalesInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  const customer = sale.customer?.customer

  return (
    <FlexRow
      alignItems="flex-start"
      style={{
        borderRight: `1px solid ${palette.smoke.light}`,
        marginRight: `${spacing.gutterSmall}`,
      }}
    >
      <Wrapper
        onClick={(e) => {
          e.stopPropagation()
          openSalesInNewTab(`/sales/details/${sale.id}?view[0]=accommodation`)
        }}
      >
        <NameWrapper>
          <Name>{`#${sale.orderNumber} ${sale.name}`}</Name>
          <CustomerName>
            {customer ? (
              <>
                <FontAwesomeIcon
                  fixedWidth
                  icon={
                    customer.__typename === 'CustomerOrganization'
                      ? ['far', 'building']
                      : 'user'
                  }
                  size="xs"
                />{' '}
                {customer.__typename === 'CustomerOrganization'
                  ? `${customer.organization.name}`
                  : `${customer.person.firstName}, ${customer.person.lastName}`}
              </>
            ) : (
              <FontWeight light style={{ fontStyle: 'italic' }}>
                <T>SalesDetails:Customer.unset</T>
              </FontWeight>
            )}
          </CustomerName>
        </NameWrapper>
        <FontAwesomeIcon
          color={palette.text.lighter}
          fixedWidth
          icon="up-right-from-square"
          size="xs"
        />
      </Wrapper>
    </FlexRow>
  )
}

////////

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 12rem;
  max-width: 12rem;

  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}

  cursor: pointer;

  &:hover > svg,
  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
    `}
  }
`

const NameWrapper = styled(FlexColumn)`
  align-items: flex-start;
  padding: 0;
  max-width: 10rem;
  width: 100%;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Name = styled.span`
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`

const CustomerName = styled.span`
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
