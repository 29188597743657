import { ParticipantsListContainer } from './ParticipantsListContainer'
import { ParticipantsListContextProvider } from './ParticipantsListState'

type Props = {
  ownerId: string
  readOnly?: boolean
}

export const ParticipantsList = ({ ownerId, readOnly }: Props) => (
  <ParticipantsListContextProvider salesId={ownerId}>
    <ParticipantsListContainer readOnly={readOnly} />
  </ParticipantsListContextProvider>
)

export {
  ParticipantsListContextProvider,
  useParticipantsListContext,
} from './ParticipantsListState'
export {
  ParticipantAddressModal,
  ParticipantAgeModal,
  ParticipantSaleSelector,
  RoomGrouping,
} from './components'
export { default as ParticipantsListQueries } from './ParticipantsList.queries'
export { useSaleParticipants } from './hooks'
export * from './ParticipantsList.types'
