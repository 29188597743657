import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  isNested?: boolean
  info: string
}

export const Notes = ({ isNested, info }: Props) => (
  <Wrapper noPadding>
    <Section>
      <Subtitle justifyContent="flex-end" isNested={isNested}>
        <T>SalesDetails:PrintSale.label.NOTES</T>
      </Subtitle>

      <FlexRow flex={1} style={{ whiteSpace: 'pre-wrap' }}>
        {info}
      </FlexRow>
    </Section>
  </Wrapper>
)

////////////

const Section = styled(FlexRow)`
  font-size: 1rem;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) =>
    css`
      border-top: 1px dashed ${theme.palette.smoke.dark};
      padding: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(1.5)}rem 0;
    `}
`

const Subtitle = styled(FlexRow)<{ isNested?: boolean }>`
  font-weight: 500;

  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ isNested, theme }) => css`
    width: ${isNested
      ? `${theme.spacing.gu(28)}rem`
      : `${theme.spacing.gu(32)}rem`};
  `}
`
