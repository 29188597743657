import { CSSProperties, ReactNode, Ref } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineFieldButton } from '@/components/ExtraButtons'
import { useTheme } from '@/theme'

import { HoverHelper } from './HoverHelper'

type Props = {
  disabled?: boolean
  flex?: boolean
  handleOnClick?: (...args: Array<any>) => any
  hideEditIcon?: boolean
  inline?: boolean
  innerRef?: Ref<HTMLButtonElement>
  placeholder?: ReactNode
  style?: CSSProperties
  valueWrapperStyle?: CSSProperties
  value?: ReactNode | null
}

export const ToggleButtonHelper = ({
  disabled,
  flex,
  handleOnClick,
  hideEditIcon,
  inline,
  innerRef,
  placeholder,
  style,
  valueWrapperStyle,
  value,
  ...rest
}: Props) => {
  const theme = useTheme()

  return (
    <Wrapper inline={inline} {...rest}>
      <HoverHelper disabled={disabled}>
        <InlineFieldButton
          disabled={disabled}
          onClick={() => handleOnClick && handleOnClick()}
          ref={innerRef}
          style={{
            justifyContent: 'space-between',
            padding: `${theme.spacing.gu(1)}rem`,
            ...style,
          }}
        >
          <div
            style={{
              flexGrow: flex ? 1 : 0,
              margin: `0 ${
                hideEditIcon ? '0' : `${theme.spacing.gu(0.5)}rem`
              } 0 0`,
              textAlign: 'left',
              ...valueWrapperStyle,
            }}
          >
            {value || placeholder || '—'}
          </div>
          {!hideEditIcon && (
            <FontAwesomeIcon
              className="ifb-icon"
              size="sm"
              color={theme.palette.text.lighter}
              icon="pen"
            />
          )}
        </InlineFieldButton>
      </HoverHelper>
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div<{ inline?: boolean }>`
  ${({ theme }) => css`
    margin: -${theme.spacing.gu(1)}rem;
  `}

  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`
