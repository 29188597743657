import styled from 'styled-components/macro'

import { DangerColor, PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModalFooter } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  onClose: () => void
  readOnly: boolean
}

export const PurchasesFooter = ({ onClose, readOnly }: Props) => {
  const { deletePurchaseWithConfirm, targetPurchaseId } =
    useSalesProductManagerContext()

  return (
    <InlineModalFooter>
      <FlexRow flex={1}>
        {targetPurchaseId && !readOnly && (
          <InnocuousButton
            onClick={() => deletePurchaseWithConfirm(targetPurchaseId)}
          >
            <DangerColor>
              <T>Products:ProductManager.action.deleteTargetPurchase</T>
            </DangerColor>
          </InnocuousButton>
        )}

        <Spacer />

        <InnocuousButton onClick={onClose}>
          <PrimaryColor>
            <T>common:action.ready</T>
          </PrimaryColor>
        </InnocuousButton>
      </FlexRow>
    </InlineModalFooter>
  )
}

/////////

const Spacer = styled.div`
  flex: 1;
`
