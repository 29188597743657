import styled, { css } from 'styled-components/macro'

const ListTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}
`

export default ListTable
