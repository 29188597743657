import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

import type {
  CreateOfferMutation,
  CreateOfferMutationVariables,
} from '~generated-types'

const CREATE_OFFER_MUTATION = gql`
  ${orderFragments.offer}

  mutation CreateOffer($input: OrderCreateOfferInput!) {
    orderCreateOffer(input: $input) {
      offer {
        ...Offer
      }
    }
  }
`

export const useCreateOfferMutation = () =>
  useMutation<CreateOfferMutation, CreateOfferMutationVariables>(
    CREATE_OFFER_MUTATION
  )
