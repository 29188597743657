import { gql } from '@apollo/client'

export default gql`
  mutation RemoveOrganizationCustomerContact(
    $input: CustomerOrganizationRemoveContactInput!
  ) {
    customerOrganizationRemoveContact(input: $input) {
      removedContactId
    }
  }
`
