import { ReactiveComponent } from '@appbaseio/reactivesearch'

import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T } from '@/modules/Language'
import { categoryHooks } from '@/modules/Registry/Category'

import { CategorySelector, SelectedPathsBySet } from './CategorySelector'
import { getDocumentsByPath } from './utils'

type Props = {
  categoryTargetKey: string
  componentId: string
  dataField: string
  defaultQuery: () => {
    [key: string]: any
  }
  filterLabel?: string
  forcedInitialSelection?: string[]
  hideEmpty?: boolean
  isCollapsed?: boolean
  onUpdateSelection?: (arg0: SelectedPathsBySet) => void
  showFilter?: boolean
  URLParams?: boolean
}

export const CategorySelectorContainer = ({
  categoryTargetKey,
  componentId,
  dataField,
  defaultQuery,
  filterLabel,
  hideEmpty,
  forcedInitialSelection,
  isCollapsed,
  onUpdateSelection,
  showFilter = true,
  URLParams,
}: Props) => {
  const {
    categorySets,
    error: apiError,
    loading: apiLoading,
  } = categoryHooks.useCategorySetsByTarget({ targetKey: categoryTargetKey })

  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={() => ({
        ...defaultQuery(),
        aggs: {
          categories: {
            aggs: {
              path: {
                terms: {
                  field: 'categories.path',
                  // This is 25% of the default search.max_buckets value.
                  // It should be more than enough as the amount of categories
                  // is in the hundreds
                  size: 2500,
                },
              },
            },
            nested: {
              path: 'categories',
            },
          },
        },
        size: 0,
      })}
      filterLabel={filterLabel}
      render={({ aggregations, error, loading, setQuery, value }) => {
        if (apiLoading || (!aggregations && loading)) {
          return <LoadingPlaceholder size="lg" style={{ width: '100%' }} />
        }

        if (apiError || error) {
          return (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          )
        }

        const defaultSort = defaultQuery()?.sort || {}

        return (
          <CategorySelector
            categorySets={categorySets}
            componentId={componentId}
            dataField={dataField}
            defaultSort={defaultSort}
            documentCounts={getDocumentsByPath(aggregations)}
            hideEmpty={hideEmpty}
            forcedInitialSelection={forcedInitialSelection}
            isCollapsed={isCollapsed}
            onUpdateSelection={onUpdateSelection}
            setQuery={setQuery}
            value={Array.isArray(value) ? value.join(',') : value}
          />
        )
      }}
      showFilter={showFilter}
      URLParams={URLParams}
    />
  )
}
