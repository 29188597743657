import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { CustomerDetails } from '@/modules/Order/types'

import { Customer } from '../types'
import { CustomerDisplayName } from './CustomerDisplayName'
import { CustomerLabel } from './CustomerLabel'

type Props = {
  customer: Customer | CustomerDetails
}

export const CustomerBasicDetails = ({ customer }: Props) => {
  const labels =
    customer.__typename === 'ParsedCustomerDetails' ? [] : customer.labels

  return (
    <>
      {!!labels.length && (
        <CustomerLabelsWrapper alignItems="center">
          {labels.map((label) => (
            <CustomerLabel key={`customer-label-${label.id}`} {...label} />
          ))}
        </CustomerLabelsWrapper>
      )}

      <CustomerDisplayName customer={customer} />
    </>
  )
}

/////////

const CustomerLabelsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
