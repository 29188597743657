import { gql, useQuery } from '@apollo/client'

import {
  BuildingsQuery as QueryData,
  BuildingsQueryVariables as QueryVariables,
} from '~generated-types'

import buildingFragments from '../fragments'
import { Building } from '../types'

export const QUERY = gql`
  ${buildingFragments.building}

  query Buildings {
    registry {
      buildings {
        ...Building
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useBuildings(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
    }
  )

  const buildings: Building[] = data ? [...data.registry.buildings] : []

  return {
    buildings,
    error,
    loading,
    refetch,
  }
}
