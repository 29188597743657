import React from 'react'

import { InlineModalHeader, InlineModalLine } from '@/components/InlineModal'
import { useTheme } from '@/theme'

type Props = {
  title: React.ReactNode
  onClose: () => void
}

const ModalHeader = ({ title, onClose }: Props) => {
  const { spacing } = useTheme()

  return (
    <>
      <InlineModalHeader
        onClose={() => onClose()}
        title={title}
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />
    </>
  )
}

export default ModalHeader
