import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexRow } from '@/components/Layout'
import type { CatalogProductItem } from '@/modules/Products/types'

import {
  ActionsDropdown,
  NameSection,
  PriceSection,
  QuantityUnitSection,
  VatIncludedSection,
  VatSection,
} from './components'

type Props = {
  orderNumber: number
  productItem: CatalogProductItem
}

export const MainSection = ({
  orderNumber,
  productItem: {
    code,
    id,
    internalCost,
    internalPurchase,
    marginalTax,
    name,
    quantityUnit,
    unitPrice,
  },
}: Props) => (
  <Wrapper>
    <FlexRow alignItems="center" flex={1}>
      <OrderNumberChip color="primary" size="sm" variant="outlined">
        {orderNumber}
      </OrderNumberChip>

      <Divider />

      <NameSection code={code} id={id} name={name} />

      <Divider />

      <QuantityUnitSection quantityUnit={quantityUnit} />

      <Divider />

      <VatSection
        vatRate={unitPrice.vat.vatRate}
        withMarginalTax={!!marginalTax?.active}
      />

      <VatIncludedSection id={id} unitPrice={unitPrice} />

      <PriceSection id={id} price={unitPrice.price} />

      <Divider />

      <ActionsDropdown
        id={id}
        noInternalCost={internalCost === null}
        noInternalPurchase={internalPurchase === null}
        noMarginalTax={!marginalTax}
      />
    </FlexRow>
  </Wrapper>
)

/////////

const OrderNumberChip = styled(Chip)`
  && {
    justify-content: center;
    margin: 0;

    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeSmaller};
      width: ${theme.spacing.gu(3)}rem;
    `}
  }
`

const Divider = styled.div`
  height: 100%;
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
