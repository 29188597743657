import { gql, useMutation } from '@apollo/client'

import {
  SetResourceReservationResourceMutation,
  SetResourceReservationResourceMutationVariables,
} from '~generated-types'

import ResourceReservationFragment from '../fragments/resourceReservation'

export type {
  SetResourceReservationResourceMutation,
  SetResourceReservationResourceMutationVariables,
}

export const SET_RESOURCE_RESERVATION_RESOURCE_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationResource(
    $input: ResourceReservationSetResourceInput!
  ) {
    resourceReservationSetResource(input: $input) {
      ... on ReservationPayloadOk {
        reservation {
          ...ResourceReservation
        }
      }
      ... on ReservationAvailabilityError {
        message
      }
      ... on ReservationAvailabilityWarning {
        message
      }
    }
  }
`

export function useSetResourceReservationResourceMutation() {
  return useMutation<
    SetResourceReservationResourceMutation,
    SetResourceReservationResourceMutationVariables
  >(SET_RESOURCE_RESERVATION_RESOURCE_MUTATION)
}

export default useSetResourceReservationResourceMutation
