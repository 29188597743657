import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Gutter, GutterOptions } from '@/components/Layout'

type Props = {
  children: ReactNode
  gutter?: GutterOptions
  noMaxWidth?: boolean
  style?: CSSProperties
}
export const ErrorContainer = ({
  children,
  gutter,
  noMaxWidth,
  style,
}: Props) => (
  <ErrorContainerWrapper style={style} noMaxWidth={noMaxWidth}>
    <Gutter type={gutter || 'gutter'}>{children}</Gutter>
  </ErrorContainerWrapper>
)

export const ErrorContainerWrapper = styled.div<{ noMaxWidth?: boolean }>`
  display: inline-block;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}
  ${({ noMaxWidth, theme }) =>
    !noMaxWidth && `max-width: ${theme.spacing.gu(70)}rem`};

  background: #efe9e9;
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

export const ErrorTitle = styled.h4`
  ${({ theme }) => css`
    margin: 0 0 ${theme.spacing.gutterSmall};
    font-size: ${theme.typography.fontSizeBase2};
    font-weight: 600;
  `}

  &:last-child {
    margin: 0;
  }
`

export const ErrorMessage = styled.p`
  margin: 0;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
    font-size: ${theme.typography.fontSizeBase};
  `}

  font-weight: 400;
  white-space: pre;

  &:last-child {
    margin: 0;
  }
`
