import { MouseEvent, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import {
  InlineModal,
  InlineModalContent,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Theme, useTheme } from '@/theme'

import { taskHooks } from '../../hooks'
import { Task } from '../../types'
import { Footer } from './components'
import { TaskManager } from './TaskManager'
import { TaskPurchaseSearchManager } from './TaskPurchaseSearchManager'
import { Context, Section, SectionType } from './types'

type Props = {
  context: Context
  onClose: (event: MouseEvent) => void
  onUpdateTasks?: (tasks: Task[]) => void
  readOnly: boolean
  task?: Task
  taskNumber: number
}

const TaskManagerContainer = ({
  context,
  onClose,
  onUpdateTasks,
  readOnly,
  task,
  taskNumber,
}: Props) => {
  const theme = useTheme()

  const {
    error,
    loading: fetching,
    refetch: refetchTask,
    task: fetchedTask,
  } = taskHooks.useTask({ number: taskNumber })

  const [tasks, setTasks] = useState<Task[]>([])
  const [isLoading, setLoading] = useState<boolean>(context === 'GLOBAL')
  const [currentSection, setCurrentSection] = useState<Section>({
    type: 'TASK_MANAGER',
  })

  const currentTask = context === 'GLOBAL' ? tasks[0] : task
  const updateTasks = onUpdateTasks || setTasks

  useEffect(() => {
    if (fetchedTask) {
      setTasks([fetchedTask])
      setLoading(false)
    }
  }, [fetchedTask])

  const onBackNavigate = () =>
    currentSection.type !== 'TASK_MANAGER'
      ? setCurrentSection({
          type: currentSection?.previous || 'TASK_MANAGER',
        })
      : null

  const renderContent = () => {
    if (error) {
      return (
        <Placeholder alignItems="center" flex={1} justifyContent="center">
          <T>Tasks:Task.error</T>
        </Placeholder>
      )
    }

    if (fetching || isLoading) {
      return (
        <LoaderWrapper alignItems="center" flex={1} justifyContent="center">
          <ReactLoading
            type="spin"
            color={theme.palette.smoke.main}
            height={40}
            width={40}
          />
        </LoaderWrapper>
      )
    }

    if (currentTask) {
      return (
        <>
          <InlineModalContent>
            {currentSection.type === 'TASK_MANAGER' && (
              <TaskManager
                context={context}
                onUpdateTasks={updateTasks}
                readOnly={readOnly}
                refetchTask={refetchTask}
                setCurrentSection={setCurrentSection}
                task={currentTask}
              />
            )}

            {currentSection.type === 'TASK_PURCHASE_SEARCH' && (
              <TaskPurchaseSearchManager
                currentSection={currentSection}
                onUpdateTasks={updateTasks}
                taskId={currentTask.id}
                salesId={currentTask.sales.id}
                sellerId={currentTask.sales.seller?.id}
                setCurrentSection={setCurrentSection}
              />
            )}
          </InlineModalContent>

          <Footer
            currentSection={currentSection}
            onCloseModal={onClose}
            onUpdateTasks={updateTasks}
            readOnly={readOnly}
            task={currentTask}
            setCurrentSection={setCurrentSection}
          />
        </>
      )
    }
  }

  return (
    <Modal context={context}>
      <InlineModalHeader
        onClose={(e: MouseEvent) => onClose(e)}
        onBackNavigate={
          currentSection.type === 'TASK_MANAGER' ? undefined : onBackNavigate
        }
        title={getHeaderTitle(currentSection.type)}
        style={{ paddingTop: `${theme.spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      {renderContent()}
    </Modal>
  )
}

export default TaskManagerContainer

/////////

const getHeaderTitle = (sectionType: SectionType) => {
  switch (sectionType) {
    case 'TASK_MANAGER':
      return <T>ReservationModal:title.taskManager</T>
    case 'TASK_PURCHASE_SEARCH':
      return <T>ReservationModal:title.selectTaskProduct</T>

    default:
      return ''
  }
}

const getModalTopOffset = (context: Context, theme: Theme) => {
  switch (context) {
    case 'SALES':
      return `${theme.spacing.guPx(19) + 3}px`
    case 'GLOBAL':
      return `${theme.spacing.guPx(9) + 3}px`
    default:
      return 0
  }
}

const Modal = styled(InlineModal)<{ context: Context }>`
  height: ${({ context, theme }) =>
    `calc(100vh - ${getModalTopOffset(context, theme)} - 10px)`};
  max-height: 100vh;
  width: 500px;

  margin-top: ${({ context, theme }) => getModalTopOffset(context, theme)};
  margin-bottom: 10px;
  margin-right: 10px;
`

const LoaderWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(7)}rem;
  `}
`

const Placeholder = styled(FlexRow)`
  font-weight: 300;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin-bottom: ${theme.spacing.gu(7)}rem;
  `}
`
