import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

import {
  CellBase,
  ColumnHeaderBase as ColumnHeader,
  RowBase as Row,
} from './BaseComponents'
import { ScrollTableProps } from './types'

type Props = ScrollTableProps

export const LoadingRow = (props: Props) => {
  const { palette } = useTheme()

  return (
    <Row>
      <ColumnHeader {...props} />
      <PlaceholderCell {...props}>
        <ReactLoading
          type={'bubbles'}
          color={palette.smoke.dark}
          height={props.rowHeight}
          width={props.rowHeight}
        />
      </PlaceholderCell>
    </Row>
  )
}

////////////

const PlaceholderCell = styled(CellBase)<ScrollTableProps>`
  border-left: none;
  height: ${({ rowHeight }) => rowHeight};
  left: ${({ columnHeaderWidth }) => columnHeaderWidth};
  position: sticky;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutterBig};
  `}
`
