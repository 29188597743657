import { CustomerDetails } from '@/modules/Order/types'

import { Customer } from '../types'

export default function parseExtraDetails(
  customer: Customer | CustomerDetails
): string | null | undefined {
  if (customer.__typename === 'CustomerOrganization') {
    return customer.organization.businessId
  }

  if (customer.__typename === 'CustomerPerson') {
    return null
  }

  return customer.businessId
}
