import { ReactNode } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  children: ReactNode
  isLoading?: boolean
}

export const FilterContainer = ({ children, isLoading }: Props) => {
  const { palette } = useTheme()

  return (
    <FlexColumn flex={1} justifyContent="space-between" noPadding>
      {isLoading ? (
        <LoaderWrapper>
          <ReactLoading
            type={'spin'}
            height={24}
            width={24}
            color={palette.smoke.main}
          />
        </LoaderWrapper>
      ) : (
        children
      )}
    </FlexColumn>
  )
}

////////

const LoaderWrapper = styled.div`
  padding: 4px;
`
