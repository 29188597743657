import { useState } from 'react'
import Collapse from '@kunukn/react-collapse'
import styled, { css } from 'styled-components/macro'

import { InlineModalLine } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales/hooks'

import { SalesPrintConfig, SalesPrintConfigKey } from '../../../../../types'
import { Checkbox } from './Checkbox'
import { LineLeader } from './LineLeader'
import { PrintSaleSectionHeader } from './PrintSaleSectionHeader'

type Props = {
  isLast: boolean
  parentKey: SalesPrintConfigKey
  parentVisible: boolean
  section: SalesPrintConfig
}

export const PrintSaleSubsection = ({
  isLast,
  parentKey,
  parentVisible,
  section,
}: Props) => {
  const { key, sections: nestedSections, visible } = section

  const { salesPrintConfig, updateSalesPrintConfig } =
    salesHooks.useSalesDetailsContext()

  const [isOpen, setOpen] = useState<boolean>(false)

  const handleUpdateVisibility = (path: string) =>
    updateSalesPrintConfig(salesPrintConfig, path)

  return (
    <FlexColumn key={key}>
      <InlineModalLine dashed style={{ margin: 0 }} />

      <FlexRow flex={1}>
        <FlexColumn>
          <LineLeader fullHeight={!isLast} />
        </FlexColumn>

        <FlexColumn flex={1}>
          <PrintSaleSectionHeader
            isOpen={isOpen}
            parentKey={parentKey}
            parentVisible={parentVisible}
            section={section}
            setOpen={setOpen}
          />

          <CollapseWrapper isOpen={isOpen}>
            {nestedSections && (
              <Section>
                <FlexRow wrap="wrap">
                  {nestedSections.map(
                    ({ disabled, key: nestedKey, visible: nestedVisible }) => (
                      <Checkbox
                        key={nestedKey}
                        checked={nestedVisible}
                        disabled={!parentVisible || !visible || disabled}
                        label={nestedKey}
                        onChange={() =>
                          handleUpdateVisibility(
                            `${parentKey}.${key}.${nestedKey}`
                          )
                        }
                      />
                    )
                  )}
                </FlexRow>
              </Section>
            )}
          </CollapseWrapper>
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  )
}

/////

const CollapseWrapper = styled(Collapse)`
  transition: height 0.25s;
  overflow: visible;
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem ${theme.spacing.gu(1.5)}rem
      ${theme.spacing.gu(1)}rem;
  `}
`
