import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

import { CustomerPostalAddress } from '../types'
import { CustomerIconValue } from './CustomerIconValue'

type Props = {
  address: CustomerPostalAddress | null | undefined
}

export const CustomerAddressView = ({ address }: Props) => {
  const isEmpty =
    !address ||
    (!address?.address1 &&
      !address?.address2 &&
      !address?.city &&
      !address?.postcode &&
      !address?.country)

  return (
    <CustomerIconValue icon="envelope">
      <FlexColumn>
        {isEmpty ? (
          <T>Customers:address.empty</T>
        ) : (
          <>
            {address?.address1 && <Row>{address?.address1}</Row>}
            {address?.address2 && <Row>{address?.address2}</Row>}
            {(address?.postcode || address?.city) && (
              <Row>
                {address?.postcode ? `${address?.postcode || ''} ` : ''}
                {address?.city}
              </Row>
            )}
            {address?.country && <Row>{address?.country}</Row>}
          </>
        )}
      </FlexColumn>
    </CustomerIconValue>
  )
}

////////

const Row = styled.span`
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`
