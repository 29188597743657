import styled, { css } from 'styled-components/macro'

type Props = {
  highlight?: boolean
  onClick?: (...args: any[]) => void
}

const ListTR = styled.tr<Props>`
  ${({ highlight }) =>
    highlight &&
    css<any>`
      ${({ theme }) => css`
        box-shadow: inset -1px -1px 0px 1px ${theme.palette.primary.light},
          inset 1px 0px 0px 1px ${theme.palette.primary.light};
      `}

      &&& > td {
        ${({ theme }) => css`
          border-color: ${theme.palette.primary.light};
        `}
      }

      &&& > td::after {
        opacity: 1;
      }
    `}
`

export default ListTR
