import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import type { SalesEvent } from '../../types'
import { SubNavLink } from './SubNavLink'

type Props = {
  event: SalesEvent
}

export const EventBriefInfo = ({ event }: Props) => {
  const theme = useTheme()

  const eventEstimatedDates = event.estimatedDates
    ? `${moment(event.estimatedDates.start).format('DD.MM.')} – ${moment(
        event.estimatedDates.end
      ).format('DD.MM.YY')}`
    : null

  return (
    <Gutter type={[0, 2]}>
      <FlexColumn noPadding>
        <SubNavLink
          icon="up-right-from-square"
          label={<T>Sales:entity.type.EVENT</T>}
          to={`/sales/details/${event.id}`}
        />

        <div style={{ height: theme.spacing.gutter }} />

        <EventInfo>
          <Label>
            {`#${event.orderNumber} ${event.name && `– ${event.name}`}`}
          </Label>
        </EventInfo>

        <EventInfo alignItems="center">
          <FontAwesomeIcon
            fixedWidth
            size="sm"
            color={theme.palette.text.light}
            icon="user-group"
            style={{ marginRight: theme.spacing.gutter }}
          />
          <FontWeight normal>
            {`${event.estimatedParticipants || 0} `}
            <T>SalesDetails:EstimatedParticipants.unit</T>
          </FontWeight>
        </EventInfo>

        <EventInfo alignItems="center">
          <FontAwesomeIcon
            fixedWidth
            size="sm"
            color={theme.palette.text.light}
            icon={['far', 'calendar']}
            style={{ marginRight: theme.spacing.gutter }}
          />
          <FontWeight normal>
            {eventEstimatedDates || (
              <T>SalesDetails:EstimatedDates.placeholder</T>
            )}
          </FontWeight>
        </EventInfo>
      </FlexColumn>
    </Gutter>
  )
}

////////////

const EventInfo = styled(FlexRow)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
  `}
`
