import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

type Props = {
  onClick: () => void
}

export const BackToListButton = ({ onClick }: Props) => (
  <Button onClick={onClick}>
    <IconWrapper>
      <FontAwesomeIcon icon="arrow-left" size="sm" />
    </IconWrapper>

    <T>Customers:BackToListButton</T>
  </Button>
)

////////////

const Button = styled(InnocuousButton)`
  align-self: flex-start;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
  `};
`

const IconWrapper = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
