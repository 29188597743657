import styled, { css } from 'styled-components/macro'

export const Button = styled.button`
  cursor: pointer;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gu(4)}rem;
    padding: 0 ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.lighter};
      cursor: not-allowed;
    `}
  }
`
