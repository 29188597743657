import styled from 'styled-components/macro'

import { ContentWrap } from '@/components/Layout'

import { Header, RoomReservations, Sidebar } from './components'

export const DailyRoomReservations = () => (
  <ContentWrap>
    <ScrollWrapper>
      <Header />
      <RoomReservations />
    </ScrollWrapper>

    <Sidebar />
  </ContentWrap>
)

////////////

const ScrollWrapper = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex: 1;
`
