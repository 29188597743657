import React from 'react'

import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

export const ListHeader = () => (
  <Wrapper>
    <Section width="10rem" />

    <Section>
      <T>Catalog:CatalogList.field.name</T>
    </Section>

    <Section>
      <T>Catalog:CatalogList.field.companyName</T>
    </Section>
  </Wrapper>
)
