import styled, { css } from 'styled-components/macro'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div<{ width: string }>`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.text.lighter};
  `}

  width: ${({ width }) => (width ? width : 'auto')};
`

export const MealName = styled.span<{ width: string }>`
  max-width: ${({ width }) => (width ? width : 'auto')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    border-radius: ${theme.spacing.gutterSmall};
  `}
`

export const Section = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : 'auto')};
`

export const Separator = styled.div<{ showDivider?: boolean }>`
  width: ${({ showDivider }) => (showDivider ? '1px' : '0')};

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gutterBig};
  `}
`

export const StaticSeparator = styled(Separator)`
  width: 1px;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
  `}
`

export const ShortSeparator = styled(Separator)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

export const WeekDayTag = styled.span`
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
    margin-right: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.primary.dark};
    background: ${theme.palette.primary.extraLight};
  `}
`
