import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  monthLabels: string[]
  setValue: (arg0: Date) => void
  value: Date
  years: number[]
}

const YearMonthSelector: React.FC<Props> = ({
  monthLabels,
  setValue,
  value,
  years,
}): ReactElement => {
  const handleChange = (e: any) => {
    const { year, month } = e.target.form

    setValue(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <div>
        <Select name="month" onChange={handleChange} value={value.getMonth()}>
          {monthLabels.map((label, idx) => (
            <option key={label} value={idx}>
              {label}
            </option>
          ))}
        </Select>
        <Select name="year" onChange={handleChange} value={value.getFullYear()}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </div>
    </form>
  )
}

export default YearMonthSelector

////////////

const Select = styled.select`
  border-radius: 3px;
  font-weight: 500;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
    color: ${theme.palette.text.dark};
    font-size: ${theme.typography.fontSizeBase2};
    margin-right: ${theme.spacing.gutterSmall};
    padding: ${theme.spacing.gu(0.25)}rem ${theme.spacing.gu(0.5)}rem;
  `}
`
