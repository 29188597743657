import { gql } from '@apollo/client'

export default gql`
  fragment ProductMeal on ProductMeal {
    boundary
    duration
    id
    location {
      id
      name
    }
    meal {
      defaultDuration
      defaultRestaurant {
        id
        name
      }
      defaultStartTime
      id
      name
    }
    startTime
  }
`
