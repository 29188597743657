import { Ref } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { DatePickerControl } from '@/components/TimeControls'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'

import { EditButton, Subsection } from './components'

type Props = {
  date: string | null
  readOnly: boolean
  type: 'INVOICE_DATE' | 'VALUE_DATE'
  setDate: (invoiceDate: string | null) => Promise<void | null | undefined>
}

export const DateSelector = ({ date, readOnly, type, setDate }: Props) => (
  <Subsection isEditable title={<T>{`Orders:DateManager.title.${type}`}</T>}>
    <DatePickerControl
      clearable
      placement="bottom-start"
      render={({ date, ref, toggleModal }) => (
        <EditButton
          disabled={readOnly}
          onClick={toggleModal}
          innerRef={ref as Ref<HTMLButtonElement>}
        >
          {date ? (
            date.format('dd, D.M.YYYY')
          ) : (
            <FlexRow flex={1} style={{ justifyContent: 'flex-start' }}>
              <T>Orders:DateManager.automatic</T>
              <FontColor lighter>
                <Dash>–</Dash>
                {moment().format('dd, D.M.YYYY')}
              </FontColor>
            </FlexRow>
          )}
        </EditButton>
      )}
      setValue={(date) => setDate(date ? date.format('YYYY-MM-DD') : null)}
      value={date ? moment(date) : null}
    />
  </Subsection>
)

/////

const Dash = styled.span`
  ${({ theme }) => `
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
