import { FlexColumn } from '@/components/Layout'

import { ReservationDataRow } from './ReservationDataRow'
import { RoomTypeLabel } from './RoomTypeLabel'
import { Title } from './Title'

type Props = {
  isNested?: boolean
  roomNumber: string
  roomTypeName: string
}

export const RoomHeader = ({ isNested, roomNumber, roomTypeName }: Props) => (
  <ReservationDataRow isNested={isNested}>
    <FlexColumn>
      <Title>#{roomNumber}</Title>
      <RoomTypeLabel>{roomTypeName}</RoomTypeLabel>
    </FlexColumn>
  </ReservationDataRow>
)
