import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import {
  Option,
  ThemedMultiSelect,
  ThemedSelect,
} from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  AllergyBy,
  EnrollmentDisplay,
  ListType,
} from '../usePacketMealListControls'
import { ListingRestaurant } from '../usePacketMealsListing'

type Props = {
  allergyBy: AllergyBy
  enrollmentDisplay: EnrollmentDisplay
  listType: ListType
  loading: boolean
  setAllergyBy: (arg: AllergyBy) => void
  setRestaurantKey: (arg: string[]) => void
  setListType: (arg: ListType) => void
  setEnrollmentDisplay: (arg: EnrollmentDisplay) => void
  restaurants: Record<string, ListingRestaurant>
}

export default function PacketMealListControls({
  allergyBy,
  enrollmentDisplay,
  listType,
  restaurants,
  loading,
  setAllergyBy,
  setEnrollmentDisplay,
  setListType,
  setRestaurantKey,
}: Props) {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const [restaurantValues, setRestaurantValues] = useState<string[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<Option[]>([])

  useMemo(() => {
    const options: Option[] = Object.values(restaurants)
      .flat()
      .filter((r) => r.id !== 'missing')
      .sort(generateCompareFn('name'))
      .map((r) => ({ label: r.name, value: r.id }))

    if (!loading) {
      setRestaurantOptions(options)
    }
  }, [restaurants, loading])

  const parsedRestaurantsKeys = restaurantOptions.map((itm) => itm.value)

  useEffect(() => {
    if (
      parsedRestaurantsKeys.length &&
      restaurantValues.length &&
      restaurantValues.find((key) => !parsedRestaurantsKeys.includes(key))
    ) {
      setRestaurantValues(
        restaurantValues.filter((key) => parsedRestaurantsKeys.includes(key))
      )
    }
    if (
      restaurantValues.length === 0 ||
      restaurantValues.length === parsedRestaurantsKeys.length
    ) {
      setRestaurantKey(parsedRestaurantsKeys)
    } else {
      setRestaurantKey(restaurantValues)
    }
  }, [restaurantValues, JSON.stringify(parsedRestaurantsKeys)])

  const getOption = (
    key: AllergyBy | ListType | EnrollmentDisplay,
    translationKey: string
  ) => ({
    label: translate(
      `Listings:PacketMealListing.${translationKey}.${key}`,
      language
    ),
    value: key,
  })

  return (
    <ControlsWrapper>
      <SelectWrapper style={{ width: `${spacing.gu(25)}rem` }}>
        <SelectLabel>
          <T>Listings:PacketMealListing.allergyBy.label</T>
        </SelectLabel>

        <ThemedSelect
          isCompact
          name="language-selector"
          placeholder=""
          onChange={(option?: Option | null) =>
            option && setAllergyBy(option.value as AllergyBy)
          }
          options={[
            getOption('MEAL', 'allergyBy'),
            getOption('SALE', 'allergyBy'),
          ]}
          value={getOption(allergyBy, 'allergyBy')}
        />
      </SelectWrapper>

      <SelectWrapper style={{ width: `${spacing.gu(26)}rem` }}>
        <SelectLabel>
          <T>Listings:PacketMealListing.restaurants.label</T>
        </SelectLabel>

        <ThemedMultiSelect
          isCompact
          loading={loading}
          noOptionsPlaceholder={
            <T>Listings:PacketMealListing.restaurants.notAvailable</T>
          }
          modalWidth={`${spacing.gu(26)}rem`}
          options={restaurantOptions}
          placeholder={<T>Listings:PacketMealListing.restaurants.all</T>}
          selectedValues={parsedRestaurantsKeys.length ? restaurantValues : []}
          setSelectedValues={setRestaurantValues}
          withSelectAll
        />
      </SelectWrapper>

      <SelectWrapper style={{ width: `${spacing.gu(28)}rem` }}>
        <SelectLabel>
          <T>Listings:PacketMealListing.listType.label</T>
        </SelectLabel>

        <ThemedSelect
          isCompact
          name="list-type-selector"
          placeholder=""
          onChange={(option?: Option | null) =>
            option && setListType(option.value as ListType)
          }
          options={[
            getOption('ALL', 'listType'),
            getOption('MEAL', 'listType'),
            getOption('ALLERGY', 'listType'),
            getOption('PLANNING', 'listType'),
          ]}
          value={getOption(listType, 'listType')}
        />
      </SelectWrapper>

      <SelectWrapper style={{ width: `${spacing.gu(18)}rem` }}>
        <SelectLabel>
          <T>Listings:PacketMealListing.enrollmentDisplay.label</T>
        </SelectLabel>

        <ThemedSelect
          isCompact
          name="enrollment-selector"
          placeholder=""
          onChange={(option?: Option | null) =>
            option && setEnrollmentDisplay(option.value as EnrollmentDisplay)
          }
          options={[
            getOption('GROUPED', 'enrollmentDisplay'),
            getOption('SEPARATE', 'enrollmentDisplay'),
          ]}
          value={getOption(enrollmentDisplay, 'enrollmentDisplay')}
        />
      </SelectWrapper>
    </ControlsWrapper>
  )
}

////////////

const ControlsWrapper = styled(FlexRow)`
  @media print {
    margin: ${({ theme }) => theme.spacing.gu(2)}rem 0
      ${({ theme }) => theme.spacing.gu(1.5)}rem 0;
  }
`

const SelectLabel = styled(Label)`
  display: block;
`

const SelectWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing.gu(2)}rem;
`
