import { gql } from '@apollo/client'

export default gql`
  query SalesStateOptions($id: ID!) {
    sales(id: $id) {
      id
      lifecycle {
        stateOptions {
          state {
            isConfirmed
            key
            names {
              locale
              content
            }
            processOrder
            disabled
            systemState
          }
          valid
          issues {
            code
            key
            level
            message
            targetPath {
              id
              type
            }
          }
        }
      }
    }
  }
`
