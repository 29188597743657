import { typeGuardFactory } from '@/utils/types'

import {
  ArrivalsEvent,
  ArrivalsParticipant,
  ArrivalsRoomReservation,
  ArrivalsSales,
} from '../ArrivalsListing/useArrivalsForDate'
import {
  DeparturesParticipant,
  DeparturesRoomReservation,
  DeparturesSales,
} from '../DeparturesListing/useDeparturesForDate'

export const getParticipantsByRoom = (
  data: ArrivalsSales | ArrivalsEvent | DeparturesSales
) =>
  data.participants
    .filter((participants) => {
      return !!participants.services.length
    })
    .reduce(
      (
        acc: Record<string, ArrivalsParticipant[] | DeparturesParticipant[]>,
        val
      ) => {
        const participantBedServices = val.services.filter(
          typeGuardFactory('__typename', 'ServiceParticipantBed')
        )
        const roomReservationIds = participantBedServices
          .map((service) => service.participantRoom?.roomReservation.id || null)
          .filter(Boolean) as string[]
        const roomReservations:
          | ArrivalsRoomReservation[]
          | DeparturesRoomReservation[] = roomReservationIds.map(
          (rId) => data.roomsById[rId]
        )

        if (roomReservations.length) {
          roomReservations.forEach((roomReservation) => {
            const services = participantBedServices.filter(
              (service) =>
                service.participantRoom?.roomReservation.id ===
                roomReservation.id
            )
            acc[roomReservation.id] = acc[roomReservation.id] || []

            if (!acc[roomReservation.id].find(({ id }) => id === val.id)) {
              acc[roomReservation.id].push({ ...val, services })
            }
          })
        } else {
          acc['unassigned'] = acc['unassigned'] || []
          acc['unassigned'].push(val)
        }

        return acc
      },
      {}
    )
