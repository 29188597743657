import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataFieldInput } from '@/components/DataField'
import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

import { EditButton, IconButton } from './common'

type Props = {
  editModeByDefault: boolean
  removeValue: () => Promise<any>
  setValue: (newValue: string) => Promise<any>
  validateValue: (value: string) => boolean
  value: string
}

export const GroupValue = ({
  editModeByDefault,
  removeValue,
  value,
  validateValue,
  setValue,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [error, setError] = useState<boolean>(false)
  const [isEditMode, setEditMode] = useState<boolean>(editModeByDefault)

  const handleSetGroupLabel = (newValue: string | null) => {
    if (!newValue) {
      return removeValue()
    } else {
      const isError = validateValue(newValue)

      setError(isError)

      return !isError
        ? setValue(newValue.trim()).then(() => setEditMode(false))
        : new Promise<void>((reject) => reject())
    }
  }

  return (
    <InlineModalIconSection icon="minus" style={{ alignItems: 'center' }}>
      <FlexRow>
        {isEditMode ? (
          <DataFieldInput
            handleSubmit={handleSetGroupLabel}
            type="text"
            style={{
              borderColor: error ? palette.danger.main : undefined,
              height: '30px',
              marginLeft: `-${spacing.gu(1)}rem`,
            }}
            value={value}
          />
        ) : (
          <EditButton onClick={() => setEditMode(true)}>{value}</EditButton>
        )}
        <IconButton
          onClick={removeValue}
          style={{ marginLeft: `${spacing.gu(1)}rem` }}
        >
          <FontAwesomeIcon color={palette.danger.main} icon="trash" size="sm" />
        </IconButton>
      </FlexRow>
    </InlineModalIconSection>
  )
}
