import { gql, useMutation } from '@apollo/client'

import type {
  UpdateCatalogProductItemRuleMutation,
  UpdateCatalogProductItemRuleMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productRule}

  mutation UpdateCatalogProductItemRule($input: ProductRuleUpdateInput!) {
    catalogProductItemUpdateRule(input: $input) {
      ...ProductRule
    }
  }
`

export const useUpdateCatalogProductItemRuleMutation = () =>
  useMutation<
    UpdateCatalogProductItemRuleMutation,
    UpdateCatalogProductItemRuleMutationVariables
  >(MUTATION)
