import { useRef, useState } from 'react'

import type { CategorySelection } from '@/modules/Reservations'

enum ChangeActionType {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE',
}

type ChangeAction =
  | {
      type: ChangeActionType.Add
      value: CategorySelection
    }
  | {
      type: ChangeActionType.Remove
      value: string
    }
  | {
      type: ChangeActionType.Update
      value: {
        id: string
        update: Record<string, any>
      }
    }

interface Props {
  /**
   * Optional list of action types that should not be throttled
   */
  immediateActions?: ChangeActionType[]
  /**
   * For how long should updates to the value be buffered
   */
  throttleDuration?: number
}

interface Hook {
  add: (value: CategorySelection) => void
  /**
   * Clear the old value and buffer and immediately set the value
   */
  init: (value: CategorySelection[]) => void
  remove: (id: string) => void
  selection: CategorySelection[]
  update: (id: string, update: Record<string, any>) => void
}

/**
 * A custom hook for handling category selector selections. The mutations
 * to the value are buffered if a throttleDuration is set.
 */
const useCategorySelections = ({
  immediateActions = [ChangeActionType.Add, ChangeActionType.Remove],
  throttleDuration = 0,
}: Props): Hook => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeBuffer, setChangeBuffer] = useState<ChangeAction[]>([])
  const [selection, setSelection] = useState<CategorySelection[]>([])

  const throttleTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  // Helpers

  const clearQueue = () => setChangeBuffer([])

  const processQueue = () => {
    setChangeBuffer((actions) => {
      setSelection((prev) => {
        let next = [...prev]

        actions.forEach((action) => {
          if (action.type === 'ADD') {
            next = [...next, action.value]
          } else if (action.type === 'REMOVE') {
            next = next.filter((x) => x.id !== action.value)
          } else if (action.type === 'UPDATE') {
            const selectionIdx = next.findIndex((x) => x.id === action.value.id)

            if (selectionIdx < 0) {
              console.warn('Invalid category selection id')
            } else {
              next[selectionIdx] = {
                ...next[selectionIdx],
                ...action.value.update,
              }
            }
          }
        })

        return next
      })

      // Clear the buffer
      return []
    })
  }

  const queueAction = (action: ChangeAction) => {
    setChangeBuffer((current) => [...current, action])

    if (immediateActions.includes(action.type)) {
      throttleTimeout.current = null
      processQueue()
    } else if (!throttleTimeout.current) {
      throttleTimeout.current = setTimeout(() => {
        throttleTimeout.current = null
        processQueue()
      }, throttleDuration)
    }
  }

  // Hook functions

  const add = (value: CategorySelection) =>
    queueAction({ type: ChangeActionType.Add, value })

  const init = (value: CategorySelection[]) => {
    clearQueue()
    setSelection(value)
  }

  const remove = (id: string) =>
    queueAction({ type: ChangeActionType.Remove, value: id })

  const update = (id: string, update: Record<string, any>) =>
    queueAction({
      type: ChangeActionType.Update,
      value: {
        id,
        update,
      },
    })

  return {
    add,
    init,
    remove,
    selection,
    update,
  }
}

export default useCategorySelections
