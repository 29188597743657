import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { InvoiceSeller } from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import { EditButton, Subsection } from './components'

type Props = {
  readOnly: boolean
  seller: InvoiceSeller | null
  sellerContact: string | null
  setSellerContact: (contact: string | null) => Promise<void | null | undefined>
}

export const Seller = ({
  readOnly,
  seller,
  sellerContact,
  setSellerContact,
}: Props) => {
  const { spacing } = useTheme()

  const { navItemSelect } = salesHooks.useSalesDetailsContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [sellerContactValue, setSellerContactValue] = useState<string>(
    sellerContact || ''
  )

  useEffect(() => {
    setSellerContactValue(sellerContact || '')
  }, [sellerContact])

  const handleSetSellerContact = () =>
    setSellerContact(sellerContactValue || null).finally(() =>
      setEditMode(false)
    )

  return seller ? (
    <>
      <Subsection title={<T>Orders:Seller.title</T>}>
        {seller.shortName}
      </Subsection>

      <Subsection isEditable title={<T>Orders:Seller.contact</T>}>
        {isEditMode ? (
          <StyledInput
            autoFocus
            onBlur={handleSetSellerContact}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                handleSetSellerContact()
              }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSellerContactValue(e.target.value)
            }
            type="text"
            value={sellerContactValue}
          />
        ) : (
          <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
            {sellerContact ?? '–'}
          </EditButton>
        )}
      </Subsection>
    </>
  ) : (
    <Subsection title={<T>Orders:Seller.title</T>}>
      <InnocuousButton
        compact
        disabled={readOnly}
        noNudge
        style={{ marginLeft: `-${spacing.gu(1)}rem` }}
        onClick={() => navItemSelect('overview')}
      >
        <PrimaryColor>
          <T>Orders:Seller.setSeller</T>
        </PrimaryColor>
      </InnocuousButton>
    </Subsection>
  )
}

///////

const StyledInput = styled(Input)`
  &&& {
    height: 30px;
  }
`
