import { CSSProperties, useState } from 'react'

import { EditButton } from '@/components/ExtraButtons'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { Spacing, useTheme } from '@/theme'

import { usePublishContext } from '../../../PublishState'
import { Section, TruncateContent } from '../../common'

type Props = {
  blockingCapacity: boolean
  targetId: string
}

export const BlockingCapacitySelector = ({
  blockingCapacity,
  targetId,
}: Props) => {
  const { spacing } = useTheme()
  const { readOnly, updateAccommodationTarget } = usePublishContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const options = [
    {
      label: <T>Publish:Rooms.reserved.blocking</T>,
      value: 'BLOCKING',
    },
    {
      label: <T>Publish:Rooms.reserved.nonBlocking</T>,
      value: 'NON_BLOCKING',
    },
  ]

  const handleUpdateAccommodationTarget = (option?: Option | null) => {
    if (option) {
      updateAccommodationTarget({
        id: targetId,
        nonBlockingCapacity: option.value === 'NON_BLOCKING',
      }).finally(() => setEditMode(false))
    }
  }

  const label = blockingCapacity ? (
    <T>Publish:Rooms.reserved.blocking</T>
  ) : (
    <T>Publish:Rooms.reserved.nonBlocking</T>
  )

  return (
    <Section flex={1}>
      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isDisabled={readOnly}
          menuIsOpen={isEditMode}
          name="blocking-capacity-selector"
          onChange={handleUpdateAccommodationTarget}
          onBlur={() => setEditMode(false)}
          options={options}
          placeholder=""
          value={{
            label,
            value: blockingCapacity ? 'BLOCKING' : 'NON_BLOCKING',
          }}
        />
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          style={{ flex: 'unset' }}
        >
          <TruncateContent content={label} />
        </EditButton>
      )}
    </Section>
  )
}

///////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
})
