import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  SalesForPrintMeal,
  SalesPrintConfigKey as Key,
} from '../../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../../utils/salesPrintConfig'
import { formatMealTime } from '../utils'

type Props = {
  data: SalesForPrintMeal
}

export const MealRow = ({ data }: Props) => {
  const { duration, meal, notes, quantities, location, start } = data

  const { salesPrintConfig: config } = salesHooks.useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(
      config,
      `${Key.Program}.${Key.Meals}.${key}`
    )

  const time = formatMealTime(start, duration)
  const quantity = quantities.reduce((acc, x) => acc + x.quantity, 0)

  const showLocation = checkVisibility(Key.Location)
  const showMeal = checkVisibility(Key.Name)
  const showQuantity = checkVisibility(Key.Quantity) && quantity > 1

  return (
    <Wrapper>
      <Time>{time}</Time>

      <Details>
        <FlexColumn alignItems="flex-start">
          <FlexRow>
            {showLocation && (
              <span style={{ fontWeight: 500 }}>{location.name}</span>
            )}

            {showLocation && (showQuantity || showMeal) && '  –  '}

            {showQuantity && quantity}

            {showQuantity && showMeal && ' × '}

            {showMeal && meal.name}
          </FlexRow>

          {checkVisibility(Key.Notes) && notes && <Notes>{notes}</Notes>}
        </FlexColumn>
      </Details>
    </Wrapper>
  )
}

////////////

const Details = styled.td`
  white-space: pre-wrap;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Notes = styled.span`
  font-size: 1rem;
`

const Time = styled.td`
  text-align: end;
  vertical-align: top;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(22)}rem;
  `}
`

const Wrapper = styled.tr`
  page-break-inside: avoid;
`
