import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { FormSectionTitle } from '@/components/FormWrappers'
import { T } from '@/modules/Language'
import { ElasticOrder } from '@/modules/Order/types'

import { ListHeader, ListItem } from './components'

type Props = {
  customerId: string
}

export const CustomerOrders = ({ customerId }: Props) => {
  const defaultQueryFn = () => ({
    query: {
      bool: {
        must: [{ term: { customerId } }],
      },
    },
  })

  return (
    <>
      <FormSectionTitle>
        <T>Customers:orders.title</T>
      </FormSectionTitle>
      <ElasticFilterSearchList
        columnCount={4}
        indexName="order.order"
        placeholders={{
          empty: <T>Customers:orders.empty</T>,
          error: <T>Customers:orders.error</T>,
        }}
        reactiveListProps={{
          componentId: 'CustomerSales',
          dataField: 'sales.estimatedDates.start',
          defaultQuery: defaultQueryFn,
          pagination: true,
          size: 5,
          sortBy: 'desc',
          URLParams: false,
        }}
        renderListItem={(item: ElasticOrder) => (
          <ListItem data={item} key={`customer-order-${item.id}`} />
        )}
        renderListHeader={() => <ListHeader />}
      />
    </>
  )
}
