import { gql, useMutation } from '@apollo/client'

import {
  AccommodationSettingsFragment,
  RoomReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

import {
  RoomReservationSetBedQuantityMutation,
  RoomReservationSetBedQuantityMutationVariables,
} from '~generated-types'

export type {
  RoomReservationSetBedQuantityMutation,
  RoomReservationSetBedQuantityMutationVariables,
}

export const ROOM_RESERVATION_SET_BED_QUANTITY = gql`
  ${RoomReservationFragment}
  ${AccommodationSettingsFragment}

  mutation RoomReservationSetBedQuantity(
    $input: AccommodationRoomReservationSetBedQuantityInput!
  ) {
    accommodationRoomReservationSetBedQuantity(input: $input) {
      ... on AccommodationRoomReservationPayloadOk {
        roomReservation {
          ...RoomReservation
        }
        accommodationGroup {
          ...AccommodationSettings
        }
      }
    }
  }
`

export function useRoomReservationSetBedQuantity() {
  return useMutation<
    RoomReservationSetBedQuantityMutation,
    RoomReservationSetBedQuantityMutationVariables
  >(ROOM_RESERVATION_SET_BED_QUANTITY)
}

export default useRoomReservationSetBedQuantity
