import { SalesProductList } from '@/modules/Products'

import { SectionContainer } from '../components'
import { SectionFeatures } from '../types'

const SECTION: SectionFeatures = {
  icon: 'cart-shopping',
  key: 'products',
}

const ProductsComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <SalesProductList />
  </SectionContainer>
)

export const Products = Object.freeze({
  component: ProductsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
