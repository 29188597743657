import { gql, useMutation } from '@apollo/client'

import {
  UpdateEventSettingsMutation,
  UpdateEventSettingsMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation UpdateEventSettings($input: SalesWebshopSettingsInput!) {
    salesSetWebshopSettings(input: $input) {
      id
      webshopSettings {
        id
        eventSettings {
          enrollEnd
          enrollStart
          visibility
          link
        }
      }
    }
  }
`

export const useUpdateEventSettings = () =>
  useMutation<
    UpdateEventSettingsMutation,
    UpdateEventSettingsMutationVariables
  >(MUTATION)
