import React, { ChangeEvent, KeyboardEvent, ReactNode, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput, Input } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

export type Option = {
  disabled: boolean
  label: ReactNode | string
  value: string
  selected?: boolean
  searchValue?: string
}

type Props = {
  noSearch?: boolean
  type?: 'select' | 'checkbox'
  disabled?: boolean
  options: Option[]
  handleChange: (value: string, isSelected?: boolean) => void
  setModalOpen: (isOpen: boolean) => void
  loading?: boolean
  error?: any
  emptyPlaceholder?: ReactNode | string
}

export const ItemsList = ({
  noSearch = false,
  type = 'select',
  options: propOptions,
  handleChange,
  setModalOpen,
  loading,
  error,
  emptyPlaceholder,
}: Props) => {
  const theme = useTheme()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [highlightedOptionIndex, setHighlightedOptionIndex] =
    useState<number>(0)
  const [useKeyNavigation, setUseKeyNavigation] = useState<boolean>(false)

  const { language } = useLanguageContext()

  const options = propOptions.filter((option) =>
    option.searchValue !== undefined
      ? option.searchValue.toLowerCase().includes(searchTerm.toLowerCase())
      : option.label !== undefined &&
        (option.label as string)
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
  )

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && highlightedOptionIndex >= 0) {
      handleChange(
        options[highlightedOptionIndex].value,
        !options[highlightedOptionIndex].selected
      )
      if (type !== 'checkbox') {
        setModalOpen(false)
        setSearchTerm('')
        setHighlightedOptionIndex(0)
      }
    } else if (e.key === 'ArrowUp') {
      setUseKeyNavigation(true)
      setHighlightedOptionIndex((current) =>
        current - 1 > 0 ? current - 1 : 0
      )
    } else if (e.key === 'ArrowDown') {
      setUseKeyNavigation(true)
      setHighlightedOptionIndex((current) => {
        return current + 1 < options.length - 1
          ? current === 0 && !useKeyNavigation
            ? current
            : current + 1
          : options.length - 1
      })
    }
  }

  return (
    <>
      {!noSearch && (
        <FlexRow
          style={{
            padding: `0 ${theme.spacing.gu(2)}rem  ${theme.spacing.gu(2)}rem`,
          }}
        >
          <Input
            autoFocus
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
            onKeyDown={onKeyDown}
            placeholder={translate(
              'SalesDetails:dimensions.action.searchShortcuts',
              language
            )}
            value={searchTerm}
          />
        </FlexRow>
      )}

      <FlexColumn
        style={{ paddingBottom: '0px', width: '100%' }}
        justifyContent={'center'}
        onMouseEnter={() => setUseKeyNavigation(false)}
      >
        {loading ? (
          <ReactLoading
            type={'bubbles'}
            height={18}
            width={18}
            color={theme.palette.smoke.main}
          />
        ) : error ? (
          <T>common:error.common</T>
        ) : options.length > 0 ? (
          options.map((option, index: number) => (
            <OptionWrapper
              disabled={option.disabled}
              key={option.value}
              onClick={() => {
                if (!option.disabled) {
                  !(type === 'checkbox') &&
                    handleChange(option.value, !option.selected)
                  type !== 'checkbox' && setModalOpen(false)
                }
              }}
              style={
                useKeyNavigation
                  ? {
                      backgroundColor: `${
                        highlightedOptionIndex === index
                          ? theme.palette.smoke.light
                          : theme.palette.white
                      }`,
                    }
                  : {}
              }
            >
              {!!(type === 'checkbox') && (
                <CheckboxInput
                  checked={option.selected}
                  noMargin
                  onChange={() => handleChange(option.value, !option.selected)}
                />
              )}
              <FontWeight style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}>
                {option.label}
              </FontWeight>
            </OptionWrapper>
          ))
        ) : (
          <FontWeight
            light
            style={{ fontStyle: 'italic', textAlign: 'center' }}
          >
            {emptyPlaceholder !== undefined ? emptyPlaceholder : ''}
          </FontWeight>
        )}
      </FlexColumn>
    </>
  )
}

const OptionWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.1s;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
    background-color: ${theme.palette.white};
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `background: ${theme.palette.smoke.lighter};
    color: ${theme.palette.text.light};
    cursor: default;`}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`
