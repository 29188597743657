import { gql } from '@apollo/client'

import ResourceReservationResourceFragment from './resourceReservationResource'

export const SalesResourceReservationForGroupFragment = gql`
  ${ResourceReservationResourceFragment}

  fragment SalesResourceReservationForGroup on ResourceReservation {
    end
    group {
      end
      id
      name
      number
      start
    }
    id
    resource {
      ...ResourceReservationResource
    }
    start
  }
`

export default SalesResourceReservationForGroupFragment
