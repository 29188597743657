import styled, { css } from 'styled-components/macro'

import { FormField, FormFieldProps } from './FormField'

export const FormFieldTuple = styled(FormField)<FormFieldProps>`
  flex-direction: row;
  padding-bottom: 0;

  ${({ theme }) => css`
    & > * {
      width: calc(50% - ${theme.spacing.gu(1)}rem);
    }
  `}

  & > *:not(:last-child) {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(2)}rem;
    `}
  }
`
