import { FC, ReactElement } from 'react'
import styled from 'styled-components/macro'

import { useTheme } from '@/theme'

import { CustomerInfoHeader } from './CustomerInfoHeader'
import { ListItemColumn } from './ListItemColumn'

export const ListHeader: FC<EmptyObject> = (): ReactElement => {
  const theme = useTheme()

  return (
    <Wrapper>
      <ListItemColumn
        noGrow
        noShrink
        style={{ paddingRight: theme.spacing.gutterSmall }}
      />
      <CustomerInfoHeader />
    </Wrapper>
  )
}

////////////

const Wrapper = styled.header`
  display: flex;
  align-items: center;
`
