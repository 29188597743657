import { ErrorInfo } from 'react'
import styled, { css } from 'styled-components/macro'

import { useErrorLogger } from './useErrorLogger'

type Props = {
  error: Error
  info: ErrorInfo
}

export const ErrorContent = ({ error, info }: Props) => {
  useErrorLogger({ error, info })

  return (
    <ErrorWrapper>
      <ErrorTitle>
        {error.name}: <ErrorDescription>{error.message}</ErrorDescription>
      </ErrorTitle>
      <Stack>{info.componentStack}</Stack>
    </ErrorWrapper>
  )
}

/////

const ErrorWrapper = styled.div`
  background: #efe9e9;
  flex-grow: 1;
  height: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
    color: ${theme.palette.danger.main};
  `}
`

const ErrorTitle = styled.h3`
  margin: 0;
  padding: 0;
`

const ErrorDescription = styled.span`
  margin: 0;
  padding: 0;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(80)}rem;
  `}

  font-weight: 500;
`

const Stack = styled.p`
  white-space: pre;
  margin: 0;
`
