import styled, { css } from 'styled-components/macro'

export const DataTableHead = styled.thead`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  font-weight: 500;

  & > tr > td,
  & > tr > th {
    border: none;
    white-space: initial;
    vertical-align: bottom;
    line-height: 1;
  }
`
