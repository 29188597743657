import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { NumberInput } from './NumberInput'

type Props = {
  rooms: number
  changeAmount?: (rooms: number) => Promise<void>
}

export const RoomsAmount = ({ rooms, changeAmount }: Props) => {
  const [roomCount, setRoomCount] = useState<number>(rooms)

  return (
    <Wrapper>
      {changeAmount ? (
        <NumberInput
          min={0}
          onBlur={() => changeAmount(roomCount)}
          onChange={(e) => setRoomCount(Number(e.target.value))}
          onFocus={(e) => e.currentTarget.select()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // @ts-ignore
              e.target.blur()
            }
          }}
          value={`${roomCount}`}
        />
      ) : (
        <span style={{ width: '2rem' }}>{rooms}</span>
      )}
    </Wrapper>
  )
}

/////////

const Wrapper = styled.div`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(6)}rem;
    border-left: 1px solid ${theme.palette.smoke.light};
    border-right: 1px solid ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gutter};
    padding: 0 ${theme.spacing.gutterSmall};
  `}
`
