import { Attachments as AttachmentList } from '@/modules/Attachments'

import { SectionContainer } from '../components'
import { SectionFeatures } from '../types'

const SECTION: SectionFeatures = {
  icon: 'file',
  key: 'attachments',
}

const AttachmentsComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <AttachmentList />
  </SectionContainer>
)

export const Attachments = Object.freeze({
  component: AttachmentsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
