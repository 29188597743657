import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { P } from '@/components/Typography'
import { useTheme } from '@/theme'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import { NumberInput } from './NumberInput'

type Props = {
  beds: number
  totalBeds: number
  extraBeds: number
  totalExtraBeds: number
  reservationId: string
}

export const Beds = ({
  beds,
  totalBeds,
  extraBeds,
  totalExtraBeds,
  reservationId,
}: Props) => {
  const theme = useTheme()

  const [stateBeds, setBeds] = useState<number>(beds)
  const [stateExtraBeds, setExtraBeds] = useState<number>(extraBeds)

  const { updateBedQuantity } = useReservationListContext()

  useEffect(() => {
    setBeds(beds)
    setExtraBeds(extraBeds)
  }, [beds, extraBeds])

  const onBlur = () => {
    updateBedQuantity({
      beds: stateBeds,
      extraBeds: stateExtraBeds,
      reservationId: reservationId,
    })
  }

  return (
    <FlexRow alignItems="center">
      <BedsWrapper>
        <NumberInput
          min={0}
          onBlur={onBlur}
          onChange={(e) => setBeds(Number(e.target.value))}
          onFocus={(e) => e.currentTarget.select()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // @ts-ignore
              e.target.blur()
            }
          }}
          value={`${stateBeds}`}
          style={{ width: `${theme.spacing.gu(8)}rem` }}
        />
        <P>{` / ${totalBeds}`}</P>
      </BedsWrapper>
      <PlusIcon>+</PlusIcon>
      <BedsWrapper>
        <NumberInput
          min={0}
          onBlur={onBlur}
          onChange={(e) => setExtraBeds(Number(e.target.value))}
          onFocus={(e) => e.currentTarget.select()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // @ts-ignore
              e.target.blur()
            }
          }}
          value={`${stateExtraBeds}`}
          style={{ width: `${theme.spacing.gu(8)}rem` }}
        />
        <P>{` / ${totalExtraBeds}`}</P>
      </BedsWrapper>
    </FlexRow>
  )
}

////////////

const PlusIcon = styled.span`
  font-weight: 300;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const BedsWrapper = styled.div`
  display: flex;
  align-items: center;
`
