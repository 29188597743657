import { ComponentType } from 'react'
import styled from 'styled-components/macro'

export default styled.div<{
  height: number
  isLast: boolean
  isLight: boolean
}>`
  position: relative;
  display: inline-flex;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: 100%;
  ${({ isLast, isLight, theme }) =>
    !isLast &&
    `
    border-bottom-color: ${
      isLight ? theme.palette.smoke.light : theme.palette.smoke.main
    };
    border-bottom-style: ${isLight ? 'dashed' : 'solid'};
    border-bottom-width: 1px;
    `}
` as ComponentType<any>
