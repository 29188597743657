import { gql, useMutation } from '@apollo/client'

import type {
  UpdateCatalogProductItemMutation,
  UpdateCatalogProductItemMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProductItem}

  mutation UpdateCatalogProductItem($input: ProductUpdateItemInput!) {
    catalogProductItemUpdate(input: $input) {
      ...CatalogProductItem
    }
  }
`

export const useUpdateCatalogProductItemMutation = () =>
  useMutation<
    UpdateCatalogProductItemMutation,
    UpdateCatalogProductItemMutationVariables
  >(MUTATION)
