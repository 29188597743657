import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'

export const Placeholder = styled.span`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

export const ParticipantWrapper = styled(FlexRow)<{ isFirst: boolean }>`
  ${({ isFirst, theme }) =>
    !isFirst &&
    css`
      border-top: 1px solid ${theme.palette.text.light};
      padding-top: ${theme.spacing.gu(1.5)}rem;
      margin-top: ${theme.spacing.gu(1.5)}rem;
    `}
`

export const Section = styled(FlexRow)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`

export const Wrapper = styled(FlexColumn)`
  font-size: 1.1rem;
  margin-top: -1px;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.text.light};
    border-top: 1px solid ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1.5)}rem 0;
    page-break-inside: avoid;
  `}
`
