import { gql, useQuery } from '@apollo/client'

import { RoomsQuery as QueryData } from '~generated-types'

import roomFragments from '../fragments'
import { Room } from '../types'

export const QUERY = gql`
  ${roomFragments.room}

  query Rooms {
    registry {
      rooms {
        ...Room
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useRooms(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, any>(QUERY, {
    fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
  })

  const rooms: Room[] = data ? [...data.registry.rooms] : []

  return {
    error,
    loading,
    refetch,
    rooms,
  }
}
