import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

import { SalesStateOptionsQuery } from '~generated-types'

type SalesLifecycle = SalesStateOptionsQuery['sales']['lifecycle']
type SalesLifecycleIssue = SalesLifecycle['stateOptions'][0]['issues'][0]

type Props = {
  children: ReactNode
  issues: SalesLifecycleIssue[]
}

export const ActionTooltip = ({ children, issues }: Props) => (
  <Tooltip
    content={
      <FlexColumn noPadding alignItems="flex-start">
        {issues.map(({ code }) => (
          <span key={`issue-code-${code}`}>
            <T>{`IssueCodes:${code}`}</T>
          </span>
        ))}
      </FlexColumn>
    }
    maxWidth={184}
    placement="left"
    trigger={(triggerProps) => (
      <FlexRow
        alignItems="center"
        justifyContent="space-between"
        {...triggerProps}
      >
        {children}
        <FontAwesomeIcon icon={['far', 'circle-question']} />
      </FlexRow>
    )}
  />
)
