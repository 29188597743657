import { useEffect, useState } from 'react'

type Props = {
  readonly amount: number
  readonly children: any
}

export const Delay = ({ amount, children }: Props) => {
  const [delayedTimeHasPassed, setDelayedTimeHasPassed] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setDelayedTimeHasPassed(true), amount)

    return () => {
      clearTimeout(timer)
    }
  }, [amount])

  return delayedTimeHasPassed ? children : null
}
