import { gql, useMutation } from '@apollo/client'

import {
  AddWebshopContactsMutation,
  AddWebshopContactsMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.saleWebshopContacts}

  mutation AddWebshopContacts($input: SalesWebshopContactInput!) {
    salesAddWebshopContacts(input: $input) {
      ...SalesWebshopContacts
    }
  }
`

export const useAddWebshopContactsMutation = () =>
  useMutation<AddWebshopContactsMutation, AddWebshopContactsMutationVariables>(
    MUTATION
  )
