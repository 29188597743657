import React from 'react'
import moment, { Moment } from 'moment'

import BasePicker, { BasePickerProps } from './components/BasePicker'

export type WeekSelection = { week: number; year: number }
export type WeekPickerProps = BasePickerProps & {
  numberOfWeeks?: number
  setValue: (target: WeekSelection | null | undefined) => void
  value: WeekSelection | null | undefined
}

const WeekPicker = ({
  numberOfWeeks,
  setValue,
  value,
  ...dayPickerProps
}: WeekPickerProps) => {
  const targetWeek = value
    ? moment().year(value.year).isoWeek(value.week)
    : null

  const currentSelection = !!value
    ? {
        from: moment(targetWeek).startOf('isoWeek').toDate(),
        to: numberOfWeeks
          ? moment(targetWeek)
              .endOf('isoWeek')
              .add(numberOfWeeks - 1, 'w')
              .toDate()
          : moment(targetWeek).endOf('isoWeek').toDate(),
      }
    : null

  const handleOnDayClick = (day: Moment | null | undefined) => {
    setValue(
      day
        ? { week: moment(day).isoWeek(), year: moment(day).isoWeekYear() }
        : null
    )
  }

  return (
    <BasePicker
      initialMonth={currentSelection?.from}
      modifiers={getModifiers(currentSelection)}
      // @ts-ignore
      onDayClick={handleOnDayClick}
      // @ts-ignore
      selectedDays={currentSelection}
      {...dayPickerProps}
    />
  )
}

export default WeekPicker

////////////

const getModifiers = (
  selection: null | { from: Date; to: Date }
): {
  [key: string]: any
} =>
  selection
    ? {
        between: { after: selection.from, before: selection.to },
        from: selection.from,
        to: selection.to,
      }
    : {}
