import { gql, useMutation } from '@apollo/client'

import type {
  DeleteSalesProductItemRuleMutation,
  DeleteSalesProductItemRuleMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteSalesProductItemRule($productRuleId: ID!) {
    salesProductItemDeleteRule(productRuleId: $productRuleId) {
      deleted
      id
    }
  }
`

export const useDeleteSalesProductItemRuleMutation = () =>
  useMutation<
    DeleteSalesProductItemRuleMutation,
    DeleteSalesProductItemRuleMutationVariables
  >(MUTATION)
