import { NavDropdownItemType, NavItem } from '@/components/MainNavbar/types'

import { NavInternalViewType, NavItemType, NavSortBy } from '~generated-types'

import { globalRoutes } from './globalRoutes'

export const defaultNavItems: NavItem[] = [
  {
    items: [
      {
        key: NavInternalViewType.SearchSales,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.SearchSales],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.SearchTask,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.SearchTask],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.SearchInvoice,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.SearchInvoice],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.SearchEnrollment,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.SearchEnrollment],
        type: NavDropdownItemType.InternalItem,
      },
    ],
    key: NavItemType.Search,
    sort: NavSortBy.Name,
  },
  {
    items: [
      {
        key: NavInternalViewType.FrontDeskSales,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.FrontDeskSales],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.FrontDeskRoom,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.FrontDeskRoom],
        type: NavDropdownItemType.InternalItem,
      },
    ],
    key: NavItemType.FrontDesk,
    sort: NavSortBy.Name,
  },
  {
    items: [
      {
        key: NavInternalViewType.BookingAccommodationReservation,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.BookingAccommodationReservation],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.BookingResourceCalendar,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.BookingResourceCalendar],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.BookingRoomLayout,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.BookingRoomLayout],
        type: NavDropdownItemType.InternalItem,
      },
    ],
    key: NavItemType.Booking,
    sort: NavSortBy.Name,
  },
  {
    items: [
      {
        key: NavInternalViewType.ListingRoomReservation,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ListingRoomReservation],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.ListingGuest,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ListingGuest],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.ListingSales,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ListingSales],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.ListingReservation,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ListingReservation],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.ListingPacketMeal,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ListingPacketMeal],
        type: NavDropdownItemType.InternalItem,
      },
    ],
    key: NavItemType.Listing,
    sort: NavSortBy.Name,
  },
  {
    items: [
      {
        key: NavInternalViewType.ReportPayment,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ReportPayment],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.ReportReservation,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.ReportReservation],
        type: NavDropdownItemType.InternalItem,
      },
    ],
    key: NavItemType.Report,
    sort: NavSortBy.Name,
  },
  {
    items: [
      {
        key: NavInternalViewType.RegistryCustomer,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.RegistryCustomer],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.RegistryBuilding,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.RegistryBuilding],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.RegistryCatalog,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.RegistryCatalog],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.RegistryProduct,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.RegistryProduct],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.RegistryRoomType,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.RegistryRoomType],
        type: NavDropdownItemType.InternalItem,
      },
      {
        key: NavInternalViewType.RegistryRoom,
        openInNewTab: false,
        to: globalRoutes[NavInternalViewType.RegistryRoom],
        type: NavDropdownItemType.InternalItem,
      },
    ],
    key: NavItemType.Registry,
    sort: NavSortBy.Name,
  },
]

export default defaultNavItems
