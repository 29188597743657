import styled, { css } from 'styled-components/macro'

import { Button as BaseButton, ButtonProps } from '@/components/Button'
import { justifyContent } from '@/components/Layout'

interface Props extends ButtonProps {
  align: justifyContent
}

const Button = styled(BaseButton).attrs(() => ({
  noNudge: true,
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || 'flex-start'};

  background: transparent;
  border: solid 1px transparent;
  border-radius: 0;
  font-weight: 500;
  font-style: italic;
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem;
  `}

  &&&:not(:disabled):hover {
    border-color: transparent;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &&&:disabled {
    background: transparent;
    border-color: transparent;
  }

  &&&:active,
  &&&:focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      border-color: ${theme.palette.primary.light};
    `}
  }
`

export default Button
