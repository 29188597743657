import { useState } from 'react'
import { lighten } from 'polished'
import Media from 'react-media'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { WillbaUser } from '@/modules/Auth'

import { UserMenuModal } from './UserMenuModal'

type Props = {
  signOut: () => void
  user: WillbaUser
}

export const UserMenuButton = ({ signOut, user }: Props) => {
  const { displayName, photoURL } = user

  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={<UserMenuModal signOut={signOut} user={user} />}
      onClose={() => setOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Wrapper ref={ref}>
          <DropdownButton isActive={isOpen} onClick={() => setOpen(true)}>
            <Media query={{ minWidth: 1250 }}>
              {(matches) => matches && <NameLabel>{displayName}</NameLabel>}
            </Media>

            <AvatarWrapper>
              <Avatar
                alt="User avatar"
                src={photoURL || '/portrait_placeholder.png'}
              />
            </AvatarWrapper>
          </DropdownButton>
        </Wrapper>
      )}
    />
  )
}

////////////

const Avatar = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`

const AvatarWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
  `}
`

const NameLabel = styled.span`
  font-weight: 600;
  user-select: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

interface DropdownButtonProps {
  isActive?: boolean
}

const DropdownButton = styled.button<DropdownButtonProps>`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  position: relative;
  border: 0;
  text-decoration: none;
  font-weight: 500;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
      background: ${theme.palette.primary.extraLight};
    `}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
        background: ${lighten(0.05, theme.palette.primary.extraLight)};
      `}
    `}
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutter};
  `}
`
