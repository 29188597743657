import { ReactElement } from 'react'

import { usePresentationState } from '../PresentationState'

type ItemPositionProps = {
  children: (renderProps: {
    position: {
      x: number
      y: number
    }
    width: number
    height: number
  }) => ReactElement
  columnIndexes: number[]
  rowIndexes: number[]
}

export const ItemPosition = ({
  children,
  columnIndexes,
  rowIndexes,
}: ItemPositionProps) => {
  const { rowHeight, columnWidth } = usePresentationState()
  const width = columnIndexes.length * columnWidth
  const height = rowIndexes.length * rowHeight
  const position = {
    x: columnIndexes[0] * columnWidth,
    y: rowIndexes[0] * rowHeight,
  }

  return children({ height, position, width })
}
