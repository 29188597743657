import { CSSProperties, ReactNode, Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { OutsideClick } from '@/components/OutsideClick'

import { ToggleButtonHelper } from './ToggleButtonHelper'

type Props = {
  disabled?: boolean
  hideEditIcon?: boolean
  inline?: boolean
  mode?: 'input' | 'modal'
  renderEditor?: (onCloseEditor: (...args: Array<any>) => any) => ReactNode
  placeholder?: ReactNode
  value: ReactNode | null | undefined
  style?: CSSProperties
  valueWrapperStyle?: CSSProperties
}

export const ToggleEditableValue = ({
  disabled,
  hideEditIcon,
  inline,
  mode,
  renderEditor,
  placeholder,
  value,
  style,
  valueWrapperStyle,
  ...rest
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const renderEditorWrapped = () =>
    renderEditor ? ( // Wrap in an <OutsideClick /> to make sure the editor is closed
      // even in the case of nested modals (→ shared shadow)
      <OutsideClick onOutsideClick={() => setIsEditing(false)}>
        {renderEditor(() => setIsEditing(false))}
      </OutsideClick>
    ) : null

  const renderValue = (ref: Ref<HTMLButtonElement> | undefined) => (
    <ToggleButtonHelper
      disabled={disabled}
      handleOnClick={() => setIsEditing(true)}
      hideEditIcon={hideEditIcon}
      inline={inline}
      innerRef={ref}
      placeholder={<Placeholder>{placeholder || '–'}</Placeholder>}
      value={value}
      style={style}
      valueWrapperStyle={valueWrapperStyle}
      {...rest}
    />
  )

  switch (mode) {
    case 'modal':
      return (
        <ModalContainer
          isOpen={isEditing}
          modal={renderEditorWrapped()}
          onClose={() => setIsEditing(false)}
          placement="bottom-end"
          referenceElement={({ ref }) =>
            renderValue(ref as Ref<HTMLButtonElement> | undefined)
          }
        />
      )
    case 'input':
      return isEditing ? renderEditorWrapped() : renderValue(null)
    default:
      return renderValue(null)
  }
}

////////////

const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
