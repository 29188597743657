import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { RadioInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  salesQueries,
  SalesStateReasonsPayload,
  SalesStateReasonsVariables,
} from '@/modules/Sales/queries'
import { getStateLabels } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'

type Props = {
  language: string
  setSeletedReason: (reason: string) => void
  specifier: string | null
}

export const CancelDescription = ({
  language,
  setSeletedReason,
  specifier,
}: Props) => {
  const { palette } = useTheme()

  const [reason, setReason] = useState<string | null>(null)

  const { data: stateReasonsData } = useQuery<
    SalesStateReasonsPayload,
    SalesStateReasonsVariables
  >(salesQueries.SALES_STATE_REASONS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const reasons = stateReasonsData?.registry.salesStateReasons ?? []
    const selectedReason = reasons.find(({ key }) => key === specifier)

    if (selectedReason) {
      setReason(selectedReason.key)
      setSeletedReason(selectedReason.key)
    }
  }, [stateReasonsData])

  return (
    <FlexColumn noPadding>
      <span>
        <T>SalesDetails:Lifecycle.description.CANCEL</T>
      </span>

      {!!stateReasonsData?.registry.salesStateReasons.length && (
        <>
          <br />

          <span>
            <T>SalesDetails:Lifecycle.common.selectReason</T>
          </span>

          {stateReasonsData?.registry.salesStateReasons.map(
            ({ key, names }) => (
              <OptionWrapper key={key} alignItems="flex-start">
                <RadioInput
                  noMargin
                  color={palette.danger.main}
                  checked={key === reason}
                  onChange={() => {
                    setReason(key)
                    setSeletedReason(key)
                  }}
                >
                  <OptionLabel>{getStateLabels(names)[language]}</OptionLabel>
                </RadioInput>
              </OptionWrapper>
            )
          )}
        </>
      )}
    </FlexColumn>
  )
}

////////////

const OptionLabel = styled.span`
  margin-top: 1px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const OptionWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`
