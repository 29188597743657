import { gql } from '@apollo/client'

export default gql`
  fragment SalesForDateSales on Sales {
    facet {
      id
      name
      abbreviation
      color
    }
    estimatedDates {
      end
      start
    }
    id
    lifecycle {
      state {
        key
        systemState
      }
    }
    name
    orderNumber
    reservationDates {
      end
      start
    }
    seller {
      id
      name
      shortName
    }
    paymentAgreement {
      id
      code
      name
    }
    type
  }
`
