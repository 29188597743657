import { gql } from '@apollo/client'

export default gql`
  mutation UpdateCustomerPerson($input: CustomerPersonUpdateInput!) {
    customerPersonUpdate(input: $input) {
      customer {
        id
        person {
          email
          firstName
          lastName
          phone
        }
      }
    }
  }
`
