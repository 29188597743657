import React, { Component, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import { Canvas } from '@/components/Canvas'
import { OutsideClick } from '@/components/OutsideClick'

const Control = styled.div<{ isInline?: boolean }>`
  display: ${({ isInline }) => (isInline ? 'inline-flex' : 'flex')};
`
const popIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const DD = styled(Canvas)<{
  bottom?: string
  left?: string
  noPadding: boolean
  right?: string
  top?: string
}>`
  position: absolute;

  ${({ bottom, left, noPadding, right, theme, top }) => css`
    padding: ${noPadding ? 0 : theme.spacing.gutter};
    margin-bottom: ${theme.spacing.gutterBig};
    ${bottom ? `bottom: ${bottom};` : ''}
    ${left ? `left: ${left};` : ''}
    ${right ? `right: ${right};` : ''}
    ${top ? `top: ${top};` : ''}
  `}

  transition: transform 0.2s ease-out;

  animation: ${popIn} 0.1s ease-in;

  z-index: 9000;
`

type State = {
  isOpen: boolean
}
export type DropdownRenderProps = State & {
  toggleDropdown: (e: React.MouseEvent<HTMLElement>) => unknown
}
export type PositioningProps = {
  bottom?: string
  left?: string
  right?: string
  top?: string
}
type Props = {
  isInline?: boolean
  // Is used to remove padding from the Dropdown
  noPadding?: boolean
  // Override the default `onOutsideClick` event. This prop could be used to
  // block a dropdown from closing and for applying a function which sets and
  // error state for the parent component.
  onOutsideClick?: (event: React.MouseEvent<HTMLElement>) => unknown
  // Is used to adjust the position of the dropdown. The positioning is done as
  // an absolute in relation to the nearest positioned ancestor.
  positioning?: PositioningProps
  // Is used to render the control for the dropdown, which is often a button.
  renderControl: (obj: DropdownRenderProps) => ReactNode
  // Is used to render the content of the dropdown.
  renderContent: (obj: DropdownRenderProps) => ReactNode
}
export class Dropdown extends Component<Props, State> {
  state: State = {
    isOpen: false,
  }

  openDD = (): unknown => this.setState({ isOpen: true })
  closeDD = (): unknown => this.setState({ isOpen: false })
  toggleDD = (): unknown => (this.state.isOpen ? this.closeDD() : this.openDD())
  outSideClick = (
    e: React.MouseEvent<HTMLElement>
  ): unknown => // Only fire this event when the dropdown is actually open to save on CPU
    // time.
    this.state.isOpen && this.props.onOutsideClick
      ? this.props.onOutsideClick(e)
      : this.closeDD()

  findPassedProps = (): DropdownRenderProps => ({
    ...this.state,
    toggleDropdown: this.toggleDD,
  })

  render() {
    const { isInline, renderControl } = this.props
    const { isOpen } = this.state

    return (
      // @ts-ignore
      <OutsideClick onOutsideClick={this.outSideClick}>
        <Control isInline={isInline}>
          {renderControl(this.findPassedProps())}
        </Control>
        {isOpen && this.content()}
      </OutsideClick>
    )
  }

  content() {
    const { noPadding = false, positioning = {}, renderContent } = this.props

    return (
      <DD {...positioning} noPadding={noPadding} zIndex={2}>
        {renderContent(this.findPassedProps())}
      </DD>
    )
  }
}
