import classNames from 'classnames'
import { Moment } from 'moment'

import { TD } from '@/modules/Listing/common'

import { TaskDue, TaskInfo, TaskPurchases } from '../Cells'
import { ListingReservationTask } from '../useReservationList'

type Props = {
  indent: number
  isLast: boolean
  reservationDate: Moment
  task: ListingReservationTask
}

export const TaskSubRow = ({
  indent,
  isLast,
  reservationDate,
  task,
}: Props) => (
  <tr
    className={classNames({
      'dashed-bottom-border': !isLast,
      subrow: indent === 1,
    })}
    key={`${task.id}`}
  >
    <TD colSpan={indent}></TD>
    <TD>
      <TaskInfo task={task} />
    </TD>
    <TD>
      <TaskDue task={task} reservationDate={reservationDate} />
    </TD>
    <TD colSpan={4}>
      <TaskPurchases task={task} />
    </TD>
    <TD colSpan={3}>{task.description}</TD>
  </tr>
)

export default TaskSubRow
