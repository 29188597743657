import ReactLoading from 'react-loading'

import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  AccommodationGroupSelector,
  DatesSelector,
} from '@/modules/Products/components'
import { SalesProduct } from '@/modules/Products/types'
import { useTheme } from '@/theme'

import { ContentSection } from '../../../../components'
import { usePublishContext } from '../../PublishState'
import {
  ListContainerColumn,
  ListPlaceholder,
  ListRow,
  RowWrapper,
  Section,
  VerticalDivider,
} from '../common'
import {
  Product,
  ProductDescription,
  PublishProductButton,
  UnpublishProductButton,
} from './components'

export const PublishedProducts = () => {
  const { palette } = useTheme()
  const {
    readOnly,
    salesProducts,
    updatePublishedProduct,
    unpublishProduct,
    salesProductsError,
    salesProductsLoading,
  } = usePublishContext()

  const { publishedProducts, unpublishedProducts } =
    groupPublishedProducts(salesProducts)

  return (
    <RowWrapper>
      <ContentSection
        title={<T>Publish:Products.title</T>}
        headerExtra={<PublishProductButton products={unpublishedProducts} />}
      >
        {salesProductsLoading ? (
          <ReactLoading
            color={palette.smoke.main}
            height={24}
            type="spin"
            width={24}
          />
        ) : salesProductsError ? (
          <ListPlaceholder>
            – <T>Products:SalesProductList.error</T> –
          </ListPlaceholder>
        ) : !publishedProducts?.length ? (
          <ListPlaceholder>
            – <T>Products:SalesProductList.noProducts</T> –
          </ListPlaceholder>
        ) : (
          <ListContainerColumn>
            <FlexRow>
              <Section noPadding flex={24} noLeftMargin>
                <Label>
                  <T>Publish:Products.product</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding style={{ width: '210px' }}>
                <Label>
                  <T>Publish:Products.accommodationGroup</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding style={{ width: '210px' }}>
                <Label>
                  <T>Publish:Products.productInterval</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding flex={25}>
                <Label>
                  <T>Publish:Products.description</T>
                </Label>
              </Section>

              <VerticalDivider />

              <Section noPadding flex={10} />
            </FlexRow>

            {publishedProducts.map((product) => (
              <ListRow key={`published-product-${product.id}`}>
                <Section noPadding flex={24} noLeftMargin>
                  <Product product={product} />
                </Section>

                <VerticalDivider isTransparent />

                {product.settings && (
                  <>
                    <Section noPadding style={{ width: '210px' }}>
                      <AccommodationGroupSelector
                        noMargin
                        id={product.id}
                        readOnly={readOnly}
                        updateProduct={updatePublishedProduct}
                        accommodationGroup={product.settings.accommodationGroup}
                      />
                    </Section>

                    <VerticalDivider isTransparent />

                    <Section noPadding style={{ width: '210px' }}>
                      <DatesSelector
                        noMargin
                        id={product.id}
                        readOnly={readOnly}
                        purchaseDates={product.settings.purchaseDates}
                        updateProduct={updatePublishedProduct}
                      />
                    </Section>

                    <VerticalDivider isTransparent />
                  </>
                )}

                <Section noPadding flex={25}>
                  <ProductDescription
                    description={product.settings?.description || null}
                    productId={product.id}
                    readOnly={readOnly}
                    updateProduct={updatePublishedProduct}
                  />
                </Section>

                <VerticalDivider isTransparent />

                <Section noPadding flex={10}>
                  <UnpublishProductButton
                    productId={product.id}
                    readOnly={readOnly}
                    unpublishProduct={unpublishProduct}
                  />
                </Section>
              </ListRow>
            ))}
          </ListContainerColumn>
        )}
      </ContentSection>
    </RowWrapper>
  )
}

////////////

type GroupedPublishedProducts = {
  publishedProducts: SalesProduct[]
  unpublishedProducts: SalesProduct[]
}

const groupPublishedProducts = (salesProducts: SalesProduct[]) => {
  const { publishedProducts, unpublishedProducts } = salesProducts.reduce(
    (acc: GroupedPublishedProducts, product) => {
      if (product.settings?.published) {
        return {
          ...acc,
          publishedProducts: [...acc.publishedProducts, product],
        }
      } else {
        return {
          ...acc,
          unpublishedProducts: [...acc.unpublishedProducts, product],
        }
      }
    },
    { publishedProducts: [], unpublishedProducts: [] }
  )
  return { publishedProducts, unpublishedProducts }
}
