import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import { SalesAssigneeFragment as SalesAssignee } from '~generated-types'

type Props = {
  assignee: SalesAssignee
  isSelected: boolean
  onClick: () => void
}

export const AssigneeOption = ({ assignee, isSelected, onClick }: Props) => (
  <Option alignItems="center" isSelected={isSelected} onClick={onClick}>
    <AvatarWrapper>
      <Avatar
        alt="assignee avatar"
        src={assignee.avatarUrl || '/portrait_placeholder.png'}
      />
    </AvatarWrapper>

    {getFullName(assignee)}

    <Spacer />

    {isSelected && <CheckIcon icon="check" />}
  </Option>
)

////////

const getFullName = ({ firstName, lastName }: SalesAssignee) =>
  `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`

const Avatar = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`

const AvatarWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  z-index: 2;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
    border: 1px solid ${theme.palette.smoke.main};
  `}
`

const CheckIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
    color: ${theme.palette.primary.main};
  `}
`

const Option = styled(FlexRow)<{ isSelected: boolean }>`
  cursor: pointer;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    min-height: ${theme.spacing.gu(5)}rem;
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ isSelected, theme }) =>
      !isSelected &&
      css`
        background: ${theme.palette.smoke.light};
      `}
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      cursor: not-allowed;

      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    `}
`

const Spacer = styled.div`
  flex: 1;
`
