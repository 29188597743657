import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ScheduledMealStatus as Status } from '~generated-types'

type Props = {
  statuses: Status[]
}

export const StatusTooltip = ({ statuses }: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const STATUSES_INFO = {
    [Status.Manual]: {
      color: palette.success.main,
      content: translate('SalesDetails:meals.status.manual', language),
      priority: 2,
    },
    [Status.ManuallyUpdated]: {
      color: palette.primary.main,
      content: translate('SalesDetails:meals.status.manuallyUpdated', language),
      priority: 3,
    },
    [Status.QuantityUpdated]: {
      color: palette.warning.main,
      content: translate('SalesDetails:meals.status.quantityUpdated', language),
      priority: 1,
    },
    [Status.ManuallyRemoved]: {
      color: palette.danger.main,
      content: translate('SalesDetails:meals.status.manuallyRemoved', language),
      priority: 5,
    },
    [Status.Automatic]: {
      color: palette.text.lighter,
      content: translate('SalesDetails:meals.status.automatic', language),
      priority: 4,
    },
  }

  const sortedStatuses = statuses
    .map((status) => STATUSES_INFO[status])
    .sort((s1, s2) => s1.priority - s2.priority)

  return (
    <Tooltip
      content={
        <FlexColumn noPadding alignItems="flex-start">
          {sortedStatuses.map(({ content, priority }) => (
            <span key={`meal-status-${priority}`}>{content}</span>
          ))}
        </FlexColumn>
      }
      placement="left"
      trigger={(triggerProps) => (
        <span {...triggerProps}>
          <FontAwesomeIcon
            color={sortedStatuses[0].color}
            icon="circle-info"
            size="sm"
          />
        </span>
      )}
    />
  )
}
