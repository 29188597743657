import { gql } from '@apollo/client'

import {
  AccommodationTargetFragment,
  RoomReservationFragment,
  RoomTypeReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

export default Object.freeze({
  ACCOMMODATION: gql`
    ${AccommodationTargetFragment}
    ${RoomReservationFragment}
    ${RoomTypeReservationFragment}

    subscription Accommodation($filter: AccommodationReservationFilterInput!) {
      accommodationReservation(filter: $filter) {
        salesId
        accommodationGroupId
        event {
          ... on AccommodationGroupEvent {
            id
            type
            accommodationGroupData: data {
              id
              name
              sortOrder
              settings {
                consumptionType
                consumptionDurationType
              }
              status {
                available
                dates {
                  checkIn {
                    date
                  }
                  checkOut {
                    date
                  }
                }
                reserved
                reservedDetailed {
                  rooms
                  beds
                  extraBeds
                }
                type
                status {
                  available
                  date
                  reserved
                  reservedDetailed {
                    rooms
                    beds
                    extraBeds
                  }
                }
              }
            }
          }
          ... on RoomEvent {
            id
            type
            roomData: data {
              ...RoomReservation
            }
          }
          ... on RoomTypeEvent {
            id
            type
            roomTypeData: data {
              ...RoomTypeReservation
            }
          }
          ... on TargetEvent {
            id
            type
            targetData: data {
              ...AccommodationTarget
            }
          }
        }
      }
    }
  `,
})
