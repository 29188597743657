import { gql, useQuery } from '@apollo/client'

import {
  UseCustomerLabelsQuery as QueryData,
  UseCustomerLabelsQueryVariables as QueryVariables,
} from '~generated-types'

import customerLabelFragment from '../fragments/customerLabel'
import type { CustomerLabel } from '../types'

export const QUERY = gql`
  ${customerLabelFragment}

  query UseCustomerLabels {
    registry {
      customerLabels {
        ...CustomerLabel
      }
    }
  }
`

export interface UseCustomerLabelsHook {
  error: boolean
  labels: CustomerLabel[]
  loading: boolean
  refetch: () => void
}

export interface UseCustomerLabelsParams {
  forceRefetch?: boolean
}

export default function useCustomerLabels({
  forceRefetch,
}: UseCustomerLabelsParams): UseCustomerLabelsHook {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: !!forceRefetch ? 'cache-and-network' : 'cache-first',
    }
  )

  return {
    error: !!error,
    labels: [...(data?.registry.customerLabels || [])],
    loading,
    refetch,
  }
}
