import { useEffect, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'
import { useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { useReservationListContext } from '../../../ReservationListState'
import { SelectedFeatures } from './SelectedFeatures'

type Props = {
  selectedFeatures: RoomFeature[]
  readOnly?: boolean
  handleUpdateFeatures: (featureIds: string[]) => Promise<void>
}

type Feature = RoomFeature & {
  selected: boolean
}

export const Features = ({
  selectedFeatures,
  readOnly,
  handleUpdateFeatures,
}: Props) => {
  const theme = useTheme()

  const { checkIconValidity } = useGlobalIconsContext()
  const { roomFeatures } = useReservationListContext()

  const getCurrentFeatures = (roomFeatures: RoomFeature[]) =>
    roomFeatures.map((feature: RoomFeature) => ({
      ...feature,
      selected: !!selectedFeatures.find(
        (selectedFeature: RoomFeature) => selectedFeature.id === feature.id
      ),
    }))

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [features, setFeatuers] = useState<Feature[]>(
    getCurrentFeatures(roomFeatures)
  )

  useEffect(() => {
    setFeatuers(getCurrentFeatures(roomFeatures))
  }, [roomFeatures])

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection
            style={{ margin: 0, padding: theme.spacing.gutter }}
          >
            <FlexColumn noPadding>
              {features
                .sort(generateCompareFn('name'))
                .map((feature: Feature) => (
                  <CheckboxWrapper key={feature.id}>
                    <CheckboxInput
                      checked={feature.selected}
                      noMargin
                      onChange={() =>
                        setFeatuers(
                          features.map((f: Feature) =>
                            f.id === feature.id
                              ? { ...f, selected: !f.selected }
                              : f
                          )
                        )
                      }
                    >
                      <FlexRow alignItems="center">
                        <CheckboxLabel>{feature.name}</CheckboxLabel>
                        {checkIconValidity(feature.icon) && (
                          <FontAwesomeIcon
                            fixedWidth
                            icon={feature.icon as IconProp}
                            size="sm"
                          />
                        )}
                      </FlexRow>
                    </CheckboxInput>
                  </CheckboxWrapper>
                ))}
            </FlexColumn>
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => {
        setModalOpen(false)
        handleUpdateFeatures(
          features.filter((f: Feature) => f.selected).map((f: Feature) => f.id)
        )
      }}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          ref={ref}
          onClick={() => !readOnly && setModalOpen(true)}
          readOnly={readOnly}
        >
          {selectedFeatures.length ? (
            <SelectedFeatures selectedFeatures={selectedFeatures} />
          ) : (
            <Placeholder>
              <T>Accommodation:SalesReservationList.addFeatures</T>
            </Placeholder>
          )}
        </Button>
      )}
    />
  )
}

////////

const Button = styled.div<{
  readOnly?: boolean
}>`
  display: flex;
  align-items: center;
  align-self: stretch;

  cursor: pointer;
  transition: 0.2s;

  height: 100%;
  width: 150px;

  ${({ theme, readOnly }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;

    ${readOnly &&
    css`
      cursor: not-allowed;
      background-color: ${theme.palette.smoke.light};
    `}

    &:hover {
      background-color: ${theme.palette.smoke.light};
    }
  `}
`

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

const Placeholder = styled.span`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
