import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { H2, H4 } from '@/components/Typography'
import { T } from '@/modules/Language'

import { PrintButton } from './PrintButton'

interface Props {
  date: Moment
  selectNextDate: () => void
  selectPrevDate: () => void
}

export const Header = ({ date, selectNextDate, selectPrevDate }: Props) => (
  <Gutter flex={1} type={[2, 4]}>
    <FlexRow alignItems="center" justifyContent="space-between">
      <FlexColumn alignItems="flex-start" noPadding>
        <SectionTitle>
          <T>FrontDesk:DailyCustomerList.subtitle</T>
        </SectionTitle>
        <ViewTitle>
          <T>FrontDesk:DailyCustomerList.title</T>
        </ViewTitle>
      </FlexColumn>
      <FlexRow alignItems="center" justifyContent="flex-end">
        <SelectedDateWrapper>
          <Button onClick={() => selectPrevDate()} variant="borderless">
            <FontAwesomeIcon icon="angle-left" />
          </Button>
          <SelectedDateLabel>{date.format('dd D.M.YYYY')}</SelectedDateLabel>
          <Button onClick={() => selectNextDate()} variant="borderless">
            <FontAwesomeIcon icon="angle-right" />
          </Button>
        </SelectedDateWrapper>
        <PrintButton targetDate={date} />
      </FlexRow>
    </FlexRow>
  </Gutter>
)

////////////

const SelectedDateWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  width: 240px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterBig};
  `}
`

const SelectedDateLabel = styled(H4)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`

const SectionTitle = styled.span`
  font-variant-caps: all-small-caps;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const ViewTitle = styled(H2)`
  margin: 0;
  font-weight: 600;
`
