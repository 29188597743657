import { gql, useMutation } from '@apollo/client'

import { salesFragments } from '@/modules/Sales/fragments'

import type {
  RefreshUserMutation,
  RefreshUserMutationVariables,
} from '~generated-types'

export const REFRESH_USER = gql`
  ${salesFragments.salesAssignee}

  mutation RefreshUser($externalId: ID!) {
    refreshUserByExternalId(externalId: $externalId) {
      ...SalesAssignee
    }
  }
`

export const useRefreshUserMutation = () =>
  useMutation<RefreshUserMutation, RefreshUserMutationVariables>(REFRESH_USER)
