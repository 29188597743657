import { MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ChangelogFAB = ({ onClick }: Props) => (
  <WidgetButton onClick={onClick}>
    <WidgetButtonLabel>
      <T>Changelog:action.show</T>
    </WidgetButtonLabel>
    <FontAwesomeIcon icon="clock-rotate-left" size="1x" />
  </WidgetButton>
)

////////////

const WidgetButtonLabel = styled.span`
  font-weight: 600;
  margin-bottom: 2px;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const WidgetButton = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.2s;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    color: ${theme.palette.primary.main};
    padding: ${theme.spacing.gu(1)}rem;
  `}

  &:active {
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.09),
      0 3px 1px -2px rgba(0, 0, 0, 0.08),
      0 1px 5px 0 rgba(0, 0, 0, 0.13);
    transform: translateY(2px);
  }

  &:hover {
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.09),
      0 1px 18px 0 rgba(0, 0, 0, 0.08),
      0 3px 5px -1px rgba(0, 0, 0, 0.13);

    ${({ theme }) => css`
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.white};
    `}
  }
`
