import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'

export const NumberInput = styled(Input).attrs(() => ({
  step: 1,
  type: 'number',
}))`
  &&& {
    ${({ theme }) => css`
      height: ${theme.spacing.gu(4)}rem;
      max-width: ${theme.spacing.gu(10)}rem;
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }
`
