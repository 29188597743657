import React from 'react'
import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components/macro'

import type { PropsWithTheme } from '@/theme'

type Color =
  | 'primary'
  | 'default'
  | 'danger'
  | 'warning'
  | 'success'
  | 'transparent'
type Size = 'small' | 'default' | 'big'
type Variant = 'borderless' | 'default' | 'outlined'

export type ButtonProps = {
  color?: Color
  disabled?: boolean
  noMargin?: boolean
  noNudge?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>
  size?: Size
  type?: string
  variant?: Variant
}

export const Button = styled.button.attrs((props: ButtonProps) => ({
  size: props.size ? props.size : 'default',
  type: props.type ? props.type : 'button',
}))<ButtonProps>`
  position: relative;
  z-index: 500;
  flex-shrink: 0;
  align-items: center;

  display: inline-flex;

  transition: all 0.1s ease-out;

  ${({ noMargin, theme }) => css`
    margin: 0 ${noMargin ? '0' : theme.spacing.gutterSmall} 0 0;
  `}
  border-style: solid;
  border-width: 2px;
  border-radius: ${(props) => radiusReconciler(props)};
  height: ${(props) => sizeReconciler(props)};

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}

  outline: none;
  cursor: pointer;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    letter-spacing: ${theme.spacing.gu(0.05)}rem;
  `}
  font-size: ${(props) => fontSizeReconciler(props)};
  line-height: 1;
  font-weight: 600;
  white-space: nowrap;

  ${(props) => colorReconciler(props)}

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    ${(props) => (props.noNudge ? '' : 'transform: translateY(-1px);')}
  }
`

export default Button

//////////////////////

const isOutlined = (variant: Variant | null | undefined) =>
  variant === 'outlined' || variant === 'borderless'
const hideBorder = (variant: Variant | null | undefined) =>
  variant === 'borderless'

const colorReconciler = ({
  color,
  theme,
  variant,
}: PropsWithTheme<ButtonProps>) => {
  switch (color) {
    case 'danger':
      return css`
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.danger.main};
        border-color: ${hideBorder(variant)
          ? 'transparent'
          : theme.palette.danger.main};
        color: ${isOutlined(variant)
          ? theme.palette.danger.main
          : theme.palette.text.white};

        &:not(:disabled):hover {
          background: ${lighten(0.1, theme.palette.danger.main)};
          border-color: ${lighten(0.1, theme.palette.danger.main)};
          color: ${theme.palette.text.white};
        }

        &:not(:disabled):active,
        &:not(:disabled):focus {
          background: ${darken(0.05, theme.palette.danger.main)};
          border-color: ${darken(0.05, theme.palette.danger.main)};
          color: ${theme.palette.text.white};
        }
      `
    case 'warning':
      return css`
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.warning.main};
        border-color: ${hideBorder(variant)
          ? 'transparent'
          : theme.palette.warning.main};
        color: ${isOutlined(variant)
          ? theme.palette.warning.main
          : theme.palette.text.white};

        &:not(:disabled):hover {
          background: ${lighten(0.1, theme.palette.warning.main)};
          border-color: ${lighten(0.1, theme.palette.warning.main)};
          color: ${theme.palette.text.white};
        }

        &:not(:disabled):active,
        &:not(:disabled):focus {
          background: ${darken(0.05, theme.palette.warning.main)};
          border-color: ${darken(0.05, theme.palette.warning.main)};
          color: ${theme.palette.text.white};
        }
      `
    case 'success':
      return css`
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.success.main};
        border-color: ${hideBorder(variant)
          ? 'transparent'
          : theme.palette.success.main};
        color: ${isOutlined(variant)
          ? theme.palette.success.main
          : theme.palette.text.white};

        &:not(:disabled):hover {
          background: ${lighten(0.1, theme.palette.success.main)};
          border-color: ${lighten(0.1, theme.palette.success.main)};
          color: ${theme.palette.text.white};
        }

        &:not(:disabled):active,
        &:not(:disabled):focus {
          background: ${darken(0.05, theme.palette.success.main)};
          border-color: ${darken(0.05, theme.palette.success.main)};
          color: ${theme.palette.text.white};
        }
      `
    case 'primary':
      return css`
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.primary.main};
        border-color: ${hideBorder(variant)
          ? 'transparent'
          : theme.palette.primary.main};
        color: ${isOutlined(variant)
          ? theme.palette.primary.main
          : theme.palette.text.white};

        &:not(:disabled):hover {
          background: ${theme.palette.primary.light};
          border-color: ${theme.palette.primary.light};
          color: ${theme.palette.text.white};
        }

        &:not(:disabled):active,
        &:not(:disabled):focus {
          background: ${theme.palette.primary.dark};
          border-color: ${theme.palette.primary.dark};
          color: ${theme.palette.text.white};
        }
      `
    case 'transparent':
      return css`
        background: transparent;
        border-color: transparent;
        color: ${theme.palette.text.dark};

        &:not(:disabled):hover {
          background: ${theme.palette.smoke.main};
          border-color: ${theme.palette.smoke.main};
        }

        &:not(:disabled):active,
        &:not(:disabled):focus {
          background: ${theme.palette.smoke.dark};
          border-color: ${theme.palette.smoke.dark};
        }
      `
    case 'default':
    default:
      return css`
        background: ${isOutlined(variant)
          ? 'transparent'
          : theme.palette.smoke.main};
        border-color: ${hideBorder(variant)
          ? 'transparent'
          : theme.palette.smoke.main};
        color: ${theme.palette.text.dark};

        &:not(:disabled):hover {
          background: ${theme.palette.smoke.dark};
          border-color: ${theme.palette.smoke.dark};
        }

        &:not(:disabled):active,
        &:not(:disabled):focus {
          background: ${theme.palette.smoke.extraDark};
          border-color: ${theme.palette.smoke.extraDark};
        }
      `
  }
}

const fontSizeReconciler = ({
  size,
  theme,
}: PropsWithTheme<ButtonProps>): string => {
  switch (size) {
    case 'small':
      return '14px'
    case 'big':
      return theme.typography.fontSizeBig
    case 'default':
    default:
      return theme.typography.fontSizeBase
  }
}

const radiusReconciler = ({
  size,
  theme,
}: PropsWithTheme<ButtonProps>): string => {
  switch (size) {
    case 'small':
      return `${theme.spacing.gu(2)}rem`
    case 'big':
      return `${theme.spacing.gu(3)}rem`
    case 'default':
    default:
      return `${theme.spacing.gu(2.5)}rem`
  }
}

const sizeReconciler = ({
  size,
  theme,
}: PropsWithTheme<ButtonProps>): string => {
  switch (size) {
    case 'small':
      return `${theme.spacing.gu(4)}rem`
    case 'big':
      return `${theme.spacing.gu(6)}rem`
    case 'default':
    default:
      return `${theme.spacing.gu(5)}rem`
  }
}
