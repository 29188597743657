import type { DefaultThemeOptions, Theme } from './types'
import { generateTheme } from './utils'

export const defaultThemeOpts: Readonly<DefaultThemeOptions> = {
  branding: {
    faviconSrc: '/favicon.ico',
    logoWideSrc: '/willba-default-logo.png',
    pageTitle: 'Willba',
  },
  palette: {
    accent1: '#008f4c',
    accent2: '#ee4c9b',
    accent3: '#ece936',
    black: '#111',
    coal: {
      dark: '#222',
      light: '#777',
      main: '#666',
    },
    danger: '#c75c3b',
    gold: '#f2cc00',
    primary: '#0095da',
    secondary: '#8dc63f',
    smoke: {
      dark: '#c8ccd0',
      extraDark: '#a2a8b1',
      extraLight: '#fcfcfd',
      light: '#e9f1f3',
      lighter: '#f2f6f8',
      main: '#d9e5ea',
    },
    success: '#8cb945',
    text: {
      dark: '#181821',
      light: '#5c5e74',
      lighter: '#999ca2',
      main: '#282831',
      white: '#fff',
    },
    warning: '#f0c45b',
    white: '#fff',
  },
  spacing: {
    gridUnitSizeInPx: 6.5,
  },
  typography: {
    defaultFontWeight: 400,
    defaultFontWeightHeading: 600,
    fontFamily: 'Montserrat, sans-serif',
    fontFamilyHeading: 'Montserrat, sans-serif',
    fontSizeBaseInPx: 13,
  },
}

export const defaultTheme: Readonly<Theme> = generateTheme({}, defaultThemeOpts)

export default defaultTheme
