import { Reservation, Room } from '../types'
import {
  InfoCell,
  ReservationCell,
  RoomCell,
  SalesCell,
  SpacerCell,
} from './Cells'

interface Props {
  reservation: Reservation
  room: Room
}

export const LodgingRoomReservationsTableRow = ({
  reservation,
  room,
}: Props) => (
  <tr>
    <RoomCell hideCleaningStatus room={room} />
    <SpacerCell />
    <ReservationCell reservation={reservation} type="LODGING" />
    <SalesCell data={reservation?.participantRooms[0] ?? null} />
    <InfoCell data={reservation?.request ?? null} />
    <SpacerCell />
    <td colSpan={3} />
  </tr>
)
