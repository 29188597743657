import styled, { css } from 'styled-components/macro'

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const EditButton = styled(IconButton)`
  justify-content: space-between;
  flex: 1;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: -${theme.spacing.gu(1)}rem;
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem;
    width: calc(100% + ${theme.spacing.gu(1)}rem);
  `}
`

export const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

export const IconWrapper = styled.div`
  text-align: end;

  ${({ theme }) => css`
    width: ${theme.spacing.gutterBig};
  `}
`
