import styled, { css } from 'styled-components/macro'

import { InputActionButton } from '../InputActionButton'
import { InputActions } from '../InputActions'
import { InputWrapper } from '../InputWrapper'
import { PrimitiveNumberInput } from '../PrimitiveNumberInput'

type NSProps = {
  type: 'number'
  disabled?: boolean
}
// No clean way of declaring as types aren't provided for StyledComponents
// which would declare .extend
//type IABProps = {
//  onClick: Function,
//  disabled?: boolean,
//}
type NIProps = {
  onIncrease: (...args: Array<any>) => any
  onDecrease: (...args: Array<any>) => any
  disabled?: boolean
}

const CondensedWrapper = styled(InputWrapper)`
  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(20)}rem;
  `}
`
export const NumberStyledInput = styled(PrimitiveNumberInput)<NSProps>`
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const StackedInputActions = styled(InputActions)`
  flex-direction: column;
`
const Arrow = styled(InputActionButton)<{ disabled?: boolean }>`
  position: relative;
  height: 50%;
  line-height: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
  `}

  &::after {
    content: '';
    position: relative;
    display: block;
    width: 0;
    height: 0;
    margin: 0 auto;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid
      ${(props) =>
        props.disabled
          ? props.theme.palette.smoke.dark
          : props.theme.palette.black};
  }
`
export const UpArrow = styled(Arrow)`
  &::after {
    bottom: -4px;
  }
`
export const DownArrow = styled(Arrow)`
  &::after {
    top: -4px;

    transform: rotate(180deg);
  }
`

const NumberInput = ({
  onIncrease,
  onDecrease,
  disabled,
  ...props
}: NIProps) => (
  <CondensedWrapper>
    <NumberStyledInput {...props} disabled={disabled} type="number" />
    <StackedInputActions>
      <UpArrow disabled={disabled} onClick={onIncrease} />
      <DownArrow disabled={disabled} onClick={onDecrease} />
    </StackedInputActions>
  </CondensedWrapper>
)

export default NumberInput
