import { gql, useMutation } from '@apollo/client'

import type {
  AddPurchaseFromCatalogProductMutation,
  AddPurchaseFromCatalogProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productPurchase}

  mutation AddPurchaseFromCatalogProduct(
    $input: PurchaseProductAddFromCatalogProductInput!
  ) {
    purchaseProductAddFromCatalogProduct(input: $input) {
      ...ProductPurchase
    }
  }
`

export const useAddPurchaseFromCatalogProductMutation = () =>
  useMutation<
    AddPurchaseFromCatalogProductMutation,
    AddPurchaseFromCatalogProductMutationVariables
  >(MUTATION)
