import { gql, useMutation } from '@apollo/client'

import type {
  AddPurchasesFromSalesProductMutation,
  AddPurchasesFromSalesProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productPurchase}

  mutation AddPurchasesFromSalesProduct(
    $input: [PurchaseProductAddFromSalesProductInput!]!
  ) {
    purchaseProductMassAdd(input: $input) {
      ...ProductPurchase
    }
  }
`

export const useAddPurchasesFromSalesProductMutation = () =>
  useMutation<
    AddPurchasesFromSalesProductMutation,
    AddPurchasesFromSalesProductMutationVariables
  >(MUTATION)
