import { BottomSheet } from '@/components/BottomSheet'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'

import { CatalogProductPickerList } from './CatalogProductPickerList'

type Props = {
  isMulti?: boolean
  onClose: () => void
  onItemSelect: (id: string) => Promise<string[] | undefined | void>
  onItemUnselect?: (ids: string[]) => Promise<string | undefined>
  sellerId?: string
}

export const CatalogProductPicker = ({
  isMulti,
  onClose,
  onItemSelect,
  onItemUnselect,
  sellerId,
}: Props) => (
  <BottomSheet
    isTextCloseButton={isMulti}
    onClose={onClose}
    title={<T>Products:CatalogProductList.picker</T>}
    zIndex={10010}
  >
    <Gutter type={[0, 5, 10]}>
      <CatalogProductPickerList
        isMulti={isMulti}
        onItemSelect={onItemSelect}
        onItemUnselect={onItemUnselect}
        sellerId={sellerId}
      />
    </Gutter>
  </BottomSheet>
)
