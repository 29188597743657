import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { InlineModal, InlineModalLine } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'
import ModalHeader from './common/ModalHeader'
import ModalNoteEditor from './common/ModalNoteEditor'
import SpecialDietary from './components/SpecialDietary'
import SpecialDiets from './components/SpecialDiets'

type Props = {
  allergyDescription: string
  drugAllergyDescription: string
  specialDietaryNeeds: string[]
  diets: string[]
  readOnly?: boolean
  handleUpdateNote: (
    allergyDescription: string
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateDrugAllergy: (
    drugAllergyDescription: string
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateDietary: (
    specialDietaryNeeds: string[]
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateDiets: (
    diets: string[]
  ) => Promise<FetchedParticipant | undefined>
}

const Allergy = ({
  allergyDescription,
  drugAllergyDescription,
  specialDietaryNeeds,
  diets,
  readOnly,
  handleUpdateNote,
  handleUpdateDrugAllergy,
  handleUpdateDietary,
  handleUpdateDiets,
}: Props) => {
  const { palette, spacing, typography } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalContainer
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            ref={ref}
            disabled={readOnly}
            minWidth={`${spacing.gu(16)}rem`}
            onClick={readOnly ? () => null : () => setModalOpen(true)}
            renderContent={() =>
              allergyDescription ||
              specialDietaryNeeds.length ||
              drugAllergyDescription ||
              diets.length ? (
                <ContentWrapper>
                  <FontAwesomeIcon
                    color={
                      allergyDescription || drugAllergyDescription
                        ? palette.warning.dark
                        : palette.text.light
                    }
                    icon={['far', 'note-sticky']}
                    size="lg"
                    style={{ margin: `0 ${spacing.gutterSmall}` }}
                  />
                  {specialDietaryNeeds.length || diets.length ? (
                    <>
                      <FontAwesomeIcon
                        color={palette.coal.dark}
                        size="xs"
                        icon={'plus'}
                        style={{ margin: `0 ${spacing.gu(1)}rem` }}
                      />
                      {[
                        specialDietaryNeeds[0],
                        specialDietaryNeeds[1],
                        diets[0],
                        diets[1],
                      ]
                        .filter(Boolean)
                        .map((diet) => (
                          <Chip
                            color="primary"
                            key={diet}
                            size="sm"
                            style={{ marginRight: `${spacing.gu(1)}rem` }}
                          >
                            {diet}
                          </Chip>
                        ))
                        .slice(0, 2)}
                      {specialDietaryNeeds.length + diets.length > 2 && (
                        <>
                          <FontAwesomeIcon
                            color={palette.coal.dark}
                            size="xs"
                            icon={'plus'}
                            style={{ margin: `0 ${spacing.gu(1)}rem` }}
                          />
                          <FontWeight
                            style={{ fontSize: typography.fontSizeBase2 }}
                          >
                            {specialDietaryNeeds.length + diets.length - 2}
                          </FontWeight>
                        </>
                      )}
                    </>
                  ) : (
                    <FlexRow>
                      <FontAwesomeIcon
                        color={palette.coal.dark}
                        size="xs"
                        icon={'plus'}
                        style={{ margin: `0 ${spacing.gu(1)}rem` }}
                      />
                      <FontWeight light style={{ fontStyle: 'italic' }}>
                        <T>
                          ParticipantsList:PersonalFields.noSpecialDietaries
                        </T>
                      </FontWeight>
                    </FlexRow>
                  )}
                </ContentWrapper>
              ) : (
                <FlexRow>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon={'plus'}
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                  <Label>
                    <T>ParticipantsList:PersonalFields.specialDietaryNeeds</T>
                  </Label>
                </FlexRow>
              )
            }
          />
        )}
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        modal={
          <ModalWrapper>
            <ModalHeader
              title={
                <T>ParticipantsList:PersonalFields.specialDietaryManager</T>
              }
              onClose={() => setModalOpen(false)}
            />
            <ModalNoteEditor
              title={<T>ParticipantsList:PersonalFields.allergy</T>}
              note={allergyDescription}
              handleUpdateNote={handleUpdateNote}
            />
            <ModalNoteEditor
              title={<T>ParticipantsList:PersonalFields.drugAllergy</T>}
              note={drugAllergyDescription}
              handleUpdateNote={handleUpdateDrugAllergy}
            />
            <SpecialDietary
              specialDietaryNeeds={specialDietaryNeeds}
              handleUpdateDietaryNeeds={handleUpdateDietary}
            />
            <InlineModalLine />
            <SpecialDiets
              specialDiets={diets}
              handleUpdateDiets={handleUpdateDiets}
            />
          </ModalWrapper>
        }
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

export default Allergy

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`

const ModalWrapper = styled(InlineModal)`
  max-height: 100vh;
  width: 450px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`
