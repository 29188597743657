import { ApolloClient } from '@apollo/client'
import { Buffer, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'

import { Palette } from '@/theme'

import {
  FeatureStatus,
  InvoiceCostingQuery as QueryData,
  InvoiceCostingQueryVariables as QueryVariables,
} from '~generated-types'

import { addPayments } from './addPayments'
import { addProducts } from './addProducts'
import { addSummary } from './addSummary'
import { addTitle } from './addTitle'
import { QUERY } from './query'

export type Input = {
  client: ApolloClient<object>
  invoiceId: string
  palette: Palette
  translateFn: (keys: string | Array<string>) => string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export async function generateCostingExcel({
  client,
  invoiceId,
  palette,
  translateFn,
}: Input): Promise<void> {
  const { data } = await client.query<QueryData, QueryVariables>({
    fetchPolicy: 'no-cache',
    query: QUERY,
    variables: { input: invoiceId },
  })

  if (!data || !data.invoice) {
    throw new Error('Failed to fetch invoice data')
  }

  const { invoice, registry } = data
  const { invoiceNumber, items, order, payments } = invoice

  const isCommission = order.sales.commissionRate !== 0
  const isMarginalTax = registry.config.marginalTax === FeatureStatus.Enabled

  const workbook = createWorkbook()

  const sheet = workbook.addWorksheet(translateFn('Excel.title'), {
    views: [{ state: 'frozen', ySplit: 8 }],
  })

  addTitle({ palette, sheet, translateFn })

  addSummary({ invoice, isCommission, palette, sheet, translateFn })

  addProducts({ isMarginalTax, items, palette, sheet, translateFn })

  if (!!payments.length) {
    addPayments({ palette, payments, sheet, translateFn })
  }

  const fileName = `${translateFn(
    'Header.title.INVOICE'
  )}-${invoiceNumber}.xlsx`

  const buffer: Buffer = await workbook.xlsx.writeBuffer({
    useStyles: true,
  })

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, `${fileName}`)
}

const createWorkbook = () => {
  const workbook = new Workbook()

  workbook.creator = 'Willba'
  workbook.lastModifiedBy = 'Willba'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  return workbook
}
