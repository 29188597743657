import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'dot-prop'
import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

import { SalesRoomReservation } from '../../../SalesReservationList.types'

type Props = {
  fulfilledByRooms: boolean
  roomReservations: SalesRoomReservation[]
}

export const TotalRoomTypeBeds = ({
  fulfilledByRooms,
  roomReservations,
}: Props) => {
  const theme = useTheme()

  const getBackgroundColor = () =>
    fulfilledByRooms
      ? theme.palette.success.main
      : darken(0.1, theme.palette.gold)

  const getBedCount = (field: string) =>
    roomReservations.reduce(
      (acc: number, r: SalesRoomReservation) =>
        acc + (get(r.request, field) as number),
      0
    )

  const renderRoomTypeTotalBeds = () =>
    `${getBedCount('beds')} / ${getBedCount('room.beds')}`

  const renderRoomTypeTotalExtraBeds = () =>
    `${getBedCount('extraBeds')} / ${getBedCount('room.extraBeds')}`

  return (
    <Wrapper background={getBackgroundColor()}>
      <IconWrapper>
        <FontAwesomeIcon icon="bed" size="sm" />
      </IconWrapper>
      <Section width={`${theme.spacing.gu(8)}rem`}>
        {renderRoomTypeTotalBeds()}
      </Section>
      <Section width={`${theme.spacing.gu(1)}rem`} noMargin>
        <span>+</span>
      </Section>
      <IconWrapper>
        <FontAwesomeIcon icon="couch" size="sm" />
      </IconWrapper>
      <Section width={`${theme.spacing.gu(8)}rem`}>
        {renderRoomTypeTotalExtraBeds()}
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  background: string
}>`
  display: flex;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  color: ${({ background }) => background};

  ${({ theme }) => css`
    & svg {
      color: ${lighten(0.16, theme.palette.text.light)};
    }
  `}
`

const IconWrapper = styled.div`
  text-align: center;
  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
  `}
`

const Section = styled.div<{
  width: string
  noMargin?: boolean
}>`
  width: ${({ width }) => width};
  text-align: center;
  ${({ theme, noMargin }) =>
    !noMargin &&
    css`
      margin-left: -${theme.spacing.gu(1)}rem;
    `}
`
