import { salesHooks } from '@/modules/Sales/hooks'

import { Feature } from '~generated-types'

import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import {
  Basic,
  Permissions,
  PublishedProducts,
  PublishedRooms,
} from './components'
import { PublishContextProvider } from './PublishState'

const SECTION: SectionFeatures = {
  icon: 'eye',
  key: 'publish',
}

const PublishComponent = () => {
  const {
    data: { facet },
  } = salesHooks.useSalesDetailsContext()

  const showPublishedRooms = !!facet.features.find(
    ({ feature }) => feature === Feature.PublishRoom
  )

  return (
    <PublishContextProvider>
      <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
        <Basic />

        <Permissions />

        <PublishedProducts />

        {showPublishedRooms && <PublishedRooms />}
      </SectionContainer>
    </PublishContextProvider>
  )
}

export const Publish = Object.freeze({
  component: PublishComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
