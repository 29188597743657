import { gql, useMutation } from '@apollo/client'

import {
  AccommodationSettingsFragment,
  RoomTypeReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

import {
  RoomTypeReservationSetBedQuantityMutation,
  RoomTypeReservationSetBedQuantityMutationVariables,
} from '~generated-types'

export type {
  RoomTypeReservationSetBedQuantityMutation,
  RoomTypeReservationSetBedQuantityMutationVariables,
}

export const ROOM_TYPE_RESERVATION_SET_BED_QUANTITY = gql`
  ${RoomTypeReservationFragment}
  ${AccommodationSettingsFragment}

  mutation RoomTypeReservationSetBedQuantity(
    $input: AccommodationRoomTypeReservationSetBedQuantityInput!
  ) {
    accommodationRoomTypeReservationSetBedQuantity(input: $input) {
      roomTypeReservation {
        ...RoomTypeReservation
      }
      accommodationGroup {
        ...AccommodationSettings
      }
    }
  }
`

export function useRoomTypeReservationSetBedQuantity() {
  return useMutation<
    RoomTypeReservationSetBedQuantityMutation,
    RoomTypeReservationSetBedQuantityMutationVariables
  >(ROOM_TYPE_RESERVATION_SET_BED_QUANTITY)
}

export default useRoomTypeReservationSetBedQuantity
