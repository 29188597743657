import moment from 'moment'
import styled from 'styled-components/macro'

import { ListItem as ListItemWrapper } from '@/components/List'
import { ElasticTask } from '@/modules/Task/types'

import { TargetTask } from '../../TaskSearchList'
import AssigneeInfo from './components/AssigneeInfo'
import DueInfo from './components/DueInfo'
import ItemInfo from './components/ItemInfo'
import ReservationInfo from './components/ReservationInfo'
import SalesInfo from './components/SalesInfo'
import StatusInfo from './components/StatusInfo'

type Props = {
  data: ElasticTask
  setTargetTask: (task: TargetTask | null) => void
}

const ListItem = ({
  data: {
    closedAt,
    createdAt,
    dueDate,
    dueTime,
    isOpen,
    name,
    number,
    reservation,
    sales,
    assignee,
  },
  setTargetTask,
}: Props) => (
  <Wrapper
    align="center"
    onClick={() =>
      setTargetTask({
        number,
        readOnly: sales.state === 'CLOSED' || sales.state === 'CANCELLED',
      })
    }
  >
    <StatusInfo isOpen={isOpen} />

    <ItemInfo
      closedAt={closedAt ? moment.unix(Number(closedAt) / 1000) : null}
      createdAt={moment.unix(Number(createdAt) / 1000)}
      name={name}
      number={number ?? -1}
    />

    <AssigneeInfo assignee={assignee?.name} />

    <DueInfo dueDate={dueDate} dueTime={dueTime} reservation={reservation} />

    <ReservationInfo reservation={reservation} />

    <SalesInfo sales={sales} />
  </Wrapper>
)

export default ListItem

const Wrapper = styled(ListItemWrapper)<{ disabled?: boolean }>`
  ${({ disabled, theme }) =>
    disabled &&
    `
    opacity: 0.7;
    background-color: ${theme.palette.smoke.light};
  `}
`
