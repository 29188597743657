import { gql, useMutation } from '@apollo/client'

import {
  CreateSaleMutation,
  CreateSaleMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation CreateSale($input: SalesCreateInput!) {
    salesCreate(input: $input) {
      id
      orderNumber
    }
  }
`

export const useCreateSaleMutation = () =>
  useMutation<CreateSaleMutation, CreateSaleMutationVariables>(MUTATION)
