import React from 'react'
import styled, { css } from 'styled-components/macro'

import { FontWeight } from '@/components/Typography'
import { useTheme } from '@/theme'

import OptionsList from './OptionsList'

type Props = {
  groupTitle: React.ReactNode | string
  options: {
    value: string
    label: React.ReactNode | string
    disabled?: boolean
    startAdornment?: React.ReactNode | string
    endAdornment?: React.ReactNode | string
  }[]
  optionEndAdornment?: React.ReactNode | string
  noOptionsLabel: React.ReactNode | string
  handleSelect: (label: string) => Promise<any> | void
}

const OptionsListWithHeading = ({
  groupTitle,
  options,
  noOptionsLabel,
  handleSelect,
}: Props) => {
  const { spacing } = useTheme()

  return (
    <>
      <TitleWrapper>{groupTitle}</TitleWrapper>
      {options.length ? (
        <OptionsList options={options} handleSelect={handleSelect} />
      ) : (
        <FontWeight style={{ margin: `${spacing.gu(1)}rem` }}>
          {noOptionsLabel}
        </FontWeight>
      )}
    </>
  )
}

export default OptionsListWithHeading

const TitleWrapper = styled.div`
  display: flex;

  border-radius: 8px;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.primary.extraLight};
    padding: ${theme.spacing.gutterSmall};
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`
