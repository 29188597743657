import { ErrorInfo, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'

import { LogErrorMutation, LogErrorMutationVariables } from '~generated-types'

const LOG_ERROR_MUTATION = gql`
  mutation LogError($error: String!) {
    logExternal(error: $error) {
      success
    }
  }
`

type Props = {
  error: Error
  info: ErrorInfo
}

export const useErrorLogger = ({
  error: { name, message },
  info: { componentStack },
}: Props) => {
  const [logError] = useMutation<LogErrorMutation, LogErrorMutationVariables>(
    LOG_ERROR_MUTATION
  )

  useEffect(() => {
    logError({ variables: { error: `${name}: ${message} ${componentStack}` } })
  }, [])
}
