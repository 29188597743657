import { gql, useMutation } from '@apollo/client'

import type {
  CreateSalesProductMutation,
  CreateSalesProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.salesProduct}

  mutation CreateSalesProduct($input: SalesProductCreateInput!) {
    salesProductCreate(input: $input) {
      ...SalesProduct
    }
  }
`

export const useCreateSalesProductMutation = () =>
  useMutation<CreateSalesProductMutation, CreateSalesProductMutationVariables>(
    MUTATION
  )
