import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import type {
  ProductPurchase,
  ProductPurchaseParticipant,
} from '@/modules/Products/types'
import { formatCurrency } from '@/utils/currency'

import {
  PurchaseProductAction as PPA,
  QuantityUnit,
  TargetType,
} from '~generated-types'

import { ActionsDropdown } from './ActionsDropdown'
import { OrderSelector } from './OrderSelector'
import {
  InvoiceSection,
  ParticipantSection,
  PurchaseOrderSection,
  ReservationSection,
  TaskSection,
} from './TargetSections'

type Props = {
  purchase: ProductPurchase
  quantityUnit: QuantityUnit
}

export const PurchaseSection = ({
  purchase: {
    attributes: { duration, quantity },
    id,
    link,
    order,
    status: { validatedActions },
    totalPrice: { amountVatIncluded },
    targetType,
  },
  quantityUnit,
}: Props) => {
  const { salesReadOnly, targetPurchaseId } = useSalesProductManagerContext()

  const updateAction = validatedActions.find((a) => a.action === PPA.Update)
  const issues = updateAction?.issues ?? []
  const readOnly = !updateAction?.valid ?? false

  const renderTargetContent = () => {
    switch (targetType) {
      case TargetType.Invoice:
        return (
          <InvoiceSection
            duration={duration}
            id={id}
            quantityUnit={quantityUnit}
            readOnly={readOnly}
          />
        )
      case TargetType.Participant:
        return (
          <ParticipantSection
            duration={duration}
            id={id}
            participant={link.participant}
            quantityUnit={quantityUnit}
            readOnly={readOnly}
          />
        )
      case TargetType.PurchaseOrder:
        return (
          <PurchaseOrderSection
            duration={duration}
            id={id}
            quantity={quantity}
            quantityUnit={quantityUnit}
            readOnly={readOnly}
          />
        )
      case TargetType.Reservation:
        return (
          <ReservationSection
            duration={duration}
            id={id}
            quantityUnit={quantityUnit}
            readOnly={readOnly}
            reservation={link.reservation}
          />
        )
      case TargetType.Task:
        return (
          <TaskSection
            duration={duration}
            id={id}
            quantity={quantity}
            quantityUnit={quantityUnit}
            readOnly={readOnly}
            task={link.task}
          />
        )
      default:
        return null
    }
  }

  return (
    <Wrapper isTarget={targetPurchaseId === id}>
      <FlexRow>
        <Tooltip
          content={<T>{`Products:TargetType.type.${targetType}`}</T>}
          trigger={(triggerProps) => (
            <TargetTypeChip
              color="primary"
              size="sm"
              style={{ margin: 0 }}
              variant="outlined"
              {...triggerProps}
            >
              <T>{`Products:TargetType.abbrev.${targetType}`}</T>
            </TargetTypeChip>
          )}
        />

        <OrderSelector
          order={order}
          purchaseId={id}
          purchaseSales={link.sales}
          readOnly={readOnly}
          suggestedName={
            targetType === TargetType.Participant && link.participant
              ? getParticipantName(link.participant)
              : undefined
          }
        />

        <FlexRow flex={1}>{renderTargetContent()}</FlexRow>

        <Price>{formatCurrency(amountVatIncluded)} €</Price>

        {salesReadOnly || readOnly ? (
          <ActionsPlaceholder justifyContent="center">
            {readOnly && !salesReadOnly && (
              <Tooltip
                content={
                  <FlexColumn noPadding alignItems="flex-start">
                    {issues.map(({ code }) => (
                      <span key={`issue-code-${code}`}>
                        <T>{`IssueCodes:${code}`}</T>
                      </span>
                    ))}
                  </FlexColumn>
                }
                maxWidth={150}
                trigger={(triggerProps) => (
                  <span {...triggerProps}>
                    <FontAwesomeIcon icon="lock" />
                  </span>
                )}
              />
            )}
          </ActionsPlaceholder>
        ) : (
          <ActionsDropdown
            purchaseId={id}
            quantity={quantity}
            targetType={targetType}
          />
        )}
      </FlexRow>
    </Wrapper>
  )
}

/////////

const getParticipantName = (participant: ProductPurchaseParticipant) => {
  const { firstName, lastName } = participant

  return firstName || lastName
    ? `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`
    : undefined
}

const ActionsPlaceholder = styled(FlexRow)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}
`

const Price = styled.span`
  text-align: end;
  font-weight: 500;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(0.5)}rem;
    width: ${theme.spacing.gu(14)}rem;
  `}
`

const TargetTypeChip = styled(Chip)`
  justify-content: center;
  padding-left: 0;
  padding-right: 0;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}
`

const Wrapper = styled.div<{ isTarget: boolean }>`
  align-items: center;

  ${({ isTarget, theme }) => css`
    color: ${theme.palette.text.light};
    background: ${isTarget ? '#fff7e6' : 'transparent'};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
