import { DimensionLabelKeyMap, DimensionSelection } from './types'

export default function transformSelectionToElasticQuery(
  labelKeys: DimensionLabelKeyMap,
  selection: DimensionSelection
): Record<string, unknown> | null {
  const queryTerms: Array<string[]> = []

  Object.entries(selection).forEach(([dimensionName, labelNames]) => {
    const keys: string[] = labelNames
      .map((labelName) => labelKeys[dimensionName]?.[labelName])
      .filter((x) => typeof x === 'string')

    if (keys.length) {
      queryTerms.push(keys)
    }
  })

  return queryTerms.length
    ? {
        bool: {
          must: queryTerms.map((terms) => ({
            terms: {
              'dimensions.key': terms,
            },
          })),
        },
      }
    : null
}
