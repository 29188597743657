import { gql, useMutation } from '@apollo/client'

import type {
  CancelExternalPaymentMutation,
  CancelExternalPaymentMutationVariables,
} from '~generated-types'

const CANCEL_EXTERNAL_PAYMENT_MUTATION = gql`
  mutation CancelExternalPayment($id: ID!) {
    externalPaymentCancel(id: $id) {
      cancelInitiated
      id
    }
  }
`

export const useCancelExternalPaymentMutation = () =>
  useMutation<
    CancelExternalPaymentMutation,
    CancelExternalPaymentMutationVariables
  >(CANCEL_EXTERNAL_PAYMENT_MUTATION)
