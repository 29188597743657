import classNames from 'classnames'
import moment from 'moment'
import styled from 'styled-components'

import { TD } from '@/modules/Listing/common'

import { Resource, TaskDetails } from '../Cells'
import {
  ListingGroupReservation,
  ListingReservation,
} from '../useReservationList'
import { taskCompareFn } from '../utils'

type Props = {
  reservation: ListingReservation | ListingGroupReservation
  showExtendedTasks: boolean
  isGroupReservation: boolean
}

export const GroupReservationsRow = ({
  reservation,
  showExtendedTasks,
  isGroupReservation,
}: Props) => {
  const { start, end, tasks, internalNote, description, dimensions, sales } =
    reservation

  return (
    <tr
      className={classNames({
        'dashed-bottom-border': showExtendedTasks && !!tasks.length,
        subrow: true,
      })}
    >
      <TD colSpan={isGroupReservation ? 1 : 2} noWrap>
        <Resource reservation={reservation} showProduct={true} />
      </TD>
      <TD noWrap>
        {moment(start).format('HH:mm')} – {moment(end).format('HH:mm')}
      </TD>
      <TD>{dimensions?.selection[0]?.selectedLabel?.name || '–'}</TD>
      <TD>{description || '–'}</TD>
      <TD>{internalNote || '–'}</TD>

      <TD colSpan={4} />
      <TD style={{ textAlign: 'center' }}>
        {sales.estimatedParticipants || '–'}
      </TD>
      {!showExtendedTasks && (
        <TD>
          <TasksWrapper>
            {!tasks.length
              ? '–'
              : [...tasks]
                  .sort(taskCompareFn)
                  .map((task) => (
                    <TaskDetails
                      key={task.id}
                      reservationDate={moment(start)}
                      task={task}
                    />
                  ))}
          </TasksWrapper>
        </TD>
      )}
    </tr>
  )
}

////////////

const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
