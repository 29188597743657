import styled, { css } from 'styled-components/macro'

export const IconWrapper = styled.span<{
  borderColor: string
  disabled: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  width: 30px;

  border-radius: 100%;
  border: 2px solid ${({ borderColor }) => borderColor};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const TooltipLine = styled.span`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`
