import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { DropdownButton } from '@/components/ExtraButtons'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ActionButton } from '@/modules/Products/components'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { Catalog, catalogHooks } from '@/modules/Registry/Catalog'
import { useTheme } from '@/theme'

type Props = {
  catalog: Catalog
  productId: string
}

export const Actions = ({ catalog, productId }: Props) => {
  const { palette } = useTheme()

  const { copyProduct, saveProduct } = useSalesProductManagerContext()
  const { catalogs } = catalogHooks.useCatalogs()

  const handleSaveProduct = (catalogId: string) =>
    saveProduct(productId, catalogId)

  const catalogOptions = catalogs
    .filter((c) => c.company.id === catalog.company.id && c.active)
    .map((c) => ({
      label: (
        <>
          <Icon icon="list" size="sm" />
          {c.name}
        </>
      ),
      onClick: () => handleSaveProduct(c.id),
    }))

  return (
    <FlexColumn>
      <ActionButton
        content={<T>Products:ProductManager.action.copyProduct</T>}
        icon="copy"
        iconColor={palette.text.light}
        onClick={() => copyProduct(productId)}
      />

      {catalogOptions.length > 1 ? (
        <DropdownButton
          options={catalogOptions}
          dropdownPlacement="bottom-end"
          renderCustomButton={({ onClick }) => (
            <ActionButton
              content={<T>Products:ProductManager.action.saveToCatalog</T>}
              icon="save"
              iconColor={palette.text.light}
              onClick={onClick}
            />
          )}
          zIndex={10006}
        />
      ) : (
        <ActionButton
          content={<T>Products:ProductManager.action.saveToCatalog</T>}
          icon="save"
          iconColor={palette.text.light}
          onClick={() => saveProduct(productId, catalog.id)}
        />
      )}
    </FlexColumn>
  )
}

/////////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 12px;
    margin-right: 10px;
  }
`
