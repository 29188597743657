import { gql, useMutation } from '@apollo/client'

import {
  AccommodationTargetFragment,
  RoomTypeReservationFragment,
} from '@/modules/Accommodation/Accommodation.fragments'

import {
  AccommodationTargetRoomTypeMutation,
  AccommodationTargetRoomTypeMutationVariables,
} from '~generated-types'

export type {
  AccommodationTargetRoomTypeMutation,
  AccommodationTargetRoomTypeMutationVariables,
}

export const ACCOMMODATION_TARGET_ROOM_TYPE = gql`
  ${RoomTypeReservationFragment}
  ${AccommodationTargetFragment}

  mutation AccommodationTargetRoomType(
    $input: AccommodationRoomTypeTargetInput!
  ) {
    accommodationRoomTypeTarget(input: $input) {
      accommodationTarget {
        ...AccommodationTarget
      }
      roomTypeReservation {
        ...RoomTypeReservation
      }
    }
  }
`

export function useAccommodationTargetRoomType() {
  return useMutation<
    AccommodationTargetRoomTypeMutation,
    AccommodationTargetRoomTypeMutationVariables
  >(ACCOMMODATION_TARGET_ROOM_TYPE)
}

export default useAccommodationTargetRoomType
