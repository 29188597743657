import { lighten } from 'polished'
import styled, { css } from 'styled-components/macro'

type Props = {
  borderBottomStyle?: 'dashed' | 'dotted' | 'inherit' | 'none' | 'solid'
  color?: 'dark' | 'default'
  subRow?: boolean
}

const TR = styled.tr<Props>`
  background-color: ${({ color, theme }) =>
    color === 'dark' ? lighten(0.125, theme.palette.smoke.dark) : 'inherit'};

  ${({ borderBottomStyle }) =>
    borderBottomStyle &&
    css`
      &&& > td {
        border-bottom-style: ${borderBottomStyle};
      }
    `}

  ${({ subRow }) =>
    subRow &&
    css<any>`
      &&& > td:first-child {
        padding-left: 0;
        border-left-width: 0;
        border-bottom-left-radius: 0;
      }
      &&& > td:last-child {
        padding-right: 0;
        border-right-width: 0;
        border-bottom-right-radius: 0;
      }
    `}
`

export default TR
