import { ChangeEvent } from 'react'
import { EditorState } from 'draft-js'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { Input, Label } from '@/components/FormControls'
import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { MarkdownCss, RichTextEditor } from '@/components/RichTextEditor'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  handleSaveNotes: () => void | Promise<any>
  processing: boolean
  readOnly: boolean
  reservationNotes: string
  salesNotes: EditorState
  setReservationNotes: (notes: string) => void
  setSalesNotes: (notes: EditorState) => void
}

export const NotesModal = ({
  handleSaveNotes,
  processing,
  readOnly,
  reservationNotes,
  salesNotes,
  setReservationNotes,
  setSalesNotes,
}: Props) => {
  const { spacing } = useTheme()

  return (
    <Modal>
      <InlineModalHeader
        title={
          <ShiftLeft>
            <T>FrontDesk:DailyRoomReservations.notes.title</T>
          </ShiftLeft>
        }
        onClose={handleSaveNotes}
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent>
        <Wrapper>
          <Label>
            <FontWeight>
              <T>FrontDesk:DailyRoomReservations.notes.reservation</T>
            </FontWeight>
          </Label>
          <Textarea
            disabled={readOnly}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setReservationNotes(e.target.value)
            }
            value={reservationNotes}
          />
        </Wrapper>

        <Separator />

        <Wrapper>
          <MarkdownCss disabled={readOnly} />
          <Label>
            <FontWeight>
              <T>FrontDesk:DailyRoomReservations.notes.sales</T>
            </FontWeight>
          </Label>
          <RichTextEditorWrapper>
            <RichTextEditor
              editorState={salesNotes}
              maxHeight={400}
              minHeight={150}
              readOnly={readOnly}
              setEditorState={setSalesNotes}
            />
          </RichTextEditorWrapper>
        </Wrapper>
      </InlineModalContent>

      <InlineModalFooter justifyContent="flex-end">
        <InnocuousButton
          compact
          disabled={processing}
          onClick={handleSaveNotes}
        >
          <T>common:action.close</T>
        </InnocuousButton>
      </InlineModalFooter>
    </Modal>
  )
}

////////////

const Modal = styled(InlineModal)`
  height: ${({ theme }) =>
    `calc(100vh - ${theme.spacing.guPx(9) + 3}px - 10px)`};
  max-height: 100vh;
  width: 450px;

  margin-top: ${({ theme }) => `${theme.spacing.guPx(9) + 3}px`};
  margin-bottom: 10px;
  margin-right: 10px;
`

const RichTextEditorWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    border-radius: 6px;
    margin-bottom: ${theme.spacing.gu(1)}rem;
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled(InlineModalLine)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
    margin-top: ${theme.spacing.gu(2)}rem;
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`

const Textarea = styled(Input).attrs(() => ({ as: 'textarea' }))<any>`
  &&& {
    line-height: 1.2;
    resize: vertical;

    min-height: 106px;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      border-color: ${theme.palette.smoke.main};
      margin-top: ${theme.spacing.gu(1)}rem;
      padding: 10px;
    `}

    &:disabled {
      cursor: not-allowed;

      ${({ theme }) => css`
        color: ${theme.palette.text.light};
        background: ${theme.palette.smoke.extraLight};
      `}
    }
  }
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem;
  `}
`
