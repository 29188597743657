import React from 'react'

import { ResourceCalendarSidebar } from '@/modules/Reservations'
import { useCalendarState } from '@/modules/Reservations'

import { useReservationsGridState } from './Calendar/ReservationsGrid'

export const Sidebar = () => {
  const {
    addCategorySelection,
    addResourceSelection,
    allResources,
    fetching,
    ownerId,
    removeCategorySelection,
    removeResourceSelection,
    selectedCategories,
    selectedResources,
    setShowFocusedResources,
    setTargetDate,
    showFocusedResources,
    targetDate,
    updateCategorySelection,
    viewMode,
  } = useCalendarState()
  const { isReservationModalOpen } = useReservationsGridState()

  return (
    <ResourceCalendarSidebar
      addCategorySelection={addCategorySelection}
      addResourceSelection={addResourceSelection}
      allResources={allResources}
      date={targetDate}
      isHidden={isReservationModalOpen}
      loadingResources={fetching}
      mode={viewMode}
      ownerId={ownerId}
      removeCategorySelection={removeCategorySelection}
      removeResourceSelection={removeResourceSelection}
      selectedCategories={selectedCategories}
      selectedResources={selectedResources}
      setShowFocusedResources={setShowFocusedResources}
      setDate={setTargetDate}
      showFocusedResources={showFocusedResources}
      updateCategorySelection={updateCategorySelection}
    />
  )
}

export default Sidebar
