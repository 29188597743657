import { gql, useMutation } from '@apollo/client'

import type {
  DeletePurchaseMutation,
  DeletePurchaseMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeletePurchase($id: ID!) {
    purchaseProductDelete(id: $id) {
      deleted
      id
    }
  }
`

export const useDeletePurchaseMutation = () =>
  useMutation<DeletePurchaseMutation, DeletePurchaseMutationVariables>(MUTATION)
