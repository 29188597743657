import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { getStateLabels } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

type Props = {
  activeStates: string[]
  handleSetActiveStates: (states: string[]) => void
}

export const StatesSelector = ({
  activeStates,
  handleSetActiveStates,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const { salesStates } = salesHooks.useSalesStates()

  const [isOpen, setOpen] = useState(false)

  const stateOptions = [...(salesStates ?? [])]
    .sort(generateCompareFn('processOrder'))
    .map(({ key, names }) => ({
      label: getStateLabels(names)[language],
      selected: activeStates.includes(key),
      value: key,
    }))

  const handleSelectState = (value: string, select: boolean) => {
    const updatedStates = select
      ? [...activeStates, value]
      : activeStates.filter((state) => state !== value)

    handleSetActiveStates(updatedStates)
  }

  const selectedStates = stateOptions.filter(({ selected }) => selected)

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <InlineModal style={{ minWidth: `${spacing.gu(24)}rem` }}>
          <InlineModalSection
            style={{
              marginTop: `${spacing.gu(1)}rem`,
              padding: `0 0 ${spacing.gu(1)}rem`,
            }}
          >
            {
              <FlexColumn noPadding flex={1}>
                {stateOptions.map((option) => (
                  <OptionWrapper
                    key={option.value}
                    onClick={() =>
                      handleSelectState(option.value, !option.selected)
                    }
                  >
                    <CheckIconWrapper>
                      {option.selected && (
                        <FontAwesomeIcon
                          icon="check"
                          size="sm"
                          color={palette.primary.main}
                        />
                      )}
                    </CheckIconWrapper>
                    {option.label}
                  </OptionWrapper>
                ))}
              </FlexColumn>
            }
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <FlexRow ref={ref} onClick={() => setOpen(true)} alignItems="center">
          <StatesWrapper>
            <FontAwesomeIcon
              icon="filter"
              color={palette.primary.main}
              size="sm"
            />

            {!!selectedStates.length && (
              <States>
                {selectedStates.map(({ label }) => label).join(', ')}
              </States>
            )}
          </StatesWrapper>
        </FlexRow>
      )}
    />
  )
}

const OptionWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.primary.extraLight};
    `}
  }
`

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
`

const States = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`

const StatesWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
  font-weight: 500;
  height: 35px;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`
