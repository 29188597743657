import { useMemo } from 'react'

import { Option, ThemedMultiSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { buildingHooks } from '@/modules/Registry/Building'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { FilterWrapper } from './FilterWrapper'

type Props = {
  targetBuildingIds: string[]
  setTargetBuildingIds: (buildingIds: string[]) => void
}

export const BuildingSelector = ({
  targetBuildingIds,
  setTargetBuildingIds,
}: Props) => {
  const { spacing } = useTheme()
  const { buildings, loading } = buildingHooks.useBuildings()

  const options: Option[] = useMemo(
    () =>
      buildings
        .map(({ id, name }) => ({
          label: name,
          searchValue: name,
          value: id,
        }))
        .sort(generateCompareFn('label')),
    [buildings]
  )

  return (
    <FilterWrapper
      label={<T>RoomLayout:controlRow.label.building</T>}
      width={`${spacing.gu(32)}rem`}
    >
      <ThemedMultiSelect
        loading={loading}
        modalWidth={`${spacing.gu(32)}rem`}
        noOptionsPlaceholder={<T>RoomLayout:controlRow.empty.noBuildings</T>}
        options={options}
        selectedValues={targetBuildingIds}
        setSelectedValues={setTargetBuildingIds}
        withSearch
        withSelectAll
      />
    </FilterWrapper>
  )
}
