import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'

type Props = {
  children: ReactNode
  onOutsideClick: (event: MouseEvent) => unknown
  style?: CSSProperties
}

export const OutsideClick = ({ children, onOutsideClick, style }: Props) => {
  const selfRef = useRef<HTMLDivElement | null>(null)
  const mouseDownRef = useRef<HTMLDivElement | null>(null)

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      const { target } = event

      if (
        selfRef.current &&
        target instanceof Node &&
        !selfRef.current.contains(target) &&
        !selfRef.current.contains(mouseDownRef.current)
      ) {
        onOutsideClick(event)
      }
    },
    [onOutsideClick]
  )

  const handleMouseDown = useCallback((event: MouseEvent) => {
    const { target } = event
    // @ts-ignore
    mouseDownRef.current = target
  }, [])

  useEffect(() => {
    // Bind the event listeners
    document.addEventListener('mousedown', handleMouseDown, true)
    document.addEventListener('mouseup', handleMouseUp, true)
    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener('mousedown', handleMouseDown, true)
      document.removeEventListener('mouseup', handleMouseUp, true)
    }
  }, [handleMouseDown, handleMouseUp])

  return (
    <div style={{ flex: 1, ...(style || {}) }} ref={selfRef}>
      {children}
    </div>
  )
}
