import { FC, ReactElement } from 'react'
import { ApolloError } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import {
  ContentPlaceholder,
  LoadingPlaceholder,
} from '@/components/Placeholders'
import { T } from '@/modules/Language'

import {
  LocalStoragePosition,
  SalesForDate,
  SalesForDateStats,
} from '../../types'
import { FocusedCustomer, ListHeader, ListItem } from './components'

interface Props {
  error?: ApolloError
  focusedSales: SalesForDate | null | undefined
  loading: boolean
  sales: SalesForDate[]
  salesStats: SalesForDateStats[]
  localStoragePosition: LocalStoragePosition | null
  setFocusedSalesId: (id: string | null) => void
  onSaleSectionsUpdate: (sections: (string | null)[]) => void
}

export const CustomerList: FC<Props> = ({
  error,
  focusedSales,
  loading,
  sales,
  salesStats,
  localStoragePosition,
  setFocusedSalesId,
  onSaleSectionsUpdate,
}): ReactElement => (
  <Wrapper>
    {focusedSales && (
      <FocusedItemWrapper>
        <FocusedCustomer
          data={focusedSales}
          customerData={
            salesStats.find(({ id }) => focusedSales.id === id)?.customer
              ?.customer
          }
          salesStats={
            salesStats.find(({ id }) => focusedSales.id === id)?.visitStats
          }
          onClose={() => setFocusedSalesId(null)}
          localStoragePosition={localStoragePosition}
          onSaleSectionsUpdate={onSaleSectionsUpdate}
        />
      </FocusedItemWrapper>
    )}
    {loading ? (
      <LoadingPlaceholder flex={1} size="xl" transparent />
    ) : error ? (
      <ContentPlaceholder size="lg">
        <T>FrontDesk:DailyCustomerList.error</T>
      </ContentPlaceholder>
    ) : !sales.length ? (
      <ContentPlaceholder size="lg">
        <T>FrontDesk:DailyCustomerList.empty</T>
      </ContentPlaceholder>
    ) : (
      <ListWrapper>
        <ListHeader />
        {sales.map((data) => {
          const saleStats = salesStats.find(({ id }) => id === data.id)

          return (
            <ListItem
              data={data}
              salesStats={saleStats?.visitStats}
              key={`daily-customer-${data.id}`}
              customerData={saleStats?.customer?.customer}
              onOpen={() => setFocusedSalesId(data.id)}
            />
          )
        })}
      </ListWrapper>
    )}
  </Wrapper>
)

////////////

const FocusedItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  z-index: 2;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`

const ListWrapper = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
  z-index: 1;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(3)}rem ${theme.spacing.gu(4)}rem
      ${theme.spacing.gu(10)}rem;
  `}
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`
