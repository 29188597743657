import { CreateResourceReservationMutation as Mutation } from '~generated-types'

import {
  Reservation,
  ResourceReservation,
  ResourceReservationTask,
} from '../types'

enum OverlappingErrorType {
  Error = 'ERROR',
  Warning = 'WARNING',
}

type OverlappingPayload = Mutation['resourceReservationCreate']

type Props = {
  payload?: OverlappingPayload
  onUpdate: (reservation: ResourceReservation) => void
}

export const overlappingHandler = ({ payload, onUpdate }: Props) => {
  if (!payload) {
    return null
  }

  switch (payload.__typename) {
    case 'ReservationPayloadOk':
      if (payload.reservation) {
        onUpdate(payload.reservation)

        return payload.reservation
      }
      return null
    case 'ReservationAvailabilityError':
      return {
        message: payload.message,
        type: OverlappingErrorType.Error,
      }
    case 'ReservationAvailabilityWarning':
      return {
        message: payload.message,
        type: OverlappingErrorType.Warning,
      }
    default:
      return null
  }
}

export const updateReservation = (
  reservations: Reservation[],
  newReservation: ResourceReservation | null
) =>
  reservations.map((r) =>
    r.id === newReservation?.id ? newReservation || r : r
  )

export const updateReservationTask = (
  reservations: Reservation[],
  reservationId: string,
  taskId: string,
  newTask: ResourceReservationTask | null
) =>
  reservations.map((r) =>
    r.id === reservationId
      ? {
          ...r,
          tasks: (r as ResourceReservation).tasks.map((t) =>
            t.id === taskId ? newTask || t : t
          ),
        }
      : r
  )
