import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Textarea } from '@/components/FormControls'

export const DataTableTextarea = ({
  onBlur,
  onFocus,
  ...props
}: {
  [key: string]: any
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  return (
    <TextareaWrapper>
      <TextareaM
        onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(false)
          onBlur && onBlur(event)
        }}
        onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
          setHasFocus(true)
          onFocus && onFocus(event)
        }}
        readOnly={!hasFocus}
        {...props}
      />
    </TextareaWrapper>
  )
}

////////////

const TextareaWrapper = styled.form`
  display: flex;
  justify-content: stretch;
  flex: 1;

  & > * {
    min-width: 100%;
    max-width: 100%;
  }
`

const TextareaM = styled(Textarea)<{ background: string }>`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
    `}

    text-align: inherit;

    border-color: transparent;
    border-radius: 4px;

    height: 1.75rem;
    max-height: 1.75rem;
    min-height: 1.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      height: auto;
      max-height: none;
      min-height: 5rem;
      white-space: pre-wrap;
      overflow: auto;
    }

    ${({ background }) =>
      background &&
      css`
        background: ${background};
      `}
  }
`
