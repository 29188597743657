import { gql } from '@apollo/client'

export default gql`
  mutation SetOrganizationCustomerDefaultContact(
    $input: CustomerOrganizationSetDefaultContactInput!
  ) {
    customerOrganizationSetDefaultContact(input: $input) {
      customer {
        defaultContactId
        id
      }
    }
  }
`
