import { gql, useMutation } from '@apollo/client'

import type {
  UpdateSalesProductItemRuleMutation,
  UpdateSalesProductItemRuleMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productRule}

  mutation UpdateSalesProductItemRule($input: ProductRuleUpdateInput!) {
    salesProductItemUpdateRule(input: $input) {
      ...ProductRule
    }
  }
`

export const useUpdateSalesProductItemRuleMutation = () =>
  useMutation<
    UpdateSalesProductItemRuleMutation,
    UpdateSalesProductItemRuleMutationVariables
  >(MUTATION)
