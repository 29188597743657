import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { OrderType as Order } from '@/modules/Order/types'
import { formatCurrency } from '@/utils/currency'

import { useSalesDetailsContext } from '../../../../SalesDetailsState'

type Props = {
  estimation: number
}

export const Totals = ({ estimation }: Props) => {
  const { ordersById } = useSalesDetailsContext()

  const saleTotal = getOrdersTotal(Object.values(ordersById))
  const priceFormatted = formatCurrency(saleTotal)

  return (
    <Wrapper flex={1.5}>
      <Label>
        <T>SalesDetails:Settings.probability.income</T>
      </Label>

      <ValueWrapper>
        {formatCurrency(Number(((saleTotal * estimation) / 100).toFixed(2)))} €{' '}
        {estimation !== 100 && (
          <FontColor lighter>({priceFormatted} €)</FontColor>
        )}
      </ValueWrapper>
    </Wrapper>
  )
}

const getOrdersTotal = (orders: Order[]) =>
  +orders
    ?.reduce(
      (acc: number, order: Order) =>
        acc + order.paymentInfo.totalPrice.amountVatIncluded,
      0
    )
    .toFixed(2)

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`

const ValueWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`
