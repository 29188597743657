import { gql } from '@apollo/client'

export default gql`
  query SalesAccommodationGroups($id: ID!) {
    sales(id: $id) {
      accommodation {
        accommodationGroups {
          id
          name
          sortOrder
        }
        id
      }
      id
    }
  }
`
