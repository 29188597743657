import moment, { HTML5_FMT, Moment } from 'moment'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

interface UseRouteDateHook {
  date: Moment
  isDefaultDate: boolean
  setDate: (targetDate: Moment) => void
}

const useRouteDate = (): UseRouteDateHook => {
  const history = useHistory()
  const { search } = useLocation()
  const { path } = useRouteMatch()
  const { date: searchDate, ...searchRest } = parse(search)

  const isDateString = searchDate && typeof searchDate === 'string'

  if (isDateString && !moment(searchDate).isValid()) {
    const nextSearch = stringify({ ...searchRest }).trim()

    history.replace(
      nextSearch ? `${path}?${stringify({ ...searchRest })}` : path
    )
  }

  const date: Moment =
    isDateString && moment(searchDate).isValid() ? moment(searchDate) : moment()

  const handleSetDate = (targetDate: Moment) => {
    history.replace(
      `${path}?${stringify({
        ...parse(search),
        date: targetDate.format(HTML5_FMT.DATE),
      })}`
    )
  }

  return {
    date,
    isDefaultDate: !isDateString,
    setDate: handleSetDate,
  }
}

export default useRouteDate
