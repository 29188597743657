import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { HTML5_FMT } from 'moment'
import styled, { css } from 'styled-components/macro'

import { FetchStates } from '@/common/types'
import { Button } from '@/components/Button'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ContentPlaceholder,
  LoadingPlaceholder,
} from '@/components/Placeholders'
import PrintButton from '@/components/PrintButton'
import { Description, H2 } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useRoomLayoutContext } from '@/modules/Reservations/components/RoomLayout'
import { useTheme } from '@/theme'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { Week } from '../../../types'
import { PrintRoomGroup } from '../components'

interface PrintCalendarProps {
  week: Week
  onClose: () => void
}

export const PrintCalendar = ({ week, onClose }: PrintCalendarProps) => {
  const theme = useTheme()

  const {
    reservations,
    reservationsStatus,
    roomGroups,
    roomGroupsStatus,
    targetCalendarDateRange,
  } = useRoomLayoutContext()

  const { isoWeek, isoWeekYear } = week

  const isEmpty =
    roomGroupsStatus === FetchStates.EMPTY ||
    reservationsStatus === FetchStates.EMPTY

  const isError =
    roomGroupsStatus === FetchStates.ERROR ||
    reservationsStatus === FetchStates.ERROR

  const isLoading =
    roomGroupsStatus === FetchStates.LOADING ||
    reservationsStatus === FetchStates.LOADING

  const getReservationsByWeek = (isoWeek: number, isoWeekYear: number) => {
    return reservations.filter(({ request }) => {
      const checkIn = moment(request.checkIn.date)
      const checkOut = moment(request.checkOut.date)

      return (
        (checkIn.isoWeek() === isoWeek &&
          checkIn.isoWeekYear() === isoWeekYear) ||
        (checkOut.isoWeek() === isoWeek &&
          checkOut.isoWeekYear() === isoWeekYear)
      )
    })
  }

  return (
    <Wrapper>
      <FlexRow
        alignItems="flex-start"
        hideFromPrint
        justifyContent="space-between"
        style={{ marginBottom: theme.spacing.gutterBig }}
      >
        <FlexColumn>
          <H2 style={{ marginBottom: 0 }}>
            <T>RoomLayout:PrintCalendar.title</T>
          </H2>
          <Description>
            {formatDateRangeWithoutRepetition(
              targetCalendarDateRange.from.format(HTML5_FMT.DATE),
              targetCalendarDateRange.to.format(HTML5_FMT.DATE)
            )}
          </Description>
        </FlexColumn>

        <FlexRow>
          <PrintButton disabled={isError || isLoading} />
          <Button onClick={onClose} size="small">
            <FontAwesomeIcon
              icon="xmark"
              style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
            />
            <T>RoomLayout:PrintCalendar.action.close</T>
          </Button>
        </FlexRow>
      </FlexRow>

      {isLoading ? (
        <LoadingPlaceholder size="xl" />
      ) : isError ? (
        <ContentPlaceholder size="xl">
          <T>RoomLayout:PrintCalendar.error</T>
        </ContentPlaceholder>
      ) : isEmpty || !roomGroups.length ? (
        <ContentPlaceholder size="xl">
          <FlexColumn>
            <T>RoomLayout:PrintCalendar.empty</T>
            <Button
              onClick={() => onClose()}
              size="big"
              style={{ marginTop: theme.spacing.gutterBig }}
            >
              <T>RoomLayout:PrintCalendar.action.editFilters</T>
            </Button>
          </FlexColumn>
        </ContentPlaceholder>
      ) : (
        roomGroups.map(({ building, groupId, rooms }) => (
          <PrintRoomGroup
            key={`room-group-${groupId}-${isoWeekYear}W${isoWeek}`}
            isoWeek={isoWeek}
            isoWeekYear={isoWeekYear}
            reservations={getReservationsByWeek(isoWeek, isoWeekYear)}
            rooms={rooms}
            title={building}
          />
        ))
      )}
    </Wrapper>
  )
}

export default PrintCalendar

////////////

const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(3)}rem auto;
    padding: 0 ${theme.spacing.gu(5)}rem ${theme.spacing.gu(10)}rem;
  `}

  @media print {
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
  }
`
