import { generateCompareFn } from '@/utils/arrays'

import { MobileSalesParticipant } from '../types'

type ParticipantsByAgeCategory = {
  amount: number
  ageCategory: MobileSalesParticipant['ageCategory']
}

export const getParticipantsByAgeCategory = (
  participants: MobileSalesParticipant[]
) =>
  participants
    .reduce((acc: ParticipantsByAgeCategory[], p) => {
      const currentAgeCategory = acc.find(
        ({ ageCategory }) => ageCategory?.key === p.ageCategory?.key
      )

      if (currentAgeCategory) {
        acc = acc.map((a) =>
          a.ageCategory?.key === p.ageCategory?.key
            ? { ...a, amount: a.amount + 1 }
            : a
        )
      } else {
        acc = [...acc, { ageCategory: p.ageCategory, amount: 1 }]
      }

      return acc
    }, [])
    .sort(generateCompareFn('sortOrder'))
