import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { Payment } from '@/modules/Order/types'

import { Section } from '../components'
import { PaymentRow } from './components'

type Props = {
  invoiceId: string
  openPaymentsModal: () => void
  orderId: string
  payments: Payment[]
  sellerId?: string
}

export const Payments = ({
  invoiceId,
  openPaymentsModal,
  orderId,
  payments,
  sellerId,
}: Props) => (
  <Section>
    <Table>
      {payments
        .sort(
          (a, b) =>
            moment(a.auditLog.createdAt).valueOf() -
            moment(b.auditLog.createdAt).valueOf()
        )
        .map((payment, index) => (
          <PaymentRow
            invoiceId={invoiceId}
            isFirst={!index}
            key={payment.id}
            openPaymentsModal={openPaymentsModal}
            orderId={orderId}
            payment={payment}
            sellerId={sellerId}
          />
        ))}
    </Table>
  </Section>
)

//////

export const Table = styled.div`
  border-radius: 8px;
  overflow: hidden;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
  `}
`
