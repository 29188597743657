import React, { ReactNode, useState } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { LinkLikeButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { List, ListContent, ListControls } from '@/components/List'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ListPlaceholder } from './ListPlaceholder'

interface Props<DataType> {
  allData: DataType[]
  columnCount: number
  error: any | null | undefined
  loading: boolean
  onAdd?: (...args: any[]) => void
  placeholders?: {
    empty?: ReactNode
    error?: ReactNode
  }
  renderListHeader: () => ReactNode
  renderListItem: (data: DataType) => ReactNode
  searchFilter: (searchValue: string) => (data: DataType) => boolean
}

export function FilterSearchList<DataType>({
  allData,
  columnCount,
  error,
  loading,
  onAdd,
  placeholders: { empty: emptyLabel, error: errorLabel } = {},
  renderListHeader,
  renderListItem,
  searchFilter,
}: Props<DataType>) {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [searchValue, setSearchValue] = useState<string>('')

  const renderData = (data: DataType[]) =>
    data.length ? (
      data.map((x) => renderListItem(x))
    ) : (
      <ListPlaceholder
        columnCount={columnCount}
        content={emptyLabel ? emptyLabel : <T>ElasticFilterSearchList:empty</T>}
        icon="circle-info"
      />
    )

  const renderError = () => (
    <ListPlaceholder
      columnCount={columnCount}
      content={errorLabel ? errorLabel : <T>ElasticFilterSearchList:error</T>}
      icon="circle-exclamation"
    />
  )

  const renderLoader = () => (
    <ListPlaceholder
      columnCount={columnCount}
      content={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ReactLoading type={'spin'} color={theme.palette.smoke.main} />
          <span style={{ marginTop: theme.spacing.gutter }}>
            <T>ElasticFilterSearchList:loading</T>
          </span>
        </div>
      }
    />
  )

  const data = allData.filter(
    !searchValue ? () => true : searchFilter(searchValue)
  )

  return (
    <ListWrapper noPadding>
      <ListControls style={{ alignItems: 'flex-end' }}>
        <FlexColumn style={{ alignItems: 'stretch', flex: 1 }}>
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
            placeholder={translate(`FilterSearchList:search`, language)}
            style={{ width: '200px' }}
            type="text"
            value={searchValue}
          />
        </FlexColumn>
        {onAdd && (
          <LinkLikeButton onClick={() => onAdd()}>
            + <T>FilterSearchList:add</T>
          </LinkLikeButton>
        )}
      </ListControls>
      <List>
        {renderListHeader()}
        <ListContent>
          {loading && !data.length
            ? renderLoader()
            : !!error
            ? renderError()
            : renderData(data)}
        </ListContent>
      </List>
    </ListWrapper>
  )
}

////////////

const ListWrapper = styled(FlexColumn)`
  max-width: 1400px;
  margin: auto;
`
