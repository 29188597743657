import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@kunukn/react-collapse'
import { darken } from 'polished'
import styled, { css, keyframes } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'

const glow = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

export const ChevronIcon = styled(FontAwesomeIcon)<{
  hover?: number
  loading?: number
  open: boolean
}>`
  transition: 0.25s;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    width: ${theme.spacing.gu(3)}rem;
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ open }) =>
    open &&
    css`
      transform: rotate(90deg);
    `}

  ${({ loading }) =>
    loading &&
    css`
      animation: ${glow} 1.5s infinite;
    `};

  ${({ hover, open, theme }) =>
    (hover || open) &&
    css`
      color: ${theme.palette.primary.main};
    `}
`

export const CollapseWrapper = styled(Collapse)`
  transition: height 0.5s;
  overflow: visible;

  ${({ theme }) => css`
    background: ${darken(0.015, theme.palette.smoke.extraLight)};
    box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.1);
  `}
`

export const ContentWrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    margin: ${theme.spacing.gu(2.5)}rem;
    border: 1px solid ${theme.palette.smoke.main};
  `}
`

export const HeaderLabel = styled.span<{ color?: string; width: string }>`
  font-weight: 500;

  ${({ color, theme, width }) => css`
    color: ${color || theme.palette.text.light};
    width: ${width};
  `}
`

export const HeaderRow = styled(FlexRow)`
  cursor: pointer;
  height: 40px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1.5)}rem;
    border-top: 1px solid ${theme.palette.smoke.main};
  `}
`

export const PriceLabel = styled(HeaderLabel)`
  text-align: right;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

export const Spacer = styled.div`
  flex: 1;
`
