import { IssueLevel } from '~generated-types'

import { Issue } from '../types'

export const getSectionIssueLevel = (issues?: Issue[]) =>
  issues?.reduce((acc: IssueLevel | undefined, { level }: Issue) => {
    if (level === IssueLevel.Error) {
      return level
    }

    if (level === IssueLevel.Warning && !acc) {
      return level
    }

    return acc
  }, undefined)
