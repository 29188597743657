import { CustomerIconValue } from './CustomerIconValue'

type Props = {
  email?: string | null
  phone?: string | null
}

export const CustomerContactDetails = ({ email, phone }: Props) => (
  <>
    <CustomerIconValue icon="at">{email || '—'}</CustomerIconValue>
    <CustomerIconValue icon="phone">{phone || '—'}</CustomerIconValue>
  </>
)
