import { CSSProperties, useState } from 'react'
import moment, { Moment } from 'moment'
import { DateUtils, Modifiers } from 'react-day-picker'
import styled, { css } from 'styled-components/macro'

import { translate, useLanguageContext } from '@/modules/Language'

import BasePicker, { BasePickerProps } from './components/BasePicker'

enum Target {
  From = 'FROM',
  To = 'TO',
}

export type DateRange = { from: Moment; to: Moment }

export type DateRangePickerProps = BasePickerProps & {
  disablePastDays?: boolean
  fromModal?: boolean
  maxWeekRange?: number
  setValue: (target: DateRange | null | undefined) => void
  style?: CSSProperties
  value: DateRange | null | undefined
}

const DateRangePicker = ({
  disablePastDays,
  fromModal,
  maxWeekRange,
  setValue,
  value,
  ...dayPickerProps
}: DateRangePickerProps) => {
  const { language } = useLanguageContext()

  const [nextTarget, setNextTarget] = useState<Target>(Target.From)

  const currentRange = !!value
    ? {
        from: value.from.toDate(),
        to: value.to.toDate(),
      }
    : undefined

  const handleOnDayClick = (day: Date) => {
    if (!currentRange) {
      return setValue({ from: moment(day), to: moment(day) })
    }

    if (DateUtils.isDayInRange(day, currentRange)) {
      if (nextTarget === Target.From) {
        setNextTarget(Target.To)

        return setValue({ from: moment(day), to: moment(currentRange.to) })
      } else {
        setNextTarget(Target.From)

        return setValue({ from: moment(currentRange.from), to: moment(day) })
      }
    }

    const targetDay = new Date(day)

    if (DateUtils.isDayBefore(day, currentRange.from)) {
      setNextTarget(Target.To)

      if (maxWeekRange) {
        const minValidDay = new Date(
          targetDay.setDate(targetDay.getDate() + maxWeekRange * 7 - 1)
        )

        if (DateUtils.isDayBefore(minValidDay, currentRange.to)) {
          return setValue({ from: moment(day), to: moment(day) })
        }
      }

      return setValue({ from: moment(day), to: moment(currentRange.to) })
    }

    setNextTarget(Target.From)

    if (maxWeekRange) {
      const maxValidDay = new Date(
        targetDay.setDate(targetDay.getDate() - maxWeekRange * 7 + 1)
      )

      if (DateUtils.isDayBefore(currentRange.from, maxValidDay)) {
        return setValue({ from: moment(day), to: moment(day) })
      }
    }

    return setValue({ from: moment(currentRange.from), to: moment(day) })
  }

  const disabledDays = {
    from: new Date(2000, 1, 1),
    to: new Date(Date.now() - 86400000),
  }

  return (
    <>
      {maxWeekRange && (
        <LightLabel fromModal={fromModal}>
          {translate('TimeControls:selectUpTo', language, {
            count: maxWeekRange,
          })}
        </LightLabel>
      )}

      <BasePicker
        disabledDays={disablePastDays ? disabledDays : []}
        initialMonth={currentRange?.from}
        modifiers={getModifiers(currentRange)}
        onDayClick={handleOnDayClick}
        selectedDays={currentRange}
        {...dayPickerProps}
      />
    </>
  )
}

export default DateRangePicker

//////

type Selection = {
  from: Date
  to: Date
}

const getModifiers = (selection?: Selection): Partial<Modifiers> =>
  selection
    ? {
        between: { after: selection.from, before: selection.to },
        from: selection.from,
        to: selection.to,
      }
    : {}

const LightLabel = styled.span<{ fromModal?: boolean }>`
  ${({ fromModal, theme }) => css`
    padding: ${fromModal
      ? `${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem 0`
      : `0 ${theme.spacing.gu(1)}rem`};
    color: ${theme.palette.text.lighter};
  `}
`
