import React, { createContext, ReactNode, useContext, useState } from 'react'

import { defaultLocale } from './config'
import { setLocale } from './utils'

type LanguageContextType = {
  switchLanguage: (arg0: string) => void
  language: string
}

export const LanguageContext = createContext<LanguageContextType>({
  language: 'fi',
  switchLanguage: () => {
    console.warn(
      'Using default context values. Did you forget to add a context provider?'
    )
  },
})

type Props = {
  children: ReactNode
}

export const LanguageContextProvider = ({ children }: Props) => {
  const [language, setLanguage] = useState<string>(defaultLocale)

  const switchLanguage = (language: string) => {
    setLocale(language).then(() => {
      setLanguage(language)
      localStorage.setItem('language', language)
    })
  }

  const value = {
    language,
    switchLanguage,
  }

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => useContext(LanguageContext)

export const useLanguageInState = () => {
  return useState<string>(useLanguageContext().language)
}
