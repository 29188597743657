import { MouseEvent, useEffect, useState } from 'react'

import { DangerColor, PrimaryColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModalFooter } from '@/components/InlineModal'
import { Right } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useCalendarState } from '@/modules/Reservations'
import {
  Reservation,
  ResourceReservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'

import { useReservationsGridState } from '../../../Calendar'
import { Context, Section } from '../../types'
import { GroupActions } from './GroupActions'
import { RemoveButton } from './RemoveButton'

type Props = {
  context: Context
  currentSection: Section
  onCloseModal: (event: MouseEvent) => void
  readOnly: boolean
  reservation: ResourceReservation
  setCurrentSection: (section: Section) => void
  updateReservations: (reservations: Reservation[]) => void
}

export const Footer = ({
  context,
  currentSection,
  onCloseModal,
  readOnly,
  reservation,
  setCurrentSection,
  updateReservations,
}: Props) => {
  const { group, id, resource, tasks } = reservation

  const { removeGroup, removeReservation, removeTask } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })
  const { refresh: refreshFocusedResources } = useCalendarState()
  const { refetchAvailabilities, updateCalendarWidthForModal } =
    useReservationsGridState()
  const { confirm } = useDialogService()

  const [isProcessing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    return () => setProcessing(false)
  }, [])

  const currentTask = tasks.find(({ id }) => id === currentSection.taskId)

  const confirmDelete = (deleteFn: () => Promise<void>, type: string) => {
    setProcessing(true)

    const handleDelete = () =>
      deleteFn()
        .catch(() => undefined)
        .finally(() => setProcessing(false))

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>{`ReservationModal:removalConfirmation.${type}`}</T>,
    })
      .then(handleDelete)
      .catch(() => setProcessing(false))
  }

  const handleDeleteGroup = (groupId: string, keepReservations: boolean) => {
    const deleteFn = () => removeGroup(groupId, keepReservations)

    return confirmDelete(deleteFn, 'group')
  }

  const handleDeleteReservation = () => {
    const deleteFn = () =>
      removeReservation(id).then(() => {
        context === 'BOTTOM_SHEET' && refreshFocusedResources()
        context !== 'SALES' && resource?.isPooled && refetchAvailabilities()
        updateCalendarWidthForModal(false)
      })

    return confirmDelete(deleteFn, 'reservation')
  }

  const handleDeleteTask = () => {
    const deleteFn = () =>
      removeTask(currentTask?.id || '', id).then(() =>
        setCurrentSection({ type: 'MAIN' })
      )

    return confirmDelete(deleteFn, 'task')
  }

  return (
    <InlineModalFooter justifyContent="flex-end">
      {currentSection.type === 'GROUP_MANAGER' && group && !readOnly && (
        <GroupActions
          handleDelete={handleDeleteGroup}
          groupId={group.id}
          isProcessing={isProcessing}
        />
      )}

      {currentSection.type === 'MAIN' && !readOnly && (
        <RemoveButton
          disabled={isProcessing}
          onClick={handleDeleteReservation}
          target="deleteReservation"
        />
      )}

      {currentSection.type === 'TASK_MANAGER' && !readOnly && (
        <RemoveButton
          disabled={isProcessing}
          onClick={handleDeleteTask}
          target="deleteTask"
        />
      )}

      <Right>
        <InnocuousButton onClick={onCloseModal}>
          <PrimaryColor>
            <T>common:action.ready</T>
          </PrimaryColor>
        </InnocuousButton>
      </Right>
    </InlineModalFooter>
  )
}
