import { gql } from '@apollo/client'

export const PaymentAgreementsQuery = gql`
  query PaymentAgreements {
    salesPaymentAgreements {
      code
      id
      name
    }
  }
`
