import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

type Props = {
  label?: ReactNode
  children: ReactNode
  width?: string
}

export const FilterWrapper = ({ children, label, width }: Props) => (
  <Wrapper width={width}>
    <Label>{label}</Label>
    {children}
  </Wrapper>
)

////////////

interface WrapperProps {
  width?: number | string
}

const Wrapper = styled(FlexColumn)<WrapperProps>`
  ${({ width }) => width && `width: ${width}`};
  padding: 0;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(3)}rem;
  `}
`

const Label = styled.span`
  flex: 0 0 16px;
  margin-bottom: 0.25rem;
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
