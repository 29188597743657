import { gql } from '@apollo/client'

export default gql`
  fragment ProductPurchaseLink on PurchaseProductLink {
    participant {
      firstName
      id
      lastName
    }
    reservation {
      id
      resourceQuantity
      resource {
        id
        isPooled
        name
      }
    }
    sales {
      id
      orderNumber
      type
    }
    task {
      id
      assignee {
        ... on UserGroup {
          id
          name
        }
      }
    }
  }
`
