import { gql, useQuery } from '@apollo/client'

import {
  PaymentTypeOptionInput,
  PaymentTypeOptionsQuery as QueryData,
  PaymentTypeOptionsQueryVariables as QueryVariables,
} from '~generated-types'

type PaymentTypeOption = QueryData['registry']['paymentTypeOptions'][0]

export const QUERY = gql`
  query PaymentTypeOptions($input: PaymentTypeOptionInput!) {
    registry {
      paymentTypeOptions(input: $input) {
        dynamicType {
          id
          name
        }
        enabled
        reason {
          code
          key
          level
        }
        type
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
  input: PaymentTypeOptionInput
  skip?: boolean
}

export const usePaymentTypeOptions = ({
  forceRefetch,
  input,
  skip,
}: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
      skip,
      variables: { input },
    }
  )

  const paymentTypeOptions: PaymentTypeOption[] = data
    ? data.registry.paymentTypeOptions
    : []

  return {
    error,
    loading,
    paymentTypeOptions,
    refetch,
  }
}
