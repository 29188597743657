import { Fragment } from 'react'
import { Moment } from 'moment'
import styled, { css } from 'styled-components'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ListingTitle } from '@/modules/Listing/common'

import { PrintSize } from '../../../types'
import { AllergyBy } from '../../usePacketMealListControls'
import {
  ListingMealType,
  ListingRestaurant,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { RestaurantSpecialAllergies } from './RestaurantSpecialAllergies'

type Props = {
  data: {
    [restaurantKey: string]: {
      [mealKey: string]: ListingScheduledMeal[]
    }
  }
  mealTypes: {
    [mealKey: string]: ListingMealType
  }
  printSize: PrintSize
  restaurants: ListingRestaurant[]
  date: Moment
  allergyBy: AllergyBy
}

export const DailyAllergies = ({
  data,
  mealTypes,
  printSize,
  restaurants,
  date,
  allergyBy,
}: Props) => {
  const mealsWidouthEmptyParticipants = Object.fromEntries(
    Object.entries(data)
      .map(([key, value]) => [
        key,
        Object.fromEntries(
          Object.entries(value).filter(([_, meals]) =>
            meals.some((meal) => meal.participants.length)
          )
        ),
      ])
      .filter(([_, value]) => Object.keys(value).length > 0)
  )

  return (
    <>
      {restaurants.map((restaurant) => (
        <Fragment key={`restaurant-special-alergy-${restaurant.id}`}>
          {mealsWidouthEmptyParticipants[restaurant.id] && (
            <FlexRow
              alignItems="center"
              justifyContent="space-between"
              style={{ pageBreakAfter: 'avoid' }}
            >
              <Title isFirst>{restaurant.name}</Title>
              <Title isFirst>
                <T>Listings:PacketMealListing.field.allergies</T>
                {' – '}
                {date.format('dd D.M.YYYY')}
              </Title>
            </FlexRow>
          )}
          <RestaurantSpecialAllergies
            data={mealsWidouthEmptyParticipants[restaurant.id] || {}}
            mealTypes={Object.keys(mealTypes).map((x) => mealTypes[x])}
            restaurant={restaurant}
            printSize={printSize}
            allergyBy={allergyBy}
          />
        </Fragment>
      ))}
    </>
  )
}

//////////////////////////

const Title = styled(ListingTitle)<{ isFirst?: boolean }>`
  ${({ isFirst }) => css`
    break-before: ${isFirst ? 'auto' : 'page'};
  `}

  @media print {
    margin-top: 0;
  }
`
