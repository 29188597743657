import { CategorySet } from '@/modules/Registry/Category'

export const getEmphasisedPaths = (set: CategorySet): string[] => {
  const paths: string[] = []
  let hasRootEmphasis = false

  const processCategories = (
    path: string,
    categories: {
      [key: string]: any
    }[]
  ) => {
    if (Array.isArray(categories)) {
      categories.forEach((x) => {
        if (x.isEmphasised) {
          if (!path) {
            hasRootEmphasis = true
          } else {
            paths.push(path)
          }
        }

        const nextPath = `${path}/${x.id}`
        processCategories(nextPath, x.children)
      })
    }
  }

  processCategories('', set.rootCategories)

  const pathsWithParents = paths.reduce((acc: any, val: any) => {
    const parts = val.split('/').slice(1)
    const allPaths: string[] = []

    parts.forEach((x: any, idx: number) => {
      if (idx > 0) {
        allPaths.push(`${allPaths[idx - 1]}/${x}`)
      } else {
        allPaths.push(`/${x}`)
      }
    })

    return [...acc, ...allPaths]
  }, [])

  const uniquePaths = [...new Set(pathsWithParents)]

  return hasRootEmphasis ? ['root', ...uniquePaths] : uniquePaths
}
