import { gql, useMutation } from '@apollo/client'

import type {
  AddCatalogProductItemRuleMutation,
  AddCatalogProductItemRuleMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productRule}

  mutation AddCatalogProductItemRule($input: ProductItemAddRuleInput!) {
    catalogProductItemAddRule(input: $input) {
      ...ProductRule
    }
  }
`

export const useAddCatalogProductItemRuleMutation = () =>
  useMutation<
    AddCatalogProductItemRuleMutation,
    AddCatalogProductItemRuleMutationVariables
  >(MUTATION)
