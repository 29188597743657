import { gql } from '@apollo/client'

import {
  paymentOtherDetails,
  paymentReservationFeeDetails,
  paymentVoucherDetails,
} from '@/modules/Order/fragments/paymentDetails'

export const QUERY = gql`
  ${paymentReservationFeeDetails}
  ${paymentVoucherDetails}
  ${paymentOtherDetails}

  query InvoiceCosting($input: ID!) {
    registry {
      config {
        marginalTax
      }
    }
    invoice(id: $input) {
      order {
        id
        sales {
          id
          name
          orderNumber
          commissionRate
          searchDates {
            start
            end
          }
        }
      }
      customer {
        customerNumber
        name
        businessId
        phone
        email
        contactPerson
        type
      }
      totalWithCommission
      invoiceDate
      dueDate
      id
      invoiceNumber
      referenceNumber
      lifecycle {
        state
      }
      invoiceType
      seller {
        id
        name
      }
      paymentType
      paymentInfo {
        totalPrice {
          amount
          amountVatIncluded
          vatAmount
        }
      }
      payments {
        id
        amount
        type
        date
        number
        details {
          ... on PaymentOtherDetails {
            ...PaymentOtherDetails
          }
          ... on PaymentVoucherDetails {
            ...PaymentVoucherDetails
          }
          ... on PaymentReservationFeeDetails {
            ...PaymentReservationFeeDetails
          }
        }
      }
      items {
        id
        name
        dates {
          startDate
          startTime
          endDate
          endTime
        }
        quantity
        packetTotal {
          amount
          amountVatIncluded
          vatAmount
        }
        products {
          id
          name
          internalCost
          internalPurchase
          marginalTax {
            marginalTaxRate
            active
          }
          quantity
          commissionMode
          unitPrice {
            price
            priceVatIncluded
            priceVatExcluded
            type
            vat {
              id
              code
              vatRate
            }
          }
          totalPrice {
            amount
            amountVatIncluded
            vatAmount
          }
        }
      }
    }
  }
`
