import { gql, useMutation } from '@apollo/client'

import { ParticipantServiceFragment } from '@/modules/ParticipantsList/ParticipantsList.fragments'

import {
  SalesParticipantAddRoomMutation,
  SalesParticipantAddRoomMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantAddRoomMutation,
  SalesParticipantAddRoomMutationVariables,
}

export const SALES_PARTICIPANT_ADD_ROOM = gql`
  ${ParticipantServiceFragment}

  mutation SalesParticipantAddRoom($input: SalesParticipantAddRoomInput!) {
    salesParticipantAddRoom(input: $input) {
      service {
        ...ParticipantService
      }
    }
  }
`

export function useSalesParticipantAddRoom() {
  return useMutation<
    SalesParticipantAddRoomMutation,
    SalesParticipantAddRoomMutationVariables
  >(SALES_PARTICIPANT_ADD_ROOM)
}

export default useSalesParticipantAddRoom
