import React from 'react'
import { Moment } from 'moment'

import { DateRange } from '@/common/types'
import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal, InlineModalContent } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { DateRangePicker } from '@/components/TimeControls'
import { T } from '@/modules/Language'

type Dates = {
  end: Moment
  start: Moment
}

type Props = {
  handleChange: (dates: DateRange | null) => Promise<void>
  dates: Dates | null
  onUpdate: (dates: Dates | null) => void
  hasChanged: boolean
  clearDisabled?: boolean
  warningMessage?: React.ReactNode
}

export const EstimatedDatesModal = ({
  handleChange,
  dates,
  onUpdate,
  hasChanged,
  clearDisabled,
  warningMessage,
}: Props) => (
  <InlineModal>
    {warningMessage && (
      <InlineModalContent style={{ paddingBottom: 0 }}>
        {warningMessage}
      </InlineModalContent>
    )}
    <InlineModalContent>
      <FlexColumn alignItems="center">
        <DateRangePicker
          setValue={(value) => {
            const range = !!value
              ? {
                  end: value.to.set({ hour: 0 }),
                  start: value.from.set({ hour: 0 }),
                }
              : null

            onUpdate(range)
          }}
          value={dates && { from: dates.start, to: dates.end }}
        />
        {dates && (
          <Gutter type={[0, 1, 1]}>
            <InnocuousButton
              compact
              disabled={!hasChanged}
              onClick={() =>
                dates &&
                handleChange({
                  end: dates.end.format('YYYY-MM-DD'),
                  start: dates.start.format('YYYY-MM-DD'),
                })
              }
            >
              <PrimaryColor>
                <T>common:action.apply</T>
              </PrimaryColor>
            </InnocuousButton>
            <InnocuousButton
              compact
              disabled={clearDisabled}
              onClick={() => {
                handleChange(null)
                onUpdate(null)
              }}
            >
              <T>SalesDetails:EstimatedDates.clear</T>
            </InnocuousButton>
          </Gutter>
        )}
      </FlexColumn>
    </InlineModalContent>
  </InlineModal>
)
