import { gql } from '@apollo/client'

export const MealFragment = gql`
  fragment Meal on ScheduledMeal {
    duration
    id
    location {
      id
      name
    }
    meal {
      id
      name
    }
    notes
    quantities {
      quantity
      ageCategory {
        key
        name
        shortName
        sortOrder
      }
    }
    start
    status
  }
`
