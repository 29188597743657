import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { formatCurrency } from '@/utils/currency'

import {
  MobileSalesParticipant,
  MobileSalesPurchase,
} from '../../../../../../types'
import { Info, InfoTitle, InfoWrapper } from '../../../components'

type Props = {
  isRoomParticipant?: boolean
  participant: MobileSalesParticipant
  showPrices: boolean
}

export const ParticipantDetails = ({
  isRoomParticipant,
  participant: {
    accommodationRequest,
    additionalInfo,
    age,
    ageCategory,
    allergies,
    allergyDescription,
    diets,
    drugAllergyDescription,
    group,
    services,
  },
  showPrices,
}: Props) => {
  const products = services
    .map(({ purchaseProduct }) => purchaseProduct)
    .filter(Boolean) as MobileSalesPurchase[]

  return (
    <FlexColumn style={{ paddingLeft: isRoomParticipant ? '20px' : 0 }}>
      {!!(age ?? ageCategory?.name) && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.AGE</T>
          </InfoTitle>

          <Info>{age ?? ageCategory?.name}</Info>
        </InfoWrapper>
      )}

      {additionalInfo && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.NOTES</T>
          </InfoTitle>

          <Info>{additionalInfo}</Info>
        </InfoWrapper>
      )}

      {!!products.length && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.PRODUCT</T>
          </InfoTitle>

          {products.map(({ attributes, id, product, totalPrice }) => (
            <Info key={id}>
              <FlexRow flex={1} justifyContent="space-between">
                <span>
                  {attributes.quantity && `${attributes.quantity} × `}

                  <span>{product.name}</span>
                </span>

                {showPrices && (
                  <Price>
                    {formatCurrency(totalPrice.amountVatIncluded)} €
                  </Price>
                )}
              </FlexRow>
            </Info>
          ))}
        </InfoWrapper>
      )}

      {!!allergies.length && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.ALLERGIES</T>
          </InfoTitle>

          <Info>{allergies.join(', ')}</Info>
        </InfoWrapper>
      )}

      {allergyDescription && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.ALLERGY_DESCRIPTION</T>
          </InfoTitle>

          <Info>{allergyDescription}</Info>
        </InfoWrapper>
      )}

      {!!diets.length && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.DIETS</T>
          </InfoTitle>

          <Info>{diets.join(', ')}</Info>
        </InfoWrapper>
      )}

      {drugAllergyDescription && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.DRUG_ALLERGY_DESCRIPTION</T>
          </InfoTitle>

          <Info>{drugAllergyDescription}</Info>
        </InfoWrapper>
      )}

      {accommodationRequest && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.REQUEST</T>
          </InfoTitle>

          <Info>{accommodationRequest}</Info>
        </InfoWrapper>
      )}

      {group && (
        <InfoWrapper>
          <InfoTitle>
            <T>SalesDetails:PrintSale.label.GROUP</T>
          </InfoTitle>

          <Info>{group}</Info>
        </InfoWrapper>
      )}
    </FlexColumn>
  )
}

//////

const Price = styled.span`
  font-weight: 600;
  text-wrap: nowrap;
  margin-top: 1px;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    padding-left: ${theme.spacing.gu(1)}rem;
  `};
`
