import { gql } from '@apollo/client'

import { publishFragments } from '../fragments'

export default gql`
  ${publishFragments.publicRoom}

  query AccommodationTargetsForPublish($salesId: ID!) {
    sales(id: $salesId) {
      accommodation {
        accommodationTargets {
          default
          id
          name
          nonBlockingCapacity
          publicRooms {
            ...PublicRoom
          }
          sortOrder
        }
        id
      }
      id
    }
  }
`
