import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { ActionDropdown } from '@/components/ActionDropdown'
import { Button } from '@/components/Button'
import { translate, useLanguageContext } from '@/modules/Language'

import { PendingRoomReservation } from '../SalesReservationManager.types'

type Props = {
  pendingReservations: PendingRoomReservation[]
  handleChangeReservationRoom: (
    roomId: string,
    roomNumber: string
  ) => Promise<void>
}

export const ReservationRoomSelector = ({
  pendingReservations,
  handleChangeReservationRoom,
}: Props) => {
  const { language } = useLanguageContext()

  const reservationsOptions =
    pendingReservations.map((reservation) => ({
      disabled: false,
      label: `#${reservation.roomNumber}`,
      onClick: () =>
        handleChangeReservationRoom(reservation.roomId, reservation.roomNumber),
    })) || null

  const noOptions = [
    {
      disabled: true,
      label: translate(
        'Accommodation:AvailabilityCalendar.noRendingRooms',
        language
      ),
      onClick: () => undefined,
    },
  ]

  return (
    <ActionDropdown
      items={!!reservationsOptions?.length ? reservationsOptions : noOptions}
      renderToggle={({ toggleDropdown }) => (
        <IconButton color="transparent" onClick={toggleDropdown} size="small">
          <FontAwesomeIcon icon={'retweet'} size="lg" />
        </IconButton>
      )}
    />
  )
}

////////////

const IconButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`
