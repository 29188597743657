import styled, { css } from 'styled-components/macro'

export const FormFields = styled.div`
  display: flex;
  flex-direction: 'column';
  flex-wrap: wrap;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gutterBig};
  `}

  &:last-child {
    ${({ theme }) => css`
      margin-bottom: -${theme.spacing.gutter};
    `}
  }
`
