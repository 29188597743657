import { CSSProperties } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ControlProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  NationalitiesRegistryQuery,
  NationalitiesRegistryQueryVariables,
} from '~generated-types'

type Props = {
  title: string
  countryName: string | null
  handleSetCountry: (countryName: string) => void
}

export const CountrySelector = ({
  title,
  countryName,
  handleSetCountry,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { language } = useLanguageContext()

  const [loadNationalities, { data }] = useLazyQuery<
    NationalitiesRegistryQuery,
    NationalitiesRegistryQueryVariables
  >(ParticipantsListQueries.NATIONALITIES_REGISTRY, {
    fetchPolicy: 'cache-first',
  })

  const frequentlyUsed =
    data?.registry.countries
      .filter(({ frequentlyUsed }) => frequentlyUsed)
      .sort(generateCompareFn('name'))
      .map(({ name }) => ({
        isDisabled: false,
        label: name,
        searchValue: name,
        value: name,
      })) || []

  const otherOptions =
    data?.registry.countries
      .filter(({ frequentlyUsed }) => !frequentlyUsed)
      .sort(generateCompareFn('name'))
      .map(({ name }) => ({
        isDisabled: false,
        label: name,
        searchValue: name,
        value: name,
      })) || []

  const groupedOptions = [
    {
      label: '',
      options: [
        {
          disabled: false,
          label: translate(
            'ParticipantsList:ParticipantFormFields.notSelected',
            language
          ),
          value: '',
        },
      ],
    },
    {
      label: <T>ParticipantsList:PersonalFields.frequentlyUsed</T>,
      options: frequentlyUsed,
    },
    {
      label: <T>ParticipantsList:PersonalFields.countries</T>,
      options: otherOptions,
    },
  ]

  const extraStyles = {
    container: (styles: CSSProperties) => ({
      ...styles,
      fontWeight: 400,
      marginBottom: spacing.gutter,
      width: '100%',
    }),
    control: (
      styles: CSSProperties,
      { isFocused }: ControlProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      borderColor: isFocused ? palette.primary.main : palette.smoke.dark,
      cursor: 'pointer',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
  }

  return (
    <FlexColumn style={{ width: '100%' }}>
      <Title>{title}</Title>
      <ThemedSelect
        isClearable
        extraStyles={extraStyles}
        name="country-selector"
        onChange={(selected: Option | null | undefined) =>
          handleSetCountry(selected ? selected.value : '')
        }
        onMenuOpen={loadNationalities}
        options={groupedOptions}
        isSearchable
        placeholder=""
        value={
          countryName
            ? {
                label: countryName,
                value: countryName,
              }
            : null
        }
      />
    </FlexColumn>
  )
}

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
