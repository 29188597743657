import classNames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { T, useLanguageContext } from '@/modules/Language'
import { TD } from '@/modules/Listing/common'
import { SalesTypeChip } from '@/modules/Sales'

import {
  ShowReservationGroups,
  SortOption,
} from '../../CalendarResourceListingContainer'
import { Resource, TaskDetails } from '../Cells'
import { GroupReservationsRow, TaskSubRow } from '../Rows'
import {
  ListingReservation,
  ListingReservationTask,
} from '../useReservationList'
import { parseCustomerName, resourceCompareFn, taskCompareFn } from '../utils'

type Props = {
  isLast: boolean
  reservation: ListingReservation
  showAllTasks: boolean
  showExtendedTasks: boolean
  showReservationGroups: ShowReservationGroups
  sortBy: SortOption
  viewAssigneeSelection: string[]
}

export const ReservationSectionRow = ({
  isLast,
  reservation,
  showAllTasks,
  showExtendedTasks,
  showReservationGroups,
  sortBy,
  viewAssigneeSelection,
}: Props) => {
  const { language } = useLanguageContext()

  const {
    group,
    id,
    start,
    end,
    tasks,
    sales,
    internalNote,
    description,
    dimensions,
  } = reservation

  const filterTasksByAssigneeSelection = ({
    assignee,
  }: ListingReservationTask) =>
    viewAssigneeSelection.includes(assignee?.id || '')

  const isReservationHasSelectedTasks = !!tasks.filter(
    filterTasksByAssigneeSelection
  ).length

  const tasksForRender =
    showAllTasks && isReservationHasSelectedTasks
      ? tasks
      : tasks.filter(filterTasksByAssigneeSelection)

  const isGroupReservation =
    showReservationGroups !== 'hidden' &&
    !!group &&
    group?.reservations.length > 1

  const groupReservations =
    showReservationGroups !== 'hidden'
      ? group?.reservations.filter(
          ({ id: groupReservationId }) => id !== groupReservationId
        ) || []
      : []

  const subrowCount =
    groupReservations.reduce((acc, r) => {
      const isReservationHasSelectedTasks = !!r.tasks.filter(
        filterTasksByAssigneeSelection
      ).length

      const groupReservationTasks =
        showAllTasks && isReservationHasSelectedTasks
          ? r.tasks
          : r.tasks.filter(filterTasksByAssigneeSelection)

      return showExtendedTasks
        ? acc + 1 + groupReservationTasks.length
        : acc + 1
    }, 0) + (showExtendedTasks ? tasksForRender.length : 0)

  return (
    <>
      <tr
        className={classNames({
          'dashed-bottom-border': showExtendedTasks && !!tasksForRender.length,
          lastGroupRow: isLast && isGroupReservation,
        })}
      >
        {isGroupReservation && (
          <TD className="groupheader" noWrap rowSpan={subrowCount + 1}>
            <span>
              <T>Listings:CalendarResourceListing.group</T>
            </span>
          </TD>
        )}
        <TD colSpan={isGroupReservation ? 1 : 2} noWrap>
          <Resource reservation={reservation} showProduct={true} />
        </TD>
        <TD noWrap>
          {moment(start).format('HH:mm')} – {moment(end).format('HH:mm')}
        </TD>
        <TD>{dimensions?.selection[0]?.selectedLabel?.name || '–'}</TD>
        <TD>{description || '–'}</TD>
        <TD>{internalNote || '–'}</TD>
        <TD>{sales.seller?.shortName || '–'}</TD>
        <TD>{parseCustomerName(sales)}</TD>
        <TD>
          <StyledLink to={`/sales/details/${sales.id}`}>
            {sales.orderNumber && `#${sales.orderNumber} `}
            {sales.name && sales.orderNumber && '– '}
            {sales.name}
          </StyledLink>
        </TD>
        <TD>
          <SalesTypeChip
            facet={sales.facet}
            format="short"
            size="xs"
            type={sales.type}
          />
        </TD>
        <TD style={{ textAlign: 'center' }}>
          {sales.estimatedParticipants || '–'}
        </TD>
        {!showExtendedTasks && (
          <TD>
            <TasksWrapper>
              {!tasksForRender.length
                ? '–'
                : [...tasksForRender]
                    .sort(taskCompareFn)
                    .map((task) => (
                      <TaskDetails
                        key={task.id}
                        reservationDate={moment(start)}
                        task={task}
                      />
                    ))}
            </TasksWrapper>
          </TD>
        )}
      </tr>
      {showExtendedTasks &&
        [...tasksForRender]
          .sort(taskCompareFn)
          .map((task, index) => (
            <TaskSubRow
              key={`${id}-${task.id}`}
              indent={isGroupReservation ? 1 : 2}
              isLast={index === tasksForRender.length - 1}
              reservationDate={moment(start)}
              task={task}
            />
          ))}

      {groupReservations.sort(resourceCompareFn(sortBy, language)).map((x) => {
        const tasks = showExtendedTasks
          ? showAllTasks
            ? x.tasks
            : x.tasks.filter(({ assignee }) =>
                viewAssigneeSelection.includes(assignee?.id || '')
              )
          : []

        return [
          <GroupReservationsRow
            key={`${id}-sub-${x.id}`}
            reservation={x}
            showExtendedTasks={showExtendedTasks}
            isGroupReservation={isGroupReservation}
          />,
          ...[...tasks]
            .sort(taskCompareFn)
            .map((subResTask, index) => (
              <TaskSubRow
                key={`${id}-sub-${x.id}-${subResTask.id}`}
                indent={isGroupReservation ? 1 : 2}
                isLast={index === tasks.length - 1}
                reservationDate={moment(start)}
                task={subResTask}
              />
            )),
        ]
      })}
    </>
  )
}

////////////

const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  @media print {
    color: inherit;
    text-decoration: none;
  }
`
