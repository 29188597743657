import styled, { css } from 'styled-components/macro'

type LineLeaderProps = {
  fullHeight: boolean
}

export const LineLeader = ({ fullHeight }: LineLeaderProps) => (
  <LineLeaderWrapper fullHeight={fullHeight}>
    <LineLeaderDot />
  </LineLeaderWrapper>
)

const LineLeaderDot = styled.div`
  display: inline-block;
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  right: -3px;
  top: calc(50% - 2px);

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
  `}
`

const LineLeaderWrapper = styled.div<LineLeaderProps>`
  align-self: stretch;
  position: relative;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(3)}rem;
    margin-right: ${theme.spacing.gutter};
    width: ${theme.spacing.gu(3)}rem;
  `}

  &::after {
    content: ' ';
    position: absolute;
    height: ${({ fullHeight }) => (fullHeight ? '100%' : '50%')};
    width: 2px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    height: 2px;
    width: calc(100%);

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`
