// A utility function that converts a monetary sum to a string
// It makes sure that the string contains at least two decimal
// places.
export default function formatCurrency(
  value: number | null | undefined
): string {
  if (typeof value !== 'number') {
    return ''
  }

  const decimals = value.toString().split('.')[1] || []
  const withForcedDecimals =
    decimals.length !== 2 ? value.toFixed(2) : '' + value

  const [integer, fraction] = withForcedDecimals.split('.')

  return `${new Intl.NumberFormat('fi-FI').format(
    parseInt(integer, 10)
  )}.${fraction}`
}
