import {
  SalesForPrintMeal as Meal,
  SalesForPrintResourceReservation as Reservation,
  SalesForPrintResourceReservationWithTasks as ReservationWithTasks,
  SalesForPrintTask as Task,
} from '../../../../../types'

export enum ProgramTypeKey {
  Meal = 'MEAL',
  Reservation = 'RESERVATION',
  ReservationGroup = 'RESERVATION_GROUP',
  Task = 'TASK',
}

type ProgramType = { __type: ProgramTypeKey }

export type Group = {
  end: string
  id: string
  name: string | null
  reservations: ReservationWithTasks[]
  start: string
}

type MealWithType = Meal & ProgramType
type ReservationWithType = ReservationWithTasks & ProgramType
type GroupWithType = Group & ProgramType
type TaskWithType = Task & ProgramType & { start: string }

type ProgramByDate = {
  [dateKey: string]: (
    | GroupWithType
    | MealWithType
    | ReservationWithType
    | TaskWithType
  )[]
}

type Props = {
  meals: Meal[]
  reservations: Reservation[]
  showGroups: boolean
  showReservations: boolean
  showTasks: boolean
  tasks: Task[]
}

export const getProgramByDateKey = ({
  meals: inputMeals,
  reservations: inputReservations,
  showGroups,
  showReservations,
  showTasks,
  tasks: inputTasks,
}: Props) => {
  const meals: MealWithType[] = inputMeals.map((x) => ({
    ...x,
    __type: ProgramTypeKey.Meal,
  }))

  const tasks: TaskWithType[] = inputTasks
    .filter(({ reservation }) => !showReservations || !reservation)
    .map((x) => ({
      ...x,
      __type: ProgramTypeKey.Task,
      start: x.dueTime ? `${x.dueDate}T${x.dueTime}` : `${x.dueDate}T23:59:59`,
    }))

  const reservations = inputReservations.map((r) => ({
    ...r,
    __type: ProgramTypeKey.Reservation,
    tasks: inputTasks.filter(
      ({ reservation }) => showTasks && reservation?.id === r.id
    ),
  }))

  const reservationsById: { [id: string]: ReservationWithType } =
    reservations.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})

  const singleReservations = Object.values(reservationsById).filter(
    (x) => !showGroups || !x.group
  )

  const reservationGroupsById: { [id: string]: GroupWithType } =
    reservations.reduce(
      (acc, { group }) =>
        group
          ? {
              ...acc,
              [group.id]: {
                __type: ProgramTypeKey.ReservationGroup,
                end: group.customerEnd,
                id: group.id,
                name: group.name,
                reservations: group.reservations
                  .map((x) => reservationsById[x.id])
                  .filter((x) => !!x),
                start: group.customerStart,
              },
            }
          : acc,
      {}
    )

  const reservationGroups = showGroups
    ? Object.values(reservationGroupsById)
    : []

  const byDate = [
    ...meals,
    ...singleReservations,
    ...reservationGroups,
    ...tasks,
  ].reduce((acc: ProgramByDate, val) => {
    const dateKey = val.start ? val.start.substring(0, 10) : null

    if (!dateKey) return acc

    acc[dateKey] = acc[dateKey] || []
    acc[dateKey].push(val)

    return acc
  }, {})

  return byDate
}
