import {
  SpreadsheetTH as TH,
  SpreadsheetTHead as THead,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { T } from '@/modules/Language'

export const OrderItemsHead = () => (
  <THead>
    <TR>
      <TH forcedWidth={34} />

      <TH minWidth={260} style={{ paddingLeft: 0 }}>
        <T>Orders:OrderItems.title.product</T>
      </TH>

      <TH forcedWidth={170}>
        <T>Orders:OrderItems.title.participant</T>
      </TH>

      <TH align="right" forcedWidth={60}>
        <T>Orders:OrderItems.title.vatRate</T>
      </TH>

      <TH align="right" forcedWidth={120}>
        <T>Orders:OrderItems.title.unitPriceVatIncluded</T>
      </TH>

      <TH align="right" forcedWidth={100}>
        <T>Orders:OrderItems.title.unitPrice</T>
      </TH>

      <TH align="right" forcedWidth={80}>
        <T>Orders:OrderItems.title.quantity</T>
      </TH>

      <TH align="right" forcedWidth={120}>
        <T>Orders:OrderItems.title.vatAmount</T>
      </TH>

      <TH align="right" forcedWidth={100}>
        <T>Orders:OrderItems.title.totalPrice</T>
      </TH>

      <TH style={{ width: '10%' }} />
    </TR>
  </THead>
)
