import { gql } from '@apollo/client'

export default gql`
  fragment PurchaseForSalesPrint on PurchaseProduct {
    attributes {
      quantity
      source
    }
    id
    product {
      id
      name
    }
    totalPrice {
      amount
      amountVatIncluded
      vatAmount
    }
  }
`
