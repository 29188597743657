import { ReactNode, useEffect, useRef } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useTheme } from '@/theme'

import { useSalesDetailsContext } from '../SalesDetailsState'
import { ContentWrapper } from './ContentWrapper'
import { SectionErrorBoundary } from './SectionErrorBoundary'
import { SectionName } from './SectionName'

type Props = {
  sectionIcon: IconProp
  keyName: string
  children: ReactNode
}

export const SectionContainer = ({ sectionIcon, keyName, children }: Props) => {
  const { spacing } = useTheme()

  const ref = useRef<HTMLDivElement | null>(null)

  const { viewType, contentColors, scrollToComponent, setScrollToComponent } =
    useSalesDetailsContext()

  useEffect(() => {
    if (scrollToComponent !== keyName) {
      return
    }

    ref.current &&
      ref.current.scrollIntoView({
        behavior: 'smooth',
      })

    setScrollToComponent(null)
  }, [scrollToComponent])

  return (
    <>
      <div ref={ref} style={{ scrollMarginTop: spacing.gutterBig }} />
      <ContentWrapper
        withPadding={viewType === 'secondary'}
        style={{ minWidth: '1100px' }}
      >
        <SectionName
          icon={sectionIcon}
          keyName={keyName}
          color={contentColors[keyName]}
        />
        <SectionErrorBoundary>{children}</SectionErrorBoundary>
      </ContentWrapper>
    </>
  )
}
