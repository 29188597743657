export enum ColorLightness {
  Dark = -1,
  Darker = -2,
  ExtraDark = -3,
  ExtraDarker = -4,
  ExtraLight = 3,
  ExtraLighter = 4,
  Light = 1,
  Lighter = 2,
}
