import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { ReservationsForDateQuery as ReservationsForDate } from '~generated-types'

import { SaleInfo } from './SaleInfo'

type ReservationAccommodationGroup =
  ReservationsForDate['accommodationRoomReservations']['reservations'][0]['accommodationGroup']

interface Props<ReservationType> {
  reservations: ReservationType[]
  title: ReactNode | string
  renderSaleReservations: (reservations: ReservationType[]) => React.ReactNode
}

export const ReservationsContainer = <
  ReservationType extends { accommodationGroup: ReservationAccommodationGroup }
>({
  reservations: reservationsProps,
  title,
  renderSaleReservations,
}: PropsWithChildren<Props<ReservationType>>) => {
  const { palette, spacing } = useTheme()

  const reservationsSorted = useMemo(() => {
    const reservations = [...reservationsProps]

    return reservations
      .sort(generateCompareFn('accommodationGroup.sales.id'))
      .reduce((prev: ReservationType[][], reservation, i) => {
        if (
          !i ||
          reservation.accommodationGroup.sales.id !==
            prev[prev.length - 1][0]?.accommodationGroup.sales.id
        ) {
          return prev.concat([[reservation]])
        }
        prev[prev.length - 1].push(reservation)
        return prev
      }, [])
  }, [reservationsProps])

  return (
    <FlexColumn noPadding>
      <FlexRow>
        <Label>{title}</Label>

        <RoomIconWrapper>
          <FontAwesomeIcon
            color={palette.text.lighter}
            icon="door-open"
            size="sm"
          />
        </RoomIconWrapper>

        <CapacityIconWrapper>
          <FontAwesomeIcon color={palette.text.lighter} icon="bed" size="sm" />
        </CapacityIconWrapper>

        <span style={{ width: `${spacing.gu(1)}rem` }} />

        <CapacityIconWrapper>
          <FontAwesomeIcon
            color={palette.text.lighter}
            icon="couch"
            size="sm"
          />
        </CapacityIconWrapper>
      </FlexRow>

      {reservationsSorted.length ? (
        reservationsSorted.map((saleReservations) => (
          <FlexRow
            style={{ marginBottom: `${spacing.gu(1)}rem`, width: '100%' }}
            key={saleReservations[0].accommodationGroup.sales.id}
            alignItems="stretch"
          >
            <SaleInfo sale={saleReservations[0].accommodationGroup.sales} />

            <FlexColumn style={{ width: '100%' }}>
              {renderSaleReservations(
                [...saleReservations].sort(generateCompareFn('sortOrder'))
              )}
            </FlexColumn>
          </FlexRow>
        ))
      ) : (
        <FlexRow>
          <StyledP>
            <T>Accommodation:AvailabilityCalendar.noReservations</T>
          </StyledP>

          <RoomIconWrapper>–</RoomIconWrapper>

          <CapacityIconWrapper>–</CapacityIconWrapper>

          <span style={{ width: `${spacing.gu(1)}rem` }} />

          <CapacityIconWrapper>–</CapacityIconWrapper>
        </FlexRow>
      )}
    </FlexColumn>
  )
}

////////

const StyledP = styled.p`
  margin-top: 0;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(55.5)}rem;
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const Label = styled(StyledP)`
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const RoomIconWrapper = styled.div`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(6)}rem;
    margin: 0 ${theme.spacing.gutter};
  `}
`

const CapacityIconWrapper = styled.div`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(11)}rem;
  `}
`
