import React, { useState } from 'react'
import moment, { Moment } from 'moment-timezone'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { TimeInputControl, TimeValue } from '@/components/TimeControls'
import { DatePickerControl } from '@/components/TimeControls/'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { useTheme } from '@/theme'

type Props = {
  checkTimeValue: {
    date: moment.Moment
    time: {
      hour: string
      minute: string
    }
  }
  setCheckTime: (time: string | null) => void
  readOnly?: boolean
}

const CheckTime = ({
  checkTimeValue: { date, time },
  setCheckTime,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const { timezone } = useWillbaConfigContext()

  const [editCheckTime, setEditCheckTime] = useState(false)

  const { hour, minute } = time

  const handleSetDate = (value?: Moment | null) => {
    if (value) {
      const specifiedDate = moment(
        `${value?.format('YYYY-MM-DD')}T${hour}:${minute}:00`
      ).format()

      setCheckTime(
        moment(specifiedDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss')
      )
    }
  }

  const handleSetTime = (value?: TimeValue | null) => {
    if (value) {
      setEditCheckTime(false)

      const specifiedDate = moment(
        `${date.format('YYYY-MM-DD')}T${value.hour
          .toString()
          .padStart(2, '0')}:${value.minute.toString().padStart(2, '0')}:00`
      ).format()

      setCheckTime(
        moment(specifiedDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss')
      )
    }
  }

  return (
    <>
      <CheckTimeContainer>
        {readOnly ? (
          <ValuePlaceholder>
            {moment(date).tz(timezone).format('DD.MM.YYYY')}
          </ValuePlaceholder>
        ) : (
          <DatePickerControl
            render={({ date, ref, toggleModal }: any) => (
              <div ref={ref}>
                <StyledButton onClick={toggleModal}>
                  {moment(date).tz(timezone).format('DD.MM.YYYY')}
                </StyledButton>
              </div>
            )}
            setValue={handleSetDate}
            value={date}
          />
        )}
        {readOnly ? (
          <ValuePlaceholder>{`${hour}:${minute}`}</ValuePlaceholder>
        ) : editCheckTime ? (
          // @ts-ignore
          <TimeInputControl
            setValue={handleSetTime}
            value={
              time && {
                hour: Number(hour),
                minute: Number(minute),
              }
            }
          >
            {({ handleSubmit, inputValue, setInputValue }) => (
              <Input
                autoFocus
                onBlur={handleSubmit}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue(event.target.value)
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    handleSubmit()
                  }
                }}
                placeholder="—"
                style={{ height: `${spacing.gu(3)}rem`, width: 56 }}
                value={inputValue}
              />
            )}
          </TimeInputControl>
        ) : (
          <StyledButton onClick={() => setEditCheckTime(true)}>
            {`${hour}:${minute}`}
          </StyledButton>
        )}
      </CheckTimeContainer>
    </>
  )
}

export default CheckTime

const CheckTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button<{ forcedWidth?: string }>`
  border: 0px;
  border-radius: 0px;
  width: ${(props) => props.forcedWidth || '100%'};
  background: transparent;
  cursor: pointer;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(0.5)}rem !important;
    height: ${theme.spacing.gu(3)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const ValuePlaceholder = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(0.5)}rem;
  `}
`
