import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { useLanguageContext } from '@/modules/Language'
import { EnrollmentStateFilterType } from '@/modules/ParticipantsList'
import { salesHooks } from '@/modules/Sales/hooks'
import { getStateLabels } from '@/modules/Sales/utils'
import { generateCompareFn } from '@/utils/arrays'

import { CheckboxLabel, CheckboxWrapper } from './common'

type Props = {
  filters: EnrollmentStateFilterType[] | null
  setFilters: (filters: EnrollmentStateFilterType[] | null) => void
}

export const EnrollmentStateFilter = ({ filters, setFilters }: Props) => {
  const { language } = useLanguageContext()

  const { salesStates } = salesHooks.useSalesStates()

  const handleSelect = (targetFilter: EnrollmentStateFilterType) => {
    if (!filters) {
      return setFilters([targetFilter])
    }

    const newFilters = !!filters.find((f) => f.value === targetFilter.value)
      ? filters.filter((f) => f.value !== targetFilter.value)
      : [...filters, targetFilter]

    return setFilters(newFilters.length ? newFilters : null)
  }

  return (
    <FlexColumn noPadding>
      {[...(salesStates ?? [])]
        .sort(generateCompareFn('processOrder'))
        .map(({ key, names }) => (
          <CheckboxWrapper alignItems="center" key={key}>
            <CheckboxInput
              checked={!!filters?.find((v) => v.value === key)}
              noMargin
              onChange={() =>
                handleSelect({
                  label: getStateLabels(names)[language],
                  value: key,
                })
              }
            >
              <CheckboxLabel>{getStateLabels(names)[language]}</CheckboxLabel>
            </CheckboxInput>
          </CheckboxWrapper>
        ))}
    </FlexColumn>
  )
}
