import { gql, useMutation } from '@apollo/client'

import {
  ResourceReservationSetDisplayMessageMutation,
  ResourceReservationSetDisplayMessageMutationVariables,
} from '~generated-types'

import ResourceReservationFragment from '../fragments/resourceReservation'

export type {
  ResourceReservationSetDisplayMessageMutation,
  ResourceReservationSetDisplayMessageMutationVariables,
}

export const SET_RESOURCE_RESERVATION_DISPLAY_MESSAGE_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation ResourceReservationSetDisplayMessage(
    $input: ResourceReservationSetDisplayMessageInput!
  ) {
    resourceReservationSetDisplayMessage(input: $input) {
      ...ResourceReservation
    }
  }
`

export function useSetResourceReservationDisplayMessageMutation() {
  return useMutation<
    ResourceReservationSetDisplayMessageMutation,
    ResourceReservationSetDisplayMessageMutationVariables
  >(SET_RESOURCE_RESERVATION_DISPLAY_MESSAGE_MUTATION)
}

export default useSetResourceReservationDisplayMessageMutation
