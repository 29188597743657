import { gql, useMutation } from '@apollo/client'

import type {
  DeleteCatalogProductItemMutation,
  DeleteCatalogProductItemMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation DeleteCatalogProductItem($id: ID!) {
    catalogProductItemDelete(id: $id) {
      deleted
      id
    }
  }
`

export const useDeleteCatalogProductItemMutation = () =>
  useMutation<
    DeleteCatalogProductItemMutation,
    DeleteCatalogProductItemMutationVariables
  >(MUTATION)
