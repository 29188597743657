import styled, { css } from 'styled-components/macro'

export const InputActions = styled.span`
  flex-grow: 0;
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
  display: flex;

  ${({ theme }) => css`
    max-height: ${theme.spacing.gu(5)}rem;
  `}

  pointer-events: none;
`

export default InputActions
