import styled, { css } from 'styled-components/macro'

import Label from '../Label'

export const InlineLabel = styled(Label)`
  display: inline-flex;
  align-items: center;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.7)}rem;
    margin-right: ${theme.spacing.gutter};
  `}

  font-weight: 400;
  letter-spacing: inherit;

  &:last-child {
    margin-right: 0;
  }
`

export default InlineLabel
