import { CSSProperties, ReactNode } from 'react'

import { ListTable } from './common'

type Props = {
  children: ReactNode
  style?: CSSProperties
  width?: string
}

const List = ({ children, width, ...rest }: Props) => (
  <ListTable {...rest} width={width || '100%'}>
    {children}
  </ListTable>
)

export default List
