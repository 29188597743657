import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { Action, OtherPaymentModal } from '@/modules/Order/components'
import {
  InvoiceValidatedAction as IVA,
  OtherPaymentMethod as OPM,
} from '@/modules/Order/types'
import { useTheme } from '@/theme'

import { OrderPhaseType } from '~generated-types'

type Props = {
  action: IVA
  invoiceId: string
  orderId: string
  phase: OrderPhaseType
  sellerId?: string
}

export const OtherPaymentActions = ({
  action,
  invoiceId,
  orderId,
  phase,
  sellerId,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)
  const [otherPaymentMethod, setOtherPaymentMethod] = useState<OPM>(OPM.Payment)

  const handleSelectPaymentMethod = (method: OPM) => {
    setModalOpen(true)
    setOptionsModalOpen(false)
    setOtherPaymentMethod(method)
  }

  const renderOptionButton = (method: OPM) => (
    <Button
      compact
      textColor={
        method === OPM.Refund ? palette.danger.main : palette.primary.main
      }
      noMargin
      noNudge
      onClick={() => handleSelectPaymentMethod(method)}
    >
      <FontAwesomeIcon
        icon="euro-sign"
        size="sm"
        style={{ marginRight: `${spacing.gu(1)}rem` }}
      />
      <T>{`Orders:Payments.otherPaymentMethod.${method}`}</T>
    </Button>
  )

  return (
    <FlexRow>
      <ModalContainer
        placement="bottom-start"
        isOpen={isOptionsModalOpen}
        onClose={() => setOptionsModalOpen(false)}
        modal={
          <InlineModal>
            <OptionsWrapper>
              {renderOptionButton(OPM.Payment)}
              {renderOptionButton(OPM.Refund)}
            </OptionsWrapper>
          </InlineModal>
        }
        referenceElement={({ ref }) => (
          <Action
            innerRef={ref}
            onClick={() => setOptionsModalOpen(true)}
            phase={phase}
            validatedAction={action}
          />
        )}
      />

      {sellerId && (
        <ModalContainer
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          modal={
            <OtherPaymentModal
              invoiceId={invoiceId}
              method={otherPaymentMethod}
              onClose={() => setModalOpen(false)}
              orderId={orderId}
              sellerId={sellerId}
            />
          }
          referenceElement={() => null}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />
      )}
    </FlexRow>
  )
}

///////

const Button = styled(InnocuousButton)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  justify-content: flex-start;
`

const OptionsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
