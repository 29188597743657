import { createGlobalStyle, css } from 'styled-components/macro'

export const MarkdownCss = createGlobalStyle<{ disabled?: boolean }>`
  .markdown-wrapper p {
    ${({ theme }) => css`
      margin: 0 0 ${theme.spacing.gu(1)}rem;
    `}
  }

  .markdown-wrapper ul {
    ${({ theme }) => css`
      margin: 0 0 ${theme.spacing.gu(1)}rem;
      padding-left: ${theme.spacing.gutterBig};
    `}
  }

  .public-DraftStyleDefault-block {
    ${({ theme }) => css`
      margin: 0 0 ${theme.spacing.gu(0.5)}rem;
    `}
  }

  .public-DraftStyleDefault-ul {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0;
    `}
  }

  .rdw-editor-main {
    border-radius: 6px;
    
    ${({ theme }) => css`
      background: ${theme.palette.white};
      padding: 10px;
    `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
        cursor: not-allowed;
        color: ${theme.palette.text.light};
        background: ${theme.palette.smoke.extraLight};
      `}
  }

  .rdw-editor-toolbar.rdw-editor-toolbar {
    margin-bottom: 0;
    border: none;
    border-radius: 6px 6px 0 0;
    
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
      background: ${theme.palette.smoke.lighter};
      border-bottom: 1px solid ${theme.palette.smoke.main};
    `}
  }

  .rdw-editor-toolbar > div {
    margin-bottom: 0;
  }

  .rdw-editor-toolbar > div > div {
    ${({ disabled, theme }) =>
      disabled &&
      css`
        background: ${theme.palette.smoke.extraLight};
        cursor: not-allowed;

        &:hover {
          box-shadow: none;
        }
      `}
  }

  .rdw-inline-wrapper:first-child .rdw-option-wrapper:first-child {
    margin-left: 0;
  }

  .rdw-dropdown-optionwrapper {
    max-height: 100px;

    & > li:not(:first-child):not(:nth-child(4)) {
      display: none;
    }
  }
`
