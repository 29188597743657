import { gql, useMutation } from '@apollo/client'

import {
  SalesParticipantSetVisitStatusMutation,
  SalesParticipantSetVisitStatusMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantSetVisitStatusMutation,
  SalesParticipantSetVisitStatusMutationVariables,
}

export const SALES_PARTICIPANT_SET_VISIT_STATUS = gql`
  mutation SalesParticipantSetVisitStatus(
    $input: SalesParticipantSetVisitStatusInput!
  ) {
    salesParticipantSetVisitStatus(input: $input) {
      id
      visitStatus {
        isDayVisitor
        dayVisitorDates {
          start
          end
        }
      }
    }
  }
`

export function useSalesParticipantSetVisitStatus() {
  return useMutation<
    SalesParticipantSetVisitStatusMutation,
    SalesParticipantSetVisitStatusMutationVariables
  >(SALES_PARTICIPANT_SET_VISIT_STATUS)
}

export default useSalesParticipantSetVisitStatus
