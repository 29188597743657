import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'

import {
  AccommodationLevelAvailability,
  Mode,
} from '../../AvailabilityCalendar.types'
import {
  PrimaryDataRow,
  ScrollTableProps,
  TotalsHeaderRow,
} from './ScrollTable'
import { TotalsStatusRow } from './TotalsStatusRow'

type Props = ScrollTableProps & {
  data: AccommodationLevelAvailability[]
  mode: Mode
}

export const AvailabilityTotals = ({ data, mode, ...tableProps }: Props) => (
  <Wrapper>
    <TotalsHeaderRow rowHeight={tableProps.rowHeight}>
      <T>Accommodation:AvailabilityCalendar.totals</T>
    </TotalsHeaderRow>

    <PrimaryDataRow
      isExpanded={false}
      renderContent={() => (
        <TotalsStatusRow
          mode={mode}
          property="rooms"
          status={data.map(({ status }) => status)}
          {...tableProps}
        />
      )}
      renderHeader={() => (
        <PrimaryHeader bold>
          <T>Accommodation:AvailabilityCalendar.levelHeaders.rooms</T>
        </PrimaryHeader>
      )}
      {...tableProps}
    />

    <PrimaryDataRow
      isExpanded={false}
      renderContent={() => (
        <TotalsStatusRow
          mode={mode}
          property="beds"
          status={data.map(({ status }) => status)}
          {...tableProps}
        />
      )}
      renderHeader={() => (
        <T>Accommodation:AvailabilityCalendar.levelHeaders.beds</T>
      )}
      {...tableProps}
    />

    <PrimaryDataRow
      isExpanded={false}
      isLastRow
      renderContent={() => (
        <TotalsStatusRow
          mode={mode}
          property="extraBeds"
          status={data.map(({ status }) => status)}
          {...tableProps}
        />
      )}
      renderHeader={() => (
        <T>Accommodation:AvailabilityCalendar.levelHeaders.extraBeds</T>
      )}
      {...tableProps}
    />
  </Wrapper>
)

///////

const PrimaryHeader = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.coal.dark};
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    margin-top: ${theme.spacing.gu(8)}rem;
  `}
`
