import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { useTheme } from '@/theme'

type Props = {
  isRoomType: boolean
  isFullfilled: boolean
  isDisabled?: boolean
  handleSetFulfilledByRooms: () => Promise<void>
}

export const FullfilledCheckBox = ({
  isRoomType,
  isFullfilled,
  isDisabled,
  handleSetFulfilledByRooms,
}: Props) => {
  const [isHovered, setHovered] = useState(false)
  const theme = useTheme()

  return (
    <IconWrapper
      isDisabled={isDisabled}
      isHidden={!isRoomType}
      onClick={() => !isDisabled && handleSetFulfilledByRooms()}
      onMouseEnter={() => !isDisabled && setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isRoomType && (
        <FontAwesomeIcon
          icon={isHovered || isFullfilled ? 'check' : ['far', 'circle']}
          color={
            isDisabled
              ? theme.palette.smoke.dark
              : isHovered
              ? theme.palette.success.main
              : isFullfilled
              ? theme.palette.success.dark
              : theme.palette.primary.dark
          }
          size="sm"
        />
      )}
    </IconWrapper>
  )
}

const IconWrapper = styled.div<{
  isDisabled?: boolean
  isHidden?: boolean
}>`
  display: flex;
  justify-content: center;

  ${({ isHidden, isDisabled }) =>
    !isHidden &&
    css`
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    `}

  ${({ theme }) => css`
    width: ${theme.spacing.gutterBig};
    padding: ${theme.spacing.gu(1)}rem;
    margin-right: calc(${theme.spacing.gu(0.7)}rem + 1px);
  `}
`
