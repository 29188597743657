import { CSSProperties, ReactNode, Ref } from 'react'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Tooltip } from '@/components/Tooltip'

type ActionButtonProps = {
  content: ReactNode | string
  icon: IconProp
  iconColor: string
  iconSize?: SizeProp
  innerRef?: Ref<HTMLButtonElement>
  onClick: () => void | Promise<any>
  style?: CSSProperties
}

export const ActionButton = ({
  content,
  icon,
  iconColor,
  iconSize,
  innerRef,
  onClick,
  style,
}: ActionButtonProps) => (
  <Tooltip
    content={content}
    trigger={(triggerProps) => (
      <div {...triggerProps} style={style}>
        <IconButton onClick={onClick} ref={innerRef}>
          <FontAwesomeIcon
            color={iconColor}
            icon={icon}
            size={iconSize ?? 'sm'}
          />
        </IconButton>
      </div>
    )}
  />
)

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    height: ${theme.spacing.gu(4)}rem;
    width: ${theme.spacing.gu(4)}rem;
  `}

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &:disabled {
    cursor: not-allowed;

    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
    `}
  }
`

export const IconWrapper = styled.div`
  text-align: end;

  ${({ theme }) => css`
    width: ${theme.spacing.gutterBig};
  `}
`

export const EditButton = styled(IconButton)`
  justify-content: space-between;
  flex: 1;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
    min-height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1.5)}rem
      ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    width: calc(100% + ${theme.spacing.gu(1)}rem);
  `}

  &:disabled {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.guPx(1) - 2}px;
  `}
`

export const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
