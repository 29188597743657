import styled, { css } from 'styled-components/macro'

import { ValidationIndicator } from '@/components/ValidationIndicator'
import { SalesResourceReservationPurchase } from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import { Name, Price, Quantity } from './common'

type Props = {
  isPooled: boolean
  purchase?: SalesResourceReservationPurchase
}

export const ReservationPurchase = ({ purchase }: Props) => {
  if (!purchase) {
    return (
      <>
        <Name compactWidth={18} width={26} />

        <Quantity />

        <Price />
      </>
    )
  }

  const { spacing } = useTheme()

  const {
    // isPooled,
    product: { name },
    // quantityBreakdown: {
    //   durationUnit,
    //   quantity: { duration, resourceQuantity },
    // },
    status: { issues },
    totalPrice: { amountVatIncluded },
  } = purchase

  return (
    <>
      <Name compactWidth={18} width={26}>
        {issues.map(({ code, key, level }) => (
          <ValidationIndicatorWrapper key={`${key}-${code}`}>
            <ValidationIndicator
              code={code}
              level={level}
              style={{ margin: `0 ${spacing.gutterSmall}` }}
            />
          </ValidationIndicatorWrapper>
        ))}

        {name}
      </Name>

      <Quantity>
        {/* {durationUnit !== 'PIECE' && (
        <>
          {duration} <T>{`enums:quantityUnit.abbrev.${durationUnit}`}</T>
        </>
      )}

      {durationUnit !== 'PIECE' && isPooled && ' x '}

      {isPooled && (
        <>
          {resourceQuantity} <T>enums:quantityUnit.abbrev.PIECE</T>
        </>
      )} */}
      </Quantity>

      <Price>{formatCurrency(amountVatIncluded)} €</Price>
    </>
  )
}

/////

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
