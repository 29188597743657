import { Customer } from '@/modules/FrontDesk'

export const parseCustomerName = (customer: Customer) =>
  customer.__typename === 'CustomerOrganization'
    ? customer.organization.name
    : parseName({
        firstName: customer?.person.firstName || '',
        lastName: customer?.person.lastName || '',
      })

export const parseName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`
