import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { InputEditor, ToggleEditableValue } from '@/components/ToggleHelpers'
import { H3 } from '@/components/Typography'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import * as salesMutations from '@/modules/Sales/mutations'
import { useTheme } from '@/theme'

type Props = {
  salesId: string
  name: string | null
  orderNumber?: number | null
}

export const BasicDetails = ({ salesId, name, orderNumber }: Props) => {
  const { spacing, typography } = useTheme()

  const { data, saleReadOnly } = salesHooks.useSalesDetailsContext()

  const [changeName] = salesMutations.useUpdateSalesNameMutation()

  return (
    <>
      <Gutter type={[2, 0, 1]}>
        <FlexColumn alignItems="stretch" noPadding>
          <span style={{ marginBottom: 0, marginLeft: `${spacing.gu(1)}rem` }}>
            {data && orderNumber && `#${orderNumber}`}
          </span>
          {saleReadOnly ? (
            name ? (
              <ValueLabel style={{ padding: `0 ${spacing.gu(1)}rem` }}>
                {name}
              </ValueLabel>
            ) : (
              <Placeholder style={{ padding: `0 ${spacing.gu(1)}rem` }}>
                <T>{`SalesSearchList:ListItem.unnamed.${data.type}`}</T>
              </Placeholder>
            )
          ) : (
            <ToggleEditableValue
              mode="input"
              placeholder={<Placeholder>Lisää nimi...</Placeholder>}
              renderEditor={(onClose) => (
                <InputEditor
                  close={onClose}
                  handleSubmit={(newValue: string | null) =>
                    changeName({
                      variables: {
                        input: {
                          name: newValue,
                          salesId: salesId,
                        },
                      },
                    })
                  }
                  inputComponent={Input}
                  type="text"
                  value={name}
                  style={{
                    fontSize: typography.fontSizeBase2,
                    height: '25px',
                  }}
                />
              )}
              value={name ? <ValueLabel>{name}</ValueLabel> : null}
              style={{
                margin: `${spacing.gu(1)}rem`,
                padding: `0 ${spacing.gu(1)}rem`,
                width: '100%',
              }}
              valueWrapperStyle={{
                flex: 1,
                minWidth: '240px',
                width: '240px',
              }}
            />
          )}
        </FlexColumn>
      </Gutter>
    </>
  )
}

////////////

const ValueLabel = styled(H3)`
  margin: 0;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Placeholder = styled(ValueLabel)`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
