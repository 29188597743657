import { gql } from '@apollo/client'

export default gql`
  mutation CreateCustomerPerson($input: CustomerPersonCreateInput!) {
    customerPersonCreate(input: $input) {
      customer {
        customerNumber
        defaultAddressId
        id
      }
    }
  }
`
