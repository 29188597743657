import { gql } from '@apollo/client'

export default gql`
  fragment SalesWebshopContacts on Sales {
    id
    webshopSettings {
      id
      contacts {
        id
        name
      }
    }
  }
`
