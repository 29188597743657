import styled, { css } from 'styled-components/macro'

import { H3 } from '@/components/Typography'

export const ListingTitle = styled(H3)`
  @media print {
    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeBase2};
      margin-top: ${theme.spacing.gu(3)}rem;
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }
`
