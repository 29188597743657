import { gql, useQuery } from '@apollo/client'

import {
  PaymentTerminalsQuery as QueryData,
  PaymentTerminalsQueryVariables as QueryVariables,
} from '~generated-types'

type PaymentTerminal = QueryData['registry']['paymentTerminals'][0]

const QUERY = gql`
  query PaymentTerminals {
    registry {
      paymentTerminals {
        id
        name
        pointOfSale {
          id
          name
        }
        terminalConnected
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export const usePaymentTerminals = (params?: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
    }
  )

  const paymentTerminals: PaymentTerminal[] | null = data
    ? data.registry.paymentTerminals
    : null

  return {
    error,
    loading,
    paymentTerminals,
    refetch,
  }
}
