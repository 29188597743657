import styled from 'styled-components/macro'

import {
  ListHeader as ListHeaderBase,
  ListHeaderSection,
} from '@/components/List'

const ListHeader = () => (
  <ListHeaderBase>
    <Section></Section>
  </ListHeaderBase>
)

export default ListHeader

const Section = styled(ListHeaderSection)`
  &&& {
    border: none;
    padding: 0;
  }
`
