import { useQuery } from '@apollo/client'

import type {
  DimensionShortcutsQuery as QueryData,
  DimensionShortcutsQueryVariables as QueryVariables,
} from '~generated-types'

import { dimensionQueries } from '../queries'
import { Shortcut } from '../types'

export const useDimensionShortcuts = () => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    dimensionQueries.GET_DIMENSION_SHORTCUTS
  )

  const shortcuts: Shortcut[] = data?.registry.dimensionShortcuts || []

  return {
    error,
    loading,
    refetch,
    shortcuts,
  }
}
