import { Ref, useEffect, useState } from 'react'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'

import { ModalContainer } from '@/components/Modal'
import {
  Reservation,
  useRoomReservationsContext,
  useSetRoomReservationNotes,
} from '@/modules/FrontDesk'
import { useUpdateSalesAttributesMutation } from '@/modules/Sales/mutations'

import { Button } from '../common'
import { NotesIcon } from './NotesIcon'
import { NotesModal } from './NotesModal'

type Props = {
  reservation: Reservation
}

export const Notes = ({
  reservation: {
    id: reservationId,
    request: { features, info: reservationNotes },
    sales: { id: salesId, notes: salesNotes, locked },
  },
}: Props) => {
  const { setTargetId } = useRoomReservationsContext()

  const [isOpen, setOpen] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  const [editedReservationNotes, setEditedReservationNotes] = useState<string>(
    reservationNotes || ''
  )
  const [editedSalesNotes, setEditedSalesNotes] = useState<EditorState>(
    getEditorContent(salesNotes)
  )

  useEffect(() => {
    setEditedSalesNotes(getEditorContent(salesNotes))
  }, [salesNotes])

  const [setReservationNotes] = useSetRoomReservationNotes()
  const [updateAttributes] = useUpdateSalesAttributesMutation()

  const handleOpenModal = () => {
    setOpen(true)
    setTargetId(reservationId)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setTargetId(null)
  }

  const handleSaveNotes = () => {
    const newReservationNotes = editedReservationNotes || null
    const newSalesNotes = editedSalesNotes.getCurrentContent().hasText()
      ? JSON.stringify(convertToRaw(editedSalesNotes.getCurrentContent()))
      : null

    const changed =
      reservationNotes !== newReservationNotes || salesNotes !== newSalesNotes

    if (locked || !changed) {
      return handleCloseModal()
    }

    setProcessing(true)

    const reservationVariables = {
      input: {
        featureIds: features.map(({ id }) => id),
        id: reservationId,
        info: newReservationNotes,
      },
    }

    const salesVariables = {
      input: {
        attributes: { notes: newSalesNotes },
        salesId,
      },
    }

    return updateAttributes({ variables: salesVariables })
      .then(() => setReservationNotes({ variables: reservationVariables }))
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        handleCloseModal()
      })
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <NotesModal
          handleSaveNotes={handleSaveNotes}
          processing={processing}
          readOnly={locked}
          reservationNotes={editedReservationNotes}
          salesNotes={editedSalesNotes}
          setReservationNotes={setEditedReservationNotes}
          setSalesNotes={setEditedSalesNotes}
        />
      }
      onClose={handleSaveNotes}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Button
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={handleOpenModal}
          width="35px"
        >
          <NotesIcon
            hasPrimaryNote={!!reservationNotes}
            hasSecondaryNote={!!salesNotes}
          />
        </Button>
      )}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}

////////////

const getEditorContent = (notes: string | null) => {
  const parsedNotes = notes ? JSON.parse(notes) : null

  return parsedNotes
    ? EditorState.createWithContent(convertFromRaw(parsedNotes))
    : EditorState.createEmpty()
}
