import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { PrintLink } from '@/modules/Listing/common'
import { SalesCell } from '@/modules/Listing/common/cells'
import { useTheme } from '@/theme'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { SortOrder } from '../types'
import { AccommodationGuest } from '../useAccommodationGuests'

interface Props {
  data: AccommodationGuest
  sortOrder: SortOrder
}

export const AccommodationGuestRow = ({
  data: { guest, room },
  sortOrder,
}: Props) => {
  const theme = useTheme()

  const {
    participant: { firstName, lastName, sales },
  } = guest

  const customer = sales.customer?.customer

  const parseName = ({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`

  const customerName =
    customer && customer.__typename === 'CustomerOrganization'
      ? customer.organization.name
      : parseName({
          firstName: customer?.person.firstName || '',
          lastName: customer?.person.lastName || '',
        })
  const guestName = parseName({ firstName, lastName })

  const customerCell = (
    <td>
      {customer ? (
        <PrintLink to={`/registry/customers/${customer?.customerNumber}`}>
          {customer?.customerNumber ? `#${customer?.customerNumber} ` : ''}
          {customerName || '—'}
        </PrintLink>
      ) : (
        '—'
      )}
    </td>
  )
  const guestNameCell = <td>{guestName || '—'}</td>
  const roomCell = (
    <td>
      <strong style={{ display: 'inline-block', minWidth: 50 }}>
        #{room.number}
      </strong>
      <span style={{ whiteSpace: 'nowrap' }}>
        {room.beds} + {room.extraBeds}
      </span>
    </td>
  )

  const stayCell = (
    <td>
      {guest.request.checkIn.type === CheckInTypes.EARLY && (
        <FontAwesomeIcon
          color={theme.palette.primary.dark}
          icon="sun"
          size="sm"
          style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
        />
      )}
      {formatDateRangeWithoutRepetition(
        guest.request.checkIn.date,
        guest.request.checkOut.date,
        'short'
      )}
      {guest.request.checkOut.type === CheckOutTypes.LATE && (
        <FontAwesomeIcon
          color={theme.palette.primary.dark}
          icon="moon"
          size="sm"
          style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
        />
      )}
    </td>
  )

  switch (sortOrder) {
    case SortOrder.PERSON:
      return (
        <tr>
          {guestNameCell}
          {stayCell}
          {roomCell}
          {customerCell}
          <SalesCell {...sales} />
        </tr>
      )
    case SortOrder.ROOM:
      return (
        <tr>
          {roomCell}
          {guestNameCell}
          {stayCell}
          {customerCell}
          <SalesCell {...sales} />
        </tr>
      )
  }
}

export default AccommodationGuestRow
