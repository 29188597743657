import { gql, useMutation } from '@apollo/client'

import {
  SetCheckInMutation,
  SetCheckInMutationVariables,
} from '~generated-types'

export type { SetCheckInMutation, SetCheckInMutationVariables }

export const SET_CHECK_IN = gql`
  mutation SetCheckIn($input: MarkCheckedInput!) {
    salesParticipantCheckIn(input: $input) {
      id
      checkIn
    }
  }
`

export function useSetCheckIn() {
  return useMutation<SetCheckInMutation, SetCheckInMutationVariables>(
    SET_CHECK_IN
  )
}

export default useSetCheckIn
