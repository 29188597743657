import styled, { css } from 'styled-components/macro'

export const PrimaryLabel = styled.span<{
  light: boolean
  lineHeight: string
  size: string
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ lineHeight, size, theme }) =>
    css`
      color: ${theme.palette.text.main};
      font-size: ${size};
      line-height: ${lineHeight};
    `}

  ${({ light, theme }) => light && `color: ${theme.palette.text.lighter};`}
`
