import { ReactNode } from 'react'

// TODO: During a polish phase, replicate similar functionality to the
//       Material UI <Collapse /> component. For now, this is simply a
//       conditional wrapper for content.

type Props = {
  children: ReactNode
  in: boolean
}

export const Collapse = ({ children, in: inProp }: Props) => (
  <>{inProp ? children : null}</>
)
