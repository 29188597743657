import { gql, useMutation } from '@apollo/client'

import { ParticipantFragmentFull } from '@/modules/ParticipantsList/ParticipantsList.fragments'

import {
  SalesParticipantUpdateMutation,
  SalesParticipantUpdateMutationVariables,
} from '~generated-types'

export type {
  SalesParticipantUpdateMutation,
  SalesParticipantUpdateMutationVariables,
}

export const SALES_PARTICIPANT_UPDATE = gql`
  ${ParticipantFragmentFull}
  mutation SalesParticipantUpdate($input: SalesParticipantUpdateInput!) {
    salesParticipantUpdate(input: $input) {
      ...SalesParticipantFull
    }
  }
`

export function useSalesParticipantUpdate() {
  return useMutation<
    SalesParticipantUpdateMutation,
    SalesParticipantUpdateMutationVariables
  >(SALES_PARTICIPANT_UPDATE)
}

export default useSalesParticipantUpdate
