import { gql, useMutation } from '@apollo/client'

import type {
  AddSalesProductItemMutation,
  AddSalesProductItemMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.salesProductItem}

  mutation AddSalesProductItem($input: SalesProductAddItemsInput!) {
    salesProductAddItems(input: $input) {
      ...SalesProductItem
    }
  }
`

export const useAddSalesProductItemMutation = () =>
  useMutation<
    AddSalesProductItemMutation,
    AddSalesProductItemMutationVariables
  >(MUTATION)
