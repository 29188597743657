import { Decimal } from 'decimal.js'
import { Worksheet } from 'exceljs'
import moment from 'moment/moment'

import { Palette } from '@/theme'

import { InvoiceCostingQuery, InvoiceType } from '~generated-types'

import { extendCells, hexToArgb } from './utils'

type Input = {
  invoice: InvoiceCostingQuery['invoice']
  isCommission: boolean
  palette: Palette
  sheet: Worksheet
  translateFn: (keys: string | Array<string>) => string
}

export const addSummary = ({
  invoice,
  isCommission,
  palette,
  sheet,
  translateFn,
}: Input) => {
  const type =
    invoice.invoiceType === InvoiceType.Invoice
      ? 'INVOICE'
      : 'INVOICE_RESERVATION_FEE'

  const invoiceNumber = [
    `${translateFn(`Header.title.${type}`)}:`,
    `#${invoice.invoiceNumber}`,
  ]

  const paymentType = [
    `${translateFn('PaymentType.title')}:`,
    translateFn(`PaymentType.${invoice.paymentType}`),
  ]

  const customer = [
    `${translateFn('Header.section:CUSTOMER')}:`,
    invoice.customer?.name,
  ]

  const customerNumber = [
    `${translateFn('Excel:customerNumber')}:`,
    (invoice.customer?.customerNumber &&
      `#${invoice.customer.customerNumber}`) ||
      '',
  ]

  const invoiceDate = [
    `${translateFn('DateManager.title:INVOICE_DATE')}:`,
    moment(invoice.invoiceDate).toDate(),
  ]

  const dueDate = [
    `${translateFn('DateDurationManager.due')}:`,
    moment(invoice.dueDate).toDate(),
  ]

  const sales = [
    `${translateFn('Excel.sales')}:`,
    `#${invoice.order.sales.orderNumber}`,
  ]

  const salesStart = [
    `${translateFn('Excel.salesStart')}:`,
    (invoice.order.sales.searchDates?.start &&
      moment(invoice.order.sales.searchDates.start).toDate()) ||
      '-',
  ]

  const salesEnd = [
    `${translateFn('Excel.salesEnd')}:`,
    (invoice.order.sales.searchDates?.end &&
      moment(invoice.order.sales.searchDates.end).toDate()) ||
      '-',
  ]

  const netPrice = [
    isCommission
      ? `${translateFn('Excel.netPrice')}:`
      : `${translateFn('Excel.invoiceTotal')}:`,
    invoice.paymentInfo.totalPrice.amountVatIncluded,
  ]

  const grossPrice = isCommission
    ? [`${translateFn('Excel.grossPrice')}:`, invoice.totalWithCommission]
    : []

  const commissionRate = isCommission
    ? [
        `${translateFn('Excel.commissionRate')}:`,
        invoice.order.sales.commissionRate,
      ]
    : []

  const commissionAmount = isCommission
    ? [
        `${translateFn('Excel.commissionAmount')}:`,
        Number(
          new Decimal(grossPrice[1] as number)
            .minus(new Decimal(netPrice[1] as number))
            .toFixed(2)
        ),
      ]
    : []

  const summaryRows = [
    [],
    [...invoiceNumber, ...sales, '', ...netPrice],
    [...paymentType, ...salesStart, '', ...grossPrice],
    [...customer, ...salesEnd, '', ...commissionRate],
    [...customerNumber, '', '', '', ...commissionAmount],
    [...invoiceDate],
    [...dueDate],
    [],
  ]

  sheet.addRows(summaryRows)

  const summaryHeaders = [
    ...extendCells(1, 3, 1, 8),
    ...extendCells(3, 3, 3, 5),
    ...extendCells(6, 3, 6, 6),
  ]

  summaryHeaders.forEach((location) => {
    const cell = sheet.getCell(location)

    cell.alignment = {
      horizontal: 'right',
    }
    cell.font = {
      bold: true,
      color: {
        argb: hexToArgb(palette.text.dark),
      },
    }
  })

  const summaryValues = [
    ...extendCells(2, 3, 2, 8),
    ...extendCells(4, 3, 4, 5),
    ...extendCells(7, 3, 7, 6),
  ]

  summaryValues.forEach((location) => {
    const cell = sheet.getCell(location)

    cell.alignment = {
      horizontal: 'left',
    }
    cell.font = {
      color: {
        argb: hexToArgb(palette.text.dark),
      },
    }
  })

  extendCells(7, 3, 7, 6).map(
    (cellLocation) => (sheet.getCell(cellLocation).numFmt = '0.00 €')
  )

  if (isCommission) {
    const cell = sheet.getCell('G5')

    cell.value = ((cell.value || 0) as number) / 100
    cell.numFmt = '0 %'
  }

  setColumnWidths(sheet)
}

const setColumnWidths = (sheet: Worksheet) => {
  sheet.getColumn('A').width = 20
  sheet.getColumn('B').width = 20
  sheet.getColumn('C').width = 25
  sheet.getColumn('D').width = 15
  sheet.getColumn('E').width = 10
  sheet.getColumn('F').width = 20
  sheet.getColumn('G').width = 20
  sheet.getColumn('H').width = 20
  sheet.getColumn('I').width = 20
  sheet.getColumn('J').width = 20
  sheet.getColumn('K').width = 20
  sheet.getColumn('L').width = 20
  sheet.getColumn('M').width = 20
  sheet.getColumn('N').width = 2
  sheet.getColumn('O').width = 20
  sheet.getColumn('P').width = 20
  sheet.getColumn('Q').width = 20
  sheet.getColumn('R').width = 20
}
