import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { PaymentRefundDetails as RefundDetailsType } from '@/modules/Order/types'

import { SectionWithTooltip } from '../../components'

type Props = {
  details: RefundDetailsType
}

export const RefundDetails = ({ details: { refund } }: Props) => (
  <SectionWithTooltip
    content={refund.invoiceNumber || <FontColor lighter>–</FontColor>}
    flex={1}
    sign="#"
    title={<T>Orders:Payments.field.invoiceNumber</T>}
  />
)
