import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'

type Props = {
  hasEndAdornment?: boolean
}

const InputBase = styled(Input)<Props>`
  &:not([type='checkbox']):not([type='radio']) {
    border-radius: 0;
    height: 100%;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      padding: ${theme.spacing.gu(1)}rem;
    `}

    ${({ hasEndAdornment, theme }) =>
      hasEndAdornment
        ? css<any>`
            padding-right: ${theme.spacing.gu(4.5)}rem;
          `
        : ''}

    text-align: inherit;
    cursor: pointer;

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }

    &:disabled {
      cursor: not-allowed;

      ${({ theme }) => css`
        border-color: transparent;
        background: ${theme.palette.smoke.lighter};
        color: ${theme.palette.text.light};
      `}
    }

    &::placeholder {
      font-style: italic;
      opacity: 1;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }

  /* Hide number spinners (for space) */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export default InputBase
