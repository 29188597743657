import RoomNote from '@/components/TextNoteModal'
import { useSaleParticipants } from '@/modules/ParticipantsList'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'

import { SaleAccommodationQuery } from '~generated-types'

import { Features } from './Features'

type Props = {
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']
  readOnly?: boolean
}

export const RoomNeeds = ({ room, readOnly }: Props) => {
  const { handleSetRoomNeeds } = useSaleParticipants()

  return (
    <>
      <Features
        selectedFeatures={room.request.features}
        readOnly={readOnly}
        updateFeatures={(featureIds) =>
          handleSetRoomNeeds({
            featureIds,
            id: room.id,
            info: room.request.info,
          })
        }
      />

      <RoomNote
        note={room.request.info || ''}
        updateNote={(note) =>
          handleSetRoomNeeds({
            featureIds:
              room?.request.features.map((f: RoomFeature) => f.id) || [],
            id: room.id,
            info: note,
          })
        }
        readOnly={readOnly}
      />
    </>
  )
}
