import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useSalesProductListContext } from '@/modules/Products/hooks'
import { SalesProductSettings } from '@/modules/Products/types'
import { useTheme } from '@/theme'

interface Props {
  id: string
  readOnly: boolean
  settings: SalesProductSettings
}

export const VisibilityToggle = ({
  id,
  readOnly,
  settings: { accommodationGroup, published, purchaseDates },
}: Props) => {
  const { palette, spacing } = useTheme()
  const { updateProduct } = useSalesProductListContext()

  const handleToggleVisibility = () =>
    updateProduct({ id, settings: { published: !published } })

  return (
    <Tooltip
      content={
        <FlexColumn alignItems="flex-start" noPadding>
          <FontWeight
            style={{
              marginBottom:
                !accommodationGroup || !purchaseDates ? spacing.gutterSmall : 0,
            }}
          >
            <T>{`Products:Settings.published.${published ? 'yes' : 'no'}`}</T>
          </FontWeight>

          {!accommodationGroup && (
            <FlexRow alignItems="center">
              <FontAwesomeIcon
                color={palette.warning.main}
                icon="triangle-exclamation"
                size="sm"
                style={{ marginRight: `${spacing.gu(1)}rem` }}
              />
              <T>Products:Settings.accommodationGroup.empty</T>
            </FlexRow>
          )}

          {!purchaseDates && (
            <FlexRow alignItems="center">
              <FontAwesomeIcon
                color={palette.warning.main}
                icon="triangle-exclamation"
                size="sm"
                style={{ marginRight: `${spacing.gu(1)}rem` }}
              />
              <T>Products:Settings.dates.empty</T>
            </FlexRow>
          )}
        </FlexColumn>
      }
      trigger={(triggerProps) => (
        <span {...triggerProps}>
          <Button
            isActive={published}
            disabled={readOnly}
            size="sm"
            variant="outlined"
            onClick={() => !readOnly && handleToggleVisibility()}
          >
            <FontAwesomeIcon
              color={published ? palette.primary.main : palette.smoke.dark}
              icon={published ? 'eye' : 'eye-slash'}
              size="lg"
            />
          </Button>
        </span>
      )}
    />
  )
}

/////

const Button = styled(Chip)<{ isActive: boolean; disabled: boolean }>`
  align-items: center;
  justify-content: center;
  padding: 0;

  ${({ isActive, theme }) => css`
    background: ${theme.palette.white};
    border-color: ${isActive
      ? theme.palette.primary.main
      : theme.palette.smoke.dark};
    height: ${theme.spacing.gu(4)}rem;
    width: ${theme.spacing.gu(4)}rem;
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    `}

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`
