import styled, { css } from 'styled-components/macro'

import { H4 } from '@/components/Typography'

export const ListingSectionTitle = styled(H4)`
  break-after: avoid;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
    font-size: ${theme.typography.fontSizeBig};
  `};
`
