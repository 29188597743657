import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import {
  ElasticFeature,
  ElasticRoom,
} from '@/modules/Reservations/components/RoomLayout'
import { useGlobalIconsContext } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

interface RoomRowHeaderProps {
  room: ElasticRoom
}

export const RoomRowHeader = ({ room }: RoomRowHeaderProps) => {
  const { checkIconValidity } = useGlobalIconsContext()

  return (
    <Wrapper>
      <Room>
        <span>{room.number}</span>
        <RoomSize>
          {room.beds} + {room.extraBeds}
        </RoomSize>
      </Room>
      <Features>
        {!!room.features.length && (
          <>
            {[...room.features]
              .sort(generateCompareFn('name'))
              .map((feature: ElasticFeature) => (
                <Feature key={feature.id}>
                  {checkIconValidity(feature.icon) ? (
                    <FontAwesomeIcon
                      fixedWidth
                      icon={feature.icon as IconProp}
                      size="sm"
                    />
                  ) : (
                    <span>{feature.shortName}</span>
                  )}
                </Feature>
              ))}
          </>
        )}
      </Features>
    </Wrapper>
  )
}

export default RoomRowHeader

////////////

const Features = styled.span`
  flex: 1;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterBig};
  `}

  @media print {
    margin-left: 0.1in;
  }
`

const Feature = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
    margin-right: ${theme.spacing.gutterSmall};
  `}

  @media print {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.fontSizeSmaller};
    `}
  }
`

const Room = styled.span`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  flex: 1;
  font-weight: 500;

  @media print {
    align-self: stretch;

    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeSmall};
      margin-bottom: ${theme.spacing.gutterSmall};
    `}
  }
`

const RoomSize = styled.span`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-left: ${theme.spacing.gutter};
  `}

  @media print {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
      font-size: ${theme.typography.fontSizeSmaller};
    `}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;

  @media print {
    flex-direction: column;
    align-items: flex-start;
  }
`
