import { CSSProperties, MouseEvent, ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import ReactLoading from 'react-loading'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { getIssueColor, useTheme } from '@/theme'

import { IssueLevel } from '~generated-types'

type Props = {
  disabled?: boolean
  icon?: IconProp
  issue?: IssueLevel
  label: ReactNode
  loading?: boolean
  style?: CSSProperties
  onClick?: (event: MouseEvent) => void
  to?: string
  color?: string
  isActive?: () => boolean
}

export const SubNavLink = ({ icon, label, ...rest }: Props) => (
  <StyledSubNavLink {...rest}>
    {icon && (
      <IconWrapper>
        <FontAwesomeIcon fixedWidth icon={icon} size="sm" />
      </IconWrapper>
    )}
    {label}
  </StyledSubNavLink>
)

export const SubNavLinkButton = ({ icon, label, loading, ...rest }: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <StyledSubNavLinkButton {...rest}>
      {icon && (
        <IconWrapper>
          {loading ? (
            <ReactLoading
              color={palette.text.light}
              height={spacing.gutter}
              type="spin"
              width={spacing.gutter}
            />
          ) : (
            <FontAwesomeIcon fixedWidth icon={icon} size="sm" />
          )}
        </IconWrapper>
      )}
      {label}
    </StyledSubNavLinkButton>
  )
}

export const SubNavLinkSelectable = ({
  icon,
  issue,
  label,
  ...rest
}: Props) => {
  const { palette } = useTheme()

  return (
    <StyledSubNavLinkSelectable {...rest}>
      {icon && (
        <IconWrapper
          issueColor={issue ? getIssueColor(issue, palette).color : null}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={issue ? 'exclamation-triangle' : icon}
            size={issue ? '1x' : 'sm'}
          />
        </IconWrapper>
      )}
      {label}
    </StyledSubNavLinkSelectable>
  )
}

////////////

const IconWrapper = styled.span<{ issueColor?: string | null }>`
  display: flex;
  line-height: 16px;

  ${({ theme }) => css`
    width: ${theme.spacing.gutter};
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ issueColor }) =>
    issueColor &&
    css`
      &&& svg {
        color: ${issueColor};
      }
    `}
`

const StyledNavLink = styled(NavLink)<any>`
  display: flex;
  position: relative;
  white-space: nowrap;

  border: none;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem;
    line-height: ${theme.typography.fontSizeBase};
    margin-bottom: ${theme.spacing.gu(0.25)}rem;
  `}

  ${({ disabled }) => disabled && `opacity: 0.8`};

  background: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.lighter : 'none'};

  cursor: pointer;

  & svg {
    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
    `}
  }

  &.active svg,
  &:hover svg {
    ${({ disabled, color, theme }) =>
      !disabled &&
      `color: ${color ? darken(0.08, color) : theme.palette.primary.dark};`}
  }
`

const StyledSubNavLink = styled(StyledNavLink)<any>`
  &.active,
  &:hover {
    ${({ disabled, color, theme }) =>
      !disabled &&
      css`
        background: ${theme.palette.primary.extraLight};
        color: ${color ? darken(0.08, color) : theme.palette.primary.dark};

        .order-number {
          color: ${color ? darken(0.08, color) : theme.palette.primary.dark};
        }
      `}
  }
`

const StyledSubNavLinkButton = styled(StyledSubNavLink).attrs(() => ({
  as: 'button',
}))<any>`
  &:disabled {
    background: transparent;
    cursor: not-allowed;

    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
    `}

    & svg {
      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }
`

const StyledSubNavLinkSelectable = styled(StyledNavLink)`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}

  &.active,
  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.text.dark};
    `}
  }

  &:hover {
    opacity: 0.7;
  }
`
