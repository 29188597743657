import React from 'react'

import { useGridContext } from '../GridContext'
import { usePresentationState } from '../PresentationState'
import { ItemData } from './ItemData'
import { ItemPosition } from './ItemPosition'
import { ItemResizable } from './ItemResizable'

type Props = {
  columnIndexes: number[]
  gridId: string
  id: string
  isDragging: boolean
  rowIndexes: number[]
}

export const Item = ({
  columnIndexes,
  gridId,
  id,
  isDragging,
  rowIndexes,
}: Props) => {
  const {
    leftOffset,
    onResize,
    renderItem,
    resourceCalendarConfig: config,
  } = usePresentationState()
  const {
    getAllItemData,
    setItemPosition,
    getColumnDataByIndex,
    getRowDataByIndex,
  } = useGridContext()

  const item = getAllItemData(id)
  const isImmutable = item?.config?.immutable

  if (!item) {
    return null
  }

  const onResizeEnd = ({
    // @ts-ignore
    columnIndexes: nextColumnIndexes,
    // @ts-ignore
    rowIndexes: nextRowIndexes,
  }) => {
    const columns = nextColumnIndexes
      .map((columnIndex: any) => getColumnDataByIndex(columnIndex))
      .filter(Boolean)
    const rows = nextRowIndexes
      .map((rowIndex: any) => getRowDataByIndex(gridId, rowIndex))
      .filter(Boolean)

    const actionData = {
      columns,
      gridId,
      item: item.data,
      rows,
    }

    // Optimisticly set item position
    setItemPosition(gridId, id, nextRowIndexes, nextColumnIndexes)

    onResize(actionData)
  }

  return (
    // @ts-ignore
    <ItemData
      id={id}
      gridId={gridId}
      columnIndexes={columnIndexes}
      rowIndexes={rowIndexes}
    >
      {({ columns, item, rows }) => {
        if (!item) {
          return null
        }

        // Only for resource calendar
        const isShadow =
          config && item?.resource?.id !== rows[0]?.data?.resourceId

        // Only for resource calendar
        const isResizingDisabled =
          // Compare the reservation owner id and the context owner id to
          // disable resizing for "external" reservations
          (config?.isMovingDisabled && item?.sales?.id !== config?.ownerId) ||
          // Disable resizing for minimal reservation for "S" density
          (config?.density === 'S' && columnIndexes.length === 1) ||
          // Disable resizing for reservation "shadow"
          isShadow

        return (
          <ItemPosition columnIndexes={columnIndexes} rowIndexes={rowIndexes}>
            {(renderProps) =>
              isImmutable || isResizingDisabled ? (
                <div
                  style={{
                    height: `${renderProps.height}px`,
                    left: `${renderProps.position.x}px`,
                    position: 'absolute',
                    top: `${renderProps.position.y}px`,
                    width: `${renderProps.width}px`,
                    zIndex: isShadow ? 0 : 1,
                  }}
                >
                  {renderItem({
                    columns,
                    gridId,
                    isDragging,
                    itemData: item,
                    leftOffset,
                    rows,
                  })}
                </div>
              ) : (
                <ItemResizable
                  columnIndexes={columnIndexes}
                  // Offset sets the reservation block to the correct location.
                  // It is different for resource and room reservations.
                  externalStyles={{ zIndex: isShadow ? 0 : 1 }}
                  offset={{
                    left: item.accommodationId ? leftOffset : 0,
                    right: item.accommodationId ? leftOffset : 0,
                  }}
                  onResizeEnd={onResizeEnd}
                  rowIndexes={rowIndexes}
                  {...renderProps}
                >
                  {renderItem({
                    columns,
                    gridId,
                    isDragging,
                    itemData: item,
                    leftOffset,
                    rows,
                  })}
                </ItemResizable>
              )
            }
          </ItemPosition>
        )
      }}
    </ItemData>
  )
}
