import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Textarea } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'

type Props = {
  features: RoomFeature[]
  note: string
  reservationId: string
}

export const AdditionalInfo = ({ features, note, reservationId }: Props) => {
  const [editNote, setEditNote] = useState<boolean>(false)
  const [noteValue, setNoteValue] = useState<string>(note)

  const { setNeeds } = useReservationListContext()

  const handleSetNeeds = () => {
    setNeeds({
      featureIds: features.map((f: RoomFeature) => f.id),
      note: noteValue,
      reservationId,
    })
    setEditNote(false)
  }

  return (
    <FlexRow alignItems="center">
      {editNote ? (
        <TextareaWrapper>
          <StyledDataFieldTextarea
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setNoteValue(e.target.value)
            }
            autoFocus
            value={noteValue}
            onBlur={handleSetNeeds}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                handleSetNeeds()
              }
            }}
          />
        </TextareaWrapper>
      ) : (
        <ToggleNote
          disabled={false}
          isEmpty={!noteValue}
          onClick={() => {
            setEditNote(true)
          }}
        >
          {noteValue || <T>Accommodation:SalesReservationList.addNote</T>}
        </ToggleNote>
      )}
    </FlexRow>
  )
}

const TextareaWrapper = styled.div`
  width: 100%;

  & span {
    width: 100%;
  }
`

const StyledDataFieldTextarea = styled(Textarea)`
  border-radius: 4px;

  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const ToggleNote = styled.button<any>`
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  text-align: start;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.guPx(1) - 1}px ${theme.spacing.gu(1)}rem;
  `}

  color: ${(props) =>
    props.isEmpty
      ? props.theme.palette.text.lighter
      : props.theme.palette.text.main};

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
      border: 1px solid ${theme.palette.primary.main};
    `}
  }
`
