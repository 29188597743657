import { QuantityUnit as QU } from '~generated-types'

import { SalesProductItem } from '../types'

export const getQuantityUnit = (items: SalesProductItem[]) =>
  items.reduce((acc: QU, { quantityUnit: qu }) => {
    if (qu === QU.Hour || acc === QU.Hour) {
      return QU.Hour
    }
    if (qu === QU.Day || qu === QU.Night) {
      return qu
    }
    return acc
  }, QU.Piece)
