import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  compact?: boolean
}

export const Loader = ({ compact }: Props) => {
  const { palette } = useTheme()

  return (
    <Wrapper
      alignItems="center"
      compact={compact}
      justifyContent="center"
      flex={1}
    >
      <ReactLoading
        color={palette.smoke.main}
        height={60}
        type="spin"
        width={60}
      />
    </Wrapper>
  )
}

const Wrapper = styled(FlexColumn)<{ compact?: boolean }>`
  height: 100%;
  margin: auto 0;

  ${({ compact, theme }) =>
    css`
      padding-bottom: ${theme.spacing.gu(compact ? 5 : 20)}rem;
    `}
`
