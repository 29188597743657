import React, {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { FetchState, FetchStates } from '@/common/types'
import { useUpdateRoomMutation } from '@/modules/Accommodation'
import { generateCompareFn } from '@/utils/arrays'

import { RoomFeature, RoomLayoutQueries, RoomLayoutService } from './api'
import { useRoomLayoutReservations, useRoomLayoutRoomGroups } from './hooks'
import {
  ElasticRoom,
  ElasticRoomGroup,
  ElasticRoomReservation,
  MomentDateRange,
  Sale,
} from './types'
import { getSaleDataFromElastic, getSaleDataFromGraphQL } from './utils'

type LayoutVariables = {
  COLUMN_HEADER_WIDTH: number
  COLUMN_WIDTH: number
  ROW_HEIGHT: number
}

type Props = {
  children: ReactNode
  defaultRange: MomentDateRange | null | undefined
  targetGroupId: string
  targetId: string
  targetSalesId: string
}

type RoomTypeData = {
  checkIn: string
  checkOut: string
  id: string
  reservationId: string
}

type RoomLayoutContextType = {
  // Data
  calendarWrapperRef: RefObject<HTMLDivElement> | null
  isCleaningMode: boolean
  layoutVariables: LayoutVariables
  refreshCalendarTicker: number
  refreshSidebarTicker: number
  reservations: ElasticRoomReservation[]
  reservationsStatus: FetchState
  roomFeatures: RoomFeature[]
  roomGroups: ElasticRoomGroup[]
  roomGroupsStatus: FetchState
  roomsBySelectedRoomType: ElasticRoom[]
  sales: Sale[]
  selectedRoomIds: string[]
  selectedRoomType: RoomTypeData
  targetAccommodationLevelIds: string[]
  targetBuildingIds: string[]
  targetCalendarDateRange: MomentDateRange
  targetFeatureIds: string[]
  targetGroupId: string
  targetId: string
  targetRoomSize: string
  targetSalesDateRange: MomentDateRange
  targetSalesId: string

  // Methods
  onSetCleaningMode: (mode: boolean) => void
  onSetCurrentSalesDateRange: () => void
  onSetTargetAccommodationLevelIds: (levelIds: string[]) => void
  onSetTargetBuildingIds: (buildingIds: string[]) => void
  onSetTargetCalendarDateRange: (range: MomentDateRange) => void
  onSetTargetFeatureIds: (featureIds: string[]) => void
  onSetTargetRoomSize: (roomSize: string) => void
  onSetTargetSalesDateRange: (range: MomentDateRange | null | undefined) => void
  onSetSales: (updatedSales: Sale[]) => void
  onSetSelectedRoomIds: (ids: string[]) => void
  onSetSelectedRoomType: (roomTypeData: RoomTypeData | null) => void
  onUpdateReservation: (updated: ElasticRoomReservation) => void
  onUpdateRoom: (
    id: string,
    groupId: string,
    internalInfo: string
  ) => Promise<void | undefined>
  refreshCalendar: () => void
  refreshSidebar: () => void
}

const RoomLayoutContext = createContext<RoomLayoutContextType>({
  calendarWrapperRef: null,
  isCleaningMode: false,
  layoutVariables: {
    COLUMN_HEADER_WIDTH: 275,
    COLUMN_WIDTH: 100,
    ROW_HEIGHT: 35,
  },
  onSetCleaningMode: () => undefined,
  onSetCurrentSalesDateRange: () => undefined,
  onSetSales: () => undefined,
  onSetSelectedRoomIds: () => undefined,
  onSetSelectedRoomType: () => undefined,
  onSetTargetAccommodationLevelIds: () => undefined,
  onSetTargetBuildingIds: () => undefined,
  onSetTargetCalendarDateRange: () => undefined,
  onSetTargetFeatureIds: () => undefined,
  onSetTargetRoomSize: () => undefined,
  onSetTargetSalesDateRange: () => undefined,
  onUpdateReservation: () => undefined,
  onUpdateRoom: Promise.reject,
  refreshCalendar: () => undefined,
  refreshCalendarTicker: 0,
  refreshSidebar: () => undefined,
  refreshSidebarTicker: 0,
  reservations: [],
  reservationsStatus: FetchStates.ERROR,
  roomFeatures: [],
  roomGroups: [],
  roomGroupsStatus: FetchStates.ERROR,
  roomsBySelectedRoomType: [],
  sales: [],
  selectedRoomIds: [],
  selectedRoomType: { checkIn: '', checkOut: '', id: '', reservationId: '' },
  targetAccommodationLevelIds: [],
  targetBuildingIds: [],
  targetCalendarDateRange: { from: moment(), to: moment() },
  targetFeatureIds: [],
  targetGroupId: '',
  targetId: '',
  targetRoomSize: '',
  targetSalesDateRange: { from: moment(), to: moment() },
  targetSalesId: '',
})

export const RoomLayoutProvider = ({
  children,
  defaultRange,
  targetGroupId,
  targetId,
  targetSalesId,
}: Props) => {
  const contextValueRef = useRef<RoomLayoutContextType | null>(null)
  const calendarWrapperRef = useRef<HTMLDivElement | null>(null)

  const LAYOUT_VARIABLES = Object.freeze({
    COLUMN_HEADER_WIDTH: 275,
    COLUMN_WIDTH: 100,
    ROW_HEIGHT: 35,
  })

  const isArrayOfStrings = (value: unknown) =>
    Array.isArray(value) && value.every((item) => typeof item === 'string')

  const localStorageAccommodationLevelIds = JSON.parse(
    localStorage.getItem('roomLayoutAccommodationLevelsFilter') || '[]'
  )
  const localStorageBuildingIds = JSON.parse(
    localStorage.getItem('roomLayoutBuildingsFilter') || '[]'
  )
  const localStorageFeatureIds = JSON.parse(
    localStorage.getItem('roomLayoutFeaturesFilter') || '[]'
  )

  const [columnWidth, setColumnWidth] = useState<number>(0)
  const [isCleaningMode, setCleaningMode] = useState<boolean>(false)
  const [refreshCalendarTicker, setRefreshCalendarTicker] = useState<number>(0)
  const [refreshSidebarTicker, setRefreshSidebarTicker] = useState<number>(0)
  const [roomFeatures, setRoomFeatures] = useState<RoomFeature[]>([])
  const [roomsBySelectedRoomType, setRoomsBySelectedRoomType] = useState<
    ElasticRoom[]
  >([])
  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([])
  const [selectedRoomType, setSelectedRoomType] = useState<RoomTypeData>({
    checkIn: '',
    checkOut: '',
    id: '',
    reservationId: '',
  })
  const [targetAccommodationLevelIds, setTargetAccommodationLevelIds] =
    useState<string[]>(
      isArrayOfStrings(localStorageAccommodationLevelIds)
        ? localStorageAccommodationLevelIds
        : []
    )
  const [targetBuildingIds, setTargetBuildingIds] = useState<string[]>(
    isArrayOfStrings(localStorageBuildingIds) ? localStorageBuildingIds : []
  )
  const [targetFeatureIds, setTargetFeatureIds] = useState<string[]>(
    isArrayOfStrings(localStorageFeatureIds) ? localStorageFeatureIds : []
  )
  const [targetSalesDateRange, setTargetSalesDateRange] =
    useState<MomentDateRange>({
      from: moment().add(1, 'w').startOf('isoWeek'),
      to: moment().add(1, 'w').endOf('isoWeek'),
    })
  const [targetCalendarDateRange, setTargetCalendarDateRange] =
    useState<MomentDateRange>(
      targetSalesId
        ? defaultRange
          ? {
              from: defaultRange.from.clone().startOf('isoWeek'),
              to: defaultRange.from.clone().add(2, 'w').endOf('isoWeek'),
            }
          : {
              from: moment().startOf('isoWeek'),
              to: moment().add(2, 'w').endOf('isoWeek'),
            }
        : {
            from: targetSalesDateRange.from.clone().startOf('isoWeek'),
            to: targetSalesDateRange.from.clone().add(2, 'w').endOf('isoWeek'),
          }
    )
  const [targetRoomSize, setTargetRoomSize] = useState<string>(
    localStorage.getItem('roomLayoutRoomSizeFilter') || ''
  )
  const [sales, setSales] = useState<Sale[]>([])

  const gqlRoomFeatures = useQuery(RoomLayoutQueries.ROOM_FEATURES)

  const [updateRoom] = useUpdateRoomMutation()

  const {
    onUpdate: onUpdateReservation,
    reservations,
    status: reservationsStatus,
  } = useRoomLayoutReservations({
    accommodationLevelIds: targetAccommodationLevelIds,
    buildingIds: targetBuildingIds,
    dateRange: targetCalendarDateRange,
    updateTicker: refreshCalendarTicker,
  })

  const {
    groups: roomGroups,
    setGroups: setRoomGroups,
    status: roomGroupsStatus,
  } = useRoomLayoutRoomGroups({
    accommodationLevelIds: targetAccommodationLevelIds,
    buildingIds: targetBuildingIds,
    dateRange: targetCalendarDateRange,
    featureIds: targetFeatureIds,
    roomSize: targetRoomSize,
    updateTicker: refreshCalendarTicker,
  })

  useEffect(() => {
    if (calendarWrapperRef && calendarWrapperRef.current) {
      const calendarWidth = calendarWrapperRef.current.clientWidth
      const columnWidth = Math.round(
        (calendarWidth - LAYOUT_VARIABLES.COLUMN_HEADER_WIDTH) / 7
      )
      setColumnWidth(columnWidth)
    }
  }, [calendarWrapperRef, LAYOUT_VARIABLES])

  useEffect(() => {
    RoomLayoutService.fetchRoomsByRoomType(selectedRoomType.id).then(
      ({ data, ok }) => {
        if (ok) {
          if (data.length) {
            setTargetBuildingIds(
              Array.from(new Set(data.map(({ building }) => building.id)))
            )
            setTargetAccommodationLevelIds([])
            setTargetFeatureIds([])
            setTargetRoomSize('')
          }
          setRoomsBySelectedRoomType(data)
        }
      }
    )
  }, [selectedRoomType])

  useEffect(() => {
    if (gqlRoomFeatures.data && !gqlRoomFeatures.error) {
      setRoomFeatures(gqlRoomFeatures.data.registry.roomFeatures)
    }
  }, [gqlRoomFeatures])

  const onSetSelectedRoomIds = (ids: string[]) => setSelectedRoomIds(ids)

  const onSetSelectedRoomType = (roomTypeData: RoomTypeData | null) =>
    setSelectedRoomType(
      roomTypeData ?? {
        checkIn: '',
        checkOut: '',
        id: '',
        reservationId: '',
      }
    )

  const onSetTargetAccommodationLevelIds = (levelIds: string[]) => {
    localStorage.setItem(
      'roomLayoutAccommodationLevelsFilter',
      JSON.stringify(levelIds)
    )
    setTargetAccommodationLevelIds(levelIds)
  }

  const onSetTargetBuildingIds = (buildingIds: string[]) => {
    localStorage.setItem(
      'roomLayoutBuildingsFilter',
      JSON.stringify(buildingIds)
    )
    setTargetBuildingIds(buildingIds)
  }

  const onSetTargetFeatureIds = (featureIds: string[]) => {
    localStorage.setItem('roomLayoutFeaturesFilter', JSON.stringify(featureIds))
    setTargetFeatureIds(featureIds)
  }

  const onSetTargetCalendarDateRange = (dateRange: MomentDateRange) =>
    setTargetCalendarDateRange(dateRange)

  const onSetTargetRoomSize = (roomSize: string) => {
    localStorage.setItem('roomLayoutRoomSizeFilter', roomSize)
    setTargetRoomSize(roomSize)
  }

  const onSetTargetSalesDateRange = (
    dateRange: MomentDateRange | null | undefined
  ) => {
    setTargetSalesDateRange({
      from: dateRange ? dateRange.from : moment(),
      to: dateRange ? dateRange.to : moment(),
    })
    setTargetCalendarDateRange({
      from: dateRange ? dateRange.from.clone().startOf('isoWeek') : moment(),
      to: dateRange
        ? dateRange.from.clone().add(2, 'w').endOf('isoWeek')
        : moment(),
    })
  }

  const onSetCurrentSalesDateRange = () => {
    setTargetSalesDateRange({
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
    })
    setTargetCalendarDateRange({
      from: moment().startOf('isoWeek'),
      to: moment().clone().add(2, 'w').endOf('isoWeek'),
    })
  }

  const onSetSales = (updatedSales: Sale[]) => {
    const sortedSales = [...sales].sort(generateCompareFn('id'))

    setSales(
      [...updatedSales]
        .sort(generateCompareFn('id'))
        .map((sale: Sale, index: number) => ({
          ...sortedSales[index],
          ...sale,
          saleData: sale.accommodationGroups
            ? getSaleDataFromGraphQL(sale)
            : getSaleDataFromElastic(sale),
        }))
    )
  }

  const onSetCleaningMode = (mode: boolean) => setCleaningMode(mode)

  const onUpdateRoom = (id: string, groupId: string, internalInfo: string) =>
    updateRoom({ variables: { input: { id, internalInfo } } })
      .then(({ data }) => {
        if (data) {
          setRoomGroups(
            roomGroups.map((g) =>
              g.groupId === groupId
                ? {
                    ...g,
                    rooms: g.rooms.map((r) =>
                      r.id === id
                        ? { ...r, internalInfo: data.roomUpdate.internalInfo }
                        : r
                    ),
                  }
                : g
            )
          )
        }
      })
      .catch(() => undefined)

  const refreshCalendar = () =>
    setRefreshCalendarTicker((ticker: number) => ticker + 1)

  const refreshSidebar = () =>
    setRefreshSidebarTicker((ticker: number) => ticker + 1)

  contextValueRef.current = {
    calendarWrapperRef,
    isCleaningMode,
    layoutVariables: { ...LAYOUT_VARIABLES, COLUMN_WIDTH: columnWidth },
    onSetCleaningMode,
    onSetCurrentSalesDateRange,
    onSetSales,
    onSetSelectedRoomIds,
    onSetSelectedRoomType,
    onSetTargetAccommodationLevelIds,
    onSetTargetBuildingIds,
    onSetTargetCalendarDateRange,
    onSetTargetFeatureIds,
    onSetTargetRoomSize,
    onSetTargetSalesDateRange,
    onUpdateReservation,
    onUpdateRoom,
    refreshCalendar,
    refreshCalendarTicker,
    refreshSidebar,
    refreshSidebarTicker,
    reservations,
    reservationsStatus,
    roomFeatures,
    roomGroups,
    roomGroupsStatus,
    roomsBySelectedRoomType,
    sales,
    selectedRoomIds,
    selectedRoomType,
    targetAccommodationLevelIds,
    targetBuildingIds,
    targetCalendarDateRange,
    targetFeatureIds,
    targetGroupId,
    targetId,
    targetRoomSize,
    targetSalesDateRange,
    targetSalesId,
  }

  return (
    <RoomLayoutContext.Provider value={contextValueRef.current}>
      {children}
    </RoomLayoutContext.Provider>
  )
}

export const useRoomLayoutContext = () => useContext(RoomLayoutContext)
