import { Fragment } from 'react'
import moment from 'moment'

import { FlexColumn } from '@/components/Layout'
import { orderContexts } from '@/modules/Order/contexts'
import {
  Invoice as InvoiceFull,
  InvoiceInfo,
  OrderType as Order,
} from '@/modules/Order/types'

import { OrderPhaseType as OPT } from '~generated-types'

import {
  ConfirmationPhase,
  InvoicePhase,
  InvoicePhaseActions,
  InvoicePhaseTitles,
  OfferPhase,
  OtherPhaseActions,
  OtherPhaseTitles,
} from './Phases'

type Invoice = InvoiceFull | InvoiceInfo
type InvoiceWithChilds = Invoice & { childs: Invoice[] }

type Props = {
  order: Order
}

export const OrderContent = ({ order }: Props) => {
  const {
    confirmations,
    id,
    invoices,
    lifecycle: { validatedActions },
    offers,
  } = order

  const { orderView } = orderContexts.useOrderView()

  const findChildInvoices = (id: string): Invoice[] => {
    const childInvoice = invoices.find(
      (i) => i.refundDetails?.originalInvoice?.id === id
    )

    if (childInvoice) {
      return [childInvoice, ...findChildInvoices(childInvoice.id)]
    }

    return []
  }

  const invoicePhases = [...invoices]
    .filter((i) => !i.refundDetails?.originalInvoice?.id)
    .sort(compareFn)
    .reduce(
      (acc: InvoiceWithChilds[], i) => [
        ...acc,
        { ...i, childs: findChildInvoices(i.id) },
      ],
      []
    )

  const otherPhases = [...confirmations, ...offers].sort(
    (a, b) =>
      moment(a.auditLog.createdAt).valueOf() -
      moment(b.auditLog.createdAt).valueOf()
  )

  return orderView === OPT.Invoice ? (
    <FlexColumn>
      {!!invoicePhases.length && (
        <FlexColumn>
          <InvoicePhaseTitles />

          {invoicePhases.map((invoice) => {
            const { childs, ...invoiceBase } = invoice

            return (
              <Fragment key={invoiceBase.id}>
                <InvoicePhase invoice={invoiceBase} order={order} />

                {childs.map((childInvoice, idx) => (
                  <InvoicePhase
                    invoice={childInvoice}
                    isLastNested={idx === childs.length - 1}
                    isNested
                    key={childInvoice.id}
                    order={order}
                  />
                ))}
              </Fragment>
            )
          })}
        </FlexColumn>
      )}

      <InvoicePhaseActions
        actions={validatedActions}
        orderId={id}
        noPhases={!invoicePhases.length}
      />
    </FlexColumn>
  ) : (
    <FlexColumn>
      {!!otherPhases.length && (
        <FlexColumn>
          <OtherPhaseTitles />

          {otherPhases.map((p) =>
            p.__typename === 'Offer' ? (
              <OfferPhase key={p.id} offer={p} order={order} />
            ) : (
              <ConfirmationPhase confirmation={p} key={p.id} order={order} />
            )
          )}
        </FlexColumn>
      )}

      <OtherPhaseActions
        actions={validatedActions}
        orderId={id}
        noPhases={!otherPhases.length}
      />
    </FlexColumn>
  )
}

/////

const compareFn = (a: Invoice, b: Invoice) => {
  const aAcceptedDate = a.auditLog.invoiceAccepted
  const bAcceptedDate = b.auditLog.invoiceAccepted

  if (aAcceptedDate && bAcceptedDate) {
    return moment(aAcceptedDate).valueOf() - moment(bAcceptedDate).valueOf()
  } else if (aAcceptedDate || bAcceptedDate) {
    return (
      (moment(bAcceptedDate).valueOf() || 0) -
      (moment(aAcceptedDate).valueOf() || 0)
    )
  }

  const aCreatedDate = a.auditLog.createdAt
  const bCreatedDate = b.auditLog.createdAt

  return moment(bCreatedDate).valueOf() - moment(aCreatedDate).valueOf()
}
