import type { ApolloClient } from '@apollo/client'
import { gql } from '@apollo/client'
import moment, { HTML5_FMT, Moment } from 'moment'
import writeXlsxFile, { Schema } from 'write-excel-file'

import { ACTIVE_STATES } from '@/modules/Sales/types'
import { getStatesBySystemStates } from '@/modules/Sales/utils'
import { generateCompareFn } from '@/utils/arrays'
import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { typeGuardFactory } from '@/utils/types'

import type {
  KeycardExcelForDateQuery as QueryData,
  KeycardExcelForDateQueryVariables as QueryVariables,
  SalesStatesQuery,
} from '~generated-types'
import { SalesType } from '~generated-types'

type KeycardSales = QueryData['arrivingSalesForDate'][0]
type KeycardSalesCustomer = KeycardSales['customer']
type KeycardSalesParticipant = KeycardSales['participantConnection']['nodes'][0]

const QUERY = gql`
  fragment KeycardExcelForDateParticipant on SalesParticipant {
    firstName
    id
    lastName
    services {
      id

      ... on ServiceParticipantBed {
        dates {
          checkIn {
            date
          }
          checkOut {
            date
          }
        }
        participantRoom {
          id
          roomReservation {
            id
            request {
              checkIn {
                date
              }
              checkOut {
                date
              }
              room {
                id
                number
              }
            }
          }
        }
      }
    }
  }

  query KeycardExcelForDate(
    $input: SalesForDateInput!
    $enrollmentStates: [String!]
  ) {
    arrivingSalesForDate(input: $input) {
      accommodation {
        id
        roomReservations {
          id
          participantRooms {
            id
          }
          request {
            beds
            checkIn {
              date
            }
            checkOut {
              date
            }
            extraBeds
            room {
              id
              number
            }
          }
        }
      }
      customer {
        customer {
          id

          ... on CustomerOrganization {
            organization {
              name
            }
          }

          ... on CustomerPerson {
            person {
              firstName
              lastName
            }
          }
        }
      }
      id
      lifecycle {
        state {
          key
          systemState
        }
      }
      name
      participantConnection(
        input: {
          filter: { enrollmentStates: $enrollmentStates }
          pagination: { size: 10000 }
        }
      ) {
        nodes {
          ...KeycardExcelForDateParticipant
        }
      }
      type
    }
  }
`

interface DataRow {
  guestName: string
  roomNumber: string
  targetName: string
  visitDays: string
}

interface Input {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>
  salesStates: SalesStatesQuery['registry']['salesStates']
  targetDate: Moment
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default async function generateKeycardExcel({
  client,
  salesStates,
  targetDate,
}: Input): Promise<void> {
  const dateKey = targetDate.format('YYYY-MM-DD')

  const { data } = await client.query<QueryData, QueryVariables>({
    fetchPolicy: 'no-cache',
    query: QUERY,
    variables: {
      enrollmentStates: getStatesBySystemStates(salesStates, ACTIVE_STATES),
      input: { date: dateKey },
    },
  })

  if (!data || !data.arrivingSalesForDate) {
    throw new Error('Failed to fetch keycard data')
  }

  const emptyRoomRows: DataRow[] = data.arrivingSalesForDate
    .filter(({ lifecycle }) =>
      ACTIVE_STATES.includes(lifecycle.state.systemState)
    )
    .map(({ accommodation, customer, name, type }) =>
      accommodation.roomReservations.map((x) => ({
        ...x,
        customer,
        salesName: name,
        salesType: type,
      }))
    )
    .flat()
    .filter(({ request }) => request.checkIn.date === dateKey)
    .map(({ customer, participantRooms, request, salesName, salesType }) => {
      return new Array(
        Math.max(request.beds + request.extraBeds - participantRooms.length, 0)
      )
        .fill(0)
        .map(() => ({
          guestName: '',
          roomNumber: request.room.number,
          targetName:
            salesType === SalesType.Event
              ? salesName || ''
              : getCustomerName(customer),
          visitDays: formatDateRangeWithoutRepetition(
            request.checkIn.date,
            request.checkOut.date,
            'short'
          ),
        }))
    })
    .flat()

  let participantRoomRows: DataRow[] = []

  const openSales = data.arrivingSalesForDate.filter(({ lifecycle }) =>
    ACTIVE_STATES.includes(lifecycle.state.systemState)
  )

  openSales.forEach((x) => {
    const targetName =
      x.type === SalesType.Event ? x.name || '' : getCustomerName(x.customer)
    const salesRows: DataRow[] = []
    const participants: KeycardSalesParticipant[] =
      x.participantConnection.nodes

    participants.forEach((p) => {
      const guestName = getParticipantName(p)
      const participantBedServices = p.services.filter(
        typeGuardFactory('__typename', 'ServiceParticipantBed')
      )
      const arrivingService = participantBedServices.find(
        (service) =>
          service.dates &&
          moment(service.dates.checkIn.date).isSame(targetDate, 'day')
      )
      const roomIdServices = participantBedServices
        .filter(
          (service) =>
            service.participantRoom?.roomReservation.request.room.id ===
            arrivingService?.participantRoom?.roomReservation.request.room.id
        )
        .sort(
          generateCompareFn(
            'participantRoom.roomReservation.request.checkIn.date'
          )
        )

      const arrivingRoomServices = [roomIdServices[0]]
      arrivingRoomServices.pop()
      arrivingService && arrivingRoomServices.push(arrivingService)
      let skipOtherServices = false

      roomIdServices.forEach((service, index) => {
        if (
          index === 0 ||
          skipOtherServices ||
          !service.participantRoom ||
          !roomIdServices[index - 1].participantRoom
        )
          return
        if (
          service.participantRoom.roomReservation.request.checkIn.date !==
          roomIdServices[index - 1].participantRoom?.roomReservation.request
            .checkOut.date
        ) {
          skipOtherServices = true
          return
        }

        arrivingRoomServices.push(service)
      })

      const guestDates = {
        checkIn: {
          date: arrivingRoomServices
            .map(({ dates }) => dates?.checkIn.date)
            .reduce((p, c) => (p ? (moment(p).isBefore(c) ? p : c) : c), ''),
        },
        checkOut: {
          date: arrivingRoomServices
            .map(({ dates }) => dates?.checkOut.date)
            .reduce((p, c) => (p ? (moment(p).isBefore(c) ? c : p) : c), ''),
        },
      }
      const roomReservation =
        arrivingService?.participantRoom?.roomReservation.request
      const roomNumber = roomReservation?.room.number || ''

      const visitCheckIn =
        guestDates?.checkIn.date || roomReservation?.checkIn.date
      const visitCheckOut =
        guestDates?.checkOut.date || roomReservation?.checkOut.date

      const visitDays =
        visitCheckIn && visitCheckOut
          ? formatDateRangeWithoutRepetition(
              visitCheckIn,
              visitCheckOut,
              'short'
            )
          : ''

      const arrivesToday =
        visitDays && moment(visitCheckIn).isSame(targetDate, 'day')

      if (arrivesToday && roomNumber) {
        salesRows.push({
          guestName,
          roomNumber,
          targetName,
          visitDays,
        })
      }
    })

    participantRoomRows = [...participantRoomRows, ...salesRows]
  })

  const schema: Schema<DataRow> = [
    {
      column: 'Asiakas',
      value: ({ targetName }) => targetName,
      width: 20,
    },
    {
      column: 'Huone',
      value: ({ roomNumber }) => roomNumber,
      width: 14,
    },
    {
      column: 'Majoittuja',
      value: ({ guestName }) => guestName,
      width: 20,
    },
    {
      column: 'Päivät',
      value: ({ visitDays }) => visitDays,
      width: 14,
    },
  ]

  const rows = [...emptyRoomRows, ...participantRoomRows].sort((a, b) => {
    if (a.targetName === b.targetName) {
      if (a.roomNumber === b.roomNumber) {
        return a.guestName.localeCompare(b.guestName)
      }

      return a.roomNumber.localeCompare(b.roomNumber, 'fi', {
        numeric: true,
      })
    }

    return a.targetName.localeCompare(b.targetName)
  })

  await writeXlsxFile(rows, {
    fileName: `avainkortit-${targetDate.format(HTML5_FMT.DATE)}.xlsx`,
    schema,
  })
}

////////////

const getCustomerName = (customer: KeycardSalesCustomer): string => {
  if (!customer?.customer) {
    return ''
  }

  if (customer?.customer.__typename === 'CustomerOrganization') {
    return customer.customer.organization.name || ''
  } else {
    return `${customer.customer.person.lastName} ${customer.customer.person.firstName}`.trim()
  }
}

const getParticipantName = ({
  firstName,
  lastName,
}: KeycardSalesParticipant): string =>
  `${lastName}${firstName && lastName ? ' ' : ''}${firstName}`
