import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { FetchState, FetchStates } from '@/common/types'
import {
  ElasticRoom,
  ElasticRoomGroup,
  MomentDateRange,
  RoomLayoutQueries,
  RoomLayoutService,
} from '@/modules/Reservations/components/RoomLayout'

interface UseRoomLayoutRoomGroupsProps {
  accommodationLevelIds: string[]
  buildingIds: string[]
  dateRange: MomentDateRange
  featureIds: string[]
  roomSize: string
  updateTicker: number
}

interface UseRoomLayoutRoomGroupsHook {
  groups: ElasticRoomGroup[]
  setGroups: (groups: ElasticRoomGroup[]) => void
  status: FetchState
}

export default function useRoomLayoutRooms({
  accommodationLevelIds,
  buildingIds,
  dateRange,
  featureIds,
  roomSize,
  updateTicker,
}: UseRoomLayoutRoomGroupsProps): UseRoomLayoutRoomGroupsHook {
  const [groups, setGroups] = useState<ElasticRoomGroup[]>([])
  const [roomIds, setRoomIds] = useState<string[]>([])
  const [status, setStatus] = useState<FetchState>(FetchStates.LOADING)

  const roomStatuses = useQuery(RoomLayoutQueries.ROOM_CLEANING_STATUSES, {
    skip: !roomIds.length,
    variables: {
      from: dateRange.from.format('YYYY-MM-DD'),
      ids: roomIds,
      to: moment().format('YYYY-MM-DD'),
    },
  })

  /**
   * Calculate primitive representations for all input props to know when to
   * actually run the update. We can't guarantee that the array and object
   * references are the same across renders and it is not up to this component.
   */
  //
  const accommodationLevelIdsHash = accommodationLevelIds.sort().join(';')
  const buildingIdsHash = buildingIds.sort().join(';')
  const featuresHash = featureIds.sort().join(';')

  useEffect(() => {
    setStatus(FetchStates.LOADING)

    RoomLayoutService.fetchRooms(
      accommodationLevelIds,
      buildingIds,
      featureIds,
      roomSize
    ).then(({ data, ok }) => {
      if (roomStatuses.loading) {
        setStatus(FetchStates.LOADING)
      }

      if (!ok || roomStatuses.error) {
        setGroups([])
        setStatus(FetchStates.ERROR)
      }

      if (ok && !data.length) {
        setGroups([])
        setStatus(FetchStates.EMPTY)
      }

      if (ok && data.length) {
        setStatus(FetchStates.IDLE)
        setRoomIds(data.map((room) => room.id))

        if (roomStatuses.data?.registry.roomsByIds.length) {
          const groups = parseRoomGroups(data)

          setGroups(
            groups.map((group: ElasticRoomGroup) => ({
              ...group,
              rooms: group.rooms.map((room: ElasticRoom) => {
                const roomStatus = roomStatuses.data?.registry.roomsByIds.find(
                  (r: any) => r.id === room.id
                )

                return { ...room, status: roomStatus?.status }
              }),
            }))
          )
        }
      }
    })
    // Exhaustive deps intentionally disabled here. We cannot use the input prop
    // values here as they are not primitives.
    //
  }, [
    accommodationLevelIdsHash,
    buildingIdsHash,
    featuresHash,
    roomSize,
    roomStatuses,
  ])

  useEffect(() => {
    roomStatuses.refetch()
  }, [updateTicker])

  return {
    groups,
    setGroups,
    status,
  }
}

////////////

const parseRoomGroups = (rooms: ElasticRoom[]): ElasticRoomGroup[] => {
  const groups: Record<string, ElasticRoomGroup> = {}

  rooms.forEach((room: ElasticRoom) =>
    groups[room.building.id]
      ? (groups[room.building.id].rooms = [
          ...groups[room.building.id].rooms,
          room,
        ])
      : (groups[room.building.id] = {
          building: room.building.name,
          groupId: room.building.id,
          rooms: [room],
        })
  )

  return Object.values(groups)
}
