import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { ContentSidebar } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { DateWeekComboPicker } from '@/components/TimeControls'
import { CategorySelection } from '@/modules/Reservations'

import {
  CategorySearchGroups,
  DateWeekControl,
  FocusedResourcesCheckbox,
  ResourceSelector,
  ResourceSelectorProps,
  Separator,
} from './components'

type Props = ResourceSelectorProps & {
  date: Moment
  isHidden: boolean
  mode: 'DATE' | 'WEEK'
  setDate: (arg0: Moment) => void
  addCategorySelection: () => CategorySelection
  ownerId: string | null
  removeCategorySelection: (selectionId: string) => void
  selectedCategories: CategorySelection[]
  setShowFocusedResources: (showFocusedResources: boolean) => void
  showFocusedResources: boolean
  updateCategorySelection: (
    selectionId: string,
    update: {
      [key: string]: any
    }
  ) => void
}

export const ResourceCalendarSidebar = ({
  addCategorySelection,
  addResourceSelection,
  allResources,
  date,
  isHidden,
  loadingResources,
  mode,
  ownerId,
  removeCategorySelection,
  removeResourceSelection,
  selectedCategories,
  selectedResources,
  setShowFocusedResources,
  showFocusedResources,
  setDate,
  updateCategorySelection,
}: Props) => (
  <ContentSidebar
    key="control-row"
    style={{
      border: 0,
      display: isHidden ? 'none' : 'block',
      overflow: 'initial',
    }}
  >
    <Wrapper>
      <DateWeekControl date={date} mode={mode} setDate={setDate} />

      <Separator />

      <DateWeekComboPicker
        mode={mode}
        setValue={(value) => setDate(value || moment())}
        value={date}
      />

      <Separator />

      {ownerId && (
        <>
          <Gutter type={2}>
            <FocusedResourcesCheckbox
              mode={mode}
              setShowFocusedResources={setShowFocusedResources}
              showFocusedResources={showFocusedResources}
            />
          </Gutter>

          <Separator />
        </>
      )}

      <Gutter type={2}>
        <ResourceSelector
          addResourceSelection={addResourceSelection}
          allResources={allResources}
          loadingResources={loadingResources}
          removeResourceSelection={removeResourceSelection}
          selectedResources={selectedResources}
        />
      </Gutter>

      <Separator />

      <Gutter type={2}>
        <CategorySearchGroups
          add={addCategorySelection}
          data={selectedCategories}
          remove={removeCategorySelection}
          update={updateCategorySelection}
        />
      </Gutter>
    </Wrapper>
  </ContentSidebar>
)

export default ResourceCalendarSidebar

export type ResourceCalendarSidebarProps = Props

////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: -5px 0 5px -2px rgba(0, 0, 0, 0.1);

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(12)}rem;
  `}
`
