import { gql } from '@apollo/client'

import { RoomReservationsForDateParticipantFragment } from './roomReservationsForDateParticipant'

export const RoomReservationsForDateParticipantRoomFragment = gql`
  ${RoomReservationsForDateParticipantFragment}

  fragment RoomReservationsForDateParticipantRoom on ParticipantRoom {
    checkIn
    checkOut
    id
    participant {
      ...RoomReservationsForDateParticipant
    }
    request {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
    }
  }
`
