import moment from 'moment'

import { T } from '@/modules/Language'
import { Section, Subsection } from '@/modules/Order/components'

type Props = {
  createdAt: string
}

export const DetailsSection = ({ createdAt }: Props) => (
  <Section flex={1}>
    <Subsection title={<T>Orders:Details.created</T>}>
      {moment(createdAt).format('dd, D.M.YYYY')}
    </Subsection>
  </Section>
)
