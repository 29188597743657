import styled, { css } from 'styled-components/macro'

export const ActionDropdownClickable = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
    background: ${theme.palette.white};
    color: ${theme.palette.text.main};
  `}

  transition: all 0.2s ease-in;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`
