import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  isLoading: boolean
  onClick: () => void
  readOnly: boolean
}

export const ProgramReservationButton = ({
  isLoading,
  onClick,
  readOnly,
}: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <Button disabled={readOnly} onClick={onClick}>
      {isLoading ? (
        <LoaderWrapper>
          <ReactLoading
            color={palette.primary.main}
            height={`${spacing.gu(2)}rem`}
            type="spin"
            width={`${spacing.gu(2)}rem`}
          />
        </LoaderWrapper>
      ) : (
        <PlusIcon icon="plus" />
      )}

      <PrimaryColor>
        <T>ResourceReservations:action.addProgramReservation</T>
      </PrimaryColor>
    </Button>
  )
}

//////

const Button = styled(InnocuousButton)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const PlusIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const LoaderWrapper = styled.div`
  display: inline-flex;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(2)}rem;
  `}
`
