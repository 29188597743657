import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { translate } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'
import {
  ElasticRoom,
  ElasticRoomReservation,
} from '@/modules/Reservations/components/RoomLayout'

import { RoomSection } from './RoomSection'

type Props = {
  isFirst: boolean
  isLast: boolean
  isLoading: boolean
  groupId: string
  reservations: ElasticRoomReservation[]
  sections: ElasticRoom[]
  suitableRoomsNumber: number
  title: ReactNode | null | undefined
  updateRoomReservation: (...args: Array<any>) => any
}

export const RoomGroup = ({
  isFirst,
  isLast,
  isLoading,
  groupId,
  reservations,
  sections,
  suitableRoomsNumber,
  title,
  updateRoomReservation,
}: Props) => {
  const { language } = useLanguageContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)

  const getReservationsByRoom = (roomId: string) =>
    reservations.filter(
      (reservation: ElasticRoomReservation) => reservation.room.id === roomId
    )

  const sortRoomsByNumber = (a: ElasticRoom, b: ElasticRoom) =>
    a.number.localeCompare(b.number, language, { numeric: true })

  return (
    <Wrapper>
      <GroupHeader height={36} isFirst={isFirst}>
        <HeaderLabel
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <FlexRow>
            {title}

            {!!suitableRoomsNumber && (
              <SuitableRoomsLabel>
                {'  –  '}
                <LightLabel>
                  {translate('RoomLayout:sidebar.suitableRoom', language, {
                    count: suitableRoomsNumber,
                  })}
                </LightLabel>
              </SuitableRoomsLabel>
            )}
          </FlexRow>

          <span>
            {translate('RoomLayout:cleaningManager.room', language, {
              count: sections.length,
            })}
          </span>
        </HeaderLabel>
      </GroupHeader>
      <GroupContent isLast={isLast}>
        {sections.sort(sortRoomsByNumber).map((s: ElasticRoom, idx: number) => (
          <RoomSection
            section={s}
            groupId={groupId}
            isLast={idx === sections.length - 1}
            isLoading={isLoading}
            isProcessing={isProcessing}
            key={`room-layout-group-section-${s.id}`}
            reservationsByRoom={getReservationsByRoom(s.id)}
            reservations={reservations}
            setProcessing={setProcessing}
            updateRoomReservation={updateRoomReservation}
          />
        ))}
      </GroupContent>
    </Wrapper>
  )
}

////////////

const GroupContent = styled.div<{ isLast: boolean }>`
  display: inline-flex;
  flex-direction: column;
  border-bottom: ${({ isLast, theme }) =>
    isLast ? `1px solid ${theme.palette.smoke.dark}` : 0};
`

const GroupHeader = styled.div<{ height: number; isFirst: boolean }>`
  position: sticky;
  display: flex;
  top: ${({ isFirst, theme }) =>
    isFirst
      ? `calc(${theme.spacing.gu(13)}rem + 1px)`
      : `${theme.spacing.gu(13)}rem`};
  left: 0;
  z-index: 3050;

  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  border-top: ${({ isFirst, theme }) =>
    isFirst ? 0 : `1px solid ${theme.palette.smoke.dark}`};

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    border-bottom: 1px solid ${theme.palette.smoke.dark};

    max-width: calc(100vw - ${theme.spacing.gu(60)}rem);
    min-width: calc(100vw - ${theme.spacing.gu(60)}rem);
  `}
`

const HeaderLabel = styled(FlexRow)`
  font-weight: 500;
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
    padding: 0 ${theme.spacing.gu(6)}rem;
  `}
`

const LightLabel = styled.div`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.success.main};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const SuitableRoomsLabel = styled(FlexRow)`
  white-space: pre;
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`
