import { translate } from '@/modules/Language'

import { SalesForPrintAccommodationTarget as Target } from '../../../types'

export const getTargetName = (target: Target, language: string) =>
  target.default
    ? translate('Accommodation:TargetGroup.default', language)
    : target.name ||
      `${translate(`Accommodation:TargetGroup.target`, language)} #${
        target.sortOrder
      }`
