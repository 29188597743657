import { gql } from '@apollo/client'

export default gql`
  mutation SetCustomerDefaultAddress($input: CustomerSetDefaultAddressInput!) {
    customerSetDefaultAddress(input: $input) {
      customer {
        defaultAddressId
        id
      }
    }
  }
`
