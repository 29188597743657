import { generateCompareFn } from '@/utils/arrays'

import {
  AccommodationLevel,
  GroupedRoomTypeAvailability,
  RoomTypeAvailability,
  RoomTypeCategory,
} from '../AvailabilityCalendar.types'

type AccommodationLevelsById = {
  [id: string]: {
    accommodationLevel: AccommodationLevel
    categoriesById: {
      [id: string]: {
        category: RoomTypeCategory
        roomTypes: RoomTypeAvailability[]
      }
    }
  }
}

export const groupRoomTypeAvailabilityByLevelsAndCategories = (
  input: RoomTypeAvailability[]
): GroupedRoomTypeAvailability[] => {
  const levelsById = input.reduce((acc: AccommodationLevelsById, val) => {
    const accommodationLevel = val.roomType.accommodationLevel
    const category = val.roomType.category

    acc[accommodationLevel.id] = acc[accommodationLevel.id] || {
      accommodationLevel,
      categoriesById: {},
    }

    acc[accommodationLevel.id].categoriesById[category.id] = acc[
      accommodationLevel.id
    ].categoriesById[category.id] || {
      category,
      roomTypes: [],
    }

    acc[accommodationLevel.id].categoriesById[category.id].roomTypes.push(val)

    return acc
  }, {})

  return Object.keys(levelsById)
    .map((id) => levelsById[id])
    .map(({ accommodationLevel, categoriesById }) => ({
      accommodationLevel,
      categories: Object.keys(categoriesById)
        .map((id) => categoriesById[id])
        .sort(generateCompareFn('category.name')),
    }))
    .sort(generateCompareFn('accommodationLevel.name'))
}
