import { ReservationsOffset } from '@/components/ReservationsOffset'
import { generateCompareFn } from '@/utils/arrays'

import { AccommodationIssueFragment } from '~generated-types'

import { useReservationListContext } from './ReservationListState'
import {
  SalesAccommodationGroup,
  SalesRoomReservation,
  SalesRoomTypeReservation,
} from './SalesReservationList.types'

type Props = {
  idsToMove: string[]
  readOnly?: boolean
  issues: AccommodationIssueFragment[]
  onCancel: () => void
  handleMoveReservations: (days: number) => Promise<void>
  setIdsToMove: (ids: string[]) => void
}

export const ReservationsOffsetContainer = ({
  idsToMove,
  readOnly,
  issues,
  onCancel,
  handleMoveReservations,
  setIdsToMove,
}: Props) => {
  const { reservationGroups } = useReservationListContext()

  const targetsList: SalesAccommodationGroup['targets'] = reservationGroups
    .map(({ targets }) => targets)
    .flat()
  const selectedTargetsReservations = reservationGroups
    .map(({ roomReservations, roomTypeReservations }) => [
      ...roomReservations,
      ...roomTypeReservations,
    ])
    .flat()
    .filter((reservation) => idsToMove.includes(reservation.target?.id ?? ''))
    .sort(generateCompareFn('request.checkIn.date'))
  const firstReservationDate = (
    selectedTargetsReservations[0] as
      | SalesRoomReservation
      | SalesRoomTypeReservation
  )?.request?.checkIn.date

  const handleSelectAllTargets = () => {
    setIdsToMove(
      idsToMove.length === targetsList.length
        ? []
        : targetsList.map(({ id }) => id)
    )
  }

  return (
    <ReservationsOffset
      onApply={handleMoveReservations}
      onCancel={onCancel}
      startDate={firstReservationDate}
      totalSelectionsAmount={targetsList.length}
      selectedAmount={idsToMove.length}
      issues={issues}
      onSelectAll={handleSelectAllTargets}
      hideMinutes
      readOnly={readOnly}
    />
  )
}
