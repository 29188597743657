import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { Request } from '@/modules/FrontDesk'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

type Props = {
  request: Request
}

export const Dates = ({
  request: { beds, checkIn, checkOut, extraBeds },
}: Props) => (
  <Wrapper noPadding alignItems="center">
    {formatDateRangeWithoutRepetition(checkIn.date, checkOut.date, 'short')}
    <RequestedBeds>
      {beds} + {extraBeds}
    </RequestedBeds>
  </Wrapper>
)

////////////

const Wrapper = styled(FlexColumn)`
  text-align: center;
  width: 120px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(0.5)}rem;
  `};
`

const RequestedBeds = styled.small`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `};
`
