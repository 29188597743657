import styled, { css } from 'styled-components/macro'

import { Input } from '../Input'
import { InputWrapper } from '../InputWrapper'

const TextareaPrimitive = styled(Input.withComponent('textarea'))`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
      min-height: ${theme.spacing.gu(10)}rem;
    `}
  }
`

const Textarea = (props: any) => (
  <InputWrapper>
    <TextareaPrimitive {...props} />
  </InputWrapper>
)

export default Textarea
