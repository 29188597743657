import { ThemedMultiSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import {
  AccommodationLevel,
  accommodationLevelHooks,
} from '@/modules/Registry/AccommodationLevel'
import { useTheme } from '@/theme'

import { FilterWrapper } from './FilterWrapper'

type Props = {
  fullWidth?: boolean
  targetLevelIds: string[]
  setTargetLevelIds: (targetLevelIds: string[]) => void
}

export const AccommodationLevelSelector = ({
  fullWidth,
  targetLevelIds,
  setTargetLevelIds,
}: Props) => {
  const theme = useTheme()

  const { accommodationLevels, loading } =
    accommodationLevelHooks.useAccommodationLevels()

  const renderSelector = () => (
    <ThemedMultiSelect
      loading={loading}
      modalWidth={`${theme.spacing.gu(30)}rem`}
      noOptionsPlaceholder={
        <T>RoomLayout:controlRow.empty.noAccommodationLevels</T>
      }
      options={levelsToOptions(accommodationLevels)}
      placeholder={<T>RoomLayout:controlRow.placeholder.all</T>}
      selectedValues={targetLevelIds}
      setSelectedValues={setTargetLevelIds}
    />
  )

  return fullWidth ? (
    renderSelector()
  ) : (
    <FilterWrapper
      label={<T>RoomLayout:controlRow.label.accommodationLevel</T>}
      width={`${theme.spacing.gu(30)}rem`}
    >
      {renderSelector()}
    </FilterWrapper>
  )
}

const levelsToOptions = (levels: AccommodationLevel[]) =>
  levels.map((l: AccommodationLevel) => ({
    label: l.name,
    value: l.id,
  }))
