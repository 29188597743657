import { Row, Worksheet } from 'exceljs'

import { Palette } from '@/theme'

import { hexToArgb } from './utils'

type Input = {
  palette: Palette
  sheet: Worksheet
  translateFn: (keys: string | Array<string>) => string
}

export const addTitle = ({ palette, sheet, translateFn }: Input) => {
  const newRow: Row = sheet.addRow([translateFn('Excel.title')])

  newRow.font = {
    bold: true,
    color: {
      argb: hexToArgb(palette.text.dark),
    },
    size: 16,
  }

  sheet.mergeCells('A1:M1')
}
