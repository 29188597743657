import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ListItemSection as Section,
  ListItemSectionIconValue as IconValue,
} from '@/components/List'
import { FontWeight } from '@/components/Typography'
import { ElasticSalesContext } from '@/modules/Sales/types'
import { useTheme } from '@/theme'

const DATE_FORMAT = 'dd DD.MM.YY'

type Props = {
  sales: ElasticSalesContext
}

const SalesInfo = ({ sales }: Props) => {
  const theme = useTheme()

  const handleOpenSales = (id: string) => {
    const url = `/sales/details/${id}?view[0]=tasks`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <SalesLinkSection
      onClick={(e) => {
        e.stopPropagation()
        handleOpenSales(sales.id)
      }}
    >
      <FlexRow alignItems="center" justifyContent="flex-start">
        {sales.searchDates && (
          <DatesWrapper alignItems="flex-start">
            <IconValue
              icon="right-to-bracket"
              value={moment(sales.searchDates?.start).format(DATE_FORMAT)}
            />
            <IconValue
              icon="right-from-bracket"
              value={moment(sales.searchDates?.end).format(DATE_FORMAT)}
            />
          </DatesWrapper>
        )}
        <FlexColumn alignItems="flex-start" justifyContent="center" noPadding>
          <small>
            <span style={{ paddingRight: theme.spacing.gutterSmall }}>
              #{sales.number}
            </span>
            {' | '}
            <FacetLabel bold>{sales.facet?.abbreviation || 'TAP'}</FacetLabel>
            <FontAwesomeIcon
              size="sm"
              icon="up-right-from-square"
              style={{
                marginBottom: 1,
                marginLeft: `${theme.spacing.gu(1)}rem`,
              }}
              color={theme.palette.primary.dark}
            />
          </small>
          {sales.name && <Title>{sales.name}</Title>}
        </FlexColumn>
      </FlexRow>
    </SalesLinkSection>
  )
}

export default SalesInfo

////////////

const SalesLinkSection = styled(Section)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}

  &:hover {
    opacity: 0.6;

    ${({ theme }) => css`
      background-color: ${theme.palette.white};
    `}
  }
`

const FacetLabel = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeSmall};
    margin-left: ${theme.spacing.gutterSmall};
  `}
`

const Title = styled.div`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const DatesWrapper = styled(FlexColumn)`
  width: 100px;
  white-space: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
