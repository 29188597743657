import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Placeholder as PlaceholderBase } from '@/components/Placeholder'

type Props = {
  children: ReactNode
}

export const CalendarPlaceholder = ({ children }: Props) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
)

////////////

const Wrapper = styled.div`
  max-width: calc(100vw - 425px);
  min-width: calc(100vw - 425px);
  position: sticky;
  top: 0;
  left: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled(PlaceholderBase.withComponent('span'))`
  min-height: 25vh;
`
