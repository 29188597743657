import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ElasticSales } from '@/modules/Sales/types'
import { formatCurrency } from '@/utils/currency'

import { SalesPaymentInfoQuery } from '~generated-types'

type GraphQLSales = SalesPaymentInfoQuery['salesAll'][0]

type Props = {
  gqlItem: GraphQLSales | null
  item: ElasticSales
}

export const Customer = ({
  gqlItem,
  item: { customerName, customerNumber },
}: Props) => (
  <Wrapper
    flex={1}
    noPadding
    justifyContent="space-between"
    alignItems="flex-end"
  >
    {customerName && customerNumber ? <Name>{customerName}</Name> : <div />}

    {gqlItem && (
      <TotalPrice>
        {formatCurrency(
          gqlItem?.paymentInfo?.totalPrice.amountVatIncluded || 0
        )}{' '}
        €
      </TotalPrice>
    )}
  </Wrapper>
)

////////////

const Name = styled.span`
  text-align: end;
  overflow-wrap: anywhere;
`

const TotalPrice = styled.span<{ color?: string }>`
  margin-top: 5px;
  font-weight: 600;
  white-space: nowrap;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.gu(1)}rem;
  `}
`
