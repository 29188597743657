import styled, { css } from 'styled-components/macro'

import type { PropsWithTheme } from '@/theme'

type Props = {
  color?: string
  disabled?: boolean
}

const findColor = ({
  color,
  disabled,
  theme,
}: PropsWithTheme<Props>): string =>
  disabled ? theme.palette.text.lighter : color || theme.palette.text.light

export const Label = styled.label`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
    font-family: ${theme.typography.fontFamily};
  `}

  font-weight: 600;
  color: ${(props): string => findColor(props)};
  letter-spacing: '.03rem';

  cursor: ${(props: Props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

export default Label
