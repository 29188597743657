import { RadioInput } from '@/components/FormControls'

import TD from '../elements/TD'

const RadioButtonCell = ({
  colSpan,
  rowSpan,
  ...props
}: {
  [key: string]: any
}) => (
  <TD colSpan={colSpan} noWrap rowSpan={rowSpan}>
    <RadioInput noMargin {...props} />
  </TD>
)

export default RadioButtonCell
