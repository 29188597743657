import { gql, useMutation } from '@apollo/client'

import {
  SetRoomReservationCheckMutation,
  SetRoomReservationCheckMutationVariables,
} from '~generated-types'

import { RoomReservationFragment } from '../fragments'

export type {
  SetRoomReservationCheckMutation,
  SetRoomReservationCheckMutationVariables,
}

export const SET_ROOM_RESERVATION_CHECK = gql`
  ${RoomReservationFragment}

  mutation SetRoomReservationCheck(
    $input: AccommodationRoomReservationCheckInput!
  ) {
    accommodationRoomReservationSetCheck(input: $input) {
      roomReservations {
        ...RoomReservationsForDateRoomReservation
      }
    }
  }
`

export function useSetRoomReservationCheck() {
  return useMutation<
    SetRoomReservationCheckMutation,
    SetRoomReservationCheckMutationVariables
  >(SET_ROOM_RESERVATION_CHECK)
}

export default useSetRoomReservationCheck
