import { CategorySet } from '@/modules/Registry/Category'

export const getSelectionsCount = (
  set: CategorySet,
  selectedPaths: string[]
): number => {
  const rootPaths = set.rootCategories.map(({ id }) => `/${id}`)

  return selectedPaths.filter(
    (path) => !!rootPaths.find((rootPath) => path.startsWith(rootPath))
  ).length
}
