import { gql, useMutation } from '@apollo/client'

import type {
  UpdateCatalogProductItemMealMutation,
  UpdateCatalogProductItemMealMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productMeal}

  mutation UpdateCatalogProductItemMeal($input: ProductMealInput!) {
    catalogProductItemUpdateMeal(input: $input) {
      ...ProductMeal
    }
  }
`

export const useUpdateCatalogProductItemMealMutation = () =>
  useMutation<
    UpdateCatalogProductItemMealMutation,
    UpdateCatalogProductItemMealMutationVariables
  >(MUTATION)
