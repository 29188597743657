import styled, { css } from 'styled-components/macro'

type Props = {
  noShadow?: boolean
}

export const InlineFieldButton = styled.button.attrs(() => ({
  type: 'button',
}))<Props>`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  font-family: inherit;
  font-size: inherit;

  background: none;
  border: none;
  border-radius: 4px;

  &[disabled] {
    color: unset;
    cursor: not-allowed;
  }
  &:not([disabled]) {
    cursor: pointer;
  }

  &:not([disabled]):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:not([disabled]):active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  ${({ noShadow, theme }) =>
    noShadow
      ? ''
      : css`
          &:not([disabled]):focus,
          &:not([disabled]):active,
          &:not([disabled]).active {
            box-shadow: 0 0 1px 2px ${theme.palette.primary.main};
          }
        `}
`
