import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chip } from '@/components/Chip'

import { ListingRoomFeatureFragment } from '~generated-types'

type Props = {
  feature: ListingRoomFeatureFragment
}

export const FeatureChip = ({ feature: { icon, shortName } }: Props) => (
  <Chip color="primary" size="xs" variant="outlined">
    {icon ? (
      <FontAwesomeIcon icon={icon as IconProp} />
    ) : (
      <span>{shortName}</span>
    )}
  </Chip>
)

export default FeatureChip
