import { MouseEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { ActionDropdown } from '@/components/ActionDropdown'
import { Chip } from '@/components/Chip'
import { DataField } from '@/components/DataField'
import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Placeholder } from './common'

type Props = {
  closeTask: () => Promise<void>
  isOpen: boolean
  name: string | null
  number: number
  openTask: () => Promise<void>
  readOnly: boolean
  updateName: (name: string) => Promise<void>
}

export const BasicDetails = ({
  closeTask,
  isOpen,
  name,
  number,
  openTask,
  readOnly,
  updateName,
}: Props) => {
  const theme = useTheme()

  return (
    <InlineModalIconSection icon={['far', 'square-check']}>
      <FlexRow alignItems="center">
        <DataField
          disabled={readOnly}
          handleSubmit={(name: string | null) => updateName(name || '')}
          hideEditIcon
          inputType="text"
          placeholder={
            <Placeholder>
              <T>Tasks:Task.namePlaceholder</T>
            </Placeholder>
          }
          style={{
            height: '30px',
            marginLeft: `-${theme.spacing.gu(1)}rem`,
          }}
          value={name || ''}
          valueColor={theme.palette.text.light}
          valueStyle={{
            fontWeight: name ? 600 : 400,
            height: '30px',
            padding: `${theme.spacing.gu(1)}rem`,
          }}
          variant="primary"
        />

        <Number>#{number}</Number>

        <Spacer />

        <ActionDropdown
          items={[
            isOpen
              ? {
                  icon: 'check',
                  label: <T>Tasks:TaskActions.close</T>,
                  onClick: closeTask,
                }
              : {
                  icon: 'clock-rotate-left',
                  label: <T>Tasks:TaskActions.open</T>,
                  onClick: openTask,
                },
          ]}
          renderToggle={({ toggleDropdown }) =>
            isOpen ? (
              <CustomChip
                color="primary"
                disabled={readOnly}
                onClick={(e: MouseEvent<HTMLElement>) =>
                  !readOnly ? toggleDropdown(e) : null
                }
                variant="outlined"
              >
                <T>Tasks:Task.state.open</T>
                {!readOnly && (
                  <FontAwesomeIcon
                    color={theme.palette.primary.dark}
                    icon="chevron-down"
                    size="xs"
                    style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
                  />
                )}
              </CustomChip>
            ) : (
              <CustomChip
                disabled={readOnly}
                onClick={(e: MouseEvent<HTMLElement>) =>
                  !readOnly ? toggleDropdown(e) : null
                }
                variant="outlined"
              >
                <T>Tasks:Task.state.closed</T>
                {!readOnly && (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size="xs"
                    style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
                  />
                )}
              </CustomChip>
            )
          }
        />
      </FlexRow>
    </InlineModalIconSection>
  )
}

//////

const CustomChip = styled(Chip)<{ disabled: boolean }>`
  display: flex;
  align-items: center;

  &:hover:not([disabled]) {
    cursor: pointer;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`

const Number = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter} 0 ${theme.spacing.gutterSmall};
    color: ${theme.palette.text.lighter};
  `}
`

const Spacer = styled.span`
  flex: 1;
`
