import {
  AvailabilityByDate,
  RawAvailabilityStatus,
} from '../AvailabilityCalendar.types'

export const groupAvailabilityStatusByDate = (
  status: RawAvailabilityStatus[]
): AvailabilityByDate =>
  status.reduce((acc: AvailabilityByDate, val) => {
    val.dates.forEach((date) => (acc[date] = val))

    return acc
  }, {})
