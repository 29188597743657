import styled, { css } from 'styled-components/macro'

const TreeEndCap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(3)}rem;
    height: ${theme.spacing.gu(3)}rem;
    margin-right: ${theme.spacing.gutterSmall};
  `}

  &::after {
    content: '';
    border-radius: 3px;
    width: 6px;
    height: 6px;

    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.main};
    `}
  }
`

export default TreeEndCap
