import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'

import { ArrowButton, ControlWrapper, TextButton, Wrapper } from './common'

type Props = {
  decrement: () => void
  increment: () => void
  setCurrentDay: () => void
  value: Moment
}

export const DateControl = ({
  decrement,
  increment,
  setCurrentDay,
  value,
}: Props) => {
  const { language } = useLanguageContext()

  return (
    <Wrapper alignItems="center" noPadding>
      <TextButton onClick={() => setCurrentDay()}>
        <T>ResourceReservationsCalendar:navigation.day</T>
      </TextButton>

      <ControlWrapper alignItems="center" justifyContent="space-between">
        <ArrowButton onClick={() => decrement()}>
          <FontAwesomeIcon icon="angle-left" />
        </ArrowButton>

        <Date>{value.locale(language).format('dd D.M.YYYY')}</Date>

        <ArrowButton onClick={() => increment()}>
          <FontAwesomeIcon icon="angle-right" />
        </ArrowButton>
      </ControlWrapper>
    </Wrapper>
  )
}

////////////

const Date = styled.div`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    margin: 0 ${theme.spacing.gu(1)}rem;
    color: ${theme.palette.text.light};
  `}
`
