import { Chip } from '@/components/Chip'

export interface CustomerLabelProps {
  id: string
  name: string
}

export const CustomerLabel = ({ name }: CustomerLabelProps) => (
  <Chip color="default" size="xs">
    {name}
  </Chip>
)
