import { RefObject, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { InlineModalIconSection } from '@/components/InlineModal'
import { DatePickerControl } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { EditButton, Placeholder } from './common'

type Props = {
  dueDate: string | null
  readOnly: boolean
  updateDueDate: (dueDate: string) => Promise<void>
}

export const DueDate = ({ dueDate, readOnly, updateDueDate }: Props) => {
  const theme = useTheme()

  const [isHover, setHover] = useState<boolean>(false)

  return (
    <InlineModalIconSection icon={['far', 'calendar']}>
      <DatePickerControl
        clearable
        placement="bottom-start"
        render={({ date, ref, toggleModal }) => (
          <EditButton
            disabled={readOnly}
            onClick={toggleModal}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            ref={ref as RefObject<HTMLButtonElement>}
          >
            {date ? (
              date.format('dddd, MMMM DD, YYYY')
            ) : (
              <Placeholder>
                <T>Tasks:Task.dueDatePlaceholder</T>
              </Placeholder>
            )}

            {isHover && (
              <FontAwesomeIcon
                color={theme.palette.text.lighter}
                icon="pen"
                size="sm"
              />
            )}
          </EditButton>
        )}
        setValue={(date) =>
          updateDueDate(date ? date.format('YYYY-MM-DD') : '')
        }
        value={dueDate ? moment(dueDate) : null}
      />
    </InlineModalIconSection>
  )
}
