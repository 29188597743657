import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Canvas } from '../Canvas'

const CanvasStretch = styled(Canvas)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

type Props = {
  children: ReactNode
}
export const Content = ({ children }: Props) => (
  <CanvasStretch>{children}</CanvasStretch>
)
