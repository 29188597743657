import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTheme } from '@/theme'

interface Props {
  showArrow?: boolean
}

export const SpacerCell = ({ showArrow }: Props) => {
  const theme = useTheme()

  return showArrow ? (
    <td
      className="horizontal-spacer"
      style={{ textAlign: 'center', verticalAlign: 'middle' }}
    >
      <FontAwesomeIcon
        color={theme.palette.text.light}
        icon="right-long"
        size="lg"
      />
    </td>
  ) : (
    <td className="horizontal-spacer" />
  )
}
