import styled, { css } from 'styled-components/macro'

import { Lessons } from './Lessons'
import { Reward } from './Reward'

export const LessonsAndReward = () => (
  <>
    <Lessons />

    <Spacer />

    <Reward />
  </>
)

////////

const Spacer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 1px;
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(3)}rem;
  `}
`
