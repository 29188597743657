import { gql } from '@apollo/client'

export default gql`
  mutation UpdateCustomerOrganization(
    $input: CustomerOrganizationUpdateInput!
  ) {
    customerOrganizationUpdate(input: $input) {
      customer {
        id
        organization {
          businessId
          name
        }
      }
    }
  }
`
