import styled, { css } from 'styled-components/macro'

export const LinkLikeButton = styled.button`
  height: auto;
  font-weight: 600;
  text-decoration: none;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gu(2)}rem;
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeBase};
    font-family: ${theme.typography.fontFamily};
  `}

  background: transparent;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;

    ${({ theme }) => css`
      background: ${theme.palette.primary.extraLighter};
      color: ${theme.palette.smoke.dark};
    `}
  }

  &:not(:disabled):hover {
    transform: none;

    ${({ theme }) => css`
      background: ${theme.palette.primary.extraLight};
    `}
  }
`
