import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { CustomerAddress as CustomerAddressType } from '@/modules/Order/types'

type Props = {
  address: CustomerAddressType
}

export const CustomerAddress = ({ address }: Props) => (
  <FlexColumn noPadding>
    {address ? (
      <>
        {address.address1 && <Label>{address.address1}</Label>}
        {address.address2 && <Label>{address.address2}</Label>}
        {(address.postcode || address.city) && (
          <Label>
            {address.postcode ? `${address.postcode} ` : ''}
            {address.city}
          </Label>
        )}
        {address.country && <Label>{address.country}</Label>}
      </>
    ) : (
      <T>Customers:address.empty</T>
    )}
  </FlexColumn>
)

//////

const Label = styled.span`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  :last-of-type {
    margin-bottom: 0;
  }
`
