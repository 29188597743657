import { gql, useMutation } from '@apollo/client'

import type {
  RemoveOfferMutation,
  RemoveOfferMutationVariables,
} from '~generated-types'

const REMOVE_OFFER_MUTATION = gql`
  mutation RemoveOffer($input: OrderDeleteOfferInput!) {
    orderDeleteOffer(input: $input) {
      deleted
      offerId
    }
  }
`

export const useRemoveOfferMutation = () =>
  useMutation<RemoveOfferMutation, RemoveOfferMutationVariables>(
    REMOVE_OFFER_MUTATION
  )
