import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { getIssueColor } from '@/theme'

import { IssueLevel } from '~generated-types'

type Props = {
  paymentError: string
  onClose: () => void
}

export const TerminalFailSection = ({ paymentError, onClose }: Props) => {
  const { language } = useLanguageContext()

  const errorLabel = translate(
    paymentError === 'UNKNOW_ERROR'
      ? 'common:error.common'
      : `IssueCodes:${paymentError}`,
    language
  )

  return (
    <Wrapper alignItems="center" justifyContent="space-between">
      <Label>{errorLabel}</Label>

      <Actions justifyContent="flex-end">
        <Button compact onClick={onClose}>
          <Icon icon="times" size="sm" />
          <T>common:action.close</T>
        </Button>
      </Actions>
    </Wrapper>
  )
}

///////

const Actions = styled(FlexRow)`
  width: 100%;
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Button = styled(InnocuousButton)`
  ${({ theme }) => css`
    color: ${theme.palette.danger.dark};
  `}
`

const Label = styled.span`
  font-weight: 500;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.danger.dark};
    font-size: ${theme.typography.fontSizeBase2};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    background: ${getIssueColor(IssueLevel.Error, theme.palette).background};
    border: 1px solid ${theme.palette.danger.dark};
    height: calc(42px * 2 + ${theme.spacing.gu(8)}rem + 1px);
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gutter};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
