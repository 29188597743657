import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'

type Props = {
  date: string
}

export const CreationDate = ({ date }: Props) => {
  return (
    <StyledP>
      <FontWeight light>
        <T>SalesDetails:common.createdAt</T>:{' '}
      </FontWeight>
      <FontWeight bold>{moment(date).format('DD.MM.YY')}</FontWeight>
    </StyledP>
  )
}

const StyledP = styled.p`
  margin: 0;
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem
      ${theme.spacing.gutterSmall};
  `}
`
