import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

type Props = {
  hasItemControls?: boolean
}

const ListHeader = ({ hasItemControls }: Props) => (
  <Wrapper>
    <Section>
      <T>Customers:CustomerList.field.type</T>
    </Section>
    <Section>
      <T>Customers:CustomerList.field.name</T>
    </Section>
    <Section>
      <T>Customers:CustomerList.field.address</T>
    </Section>
    <Section>
      <T>Customers:CustomerList.field.contacts</T>
    </Section>
    <Section>
      <T>Customers:CustomerList.field.labels</T>
    </Section>
    {hasItemControls && <Section />}
  </Wrapper>
)

export default ListHeader
