import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { FeatureChip, PrintLink } from '@/modules/Listing/common'
import { SalesTypeChip } from '@/modules/Sales'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import {
  ParticipantReservation,
  RoomReservation,
  RoomReservationCheckIn,
  RoomReservationCheckOut,
  RoomReservationCustomer,
  RoomReservationSales,
} from '../types'
import CleaningStatus from './CleaningStatus'

interface Props {
  data: RoomReservation
}

export const ArrivingRoomReservationsTableRow = ({
  data: {
    participantRooms,
    request: { beds, checkIn, checkOut, extraBeds, features, info, room },
  },
}: Props) => {
  const theme = useTheme()

  const sortedGuests = useMemo(
    () =>
      [...participantRooms].sort(
        generateCompareFn([
          'participant.lastName',
          'participant.firstName',
          'participant.id',
        ])
      ),
    [participantRooms]
  )
  const rowSpan = participantRooms.length || 1

  const customerIds = Array.from(
    new Set(
      sortedGuests.map(
        (x) => x.participant.sales.customer?.customer.id || 'missing-customer'
      )
    )
  )
  const salesIds = Array.from(
    new Set(sortedGuests.map((x) => x.participant.sales.id))
  )

  const isAllSameCustomer = customerIds.length === 1 || customerIds.length === 0
  const isAllSameSales = salesIds.length === 1 || salesIds.length === 0

  const bedsCell = (
    <td rowSpan={rowSpan} style={{ textAlign: 'center' }}>
      {beds}+{extraBeds}
    </td>
  )
  const durationCell = (
    <td rowSpan={rowSpan}>
      {checkIn.type === CheckInTypes.EARLY && (
        <FontAwesomeIcon
          color={theme.palette.primary.dark}
          icon="sun"
          size="xs"
          style={{ marginRight: `${theme.spacing.gu(0.5)}rem` }}
        />
      )}
      {formatDateRangeWithoutRepetition(checkIn.date, checkOut.date, 'short')}
      {checkOut.type === CheckOutTypes.LATE && (
        <FontAwesomeIcon
          color={theme.palette.primary.dark}
          icon="moon"
          size="sm"
          style={{ marginLeft: `${theme.spacing.gu(0.5)}rem` }}
        />
      )}
    </td>
  )
  const featuresCell = (
    <td rowSpan={rowSpan}>
      {features.length
        ? [...features]
            .sort(generateCompareFn('name'))
            .map((feature) => (
              <FeatureChip feature={feature} key={`feature-${feature.id}`} />
            ))
        : '—'}
    </td>
  )
  const infoCell = (
    <td rowSpan={rowSpan} style={{ width: 280 }}>
      {info || '—'}
    </td>
  )
  const roomCell = (
    <td rowSpan={rowSpan}>
      <FlexColumn noPadding>
        <strong>#{room.number}</strong>
        <small style={{ marginLeft: theme.spacing.gutterSmall }}>
          {room.beds} + {room.extraBeds}
        </small>
      </FlexColumn>
    </td>
  )
  const statusCell = (
    <td rowSpan={rowSpan} style={{ textAlign: 'center' }}>
      {room.status.cleaningStatus && (
        <CleaningStatus status={room.status.cleaningStatus} />
      )}
    </td>
  )

  const getCustomerLabel = (customer: RoomReservationCustomer | undefined) => {
    return customer ? (
      <PrintLink to={`/registry/customers/${customer?.customerNumber}`}>
        #{customer.customerNumber} {parseCustomerName(customer) || '—'}
      </PrintLink>
    ) : (
      '—'
    )
  }

  const getGuestDuration = (
    checkInReference: RoomReservationCheckIn,
    checkOutReference: RoomReservationCheckOut,
    target: ParticipantReservation
  ): React.ReactNode => {
    const checkInDateDiff =
      checkInReference.date !== target.request.checkIn.date
    const checkInTypeDiff =
      checkInReference.type !== target.request.checkIn.type

    const checkOutDateDiff =
      checkOutReference.date !== target.request.checkOut.date
    const checkOutTypeDiff =
      checkOutReference.type !== target.request.checkOut.type

    if (
      checkInDateDiff ||
      checkInTypeDiff ||
      checkOutDateDiff ||
      checkOutTypeDiff
    ) {
      return (
        <>
          {target.request.checkIn.type === CheckInTypes.EARLY && (
            <FontAwesomeIcon
              color={theme.palette.primary.dark}
              icon="sun"
              size="xs"
              style={{ marginRight: `${theme.spacing.gu(0.5)}rem` }}
            />
          )}
          {formatDateRangeWithoutRepetition(
            target.request.checkIn.date,
            target.request.checkOut.date,
            'short'
          )}
          {target.request.checkOut.type === CheckOutTypes.LATE && (
            <FontAwesomeIcon
              color={theme.palette.primary.dark}
              icon="moon"
              size="sm"
              style={{ marginLeft: `${theme.spacing.gu(0.5)}rem` }}
            />
          )}
        </>
      )
    }

    return null
  }

  const parseCustomerName = (customer: RoomReservationCustomer) =>
    customer.__typename === 'CustomerOrganization'
      ? customer.organization.name
      : parseName({
          firstName: customer?.person.firstName || '',
          lastName: customer?.person.lastName || '',
        })

  const parseName = ({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`

  const getSalesLabel = (sales: RoomReservationSales) => (
    <FlexRow alignItems="flex-start" justifyContent="space-between">
      <PrintLink to={`/sales/details/${sales.id}`}>
        #{sales.orderNumber}{' '}
        {sales.name ? (
          sales.name
        ) : (
          <em>
            <T
              l10n={`Listings:AccommodationGuestListing.unnamed.sales.${sales.type}`}
            />
          </em>
        )}
      </PrintLink>
      <SalesTypeChip type={sales.type} />
    </FlexRow>
  )

  return (
    <>
      <tr>
        {roomCell}
        {statusCell}
        {bedsCell}
        {featuresCell}
        {infoCell}
        {durationCell}
        {sortedGuests[0] ? (
          <td>
            <FlexRow alignItems="center" justifyContent="space-between">
              <span>{parseName(sortedGuests[0].participant)}</span>
              <small>
                {getGuestDuration(checkIn, checkOut, sortedGuests[0])}
              </small>
            </FlexRow>
          </td>
        ) : (
          <td>–</td>
        )}
        {sortedGuests[0] ? (
          <td rowSpan={isAllSameCustomer ? rowSpan : 1}>
            {getCustomerLabel(
              sortedGuests[0].participant.sales.customer?.customer
            )}
          </td>
        ) : (
          <td>–</td>
        )}
        {sortedGuests[0] ? (
          <td rowSpan={isAllSameSales ? rowSpan : 1}>
            {getSalesLabel(sortedGuests[0].participant.sales)}
          </td>
        ) : (
          <td>–</td>
        )}
      </tr>
      {sortedGuests.map((x, idx) => {
        if (idx === 0) {
          // first guest is already rendered in the main row
          return null
        }

        return (
          <tr
            className="extra-row"
            key={`arriving-room-reservation-guest-${x.participant.id}`}
          >
            <td>
              <FlexRow alignItems="center" justifyContent="space-between">
                <span>{parseName(x.participant)}</span>
                <small>{getGuestDuration(checkIn, checkOut, x)}</small>
              </FlexRow>
            </td>
            {!isAllSameCustomer && (
              <td>
                {getCustomerLabel(x.participant.sales.customer?.customer)}
              </td>
            )}
            {!isAllSameSales && <td>{getSalesLabel(x.participant.sales)}</td>}
          </tr>
        )
      })}
    </>
  )
}

export default ArrivingRoomReservationsTableRow
