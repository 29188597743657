import { gql, useMutation } from '@apollo/client'

import {
  SetResourceReservationGroupNameMutation,
  SetResourceReservationGroupNameMutationVariables,
} from '~generated-types'

export type {
  SetResourceReservationGroupNameMutation,
  SetResourceReservationGroupNameMutationVariables,
}

export const SET_RESOURCE_RESERVATION_GROUP_NAME_MUTATION = gql`
  mutation SetResourceReservationGroupName(
    $input: ResourceReservationRenameGroupInput!
  ) {
    resourceReservationRenameGroup(input: $input) {
      end
      id
      name
      number
      start
    }
  }
`

export function useSetResourceReservationGroupNameMutation() {
  return useMutation<
    SetResourceReservationGroupNameMutation,
    SetResourceReservationGroupNameMutationVariables
  >(SET_RESOURCE_RESERVATION_GROUP_NAME_MUTATION)
}

export default useSetResourceReservationGroupNameMutation
