import styled, { css } from 'styled-components/macro'

import { DataField } from '@/components/DataField'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Placeholder } from '@/modules/Products/components'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

type Props = {
  code: string
  id: string
  name: string
}

export const NameSection = ({ code, id, name }: Props) => {
  const { spacing } = useTheme()
  const { readOnly, updateProductItem } = useSalesProductManagerContext()

  const handleUpdateName = (name: string) => updateProductItem({ id, name })

  return (
    <FlexColumn alignItems="flex-start" flex={1}>
      <NameWrapper>
        {readOnly ? (
          <Name alignItems="center">
            {name ? (
              name
            ) : (
              <Placeholder>
                <T>Products:ProductManager.pricing.unnamedProduct</T>
              </Placeholder>
            )}
          </Name>
        ) : (
          <DataFieldWrapper>
            <DataField
              customValueRenderer={(value) => <Label>{value}</Label>}
              handleSubmit={(value) => handleUpdateName(value || '')}
              inputType="text"
              placeholder={
                <Placeholder>
                  <T>Products:ProductManager.pricing.unnamedProduct</T>
                </Placeholder>
              }
              style={{
                marginLeft: `-${spacing.guPx(1) + 1}px`,
                width: `calc(100% + ${spacing.gu(1)}rem)`,
              }}
              value={name}
            />
          </DataFieldWrapper>
        )}
      </NameWrapper>

      <Code>{code}</Code>
    </FlexColumn>
  )
}

/////////

const Code = styled.code`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const DataFieldWrapper = styled.div`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      ${({ theme }) => css`
        height: ${theme.spacing.gu(4)}rem;
      `}
    }
  }
`

const Label = styled(FlexRow)`
  align-items: center;

  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(0.5)}rem;
  `}
`

const Name = styled(FlexRow)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(4)}rem;
  `}
`

const NameWrapper = styled.div`
  width: 100%;

  ${({ theme }) => css`
    margin-top: -${theme.spacing.gu(1)}rem;
  `}
`
