import { ReactNode } from 'react'
import { ThemeProvider, useTheme as libUseTheme } from 'styled-components'

import { useWillbaConfigContext } from '@/config/WillbaConfigContext'

interface Props {
  children: ReactNode
}

export const WillbaThemeProvider = ({ children }: Props) => {
  const { theme } = useWillbaConfigContext()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const useTheme = libUseTheme

export default WillbaThemeProvider
