import { gql, useQuery } from '@apollo/client'

import { ageCategoryFragments } from '@/modules/Registry/AgeCategory'

import type {
  MealsConfigQuery as QueryData,
  MealsConfigQueryVariables as QueryVariables,
} from '~generated-types'

import { MealTypeFragment, RestaurantFragment } from '../fragments'
import { AgeCategory, MealType, Restaurant } from '../types'

const QUERY = gql`
  ${ageCategoryFragments.ageCategory}
  ${MealTypeFragment}
  ${RestaurantFragment}

  query MealsConfig {
    registry {
      ageCategories {
        ...AgeCategory
      }
      meals {
        ...MealType
      }
      restaurants {
        ...Restaurant
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export const useMealsConfig = (params?: Params) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
  })

  const ageCategories: AgeCategory[] = data?.registry.ageCategories || []
  const mealTypes: MealType[] = data?.registry.meals || []
  const restaurants: Restaurant[] = data?.registry.restaurants || []

  return {
    ageCategories,
    error,
    loading,
    mealTypes,
    restaurants,
  }
}
