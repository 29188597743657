import { createContext, ReactChild, useContext, useMemo, useState } from 'react'

import { PrintSize } from '../types'
import { Mode } from './types'

type Props = {
  children: ReactChild
}

type ContextType = {
  cellSize: number
  setTableMode: (tableMode: Mode) => void
  setTablePrintSize: (tablePrintSize: PrintSize) => void
}

export const CellSizeContext = createContext<ContextType>({
  cellSize: 0,
  setTableMode: () => null,
  setTablePrintSize: () => null,
})

export const CellSizeProvider = ({ children }: Props) => {
  const [tableMode, setTableMode] = useState<Mode | null>(null)
  const [tablePrintSize, setTablePrintSize] = useState<PrintSize | null>(null)

  const cellSize = useMemo(() => {
    switch (true) {
      case tableMode === Mode.FULL && tablePrintSize === PrintSize.Compact:
        return 32

      case tableMode === Mode.FULL && tablePrintSize === PrintSize.Large:
        return 22

      case tableMode === Mode.CONCISE && tablePrintSize === PrintSize.Compact:
      case tableMode === Mode.CONCISE && tablePrintSize === PrintSize.Default:
        return 27

      case tableMode === Mode.CONCISE && tablePrintSize === PrintSize.Large:
        return 34

      default:
        return 28
    }
  }, [tableMode, tablePrintSize])

  const value = {
    cellSize,
    setTableMode,
    setTablePrintSize,
  }

  return (
    <CellSizeContext.Provider value={value}>
      {children}
    </CellSizeContext.Provider>
  )
}

export const useCellSizeContext = () => useContext(CellSizeContext)
