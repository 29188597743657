import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'
import { useGlobalIconsContext } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

type Props = {
  features: RoomFeature[]
}

export const RoomFeatures = ({ features }: Props) => {
  const { checkIconValidity } = useGlobalIconsContext()

  return (
    <Tooltip
      content={
        <FlexColumn noPadding>
          {[...features].sort(generateCompareFn('name')).map((feature) => (
            <FlexRow alignItems="center" key={feature.id}>
              {checkIconValidity(feature.icon) ? (
                <Icon icon={feature.icon as IconProp} size="sm" />
              ) : (
                <IconPlaceholder />
              )}
              <Name>{feature.name}</Name>
            </FlexRow>
          ))}
        </FlexColumn>
      }
      trigger={(triggerProps) => (
        <Row {...triggerProps}>
          {[...features].sort(generateCompareFn('name')).map((feature) => (
            <IconWrapper key={feature.id}>
              {checkIconValidity(feature.icon) ? (
                <FontAwesomeIcon
                  fixedWidth
                  icon={feature.icon as IconProp}
                  size="sm"
                />
              ) : (
                <ShortName>{feature.shortName}</ShortName>
              )}
            </IconWrapper>
          ))}
        </Row>
      )}
    />
  )
}

export default RoomFeatures

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const IconPlaceholder = styled.span`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const IconWrapper = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const Name = styled.span`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
  `}
`

const Row = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(11)}rem;
  `}
`

const ShortName = styled.span`
  font-weight: 500;
`
