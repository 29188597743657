import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'

type Props = {
  handleSubmit: (newValue: string | null) => Promise<any>
  value: string | null
}

export const DataFieldTextarea = ({ handleSubmit, value }: Props) => {
  const inputEl = useRef<any>(null)

  const [editedValue, setEditedValue] = useState<string>(value || '')

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.focus()
  }, [inputEl])

  const onSubmit = (submitValue: string | null) =>
    handleSubmit(submitValue).catch(() =>
      console.warn('Failed to submit edited value')
    )

  return (
    <StyledDataFieldTextarea
      onBlur={() => onSubmit(editedValue)}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setEditedValue(e.target.value)
      }
      ref={inputEl}
      value={editedValue}
    />
  )
}

////////////

const StyledDataFieldTextarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))`
  &&& {
    ${({ theme }) => css`
      min-height: ${theme.spacing.gu(20)}rem;
      min-width: ${theme.spacing.gu(40)}rem;
      padding: ${theme.spacing.gu(1)}rem;
    `}
  }
`
