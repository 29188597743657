import { InlineModalIconSection } from '@/components/InlineModal'
import type {
  Reservation,
  ResourceReservationDimension,
} from '@/modules/Reservations/ResourceReservation'
import { generateCompareFn } from '@/utils/arrays'

import { Dimension } from './Dimension'

type Props = {
  readOnly: boolean
  reservationId: string
  reservationDimensions: ResourceReservationDimension[]
  updateReservations: (reservations: Reservation[]) => void
}

export const Dimensions = ({
  readOnly,
  reservationId,
  reservationDimensions,
  updateReservations,
}: Props) => (
  <>
    {[...reservationDimensions]
      .sort(generateCompareFn('sortOrder'))
      .map((dimension: ResourceReservationDimension) => (
        <InlineModalIconSection icon="info" key={dimension.id}>
          <Dimension
            dimension={dimension}
            dimensions={reservationDimensions}
            readOnly={readOnly}
            reservationId={reservationId}
            updateReservations={updateReservations}
          />
        </InlineModalIconSection>
      ))}
  </>
)
