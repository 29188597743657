import styled from 'styled-components/macro'

import { BottomSheet } from '@/components/BottomSheet'
import { FlexRow, Gutter } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T } from '@/modules/Language'
import { PlaceholderLabel } from '@/modules/Listing/common'

import { useParticipantsForPrint } from '../../../hooks'
import { PrintableParticipantsList } from './PrintableParticipantsList'

type Props = {
  onClose: () => void
  salesId: string
}

export const PrintableParticipantsListContainer = ({
  onClose,
  salesId,
}: Props) => {
  const { error, loading, participants } = useParticipantsForPrint({ salesId })

  const renderContent = () => {
    if (loading) {
      return (
        <FlexRow>
          <LoadingPlaceholder flex={1} size="xl" />
        </FlexRow>
      )
    }

    if (error) {
      return (
        <PlaceholderLabel>
          <T>ParticipantsList:error</T>
        </PlaceholderLabel>
      )
    }

    if (!participants.length) {
      return (
        <PlaceholderLabel>
          <T>ParticipantsList:empty</T>
        </PlaceholderLabel>
      )
    }

    return <PrintableParticipantsList participants={participants} />
  }

  return (
    <BottomSheet
      onClose={onClose}
      title={<T>ParticipantsList:PrintableList.title</T>}
    >
      <Gutter ignoreInPrint type={[2, 5, 10]}>
        <Wrapper>{renderContent()}</Wrapper>
      </Gutter>
    </BottomSheet>
  )
}

////////////

const Wrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
  overflow: visible;

  @media print {
    max-width: 100%;
    padding: 0;
  }
`
