import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

export const IconSpacer = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(4.5)}rem;
  `}
`

export const Label = styled.span<{ width: string }>`
  ${({ width }) => css`
    width: ${width};
  `}
`

export const PriceLabel = styled(Label)`
  text-align: right;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

export const Spacer = styled.div`
  flex: 1;
`

export const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
