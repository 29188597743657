import { MouseEvent, useEffect, useState } from 'react'

import { DangerColor, PrimaryColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModalFooter } from '@/components/InlineModal'
import { Right } from '@/components/Layout'
import { T } from '@/modules/Language'

import { taskHooks } from '../../../../hooks'
import { Task } from '../../../../types'
import { Section } from '../../types'
import { RemoveButton } from './RemoveButton'

type Props = {
  currentSection: Section
  onCloseModal: (event: MouseEvent) => void
  onUpdateTasks: (tasks: Task[]) => void
  readOnly: boolean
  task: Task
  setCurrentSection: (section: Section) => void
}

export const Footer = ({
  currentSection,
  onCloseModal,
  onUpdateTasks,
  readOnly,
  task: { id },
}: Props) => {
  const { confirm } = useDialogService()
  const { removeTask } = taskHooks.useTaskMutations({ onUpdateTasks })

  const [isProcessing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    return () => setProcessing(false)
  }, [])

  const confirmDelete = (deleteItem: () => Promise<void>, itemType: string) => {
    setProcessing(true)

    const handleDelete = () =>
      deleteItem()
        .catch(() => undefined)
        .finally(() => setProcessing(false))

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>{`ReservationModal:removalConfirmation.${itemType}`}</T>,
    })
      .then(handleDelete)
      .catch(() => setProcessing(false))
  }

  const handleDeleteTask = () => {
    const deleteFn = () => removeTask(id)

    return confirmDelete(deleteFn, 'task')
  }

  return (
    <InlineModalFooter justifyContent="flex-end">
      {currentSection.type === 'TASK_MANAGER' && !readOnly && (
        <RemoveButton
          disabled={isProcessing}
          onClick={handleDeleteTask}
          target="deleteTask"
        />
      )}

      <Right>
        <InnocuousButton onClick={onCloseModal}>
          <PrimaryColor>
            <T>common:action.ready</T>
          </PrimaryColor>
        </InnocuousButton>
      </Right>
    </InlineModalFooter>
  )
}
