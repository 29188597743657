import catalogProduct from './catalogProduct'
import catalogProductItem from './catalogProductItem'
import catalogProductOld from './catalogProductOld'
import productCategory from './productCategory'
import productMeal from './productMeal'
import productPurchase from './productPurchase'
import productPurchaseLink from './productPurchaseLink'
import productPurchaseStatus from './productPurchaseStatus'
import productRule from './productRule'
import salesProduct from './salesProduct'
import salesProductItem from './salesProductItem'

export const productFragments = Object.freeze({
  catalogProduct,
  catalogProductItem,
  catalogProductOld,
  productCategory,
  productMeal,
  productPurchase,
  productPurchaseLink,
  productPurchaseStatus,
  productRule,
  salesProduct,
  salesProductItem,
})
