import { RoomsAndBedsFragment as RoomsAndBeds } from '~generated-types'

import {
  AvailabilityByDate,
  AvailabilityStatus,
} from '../AvailabilityCalendar.types'

export const combineDailyAvailabilities = (
  availabilities: AvailabilityByDate[]
): AvailabilityByDate => {
  if (!availabilities.length) {
    return {}
  }

  // The assumption is that the date keys are identical for all availabilities.
  // Hence, it is safe to use the date keys from the first entry
  const dateKeys: string[] = Object.keys(availabilities[0])

  return dateKeys.reduce(
    (combined: AvailabilityByDate, date: string) => ({
      ...combined,
      [date]: availabilities.reduce(
        (acc: AvailabilityStatus, item: AvailabilityByDate) =>
          combineAvailabilityStatus(acc, item[date]),
        {} as AvailabilityStatus
      ),
    }),
    {} as AvailabilityByDate
  )
}

const combineAvailabilityStatus = (
  first?: AvailabilityStatus | null,
  second?: AvailabilityStatus | null
): AvailabilityStatus => ({
  __typename: 'DailyAccommodationStatus',
  confirmed: combineRoomsAndBeds(first?.confirmed, second?.confirmed),
  dates: second?.dates ?? [],
  tentative: combineRoomsAndBeds(first?.tentative, second?.tentative),
  totalCapacity: combineRoomsAndBeds(
    first?.totalCapacity,
    second?.totalCapacity
  ),
})

const combineRoomsAndBeds = (
  first?: RoomsAndBeds | null,
  second?: RoomsAndBeds | null
): RoomsAndBeds => ({
  __typename: 'RoomsAndBeds',
  beds: (first?.beds ?? 0) + (second?.beds ?? 0),
  extraBeds: (first?.extraBeds ?? 0) + (second?.extraBeds ?? 0),
  rooms: (first?.rooms ?? 0) + (second?.rooms ?? 0),
})
