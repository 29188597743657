import { useState } from 'react'
import styled, { css } from 'styled-components'

import { EditButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { SalesProductManager } from '@/modules/Products'
import { SalesProduct } from '@/modules/Products/types'
import { salesHooks } from '@/modules/Sales/hooks'

import { Feature } from '~generated-types'

import { usePublishContext } from '../../../PublishState'
import { ContentPlaceholder, TruncateContent } from '../../common'

type Props = {
  product: SalesProduct
}

export const Product = ({ product }: Props) => {
  const { readOnly } = usePublishContext()
  const {
    data: {
      commissionRate,
      facet: { features },
      id: salesId,
      seller,
      type: salesType,
    },
  } = salesHooks.useSalesDetailsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const isCommissionEnabled = !!features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  return (
    <>
      <StyledEditButton disabled={readOnly} onClick={() => setModalOpen(true)}>
        <TruncateContent
          content={
            product.name ?? (
              <ContentPlaceholder>
                <T>Publish:Products.noProductName</T>
              </ContentPlaceholder>
            )
          }
        />
      </StyledEditButton>

      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <SalesProductManager
            commission={commission}
            context="GLOBAL"
            onClose={() => setModalOpen(false)}
            product={product}
            productId={product.id}
            readOnly={readOnly}
            salesId={salesId}
            salesType={salesType}
            sellerId={seller?.id}
          />
        }
        onClose={() => setModalOpen(false)}
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

////////////

const StyledEditButton = styled(EditButton)`
  flex: unset;
  margin: 0;
  margin-left: -5px;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};

    &:hover:not([disabled]) {
      background: ${theme.palette.smoke.light};
    }
  `}
`
