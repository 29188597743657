import CONFIRMATION_BY_ID from './confirmationById'
import INVOICE_BY_ID from './invoiceById'
import INVOICE_WITH_PAYMENTS_BY_ID from './invoiceWithPaymentsById'
import OFFER_BY_ID from './offerById'
import ORDER_LIFECYCLE from './orderLifecycle'
import ORDERS from './orders'
import PAYMENT_BY_ID from './paymentById'

export const orderQueries = Object.freeze({
  CONFIRMATION_BY_ID,
  INVOICE_BY_ID,
  INVOICE_WITH_PAYMENTS_BY_ID,
  OFFER_BY_ID,
  ORDER_LIFECYCLE,
  ORDERS,
  PAYMENT_BY_ID,
})
