import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import { MealsControls } from './MealsControls'

type Props = {
  date: string
  isFirst: boolean
}

export const MealsHeader = ({ date, isFirst }: Props) => (
  <Wrapper alignItems="center" justifyContent="space-between" isFirst={isFirst}>
    <Date>
      <CalendarIcon icon={['far', 'calendar']} size="sm" />
      {moment(date).format('DD MMM, dddd')}
    </Date>

    {isFirst && <MealsControls />}
  </Wrapper>
)

///////////

const CalendarIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Date = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(1)}rem;
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexRow)<{ isFirst: boolean }>`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  ${({ isFirst, theme }) =>
    !isFirst && `margin-top: ${theme.spacing.gu(2.5)}rem;`}
`
