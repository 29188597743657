import { gql } from '@apollo/client'

export default gql`
  query OrderLifecycle($id: ID!) {
    order(id: $id) {
      lifecycle {
        state
        validatedActions {
          action
          issues {
            code
          }
          valid
        }
      }
    }
  }
`
