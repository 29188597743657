import { gql } from '@apollo/client'

export default gql`
  fragment Attachment on FileItemInfo {
    created
    etag
    fileName
    info {
      fileName
      folder
      ownerType
      ownerId
    }
    lastModified
    mimeType
    id
    size
  }
`
