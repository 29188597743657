import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  date: string | null
  dates: string[]
  readOnly?: boolean
  setDate: (date: string) => void
}

export const DateSelector = ({ date, dates, readOnly, setDate }: Props) => {
  const { palette, spacing } = useTheme()
  const { language } = useLanguageContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const onSetDate = (value: Moment | null | undefined) => {
    setDate(value ? value.format('YYYY-MM-DD') : '')
    setEditMode(false)
  }

  const todayFormatted = moment().format('dd, DD.MM.YY')
  const dateFormatted = moment(date).format('dd, DD.MM.YY')

  return (
    <ModalContainer
      isOpen={isEditMode}
      modal={
        <InlineModal>
          {dates.map((dateOption) => (
            <OptionWrapper
              key={dateOption}
              onClick={() => onSetDate(moment(dateOption))}
            >
              <IconWrapper>
                {dateOption === date && (
                  <FontAwesomeIcon
                    icon="check"
                    style={{ color: palette.primary.main }}
                  />
                )}
              </IconWrapper>
              {moment(dateOption).format('dd, DD.MM.YY')}
            </OptionWrapper>
          ))}
        </InlineModal>
      }
      onClose={() => setEditMode(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <EditButton
          disabled={readOnly}
          onClick={() => (readOnly ? null : setEditMode(true))}
          ref={ref}
        >
          <FontAwesomeIcon
            color={palette.text.lighter}
            icon={['far', 'calendar']}
            size="sm"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          {todayFormatted === dateFormatted ? (
            <Placeholder>
              {translate('ParticipantsList:MassUpdate.date', language)}
            </Placeholder>
          ) : (
            dateFormatted
          )}
        </EditButton>
      )}
      zIndex={10003}
    />
  )
}

const EditButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-transform: capitalize;
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(20)}rem;
    max-width: ${theme.spacing.gu(20)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ disabled, theme }) =>
    !disabled &&
    `
    &:hover {
      background-color: ${theme.palette.smoke.light};
    }
  `}
`

const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.extraDark};
  `}
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(3)}rem;
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`

const OptionWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.primary.extraLight};
    `}
  }
`
