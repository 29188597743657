import moment from 'moment'
import styled from 'styled-components'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { PrintLink } from '@/modules/Listing/common'

import {
  ListingMealParticipant,
  ListingMealType,
  ListingSales,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { parseCustomerName, parseParticipantName } from '../../utils'
import { MealTypeLabel, NoWrapSpan } from '../common'

type Props = {
  data: ListingScheduledMeal[]
  mealType: ListingMealType
}

type MealParticipant = ListingMealParticipant & {
  time: string
  sales: ListingSales
}

export const MealTypeSpecialAllegies = ({ data, mealType }: Props) => {
  const { language } = useLanguageContext()

  const specialAllergyParticipants = data.reduce(
    (acc: MealParticipant[], meal: ListingScheduledMeal) => {
      const { duration, participants, sales, start } = meal

      const time = `${moment(start).format('HH:mm')} – ${moment(start)
        .add(duration ?? 0, 'minutes')
        .format('HH:mm')}`

      const data = participants
        .filter((p) => p.allergies.length || p.allergyDescription || p.diets)
        .map((p) => ({ ...p, sales, time }))

      return [...acc, ...data]
    },
    []
  )

  const renderRow = (data: MealParticipant, idx: number) => {
    const {
      ageCategory,
      allergies,
      allergyDescription,
      diets,
      firstName,
      id,
      lastName,
      sales,
      time,
    } = data

    return (
      <tr key={`participant-special-allergy-${id}-${idx}`}>
        <td />
        <td>
          <NoWrapSpan>{time}</NoWrapSpan>
        </td>
        <td>
          <NoWrapSpan>
            {parseParticipantName({ firstName, lastName })}
          </NoWrapSpan>
        </td>
        <td>
          <NoWrapSpan>
            {ageCategory
              ? ageCategory.shortName
              : translate('Listings:PacketMealListing.field.noAge', language)}
          </NoWrapSpan>
        </td>
        <td>
          <FlexRow alignItems="flex-start" justifyContent="space-between">
            <PrintLink to={`/sales/details/${sales.id}`}>
              {parseCustomerName(sales)}
            </PrintLink>
          </FlexRow>
        </td>
        <td>
          <FlexColumn noPadding>
            <FlexRow>
              {allergies.length ? (
                <>
                  {'• '}
                  {allergies.map((allergy, idx) => (
                    <AllergyLabel key={`participant-allergy-${idx}`}>
                      {allergies.length > 1 && idx + 1 !== allergies.length
                        ? `${allergy}, `
                        : allergy}
                    </AllergyLabel>
                  ))}
                </>
              ) : null}
            </FlexRow>

            <FlexRow>
              {diets.length ? (
                <>
                  {'• '}
                  {diets.map((diet, idx) => (
                    <AllergyLabel key={`participant-diet-${idx}`}>
                      {diets.length > 1 && idx + 1 !== diets.length
                        ? `${diet}, `
                        : diet}
                    </AllergyLabel>
                  ))}
                </>
              ) : null}
            </FlexRow>

            <FlexRow>
              {allergyDescription ? (
                <>
                  {'• '}
                  <AllergyLabel>{allergyDescription}</AllergyLabel>
                </>
              ) : null}
            </FlexRow>
          </FlexColumn>
        </td>
      </tr>
    )
  }

  return data.length ? (
    <>
      <tr>
        <td colSpan={6}>
          <MealTypeLabel>{mealType.name}</MealTypeLabel>
        </td>
      </tr>
      {[...specialAllergyParticipants].map(renderRow)}
    </>
  ) : null
}

////////////

const AllergyLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
`
