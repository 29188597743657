import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import type { CellProps } from './types'

type Props = CellProps & {
  children?: ReactNode
  minimalRightPadding?: boolean
}

const ListTD = styled.td<Props>`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}

  text-align: ${({ align }) => align || 'inherit'};
  vertical-align: ${({ verticalAlign }) => verticalAlign || 'inherit'};

  & a {
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}
  }

  & small {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
      font-size: ${theme.typography.fontSizeSmall};
    `}
  }

  ${({ minimalRightPadding }) =>
    minimalRightPadding &&
    css`
      &&& {
        ${({ theme }) => css`
          padding-right: ${theme.spacing.gutter};
        `}
      }
    `}
`

export default ListTD
