import { CSSProperties, useEffect, useState } from 'react'
import { ControlProps, OptionTypeBase, SingleValueProps } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { PointOfSales } from '@/modules/Registry/PointOfSales'
import { Palette, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

type Props = {
  disabled: boolean
  loading: boolean
  pointOfSale?: Option | null
  pointsOfSales: PointOfSales[] | null
  setPointOfSale: (pointOfSale: Option | null) => void
}

export const PointOfSaleSelector = ({
  disabled,
  loading,
  pointOfSale,
  pointsOfSales,
  setPointOfSale,
}: Props) => {
  const { palette } = useTheme()

  const [ontions, setOptions] = useState<Option[] | []>([])

  useEffect(() => {
    if (pointsOfSales) {
      const pointOfSales = [...pointsOfSales]
        .sort(generateCompareFn('name'))
        .map((point: PointOfSales) => ({
          label: point.name,
          searchValue: point.name,
          value: point.id,
        }))

      setOptions(pointOfSales)

      const storedPointOfSales = JSON.parse(
        localStorage.getItem('pointOfSale') || 'null'
      )

      setPointOfSale(
        pointOfSales.find(({ value }) => value === storedPointOfSales?.value) ??
          null
      )
    }
  }, [pointsOfSales])

  const handleSetPointOfSale = (option: Option | null | undefined) => {
    if (option) {
      setPointOfSale(option)
      localStorage.setItem('pointOfSale', JSON.stringify(option))
    }
  }

  return (
    <FlexColumn flex={2} noPadding>
      <SubTitle>
        <T>Orders:Payments.field.pointOfSale</T>
      </SubTitle>

      <ThemedSelect
        extraStyles={getExtraStyles(palette)}
        isDisabled={disabled}
        isLoading={loading}
        menuPortalTarget={document.body}
        name="point-of-sale-selector"
        noOptionsMessage={() => <T>Orders:Payments.modal.emptyPointOfSales</T>}
        onChange={(option?: Option | null) => handleSetPointOfSale(option)}
        options={ontions}
        placeholder=""
        value={pointOfSale}
      />
    </FlexColumn>
  )
}

///////

const getExtraStyles = (palette: Palette) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
  control: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    background: isDisabled ? palette.smoke.lighter : styles.background,
    borderColor: isDisabled ? palette.smoke.dark : styles.borderColor,
    borderRadius: '6px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    height: '35px',
    minHeight: '35px',
    pointerEvents: isDisabled ? 'auto' : styles.pointerEvents,
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    padding: '6px',
  }),
  singleValue: (
    styles: CSSProperties,
    { isDisabled }: SingleValueProps<OptionTypeBase>
  ) => ({
    ...styles,
    color: isDisabled ? palette.text.lighter : palette.text.light,
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    padding: '2px 6px',
  }),
})

const SubTitle = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gu(1)}rem;
  `}
`
