import moment, { Moment } from 'moment'

export const getDurationString = (start: string, end: string) => {
  const momentEnd = moment(end, 'HH:mm')
  const momentStart = moment(start, 'HH:mm')

  const hours = momentEnd.diff(momentStart, 'hour')
  const totalMinutes = momentEnd.diff(momentStart, 'minute')
  const minutes = totalMinutes > 60 ? totalMinutes % 60 : totalMinutes

  return `${hours ? `${hours} h` : ''} ${
    minutes && minutes !== 60 ? `${minutes} min` : ''
  }`
}

export const getTimeArray = ({
  allowSameTime,
  boundaryTime,
}: {
  allowSameTime?: boolean
  boundaryTime?: string
}) => {
  const timeArray: Moment[] = []
  const boundaryDate = boundaryTime
    ? `${moment().format('YYYY-MM-DD')}T${boundaryTime}`
    : null

  new Array(24).fill(0).forEach((_, idx: number) => {
    timeArray.push(moment({ hour: idx }))
    timeArray.push(moment({ hour: idx, minute: 15 }))
    timeArray.push(moment({ hour: idx, minute: 30 }))
    timeArray.push(moment({ hour: idx, minute: 45 }))
  })

  boundaryTime && timeArray.push(moment({ hour: 23, minute: 59 }))

  return timeArray
    .filter(
      (time) =>
        !boundaryDate ||
        (allowSameTime
          ? time.isSameOrAfter(boundaryDate)
          : time.isAfter(boundaryDate))
    )
    .map((time) => time.format('HH:mm'))
}

export const isTimeValid = (time: string) =>
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/.test(time)

export const scrollToSelectedTime = (time: string) => {
  const momentTime = moment(time, 'HH:mm')

  const isTimeFromOptions = momentTime.get('minutes') % 15 === 0

  const roundedTime = isTimeFromOptions
    ? time
    : momentTime.startOf('hour').add(30, 'minutes').format('HH:mm')

  setTimeout(() => {
    const selectedEl = document.getElementById(`time-option-${roundedTime}`)

    if (selectedEl) {
      selectedEl.scrollIntoView({ block: 'center' })
    }
  }, 0)
}

export const transformTime = (time: string) => {
  const timeString = time.replace(/\./g, ':')

  return timeString.includes(':')
    ? timeString
    : timeString.slice(0, -2) + ':' + timeString.slice(-2)
}

type ValidateTimesProps = {
  allowSameTime: boolean
  end: string
  start: string
  target: 'end' | 'start'
  time: string
}

export const validateTimes = ({
  allowSameTime,
  end,
  start,
  target,
  time,
}: ValidateTimesProps) => {
  const input = { end, start }

  const endTime = moment(end).format('HH:mm')
  const startTime = moment(start).format('HH:mm')
  const momentEndTime = moment(endTime, 'HH:mm')
  const momentStartTime = moment(startTime, 'HH:mm')
  const momentTime = moment(time, 'HH:mm')

  if (target === 'start') {
    const validMomentTime =
      momentTime.format('HH:mm') === '23:59'
        ? moment('23:45', 'HH:mm')
        : momentTime

    input.start = start.replace(
      /T.+:/gm,
      `T${validMomentTime.format('HH:mm')}:`
    )

    if (validMomentTime.isSameOrAfter(momentEndTime)) {
      if (allowSameTime) {
        input.end = start.replace(
          /T.+:/gm,
          `T${validMomentTime.format('HH:mm')}:`
        )
      } else {
        const hours = validMomentTime.get('hours')
        const mins = validMomentTime.get('minutes')
        const duration = hours === 23 && mins > 44 ? 59 - mins : 15
        const validEndTime = validMomentTime
          .add(duration, 'minutes')
          .format('HH:mm')

        input.end = start.replace(/T.+:/gm, `T${validEndTime}:`)
      }
    }
  }

  if (target === 'end') {
    const validMomentTime =
      momentTime.format('HH:mm') === '00:00'
        ? moment('23:59', 'HH:mm')
        : momentTime

    input.end = end.replace(/T.+:/gm, `T${validMomentTime.format('HH:mm')}:`)

    if (validMomentTime.isSameOrBefore(momentStartTime)) {
      if (allowSameTime) {
        input.start = end.replace(
          /T.+:/gm,
          `T${validMomentTime.format('HH:mm')}:`
        )
      } else {
        const validStartTime = validMomentTime
          .subtract(15, 'minutes')
          .format('HH:mm')

        input.start = end.replace(/T.+:/gm, `T${validStartTime}:`)
      }
    }
  }

  return input
}
