import styled, { css } from 'styled-components/macro'

import { ParticipantCheckIn, ParticipantCheckOut } from './components'

type Props = {
  checkAvailable?: boolean
  checkIn?: string | null
  checkOut?: string | null
  disabled: boolean
  handleSetCheckIn: (providedTime?: string | null) => Promise<any>
  handleSetCheckOut: (providedTime?: string | null) => Promise<any>
}

export const ParticipantCheck = ({
  checkAvailable,
  checkIn,
  checkOut,
  disabled,
  handleSetCheckIn,
  handleSetCheckOut,
}: Props) => (
  <RowWrapper disabled={disabled}>
    <ParticipantCheckIn
      checkIn={checkIn}
      disabled={disabled}
      handleSetCheckIn={handleSetCheckIn}
      isAvailable={checkAvailable}
      isValid={!checkOut}
    />

    <Spacer />

    <ParticipantCheckOut
      checkOut={checkOut}
      disabled={disabled}
      handleSetCheckOut={handleSetCheckOut}
      isAvailable={checkAvailable}
      isValid={!!checkIn}
    />
  </RowWrapper>
)

const RowWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(0.5)}rem;
  `}

  ${({ disabled }) => disabled && `opacity: 0.5`};
`

const Spacer = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
