import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled from 'styled-components/macro'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { FontWeight } from '@/components/Typography'
import { useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { RoomCleaningStatus, SaleAccommodationQuery } from '~generated-types'

type Props = {
  request: NonNullable<
    SaleAccommodationQuery['suitableRooms'][0]
  >['roomReservation']['request']
  participantRooms: NonNullable<
    SaleAccommodationQuery['suitableRooms'][0]
  >['roomReservation']['participantRooms']
}

const ParticipantRoomLabel = ({ request, participantRooms }: Props) => {
  const { palette, spacing } = useTheme()

  const { checkIconValidity } = useGlobalIconsContext()

  const { checkIn, checkOut, room, beds, extraBeds, features } = request

  const participantCount = new Set(
    participantRooms.map(({ participant }) => participant.id)
  ).size

  return (
    <FlexRow alignItems={'center'} justifyContent="flex-start">
      <FlexRow>
        <FlexRow style={{ minWidth: '5.5rem' }}>
          <FontWeight
            semiBold
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          >{`#${room.number}`}</FontWeight>
          {moment().isSame(checkIn.date, 'day') ||
          moment().isSame(checkOut.date, 'day') ? (
            <FontAwesomeIcon
              color={
                room.status.cleaningStatus === RoomCleaningStatus.Clean
                  ? palette.success.main
                  : palette.danger.main
              }
              fixedWidth
              icon="broom"
              style={{
                marginRight: `${spacing.gu(1)}rem`,
                width: spacing.gutter,
              }}
            />
          ) : moment().isAfter(checkIn.date, 'day') &&
            moment().isBefore(checkOut.date, 'day') ? (
            <FontAwesomeIcon
              color={palette.coal.light}
              fixedWidth
              icon="user"
              style={{
                marginRight: `${spacing.gu(1)}rem`,
                width: spacing.gutter,
              }}
            />
          ) : (
            <span style={{ width: `${spacing.gu(3)}rem` }} />
          )}
        </FlexRow>

        <FlexRow
          alignItems="center"
          style={{
            margin: `0 ${spacing.gu(1)}rem`,
            minWidth: `${spacing.gu(20)}rem`,
          }}
        >
          <CheckTypeWrapper>
            {checkIn.type === CheckInTypes.EARLY && (
              <FontAwesomeIcon
                fixedWidth
                icon="sun"
                color={palette.text.light}
                size="sm"
                style={{ marginRight: spacing.gutterSmall }}
              />
            )}
          </CheckTypeWrapper>
          <FontWeight light>
            {moment(checkIn.date).format('DD.MM.')}
            {' – '}
            {moment(checkOut.date).format('DD.MM.YY')}
          </FontWeight>
          <CheckTypeWrapper>
            {checkOut.type === CheckOutTypes.LATE && (
              <FontAwesomeIcon
                icon="moon"
                color={palette.text.light}
                size="xs"
                style={{ marginLeft: spacing.gutterSmall }}
              />
            )}
          </CheckTypeWrapper>
        </FlexRow>

        <FlexRow style={{ width: '10rem' }} justifyContent="center">
          <FlexRow>
            <FontAwesomeIcon
              color={palette.coal.light}
              fixedWidth
              icon="bed"
              style={{
                margin: `0 ${spacing.gu(1)}rem`,
                width: spacing.gutter,
              }}
            />
            <FontWeight
              style={{
                color: palette.primary.dark,
                marginRight: spacing.gutterSmall,
              }}
            >
              {beds}
            </FontWeight>
            <FontWeight
              semiBold
              style={{
                whiteSpace: 'nowrap',
              }}
            >{` / ${room.beds}`}</FontWeight>
          </FlexRow>

          <FlexRow>
            <FontAwesomeIcon
              color={palette.coal.light}
              fixedWidth
              icon="couch"
              style={{
                margin: `0 ${spacing.gu(1)}rem`,
                width: spacing.gutter,
              }}
            />
            <FontWeight
              style={{
                color: palette.primary.dark,
                marginRight: spacing.gutterSmall,
              }}
            >
              {extraBeds}
            </FontWeight>
            <FontWeight
              semiBold
              style={{
                whiteSpace: 'nowrap',
              }}
            >{` / ${room.extraBeds}`}</FontWeight>
          </FlexRow>
        </FlexRow>

        <FlexRow style={{ minWidth: '4rem' }}>
          <FontAwesomeIcon
            color={
              participantCount === request.beds + request.extraBeds
                ? palette.success.main
                : palette.gold
            }
            fixedWidth
            icon="user-group"
            style={{
              margin: `0 ${spacing.gu(1)}rem`,
              width: spacing.gutter,
            }}
          />
          <FontWeight
            style={{
              color: palette.primary.dark,
              marginRight: spacing.gutterSmall,
            }}
          >
            {participantCount}
          </FontWeight>
        </FlexRow>
      </FlexRow>

      <FlexRow style={{ minWidth: '9rem' }}>
        <FontWeight semiBold style={{ marginRight: `${spacing.gu(1)}rem` }}>
          {room.roomType.accommodationLevel.shortName}
        </FontWeight>
        <FontWeight semiBold style={{ marginRight: `${spacing.gu(1)}rem` }}>
          {room.roomType.name}
        </FontWeight>
      </FlexRow>

      <FlexRow>
        {[...features].sort(generateCompareFn('name')).map((feature) => (
          <Tooltip
            key={feature.id}
            content={feature.name}
            trigger={(triggerProps) => (
              <div
                {...triggerProps}
                style={{ marginRight: spacing.gutterSmall }}
              >
                {checkIconValidity(feature.icon) ? (
                  <FontAwesomeIcon
                    icon={feature.icon as IconProp}
                    size={'1x'}
                    color={palette.coal.main}
                  />
                ) : (
                  feature.shortName
                )}
              </div>
            )}
          />
        ))}
      </FlexRow>
    </FlexRow>
  )
}

export default ParticipantRoomLabel

const CheckTypeWrapper = styled.div`
  text-align: center;
  width: 1.5rem;
`
