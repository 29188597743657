import { gql, useMutation } from '@apollo/client'

import {
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
} from '~generated-types'

export type { DeleteTaskMutation, DeleteTaskMutationVariables }

export const DELETE_TASK_MUTATION = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
      deleted
    }
  }
`

export function useDeleteTaskMutation() {
  return useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DELETE_TASK_MUTATION
  )
}

export default useDeleteTaskMutation
