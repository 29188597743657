import { ChangeEvent, useState } from 'react'

import { InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

import { Input, InputWrapper, Title } from './common'

type Props = {
  author: string
  setAuthor: (author: string) => void
}

export const AuthorInput = ({ author, setAuthor }: Props) => {
  const [authorError, setAuthorError] = useState<boolean>(false)

  const checkAuthorError = () => setAuthorError(!author)

  return (
    <InlineModalSection style={{ marginTop: `0` }}>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.author</T> *
        </Title>

        <InputWrapper>
          <Input
            hasError={authorError}
            onBlur={checkAuthorError}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAuthor(e.target.value)
            }
            showBorder
            value={author}
          />
        </InputWrapper>
      </FlexColumn>
    </InlineModalSection>
  )
}
