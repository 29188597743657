import { gql, useMutation } from '@apollo/client'

import {
  AccommodationTargetDeleteMutation,
  AccommodationTargetDeleteMutationVariables,
} from '~generated-types'

export type {
  AccommodationTargetDeleteMutation,
  AccommodationTargetDeleteMutationVariables,
}

export const REMOVE_ACCOMMODATION_TARGET = gql`
  mutation AccommodationTargetDelete($input: AccommodationTargetDeleteInput!) {
    accommodationTargetDelete(input: $input) {
      id
      deleted
    }
  }
`

export function useAccommodationTargetDelete() {
  return useMutation<
    AccommodationTargetDeleteMutation,
    AccommodationTargetDeleteMutationVariables
  >(REMOVE_ACCOMMODATION_TARGET)
}

export default useAccommodationTargetDelete
