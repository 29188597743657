import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  children?: ReactNode
  border?: boolean
  disabled?: boolean
  width?: number
}

// Using memo here is very important as there are very many instances of Cells
// being rendered.
// @ts-ignore
export default React.memo<Props>(styled.div<Props>`
  height: 100%;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
  ${({ disabled, theme }) =>
    disabled ? `background: ${theme.palette.smoke.lighter};` : ''}

  ${({ border, theme }) =>
    border &&
    css`
      border-right: 1px solid ${theme.palette.smoke.main};
    `};
`)
