import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { NotFound } from '@/components/NotFound'
import { PageRoute } from '@/components/Page'
import { globalRoutes } from '@/config/globalRoutes'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'

import { NavInternalViewType as Type, NavItemType } from '~generated-types'

import AccommodationGuestListing from './AccommodationGuestListing'
import CalendarResourceListing from './CalendarResourceListing'
import PacketMealListing from './PacketMealListing'
import RoomReservationsListing from './RoomReservationsListing'
import RoomReservationsListingPajulahti from './RoomReservationsListingPajulahti'
import SalesListing from './SalesListing'

export const ListingRoutes = () => {
  const { path } = useRouteMatch()
  const { allNavDropdownInternalTypes } = useWillbaConfigContext()

  return (
    <Switch>
      <Redirect exact from={path} to={globalRoutes[Type.ListingSales]} />

      {allNavDropdownInternalTypes.includes(Type.ListingReservation) && (
        <PageRoute
          component={CalendarResourceListing}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Listing}`,
            `Navigation:navDropdownItem.${Type.ListingReservation}`,
          ]}
          path={globalRoutes[Type.ListingReservation]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.ListingGuest) && (
        <PageRoute
          component={AccommodationGuestListing}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Listing}`,
            `Navigation:navDropdownItem.${Type.ListingGuest}`,
          ]}
          path={globalRoutes[Type.ListingGuest]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.ListingPacketMeal) && (
        <PageRoute
          component={PacketMealListing}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Listing}`,
            `Navigation:navDropdownItem.${Type.ListingPacketMeal}`,
          ]}
          path={globalRoutes[Type.ListingPacketMeal]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.ListingRoomReservation) && (
        <PageRoute
          component={RoomReservationsListing}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Listing}`,
            `Navigation:navDropdownItem.${Type.ListingRoomReservation}`,
          ]}
          path={globalRoutes[Type.ListingRoomReservation]}
        />
      )}

      {allNavDropdownInternalTypes.includes(
        Type.ListingRoomReservationPajulahti
      ) && (
        <PageRoute
          component={RoomReservationsListingPajulahti}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Listing}`,
            `Navigation:navDropdownItem.${Type.ListingRoomReservationPajulahti}`,
          ]}
          path={globalRoutes[Type.ListingRoomReservationPajulahti]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.ListingSales) && (
        <PageRoute
          component={SalesListing}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Listing}`,
            `Navigation:navDropdownItem.${Type.ListingSales}`,
          ]}
          path={globalRoutes[Type.ListingSales]}
        />
      )}

      <PageRoute component={NotFound} pageTitle="404" />
    </Switch>
  )
}
