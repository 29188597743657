import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import type { CatalogProductItem } from '@/modules/Products/types'

import {
  CostingSection,
  MainSection,
  MealSection,
  RuleSection,
} from './components'

type Props = {
  orderNumber: number
  productItem: CatalogProductItem
  showCosting: boolean
}

export const ProductItemSection = ({
  orderNumber,
  productItem,
  showCosting,
}: Props) => {
  const {
    commissionMode,
    id,
    internalCost,
    internalPurchase,
    marginalTax,
    meals,
    quantityUnit,
    rules,
  } = productItem

  const { isCommissionEnabled } = useWillbaConfigContext()

  const showCostingSection =
    showCosting &&
    (isCommissionEnabled ||
      internalCost !== null ||
      internalPurchase !== null ||
      marginalTax)

  return (
    <Wrapper>
      <MainSection orderNumber={orderNumber} productItem={productItem} />

      {showCostingSection && (
        <CostingSection
          commissionMode={commissionMode}
          id={id}
          internalCost={internalCost}
          internalPurchase={internalPurchase}
          isLast={!meals.length && !rules.length}
          marginalTax={marginalTax}
        />
      )}

      {[...meals]
        .sort(
          (a, b) =>
            moment(a.startTime, 'HH:mm:ss').valueOf() -
            moment(b.startTime, 'HH:mm:ss').valueOf()
        )
        .map((meal, idx) => (
          <MealSection
            isLast={idx === meals.length - 1 && !rules.length}
            key={meal.id}
            meal={meal}
            showBoundary={meals.length > 1}
          />
        ))}

      {[...rules]
        .sort(
          (a, b) =>
            moment(a.auditLog.createdAt).valueOf() -
            moment(b.auditLog.createdAt).valueOf()
        )
        .map((rule, idx) => (
          <RuleSection
            isLast={idx === rules.length - 1}
            key={rule.id}
            quantityUnit={quantityUnit}
            rule={rule}
            ruleNumber={idx + 1}
          />
        ))}
    </Wrapper>
  )
}

///////

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(4)}rem;
  `}
`
