import { gql } from '@apollo/client'

export default gql`
  fragment RoomType on RoomType {
    accommodationLevel {
      name
    }
    id
    name
    rooms {
      id
    }
  }
`
