import { CreateInputKey, TableRow } from '../ExcelImport.types'
import { getKeyByColumnName } from './getKeyByColumnName'

export const parseFileSchema = (schema: TableRow) => {
  const fileSchema: (CreateInputKey | null)[] = []

  schema.forEach((columnName) => {
    const key =
      typeof columnName === 'string' ? getKeyByColumnName(columnName) : null
    fileSchema.push(key)
  })

  return fileSchema
}
