import styled, { css } from 'styled-components/macro'

import { CellProps } from './types'

type Props = CellProps & {
  borderBottomStyle?: 'dashed' | 'dotted' | 'inherit' | 'none' | 'solid'
  roundedBottomLeft?: boolean
  roundedBottomRight?: boolean
  roundedTopLeft?: boolean
  roundedTopRight?: boolean
}

const TD = styled.td<Props>`
  height: 2.5rem;
  padding: ${({ noPadding, customPadding, theme }) =>
    noPadding ? 0 : customPadding || `${theme.spacing.gu(1)}rem`};
  text-align: ${(props) => props.align || 'inherit'};
  vertical-align: ${(props) => props.verticalAlign || 'inherit'};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};

  ${({ borderBottomStyle }) =>
    borderBottomStyle &&
    css<any>`
      &&& {
        border-bottom-style: ${borderBottomStyle} !important;
      }
    `}

  ${({ roundedBottomLeft }) =>
    roundedBottomLeft && 'border-bottom-left-radius: 8px!important;'}
  ${({ roundedBottomRight }) =>
    roundedBottomRight && 'border-bottom-right-radius: 8px!important;'}
  ${({ roundedTopLeft }) =>
    roundedTopLeft && 'border-top-left-radius: 8px!important;'}
  ${({ roundedTopRight }) =>
    roundedTopRight && 'border-top-right-radius: 8px!important;'}
`

export default TD
