import { ChangeEvent, FC, useEffect, useState } from 'react'

type Props = {
  value?: string
}

export const withPriceInputLogic =
  <T extends object>(InputComponent: FC<T>) =>
  (props: Props & T) => {
    const [value, setValue] = useState<string>(props.value || '')

    useEffect(() => {
      props.value !== value && handleChange(props.value || '')
    }, [props.value])

    const handleChange = (value: string) => {
      const numberReg =
        /^-?$|^-?[0-9\b]+$|^-?[0-9\b]+[.]$|^-?[0-9\b]+[.][0-9\b]+$/

      const newValue = value.replace(',', '.')

      numberReg.test(newValue) &&
        setValue(
          newValue[0] === '0'
            ? newValue.length > 1
              ? newValue.slice(1)
              : '0'
            : newValue || '0'
        )
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) =>
      handleChange(e.target.value)

    return <InputComponent {...props} value={value} onChange={onChange} />
  }

export type HOCProps = {
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}
