import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

export const ItemName = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

export const ItemNameWrapper = styled(FlexRow)<{ withPaddingLeft?: boolean }>`
  ${({ theme, withPaddingLeft }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
    padding-left: ${theme.spacing.gu(withPaddingLeft ? 2 : 0)}rem;
  `}
`

export const LightLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

type LineLeaderProps = {
  fullHeight?: boolean
}

export const LineLeader = ({ fullHeight }: LineLeaderProps) => (
  <LineLeaderWrapper fullHeight={fullHeight}>
    <LineLeaderDot />
  </LineLeaderWrapper>
)

const LineLeaderDot = styled.div`
  display: inline-block;
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  right: -3px;
  top: calc(50% - 2px);

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
  `}
`

const LineLeaderWrapper = styled.div<LineLeaderProps>`
  align-self: stretch;
  margin-left: 16px;
  position: relative;
  width: 16px;

  &::after {
    content: ' ';
    position: absolute;
    height: ${({ fullHeight }) => (fullHeight ? '100%' : '50%')};
    width: 2px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    height: 2px;
    width: calc(100%);

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

export const Spacer = styled.span`
  flex: 1;
`
