import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { getQuantityUnit } from '@/modules/Products/utils'
import { Theme, useTheme } from '@/theme'

import { Context } from '../../types'
import { PricingSection, PurchasesSection } from './components'

type Props = {
  onClose: () => void
}

export const SalesProductManager = ({ onClose }: Props) => {
  const { palette } = useTheme()
  const { context, error, loading, product } = useSalesProductManagerContext()

  const renderContent = () => {
    if (error) {
      return (
        <Placeholder alignItems="center" flex={1} justifyContent="center">
          <T>Products:ProductManager.error</T>
        </Placeholder>
      )
    }

    if (loading) {
      return (
        <FlexRow alignItems="center" flex={1} justifyContent="center">
          <ReactLoading
            color={palette.smoke.main}
            height={40}
            type="spin"
            width={40}
          />
        </FlexRow>
      )
    }

    if (product) {
      return (
        <FlexRow flex={1} style={{ overflow: 'hidden' }}>
          <PricingSection product={product} />

          <VerticalDivider />

          <PurchasesSection
            onClose={onClose}
            productId={product.id}
            purchases={product.purchases}
            quantityUnit={getQuantityUnit(product.items)}
          />
        </FlexRow>
      )
    }
  }

  return <Modal context={context}>{renderContent()}</Modal>
}

/////////

const getModalTopOffset = (context: Context, theme: Theme) => {
  switch (context) {
    case 'SALES':
      return `${theme.spacing.guPx(19) + 3}px`
    case 'BOTTOM_SHEET':
      return `${theme.spacing.guPx(17) + 3}px`
    case 'GLOBAL':
      return `${theme.spacing.guPx(9) + 3}px`
    default:
      return 0
  }
}

const Modal = styled(InlineModal)<{ context: Context }>`
  max-height: 100vh;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ context, theme }) => css`
    width: ${theme.spacing.gu(190)}rem;
    height: calc(100vh - ${getModalTopOffset(context, theme)} - 10px);
    margin-top: ${getModalTopOffset(context, theme)};
  `}
`

const VerticalDivider = styled.div`
  height: 100%;
  width: 8px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    border-left: 1px solid ${theme.palette.smoke.main};
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`

const Placeholder = styled(FlexRow)`
  font-weight: 300;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin-bottom: ${theme.spacing.gu(7)}rem;
  `}
`
