import { ReactNode, useState } from 'react'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

type Props = {
  children: ReactNode
}

export const FrameMobile = ({ children }: Props) => {
  const [height, setHeight] = useState<number>(window.innerHeight)

  window.addEventListener('resize', () => setHeight(window.innerHeight))
  window.addEventListener('orientationchange', () =>
    setHeight(window.innerHeight)
  )

  return <Frame style={{ height }}>{children}</Frame>
}

////////////

const Frame = styled(FlexColumn)`
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  z-index: 1;
`
