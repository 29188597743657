import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { Gutter } from '@/components/Layout'

import { CatalogList } from './components'

export const CatalogRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={WrappedCatalogList} exact path={path} />

      {/* TODO Create / edit views */}

      {/*
      <Route
        component={WrappedCreateCatalogForm}
        path={`${match.path}/new`}
      />

      <Route
        component={WrappedEditCatalogForm}
        path={`${match.path}/:catalogId`}
      /> */}
    </Switch>
  )
}

export default CatalogRoutes

////////////

const WrappedCatalogList = () => (
  <Gutter type={[0, 5, 10]}>
    <CatalogList
    // onAdd={() => {
    //   storeSearch()
    //   history.push(`${location.pathname}/new`)
    // }}
    // onOpen={(id) => {
    //   storeSearch()
    //   history.push(`${location.pathname}/${id}`)
    // }}
    />
  </Gutter>
)
