import { gql, useMutation } from '@apollo/client'

import type {
  AddPurchaseFromSalesProductMutation,
  AddPurchaseFromSalesProductMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productPurchase}

  mutation AddPurchaseFromSalesProduct(
    $input: PurchaseProductAddFromSalesProductInput!
  ) {
    purchaseProductAddFromSalesProduct(input: $input) {
      ...ProductPurchase
    }
  }
`

export const useAddPurchaseFromSalesProductMutation = () =>
  useMutation<
    AddPurchaseFromSalesProductMutation,
    AddPurchaseFromSalesProductMutationVariables
  >(MUTATION)
