import { ListHeader, ListHeaderSection } from '@/components/List'
import { T } from '@/modules/Language'

export const OpenAdvancesListHeader = () => (
  <ListHeader>
    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.seller</T>
    </ListHeaderSection>

    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.salesYear</T>
    </ListHeaderSection>

    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.salesMonth</T>
    </ListHeaderSection>

    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.sales</T>
    </ListHeaderSection>

    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.invoice</T>
    </ListHeaderSection>

    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.invoiceDate</T>
    </ListHeaderSection>

    <ListHeaderSection>
      <T>Reports:OpenAdvances.field.customer</T>
    </ListHeaderSection>

    <ListHeaderSection align="right">
      <T>Reports:OpenAdvances.field.openAdvance</T>
    </ListHeaderSection>
  </ListHeader>
)
