import { gql } from '@apollo/client'

export default gql`
  fragment CustomerBase on Customer {
    addresses {
      id
      label
      postalAddress {
        address1
        address2
        city
        country
        postcode
      }
    }
    customerNumber
    defaultAddressId
    id
    labels {
      id
      name
    }
    publicName
  }
`
