import { gql } from '@apollo/client'

export default gql`
  fragment ProductPurchaseStatus on PurchaseProductStatus {
    issues {
      code
      key
      level
    }
    validatedActions {
      action
      issues {
        code
        key
        level
      }
      valid
    }
  }
`
