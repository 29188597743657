import styled, { css } from 'styled-components/macro'

export type Padding = 'full' | 'half' | 'none' | 'vertical'

type Props = {
  mode: Padding
}

const TreePadding = styled.div<Props>`
  align-self: stretch;
  padding: 0;
  position: relative;

  ${({ theme }) => css`
    margin-top: calc(-${theme.spacing.gu(0.25)}rem - 2px);
    margin-bottom: -${theme.spacing.gu(0.25)}rem;
    width: ${theme.spacing.gu(2.5)}rem;
  `}

  ${({ mode }) =>
    (mode === 'full' || mode === 'half' || mode === 'vertical') &&
    css<any>`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        left: 50%;

        ${({ theme }) => css`
          background-color: ${theme.palette.smoke.main};
        `}
      }
    `}

  ${({ mode }) =>
    mode === 'half' &&
    css<any>`
      &::after {
        bottom: calc(50% - 1px);
      }
    `}

    ${({ mode }) =>
    (mode === 'full' || mode === 'half') &&
    css<any>`
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 2px;

        ${({ theme }) => css`
          right: -${theme.spacing.gu(1.5)}rem;
          background-color: ${theme.palette.smoke.main};
        `}
      }
    `}
`

export default TreePadding
