import { gql, useMutation } from '@apollo/client'

import {
  UpdateParticipantMutation,
  UpdateParticipantMutationVariables,
} from '~generated-types'

import { ParticipantFragment } from '../fragments'

export type { UpdateParticipantMutation, UpdateParticipantMutationVariables }

export const UPDATE_PARTICIPANT = gql`
  ${ParticipantFragment}

  mutation UpdateParticipant($input: SalesParticipantUpdateInput!) {
    salesParticipantUpdate(input: $input) {
      ...RoomReservationsForDateParticipant
    }
  }
`

export function useUpdateParticipant() {
  return useMutation<
    UpdateParticipantMutation,
    UpdateParticipantMutationVariables
  >(UPDATE_PARTICIPANT)
}

export default useUpdateParticipant
