import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

import type {
  CreateRefundMutation,
  CreateRefundMutationVariables,
} from '~generated-types'

const CREATE_REFUND_MUTATION = gql`
  ${orderFragments.invoice}

  mutation CreateRefund($input: OrderCreateRefundInput!) {
    orderCreateRefund(input: $input) {
      invoice {
        ...Invoice
      }
    }
  }
`

export const useCreateRefundMutation = () =>
  useMutation<CreateRefundMutation, CreateRefundMutationVariables>(
    CREATE_REFUND_MUTATION
  )
