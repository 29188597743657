import * as Moment from 'moment'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import { v4 as uuidv4 } from 'uuid'

type TimeStep = {
  end: string
  id: string
  start: string
}

export const findTimeSteps = (
  step: number,
  stepType: 'minutes' | 'hours'
): TimeStep[] => {
  const midnight = moment().startOf('day')
  const beforeMidnight = moment().endOf('day')

  const format = 'HH:mm'

  return Array.from(
    extendMoment(Moment).range(midnight, beforeMidnight).by(stepType, { step })
  ).map((x) => ({
    end:
      // Replace 00:00 with 23:59 for the last 'end' step
      moment(x).add(step, stepType).format(format) === '00:00'
        ? '23:59'
        : moment(x).add(step, stepType).format(format),
    id: uuidv4(),
    start: moment(x).format(format),
  }))
}
