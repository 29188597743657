import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { HeaderSectionSeparator } from '@/components/ExpansionPanel'
import { Toggle } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { FontWeight, P } from '@/components/Typography'
import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SalesAccommodationGroup } from '../../../SalesReservationList.types'
import { DefaultConsumption } from './common/DefaultConsumption'

type Props = {
  id: string
  readOnly?: boolean
  status: SalesAccommodationGroup['targets'][0]['status']['status']
  isDefault: boolean
  usingDefault: boolean
  defaultConsumption: number
  isManagerDisabled: boolean
  handleUpdateTarget: (usingDefault: boolean) => Promise<void>
  setManagerOpen: (targetId: string) => void
}

export const TargetStatus = ({
  id,
  status,
  readOnly,
  isDefault,
  usingDefault,
  defaultConsumption,
  isManagerDisabled,
  handleUpdateTarget,
  setManagerOpen,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const useDefaultToggleLabel = translate(
    'Accommodation:TargetGroup.useDefaultCapacity',
    language
  )

  return (
    <>
      {!isDefault && (
        <>
          <FlexRow
            alignItems="center"
            style={{ marginRight: `${theme.spacing.gu(2)}rem` }}
          >
            <Toggle
              disabled={readOnly}
              checked={usingDefault}
              noMargin
              onChange={() => handleUpdateTarget(!usingDefault)}
              onLabel={useDefaultToggleLabel}
              offLabel={useDefaultToggleLabel}
            />
          </FlexRow>

          <HeaderSectionSeparator />
        </>
      )}

      <DefaultConsumption
        consumption={
          isDefault
            ? defaultConsumption
            : status.consumptionBreakdown.floatingConsumption
        }
        hidden={!usingDefault}
      />

      <HeaderSectionSeparator />

      <RoomsStatusWrapper
        disabled={isManagerDisabled}
        onClick={() => (isManagerDisabled ? null : setManagerOpen(id))}
      >
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          fixedWidth
          icon={'plus'}
          size="sm"
        />

        <Rooms>
          <FontAwesomeIcon fixedWidth icon={'door-open'} size="sm" />
          <FontWeight bold>{status.reservedCapacity.rooms}</FontWeight>
        </Rooms>

        <FlexRow
          style={{
            marginRight: `${theme.spacing.gu(1)}rem`,
            whiteSpace: 'nowrap',
            width: `${theme.spacing.gu(11)}rem`,
          }}
        >
          <FontAwesomeIcon fixedWidth icon={'bed'} size="sm" />
          <FontWeight normal>
            {status.reservedCapacity.beds} + {status.reservedCapacity.extraBeds}
          </FontWeight>
        </FlexRow>

        <FlexRow
          alignItems="center"
          style={{
            width: `${theme.spacing.gu(5)}rem`,
          }}
        >
          {!isDefault && (
            <>
              <FontAwesomeIcon fixedWidth icon="user-group" size="sm" />
              <FontWeight normal>
                {status.consumptionBreakdown.consumption -
                  status.consumptionBreakdown.floatingConsumption}
              </FontWeight>
            </>
          )}
        </FlexRow>
      </RoomsStatusWrapper>

      <HeaderSectionSeparator />

      <FlexRow
        alignItems={'center'}
        style={{
          margin: `0 ${theme.spacing.gu(1)}rem`,
          minWidth: `${theme.spacing.gu(6)}rem`,
        }}
      >
        <P style={{ margin: `0` }}>
          <FontAwesomeIcon
            fixedWidth
            icon={'users'}
            size="sm"
            style={{
              marginRight: `${theme.spacing.gu(1)}rem`,
            }}
          />
          <FontWeight bold>
            {status.consumptionBreakdown.consumption}
          </FontWeight>
        </P>
      </FlexRow>
    </>
  )
}

const RoomsStatusWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  text-align: left;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.text.lighter : theme.palette.text.light};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;

    & svg {
      margin-right: ${theme.spacing.gu(1)}rem;
    }
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const Rooms = styled(P)`
  white-space: nowrap;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem 0 0;
    width: ${theme.spacing.gu(6)}rem;
  `}
`
