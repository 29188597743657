import styled, { css } from 'styled-components/macro'

const CountLabel = styled.span`
  color: #797992;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

export default CountLabel
