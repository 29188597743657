import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'

import {
  AgeCategoryFragment,
  Gender,
  SalesDetailsQuery,
} from '~generated-types'

import { Cell } from '../Cell'
import { DaysAndParticipants } from '../DaysAndParticipants'

type VST = NonNullable<SalesDetailsQuery['sales']['vst']>
type VSTParticipant = VST['participantVstSummary'][0]

type Props = {
  ageCategories: AgeCategoryFragment[]
  source: VSTParticipant[]
  sectionTitle: ReactNode
}

export const VisitorsSection = ({
  ageCategories,
  source,
  sectionTitle,
}: Props) => (
  <FlexRow>
    <VerticalDescription>
      <span>{sectionTitle}</span>
    </VerticalDescription>

    <FlexColumn>
      <Header>
        <Cell withBorder>
          <Icon icon="venus" />
        </Cell>
        <Cell withBorder>
          <Icon icon="mars" />
        </Cell>
        <Cell>
          <Icon icon="genderless" />
        </Cell>
      </Header>

      <FlexColumn>
        {[...ageCategories]
          .sort(generateCompareFn('sortOrder'))
          .map(({ key }) => (
            <FlexRow key={key}>
              <Cell withBorder>{getVisitData(source, key, Gender.Female)}</Cell>
              <Cell withBorder>{getVisitData(source, key, Gender.Male)}</Cell>
              <Cell>{getVisitData(source, key, Gender.Unknown)}</Cell>
            </FlexRow>
          ))}

        <FlexRow>
          <Cell withBorder>{getGenderTotalDays(source, Gender.Female)}</Cell>
          <Cell withBorder>{getGenderTotalDays(source, Gender.Male)}</Cell>
          <Cell>{getGenderTotalDays(source, Gender.Unknown)}</Cell>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  </FlexRow>
)

const getGenderTotalDays = (source: VSTParticipant[], gender: Gender) => {
  const totalDays = source
    .filter((summary) => summary.gender === gender && !!summary.ageCategory)
    .map(({ vstDays }) => vstDays)
    .reduce((a, b) => a + b, 0)

  const totalParticipants = source
    .filter((summary) => summary.gender === gender && !!summary.ageCategory)
    .map(({ vstParticipantCount }) => vstParticipantCount)
    .reduce((a, b) => a + b, 0)

  return totalDays === 0 ? (
    <ValuePlaceholder>–</ValuePlaceholder>
  ) : (
    <DaysAndParticipants days={totalDays} participants={totalParticipants} />
  )
}

const getVisitData = (
  source: VSTParticipant[],
  ageCategoryKey: string,
  gender?: Gender
) => {
  const visitors = source.find((v) =>
    gender
      ? v.gender === gender && v.ageCategory?.key === ageCategoryKey
      : !!v.gender && v.ageCategory?.key === ageCategoryKey
  )

  const visitorsDays = visitors && visitors.vstDays
  const participantsCount = visitors && visitors.vstParticipantCount

  return visitorsDays && participantsCount ? (
    <DaysAndParticipants days={visitorsDays} participants={participantsCount} />
  ) : (
    <ValuePlaceholder>–</ValuePlaceholder>
  )
}

//////////////////////

const Header = styled(FlexRow)`
  height: 20px;
  align-items: center;
`

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.text.light};
`

const VerticalDescription = styled.div`
  & > span {
    transform: rotate(-90deg);
    position: absolute;
  }

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.light};
  `}

  width: 20px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ValuePlaceholder = styled.span`
  color: ${({ theme }) => theme.palette.smoke.dark};
`
