import { Chip } from '@/components/Chip'
import { FlexColumn } from '@/components/Layout'
import { ListItemSection } from '@/components/List'
import { T } from '@/modules/Language'

type Props = {
  isOpen: boolean
}

const StatusInfo = ({ isOpen }: Props) => (
  <ListItemSection width="7rem">
    <FlexColumn noPadding>
      {isOpen ? (
        <Chip color="primary" size="sm" variant="outlined">
          <T>Tasks:Task.state.open</T>
        </Chip>
      ) : (
        <Chip size="sm" variant="outlined">
          <T>Tasks:Task.state.closed</T>
        </Chip>
      )}
    </FlexColumn>
  </ListItemSection>
)

export default StatusInfo
