import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  ApolloQueryResult,
  useApolloClient,
  useSubscription,
} from '@apollo/client'

import { FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import {
  Customer,
  Document,
  DocumentEditor,
  OrderItems,
  Payments,
  SectionSeparator,
} from '@/modules/Order/components'
import { orderQueries } from '@/modules/Order/queries'
import { orderSubscriptions } from '@/modules/Order/subscriptions'
import {
  Document as DocumentType,
  Invoice,
  InvoiceByIdPayload,
  InvoiceWithPaymentsByIdPayload,
  InvoiceWithPaymentsByIdVariables,
  OrderType as Order,
  PaymentSubscription,
  PaymentSubscriptionVariables,
} from '@/modules/Order/types'
import {
  updatePaymentFromSubscription,
  updateStateDocument,
} from '@/modules/Order/utils'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'

import { InvoiceAction as IA, PaymentState as PS } from '~generated-types'

import { generateCostingExcel } from './Sections/ActionsSection/Excel'
import { ActionsSection, DetailsSection } from './Sections'

type Props = {
  invoice: Invoice
  isVisible: boolean
  openPaymentsModal: () => void
  order: Order
  refetch: () => Promise<ApolloQueryResult<InvoiceByIdPayload>>
}

export const Content = ({
  invoice,
  isVisible,
  openPaymentsModal,
  order,
  refetch,
}: Props) => {
  const { customer: orderCustomer, id: orderId, sales, seller } = order
  const {
    config,
    customer,
    document: invoiceDocument,
    freeText,
    id,
    lifecycle: { validatedActions },
    payments,
    type,
  } = invoice

  const client = useApolloClient()
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false)

  const [refetchInvoice, { data: invoiceById }] = useLazyQuery<
    InvoiceWithPaymentsByIdPayload,
    InvoiceWithPaymentsByIdVariables
  >(orderQueries.INVOICE_WITH_PAYMENTS_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: { id },
  })

  useEffect(() => {
    refetchInvoice()
  }, [JSON.stringify(payments)])

  useEffect(() => {
    if (invoiceById) {
      setOrdersById((ordersById) => ({
        ...ordersById,
        [orderId]: {
          ...ordersById[orderId],
          invoices: ordersById[orderId].invoices.map((i) =>
            i.id === id ? { ...i, ...invoiceById.invoice } : i
          ),
        },
      }))
    }
  }, [invoiceById])

  useSubscription<PaymentSubscription, PaymentSubscriptionVariables>(
    orderSubscriptions.PAYMENT,
    {
      onData({ data: { data } }) {
        if (data) {
          updatePaymentFromSubscription(data, setOrdersById)
        }
      },
      skip: !isVisible,
      variables: { input: { orderId } },
    }
  )

  const readOnly = !validatedActions.find((a) => a.action === IA.Update)?.valid

  const paidPayments = payments.filter(
    ({ state }) => state === PS.Paid || state === PS.PaidPending
  )

  const onUpdateDocument = (document: DocumentType) =>
    updateStateDocument({
      document,
      orderId,
      ownerId: id,
      ownerType: type,
      setOrdersById,
    })

  const loadCosting = () =>
    generateCostingExcel({
      client,
      invoiceId: id,
      palette,
      translateFn: (keys) => translate(`Orders:${keys}`, language),
    })

  return (
    <>
      <ActionsSection
        invoice={invoice}
        openDocumentModal={() => setDocumentModalOpen(true)}
        openPaymentsModal={openPaymentsModal}
        orderId={orderId}
        loadCosting={loadCosting}
      />

      <SectionSeparator />

      <FlexRow>
        <DetailsSection
          invoice={invoice}
          orderId={orderId}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Customer
          customer={customer}
          orderCustomer={orderCustomer}
          orderId={orderId}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Document
          document={invoiceDocument}
          freeText={freeText}
          isVisible={isVisible}
          onUpdateDocument={onUpdateDocument}
          orderId={orderId}
          ownerId={id}
          ownerType={type}
          readOnly={readOnly}
          refetch={refetch}
        />
      </FlexRow>

      {!!paidPayments.length && (
        <>
          <SectionSeparator />

          <Payments
            invoiceId={id}
            openPaymentsModal={openPaymentsModal}
            orderId={orderId}
            payments={paidPayments}
            sellerId={seller?.id}
          />
        </>
      )}

      <SectionSeparator />

      <OrderItems
        invoice={invoice}
        readOnly={readOnly}
        refetch={refetch}
        sales={sales}
        sellerId={seller?.id}
      />

      {isDocumentModalOpen && invoiceDocument && (
        <DocumentEditor
          config={config}
          document={invoiceDocument}
          freeText={freeText}
          onClose={() => setDocumentModalOpen(false)}
          onUpdateDocument={onUpdateDocument}
          orderId={orderId}
          ownerId={id}
          readOnly={readOnly}
        />
      )}
    </>
  )
}
