import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

import ServiceItemRounded from '../../ParticipantRow/common/ServiceItemRounded'

const AccomodationServiceLoading = () => {
  const { palette, spacing } = useTheme()

  return (
    <>
      <FlexRow style={{ height: '100%', minHeight: '35px' }}>
        <ServiceItemRounded
          loading
          width={'8rem'}
          renderContent={<></>}
          onClick={() => null}
        />

        <ServiceItemRounded
          loading
          width={`${spacing.gu(14)}rem`}
          renderContent={<></>}
          onClick={() => null}
        />

        <ServiceItemRounded
          loading
          width={'15rem'}
          renderContent={<></>}
          onClick={() => null}
        />

        <ProductWrapper />

        <FlexColumn
          alignItems="flex-end"
          justifyContent={'center'}
          noPadding
          style={{
            margin: `0 ${spacing.gu(2)}rem 0 ${spacing.gu(1)}rem`,
            minWidth: '6rem',
          }}
        >
          <div
            style={{
              backgroundColor: palette.smoke.main,
              height: 16,
              marginBottom: 2,
              width: 60,
            }}
          />
          <div
            style={{
              backgroundColor: palette.smoke.light,
              height: 12,
              width: 45,
            }}
          />
        </FlexColumn>

        <RowWrapper>
          <FlexRow
            alignItems={'center'}
            style={{ padding: `${spacing.gu(0.25)}rem` }}
          >
            <IconWrapper borderColor={palette.smoke.dark}>
              <FontAwesomeIcon
                icon="right-to-bracket"
                color={palette.smoke.dark}
              />
            </IconWrapper>
          </FlexRow>

          <FlexRow
            alignItems={'center'}
            style={{ padding: `${spacing.gu(0.25)}rem` }}
          >
            <IconWrapper borderColor={palette.smoke.dark}>
              <FontAwesomeIcon
                icon="right-from-bracket"
                color={palette.smoke.dark}
              />
            </IconWrapper>
          </FlexRow>
        </RowWrapper>
      </FlexRow>
    </>
  )
}

export default AccomodationServiceLoading

const IconWrapper = styled.span<{ borderColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-width: 2px;
  border-style: solid;
  width: 30px;
  height: 30px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
`

const ProductWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  width: 100%;
  overflow: hidden;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;
    border-left: 1px solid ${theme.palette.smoke.main};
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`
