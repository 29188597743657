import { Route } from 'react-router-dom'

import { SalesRoute } from './types'

type Props = {
  routes: SalesRoute[]
  views: (string | null)[]
  baseRoute: string
}

export const SaleComponents = ({ routes, views, baseRoute }: Props) => (
  <>
    {routes
      .map(
        ({ component, key }) =>
          views.includes(key) && (
            <Route key={key} component={component} path={`${baseRoute}`} />
          )
      )
      .filter(Boolean)}
  </>
)
