import React, { useEffect } from 'react'
import moment, { HTML5_FMT } from 'moment'

import { T } from '@/modules/Language'
import { ListingTable } from '@/modules/Listing/common'
import { generateCompareFn } from '@/utils/arrays'

import { SalesType } from '~generated-types'

import { PrintSize } from '../../types'
import { useCellSizeContext } from '../CellSizeContext'
import { Mode, SalesForIsoWeekSales } from '../types'
import SalesTableRow from './SalesTableRow'
import SummaryRow from './SummaryRow'

interface SalesTableProps {
  data: SalesForIsoWeekSales[]
  isoWeek: number
  isoWeekYear: number
  mode: Mode
  printSize: PrintSize
  typeFilter?: SalesType[]
}

export const SalesTable = ({
  data,
  isoWeek,
  isoWeekYear,
  mode,
  printSize,
  typeFilter,
}: SalesTableProps) => {
  const { setTableMode, setTablePrintSize } = useCellSizeContext()

  const getDateKey = (dateOffset: number) =>
    moment(`${isoWeekYear}W${isoWeek}`, 'YYYY[W]W')
      .startOf('isoWeek')
      .add(dateOffset, 'days')
      .format(HTML5_FMT.DATE)
  const dateKeys = [
    getDateKey(0),
    getDateKey(1),
    getDateKey(2),
    getDateKey(3),
    getDateKey(4),
    getDateKey(5),
    getDateKey(6),
  ]

  const renderTableHeader = () => {
    switch (mode) {
      case Mode.FULL:
        return (
          <thead>
            <tr>
              <th>
                <T>Listings:SalesListing.field.customer</T>
              </th>
              <th colSpan={3}>
                <T>Listings:SalesListing.field.sales</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.dates</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.participants</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.beds</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.calendarReservations</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.tasks</T>
              </th>
              {/**
               * TODO: Add when state display format is specified
               */}
              {/* <th>
                <T>Listings:SalesListing.field.state</T>
              </th> */}
              <th>
                <T>Listings:SalesListing.field.seller</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.owner</T>
              </th>
            </tr>
          </thead>
        )
      case Mode.CONCISE:
        return (
          <thead>
            <tr>
              <th>
                <T>Listings:SalesListing.field.customer</T>
              </th>
              <th colSpan={3}>
                <T>Listings:SalesListing.field.sales</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.dates</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.participants</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.beds</T>
              </th>
              <th>
                <T>Listings:SalesListing.field.owner</T>
              </th>
            </tr>
          </thead>
        )
    }
  }

  const salesByDateKey: Record<string, SalesForIsoWeekSales[]> = data.reduce(
    (acc: Record<string, SalesForIsoWeekSales[]>, x) => {
      const salesDate: string =
        x.estimatedDates?.start || x.reservationDates?.start || 'INVALID_DATE'

      if (dateKeys.includes(salesDate)) {
        acc[salesDate] = acc[salesDate] || []
        acc[salesDate].push(x)
      } else if (salesDate.localeCompare(dateKeys[0]) > 0) {
        acc.AFTER = acc.AFTER || []
        acc.AFTER.push(x)
      } else {
        acc.BEFORE = acc.BEFORE || []
        acc.BEFORE.push(x)
      }

      return acc
    },
    {}
  )

  const sortFn = (a: SalesForIsoWeekSales, b: SalesForIsoWeekSales): number => {
    const aDate =
      a.estimatedDates?.start || a.reservationDates?.start || 'NODATE'
    const bDate =
      b.estimatedDates?.start || b.reservationDates?.start || 'NODATE'

    const dateDiff = aDate.localeCompare(bDate)

    if (dateDiff === 0) {
      return generateCompareFn(['name', 'id'])(a, b)
    }

    return dateDiff
  }

  useEffect(() => {
    setTableMode(mode)
    setTablePrintSize(printSize)
  }, [mode, printSize, setTableMode, setTablePrintSize])

  return (
    <ListingTable printSize={printSize}>
      {renderTableHeader()}
      <tbody>
        {salesByDateKey.BEFORE &&
          salesByDateKey.BEFORE.sort(sortFn).map((x) => (
            <SalesTableRow
              data={x}
              key={`sales-table-row-BEFORE-${x.id}`}
              mode={mode}
            />
          ))}

        {dateKeys.map((dateKey) => {
          const sales = salesByDateKey[dateKey]

          return (
            <React.Fragment key={`sales-table-date-${dateKey}`}>
              {sales?.length &&
                sales
                  .sort(sortFn)
                  .map((x) => (
                    <SalesTableRow
                      data={x}
                      key={`sales-table-row-${x.id}`}
                      mode={mode}
                    />
                  ))}
              {mode === Mode.CONCISE && (
                <SummaryRow
                  colSpan={8}
                  date={moment(dateKey)}
                  printSize={printSize}
                  typeFilter={typeFilter}
                />
              )}
            </React.Fragment>
          )
        })}

        {salesByDateKey.AFTER &&
          salesByDateKey.AFTER.sort(sortFn).map((x) => (
            <SalesTableRow
              data={x}
              key={`sales-table-row-AFTER-${x.id}`}
              mode={mode}
            />
          ))}
      </tbody>
    </ListingTable>
  )
}

export default SalesTable
