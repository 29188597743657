import styled, { css } from 'styled-components/macro'

export const InlineModalLine = styled.div<{ dashed?: boolean }>`
  height: 1px;

  ${({ dashed, theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
    border-top: 1px ${dashed ? 'dashed' : 'solid'} ${theme.palette.smoke.main};
  `}
`
