import { gql } from '@apollo/client'

export default gql`
  fragment ProductCategory on Category {
    id
    isAssignable
    isEmphasised
    name
    set {
      id
      name
    }
  }
`
