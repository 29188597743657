import { gql, useMutation } from '@apollo/client'

import type {
  RemoveConfirmationMutation,
  RemoveConfirmationMutationVariables,
} from '~generated-types'

const REMOVE_CONFIRMATION_MUTATION = gql`
  mutation RemoveConfirmation($input: OrderDeleteConfirmationInput!) {
    orderDeleteConfirmation(input: $input) {
      confirmationId
      deleted
    }
  }
`

export const useRemoveConfirmationMutation = () =>
  useMutation<RemoveConfirmationMutation, RemoveConfirmationMutationVariables>(
    REMOVE_CONFIRMATION_MUTATION
  )
