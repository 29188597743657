import { ReactNode, useState } from 'react'
import ReactLoading from 'react-loading'

import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ActionButton } from '../../../common'

type Props = {
  background: string
  disabled: boolean
  onSetCheck: () => Promise<any>
  processing: boolean
  renderAction: (triggerProps?: { [key: string]: any }) => ReactNode
  renderLabel: () => ReactNode
  warningKey: string | null
}

export const CheckInOutButton = ({
  background,
  disabled,
  onSetCheck,
  processing,
  renderAction,
  renderLabel,
  warningKey,
}: Props) => {
  const { palette } = useTheme()

  const [delay, setDelay] = useState<number | undefined>(undefined)
  const [hover, setHover] = useState<boolean>(false)

  const handleMouseEnter = () =>
    setDelay(
      window.setTimeout(() => {
        setHover(true)
      }, 200)
    )

  const handleMouseLeave = () => {
    window.clearTimeout(delay)
    setHover(false)
  }

  const handleSetCheck = () => {
    setHover(false)
    return onSetCheck()
  }

  const renderLoading = () => (
    <ReactLoading
      color={palette.smoke.dark}
      height={24}
      type="spin"
      width={24}
    />
  )

  const renderActionTooltip = (key: string) => (
    <Tooltip
      content={<T>{`FrontDesk:DailyRoomReservations.warning.${key}`}</T>}
      maxWidth={220}
      trigger={(triggerProps) => renderAction(triggerProps)}
    />
  )

  return (
    <ActionButton
      background={background}
      isDisabled={disabled}
      isHover={hover}
      onClick={() => !disabled && handleSetCheck()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {processing
        ? renderLoading()
        : hover
          ? disabled && warningKey
            ? renderActionTooltip(warningKey)
            : renderAction()
          : renderLabel()}
    </ActionButton>
  )
}
