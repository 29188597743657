import { FilterSection } from '@/components/ElasticFilterSearchList'
import { DataSearch, MultiList } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'

type Props = {
  componentIds: {
    [key: string]: string
  }
  getDefaultQuery: () => {
    [key: string]: any
  }
  URLParams?: boolean
}

export const ListFilters = ({
  componentIds,
  getDefaultQuery,
  URLParams,
}: Props) => {
  const { language } = useLanguageContext()

  return (
    <>
      <FilterSection
        label={<T>Catalog:CatalogList.filter.search</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH}
            dataField={'name'}
            filterLabel={translate(
              'Catalog:CatalogList.filter.search',
              language
            )}
            placeholder={translate(
              `Catalog:CatalogList.filter.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            showClear
            showIcon
            URLParams={URLParams}
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Catalog:CatalogList.filter.companyName</T>}
        render={({ isCollapsed }) => (
          <MultiList
            componentId={componentIds.COMPANY}
            dataField="companyName.keyword"
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'Catalog:CatalogList.field.companyName',
              language
            )}
            isCollapsed={isCollapsed}
            missingLabel={translate(
              'Catalog:CatalogList.filter.companyNameMissing',
              language
            )}
            showCheckbox
            showMissing
            showSearch={false}
            URLParams={!!URLParams}
          />
        )}
      />
    </>
  )
}
