import { CategorySet } from '@/modules/Registry/Category'

import { getPathsByNames } from './getPathsByNames'

export const groupNamesBySet = (
  names: string[],
  sets: CategorySet[]
): {
  [setName: string]: string[]
} => {
  const pathsByNames = getPathsByNames(sets)
  const getTargetSetName = (name: string): string | null | undefined =>
    sets.find(({ rootCategories }) =>
      rootCategories.find(
        ({ id }) =>
          pathsByNames[name] && pathsByNames[name].startsWith(`/${id}`)
      )
    )?.name

  return names.reduce((acc: { [setName: string]: string[] }, name) => {
    const formattedName = name.split(';').join(',')
    const setName = getTargetSetName(formattedName)

    return setName
      ? { ...acc, [setName]: [...(acc[setName] || []), formattedName] }
      : acc
  }, {})
}
