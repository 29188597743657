import styled, { css } from 'styled-components/macro'

import { Chip as BaseChip } from '@/components/Chip'
import { InlineModalLine } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ActionButton } from '@/modules/Products/components'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import type { ProductRule } from '@/modules/Products/types'
import { useTheme } from '@/theme'

import { QuantityUnit } from '~generated-types'

import {
  ActionSelector,
  AgeCategorySelector,
  AmountInput,
  BoundarySelector,
  ConditionSelector,
  DateSelector,
  QuantityInput,
  WeekdaySelector,
} from './components'

type Props = {
  isLast: boolean
  productItemId: string
  quantityUnit: QuantityUnit
  rule: ProductRule
  ruleNumber: number
}

export const RuleSection = ({
  isLast,
  productItemId,
  quantityUnit,
  rule,
  ruleNumber,
}: Props) => {
  const { action, condition, id } = rule

  const { palette, spacing } = useTheme()
  const { deleteProductItemRuleWithConfirm, readOnly } =
    useSalesProductManagerContext()

  const renderConditionValue = () => {
    switch (condition.__typename) {
      case 'AgeCategoryRule':
        return (
          <AgeCategorySelector
            ageCategories={condition.ageCategories}
            id={id}
          />
        )
      case 'DateRule':
        return <DateSelector id={id} dates={condition.dates} />
      case 'QuantityRule':
        return (
          <QuantityInput
            id={id}
            quantity={condition.quantity}
            quantityUnit={quantityUnit}
          />
        )
      case 'WeekdayRule':
        return <WeekdaySelector id={id} weekdays={condition.weekdays} />
      case 'BoundaryRule':
        return <BoundarySelector bounds={condition.bounds} id={id} />
      default:
        null
    }
  }

  return (
    <>
      <InlineModalLine dashed style={{ margin: 0 }} />

      <Wrapper isLast={isLast}>
        <FlexRow alignItems="center" justifyContent="space-between" flex={1}>
          <FlexRow>
            <ChipWrapper>
              <Chip size="sm" variant="outlined" color="primary">
                <T>Products:ProductManager.pricing.rule.title</T> {ruleNumber}
              </Chip>
            </ChipWrapper>

            <ConditionSelector quantityUnit={quantityUnit} rule={rule} />

            <ActionSelector rule={rule} />

            {renderConditionValue()}
          </FlexRow>

          <FlexRow>
            {action.__typename === 'AmountPriceRule' && (
              <AmountInput id={id} amount={action.amount} />
            )}

            {!readOnly && (
              <ActionButton
                content={
                  <T>Products:ProductManager.action.deleteProductItemRule</T>
                }
                icon="trash"
                iconColor={palette.danger.main}
                onClick={() =>
                  deleteProductItemRuleWithConfirm(productItemId, id)
                }
                style={{ marginLeft: spacing.gutter }}
              />
            )}
          </FlexRow>
        </FlexRow>
      </Wrapper>
    </>
  )
}

/////////

const Chip = styled(BaseChip)`
  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const ChipWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(11)}rem;
  `}
`

const Wrapper = styled.div<{ isLast: boolean }>`
  display: flex;
  align-items: center;

  height: 100%;

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    `}

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
    background: ${theme.palette.smoke.extraLight};
  `}
`
