import styled, { css } from 'styled-components/macro'

type LineLeaderProps = {
  fullHeight: boolean
}

export const LineLeader = ({ fullHeight }: LineLeaderProps) => (
  <LineLeaderWrapper fullHeight={fullHeight}>
    <LineLeaderDot />
  </LineLeaderWrapper>
)

const LineLeaderDot = styled.div`
  display: inline-block;
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  right: -3px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
    top: calc((${theme.spacing.gu(6)}rem / 2) - 2px);
  `}
`

const LineLeaderWrapper = styled.div<LineLeaderProps>`
  align-self: stretch;
  height: 100%;
  position: relative;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
    margin-right: ${theme.spacing.gu(1)}rem;
    margin-left: calc(${theme.spacing.gu(3)}rem + 8px);
  `}

  &::after {
    content: ' ';
    position: absolute;
    width: 2px;

    ${({ fullHeight, theme }) => css`
      height: ${fullHeight ? '100%' : `calc(${theme.spacing.gu(6)}rem / 2)`};
      background: ${theme.palette.smoke.main};
    `}
  }

  &::before {
    content: ' ';
    position: absolute;
    height: 2px;
    width: calc(100%);

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      top: calc(${theme.spacing.gu(6)}rem / 2);
    `}
  }
`
