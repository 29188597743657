import styled, { css } from 'styled-components/macro'

// "NullDefinition". I chose a initialized version of the name to make the view
// less noisy with this implemented.
export const ND = styled.span`
  font-weight: 400;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase};
  `}
`
