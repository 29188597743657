import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { DateRangePicker } from '@/components/TimeControls'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { SetDatesInput } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import {
  CheckInType,
  CheckOutType,
  ServiceParticipantBedFragment,
} from '~generated-types'

import ServiceItemRounded from '../../../ParticipantRow/common/ServiceItemRounded'

type Dates = {
  checkIn: { date: string; type: CheckInType }
  checkOut: { date: string; type: CheckOutType }
}

type Props = {
  participantId: string
  service: ServiceParticipantBedFragment
  disabled: boolean
  handleServiceSetDates: (
    input: SetDatesInput,
    participantId: string
  ) => Promise<any>
}

export const ParticipantDates = ({
  participantId,
  service,
  disabled,
  handleServiceSetDates,
}: Props) => {
  const { palette, spacing } = useTheme()

  const isDisabled = disabled || !service.dates

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [dates, setDates] = useState<Dates | null>(
    service.dates
      ? {
          checkIn: {
            date: service.dates.checkIn.date,
            type: service.dates.checkIn.type,
          },
          checkOut: {
            date: service.dates.checkOut.date,
            type: service.dates.checkOut.type,
          },
        }
      : null
  )

  useEffect(() => {
    setDates(
      service.dates
        ? {
            checkIn: {
              date: service.dates.checkIn.date,
              type: service.dates.checkIn.type,
            },
            checkOut: {
              date: service.dates.checkOut.date,
              type: service.dates.checkOut.type,
            },
          }
        : null
    )
  }, [service.dates])

  const updateDates = ({
    checkIn,
    checkOut,
    checkInType,
    checkOutType,
  }: {
    checkIn: string
    checkOut: string
    checkInType?: CheckInType
    checkOutType?: CheckOutType
  }) =>
    setDates({
      checkIn: {
        date: checkIn,
        type:
          roomCheckIn && moment(checkIn).isSame(roomCheckIn)
            ? CheckInType.Standard
            : checkInType || dates?.checkIn.type || CheckInType.Standard,
      },
      checkOut: {
        date: checkOut,
        type:
          roomCheckOut && moment(checkOut).isSame(roomCheckOut)
            ? CheckOutType.Standard
            : checkOutType || dates?.checkOut.type || CheckOutType.Standard,
      },
    })

  const roomCheckIn =
    service.participantRoom?.roomReservation.request.checkIn.date || null
  const roomCheckOut =
    service.participantRoom?.roomReservation.request.checkOut.date || null

  const getDatesLabel = ({ checkIn, checkOut }: Dates) => {
    const start = moment(checkIn.date)
    const end = moment(checkOut.date)

    return (
      <p style={{ margin: 0, whiteSpace: 'nowrap' }}>
        {start.isSame(end) ? (
          start.format('dd, D.M.YY')
        ) : (
          <>
            {start.format(`dd, D.M${start.isSame(end, 'year') ? '' : '.YY'}`)}
            {' – '}
            {end.format('dd, D.M.YY')}
          </>
        )}
      </p>
    )
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <DateRangePicker
            disabledDays={
              roomCheckIn && roomCheckOut
                ? [
                    {
                      after: new window.Date(roomCheckOut),
                      before: new window.Date(roomCheckIn),
                    },
                  ]
                : []
            }
            setValue={(newRange) => {
              newRange &&
                updateDates({
                  checkIn: newRange.from.format('YYYY-MM-DD'),
                  checkOut: newRange.to.format('YYYY-MM-DD'),
                })
            }}
            value={
              dates
                ? {
                    from: moment(dates.checkIn.date),
                    to: moment(dates.checkOut.date),
                  }
                : null
            }
          />
          <InlineModalFooter>
            <CheckInOutWrapper flex={1}>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkIn</T>
                </Title>
                <CheckboxInput
                  checked={dates?.checkIn.type === CheckInType.Early}
                  noMargin
                  disabled={
                    !dates ||
                    moment(dates.checkIn.date).isSame(roomCheckIn, 'day')
                  }
                  onChange={() =>
                    dates &&
                    updateDates({
                      checkIn: dates.checkIn.date,
                      checkInType:
                        dates.checkIn.type === CheckInType.Early
                          ? CheckInType.Standard
                          : CheckInType.Early,
                      checkOut: dates.checkOut.date,
                    })
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckInType.Early}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkOut</T>
                </Title>
                <CheckboxInput
                  checked={dates?.checkOut.type === CheckOutType.Late}
                  noMargin
                  disabled={
                    !dates ||
                    moment(dates.checkOut.date).isSame(roomCheckOut, 'day')
                  }
                  onChange={() =>
                    dates &&
                    updateDates({
                      checkIn: dates.checkIn.date,
                      checkOut: dates.checkOut.date,
                      checkOutType:
                        dates.checkOut.type === CheckOutType.Late
                          ? CheckOutType.Standard
                          : CheckOutType.Late,
                    })
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckOutType.Late}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
            </CheckInOutWrapper>
          </InlineModalFooter>
        </InlineModal>
      }
      onClose={() => {
        dates &&
          handleServiceSetDates({ id: service.id, ...dates }, participantId)
        setModalOpen(false)
      }}
      referenceElement={({ ref }) => (
        <ServiceItemRounded
          ref={ref}
          disabled={isDisabled}
          width={'15rem'}
          renderContent={
            <Wrapper>
              <FontAwesomeIcon
                color={palette.primary.main}
                icon={['far', 'calendar']}
              />
              <FlexRow flex={1} justifyContent="center">
                {dates?.checkIn.type === CheckInType.Early && (
                  <FontAwesomeIcon
                    icon="sun"
                    color={palette.warning.dark}
                    size="sm"
                    style={{ margin: `0 ${spacing.gutterSmall}` }}
                  />
                )}
                {dates ? (
                  getDatesLabel(dates)
                ) : (
                  <FontWeight
                    style={{
                      color: palette.smoke.dark,
                      fontStyle: 'italic',
                      paddingLeft: `${spacing.gu(0.5)}rem`,
                    }}
                  >
                    <T>ParticipantsList:ParticipantFormFields.noDates</T>
                  </FontWeight>
                )}
                {dates?.checkOut.type === CheckOutType.Late && (
                  <FontAwesomeIcon
                    icon="moon"
                    color={palette.primary.dark}
                    size="sm"
                    style={{ margin: `0 ${spacing.gutterSmall}` }}
                  />
                )}
              </FlexRow>
            </Wrapper>
          }
          onClick={() => (isDisabled ? null : setModalOpen(true))}
        />
      )}
    />
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutterSmall};
  `}
`

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckInOutWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
