import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { css, keyframes } from 'styled-components/macro'

import { Canvas } from '@/components/Canvas'
import { Delay } from '@/components/Delay'
import { IconButton, InnocuousButton } from '@/components/ExtraButtons'
import { SideDrawerShadow } from '@/components/SideDrawer/SideDrawerShadow'
import { H3 } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  children?: ReactNode
  isTextCloseButton?: boolean
  onBack?: (...args: Array<any>) => any | null | undefined
  onClose: (...args: Array<any>) => any
  title: ReactNode
  zIndex?: number
}

const getNumberOfParentSheets = (
  // TODO: How to type this?
  element: React.ElementRef<any> | null,
  id: string
) => {
  let numberOfParentSheets = 0

  // @ts-ignore
  while (element && element.parentNode) {
    // @ts-ignore
    element = element.parentNode
    // @ts-ignore
    if (element.id === id) {
      numberOfParentSheets++
    }
  }

  return numberOfParentSheets
}

export const BottomSheet = ({
  children,
  isTextCloseButton,
  onBack,
  onClose,
  title,
  zIndex,
}: Props) => {
  const theme = useTheme()

  const bottomSheetRef = useRef(null)
  const [numberOfParentSheets, setNumberOfParentSheets] = useState(0)

  useEffect(() => {
    setNumberOfParentSheets(
      getNumberOfParentSheets(bottomSheetRef.current, 'bottom-sheet')
    )
  }, [])

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault()
    onClose()
  }

  return createPortal(
    <>
      <SideDrawerShadow onClick={handleClose} style={{ width: '100%' }} to="" />

      <FadeInCanvas
        id="bottom-sheet"
        ref={bottomSheetRef}
        style={{
          top: `${(numberOfParentSheets + 1) * theme.spacing.gu(8)}rem`,
          zIndex: zIndex ?? 4020,
        }}
      >
        <Header>
          <ButtonWrapper>
            {onBack && (
              <IconButton
                color="transparent"
                icon="arrow-left"
                onClick={onBack}
              />
            )}
          </ButtonWrapper>
          <Title>{title}</Title>
          <Spacer />
          {isTextCloseButton ? (
            <InnocuousButton
              onClick={onClose}
              style={{ marginRight: `${theme.spacing.gu(2)}rem` }}
            >
              <T>common:action.saveAndClose</T>
            </InnocuousButton>
          ) : (
            <ButtonWrapper>
              <IconButton
                color="transparent"
                icon="xmark"
                onClick={onClose}
                // @ts-ignore (should be ok though?)
                size="lg"
              />
            </ButtonWrapper>
          )}
        </Header>
        <Content>
          <Delay amount={220}>{children}</Delay>
        </Content>
      </FadeInCanvas>
    </>,
    document.getElementById('modal-root') as any
  )
}

////////////

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);}
`

const FadeInCanvas = styled(Canvas)`
  animation: ${fadeIn} 0.2s ease-in;
  border-radius: 18px 18px 0 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;

  @media print {
    &&& {
      position: absolute;
      overflow: visible;
      border-radius: 0;
      top: 0 !important;
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(8)}rem;
    border-bottom: solid 1px ${theme.palette.smoke.main};
  `}

  @media print {
    display: none;
  }
`

const Title = styled(H3)`
  margin: 0%;
`

const Spacer = styled.span`
  flex: 1;
`

const ButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(8)}rem;
  `}
`

const Content = styled.div`
  flex: 1;
  overflow: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;

  @media print {
    width: 100%;
    overflow: visible;
    position: static;
    top: 0;
    left: 0;
  }
`
