import { gql, useMutation } from '@apollo/client'

import {
  SetResourceReservationDimensionsMutation,
  SetResourceReservationDimensionsMutationVariables,
} from '~generated-types'

import ResourceReservationDimensionFragment from '../fragments/resourceReservationDimensions'

export type {
  SetResourceReservationDimensionsMutation,
  SetResourceReservationDimensionsMutationVariables,
}

export const SET_RESOURCE_RESERVATION_DIMENSIONS_MUTATION = gql`
  ${ResourceReservationDimensionFragment}

  mutation SetResourceReservationDimensions(
    $input: ResourceReservationDimensionsSetLabelsInput!
  ) {
    resourceReservationDimensionsSetLabels(input: $input) {
      ...ResourceReservationDimensions
    }
  }
`

export function useSetResourceReservationDimensionMutation() {
  return useMutation<
    SetResourceReservationDimensionsMutation,
    SetResourceReservationDimensionsMutationVariables
  >(SET_RESOURCE_RESERVATION_DIMENSIONS_MUTATION)
}

export default useSetResourceReservationDimensionMutation
