import styled, { css } from 'styled-components/macro'

const H4 = styled.h4`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(1)}rem;
    font-family: ${theme.typography.fontFamilyHeading};
    font-size: ${theme.typography.fontSizeBig};
    font-weight: ${theme.typography.defaultFontWeightHeading};
  `}

  padding: 0;

  &:first-child {
    margin-top: 0;
  }
`

export default H4
