import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T, useLanguageContext } from '@/modules/Language'
import {
  salesQueries,
  SalesStateOptionsPayload,
  SalesStateOptionsVariables,
  SalesStateReasonsPayload,
  SalesStateReasonsVariables,
} from '@/modules/Sales/queries'
import { getStateColor, getStateLabels } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'

import { SalesLifecycleFragment } from '~generated-types'

import { SaleStateModal } from './SaleStateModal'

type Props = {
  salesId: string
  lifecycle: SalesLifecycleFragment
}

export const SaleState = ({ salesId, lifecycle }: Props) => {
  const { palette } = useTheme()
  const { language } = useLanguageContext()

  const { reason, state, specifier: specifierKey } = lifecycle

  const [isModalOpen, setModalOpen] = useState(false)

  const { data, loading } = useQuery<
    SalesStateOptionsPayload,
    SalesStateOptionsVariables
  >(salesQueries.SALES_STATE_OPTIONS, {
    fetchPolicy: 'network-only',
    skip: !isModalOpen,
    variables: { id: salesId },
  })

  const { data: stateReasonsData } = useQuery<
    SalesStateReasonsPayload,
    SalesStateReasonsVariables
  >(salesQueries.SALES_STATE_REASONS, {
    fetchPolicy: 'network-only',
  })

  const stateOptions = data?.sales.lifecycle.stateOptions
  const specifier = (stateReasonsData?.registry.salesStateReasons ?? []).find(
    ({ key }) => key === specifierKey
  )

  return (
    <ModalContainer
      isOpen={isModalOpen && !!data && !loading}
      modal={
        !!stateOptions && (
          <SaleStateModal
            lifecycle={lifecycle}
            salesId={salesId}
            stateOptions={stateOptions}
            onClose={() => setModalOpen(false)}
          />
        )
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Wrapper ref={ref} onClick={() => setModalOpen(true)}>
          <FlexColumn alignItems="flex-start" noPadding>
            <State color={getStateColor(state.systemState, palette)}>
              {getStateLabels(state.names)[language]}
            </State>

            {specifier ? (
              <Specifier>{getStateLabels(specifier.names)[language]}</Specifier>
            ) : reason ? (
              <Specifier>
                <T>{`SalesDetails:Lifecycle.reason.${reason}`}</T>
              </Specifier>
            ) : null}
          </FlexColumn>
          <ChevronIcon icon="chevron-down" size="sm" />
        </Wrapper>
      )}
    />
  )
}

const ChevronIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const State = styled.span`
  font-weight: 600;
  color: ${({ color }) => color};

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Specifier = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  height: 100%;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    border-left: 1px solid ${theme.palette.smoke.main};
    border-right: 1px solid ${theme.palette.smoke.main};
  `}

  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }
`
