import { Ref, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { DataTableInput } from '@/components/DataTable'
import { InlineModal, InlineModalLine } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { DateRangePicker } from '@/components/TimeControls'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import type { ProductPurchaseDuration } from '@/modules/Products/types'
import { parseTimeFromString } from '@/utils/time'

import { QuantityUnit } from '~generated-types'

type Range = { from: Moment; to: Moment }

type Props = {
  duration: ProductPurchaseDuration
  id: string
  quantityUnit: QuantityUnit
  readOnly: boolean
}

export const DatesSelector = ({
  duration,
  id,
  quantityUnit,
  readOnly: purchaseReadOnly,
}: Props) => {
  const { salesReadOnly, updatePurchase } = useSalesProductManagerContext()

  const readOnly = purchaseReadOnly || salesReadOnly

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<Range>({
    from: moment(),
    to: moment(),
  })
  const [endTime, setEndTime] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')

  const setDefaultValues = (duration: ProductPurchaseDuration) => {
    setDateRange({ from: moment(duration.from), to: moment(duration.to) })
    setEndTime(moment(duration.to).format('HH:mm'))
    setStartTime(moment(duration.from).format('HH:mm'))
  }

  useEffect(() => {
    setDefaultValues(duration)
  }, [duration])

  const handleSetDates = () => {
    const endObj = parseTimeFromString(endTime)
    const endString = endObj ? parseTimeFromObject(endObj) : '00:00:00'
    const startObj = parseTimeFromString(startTime)
    const startString = startObj ? parseTimeFromObject(startObj) : '00:00:00'

    const momentEnd = moment(
      `${dateRange.to.format('YYYY-MM-DD')}T${endString}`,
      'YYYY-MM-DDTHH:mm:ss'
    )
    const momentStart = moment(
      `${dateRange.from.format('YYYY-MM-DD')}T${startString}`,
      'YYYY-MM-DDTHH:mm:ss'
    )

    const isSameDate = momentStart.isSame(momentEnd, 'day')
    const isStartAfterEnd = momentStart.isAfter(momentEnd)

    if (isSameDate && isStartAfterEnd && quantityUnit === QuantityUnit.Hour) {
      setError(true)
      return
    }

    setEditMode(false)
    setError(false)

    return updatePurchase({
      attributes: {
        duration: {
          from: momentStart.format('YYYY-MM-DDTHH:mm:ss'),
          to: momentEnd.format('YYYY-MM-DDTHH:mm:ss'),
        },
      },
      purchaseId: id,
    }).then((res) => !res && setDefaultValues(duration))
  }

  return (
    <ModalContainer
      isOpen={isEditMode}
      modal={
        <InlineModal>
          <DateRangePicker
            setValue={(range) => range && setDateRange(range)}
            value={dateRange}
          />

          {quantityUnit === QuantityUnit.Hour && (
            <>
              <InlineModalLine style={{ margin: 0 }} />

              <TimeWrapper alignItems="center" justifyContent="space-between">
                <Input
                  hasError={isError}
                  flex="unset"
                  onBlur={() => setError(false)}
                  onChange={(e) => setStartTime(e.target.value)}
                  onFocus={(e) => e.currentTarget.select()}
                  showBorder
                  value={startTime}
                />
                {` – `}
                <Input
                  hasError={isError}
                  flex="unset"
                  onBlur={() => setError(false)}
                  onChange={(e) => setEndTime(e.target.value)}
                  onFocus={(e) => e.currentTarget.select()}
                  showBorder
                  value={endTime}
                />
              </TimeWrapper>
            </>
          )}
        </InlineModal>
      }
      onClose={handleSetDates}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          ref={ref as Ref<HTMLButtonElement> | undefined}
        >
          {quantityUnit === QuantityUnit.Hour ? (
            <FlexColumn alignItems="center" noPadding>
              <span>{formatDate(duration)}</span>
              <Time>{formatTime(duration)}</Time>
            </FlexColumn>
          ) : (
            formatDate(duration)
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

///////

const formatDate = (date: ProductPurchaseDuration) => {
  const isSameDate = moment(date.from).isSame(moment(date.to), 'day')
  const isSameYear = moment(date.from).isSame(moment(date.to), 'year')

  const start = moment(date.from).format(isSameYear ? 'DD.MM' : 'DD.MM.YY')
  const end = moment(date.to).format('DD.MM.YY')

  return isSameDate ? end : `${start} – ${end}`
}

const formatTime = (date: ProductPurchaseDuration) => {
  const start = moment(date.from).format('HH:mm')
  const end = moment(date.to).format('HH:mm')

  return `${start} – ${end}`
}

type TimeParams = {
  hour: number
  minute: number
}

const parseTimeFromObject = ({ hour, minute }: TimeParams) => {
  const validHour = hour > 9 ? hour : `0${hour}`
  const validMinutes = minute > 9 ? minute : `0${minute}`

  return `${validHour}:${validMinutes}:00`
}

const Button = styled.button`
  cursor: pointer;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gu(1.5)}rem;
    min-height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.5)}rem 0;
    width: ${theme.spacing.gu(20)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.lighter};
      cursor: not-allowed;
    `}
  }
`

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: center;

    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(0.5)}rem;
      max-width: ${theme.spacing.gu(15)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const Time = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const TimeWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(2)}rem;
  `}
`
