import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

export const CheckboxLabel = styled(FlexRow)`
  margin-left: 10px;
`

export const CheckboxWrapper = styled(FlexRow)`
  height: 30px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

export const Divider = styled.div`
  height: 1px;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(0.5)}rem -${theme.spacing.gu(1)}rem;
    background: ${theme.palette.smoke.main};
  `};
`

export const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
