import { gql } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'
import { customerFragments } from '@/modules/Registry/Customer/fragments'

export const ResourceReservationSalesFragment = gql`
  ${customerFragments.customerReference}
  ${orderFragments.orderCustomer}

  fragment ResourceReservationSales on Sales {
    commissionRate
    customer {
      ...CustomerReference
    }
    facet {
      color
      features {
        feature
      }
      id
    }
    id
    lifecycle {
      state {
        key
        names {
          locale
          content
        }
        systemState
      }
    }
    locked
    name
    orderNumber
    orders(filterIsOpen: true) {
      customer {
        ...OrderCustomer
      }
      id
      lifecycle {
        state
      }
      name
      orderNumber
    }
    seller {
      id
      name
    }
    type
  }
`

export default ResourceReservationSalesFragment
