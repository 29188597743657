import styled, { css } from 'styled-components/macro'

import { CellProps } from './types'

type WidthValue = number | string

type Props = CellProps & {
  forcedWidth?: WidthValue
  minWidth?: WidthValue
}

const TH = styled.th<Props>`
  font-weight: 600;
  position: relative;
  text-align: left;
  ${(props) => reconcileWidth(props)}
  padding: ${({ noPadding, customPadding, theme }) =>
    noPadding ? 0 : customPadding || `${theme.spacing.gu(1)}rem`};
  text-align: ${(props) => props.align || 'inherit'};
  vertical-align: ${(props) => props.verticalAlign || 'inherit'};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
`

export default TH

////////////

function reconcileWidth({ forcedWidth, minWidth }: Props) {
  if (forcedWidth) {
    switch (typeof forcedWidth) {
      case 'string':
        return css<any>`
          max-width: ${forcedWidth};
          min-width: ${forcedWidth};
          width: ${forcedWidth};
        `
      case 'number':
        return css<any>`
          max-width: ${forcedWidth}px;
          min-width: ${forcedWidth}px;
          width: ${forcedWidth}px;
        `
      default:
        return ''
    }
  } else if (minWidth) {
    switch (typeof minWidth) {
      case 'string':
        return css<any>`
          min-width: ${minWidth};
        `
      case 'number':
        return css<any>`
          min-width: ${minWidth}px;
        `
      default:
        return ''
    }
  }

  return ''
}
