import styled, { css } from 'styled-components/macro'

type Props = Partial<{
  disabled?: boolean
}>

export const inputActionStyle = css<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled, theme }) => css`
    width: ${theme.spacing.gutterBig};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizeBase};
    color: ${disabled ? theme.palette.text.light : theme.palette.text.main};
    text-align: center;
    line-height: ${theme.spacing.gu(5)}rem;
  `}

  background: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.main
      : 'rgba(255, 255, 255, .8)'};
`

export const InputAction = styled.span<Props>`
  ${inputActionStyle}
`

export default InputAction
