import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { CategorySet } from '@/modules/Registry/Category'
import { generateCompareFn } from '@/utils/arrays'

import {
  CategorySelection,
  CategorySetTree,
  CollapsedCategorySelectorValue,
} from './components'
import {
  getEmphasisedPaths,
  getPathsByNames,
  getQuery,
  groupPathsBySetId,
} from './utils'

type CategorySetChildren = CategorySet['rootCategories'][0]['children']

export type SelectedPathsBySet = {
  [setId: string]: string[]
}

type Props = {
  categorySets: CategorySet[]
  componentId: string
  dataField: string
  defaultSort?: {
    [key: string]: string
  }
  documentCounts: {
    [path: string]: number
  }
  forcedInitialSelection?: string[]
  hideEmpty?: boolean
  isCollapsed?: boolean
  onUpdateSelection?: (arg0: SelectedPathsBySet) => void
  setQuery: (...args: Array<any>) => any
  value: string | null | undefined
}

export const CategorySelector = ({
  categorySets,
  componentId,
  dataField,
  defaultSort,
  documentCounts,
  forcedInitialSelection,
  hideEmpty,
  isCollapsed,
  onUpdateSelection,
  setQuery,
  value,
}: Props) => {
  const initialised = useRef<boolean>(false)
  const [selection, setSelection] = useState<CategorySelection>({})

  const categorySetsSerialised = JSON.stringify(categorySets)
  const defaultSortSerialised = JSON.stringify(defaultSort)

  useEffect(() => {
    try {
      const parsedCategorySets = JSON.parse(categorySetsSerialised)
      const parsedDefaultSort = JSON.parse(defaultSortSerialised)

      const pathsByName = getPathsByNames(parsedCategorySets)
      const namesByPath = Object.assign(
        {},
        ...Object.entries(pathsByName).map(([a, b]) => ({ [b]: a }))
      )

      let propsSelection

      if (forcedInitialSelection) {
        propsSelection = forcedInitialSelection
          ? forcedInitialSelection.reduce(
              (acc, val) => ({ ...acc, [val]: namesByPath[val] }),
              {}
            )
          : {}
      } else {
        const propsNames = value ? value.split(',') : []

        propsSelection = propsNames
          .map((x) => x.trim())
          .map((x) => x.replace(';', ','))
          .map((name) => ({ name, path: pathsByName[name] }))
          .filter(({ path }) => !!path)
          .reduce((acc, { name, path }) => ({ ...acc, [path]: name }), {})
      }

      // Update the selection once on mount to make sure that the selection
      // is up to date. This might be necessary for forcedInitialSelection
      // to be handled correctly.
      // TODO: Think of a more robust way for managing this (might need
      // some refatcoring).
      if (!initialised.current) {
        initialised.current = true
        !!onUpdateSelection &&
          onUpdateSelection(
            groupPathsBySetId(Object.keys(propsSelection), parsedCategorySets)
          )
      }

      setQuery(getQuery(propsSelection, parsedDefaultSort))

      if (value) {
        setSelection(propsSelection)
      } else {
        setSelection({})
      }
    } catch (err) {
      console.warn('Failed to parse categorySets')
    }
  }, [
    categorySetsSerialised,
    defaultSortSerialised,
    dataField,
    forcedInitialSelection,
    onUpdateSelection,
    setQuery,
    value,
  ])

  const toggleCategory = (path: string, name: string) => {
    setSelection((prev) => {
      const next = { ...prev, [path]: name }

      const selectedAncestorPaths = Object.keys(prev).filter(
        (x) => path.startsWith(x) && path.length > x.length
      )
      const selectedSubPaths = Object.keys(prev).filter(
        (x) => x.startsWith(path) && x.length > path.length
      )

      if (!!prev[path]) {
        delete next[path]
      }

      selectedAncestorPaths.forEach((x) => {
        delete next[x]
      })
      selectedSubPaths.forEach((x) => {
        delete next[x]
      })

      !!onUpdateSelection &&
        onUpdateSelection(groupPathsBySetId(Object.keys(next), categorySets))

      setQuery(getQuery(next, defaultSort))

      return next
    })
  }

  if (isCollapsed) {
    return (
      <CollapsedCategorySelectorValue
        categorySets={categorySets}
        componentId={componentId}
        placeholder={<T>ResourceReservationsCalendar:CategorySelector.empty</T>}
      />
    )
  }

  const sortData = (data: CategorySetChildren): CategorySetChildren =>
    [...data].sort(generateCompareFn('name')).map((item) =>
      item.children
        ? {
            ...item,
            children: sortData(item.children as CategorySetChildren),
          }
        : item
    )

  const categorySetsSorted = [...categorySets]
    .sort(generateCompareFn('name'))
    .map((set) => ({
      ...set,
      rootCategories: set.rootCategories
        .sort(generateCompareFn('name'))
        .map((category) => ({
          ...category,
          children: sortData(category.children),
        })),
    }))

  return (
    <Wrapper>
      {categorySetsSorted.map((x) => {
        const emphasisedPaths = getEmphasisedPaths(x)

        return (
          <CategorySetTree
            data={x}
            documentCounts={documentCounts}
            emphasisedPaths={emphasisedPaths}
            expand={
              categorySets.length === 1 || emphasisedPaths.includes('root')
            }
            hideEmpty={hideEmpty}
            key={`category-set-tree-${x.id}`}
            selection={selection}
            toggleCategory={toggleCategory}
          />
        )
      })}
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div`
  border-radius: 4px;
  max-height: 500px;
  overflow: auto;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
