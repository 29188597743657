import { ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'

import { customerHooks } from '../../hooks'
import { Customer, CustomerTarget } from '../../types'
import { DetailsModal, RegistryModal } from './components'

type ManipulationMethods = {
  setAddress: (addressId: string | null) => Promise<undefined>
  setContact: (contactId: string | null) => Promise<undefined>
  setCustomer: (
    args: {
      addressId?: string | null
      contactId?: string | null
      customer: CustomerTarget | null
    } | null
  ) => Promise<undefined>
}

export type CustomerManagerRenderProps = ManipulationMethods & {
  // Data
  customer: Customer | null | undefined
  error: boolean
  loading: boolean
  selectedAddressId: string | null | undefined
  selectedContactId: string | null | undefined
  // Overlay toggles
  openDetails: (target: CustomerTarget) => void
  openRegistry: () => void
}

type Props = ManipulationMethods & {
  renderContent: (arg0: CustomerManagerRenderProps) => ReactNode
  selectedAddressId: string | null | undefined
  selectedContactId: string | null | undefined
  selectedCustomer: CustomerTarget | null | undefined
}

export const CustomerManager = ({
  renderContent,
  selectedAddressId,
  selectedContactId,
  selectedCustomer,
  setAddress,
  setContact,
  setCustomer,
}: Props) => {
  const [sheet, setSheet] = useState<null | 'DETAILS' | 'REGISTRY'>(null)
  const [target, setTarget] = useState<CustomerTarget | null>(null)

  const { customer, error, loading } = customerHooks.useCustomer({
    customerNumber: selectedCustomer?.customerNumber,
  })

  const closeSheet = () => {
    setSheet(null)
    setTarget(null)
  }

  const openDetails = (newTarget: CustomerTarget) => {
    setSheet('DETAILS')
    setTarget(newTarget)
  }

  const openRegistry = () => {
    setSheet('REGISTRY')
  }

  const modalRootEl = document.getElementById('modal-root')

  const renderSheet = () => {
    switch (sheet) {
      case 'DETAILS':
        return target && modalRootEl
          ? createPortal(
              <DetailsModal onClose={closeSheet} target={target} />,
              modalRootEl
            )
          : null

      case 'REGISTRY':
        return modalRootEl
          ? createPortal(
              <RegistryModal
                focusTarget={selectedCustomer}
                onClose={closeSheet}
                onSetCustomer={setCustomer}
              />,
              modalRootEl
            )
          : null
      default:
        return null
    }
  }

  return (
    <>
      {renderContent({
        customer,
        error,
        loading,
        openDetails,
        openRegistry,
        selectedAddressId,
        selectedContactId,
        setAddress,
        setContact,
        setCustomer,
      })}
      {renderSheet()}
    </>
  )
}
