import { gql, useMutation } from '@apollo/client'

import {
  RemoveWebshopContactsMutation,
  RemoveWebshopContactsMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.saleWebshopContacts}

  mutation RemoveWebshopContacts($input: SalesWebshopContactInput!) {
    salesRemoveWebshopContacts(input: $input) {
      ...SalesWebshopContacts
    }
  }
`

export const useRemoveWebshopContactsMutation = () =>
  useMutation<
    RemoveWebshopContactsMutation,
    RemoveWebshopContactsMutationVariables
  >(MUTATION)
