import styled from 'styled-components/macro'

import { inputActionStyle } from '../InputAction'

export const InputActionButton = styled.button.attrs(() => ({
  type: 'button',
}))<any>`
  ${inputActionStyle}

  padding: 0;
  margin: 0;

  border: none;
  background: transparent;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: auto;
`

export default InputActionButton
