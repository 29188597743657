import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  notes: string
  noTitle?: boolean
}

export const Notes = ({ notes, noTitle }: Props) => (
  <Wrapper noTitle={noTitle}>
    {!noTitle && (
      <Title>
        <T>SalesDetails:PrintSale.label.NOTES</T>
      </Title>
    )}

    <NoteWrapper>
      <ReactMarkdown remarkPlugins={[remarkGfm]} children={notes} />
    </NoteWrapper>
  </Wrapper>
)

////////////

const NoteWrapper = styled.span`
  overflow-wrap: anywhere;

  & > p {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(0.5)}rem 0;
    `}
  }

  & > ul {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0;
    `}
  }

  & > ul > li > p {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0;
    `}
  }
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Wrapper = styled(FlexColumn)<{ noTitle?: boolean }>`
  ${({ noTitle, theme }) => css`
    padding: ${theme.spacing.gu(noTitle ? 2 : 0)}rem ${theme.spacing.gu(3)}rem
      ${theme.spacing.gu(2)}rem;
  `}
`
