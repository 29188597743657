import React from 'react'
import styled, { css } from 'styled-components/macro'

import { useReservationsGridState } from '../ReservationsGridState'
import { findTimeSteps } from '../utils'

export default function GridHeader() {
  const {
    calendarVariables: { COLUMN_HEADER_WIDTH, COLUMN_WIDTH, TIME_STEP },
  } = useReservationsGridState()

  const timeLabels = findTimeSteps(TIME_STEP, 'minutes').map(
    ({ start }) => start
  )

  return (
    <HeadRowWrapper leftPadding={COLUMN_HEADER_WIDTH}>
      {timeLabels.map((label: string) => (
        <TimeCell columnWidth={COLUMN_WIDTH * 4} key={label}>
          <TimeCellLabel>{label}</TimeCellLabel>
        </TimeCell>
      ))}
    </HeadRowWrapper>
  )
}

const HeadRowWrapper = styled.div<{ leftPadding: number }>`
  display: block;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3050;
  padding-left: ${({ leftPadding }) =>
    leftPadding ? leftPadding + 'px' : '200px'};

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gutterBig};
    border-bottom: 1px solid ${theme.palette.smoke.dark};
  `}
  /* Hacky fix to prevent nested inline block spacing */
  font-size: 0;
`

const TimeCell = React.memo(styled.div<{ columnWidth: number }>`
  display: inline-block;
  position: relative;
  width: ${({ columnWidth }) => columnWidth}px;
  height: 2rem;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
  `}

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: 1px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`)

const TimeCellLabel = styled.span`
  position: absolute;
  bottom: 60%;
  left: -50%;
  width: 100%;
  text-align: center;
`
