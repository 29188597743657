import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { RadioInput } from '@/components/FormControls'
import {
  InlineModal,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
  InlineModalSection,
} from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { T, useLanguageContext } from '@/modules/Language'
import { SalesStateOption } from '@/modules/Sales/components/SalesDetails/types'
import { useSetSalesStateMutation } from '@/modules/Sales/mutations'
import { getStateColor, getStateLabels } from '@/modules/Sales/utils'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { SalesLifecycleFragment, SalesSystemState } from '~generated-types'

type Props = {
  lifecycle: SalesLifecycleFragment
  onClose: () => void
  salesId: string
  stateOptions: SalesStateOption[]
}

export const SaleStateModal = ({
  lifecycle: { state },
  onClose,
  salesId,
  stateOptions,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const [setSalesState] = useSetSalesStateMutation()

  const [selectedAction, setSelectedAction] = useState<string | null>(null)

  const options = stateOptions
    .filter(
      ({ state: { key, systemState } }) =>
        (systemState === SalesSystemState.Closed ||
          systemState === SalesSystemState.Open) &&
        key !== state.key
    )
    .sort(generateCompareFn('processOrder'))

  const handleSetState = (stateKey: string) =>
    setSalesState({ variables: { input: { salesId, stateKey } } }).then(onClose)

  return (
    <InlineModal style={{ width: '320px' }}>
      <InlineModalHeader
        title={
          <ShiftLeft>
            <T>SalesDetails:Lifecycle.common.saleState</T>
          </ShiftLeft>
        }
        onClose={onClose}
      />
      <InlineModalLine style={{ margin: 0 }} />

      <InlineModalSection>
        <FlexColumn noPadding>
          {options.map(
            ({ issues, state: { key, names, systemState }, valid }) => (
              <OptionWrapper key={key} alignItems="flex-start">
                <RadioInput
                  noMargin
                  disabled={!valid}
                  color={getStateColor(systemState, palette)}
                  checked={key === selectedAction}
                  onChange={() => setSelectedAction(key)}
                >
                  <OptionLabel>{getStateLabels(names)[language]}</OptionLabel>
                </RadioInput>

                {!valid && (
                  <FlexColumn alignItems="flex-start">
                    {issues.map(({ code, key }) => (
                      <IssueLabel key={key}>
                        <T>{`IssueCodes:${code}`}</T>
                      </IssueLabel>
                    ))}
                  </FlexColumn>
                )}
              </OptionWrapper>
            )
          )}
        </FlexColumn>
      </InlineModalSection>

      <InlineModalFooter justifyContent="flex-end">
        <InnocuousButton
          disabled={selectedAction === null}
          onClick={() => selectedAction && handleSetState(selectedAction)}
        >
          <PrimaryDarkColor>
            <T>common:action.apply</T>
          </PrimaryDarkColor>
        </InnocuousButton>
      </InlineModalFooter>
    </InlineModal>
  )
}

//////////

const OptionWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const OptionLabel = styled.span`
  font-weight: 600;
  margin-top: 1px;
`

const PrimaryDarkColor = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`

const IssueLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: calc(${theme.spacing.gu(1)}rem + 16px);
  `}
`
