import React from 'react'
import moment from 'moment'

import {
  ListItemSection,
  ListItemSectionIconValue as IconValue,
} from '@/components/List'
import { T } from '@/modules/Language'

import { ElasticTaskReservation } from '../../../../../types'

type Props = {
  dueDate: string | null | undefined
  dueTime: string | null | undefined
  reservation: ElasticTaskReservation | null | undefined
}

const DueInfo = ({ dueDate, dueTime, reservation }: Props) => {
  const dueMoment = dueDate
    ? moment(`${dueDate}${dueTime ? `T${dueTime}` : ''}`)
    : null
  const dueFromReservation = reservation ? moment(reservation.start) : null

  return (
    <ListItemSection usePlaceholder={<T>Tasks:Task.noDue</T>}>
      {dueMoment ? (
        <>
          <IconValue
            icon={['far', 'calendar']}
            value={dueMoment.format('ll')}
          />
          {dueTime && (
            <IconValue
              icon={['far', 'clock']}
              value={dueMoment.format('HH:mm')}
            />
          )}
        </>
      ) : dueFromReservation ? (
        <>
          <IconValue
            icon={['far', 'calendar']}
            value={dueFromReservation.format('ll')}
          />
          <IconValue
            icon={['far', 'clock']}
            value={dueFromReservation.format('HH:mm')}
          />
        </>
      ) : null}
    </ListItemSection>
  )
}

export default DueInfo
