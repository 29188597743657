import { gql, useQuery } from '@apollo/client'
import { HTML5_FMT, Moment } from 'moment'

import { SalesType } from '~generated-types'
import {
  UseSalesArrivalsCountsForDateQuery as QueryData,
  UseSalesArrivalsCountsForDateQueryVariables as QueryVariables,
} from '~generated-types'

const QUERY = gql`
  query UseSalesArrivalsCountsForDate(
    $input: SalesForDateInput!
    $date: LocalDate!
  ) {
    salesForDate(input: $input) {
      id
      type
      visitStats(date: $date) {
        arriving {
          beds
          extraBeds
        }
        departing {
          beds
          extraBeds
        }
      }
    }
  }
`

interface UseSalesArrivalsCountsForDateProps {
  date: Moment
  typeFilter?: SalesType[]
}

interface ArrivalCounts {
  beds: number
  extraBeds: number
}

interface ArrivalsData {
  arriving: ArrivalCounts
  departing: ArrivalCounts
}

interface UseSalesArrivalsCountsForDateHook {
  data: ArrivalsData
  error: boolean
  loading: boolean
}

const useSalesArrivalsCountsForDate = ({
  date,
  typeFilter,
}: UseSalesArrivalsCountsForDateProps): UseSalesArrivalsCountsForDateHook => {
  const {
    data,
    error: apolloError,
    loading,
  } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      date: date.format(HTML5_FMT.DATE),
      input: {
        date: date.format(HTML5_FMT.DATE),
      },
    },
  })

  const accumulatedData = data
    ? data.salesForDate
        .filter((sales) =>
          typeFilter ? typeFilter.includes(sales.type) : true
        )
        .map((sale) => sale.visitStats)
        .reduce(
          ({ arriving, departing }, val) => ({
            arriving: {
              beds: arriving.beds + val.arriving.beds,
              extraBeds: arriving.extraBeds + val.arriving.extraBeds,
            },
            departing: {
              beds: departing.beds + val.departing.beds,
              extraBeds: departing.extraBeds + val.departing.extraBeds,
            },
          }),
          {
            arriving: {
              beds: 0,
              extraBeds: 0,
            },
            departing: {
              beds: 0,
              extraBeds: 0,
            },
          }
        )
    : {
        arriving: {
          beds: 0,
          extraBeds: 0,
        },
        departing: {
          beds: 0,
          extraBeds: 0,
        },
      }

  return {
    data: accumulatedData,
    error: !!apolloError,
    loading,
  }
}

export default useSalesArrivalsCountsForDate
