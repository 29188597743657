import { useEffect, useState } from 'react'
import WebFont from 'webfontloader'

import { apiCall, StrapiBaseUrl } from '@/utils/api'

import { defaultThemeOpts } from './defaultTheme'
import type { Theme, WebFontConfiguration } from './types'
import {
  generateTheme,
  mapStrapiThemeConfigToThemeOptions,
  parseWebFontConfig,
} from './utils'

export interface UseDynamicThemeHook {
  error: boolean
  loading: boolean
  theme: Theme | null
}

function getFaviconElement() {
  return document.getElementById('favicon')
}

export const useDynamicTheme = (): UseDynamicThemeHook => {
  const [loading, setLoading] = useState<boolean>(true)
  const [theme, setTheme] = useState<Theme | null>(null)
  const [webFontConfig, setWebFontConfig] =
    useState<WebFontConfiguration | null>(null)

  useEffect(() => {
    const fetchDynamicTheme = async () => {
      try {
        const { data, ok } = await apiCall<Record<string, unknown>>(
          `${StrapiBaseUrl}/erp-theme-configuration`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (!ok || !data) {
          throw new Error('No dynamic theme configuration available')
        }

        const parsedOpts = mapStrapiThemeConfigToThemeOptions(data)
        const dynamicTheme = generateTheme(parsedOpts, defaultThemeOpts)

        const parsedWebFontConfig = parseWebFontConfig(data)

        // Update the favicon src
        const favicon = getFaviconElement()

        if (favicon) {
          const faviconAsLink = favicon as HTMLLinkElement

          faviconAsLink.href = dynamicTheme.branding.faviconSrc
        }

        setTheme(dynamicTheme)
        setWebFontConfig(parsedWebFontConfig)
      } catch (err) {
        console.error('Failed to fetch dynamic theme options', err)
      } finally {
        setLoading(false)
      }
    }

    fetchDynamicTheme()
  }, [])

  const serializedWebFontConfig = JSON.stringify(webFontConfig)

  useEffect(() => {
    const { fonts, typekitKitId } = (JSON.parse(serializedWebFontConfig) || {
      fonts: [],
      typekitKitId: null,
    }) as WebFontConfiguration

    const targetFontWeights = [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      '400',
      '400italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ].join(',')

    const webFontLoaderOpts: WebFont.Config = {
      google: {
        // Montserrat is used always (fallback font family)
        families: [`Montserrat:ital,${targetFontWeights}`],
      },
    }

    fonts.forEach(({ service, value }) => {
      if (service === 'google' && value !== 'Montserrat') {
        webFontLoaderOpts.google?.families.push(`${value}:${targetFontWeights}`)
      }
    })

    if (typekitKitId) {
      webFontLoaderOpts.typekit = {
        id: typekitKitId,
      }
    }

    WebFont.load(webFontLoaderOpts)
  }, [serializedWebFontConfig])

  return {
    error: !loading && !theme,
    loading,
    theme,
  }
}
