import { FlexColumn, FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

import ServiceItemRounded from '../../ParticipantRow/common/ServiceItemRounded'

const ParticipantPurchaseServiceLoading = () => {
  const { palette, spacing } = useTheme()

  return (
    <>
      <FlexRow style={{ height: '100%', minHeight: '35px' }}>
        <div style={{ minWidth: `18rem` }} />

        <ServiceItemRounded
          loading
          width={'15rem'}
          renderContent={<></>}
          onClick={() => null}
        />

        <ServiceItemRounded
          loading
          minWidth="8rem"
          renderContent={<></>}
          onClick={() => null}
        />

        <FlexColumn
          alignItems="flex-end"
          justifyContent={'center'}
          noPadding
          style={{
            margin: `0 ${spacing.gu(2)}rem 0 ${spacing.gu(1)}rem`,
            minWidth: '6rem',
          }}
        >
          <div
            style={{
              backgroundColor: palette.smoke.main,
              height: 16,
              marginBottom: 2,
              width: 60,
            }}
          />
          <div
            style={{
              backgroundColor: palette.smoke.light,
              height: 12,
              width: 45,
            }}
          />
        </FlexColumn>

        <span style={{ width: '67px' }} />
      </FlexRow>
    </>
  )
}

export default ParticipantPurchaseServiceLoading
