import styled, { css } from 'styled-components/macro'

const ListTHead = styled.thead`
  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.lighter};
  `}

  & > tr > th {
    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.dark};
    `}

    border-radius: 0;
    border-style: solid;

    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 1px;
    border-right-width: 0;

    vertical-align: bottom;
  }

  & > tr:first-child > th {
    border-top-width: 1px;

    &:first-child {
      border-left-width: 1px;
      border-top-left-radius: 8px;

      ${({ theme }) => css`
        padding-left: ${theme.spacing.gu(5)}rem;
      `}
    }

    &:last-child {
      border-right-width: 1px;
      border-top-right-radius: 8px;

      ${({ theme }) => css`
        padding-right: ${theme.spacing.gu(5)}rem;
      `}
    }
  }
`

export default ListTHead
