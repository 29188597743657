import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'

const ListHeader = () => (
  <Wrapper>
    <Section />
    <Section />
    <Section width="auto" />
    <Section width="12rem" />
    <Section width="11rem" />
    <Section />
    <Section />
  </Wrapper>
)

export default ListHeader
