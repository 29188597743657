import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

export const HeaderWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  flex: 1;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterBig};
  `}
`
