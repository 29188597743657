import { useQuery } from '@apollo/client'

import { USER_GROUPS_QUERY } from './queries'
import { UserGroup } from './types'

export default function useUserGroups() {
  const { data, error, loading } = useQuery(USER_GROUPS_QUERY)
  const userGroups: UserGroup[] = data?.registry?.userGroups || []

  return {
    error,
    loading,
    userGroups,
  }
}
