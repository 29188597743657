import { gql, useMutation } from '@apollo/client'

import type {
  CopyCatalogProductToCatalogMutation,
  CopyCatalogProductToCatalogMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.catalogProduct}

  mutation CopyCatalogProductToCatalog($input: CatalogProductCopyInput!) {
    catalogProductCopy(input: $input) {
      ...CatalogProduct
    }
  }
`

export const useCopyCatalogProductToCatalogMutation = () =>
  useMutation<
    CopyCatalogProductToCatalogMutation,
    CopyCatalogProductToCatalogMutationVariables
  >(MUTATION)
