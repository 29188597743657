import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T, useLanguageContext } from '@/modules/Language'

type Props = {
  date?: string
}

export const SectionTitle = ({ date }: Props) => {
  const { language } = useLanguageContext()

  const isToday = !!(date && moment(date).isSame(moment(), 'day'))

  return (
    <Title isToday={isToday}>
      <Date alignItems="center" justifyContent="flex-end">
        {date ? (
          moment(date).locale(language).format('MMMM Do')
        ) : (
          <T>SalesDetails:PrintSale.noDate</T>
        )}
      </Date>

      <span>{date && moment(date).locale(language).format('dddd')}</span>
    </Title>
  )
}

////////////

const Title = styled(FlexRow)<{ isToday: boolean }>`
  font-size: 1rem;
  font-weight: 600;

  ${({ isToday, theme }) =>
    isToday &&
    css`
      color: ${theme.palette.danger.main};
    `}
`

const Date = styled(FlexRow)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(2)}rem;
    min-width: ${theme.spacing.gu(16)}rem;
  `}
`
