import React, { forwardRef, Ref, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css, keyframes } from 'styled-components/macro'

import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

type Props = {
  isRemovable?: boolean
  disabled?: boolean
  loading?: boolean
  width?: string
  minWidth?: string
  contentTooltip?: string
  renderContent: React.ReactNode
  onClick: () => void
  handleDelete?: () => Promise<any>
}

const ServiceItemRounded = forwardRef(
  (
    {
      isRemovable,
      disabled,
      loading,
      width,
      minWidth,
      contentTooltip,
      renderContent,
      onClick,
      handleDelete,
    }: Props,
    ref
  ) => {
    const { palette } = useTheme()

    const [hover, setHover] = useState<boolean>(false)

    return (
      <Container
        ref={ref as Ref<HTMLDivElement> | undefined}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        width={width}
        minWidth={minWidth}
      >
        <Wrapper
          isRemovable={isRemovable && !disabled}
          disabled={disabled}
          isLoading={loading}
          hover={hover}
          onClick={onClick}
        >
          {loading ? (
            <FloatingBoxWrapper>
              <FloatingBox />
            </FloatingBoxWrapper>
          ) : contentTooltip ? (
            <Tooltip
              content={contentTooltip}
              trigger={(triggerProps) => (
                <div {...triggerProps} style={{ width: '100%' }}>
                  {renderContent}
                </div>
              )}
            />
          ) : (
            <>{renderContent}</>
          )}
        </Wrapper>

        {isRemovable && !disabled && hover && (
          <RemoveWrapper onClick={handleDelete}>
            <FontAwesomeIcon
              color={palette.danger.light}
              icon={'trash'}
              size={'sm'}
            />
          </RemoveWrapper>
        )}
      </Container>
    )
  }
)

export default ServiceItemRounded

const Container = styled.div<{ minWidth?: string; width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  min-width: ${({ width, minWidth }) => minWidth || width || '10rem'};
  ${({ width, minWidth }) =>
    minWidth ? `width: ${minWidth}` : `max-width: ${width || '10rem'} `};
  overflow: hidden;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled.div<{
  disabled?: boolean
  hover: boolean
  isLoading?: boolean
  isRemovable?: boolean
}>`
  position: relative;
  width: calc(100% - 35px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  flex: 1;
  ${({ isRemovable, hover }) =>
    isRemovable && hover
      ? `border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;`
      : `border-radius: 8px;`}
  background-color: ${({ disabled, isLoading, theme }) =>
    isLoading
      ? theme.palette.smoke.main
      : disabled
        ? theme.palette.smoke.light
        : theme.palette.white};
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const RemoveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 0;
  transition: all 0.1s ease-out;
  min-width: 30px;
  max-width: 30px;

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.light};
    background-color: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const float = keyframes`
  0%   { transform: translateX(0%); }
  30%   { transform: translateX(calc(100% + 100px)); }
  100% { transform: translateX(calc(100% + 100px)); }
`

const FloatingBoxWrapper = styled.div`
  position: absolute;
  left: -60px;
  width: 100%;
  animation-name: ${float};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
`

const FloatingBox = styled.div`
  width: 40px;
  height: 80px;
  top: -45px;
  transform: rotate(25deg);

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.light};
  `}
`
