import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input as InputBase } from '@/components/FormControls'
import { ToggleEditableValue } from '@/components/ToggleHelpers'
import { useTheme } from '@/theme'

type Props = {
  placeholder: ReactNode
  setValue: (arg0: string | null | undefined) => void
  value: string | null | undefined
}

const EditableGroupName = ({ placeholder, setValue, value }: Props) => {
  const theme = useTheme()

  const [editedValue, setEditedValue] = useState<string>(value || '')

  useEffect(() => {
    setEditedValue(value || '')
  }, [value])

  return (
    <Wrapper>
      <ToggleEditableValue
        hideEditIcon
        mode="input"
        placeholder={placeholder}
        renderEditor={(onClose) => (
          <Input
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              setValue(event.target.value || null)
              onClose()
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEditedValue(event?.target?.value || '')
            }}
            value={editedValue}
          />
        )}
        style={{ padding: `${theme.spacing.gu(0.5)}rem` }}
        value={value}
      />
    </Wrapper>
  )
}

export default EditableGroupName

////////////

const Input = (props: any) => {
  const inputEl = useRef<any>(null)

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.focus()
  }, [inputEl])

  return (
    <StyledInput
      {...props}
      onKeyDown={(event: React.KeyboardEvent) => {
        // Mimic submitting on enter press
        if (event.key === 'Enter') {
          inputEl && inputEl.current && inputEl.current.blur()
        }
      }}
      ref={inputEl}
    />
  )
}

const StyledInput = styled(InputBase)`
  &&& {
    height: 24px;
    margin: -0.5rem;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`

const Wrapper = styled.span`
  flex: 1;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
    margin-left: ${theme.spacing.gu(1)}rem;
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
