import { gql } from '@apollo/client'

export default gql`
  fragment RoomsAndParticipants on RoomsAndParticipants {
    beds
    checkedParticipants
    checkedRooms
    extraBeds
    participants
    rooms
  }
`
