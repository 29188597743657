import { gql } from '@apollo/client'

import confirmationInfo from './confirmationInfo'
import invoiceInfo from './invoiceInfo'
import offerInfo from './offerInfo'
import orderShortInfo from './orderShortInfo'

export default gql`
  ${confirmationInfo}
  ${invoiceInfo}
  ${offerInfo}
  ${orderShortInfo}

  fragment OrderInfo on Order {
    ...OrderShortInfo

    confirmations {
      ...ConfirmationInfo
    }
    invoices {
      ...InvoiceInfo
    }
    offers {
      ...OfferInfo
    }
  }
`
