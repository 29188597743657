import styled from 'styled-components/macro'

interface Props {
  disabled?: boolean
}

export const HoverHelper = styled.div<Props>`
  flex: 0 1 auto;
  display: flex;
  align-items: center;

  & .ifb-icon {
    opacity: 0;
  }
  &:hover:not([disabled]) .ifb-icon {
    opacity: 1;
  }
`
