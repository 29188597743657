import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import { ListingRoomFeatureFragment } from '@/modules/Listing/fragments'

import {
  RoomReservationsListingPajulahtiQuery as QueryData,
  RoomReservationsListingPajulahtiQueryVariables as QueryVariables,
} from '~generated-types'

import { RoomReservation } from './types'

const QUERY = gql`
  ${ListingRoomFeatureFragment}

  fragment RoomReservationsListingSalesPajulahti on Sales {
    customer {
      customer {
        customerNumber
        id
        ... on CustomerOrganization {
          organization {
            businessId
            name
          }
        }
        ... on CustomerPerson {
          person {
            firstName
            lastName
          }
        }
      }
    }
    id
    name
    orderNumber
    type
  }

  fragment RoomReservationsListingParticipantRoomsPajulahti on ParticipantRoom {
    id
    participant {
      accommodationRequest
      additionalInfo
      age
      ageCategory {
        key
        name
        shortName
      }
      birthday {
        date
        month
        year
      }
      firstName
      gender
      id
      lastName
      nationality
      sales {
        ...RoomReservationsListingSalesPajulahti
      }
    }
    request {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      info
    }
  }

  fragment RoomReservationsListingRequestPajulahti on RoomRequest {
    beds
    checkIn {
      date
      type
    }
    checkOut {
      date
      type
    }
    extraBeds
    features {
      ...ListingRoomFeature
    }
    info
  }

  fragment RoomReservationsListingRoomReservationPajulahti on RoomReservation {
    id
    nextInRoom {
      accommodationGroup {
        sales {
          ...RoomReservationsListingSalesPajulahti
        }
      }
      id
      participantRooms {
        ...RoomReservationsListingParticipantRoomsPajulahti
      }
      request {
        ...RoomReservationsListingRequestPajulahti
      }
    }
    participantRooms {
      ...RoomReservationsListingParticipantRoomsPajulahti
    }
    request {
      ...RoomReservationsListingRequestPajulahti
      room {
        beds
        building {
          id
          name
        }
        extraBeds
        floor
        id
        number
        status {
          cleaningStatus
        }
      }
    }
  }

  query RoomReservationsListingPajulahti($input: ReservationSearchInput!) {
    accommodationRoomReservations(input: $input) {
      arriving {
        ...RoomReservationsListingRoomReservationPajulahti
      }
      departing {
        ...RoomReservationsListingRoomReservationPajulahti
      }
    }
  }
`

interface Props {
  date: Moment
}

interface Hook {
  arriving: RoomReservation[]
  departing: RoomReservation[]
  error: boolean
  loading: boolean
}

const useRoomReservationsPajulahti = ({ date }: Props): Hook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        date: date.format('YYYY-MM-DD'),
      },
    },
  })

  const arriving = (
    data ? [...data.accommodationRoomReservations.arriving] : []
  ).map((x) => ({ ...x, participantRooms: [...x.participantRooms] }))
  const departing = (
    data ? [...data.accommodationRoomReservations.departing] : []
  ).map((x) => ({ ...x, participantRooms: [...x.participantRooms] }))

  return {
    arriving,
    departing,
    error: !!error,
    loading,
  }
}

export default useRoomReservationsPajulahti
