import { RefObject, useEffect, useState } from 'react'

export const useIsVisible = (ref: RefObject<HTMLDivElement> | null) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    ref?.current && observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}
