import { gql } from '@apollo/client'

const paymentPointOfSale = gql`
  fragment PaymentPointOfSale on PointOfSale {
    id
    name
  }
`

export const paymentCashDetails = gql`
  ${paymentPointOfSale}

  fragment PaymentCashDetails on PaymentCashDetails {
    notes
    pointOfSale {
      ...PaymentPointOfSale
    }
  }
`

export const paymentCreditCardDetails = gql`
  ${paymentPointOfSale}

  fragment PaymentCreditCardDetails on PaymentCreditCardDetails {
    notes
    pointOfSale {
      ...PaymentPointOfSale
    }
  }
`

export const paymentGiftCardDetails = gql`
  ${paymentPointOfSale}

  fragment PaymentGiftCardDetails on PaymentGiftCardDetails {
    code
    notes
    pointOfSale {
      ...PaymentPointOfSale
    }
  }
`

export const paymentOtherDetails = gql`
  fragment PaymentOtherDetails on PaymentOtherDetails {
    author
    invoiceReference
    notes
    salesReference
    paymentType: type {
      id
      name
    }
  }
`

export const paymentReferenceTransferDetails = gql`
  fragment PaymentReferenceTransferDetails on PaymentReferenceTransferDetails {
    reference
    notes
    source
  }
`

export const paymentRefundDetails = gql`
  fragment PaymentRefundDetails on PaymentRefundDetails {
    refund {
      invoiceNumber
    }
  }
`

export const paymentReservationFeeDetails = gql`
  fragment PaymentReservationFeeDetails on PaymentReservationFeeDetails {
    reservationFee {
      invoiceNumber
    }
  }
`

export const paymentVoucherDetails = gql`
  ${paymentPointOfSale}

  fragment PaymentVoucherDetails on PaymentVoucherDetails {
    notes
    pointOfSale {
      ...PaymentPointOfSale
    }
    type {
      id
      name
    }
  }
`
