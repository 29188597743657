import { gql } from '@apollo/client'

export default gql`
  query WebshopAvailableScopes($input: WebshopAvailableScopesInput!) {
    webshop {
      availableScopes(input: $input) {
        scopes
      }
    }
  }
`
