import React, { ReactNode, useRef, useState } from 'react'

import { ensureInteger } from './helpers'
import { usePresentationState } from './PresentationState'

type Props = {
  children: ReactNode
  onDragEnd: (...args: Array<any>) => any
}

export const DragEmpty = ({ children, onDragEnd }: Props) => {
  const defaultDragDirections = {
    bottom: true,
    left: true,
  }
  const {
    snapToGridCellBounds,
    columnWidth,
    rowHeight,
    minColumnSpan = 1,
    minRowSpan = 1,
    emptyDragDirections = defaultDragDirections,
  } = usePresentationState()

  const [isDragging, setDragging] = useState(false)
  const [wasDragged, setWasDragged] = useState(false)
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(rowHeight)
  const element = useRef(null)

  const minWidth = minColumnSpan * columnWidth
  const minHeight = minRowSpan * rowHeight

  const getElementOffset = () => {
    if (!element.current) {
      return { left: 0, top: 0 }
    }

    // @ts-ignore
    const { left, top } = element.current.getBoundingClientRect()

    return { left, top }
  }

  const isDragEvent = (e: any) => {
    const className = e.target.className

    return className === 'draggable-item'
  }

  const resetState = () => {
    setDragging(false)
    setWasDragged(false)
    setCoordinates({ x: 0, y: 0 })
    setWidth(0)
    setHeight(0)
  }

  const handleOnMouseDown = (e: any) => {
    if (isDragEvent(e)) {
      return e
    }

    resetState()

    const { left: offsetX, top: offsetY } = getElementOffset()
    const [x, y] = snapToGridCellBounds(
      e.clientX - offsetX,
      e.clientY - offsetY
    )

    setCoordinates({ x, y })
    setWidth(minWidth)
    setHeight(minHeight)
    setDragging(true)
  }

  const handleOnMouseMove = (e: any) => {
    const { left: offsetX, top: offsetY } = getElementOffset()
    const { x: originalX, y: originalY } = coordinates
    const currentX = e.clientX - offsetX
    const realWidth = currentX - originalX
    const currentY = e.clientY - offsetY
    const realHeight = currentY - originalY
    const [width, height] = snapToGridCellBounds(realWidth, realHeight)

    if (realWidth > columnWidth) {
      setWasDragged(true)
    }

    if (emptyDragDirections.left) {
      setWidth(Math.max(width, minWidth))
    }

    if (emptyDragDirections.bottom) {
      setHeight(Math.max(height, minHeight))
    }
  }

  const handleOnMouseUp = () => {
    const { x, y } = coordinates
    const firstIndex = ensureInteger(x / columnWidth)
    const columnCount = ensureInteger(width / columnWidth)
    const overlapsWithColumns = Array.from(
      { length: columnCount },
      (content, i) => firstIndex + i
    )
    const nextFirstRowIndex = ensureInteger(y / rowHeight)
    const nextRowCount = ensureInteger(height / rowHeight)
    const nextRowIndexes = Array.from(
      { length: nextRowCount },
      (content, i) => nextFirstRowIndex + i
    )

    wasDragged &&
      onDragEnd({
        columnIndexes: overlapsWithColumns,
        rowIndexes: nextRowIndexes,
      })

    resetState()
  }

  return (
    <div
      ref={element}
      onMouseDown={handleOnMouseDown}
      onMouseMove={isDragging ? handleOnMouseMove : undefined}
      onMouseUp={isDragging ? handleOnMouseUp : undefined}
      style={{
        bottom: 0,
        height: '100%',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: 'auto',
        zIndex: isDragging ? 2001 : 1999,
      }}
    >
      <div
        style={{
          height: `${height}px`,
          left: 0,
          position: 'absolute',
          top: 0,
          transform: `translate3d(${coordinates.x}px, ${coordinates.y}px, 0)`,
          width: `${width}px`,
          zIndex: 1000,
        }}
      >
        {isDragging && children}
      </div>
    </div>
  )
}
