import {
  ElasticRoom,
  ElasticRoomReservation,
  MomentDateRange,
  Sale,
} from '@/modules/Reservations/components/RoomLayout'
import {
  ElasticAggregation,
  elasticApiAggregations,
  elasticApiGet,
  elasticApiSearch,
} from '@/utils/api'

type ElasticRoomSizeAggregation = {
  roomSizes: ElasticAggregation
}

type RoomLayoutServiceType = {
  fetchRooms: (
    accommodationLevelIds: string[],
    buildingIds: string[],
    featuresIds: string[],
    roomSize: string
  ) => Promise<{ data: ElasticRoom[]; ok: boolean }>
  fetchRoomsByRoomType: (
    roomTypeId: string
  ) => Promise<{ data: ElasticRoom[]; ok: boolean }>
  fetchRoomReservations: (
    accommodationLevelIds: string[],
    buildingIds: string[],
    dateRange: MomentDateRange
  ) => Promise<{ data: ElasticRoomReservation[]; ok: boolean }>
  fetchRoomSizes: () => Promise<{
    data: ElasticRoomSizeAggregation | null | undefined
    ok: boolean
  }>
  fetchSales: (
    accommodationLevelIds: string[],
    dateRange: MomentDateRange,
    salesState: string[]
  ) => Promise<{ data: Sale[]; ok: boolean }>
  fetchTargetSales: (
    targetSalesId: string
  ) => Promise<{ data: Sale | null | undefined; ok: boolean }>
}

const ROOM_INDEX = 'registry.room'
const ROOM_RESERVATIONS_INDEX = 'accommodation.roomreservation'
const SALES_INDEX = 'sales.sales'

// @ts-ignore
const RoomLayoutService: RoomLayoutServiceType = Object.freeze({
  fetchRoomReservations: (
    accommodationLevelIds: string[],
    buildingIds: string[],
    dateRange: MomentDateRange
  ) => {
    const accommodationLevelFilter = {
      terms: {
        'room.accommodationLevel.id': accommodationLevelIds,
      },
    }
    const buildingFilter = {
      terms: {
        'room.building.id': buildingIds,
      },
    }

    const filters: any = [buildingFilter]

    if (accommodationLevelIds.length) {
      filters.push(accommodationLevelFilter)
    }

    return elasticApiSearch(ROOM_RESERVATIONS_INDEX, {
      query: {
        bool: {
          must: [
            {
              range: {
                'request.checkIn.date': {
                  lte: dateRange.to.format('YYYY-MM-DD'),
                },
              },
            },
            {
              range: {
                'request.checkOut.date': {
                  gte: dateRange.from.format('YYYY-MM-DD'),
                },
              },
            },
            ...filters,
          ],
        },
      },

      size: 1000,
    }).then(({ data, ok }) => ({
      data,
      ok,
    }))
  },
  fetchRooms: (
    accommodationLevelIds: string[],
    buildingIds: string[],
    featuresIds: string[],
    roomSize: string
  ) => {
    const accommodationLevelFilter = {
      terms: {
        'accommodationLevel.id': accommodationLevelIds,
      },
    }
    const buildingFilter = {
      terms: {
        'building.id': buildingIds,
      },
    }
    const featureFilter = featuresIds.map((featureId: string) => ({
      match: {
        'features.id': featureId,
      },
    }))
    const roomSizeFilter = {
      term: {
        beds: roomSize,
      },
    }

    const filters: any = [buildingFilter]

    if (accommodationLevelIds.length) {
      filters.push(accommodationLevelFilter)
    }

    if (featuresIds.length) {
      filters.push(...featureFilter)
    }

    if (roomSize) {
      filters.push(roomSizeFilter)
    }

    return elasticApiSearch(ROOM_INDEX, {
      query: {
        bool: {
          must: filters,
        },
      },

      size: 1000,
    }).then(({ data, ok }) => ({
      data,
      ok,
    }))
  },
  fetchRoomsByRoomType: (roomTypeId: string) =>
    elasticApiSearch(ROOM_INDEX, {
      query: {
        bool: {
          must: [
            {
              term: {
                'roomType.id': roomTypeId,
              },
            },
          ],
        },
      },

      size: 1000,
    }).then(({ data, ok }) => ({
      data,
      ok,
    })),
  fetchRoomSizes: () =>
    elasticApiAggregations(ROOM_INDEX, {
      aggs: {
        roomSizes: {
          terms: {
            field: 'beds',
          },
        },
      },

      size: 1000,
    }).then(({ data, ok }) => ({
      data,
      ok,
    })),
  fetchSales: (
    accommodationLevelIds: string[],
    dateRange: MomentDateRange,
    salesState: string[]
  ) => {
    const roomTypeFilter = {
      term: {
        'roomTypeReservations.type': 'ROOM_TYPE_RESERVATION',
      },
    }
    const dateRangeFilter = {
      range: {
        'roomTypeReservations.startDate': {
          gte: dateRange.from.format('YYYY-MM-DD'),
          lte: dateRange.to.format('YYYY-MM-DD'),
        },
      },
    }
    const accommodationLevelFilter = {
      terms: {
        'roomTypeReservations.accommodationLevel.id': accommodationLevelIds,
      },
    }
    const salesStateFilter = {
      terms: {
        state: salesState,
      },
    }

    const filters: any = [roomTypeFilter, dateRangeFilter]

    if (accommodationLevelIds.length) {
      filters.push(accommodationLevelFilter)
    }

    return elasticApiSearch(SALES_INDEX, {
      query: {
        bool: {
          must: [
            salesStateFilter,
            {
              nested: {
                path: 'roomTypeReservations',
                query: {
                  bool: {
                    must: filters,
                  },
                },
              },
            },
          ],
        },
      },

      size: 1000,
    }).then(({ data, ok }) => ({
      data,
      ok,
    }))
  },
  fetchTargetSales: (targetSalesId: string) =>
    elasticApiGet(SALES_INDEX, 'salesdocument', targetSalesId).then(
      ({ data, ok }) => ({
        data,
        ok,
      })
    ),
})

export default RoomLayoutService
