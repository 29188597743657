import { SalesDetails } from './SalesDetails'
import { SalesDetailsContextProvider } from './SalesDetailsState'

type Props = {
  baseRoute: string
  id: string
  viewType?: 'primary' | 'secondary'
  externalEmbeddedSections?: string[]
  onSectionsUpdate?: (sections: string[]) => void
}

export const SalesDetailsContainer = ({
  baseRoute,
  viewType = 'primary',
  ...props
}: Props) => (
  <SalesDetailsContextProvider
    baseRoute={baseRoute}
    viewType={viewType}
    {...props}
  >
    <SalesDetails baseRoute={baseRoute} viewType={viewType} />
  </SalesDetailsContextProvider>
)
