import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

import type {
  SetPaymentTypeMutation,
  SetPaymentTypeMutationVariables,
} from '~generated-types'

const SET_PAYMENT_TYPE_MUTATION = gql`
  ${orderFragments.invoice}

  mutation SetPaymentType($input: OrderInvoiceSetPaymentTypeInput!) {
    orderInvoiceSetPaymentType(input: $input) {
      invoice {
        ...Invoice
      }
    }
  }
`

export const useSetPaymentTypeMutation = () =>
  useMutation<SetPaymentTypeMutation, SetPaymentTypeMutationVariables>(
    SET_PAYMENT_TYPE_MUTATION
  )
