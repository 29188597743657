import { gql, useMutation } from '@apollo/client'

import {
  UpdateWebshopSettingsMutation,
  UpdateWebshopSettingsMutationVariables,
} from '~generated-types'

import { publishFragments } from '../fragments'

const MUTATION = gql`
  ${publishFragments.salesWebshopSettings}

  mutation UpdateWebshopSettings($input: SalesWebshopSettingsInput!) {
    salesSetWebshopSettings(input: $input) {
      id
      webshopSettings {
        ...SalesWebshopSettings
      }
    }
  }
`

export const useUpdateWebshopSettings = () =>
  useMutation<
    UpdateWebshopSettingsMutation,
    UpdateWebshopSettingsMutationVariables
  >(MUTATION)
