/**
 * GENERATED
 */
import {
  RoomReservationsForDateRoomFragment,
  RoomReservationsForDateRoomReservationFragment,
} from '~generated-types'

export type Reservation = RoomReservationsForDateRoomReservationFragment
export type ParticipantRoom = Reservation['participantRooms'][0]
export type Participant = ParticipantRoom['participant']
export type ParticipantSales = Participant['sales']
export type Address = Omit<
  NonNullable<Participant['postalAddress']>,
  '__typename'
>
export type Request = Reservation['request']
export type Feature = Request['features'][0]
export type Sales = Reservation['sales']
export type Customer = NonNullable<Sales['customer']>['customer']
export type Room = RoomReservationsForDateRoomFragment
export type Building = Room['building']

export type RoomReservation = {
  reservations: Reservation[]
  room: Room
}

export {
  RoomReservationCheckAction as CheckType,
  RoomCleaningStatus as CleaningStatus,
  RoomReservationStatus as RoomStatus,
} from '~generated-types'

/**
 * MANUAL
 */

export enum ReservationType {
  Arrivinng = 'ARRIVING',
  Departing = 'DEPARTING',
  Ongoing = 'ONGOING',
  Pending = 'PENDING',
}
