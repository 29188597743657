import styled, { css } from 'styled-components/macro'

import { DataTableInput } from '@/components/DataTable'
import { Label } from '@/components/FormControls'

export const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    border-radius: 6px;

    ${({ theme }) => css`
      height: ${theme.spacing.gu(5)}rem;
    `}

    &:hover, &:focus, &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

export const InputWrapper = styled.div`
  & {
    width: 100%;
  }
`

export const Title = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gu(1)}rem;
  `}
`
