import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'

import { Input, InputWrapper, Title } from './common'

type Props = HOCProps & {
  setAmount: (amount: number) => void
}

const AmountInputBase = ({ value, setAmount, onChange, ...props }: Props) => {
  const [amountError, setAmountError] = useState<boolean>(false)

  const checkAmountError = () => setAmountError(!value || value === '0')

  useEffect(() => {
    setAmount(Number(value || 0))
  }, [value])

  return (
    <Wrapper>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.amount</T> *
        </Title>

        <InputWrapper>
          <Input
            {...props}
            endAdornment="€"
            hasError={amountError}
            onBlur={checkAmountError}
            onChange={onChange}
            onFocus={(e) => e.currentTarget.select()}
            showBorder
            type="text"
            value={value}
          />
        </InputWrapper>
      </FlexColumn>
    </Wrapper>
  )
}

export const AmountInput = withPriceInputLogic(AmountInputBase)

////////

const Wrapper = styled(InlineModalSection)`
  flex: 2;
  margin-top: 0;
  padding-right: 0;
`
