import styled, { css } from 'styled-components/macro'

type Props = {
  hideSideLine?: boolean
  index: number
  isLastRow?: boolean
  isVisible?: boolean
}

export const TreeLine = styled.div<Props>`
  position: sticky;
  flex: 0 0 30px;
  left: ${({ index }) => `${30 * (index || 0)}px`};
  z-index: 3001;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}

  ${({ hideSideLine, isLastRow, isVisible }) =>
    isVisible &&
    css`
      &:after {
        content: ' ';
        position: absolute;
        width: 2px;
        left: 19px;
        top: -5px;
        bottom: ${isLastRow ? '50%' : 0};

        ${({ theme }) => css`
          background: ${theme.palette.smoke.main};
        `}
      }

      ${!hideSideLine &&
      css`
        &:before {
          content: ' ';
          position: absolute;
          height: 2px;
          left: 19px;
          right: -5px;
          top: calc(50% - 1px);

          ${({ theme }) => css`
            background: ${theme.palette.smoke.main};
          `}
        }
      `}
    `}
`
