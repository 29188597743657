import { gql } from '@apollo/client'

import { dimensionFragments } from '../fragments'

export default gql`
  ${dimensionFragments.dimensionReference}

  query SalesDimensions($salesId: ID!) {
    salesDimensions(salesId: $salesId) {
      ...DimensionReference
    }
  }
`
