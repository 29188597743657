import { gql } from '@apollo/client'

import { orderFragments } from '@/modules/Order/fragments'

export default gql`
  ${orderFragments.invoice}

  query InvoiceById($id: ID!) {
    invoice(id: $id) {
      ...Invoice
    }
  }
`
