import styled, { css } from 'styled-components/macro'

export const PageSection = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(8)}rem;
  `}

  &:first-child {
    ${({ theme }) => css`
      margin-top: ${theme.spacing.gu(4)}rem;
    `}
  }
`
