import styled, { css } from 'styled-components/macro'

import TD from '../elements/TD'

const DividerCell = ({ colSpan, rowSpan }: { [key: string]: any }) => (
  <TD colSpan={colSpan} rowSpan={rowSpan} noPadding>
    <Divider />
  </TD>
)

export default DividerCell

////////

const Divider = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
  `}

  width: 1px;
  height: 100%;
`
