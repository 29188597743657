import styled from 'styled-components/macro'

export const OnlyInPrintSpan = styled.span`
  display: none;

  @media print {
    display: inline;
  }
`

export default OnlyInPrintSpan
