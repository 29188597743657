import { gql } from '@apollo/client'

export default gql`
  query SalesProductsGroups($id: ID!) {
    sales(id: $id) {
      id
      products {
        group
        id
      }
    }
  }
`
