import { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { orderQueries } from '@/modules/Order/queries'
import { orderServices } from '@/modules/Order/services'
import {
  Payment,
  PaymentByIdPayload,
  PaymentByIdVariables,
} from '@/modules/Order/types'
import { updateStatePayments } from '@/modules/Order/utils'
import { usePaymentTypeOptions } from '@/modules/Registry/PaymentTypeOption'
import { usePointsOfSales } from '@/modules/Registry/PointOfSales'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  PaymentState as PS,
  PaymentType as PaymentMethod,
} from '~generated-types'

import {
  Actions,
  AmountInput,
  GiftCardCodeInput,
  NotesTextarea,
  PaymentTypesSection,
  PointOfSaleSelector,
  TerminalFailSection,
  TerminalWaitingSection,
} from './components'
import { PaymentType } from './types'

type Props = {
  invoiceId: string
  orderId: string
  payableAmount: number
  payments: Payment[]
  refreshDocument: () => void
  sellerId: string
}

export const PaymentManager = ({
  invoiceId,
  orderId,
  payableAmount,
  payments,
  refreshDocument,
  sellerId,
}: Props) => {
  const {
    addCashPayment,
    addCreditCardPayment,
    addGiftCardPayment,
    addVoucherPayment,
  } = orderServices.invoiceService()
  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const isInitialRender = useRef(true)

  const [amount, setAmount] = useState<number>(payableAmount)
  const [giftCardCode, setGiftCardCode] = useState<string>('')
  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const [observablePayment, setObservablePayment] = useState<Payment | null>(
    null
  )
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null)
  const [paymentError, setPaymentError] = useState<string | null>(null)
  const [pointOfSale, setPointOfSale] = useState<Option | null | undefined>(
    undefined
  )

  const { loading: pointsOfSalesLoading, pointsOfSales } = usePointsOfSales({
    forceRefetch: true,
  })

  const { loading: paymentTypeOptionsLoading, paymentTypeOptions } =
    usePaymentTypeOptions({
      forceRefetch: true,
      input: { pointOfSaleId: pointOfSale?.value ?? null, sellerId },
      skip: pointOfSale === undefined,
    })

  const { data: paymentById } = useQuery<
    PaymentByIdPayload,
    PaymentByIdVariables
  >(orderQueries.PAYMENT_BY_ID, {
    fetchPolicy: 'no-cache',
    pollInterval: 3000,
    skip: !observablePayment,
    variables: { id: observablePayment?.id ?? '' },
  })

  useEffect(() => {
    const paymentInProgress =
      payments.find(({ state }) => state === PS.InProgress) ?? null

    setObservablePayment((prevPayment) => {
      if (prevPayment && !paymentInProgress) {
        const currentPayment = payments.find(({ id }) => id === prevPayment?.id)

        if (currentPayment?.state === PS.Failed) {
          const { externalPayment } = currentPayment

          setPaymentError(externalPayment?.errorCode ?? 'UNKNOW_ERROR')
        }
      }

      return paymentInProgress
    })
  }, [JSON.stringify(payments)])

  useEffect(() => {
    if (paymentById && paymentById.payment.state !== PS.InProgress) {
      updateStatePayments({
        invoiceId,
        modifyPayments: (payments) =>
          payments.map((p) =>
            p.id === paymentById.payment.id ? paymentById.payment : p
          ),
        orderId,
        setOrdersById,
      })
    }
  }, [paymentById])

  useEffect(() => {
    setAmount(payableAmount)
  }, [payableAmount])

  useEffect(() => {
    setPaymentType(null)
  }, [pointOfSale?.value])

  const paidPayments = payments.filter(
    ({ state }) => state === PS.Paid || state === PS.PaidPending
  )

  useEffect(() => {
    // Skip first useEffect run to refresh document only after payments change
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    if (!!paidPayments.length) {
      refreshDocument()
      setPaymentType(null)
      setGiftCardCode('')
      setNotes('')
    }

    return () => refreshDocument()
  }, [paidPayments.length])

  const readOnly = isProcessing || !!paymentError || !!observablePayment

  const handleAddPayment = () => {
    const pointOfSaleId = pointOfSale?.value || ''
    const providerId = paymentType?.providerId || ''
    const terminalId = paymentType?.terminalId

    const attributes = { amount, invoiceId, notes, orderId, pointOfSaleId }

    const doAddPayment = () => {
      setProcessing(true)

      switch (paymentType?.method) {
        case PaymentMethod.CreditCard:
          return addCreditCardPayment({ ...attributes, terminalId })
        case PaymentMethod.GiftCard:
          return addGiftCardPayment({ ...attributes, code: giftCardCode })
        case PaymentMethod.Voucher:
          return addVoucherPayment({ ...attributes, providerId })
        case PaymentMethod.Cash:
        default:
          return addCashPayment(attributes)
      }
    }

    return doAddPayment().then(() => setProcessing(false))
  }

  const renderDynamicSection = () => {
    if (observablePayment) {
      return (
        <TerminalWaitingSection
          invoiceId={invoiceId}
          orderId={orderId}
          paymentId={observablePayment.id}
        />
      )
    }

    if (paymentError) {
      return (
        <TerminalFailSection
          paymentError={paymentError}
          onClose={() => setPaymentError(null)}
        />
      )
    }

    return (
      <>
        <PaymentTypesSection
          loading={pointsOfSalesLoading || paymentTypeOptionsLoading}
          paymentType={paymentType}
          paymentTypeOptions={paymentTypeOptions}
          pointOfSaleId={pointOfSale?.value ?? null}
          readOnly={isProcessing}
          setPaymentType={setPaymentType}
        />

        <LineCompact />

        <Actions
          handleAddPayment={handleAddPayment}
          isProcessing={isProcessing}
          values={{ amount, giftCardCode, paymentType, pointOfSale }}
        />
      </>
    )
  }

  return (
    <Wrapper noPadding>
      <Title>
        <T>Orders:Payments.modal.title</T>
      </Title>

      <BasicDetailsWrapper>
        <AmountInput
          disabled={readOnly}
          value={amount.toString()}
          payableAmount={payableAmount}
          setAmount={setAmount}
        />

        <Spacer />

        <PointOfSaleSelector
          disabled={readOnly}
          loading={pointsOfSalesLoading}
          pointOfSale={pointOfSale}
          pointsOfSales={pointsOfSales}
          setPointOfSale={setPointOfSale}
        />
      </BasicDetailsWrapper>

      <NotesTextarea disabled={readOnly} notes={notes} setNotes={setNotes} />

      {paymentType?.method === PaymentMethod.GiftCard && (
        <GiftCardCodeInput code={giftCardCode} setCode={setGiftCardCode} />
      )}

      <Line />

      {renderDynamicSection()}
    </Wrapper>
  )
}

///////

const BasicDetailsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}
`

const Line = styled.span`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(2)}rem 0;
  `}
`

const LineCompact = styled(Line)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
    padding: ${theme.spacing.gutter};
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;
  background: #f8fafb;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutter};
    border: 1px solid ${theme.palette.smoke.main};
  `}
`
