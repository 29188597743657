import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { MealRowsByDate } from '../../../../types'
import { Column, Header, Row } from '../../../common'
import { NoteRow } from './NoteRow'

type Props = {
  day: MealRowsByDate
}

export const NoteSection = ({ day: { meals } }: Props) => {
  const { spacing } = useTheme()

  return (
    <Column>
      <Row style={{ height: `${spacing.gu(3)}rem` }}>
        <Header width={`${spacing.gu(6)}rem`}>
          <T>SalesDetails:meals.title.notes</T>
        </Header>
      </Row>

      <Column>
        {meals.map((meal) => (
          <NoteRow key={meal.rowId} meal={meal} />
        ))}
      </Column>
    </Column>
  )
}
