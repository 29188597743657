import styled, { css } from 'styled-components/macro'

const H2 = styled.h2`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gutter};
    font-family: ${theme.typography.fontFamilyHeading};
    font-size: ${theme.typography.fontSizeLarge};
    font-weight: ${theme.typography.defaultFontWeightHeading};
  `}

  padding: 0;

  &:first-child {
    margin-top: 0;
  }
`

export default H2
