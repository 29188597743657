import { gql, useMutation } from '@apollo/client'

import {
  SetRoomReservationNotesMutation,
  SetRoomReservationNotesMutationVariables,
} from '~generated-types'

import { RoomReservationFragment } from '../fragments'

export type {
  SetRoomReservationNotesMutation,
  SetRoomReservationNotesMutationVariables,
}

export const SET_ROOM_RESERVATION_NOTES = gql`
  ${RoomReservationFragment}

  mutation SetRoomReservationNotes(
    $input: AccommodationRoomReservationSetNeedsInput!
  ) {
    accommodationRoomReservationSetNeeds(input: $input) {
      ... on AccommodationRoomReservationPayloadOk {
        roomReservation {
          ...RoomReservationsForDateRoomReservation
        }
      }
    }
  }
`

export function useSetRoomReservationNotes() {
  return useMutation<
    SetRoomReservationNotesMutation,
    SetRoomReservationNotesMutationVariables
  >(SET_ROOM_RESERVATION_NOTES)
}

export default useSetRoomReservationNotes
