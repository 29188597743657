import { gql, useMutation } from '@apollo/client'

import type {
  RemoveInvoiceMutation,
  RemoveInvoiceMutationVariables,
} from '~generated-types'

const REMOVE_INVOICE_MUTATION = gql`
  mutation RemoveInvoice($input: OrderDeleteInvoiceInput!) {
    orderDeleteInvoice(input: $input) {
      deleted
      invoiceId
    }
  }
`

export const useRemoveInvoiceMutation = () =>
  useMutation<RemoveInvoiceMutation, RemoveInvoiceMutationVariables>(
    REMOVE_INVOICE_MUTATION
  )
