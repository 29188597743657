import { ById } from '@/common/types'

import { OrderType as Order, Payment, PaymentSubscription } from '../types'
import { updateStatePayments } from './updateStatePayments'

type StateType<T> = T | ((data: T) => T)

export const updatePaymentFromSubscription = (
  data: PaymentSubscription,
  setOrdersById: (ordersById: StateType<ById<Order>>) => void
) => {
  if (data) {
    const { event, invoiceId, orderId, paymentId } = data.payment

    const updatePayments = (
      modifyPayments: (payments: Payment[]) => Payment[]
    ) =>
      updateStatePayments({ invoiceId, modifyPayments, orderId, setOrdersById })

    switch (event.type) {
      case 'CREATE':
        if (event.data) {
          const newPayment = event.data as Payment

          updatePayments((payments) =>
            !payments.find(({ id }) => id === newPayment.id)
              ? [...payments, newPayment]
              : payments
          )
        }
        return
      case 'DELETE':
        updatePayments((payments) => payments.filter((p) => p.id !== paymentId))
        return
      case 'UPDATE':
        if (event.data) {
          const newPayment = event.data as Payment

          updatePayments((payments) =>
            payments.map((p) => {
              if (p.id === paymentId) {
                if (newPayment.auditLog.updatedAt > p.auditLog.updatedAt) {
                  return newPayment
                }
              }

              return p
            })
          )
        }
        return
      default:
        return
    }
  }
}
