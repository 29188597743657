import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/components'
import { MarginalTax } from '@/modules/Products/types'

import { DeleteIcon, Title } from './common'
import { MarginalVatInput } from './MarginalVatInput'

type Props = {
  id: string
  marginalTax: MarginalTax
}

export const MarginalTaxSection = ({
  id,
  marginalTax: { active, marginalTaxRate },
}: Props) => {
  const { updateProductItem } = useCatalogProductManagerContext()
  const { isMarginalTaxEnabled } = useWillbaConfigContext()

  return (
    <FlexColumn>
      <Title>
        <T>Products:ProductManager.pricing.marginalTax</T>
      </Title>

      <FlexRow alignItems="center">
        <CheckboxWrapper>
          <CheckboxInput
            checked={active}
            disabled={!isMarginalTaxEnabled}
            noMargin
            onChange={() =>
              updateProductItem({ id, marginalTax: { active: !active } })
            }
          />
        </CheckboxWrapper>

        <MarginalVatInput
          disabled={!active || !isMarginalTaxEnabled}
          id={id}
          vatRate={marginalTaxRate}
        />

        {isMarginalTaxEnabled && (
          <DeleteIcon
            icon="xmark"
            onClick={() => updateProductItem({ id, marginalTax: null })}
          />
        )}
      </FlexRow>
    </FlexColumn>
  )
}

/////////

const CheckboxWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
