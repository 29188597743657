import { gql, useMutation } from '@apollo/client'

import {
  SetSalesCustomerMutation,
  SetSalesCustomerMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation SetSalesCustomer($input: SalesSetCustomerInput!) {
    salesSetCustomer(input: $input) {
      sales {
        id
      }
    }
  }
`

export const useSetSalesCustomerMutation = () =>
  useMutation<SetSalesCustomerMutation, SetSalesCustomerMutationVariables>(
    MUTATION
  )
