import { useState } from 'react'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  FetchedParticipant,
  Participant as ParticipantType,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { Participant } from '@/modules/ParticipantsList/Participant'

import { OrderState as OS } from '~generated-types'

import { DataRowsSwitcher } from '../../components'
import { MessageWrapper } from '../../components/common'
import { RoomsList } from './../RoomsList'

type Props = {
  readOnly?: boolean
}

export const RoomGrouping = ({ readOnly }: Props) => {
  const { salesId, participants } = useParticipantsListContext()

  const [dataRows, setDataRows] = useState({
    SERVICES: true,
    // eslint-disable-next-line
    PERSONAL: false,
    VST: false,
  })

  const noRoomParticipants = participants.filter((participant) =>
    (participant as FetchedParticipant).services?.find(
      (service) => service.__typename === 'ServiceParticipantBed'
    )
      ? (participant as FetchedParticipant).services.find(
          (service) =>
            service.__typename === 'ServiceParticipantBed' &&
            !service.participantRoom?.id
        )
      : true
  )

  return (
    <FlexColumn noPadding style={{ paddingTop: '2rem' }}>
      <DataRowsSwitcher dataRows={dataRows} setDataRows={setDataRows} />
      <FlexColumn noPadding>
        {noRoomParticipants.length ? (
          noRoomParticipants.map((p) => {
            const participant = p as ParticipantType

            const participantNotRemovable = participant.services.length
              ? !!participant.services.find(({ purchaseProduct }) => {
                  const state = purchaseProduct?.order?.lifecycle.state
                  return !!state && state !== OS.Open && state !== OS.Correction
                })
              : false

            return (
              <Participant
                key={participant.id}
                participant={participant}
                isVisible
                notRemovable={participantNotRemovable}
                readOnly={readOnly}
                dataRows={dataRows}
                isSelectable={false}
                selectedIds={[]}
              />
            )
          })
        ) : (
          <MessageWrapper message={<T>ParticipantsList:empty</T>} />
        )}
      </FlexColumn>
      <RoomsList
        ownerId={salesId}
        participants={participants}
        readOnly={readOnly}
      />
    </FlexColumn>
  )
}
