import { CSSProperties, useState } from 'react'
import { OptionProps, OptionTypeBase } from 'react-select'

import { PrimaryColor } from '@/components/Colors'
import { EditButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { Theme, useTheme } from '@/theme'

import { CustomerAddress } from '../types'
import { CustomerAddressDisplayName } from './CustomerAddressDisplayName'
import { CustomerIconValue } from './CustomerIconValue'

type Props = {
  addresses: CustomerAddress[]
  defaultAddressId: string | null
  editInRegistry: () => void
  readOnly?: boolean
  selectedAddress: CustomerAddress | null
  setAddress: (addressId: string | null) => Promise<void>
}

export const CustomerAddressSelector = ({
  addresses,
  defaultAddressId,
  editInRegistry,
  readOnly,
  selectedAddress,
  setAddress,
}: Props) => {
  const theme = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const addressOptions = [
    ...addresses.map((address) => ({
      label: (
        <CustomerIconValue icon="location-dot">
          <CustomerAddressDisplayName
            address={address}
            isDefault={defaultAddressId === address.id}
          />
        </CustomerIconValue>
      ),
      value: address.id,
    })),
    {
      label: (
        <FontWeight semiBold>
          <PrimaryColor>
            + <T>Customers:addresses.add</T>
          </PrimaryColor>
        </FontWeight>
      ),
      value: 'NEW',
    },
  ]

  const selectedOption =
    addressOptions.find(({ value }) => value === selectedAddress?.id) || null

  const handleSelectAddress = (option?: Option | null) => {
    if (option?.value === 'NEW') {
      editInRegistry()
    } else {
      setProcessing(true)
      setAddress(option?.value ?? null)
        .catch(() => undefined)
        .finally(() => {
          setProcessing(false)
          setEditMode(false)
        })
    }
  }

  return (
    <FlexRow>
      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(theme)}
          isCompact
          isDisabled={readOnly || processing}
          isLoading={processing}
          menuIsOpen={isEditMode}
          name="customer-address"
          noOptionsMessage={() => <T>Customers:addresses.empty</T>}
          onBlur={() => setEditMode(false)}
          onChange={handleSelectAddress}
          options={addressOptions}
          placeholder={<T>Customers:address.placeholder</T>}
          value={selectedOption}
        />
      ) : (
        <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
          <CustomerIconValue icon="location-dot">
            {selectedAddress ? (
              <CustomerAddressDisplayName
                address={selectedAddress}
                isDefault={defaultAddressId === selectedAddress.id}
              />
            ) : (
              <T>Customers:addresses.empty</T>
            )}
          </CustomerIconValue>
        </EditButton>
      )}
    </FlexRow>
  )
}

////////////

const getExtraStyles = (theme: Theme) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${theme.spacing.gu(1)}rem`,
    minHeight: '30px',
    pagging: 0,
    width: `calc(100% + ${theme.spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${theme.spacing.gu(1)}rem`,
    marginTop: 4,
    width: `calc(100% + ${theme.spacing.gu(2)}rem)`,
    zIndex: 10005,
  }),
  option: (
    styles: CSSProperties,
    { isSelected }: OptionProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    cursor: 'pointer',
    paddingLeft: 8,
    path: {
      fill: isSelected && theme.palette.white,
    },
    span: {
      color: isSelected && theme.palette.white,
    },
  }),
})
