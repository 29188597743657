import styled from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'

export const DataTableCheckbox = (props: { [key: string]: any }) => (
  <CheckboxWrapper>
    <CheckboxInput {...props} />
  </CheckboxWrapper>
)

////////////

const CheckboxWrapper = styled.span`
  display: flex;
  justify-content: center;

  & * {
    margin: 0;
  }
`
