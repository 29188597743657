import { gql } from '@apollo/client'

import purchaseFragment from './salesForPrintPurchase'

export default gql`
  ${purchaseFragment}

  fragment ResourceReservationForSalesPrint on ResourceReservation {
    customerVisibility
    description
    dimensions {
      selection {
        dimension {
          name
        }
        selectedLabel {
          name
        }
      }
    }
    end
    group {
      customerEnd
      customerStart
      id
      name
      reservations {
        id
      }
    }
    id
    internalNote
    purchaseProducts {
      ...PurchaseForSalesPrint
    }
    resource {
      id
      internalInfo
      name
      type
    }
    resourceQuantity
    start
  }
`
