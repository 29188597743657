import { gql, useQuery } from '@apollo/client'

import {
  PointsOfSalesQuery as QueryData,
  PointsOfSalesQueryVariables as QueryVariables,
} from '~generated-types'

type PointOfSales = QueryData['registry']['pointOfSales'][0]

const QUERY = gql`
  query PointsOfSales {
    registry {
      pointOfSales {
        id
        name
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export const usePointsOfSales = (params?: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
    }
  )

  const pointsOfSales: PointOfSales[] | null = data
    ? data.registry.pointOfSales
    : null

  return {
    error,
    loading,
    pointsOfSales,
    refetch,
  }
}
