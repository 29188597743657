import styled, { css } from 'styled-components/macro'

export const EllipsisButton = styled.button`
  padding: 0;
  margin: 0;
  height: 100%;

  font-weight: 900;

  background: 0;
  border: none;

  outline: none;
  cursor: pointer;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
  `}
`
