import { SalesForPrintParticipant } from '../../../../../types'

export type ParticipantsByGroup = {
  group: string
  participants: SalesForPrintParticipant[]
}

export const groupByGroup = (participants: SalesForPrintParticipant[]) =>
  Object.values(
    participants.reduce((acc: { [group: string]: ParticipantsByGroup }, p) => {
      const group = p.group ?? 'NO_GROUP'

      acc[group] = {
        group,
        participants: [...(acc[group]?.participants ?? []), p],
      }

      return acc
    }, {})
  ).sort((a, b) =>
    a.group === 'NO_GROUP'
      ? 1
      : b.group === 'NO_GROUP'
      ? -1
      : a.group > b.group
      ? 1
      : -1
  )
