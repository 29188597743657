import { T, translate, useLanguageContext } from '@/modules/Language'

import {
  ListingMealType,
  ListingQuantity,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { MealTypeLabel } from '../common'
import { MealRow } from './MealRow'

type Props = {
  data: ListingScheduledMeal[]
  mealType: ListingMealType
}

export const MealTypeMeals = ({ data, mealType }: Props) => {
  const { language } = useLanguageContext()

  const totals = data.reduce(
    (acc: { [key: string]: number }, x: ListingScheduledMeal) => {
      x.quantities.forEach(({ ageCategory, quantity }: ListingQuantity) => {
        const key = ageCategory?.name ?? 'missed'

        acc.all += quantity
        acc[key] = acc[key] || 0
        acc[key] += quantity
      })

      return acc
    },
    {
      all: 0,
    }
  )

  const renderTotals = () =>
    Object.keys(totals)
      .filter((x) => x !== 'all')
      .filter((x) => !!totals[x])
      .map(
        (x) =>
          `${totals[x]} × ${
            x !== 'missed'
              ? x
              : translate('Listings:PacketMealListing.field.noAge', language)
          }`
      )
      .join(', ')

  return data.length ? (
    <>
      <tr>
        <td colSpan={2}>
          <MealTypeLabel>{mealType.name}</MealTypeLabel>
        </td>
        <td colSpan={2}>
          <MealTypeLabel>
            {totals.all} <T>common:unit.PERSON</T>
          </MealTypeLabel>
        </td>
        <td>
          <MealTypeLabel>{renderTotals()}</MealTypeLabel>
        </td>
        <td />
      </tr>
      {data.map((x) => (
        <MealRow data={x} key={x.id} />
      ))}
    </>
  ) : null
}
