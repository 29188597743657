import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'

type Props = {
  targetName: string
  targetOrderNumber: number
  isDefaultTarget: boolean
  readOnly?: boolean
  onNameUpdate: (name: string) => Promise<void>
}

export const TargetName = ({
  targetName,
  targetOrderNumber,
  isDefaultTarget,
  readOnly,
  onNameUpdate,
}: Props) => {
  const { language } = useLanguageContext()

  const [name, setName] = useState(targetName)

  useEffect(() => {
    targetName !== name && setName(targetName)
  }, [targetName])

  return !isDefaultTarget ? (
    <StyledInput
      disabled={readOnly}
      onBlur={() => targetName !== name && onNameUpdate(name)}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          onNameUpdate(name)
        }
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setName(e.target.value)
      }
      placeholder={`${translate(
        `Accommodation:TargetGroup.target`,
        language
      )} #${targetOrderNumber}`}
      type="text"
      value={name}
    />
  ) : (
    <DefaultWrapper alignItems="center">
      <Chip color="primary" variant={'outlined'} size="sm">
        {targetName || <T>Accommodation:TargetGroup.default</T>}
      </Chip>
    </DefaultWrapper>
  )
}

const StyledInput = styled(Input)`
  &&& {
    height: auto;
    border: 0;
    border-radius: 0;
    font-weight: 500;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-weight: 400;
      opacity: 1;
    }

    ${({ theme }) => css`
      max-width: ${theme.spacing.gu(24)}rem;
      font-size: ${theme.typography.fontSizeBase};
      &:hover {
        background: ${theme.palette.smoke.light};
      }

      &::placeholder {
        color: ${theme.palette.text.lighter};
      }
    `}
  }
`

const DefaultWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(24)}rem;
  `}
  width: 100%;
`
