import { CSSProperties, ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components/macro'

import { InlineFieldButton } from '@/components/ExtraButtons'
import { HoverHelper } from '@/components/ToggleHelpers/HoverHelper'

type Props = {
  disabled?: boolean
  flex?: boolean
  handleOnClick?: (...args: Array<any>) => any
  inline?: boolean
  innerRef?: Ref<HTMLButtonElement>
  placeholder?: ReactNode
  style?: CSSProperties
  value: ReactNode | null | undefined
}

export const ToggleButtonHelper = ({
  disabled,
  flex,
  handleOnClick,
  inline,
  innerRef,
  placeholder,
  style,
  value,
}: Props) => (
  <Wrapper inline={inline}>
    <HoverHelper disabled={disabled}>
      <StyledInlineFieldButton
        disabled={disabled}
        onClick={() => handleOnClick && handleOnClick()}
        ref={innerRef}
        style={style}
      >
        <div style={{ flexGrow: flex ? 1 : 0 }}>
          {value || placeholder || '—'}
        </div>
      </StyledInlineFieldButton>
    </HoverHelper>
  </Wrapper>
)

////////////

const StyledInlineFieldButton = styled(InlineFieldButton)`
  border-radius: 4px;
  justify-content: center;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
  `};

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.lighter};
    `};
  }
`

const Wrapper = styled.div<{ inline?: boolean }>`
  width: 100%;
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`
