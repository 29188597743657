import { DimensionSelection } from './types'

export default function transformSelectorValueToSelection(
  value: string[] | string | null | undefined
): DimensionSelection {
  const rawValueArr: string[] = Array.isArray(value)
    ? value
    : typeof value === 'string'
    ? [value]
    : []

  return rawValueArr.reduce((acc: DimensionSelection, val) => {
    const [dimensionName, labelName] = val.split('—')

    if (!dimensionName || !labelName) {
      return acc
    }

    const dimensionLabels = !!acc[dimensionName]
      ? [...acc[dimensionName], labelName]
      : [labelName]

    return {
      ...acc,
      [dimensionName]: dimensionLabels,
    }
  }, {})
}
