import { gql, useMutation } from '@apollo/client'

import type {
  UpdateSalesProductItemMealMutation,
  UpdateSalesProductItemMealMutationVariables,
} from '~generated-types'

import { productFragments } from '../fragments'

const MUTATION = gql`
  ${productFragments.productMeal}

  mutation UpdateSalesProductItemMeal($input: ProductMealInput!) {
    salesProductItemUpdateMeal(input: $input) {
      ...ProductMeal
    }
  }
`

export const useUpdateSalesProductItemMealMutation = () =>
  useMutation<
    UpdateSalesProductItemMealMutation,
    UpdateSalesProductItemMealMutationVariables
  >(MUTATION)
