import styled, { css } from 'styled-components/macro'

export const DataFieldColorCircle = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color, theme }) => css`
    background: ${color || theme.palette.white};
    flex: 0 0 ${theme.spacing.guPx(1) + 3}px;
    width: ${theme.spacing.guPx(1) + 3}px;
    height: ${theme.spacing.guPx(1) + 3}px;
    border: solid 1px ${color ? 'transparent' : theme.palette.primary.main};
  `}

  border-radius: 100%;
  margin: auto;
`
