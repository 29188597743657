import { ReactiveBase as LibraryReactiveBase } from '@appbaseio/reactivesearch'
import { ReactiveBaseProps } from '@appbaseio/reactivesearch/lib/components/basic/ReactiveBase'

import { useTheme } from '@/theme'

import { getTheme } from './theme'

export const ReactiveBase = (props: ReactiveBaseProps) => {
  const baseTheme = useTheme()

  return (
    <LibraryReactiveBase
      {...props}
      theme={getTheme(baseTheme)}
      transformRequest={(request) => ({
        ...request,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          ...request.headers,
        },
      })}
    />
  )
}
