import { gql } from '@apollo/client'

export default gql`
  query DimensionHierarchy($dimensionId: ID!) {
    registry {
      dimensionHierarchy(dimensionId: $dimensionId) {
        name
        dimension {
          id
          name
        }
        labels {
          label {
            id
            name
          }
          uniquePath
          childHierarchy {
            dimension {
              id
              name
            }
            labels {
              label {
                id
                name
              }
              uniquePath
              childHierarchy {
                dimension {
                  id
                  name
                }
                labels {
                  label {
                    id
                    name
                  }
                  uniquePath
                  childHierarchy {
                    dimension {
                      id
                      name
                    }
                    labels {
                      label {
                        id
                        name
                      }
                      uniquePath
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
