import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'

type Props = {
  isChecked: boolean
  isIndeterminate?: boolean
  amountSelected?: number
  totalEntries?: number
  noMargin?: boolean
  handleSelect: (allSelected: boolean) => void
}

export const CheckboxSelector = ({
  isChecked,
  isIndeterminate,
  amountSelected,
  totalEntries,
  noMargin,
  handleSelect,
}: Props) => {
  return (
    <FlexColumn justifyContent="center" noPadding style={{ marginLeft: -1 }}>
      <StyledCheckboxInput noMargin={noMargin}>
        <CheckboxInput
          checked={isChecked}
          indeterminate={isIndeterminate}
          noMargin
          onChange={() => handleSelect(!isChecked)}
        >
          {amountSelected || totalEntries ? (
            <FontWeight>
              <T>ResourceReservations:reservation.selected</T> {amountSelected}{' '}
              / {totalEntries}
            </FontWeight>
          ) : (
            false
          )}
        </CheckboxInput>
      </StyledCheckboxInput>
    </FlexColumn>
  )
}

const StyledCheckboxInput = styled.div<{
  noMargin?: boolean
}>`
  ${({ theme, noMargin }) =>
    !noMargin &&
    css`
      & > label > span {
        margin-right: ${theme.spacing.gu(1)}rem;
      }
    `}
`
