import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { PaymentTypeOption } from '@/modules/Registry/PaymentTypeOption'

import { PaymentType as PaymentMethod } from '~generated-types'

import { PaymentType } from '../../types'
import {
  CreditCardPaymentButton,
  PaymentButton,
  VoucherPaymentButton,
} from './components'

type Props = {
  loading: boolean
  paymentType: PaymentType | null
  paymentTypeOptions: PaymentTypeOption[]
  pointOfSaleId: string | null
  readOnly: boolean
  setPaymentType: (paymentType: PaymentType | null) => void
}

export const PaymentTypesSection = ({
  loading,
  paymentType,
  paymentTypeOptions,
  pointOfSaleId,
  readOnly,
  setPaymentType,
}: Props) => {
  const cashPaymentValidation = paymentTypeOptions.find(
    ({ type }) => type === PaymentMethod.Cash
  )

  const creditCardPaymentValidation = paymentTypeOptions.find(
    ({ type }) => type === PaymentMethod.CreditCard
  )

  const giftCardPaymentValidation = paymentTypeOptions.find(
    ({ type }) => type === PaymentMethod.GiftCard
  )

  const voucherPaymentValidations = paymentTypeOptions.filter(
    ({ type }) => type === PaymentMethod.Voucher
  )

  const handleSetPaymentType = (method: PaymentMethod) =>
    setPaymentType(paymentType?.method !== method ? { method } : null)

  return (
    <Wrapper>
      <FlexRow wrap="wrap">
        <PaymentButton
          icon="wallet"
          isSelected={paymentType?.method === PaymentMethod.Cash}
          onClick={() => handleSetPaymentType(PaymentMethod.Cash)}
          readOnly={readOnly}
          type={PaymentMethod.Cash}
          validation={cashPaymentValidation ?? null}
        />

        <Spacer />

        <PaymentButton
          icon="gift"
          isSelected={paymentType?.method === PaymentMethod.GiftCard}
          onClick={() => handleSetPaymentType(PaymentMethod.GiftCard)}
          readOnly={readOnly}
          type={PaymentMethod.GiftCard}
          validation={giftCardPaymentValidation ?? null}
        />

        <CreditCardPaymentButton
          paymentType={paymentType}
          pointOfSaleId={pointOfSaleId}
          readOnly={readOnly}
          setPaymentType={setPaymentType}
          validation={creditCardPaymentValidation ?? null}
        />

        <Spacer />

        <VoucherPaymentButton
          loading={loading}
          paymentType={paymentType}
          readOnly={readOnly}
          setPaymentType={setPaymentType}
          validations={voucherPaymentValidations}
        />
      </FlexRow>
    </Wrapper>
  )
}

///////

const Spacer = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}
`
