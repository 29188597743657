import { T, translate, useLanguageContext } from '@/modules/Language'
import { ListingTable } from '@/modules/Listing/common'
import { PrintSize } from '@/modules/Listing/types'

import {
  ListingMealType,
  ListingQuantity,
  ListingRestaurant,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { compareMealsFn } from '../../utils'
import { MealTypeLabel } from '../common'

type Props = {
  data: {
    [mealKey: string]: ListingScheduledMeal[]
  }
  mealTypes: ListingMealType[]
  restaurant: ListingRestaurant
  printSize: PrintSize
}

export const RestaurantMealsPlanning = ({
  data,
  mealTypes,
  printSize,
}: Props) => {
  const { language } = useLanguageContext()

  const dataCount = Object.keys(data).reduce(
    (acc, mKey) => acc + (data[mKey] || []).length,
    0
  )

  const renderMealRow = (
    data: ListingScheduledMeal[],
    mealType: ListingMealType
  ) => {
    const totals = data.reduce(
      (acc: { [key: string]: number }, x: ListingScheduledMeal) => {
        x.quantities.forEach(({ ageCategory, quantity }: ListingQuantity) => {
          const key = ageCategory?.name ?? 'missed'

          acc.all += quantity
          acc[key] = acc[key] || 0
          acc[key] += quantity
        })

        return acc
      },
      {
        all: 0,
      }
    )

    const renderTotals = () =>
      Object.keys(totals)
        .filter((x) => x !== 'all')
        .filter((x) => !!totals[x])
        .map(
          (x) =>
            `${totals[x]} × ${
              x !== 'missed'
                ? x
                : translate('Listings:PacketMealListing.field.noAge', language)
            }`
        )
        .join(', ')

    return (
      <tr key={`meal-type-meal-${mealType.id}`}>
        <td style={{ width: '25%' }}>
          <MealTypeLabel>{mealType.name}</MealTypeLabel>
        </td>
        <td style={{ width: '20%' }}>
          <MealTypeLabel>
            {totals.all} <T>common:unit.PERSON</T>
          </MealTypeLabel>
        </td>
        <td style={{ width: '50%' }}>
          <MealTypeLabel>{renderTotals()}</MealTypeLabel>
        </td>
        <td />
      </tr>
    )
  }

  return dataCount ? (
    <ListingTable printSize={printSize}>
      <thead>
        <tr>
          <th />
          <th>
            <T>Listings:PacketMealListing.field.people</T>
          </th>
          <th>
            <T>Listings:PacketMealListing.field.sales</T>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {compareMealsFn(data, mealTypes).map((m) =>
          data[m.id] ? renderMealRow(data[m.id] || [], m) : null
        )}
      </tbody>
    </ListingTable>
  ) : null
}
