import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

import {
  SalesForPrintAccommodationRoomReservation,
  SalesPrintConfigKey as Key,
} from '../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../utils/salesPrintConfig'
import { formatRoom, getTargetName } from '../../utils'
import { Features } from './Features'
import { Notes } from './Notes'

type Props = {
  isNested?: boolean
  reservation: SalesForPrintAccommodationRoomReservation
}

export const RoomReservation = ({
  isNested,
  reservation: {
    request: {
      beds,
      checkIn,
      checkOut,
      extraBeds,
      features,
      info,
      room: { number },
    },
    target,
  },
}: Props) => {
  const { language } = useLanguageContext()
  const { salesPrintConfig: config } = salesHooks.useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Accommodation}.${key}`)

  return (
    <Wrapper isNested={isNested}>
      <Row alignItems="center">
        {checkVisibility(Key.Room) && (
          <Section style={{ width: isNested ? '14rem' : '16rem' }}>
            <span style={{ fontWeight: 600 }}>{formatRoom(number)}</span>
            <span style={{ fontSize: '0.9rem', whiteSpace: 'pre-wrap' }}>
              {' '}
              ({beds} + {extraBeds})
            </span>
          </Section>
        )}

        {checkVisibility(Key.Target) && (
          <Section flex={1}>
            {target ? getTargetName(target, language) : '–'}
          </Section>
        )}

        {checkVisibility(Key.Dates) && (
          <Section flex={1}>
            {formatDate(checkIn.date, checkOut.date, 'short')}
          </Section>
        )}

        {checkVisibility(Key.Features) && <Features features={features} />}
      </Row>

      {checkVisibility(Key.Notes) && !!info && (
        <Notes info={info} isNested={isNested} />
      )}
    </Wrapper>
  )
}

////////////

const Row = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem 0;
  `}
`

const Section = styled(FlexRow)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ isNested?: boolean }>`
  font-size: 1.1rem;
  margin-top: -1px;
  page-break-inside: avoid;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.text.light};
    border-top: 1px solid ${theme.palette.text.light};
  `}

  ${({ isNested, theme }) =>
    isNested &&
    css`
      margin-left: ${theme.spacing.gu(4)}rem;
    `}
`
