import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModal,
  InlineModalContent,
  InlineModalSection,
} from '@/components/InlineModal'
import { List, ListContent } from '@/components/List'
import { T } from '@/modules/Language'
import { useLastUsedSalesContext } from '@/modules/Sales/contexts'
import { LastUsedSalesPayload } from '@/modules/Sales/queries'
import { useTheme } from '@/theme'

import { LastUsedSalesModalHeader } from './LastUsedSalesModalHeader'
import { LastUsedSalesModalRow } from './LastUsedSalesModalRow'

type Props = {
  isShowAll: boolean
  onClose: () => void
  onShowAll: () => void
  sales: LastUsedSalesPayload['salesAll']
}

export const LastUsedSalesModal = ({
  isShowAll,
  onClose,
  onShowAll,
  sales,
}: Props) => {
  const { lastUsedSalesIds } = useLastUsedSalesContext()
  const { spacing } = useTheme()

  return (
    <InlineModal style={{ paddingBottom: `${spacing.gu(1)}rem` }}>
      <InlineModalContent>
        <InlineModalSection>
          {sales.length ? (
            <List>
              <LastUsedSalesModalHeader />

              <ListContent>
                {sales.slice(0, isShowAll ? 10 : 5).map((sale) => (
                  <LastUsedSalesModalRow
                    key={sale.id}
                    onClose={onClose}
                    sale={sale}
                  />
                ))}
              </ListContent>
            </List>
          ) : (
            <Placeholder>
              <T>Sales:lastUsedSales.empty</T>
            </Placeholder>
          )}
        </InlineModalSection>

        {!isShowAll && lastUsedSalesIds.length > 5 && (
          <InlineModalSection
            style={{ justifyContent: 'center', marginTop: 0 }}
          >
            <InnocuousButton onClick={onShowAll}>
              <PrimaryColor>
                <T>Sales:lastUsedSales.showAll</T>
              </PrimaryColor>
            </InnocuousButton>
          </InlineModalSection>
        )}
      </InlineModalContent>
    </InlineModal>
  )
}

/////

const Placeholder = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`
