import styled, { css } from 'styled-components/macro'

import { FontWeight, P } from '@/components/Typography'
import { translate, useLanguageContext } from '@/modules/Language'

type Props = {
  status: Array<any>
  dateGroups: Array<any>
}

export const Calendar = ({ status, dateGroups }: Props) => {
  const { language } = useLanguageContext()

  const weekDays = [
    translate(`enums:weekday.abbrev.MONDAY`, language),
    translate(`enums:weekday.abbrev.TUESDAY`, language),
    translate(`enums:weekday.abbrev.WEDNESDAY`, language),
    translate(`enums:weekday.abbrev.THURSDAY`, language),
    translate(`enums:weekday.abbrev.FRIDAY`, language),
    translate(`enums:weekday.abbrev.SATURDAY`, language),
    translate(`enums:weekday.abbrev.SUNDAY`, language),
  ]

  return (
    <Wrapper>
      <Row>
        {['', ...weekDays].map((weekDay, index) => (
          <Cell key={`weekday-${index}`}>{weekDay}</Cell>
        ))}
      </Row>
      {dateGroups.map(({ label, items, key }) => (
        <Row key={key}>
          <Cell>{label}</Cell>
          {items.map(({ key, label }: any) => {
            const reservationDate = status.find(({ date }) => key === date)

            return (
              <Cell
                key={key}
                filled={
                  reservationDate === undefined
                    ? null
                    : reservationDate.available / reservationDate.reserved
                }
              >
                <P>
                  <FontWeight semiBold>
                    {reservationDate
                      ? `${reservationDate.available}/${reservationDate.reserved}`
                      : '–'}
                  </FontWeight>
                </P>
                <P>{label}</P>
              </Cell>
            )
          })}
        </Row>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: inline-flex;
  flex-direction: column;
  position: relative;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
  `}

  & > div:not(:first-child) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.dark};
    `}

    & > div:nth-child(2) {
      ${({ theme }) => css`
        border-left: 1px solid ${theme.palette.smoke.dark};
      `}
    }
  }
`

const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:first-child > div:nth-child(2) {
    ${({ theme }) => css`
      border-left: 1px solid ${theme.palette.smoke.dark};
    `}
  }
`

const Cell = styled.div<{ filled?: number | null }>`
  position: relative;
  text-align: center;
  width: 50px;
  min-height: 25px;
  line-height: 25px;

  & p:first-child {
    margin-bottom: 0;
    color: ${({ filled, theme }) =>
      filled === null || filled === undefined
        ? theme.palette.black
        : filled === 0
        ? theme.palette.danger.main
        : filled < 1
        ? '#a7a711'
        : theme.palette.success.main};
  }

  background-color: ${({ filled, theme }) =>
    filled === null ? theme.palette.smoke.light : theme.palette.white};
`
