import React, { ReactNode, Ref, useState } from 'react'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { useTheme } from '@/theme'

import { ItemsList, Option } from './components/ItemsList'
import { ModalContainer } from './ModalContainer'

type Props = {
  noSearch?: boolean
  type?: 'select' | 'checkbox'
  title?: any
  disabled?: boolean
  options: Option[]
  handleChange: (value: string, isSelected?: boolean) => void
  referenceElement: (
    ref: Ref<HTMLDivElement> | undefined,
    setModalOpen: (isModalOpen: boolean) => void
  ) => ReactNode
  loading?: boolean
  error?: any
  emptyPlaceholder?: ReactNode | string
  zIndex?: number
}

export const ModalSelector = ({
  noSearch = false,
  type = 'select',
  title,
  options,
  handleChange,
  referenceElement,
  loading,
  error,
  emptyPlaceholder,
  zIndex,
}: Props) => {
  const theme = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <FlexColumn
            flex={1}
            alignItems={'center'}
            style={{
              maxHeight: '370px',
              minWidth: '250px',
              padding: `${theme.spacing.gu(2)}rem 0`,
            }}
          >
            {!!title && (
              <FontWeight
                semiBold
                style={{
                  fontSize: theme.typography.fontSizeBase2,
                  marginBottom: `${theme.spacing.gu(1)}rem`,
                }}
              >
                {title}
              </FontWeight>
            )}

            <ItemsList
              noSearch={noSearch}
              type={type}
              options={options}
              handleChange={handleChange}
              setModalOpen={setModalOpen}
              loading={loading}
              error={error}
              emptyPlaceholder={emptyPlaceholder}
            />
          </FlexColumn>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => referenceElement(ref, setModalOpen)}
      zIndex={zIndex}
    />
  )
}
