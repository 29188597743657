import { Response } from '@/utils/api'
import elasticApiSearch from '@/utils/api/elasticApi/elasticApiSearch'

function elasticApiGet<T>(
  index: string,
  documentType: string,
  documentId: string
): Promise<Response<T | null | undefined>> {
  return elasticApiSearch<T>(index, {
    query: {
      match: {
        id: documentId,
      },
    },
  }).then(({ data, ok, ...rest }) => ({
    ...rest,
    data: data[0],
    ok,
  }))
}

export default elasticApiGet
