import { gql } from '@apollo/client'

export default gql`
  query OrderSelector($id: ID!) {
    sales(id: $id) {
      id
      orders(filterIsOpen: false) {
        customer {
          details {
            name
            type
          }
        }
        id
        lifecycle {
          state
        }
        name
        orderNumber
        paymentInfo {
          totalPrice {
            amountVatIncluded
          }
        }
        sortOrder
      }
    }
  }
`
