import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'

import { CustomerAddress } from '../types'
import { parseAddressName } from '../utils'

type Props = {
  address: CustomerAddress
  isDefault?: boolean
}

export const CustomerAddressDisplayName = ({ address, isDefault }: Props) => {
  const addressName = parseAddressName(address)

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {addressName || <T>Customers:address.empty</T>}
      {isDefault && (
        <FontColor lighter>
          {' | '}
          <T>Customers:addresses.default</T>
        </FontColor>
      )}
    </div>
  )
}
