import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { ListItem, ListItemSection } from '@/components/List'
import { formatCurrency } from '@/utils/currency'

import { OpenAdvancesQuery } from '~generated-types'

import { getCustomerName } from '../utils'

type Props = {
  item: OpenAdvancesQuery['openAdvances'][0]
}

export const OpenAdvancesListItem = ({
  item: {
    amountWithoutServiceFeeOrVat,
    customerNumber,
    customerName,
    invoiceDate,
    invoiceNumber,
    salesEnd,
    salesId,
    salesNumber,
    sellerShortName,
  },
}: Props) => {
  const handleOpenSales = () => {
    const url = `/sales/details/${salesId}?view[0]=orders`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <ListItemRow align="center" onClick={handleOpenSales}>
      <ListItemSection>{sellerShortName}</ListItemSection>
      <ListItemSection>
        {salesEnd ? moment(salesEnd).format('YYYY') : '–'}
      </ListItemSection>
      <ListItemSection>
        {salesEnd ? moment(salesEnd).format('MMMM') : '–'}
      </ListItemSection>
      <ListItemSection>{salesNumber}</ListItemSection>
      <ListItemSection>{invoiceNumber}</ListItemSection>
      <ListItemSection>
        {invoiceDate ? moment(invoiceDate).format('DD.MM.YYYY') : '–'}
      </ListItemSection>
      <ListItemSection>
        {getCustomerName(customerName, customerNumber)}
      </ListItemSection>
      <ListItemSection align="right">
        {formatCurrency(amountWithoutServiceFeeOrVat ?? 0)}
      </ListItemSection>
    </ListItemRow>
  )
}

//////

const ListItemRow = styled(ListItem)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(6)}rem;
  `}
`
