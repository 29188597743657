import styled, { css } from 'styled-components/macro'

import { Chip as ChipBase, ChipProps } from '@/components/Chip'
import { T } from '@/modules/Language'
import { CalendarResourceType } from '@/modules/Registry/CalendarResources'
import { Palette, useTheme } from '@/theme'

type Props = ChipProps & {
  type: CalendarResourceType
}

export const CalendarResourceTypeChip = ({ type, ...chipProps }: Props) => {
  const { palette } = useTheme()

  return (
    <Chip
      customColor={{
        fill: 'transparent',
        stroke: getChipColor(type, palette),
      }}
      {...chipProps}
    >
      <T>{`ResourceReservationsCalendar:resourceType.${type}`}</T>
    </Chip>
  )
}

///////

const getChipColor = (type: CalendarResourceType, palette: Palette) => {
  switch (type) {
    case 'EQUIPMENT':
      return palette.secondary.light
    case 'HUMAN_RESOURCE':
      return palette.danger.light
    case 'PROPERTY':
    default:
      return palette.primary.lighter
  }
}

const Chip = styled(ChipBase)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutterSmall} 0;
  `}
`
