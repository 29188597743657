import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { ListingTable } from '@/modules/Listing/common'

import { PrintSize } from '../../../types'
import { ParticipantsMode } from '../../common'
import type { ArrivalsSales } from '../useArrivalsForDate'
import { SalesTableRow } from './SalesTableRow'

type Props = {
  data: Readonly<ArrivalsSales[]>
  guestCounts: Record<string, Record<string, number>>
  printSize: PrintSize
  selection: string[]
  selectionEnabled: boolean
  targetDate: Moment
  toggleSelection: (target: string) => void
  participantsMode: ParticipantsMode
}

export const SalesTable = ({
  data,
  guestCounts,
  printSize,
  selection,
  selectionEnabled,
  targetDate,
  toggleSelection,
  participantsMode,
}: Props) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const headerRef = useRef<HTMLTableRowElement | null>(null)

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  }, [headerRef])

  const hideHeaderFromPrint = selectionEnabled && !selection.length

  return (
    <ListingTable>
      <thead className={hideHeaderFromPrint ? 'hide-from-print' : ''}>
        <tr ref={headerRef}>
          <th style={{ width: '14%' }}>
            <Label>
              <T>Listings:ArrivalsAndDeparturesListing.field.room</T>
            </Label>
          </th>
          <th style={{ width: '7%' }}>
            <Label>
              <FontAwesomeIcon icon="bed" size="sm" />
            </Label>
          </th>
          <th style={{ width: '15%' }}>
            <Label>
              <T>Listings:ArrivalsAndDeparturesListing.field.guest</T>
            </Label>
          </th>
          <th style={{ width: '20%' }}>
            <Label>
              <T>Listings:ArrivalsAndDeparturesListing.field.product</T>
            </Label>
          </th>
          <th style={{ width: '16%' }}>
            <Label>
              <T>Listings:ArrivalsAndDeparturesListing.field.guestInfo</T>
            </Label>
          </th>
          <th style={{ width: '16%' }}>
            <Label>
              <T>Listings:ArrivalsAndDeparturesListing.field.info</T>
            </Label>
          </th>
          <th style={{ width: '8%' }}>
            <Label>
              <T>Listings:ArrivalsAndDeparturesListing.field.duration</T>
            </Label>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((x) => (
          <SalesTableRow
            key={`arrivals-sales-${x.id}`}
            data={x}
            guestCounts={guestCounts[x.id]}
            headerOffset={headerHeight}
            isSelected={selection.includes(x.id)}
            printSize={printSize}
            selectionEnabled={selectionEnabled}
            targetDate={targetDate}
            toggleSelection={() => toggleSelection(x.id)}
            participantsMode={participantsMode}
          />
        ))}
      </tbody>
    </ListingTable>
  )
}

////////////

const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
