import { CSSProperties, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { useDialogService } from '@/components/DialogService'
import { FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { CalendarResourcesService } from '@/modules/Registry/CalendarResources'
import {
  OverlappingError,
  Reservation,
  ResourceReservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { Spacing, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { EditButton } from '../common'

type Props = {
  isEditable: boolean
  readOnly: boolean
  reservationId: string
  resourceName: string
  updateReservations: (reservations: Reservation[]) => void
}

export const ResourceSelector = ({
  isEditable,
  readOnly,
  reservationId,
  resourceName,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { confirm, warn } = useDialogService()
  const { changeResource } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [resourceOptions, setResourceOptions] = useState<Option[]>([])

  useEffect(() => {
    if (isEditMode && !resourceOptions.length) {
      setProcessing(true)

      CalendarResourcesService.findAll()
        .then((resources) =>
          setResourceOptions(
            resources.sort(generateCompareFn('name')).map(({ id, name }) => ({
              label: name || '',
              searchValue: name || '',
              value: id,
            }))
          )
        )
        .finally(() => setProcessing(false))
    }
  }, [isEditMode, resourceOptions.length])

  const handleChangeResource = (resourceId: string) => {
    setProcessing(true)

    const doChangeResource = (allowOverbooking: boolean) =>
      changeResource(allowOverbooking, reservationId, resourceId, 1)

    return doChangeResource(false)
      .then((data: void | ResourceReservation | OverlappingError | null) => {
        const errorData = data as OverlappingError

        if (errorData.message) {
          if (errorData.type === 'WARNING') {
            return renderConfirmDialog()
              .then(() => doChangeResource(true))
              .catch(() => undefined)
          }
          if (errorData.type === 'ERROR') {
            return renderWarningDialog().catch(() => undefined)
          }
        }
      })
      .catch(() => undefined)
      .finally(() => {
        setEditMode(false)
        setHover(false)
        setProcessing(false)
      })
  }

  const renderConfirmDialog = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.continue</T>,
      description: (
        <T>ResourceReservationsCalendar:overlapWarning.description</T>
      ),
      title: <T>ResourceReservationsCalendar:overlapWarning.title</T>,
    })

  const renderWarningDialog = () =>
    warn({
      cancelLabel: <T>common:action.cancel</T>,
      description: <T>ResourceReservationsCalendar:overlapError.description</T>,
      title: <T>ResourceReservationsCalendar:overlapError.title</T>,
    })

  return isEditable ? (
    isEditMode ? (
      <ThemedSelect
        autoFocus
        extraStyles={getExtraStyles(spacing)}
        isClearable
        isCompact
        isLoading={isProcessing}
        isSearchable
        menuIsOpen={isEditMode}
        name="assignee-selector"
        noOptionsMessage={() => (
          <T>SalesDetails:dimensions.error.emptyLabels</T>
        )}
        onBlur={() => {
          setEditMode(false)
          setHover(false)
        }}
        onChange={(selected: Option | null | undefined) =>
          selected && handleChangeResource(selected.value)
        }
        options={resourceOptions}
        placeholder={resourceName}
        value={null}
      />
    ) : (
      <EditButton
        disabled={readOnly}
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ResourceName>{resourceName}</ResourceName>

        {isHover && (
          <FontAwesomeIcon
            color={palette.primary.main}
            icon="retweet"
            size="sm"
          />
        )}
      </EditButton>
    )
  ) : (
    <FlexRow flex={1}>
      <ResourceName>{resourceName}</ResourceName>
    </FlexRow>
  )
}

/////////

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
})

const ResourceName = styled.span`
  line-height: 1.1;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
