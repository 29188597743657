import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  children: ReactNode
}

export const FullScreenPlaceholder = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
