import { CSSProperties, useState } from 'react'
import { ControlProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import { ParticipantLifeStage } from '~generated-types'

import { isSameOption } from '../../utils'

type Props = {
  handleUpdateParticipan: (data: Partial<FetchedParticipant>) => Promise<void>
  selectedParticipants: FetchedParticipant[]
  readOnly: boolean
}

export const LifeStageSelector = ({
  handleUpdateParticipan,
  selectedParticipants,
  readOnly,
}: Props) => {
  const { spacing, palette } = useTheme()
  const { language } = useLanguageContext()

  const lifeStage = isSameOption(selectedParticipants, 'lifeStage')
    ? selectedParticipants[0]?.lifeStage || null
    : null

  const [isLoading, setLoading] = useState<boolean>(false)

  const extraStyles = {
    container: (styles: CSSProperties) => ({
      ...styles,
      fontWeight: 400,
      marginBottom: spacing.gutter,
      width: '100%',
    }),
    control: (
      styles: CSSProperties,
      { isDisabled, isFocused }: ControlProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      background: isDisabled ? palette.smoke.lighter : 'transparent',
      borderColor: isFocused ? palette.primary.main : palette.smoke.dark,
      cursor: 'pointer',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
  }

  const lifeStageOptions = Object.values(ParticipantLifeStage).map(
    (lifeStage: ParticipantLifeStage) => ({
      label: translate(`enums:lifeStage.${lifeStage}`, language),
      value: lifeStage,
    })
  )

  const handleMassUpdateLifeStage = async (
    value: ParticipantLifeStage | null
  ) => {
    setLoading(true)
    handleUpdateParticipan({ lifeStage: value }).then(() => setLoading(false))
  }

  return (
    <FlexColumn style={{ width: '100%' }}>
      <Title>
        {translate('ParticipantsList:PersonalFields.lifeStage', language)}
      </Title>
      <ThemedSelect
        extraStyles={extraStyles}
        isClearable
        isDisabled={readOnly}
        isLoading={isLoading}
        name="lifeStage-selector"
        onChange={(selected: Option | null | undefined) =>
          handleMassUpdateLifeStage(
            selected ? (selected.value as ParticipantLifeStage) : null
          )
        }
        options={lifeStageOptions}
        placeholder=""
        value={
          lifeStage
            ? lifeStageOptions.find(({ value }) => value === lifeStage)
            : null
        }
      />
    </FlexColumn>
  )
}

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
