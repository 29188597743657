import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'

export const ArrowButton = styled.div`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: ${theme.typography.fontSizeBigger};
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
    `}
  }
`

export const ControlWrapper = styled(FlexRow)`
  width: 100%;
`

export const TextButton = styled.div`
  cursor: pointer;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    margin-bottom: ${theme.spacing.gutterSmall};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
    `}
  }
`

export const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`
