import styled, { css } from 'styled-components'

import { translate, useLanguageContext } from '@/modules/Language'

enum GroupBy {
  Name = 'NAME',
  Room = 'ROOM',
}

type Props = {
  groupBy: GroupBy
  setGroupBy: (groupBy: GroupBy) => void
}

export const GroupBySelector = ({ groupBy, setGroupBy }: Props) => {
  const { language } = useLanguageContext()

  return (
    <Select
      onChange={(e) => setGroupBy(e.target.value as GroupBy)}
      onClick={(e) => e.stopPropagation()}
      value={groupBy}
    >
      <option value={GroupBy.Name}>
        {translate('Mobile:salesList.participants.groupByName', language)}
      </option>
      <option value={GroupBy.Room}>
        {translate('Mobile:salesList.participants.groupByRoom', language)}
      </option>
    </Select>
  )
}

//////

const Select = styled.select`
  border: none;
  outline: none;
  font-weight: 500;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
