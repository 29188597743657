import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { ExpansionPanel } from '@/components/ExpansionPanel'
import { FlexColumn } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  message: string | ReactNode
}

export const MessageWrapper = ({ message }: Props) => {
  const { spacing } = useTheme()

  const padding = `${spacing.gutter} 0 ${spacing.gutter} ${spacing.gutter}`

  return (
    <FlexColumn noPadding>
      <ExpansionPanel
        renderHeader={() => <Placeholder>{message}</Placeholder>}
        headerPadding={padding}
      />
    </FlexColumn>
  )
}

const Placeholder = styled.div`
  width: 100%;
  text-align: center;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
  `}
`
