import { ChangeEvent } from 'react'
import styled from 'styled-components/macro'

import { Select } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'

export enum PrintMode {
  All = 'ALL',
  EventsOnly = 'EVENTS_ONLY',
  SalesOnly = 'SALES_ONLY',
  Selected = 'SELECTED',
}

export enum ParticipantsMode {
  All = 'ALL',
  ArrivingTODAY = 'ARRIVING_TODAY',
  DeparturesTODAY = 'DEPARTING_TODAY',
}

type Props = {
  disabled?: boolean
  mode: PrintMode
  participantsMode: ParticipantsMode
  participantsModeOptions: ParticipantsMode[]
  printModeOptions: PrintMode[]
  setMode: (mode: PrintMode) => void
  setParticipantsMode: (participantsMode: ParticipantsMode) => void
}

export const PrintSettings = ({
  mode,
  participantsMode,
  participantsModeOptions,
  printModeOptions,
  setMode,
  setParticipantsMode,
}: Props) => {
  const { language } = useLanguageContext()

  return (
    <FlexRow>
      <Wrapper>
        <Title>
          <T>
            Listings:ArrivalsAndDeparturesListing.printSettings.participants
          </T>
        </Title>
        <Select
          style={{ height: 'auto' }}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setParticipantsMode(e.target.value as ParticipantsMode)
          }
          value={participantsMode}
        >
          {participantsModeOptions.map((x) => (
            <option key={`show-participants-option-${x}`} value={x}>
              {translate(
                `Listings:ArrivalsAndDeparturesListing.participantsMode.${x}`,
                language
              )}
            </option>
          ))}
        </Select>
      </Wrapper>
      <Wrapper>
        <Title>
          <T>Listings:ArrivalsAndDeparturesListing.printSettings.targets</T>
        </Title>

        <Select
          style={{ height: 'auto' }}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setMode(e.target.value as PrintMode)
          }
          value={mode}
        >
          {printModeOptions.map((x) => (
            <option key={`print-mode-option-${x}`} value={x}>
              {translate(
                `Listings:ArrivalsAndDeparturesListing.printMode.${x}`,
                language
              )}
            </option>
          ))}
        </Select>
      </Wrapper>
    </FlexRow>
  )
}

////////////

const Wrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.gu(3)}rem;

  & span {
    margin: 0;
  }
`

const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.gu(0.5)}rem;
`
